import React, { useEffect, useState } from "react";
import { Button, Form, InputGroup, Modal } from "react-bootstrap";
import { validationRule } from "../../utils/constant";
import { apiCall } from "../../utils/axiosService";
import { API_URL } from "../../utils/apiRoutes";
import { useNavigate } from "react-router";
import {
    errorToastMessage,
    successToastMessage,
} from "../../utils/toastMessage";
import { checkValidation } from "../../utils/helper";

const ForgotPasswordModal = ({ showModal, closeModal }) => {
    let validationObj = {
        email: "",
        password: "",
        validationRule: [
            {
                fieldName: "email",
                rule: validationRule.required,
                message: "Email is required",
            },
            {
                fieldName: "email",
                rule: validationRule.email,
                message: "Please enter valid email",
            },
        ],
        errorObj: {},
        isValidate: true,
    };

    const [modalData, setModalData] = useState(validationObj);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = async () => {
        setLoading(true);
        let validation = checkValidation(modalData);
        if (!validation?.isValidate) {
            setLoading(false);
            setModalData(validation);
            return;
        }
        let forgotPasswordResponse = await apiCall(
            {
                method: "POST",
                url: API_URL.BASE_API_URL.Configuration + API_URL.FORGOT_PASSWORD,
                body: {
                    email: modalData?.email,
                    requestfrom: "web",
                },
            },
            true
        );

        if (forgotPasswordResponse?.status_code === 0) {
            setLoading(false);
            return errorToastMessage(forgotPasswordResponse?.message);
        } else if (forgotPasswordResponse?.status_code === 1) {
            closeModal();
            return successToastMessage(forgotPasswordResponse?.message);
        } else if (forgotPasswordResponse?.status_code === 2) {
            localStorage.clear();
            window.location.href = API_URL.BASE_API_URL.EMA_MAIN_DOMAIN+"?logout=true";
        }
    };

    return (
        <>
            <Modal
                className="PortfolioSetupModalMain box-shadow-custom"
                size="md"
                centered
                show={showModal}
                onHide={closeModal}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Forgot Password
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="custom-modal-body">
                    <div className="flotingLableGroup">
                        <InputGroup>
                            <Form.Control
                                type="text"
                                placeholder="Email"
                                value={modalData?.email}
                                onChange={(e) =>
                                    setModalData({
                                        ...modalData,
                                        email: e.target.value,
                                    })
                                }
                            />
                        </InputGroup>
                        {modalData?.errorObj?.email && (
                            <small className="text-danger">
                                {modalData?.errorObj?.email}
                            </small>
                        )}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {loading ? (
                        <button className="default-btn primary-btn" type="button" disabled>
                            <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                            ></span>
                            Loading...
                        </button>
                    ) : (
                        <button
                            className="default-btn primary-btn"
                            onClick={() => {
                                handleSubmit();
                            }}
                        >
                            Submit
                        </button>
                    )}
                    <Button className="default-btn secondary-btn" onClick={closeModal}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ForgotPasswordModal;
