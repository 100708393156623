import React, { useEffect, useRef, useState } from "react";
import { apiCall } from "../../../../utils/axiosService";
import { API_URL } from "../../../../utils/apiRoutes";
import { apiResponse } from "../../../../utils/apiResponse";
import { commonService } from "../../../../utils/commonService";
import { Button, Col } from "react-bootstrap";
import HorizontalBarChart from "../../../CommonComponent/HorizontalBarChart";
import { Bar } from "react-chartjs-2";
import {
  Chart,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
} from "chart.js";
import { compName, month } from "../../../../utils/constant";
import WidgetNote from "../../WidgetNote";
import WidgetDetailModal from "../../WidgetDetailModal";
import { useSelector } from "react-redux";
import infoIcon from "../../../../Assets/Images/Action Icons/infoIcon..svg";
import NoteIcon from "../../../../Assets/Images/CRM/notes.svg";
import downloadIcon from "../../../../Assets/Images/Operation/downloadIcon.svg";
import AddWidgetModal from "../../AddWidgetModal";
import Comments from "../Comments/Comment";

const GroupChart = ({
  setCompDataFromChild,
  rowIndex,
  colIndex,
  api,
  para,
  setting,
}) => {
  const yearsArr = [
    ...commonService.getPastYearForDropDown(10),
    ...commonService.getFutureYearForDropDown(10),
    new Date().getFullYear(),
  ]?.sort(function (a, b) {
    return a - b;
  });
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const labels = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const [hoverValue, setHoverValue] = useState(null);
  let groupOtb = {
    label: "Group OTB",
    backgroundColor: "#ffb939",
    data: [],
    stack: "stack1",
  };
  let groupBlock = {
    label: "Group Block",
    backgroundColor: "#fdca6c",
    data: [],
    stack: "stack1",
  };

  let transient = {
    label: "Transient",
    backgroundColor: "#4f98ca",
    data: [],
    stack: "stack1",
  };

  let inventory = {
    label: "Inventory",
    backgroundColor: "#969696",
    data: [],
    stack: "stack2",
  };

  const [chartData, setChartData] = useState(null);

  const [loading, setLoading] = useState(false);
  const [showNoteSidebar, setShowNoteSidebar] = useState(false);
  const [widgetModal, setWidgetModal] = useState(false);
  const widgetDetailRef = useRef();
  const propertyDetailFromRTK = useSelector(
    (store) => store.headerProperty.propertyDetail
  );
  const closeWidgetModal = () => {
    setWidgetModal(false);
  };
  const closeNoteSideBar = () => {
    setShowNoteSidebar(false);
  };

  const getChartDetail = async () => {
    let chartDetailResponse = await apiCall(
      {
        method: "POST",
        url: API_URL.BASE_API_URL.SnapShot + API_URL.TRANSIENT_GROUP.CHART,
        body: {
          AsOfDate: para?.asOfDate,
          year: new Date().getFullYear(),
          propertyCode: para?.propertyCode,
        },
      },
      false
    );

    let response = apiResponse(false, chartDetailResponse);

    if (response?.isValidate) {
      let dataFromApi = response?.data?.data;
      let groupOtbPr = [],
        groupBlockPr = [],
        transientPr = [],
        inventoryPr = [];

      let groupOtbHover = [],
        groupBlockHover = [],
        transientHover = [],
        inventoryHover = [];

      for (let i = 0; i < month?.length; i++) {
        var groupBlockItem = dataFromApi.find((item) => {
          if (
            item.result_type === "group_blk" &&
            item.month?.trim() == month[i]
          ) {
            return item;
          }
          return null;
        });
        groupBlockPr.push(groupBlockItem ? groupBlockItem.Value : 0);

        var groupOtbItem = dataFromApi.find((item) => {
          if (
            item.result_type === "group_otb" &&
            item.month?.trim() == month[i]
          ) {
            return item;
          }
          return null;
        });
        groupOtbPr.push(groupOtbItem ? groupOtbItem.Value : 0);

        var transientItem = dataFromApi.find((item) => {
          if (
            item.result_type === "transient" &&
            item.month?.trim() == month[i]
          ) {
            return item;
          }
          return null;
        });
        transientPr.push(transientItem ? transientItem.Value : 0);

        var inventoryItem = dataFromApi.find((item) => {
          if (
            item.result_type === "inventory" &&
            item.month?.trim() == month[i]
          ) {
            return item;
          }
          return null;
        });
        inventoryPr.push(inventoryItem ? inventoryItem.Value : 0);
      }

      groupBlockPr = groupBlockPr.map(function (x, index) {
        groupBlockHover.push(x);
        if (Math.round((x * 100) / inventoryPr[index], 2) === NaN) {
          return 0;
        } else {
          return (x * 100) / inventoryPr[index];
        }
      });

      groupOtbPr = groupOtbPr.map(function (x, index) {
        groupOtbHover.push(x);
        if (Math.round((x * 100) / inventoryPr[index], 2) === NaN) {
          return 0;
        } else {
          return (x * 100) / inventoryPr[index];
        }
      });

      transientPr = transientPr.map(function (x, index) {
        transientHover.push(x);
        if (Math.round((x * 100) / inventoryPr[index], 2) === NaN) {
          return 0;
        } else {
          return (x * 100) / inventoryPr[index];
        }
      });

      inventoryPr = inventoryPr.map(function (x, index) {
        inventoryHover.push(x);
        return 100;
      });

      groupBlock.data = [];
      groupOtb.data = [];
      transient.data = [];
      inventory.data = [];

      groupBlock.data = groupBlockPr;
      groupOtb.data = groupOtbPr;
      transient.data = transientPr;
      inventory.data = inventoryPr;

      setChartData([groupBlock, groupOtb, transient, inventory]);
      setHoverValue({
        groupBlockHover,
        groupOtbHover,
        transientHover,
        inventoryHover,
      });
    }
    if (!response?.isValidate) {
      let arr = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      groupBlock.data = arr;
      groupOtb.data = arr;
      transient.data = arr;
      inventory.data = arr;

      setChartData([groupBlock, groupOtb, transient, inventory]);
    }
  };

  useEffect(() => {
    if (api === 0) {
      groupBlock.data = [
        100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100,
      ];
      groupOtb.data = [
        100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100,
      ];
      transient.data = [
        100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100,
      ];
      inventory.data = [
        100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100,
      ];

      let groupOtbHover = [
          100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100,
        ],
        groupBlockHover = [
          100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100,
        ],
        transientHover = [
          100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100,
        ],
        inventoryHover = [
          100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100,
        ];
      setChartData([groupBlock, groupOtb, transient, inventory]);
      setHoverValue({
        groupOtbHover,
        groupBlockHover,
        transientHover,
        inventoryHover,
      });
    } else {
      if (para?.asOfDate) {
        getChartDetail();
      }
    }
  }, [selectedYear, para?.asOfDate]);
  let chartDetail = {};
  let chartOptions = {};
  if (chartData && hoverValue) {
    chartDetail = {
      labels: labels,
      datasets: [
        {
          type: "bar",
          label: "Group Block",
          backgroundColor: chartData?.[0]?.backgroundColor,
          data: chartData?.[0].data,
          stack: chartData?.[0].stack || "default",
        },
        {
          type: "bar",
          label: "Group OTB",
          backgroundColor: chartData?.[1]?.backgroundColor,
          data: chartData?.[1].data,
          stack: chartData?.[1].stack || "default",
        },
        {
          type: "bar",
          label: "Transient",
          backgroundColor: chartData?.[2]?.backgroundColor,
          data: chartData?.[2].data,
          stack: chartData?.[2].stack || "default",
        },
        {
          type: "line",
          label: "Inventory",
          backgroundColor: chartData?.[3]?.backgroundColor,
          data: chartData?.[3].data,
          stack: chartData?.[3].stack || "default",
        },
      ],
    };

    chartOptions = {
      indexAxis: "y",
      scales: {
        x: {
          beginAtZero: true,
          stacked: true,
          title: {
            display: true,
            text: "",
          },
          categorySpacing: 100000,
          ticks: {
            callback: function (value) {
              return value + "%";
            },
          },
        },
        y: {
          title: {
            display: true,
            text: "",
          },
          grid: {
            display: false,
          },
          offset: true,
          ticks: {
            align: "start",
            autoSkip: false,
          },
        },
      },

      plugins: {
        legend: {
          display: true,
          align: "end",
          position: "top",
          maxWidth: 20,
          maxHeight: 100,
          labels: {
            boxWidth: 10,
            boxHeight: 10,
          },
        },
        tooltip: {
          enabled: true,
          mode: "index",
          callbacks: {
            title: function (context) {
              return context[0].label;
            },
            label: function (context) {
              let label = context.dataset.label;
              let displayValue;
              if (label === "Group Block") {
                displayValue = hoverValue?.groupBlockHover[context.dataIndex];
              } else if (label === "Group OTB") {
                displayValue = hoverValue?.groupOtbHover[context.dataIndex];
              } else if (label === "Transient") {
                displayValue = hoverValue?.transientHover[context.dataIndex];
              } else if (label === "Inventory") {
                displayValue = hoverValue?.inventoryHover[context.dataIndex];
              }

              return `${label}: ${displayValue}`;
            },
          },
        },
      },
      responsive: true,
      barThickness: 12,
      categorySpacing: 0.3,
      barPercentage: 0.8,
      categoryPercentage: 0.9,
    };
  }

  Chart.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Tooltip,
    Legend,
    PointElement,
    LineElement
  );
  return (
    <div className="widget-container">
      {api !== 0 && (
        <div className="gap-1 widget-container-menu">
          {/* <Button className="heading-menu-btns" type="button">
            <img
              src={NoteIcon}
              alt="menuIcon"
              onClick={() => {
                setShowNoteSidebar(true);
              }}
            />
          </Button> */}
          <Button className="heading-menu-btns" type="button">
            <img
              src={infoIcon}
              alt="info"
              onClick={() => {
                widgetDetailRef.current = {
                  widgetdetails: "",
                  usernote: "",
                  compName: compName?.groupChart,
                  widgetId: para?.widgetId,
                };
                setWidgetModal(true);
              }}
            />
          </Button>
          {/* <Button className="heading-menu-btns" type="button">
            <img src={downloadIcon} alt="menuIcon" />
          </Button> */}
        </div>
      )}
      {chartData && (
        <>
          <div className="ForecastHorizontalChartInner">
            <Bar data={chartDetail} options={chartOptions} />
          </div>
          {api !== 0 && <Comments widgetDetails={para} editorId={"GroupChart"}/>}
        </>
      )}
      {/* {showNoteSidebar && (
        <WidgetNote
          show={showNoteSidebar}
          handleClose={closeNoteSideBar}
          widgetId={para?.widgetId}
          propertyId={propertyDetailFromRTK?.propertyid}
          rowTitle={para?.rowTitle}
        />
      )} */}
      {showNoteSidebar && (
        <AddWidgetModal
          showModal={showNoteSidebar}
          closeModal={closeNoteSideBar}
          widgetId={para?.widgetId}
          propertyId={propertyDetailFromRTK?.propertyid}
          rowTitle={para?.rowTitle}
        />
      )}
      {widgetModal && (
        <WidgetDetailModal
          showModal={widgetModal}
          closeModal={closeWidgetModal}
          data={widgetDetailRef.current}
        />
      )}
    </div>
  );
};

export default GroupChart;
