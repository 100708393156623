import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import HorizontalBarChart from "../../../CommonComponent/HorizontalBarChart";
import { apiCall } from "../../../../utils/axiosService";
import { apiResponse } from "../../../../utils/apiResponse";
import { compName, month } from "../../../../utils/constant";
import { commonService } from "../../../../utils/commonService";
import { API_URL } from "../../../../utils/apiRoutes";
import infoIcon from "../../../../Assets/Images/Action Icons/infoIcon..svg";
import NoteIcon from "../../../../Assets/Images/CRM/notes.svg";
import downloadIcon from "../../../../Assets/Images/Operation/downloadIcon.svg";
import { useSelector } from "react-redux";
import WidgetNote from "../../WidgetNote";
import WidgetDetailModal from "../../WidgetDetailModal";
import AddWidgetModal from "../../AddWidgetModal";
import Comments from "../Comments/Comment";

const ForecastChart = ({
  setCompDataFromChild,
  rowIndex,
  colIndex,
  api,
  para,
  setting,
}) => {
  const yearsArr = [
    ...commonService.getPastYearForDropDown(10),
    ...commonService.getFutureYearForDropDown(10),
    new Date().getFullYear(),
  ]?.sort(function (a, b) {
    return a - b;
  });
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [showNoteSidebar, setShowNoteSidebar] = useState(false);
  const [widgetModal, setWidgetModal] = useState(false);
  const widgetDetailRef = useRef();
  const propertyDetailFromRTK = useSelector(
    (store) => store.headerProperty.propertyDetail
  );
  const closeWidgetModal = () => {
    setWidgetModal(false);
  };
  const closeNoteSideBar = () => {
    setShowNoteSidebar(false);
  };
  const labels = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const onTheBook = {
    label: "On The Book",
    backgroundColor: "#3EB978",
    // data: [150, 150, 300, 450, 600, 750, 900, 1050, 900, 900, 600, 300],
    data: [],
    stack: "stack1",
  };
  const emaForecast = {
    label: "System Forecast",
    backgroundColor: "#4F98CA",
    // data: [150, 200, 400, 600, 800, 1000, 1200, 800, 400, 200, 100, 500],
    data: [],
    stack: "stack2",
  };
  const rmsForecast = {
    label: "RMS Forecast",
    backgroundColor: "#FEEA5D",
    // data: [150, 200, 400, 600, 800, 1000, 1200, 800, 400, 200, 100, 500],
    data: [],
    stack: "stack3",
  };
  const userForecast = {
    label: "User Forecast",
    backgroundColor: "#AD56AD",
    // data: [150, 200, 400, 600, 800, 1000, 1200, 800, 400, 200, 100, 500],
    data: [],
    stack: "stack4",
  };
  const [chartData, setChartData] = useState(null);
  //   const datasets = [dataset1, dataset2, dataset3, dataset4];

  const getChartDetail = async () => {
    let chartDetailResponse = await apiCall(
      {
        method: "POST",
        url: API_URL.BASE_API_URL.SnapShot + API_URL.FORECAST.CHART,
        body: {
          AsOfDate: para?.asOfDate,
          year: `${selectedYear}`,
          propertyCode: para?.propertyCode,
        },
      },
      false
    );

    let response = apiResponse(false, chartDetailResponse);

    if (response?.isValidate) {
      onTheBook.data = [];
      emaForecast.data = [];
      rmsForecast.data = [];
      userForecast.data = [];
      let dataFromApi = response?.data?.data;
      for (let i = 0; i < month?.length; i++) {
        var onTheBookItem = dataFromApi.find((item) => {
          if (item.result_type === "OTB" && item.month == month[i]) {
            return item;
          }
          return null;
        });
        onTheBook.data.push(onTheBookItem ? onTheBookItem.rev : 0);

        var userForecastItem = dataFromApi.find((item) => {
          if (item.result_type === "fcUser" && item.month == month[i]) {
            return item;
          }
          return null;
        });
        userForecast.data.push(userForecastItem ? userForecastItem.rev : 0);

        var emaForecastItem = dataFromApi.find((item) => {
          if (item.result_type === "fcSpider" && item.month == month[i]) {
            return item;
          }
          return null;
        });
        emaForecast.data.push(emaForecastItem ? emaForecastItem.rev : 0);

        var rmsForecastItem = dataFromApi.find((item) => {
          if (item.result_type === "fcRms" && item.month == month[i]) {
            return item;
          }
          return null;
        });
        rmsForecast.data.push(rmsForecastItem ? rmsForecastItem.rev : 0);
      }

      setChartData([onTheBook, emaForecast, rmsForecast, userForecast]);
    }
    if (!response?.isValidate) {
      onTheBook.data = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      emaForecast.data = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      rmsForecast.data = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      userForecast.data = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      setChartData([onTheBook, emaForecast, rmsForecast, userForecast]);
    }
  };

  useEffect(() => {
    if (api === 0) {
      onTheBook.data = [
        150, 200, 400, 600, 800, 1000, 1200, 800, 400, 200, 100, 500,
      ];
      emaForecast.data = [
        150, 200, 400, 600, 800, 1000, 1200, 800, 400, 200, 100, 500,
      ];
      rmsForecast.data = [
        150, 200, 400, 600, 800, 1000, 1200, 800, 400, 200, 100, 500,
      ];
      userForecast.data = [
        150, 200, 400, 600, 800, 1000, 1200, 800, 400, 200, 100, 500,
      ];
      setChartData([onTheBook, emaForecast, rmsForecast, userForecast]);
    } else {
      if (para?.asOfDate) {
        getChartDetail();
      }
    }
  }, [para?.asOfDate, selectedYear]);

  return (
    <div className="widget-container">
      {api !== 0 && (
        <div className="gap-1 widget-container-menu">
          {/* <Button className="heading-menu-btns" type="button">
            <img
              src={NoteIcon}
              alt="menuIcon"
              onClick={() => {
                setShowNoteSidebar(true);
              }}
            />
          </Button> */}
          <Button className="heading-menu-btns" type="button">
            <img
              src={infoIcon}
              alt="info"
              onClick={() => {
                widgetDetailRef.current = {
                  widgetdetails: "",
                  usernote: "",
                  compName: compName?.forecastChart,
                  widgetId: para?.widgetId,
                };
                setWidgetModal(true);
              }}
            />
          </Button>
          {/* <Button className="heading-menu-btns" type="button">
            <img src={downloadIcon} alt="menuIcon" />
          </Button> */}
        </div>
      )}
      <Col xl={12}>
        <Col xl={1}>
          <div className="from-groups no-border-break">
            <Form.Select
              aria-label="Default select example"
              value={selectedYear}
              onChange={(e) => {
                setSelectedYear(e.target.value);
              }}
            >
              {yearsArr?.map((item, index) => (
                <option key={item} value={item}>
                  {item}
                </option>
              ))}
            </Form.Select>
          </div>
        </Col>
      </Col>
      {chartData && (
        <>
          <div className="snapShotHorizontalChart">
            <div className="ForecastHorizontalChartInner">
              <HorizontalBarChart
                labels={labels}
                datasets={chartData}
                maxPercentage={""}
                barThickness={12}
              />
            </div>
          </div>
          {api !== 0 && <Comments widgetDetails={para} editorId={"ForecastChart"}/>}
        </>
      )}
      {/* {showNoteSidebar && (
        <WidgetNote
          show={showNoteSidebar}
          handleClose={closeNoteSideBar}
          widgetId={para?.widgetId}
          propertyId={propertyDetailFromRTK?.propertyid}
          rowTitle={para?.rowTitle}
        />
      )} */}
      {showNoteSidebar && (
        <AddWidgetModal
          showModal={showNoteSidebar}
          closeModal={closeNoteSideBar}
          widgetId={para?.widgetId}
          propertyId={propertyDetailFromRTK?.propertyid}
          rowTitle={para?.rowTitle}
        />
      )}
      {widgetModal && (
        <WidgetDetailModal
          showModal={widgetModal}
          closeModal={closeWidgetModal}
          data={widgetDetailRef.current}
        />
      )}
    </div>
  );
};

export default ForecastChart;
