import React, { useEffect, useRef, useState } from "react";
import { apiCall } from "../../../utils/axiosService";
import { API_URL } from "../../../utils/apiRoutes";
import { useSelector } from "react-redux";
import { apiResponse } from "../../../utils/apiResponse";
import { Col, Form, Table } from "react-bootstrap";
import Loader from "../../Loader/Loader";
import Skeleton from "react-loading-skeleton";

const WeekDay = () => {
  const propertyDetailFromRTK = useSelector(
    (store) => store.headerProperty.propertyDetail
  );
  const crmPropertyDetailFromRTK = useSelector(
    (store) => store.headerProperty.crmPropertyDetail
  );
  const weekDayArr = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const [weekDayData, setWeekDayData] = useState(null);
  const weekDayDataRef = useRef([]);
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);

  const getWeekDayData = async () => {
    weekDayDataRef.current = [];
    setLoading(true);
    let weekDayResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.RevenueManagement +
          API_URL.RATEIQ_WEEKDAY.GET_LIST,
        body: {
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
        },
      },
      false
    );

    let response = apiResponse(false, weekDayResponse);

    if (response?.isValidate) {
      response?.data?.data?.map((item) => {
        weekDayDataRef.current = [
          ...weekDayDataRef.current,
          {
            rateiqweekdaysid: 0,
            weekdayname: item?.weekdayname,
            ratevalue: item?.ratevalue,
            propertyid: item?.propertyid,
          },
        ];
      });
      setWeekDayData(response?.data?.data);
      setLoading(false);
    }

    if (!response?.isValidate) {
      setWeekDayData(null);
      setLoading(false);
    }
  };

  const handleSubmit = async () => {
    setSubmitLoading(true);
    if (weekDayDataRef.current?.length <= 0) {
      setSubmitLoading(false);
      return;
    }

    let saveResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.RevenueManagement + API_URL.RATEIQ_WEEKDAY.SAVE,
        body: {
          F: weekDayDataRef.current,
        },
      },
      true
    );

    let response = apiResponse(true, saveResponse, setSubmitLoading);

    if (response?.isValidate) {
    }
    if (!response?.isValidate) {
    }
  };

  useEffect(() => {
    getWeekDayData();
  }, []);

  return (
    <div>
      <Col xl={12}>
        <div className="table__container">
          <div className="stripped__table">
            <div className="table-responsive maxHeight400">
              <Table>
                <thead className="tbl-header-sticky my-tbl-header-sticky">
                  <tr>
                    <th>Week Day</th>
                    <th className="text-center width100">%In Rate</th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr>
                      <td>
                        <Skeleton count={5} />
                      </td>
                      <td>
                        <Skeleton count={5} />
                      </td>
                    </tr>
                  ) : (
                    weekDayArr?.map((item, index) => {
                      let findWeekDetail = weekDayData?.filter(
                        (x) => x?.weekdayname === item
                      );
                      return (
                        <tr className="shadowOnHover">
                          <td>{item}</td>
                          <td className="p-1">
                            <Form.Control
                              type="text"
                              className="py-0 px-2"
                              maxLength={6}
                              defaultValue={findWeekDetail?.[0]?.ratevalue}
                              onChange={(e) => {
                                let findIndexOfWeekDay =
                                  weekDayDataRef.current?.findIndex(
                                    (x) => x?.weekdayname === item
                                  );

                                if (findIndexOfWeekDay >= 0) {
                                  weekDayDataRef.current[
                                    findIndexOfWeekDay
                                  ].ratevalue = e.target.value;
                                } else {
                                  weekDayDataRef.current = [
                                    ...weekDayDataRef.current,
                                    {
                                      rateiqweekdaysid: 0,
                                      weekdayname: item,
                                      ratevalue: e.target.value,
                                      propertyid: window.location.href.includes(
                                        "/crm"
                                      )
                                        ? crmPropertyDetailFromRTK?.propertyid
                                        : propertyDetailFromRTK?.propertyid,
                                    },
                                  ];
                                }
                              }}
                            />
                          </td>
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </Col>
      <div className="rate-iq-setting-modal footer-buttons pt-2">
        {submitLoading ? (
          <button className="default-btn primary-btn" type="button" disabled>
            <span
              className="spinner-border spinner-border-sm me-3"
              role="status"
              aria-hidden="true"
            ></span>
            Loading...
          </button>
        ) : (
          <button
            className="default-btn primary-btn"
            onClick={() => {
              handleSubmit();
            }}
          >
            Submit
          </button>
        )}
      </div>
    </div>
  );
};

export default WeekDay;
