import React, { useEffect, useRef, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import dashboardIcon from "../../Assets/Images/Revenue-management/dashboardIcon.svg";
import prospect from "../../Assets/Images/CRM/leads.svg";
import accounts from "../../Assets/Images/CRM/accounts.svg";
import contact from "../../Assets/Images/CRM/contact.svg";
import activity from "../../Assets/Images/CRM/activity.svg";
import opportunity from "../../Assets/Images/CRM/opportunity.svg";
import tools from "../../Assets/Images/CRM/tools.svg";
import reports from "../../Assets/Images/CRM/reports.svg";
import clock from "../../Assets/Images/onlyClock.svg";
import setup from "../../Assets/Images/CRM/setup.svg";
import BI from "../../Assets/Images/Revenue-management/BI.svg";
import { Accordion } from "react-bootstrap";
import CrmInsight from "./CrmInsight";
import toolsImage from "../../Assets/Images/Revenue-management/tools.svg";
import { useSelector } from "react-redux";
import { apiResponse } from "../../utils/apiResponse";
import { apiCall } from "../../utils/axiosService";
import { API_URL } from "../../utils/apiRoutes";
import infoIcon from "../../Assets/Images/Action Icons/infoIcon..svg";
import ViewSnapShotDetailModal from "../RevenueManagement/ViewSnapShotDetailModal";

function AsideCRM() {
    const [showModal, setShowModal] = useState(false);
    const openModal = () => setShowModal(true);
    const closeModal = () => setShowModal(false);
    const [menuList, setMenuList] = useState(null);
    const snapShotIdRef = useRef();
    const [showSnapShotDetailModal, setShowSnapShotDetailModal] = useState(false);

    const closeSnapShotDetailModal = () => {
        setShowSnapShotDetailModal(false);
    };

    const crmPropertyDetailFromRTK = useSelector(
        (store) => store.headerProperty.crmPropertyDetail
    );

    const callSnapShotApi = useSelector(
        (store) => store.callApiAgain.snapShotApi
    );

    let propertyIdPayload = "";
    if (crmPropertyDetailFromRTK) {
        if (crmPropertyDetailFromRTK?.propertyid === "") {
            let propertyPayload = crmPropertyDetailFromRTK?.allPropertyId;
            propertyIdPayload = propertyPayload.slice(0, -1);
        } else {
            propertyIdPayload = crmPropertyDetailFromRTK?.propertyid?.toString();
        }
    }

    const getAsideMenuList = async () => {
        let asideMenuListResponse = await apiCall(
            {
                method: "POST",
                url:
                    API_URL.BASE_API_URL.Configuration +
                    API_URL.SNAPSHOT.GET_LIST_FOR_MENU,
                body: {
                    modulenames: "Crm",
                    propertyid: crmPropertyDetailFromRTK?.propertyid,
                },
            },
            false
        );

        let response = apiResponse(false, asideMenuListResponse);

        if (response?.isValidate) {
            let dataFromApi = response?.data?.data;
            let groupingJson = {};
            for (let i = 0; i < dataFromApi?.length; i++) {
                if (groupingJson?.[dataFromApi?.[i]?.category]) {
                    groupingJson[dataFromApi[i].category] = [
                        ...groupingJson?.[dataFromApi?.[i]?.category],
                        dataFromApi?.[i],
                    ];
                } else {
                    groupingJson = {
                        ...groupingJson,
                        [dataFromApi?.[i]?.category]: [dataFromApi?.[i]],
                    };
                }
            }
            setMenuList(groupingJson);
            // setMenuList(response?.data?.data);
        }

        if (!response?.isValidate) {
            setMenuList(null);
        }
    };

    useEffect(() => {
        if (crmPropertyDetailFromRTK?.propertyid !== "") {
            getAsideMenuList();
        }
    }, [crmPropertyDetailFromRTK, callSnapShotApi]);

    return (
        <>
            <aside className="revenue-management-aside-main asideConfigurationMain container-menu-bar">
                <ul className="list-unstyled p-0 m-0">
                    <li>
                        <NavLink
                            to="/crm/dashboard"
                            className="revenue-aside-links"
                        >
                            <img src={dashboardIcon} alt="Dashboard" />
                            <span className="main-menu-text">Dashboard</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            to="/crm/prospect"
                            className="revenue-aside-links"
                        >
                            <img src={prospect} alt="configuration" />
                            <span className="main-menu-text">Prospect</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            to="/crm/accounts"
                            className="revenue-aside-links"
                        >
                            <img src={accounts} alt="configuration" />
                            <span className="main-menu-text">Accounts</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            to="/crm/contacts"
                            className="revenue-aside-links"
                        >
                            <img src={contact} alt="configuration" />
                            <span className="main-menu-text">Contacts</span>
                        </NavLink>
                    </li>
                    <li>
                        <Accordion>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header className="configurationAccordionHeader  having-children">
                                    <img src={opportunity} alt="configuration" />
                                    <span className="main-menu-text">Opportunity</span>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <ul>
                                        <li>
                                            <NavLink to="/crm/RFP">RFP</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/crm/LNR">LNR</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/crm/Group">Group</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/crm/meeting-and-catering">Meeting & Catering</NavLink>
                                        </li>
                                    </ul>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </li>
                    <li>
                        <NavLink
                            to="/crm/activities"
                            className="revenue-aside-links"
                        >
                            <img src={activity} alt="configuration" />
                            <span className="main-menu-text">Activities</span>
                        </NavLink>
                    </li>
                    <li>
                        <Accordion>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header className="configurationAccordionHeader  having-children">
                                    <img src={tools} alt="configuration" />
                                    <span className="main-menu-text">Tools</span>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <ul>
                                        <li>
                                            <NavLink to="/crm/Crm-Rateshop">Rate Shop</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/crm/crm-reminder">Reminder</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/crm/crm-events">Events</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/crm/crm-rateiq">RateIQ</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/crm/crm-displacement-calculator">
                                                Displacement Calculator
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/crm/zoom-info">
                                                Zoom Info
                                            </NavLink>
                                        </li>
                                    </ul>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </li>
                    <li>
                        <Accordion>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header className="configurationAccordionHeader  having-children">
                                    <img src={BI} alt="tools" />
                                    <span className="main-menu-text">Business Intelligence</span>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <ul>
                                        <li>
                                            <NavLink
                                                to={`/crm/business-intelligence`}
                                                className="revenue-aside-links"
                                            >
                                                <img src={tools} alt="configuration" />
                                                <span>ALL</span>
                                            </NavLink>
                                        </li>
                                        {menuList &&
                                            Object?.entries(menuList)?.map(([key, value], index) => {
                                                if (key === "Business Intelligence") {
                                                    return value?.map((item, index) => (
                                                        <li key={item?.snapshottitle}>
                                                            <div className="d-flex justify-content-between align-items-center">
                                                                <NavLink
                                                                    to={`/crm/snapshot/${item?.snapshottitle}/${item?.propertycode}/${item?.snapshotid}`}
                                                                    className="revenue-aside-links d-block"
                                                                >
                                                                    <div className="d-flex justify-content-between align-items-center">
                                                                        <div className="d-flex gap-3 align-items-center">
                                                                            <div
                                                                                dangerouslySetInnerHTML={{
                                                                                    __html: item?.icon,
                                                                                }}
                                                                            ></div>
                                                                            <span>{item?.snapshottitle}</span>
                                                                        </div>
                                                                    </div>
                                                                </NavLink>
                                                                <img
                                                                    src={infoIcon}
                                                                    style={{ cursor: "pointer" }}
                                                                    onClick={() => {
                                                                        snapShotIdRef.current = item?.snapshotid;
                                                                        setShowSnapShotDetailModal(true);
                                                                    }}
                                                                />
                                                            </div>
                                                        </li>
                                                    ));
                                                }
                                            })}
                                    </ul>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </li>
                    <li>
                        <NavLink
                            to="javascript:;" onClick={openModal}
                            className="revenue-aside-links"
                        >
                            <img src={clock} alt="configuration" />
                            <span className="main-menu-text">Insights</span>
                        </NavLink>
                    </li>

                    <li>
                        <Accordion>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header className="configurationAccordionHeader  having-children">
                                    <img src={reports} alt="reports" />
                                    <span className="main-menu-text">Reports</span>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <ul>
                                        {/* <li>
                  <NavLink to="/crm/reports/dashboard-weekly-report">
                    Dashboard Weekly Report
                  </NavLink>
                </li> */}
                                        <li>
                                            <NavLink to="/crm/reports/activies-Report">
                                                Activities Report
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/crm/reports/trace-report">Trace Report</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/crm/reports/monthly-rollup-report">
                                                Monthly Rollup Report
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/crm/reports/rfp-grid-report">
                                                RFP Grid Report
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/crm/reports/lnr-grid-report">
                                                LNR Grid Report
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/crm/reports/group-grid-report">
                                                Group Grid Report
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/crm/reports/meeting-catering-grid-report">
                                                Meeting Catering Report
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/crm/reports/sales-funnel-grid-report">
                                                Sales Funnel Grid Report
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/crm/reports/lost-bussiness-grid-report">
                                                Lost Bussiness Grid Report
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/crm/reports/opportunity-by-source-report">
                                                Opportunity By Source Report
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/crm/reports/meeting-catering-by-date-report">
                                                Meeting Catering by Date Report
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/crm/reports/activity-goal-report">
                                                Activity Goal Report
                                            </NavLink>
                                        </li>

                                        <li>
                                            <NavLink to="/crm/reports/marketing-plan-report">
                                                Marketing Plan Report
                                            </NavLink>
                                        </li>
                                        {/* <li>
                  <NavLink to="/crm/reports/group-room-count">
                    Group Room Count(GRC)
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/crm/reports/meeting-room-book-report"> 
                    Meeting Room Book Report
                  </NavLink>
                </li>  */}
                                        <li>
                                            <NavLink to="/crm/reports/group-pace-report">
                                                Group Pace Report
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/crm/reports/meeting-pace-report">
                                                Meeting Pace Report
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/crm/reports/lead-report">Lead Report</NavLink>
                                        </li>
                                        {/* <li>
                  <NavLink to="/crm/reports/weekly-report">
                    Weekly Report
                  </NavLink>
                </li> */}
                                    </ul>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </li>

                    <li>
                        <Accordion>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header className="configurationAccordionHeader  having-children">
                                    <img src={setup} alt="configuration" />
                                    <span className="main-menu-text">Setup</span>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <ul>
                                        <li>
                                            <NavLink to="/crm/setup-settings">Hotels</NavLink>
                                        </li>

                                        {/* <li>
                  <NavLink to="/crm/crm-portfolios">Portfolios</NavLink>
                </li> */}

                                        <li>
                                            <NavLink to="/crm/account-rules">Account Rules</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/crm/general-term-setup">
                                                General Term Setup
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/crm/role-management">Role</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/crm/user-management">User</NavLink>
                                        </li>
                                    </ul>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </li>
                </ul>
            </aside>
            {showModal && <CrmInsight show={showModal} handleClose={closeModal} />}

            {showSnapShotDetailModal && (
                <ViewSnapShotDetailModal
                    showModal={showSnapShotDetailModal}
                    closeModal={closeSnapShotDetailModal}
                    snapshotId={snapShotIdRef.current}
                />
            )}
        </>
    );
}

export default AsideCRM;
