import React from "react";

const FilterModal = ({ keyName, list, selectedData, updateFilter }) => {
  let finalSelectedData = { ...selectedData };
  return (
    <div style={{ padding: "0 10px", width: "100%" }}>
      <div className="reservationFilterList">
        {list &&
          Object.keys(list)?.map((item) => {
            return (
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id={`check-${item}`}
                  defaultChecked={finalSelectedData?.[item]}
                  onChange={(e) => {
                    if (e.target.checked) {
                      finalSelectedData[item] = item;
                    } else {
                      delete finalSelectedData?.[item];
                    }
                  }}
                />
                <label class="form-check-label" for={`check-${item}`}>
                  {item}
                </label>
              </div>
            );
          })}
      </div>
      <div className="d-flex justify-content-end">
        <button
          className="default-btn primary-btn"
          style={{ padding: "6px 12px", margin: "5px 0px" }}
          onClick={() => {
            updateFilter(keyName, finalSelectedData);
          }}
        >
          Apply
        </button>
      </div>
    </div>
  );
};

export default FilterModal;
