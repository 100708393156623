import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Table } from "react-bootstrap";
import { commonService } from "../../../../utils/commonService";
import DatePicker from "react-datepicker";
import NoteIcon from "../../../../Assets/Images/CRM/notes.svg";
import downloadIcon from "../../../../Assets/Images/Operation/downloadIcon.svg";
import { apiCall } from "../../../../utils/axiosService";
import { apiResponse } from "../../../../utils/apiResponse";
import { API_URL } from "../../../../utils/apiRoutes";
import Loader from "../../../Loader/Loader";
import infoIcon from "../../../../Assets/Images/Action Icons/infoIcon..svg";
import { useSelector } from "react-redux";
import { compName } from "../../../../utils/constant";
import WidgetNote from "../../WidgetNote";
import WidgetDetailModal from "../../WidgetDetailModal";
import AddWidgetModal from "../../AddWidgetModal";
import { useParams } from "react-router-dom";
import emailIcon from "../../../../Assets/Images/Email.svg";
import moment from "moment";

const BestWesternReservationAgent = ({
  setCompDataFromChild,
  rowIndex,
  colIndex,
  api,
  para,
  setting,
}) => {
  const tableData = [
    {
      id: 1,
      asOfDate: "-",
      propertyCode: "-",
      pullDateId: "0",
      HotelName: "0",
      PrintedValue: "-",
      PrintedCaption: "0",
      BusinessDateVal: "-",
      BusinessDateCaption: "0",
      textBox66: "0",
      textBox68: "0",
      textBox60: "0",
      textBox55: "0",
      textBox8: "0",
      textBox92: "0",
      textBox90: "0",
      textBox88: "0",
      textBox86: "0",
      textBox83: "0",
      textBox89: "0",
      textBox91: "0",
      textBox87: "0",
      textBox93: "0",
      textBox94: "0",
      textBox85: "0",
      textBox84: "0",
      textBox96: "0",
      textBox17: "0",
      textBox37: "0",
      textBox44: "0",
      textBox43: "0",
      textBox3: "0",
      textBox5: "0",
      textBox6: "-",
      textBox7: "-",
      textBox9: "0",
      textBox10: "0",
      textBox11: "-",
      textBox12: "0",
      textBox13: "0",
      textBox14: "0",
      textBox16: "0",
      textBox15: "0",
      textBox4: "0",
    },
    {
      id: 2,
      asOfDate: "-",
      propertyCode: "-",
      pullDateId: "0",
      HotelName: "0",
      PrintedValue: "-",
      PrintedCaption: "0",
      BusinessDateVal: "-",
      BusinessDateCaption: "0",
      textBox66: "0",
      textBox68: "0",
      textBox60: "0",
      textBox55: "0",
      textBox8: "0",
      textBox92: "0",
      textBox90: "0",
      textBox88: "0",
      textBox86: "0",
      textBox83: "0",
      textBox89: "0",
      textBox91: "0",
      textBox87: "0",
      textBox93: "0",
      textBox94: "0",
      textBox85: "0",
      textBox84: "0",
      textBox96: "0",
      textBox17: "0",
      textBox37: "0",
      textBox44: "0",
      textBox43: "0",
      textBox3: "0",
      textBox5: "0",
      textBox6: "-",
      textBox7: "-",
      textBox9: "0",
      textBox10: "0",
      textBox11: "-",
      textBox12: "0",
      textBox13: "0",
      textBox14: "0",
      textBox16: "0",
      textBox15: "0",
      textBox4: "0",
    },
    {
      id: 3,
      asOfDate: "-",
      propertyCode: "-",
      pullDateId: "0",
      HotelName: "0",
      PrintedValue: "-",
      PrintedCaption: "0",
      BusinessDateVal: "-",
      BusinessDateCaption: "0",
      textBox66: "0",
      textBox68: "0",
      textBox60: "0",
      textBox55: "0",
      textBox8: "0",
      textBox92: "0",
      textBox90: "0",
      textBox88: "0",
      textBox86: "0",
      textBox83: "0",
      textBox89: "0",
      textBox91: "0",
      textBox87: "0",
      textBox93: "0",
      textBox94: "0",
      textBox85: "0",
      textBox84: "0",
      textBox96: "0",
      textBox17: "0",
      textBox37: "0",
      textBox44: "0",
      textBox43: "0",
      textBox3: "0",
      textBox5: "0",
      textBox6: "-",
      textBox7: "-",
      textBox9: "0",
      textBox10: "0",
      textBox11: "-",
      textBox12: "0",
      textBox13: "0",
      textBox14: "0",
      textBox16: "0",
      textBox15: "0",
      textBox4: "0",
    },
    {
      id: 4,
      asOfDate: "-",
      propertyCode: "-",
      pullDateId: "0",
      HotelName: "0",
      PrintedValue: "-",
      PrintedCaption: "0",
      BusinessDateVal: "-",
      BusinessDateCaption: "0",
      textBox66: "0",
      textBox68: "0",
      textBox60: "0",
      textBox55: "0",
      textBox8: "0",
      textBox92: "0",
      textBox90: "0",
      textBox88: "0",
      textBox86: "0",
      textBox83: "0",
      textBox89: "0",
      textBox91: "0",
      textBox87: "0",
      textBox93: "0",
      textBox94: "0",
      textBox85: "0",
      textBox84: "0",
      textBox96: "0",
      textBox17: "0",
      textBox37: "0",
      textBox44: "0",
      textBox43: "0",
      textBox3: "0",
      textBox5: "0",
      textBox6: "-",
      textBox7: "-",
      textBox9: "0",
      textBox10: "0",
      textBox11: "-",
      textBox12: "0",
      textBox13: "0",
      textBox14: "0",
      textBox16: "0",
      textBox15: "0",
      textBox4: "0",
    },
    {
      id: 5,
      asOfDate: "-",
      propertyCode: "-",
      pullDateId: "0",
      HotelName: "0",
      PrintedValue: "-",
      PrintedCaption: "0",
      BusinessDateVal: "-",
      BusinessDateCaption: "0",
      textBox66: "0",
      textBox68: "0",
      textBox60: "0",
      textBox55: "0",
      textBox8: "0",
      textBox92: "0",
      textBox90: "0",
      textBox88: "0",
      textBox86: "0",
      textBox83: "0",
      textBox89: "0",
      textBox91: "0",
      textBox87: "0",
      textBox93: "0",
      textBox94: "0",
      textBox85: "0",
      textBox84: "0",
      textBox96: "0",
      textBox17: "0",
      textBox37: "0",
      textBox44: "0",
      textBox43: "0",
      textBox3: "0",
      textBox5: "0",
      textBox6: "-",
      textBox7: "-",
      textBox9: "0",
      textBox10: "0",
      textBox11: "-",
      textBox12: "0",
      textBox13: "0",
      textBox14: "0",
      textBox16: "0",
      textBox15: "0",
      textBox4: "0",
    },
    {
      id: 6,
      asOfDate: "-",
      propertyCode: "-",
      pullDateId: "0",
      HotelName: "0",
      PrintedValue: "-",
      PrintedCaption: "0",
      BusinessDateVal: "-",
      BusinessDateCaption: "0",
      textBox66: "0",
      textBox68: "0",
      textBox60: "0",
      textBox55: "0",
      textBox8: "0",
      textBox92: "0",
      textBox90: "0",
      textBox88: "0",
      textBox86: "0",
      textBox83: "0",
      textBox89: "0",
      textBox91: "0",
      textBox87: "0",
      textBox93: "0",
      textBox94: "0",
      textBox85: "0",
      textBox84: "0",
      textBox96: "0",
      textBox17: "0",
      textBox37: "0",
      textBox44: "0",
      textBox43: "0",
      textBox3: "0",
      textBox5: "0",
      textBox6: "-",
      textBox7: "-",
      textBox9: "0",
      textBox10: "0",
      textBox11: "-",
      textBox12: "0",
      textBox13: "0",
      textBox14: "0",
      textBox16: "0",
      textBox15: "0",
      textBox4: "0",
    },
    {
      id: 7,
      asOfDate: "-",
      propertyCode: "-",
      pullDateId: "0",
      HotelName: "0",
      PrintedValue: "-",
      PrintedCaption: "0",
      BusinessDateVal: "-",
      BusinessDateCaption: "0",
      textBox66: "0",
      textBox68: "0",
      textBox60: "0",
      textBox55: "0",
      textBox8: "0",
      textBox92: "0",
      textBox90: "0",
      textBox88: "0",
      textBox86: "0",
      textBox83: "0",
      textBox89: "0",
      textBox91: "0",
      textBox87: "0",
      textBox93: "0",
      textBox94: "0",
      textBox85: "0",
      textBox84: "0",
      textBox96: "0",
      textBox17: "0",
      textBox37: "0",
      textBox44: "0",
      textBox43: "0",
      textBox3: "0",
      textBox5: "0",
      textBox6: "-",
      textBox7: "-",
      textBox9: "0",
      textBox10: "0",
      textBox11: "-",
      textBox12: "0",
      textBox13: "0",
      textBox14: "0",
      textBox16: "0",
      textBox15: "0",
      textBox4: "0",
    },
    {
      id: 8,
      asOfDate: "-",
      propertyCode: "-",
      pullDateId: "0",
      HotelName: "0",
      PrintedValue: "-",
      PrintedCaption: "0",
      BusinessDateVal: "-",
      BusinessDateCaption: "0",
      textBox66: "0",
      textBox68: "0",
      textBox60: "0",
      textBox55: "0",
      textBox8: "0",
      textBox92: "0",
      textBox90: "0",
      textBox88: "0",
      textBox86: "0",
      textBox83: "0",
      textBox89: "0",
      textBox91: "0",
      textBox87: "0",
      textBox93: "0",
      textBox94: "0",
      textBox85: "0",
      textBox84: "0",
      textBox96: "0",
      textBox17: "0",
      textBox37: "0",
      textBox44: "0",
      textBox43: "0",
      textBox3: "0",
      textBox5: "0",
      textBox6: "-",
      textBox7: "-",
      textBox9: "0",
      textBox10: "0",
      textBox11: "-",
      textBox12: "0",
      textBox13: "0",
      textBox14: "0",
      textBox16: "0",
      textBox15: "0",
      textBox4: "0",
    },
    {
      id: 9,
      asOfDate: "-",
      propertyCode: "-",
      pullDateId: "0",
      HotelName: "0",
      PrintedValue: "-",
      PrintedCaption: "0",
      BusinessDateVal: "-",
      BusinessDateCaption: "0",
      textBox66: "0",
      textBox68: "0",
      textBox60: "0",
      textBox55: "0",
      textBox8: "0",
      textBox92: "0",
      textBox90: "0",
      textBox88: "0",
      textBox86: "0",
      textBox83: "0",
      textBox89: "0",
      textBox91: "0",
      textBox87: "0",
      textBox93: "0",
      textBox94: "0",
      textBox85: "0",
      textBox84: "0",
      textBox96: "0",
      textBox17: "0",
      textBox37: "0",
      textBox44: "0",
      textBox43: "0",
      textBox3: "0",
      textBox5: "0",
      textBox6: "-",
      textBox7: "-",
      textBox9: "0",
      textBox10: "0",
      textBox11: "-",
      textBox12: "0",
      textBox13: "0",
      textBox14: "0",
      textBox16: "0",
      textBox15: "0",
      textBox4: "0",
    },
    {
      id: 10,
      asOfDate: "-",
      propertyCode: "-",
      pullDateId: "0",
      HotelName: "0",
      PrintedValue: "-",
      PrintedCaption: "0",
      BusinessDateVal: "-",
      BusinessDateCaption: "0",
      textBox66: "0",
      textBox68: "0",
      textBox60: "0",
      textBox55: "0",
      textBox8: "0",
      textBox92: "0",
      textBox90: "0",
      textBox88: "0",
      textBox86: "0",
      textBox83: "0",
      textBox89: "0",
      textBox91: "0",
      textBox87: "0",
      textBox93: "0",
      textBox94: "0",
      textBox85: "0",
      textBox84: "0",
      textBox96: "0",
      textBox17: "0",
      textBox37: "0",
      textBox44: "0",
      textBox43: "0",
      textBox3: "0",
      textBox5: "0",
      textBox6: "-",
      textBox7: "-",
      textBox9: "0",
      textBox10: "0",
      textBox11: "-",
      textBox12: "0",
      textBox13: "0",
      textBox14: "0",
      textBox16: "0",
      textBox15: "0",
      textBox4: "0",
    },
  ];

  const [reservationAgent, setReservationAgent] = useState(null);
  const [loading, setLoading] = useState(false);
  const [startRangeDate, setStartRangeDate] = useState();
  const [endRangeDate, setEndRangeDate] = useState();
  const startRangeDateRef = useRef();
  const endRangeDateRef = useRef();
  const [showNoteSidebar, setShowNoteSidebar] = useState(false);
  const [widgetModal, setWidgetModal] = useState(false);
  const widgetDetailRef = useRef();
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const propertyDetailFromRTK = useSelector(
    (store) => store.headerProperty.propertyDetail
  );
  const defaultAsOfDateFromRTK = useSelector(
    (store) => store.headerProperty.defaultAsOfDate
  );
  const { snapshottitle, propertyCode, snapshotId } = useParams();
  const closeWidgetModal = () => {
    setWidgetModal(false);
  };
  const closeNoteSideBar = () => {
    setShowNoteSidebar(false);
  };
  const handleDateChange = (event) => {
    const [start, end] = event;
    let startSelectedDate = "",
      endSelectedDate = "";
    if (start) {
      let date = new Date(start);
      let month =
        date.getMonth() + 1 > 9
          ? date.getMonth() + 1
          : `0${date.getMonth() + 1}`;
      let dateFormat =
        date?.getDate() > 9 ? date.getDate() : `0${date.getDate()}`;
      startSelectedDate = `${date.getFullYear()}-${month}-${dateFormat}`;
      startRangeDateRef.current = startSelectedDate;
    }
    if (end) {
      let date = new Date(end);
      let month =
        date.getMonth() + 1 > 9
          ? date.getMonth() + 1
          : `0${date.getMonth() + 1}`;
      let dateFormat =
        date?.getDate() > 9 ? date.getDate() : `0${date.getDate()}`;
      endSelectedDate = `${date.getFullYear()}-${month}-${dateFormat}`;
      endRangeDateRef.current = endSelectedDate;
    }

    setStartRangeDate(event[0]);
    setEndRangeDate(event[1]);
    if (event[0] !== null && event[1] !== null) {
      // getReservationAgentDetail();
    }
  };

  const getReservationAgentDetail = async () => {
    setLoading(true);
    let payload = {
      AsOfDate: para?.asOfDate,
      propertyCode: para?.propertyCode,
    };

    if (startRangeDateRef.current && endRangeDateRef.current) {
      payload = {
        ...payload,
        startDate: startRangeDateRef.current,
        endDate: endRangeDateRef.current,
      };
    }

    let reservationAgentResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.SnapShot +
          API_URL.WIDGET_MODULE.BEST_WESTERN_RESERVATION_AGENT,
        body: payload,
      },
      false
    );

    let response = apiResponse(false, reservationAgentResponse);

    if (response?.isValidate) {
      setLoading(false);
      setReservationAgent(response?.data?.data);
    }

    if (!response?.isValidate) {
      setLoading(false);
      setReservationAgent(null);
    }
  };

  useEffect(() => {
    if (api === 0) {
      setReservationAgent(tableData);
    } else {
      if (para?.asOfDate) {
        getReservationAgentDetail();
      }
    }
  }, [para?.asOfDate]);

  const [tblids, settblids] = useState({
    tblBestWesternReservationAgent: commonService.GenerateGUID(),
  });

  return (
    <div className="widget-container">
      {api !== 0 && (
        <div className="gap-1 widget-container-menu">
          <Button className="heading-menu-btns" type="button">
            <img
              src={NoteIcon}
              alt="menuIcon"
              onClick={() => {
                setShowNoteSidebar(true);
              }}
            />
          </Button>
          <Button className="heading-menu-btns" type="button" title="Send Mail">
            <img
              src={emailIcon}
              alt="emailIcon"
              onClick={() => {
                commonService.fnSendWidgetMail(
                  tblids.tblBestWesternReservationAgent,
                  "Best Western Reservation Agent",
                  snapshotId,
                  defaultAsOfDateFromRTK,
                  "",
                  para?.widgetId
                );
              }}
            />
          </Button>
          <Button className="heading-menu-btns" type="button">
            <img
              src={infoIcon}
              alt="info"
              onClick={() => {
                widgetDetailRef.current = {
                  widgetdetails: "",
                  usernote: "",
                  compName: compName?.widgetBestWesternReservationAgent,
                  widgetId: para?.widgetId,
                };
                setWidgetModal(true);
              }}
            />
          </Button>
          <Button className="heading-menu-btns" type="button">
            <img
              src={downloadIcon}
              alt="menuIcon"
              import={tblids.tblBestWesternReservationAgent}
              onClick={() =>
                commonService.fnExportInCSV(
                  tblids.tblBestWesternReservationAgent,
                  // "Best Western Reservation Agent"
                  `${
                    para?.propertyCode
                  }-Best Western Reservation Agent-${moment(new Date()).format(
                    "MMDDYYYY"
                  )}`
                )
              }
            />
          </Button>
        </div>
      )}
      <div className="ViewPropertySetupTable">
        <div className="stripped__table">
          <Table
            responsive
            id={tblids.tblBestWesternReservationAgent}
            className="positionStaticTable"
          >
            <thead>
              <tr>
                <th
                  className="width175"
                  onClick={() => {
                    setOpenDatePicker(true);
                  }}
                >
                  Date{" "}
                  <DatePicker
                    onChange={(event) => handleDateChange(event)}
                    startDate={startRangeDate}
                    endDate={endRangeDate}
                    selectsRange
                    monthsShown={2}
                    open={openDatePicker}
                    shouldCloseOnSelect={false}
                  >
                    <div className="w-100" style={{ display: "inline-block" }}>
                      <div className="content-inside-calendar">
                        <p>
                          {startRangeDate
                            ? commonService.getDateInFormat(startRangeDate)
                            : ""}
                          {startRangeDate && endRangeDate ? " - " : ""}
                          {endRangeDate
                            ? commonService.getDateInFormat(endRangeDate)
                            : ""}
                        </p>
                        <div className="d-flex align-items-center gap-2">
                          <button
                            className="secondary-btn"
                            onClick={(e) => {
                              e.stopPropagation();
                              setStartRangeDate("");
                              setEndRangeDate("");
                              setOpenDatePicker(false);
                            }}
                          >
                            Cancel
                          </button>
                          <button
                            disabled={
                              startRangeDate && endRangeDate ? false : true
                            }
                            onClick={(e) => {
                              e.stopPropagation();
                              if (
                                startRangeDateRef.current &&
                                endRangeDateRef.current
                              ) {
                                getReservationAgentDetail();
                              }

                              setOpenDatePicker(false);
                            }}
                            className="primary-btn"
                          >
                            Apply
                          </button>
                        </div>
                      </div>
                    </div>
                  </DatePicker>
                  {startRangeDate && endRangeDate && (
                    <span
                      style={{ display: "none" }}
                    >{`${commonService.getDateInFormat(
                      startRangeDate
                    )} - ${commonService.getDateInFormat(endRangeDate)}`}</span>
                  )}
                </th>
                <th colSpan={42}></th>
                <th className="text-center"></th>
              </tr>
              <tr>
                <th className="width100 text-start">asOfDate</th>
                {/* <th className="width100">propertyCode</th>
                <th className="width100">pullDateId</th> */}
                <th className="width100 text-center">HotelName</th>
                <th className="width100 text-start">PrintedValue</th>
                <th className="width100 text-center">PrintedCaption</th>
                <th className="width100 text-start">BusinessDateVal</th>
                <th className="width100 text-center">BusinessDateCaption</th>
                <th className="width100 text-center">textBox66</th>
                <th className="width100 text-center">textBox68</th>
                <th className="width100 text-center">textBox60</th>
                <th className="width100 text-center">textBox55</th>
                <th className="width100 text-center">textBox8</th>
                <th className="width100 text-center">textBox92</th>
                <th className="width10 text-center">textBox90</th>
                <th className="width100 text-center">textBox88</th>
                <th className="width100 text-center">textBox86</th>
                <th className="width100 text-center">textBox83</th>
                <th className="width100 text-center">textBox89</th>
                <th className="width100 text-center">textBox91</th>
                <th className="width100 text-center">textBox87</th>
                <th className="width100 text-center">textBox93</th>
                <th className="width100 text-center">textBox94</th>
                <th className="width100 text-center">textBox85</th>
                <th className="width100 text-center">textBox84</th>
                <th className="width100 text-center">textBox96</th>
                <th className="width100 text-center">textBox17</th>
                <th className="width100 text-center">textBox37</th>
                <th className="width100 text-center">textBox44</th>
                <th className="width100 text-center">textBox43</th>
                <th className="width100 text-center">textBox3</th>
                <th className="width100 text-center">textBox5</th>
                <th className="width100 text-start">textBox6</th>
                <th className="width100 text-start">textBox7</th>
                <th className="width100 text-center">textBox9</th>
                <th className="width100 text-center">textBox10</th>
                <th className="width100 text-start">textBox11</th>
                <th className="width100 text-center">textBox12</th>
                <th className="width100 text-center">textBox13</th>
                <th className="width100 text-center">textBox14</th>
                <th className="width100 text-center">textBox16</th>
                <th className="width100 text-center">textBox15</th>
                <th className="width100 text-center">textBox4</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr style={{ height: "80px", position: "relative" }}>
                  <Loader />
                </tr>
              ) : reservationAgent && reservationAgent?.length > 0 ? (
                reservationAgent?.map((item) => (
                  <tr key={item?.id} className="shadowOnHover">
                    <td className="text-start">
                      {item?.asOfDate
                        ? commonService.getDateInFormat(item?.asOfDate)
                        : ""}
                    </td>
                    {/* <td>{item?.propertyCode}</td>
                    <td>{item?.pullDateId}</td> */}
                    <td className="text-center">{item?.HotelName}</td>
                    <td className="text-start">
                      {item?.PrintedValue
                        ? commonService.getDateInFormat(item?.PrintedValue)
                        : ""}
                    </td>
                    <td className="text-center">{item?.PrintedCaption}</td>
                    <td className="text-start">
                      {item?.BusinessDateVal
                        ? commonService.getDateInFormat(item?.BusinessDateVal)
                        : ""}
                    </td>
                    <td className="text-center">{item?.BusinessDateCaption}</td>
                    <td className="text-center">{item?.textBox66}</td>
                    <td className="text-center">{item?.textBox68}</td>
                    <td className="text-center">{item?.textBox60}</td>
                    <td className="text-center">{item?.textBox55}</td>
                    <td className="text-center">{item?.textBox8}</td>
                    <td className="text-center">{item?.textBox92}</td>
                    <td className="text-center">{item?.textBox90}</td>
                    <td className="text-center">{item?.textBox88}</td>
                    <td className="text-center">{item?.textBox86}</td>
                    <td className="text-center">{item?.textBox83}</td>
                    <td className="text-center">{item?.textBox89}</td>
                    <td className="text-center">{item?.textBox91}</td>
                    <td className="text-center">{item?.textBox87}</td>
                    <td className="text-center">{item?.textBox93}</td>
                    <td className="text-center">{item?.textBox94}</td>
                    <td className="text-center">{item?.textBox85}</td>
                    <td className="text-center">{item?.textBox84}</td>
                    <td className="text-center">{item?.textBox96}</td>
                    <td className="text-center">{item?.textBox17}</td>
                    <td className="text-center">{item?.textBox37}</td>
                    <td className="text-center">{item?.textBox44}</td>
                    <td className="text-center">{item?.textBox43}</td>
                    <td className="text-center">{item?.textBox3}</td>
                    <td className="text-center">{item?.textBox5}</td>
                    <td className="text-start">
                      {item?.textBox6
                        ? commonService.getDateInFormat(item?.textBox6)
                        : ""}
                    </td>
                    <td className="text-start">
                      {item?.textBox7
                        ? commonService.getDateInFormat(item?.textBox7)
                        : ""}
                    </td>
                    <td className="text-center">{item?.textBox9}</td>
                    <td className="text-center">{item?.textBox10}</td>
                    <td className="text-start">
                      {item?.textBox11
                        ? commonService.getDateInFormat(item?.textBox11)
                        : ""}
                    </td>
                    <td className="text-center">{item?.textBox12}</td>
                    <td className="text-center">{item?.textBox13}</td>
                    <td className="text-center">{item?.textBox14}</td>
                    <td className="text-center">{item?.textBox16}</td>
                    <td className="text-center">{item?.textBox15}</td>
                    <td className="text-center">{item?.textBox4}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td className="text-center" colSpan={44}>
                    No Data Found
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </div>
      {/* {showNoteSidebar && (
        <WidgetNote
          show={showNoteSidebar}
          handleClose={closeNoteSideBar}
          widgetId={para?.widgetId}
          propertyId={propertyDetailFromRTK?.propertyid}
          rowTitle={para?.rowTitle}
        />
      )} */}
      {showNoteSidebar && (
        <AddWidgetModal
          showModal={showNoteSidebar}
          closeModal={closeNoteSideBar}
          widgetId={para?.widgetId}
          propertyId={propertyDetailFromRTK?.propertyid}
          rowTitle={para?.rowTitle}
        />
      )}
      {widgetModal && (
        <WidgetDetailModal
          showModal={widgetModal}
          closeModal={closeWidgetModal}
          data={widgetDetailRef.current}
        />
      )}
    </div>
  );
};

export default BestWesternReservationAgent;
