import React, { useRef } from "react";
import { commonService } from "../../../../utils/commonService";
import { apiCall } from "../../../../utils/axiosService";
import { API_URL } from "../../../../utils/apiRoutes";
import { apiResponse } from "../../../../utils/apiResponse";
// import { Line } from "react-chartjs-2";
// import {
//   Chart,
//   CategoryScale,
//   LinearScale,
//   BarElement,
//   Tooltip,
//   Legend,
//   PointElement,
//   LineElement,
//   Filler,
// } from "chart.js";
import { useState } from "react";
import { month } from "../../../../utils/constant";
import { Col, Form, Overlay, Popover, Row, Table } from "react-bootstrap";
import moment from "moment";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import Loader from "../../../Loader/Loader";
import { Chart } from "chart.js/auto";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";
import SingleDateSummaryModal from "../../SingleDateSummaryModal";
import SingleDateSummary from "./SingleDateSummary";
import { MultiSelect } from "primereact/multiselect";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const RateShopChart = ({
    setCompDataFromChild,
    rowIndex,
    colIndex,
    api,
    para,
    setting,
}) => {
    const propertyDetailFromRTK = useSelector(
        (store) => store.headerProperty.propertyDetail
    );
    const yearsArr = [
        ...commonService.getPastYearForDropDown(10),
        ...commonService.getFutureYearForDropDown(10),
        new Date().getFullYear(),
    ]?.sort(function (a, b) {
        return a - b;
    });
    // let datesArray = commonService.getAllDatesInMonth(
    //   new Date().getFullYear(),
    //   new Date().getMonth()
    // );
    const date = new Date();
    const startDate = commonService.getDateInDBFormat(
        new Date(date.getFullYear(), date.getMonth(), 1)
    );
    const endDate = commonService.getDateInDBFormat(
        new Date(date.getFullYear(), date.getMonth() + 1, 0)
    );
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
    const [dateRange, setDateRange] = useState({
        start: startDate,
        end: endDate,
    });
    let datesArray = commonService.getAllDatesInMonthForAdrChart(
        new Date(dateRange?.start),
        new Date(dateRange?.end)
    );
    const [chartData, setChartData] = useState(null);
    const [chartOptions, setchartOptions] = useState({
        elements: {
            point: {
                radius: 0,
            },
        },
        scales: {
            x: {
                
                title: {
                    display: false,
                    text: "Date",
                },
                grid: {
                    display: false,
                },
                offset: true,
                barPercentage: 0.6,
                categoryPercentage: 0.8,
                // ticks: {
                //   display: true,
                //   callback: function (value) {
                //     return value;
                //   },
                //   font: function (context) {
                //     let date = moment(dateRange?.start);
                //     let finalDate = moment(
                //       `${date.month() + 1}-${context.tick.value}-${date.year()}`,
                //       "MM-DD-YYYY"
                //     );
                //     if (
                //       moment(finalDate, "l").format("ddd") === "Sat" ||
                //       moment(finalDate, "l").format("ddd") === "Fri"
                //     ) {
                //       if (context.tick.value != "0") {
                //         return {
                //           weight: "bolder",
                //         };
                //       }
                //     } else {
                //       return {};
                //     }
                //   },
                // },
                ticks: {
                    color: function (context) {
                        let date = moment(dateRange?.start);
                        let finalDate = moment(
                            `${date.month() + 1}-${context.tick.value}-${date.year()}`,
                            "MM-DD-YYYY"
                        );
                        if (
                            moment(finalDate, "l").format("ddd") === "Sat" ||
                            moment(finalDate, "l").format("ddd") === "Fri"
                        ) {
                            if (context.tick.value != "0") {
                                if (
                                    moment(finalDate, "l").format("MM-DD-YYY") ===
                                    moment(new Date(), "l").format("MM-DD-YYY")
                                ) {
                                    return "#FFFFFF";
                                } else {
                                    return "#000000";
                                }
                            }
                        } else {
                            if (
                                moment(finalDate, "l").format("MM-DD-YYY") ===
                                moment(new Date(), "l").format("MM-DD-YYY")
                            ) {
                                return "#FFFFFF";
                            } else {
                                return "#676767";
                            }
                        }
                    },
                    font: function (context) {
                        let date = moment(dateRange?.start);
                        let finalDate = moment(
                            `${date.month() + 1}-${context.tick.value}-${date.year()}`,
                            "MM-DD-YYYY"
                        );
                        if (
                            moment(finalDate, "l").format("ddd") === "Sat" ||
                            moment(finalDate, "l").format("ddd") === "Fri"
                        ) {
                            if (context.tick.value != "0") {
                                if (
                                    moment(finalDate, "l").format("MM-DD-YYY") ===
                                    moment(new Date(), "l").format("MM-DD-YYY")
                                ) {
                                    return {
                                        weight: "bolder",
                                        /*size: "15px",*/
                                    };
                                } else {
                                    return {
                                        weight: "bolder",
                                    };
                                }
                            }
                        } else {
                            if (
                                moment(finalDate, "l").format("MM-DD-YYY") ===
                                moment(new Date(), "l").format("MM-DD-YYY")
                            ) {
                                return {
                                    weight: "bolder",
                                    /*size: "15px",*/
                                };
                            } else {
                                return {};
                            }
                        }
                    },
                    showLabelBackdrop:true,
                    backdropColor: function (context) {
                        let date = moment(dateRange?.start);
                        let finalDate = moment(
                            `${date.month() + 1}-${context.tick.value}-${date.year()}`,
                            "MM-DD-YYYY"
                        );
                        if (
                            moment(finalDate, "l").format("ddd") === "Sat" ||
                            moment(finalDate, "l").format("ddd") === "Fri"
                        ) {
                            if (context.tick.value !== "0") {
                                if (
                                    moment(finalDate, "l").format("MM-DD-YYY") ===
                                    moment(new Date(), "l").format("MM-DD-YYY")
                                ) {
                                    return "#000000";
                                } else {
                                    return "#FFFFFF";
                                }
                            }
                        } else {
                            if (
                                moment(finalDate, "l").format("MM-DD-YYY") ===
                                moment(new Date(), "l").format("MM-DD-YYY")
                            ) {
                                return "#000000";
                            } else {
                                return "#FFFFFF";
                            }
                        }
                    },
                },
                
            },
            x1: {
                backgroundColor: "#ebeced",
                title: {
                    display: false,
                    text: "OTB",
                },
                labels: [],
                grid: {
                    display: false,
                },
                offset: true,
                barPercentage: 0.6,
                categoryPercentage: 0.8,
                ticks: {
                    color: "#144BFF",
                },
            },
            x2: {
                title: {
                    display: false,
                    text: "Cap",
                },
                labels: [],
                grid: {
                    display: false,
                },
                offset: true,
                barPercentage: 0.6,
                categoryPercentage: 0.8,
            },
            x3: {
                backgroundColor: "#ebeced",
                title: {
                    display: false,
                    text: "ECa",
                },
                labels: [],
                grid: {
                    display: false,
                },
                offset: true,
                barPercentage: 0.6,
                categoryPercentage: 0.8,
            },
            x4: {
                title: {
                    display: false,
                    text: "OF",
                },
                labels: [],
                grid: {
                    display: false,
                },
                offset: true,
                barPercentage: 0.6,
                categoryPercentage: 0.8,
            },
            x5: {
                backgroundColor: "#ebeced",
                title: {
                    display: false,
                    text: "GR",
                },
                labels: [],
                grid: {
                    display: false,
                },
                offset: true,
                barPercentage: 0.6,
                categoryPercentage: 0.8,
            },
            x6: {
                /* type:"Percentage",*/
                title: {
                    display: false,
                    text: "OCC %",
                },
                labels: [],
                grid: {
                    display: false,
                },
                offset: true,
                barPercentage: 0.6,
                categoryPercentage: 0.8,
                ticks: {
                    callback: function (val, index) {
                        return index === 0
                            ? this.getLabelForValue(val)
                            : this.getLabelForValue(val) + "%";
                    },
                },
            },
            x7: {
                backgroundColor: "#ebeced",
                title: {
                    display: false,
                    text: "LS",
                },
                labels: [],
                grid: {
                    display: false,
                },
                offset: true,
                barPercentage: 0.6,
                categoryPercentage: 0.8,
            },
            x8: {
                title: {
                    display: false,
                    text: "ADR",
                },
                labels: [],
                grid: {
                    display: false,
                },
                offset: true,
                barPercentage: 0.6,
                categoryPercentage: 0.8,
                ticks: {
                    callback: function (val, index) {
                        return index === 0
                            ? this.getLabelForValue(val)
                            : "$" + this.getLabelForValue(val);
                    },
                },
            },
            y: {
                title: {
                    display: true,
                    text: "Rate",
                },
                stacked: false,
                beginAtZero: true,
                type: "linear",
                position: "right",
                ticks: {
                    callback: function (value, index, values) {
                        return `${value}`;
                    },
                },
            },
            y2: {
                title: {
                    display: true,
                    text: "Room",
                },
                stacked: false,
                beginAtZero: true,
                min: 0,
                /*max: 100,*/
                type: "linear",
                position: "left",
                grid: {
                    drawOnChartArea: false,
                },
                ticks: {
                    min: 0,
                    /*max: 100,*/
                    stepSize: 10,
                    callback: function (value, index, values) {
                        return `${value}`;
                    },
                },
            },
        },
        plugins: {
            tooltip: {
                mode: "index",
                intersect: false,
                callbacks: {
                    label: function (context) {
                        let label = context.dataset.label || "";
                        if (label) {
                            label += " : ";
                        }
                        if (context.parsed.y !== null) {
                            if (
                                label.includes("ADR") ||
                                label.includes("Self") ||
                                label.includes("Avg") ||
                                label.includes("Min") ||
                                label.includes("Max")
                            ) {
                                label += `$${context.parsed.y}`;
                            } else if (label.includes("OCC")) {
                                label += `${context.parsed.y}%`;
                            } else if (
                                label.includes("OTB") ||
                                label.includes("Capacity") ||
                                label.includes("Effective Capacity") ||
                                label.includes("Occupancy Forecast") ||
                                label.includes("Left To Sell")
                            ) {
                                label += context.parsed.y;
                            } else {
                                label += `$${context.parsed.y}`;
                            }
                        }
                        return label;
                    },
                },
            },
            legend: {
                display: true,
                align: "center",
                position: "bottom",
                paddingTop: 10,
                maxWidth: 20,
                maxHeight: 100,
                labels: {
                    boxWidth: 10,
                    boxHeight: 10,
                    filter: function (legendItem, data) {
                        // if (legendItem.text === "Quality Inn Festus") {
                        //   return false;
                        // }
                        return true;
                    },
                },
            },
        },
        responsive: true,
        maintainAspectRatio: false,
        aspectRatio: 4 / 1,
    });
    const [loading, setLoading] = useState(false);
    const [showSingleDateSummaryModal, setShowSingleDateSummaryModal] =
        useState(false);
    const singleDateSelectedDateRef = useRef();
    const jsonexportDataRef = useRef();
    const usedColorArr = [
        "#3565FC",
        "#CFDAFE",
        "#4A84C2",
        "#197EB4",
        "#CDD2B5",
        "#B0A4F6",
        "#CA4D46",
        "#DE9234",
        "#FDDFBC",
        "#FDDFBC",
    ];
    const withoutCompetitorChartDataSet = useRef();
    const competitorChartDataSet = useRef();
    const [competitorDropDown, setCompetitorDropDown] = useState(null);
    const [selectedCompetitor, setSelectedCompetitor] = useState(null);

    const closeSingleDateSummaryModal = () => {
        setShowSingleDateSummaryModal(false);
    };

    const generateColor = () => {
        let colorFromService = commonService?.generateRandomColor();
        let isColorExists = usedColorArr?.filter((x) => x === colorFromService);
        while (isColorExists?.length > 0) {
            generateColor();
        }
        usedColorArr.push(colorFromService);
        return colorFromService;
    };

    const getChartDetail = async () => {
        setLoading(true);
        let month =
            selectedMonth >= 9 ? selectedMonth + 1 : `0${selectedMonth + 1}`;
        const chartDetailResponse = await apiCall(
            {
                method: "POST",
                url:
                    API_URL.BASE_API_URL.SnapShot +
                    API_URL.WIDGET_MODULE.ADR_VS_RATE_CHART,
                body: {
                    startDate: dateRange?.start,
                    AsOfDate: para?.asOfDate,
                    endDate: dateRange?.end,
                    propertyCode: para?.propertyCode,
                    propertyid: window.location.href.includes("crm")
                        ? para?.propertyId
                        : propertyDetailFromRTK?.propertyid
                            ? propertyDetailFromRTK?.propertyid
                            : "",
                    ChannelName: para?.propertyDetail?.chain_term,
                },
            },
            false
        );

        let response = apiResponse(false, chartDetailResponse);

        if (response?.isValidate) {
            let dataFromApi = response?.data?.data;
            let competitorDropdownValue = [];
            let jsonForExportData = {
                chartData: [
                    {
                        data: [
                            "Dates",
                            "Room",
                            "Self",
                            "Left to Sell (LS)",
                            "OTB",
                            "Occupancy Forecast (OF)",
                            "Capacity (CAP)",
                            "Effective Capacity (EC)",
                            "ADR",
                            "CompSet AVG",
                            "CompSet Min",
                            "CompSet Max",
                        ],
                    },
                ],
                chartTable: [],
            };
            dataFromApi?.compitiore_list?.forEach((item) => {
                competitorDropdownValue.push({
                    name: item?.competiterpropertyname,
                    code: item?.competiterpropertyname,
                });
            });
            let chartDataSet = {
                //OCCData: {
                //    type: "bar",
                //    label: "OCC",
                //    data: [,],
                //    fill: false,
                //    backgroundColor: "#3565FC",
                //    borderColor: "#3565FC",
                //    barThickness: 10,
                //    borderWidth: 0,
                //    tension: 0.4,
                //    yAxisID: "y2",
                //    order: 1,
                //},
                otb: {
                    type: "bar",
                    label: "OTB",
                    data: [,],
                    fill: false,
                    backgroundColor: "#3565FC",
                    borderColor: "#3565FC",
                    borderWidth: 3,
                    order: 1,
                    yAxisID: "y2",
                    pointRadius: 4,
                    pointBorderWidth: 2,
                    pointBorderColor: "#ffffff",
                },
                occForecast: {
                    type: "bar",
                    label: "Occupancy Forecast (OF)",
                    data: [,],
                    fill: false,
                    backgroundColor: "#CFDAFE",
                    borderColor: "#CFDAFE",
                    borderWidth: 3,
                    order: 2,
                    yAxisID: "y2",
                    pointRadius: 4,
                    pointBorderWidth: 2,
                    pointBorderColor: "#ffffff",
                },
                capacity: {
                    type: "line",
                    label: "Capacity (CAP)",
                    data: [,],
                    fill: false,
                    backgroundColor: "#CDD2B5",
                    borderColor: "#CDD2B5",
                    borderWidth: 3,
                    order: 3,
                    yAxisID: "y2",
                    pointRadius: 4,
                    pointBorderWidth: 2,
                    pointBorderColor: "#ffffff",
                },
                effectiveCapacity: {
                    type: "line",
                    label: "Effective Capacity (EC)",
                    data: [,],
                    fill: false,
                    backgroundColor: "#B0A4F6",
                    borderColor: "#B0A4F6",
                    borderWidth: 3,
                    order: 4,
                    yAxisID: "y2",
                    pointRadius: 4,
                    pointBorderWidth: 2,
                    pointBorderColor: "#ffffff",
                },

                propertyData: {
                    type: "line",
                    label: "ADR",
                    data: [,],
                    fill: false,
                    backgroundColor: "#197EB4",
                    borderColor: "#197EB4",
                    borderWidth: 3,
                    order: 5,
                    yAxisID: "y",
                    pointRadius: 4,
                    pointBorderWidth: 2,
                    pointBorderColor: "#ffffff",
                    hidden: true,
                },
                avgData: {
                    type: "line",
                    label: "CompSet Avg",
                    data: [,],
                    fill: false,
                    backgroundColor: "#DE9234",
                    borderColor: "#DE9234",
                    borderWidth: 2,
                    order: 6,
                    yAxisID: "y",
                    pointRadius: 4,
                    pointBorderWidth: 2,
                    pointBorderColor: "#ffffff",
                    hidden: false,
                },
                minData: {
                    type: "line",
                    stacked: true,
                    label: "CompSet Min",
                    data: [,],
                    fill: "+1",
                    backgroundColor: "#FDDFBC",
                    borderColor: "#FDDFBC",
                    borderWidth: 0,
                    tension: 0.2,
                    order: 7,
                    yAxisID: "y",
                    hidden: false,
                },
                maxData: {
                    type: "line",
                    stacked: true,
                    label: "CompSet Max",
                    data: [,],
                    fill: 1,
                    backgroundColor: "#FDDFBC",
                    borderColor: "#FDDFBC",
                    borderWidth: 0,
                    tension: 0.2,
                    order: 8,
                    yAxisID: "y",
                    hidden: false,
                },
                selfData: {
                    type: "line",
                    label: "Self",
                    data: [,],
                    fill: false,
                    backgroundColor: "#4A84C2",
                    borderColor: "#4A84C2",
                    borderWidth: 3,
                    order: 0,
                    yAxisID: "y",
                    pointRadius: 4,
                    pointBorderWidth: 2,
                    pointBorderColor: "#ffffff",
                },
                leftToSell: {
                    type: "line",
                    label: "Left To Sell (LS)",
                    data: [,],
                    fill: false,
                    backgroundColor: "#FF0000",
                    borderColor: "#FF0000",
                    borderWidth: 3,
                    order: 0,
                    yAxisID: "y2",
                    pointRadius: 4,
                    pointBorderWidth: 2,
                    pointBorderColor: "#ffffff",
                    hidden: true,
                },
            };
            let groupingForTable = {
                OTB: ["OTB"],
                Cap: ["CAP"],
                ECa: ["EC"],
                OF: ["OF"],
                GR: ["GR"],
                Occperc: ["OCC %"],
                LS: ["LS"],
                ADR: ["ADR"],
            };
            let labels = ["Date"];
            let labelForJson = ["Date"],
                otbForJson = ["OTB"],
                capForJson = ["CAP"],
                ecaForJson = ["EC"],
                ofForJson = ["OF"],
                grForJson = ["GR"],
                occPerForJson = ["OCC %"],
                lsForJson = ["LS"],
                adrForJson = ["ADR"];
            let compitioreChartDataSet = {};
            for (let i = 0; i < datesArray?.length; i++) {
                labels.push(i + 1);
                labelForJson.push(`${i + 1}`);
                let month =
                    selectedMonth >= 9 ? selectedMonth + 1 : `0${selectedMonth + 1}`;
                let iDate = i >= 9 ? i + 1 : `0${i + 1}`;
                let date = `${selectedYear}-${month}-${iDate}`;
                let findDataForAdr = dataFromApi?.adr?.filter(
                    (x) => x.Dates === moment(date).format("YYYY-MM-DD")
                );
                let adrValue =
                    findDataForAdr?.length > 0
                        ? findDataForAdr[0]?.ADR
                            ? findDataForAdr[0]?.ADR
                            : 0
                        : 0;
                let occPercentageValue =
                    findDataForAdr?.length > 0
                        ? findDataForAdr[0]?.Occperc
                            ? findDataForAdr[0]?.Occperc
                            : 0
                        : 0;
                let otbValue =
                    findDataForAdr?.length > 0
                        ? findDataForAdr[0]?.RoomSold
                            ? findDataForAdr[0]?.RoomSold
                            : 0
                        : 0;
                let capacityValue =
                    findDataForAdr?.length > 0
                        ? findDataForAdr[0]?.Inventory
                            ? findDataForAdr[0]?.Inventory
                            : 0
                        : 0;
                let effectiveCapacityValue =
                    findDataForAdr?.length > 0
                        ? findDataForAdr[0]?.RoomsOfAvailable
                            ? findDataForAdr[0]?.RoomsOfAvailable
                            : 0
                        : 0;
                let occForecastValue =
                    findDataForAdr?.length > 0
                        ? findDataForAdr[0]?.Forecast_RMS
                            ? findDataForAdr[0]?.Forecast_RMS
                            : 0
                        : 0;

                let groupValue =
                    findDataForAdr?.length > 0
                        ? findDataForAdr[0]?.GroupOTB
                            ? findDataForAdr[0]?.GroupOTB
                            : 0
                        : 0;
                groupingForTable.OTB = [...groupingForTable?.OTB, otbValue];
                groupingForTable.Cap = [...groupingForTable?.Cap, capacityValue];
                groupingForTable.ECa = [
                    ...groupingForTable?.ECa,
                    effectiveCapacityValue,
                ];
                groupingForTable.OF = [...groupingForTable?.OF, occForecastValue];
                groupingForTable.GR = [...groupingForTable?.GR, groupValue];
                groupingForTable.Occperc = [
                    ...groupingForTable?.Occperc,
                    occPercentageValue,
                ];
                groupingForTable.LS = [
                    ...groupingForTable.LS,
                    effectiveCapacityValue - otbValue,
                ];
                groupingForTable.ADR = [...groupingForTable.ADR, adrValue];

                otbForJson.push(`${otbValue}`);
                capForJson.push(`${capacityValue}`);
                ecaForJson.push(`${effectiveCapacityValue}`);
                ofForJson.push(`${occForecastValue}`);
                grForJson.push(`${groupValue}`);
                occPerForJson.push(`${occPercentageValue}`);
                lsForJson.push(`${effectiveCapacityValue - otbValue}`);
                adrForJson.push(`${adrValue}`);

                chartDataSet?.propertyData?.data.push(adrValue);
                /*chartDataSet?.OCCData?.data.push(occPercentageValue);*/
                chartDataSet?.otb?.data.push(otbValue);
                chartDataSet?.capacity?.data.push(capacityValue);
                chartDataSet?.effectiveCapacity?.data.push(effectiveCapacityValue);
                chartDataSet?.occForecast?.data.push(occForecastValue);
                chartDataSet?.leftToSell?.data?.push(effectiveCapacityValue - otbValue);

                let selfValueForExport = 0,
                    minValueForExport = 0,
                    maxValueForExport = 0,
                    avgValueForExport = 0;

                let findCompetitorData = dataFromApi?.compitiore?.filter(
                    (x) => x?.CheckInDate === moment(date).format("YYYY-MM-DD")
                );
                let findDataForAvgCount = dataFromApi?.compitiore?.filter(
                    (x) =>
                        x?.CheckInDate === moment(date).format("YYYY-MM-DD") &&
                        x?.IsSelf !== true &&
                        parseFloat(x?.RATE) > 0
                );
                if (findCompetitorData?.length > 0) {
                    let findSelfData = findCompetitorData?.filter(
                        (x) => x?.IsSelf === true
                    );
                    chartDataSet?.selfData?.data?.push(
                        findSelfData[0]?.RATE ? findSelfData[0]?.RATE : 0
                    );
                    let minValue = dataFromApi?.compitiore?.filter(
                        (x) => parseFloat(x.RATE) > 0 && x?.IsSelf !== true
                    );
                    let total = 0,
                        min = minValue[0]?.RATE ? parseFloat(minValue[0]?.RATE) : "",
                        max = findCompetitorData[0]?.RATE
                            ? parseFloat(findCompetitorData[0]?.RATE)
                            : 0;
                    for (let i = 0; i < findCompetitorData?.length; i++) {
                        min =
                            findCompetitorData[i]?.IsSelf === true
                                ? min
                                : findCompetitorData[i]?.RATE > 0
                                    ? parseFloat(findCompetitorData[i]?.RATE) < min
                                        ? parseFloat(findCompetitorData[i]?.RATE)
                                        : min
                                    : min;
                        max =
                            findCompetitorData[i]?.IsSelf === true
                                ? max
                                : findCompetitorData[i]?.RATE
                                    ? parseFloat(findCompetitorData[i]?.RATE) > max
                                        ? parseFloat(findCompetitorData[i]?.RATE)
                                        : max
                                    : findCompetitorData[i]?.RATE;
                        // if (findCompetitorData[i]?.IsSelf === true) {
                        //   chartDataSet?.selfData?.data?.push(
                        //     findCompetitorData[i]?.RATE ? findCompetitorData[i]?.RATE : 0
                        //   );
                        // }
                        total =
                            findCompetitorData[i]?.IsSelf === true
                                ? total
                                : total +
                                parseFloat(
                                    findCompetitorData[i]?.RATE
                                        ? findCompetitorData[i]?.RATE
                                        : 0
                                );
                    }
                    chartDataSet?.avgData?.data?.push(
                        total > 0 ? Math.round(total / findDataForAvgCount?.length) : 0
                    );
                    chartDataSet?.minData?.data?.push(min);
                    chartDataSet?.maxData?.data?.push(max);

                    selfValueForExport = findSelfData[0]?.RATE
                        ? findSelfData[0]?.RATE
                        : 0;
                    minValueForExport = min;
                    maxValueForExport = max;
                    avgValueForExport =
                        total > 0 ? Math.round(total / findDataForAvgCount?.length) : 0;
                } else {
                    chartDataSet?.selfData?.data?.push(0);
                    chartDataSet?.avgData?.data?.push(0);
                    chartDataSet?.minData?.data?.push(0);
                    chartDataSet?.maxData?.data?.push(0);

                    selfValueForExport = 0;
                    minValueForExport = 0;
                    maxValueForExport = 0;
                    avgValueForExport = 0;
                }

                jsonForExportData.chartData.push({
                    data: [
                        `${i + 1}`,
                        `${capacityValue}`,
                        `${selfValueForExport}`,
                        `${effectiveCapacityValue - otbValue}`,
                        `${otbValue}`,
                        `${occForecastValue}`,
                        `${capacityValue}`,
                        `${effectiveCapacityValue}`,
                        `${adrValue}`,
                        `${avgValueForExport}`,
                        `${minValueForExport}`,
                        `${maxValueForExport}`,
                    ],
                });
                dataFromApi?.compitiore_list?.forEach((compItem) => {
                    let isCompetitorExists = findCompetitorData?.filter(
                        (x) => x?.competiterName === compItem?.competiterpropertyname
                    );
                    // let randomColor = generateColor();
                    let randomColor = commonService.CreateColor(
                        `${compItem?.competiterpropertyname}`
                    ).BGColor;

                    if (compitioreChartDataSet[compItem?.competiterpropertyname]) {
                        compitioreChartDataSet[compItem?.competiterpropertyname].data.push(
                            isCompetitorExists?.[0]?.RATE ? isCompetitorExists?.[0]?.RATE : 0
                        );
                    } else {
                        compitioreChartDataSet[compItem?.competiterpropertyname] = {
                            type: "line",
                            label: `${compItem?.competiterpropertyname}`,
                            data: [
                                ,
                                isCompetitorExists?.[0]?.RATE
                                    ? isCompetitorExists?.[0]?.RATE
                                    : 0,
                            ],
                            fill: false,
                            backgroundColor: randomColor,
                            borderColor: randomColor,
                            borderWidth: 3,
                            order: 0,
                            yAxisID: "y",
                            pointRadius: 4,
                            pointBorderWidth: 2,
                            pointBorderColor: "#ffffff",
                            hidden: false,
                        };
                    }
                });
            }
            let datasetForchart = [];
            Object?.keys(chartDataSet)?.forEach((key) => {
                if (key !== "propertyData" && key !== "leftToSell") {
                    datasetForchart.push(chartDataSet[key]);
                }
            });

            let newChatOption = { ...chartOptions };
            newChatOption.scales.x1.labels = groupingForTable.OTB;
            newChatOption.scales.x2.labels = groupingForTable.Cap;
            newChatOption.scales.x3.labels = groupingForTable.ECa;
            newChatOption.scales.x4.labels = groupingForTable.OF;
            newChatOption.scales.x5.labels = groupingForTable.GR;
            newChatOption.scales.x6.labels = groupingForTable.Occperc;
            newChatOption.scales.x7.labels = groupingForTable.LS;
            newChatOption.scales.x8.labels = groupingForTable.ADR;

            competitorChartDataSet.current = compitioreChartDataSet;
            withoutCompetitorChartDataSet.current = datasetForchart;
            jsonForExportData.chartData.push({ data: [] });
            jsonForExportData.chartData.push({ data: labelForJson });
            jsonForExportData.chartData.push({ data: otbForJson });
            jsonForExportData.chartData.push({ data: capForJson });
            jsonForExportData.chartData.push({ data: ecaForJson });
            jsonForExportData.chartData.push({ data: ofForJson });
            jsonForExportData.chartData.push({ data: grForJson });
            jsonForExportData.chartData.push({ data: occPerForJson });
            jsonForExportData.chartData.push({ data: lsForJson });
            jsonForExportData.chartData.push({ data: adrForJson });

            // jsonForExportData.chartTable.push({ data: labelForJson });
            // jsonForExportData.chartTable.push({ data: otbForJson });
            // jsonForExportData.chartTable.push({ data: capForJson });
            // jsonForExportData.chartTable.push({ data: ecaForJson });
            // jsonForExportData.chartTable.push({ data: ofForJson });
            // jsonForExportData.chartTable.push({ data: grForJson });
            // jsonForExportData.chartTable.push({ data: occPerForJson });
            // jsonForExportData.chartTable.push({ data: lsForJson });
            jsonexportDataRef.current = jsonForExportData;

            setCompetitorDropDown(competitorDropdownValue);
            setChartData({
                labels: labels,
                datasets: datasetForchart,
            });
            setchartOptions(newChatOption);
            setLoading(false);
        }

        if (!response?.isValidate) {
            setChartData(null);
            setLoading(false);
            jsonexportDataRef.current = [];
        }
    };

    useEffect(() => {
        if (api === 0) {
            let dummyChartDetail = {
                labels: datesArray,
                datasets: [
                    {
                        type: "bar",
                        label: "Occ %",
                        data: [
                            240, 160, 145, 120, 48, 225, 47, 52, 99, 140, 160, 180, 180, 275,
                            225, 145, 145, 120, 48, 225, 47, 52, 99, 140, 160, 180, 180, 160,
                            120, 190, 120,
                        ],
                        fill: false,
                        backgroundColor: "#cfdafe",
                        borderColor: "#cfdafe",
                        barThickness: 10,
                        borderWidth: 0,
                        // pointRadius: 4,
                        // pointBorderWidth: 0,
                        // pointBorderColor: '#ffffff',
                        tension: 0.4,
                        yAxisID: "y",
                        order: 3,
                    },
                    {
                        type: "bar",
                        label: "Property",
                        data: [
                            240, 160, 145, 120, 48, 225, 47, 52, 99, 140, 160, 180, 180, 275,
                            225, 145, 145, 120, 48, 225, 47, 52, 99, 140, 160, 180, 180, 160,
                            120, 190, 120,
                        ],
                        fill: false,
                        backgroundColor: "#cfdafe",
                        borderColor: "#cfdafe",
                        barThickness: 10,
                        borderWidth: 0,
                        tension: 0.4,
                        yAxisID: "y",
                        order: 1,
                    },
                    {
                        type: "line",
                        label: "Self",
                        data: [
                            140, 160, 145, 120, 48, 225, 47, 52, 99, 140, 160, 180, 180, 275,
                            225, 145, 145, 120, 48, 225, 47, 52, 99, 140, 160, 180, 180, 160,
                            120, 190, 120,
                        ],
                        fill: false,
                        backgroundColor: "#4a84c2",
                        borderColor: "#4a84c2",
                        borderWidth: 3,
                        order: 0,
                        pointRadius: 4,
                        pointBorderWidth: 2,
                        pointBorderColor: "#ffffff",
                    },
                    {
                        type: "line",
                        label: "Avg",
                        data: [
                            160, 180, 180, 275, 225, 180, 180, 160, 120, 190, 120, 145, 145,
                            120, 48, 225, 47, 140, 160, 145, 120, 48, 225, 47, 52, 99, 140,
                            52, 99, 140, 160,
                        ],
                        fill: false,
                        backgroundColor: "#de9234",
                        borderColor: "#000",
                        borderWidth: 2,
                        order: 0,
                        pointRadius: 4,
                        pointBorderWidth: 2,
                        pointBorderColor: "#ffffff",
                    },
                    {
                        type: "line",
                        stacked: true,
                        label: "Min",
                        data: [
                            170, 190, 185, 295, 245, 190, 190, 170, 140, 210, 125, 165, 175,
                            140, 78, 255, 77, 150, 170, 155, 120, 68, 245, 87, 82, 109, 150,
                            72, 109, 150, 170,
                        ],
                        fill: 1,
                        backgroundColor: "#FDDFBC",
                        borderColor: "#FDDFBC",
                        borderWidth: 0,
                        tension: 0.2,
                        order: 2,
                    },
                    {
                        type: "line",
                        stacked: true,
                        label: "Max",
                        data: [
                            100, 120, 135, 90, 28, 165, 37, 42, 78, 120, 100, 110, 120, 100,
                            40, 125, 35, 110, 38, 125, 27, 32, 69, 30, 35, 80, 30, 20, 25,
                            100, 80,
                        ],
                        fill: 1,
                        backgroundColor: "#FDDFBC",
                        borderColor: "#FDDFBC",
                        borderWidth: 0,
                        tension: 0.2,
                        order: 2,
                    },
                ],
            };
            setChartData(dummyChartDetail);
        } else {
            if (para?.asOfDate) {
                getChartDetail();
            }
        }
    }, [para?.asOfDate, selectedYear, selectedMonth]);

    // Chart.register(
    //   CategoryScale,
    //   LinearScale,
    //   BarElement,
    //   Tooltip,
    //   Legend,
    //   PointElement,
    //   LineElement,
    //   Filler
    // );

    useEffect(() => {
        if (chartData) {
            var ctx = document?.getElementById("revGlance");
            var myChart = new Chart(ctx, {
                type: "line",
                data: {
                    labeslLink: ["https:google.com"],
                    labels: chartData?.labels,
                    datasets: chartData?.datasets,
                },
                options: {
                    ...chartOptions,
                },
            });

            function clickableScales(canvas, click) {
                let height = myChart.scales.x1.height;
                let top = myChart.scales.x1.top;
                let bottom = myChart.scales.x1.bottom;
                let left = myChart.scales.x1.left;
                let right = myChart.scales.x1.maxWidth / myChart.scales.x1.ticks.length;
                let resetCoordinates = canvas.getBoundingClientRect();
                let x = click.clientX - resetCoordinates.left;
                let y = click.clientY - resetCoordinates.top;
                for (let i = 0; i < myChart.scales.x1.ticks.length; i++) {
                    if (
                        x >= left + right * i &&
                        x <= left + right * i + right &&
                        y >= top &&
                        y <= bottom
                    ) {
                        let startdate = new Date(dateRange?.start);
                        singleDateSelectedDateRef.current = new Date(
                            startdate.getFullYear(),
                            startdate.getMonth(),
                            i
                        );
                        // startDateArrayRef.current[index];
                        // handleFilterBtnClick(click, left + right * i + right);
                        setShowSingleDateSummaryModal(true);
                    }
                }
            }
            ctx?.addEventListener("click", (e) => {
                clickableScales(ctx, e);
            });

            return () => {
                myChart.destroy();
            };
        }
    }, [chartData]);

    const hadnlePropertySelection = (e) => {
        setSelectedCompetitor(e?.value ? e?.value : "");
        let compDataSet = [];

        e?.value?.forEach((item) => {
            compDataSet.push(competitorChartDataSet.current[item?.name]);
        });

        setChartData({
            ...chartData,
            datasets: [...withoutCompetitorChartDataSet.current, ...compDataSet],
        });
    };

    return (
        <div>
            <Col xl={12}>
                <Row>
                    <Col xl={3}>
                        <Form.Select
                            value={selectedYear}
                            onChange={(e) => {
                                let d = new Date();
                                d.setMonth(parseInt(selectedMonth));
                                let startDate = commonService.getDateInDBFormat(
                                    new Date(e.target.value, d.getMonth(), 1)
                                );
                                let endDate = commonService.getDateInDBFormat(
                                    new Date(e.target.value, d.getMonth() + 1, 0)
                                );
                                let opt = { ...chartOptions };
                                opt.scales.x = {
                                    title: {
                                        display: false,
                                        text: "Date",
                                    },
                                    grid: {
                                        display: false,
                                    },
                                    offset: true,
                                    barPercentage: 0.6,
                                    categoryPercentage: 0.8,
                                    ticks: {
                                        display: true,
                                        callback: function (value) {
                                            return value;
                                        },
                                        font: function (context) {
                                            let date = moment(startDate);
                                            let finalDate = moment(
                                                `${date.month() + 1}-${context.tick.value
                                                }-${date.year()}`,
                                                "MM-DD-YYYY"
                                            );
                                            if (
                                                moment(finalDate, "l").format("ddd") === "Sat" ||
                                                moment(finalDate, "l").format("ddd") === "Sun"
                                            ) {
                                                if (context.tick.value != "0") {
                                                    return {
                                                        weight: "bolder",
                                                    };
                                                }
                                            } else {
                                                return {};
                                            }
                                        },
                                    },
                                };
                                setchartOptions(opt);
                                setDateRange({
                                    start: startDate,
                                    end: endDate,
                                });
                                setSelectedYear(e.target.value);
                            }}
                        >
                            {yearsArr?.map((item, index) => (
                                <option key={item} value={item}>
                                    {item}
                                </option>
                            ))}
                        </Form.Select>
                    </Col>
                    <Col xl={3}>
                        <Form.Select
                            value={selectedMonth}
                            onChange={(e) => {
                                let d = new Date();
                                d.setMonth(parseInt(e.target.value));
                                let startDate = commonService.getDateInDBFormat(
                                    new Date(selectedYear, d.getMonth(), 1)
                                );
                                let endDate = commonService.getDateInDBFormat(
                                    new Date(selectedYear, d.getMonth() + 1, 0)
                                );
                                let opt = { ...chartOptions };
                                opt.scales.x = {
                                    title: {
                                        display: false,
                                        text: "Date",
                                    },
                                    grid: {
                                        display: false,
                                    },
                                    offset: true,
                                    barPercentage: 0.6,
                                    categoryPercentage: 0.8,
                                    ticks: {
                                        display: true,
                                        callback: function (value) {
                                            return value;
                                        },
                                        font: function (context) {
                                            let date = moment(startDate);
                                            let finalDate = moment(
                                                `${date.month() + 1}-${context.tick.value
                                                }-${date.year()}`,
                                                "MM-DD-YYYY"
                                            );
                                            if (
                                                moment(finalDate, "l").format("ddd") === "Sat" ||
                                                moment(finalDate, "l").format("ddd") === "Sun"
                                            ) {
                                                if (context.tick.value != "0") {
                                                    return {
                                                        weight: "bolder",
                                                    };
                                                }
                                            } else {
                                                return {};
                                            }
                                        },
                                    },
                                };
                                setchartOptions(opt);
                                setDateRange({
                                    start: startDate,
                                    end: endDate,
                                });
                                setSelectedMonth(parseInt(e.target.value));
                            }}
                        >
                            {month?.map((item, index) => (
                                <option key={item} value={index}>
                                    {item}
                                </option>
                            ))}
                        </Form.Select>
                    </Col>
                    <Col xl={3}>
                        <div className="from-groups no-border-break">
                            <MultiSelect
                                value={selectedCompetitor}
                                onChange={(e) => {
                                    hadnlePropertySelection(e);
                                }}
                                options={competitorDropDown}
                                optionLabel="name"
                                placeholder="Select Competitor"
                                maxSelectedLabels={0}
                            />
                        </div>
                    </Col>
                </Row>
            </Col>
            <div className="mt-3">
                {loading ? (
                    <Loader />
                ) : chartData ? (
                    <div
                        className="ForecastHorizontalChartInner mt-2"
                        id="revGlanceChart"
                        style={{ height: "600px" }}
                        value={JSON.stringify(jsonexportDataRef.current)}
                    >
                        {/* <Line data={chartData} options={chartOptions} /> */}
                        <canvas id="revGlance" />
                    </div>
                ) : (
                    <div className="text-center">
                        <p>No Data Found</p>
                    </div>
                )}
            </div>
            {showSingleDateSummaryModal && (
                <SingleDateSummary
                    showModal={showSingleDateSummaryModal}
                    closeModal={closeSingleDateSummaryModal}
                    asOfDate={para?.asOfDate}
                    selectedDate={singleDateSelectedDateRef.current}
                    // pickupDate={
                    //   selectPickupDate
                    //     ? selectPickupDate
                    //     : singleDatePickupDateRef.current
                    // }
                    propertyDetail={{ propertyCode: para?.propertyCode }}
                />
            )}
            {/* <Overlay
        show={ONTHEBOOKS.IsDisplay}
        target={ONTHEBOOKS.Target}
        placement={ONTHEBOOKS.Position}
      >
        <Popover
          id="popover-contained"
          className="SingleDateSummaryModalPopoverMain"
          style={{ zIndex: 999999 }}
        >
          <div className="horizontal-menu-option active">
            <SingleDateSummaryModal
              closeModal={closeSingleDate}
              asOfDate={para?.asOfDate}
              selectedDate={singleDateSelectedDateRef.current}
              // pickupDate={
              //   selectPickupDate
              //     ? selectPickupDate
              //     : singleDatePickupDateRef.current
              // }
              propertyDetail={{ propertyCode: para?.propertyCode }}
            />
          </div>
        </Popover>
      </Overlay> */}
        </div>
    );
};

export default RateShopChart;
