import React, { useEffect, useRef, useState } from "react";
import { apiResponse } from "../../../../utils/apiResponse";
import { apiCall } from "../../../../utils/axiosService";
import { API_URL } from "../../../../utils/apiRoutes";
import { Button, Col, Form, Table } from "react-bootstrap";
import { compName, month } from "../../../../utils/constant";
import NoteIcon from "../../../../Assets/Images/CRM/notes.svg";
import downloadIcon from "../../../../Assets/Images/Operation/downloadIcon.svg";
import { commonService } from "../../../../utils/commonService";
import Loader from "../../../Loader/Loader";
import DatePicker from "react-datepicker";
import moment from "moment";
import infoIcon from "../../../../Assets/Images/Action Icons/infoIcon..svg";
import WidgetDetailModal from "../../WidgetDetailModal";
import { useSelector } from "react-redux";
import WidgetNote from "../../WidgetNote";
import AddWidgetModal from "../../AddWidgetModal";
import { useParams } from "react-router-dom";
import emailIcon from "../../../../Assets/Images/Email.svg";
import Comments from "../Comments/Comment";

const Spider = ({
  setCompDataFromChild,
  rowIndex,
  colIndex,
  api,
  para,
  setting,
}) => {
  const yearsArr = [
    ...commonService.getPastYearForDropDown(10),
    ...commonService.getFutureYearForDropDown(10),
    new Date().getFullYear(),
  ]?.sort(function (a, b) {
    return a - b;
  });
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const tableData = [
    { month: "January", occ: 0, rms: 0, adr: 0, rev: 0, var: 0 },
    { month: "February", occ: 0, rms: 0, adr: 0, rev: 0, var: 0 },
    { month: "March", occ: 0, rms: 0, adr: 0, rev: 0, var: 0 },
    { month: "April", occ: 0, rms: 0, adr: 0, rev: 0, var: 0 },
    { month: "May", occ: 0, rms: 0, adr: 0, rev: 0, var: 0 },
    { month: "June", occ: 0, rms: 0, adr: 0, rev: 0, var: 0 },
    { month: "July", occ: 0, rms: 0, adr: 0, rev: 0, var: 0 },
    { month: "August", occ: 0, rms: 0, adr: 0, rev: 0, var: 0 },
    { month: "September", occ: 0, rms: 0, adr: 0, rev: 0, var: 0 },
    { month: "October", occ: 0, rms: 0, adr: 0, rev: 0, var: 0 },
    { month: "November", occ: 0, rms: 0, adr: 0, rev: 0, var: 0 },
    { month: "December", occ: 0, rms: 0, adr: 0, rev: 0, var: 0 },
  ];

  const [spiderData, setSpiderData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectAsOfDate, setSelectAsOfDate] = useState();
  const [widgetModal, setWidgetModal] = useState(false);
  const widgetDetailRef = useRef();
  const [showNoteSidebar, setShowNoteSidebar] = useState(false);
  const propertyDetailFromRTK = useSelector(
    (store) => store.headerProperty.propertyDetail
  );
  const defaultAsOfDateFromRTK = useSelector(
    (store) => store.headerProperty.defaultAsOfDate
  );
  const { snapshottitle, propertyCode, snapshotId } = useParams();
  const closeNoteSideBar = () => {
    setShowNoteSidebar(false);
  };

  const closeWidgetModal = () => {
    setWidgetModal(false);
  };
  let occ = 0,
    rms = 0,
    adr = 0,
    rev = 0,
    tableVar = 0;

  const getBudgetDetail = async () => {
    setLoading(true);
    let budgetDetailResponse = await apiCall(
      {
        method: "POST",
        url: API_URL.BASE_API_URL.SnapShot + API_URL.FORECAST.SPIDER_FORECAST,
        body: {
          AsOfDate: selectAsOfDate
            ? moment(selectAsOfDate).format("YYYY-MM-DD")
            : para?.asOfDate,
          year: selectedYear,
          propertyCode: para?.propertyCode,
        },
      },
      false
    );

    let response = apiResponse(false, budgetDetailResponse);
    if (response?.isValidate) {
      setSpiderData(response?.data?.data);
      setLoading(false);
    }

    if (!response?.isValidate) {
      setSpiderData(tableData);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (api === 0) {
      setSpiderData(tableData);
    } else {
      if (para?.asOfDate) {
        getBudgetDetail();
      }
    }
  }, [selectedYear, selectAsOfDate, para?.asOfDate]);
  const [tblids, settblids] = useState({
    spiderWidget: commonService.GenerateGUID(),
  });
  commonService.setBackColorPerByTDValue(
    "Green",
    "Red",
    `#${tblids.spiderWidget} .perbackground-rev`
  );

  return (
    <div className="widget-container">
      {api !== 0 && (
        <div className="gap-1 widget-container-menu">
          {/* <Button className="heading-menu-btns" type="button">
            <img
              src={NoteIcon}
              alt="menuIcon"
              onClick={() => {
                setShowNoteSidebar(true);
              }}
            />
          </Button> */}
          <Button className="heading-menu-btns" type="button" title="Send Mail">
            <img
              src={emailIcon}
              alt="emailIcon"
              onClick={() => {
                commonService.fnSendWidgetMail(
                  tblids.spiderWidget,
                  "System Forecast",
                  snapshotId,
                  defaultAsOfDateFromRTK,
                  "",
                  para?.widgetId
                );
              }}
            />
          </Button>
          <Button className="heading-menu-btns" type="button">
            <img
              src={infoIcon}
              alt="info"
              onClick={() => {
                widgetDetailRef.current = {
                  widgetdetails: "",
                  usernote: "",
                  compName: compName?.spiderForecast,
                  widgetId: para?.widgetId,
                };
                setWidgetModal(true);
              }}
            />
          </Button>
          <Button className="heading-menu-btns" type="button">
            <img
              src={downloadIcon}
              alt="menuIcon"
              import={tblids.spiderWidget}
              onClick={() =>
                commonService.fnExportInCSV(
                  tblids.spiderWidget,
                  // "System Forecast"
                  `${para?.propertyCode}-System Forecast-${moment(
                    new Date()
                  ).format("MMDDYYYY")}`
                )
              }
            />
          </Button>
        </div>
      )}
      <div className="ViewPropertySetupTable">
        <div className="stripped__table">
          <Table id={tblids.spiderWidget} responsive>
            <thead>
              <tr>
                <th>
                  <div>
                    <Form.Select
                      value={selectedYear}
                      onChange={(e) => {
                        setSelectedYear(e.target.value);
                      }}
                    >
                      <option value="">Select</option>
                      {yearsArr?.map((item, index) => (
                        <option key={index} value={item}>
                          {item}
                        </option>
                      ))}
                    </Form.Select>
                    <span style={{ display: "none" }}>{selectedYear}</span>
                  </div>
                </th>
                <th>
                  <DatePicker
                    // className="widget-table-header-input"
                    id="asOfDate"
                    selected={commonService.convertDateInTimezone(
                      selectAsOfDate
                    )}
                    onChange={(date) => {
                      setSelectAsOfDate(date);
                    }}
                    placeholderText="As Of Date"
                  />
                  <span style={{ display: "none" }}>{selectAsOfDate}</span>
                </th>
                <th colSpan={3} className="text-center">
                  System Forecast
                </th>
              </tr>
              <tr>
                <th>Month</th>
                <th>OCC</th>
                <th className="text-center">RMS</th>
                <th className="text-end">ADR</th>
                <th className="text-end">REV</th>
                {/* <th>VAR</th> */}
              </tr>
            </thead>
            <tbody>
              {api === 0 ? (
                spiderData?.map((item, index) => (
                  <React.Fragment key={index}>
                    <tr key={index}>
                      <td>{item?.month}</td>
                      <td>{item?.occ}%</td>
                      <td className="text-center">{item?.rms}</td>
                      <td className="text-end">${item?.adr}</td>
                      <td
                        className="text-end perbackground perbackground-rev"
                        value={item?.rev}
                      >
                        ${item?.rev}
                      </td>
                      {/* <td>{item?.var}%</td> */}
                    </tr>
                    {index === 11 && (
                      <tr>
                        <td className="boldTd">Total</td>
                        <td className="boldTd">0%</td>
                        <td className="boldTd text-center">0</td>
                        <td className="boldTd text-end">$0</td>
                        <td className="boldTd text-end">$0</td>
                        {/* <td className="boldTd">0%</td> */}
                      </tr>
                    )}
                  </React.Fragment>
                ))
              ) : loading ? (
                <tr style={{ height: "80px", position: "relative" }}>
                  <Loader />
                </tr>
              ) : (
                spiderData &&
                month?.map((item, index) => {
                  let findMonthData = spiderData?.filter(
                    (x) => x.month === item
                  );

                  occ =
                    occ +
                    (findMonthData?.[0]?.occ ? findMonthData?.[0]?.occ : 0);
                  rms =
                    rms +
                    (findMonthData?.[0]?.rms ? findMonthData?.[0]?.rms : 0);
                  adr =
                    adr +
                    (findMonthData?.[0]?.adr ? findMonthData?.[0]?.adr : 0);
                  rev =
                    rev +
                    (findMonthData?.[0]?.rev ? findMonthData?.[0]?.rev : 0);
                  tableVar =
                    tableVar +
                    (findMonthData?.[0]?.var ? findMonthData?.[0]?.var : 0);

                  return (
                    <React.Fragment key={index}>
                      <tr key={index} className="shadowOnHover">
                        <td>{item}</td>
                        <td>
                          {findMonthData?.[0]?.occ
                            ? findMonthData?.[0]?.occ
                            : 0}
                          %
                        </td>
                        <td className="text-center">
                          {findMonthData?.[0]?.rms
                            ? commonService.formateRoom(findMonthData?.[0]?.rms)
                            : 0}
                        </td>
                        <td className="text-end">
                          {findMonthData?.[0]?.adr
                            ? commonService.formateAmount(
                                findMonthData?.[0]?.adr
                              )
                            : commonService.formateAmount(0)}
                        </td>
                        <td
                          className="text-end perbackground perbackground-rev"
                          value={findMonthData?.[0]?.rev}
                        >
                          {findMonthData?.[0]?.rev
                            ? commonService.formateAmount(
                                findMonthData?.[0]?.rev
                              )
                            : commonService.formateAmount(0)}
                        </td>
                        {/* <td>
                          {findMonthData?.[0]?.var
                            ? findMonthData?.[0]?.var
                            : 0}
                          %
                        </td> */}
                      </tr>
                      {index === 11 && (
                        <tr className="shadowOnHover">
                          <td className="boldTd">Total</td>
                          <td className="boldTd">
                            {occ > 0 ? parseInt(occ / 12) : 0}%
                          </td>
                          <td className="boldTd text-center">{commonService.formateRoom(rms)}</td>
                          <td className="boldTd text-end">
                            {rev !== 0 && rms !== 0
                              ? commonService.formateAmount(parseInt(rev / rms))
                              : commonService.formateAmount(0)}
                          </td>
                          <td className="boldTd text-end">
                            {commonService.formateAmount(rev)}
                          </td>
                          {/* <td className="boldTd">
                            {tableVar > 0 ? parseInt(tableVar / 12) : 0}%
                          </td> */}
                        </tr>
                      )}
                    </React.Fragment>
                  );
                })
              )}
            </tbody>
          </Table>
        </div>
      </div>
      {api !== 0 && <Comments widgetDetails={para} editorId={"Spider"} />}

      {/* {showNoteSidebar && (
        <WidgetNote
          show={showNoteSidebar}
          handleClose={closeNoteSideBar}
          widgetId={para?.widgetId}
          propertyId={propertyDetailFromRTK?.propertyid}
          rowTitle={para?.rowTitle}
        />
      )} */}
      {showNoteSidebar && (
        <AddWidgetModal
          showModal={showNoteSidebar}
          closeModal={closeNoteSideBar}
          widgetId={para?.widgetId}
          propertyId={propertyDetailFromRTK?.propertyid}
          rowTitle={para?.rowTitle}
        />
      )}
      {widgetModal && (
        <WidgetDetailModal
          showModal={widgetModal}
          closeModal={closeWidgetModal}
          data={widgetDetailRef.current}
        />
      )}
    </div>
  );
};

export default Spider;
