import React from 'react'
import tentIcon from "../../Assets/Images/Revenue-management/circus-tent.svg";

function RmToolEventCard(props) {
    const {eventName,eventBg,showModal} = props 
  return (
    <div className='RmToolEventCardMain' style={{background: eventBg}} onClick={showModal}>
        <img src={tentIcon} alt="tentIcon" />
        <p>{eventName}</p>
    </div>
  )
}

export default RmToolEventCard