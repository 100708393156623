import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Budget from "./ListingComponent/AnnualSummary/Budget";
import NetStly from "./ListingComponent/AnnualSummary/NetStly";
import OnTheBook from "./ListingComponent/AnnualSummary/OnTheBook";
import TotalLy from "./ListingComponent/AnnualSummary/TotalLy";
import OneDay from "./ListingComponent/PickUp/OneDay";
import OneDayForecastChange from "./ListingComponent/PickUp/OneDayForecastChange";
import SevenDay from "./ListingComponent/PickUp/SevenDay";
import SevenDayForecastChange from "./ListingComponent/PickUp/SevenDayForecastChange";
import Group from "./ListingComponent/Group/Group";
import GroupLastYear from "./ListingComponent/Group/GroupLastYear";
import Transient from "./ListingComponent/Group/Transient";
import TransientLastYearGroup from "./ListingComponent/Group/TransientLastYear";
import ORG from "./ListingComponent/DailySummary/ORG";
import PickupFrom from "./ListingComponent/DailySummary/PickupFrom";
import ReservationActivity from "./ListingComponent/ReservationActivity/ReservationActivity";
import HeatMap from "./ListingComponent/HeatMap/HeatMap";
import WidgetBudgetGraph from "./ListingComponent/WidgetBudget/WidgetBudgetGraph";
import Spider from "./ListingComponent/Forecast/Spider";
import Rms from "./ListingComponent/Forecast/Rms";
import User from "./ListingComponent/Forecast/User";
import ChoiceRes from "./ListingComponent/ChoiceRes/ChoiceRes";
import MstReservation from "./ListingComponent/MstReservation/MstReservation";
import DailyDataTransaction from "./ListingComponent/DailyDataTransaction/DailyDataTransaction";
import EMAForecast from "./ListingComponent/Forecast/EMAForecast";
import AnnualSummaryChart from "./ListingComponent/AnnualSummary/AnnualSummaryChart";
import ForecastChart from "./ListingComponent/Forecast/ForecastChart";
import PickupChart from "./ListingComponent/PickUp/PickupChart";
import GroupChart from "./ListingComponent/Group/GroupChart";
import BookinPaceChart from "./ListingComponent/BookingPace/BookinPaceChart";
import MarketSegmentChart from "./ListingComponent/MarketSegment/MarketSegmentChart";
import ChoiceOccupancy from "./ListingComponent/WidgetModule/ChoiceOccupancy";
import HotelKeyReservation from "./ListingComponent/WidgetModule/HotelKeyReservation";
import HotelKeyOccupancySnapshot from "./ListingComponent/WidgetModule/HotelKeyOccupancySnapshot";
import BestWesternGuestName from "./ListingComponent/WidgetModule/BestWesternGuestName";
import BestWesternReservationAgent from "./ListingComponent/WidgetModule/BestWesternReservationAgent";
import BestWesternReservationForecast from "./ListingComponent/WidgetModule/BestWesternReservationForecast";
import BestWesternRoomMaintaince from "./ListingComponent/WidgetModule/BestWesternRoomMaintaince";
import ChoiceCancelationSummaryReport from "./ListingComponent/WidgetModule/ChoiceCancelationSummaryReport";
import AdrVsRateChart from "./ListingComponent/Chart/AdrVsRateChart";
import WidgetPickup from "./ListingComponent/WidgetModule/WidgetPickup";
import BookingPaceTable from "./ListingComponent/BookingPace/BookingPaceTable";
import AnnualSummary from "./ListingComponent/AnnualSummary/AnnualSummary";
import ChoiceBusinessOnTheBook from "./ListingComponent/WidgetModule/ChoiceBusinessOnTheBook";
import ChoiceRevenue from "./ListingComponent/WidgetModule/ChoiceRevenue";
import DayByDay from "./ListingComponent/MarketSegment/DayByDay";
import RoomType from "./ListingComponent/MarketSegment/RoomType";
import RatePlanDayByDay from "./ListingComponent/RatePlan/DayByDay";
import DynamicPickup from "./ListingComponent/PickUp/DynamicPickup";
import LosDayOfWeek from "./ListingComponent/ReservationActivity/LosDayOfWeek";
import Top10Chart from "./ListingComponent/RatePlan/Top10Chart";
import DayByDayGroup from "./ListingComponent/MarketSegment/Group";
import SevenDayBookingReservation from "./ListingComponent/PickUp/SevenDayBookingReservation";
import SevenDayBookingExpected from "./ListingComponent/PickUp/SevenDayBookingExpected";
import SevenDayBookingPace from "./ListingComponent/PickUp/SevenDayBookingPace";
import SevenWeekBookingPace from "./ListingComponent/PickUp/SevenWeekBookingPace";
import LengthOfStay from "./ListingComponent/MarketSegment/LengthOfStay";
import EmaForecastV2 from "./ListingComponent/Forecast/EmaForecastV2";
import EmaForecastV3 from "./ListingComponent/Forecast/EmaForecastV3";
import ForecastCommon from "./ListingComponent/Forecast/ForecastCommon";
import PickupCommon from "./ListingComponent/PickUp/PickupCommon";
import GroupCommon from "./ListingComponent/Group/GroupCommon";
import RevYoYChart from "./ListingComponent/Chart/RevYoYChart";
import { compName } from "../../utils/constant";
import SegmentDrillDown from "./ListingComponent/SegmentDrilldown/SegmentDrillDown";
import CyCancellationBookingRevLoss from "./ListingComponent/Cancellation/CyCancellationBookingRevLoss";
import StlyCancellationBookingRevLoss from "./ListingComponent/Cancellation/StlyCancellationBookingRevLoss";
import LyCancellationBookingRevLoss from "./ListingComponent/Cancellation/LyCancellationBookingRevLoss";
import AnnualCancellationSummary from "./ListingComponent/Cancellation/AnnualCancellationSummary";
import Cy2CancellationBookingRevLoss from "./ListingComponent/Cancellation/Cy2CancellationBookingRevLoss";
import MonthlyCancellationPace from "./ListingComponent/Cancellation/MonthlyCancellationPace";
import CancellationDayByDay from "./ListingComponent/Cancellation/CancellationDayByDay";
import Top50ByRevenue from "./ListingComponent/General/Top50ByRevenue";
import Account from "./ListingComponent/Accounts/Account";
import LastYearAndForecast from "./ListingComponent/LastYearAndForecast/LastYearAndForecast";
import { apiCall } from "../../utils/axiosService";
import { API_URL } from "../../utils/apiRoutes";
import { apiResponse } from "../../utils/apiResponse";
import NinentyDaySegment from "./ListingComponent/RevPak/NinentyDaySegment";
import NinentyDayOutLook from "./ListingComponent/RevPak/NinentyDayOutLook";
import DailyData from "./ListingComponent/Email/DailyData";
import WeeklyData from "./ListingComponent/Email/WeeklyData";
import MonthlyData from "./ListingComponent/Email/MonthlyData";
import RunningStar from "./ListingComponent/RevPak/RunningStar";
import WeeklyStar from "./ListingComponent/RevPak/WeeklyStar";
import StrAnalysis from "./ListingComponent/RevPak/StrAnalysis";
import StrDayByDay from "./ListingComponent/STR/DayByDay";
import MonthWiseDrillDown from "./ListingComponent/SegmentDrilldown/MonthWiseDrillDown";
import PastDynamicPickup from "./ListingComponent/PickUp/PastDynamicPickup";
import BookingPaceComparisonChart from "./ListingComponent/BookingPace/BookingPaceComparisonChart";
import ThirtyDayPickup from "./ListingComponent/PickUp/ThirtyDayPickup";
import BookingPaceComparison from "./ListingComponent/BookingPace/BookingPaceComparison";
import CommonComment from "./ListingComponent/Comments/CommonComment";
import ForecastUsingNinetyDayPickup from "./ListingComponent/PickUp/ForecastUsingNinetyDayPickup";
import RevenueByCompany from "./ListingComponent/CrmSnapshot/RevenueByCompany";
import RevenueByMarketSegment from "./ListingComponent/CrmSnapshot/RevenueByMarketSegment";
import RevenueByRoomType from "./ListingComponent/CrmSnapshot/RevenueByRoomType";
import ForecastVsOTBChart from "./ListingComponent/Forecast/ForecastVsOTBChart";
import PerityCheck from "./ListingComponent/RateShop/PerityCheck";
import ChannelMonthlyDrilldown from "./ListingComponent/SegmentDrilldown/ChannelMonthlyDrilldown";
import Channel from "./ListingComponent/MarketSegment/Channel";
import CrmCancellationActivity from "./ListingComponent/CrmSnapshot/CancellationActivity";
import CrmCompanyMonthlyDrilldown from "./ListingComponent/CrmSnapshot/CompanyMonthlyDrilldown";
import CrmTodayArrivalDeparture from "./ListingComponent/CrmSnapshot/TodayArrivalDeparture";
import BenchMarking from "./ListingComponent/RateShop/BenchMarking";
import Rateshop from "./ListingComponent/Tools/Rateshop";
import Reminder from "./ListingComponent/Tools/Reminder";
import Event from "./ListingComponent/Tools/Event";
import RateIq from "./ListingComponent/Tools/RateIq";
import DisplacementCalculator from "./ListingComponent/Tools/DisplacementCalculator";
import ToolPerityCheck from "./ListingComponent/Tools/PerityCheck";
import HeatMapDaywise from "./ListingComponent/HeatMap/HeatMapDaywise";
import EventStretegy from "./ListingComponent/Event/EventStretegy";
import PastForecastChart from "./ListingComponent/Forecast/PastForecastChart";
import WeatherWidget from "./ListingComponent/General/WeatherWidget";
import BookingPaceForecastChart from "./ListingComponent/BookingPace/BookingPaceForecastChart";
import BookingPaceMonthlyForecast from "./ListingComponent/BookingPace/BookingPaceMonthlyForecast";
import DynamicForecastUsingNinetyDayPickup from "./ListingComponent/PickUp/DynamicForecastUsingNinetyDayPickup";
import RateShopChart from "./ListingComponent/RateShop/RateShopChart";
import SeasonalityAnalysis from "./ListingComponent/AnnualSummary/SeasonalityAnalysis";
import ReservationAnalyticsChart from "./ListingComponent/MarketSegment/ReservationAnalyticsChart";
import MonthlyBookingWindow from "./ListingComponent/BookingWindow/MonthlyBookingWindow";
import DayByDayBookingWindow from "./ListingComponent/BookingWindow/DayByDayBookingWindow";
import GoogleRatings from "./ListingComponent/Analytics/GoogleRatings";
import Reviews from "./ListingComponent/Analytics/Reviews";
import Rates from "./ListingComponent/Analytics/Rates";
import RatesCount from "./ListingComponent/Analytics/RatesCount";

const WidgetDetailModal = ({ showModal, closeModal, data }) => {
  const [widgetData, setWidgetData] = useState(null);
  let componentObj = {
    [compName?.budget]: Budget,
    [compName?.netStly]: NetStly,
    [compName?.onTheBook]: OnTheBook,
    [compName?.reservationActivity]: ReservationActivity,
    [compName?.totalLy]: TotalLy,
    [compName?.oneDay]: OneDay,
    [compName?.oneDayForecastChange]: OneDayForecastChange,
    [compName?.sevenDay]: SevenDay,
    [compName?.sevenDayForecastChange]: SevenDayForecastChange,
    [compName?.group]: Group,
    [compName?.groupLastYear]: GroupLastYear,
    [compName?.transient]: Transient,
    [compName?.transientLastYear]: TransientLastYearGroup,
    [compName?.org]: ORG,
    [compName?.pickupFrom]: PickupFrom,
    [compName?.reservationActivity]: ReservationActivity,
    [compName?.heatMap]: HeatMap,
    [compName?.widgetBudgetGraph]: WidgetBudgetGraph,
    [compName?.spiderForecast]: Spider,
    [compName?.rmsForecast]: Rms,
    [compName?.userForecast]: User,
    [compName?.choiceRes]: ChoiceRes,
    [compName?.mstReservation]: MstReservation,
    [compName?.dailyDataTransaction]: DailyDataTransaction,
    [compName?.emaForecast]: EMAForecast,
    [compName?.annualSummaryChart]: AnnualSummaryChart,
    [compName?.forecastChart]: ForecastChart,
    [compName?.pickupChart]: PickupChart,
    [compName?.groupChart]: GroupChart,
    [compName?.bookingPaceChart]: BookinPaceChart,
    [compName?.marketSegmentChart]: MarketSegmentChart,
    [compName?.widgetChoiceOccupancy]: ChoiceOccupancy,
    [compName?.widgetHotelKeyReservation]: HotelKeyReservation,
    [compName?.widgetHotelKeyOccupancySnapshot]: HotelKeyOccupancySnapshot,
    [compName?.widgetBestWesternGuestName]: BestWesternGuestName,
    [compName?.widgetBestWesternReservationAgent]: BestWesternReservationAgent,
    [compName?.widgetBestWesternReservationForecast]:
      BestWesternReservationForecast,
    [compName?.widgetBestWesternRoomMaintaince]: BestWesternRoomMaintaince,
    [compName?.widgetChoiceCancelationSummary]: ChoiceCancelationSummaryReport,
    [compName?.adrVsRateChart]: AdrVsRateChart,
    [compName?.widgetPickup]: WidgetPickup,
    [compName?.bookingPaceTable]: BookingPaceTable,
    [compName?.annualSummary]: AnnualSummary,
    [compName?.widgetChoiceBusinessOTB]: ChoiceBusinessOnTheBook,
    [compName?.widgetChoiceRevenue]: ChoiceRevenue,
    [compName?.marketSegmentDayByDay]: DayByDay,
    [compName?.marketSegmentRoomType]: RoomType,
    [compName?.ratePlanDayByDay]: RatePlanDayByDay,
    [compName?.dynamicPickup]: DynamicPickup,
    [compName?.losDayOfWeek]: LosDayOfWeek,
    [compName?.top10Chart]: Top10Chart,
    [compName?.dayByDayGroup]: DayByDayGroup,
    [compName?.sevenDayBookingReservation]: SevenDayBookingReservation,
    [compName?.sevenDayBookingExpected]: SevenDayBookingExpected,
    [compName?.sevenDayBookingPace]: SevenDayBookingPace,
    [compName?.sevenWeekBookingPace]: SevenWeekBookingPace,
    [compName?.lengthOfStay]: LengthOfStay,
    [compName?.emaForecastV2]: EmaForecastV2,
    [compName?.emaForecastV3]: EmaForecastV3,
    [compName?.forecastCommon]: ForecastCommon,
    [compName?.pickupCommon]: PickupCommon,
    [compName?.groupCommon]: GroupCommon,
    [compName?.revYoYChart]: RevYoYChart,
    [compName?.segmentDrillDown]: SegmentDrillDown,
    [compName?.cyCancellationBookingRevLoss]: CyCancellationBookingRevLoss,
    [compName?.stlyCancellationBookingRevLoss]: StlyCancellationBookingRevLoss,
    [compName?.lyCancellationBookingRevLoss]: LyCancellationBookingRevLoss,
    [compName?.annSummaryCancellation]: AnnualCancellationSummary,
    [compName?.cy2CancellationBookingRevLoss]: Cy2CancellationBookingRevLoss,
    [compName?.monthlyCancellationPace]: MonthlyCancellationPace,
    [compName?.cancellationDayByDay]: CancellationDayByDay,
    [compName?.top50ByRevenue]: Top50ByRevenue,
    [compName?.account]: Account,
    [compName?.lastYearAndForecast]: LastYearAndForecast,
    [compName?.ninetyDaySegment]: NinentyDaySegment,
    [compName?.ninetyDayOutlook]: NinentyDayOutLook,
    [compName?.dailyDataForEmail]: DailyData,
    [compName?.weeklyDataForEmail]: WeeklyData,
    [compName?.monthlyDataForEmail]: MonthlyData,
    [compName?.runningStrReport]: RunningStar,
    [compName?.weeklyStrReport]: WeeklyStar,
    [compName?.strAnalysis]: StrAnalysis,
    [compName?.strDayByDay]: StrDayByDay,
    [compName?.monthlySegmentDrilldown]: MonthWiseDrillDown,
    [compName?.pastDynamicPickup]: PastDynamicPickup,
    [compName?.bookingPaceComparisonChart]: BookingPaceComparisonChart,
    [compName?.thirtyDayPickup]: ThirtyDayPickup,
    [compName?.bookingPaceComparison]: BookingPaceComparison,
    [compName?.commentWidget]: CommonComment,
    [compName?.forecastUsing90DayPickup]: ForecastUsingNinetyDayPickup,
    [compName?.crmRevnueByCompany]: RevenueByCompany,
    [compName?.crmRevenueByRoomType]: RevenueByRoomType,
    [compName?.crmRevenueByMarketSegment]: RevenueByMarketSegment,
    [compName?.forecastvsOtbChart]: ForecastVsOTBChart,
    [compName?.perityCheck]: PerityCheck,
    [compName?.channelMonthlyDrilldown]: ChannelMonthlyDrilldown,
    [compName?.dayByDayChannel]: Channel,
    [compName?.crmCancellationActivity]: CrmCancellationActivity,
    [compName?.crmCompanyDrilldown]: CrmCompanyMonthlyDrilldown,
    [compName?.crmArrialDeparture]: CrmTodayArrivalDeparture,
    [compName?.rateShopBenchmarking]: BenchMarking,
    [compName?.toolRateShop]: Rateshop,
    [compName?.toolReminder]: Reminder,
    [compName?.toolEvent]: Event,
    [compName?.toolRateIq]: RateIq,
    [compName?.toolDisplacementCalculator]: DisplacementCalculator,
    [compName?.toolPerityCheck]: ToolPerityCheck,
    [compName?.heatMapdaywise]: HeatMapDaywise,
    [compName?.eventStretegyWidget]: EventStretegy,
    [compName?.pastForecastChart]: PastForecastChart,
    [compName?.weatherWidget]: WeatherWidget,
    [compName?.bookingPaceForecastComparison]: BookingPaceForecastChart,
    [compName?.bookingPaceMonthlyForecast]: BookingPaceMonthlyForecast,
    [compName?.dynamicForecastUsingNinentyDayPickup]:
      DynamicForecastUsingNinetyDayPickup,
    [compName?.rateShopChart]: RateShopChart,
    [compName?.seasonalityAnalysis]: SeasonalityAnalysis,
    [compName?.marketSegmentWiseReservation]: ReservationAnalyticsChart,
    [compName?.monthlyBookingWindow]: MonthlyBookingWindow,
    [compName?.dayByDayBookingWindow]: DayByDayBookingWindow,
    [compName?.googleRatings]: GoogleRatings,
    [compName?.analyticsReview]: Reviews,
    [compName?.analyticsRates]: Rates,
    [compName?.analyticsRatesCount]: RatesCount,
  };

  const getWidgetDetail = async () => {
    let widgetDetail = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.Configuration +
          API_URL.WIDGET.GET_BY_PRIMARY_KEY,
        body: {
          id: data?.widgetId,
        },
      },
      false
    );

    let response = apiResponse(false, widgetDetail);

    if (response?.isValidate) {
      setWidgetData(response?.data?.data);
    }

    if (!response?.isValidate) {
      setWidgetData(null);
    }
  };

  useEffect(() => {
    getWidgetDetail();
  }, []);
  return (
    <>
      <Modal
        className="DeviceDetailModalMain"
        size="xl"
        centered
        show={showModal}
        onHide={closeModal}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Widget Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="widgetDetailModal">
          {widgetData && (
            <>
              <div
                className="DeviceDetailModalMain-inputs"
                dangerouslySetInnerHTML={{
                  __html: widgetData?.[0]?.widgetdetails,
                }}
              />

              <div
                className="DeviceDetailModalMain-inputs"
                dangerouslySetInnerHTML={{ __html: widgetData?.[0]?.usernote }}
              />
              <div>
                {React.createElement(componentObj[data?.compName], {
                  // setCompDataFromChild: setCompDataFromChild,
                  // rowIndex: index,
                  // colIndex: compIndex,
                  api: 0,
                  setting: data?.setting,
                })}
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default WidgetDetailModal;
