import { apiResponse } from "../../../utils/apiResponse";
import { apiCall } from "../../../utils/axiosService";

export const AccountListCommon = async (payload, formtype) => {
  let Result = {
    data: "",
    isValidate: false,
  };
  let accountListResponse = await apiCall(
    {
      method: payload?.method,
      url: payload?.url,
      body: payload?.body,
    },
    formtype
  );
  let response = apiResponse(formtype, accountListResponse);
  if (response?.isValidate) {
    Result = {
      data: response?.data?.data,
      isValidate: true,
    };
    return Result;
  } else {
    // errorToastMessage("Get Contact List Error ");
  }
};
