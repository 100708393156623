import AsideCRM from "./AsideCRM";
import { Navigate, Route, Routes } from "react-router-dom";
import Header from "../Header/Header";
import React, { useState } from "react";
import Loader from "../Loader/Loader";
import { commonService } from "../../utils/commonService";
import DashboardWeeklyReport from "./Report/DashboardWeeklyReport";
import ActiviesReport from "./Report/ActiviesReport";
import TraceReport from "./Report/TraceReport";
import MonthlyRollupReport from "./Report/MonthlyRollupReport";
import RFPGridReport from "./Report/RFPGridReport";
import LNRGridReport from "./Report/LNRGridReport";
import GroupGridReport from "./Report/GroupGridReport";
import MeetingCateringGrid from "./Report/MeetingCateringGridReport";
import SalesFunnelGridReport from "./Report/SalesFunnelGridReport";
import LostBussinessGridReport from "./Report/LostBussinessGridReport";
import OpportunityBySourceReport from "./Report/OpportunityBySourceReport";
import MeetingCateringbyDateReport from "./Report/MeetingCateringbyDateReport";
import ActivityGoalReport from "./Report/ActivityGoalReport";
import MarketingPlanReport from "./Report/MarketingPlanReport";
import GroupRoomCount from "./Report/GroupRoomCountReport";
import MeetingRoomBookReport from "./Report/MeetingRoomBookReport";
import GroupPaceReport from "./Report/GroupPaceReport";
import MeetingPaceReport from "./Report/MeetingPaceReport";
import LeadReport from "./Report/LeadReport";
import WeeklyReport from "./Report/WeeklyReport";
import { useSelector } from "react-redux";
const CrmRateIq = React.lazy(() => import("./Tools/CrmRateIq"));
const CrmEvents = React.lazy(() => import("./Tools/CrmEvents"));
const CrmEventsListingPage = React.lazy(() =>
    import("./Tools/CrmEventListingPage")
);
const CrmEventsMapView = React.lazy(() => import("./Tools/CrmEventMapView"));

const LNRDetail = React.lazy(() => import("./Opportunity/LNRDetail"));
const GroupDetail = React.lazy(() => import("./Opportunity/GroupDetail"));
const CrmSnapshot = React.lazy(() => import("./CrmSnapshot"));
const RFPDetail = React.lazy(() => import("./Opportunity/RFPDetail"));
const CrmRateshop = React.lazy(() => import("././Tools/CrmRateshop"));
const CrmRateshopGraphView = React.lazy(() =>
    import("././Tools/CrmRateshopGraphView")
);
const CrmRateshopTableView = React.lazy(() =>
    import("././Tools/CrmRateshopTableView")
);
const CRMActivityView = React.lazy(() =>
    import("./Activities/CRMActivityView")
);
const ToolsDisplacementCalc = React.lazy(() =>
    import("./Tools/ToolsDisplacementCalc")
);
const CRMMeetingAndCatering = React.lazy(() =>
    import("././Opportunity/CRMMeetingAndCatering")
);
const CRMGeneralTermSetup = React.lazy(() =>
    import("../Configuration/GeneralTermSetup")
);
const ActivityInformation = React.lazy(() =>
    import("../CRM/Activities/CRMActivityView")
);

const CRMGroupGridView = React.lazy(() =>
    import("./Opportunity/CRMGroupGridView")
);
const CRMGroup = React.lazy(() => import("././Opportunity/CRMGroup"));
const CRMLNRGridView = React.lazy(() =>
    import("././Opportunity/CRMLNRGridView")
);
const CRMLNR = React.lazy(() => import("././Opportunity/CRMLNR"));
const CRMRFPGridView = React.lazy(() =>
    import("././Opportunity/CRMRFPGridView")
);
const CRMMeetingAndCateringGridView = React.lazy(() =>
    import("././Opportunity/CRMMeetingAndCateringGrid")
);

const CRMRFP = React.lazy(() => import("./././Opportunity/CRMRFP"));
const CRMActivities = React.lazy(() => import("./Activities/CRMActivityTable"));
const CRMAccountRules = React.lazy(() =>
    import("././AccountRules/CRMAccountRules")
);
const CRMReportDetail = React.lazy(() => import("./CRMReportDetail"));
const CRMUserManagement = React.lazy(() =>
    import("././CrmUserManagement/CRMUserMangement")
);
const CRMRoleManagement = React.lazy(() =>
    import("././CrmRoleMangement/CRMRoleManagement")
);
const HotelInformation = React.lazy(() =>
    import("../CRM/Hotels/HotelInformation")
);
const CRMAccounts = React.lazy(() => import("../CRM/Account/CRMAccounts"));
const AddHotels = React.lazy(() => import("../CRM/Hotels/AddHotels"));
const CRMMailSettings = React.lazy(() => import("./CRMMailSettings"));
const CRMHotels = React.lazy(() => import("../CRM/Hotels/CRMHotels"));
const CRMPortfolios = React.lazy(() => import("./CRMPortfolios"));
const CRMSettings = React.lazy(() => import("./CRMSettings"));
const CRMReports = React.lazy(() => import("./Report/CRMReports"));
const CRMContacts = React.lazy(() => import("././Contacts/CRMContacts"));
const CRMContactsInformation = React.lazy(() =>
    import("././Contacts/CRMContactsInformation")
);
const CRMAccountInformation = React.lazy(() =>
    import("../CRM/Account/CRMAccountInformation")
);
const ProspectWestModernLocations = React.lazy(() =>
    import("./CrmProspects/ProspectWestModernLocations")
);
const DashboardLast30DaysActivity = React.lazy(() =>
    import("./DashboardLast30DaysActivity")
);
const CrmDashboard = React.lazy(() => import("./CRMDashboard"));
const Prospect = React.lazy(() => import("./CrmProspects/ProspectTableView"));
const DashboardLast7DaysActivity = React.lazy(() =>
    import("./DashboardLast7DaysActivity")
);
const MeetingCateringDetail = React.lazy(() =>
    import("./Opportunity/MeetingAndCateringDetail")
);
const BusinessIntelligence = React.lazy(() =>
    import("../RevenueManagement/BusinessIntelligence")
);
const CustomizeComponent = React.lazy(() =>
    import("../RevenueManagement/CustomizeComponent")
);
const CrmReminder = React.lazy(() => import("./Tools/CrmReminder"));
const CrmDisplacementCalc = React.lazy(() =>
    import("./Tools/CrmDisplacementCalc")
);

const ZoomInfo = React.lazy(() => import("./Opportunity/ZoomInfo"));

function LayoutCRM() {
    const token = commonService.getItem("token");
    const sidebarOpen = useSelector(
        (store) => store.headerProperty.isSidebarOpen
    );
    return (
        <div className={`mainRoot ${sidebarOpen ? "full-menu" : "icon-toggle-menu"}`} >
            <Header />
            <AsideCRM />
            <main
                className={`crm-page-body container-data-load`}
            >
                <Routes>
                    <Route
                        path="/dashboard"
                        element={
                            token ? (
                                <React.Suspense fallback={<Loader />}>
                                    <CrmDashboard />
                                </React.Suspense>
                            ) : (
                                <Navigate to="/" replace />
                            )
                        }
                    />
                    <Route
                        path="/last-7days-activity"
                        element={
                            token ? (
                                <React.Suspense fallback={<Loader />}>
                                    <DashboardLast7DaysActivity />
                                </React.Suspense>
                            ) : (
                                <Navigate to="/" replace />
                            )
                        }
                    />
                    <Route
                        path="/last-days-activity"
                        element={
                            token ? (
                                <React.Suspense fallback={<Loader />}>
                                    <DashboardLast30DaysActivity />
                                </React.Suspense>
                            ) : (
                                <Navigate to="/" replace />
                            )
                        }
                    />
                    <Route
                        path="/prospect/:prospectId"
                        element={
                            token ? (
                                <React.Suspense fallback={<Loader />}>
                                    <ProspectWestModernLocations />
                                </React.Suspense>
                            ) : (
                                <Navigate to="/" replace />
                            )
                        }
                    />
                    <Route
                        path="/prospect"
                        element={
                            token ? (
                                <React.Suspense fallback={<Loader />}>
                                    <Prospect />
                                </React.Suspense>
                            ) : (
                                <Navigate to="/" replace />
                            )
                        }
                    />
                    <Route
                        path="/accounts"
                        element={
                            token ? (
                                <React.Suspense fallback={<Loader />}>
                                    <CRMAccounts />
                                </React.Suspense>
                            ) : (
                                <Navigate to="/" replace />
                            )
                        }
                    />
                    <Route
                        path="/accounts/account-information/:id"
                        element={
                            token ? (
                                <React.Suspense fallback={<Loader />}>
                                    <CRMAccountInformation />
                                </React.Suspense>
                            ) : (
                                <Navigate to="/" replace />
                            )
                        }
                    />
                    <Route
                        path="/contacts"
                        element={
                            token ? (
                                <React.Suspense fallback={<Loader />}>
                                    <CRMContacts />
                                </React.Suspense>
                            ) : (
                                <Navigate to="/" replace />
                            )
                        }
                    />
                    <Route
                        path="/contacts/CRM-Contact-Information/:contactId"
                        element={
                            token ? (
                                <React.Suspense fallback={<Loader />}>
                                    <CRMContactsInformation />
                                </React.Suspense>
                            ) : (
                                <Navigate to="/" replace />
                            )
                        }
                    />
                    <Route
                        path="/displacement-calculator"
                        element={
                            token ? (
                                <React.Suspense fallback={<Loader />}>
                                    <ToolsDisplacementCalc />
                                </React.Suspense>
                            ) : (
                                <Navigate to="/" replace />
                            )
                        }
                    />
                    <Route
                        path="/reports/dashboard-weekly-report"
                        element={
                            token ? (
                                <React.Suspense fallback={<Loader />}>
                                    <DashboardWeeklyReport title="Dashboard Weekly Report" />
                                </React.Suspense>
                            ) : (
                                <Navigate to="/" replace />
                            )
                        }
                    />

                    <Route
                        path="/reports/activies-Report"
                        element={
                            token ? (
                                <React.Suspense fallback={<Loader />}>
                                    <ActiviesReport title="Activities Report" />
                                </React.Suspense>
                            ) : (
                                <Navigate to="/" replace />
                            )
                        }
                    />
                    <Route
                        path="/reports/trace-report"
                        element={
                            token ? (
                                <React.Suspense fallback={<Loader />}>
                                    <TraceReport title="Trace Report" />
                                </React.Suspense>
                            ) : (
                                <Navigate to="/" replace />
                            )
                        }
                    />
                    <Route
                        path="/reports/monthly-rollup-report"
                        element={
                            token ? (
                                <React.Suspense fallback={<Loader />}>
                                    <MonthlyRollupReport title="Monthly Rollup Report" />
                                </React.Suspense>
                            ) : (
                                <Navigate to="/" replace />
                            )
                        }
                    />
                    <Route
                        path="/reports/rfp-grid-report"
                        element={
                            token ? (
                                <React.Suspense fallback={<Loader />}>
                                    <RFPGridReport title="RFP Grid Report" />
                                </React.Suspense>
                            ) : (
                                <Navigate to="/" replace />
                            )
                        }
                    />
                    <Route
                        path="/reports/lnr-grid-report"
                        element={
                            token ? (
                                <React.Suspense fallback={<Loader />}>
                                    <LNRGridReport title="LNR Grid Report" />
                                </React.Suspense>
                            ) : (
                                <Navigate to="/" replace />
                            )
                        }
                    />
                    <Route
                        path="/reports/group-grid-report"
                        element={
                            token ? (
                                <React.Suspense fallback={<Loader />}>
                                    <GroupGridReport title="Group Grid Report" />
                                </React.Suspense>
                            ) : (
                                <Navigate to="/" replace />
                            )
                        }
                    />
                    <Route
                        path="/reports/meeting-catering-grid-report"
                        element={
                            token ? (
                                <React.Suspense fallback={<Loader />}>
                                    <MeetingCateringGrid title="Meeting Catering Report" />
                                </React.Suspense>
                            ) : (
                                <Navigate to="/" replace />
                            )
                        }
                    />
                    <Route
                        path="/reports/sales-funnel-grid-report"
                        element={
                            token ? (
                                <React.Suspense fallback={<Loader />}>
                                    <SalesFunnelGridReport title="Sales Funnel Grid Report" />
                                </React.Suspense>
                            ) : (
                                <Navigate to="/" replace />
                            )
                        }
                    />
                    <Route
                        path="/reports/lost-bussiness-grid-report"
                        element={
                            token ? (
                                <React.Suspense fallback={<Loader />}>
                                    <LostBussinessGridReport title="Lost Bussiness Grid Report" />
                                </React.Suspense>
                            ) : (
                                <Navigate to="/" replace />
                            )
                        }
                    />
                    <Route
                        path="/reports/opportunity-by-source-report"
                        element={
                            token ? (
                                <React.Suspense fallback={<Loader />}>
                                    <OpportunityBySourceReport title="Opportunity by Source Report" />
                                </React.Suspense>
                            ) : (
                                <Navigate to="/" replace />
                            )
                        }
                    />
                    <Route
                        path="/reports/meeting-catering-by-date-report"
                        element={
                            token ? (
                                <React.Suspense fallback={<Loader />}>
                                    <MeetingCateringbyDateReport title="Meeting Catering by data Report" />
                                </React.Suspense>
                            ) : (
                                <Navigate to="/" replace />
                            )
                        }
                    />
                    <Route
                        path="/reports/activity-goal-report"
                        element={
                            token ? (
                                <React.Suspense fallback={<Loader />}>
                                    <ActivityGoalReport title="Activity Goals Report" />
                                </React.Suspense>
                            ) : (
                                <Navigate to="/" replace />
                            )
                        }
                    />
                    <Route
                        path="/reports/marketing-plan-report"
                        element={
                            token ? (
                                <React.Suspense fallback={<Loader />}>
                                    <MarketingPlanReport title="Marketing Plan Report" />
                                </React.Suspense>
                            ) : (
                                <Navigate to="/" replace />
                            )
                        }
                    />
                    <Route
                        path="/reports/group-room-count"
                        element={
                            token ? (
                                <React.Suspense fallback={<Loader />}>
                                    <GroupRoomCount title="Group Room Count(GRC)" />
                                </React.Suspense>
                            ) : (
                                <Navigate to="/" replace />
                            )
                        }
                    />
                    <Route
                        path="/reports/meeting-room-book-report"
                        element={
                            token ? (
                                <React.Suspense fallback={<Loader />}>
                                    <MeetingRoomBookReport title="Meeting Room Booked" />
                                </React.Suspense>
                            ) : (
                                <Navigate to="/" replace />
                            )
                        }
                    />
                    <Route
                        path="/reports/group-pace-report"
                        element={
                            token ? (
                                <React.Suspense fallback={<Loader />}>
                                    <GroupPaceReport title="Group Pace Report" />
                                </React.Suspense>
                            ) : (
                                <Navigate to="/" replace />
                            )
                        }
                    />
                    <Route
                        path="/reports/meeting-pace-report"
                        element={
                            token ? (
                                <React.Suspense fallback={<Loader />}>
                                    <MeetingPaceReport title="Meeting Pace Report" />
                                </React.Suspense>
                            ) : (
                                <Navigate to="/" replace />
                            )
                        }
                    />
                    <Route
                        path="/reports/lead-report"
                        element={
                            token ? (
                                <React.Suspense fallback={<Loader />}>
                                    <LeadReport title="Lead Report" />
                                </React.Suspense>
                            ) : (
                                <Navigate to="/" replace />
                            )
                        }
                    />
                    <Route
                        path="/reports/weekly-report"
                        element={
                            token ? (
                                <React.Suspense fallback={<Loader />}>
                                    <WeeklyReport title="Weekly Report" />
                                </React.Suspense>
                            ) : (
                                <Navigate to="/" replace />
                            )
                        }
                    />
                    <Route
                        path="/setup-settings"
                        element={
                            token ? (
                                <React.Suspense fallback={<Loader />}>
                                    <CRMHotels />
                                </React.Suspense>
                            ) : (
                                <Navigate to="/" replace />
                            )
                        }
                    />
                    <Route
                        path="/reports/report-detail"
                        element={
                            token ? (
                                <React.Suspense fallback={<Loader />}>
                                    <CRMReportDetail />
                                </React.Suspense>
                            ) : (
                                <Navigate to="/" replace />
                            )
                        }
                    />
                    <Route
                        path="/mail-settings"
                        element={
                            token ? (
                                <React.Suspense fallback={<Loader />}>
                                    <CRMMailSettings />
                                </React.Suspense>
                            ) : (
                                <Navigate to="/" replace />
                            )
                        }
                    />
                    <Route
                        path="/crm-portfolios"
                        element={
                            token ? (
                                <React.Suspense fallback={<Loader />}>
                                    <CRMPortfolios />
                                </React.Suspense>
                            ) : (
                                <Navigate to="/" replace />
                            )
                        }
                    />
                    <Route
                        path="/hotels"
                        element={
                            token ? (
                                <React.Suspense fallback={<Loader />}>
                                    <CRMHotels />
                                </React.Suspense>
                            ) : (
                                <Navigate to="/" replace />
                            )
                        }
                    />
                    <Route
                        path="/hotels/add-hotels/:id"
                        element={
                            token ? (
                                <React.Suspense fallback={<Loader />}>
                                    <AddHotels />
                                </React.Suspense>
                            ) : (
                                <Navigate to="/" replace />
                            )
                        }
                    />
                    <Route
                        path="/hotels/hotel-information/:id"
                        element={
                            token ? (
                                <React.Suspense fallback={<Loader />}>
                                    <HotelInformation />
                                </React.Suspense>
                            ) : (
                                <Navigate to="/" replace />
                            )
                        }
                    />
                    <Route
                        path="/crm-events"
                        element={
                            token ? (
                                <React.Suspense fallback={<Loader />}>
                                    <CrmEvents />
                                </React.Suspense>
                            ) : (
                                <Navigate to="/" replace />
                            )
                        }
                    />
                    <Route
                        path="/crm-events-listing-page"
                        element={
                            token ? (
                                <React.Suspense fallback={<Loader />}>
                                    <CrmEventsListingPage />
                                </React.Suspense>
                            ) : (
                                <Navigate to="/" replace />
                            )
                        }
                    />
                    <Route
                        path="/crm-events-map-view"
                        element={
                            token ? (
                                <React.Suspense fallback={<Loader />}>
                                    <CrmEventsMapView />
                                </React.Suspense>
                            ) : (
                                <Navigate to="/" replace />
                            )
                        }
                    />
                    <Route
                        path="/crm-rateiq"
                        element={
                            token ? (
                                <React.Suspense fallback={<Loader />}>
                                    <CrmRateIq />
                                </React.Suspense>
                            ) : (
                                <Navigate to="/" replace />
                            )
                        }
                    />
                    <Route
                        path="/rfp-detail/:id"
                        element={
                            token ? (
                                <React.Suspense fallback={<Loader />}>
                                    <RFPDetail />
                                </React.Suspense>
                            ) : (
                                <Navigate to="/" replace />
                            )
                        }
                    />
                    <Route
                        path="/lnr-detail/:id"
                        element={
                            token ? (
                                <React.Suspense fallback={<Loader />}>
                                    <LNRDetail />
                                </React.Suspense>
                            ) : (
                                <Navigate to="/" replace />
                            )
                        }
                    />
                    <Route
                        path="/group-detail/:id"
                        element={
                            token ? (
                                <React.Suspense fallback={<Loader />}>
                                    <GroupDetail />
                                </React.Suspense>
                            ) : (
                                <Navigate to="/" replace />
                            )
                        }
                    />
                    <Route
                        path="/meeting-catering-detail/:id"
                        element={
                            token ? (
                                <React.Suspense fallback={<Loader />}>
                                    <MeetingCateringDetail />
                                </React.Suspense>
                            ) : (
                                <Navigate to="/" replace />
                            )
                        }
                    />
                    <Route
                        path="/role-management"
                        element={
                            token ? (
                                <React.Suspense fallback={<Loader />}>
                                    <CRMRoleManagement />
                                </React.Suspense>
                            ) : (
                                <Navigate to="/" replace />
                            )
                        }
                    />
                    <Route
                        path="/user-management"
                        element={
                            token ? (
                                <React.Suspense fallback={<Loader />}>
                                    <CRMUserManagement />
                                </React.Suspense>
                            ) : (
                                <Navigate to="/" replace />
                            )
                        }
                    />
                    <Route
                        path="/account-rules"
                        element={
                            token ? (
                                <React.Suspense fallback={<Loader />}>
                                    <CRMAccountRules />
                                </React.Suspense>
                            ) : (
                                <Navigate to="/" replace />
                            )
                        }
                    />
                    <Route
                        path="/activities"
                        element={
                            token ? (
                                <React.Suspense fallback={<Loader />}>
                                    <CRMActivities />
                                </React.Suspense>
                            ) : (
                                <Navigate to="/" replace />
                            )
                        }
                    />
                    <Route
                        path="/activities/activities-information/:id"
                        element={
                            token ? (
                                <React.Suspense fallback={<Loader />}>
                                    <ActivityInformation />
                                </React.Suspense>
                            ) : (
                                <Navigate to="/" replace />
                            )
                        }
                    />
                    <Route
                        path="/RFP"
                        element={
                            token ? (
                                <React.Suspense fallback={<Loader />}>
                                    <CRMRFP />
                                </React.Suspense>
                            ) : (
                                <Navigate to="/" replace />
                            )
                        }
                    />
                    <Route
                        path="/Crm-Snapshot"
                        element={
                            token ? (
                                <React.Suspense fallback={<Loader />}>
                                    <CrmSnapshot />
                                </React.Suspense>
                            ) : (
                                <Navigate to="/" replace />
                            )
                        }
                    />
                    <Route
                        path="/Crm-Rateshop"
                        element={
                            token ? (
                                <React.Suspense fallback={<Loader />}>
                                    <CrmRateshop />
                                </React.Suspense>
                            ) : (
                                <Navigate to="/" replace />
                            )
                        }
                    />
                    <Route
                        path="/Crm-Rateshop/Crm-Rateshop-table-view"
                        element={
                            token ? (
                                <React.Suspense fallback={<Loader />}>
                                    <CrmRateshopTableView />
                                </React.Suspense>
                            ) : (
                                <Navigate to="/" replace />
                            )
                        }
                    />
                    <Route
                        path="/Crm-Rateshop/Crm-Rateshop-graphical-view"
                        element={
                            token ? (
                                <React.Suspense fallback={<Loader />}>
                                    <CrmRateshopGraphView />
                                </React.Suspense>
                            ) : (
                                <Navigate to="/" replace />
                            )
                        }
                    />
                    <Route
                        path="/RFP/grid-view"
                        element={
                            token ? (
                                <React.Suspense fallback={<Loader />}>
                                    <CRMRFPGridView />
                                </React.Suspense>
                            ) : (
                                <Navigate to="/" replace />
                            )
                        }
                    />
                    <Route
                        path="/MeetingCatering/grid-view"
                        element={
                            token ? (
                                <React.Suspense fallback={<Loader />}>
                                    <CRMMeetingAndCateringGridView />
                                </React.Suspense>
                            ) : (
                                <Navigate to="/" replace />
                            )
                        }
                    />
                    <Route
                        path="/LNR/grid-view"
                        element={
                            token ? (
                                <React.Suspense fallback={<Loader />}>
                                    <CRMLNRGridView />
                                </React.Suspense>
                            ) : (
                                <Navigate to="/" replace />
                            )
                        }
                    />
                    <Route
                        path="/LNR"
                        element={
                            token ? (
                                <React.Suspense fallback={<Loader />}>
                                    <CRMLNR />
                                </React.Suspense>
                            ) : (
                                <Navigate to="/" replace />
                            )
                        }
                    />
                    <Route
                        path="/group"
                        element={
                            token ? (
                                <React.Suspense fallback={<Loader />}>
                                    <CRMGroup />
                                </React.Suspense>
                            ) : (
                                <Navigate to="/" replace />
                            )
                        }
                    />
                    <Route
                        path="/meeting-and-catering"
                        element={
                            token ? (
                                <React.Suspense fallback={<Loader />}>
                                    <CRMMeetingAndCatering />
                                </React.Suspense>
                            ) : (
                                <Navigate to="/" replace />
                            )
                        }
                    />
                    <Route
                        path="/activities/activity-view"
                        element={
                            token ? (
                                <React.Suspense fallback={<Loader />}>
                                    <CRMActivityView />
                                </React.Suspense>
                            ) : (
                                <Navigate to="/" replace />
                            )
                        }
                    />
                    <Route
                        path="/Group/grid-view"
                        element={
                            token ? (
                                <React.Suspense fallback={<Loader />}>
                                    <CRMGroupGridView />
                                </React.Suspense>
                            ) : (
                                <Navigate to="/" replace />
                            )
                        }
                    />
                    <Route
                        path="/general-term-setup"
                        element={
                            token ? (
                                <React.Suspense fallback={<Loader />}>
                                    <CRMGeneralTermSetup />
                                </React.Suspense>
                            ) : (
                                <Navigate to="/" replace />
                            )
                        }
                    />
                    <Route
                        path="/business-intelligence"
                        element={
                            token ? (
                                <React.Suspense fallback={<Loader />}>
                                    <BusinessIntelligence moduleName={"Crm"} />
                                </React.Suspense>
                            ) : (
                                <Navigate to="/" replace />
                            )
                        }
                    />
                    <Route
                        path="/snapshot/:snapshottitle/:propertyCode/:snapshotId"
                        element={
                            token ? (
                                <React.Suspense fallback={<Loader />}>
                                    <CustomizeComponent />
                                </React.Suspense>
                            ) : (
                                <Navigate to="/" replace />
                            )
                        }
                    />
                    <Route
                        path="/crm-reminder"
                        element={
                            token ? (
                                <React.Suspense fallback={<Loader />}>
                                    <CrmReminder />
                                </React.Suspense>
                            ) : (
                                <Navigate to="/" replace />
                            )
                        }
                    />
                    <Route
                        path="/crm-displacement-calculator"
                        element={
                            token ? (
                                <React.Suspense fallback={<Loader />}>
                                    <CrmDisplacementCalc />
                                </React.Suspense>
                            ) : (
                                <Navigate to="/" replace />
                            )
                        }
                    />
                    <Route
                        path="/zoom-info"
                        element={
                            token ? (
                                <React.Suspense fallback={<Loader />}>
                                    <ZoomInfo />
                                </React.Suspense>
                            ) : (
                                <Navigate to="/" replace />
                            )
                        }
                    />
                </Routes>
            </main>
        </div>
    );
}

export default LayoutCRM;
