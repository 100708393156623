import React, { useEffect, useRef, useState } from "react";
import { apiResponse } from "../../../utils/apiResponse";
import { apiCall } from "../../../utils/axiosService";
import { API_URL } from "../../../utils/apiRoutes";
import Loader from "../../Loader/Loader";
import { Col, Form, Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";
import { commonService } from "../../../utils/commonService";

const Event = () => {
  const propertyDetailFromRTK = useSelector(
    (store) => store.headerProperty.propertyDetail
  );
  const crmPropertyDetailFromRTK = useSelector(
    (store) => store.headerProperty.crmPropertyDetail
  );
  const [eventDetails, setEventDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const eventListRef = useRef();
  const eventChangeRef = useRef([]);
  const datewiseEventBookingRef = useRef();

  const getEventList = async () => {
    setLoading(true);
    let eventListResponse = await apiCall(
      {
        method: "POST",
        url: API_URL.BASE_API_URL.RevenueManagement + API_URL.EVENTS.GET_LIST,
        body: {
          pageNum: 1,
          pageSize: 10,
          sortColumn: "",
          sortDirection: "asc",
          searchValue: "",
          propertyid: parseInt(
            window.location.href.includes("/crm")
              ? crmPropertyDetailFromRTK?.propertyid
              : propertyDetailFromRTK?.propertyid
          ),
          startdate: null,
          enddate: null,
          eventname: "",
          eventtypeterm: "",
          yearterm: ``,
        },
      },
      false
    );

    let response = apiResponse(false, eventListResponse);

    if (response?.isValidate) {
      eventListRef.current = response?.data?.data;
      let datesData = [];

      response?.data?.data?.forEach((item) => {
        datesData.push(commonService.getDateForNode(item?.startdate));
      });

      let eventByDateBookingResponse = await apiCall(
        {
          method: "POST",
          url: API_URL.BASE_API_URL.SnapShot + API_URL.EVENTS.DATE_WISE_BOOKING,
          body: {
            propertyCode: "AC32AW",
            Dates: datesData?.toString(),
          },
        },
        false
      );

      let responseForDateWiseBooking = apiResponse(
        false,
        eventByDateBookingResponse
      );

      if (responseForDateWiseBooking?.isValidate) {
        datewiseEventBookingRef.current =
          responseForDateWiseBooking?.data?.data;
      }

      if (!responseForDateWiseBooking?.isValidate) {
        datewiseEventBookingRef.current = null;
      }
      setEventDetails(response?.data?.data);
      setLoading(false);
    }

    if (!response?.isValidate) {
      eventListRef.current = null;
      setEventDetails(null);
      setLoading(false);
    }
  };

  const handleSubmit = async () => {
    if (eventChangeRef.current?.length <= 0) {
      return;
    }
    setSubmitLoading(true);

    let submitReponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.RevenueManagement + API_URL.EVENTS.EVENT_RATEIQ,
        body: {
          F: eventChangeRef.current,
        },
      },
      true
    );

    let response = apiResponse(true, submitReponse, setLoading);

    if (response?.isValidate) {
      setSubmitLoading(false);
    }

    if (!response?.isValidate) {
      setSubmitLoading(false);
    }
  };

  useEffect(() => {
    getEventList();
  }, []);

  return (
    <div>
      <Col xl={12}>
        <div className="table__container">
          <div className="stripped__table">
            <div className="table-responsive maxHeight400">
              <Table>
                <thead className="tbl-header-sticky my-tbl-header-sticky">
                  <tr>
                    <th>Date</th>
                    <th>Name</th>
                    <th>OOO/Rooms Available</th>
                    <th>Left To sell</th>
                    <th>On The Books</th>
                    <th>Total OCC%</th>
                    <th>ADR</th>
                    <th>Type</th>
                    <th>Impact</th>
                    <th className="text-center width100">% In Rate</th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr>
                      <td>
                        <Skeleton count={5} />
                      </td>
                      <td>
                        <Skeleton count={5} />
                      </td>
                      <td>
                        <Skeleton count={5} />
                      </td>
                      <td>
                        <Skeleton count={5} />
                      </td>
                      <td>
                        <Skeleton count={5} />
                      </td>
                      <td>
                        <Skeleton count={5} />
                      </td>
                      <td>
                        <Skeleton count={5} />
                      </td>
                      <td>
                        <Skeleton count={5} />
                      </td>
                      <td>
                        <Skeleton count={5} />
                      </td>
                      <td>
                        <Skeleton count={5} />
                      </td>
                    </tr>
                  ) : eventDetails ? (
                    eventDetails?.map((item, index) => {
                      let findDateWiseBooking =
                        datewiseEventBookingRef.current?.filter(
                          (x) =>
                            commonService.getDateInFormat(x?.Date) ===
                            commonService.getDateInFormat(item?.startdate)
                        );
                      return (
                        <tr key={item?.eventid} className="shadowOnHover">
                          <td>
                            {item?.startdate
                              ? commonService.getDateInFormat(item?.startdate)
                              : ""}
                          </td>
                          <td>{item?.eventname}</td>
                          <td>{`${
                            findDateWiseBooking?.[0]?.OOO
                              ? commonService.formateRoom(
                                  findDateWiseBooking?.[0]?.OOO
                                )
                              : 0
                          }/${
                            findDateWiseBooking?.[0]?.RoomAvailable
                              ? commonService.formateRoom(
                                  findDateWiseBooking?.[0]?.RoomAvailable
                                )
                              : 0
                          }`}</td>
                          <td>
                            {findDateWiseBooking?.[0]?.LeftToSell
                              ? commonService.formateRoom(
                                  findDateWiseBooking?.[0]?.LeftToSell
                                )
                              : 0}
                          </td>
                          <td>
                            {findDateWiseBooking?.[0]?.OnTheBook
                              ? commonService.formateRoom(
                                  findDateWiseBooking?.[0]?.OnTheBook
                                )
                              : 0}
                          </td>
                          <td>
                            {findDateWiseBooking?.[0]?.TotalOCCPercentage
                              ? commonService.formateRoom(
                                  findDateWiseBooking?.[0]?.TotalOCCPercentage
                                )
                              : 0}
                            %
                          </td>
                          <td>
                            {findDateWiseBooking?.[0]?.ADR
                              ? commonService.formateAmount(
                                  findDateWiseBooking?.[0]?.ADR
                                )
                              : commonService.formateAmount(0)}
                          </td>
                          <td>{item?.eventtypeterm}</td>
                          <td>{item?.eventimpactterm}</td>
                          <td className="p-1">
                            <Form.Control
                              type="text"
                              className="py-0 px-2"
                              maxLength={6}
                              value={item?.rateiqratevalue}
                              onChange={(e) => {
                                let updatedDetails = eventDetails?.map(
                                  (loopItem, loopIndex) => {
                                    if (item?.eventid === loopItem?.eventid) {
                                      loopItem.rateiqratevalue = e.target.value;
                                    }
                                    return loopItem;
                                  }
                                );
                                let findIndexOfEventId =
                                  eventChangeRef.current?.findIndex(
                                    (x) => x?.eventid === item?.eventid
                                  );

                                if (findIndexOfEventId >= 0) {
                                  eventChangeRef.current[
                                    findIndexOfEventId
                                  ].rateiqratevalue = e.target.value;
                                } else {
                                  eventChangeRef.current = [
                                    ...eventChangeRef.current,
                                    {
                                      eventid: item?.eventid,
                                      rateiqratevalue: e.target.value,
                                    },
                                  ];
                                }

                                setEventDetails(updatedDetails);
                              }}
                            />
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td>No Data Found</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </Col>
      <div className="rate-iq-setting-modal footer-buttons pt-2">
        {submitLoading ? (
          <button className="default-btn primary-btn" type="button" disabled>
            <span
              className="spinner-border spinner-border-sm me-3"
              role="status"
              aria-hidden="true"
            ></span>
            Loading...
          </button>
        ) : (
          <button
            className="default-btn primary-btn"
            onClick={() => {
              handleSubmit();
            }}
          >
            Submit
          </button>
        )}
      </div>
    </div>
  );
};

export default Event;
