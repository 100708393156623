import React, { useEffect, useRef, useState } from "react";
import { apiCall } from "../../../../utils/axiosService";
import { useSelector } from "react-redux";
import { API_URL } from "../../../../utils/apiRoutes";
import { apiResponse } from "../../../../utils/apiResponse";
import NoteIcon from "../../../../Assets/Images/CRM/notes.svg";
import downloadIcon from "../../../../Assets/Images/Operation/downloadIcon.svg";
import infoIcon from "../../../../Assets/Images/Action Icons/infoIcon..svg";
import { Button, Table } from "react-bootstrap";
import Loader from "../../../Loader/Loader";
import moment from "moment";
import { commonService } from "../../../../utils/commonService";
import { Line } from "react-chartjs-2";
import {
  Chart,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  Filler,
} from "chart.js";
import { compName } from "../../../../utils/constant";
import WidgetDetailModal from "../../WidgetDetailModal";
import WidgetNote from "../../WidgetNote";
import AddWidgetModal from "../../AddWidgetModal";
import { useParams } from "react-router-dom";
import emailIcon from "../../../../Assets/Images/Email.svg";
import Comments from "../Comments/Comment";
import lowPrio from "../../../../Assets/Images/Revenue-management/lowPriority.svg";
import highPrio from "../../../../Assets/Images/Revenue-management/highPriority.svg";

const DailyData = ({
  setCompDataFromChild,
  rowIndex,
  colIndex,
  api,
  para,
  setting,
}) => {
  const propertyDetailFromRTK = useSelector(
    (store) => store.headerProperty.propertyDetail
  );
  let datesArray = commonService.getAllDatesInMonth(
    new Date(para?.asOfDate).getFullYear(),
    new Date(para?.asOfDate).getMonth()
  );
  const tableData = [
    {
      propertyCode: "",
      AsOfDate: "2023-08-16",
      RateCode: "GROUP~",
      RateCodeDescription: "0",
      Company: "No Name",
      GroupName: "Comfirt",
      lata: "",
      GuestName: "BNSF, 4",
      ConfirmationNumber: "",
      ArrivalDate: "2023-08-20",
      LOS: "",
      RoomNight: 5,
      Status: "R",
      RateOfTheDay: "",
      Rate: "",
      Source: "DIRECT",
      RoomType: "NDD",
      Channel: "Yatra",
      User: "jyount.mo215",
      BookingDate: "2023-08-15",
    },
    {
      propertyCode: "",
      AsOfDate: "2023-08-16",
      RateCode: "GROUP~",
      RateCodeDescription: "0",
      Company: "No Name",
      GroupName: "Comfirt",
      lata: "",
      GuestName: "BNSF, 4",
      ConfirmationNumber: "",
      ArrivalDate: "2023-08-20",
      LOS: "",
      RoomNight: 5,
      Status: "R",
      RateOfTheDay: "",
      Rate: "",
      Source: "DIRECT",
      RoomType: "NDD",
      Channel: "Yatra",
      User: "jyount.mo215",
      BookingDate: "2023-08-15",
    },
    {
      propertyCode: "",
      AsOfDate: "2023-08-16",
      RateCode: "GROUP~",
      RateCodeDescription: "0",
      Company: "No Name",
      GroupName: "Comfirt",
      lata: "",
      GuestName: "BNSF, 4",
      ConfirmationNumber: "",
      ArrivalDate: "2023-08-20",
      LOS: "",
      RoomNight: 5,
      Status: "R",
      RateOfTheDay: "",
      Rate: "",
      Source: "DIRECT",
      RoomType: "NDD",
      Channel: "Yatra",
      User: "jyount.mo215",
      BookingDate: "2023-08-15",
    },
  ];

  const dayDataArrRef = useRef([]);
  const [dayData, setDayData] = useState(null);
  const [dayDataLoading, setDayDataLoading] = useState(false);
  const monthDataArrRef = useRef([]);
  const [monthData, setMonthData] = useState(null);
  const [monthDataLoading, setMonthDataLoading] = useState(false);
  const [chartData, setChartData] = useState(null);
  const [reservationData, setReservationData] = useState(null);
  const [reservationDataLoading, setReservationDataLoading] = useState(false);
  const [showNoteSidebar, setShowNoteSidebar] = useState(false);
  const [widgetModal, setWidgetModal] = useState(false);
  const [ReservationRateTitle, setReservationRateTitle] = useState(null);
  const widgetDetailRef = useRef();
  const clickedDateForNote = useRef();
  const defaultAsOfDateFromRTK = useSelector(
    (store) => store.headerProperty.defaultAsOfDate
  );
  const { snapshottitle, propertyCode, snapshotId } = useParams();
  const closeWidgetModal = () => {
    setWidgetModal(false);
  };
  const closeNoteSideBar = () => {
    setShowNoteSidebar(false);
  };

  const getDataForDay = async () => {
    setDayDataLoading(true);
    let dataForDayResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.SnapShot +
          API_URL.EMAIL_WIDGET.DAILY_DATA_WIDGET_ONE,
        body: {
          propertyCode: para?.propertyCode,
          AsOfDate: para?.asOfDate,
          propertyid: propertyDetailFromRTK?.propertyid,
        },
      },
      false
    );

    let response = apiResponse(false, dataForDayResponse);

    if (response?.isValidate) {
      let asDate1 = new Date(para?.asOfDate);
      let asDate2 = new Date(para?.asOfDate);
      let prevAsDate = new Date(asDate1.setDate(asDate1.getDate() - 1));
      let nextAsDate = new Date(asDate2.setDate(asDate2.getDate() + 1));
      dayDataArrRef.current = [
        prevAsDate,
        new Date(para?.asOfDate),
        nextAsDate,
      ];
      setDayData(response?.data?.data);
      setDayDataLoading(false);
    }

    if (!response?.isValidate) {
      let asDate1 = new Date(para?.asOfDate);
      let asDate2 = new Date(para?.asOfDate);
      let prevAsDate = new Date(asDate1.setDate(asDate1.getDate() - 1));
      let nextAsDate = new Date(asDate2.setDate(asDate2.getDate() + 1));
      dayDataArrRef.current = [
        prevAsDate,
        new Date(para?.asOfDate),
        nextAsDate,
      ];
      setDayData(null);
      setDayDataLoading(false);
    }
  };

  const getDataForMonth = async () => {
    let date = moment(para?.asOfDate);
    setMonthDataLoading(true);
    let dataForMonthResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.SnapShot +
          API_URL.EMAIL_WIDGET.DAILY_DATA_WIDGET_TWO,
        body: {
          propertyCode: para?.propertyCode,
          AsOfDate: para?.asOfDate,
          year: new Date(para?.asOfDate).getFullYear(),
          startDate: date.startOf("month").format("YYYY-MM-DD"),
        },
      },
      false
    );

    let response = apiResponse(false, dataForMonthResponse);

    if (response?.isValidate) {
      let asDate1 = new Date(para?.asOfDate);
      let asDate2 = new Date(para?.asOfDate);
      let prevAsDate = new Date(asDate1.setMonth(asDate1.getMonth() - 1));
      let nextAsDate = new Date(asDate2.setMonth(asDate2.getMonth() + 1));
      monthDataArrRef.current = [
        moment(prevAsDate).format("MMMM"),
        moment(para?.asOfDate).format("MMMM"),
        moment(nextAsDate).format("MMMM"),
      ];
      setMonthData(response?.data?.data);
      setMonthDataLoading(false);
    }

    if (!response?.isValidate) {
      let asDate1 = new Date(para?.asOfDate);
      let asDate2 = new Date(para?.asOfDate);
      let prevAsDate = new Date(asDate1.setMonth(asDate1.getMonth() - 1));
      let nextAsDate = new Date(asDate2.setMonth(asDate2.getMonth() + 1));
      monthDataArrRef.current = [
        moment(prevAsDate).format("MMMM"),
        moment(para?.asOfDate).format("MMMM"),
        moment(nextAsDate).format("MMMM"),
      ];
      setMonthData(null);
      setMonthDataLoading(false);
    }
  };

  const findDiffRate = (adr, rate, min, max) => {
    let diff = ((rate - adr) / adr) * 100;
    if (diff < 0) {
      diff = -diff;
      if (min < diff) {
        return {
          status: 1,
          diff: diff,
        };
      }
    }
    if (diff > 0) {
      if (max < diff) {
        return {
          status: 2,
          diff: diff,
        };
      } else {
        return 0;
      }
    }
  };

  const getReservationData = async () => {
    setReservationRateTitle(null);
    setReservationDataLoading(true);
    let reservationResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.SnapShot +
          API_URL.EMAIL_WIDGET.DAILY_DATA_WIDGET_THREE,
        body: {
          propertyCode: para?.propertyCode,
          AsOfDate: para?.asOfDate,
          year: new Date(para?.asOfDate).getFullYear(),
        },
      },
      false
    );

    let response = apiResponse(false, reservationResponse);

    if (response?.isValidate) {
      setReservationRateTitle({
        min: JSON.parse(response?.data?.data?.[0]?.rulevalues)[0]?.min,
        max: JSON.parse(response?.data?.data?.[0]?.rulevalues)[0]?.max,
      });
      setReservationData(
        response?.data?.data.map((d) => ({
          ...d,
          RateStatus: findDiffRate(
            d.ADR,
            d.Rate,
            JSON.parse(d?.rulevalues)?.[0]?.min,
            JSON.parse(d?.rulevalues)?.[0]?.max
          ),
        }))
      );
      setReservationDataLoading(false);
    }

    if (!response?.isValidate) {
      setReservationData(null);
      setReservationDataLoading(false);
    }
  };

  let prevYear = {
    label: "Prev Year",
    backgroundColor: "#97C2E0",
    data: [],
    stack: "stack1",
  };

  let currentYear = {
    label: "Current Year",
    backgroundColor: "#7ACFA2",
    data: [],
    stack: "stack2",
  };

  const getBookingPaceDetail = async () => {
    let bookingPaceResponse = await apiCall(
      {
        method: "POST",
        url: API_URL.BASE_API_URL.SnapShot + API_URL.BOOKING_PACE.CHART,
        body: {
          AsOfDate: para?.asOfDate,
          propertyCode: para?.propertyCode,
        },
      },
      false
    );

    let response = apiResponse(false, bookingPaceResponse);

    if (response?.isValidate) {
      let dataFromApi = response?.data?.data;

      datesArray?.map((item) => {
        let findData = dataFromApi?.filter((x) => {
          if (
            commonService.convertDateInTimezone(x?.Dates).getDate() === item
          ) {
            return x;
            // new Date(x?.Dates).getDate() === item
          }
        });
        prevYear.data = [
          ...prevYear.data,
          findData?.[0]?.TotalRevenuePreviousYear
            ? findData?.[0]?.TotalRevenuePreviousYear
            : 0,
        ];
        currentYear.data = [
          ...currentYear.data,
          findData?.[0]?.TotalRevenueCurrentYear
            ? findData?.[0]?.TotalRevenueCurrentYear
            : 0,
        ];
      });

      setChartData([prevYear, currentYear]);
    }

    if (!response?.isValidate) {
      prevYear.data = [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0,
      ];
      currentYear.data = [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0,
      ];
      setChartData([prevYear, currentYear]);
    }
  };
  let chartDetail = {};
  if (chartData) {
    chartDetail = {
      labels: datesArray,
      datasets: [
        {
          type: "line",
          label: `${new Date().getFullYear() - 1}`,
          backgroundColor: chartData?.[0]?.backgroundColor,
          data: chartData?.[0]?.data,
          stack: chartData?.[0]?.stack || "default",
          fill: {
            target: "origin",
            above: "rgba(151, 194, 224, 0.4)",
          },
        },
        {
          type: "line",
          label: `${new Date().getFullYear()}`,
          backgroundColor: chartData?.[1]?.backgroundColor,
          data: chartData?.[1]?.data,
          // fill: true,
          fill: {
            target: "origin",
            above: "rgba(122, 207, 162, 0.4)",
          },
          stack: chartData?.[1]?.stack || "default",
        },
      ],
    };
  }

  let chartOptions = {
    indexAxis: "x",
    scales: {
      x: {
        stacked: true,
        title: {
          display: true,
          text: "",
        },
        ticks: {
          align: "start",
          autoSkip: false,
        },
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: "",
        },
        grid: {
          display: true,
        },
        offset: true,
        ticks: {
          callback: function (value) {
            return "$" + value;
          },
        },
      },
    },

    plugins: {
      legend: {
        display: true,
        align: "end",
        position: "top",
        maxWidth: 20,
        maxHeight: 100,
        labels: {
          boxWidth: 10,
          boxHeight: 10,
        },
      },
      tooltip: {
        enabled: true,
        mode: "index",
        callbacks: {
          title: function (context) {
            return context[0].label;
          },
          label: function (context) {
            let label = context.dataset.label;
            let value = context.formattedValue;
            return `${label}: ${value}`;
          },
        },
      },
    },
    responsive: true,
    barThickness: 12,
    categorySpacing: 0.3,
    barPercentage: 0.8,
    categoryPercentage: 0.9,
  };

  Chart.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Tooltip,
    Legend,
    PointElement,
    LineElement,
    Filler
  );

  useEffect(() => {
    if (api === 0) {
      let asDate11 = new Date(para?.asOfDate);
      let asDate22 = new Date(para?.asOfDate);
      let prevAsDate = new Date(asDate11.setMonth(asDate11.getMonth() - 1));
      let nextAsDate = new Date(asDate22.setMonth(asDate22.getMonth() + 1));
      let asDate1 = new Date(para?.asOfDate);
      let asDate2 = new Date(para?.asOfDate);
      let prevAsDate1 = new Date(asDate1.setDate(asDate1.getDate() - 1));
      let nextAsDate2 = new Date(asDate2.setDate(asDate2.getDate() + 1));
      dayDataArrRef.current = [
        prevAsDate1,
        new Date(para?.asOfDate),
        nextAsDate2,
      ];
      monthDataArrRef.current = [
        moment(prevAsDate).format("MMMM"),
        moment(para?.asOfDate).format("MMMM"),
        moment(nextAsDate).format("MMMM"),
      ];
      prevYear.data = [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0,
      ];
      currentYear.data = [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0,
      ];
      setChartData([prevYear, currentYear]);
      setReservationData(tableData);
    } else {
      if (para?.asOfDate) {
        getDataForDay();
        getDataForMonth();
        getBookingPaceDetail();
        getReservationData();
      }
    }
  }, [para?.asOfDate]);

  const [tblids, settblids] = useState({
    eDailyData: commonService.GenerateGUID(),
    eMonthlyData: commonService.GenerateGUID(),
    eReservationData: commonService.GenerateGUID(),
  });

  return (
    <div>
      {/* DAY DATA */}
      <div className="eDailyData-widget-container">
        <p>{`Daily Data (${commonService.getDateInFormat(para?.asOfDate)})`}</p>
        {api !== 0 && (
          <div className="gap-1 eDailyData-widget-container-menu">
            {/* <Button
              className="heading-menu-btns"
              type="button"
              onClick={() => {
                clickedDateForNote.current = "";
                setShowNoteSidebar(true);
              }}
            >
              <img src={NoteIcon} alt="note" />
            </Button> */}
            <Button
              className="heading-menu-btns"
              type="button"
              title="Send Mail"
            >
              <img
                src={emailIcon}
                alt="emailIcon"
                onClick={() => {
                  commonService.fnSendWidgetMail(
                    tblids.eDailyData,
                    "Email Daily Data",
                    snapshotId,
                    defaultAsOfDateFromRTK,
                    "",
                    para?.widgetId
                  );
                }}
              />
            </Button>
            <Button className="heading-menu-btns" type="button">
              <img
                src={infoIcon}
                alt="info"
                onClick={() => {
                  widgetDetailRef.current = {
                    widgetdetails: "",
                    usernote: "",
                    compName: compName?.dailyDataForEmail,
                    widgetId: para?.widgetId,
                  };
                  setWidgetModal(true);
                }}
              />
            </Button>
            <Button className="heading-menu-btns" type="button">
              <img
                src={downloadIcon}
                alt="download"
                import={tblids.eDailyData}
                onClick={() =>
                  commonService.fnExportInCSV(
                    tblids.eDailyData,
                    // "Email Daily Data"
                    `${para?.propertyCode}-Email Daily Data-${moment(
                      new Date()
                    ).format("MMDDYYYY")}`
                  )
                }
              />
            </Button>
          </div>
        )}
        <div className="ViewPropertySetupTable">
          <div className="stripped__table table-responsive">
            <Table id={tblids.eDailyData} responsive>
              <thead>
                <tr>
                  <th className="border_right"></th>
                  <th colSpan={4} className="text-center border_right">
                    On The Book
                  </th>
                  <th colSpan={4} className="text-center border_right">
                    Net Stly
                  </th>
                  <th colSpan={4} className="text-center border_right">
                    Total Ly
                  </th>
                  <th colSpan={4} className="text-center border_right">
                    Budget
                  </th>
                  <th colSpan={4} className="text-center">
                    Forecast
                  </th>
                </tr>
                <tr>
                  <th className="border_right"></th>
                  <th className="text-center">OCC</th>
                  <th className="text-center">RMS</th>
                  <th className="text-end">ADR</th>
                  <th className="border_right text-end">REV</th>
                  <th className="text-center">OCC</th>
                  <th className="text-center">RMS</th>
                  <th className="text-end">ADR</th>
                  <th className="border_right text-end">REV</th>
                  <th className="text-center">OCC</th>
                  <th className="text-center">RMS</th>
                  <th className="text-end">ADR</th>
                  <th className="border_right text-end">REV</th>
                  <th className="text-center">OCC</th>
                  <th className="text-center">RMS</th>
                  <th className="text-end">ADR</th>
                  <th className="border_right text-end">REV</th>
                  <th className="text-center">OCC</th>
                  <th className="text-center">RMS</th>
                  <th className="text-end">ADR</th>
                  <th className="text-end">REV</th>
                </tr>
              </thead>
              <tbody>
                {dayDataLoading ? (
                  <tr style={{ height: "80px", position: "relative" }}>
                    <Loader />
                  </tr>
                ) : (
                  dayDataArrRef.current?.map((item, index) => {
                    let singleData = dayData?.filter(
                      (x) =>
                        moment(item).format("YYYY-MM-DD") ===
                        moment(x?.Dates).format("YYYY-MM-DD")
                    );

                    return (
                      <tr key={item} className="shadowOnHover">
                        <td className="border_right">
                          {commonService.getDateInFormat(item)}
                        </td>
                        <td className="text-center">
                          {singleData?.[0]?.OCC ? singleData?.[0]?.OCC : 0}%
                        </td>
                        <td className="text-center">
                          {singleData?.[0]?.RMS
                            ? commonService.formateRoom(singleData?.[0]?.RMS)
                            : 0}
                        </td>
                        <td className="text-end">
                          {singleData?.[0]?.ADR
                            ? commonService.formateAmount(singleData?.[0]?.ADR)
                            : commonService.formateAmount(0)}
                        </td>
                        <td className="border_right text-end">
                          {singleData?.[0]?.REV
                            ? commonService.formateAmount(singleData?.[0]?.REV)
                            : commonService.formateAmount(0)}
                        </td>
                        <td className="text-center">
                          {singleData?.[0]?.STLY_OCC
                            ? singleData?.[0]?.STLY_OCC
                            : 0}
                          %
                        </td>
                        <td className="text-center">
                          {singleData?.[0]?.STLY_RMS
                            ? commonService.formateRoom(
                                singleData?.[0]?.STLY_RMS
                              )
                            : 0}
                        </td>
                        <td className="text-end">
                          {singleData?.[0]?.STLY_ADR
                            ? commonService.formateAmount(
                                singleData?.[0]?.STLY_ADR
                              )
                            : commonService.formateAmount(0)}
                        </td>
                        <td className="border_right text-end">
                          {singleData?.[0]?.STLY_REV
                            ? commonService.formateAmount(
                                singleData?.[0]?.STLY_REV
                              )
                            : commonService.formateAmount(0)}
                        </td>
                        <td className="text-center">
                          {singleData?.[0]?.LY_OCC
                            ? singleData?.[0]?.LY_OCC
                            : 0}
                          %
                        </td>
                        <td className="text-center">
                          {singleData?.[0]?.LY_RMS
                            ? commonService.formateRoom(singleData?.[0]?.LY_RMS)
                            : 0}
                        </td>
                        <td className="text-end">
                          {singleData?.[0]?.LY_ADR
                            ? commonService.formateAmount(
                                singleData?.[0]?.LY_ADR
                              )
                            : commonService.formateAmount(0)}
                        </td>
                        <td className="border_right text-end">
                          {singleData?.[0]?.LY_REV
                            ? commonService.formateAmount(
                                singleData?.[0]?.LY_REV
                              )
                            : commonService.formateAmount(0)}
                        </td>
                        <td className="text-center">
                          {singleData?.[0]?.BUDGET_OCC
                            ? singleData?.[0]?.BUDGET_OCC
                            : 0}
                          %
                        </td>
                        <td className="text-center">
                          {singleData?.[0]?.BUDGET_RMS
                            ? commonService.formateRoom(
                                singleData?.[0]?.BUDGET_RMS
                              )
                            : 0}
                        </td>
                        <td className="text-end">
                          {singleData?.[0]?.BUDGET_ADR
                            ? commonService.formateAmount(
                                Math.round(singleData?.[0]?.BUDGET_ADR)
                              )
                            : commonService.formateAmount(0)}
                        </td>
                        <td className="border_right text-end">
                          {singleData?.[0]?.BUDGET_REV
                            ? commonService.formateAmount(
                                singleData?.[0]?.BUDGET_REV
                              )
                            : commonService.formateAmount(0)}
                        </td>
                        <td className="text-center">
                          {singleData?.[0]?.Forecast_OCC
                            ? singleData?.[0]?.Forecast_OCC
                            : 0}
                          %
                        </td>
                        <td className="text-center">
                          {singleData?.[0]?.Forecast_RMS
                            ? commonService.formateRoom(
                                singleData?.[0]?.Forecast_RMS
                              )
                            : 0}
                        </td>
                        <td className="text-end">
                          {singleData?.[0]?.Forecast_ADR
                            ? commonService.formateAmount(
                                singleData?.[0]?.Forecast_ADR
                              )
                            : commonService.formateAmount(0)}
                        </td>
                        <td className="text-end">
                          {singleData?.[0]?.Forecast_REV
                            ? commonService.formateAmount(
                                singleData?.[0]?.Forecast_REV
                              )
                            : commonService.formateAmount(0)}
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </Table>
          </div>
        </div>
      </div>

      {/* MONTH DATA */}
      <div className="mt-3 eMonthlyData-widget-container">
        <p>{moment(para?.asOfDate).format("MMM - YYYY")}</p>
        {api !== 0 && (
          <div className="gap-1 eMonthlyData-widget-container-menu">
            {/* <Button
              className="heading-menu-btns"
              type="button"
              onClick={() => {
                clickedDateForNote.current = "";
                setShowNoteSidebar(true);
              }}
            >
              <img src={NoteIcon} alt="note" />
            </Button> */}
            <Button
              className="heading-menu-btns"
              type="button"
              title="Send Mail"
            >
              <img
                src={emailIcon}
                alt="emailIcon"
                onClick={() => {
                  commonService.fnSendWidgetMail(
                    tblids.eMonthlyData,
                    "Email Daily Data",
                    snapshotId,
                    defaultAsOfDateFromRTK,
                    "",
                    para?.widgetId
                  );
                }}
              />
            </Button>
            <Button className="heading-menu-btns" type="button">
              <img
                src={infoIcon}
                alt="info"
                onClick={() => {
                  widgetDetailRef.current = {
                    widgetdetails: "",
                    usernote: "",
                    compName: compName?.dailyDataForEmail,
                    widgetId: para?.widgetId,
                  };
                  setWidgetModal(true);
                }}
              />
            </Button>
            <Button className="heading-menu-btns" type="button">
              <img
                src={downloadIcon}
                alt="download"
                import={tblids.eMonthlyData}
                onClick={() =>
                  commonService.fnExportInCSV(
                    tblids.eMonthlyData,
                    // "Email Daily Data"
                    `${para?.propertyCode}-Email Daily Data-${moment(
                      new Date()
                    ).format("MMDDYYYY")}`
                  )
                }
              />
            </Button>
          </div>
        )}
        <div className="ViewPropertySetupTable">
          <div className="stripped__table table-responsive">
            <Table id={tblids.eMonthlyData} responsive>
              <thead>
                <tr>
                  <th className="border_right"></th>
                  <th colSpan={4} className="text-center border_right">
                    On The Book
                  </th>
                  <th colSpan={4} className="text-center border_right">
                    Net Stly
                  </th>
                  <th colSpan={4} className="text-center border_right">
                    Total Ly
                  </th>
                  <th colSpan={4} className="text-center border_right">
                    Budget
                  </th>
                  <th colSpan={4} className="text-center">
                    Forecast
                  </th>
                </tr>
                <tr>
                  <th className="border_right"></th>
                  <th className="text-center">OCC</th>
                  <th className="text-center">RMS</th>
                  <th className="text-end">ADR</th>
                  <th className="border_right text-end">REV</th>
                  <th className="text-center">OCC</th>
                  <th className="text-center">RMS</th>
                  <th className="text-end">ADR</th>
                  <th className="border_right text-end">REV</th>
                  <th className="text-center">OCC</th>
                  <th className="text-center">RMS</th>
                  <th className="text-end">ADR</th>
                  <th className="border_right text-end">REV</th>
                  <th className="text-center">OCC</th>
                  <th className="text-center">RMS</th>
                  <th className="text-end">ADR</th>
                  <th className="border_right text-end">REV</th>
                  <th className="text-center">OCC</th>
                  <th className="text-center">RMS</th>
                  <th className="text-end">ADR</th>
                  <th className="text-end">REV</th>
                </tr>
              </thead>
              <tbody>
                {monthDataLoading ? (
                  <tr style={{ height: "80px", position: "relative" }}>
                    <Loader />
                  </tr>
                ) : (
                  monthDataArrRef.current?.map((item, index) => {
                    let singleData = monthData?.filter(
                      (x) => item === x?.Month?.trim()
                    );

                    return (
                      <tr key={item} className="shadowOnHover">
                        <td className="border_right">{item}</td>
                        <td className="text-center">
                          {singleData?.[0]?.OCC ? singleData?.[0]?.OCC : 0}%
                        </td>
                        <td className="text-center">
                          {singleData?.[0]?.RMS
                            ? commonService.formateRoom(singleData?.[0]?.RMS)
                            : 0}
                        </td>
                        <td className="text-end">
                          {singleData?.[0]?.ADR
                            ? commonService.formateAmount(singleData?.[0]?.ADR)
                            : commonService.formateAmount(0)}
                        </td>
                        <td className="border_right text-end">
                          {singleData?.[0]?.REV
                            ? commonService.formateAmount(singleData?.[0]?.REV)
                            : commonService.formateAmount(0)}
                        </td>
                        <td className="text-center">
                          {singleData?.[0]?.STLY_OCC
                            ? singleData?.[0]?.STLY_OCC
                            : 0}
                          %
                        </td>
                        <td className="text-center">
                          {singleData?.[0]?.STLY_RMS
                            ? commonService.formateRoom(
                                singleData?.[0]?.STLY_RMS
                              )
                            : 0}
                        </td>
                        <td className="text-end">
                          {singleData?.[0]?.STLY_ADR
                            ? commonService.formateAmount(
                                singleData?.[0]?.STLY_ADR
                              )
                            : commonService.formateAmount(0)}
                        </td>
                        <td className="border_right text-end">
                          {singleData?.[0]?.STLY_REV
                            ? commonService.formateAmount(
                                singleData?.[0]?.STLY_REV
                              )
                            : commonService.formateAmount(0)}
                        </td>
                        <td className="text-center">
                          {singleData?.[0]?.LY_OCC
                            ? singleData?.[0]?.LY_OCC
                            : 0}
                          %
                        </td>
                        <td className="text-center">
                          {singleData?.[0]?.LY_RMS
                            ? commonService.formateRoom(singleData?.[0]?.LY_RMS)
                            : 0}
                        </td>
                        <td className="text-end">
                          {singleData?.[0]?.LY_ADR
                            ? commonService.formateAmount(
                                singleData?.[0]?.LY_ADR
                              )
                            : commonService.formateAmount(0)}
                        </td>
                        <td className="border_right text-end">
                          {singleData?.[0]?.LY_REV
                            ? commonService.formateAmount(
                                singleData?.[0]?.LY_REV
                              )
                            : commonService.formateAmount(0)}
                        </td>
                        <td className="text-center">
                          {singleData?.[0]?.BUDGET_OCC
                            ? singleData?.[0]?.BUDGET_OCC
                            : 0}
                          %
                        </td>
                        <td className="text-center">
                          {singleData?.[0]?.BUDGET_RMS
                            ? commonService.formateRoom(
                                singleData?.[0]?.BUDGET_RMS
                              )
                            : 0}
                        </td>
                        <td className="text-end">
                          {singleData?.[0]?.BUDGET_ADR
                            ? commonService.formateAmount(
                                singleData?.[0]?.BUDGET_ADR
                              )
                            : commonService.formateAmount(0)}
                        </td>
                        <td className="border_right text-end">
                          {singleData?.[0]?.BUDGET_REV
                            ? commonService.formateAmount(
                                singleData?.[0]?.BUDGET_REV
                              )
                            : commonService.formateAmount(0)}
                        </td>
                        <td className="text-center">
                          {singleData?.[0]?.FORECAST_OCC
                            ? singleData?.[0]?.FORECAST_OCC
                            : 0}
                          %
                        </td>
                        <td className="text-center">
                          {singleData?.[0]?.FORECAST_RMS
                            ? commonService.formateRoom(
                                singleData?.[0]?.FORECAST_RMS
                              )
                            : 0}
                        </td>
                        <td className="text-end">
                          {singleData?.[0]?.FORECAST_ADR
                            ? commonService.formateAmount(
                                singleData?.[0]?.FORECAST_ADR
                              )
                            : commonService.formateAmount(0)}
                        </td>
                        <td className="text-end">
                          {singleData?.[0]?.FORECAST_REV
                            ? commonService.formateAmount(
                                singleData?.[0]?.FORECAST_REV
                              )
                            : commonService.formateAmount(0)}
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </Table>
          </div>
        </div>
      </div>

      {/* BOOKING PACE CHART */}
      <div className="mt-3 ebookingPace-widget-container">
        <p>{`Booking Pace (${moment(para?.asOfDate).format("MMMM")})`}</p>
        {api !== 0 && (
          <div className="gap-1 ebookingPace-widget-container-menu">
            {/* <Button className="heading-menu-btns" type="button">
              <img
                src={NoteIcon}
                alt="menuIcon"
                onClick={() => {
                  clickedDateForNote.current = "";
                  setShowNoteSidebar(true);
                }}
              />
            </Button> */}
            <Button className="heading-menu-btns" type="button">
              <img
                src={infoIcon}
                alt="info"
                onClick={() => {
                  widgetDetailRef.current = {
                    widgetdetails: "",
                    usernote: "",
                    compName: compName?.dailyDataForEmail,
                    widgetId: para?.widgetId,
                  };
                  setWidgetModal(true);
                }}
              />
            </Button>
          </div>
        )}
        {chartData && (
          <div className="ForecastHorizontalChartInner">
            <Line data={chartDetail} options={chartOptions} />
          </div>
        )}
      </div>

      {/* RESERVATION ACTIVITY DATA */}
      <div className="mt-3 ereservation-widget-container">
        <p>{`Reservation Activity (${commonService.getDateInFormat(
          para?.asOfDate
        )})`}</p>
        {api !== 0 && (
          <div className="gap-1 ereservation-widget-container-menu">
            {/* <Button className="heading-menu-btns" type="button">
              <img
                src={NoteIcon}
                alt="menuIcon"
                onClick={() => {
                  clickedDateForNote.current = "";
                  setShowNoteSidebar(true);
                }}
              />
            </Button> */}
            <Button
              className="heading-menu-btns"
              type="button"
              title="Send Mail"
            >
              <img
                src={emailIcon}
                alt="emailIcon"
                onClick={() => {
                  commonService.fnSendWidgetMail(
                    tblids.eReservationData,
                    "Email Daily Data",
                    snapshotId,
                    defaultAsOfDateFromRTK,
                    "",
                    para?.widgetId
                  );
                }}
              />
            </Button>
            <Button className="heading-menu-btns" type="button">
              <img
                src={infoIcon}
                alt="info"
                onClick={() => {
                  widgetDetailRef.current = {
                    widgetdetails: "",
                    usernote: "",
                    compName: compName?.dailyDataForEmail,
                    widgetId: para?.widgetId,
                  };
                  setWidgetModal(true);
                }}
              />
            </Button>
            <Button className="heading-menu-btns" type="button">
              <img
                src={downloadIcon}
                alt="menuIcon"
                import={tblids.eReservationData}
                onClick={() => {
                  commonService.fnExportInCSV(
                    tblids.eReservationData,
                    // "Email Daily Data"
                    `${para?.propertyCode}-Email Daily Data-${moment(
                      new Date()
                    ).format("MMDDYYYY")}`
                  );
                }}
              />
            </Button>
          </div>
        )}
        <div className="ViewPropertySetupTable">
          <div className="stripped__table table-responsive">
            <div className="table-responsive tblDynamicPickupSticky">
              <Table id={tblids.eReservationData}>
                <thead
                  id="tblreservationActivitySnapShotHead"
                  className="tbl-header-sticky"
                >
                  <tr>
                    <th className="text-center" colSpan={15}>
                      Reservation Activity
                      <span style={{ marginLeft: "10px", fontSize: "7px" }}>
                        {`${
                          ReservationRateTitle
                            ? `(Reservation Rate < ${ReservationRateTitle.min} % or > ${ReservationRateTitle.max} % by Rate of the Day (ADR))`
                            : ""
                        }`}
                      </span>
                    </th>
                  </tr>
                  <tr>
                    <th>Rate Code</th>
                    <th>Rate Plan Description</th>
                    <th>Company</th>
                    <th>Group Name</th>
                    <th>Guest Name</th>
                    <th>Confirmation Number</th>
                    <th>Arrival Date</th>
                    <th className="text-center">LOS</th>
                    <th>Status</th>
                    <th className="text-end">ADR</th>
                    <th className="text-end">Rate</th>
                    <th>Room Type</th>
                    <th>Channel</th>
                    <th>Source</th>
                    <th>Booking Date</th>
                  </tr>
                </thead>
                <tbody>
                  {reservationDataLoading ? (
                    <tr style={{ height: "80px", position: "relative" }}>
                      <Loader />
                    </tr>
                  ) : reservationData ? (
                    reservationData?.map((item, index) => {
                      let status = "";
                      if (item?.Status === "R") {
                        status = "reserved";
                      } else if (item?.Status === "I") {
                        status = "in house";
                      } else if (item?.Status === "O") {
                        status = "checked out";
                      } else if (item?.Status === "C") {
                        status = "cancelled";
                      } else if (item?.Status === "N") {
                        status = "no show";
                      }
                      return (
                        <tr key={index} className="shadowOnHover">
                          <td>{item?.RateCode}</td>
                          <td>
                            {item?.RateCodeDescript
                              ? item?.RateCodeDescript
                              : "-"}
                          </td>
                          <td>{item?.Company}</td>
                          <td>
                            {item?.GroupName ? item?.GroupName : "UNKNOWN"}
                          </td>
                          <td>{item?.GuestName}</td>
                          <td>{item?.Account}</td>
                          <td>
                            {commonService.getDateInFormat(item?.ArrivalDate)}
                          </td>
                          <td className="text-center">{item?.LOS}</td>
                          <td>{status}</td>
                          <td className="text-end">
                            {commonService.formateAmount(
                              Math.round(item?.ADR ? item?.ADR : 0)
                            )}
                          </td>
                          <td className="text-end">
                            {item?.RateStatus?.status == 1 ? (
                              <img
                                src={lowPrio}
                                title={item?.RateStatus?.diff?.toFixed(2) + "%"}
                              />
                            ) : item?.RateStatus?.status == 2 ? (
                              <img
                                src={highPrio}
                                title={item?.RateStatus?.diff?.toFixed(2) + "%"}
                              />
                            ) : null}
                            {commonService.formateAmount(
                              Math.round(item?.Rate ? item?.Rate : 0)
                            )}
                          </td>
                          <td>{item?.RoomType}</td>
                          <td>{item?.CombinedChannel}</td>
                          <td>{item?.User}</td>
                          <td>
                            {commonService.getDateInFormat(item?.BookingDate)}
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td>No Data Found</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>
      {api !== 0 && <Comments widgetDetails={para} editorId={"DailyData"} />}

      {/* {showNoteSidebar && (
        <WidgetNote
          show={showNoteSidebar}
          handleClose={closeNoteSideBar}
          widgetId={para?.widgetId}
          propertyId={propertyDetailFromRTK?.propertyid}
          rowTitle={para?.rowTitle}
        />
      )} */}
      {showNoteSidebar && (
        <AddWidgetModal
          showModal={showNoteSidebar}
          closeModal={closeNoteSideBar}
          widgetId={para?.widgetId}
          propertyId={propertyDetailFromRTK?.propertyid}
          rowTitle={para?.rowTitle}
        />
      )}
      {widgetModal && (
        <WidgetDetailModal
          showModal={widgetModal}
          closeModal={closeWidgetModal}
          data={widgetDetailRef.current}
        />
      )}
    </div>
  );
};

export default DailyData;
