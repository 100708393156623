import React, { useEffect, useRef, useState } from "react";
import { commonService } from "../../../../utils/commonService";
import DatePicker from "react-datepicker";
import NoteIcon from "../../../../Assets/Images/CRM/notes.svg";
import downloadIcon from "../../../../Assets/Images/Operation/downloadIcon.svg";
import { Button, Col, Table } from "react-bootstrap";
import { apiCall } from "../../../../utils/axiosService";
import { apiResponse } from "../../../../utils/apiResponse";
import { API_URL } from "../../../../utils/apiRoutes";
import Loader from "../../../Loader/Loader";
import WidgetDetailModal from "../../WidgetDetailModal";
import WidgetNote from "../../WidgetNote";
import { compName } from "../../../../utils/constant";
import { useSelector } from "react-redux";
import infoIcon from "../../../../Assets/Images/Action Icons/infoIcon..svg";
import AddWidgetModal from "../../AddWidgetModal";
import { useParams } from "react-router-dom";
import emailIcon from "../../../../Assets/Images/Email.svg";
import moment from "moment";

const BestWesternGuestName = ({
  setCompDataFromChild,
  rowIndex,
  colIndex,
  api,
  para,
  setting,
}) => {
  const tableData = [
    {
      id: 1,
      asOfDate: "-",
      propertyCode: "-",
      pullDateId: "0",
      HotelName: "-",
      BusinessDateCaption: "-",
      BusinessDateVal: "-",
      PrintedCaption: "-",
      PrintedValue: "-",
      txtMobileKey: "0",
      textBox13: "0",
      textBox12: "0",
      textBox11: "0",
      textBox10: "0",
      textBox9: "0",
      textBox8: "0",
      textBox7: "0",
      textBox6: "0",
      textBox5: "0",
      textBox4: "0",
      textBox3: "0",
      textBox1: "0",
      textBox15: "0",
      textBox16: "0",
      arrDateCaptionTextBox: "0",
      depDateCaptionTextBox: "0",
      guestNameCaptionTextBox: "0",
      guestAddressCaptionTextBox: "0",
      companyNameCaptionTextBox: "0",
      commentsCaptionTextBox: "0",
      adultsCaptionTextBox: "0",
      childrenOverCaptionTextBox: "0",
      marketSegmentCaptionTextBox: "0",
      ratePlanCaptionTextBox: "0",
      amountCaptionTextBox: "0",
      iATACaptionTextBox: "0",
      guestStatusCaptionTextBox: "0",
      balanceCaptionTextBox: "0",
      roomTypeCaptionTextBox: "0",
      shareNameCaptionTextBox: "0",
      roomCaptionTextBox: "0",
      groupNameCaptionTextBox: "0",
      textBox20: "0",
      holdTypeCaptionTextBox: "0",
      confirmationCaptionTextBox: "0",
      textBox22: "0",
      textBox18: "0",
      txtAutotag: "0",
      txtAutoTagState: "0",
      textBox2: "0",
      textBox19: "0",
      textBox28: "0",
      textBox30: "0",
      textBox24: "0",
      textBox31: "0",
      arrDateDataTextBox: "-",
      depDateDataTextBox: "-",
      guestNameDataTextBox: "0",
      guestAddressDataTextBox: "0",
      guestCityStatePostalDataTextBox: "0",
      companyNameDataTextBox: "0",
      iATADataTextBox: "0",
      commentsDataTextBox: "0",
      adultsDataTextBox: "0",
      childrenOverDataTextBox: "0/0",
      marketSegmentDataTextBox: "0",
      ratePlanDataTextBox: "0",
      amountDataTextBox: "0",
      roomDataTextBox: "0",
      holdTypeDataTextBox: "0",
      confirmationDataTextBox: "0",
      guestStatusDataTextBox: "0",
      groupNameDataTextBox: "0",
      shareNameDataTextBox: "0",
      balanceDataTextBox: "0",
      roomTypeDataTextBox: "0",
      textBox17: "0",
      textBox21: "0",
      CRSConfirmationDataTextBox: "0",
      textBox23: "0",
      textBox25: "0",
      TxtdataAutoTagNumber: "0",
      txtdataAutoTagState: "0",
      textBox14: "0",
      CRSConfirmationData2TextBox: "0",
      textBox26: "0",
      textBox27: "0",
      textBox29: "0",
      loyaltyNumberTextBox: "0",
      textBox32: "0",
    },
    {
      id: 2,
      asOfDate: "-",
      propertyCode: "-",
      pullDateId: "0",
      HotelName: "-",
      BusinessDateCaption: "-",
      BusinessDateVal: "-",
      PrintedCaption: "-",
      PrintedValue: "-",
      txtMobileKey: "0",
      textBox13: "0",
      textBox12: "0",
      textBox11: "0",
      textBox10: "0",
      textBox9: "0",
      textBox8: "0",
      textBox7: "0",
      textBox6: "0",
      textBox5: "0",
      textBox4: "0",
      textBox3: "0",
      textBox1: "0",
      textBox15: "0",
      textBox16: "0",
      arrDateCaptionTextBox: "0",
      depDateCaptionTextBox: "0",
      guestNameCaptionTextBox: "0",
      guestAddressCaptionTextBox: "0",
      companyNameCaptionTextBox: "0",
      commentsCaptionTextBox: "0",
      adultsCaptionTextBox: "0",
      childrenOverCaptionTextBox: "0",
      marketSegmentCaptionTextBox: "0",
      ratePlanCaptionTextBox: "0",
      amountCaptionTextBox: "0",
      iATACaptionTextBox: "0",
      guestStatusCaptionTextBox: "0",
      balanceCaptionTextBox: "0",
      roomTypeCaptionTextBox: "0",
      shareNameCaptionTextBox: "0",
      roomCaptionTextBox: "0",
      groupNameCaptionTextBox: "0",
      textBox20: "0",
      holdTypeCaptionTextBox: "0",
      confirmationCaptionTextBox: "0",
      textBox22: "0",
      textBox18: "0",
      txtAutotag: "0",
      txtAutoTagState: "0",
      textBox2: "0",
      textBox19: "0",
      textBox28: "0",
      textBox30: "0",
      textBox24: "0",
      textBox31: "0",
      arrDateDataTextBox: "-",
      depDateDataTextBox: "-",
      guestNameDataTextBox: "0",
      guestAddressDataTextBox: "0",
      guestCityStatePostalDataTextBox: "0",
      companyNameDataTextBox: "0",
      iATADataTextBox: "0",
      commentsDataTextBox: "0",
      adultsDataTextBox: "0",
      childrenOverDataTextBox: "0/0",
      marketSegmentDataTextBox: "0",
      ratePlanDataTextBox: "0",
      amountDataTextBox: "0",
      roomDataTextBox: "0",
      holdTypeDataTextBox: "0",
      confirmationDataTextBox: "0",
      guestStatusDataTextBox: "0",
      groupNameDataTextBox: "0",
      shareNameDataTextBox: "0",
      balanceDataTextBox: "0",
      roomTypeDataTextBox: "0",
      textBox17: "0",
      textBox21: "0",
      CRSConfirmationDataTextBox: "0",
      textBox23: "0",
      textBox25: "0",
      TxtdataAutoTagNumber: "0",
      txtdataAutoTagState: "0",
      textBox14: "0",
      CRSConfirmationData2TextBox: "0",
      textBox26: "0",
      textBox27: "0",
      textBox29: "0",
      loyaltyNumberTextBox: "0",
      textBox32: "0",
    },
    {
      id: 3,
      asOfDate: "-",
      propertyCode: "-",
      pullDateId: "0",
      HotelName: "-",
      BusinessDateCaption: "-",
      BusinessDateVal: "-",
      PrintedCaption: "-",
      PrintedValue: "-",
      txtMobileKey: "0",
      textBox13: "0",
      textBox12: "0",
      textBox11: "0",
      textBox10: "0",
      textBox9: "0",
      textBox8: "0",
      textBox7: "0",
      textBox6: "0",
      textBox5: "0",
      textBox4: "0",
      textBox3: "0",
      textBox1: "0",
      textBox15: "0",
      textBox16: "0",
      arrDateCaptionTextBox: "0",
      depDateCaptionTextBox: "0",
      guestNameCaptionTextBox: "0",
      guestAddressCaptionTextBox: "0",
      companyNameCaptionTextBox: "0",
      commentsCaptionTextBox: "0",
      adultsCaptionTextBox: "0",
      childrenOverCaptionTextBox: "0",
      marketSegmentCaptionTextBox: "0",
      ratePlanCaptionTextBox: "0",
      amountCaptionTextBox: "0",
      iATACaptionTextBox: "0",
      guestStatusCaptionTextBox: "0",
      balanceCaptionTextBox: "0",
      roomTypeCaptionTextBox: "0",
      shareNameCaptionTextBox: "0",
      roomCaptionTextBox: "0",
      groupNameCaptionTextBox: "0",
      textBox20: "0",
      holdTypeCaptionTextBox: "0",
      confirmationCaptionTextBox: "0",
      textBox22: "0",
      textBox18: "0",
      txtAutotag: "0",
      txtAutoTagState: "0",
      textBox2: "0",
      textBox19: "0",
      textBox28: "0",
      textBox30: "0",
      textBox24: "0",
      textBox31: "0",
      arrDateDataTextBox: "-",
      depDateDataTextBox: "-",
      guestNameDataTextBox: "0",
      guestAddressDataTextBox: "0",
      guestCityStatePostalDataTextBox: "0",
      companyNameDataTextBox: "0",
      iATADataTextBox: "0",
      commentsDataTextBox: "0",
      adultsDataTextBox: "0",
      childrenOverDataTextBox: "0/0",
      marketSegmentDataTextBox: "0",
      ratePlanDataTextBox: "0",
      amountDataTextBox: "0",
      roomDataTextBox: "0",
      holdTypeDataTextBox: "0",
      confirmationDataTextBox: "0",
      guestStatusDataTextBox: "0",
      groupNameDataTextBox: "0",
      shareNameDataTextBox: "0",
      balanceDataTextBox: "0",
      roomTypeDataTextBox: "0",
      textBox17: "0",
      textBox21: "0",
      CRSConfirmationDataTextBox: "0",
      textBox23: "0",
      textBox25: "0",
      TxtdataAutoTagNumber: "0",
      txtdataAutoTagState: "0",
      textBox14: "0",
      CRSConfirmationData2TextBox: "0",
      textBox26: "0",
      textBox27: "0",
      textBox29: "0",
      loyaltyNumberTextBox: "0",
      textBox32: "0",
    },
    {
      id: 4,
      asOfDate: "-",
      propertyCode: "-",
      pullDateId: "0",
      HotelName: "-",
      BusinessDateCaption: "-",
      BusinessDateVal: "-",
      PrintedCaption: "-",
      PrintedValue: "-",
      txtMobileKey: "0",
      textBox13: "0",
      textBox12: "0",
      textBox11: "0",
      textBox10: "0",
      textBox9: "0",
      textBox8: "0",
      textBox7: "0",
      textBox6: "0",
      textBox5: "0",
      textBox4: "0",
      textBox3: "0",
      textBox1: "0",
      textBox15: "0",
      textBox16: "0",
      arrDateCaptionTextBox: "0",
      depDateCaptionTextBox: "0",
      guestNameCaptionTextBox: "0",
      guestAddressCaptionTextBox: "0",
      companyNameCaptionTextBox: "0",
      commentsCaptionTextBox: "0",
      adultsCaptionTextBox: "0",
      childrenOverCaptionTextBox: "0",
      marketSegmentCaptionTextBox: "0",
      ratePlanCaptionTextBox: "0",
      amountCaptionTextBox: "0",
      iATACaptionTextBox: "0",
      guestStatusCaptionTextBox: "0",
      balanceCaptionTextBox: "0",
      roomTypeCaptionTextBox: "0",
      shareNameCaptionTextBox: "0",
      roomCaptionTextBox: "0",
      groupNameCaptionTextBox: "0",
      textBox20: "0",
      holdTypeCaptionTextBox: "0",
      confirmationCaptionTextBox: "0",
      textBox22: "0",
      textBox18: "0",
      txtAutotag: "0",
      txtAutoTagState: "0",
      textBox2: "0",
      textBox19: "0",
      textBox28: "0",
      textBox30: "0",
      textBox24: "0",
      textBox31: "0",
      arrDateDataTextBox: "-",
      depDateDataTextBox: "-",
      guestNameDataTextBox: "0",
      guestAddressDataTextBox: "0",
      guestCityStatePostalDataTextBox: "0",
      companyNameDataTextBox: "0",
      iATADataTextBox: "0",
      commentsDataTextBox: "0",
      adultsDataTextBox: "0",
      childrenOverDataTextBox: "0/0",
      marketSegmentDataTextBox: "0",
      ratePlanDataTextBox: "0",
      amountDataTextBox: "0",
      roomDataTextBox: "0",
      holdTypeDataTextBox: "0",
      confirmationDataTextBox: "0",
      guestStatusDataTextBox: "0",
      groupNameDataTextBox: "0",
      shareNameDataTextBox: "0",
      balanceDataTextBox: "0",
      roomTypeDataTextBox: "0",
      textBox17: "0",
      textBox21: "0",
      CRSConfirmationDataTextBox: "0",
      textBox23: "0",
      textBox25: "0",
      TxtdataAutoTagNumber: "0",
      txtdataAutoTagState: "0",
      textBox14: "0",
      CRSConfirmationData2TextBox: "0",
      textBox26: "0",
      textBox27: "0",
      textBox29: "0",
      loyaltyNumberTextBox: "0",
      textBox32: "0",
    },
    {
      id: 5,
      asOfDate: "-",
      propertyCode: "-",
      pullDateId: "0",
      HotelName: "-",
      BusinessDateCaption: "-",
      BusinessDateVal: "-",
      PrintedCaption: "-",
      PrintedValue: "-",
      txtMobileKey: "0",
      textBox13: "0",
      textBox12: "0",
      textBox11: "0",
      textBox10: "0",
      textBox9: "0",
      textBox8: "0",
      textBox7: "0",
      textBox6: "0",
      textBox5: "0",
      textBox4: "0",
      textBox3: "0",
      textBox1: "0",
      textBox15: "0",
      textBox16: "0",
      arrDateCaptionTextBox: "0",
      depDateCaptionTextBox: "0",
      guestNameCaptionTextBox: "0",
      guestAddressCaptionTextBox: "0",
      companyNameCaptionTextBox: "0",
      commentsCaptionTextBox: "0",
      adultsCaptionTextBox: "0",
      childrenOverCaptionTextBox: "0",
      marketSegmentCaptionTextBox: "0",
      ratePlanCaptionTextBox: "0",
      amountCaptionTextBox: "0",
      iATACaptionTextBox: "0",
      guestStatusCaptionTextBox: "0",
      balanceCaptionTextBox: "0",
      roomTypeCaptionTextBox: "0",
      shareNameCaptionTextBox: "0",
      roomCaptionTextBox: "0",
      groupNameCaptionTextBox: "0",
      textBox20: "0",
      holdTypeCaptionTextBox: "0",
      confirmationCaptionTextBox: "0",
      textBox22: "0",
      textBox18: "0",
      txtAutotag: "0",
      txtAutoTagState: "0",
      textBox2: "0",
      textBox19: "0",
      textBox28: "0",
      textBox30: "0",
      textBox24: "0",
      textBox31: "0",
      arrDateDataTextBox: "-",
      depDateDataTextBox: "-",
      guestNameDataTextBox: "0",
      guestAddressDataTextBox: "0",
      guestCityStatePostalDataTextBox: "0",
      companyNameDataTextBox: "0",
      iATADataTextBox: "0",
      commentsDataTextBox: "0",
      adultsDataTextBox: "0",
      childrenOverDataTextBox: "0/0",
      marketSegmentDataTextBox: "0",
      ratePlanDataTextBox: "0",
      amountDataTextBox: "0",
      roomDataTextBox: "0",
      holdTypeDataTextBox: "0",
      confirmationDataTextBox: "0",
      guestStatusDataTextBox: "0",
      groupNameDataTextBox: "0",
      shareNameDataTextBox: "0",
      balanceDataTextBox: "0",
      roomTypeDataTextBox: "0",
      textBox17: "0",
      textBox21: "0",
      CRSConfirmationDataTextBox: "0",
      textBox23: "0",
      textBox25: "0",
      TxtdataAutoTagNumber: "0",
      txtdataAutoTagState: "0",
      textBox14: "0",
      CRSConfirmationData2TextBox: "0",
      textBox26: "0",
      textBox27: "0",
      textBox29: "0",
      loyaltyNumberTextBox: "0",
      textBox32: "0",
    },
    {
      id: 6,
      asOfDate: "-",
      propertyCode: "-",
      pullDateId: "0",
      HotelName: "-",
      BusinessDateCaption: "-",
      BusinessDateVal: "-",
      PrintedCaption: "-",
      PrintedValue: "-",
      txtMobileKey: "0",
      textBox13: "0",
      textBox12: "0",
      textBox11: "0",
      textBox10: "0",
      textBox9: "0",
      textBox8: "0",
      textBox7: "0",
      textBox6: "0",
      textBox5: "0",
      textBox4: "0",
      textBox3: "0",
      textBox1: "0",
      textBox15: "0",
      textBox16: "0",
      arrDateCaptionTextBox: "0",
      depDateCaptionTextBox: "0",
      guestNameCaptionTextBox: "0",
      guestAddressCaptionTextBox: "0",
      companyNameCaptionTextBox: "0",
      commentsCaptionTextBox: "0",
      adultsCaptionTextBox: "0",
      childrenOverCaptionTextBox: "0",
      marketSegmentCaptionTextBox: "0",
      ratePlanCaptionTextBox: "0",
      amountCaptionTextBox: "0",
      iATACaptionTextBox: "0",
      guestStatusCaptionTextBox: "0",
      balanceCaptionTextBox: "0",
      roomTypeCaptionTextBox: "0",
      shareNameCaptionTextBox: "0",
      roomCaptionTextBox: "0",
      groupNameCaptionTextBox: "0",
      textBox20: "0",
      holdTypeCaptionTextBox: "0",
      confirmationCaptionTextBox: "0",
      textBox22: "0",
      textBox18: "0",
      txtAutotag: "0",
      txtAutoTagState: "0",
      textBox2: "0",
      textBox19: "0",
      textBox28: "0",
      textBox30: "0",
      textBox24: "0",
      textBox31: "0",
      arrDateDataTextBox: "-",
      depDateDataTextBox: "-",
      guestNameDataTextBox: "0",
      guestAddressDataTextBox: "0",
      guestCityStatePostalDataTextBox: "0",
      companyNameDataTextBox: "0",
      iATADataTextBox: "0",
      commentsDataTextBox: "0",
      adultsDataTextBox: "0",
      childrenOverDataTextBox: "0/0",
      marketSegmentDataTextBox: "0",
      ratePlanDataTextBox: "0",
      amountDataTextBox: "0",
      roomDataTextBox: "0",
      holdTypeDataTextBox: "0",
      confirmationDataTextBox: "0",
      guestStatusDataTextBox: "0",
      groupNameDataTextBox: "0",
      shareNameDataTextBox: "0",
      balanceDataTextBox: "0",
      roomTypeDataTextBox: "0",
      textBox17: "0",
      textBox21: "0",
      CRSConfirmationDataTextBox: "0",
      textBox23: "0",
      textBox25: "0",
      TxtdataAutoTagNumber: "0",
      txtdataAutoTagState: "0",
      textBox14: "0",
      CRSConfirmationData2TextBox: "0",
      textBox26: "0",
      textBox27: "0",
      textBox29: "0",
      loyaltyNumberTextBox: "0",
      textBox32: "0",
    },
    {
      id: 7,
      asOfDate: "-",
      propertyCode: "-",
      pullDateId: "0",
      HotelName: "-",
      BusinessDateCaption: "-",
      BusinessDateVal: "-",
      PrintedCaption: "-",
      PrintedValue: "-",
      txtMobileKey: "0",
      textBox13: "0",
      textBox12: "0",
      textBox11: "0",
      textBox10: "0",
      textBox9: "0",
      textBox8: "0",
      textBox7: "0",
      textBox6: "0",
      textBox5: "0",
      textBox4: "0",
      textBox3: "0",
      textBox1: "0",
      textBox15: "0",
      textBox16: "0",
      arrDateCaptionTextBox: "0",
      depDateCaptionTextBox: "0",
      guestNameCaptionTextBox: "0",
      guestAddressCaptionTextBox: "0",
      companyNameCaptionTextBox: "0",
      commentsCaptionTextBox: "0",
      adultsCaptionTextBox: "0",
      childrenOverCaptionTextBox: "0",
      marketSegmentCaptionTextBox: "0",
      ratePlanCaptionTextBox: "0",
      amountCaptionTextBox: "0",
      iATACaptionTextBox: "0",
      guestStatusCaptionTextBox: "0",
      balanceCaptionTextBox: "0",
      roomTypeCaptionTextBox: "0",
      shareNameCaptionTextBox: "0",
      roomCaptionTextBox: "0",
      groupNameCaptionTextBox: "0",
      textBox20: "0",
      holdTypeCaptionTextBox: "0",
      confirmationCaptionTextBox: "0",
      textBox22: "0",
      textBox18: "0",
      txtAutotag: "0",
      txtAutoTagState: "0",
      textBox2: "0",
      textBox19: "0",
      textBox28: "0",
      textBox30: "0",
      textBox24: "0",
      textBox31: "0",
      arrDateDataTextBox: "-",
      depDateDataTextBox: "-",
      guestNameDataTextBox: "0",
      guestAddressDataTextBox: "0",
      guestCityStatePostalDataTextBox: "0",
      companyNameDataTextBox: "0",
      iATADataTextBox: "0",
      commentsDataTextBox: "0",
      adultsDataTextBox: "0",
      childrenOverDataTextBox: "0/0",
      marketSegmentDataTextBox: "0",
      ratePlanDataTextBox: "0",
      amountDataTextBox: "0",
      roomDataTextBox: "0",
      holdTypeDataTextBox: "0",
      confirmationDataTextBox: "0",
      guestStatusDataTextBox: "0",
      groupNameDataTextBox: "0",
      shareNameDataTextBox: "0",
      balanceDataTextBox: "0",
      roomTypeDataTextBox: "0",
      textBox17: "0",
      textBox21: "0",
      CRSConfirmationDataTextBox: "0",
      textBox23: "0",
      textBox25: "0",
      TxtdataAutoTagNumber: "0",
      txtdataAutoTagState: "0",
      textBox14: "0",
      CRSConfirmationData2TextBox: "0",
      textBox26: "0",
      textBox27: "0",
      textBox29: "0",
      loyaltyNumberTextBox: "0",
      textBox32: "0",
    },
    {
      id: 8,
      asOfDate: "-",
      propertyCode: "-",
      pullDateId: "0",
      HotelName: "-",
      BusinessDateCaption: "-",
      BusinessDateVal: "-",
      PrintedCaption: "-",
      PrintedValue: "-",
      txtMobileKey: "0",
      textBox13: "0",
      textBox12: "0",
      textBox11: "0",
      textBox10: "0",
      textBox9: "0",
      textBox8: "0",
      textBox7: "0",
      textBox6: "0",
      textBox5: "0",
      textBox4: "0",
      textBox3: "0",
      textBox1: "0",
      textBox15: "0",
      textBox16: "0",
      arrDateCaptionTextBox: "0",
      depDateCaptionTextBox: "0",
      guestNameCaptionTextBox: "0",
      guestAddressCaptionTextBox: "0",
      companyNameCaptionTextBox: "0",
      commentsCaptionTextBox: "0",
      adultsCaptionTextBox: "0",
      childrenOverCaptionTextBox: "0",
      marketSegmentCaptionTextBox: "0",
      ratePlanCaptionTextBox: "0",
      amountCaptionTextBox: "0",
      iATACaptionTextBox: "0",
      guestStatusCaptionTextBox: "0",
      balanceCaptionTextBox: "0",
      roomTypeCaptionTextBox: "0",
      shareNameCaptionTextBox: "0",
      roomCaptionTextBox: "0",
      groupNameCaptionTextBox: "0",
      textBox20: "0",
      holdTypeCaptionTextBox: "0",
      confirmationCaptionTextBox: "0",
      textBox22: "0",
      textBox18: "0",
      txtAutotag: "0",
      txtAutoTagState: "0",
      textBox2: "0",
      textBox19: "0",
      textBox28: "0",
      textBox30: "0",
      textBox24: "0",
      textBox31: "0",
      arrDateDataTextBox: "-",
      depDateDataTextBox: "-",
      guestNameDataTextBox: "0",
      guestAddressDataTextBox: "0",
      guestCityStatePostalDataTextBox: "0",
      companyNameDataTextBox: "0",
      iATADataTextBox: "0",
      commentsDataTextBox: "0",
      adultsDataTextBox: "0",
      childrenOverDataTextBox: "0/0",
      marketSegmentDataTextBox: "0",
      ratePlanDataTextBox: "0",
      amountDataTextBox: "0",
      roomDataTextBox: "0",
      holdTypeDataTextBox: "0",
      confirmationDataTextBox: "0",
      guestStatusDataTextBox: "0",
      groupNameDataTextBox: "0",
      shareNameDataTextBox: "0",
      balanceDataTextBox: "0",
      roomTypeDataTextBox: "0",
      textBox17: "0",
      textBox21: "0",
      CRSConfirmationDataTextBox: "0",
      textBox23: "0",
      textBox25: "0",
      TxtdataAutoTagNumber: "0",
      txtdataAutoTagState: "0",
      textBox14: "0",
      CRSConfirmationData2TextBox: "0",
      textBox26: "0",
      textBox27: "0",
      textBox29: "0",
      loyaltyNumberTextBox: "0",
      textBox32: "0",
    },
    {
      id: 9,
      asOfDate: "-",
      propertyCode: "-",
      pullDateId: "0",
      HotelName: "-",
      BusinessDateCaption: "-",
      BusinessDateVal: "-",
      PrintedCaption: "-",
      PrintedValue: "-",
      txtMobileKey: "0",
      textBox13: "0",
      textBox12: "0",
      textBox11: "0",
      textBox10: "0",
      textBox9: "0",
      textBox8: "0",
      textBox7: "0",
      textBox6: "0",
      textBox5: "0",
      textBox4: "0",
      textBox3: "0",
      textBox1: "0",
      textBox15: "0",
      textBox16: "0",
      arrDateCaptionTextBox: "0",
      depDateCaptionTextBox: "0",
      guestNameCaptionTextBox: "0",
      guestAddressCaptionTextBox: "0",
      companyNameCaptionTextBox: "0",
      commentsCaptionTextBox: "0",
      adultsCaptionTextBox: "0",
      childrenOverCaptionTextBox: "0",
      marketSegmentCaptionTextBox: "0",
      ratePlanCaptionTextBox: "0",
      amountCaptionTextBox: "0",
      iATACaptionTextBox: "0",
      guestStatusCaptionTextBox: "0",
      balanceCaptionTextBox: "0",
      roomTypeCaptionTextBox: "0",
      shareNameCaptionTextBox: "0",
      roomCaptionTextBox: "0",
      groupNameCaptionTextBox: "0",
      textBox20: "0",
      holdTypeCaptionTextBox: "0",
      confirmationCaptionTextBox: "0",
      textBox22: "0",
      textBox18: "0",
      txtAutotag: "0",
      txtAutoTagState: "0",
      textBox2: "0",
      textBox19: "0",
      textBox28: "0",
      textBox30: "0",
      textBox24: "0",
      textBox31: "0",
      arrDateDataTextBox: "-",
      depDateDataTextBox: "-",
      guestNameDataTextBox: "0",
      guestAddressDataTextBox: "0",
      guestCityStatePostalDataTextBox: "0",
      companyNameDataTextBox: "0",
      iATADataTextBox: "0",
      commentsDataTextBox: "0",
      adultsDataTextBox: "0",
      childrenOverDataTextBox: "0/0",
      marketSegmentDataTextBox: "0",
      ratePlanDataTextBox: "0",
      amountDataTextBox: "0",
      roomDataTextBox: "0",
      holdTypeDataTextBox: "0",
      confirmationDataTextBox: "0",
      guestStatusDataTextBox: "0",
      groupNameDataTextBox: "0",
      shareNameDataTextBox: "0",
      balanceDataTextBox: "0",
      roomTypeDataTextBox: "0",
      textBox17: "0",
      textBox21: "0",
      CRSConfirmationDataTextBox: "0",
      textBox23: "0",
      textBox25: "0",
      TxtdataAutoTagNumber: "0",
      txtdataAutoTagState: "0",
      textBox14: "0",
      CRSConfirmationData2TextBox: "0",
      textBox26: "0",
      textBox27: "0",
      textBox29: "0",
      loyaltyNumberTextBox: "0",
      textBox32: "0",
    },
    {
      id: 10,
      asOfDate: "-",
      propertyCode: "-",
      pullDateId: "0",
      HotelName: "-",
      BusinessDateCaption: "-",
      BusinessDateVal: "-",
      PrintedCaption: "-",
      PrintedValue: "-",
      txtMobileKey: "0",
      textBox13: "0",
      textBox12: "0",
      textBox11: "0",
      textBox10: "0",
      textBox9: "0",
      textBox8: "0",
      textBox7: "0",
      textBox6: "0",
      textBox5: "0",
      textBox4: "0",
      textBox3: "0",
      textBox1: "0",
      textBox15: "0",
      textBox16: "0",
      arrDateCaptionTextBox: "0",
      depDateCaptionTextBox: "0",
      guestNameCaptionTextBox: "0",
      guestAddressCaptionTextBox: "0",
      companyNameCaptionTextBox: "0",
      commentsCaptionTextBox: "0",
      adultsCaptionTextBox: "0",
      childrenOverCaptionTextBox: "0",
      marketSegmentCaptionTextBox: "0",
      ratePlanCaptionTextBox: "0",
      amountCaptionTextBox: "0",
      iATACaptionTextBox: "0",
      guestStatusCaptionTextBox: "0",
      balanceCaptionTextBox: "0",
      roomTypeCaptionTextBox: "0",
      shareNameCaptionTextBox: "0",
      roomCaptionTextBox: "0",
      groupNameCaptionTextBox: "0",
      textBox20: "0",
      holdTypeCaptionTextBox: "0",
      confirmationCaptionTextBox: "0",
      textBox22: "0",
      textBox18: "0",
      txtAutotag: "0",
      txtAutoTagState: "0",
      textBox2: "0",
      textBox19: "0",
      textBox28: "0",
      textBox30: "0",
      textBox24: "0",
      textBox31: "0",
      arrDateDataTextBox: "-",
      depDateDataTextBox: "-",
      guestNameDataTextBox: "0",
      guestAddressDataTextBox: "0",
      guestCityStatePostalDataTextBox: "0",
      companyNameDataTextBox: "0",
      iATADataTextBox: "0",
      commentsDataTextBox: "0",
      adultsDataTextBox: "0",
      childrenOverDataTextBox: "0/0",
      marketSegmentDataTextBox: "0",
      ratePlanDataTextBox: "0",
      amountDataTextBox: "0",
      roomDataTextBox: "0",
      holdTypeDataTextBox: "0",
      confirmationDataTextBox: "0",
      guestStatusDataTextBox: "0",
      groupNameDataTextBox: "0",
      shareNameDataTextBox: "0",
      balanceDataTextBox: "0",
      roomTypeDataTextBox: "0",
      textBox17: "0",
      textBox21: "0",
      CRSConfirmationDataTextBox: "0",
      textBox23: "0",
      textBox25: "0",
      TxtdataAutoTagNumber: "0",
      txtdataAutoTagState: "0",
      textBox14: "0",
      CRSConfirmationData2TextBox: "0",
      textBox26: "0",
      textBox27: "0",
      textBox29: "0",
      loyaltyNumberTextBox: "0",
      textBox32: "0",
    },
  ];

  const [guestNameData, setGuestNameData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [startRangeDate, setStartRangeDate] = useState();
  const [endRangeDate, setEndRangeDate] = useState();
  const startRangeDateRef = useRef();
  const endRangeDateRef = useRef();
  const [showNoteSidebar, setShowNoteSidebar] = useState(false);
  const [widgetModal, setWidgetModal] = useState(false);
  const widgetDetailRef = useRef();
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const propertyDetailFromRTK = useSelector(
    (store) => store.headerProperty.propertyDetail
  );
  const defaultAsOfDateFromRTK = useSelector(
    (store) => store.headerProperty.defaultAsOfDate
  );
  const { snapshottitle, propertyCode, snapshotId } = useParams();
  const closeWidgetModal = () => {
    setWidgetModal(false);
  };
  const closeNoteSideBar = () => {
    setShowNoteSidebar(false);
  };
  const handleDateChange = (event) => {
    const [start, end] = event;
    let startSelectedDate = "",
      endSelectedDate = "";
    if (start) {
      let date = new Date(start);
      let month =
        date.getMonth() + 1 > 9
          ? date.getMonth() + 1
          : `0${date.getMonth() + 1}`;
      let dateFormat =
        date?.getDate() > 9 ? date.getDate() : `0${date.getDate()}`;
      startSelectedDate = `${date.getFullYear()}-${month}-${dateFormat}`;
      startRangeDateRef.current = startSelectedDate;
    }
    if (end) {
      let date = new Date(end);
      let month =
        date.getMonth() + 1 > 9
          ? date.getMonth() + 1
          : `0${date.getMonth() + 1}`;
      let dateFormat =
        date?.getDate() > 9 ? date.getDate() : `0${date.getDate()}`;
      endSelectedDate = `${date.getFullYear()}-${month}-${dateFormat}`;
      endRangeDateRef.current = endSelectedDate;
    }

    setStartRangeDate(event[0]);
    setEndRangeDate(event[1]);
    if (event[0] !== null && event[1] !== null) {
      // getGuestNameDetail();
    }
  };

  const getGuestNameDetail = async () => {
    setLoading(true);
    let payload = {
      AsOfDate: para?.asOfDate,
      propertyCode: para?.propertyCode,
    };
    if (startRangeDateRef.current && endRangeDateRef.current) {
      payload = {
        ...payload,
        startDate: startRangeDateRef.current,
        endDate: endRangeDateRef.current,
      };
    }
    let guestNameResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.SnapShot +
          API_URL.WIDGET_MODULE.BEST_WESTERN_GUEST_NAME,
        body: payload,
      },
      false
    );

    let response = apiResponse(false, guestNameResponse);

    if (response?.isValidate) {
      setLoading(false);
      setGuestNameData(response?.data?.data);
    }

    if (!response?.isValidate) {
      setLoading(false);
      setGuestNameData(null);
    }
  };

  useEffect(() => {
    if (api === 0) {
      setGuestNameData(tableData);
    } else {
      if (para?.asOfDate) {
        getGuestNameDetail();
      }
    }
  }, [para?.asOfDate]);

  const [tblids, settblids] = useState({
    tblBestWesternGuestName: commonService.GenerateGUID(),
  });

  return (
    <div className="widget-container">
      {api !== 0 && (
        <div className="gap-1 widget-container-menu">
          <Button className="heading-menu-btns" type="button">
            <img
              src={NoteIcon}
              alt="menuIcon"
              onClick={() => {
                setShowNoteSidebar(true);
              }}
            />
          </Button>
          <Button className="heading-menu-btns" type="button" title="Send Mail">
            <img
              src={emailIcon}
              alt="emailIcon"
              onClick={() => {
                commonService.fnSendWidgetMail(
                  tblids.tblBestWesternGuestName,
                  "Best Western Guest Name",
                  snapshotId,
                  defaultAsOfDateFromRTK,
                  "",
                  para?.widgetId
                );
              }}
            />
          </Button>
          <Button className="heading-menu-btns" type="button">
            <img
              src={infoIcon}
              alt="info"
              onClick={() => {
                widgetDetailRef.current = {
                  widgetdetails: "",
                  usernote: "",
                  compName: compName?.widgetBestWesternGuestName,
                  widgetId: para?.widgetId,
                };
                setWidgetModal(true);
              }}
            />
          </Button>
          <Button className="heading-menu-btns" type="button">
            <img
              src={downloadIcon}
              alt="menuIcon"
              import={tblids.tblBestWesternGuestName}
              onClick={() =>
                commonService.fnExportInCSV(
                  tblids.tblBestWesternGuestName,
                  // "Best Western Guest Name"
                  `${para?.propertyCode}-Best Western Guest Name-${moment(
                    new Date()
                  ).format("MMDDYYYY")}`
                )
              }
            />
          </Button>
        </div>
      )}
      <div className="ViewPropertySetupTable">
        <div className="stripped__table">
          <Table
            responsive
            id={tblids.tblBestWesternGuestName}
            className="positionStaticTable"
          >
            <thead>
              <tr>
                <th
                  className="width175"
                  onClick={() => {
                    setOpenDatePicker(true);
                  }}
                >
                  Date{" "}
                  <DatePicker
                    onChange={(event) => handleDateChange(event)}
                    startDate={startRangeDate}
                    endDate={endRangeDate}
                    selectsRange
                    monthsShown={2}
                    open={openDatePicker}
                    shouldCloseOnSelect={false}
                  >
                    <div className="w-100" style={{ display: "inline-block" }}>
                      <div className="content-inside-calendar">
                        <p>
                          {startRangeDate
                            ? commonService.getDateInFormat(startRangeDate)
                            : ""}
                          {startRangeDate && endRangeDate ? " - " : ""}
                          {endRangeDate
                            ? commonService.getDateInFormat(endRangeDate)
                            : ""}
                        </p>
                        <div className="d-flex align-items-center gap-2">
                          <button
                            className="secondary-btn"
                            onClick={(e) => {
                              e.stopPropagation();
                              setStartRangeDate("");
                              setEndRangeDate("");
                              setOpenDatePicker(false);
                            }}
                          >
                            Cancel
                          </button>
                          <button
                            disabled={
                              startRangeDate && endRangeDate ? false : true
                            }
                            onClick={(e) => {
                              e.stopPropagation();
                              if (
                                startRangeDateRef.current &&
                                endRangeDateRef.current
                              ) {
                                getGuestNameDetail();
                              }

                              setOpenDatePicker(false);
                            }}
                            className="primary-btn"
                          >
                            Apply
                          </button>
                        </div>
                      </div>
                    </div>
                  </DatePicker>
                  {startRangeDate && endRangeDate && (
                    <span
                      style={{ display: "none" }}
                    >{`${commonService.getDateInFormat(
                      startRangeDate
                    )} - ${commonService.getDateInFormat(endRangeDate)}`}</span>
                  )}
                </th>
                <th colSpan={23}></th>
              </tr>
              <tr>
                <th className="width100">asOfDate</th>
                {/* <th className="width100">propertyCode</th> */}
                {/* <th className="width100 text-center">pullDateId</th> */}
                <th className="width100 text-center">HotelName</th>
                <th className="width100 text-center">BusinessDateCaption</th>
                <th className="width100 text-center">BusinessDateVal</th>
                <th className="width100 text-center">PrintedCaption</th>
                <th className="width100 text-center">PrintedValue</th>
                <th className="width100 text-center">txtMobileKey</th>
                <th className="width100 text-center">textBox13</th>
                <th className="width100 text-center">textBox12</th>
                <th className="width100 text-center">textBox11</th>
                <th className="width100 text-center">textBox10</th>
                <th className="width100 text-center">textBox9</th>
                <th className="width100 text-center">textBox8</th>
                <th className="width100 text-center">textBox7</th>
                <th className="width100 text-center">textBox6</th>
                <th className="width100 text-center">textBox5</th>
                <th className="width100 text-center">textBox4</th>
                <th className="width100 text-center">textBox3</th>
                <th className="width100 text-center">textBox1</th>
                <th className="width100 text-center">textBox15</th>
                <th className="width100 text-center">textBox16</th>
                <th className="width100 text-center">arrDateCaptionTextBox</th>
                <th className="width100 text-center">depDateCaptionTextBox</th>
                <th className="width100 text-center">
                  guestNameCaptionTextBox
                </th>
                <th className="width100 text-center">
                  guestAddressCaptionTextBox
                </th>
                <th className="width100 text-center">
                  companyNameCaptionTextBox
                </th>
                <th className="width100 text-center">commentsCaptionTextBox</th>
                <th className="width100 text-center">adultsCaptionTextBox</th>
                <th className="width100 text-center">
                  childrenOverCaptionTextBox
                </th>
                <th className="width100 text-center">
                  marketSegmentCaptionTextBox
                </th>
                <th className="width100 text-center">ratePlanCaptionTextBox</th>
                <th className="width100 text-center">amountCaptionTextBox</th>
                <th className="width100 text-center">iATACaptionTextBox</th>
                <th className="width100 text-center">
                  guestStatusCaptionTextBox
                </th>
                <th className="width100 text-center">balanceCaptionTextBox</th>
                <th className="width100 text-center">roomTypeCaptionTextBox</th>
                <th className="width100 text-center">
                  shareNameCaptionTextBox
                </th>
                <th className="width100 text-center">roomCaptionTextBox</th>
                <th className="width100 text-center">
                  groupNameCaptionTextBox
                </th>
                <th className="width100 text-center">textBox20</th>
                <th className="width100 text-center">holdTypeCaptionTextBox</th>
                <th className="width100 text-center">
                  confirmationCaptionTextBox
                </th>
                <th className="width100 text-center">textBox22</th>
                <th className="width100 text-center">textBox18</th>
                <th className="width100 text-center">txtAutotag</th>
                <th className="width100 text-center">txtAutoTagState</th>
                <th className="width100 text-center">textBox2</th>
                <th className="width100 text-center">textBox19</th>
                <th className="width100 text-center">textBox28</th>
                <th className="width100 text-center">textBox30</th>
                <th className="width100 text-center">textBox24</th>
                <th className="width100 text-center">textBox31</th>
                <th className="width100 text-center">arrDateDataTextBox</th>
                <th className="width100 text-center">depDateDataTextBox</th>
                <th className="width100 text-center">guestNameDataTextBox</th>
                <th className="width100 text-center">
                  guestAddressDataTextBox
                </th>
                <th className="width100 text-center">
                  guestCityStatePostalDataTextBox
                </th>
                <th className="width100 text-center">companyNameDataTextBox</th>
                <th className="width100 text-center">iATADataTextBox</th>
                <th className="width100 text-center">commentsDataTextBox</th>
                <th className="width100 text-center">adultsDataTextBox</th>
                <th className="width100 text-center">
                  childrenOverDataTextBox
                </th>
                <th className="width100 text-center">
                  marketSegmentDataTextBox
                </th>
                <th className="width100 text-center">ratePlanDataTextBox</th>
                <th className="width100 text-center">amountDataTextBox</th>
                <th className="width100 text-center">roomDataTextBox</th>
                <th className="width100 text-center">holdTypeDataTextBox</th>
                <th className="width100 text-center">
                  confirmationDataTextBox
                </th>
                <th className="width100 text-center">guestStatusDataTextBox</th>
                <th className="width100 text-center">groupNameDataTextBox</th>
                <th className="width100 text-center">shareNameDataTextBox</th>
                <th className="width100 text-center">balanceDataTextBox</th>
                <th className="width100 text-center">roomTypeDataTextBox</th>
                <th className="width100 text-center">textBox17</th>
                <th className="width100 text-center">textBox21</th>
                <th className="width100 text-center">
                  CRSConfirmationDataTextBox
                </th>
                <th className="width100 text-center">textBox23</th>
                <th className="width100 text-center">textBox25</th>
                <th className="width100 text-center">TxtdataAutoTagNumber</th>
                <th className="width100 text-center">txtdataAutoTagState</th>
                <th className="width100 text-center">textBox14</th>
                <th className="width100 text-center">
                  CRSConfirmationData2TextBox
                </th>
                <th className="width100 text-center">textBox26</th>
                <th className="width100 text-center">textBox27</th>
                <th className="width100 text-center">textBox29</th>
                <th className="width100 text-center">loyaltyNumberTextBox</th>
                <th className="width100 text-center">textBox32</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr style={{ height: "80px", position: "relative" }}>
                  <Loader />
                </tr>
              ) : guestNameData && guestNameData?.length > 0 ? (
                guestNameData?.map((item, index) => (
                  <tr key={item?.id} className="shadowOnHover">
                    <td>
                      {item?.asOfDate
                        ? commonService.getDateInFormat(item?.asOfDate)
                        : ""}
                    </td>
                    {/* <td>{item?.propertyCode}</td>
                    <td>{item?.pullDateId}</td> */}
                    <td className="text-center">{item?.HotelName}</td>
                    <td className="text-center">{item?.BusinessDateCaption}</td>
                    <td className="text-center">
                      {item?.BusinessDateVal
                        ? commonService.getDateInFormat(item?.BusinessDateVal)
                        : ""}
                    </td>
                    <td className="text-center">{item?.PrintedCaption}</td>
                    <td className="text-center">
                      {item?.PrintedValue
                        ? commonService.getDateInFormat(item?.PrintedValue)
                        : ""}
                    </td>
                    <td className="text-center">{item?.txtMobileKey}</td>
                    <td className="text-center">{item?.textBox13}</td>
                    <td className="text-center">{item?.textBox12}</td>
                    <td className="text-center">{item?.textBox11}</td>
                    <td className="text-center">{item?.textBox10}</td>
                    <td className="text-center">{item?.textBox9}</td>
                    <td className="text-center">{item?.textBox8}</td>
                    <td className="text-center">{item?.textBox7}</td>
                    <td className="text-center">{item?.textBox6}</td>
                    <td className="text-center">{item?.textBox5}</td>
                    <td className="text-center">{item?.textBox4}</td>
                    <td className="text-center">{item?.textBox3}</td>
                    <td className="text-center">{item?.textBox1}</td>
                    <td className="text-center">{item?.textBox15}</td>
                    <td className="text-center">{item?.textBox16}</td>
                    <td className="text-center">
                      {item?.arrDateCaptionTextBox}
                    </td>
                    <td className="text-center">
                      {item?.depDateCaptionTextBox}
                    </td>
                    <td className="text-center">
                      {item?.guestNameCaptionTextBox}
                    </td>
                    <td className="text-center">
                      {item?.guestAddressCaptionTextBox}
                    </td>
                    <td className="text-center">
                      {item?.companyNameCaptionTextBox}
                    </td>
                    <td className="text-center">
                      {item?.commentsCaptionTextBox}
                    </td>
                    <td className="text-center">
                      {item?.adultsCaptionTextBox}
                    </td>
                    <td className="text-center">
                      {item?.childrenOverCaptionTextBox}
                    </td>
                    <td className="text-center">
                      {item?.marketSegmentCaptionTextBox}
                    </td>
                    <td className="text-center">
                      {item?.ratePlanCaptionTextBox}
                    </td>
                    <td className="text-center">
                      {item?.amountCaptionTextBox}
                    </td>
                    <td className="text-center">{item?.iATACaptionTextBox}</td>
                    <td className="text-center">
                      {item?.guestStatusCaptionTextBox}
                    </td>
                    <td className="text-center">
                      {item?.balanceCaptionTextBox}
                    </td>
                    <td className="text-center">
                      {item?.roomTypeCaptionTextBox}
                    </td>
                    <td className="text-center">
                      {item?.shareNameCaptionTextBox}
                    </td>
                    <td className="text-center">{item?.roomCaptionTextBox}</td>
                    <td className="text-center">
                      {item?.groupNameCaptionTextBox}
                    </td>
                    <td className="text-center">{item?.textBox20}</td>
                    <td className="text-center">
                      {item?.holdTypeCaptionTextBox}
                    </td>
                    <td className="text-center">
                      {item?.confirmationCaptionTextBox}
                    </td>
                    <td className="text-center">{item?.textBox22}</td>
                    <td className="text-center">{item?.textBox18}</td>
                    <td className="text-center">{item?.txtAutotag}</td>
                    <td className="text-center">{item?.txtAutoTagState}</td>
                    <td className="text-center">{item?.textBox2}</td>
                    <td className="text-center">{item?.textBox19}</td>
                    <td className="text-center">{item?.textBox28}</td>
                    <td className="text-center">{item?.textBox30}</td>
                    <td className="text-center">{item?.textBox24}</td>
                    <td className="text-center">{item?.textBox31}</td>
                    <td className="text-center">
                      {item?.arrDateDataTextBox
                        ? commonService.getDateInFormat(
                            item?.arrDateDataTextBox
                          )
                        : ""}
                    </td>
                    <td className="text-center">
                      {item?.depDateDataTextBox
                        ? commonService.getDateInFormat(
                            item?.depDateDataTextBox
                          )
                        : ""}
                    </td>
                    <td className="text-center">
                      {item?.guestNameDataTextBox}
                    </td>
                    <td className="text-center">
                      {item?.guestAddressDataTextBox}
                    </td>
                    <td className="text-center">
                      {item?.guestCityStatePostalDataTextBox}
                    </td>
                    <td className="text-center">
                      {item?.companyNameDataTextBox}
                    </td>
                    <td className="text-center">{item?.iATADataTextBox}</td>
                    <td className="text-center">{item?.commentsDataTextBox}</td>
                    <td className="text-center">{item?.adultsDataTextBox}</td>
                    <td className="text-center">
                      {item?.childrenOverDataTextBox}
                    </td>
                    <td className="text-center">
                      {item?.marketSegmentDataTextBox}
                    </td>
                    <td className="text-center">{item?.ratePlanDataTextBox}</td>
                    <td className="text-center">{item?.amountDataTextBox}</td>
                    <td className="text-center">{item?.roomDataTextBox}</td>
                    <td className="text-center">{item?.holdTypeDataTextBox}</td>
                    <td className="text-center">
                      {item?.confirmationDataTextBox}
                    </td>
                    <td className="text-center">
                      {item?.guestStatusDataTextBox}
                    </td>
                    <td className="text-center">
                      {item?.groupNameDataTextBox}
                    </td>
                    <td className="text-center">
                      {item?.shareNameDataTextBox}
                    </td>
                    <td className="text-center">{item?.balanceDataTextBox}</td>
                    <td className="text-center">{item?.roomTypeDataTextBox}</td>
                    <td className="text-center">{item?.textBox17}</td>
                    <td className="text-center">{item?.textBox21}</td>
                    <td className="text-center">
                      {item?.CRSConfirmationDataTextBox}
                    </td>
                    <td className="text-center">{item?.textBox23}</td>
                    <td className="text-center">{item?.textBox25}</td>
                    <td className="text-center">
                      {item?.TxtdataAutoTagNumber}
                    </td>
                    <td className="text-center">{item?.txtdataAutoTagState}</td>
                    <td className="text-center">{item?.textBox14}</td>
                    <td className="text-center">
                      {item?.CRSConfirmationData2TextBox}
                    </td>
                    <td className="text-center">{item?.textBox26}</td>
                    <td className="text-center">{item?.textBox27}</td>
                    <td className="text-center">{item?.textBox29}</td>
                    <td className="text-center">
                      {item?.loyaltyNumberTextBox}
                    </td>
                    <td className="text-center">{item?.textBox32}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td className="text-center" colSpan={24}>
                    No Data Found
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </div>
      {/* {showNoteSidebar && (
        <WidgetNote
          show={showNoteSidebar}
          handleClose={closeNoteSideBar}
          widgetId={para?.widgetId}
          propertyId={propertyDetailFromRTK?.propertyid}
          rowTitle={para?.rowTitle}
        />
      )} */}
      {showNoteSidebar && (
        <AddWidgetModal
          showModal={showNoteSidebar}
          closeModal={closeNoteSideBar}
          widgetId={para?.widgetId}
          propertyId={propertyDetailFromRTK?.propertyid}
          rowTitle={para?.rowTitle}
        />
      )}
      {widgetModal && (
        <WidgetDetailModal
          showModal={widgetModal}
          closeModal={closeWidgetModal}
          data={widgetDetailRef.current}
        />
      )}
    </div>
  );
};

export default BestWesternGuestName;
