import React, { useEffect, useRef, useState } from "react";
import { Button, Table } from "react-bootstrap";
import { apiCall } from "../../../../utils/axiosService";
import { apiResponse } from "../../../../utils/apiResponse";
import Loader from "../../../Loader/Loader";
import { commonService } from "../../../../utils/commonService";
import NoteIcon from "../../../../Assets/Images/CRM/notes.svg";
import downloadIcon from "../../../../Assets/Images/Operation/downloadIcon.svg";
import { API_URL } from "../../../../utils/apiRoutes";
import { compName } from "../../../../utils/constant";
import WidgetDetailModal from "../../WidgetDetailModal";
import infoIcon from "../../../../Assets/Images/Action Icons/infoIcon..svg";
import { useSelector } from "react-redux";
import WidgetNote from "../../WidgetNote";
import AddWidgetModal from "../../AddWidgetModal";
import { useParams } from "react-router-dom";
import emailIcon from "../../../../Assets/Images/Email.svg";
import Comments from "../Comments/Comment";
import moment from "moment";

const BookingPaceTable = ({
  setCompDataFromChild,
  rowIndex,
  colIndex,
  api,
  para,
  setting,
}) => {
  const tableData = [
    {
      id: 1,
      propertyCode: "-",
      AsOfDate: "",
      Dates: "",
      TotalRevenueCurrentYear: "0",
      TotalRevenuePreviousYear: "0",
      TotalRMSCurrentYear: 0,
      TotalRMSPreviousYear: 0,
      TotalADRCurrentYear: "0",
      TotalADRPreviousYear: "0",
    },
    {
      id: 2,
      propertyCode: "-",
      AsOfDate: "",
      Dates: "",
      TotalRevenueCurrentYear: "0",
      TotalRevenuePreviousYear: "0",
      TotalRMSCurrentYear: 0,
      TotalRMSPreviousYear: 0,
      TotalADRCurrentYear: "0",
      TotalADRPreviousYear: "0",
    },
    {
      id: 3,
      propertyCode: "-",
      AsOfDate: "",
      Dates: "",
      TotalRevenueCurrentYear: "0",
      TotalRevenuePreviousYear: "0",
      TotalRMSCurrentYear: 0,
      TotalRMSPreviousYear: 0,
      TotalADRCurrentYear: "0",
      TotalADRPreviousYear: "0",
    },
    {
      id: 4,
      propertyCode: "-",
      AsOfDate: "",
      Dates: "",
      TotalRevenueCurrentYear: "0",
      TotalRevenuePreviousYear: "0",
      TotalRMSCurrentYear: 0,
      TotalRMSPreviousYear: 0,
      TotalADRCurrentYear: "0",
      TotalADRPreviousYear: "0",
    },
    {
      id: 5,
      propertyCode: "-",
      AsOfDate: "",
      Dates: "",
      TotalRevenueCurrentYear: "0",
      TotalRevenuePreviousYear: "0",
      TotalRMSCurrentYear: 0,
      TotalRMSPreviousYear: 0,
      TotalADRCurrentYear: "0",
      TotalADRPreviousYear: "0",
    },
    {
      id: 6,
      propertyCode: "-",
      AsOfDate: "",
      Dates: "",
      TotalRevenueCurrentYear: "0",
      TotalRevenuePreviousYear: "0",
      TotalRMSCurrentYear: 0,
      TotalRMSPreviousYear: 0,
      TotalADRCurrentYear: "0",
      TotalADRPreviousYear: "0",
    },
    {
      id: 7,
      propertyCode: "-",
      AsOfDate: "",
      Dates: "",
      TotalRevenueCurrentYear: "0",
      TotalRevenuePreviousYear: "0",
      TotalRMSCurrentYear: 0,
      TotalRMSPreviousYear: 0,
      TotalADRCurrentYear: "0",
      TotalADRPreviousYear: "0",
    },
    {
      id: 8,
      propertyCode: "-",
      AsOfDate: "",
      Dates: "",
      TotalRevenueCurrentYear: "0",
      TotalRevenuePreviousYear: "0",
      TotalRMSCurrentYear: 0,
      TotalRMSPreviousYear: 0,
      TotalADRCurrentYear: "0",
      TotalADRPreviousYear: "0",
    },
    {
      id: 9,
      propertyCode: "-",
      AsOfDate: "",
      Dates: "",
      TotalRevenueCurrentYear: "0",
      TotalRevenuePreviousYear: "0",
      TotalRMSCurrentYear: 0,
      TotalRMSPreviousYear: 0,
      TotalADRCurrentYear: "0",
      TotalADRPreviousYear: "0",
    },
    {
      id: 10,
      propertyCode: "-",
      AsOfDate: "",
      Dates: "",
      TotalRevenueCurrentYear: "0",
      TotalRevenuePreviousYear: "0",
      TotalRMSCurrentYear: 0,
      TotalRMSPreviousYear: 0,
      TotalADRCurrentYear: "0",
      TotalADRPreviousYear: "0",
    },
  ];
  const [bookingPaceData, setBookingPaceData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showNoteSidebar, setShowNoteSidebar] = useState(false);
  const propertyDetailFromRTK = useSelector(
    (store) => store.headerProperty.propertyDetail
  );
  const defaultAsOfDateFromRTK = useSelector(
    (store) => store.headerProperty.defaultAsOfDate
  );
  const bookingPaceMonth = useSelector(
    (store) => store.headerProperty.bookingPaceMonth
  );
  const { snapshottitle, propertyCode, snapshotId } = useParams();
  const closeNoteSideBar = () => {
    setShowNoteSidebar(false);
  };
  const [widgetModal, setWidgetModal] = useState(false);
  const widgetDetailRef = useRef();

  const closeWidgetModal = () => {
    setWidgetModal(false);
  };

  const getBookingPaceDetail = async () => {
    setLoading(true);
    let bookingPaceResponse = await apiCall(
      {
        method: "POST",
        url: API_URL.BASE_API_URL.SnapShot + API_URL.BOOKING_PACE.CHART,
        body: {
          AsOfDate: bookingPaceMonth ? bookingPaceMonth : para?.asOfDate,
          propertyCode: para?.propertyCode,
        },
      },
      false
    );

    let response = apiResponse(false, bookingPaceResponse);

    if (response?.isValidate) {
      setBookingPaceData(response?.data?.data);
      setLoading(false);
    }

    if (!response?.isValidate) {
      setBookingPaceData(tableData);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (api === 0) {
      setBookingPaceData(tableData);
    } else {
      if (para?.asOfDate) {
        getBookingPaceDetail();
      }
    }
  }, [para?.asOfDate, bookingPaceMonth]);

  const [tblids, settblids] = useState({
    tblBookingPace: commonService.GenerateGUID(),
  });

  return (
    <div className="widget-container">
      {api !== 0 && (
        <div className="gap-1 widget-container-menu">
          {/* <Button className="heading-menu-btns" type="button">
            <img
              src={NoteIcon}
              alt="menuIcon"
              onClick={() => {
                setShowNoteSidebar(true);
              }}
            />
          </Button> */}
          <Button className="heading-menu-btns" type="button" title="Send Mail">
            <img
              src={emailIcon}
              alt="emailIcon"
              onClick={() => {
                commonService.fnSendWidgetMail(
                  tblids.tblBookingPace,
                  "Booking Pace",
                  snapshotId,
                  defaultAsOfDateFromRTK,
                  "",
                  para?.widgetId
                );
              }}
            />
          </Button>
          <Button className="heading-menu-btns" type="button">
            <img
              src={infoIcon}
              alt="info"
              onClick={() => {
                widgetDetailRef.current = {
                  widgetdetails: "",
                  usernote: "",
                  compName: compName?.bookingPaceTable,
                  widgetId: para?.widgetId,
                };
                setWidgetModal(true);
              }}
            />
          </Button>
          <Button className="heading-menu-btns" type="button">
            <img
              src={downloadIcon}
              alt="menuIcon"
              import={tblids.tblBookingPace}
              onClick={() =>
                commonService.fnExportInCSV(
                  tblids.tblBookingPace,
                  // "Booking Pace"
                  `${para?.propertyCode}-Booking Pace-${moment(
                    new Date()
                  ).format("MMDDYYYY")}`
                )
              }
            />
          </Button>
        </div>
      )}
      <div className="ViewPropertySetupTable">
        <div className="stripped__table">
          <Table id={tblids.tblBookingPace} responsive>
            <thead>
              <tr>
                <th>Date</th>
                <th className="text-end">Total Revenue Current Year</th>
                <th className="text-end">Total Revenue Previous Year</th>
                <th className="text-center">Total RMS Current Year</th>
                <th className="text-center">Total RMS Previous Year</th>
                <th className="text-end">Total ADR Current Year</th>
                <th className="text-end">Total ADR Previous Year</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr style={{ height: "80px", position: "relative" }}>
                  <Loader />
                </tr>
              ) : (
                bookingPaceData?.map((item, index) => (
                  <tr key={item?.id} className="shadowOnHover">
                    <td>
                      {item?.Dates
                        ? commonService?.getDateInFormat(item?.Dates)
                        : ""}
                    </td>
                    <td className="text-end">
                      {item?.TotalRevenueCurrentYear
                        ? `${commonService.formateAmount(
                            Math.round(item?.TotalRevenueCurrentYear)
                          )}`
                        : `${commonService.formateAmount(0)}`}
                    </td>
                    <td className="text-end">
                      {item?.TotalRevenuePreviousYear
                        ? `${commonService.formateAmount(
                            Math.round(item?.TotalRevenuePreviousYear)
                          )}`
                        : `${commonService.formateAmount(0)}`}
                    </td>
                    <td className="text-center">
                      {item?.TotalRMSCurrentYear
                        ? commonService.formateRoom(item?.TotalRMSCurrentYear)
                        : 0}
                    </td>
                    <td className="text-center">
                      {item?.TotalRMSPreviousYear
                        ? commonService.formateRoom(item?.TotalRMSPreviousYear)
                        : 0}
                    </td>
                    <td className="text-end">
                      {item?.TotalADRCurrentYear
                        ? `${commonService.formateAmount(
                            Math.round(item?.TotalADRCurrentYear)
                          )}`
                        : `${commonService.formateAmount(0)}`}
                    </td>
                    <td className="text-end">
                      {item?.TotalADRPreviousYear
                        ? `${commonService.formateAmount(
                            Math.round(item?.TotalADRPreviousYear)
                          )}`
                        : `${commonService.formateAmount(0)}`}
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </Table>
        </div>
      </div>
      {api !== 0 && (
        <Comments widgetDetails={para} editorId={"BookingPaceTable"} />
      )}

      {/* {showNoteSidebar && (
                <WidgetNote
                    show={showNoteSidebar}
                    handleClose={closeNoteSideBar}
                    widgetId={para?.widgetId}
                    propertyId={propertyDetailFromRTK?.propertyid}
                    rowTitle={para?.rowTitle}
                />
            )} */}
      {showNoteSidebar && (
        <AddWidgetModal
          showModal={showNoteSidebar}
          closeModal={closeNoteSideBar}
          widgetId={para?.widgetId}
          propertyId={propertyDetailFromRTK?.propertyid}
          rowTitle={para?.rowTitle}
        />
      )}
      {widgetModal && (
        <WidgetDetailModal
          showModal={widgetModal}
          closeModal={closeWidgetModal}
          data={widgetDetailRef.current}
        />
      )}
    </div>
  );
};

export default BookingPaceTable;
