import React, { useEffect, useRef, useState } from "react";
import { apiCall } from "../../../../utils/axiosService";
import { API_URL } from "../../../../utils/apiRoutes";
import { apiResponse } from "../../../../utils/apiResponse";
import { Col, Row, Table, Button, Form } from "react-bootstrap";
import { commonService } from "../../../../utils/commonService";
import Loader from "../../../Loader/Loader";
import emailIcon from "../../../../Assets/Images/Email.svg";
import downloadIcon from "../../../../Assets/Images/Operation/downloadIcon.svg";
import infoIcon from "../../../../Assets/Images/Action Icons/infoIcon..svg";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { compName } from "../../../../utils/constant";
import WidgetDetailModal from "../../WidgetDetailModal";
import moment from "moment";
import { Line } from "react-chartjs-2";
import {
  Chart,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  Filler,
} from "chart.js";
import { FixColumn } from "../../../../utils/dataTable";

const ForecastUsingNinetyDayPickup = ({
  setCompDataFromChild,
  rowIndex,
  colIndex,
  api,
  para,
  setting,
}) => {
  const [loading, setLoading] = useState(false);
  const [forecastPickupData, setForecastPickupData] = useState(null);

  let dummyArr = Array.from(Array(91).keys());
  const [widgetModal, setWidgetModal] = useState(false);
  const [chartData, setChartData] = useState(null);
  const [rateIqData, setRateIqData] = useState(null);
  const [selectedCol, setSelectedCol] = useState(90);
  const selectedColArr = [90, 60, 40, 30, 15, 7];
  const widgetDetailRef = useRef();
  const propertyDetailFromRTK = useSelector(
    (store) => store.headerProperty.propertyDetail
  );

  const defaultAsOfDateFromRTK = useSelector(
    (store) => store.headerProperty.defaultAsOfDate
  );
  const { snapshottitle, propertyCode, snapshotId } = useParams();
  const redLineForChartRef = useRef([]);
  const maxValueForInventoryRef = useRef();

  const closeWidgetModal = () => {
    setWidgetModal(false);
  };

  let dbaTotalNormalObj = {
    DBA_FINAL: 0,
    DBA_0: 0,
    DBA_1: 0,
    DBA_2: 0,
    DBA_3: 0,
    DBA_4: 0,
    DBA_5: 0,
    DBA_6: 0,
    DBA_7: 0,
    DBA_8: 0,
    DBA_9: 0,
    DBA_10: 0,
    DBA_11: 0,
    DBA_12: 0,
    DBA_13: 0,
    DBA_14: 0,
    DBA_15: 0,
    DBA_16: 0,
    DBA_17: 0,
    DBA_18: 0,
    DBA_19: 0,
    DBA_20: 0,
    DBA_21: 0,
    DBA_22: 0,
    DBA_23: 0,
    DBA_24: 0,
    DBA_25: 0,
    DBA_26: 0,
    DBA_27: 0,
    DBA_28: 0,
    DBA_29: 0,
    DBA_30: 0,
    DBA_31: 0,
    DBA_32: 0,
    DBA_33: 0,
    DBA_34: 0,
    DBA_35: 0,
    DBA_36: 0,
    DBA_37: 0,
    DBA_38: 0,
    DBA_39: 0,
    DBA_40: 0,
    DBA_41: 0,
    DBA_42: 0,
    DBA_43: 0,
    DBA_44: 0,
    DBA_45: 0,
    DBA_46: 0,
    DBA_47: 0,
    DBA_48: 0,
    DBA_49: 0,
    DBA_50: 0,
    DBA_51: 0,
    DBA_52: 0,
    DBA_53: 0,
    DBA_54: 0,
    DBA_55: 0,
    DBA_56: 0,
    DBA_57: 0,
    DBA_58: 0,
    DBA_59: 0,
    DBA_60: 0,
    DBA_61: 0,
    DBA_62: 0,
    DBA_63: 0,
    DBA_64: 0,
    DBA_65: 0,
    DBA_66: 0,
    DBA_67: 0,
    DBA_68: 0,
    DBA_69: 0,
    DBA_70: 0,
    DBA_71: 0,
    DBA_72: 0,
    DBA_73: 0,
    DBA_74: 0,
    DBA_75: 0,
    DBA_76: 0,
    DBA_77: 0,
    DBA_78: 0,
    DBA_79: 0,
    DBA_80: 0,
    DBA_81: 0,
    DBA_82: 0,
    DBA_83: 0,
    DBA_84: 0,
    DBA_85: 0,
    DBA_86: 0,
    DBA_87: 0,
    DBA_88: 0,
    DBA_89: 0,
    DBA_90: 0,
  };

  const dbaTotalRef = useRef(dbaTotalNormalObj);

  const getForecastPickupData = async () => {
    dbaTotalRef.current = dbaTotalNormalObj;
    setLoading(true);
    let forecastPickupResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.SnapShot +
          API_URL.PICK_UP.FORECAST_USING_90_DAY_PICKUP,
        body: { AsOfDate: para?.asOfDate, propertyCode: para?.propertyCode },
      },
      false
    );

    let response = apiResponse(false, forecastPickupResponse);

    if (response?.isValidate) {
      let OTB = {
          type: "line",
          label: `OTB`,
          borderColor: "#1686F9",
          backgroundColor: "#1686F9",
          data: [],
          stack: "stack1" || "default",
        },
        Forecast = {
          type: "line",
          label: `Forecast`,
          borderColor: "#25A855",
          backgroundColor: "#25A855",
          data: [],
          stack: "stack2" || "default",
        },
        avgPickup = {
          type: "line",
          label: `RSA`,
          borderColor: "#E64839",
          backgroundColor: "#E64839",
          data: [],
          stack: "stack3" || "default",
        },
        labels = [],
        maxvalueForChart = 0;

      response?.data?.data?.[0]?.PickUpDataJson?.dba_data?.map(
        (item, index) => {
          dbaTotalRef.current.DBA_FINAL =
            dbaTotalRef.current.DBA_FINAL +
            (item?.DBA_FINAL ? parseInt(item?.DBA_FINAL) : 0);

          dummyArr?.map((dummyItem, dummyIndex) => {
            dbaTotalRef.current[`DBA_${dummyIndex}`] =
              dbaTotalRef.current[`DBA_${dummyIndex}`] +
              (item?.[`DBA_${dummyIndex}`]
                ? parseInt(item?.[`DBA_${dummyIndex}`])
                : 0);
          });
        }
      );

      dummyArr.map((pickupItem, pickupIndex) => {
        let dbaFinalAvg = Math.round(dbaTotalRef.current?.DBA_FINAL / 90);
        let pickupAvg = Math.round(
          dbaTotalRef.current?.[`DBA_${pickupIndex}`] / 90
        );
        if (pickupIndex <= 30) {
          let avgOcc = dbaTotalRef.current?.[`DBA_${pickupIndex}`] / 90;
          redLineForChartRef.current = [
            ...redLineForChartRef.current,
            Math.round(avgOcc),
          ];
          // redLineForChartRef.current = [
          //   ...redLineForChartRef.current,
          //   Math.round(dbaFinalAvg - pickupAvg),
          // ];
        }
      });

      response?.data?.data?.[0]?.PickUpDataJson?.forecast_data?.map(
        (item, index) => {
          if (item?.day_difference <= 30) {
            let dbaFinalAvg = Math.round(dbaTotalRef.current?.DBA_FINAL / 90);
            let pickupAvg = Math.round(
              dbaTotalRef.current?.[`DBA_${item?.day_difference}`] / 90
            );

            let forecastCol = Math.round(dbaFinalAvg - pickupAvg);

            let finalDataForForecastChart = item?.RoomSold + forecastCol;
            if (item?.day_difference === 0) {
              finalDataForForecastChart = item?.ForecastRoom;
            }
            if (item?.RoomSold > maxvalueForChart) {
              maxvalueForChart = item?.RoomSold;
            }
            if (item?.ForecastRoom > maxvalueForChart) {
              maxvalueForChart = item?.ForecastRoom;
            }
            if (redLineForChartRef.current[index] > maxvalueForChart) {
              maxvalueForChart = redLineForChartRef.current[index];
            }
            OTB.data = [...OTB.data, item?.RoomSold];
            // Forecast.data = [...Forecast.data, item?.ForecastRoom];
            Forecast.data = [...Forecast.data, finalDataForForecastChart];
          }
        }
      );
      avgPickup.data = redLineForChartRef.current;

      let chartDetail = {
        labels: [
          0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
          20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
        ],
        datasets: [OTB, Forecast, avgPickup],
      };
      maxValueForInventoryRef.current = maxvalueForChart;
      setChartData(chartDetail);
      setForecastPickupData(response?.data?.data);
      setLoading(false);
    }

    if (!response?.isValidate) {
      setChartData(null);
      setForecastPickupData(null);
      setLoading(false);
    }
  };

  const getRateIqData = async () => {
    let startDate = new Date(para?.asOfDate);
    let endDate = new Date(para?.asOfDate);
    endDate.setDate(new Date(startDate.getDate() + 90));
    let rateIqResponse = await apiCall(
      {
        method: "POST",
        url: API_URL.BASE_API_URL.SnapShot + API_URL.RATEIQ.FORECAST_LIST,
        body: {
          propertyCode: para?.propertyCode,
          AsOfDate: para?.asOfDate,
          startDate: commonService.getDateForNode(startDate),
          endDate: commonService.getDateForNode(endDate),
          propertyid: propertyDetailFromRTK?.propertyid,
        },
      },
      false
    );

    let response = apiResponse(false, rateIqResponse);

    if (response?.isValidate) {
      setRateIqData(response?.data?.data);
    }

    if (!response?.isValidate) {
      setRateIqData(null);
    }
  };

  useEffect(() => {
    if (api === 0) {
    } else {
      if (para?.asOfDate) {
        getForecastPickupData();
        getRateIqData();
      }
    }
  }, [para?.asOfDate]);

  const [tblids, settblids] = useState({
    tblForecastPickup: commonService.GenerateGUID(),
    tblNextForecastPickup: commonService.GenerateGUID(),
  });

  let chartOptions = {
    indexAxis: "x",
    scales: {
      x: {
        stacked: true,
        title: {
          display: true,
          text: "Days Out",
        },
        ticks: {
          align: "start",
          autoSkip: false,
        },
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: "Inventory",
        },
        grid: {
          display: true,
        },
        offset: true,
        ticks: {
          callback: function (value) {
            return value;
          },
        },
        max: maxValueForInventoryRef.current,
        min: 0,
        stepSize: 10,
      },
    },
    elements: {
      line: {
        fill: false,
      },
      point: {
        radius: 0,
      },
    },

    plugins: {
      legend: {
        display: true,
        align: "end",
        position: "top",
        maxWidth: 20,
        maxHeight: 100,
        labels: {
          boxWidth: 10,
          boxHeight: 10,
        },
      },
      tooltip: {
        enabled: true,
        mode: "index",
        callbacks: {
          title: function (context) {
            return context[0].label;
          },
          label: function (context) {
            let label = context.dataset.label;
            let value = context.formattedValue;
            return `${label}: ${value}`;
          },
        },
      },
    },
    responsive: true,
    barThickness: 12,
    categorySpacing: 0.3,
    barPercentage: 0.8,
    categoryPercentage: 0.9,
  };

  Chart.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Tooltip,
    Legend,
    PointElement,
    LineElement,
    Filler
  );

  FixColumn(tblids.tblForecastPickup, [1]);

  return (
    <div className="widget-container">
      <Col xl={12}>
        {api !== 0 && (
          <div className="gap-1 widget-container-menu">
            <Button
              className="heading-menu-btns"
              type="button"
              title="Send Mail"
            >
              <img
                src={emailIcon}
                alt="emailIcon"
                onClick={() => {
                  commonService.fnSendWidgetMail(
                    tblids.tblForecastPickup,
                    "Forecast using 90 Days Pickup",
                    snapshotId,
                    defaultAsOfDateFromRTK,
                    "",
                    para?.widgetId
                  );
                }}
              />
            </Button>
            <Button className="heading-menu-btns" type="button">
              <img
                src={infoIcon}
                alt="info"
                onClick={() => {
                  widgetDetailRef.current = {
                    widgetdetails: "",
                    usernote: "",
                    compName: compName?.forecastUsing90DayPickup,
                    widgetId: para?.widgetId,
                  };
                  setWidgetModal(true);
                }}
              />
            </Button>
            <Button className="heading-menu-btns" type="button">
              <img
                src={downloadIcon}
                alt="menuIcon"
                import={tblids.tblForecastPickup}
                onClick={() =>
                  commonService.fnExportInCSV(
                    tblids.tblForecastPickup,
                    // "Forecast using 90 Days Pickup"
                    `${
                      para?.propertyCode
                    }-Dynamic Forecast using 90 Days Pickup-${moment(
                      new Date()
                    ).format("MMDDYYYY")}`
                  )
                }
              />
            </Button>
          </div>
        )}
        <div className="ViewPropertySetupTable">
          <div className="stripped__table">
            <div className="table-responsive tblDynamicPickupSticky">
              <Table id={tblids.tblForecastPickup}>
                <thead className="tbl-header-sticky">
                  <tr className="table-main-header-tr">
                    <th colSpan={94}>
                      <div className="dynamicPickupDropdown">
                        Used Last 90 Days PickUp Data
                        <Form.Select
                          className="width75"
                          aria-label="Default select example"
                          value={selectedCol}
                          onChange={(e) => {
                            setSelectedCol(e.target.value);
                          }}
                        >
                          {selectedColArr?.map((item) => (
                            <option key={item} value={item}>
                              DBA-{item}
                            </option>
                          ))}
                        </Form.Select>
                      </div>
                    </th>
                  </tr>
                  <tr className="table-main-header-tr">
                    <th className="border_right width100">Date</th>
                    <th className="border_right width100">Day Of Week</th>
                    <th className="border_right width50">DBA -1</th>

                    {dummyArr?.map((item, index) => {
                      if (index <= selectedCol) {
                        return (
                          <th
                            className="text-center border_right"
                            key={`DBA_${index}`}
                          >
                            {`DBA ${index}`}
                          </th>
                        );
                      }
                    })}
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr style={{ height: "80px", position: "relative" }}>
                      <Loader />
                    </tr>
                  ) : forecastPickupData ? (
                    forecastPickupData?.[0]?.PickUpDataJson?.dba_data?.map(
                      (item, index) => {
                        let showBgColor = false;
                        if (
                          moment(new Date(item?.["StayDate"]), "l").format(
                            "ddd"
                          ) === "Fri" ||
                          moment(new Date(item?.["StayDate"]), "l").format(
                            "ddd"
                          ) === "Sat"
                        ) {
                          showBgColor = true;
                        }
                        return (
                          <>
                            <tr
                              key={`forecast_${item?.StayDate}`}
                              className={`${
                                showBgColor ? "backgroundTr" : ""
                              } shadowOnHover`}
                            >
                              <td className="border_right">
                                {commonService.getDateInFormat(
                                  new Date(item?.["StayDate"])
                                )}
                              </td>
                              <td className="border_right text-center">
                                {moment(
                                  new Date(item?.["StayDate"]),
                                  "l"
                                ).format("dddd")}
                              </td>
                              <td className="border_right text-center">
                                {item?.DBA_FINAL}
                              </td>
                              {dummyArr?.map((dummyItem, dummyIndex) => {
                                if (dummyIndex <= selectedCol) {
                                  return (
                                    <td
                                      className="border_right text-center"
                                      key={`forecast_${dummyIndex}`}
                                    >
                                      {item?.[`DBA_${dummyIndex}`]}
                                    </td>
                                  );
                                }
                              })}
                            </tr>
                            {index ===
                              forecastPickupData?.[0]?.PickUpDataJson?.dba_data
                                ?.length -
                                1 && (
                              <>
                                <tr className="shadowOnHover">
                                  <td
                                    className="border_right boldTd"
                                    colSpan={2}
                                  >
                                    Average Occupancy
                                  </td>
                                  <td className="border_right boldTd text-center">
                                    {Math.round(
                                      dbaTotalRef.current?.DBA_FINAL / 90
                                    )}
                                  </td>
                                  {dummyArr.map((avgItem, avgIndex) => {
                                    if (avgIndex <= selectedCol) {
                                      let avgOcc =
                                        dbaTotalRef.current?.[
                                          `DBA_${avgIndex}`
                                        ] / 90;
                                      return (
                                        <td className="border_right boldTd text-center">
                                          {Math.round(avgOcc)}
                                        </td>
                                      );
                                    }
                                  })}
                                </tr>
                                <tr>
                                  <td
                                    className="border_right boldTd"
                                    colSpan={2}
                                  >
                                    Average Pick Up
                                  </td>
                                  <td className="border_right boldTd text-center">
                                    0
                                  </td>
                                  {dummyArr.map((pickupItem, pickupIndex) => {
                                    if (pickupIndex <= selectedCol) {
                                      let dbaFinalAvg = Math.round(
                                        dbaTotalRef.current?.DBA_FINAL / 90
                                      );
                                      let pickupAvg = Math.round(
                                        dbaTotalRef.current?.[
                                          `DBA_${pickupIndex}`
                                        ] / 90
                                      );
                                      return (
                                        <td className="border_right boldTd text-center">
                                          {Math.round(dbaFinalAvg - pickupAvg)}
                                        </td>
                                      );
                                    }
                                  })}
                                </tr>
                                <tr>
                                  <td
                                    className="border_right boldTd"
                                    colSpan={2}
                                  ></td>
                                  <td className="border_right boldTd text-center">
                                    PU -1
                                  </td>
                                  {dummyArr.map((pickupItem, pickupIndex) => {
                                    if (pickupIndex <= selectedCol) {
                                      return (
                                        <td className="border_right boldTd text-center">
                                          {`PU ${pickupIndex}`}
                                        </td>
                                      );
                                    }
                                  })}
                                </tr>
                              </>
                            )}
                          </>
                        );
                      }
                    )
                  ) : (
                    <tr>
                      <td>No Data Found</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </Col>
      <Row className="mt-4">
        <Col xl={4}>
          <div className="widget-container">
            {api !== 0 && (
              <div className="gap-1 widget-container-menu">
                <Button
                  className="heading-menu-btns"
                  type="button"
                  title="Send Mail"
                >
                  <img
                    src={emailIcon}
                    alt="emailIcon"
                    onClick={() => {
                      commonService.fnSendWidgetMail(
                        tblids.tblNextForecastPickup,
                        "Forecast using 90 Days Pickup",
                        snapshotId,
                        defaultAsOfDateFromRTK,
                        "",
                        para?.widgetId
                      );
                    }}
                  />
                </Button>
                <Button className="heading-menu-btns" type="button">
                  <img
                    src={infoIcon}
                    alt="info"
                    onClick={() => {
                      widgetDetailRef.current = {
                        widgetdetails: "",
                        usernote: "",
                        compName: compName?.forecastUsing90DayPickup,
                        widgetId: para?.widgetId,
                      };
                      setWidgetModal(true);
                    }}
                  />
                </Button>
                <Button className="heading-menu-btns" type="button">
                  <img
                    src={downloadIcon}
                    alt="menuIcon"
                    import={tblids.tblNextForecastPickup}
                    onClick={() =>
                      commonService.fnExportInCSV(
                        tblids.tblNextForecastPickup,
                        // "Forecast using 90 Days Pickup"
                        `${
                          para?.propertyCode
                        }-Dynamic Forecast using 90 Days Pickup-${moment(
                          new Date()
                        ).format("MMDDYYYY")}`
                      )
                    }
                  />
                </Button>
              </div>
            )}
            <div className="ViewPropertySetupTable">
              <div className="stripped__table">
                <div className="table-responsive tbl-forcast-dynamic-pickup">
                  <Table id={tblids.tblNextForecastPickup}>
                    <thead className="tbl-header-sticky">
                      <tr>
                        <th className="text-center" colSpan={5}>
                          {`Forecast For Next ${selectedCol} Days`}
                        </th>
                      </tr>
                      <tr>
                        <th className="border_right">Date</th>
                        <th className="border_right">Day diff</th>
                        <th className="border_right">OTB/ROH</th>
                        <th className="border_right">
                          Forecast (ROH + AVG PICKUP)
                        </th>
                        <th>ML Forecast</th>
                      </tr>
                    </thead>
                    <tbody>
                      {loading ? (
                        <tr style={{ height: "80px", position: "relative" }}>
                          <Loader />
                        </tr>
                      ) : forecastPickupData ? (
                        forecastPickupData?.[0]?.PickUpDataJson?.forecast_data?.map(
                          (item, index) => {
                            if (index <= selectedCol) {
                              let showBgColor = false;
                              if (
                                moment(new Date(item?.["Dates"]), "l").format(
                                  "ddd"
                                ) === "Fri" ||
                                moment(new Date(item?.["Dates"]), "l").format(
                                  "ddd"
                                ) === "Sat"
                              ) {
                                showBgColor = true;
                              }
                              let dbaFinalAvg = Math.round(
                                dbaTotalRef.current?.DBA_FINAL / 90
                              );
                              let pickupAvg = Math.round(
                                dbaTotalRef.current?.[
                                  `DBA_${item?.day_difference}`
                                ] / 90
                              );

                              let forecastCol = Math.round(
                                dbaFinalAvg - pickupAvg
                              );

                              return (
                                <React.Fragment key={`forecast_data${index}`}>
                                  <tr
                                    className={`${
                                      showBgColor ? "backgroundTr" : ""
                                    } shadowOnHover`}
                                  >
                                    <td className="border_right">
                                      {commonService.getDateInFormat(
                                        new Date(item?.["Dates"])
                                      )}
                                    </td>
                                    <td className="border_right">
                                      {item?.day_difference}
                                    </td>
                                    <td className="border_right">
                                      {item?.RoomSold}
                                    </td>
                                    <td className="border_right">
                                      {item?.day_difference === 0
                                        ? item?.ForecastRoom
                                        : item?.RoomSold + forecastCol}
                                    </td>
                                    <td>
                                      {rateIqData?.[index]?.ml_room
                                        ? rateIqData?.[index]?.ml_room
                                        : 0}
                                    </td>
                                  </tr>
                                </React.Fragment>
                              );
                            }
                          }
                        )
                      ) : (
                        <tr>
                          <td>No Data Found</td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </div>
        </Col>
        <Col xl={8}>
          {loading ? (
            <Loader />
          ) : chartData ? (
            <div>
              <Line data={chartData} options={chartOptions} />
            </div>
          ) : (
            <div>No Data Found</div>
          )}
        </Col>
      </Row>
      {widgetModal && (
        <WidgetDetailModal
          showModal={widgetModal}
          closeModal={closeWidgetModal}
          data={widgetDetailRef.current}
        />
      )}
    </div>
  );
};

export default ForecastUsingNinetyDayPickup;
