import React, { useEffect, useRef, useState } from "react";
import { apiCall } from "../../../../utils/axiosService";
import { API_URL } from "../../../../utils/apiRoutes";
import { apiResponse } from "../../../../utils/apiResponse";
import { Button, Col, Table } from "react-bootstrap";
import { commonService } from "../../../../utils/commonService";
import NoteIcon from "../../../../Assets/Images/CRM/notes.svg";
import downloadIcon from "../../../../Assets/Images/Operation/downloadIcon.svg";
import Loader from "../../../Loader/Loader";
import DatePicker from "react-datepicker";
import WidgetDetailModal from "../../WidgetDetailModal";
import { compName } from "../../../../utils/constant";
import WidgetNote from "../../WidgetNote";
import { useSelector } from "react-redux";
import infoIcon from "../../../../Assets/Images/Action Icons/infoIcon..svg";
import AddWidgetModal from "../../AddWidgetModal";
import { useParams } from "react-router-dom";
import emailIcon from "../../../../Assets/Images/Email.svg";
import moment from "moment";

const DailyDataTransaction = ({
  setCompDataFromChild,
  rowIndex,
  colIndex,
  api,
  para,
  setting,
}) => {
  const tableData = [
    {
      id: 2,
      propertyCode: "AC32AW",
      propertyId: "1",
      pullDateId: "497",
      AsOfDate: "2023-08-16",
      Dates: "2014-01-02",
      Inventory: 89,
      RoomSold: 36,
      TotalRevenue: "2434.62",
      ADR: "62.43",
      TotalOccupancy: "36.00",
      AvailableOccupancy: "41.00",
      RevPAR: "31.62",
      Occperc: "46.75",
      OutOfOrder: 12,
      RoomsOfAvailable: 77,
      DayOfWeek: "Thu",
      WeekType: "weekday",
      GroupADR: "0.00",
      GroupBlock: 0,
      GroupOTB: 0,
      GroupRevenue: "0.00",
      TransientRoomSold: 36,
      TransientRevenue: "2434.62",
      TransientADR: "67.63",
      LYTotalInventory: 0,
      LYTotalRoomSold: 0,
      LYTotalRevenue: "0.00",
      LYTotalADR: "0.00",
      LYTotalOccupancy: "0.00",
      LYTotalRevPar: "0.00",
      LYTotalOccPerc: "0.00",
      YestDayInventory: 89,
      YestDayRoomSold: 24,
      YestDayRevenue: "1480.76",
      YestDayADR: "61.70",
      YestDayOccupancy: "24.00",
      YestDayRevPar: "18.98",
      YestDayOccPerc: "30.77",
      LYPaceInventory: 0,
      LYPaceRoomSold: 0,
      LYPaceRevenue: "0.00",
      LYPaceADR: "0.00",
      LYPaceOccupancy: "0.00",
      LYPaceRevPar: "0.00",
      LYPaceOccPerc: "0.00",
    },
    {
      id: 2,
      propertyCode: "AC32AW",
      propertyId: "1",
      pullDateId: "497",
      AsOfDate: "2023-08-16",
      Dates: "2014-01-02",
      Inventory: 89,
      RoomSold: 36,
      TotalRevenue: "2434.62",
      ADR: "62.43",
      TotalOccupancy: "36.00",
      AvailableOccupancy: "41.00",
      RevPAR: "31.62",
      Occperc: "46.75",
      OutOfOrder: 12,
      RoomsOfAvailable: 77,
      DayOfWeek: "Thu",
      WeekType: "weekday",
      GroupADR: "0.00",
      GroupBlock: 0,
      GroupOTB: 0,
      GroupRevenue: "0.00",
      TransientRoomSold: 36,
      TransientRevenue: "2434.62",
      TransientADR: "67.63",
      LYTotalInventory: 0,
      LYTotalRoomSold: 0,
      LYTotalRevenue: "0.00",
      LYTotalADR: "0.00",
      LYTotalOccupancy: "0.00",
      LYTotalRevPar: "0.00",
      LYTotalOccPerc: "0.00",
      YestDayInventory: 89,
      YestDayRoomSold: 24,
      YestDayRevenue: "1480.76",
      YestDayADR: "61.70",
      YestDayOccupancy: "24.00",
      YestDayRevPar: "18.98",
      YestDayOccPerc: "30.77",
      LYPaceInventory: 0,
      LYPaceRoomSold: 0,
      LYPaceRevenue: "0.00",
      LYPaceADR: "0.00",
      LYPaceOccupancy: "0.00",
      LYPaceRevPar: "0.00",
      LYPaceOccPerc: "0.00",
    },
    {
      id: 2,
      propertyCode: "AC32AW",
      propertyId: "1",
      pullDateId: "497",
      AsOfDate: "2023-08-16",
      Dates: "2014-01-02",
      Inventory: 89,
      RoomSold: 36,
      TotalRevenue: "2434.62",
      ADR: "62.43",
      TotalOccupancy: "36.00",
      AvailableOccupancy: "41.00",
      RevPAR: "31.62",
      Occperc: "46.75",
      OutOfOrder: 12,
      RoomsOfAvailable: 77,
      DayOfWeek: "Thu",
      WeekType: "weekday",
      GroupADR: "0.00",
      GroupBlock: 0,
      GroupOTB: 0,
      GroupRevenue: "0.00",
      TransientRoomSold: 36,
      TransientRevenue: "2434.62",
      TransientADR: "67.63",
      LYTotalInventory: 0,
      LYTotalRoomSold: 0,
      LYTotalRevenue: "0.00",
      LYTotalADR: "0.00",
      LYTotalOccupancy: "0.00",
      LYTotalRevPar: "0.00",
      LYTotalOccPerc: "0.00",
      YestDayInventory: 89,
      YestDayRoomSold: 24,
      YestDayRevenue: "1480.76",
      YestDayADR: "61.70",
      YestDayOccupancy: "24.00",
      YestDayRevPar: "18.98",
      YestDayOccPerc: "30.77",
      LYPaceInventory: 0,
      LYPaceRoomSold: 0,
      LYPaceRevenue: "0.00",
      LYPaceADR: "0.00",
      LYPaceOccupancy: "0.00",
      LYPaceRevPar: "0.00",
      LYPaceOccPerc: "0.00",
    },
    {
      id: 2,
      propertyCode: "AC32AW",
      propertyId: "1",
      pullDateId: "497",
      AsOfDate: "2023-08-16",
      Dates: "2014-01-02",
      Inventory: 89,
      RoomSold: 36,
      TotalRevenue: "2434.62",
      ADR: "62.43",
      TotalOccupancy: "36.00",
      AvailableOccupancy: "41.00",
      RevPAR: "31.62",
      Occperc: "46.75",
      OutOfOrder: 12,
      RoomsOfAvailable: 77,
      DayOfWeek: "Thu",
      WeekType: "weekday",
      GroupADR: "0.00",
      GroupBlock: 0,
      GroupOTB: 0,
      GroupRevenue: "0.00",
      TransientRoomSold: 36,
      TransientRevenue: "2434.62",
      TransientADR: "67.63",
      LYTotalInventory: 0,
      LYTotalRoomSold: 0,
      LYTotalRevenue: "0.00",
      LYTotalADR: "0.00",
      LYTotalOccupancy: "0.00",
      LYTotalRevPar: "0.00",
      LYTotalOccPerc: "0.00",
      YestDayInventory: 89,
      YestDayRoomSold: 24,
      YestDayRevenue: "1480.76",
      YestDayADR: "61.70",
      YestDayOccupancy: "24.00",
      YestDayRevPar: "18.98",
      YestDayOccPerc: "30.77",
      LYPaceInventory: 0,
      LYPaceRoomSold: 0,
      LYPaceRevenue: "0.00",
      LYPaceADR: "0.00",
      LYPaceOccupancy: "0.00",
      LYPaceRevPar: "0.00",
      LYPaceOccPerc: "0.00",
    },
    {
      id: 2,
      propertyCode: "AC32AW",
      propertyId: "1",
      pullDateId: "497",
      AsOfDate: "2023-08-16",
      Dates: "2014-01-02",
      Inventory: 89,
      RoomSold: 36,
      TotalRevenue: "2434.62",
      ADR: "62.43",
      TotalOccupancy: "36.00",
      AvailableOccupancy: "41.00",
      RevPAR: "31.62",
      Occperc: "46.75",
      OutOfOrder: 12,
      RoomsOfAvailable: 77,
      DayOfWeek: "Thu",
      WeekType: "weekday",
      GroupADR: "0.00",
      GroupBlock: 0,
      GroupOTB: 0,
      GroupRevenue: "0.00",
      TransientRoomSold: 36,
      TransientRevenue: "2434.62",
      TransientADR: "67.63",
      LYTotalInventory: 0,
      LYTotalRoomSold: 0,
      LYTotalRevenue: "0.00",
      LYTotalADR: "0.00",
      LYTotalOccupancy: "0.00",
      LYTotalRevPar: "0.00",
      LYTotalOccPerc: "0.00",
      YestDayInventory: 89,
      YestDayRoomSold: 24,
      YestDayRevenue: "1480.76",
      YestDayADR: "61.70",
      YestDayOccupancy: "24.00",
      YestDayRevPar: "18.98",
      YestDayOccPerc: "30.77",
      LYPaceInventory: 0,
      LYPaceRoomSold: 0,
      LYPaceRevenue: "0.00",
      LYPaceADR: "0.00",
      LYPaceOccupancy: "0.00",
      LYPaceRevPar: "0.00",
      LYPaceOccPerc: "0.00",
    },
    {
      id: 2,
      propertyCode: "AC32AW",
      propertyId: "1",
      pullDateId: "497",
      AsOfDate: "2023-08-16",
      Dates: "2014-01-02",
      Inventory: 89,
      RoomSold: 36,
      TotalRevenue: "2434.62",
      ADR: "62.43",
      TotalOccupancy: "36.00",
      AvailableOccupancy: "41.00",
      RevPAR: "31.62",
      Occperc: "46.75",
      OutOfOrder: 12,
      RoomsOfAvailable: 77,
      DayOfWeek: "Thu",
      WeekType: "weekday",
      GroupADR: "0.00",
      GroupBlock: 0,
      GroupOTB: 0,
      GroupRevenue: "0.00",
      TransientRoomSold: 36,
      TransientRevenue: "2434.62",
      TransientADR: "67.63",
      LYTotalInventory: 0,
      LYTotalRoomSold: 0,
      LYTotalRevenue: "0.00",
      LYTotalADR: "0.00",
      LYTotalOccupancy: "0.00",
      LYTotalRevPar: "0.00",
      LYTotalOccPerc: "0.00",
      YestDayInventory: 89,
      YestDayRoomSold: 24,
      YestDayRevenue: "1480.76",
      YestDayADR: "61.70",
      YestDayOccupancy: "24.00",
      YestDayRevPar: "18.98",
      YestDayOccPerc: "30.77",
      LYPaceInventory: 0,
      LYPaceRoomSold: 0,
      LYPaceRevenue: "0.00",
      LYPaceADR: "0.00",
      LYPaceOccupancy: "0.00",
      LYPaceRevPar: "0.00",
      LYPaceOccPerc: "0.00",
    },
    {
      id: 2,
      propertyCode: "AC32AW",
      propertyId: "1",
      pullDateId: "497",
      AsOfDate: "2023-08-16",
      Dates: "2014-01-02",
      Inventory: 89,
      RoomSold: 36,
      TotalRevenue: "2434.62",
      ADR: "62.43",
      TotalOccupancy: "36.00",
      AvailableOccupancy: "41.00",
      RevPAR: "31.62",
      Occperc: "46.75",
      OutOfOrder: 12,
      RoomsOfAvailable: 77,
      DayOfWeek: "Thu",
      WeekType: "weekday",
      GroupADR: "0.00",
      GroupBlock: 0,
      GroupOTB: 0,
      GroupRevenue: "0.00",
      TransientRoomSold: 36,
      TransientRevenue: "2434.62",
      TransientADR: "67.63",
      LYTotalInventory: 0,
      LYTotalRoomSold: 0,
      LYTotalRevenue: "0.00",
      LYTotalADR: "0.00",
      LYTotalOccupancy: "0.00",
      LYTotalRevPar: "0.00",
      LYTotalOccPerc: "0.00",
      YestDayInventory: 89,
      YestDayRoomSold: 24,
      YestDayRevenue: "1480.76",
      YestDayADR: "61.70",
      YestDayOccupancy: "24.00",
      YestDayRevPar: "18.98",
      YestDayOccPerc: "30.77",
      LYPaceInventory: 0,
      LYPaceRoomSold: 0,
      LYPaceRevenue: "0.00",
      LYPaceADR: "0.00",
      LYPaceOccupancy: "0.00",
      LYPaceRevPar: "0.00",
      LYPaceOccPerc: "0.00",
    },
    {
      id: 2,
      propertyCode: "AC32AW",
      propertyId: "1",
      pullDateId: "497",
      AsOfDate: "2023-08-16",
      Dates: "2014-01-02",
      Inventory: 89,
      RoomSold: 36,
      TotalRevenue: "2434.62",
      ADR: "62.43",
      TotalOccupancy: "36.00",
      AvailableOccupancy: "41.00",
      RevPAR: "31.62",
      Occperc: "46.75",
      OutOfOrder: 12,
      RoomsOfAvailable: 77,
      DayOfWeek: "Thu",
      WeekType: "weekday",
      GroupADR: "0.00",
      GroupBlock: 0,
      GroupOTB: 0,
      GroupRevenue: "0.00",
      TransientRoomSold: 36,
      TransientRevenue: "2434.62",
      TransientADR: "67.63",
      LYTotalInventory: 0,
      LYTotalRoomSold: 0,
      LYTotalRevenue: "0.00",
      LYTotalADR: "0.00",
      LYTotalOccupancy: "0.00",
      LYTotalRevPar: "0.00",
      LYTotalOccPerc: "0.00",
      YestDayInventory: 89,
      YestDayRoomSold: 24,
      YestDayRevenue: "1480.76",
      YestDayADR: "61.70",
      YestDayOccupancy: "24.00",
      YestDayRevPar: "18.98",
      YestDayOccPerc: "30.77",
      LYPaceInventory: 0,
      LYPaceRoomSold: 0,
      LYPaceRevenue: "0.00",
      LYPaceADR: "0.00",
      LYPaceOccupancy: "0.00",
      LYPaceRevPar: "0.00",
      LYPaceOccPerc: "0.00",
    },
    {
      id: 2,
      propertyCode: "AC32AW",
      propertyId: "1",
      pullDateId: "497",
      AsOfDate: "2023-08-16",
      Dates: "2014-01-02",
      Inventory: 89,
      RoomSold: 36,
      TotalRevenue: "2434.62",
      ADR: "62.43",
      TotalOccupancy: "36.00",
      AvailableOccupancy: "41.00",
      RevPAR: "31.62",
      Occperc: "46.75",
      OutOfOrder: 12,
      RoomsOfAvailable: 77,
      DayOfWeek: "Thu",
      WeekType: "weekday",
      GroupADR: "0.00",
      GroupBlock: 0,
      GroupOTB: 0,
      GroupRevenue: "0.00",
      TransientRoomSold: 36,
      TransientRevenue: "2434.62",
      TransientADR: "67.63",
      LYTotalInventory: 0,
      LYTotalRoomSold: 0,
      LYTotalRevenue: "0.00",
      LYTotalADR: "0.00",
      LYTotalOccupancy: "0.00",
      LYTotalRevPar: "0.00",
      LYTotalOccPerc: "0.00",
      YestDayInventory: 89,
      YestDayRoomSold: 24,
      YestDayRevenue: "1480.76",
      YestDayADR: "61.70",
      YestDayOccupancy: "24.00",
      YestDayRevPar: "18.98",
      YestDayOccPerc: "30.77",
      LYPaceInventory: 0,
      LYPaceRoomSold: 0,
      LYPaceRevenue: "0.00",
      LYPaceADR: "0.00",
      LYPaceOccupancy: "0.00",
      LYPaceRevPar: "0.00",
      LYPaceOccPerc: "0.00",
    },
    {
      id: 2,
      propertyCode: "AC32AW",
      propertyId: "1",
      pullDateId: "497",
      AsOfDate: "2023-08-16",
      Dates: "2014-01-02",
      Inventory: 89,
      RoomSold: 36,
      TotalRevenue: "2434.62",
      ADR: "62.43",
      TotalOccupancy: "36.00",
      AvailableOccupancy: "41.00",
      RevPAR: "31.62",
      Occperc: "46.75",
      OutOfOrder: 12,
      RoomsOfAvailable: 77,
      DayOfWeek: "Thu",
      WeekType: "weekday",
      GroupADR: "0.00",
      GroupBlock: 0,
      GroupOTB: 0,
      GroupRevenue: "0.00",
      TransientRoomSold: 36,
      TransientRevenue: "2434.62",
      TransientADR: "67.63",
      LYTotalInventory: 0,
      LYTotalRoomSold: 0,
      LYTotalRevenue: "0.00",
      LYTotalADR: "0.00",
      LYTotalOccupancy: "0.00",
      LYTotalRevPar: "0.00",
      LYTotalOccPerc: "0.00",
      YestDayInventory: 89,
      YestDayRoomSold: 24,
      YestDayRevenue: "1480.76",
      YestDayADR: "61.70",
      YestDayOccupancy: "24.00",
      YestDayRevPar: "18.98",
      YestDayOccPerc: "30.77",
      LYPaceInventory: 0,
      LYPaceRoomSold: 0,
      LYPaceRevenue: "0.00",
      LYPaceADR: "0.00",
      LYPaceOccupancy: "0.00",
      LYPaceRevPar: "0.00",
      LYPaceOccPerc: "0.00",
    },
  ];

  const date = new Date();
  const startDate = commonService.getDateInDBFormat(
    new Date(date.getFullYear(), date.getMonth(), 1)
  );
  const endDate = commonService.getDateInDBFormat(
    new Date(date.getFullYear(), date.getMonth() + 1, 0)
  );

  const [dailyDataTransaction, setDailyDataTransaction] = useState(null);
  const [loading, setLoading] = useState(false);
  const [startRangeDate, setStartRangeDate] = useState();
  const [endRangeDate, setEndRangeDate] = useState();
  const startRangeDateRef = useRef();
  const endRangeDateRef = useRef();
  const [widgetModal, setWidgetModal] = useState(false);
  const widgetDetailRef = useRef();
  const [showNoteSidebar, setShowNoteSidebar] = useState(false);
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const propertyDetailFromRTK = useSelector(
    (store) => store.headerProperty.propertyDetail
  );
  const defaultAsOfDateFromRTK = useSelector(
    (store) => store.headerProperty.defaultAsOfDate
  );
  const { snapshottitle, propertyCode, snapshotId } = useParams();
  const closeNoteSideBar = () => {
    setShowNoteSidebar(false);
  };

  const closeWidgetModal = () => {
    setWidgetModal(false);
  };
  const handleDateChange = (event) => {
    const [start, end] = event;
    let startSelectedDate = "",
      endSelectedDate = "";
    if (start) {
      let date = new Date(start);
      let month =
        date.getMonth() + 1 > 9
          ? date.getMonth() + 1
          : `0${date.getMonth() + 1}`;
      let dateFormat =
        date?.getDate() > 9 ? date.getDate() : `0${date.getDate()}`;
      startSelectedDate = `${date.getFullYear()}-${month}-${dateFormat}`;
      startRangeDateRef.current = startSelectedDate;
    }
    if (end) {
      let date = new Date(end);
      let month =
        date.getMonth() + 1 > 9
          ? date.getMonth() + 1
          : `0${date.getMonth() + 1}`;
      let dateFormat =
        date?.getDate() > 9 ? date.getDate() : `0${date.getDate()}`;
      endSelectedDate = `${date.getFullYear()}-${month}-${dateFormat}`;
      endRangeDateRef.current = endSelectedDate;
    }

    setStartRangeDate(event[0]);
    setEndRangeDate(event[1]);
    if (event[0] !== null && event[1] !== null) {
      // getMstReservation();
    }
  };

  const getMstReservation = async () => {
    setLoading(true);
    let dailyDataTransactionResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.SnapShot +
          API_URL.DAILY_DATA_TRANSACTION.DAILY_DATA_TRANSACTION,
        body: {
          AsOfDate: para?.asOfDate,
          startDate: startRangeDateRef.current
            ? startRangeDateRef.current
            : startDate,
          endDate: endRangeDateRef.current ? endRangeDateRef.current : endDate,
          propertyCode: para?.propertyCode,
        },
      },
      false
    );

    let response = apiResponse(false, dailyDataTransactionResponse);

    if (response?.isValidate) {
      setDailyDataTransaction(response?.data?.data);
      setLoading(false);
    }

    if (!response?.isValidate) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (api === 0) {
      setDailyDataTransaction(tableData);
    } else {
      if (para?.asOfDate) {
        getMstReservation();
      }
    }
  }, [para?.asOfDate]);

  const [tblids, settblids] = useState({
    tblDailyDataTransaction: commonService.GenerateGUID(),
  });

  return (
    <div className="widget-container">
      {api !== 0 && (
        <div className="gap-1 widget-container-menu">
          <Button className="heading-menu-btns" type="button">
            <img
              src={NoteIcon}
              alt="menuIcon"
              onClick={() => {
                setShowNoteSidebar(true);
              }}
            />
          </Button>
          <Button className="heading-menu-btns" type="button" title="Send Mail">
            <img
              src={emailIcon}
              alt="emailIcon"
              onClick={() => {
                commonService.fnSendWidgetMail(
                  tblids.tblDailyDataTransaction,
                  "Daily Data",
                  snapshotId,
                  defaultAsOfDateFromRTK,
                  "",
                  para?.widgetId
                );
              }}
            />
          </Button>
          <Button className="heading-menu-btns" type="button">
            <img
              src={infoIcon}
              alt="info"
              onClick={() => {
                widgetDetailRef.current = {
                  widgetdetails: "",
                  usernote: "",
                  compName: compName?.dailyDataTransaction,
                  widgetId: para?.widgetId,
                };
                setWidgetModal(true);
              }}
            />
          </Button>
          <Button className="heading-menu-btns" type="button">
            <img
              src={downloadIcon}
              alt="menuIcon"
              import={tblids.tblDailyDataTransaction}
              onClick={() =>
                commonService.fnExportInCSV(
                  tblids.tblDailyDataTransaction,
                  // "Daily Data"
                  `${para?.propertyCode}-Daily Data-${moment(new Date()).format(
                    "MMDDYYYY"
                  )}`
                )
              }
            />
          </Button>
        </div>
      )}
      <div className="ViewPropertySetupTable">
        <div className="stripped__table">
        <div className="table-responsive tblDynamicPickupSticky">
          <Table
            
            id={tblids.tblDailyDataTransaction}
            className="positionStaticTable"
          >
            <thead className="text-center tbl-header-sticky">
              <tr className="table-main-header-tr">
                <th className="" colSpan={45}>
                  <div
                    className="d-flex gap-5 align-items-center"
                    style={{ whiteSpace: "nowrap" }}
                    onClick={() => {
                      setOpenDatePicker(true);
                    }}
                  >
                    <div className="d-flex justify-content-start align-items-center gap-3">
                      <p className="m-0 d-flex align-items-center">Date </p>

                      <DatePicker
                        onChange={(event) => handleDateChange(event)}
                        startDate={startRangeDate}
                        endDate={endRangeDate}
                        selectsRange
                        monthsShown={2}
                        open={openDatePicker}
                        shouldCloseOnSelect={false}
                      >
                        <div
                          className="w-100"
                          style={{ display: "inline-block" }}
                        >
                          <div className="content-inside-calendar">
                            <p>
                              {startRangeDate
                                ? commonService.getDateInFormat(startRangeDate)
                                : ""}
                              {startRangeDate && endRangeDate ? " - " : ""}
                              {endRangeDate
                                ? commonService.getDateInFormat(endRangeDate)
                                : ""}
                            </p>
                            <div className="d-flex align-items-center gap-2">
                              <button
                                className="secondary-btn"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setStartRangeDate("");
                                  setEndRangeDate("");
                                  setOpenDatePicker(false);
                                }}
                              >
                                Cancel
                              </button>
                              <button
                                disabled={
                                  startRangeDate && endRangeDate ? false : true
                                }
                                onClick={(e) => {
                                  e.stopPropagation();
                                  if (
                                    startRangeDateRef.current &&
                                    endRangeDateRef.current
                                  ) {
                                    getMstReservation();
                                  }

                                  setOpenDatePicker(false);
                                }}
                                className="primary-btn"
                              >
                                Apply
                              </button>
                            </div>
                          </div>
                        </div>
                      </DatePicker>
                      {startRangeDate && endRangeDate && (
                        <span
                          style={{ display: "none" }}
                        >{`${commonService.getDateInFormat(
                          startRangeDate
                        )} - ${commonService.getDateInFormat(
                          endRangeDate
                        )}`}</span>
                      )}
                      <div className="d-flex align-items-center">
                        Daily Data Transaction
                      </div>
                    </div>
                  </div>
                </th>
              </tr>
              <tr className="table-main-header-tr">
                <th className="width150">property Code</th>
                <th className="width150">property Id</th>
                <th className="width150">pullDate Id</th>
                <th className="width150">As Of Date</th>
                <th className="width150">Dates</th>
                <th className="width150">Inventory</th>
                <th className="width150">Room Sold</th>
                <th className="width150 text-end">Total Revenue</th>
                <th className="width150 text-end">ADR</th>
                <th className="width150">Total Occupancy</th>
                <th className="width150">Available Occupancy</th>
                <th className="width150 text-end">Rev PAR</th>
                <th className="width150">Occperc</th>
                <th className="width150">Out Of Order</th>
                <th className="width150">Rooms Of Available</th>
                <th className="width150">Day Of Week</th>
                <th className="width150">Week Type</th>
                <th className="width150 text-end">Group ADR</th>
                <th className="width150">Group Block</th>
                <th className="width150">Group OTB</th>
                <th className="width150 text-end">Group Revenue</th>
                <th className="width150">Transient Room Sold</th>
                <th className="width150 text-end">Transient Revenue</th>
                <th className="width150 text-end">Transient ADR</th>
                <th className="width150">LY Total Inventory</th>
                <th className="width150">LY Total RoomSold</th>
                <th className="width150 text-end">LY Total Revenue</th>
                <th className="width150 text-end">LY Total ADR</th>
                <th className="width150">LY Total Occupancy</th>
                <th className="width150 text-end">LY Total RevPar</th>
                <th className="width150">LY Total OccPerc</th>
                <th className="width150">YestDay Inventory</th>
                <th className="width150">YestDay RoomSold</th>
                <th className="width150 text-end">YestDay Revenue</th>
                <th className="width150 text-end">YestDay ADR</th>
                <th className="width150">YestDay Occupancy</th>
                <th className="width150 text-end">YestDay RevPar</th>
                <th className="width150">YestDay OccPerc</th>
                <th className="width150">LYPace Inventory</th>
                <th className="width150">LYPace RoomSold</th>
                <th className="width150 text-end">LYPace Revenue</th>
                <th className="width150 text-end">LYPace ADR</th>
                <th className="width150">LYPace Occupancy</th>
                <th className="width150 text-end">LYPace RevPar</th>
                <th className="width150">LYPace OccPerc</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr style={{ height: "80px", position: "relative" }}>
                  <Loader />
                </tr>
              ) : dailyDataTransaction ? (
                dailyDataTransaction?.map((item, index) => (
                  <tr key={index} className="text-center shadowOnHover">
                    <td>{item?.propertyCode}</td>
                    <td>{item?.propertyId}</td>
                    <td>{item?.pullDateId}</td>
                    <td>{commonService.getDateInFormat(item?.AsOfDate)}</td>
                    <td>{commonService?.getDateInFormat(item?.Dates)}</td>
                    <td>{commonService.formateRoom(Math.round(item?.Inventory))}</td>
                    <td>{commonService.formateRoom(Math.round(item?.RoomSold))}</td>
                    <td className="text-end">{commonService.formateAmount(Math.round(item?.TotalRevenue))}</td>
                    <td className="text-end">{commonService.formateAmount(Math.round(item?.ADR))}</td>
                    <td>{commonService.formateRoom(Math.round(item?.TotalOccupancy))}</td>
                    <td>{commonService.formateRoom(Math.round(item?.AvailableOccupancy))}</td>
                    <td className="text-end">{commonService.formateAmount(Math.round(item?.RevPAR))}</td>
                    <td>{item?.Occperc}</td>
                    <td>{item?.OutOfOrder}</td>
                    <td>{item?.RoomsOfAvailable}</td>
                    <td>{item?.DayOfWeek}</td>
                    <td>{item?.WeekType}</td>
                    <td className="text-end">{commonService.formateAmount(Math.round(item?.GroupADR))}</td>
                    <td>{item?.GroupBlock}</td>
                    <td>{item?.GroupOTB}</td>
                    <td className="text-end">{commonService.formateAmount(Math.round(item?.GroupRevenue))}</td>
                    <td>{item?.TransientRoomSold}</td>
                    <td className="text-end">{commonService.formateAmount(Math.round(item?.TransientRevenue))}</td>
                    <td className="text-end">{commonService.formateAmount(Math.round(item?.TransientADR))}</td>
                    <td>{commonService.formateRoom(item?.LYTotalInventory)}</td>
                    <td>{commonService.formateRoom(item?.LYTotalRoomSold)}</td>
                    <td className="text-end">{commonService.formateAmount(Math.round(item?.LYTotalRevenue))}</td>
                    <td className="text-end">{commonService.formateAmount(Math.round(item?.LYTotalADR))}</td>
                    <td>{item?.LYTotalOccupancy}</td>
                    <td className="text-end">{commonService.formateAmount(Math.round(item?.LYTotalRevPar))}</td>
                    <td>{Math.round(item?.LYTotalOccPerc)}%</td>
                    <td>{commonService.formateRoom(item?.YestDayInventory)}</td>
                    <td>{commonService.formateRoom(item?.YestDayRoomSold)}</td>
                    <td className="text-end">{commonService.formateAmount(Math.round(item?.YestDayRevenue))}</td>
                    <td className="text-end">{commonService.formateAmount(Math.round(item?.YestDayADR))}</td>
                    <td>{item?.YestDayOccupancy}</td>
                    <td className="text-end">{commonService.formateAmount(Math.round(item?.YestDayRevPar))}</td>
                    <td>{Math.round(item?.YestDayOccPerc)}%</td>
                    <td>{commonService.formateRoom(item?.LYPaceInventory)}</td>
                    <td>{commonService.formateRoom(item?.LYPaceRoomSold)}</td>
                    <td className="text-end">{commonService.formateAmount(Math.round(item?.LYPaceRevenue))}</td>
                    <td className="text-end">{commonService.formateAmount(Math.round(item?.LYPaceADR))}</td>
                    <td>{item?.LYPaceOccupancy}</td>
                    <td className="text-end">{commonService.formateAmount(Math.round(item?.LYPaceRevPar))}</td>
                    <td>{Math.round(item?.LYPaceOccPerc)}%</td>
                  </tr>
                ))
              ) : (
                <tr>No Data Found</tr>
              )}
            </tbody>
          </Table>
          </div>
        </div>
      </div>
      {/* {showNoteSidebar && (
        <WidgetNote
          show={showNoteSidebar}
          handleClose={closeNoteSideBar}
          widgetId={para?.widgetId}
          propertyId={propertyDetailFromRTK?.propertyid}
          rowTitle={para?.rowTitle}
        />
      )} */}
      {showNoteSidebar && (
        <AddWidgetModal
          showModal={showNoteSidebar}
          closeModal={closeNoteSideBar}
          widgetId={para?.widgetId}
          propertyId={propertyDetailFromRTK?.propertyid}
          rowTitle={para?.rowTitle}
        />
      )}
      {widgetModal && (
        <WidgetDetailModal
          showModal={widgetModal}
          closeModal={closeWidgetModal}
          data={widgetDetailRef.current}
        />
      )}
    </div>
  );
};

export default DailyDataTransaction;
