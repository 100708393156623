import React, { useEffect, useRef, useState } from "react";
import AccountFilter from "./AccountFilter";
import { apiCall } from "../../../../utils/axiosService";
import { API_URL } from "../../../../utils/apiRoutes";
import { apiResponse } from "../../../../utils/apiResponse";
import { useSelector } from "react-redux";
import listViewIcon from "../../../../Assets/Images/CRM/listViewIcon.svg";
import { Accordion, Button, Form, Table } from "react-bootstrap";
import NoteIcon from "../../../../Assets/Images/CRM/notes.svg";
import downloadIcon from "../../../../Assets/Images/Operation/downloadIcon.svg";
import { commonService } from "../../../../utils/commonService";
import Loader from "../../../Loader/Loader";
import profileImage from "../../../../Assets/Images/Configuration/userNo1.png";
import infoIcon from "../../../../Assets/Images/Action Icons/infoIcon..svg";
import { compName } from "../../../../utils/constant";
import WidgetDetailModal from "../../WidgetDetailModal";
import WidgetNote from "../../WidgetNote";
import AddWidgetModal from "../../AddWidgetModal";
import emailIcon from "../../../../Assets/Images/Email.svg";
import send from "../../../../Assets/Images/Revenue-management/send.svg";
import { useParams } from "react-router-dom";
import Comments from "../Comments/Comment";
import moment from "moment";

const Account = ({
  setCompDataFromChild,
  rowIndex,
  colIndex,
  api,
  para,
  setting,
}) => {
  const tableData = [
    {
      RateCode: "-",
      RMS: "0",
      REV: "0",
      ADR: "0",
      LeadTime: "0",
      LOS: "0",
      "Rate Efficiency": "0",
      "RM Nights % on >= 95% OCC": "0",
      "Displaced REV": "0",
      "Displaced REV %": "0",
      "Cost/Benefit": "0",
      "Wish Rate": "0",
      Opportunity: "0",
    },
    {
      RateCode: "-",
      RMS: "0",
      REV: "0",
      ADR: "0",
      LeadTime: "0",
      LOS: "0",
      "Rate Efficiency": "0",
      "RM Nights % on >= 95% OCC": "0",
      "Displaced REV": "0",
      "Displaced REV %": "0",
      "Cost/Benefit": "0",
      "Wish Rate": "0",
      Opportunity: "0",
    },
    {
      RateCode: "-",
      RMS: "0",
      REV: "0",
      ADR: "0",
      LeadTime: "0",
      LOS: "0",
      "Rate Efficiency": "0",
      "RM Nights % on >= 95% OCC": "0",
      "Displaced REV": "0",
      "Displaced REV %": "0",
      "Cost/Benefit": "0",
      "Wish Rate": "0",
      Opportunity: "0",
    },
    {
      RateCode: "-",
      RMS: "0",
      REV: "0",
      ADR: "0",
      LeadTime: "0",
      LOS: "0",
      "Rate Efficiency": "0",
      "RM Nights % on >= 95% OCC": "0",
      "Displaced REV": "0",
      "Displaced REV %": "0",
      "Cost/Benefit": "0",
      "Wish Rate": "0",
      Opportunity: "0",
    },
    {
      RateCode: "-",
      RMS: "0",
      REV: "0",
      ADR: "0",
      LeadTime: "0",
      LOS: "0",
      "Rate Efficiency": "0",
      "RM Nights % on >= 95% OCC": "0",
      "Displaced REV": "0",
      "Displaced REV %": "0",
      "Cost/Benefit": "0",
      "Wish Rate": "0",
      Opportunity: "0",
    },
    {
      RateCode: "-",
      RMS: "0",
      REV: "0",
      ADR: "0",
      LeadTime: "0",
      LOS: "0",
      "Rate Efficiency": "0",
      "RM Nights % on >= 95% OCC": "0",
      "Displaced REV": "0",
      "Displaced REV %": "0",
      "Cost/Benefit": "0",
      "Wish Rate": "0",
      Opportunity: "0",
    },
    {
      RateCode: "-",
      RMS: "0",
      REV: "0",
      ADR: "0",
      LeadTime: "0",
      LOS: "0",
      "Rate Efficiency": "0",
      "RM Nights % on >= 95% OCC": "0",
      "Displaced REV": "0",
      "Displaced REV %": "0",
      "Cost/Benefit": "0",
      "Wish Rate": "0",
      Opportunity: "0",
    },
    {
      RateCode: "-",
      RMS: "0",
      REV: "0",
      ADR: "0",
      LeadTime: "0",
      LOS: "0",
      "Rate Efficiency": "0",
      "RM Nights % on >= 95% OCC": "0",
      "Displaced REV": "0",
      "Displaced REV %": "0",
      "Cost/Benefit": "0",
      "Wish Rate": "0",
      Opportunity: "0",
    },
    {
      RateCode: "-",
      RMS: "0",
      REV: "0",
      ADR: "0",
      LeadTime: "0",
      LOS: "0",
      "Rate Efficiency": "0",
      "RM Nights % on >= 95% OCC": "0",
      "Displaced REV": "0",
      "Displaced REV %": "0",
      "Cost/Benefit": "0",
      "Wish Rate": "0",
      Opportunity: "0",
    },
    {
      RateCode: "-",
      RMS: "0",
      REV: "0",
      ADR: "0",
      LeadTime: "0",
      LOS: "0",
      "Rate Efficiency": "0",
      "RM Nights % on >= 95% OCC": "0",
      "Displaced REV": "0",
      "Displaced REV %": "0",
      "Cost/Benefit": "0",
      "Wish Rate": "0",
      Opportunity: "0",
    },
  ];

  let date = new Date();
  let startDateOfYear = new Date(date.getFullYear(), 0, 1);
  let endDateOfYear = new Date(date.getFullYear(), 11, 31);

  let filterObj = {
    asOfDate: "",
    stayDateStart: startDateOfYear,
    stayDateEnd: endDateOfYear,
    channel: {},
    company: {},
    marketSegment: {},
    rateCode: {},
    rateCodeDescription: {},
    leadTimeOperator: ">=",
    leadTime: "-1",
    losOperator: ">=",
    los: "0",
  };
  const asOfDateFromRTK = useSelector((store) => store.headerProperty.asOfDate);

  const [showSideBar, setShowSideBar] = useState(false);
  const filterRef = useRef(filterObj);
  const [accountData, setAccountData] = useState(null);
  const [selectedType, setSelectedType] = useState("Rate Code");
  const [loading, setLoading] = useState(false);
  const isFilterApiCall = useRef(false);
  const totalRef = useRef();
  const callApi = useRef(false);
  const [showNoteSidebar, setShowNoteSidebar] = useState(false);
  const [widgetModal, setWidgetModal] = useState(false);
  const widgetDetailRef = useRef();
  const propertyDetailFromRTK = useSelector(
    (store) => store.headerProperty.propertyDetail
  );
  const defaultAsOfDateFromRTK = useSelector(
    (store) => store.headerProperty.defaultAsOfDate
  );
  const { snapshottitle, propertyCode, snapshotId } = useParams();

  const closeWidgetModal = () => {
    setWidgetModal(false);
  };
  const closeNoteSideBar = () => {
    setShowNoteSidebar(false);
  };

  const closeSideBar = () => {
    setShowSideBar(false);
  };

  const updateFilter = (obj) => {
    filterRef.current = obj;
    callApi.current = !callApi.current;
  };

  const getKeyFromObj = (obj) => {
    let selectedValue = [];

    Object?.entries(obj)?.map(([key, value]) => {
      if (value) {
        selectedValue = [...selectedValue, key];
      }
    });

    return selectedValue?.toString();
  };

  const getFilterData = async () => {
    setLoading(true);
    let filterDataResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.SnapShot +
          API_URL.SNAPSHOT_ACCOUNT_WIDGET.GET_FILTER,
        body: { propertyCode: para?.propertyCode },
      },
      false
    );

    let response = apiResponse(false, filterDataResponse);

    if (response?.isValidate) {
      let dataFromApi = response?.data?.data;
      let companyObj = {},
        channelObj = {},
        marketSegment = {},
        rateCode = {},
        rateCodeDescription = {};

      dataFromApi?.Company?.map((item) => {
        if (item?.Company) {
          companyObj = { ...companyObj, [item?.Company]: true };
        }
      });

      dataFromApi?.Channel?.map((item) => {
        if (item?.CombinedChannel) {
          channelObj = { ...channelObj, [item?.CombinedChannel]: true };
        }
      });

      dataFromApi?.Marketsegments?.map((item) => {
        if (item?.market_segmentation) {
          marketSegment = {
            ...marketSegment,
            [item?.market_segmentation]: true,
          };
        }
      });

      dataFromApi?.RateCode?.map((item) => {
        if (item?.RateCode) {
          rateCode = { ...rateCode, [item?.RateCode]: true };
        }
      });

      dataFromApi?.RateCodeDescript?.map((item) => {
        if (item?.RateCodeDescript) {
          rateCodeDescription = {
            ...rateCodeDescription,
            [item?.RateCodeDescript]: true,
          };
        }
      });

      filterRef.current.company = companyObj;
      filterRef.current.channel = channelObj;
      filterRef.current.marketSegment = marketSegment;
      filterRef.current.rateCode = rateCode;
      filterRef.current.rateCodeDescription = rateCodeDescription;
      isFilterApiCall.current = true;
    }
  };

  const getRateCodeData = async () => {
    setLoading(true);
    let payloadUrl = "";
    if (selectedType === "Rate Code") {
      payloadUrl =
        API_URL.BASE_API_URL.SnapShot +
        API_URL.SNAPSHOT_ACCOUNT_WIDGET.GET_RATECODE;
    } else if (selectedType === "Rate Code Description") {
      payloadUrl =
        API_URL.BASE_API_URL.SnapShot +
        API_URL.SNAPSHOT_ACCOUNT_WIDGET.GET_RATECODE_DESCRIPTION;
    } else if (selectedType === "Company") {
      payloadUrl =
        API_URL.BASE_API_URL.SnapShot +
        API_URL.SNAPSHOT_ACCOUNT_WIDGET.GET_COMPANY;
    } else if (selectedType === "Market Segment") {
      payloadUrl =
        API_URL.BASE_API_URL.SnapShot +
        API_URL.SNAPSHOT_ACCOUNT_WIDGET.GET_MARKET_SEGMENT;
    } else if (selectedType === "Channel") {
      payloadUrl =
        API_URL.BASE_API_URL.SnapShot +
        API_URL.SNAPSHOT_ACCOUNT_WIDGET.GET_CHANNEL;
    }
    let rteCodeResponse = await apiCall(
      {
        method: "POST",
        url: payloadUrl,
        body: {
          propertyCode: para?.propertyCode,
          AsOfDate: commonService.getDateForNode(filterRef.current?.asOfDate),
          startDate: commonService.getDateForNode(
            filterRef.current?.stayDateStart
          ),
          endDate: commonService.getDateForNode(filterRef.current?.stayDateEnd),
          Channel: getKeyFromObj(filterRef.current?.channel),
          Company: getKeyFromObj(filterRef.current?.company),
          MarketSegment: getKeyFromObj(filterRef.current?.marketSegment),
          RateCode: getKeyFromObj(filterRef.current?.rateCode),
          RateCodeDescript: `,${getKeyFromObj(
            filterRef.current?.rateCodeDescription
          )}`,
          LeadTimeOperator: filterRef.current?.leadTimeOperator,
          LeadTime: filterRef.current?.leadTime,
          LOSOperator: filterRef.current?.losOperator,
          LOS: filterRef.current?.los,
        },
      },
      false
    );

    let response = apiResponse(false, rteCodeResponse);

    if (response?.isValidate) {
      let total = {
        roomNight: 0,
        adr: 0,
        rev: 0,
        revContri: 100,
        leadTime: 0,
        los: 0,
        rateEfficiency: 0,
        rmNights: 0,
        displacedRev: 0,
        dispalcedRevPercentage: 0,
        cost: 0,
        wishRate: 0,
        opportunity: 0,
        stlyRms: 0,
        stlyAdr: 0,
        stlyRev: 0,
      };

      response?.data?.data?.map((item) => {
        total.roomNight =
          total.roomNight + (item?.RMS ? parseInt(item?.RMS) : 0);
        total.adr = total.adr + (item?.ADR ? parseInt(item?.ADR) : 0);
        total.rev = total.rev + (item?.REV ? parseInt(item?.REV) : 0);
        total.leadTime =
          total.leadTime + (item?.LeadTime ? parseFloat(item?.LeadTime) : 0);
        total.los = total.los + (item?.LOS ? parseFloat(item?.LOS) : 0);
        total.rateEfficiency =
          total.rateEfficiency +
          (item?.["Rate Efficiency"]
            ? parseFloat(item?.["Rate Efficiency"])
            : 0);
        total.rmNights =
          total.rmNights +
          (item?.["RM Nights % on >= 95% OCC"]
            ? parseFloat(item?.["RM Nights % on >= 95% OCC"])
            : 0);
        total.displacedRev =
          total.displacedRev +
          (item?.["Displaced REV"] ? parseFloat(item?.["Displaced REV"]) : 0);
        total.dispalcedRevPercentage =
          total.dispalcedRevPercentage +
          (item?.["Displaced REV %"]
            ? parseFloat(item?.["Displaced REV %"])
            : 0);
        total.cost =
          total.cost +
          (item?.["Cost/Benefit"] ? parseFloat(item?.["Cost/Benefit"]) : 0);
        total.wishRate =
          total.wishRate +
          (item?.["Wish Rate"] ? parseFloat(item?.["Wish Rate"]) : 0);
        total.opportunity =
          total.opportunity +
          (item?.["Opportunity"] ? parseFloat(item?.["Opportunity"]) : 0);
        total.stlyRms =
          total.stlyRms + (item?.STLY_RMS ? parseInt(item?.STLY_RMS) : 0);
        total.stlyAdr =
          total.stlyAdr + (item?.STLY_ADR ? parseInt(item?.STLY_ADR) : 0);
        total.stlyRev =
          total.stlyRev + (item?.STLY_REV ? parseInt(item?.STLY_REV) : 0);
      });

      totalRef.current = total;
      setAccountData(response?.data?.data);
      setLoading(false);
    }

    if (!response?.isValidate) {
      setAccountData(tableData);
      setLoading(false);
    }
  };

  const getAccountData = async () => {
    getRateCodeData();
  };

  const callBothApi = async () => {
    await getFilterData();
    await getAccountData();
  };

  useEffect(() => {
    if (api === 0) {
      setAccountData(tableData);
    } else {
      if (para?.asOfDate) {
        if (isFilterApiCall.current === true) {
          getAccountData();
        } else {
          filterRef.current.asOfDate = para?.asOfDate;
          callBothApi();
        }
      }
    }
  }, [propertyDetailFromRTK, para?.asOfDate, callApi.current, selectedType]);

  useEffect(() => {
    filterRef.current.asOfDate = asOfDateFromRTK;
    callApi.current = !callApi.current;
  }, [asOfDateFromRTK]);

  const [tblids, settblids] = useState({
    AccountWidgetTable: commonService.GenerateGUID(),
  });

  const rateAfficiencyAverage = (items) => {
    const sum = items.reduce((accumulator, item) => {
      const expressionResult =
        (item?.ADR / Math.round(item?.rev / item?.roomNight)) * 100;
      return accumulator + expressionResult;
    }, 0);
    return sum;
  };

  let rateEfficVals = [];

  return (
    <div style={{ position: "relative" }}>
      <img
        className="heading-menu-btns filter-icon-position"
        src={listViewIcon}
        alt="listViewIcon"
        onClick={() => {
          setShowSideBar(true);
        }}
      />

      <div className="widget-container">
        {api !== 0 && (
          <div
            className="gap-1 widget-container-menu"
            style={{ right: "85px" }}
          >
            {/* <Button className="heading-menu-btns" type="button">
              <img
                src={NoteIcon}
                alt="menuIcon"
                onClick={() => {
                  setShowNoteSidebar(true);
                }}
              />
            </Button> */}
            <Button
              className="heading-menu-btns"
              type="button"
              title="Send Mail"
            >
              <img
                src={emailIcon}
                alt="emailIcon"
                onClick={() => {
                  commonService.fnSendWidgetMail(
                    tblids.AccountWidgetTable,
                    "Account",
                    snapshotId,
                    defaultAsOfDateFromRTK,
                    "",
                    para?.widgetId
                  );
                }}
              />
            </Button>
            <Button className="heading-menu-btns" type="button">
              <img
                src={infoIcon}
                alt="info"
                onClick={() => {
                  widgetDetailRef.current = {
                    widgetdetails: "",
                    usernote: "",
                    compName: compName?.account,
                    widgetId: para?.widgetId,
                  };
                  setWidgetModal(true);
                }}
              />
            </Button>
            <Button className="heading-menu-btns" type="button">
              <img
                src={downloadIcon}
                alt="menuIcon"
                import={tblids.AccountWidgetTable}
                onClick={() =>
                  commonService.fnExportInCSV(
                    tblids.AccountWidgetTable,
                    // "Account"
                    `${para?.propertyCode}-Account-${moment(new Date()).format(
                      "MMDDYYYY"
                    )}`
                  )
                }
              />
            </Button>
          </div>
        )}
        <div className="ViewPropertySetupTable">
          <div className="stripped__table ">
            <div className="table-responsive tblDynamicPickupSticky">
              <Table id={tblids.AccountWidgetTable}>
                <thead className="tbl-header-sticky">
                  <tr>
                    <th className="border_right width150">
                      <div>
                        <Form.Select
                          value={selectedType}
                          onChange={(e) => {
                            setSelectedType(e.target.value);
                          }}
                        >
                          <option value="Rate Code">Rate Code</option>
                          <option value="Rate Code Description">
                            Rate Code Description
                          </option>
                          <option value="Company">Company</option>
                          <option value="Market Segment">Market Segment</option>
                          <option value="Channel">Channel</option>
                        </Form.Select>
                        <span style={{ display: "none" }}>{selectedType}</span>
                      </div>
                    </th>
                    <th colSpan={6} className="text-center border_right">
                      Production
                    </th>
                    <th colSpan={5} className="text-center border_right">
                      Stats
                    </th>
                    <th colSpan={3} className="text-center border_right">
                      Displacement
                    </th>
                    <th colSpan={2} className="text-center">
                      Opportunity
                    </th>
                  </tr>
                  <tr>
                    <th className="border_right"></th>
                    <th className="text-center whitespace-wrap">Room Nights</th>
                    <th></th>
                    <th className="text-end">ADR</th>
                    <th></th>
                    <th className="text-end ">REV</th>
                    <th className="border_right"></th>
                    <th className="text-center whitespace-wrap">
                      REV Contribution
                    </th>
                    <th className="text-center whitespace-wrap">Lead Time</th>
                    <th className="text-center">LOS</th>
                    <th className="text-center whitespace-wrap">
                      Rate Efficiency
                    </th>
                    <th className="text-center border_right width100">{`RM Nights % on >= 95% OCC`}</th>
                    <th className="text-end whitespace-wrap">
                      Displaced REV
                    </th>
                    <th className="text-center whitespace-wrap">
                      Displaced REV %
                    </th>
                    <th className="text-end border_right whitespace-wrap">
                      Cost/Benefit
                    </th>
                    <th className="text-end whitespace-wrap">Wish Rate</th>
                    <th className="text-end">Opportunity</th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr style={{ height: "80px", position: "relative" }}>
                      <Loader />
                    </tr>
                  ) : (
                    accountData?.map((item, index) => {
                      const rateEfficVal = (
                        ((item?.ADR || 0) /
                          Math.round(
                            totalRef.current?.rev / totalRef.current?.roomNight
                          )) *
                        100
                      ).toFixed(1);

                      rateEfficVals.push(parseFloat(rateEfficVal));

                      const averageRateEffic = (
                        rateEfficVals.reduce(
                          (accumulator, currentValue) =>
                            accumulator + currentValue,
                          0
                        ) / (rateEfficVals.length || 1)
                      ).toFixed(1);

                      let keyName = "";
                      if (selectedType === "Rate Code") {
                        keyName = "RateCode";
                      } else if (selectedType === "Rate Code Description") {
                        keyName = "RateCodeDescript";
                      } else if (selectedType === "Company") {
                        keyName = "Company";
                      } else if (selectedType === "Market Segment") {
                        keyName = "MarketSegment";
                      } else if (selectedType === "Channel") {
                        keyName = "Channel";
                      }

                      let varientRms =
                        (item?.RMS ? parseInt(item?.RMS) : 0) -
                        (item?.STLY_RMS ? parseInt(item?.STLY_RMS) : 0);
                      let varientAdr =
                        (item?.ADR ? parseInt(item?.ADR) : 0) -
                        (item?.STLY_ADR ? parseInt(item?.STLY_ADR) : 0);
                      let varientRev =
                        (item?.REV ? parseInt(item?.REV) : 0) -
                        (item?.STLY_REV ? parseInt(item?.STLY_REV) : 0);
                      return (
                        <React.Fragment key={index}>
                          <tr key={index} className="shadowOnHover">
                            <td className="border_right">{item?.[keyName]}</td>
                            <td className="text-center">
                              {commonService.formateRoom(item?.RMS)}
                            </td>
                            <td
                              className={`text-center variance-font-size ${
                                varientRms > 0 ? "greenText" : "redText"
                              }`}
                            >
                              {commonService.formateRoom(varientRms)}
                            </td>
                            <td className="text-end">
                              {commonService.formateAmount(item?.ADR)}
                            </td>
                            <td
                              className={`text-end variance-font-size ${
                                varientAdr > 0 ? "greenText" : "redText"
                              }`}
                            >
                              {commonService.formateAmount(varientAdr)}
                            </td>
                            <td className="text-end">
                              {commonService.formateAmount(item?.REV)}
                            </td>
                            <td
                              className={`text-end variance-font-size border_right ${
                                varientRev > 0 ? "greenText" : "redText"
                              }`}
                            >
                              {commonService.formateAmount(varientRev)}
                            </td>
                            <td className="text-center">
                              {totalRef.current?.revContri > 0 && item?.REV > 0
                                ? (
                                    (parseInt(item?.REV) * 100) /
                                    totalRef.current?.rev
                                  )?.toFixed(1)
                                : 0}
                              %
                            </td>
                            <td className="text-center">{item?.LeadTime}</td>
                            <td className="text-center">{item?.LOS}</td>

                            <td className="text-center">
                              {(
                                (item?.ADR /
                                  Math.round(
                                    totalRef.current?.rev /
                                      totalRef.current?.roomNight
                                  )) *
                                100
                              ).toFixed(1)}
                              %
                            </td>

                            <td className="border_right text-center">
                              {item?.["RM Nights % on >= 95% OCC"]}
                            </td>
                            <td className="text-end">
                              {commonService.formateAmount(
                                item?.["Displaced REV"]
                              )}
                            </td>
                            <td className="text-center">
                              {item?.["Displaced REV %"]}
                            </td>
                            <td className="border_right text-end">
                              {commonService.formateAmount(
                                item?.["Cost/Benefit"]
                              )}
                            </td>
                            <td className="text-end">
                              {commonService.formateAmount(item?.["Wish Rate"])}
                            </td>
                            <td className="text-end">
                              {commonService.formateAmount(item?.Opportunity)}
                            </td>
                          </tr>
                          {index === accountData?.length - 1 && (
                            <tr>
                              <td className="boldTd border_right">Total</td>
                              <td className="boldTd text-center">
                                {commonService.formateRoom(
                                  totalRef.current?.roomNight
                                )}
                              </td>
                              <td className="boldTd text-center">
                                {commonService.formateRoom(
                                  totalRef.current?.roomNight -
                                    totalRef.current?.stlyRms
                                )}
                              </td>
                              <td className="boldTd text-end">
                                {totalRef.current?.rev > 0 &&
                                totalRef.current?.roomNight > 0
                                  ? commonService.formateAmount(
                                      Math.round(
                                        totalRef.current?.rev /
                                          totalRef.current?.roomNight
                                      )
                                    )
                                  : commonService.formateAmount(0)}
                              </td>
                              <td className="boldTd text-end">
                                {totalRef.current?.stlyRev > 0 &&
                                totalRef.current?.stlyRms > 0
                                  ? commonService.formateAmount(
                                      Math.round(
                                        totalRef.current?.stlyRev /
                                          totalRef.current?.stlyRms
                                      )
                                    )
                                  : commonService.formateAmount(0)}
                              </td>
                              <td className="boldTd text-end">
                                {commonService.formateAmount(
                                  totalRef.current?.rev
                                )}
                              </td>
                              <td className="boldTd text-end border_right">
                                {commonService.formateAmount(
                                  totalRef.current?.rev -
                                    totalRef.current?.stlyRev
                                )}
                              </td>
                              <td className="boldTd text-center">
                                {totalRef.current?.revContri}%
                              </td>
                              <td className="boldTd text-center">
                                {(
                                  totalRef.current?.leadTime /
                                  accountData?.length
                                )?.toFixed(1)}
                              </td>
                              <td className="boldTd text-center">
                                {(
                                  totalRef.current?.los / accountData?.length
                                )?.toFixed(1)}
                              </td>

                              <td className="boldTd text-center">
                                {averageRateEffic} %
                              </td>

                              <td className="boldTd text-center border_right">
                                {totalRef.current?.rmNights}
                              </td>
                              <td className="boldTd text-end">
                                {commonService.formateAmount(
                                  totalRef.current?.displacedRev
                                )}
                              </td>
                              <td className="boldTd text-center">
                                {totalRef.current?.dispalcedRevPercentage}
                              </td>
                              <td className="boldTd text-end border_right">
                                {commonService.formateAmount(
                                  totalRef.current?.cost
                                )}
                              </td>
                              <td className="boldTd text-end">
                                {commonService.formateAmount(
                                  totalRef.current?.wishRate
                                )}
                              </td>
                              <td className="boldTd text-end">
                                {commonService.formateAmount(
                                  totalRef.current?.opportunity
                                )}
                              </td>
                            </tr>
                          )}
                        </React.Fragment>
                      );
                    })
                  )}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
        {api !== 0 && <Comments widgetDetails={para} editorId={"Account"} />}
      </div>
      {showSideBar && (
        <AccountFilter
          showModal={showSideBar}
          closeModal={closeSideBar}
          filterData={filterRef.current}
          updatePayload={updateFilter}
        />
      )}
      {/* {showNoteSidebar && (
        <WidgetNote
          show={showNoteSidebar}
          handleClose={closeNoteSideBar}
          widgetId={para?.widgetId}
          propertyId={propertyDetailFromRTK?.propertyid}
          rowTitle={para?.rowTitle}
        />
      )} */}
      {showNoteSidebar && (
        <AddWidgetModal
          showModal={showNoteSidebar}
          closeModal={closeNoteSideBar}
          widgetId={para?.widgetId}
          propertyId={propertyDetailFromRTK?.propertyid}
          rowTitle={para?.rowTitle}
        />
      )}
      {widgetModal && (
        <WidgetDetailModal
          showModal={widgetModal}
          closeModal={closeWidgetModal}
          data={widgetDetailRef.current}
        />
      )}
    </div>
  );
};

export default Account;
