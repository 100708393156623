import React, { useEffect, useRef, useState } from "react";
import { apiCall } from "../../../../utils/axiosService";
import { API_URL } from "../../../../utils/apiRoutes";
import { useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import { Button, Col, Form, Row, Table } from "react-bootstrap";
import { commonService } from "../../../../utils/commonService";
import { apiResponse } from "../../../../utils/apiResponse";
import moment from "moment";
import Loader from "../../../Loader/Loader";
import { compName } from "../../../../utils/constant";
import { useParams } from "react-router-dom";
import emailIcon from "../../../../Assets/Images/Email.svg";
import NoteIcon from "../../../../Assets/Images/CRM/notes.svg";
import downloadIcon from "../../../../Assets/Images/Operation/downloadIcon.svg";
import infoIcon from "../../../../Assets/Images/Action Icons/infoIcon..svg";
import AddWidgetModal from "../../AddWidgetModal";
import WidgetDetailModal from "../../WidgetDetailModal";
import exampleFile from "../../../../Assets/Files/STR_analysis.xlsx";
import { Bar } from "react-chartjs-2";
import { Chart } from "chart.js/auto";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const WeeklyStar = ({
  setCompDataFromChild,
  rowIndex,
  colIndex,
  api,
  para,
  setting,
  setWeeklyDate,
}) => {
  const propertyDetailFromRTK = useSelector(
    (store) => store.headerProperty.propertyDetail
  );
  const [selectedDate, setSelectedDate] = useState(
    new Date().setDate(new Date().getDate() - 7)
  );
  const [loading, setLoading] = useState(false);
  const [reportData, setReportData] = useState(null);
  const reportDataRef = useRef();
  const datesArrRef = useRef();
  const tableDataRef = useRef([]);
  const [clickedTr, setClickedTr] = useState({
    mainRow: "",
    subRow: "",
    day: "",
    field: "",
  });
  const [showNoteSidebar, setShowNoteSidebar] = useState(false);
  const [widgetModal, setWidgetModal] = useState(false);
  const widgetDetailRef = useRef();
  const defaultAsOfDateFromRTK = useSelector(
    (store) => store.headerProperty.defaultAsOfDate
  );
  const dayNames = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const { snapshottitle, propertyCode, snapshotId } = useParams();
  const [submitLoading, setSubmitLoading] = useState(false);
  const [file, setFile] = useState("");
  const inputFile = useRef();
  const [fileImportLoading, setFileImportLoading] = useState(false);
  const closeWidgetModal = () => {
    setWidgetModal(false);
  };
  const closeNoteSideBar = () => {
    setShowNoteSidebar(false);
  };
  // let curr = new Date(selectedDate);
  // let first = curr.getDate() - curr.getDay();
  // let last = first + 6;

  // let firstday = new Date(curr.setDate(first));
  // let lastday = new Date(curr.setDate(last));

  function getWeekStartAndEnd(date) {
    const currentDate = new Date(date);

    const weekStart = new Date(currentDate);
    weekStart.setDate(currentDate.getDate() - currentDate.getDay());

    const weekEnd = new Date(currentDate);
    weekEnd.setDate(currentDate.getDate() + (6 - currentDate.getDay()));

    return {
      start: weekStart,
      end: weekEnd,
    };
  }

  // const inputDate = new Date(selectedDate);
  const inputDate = new Date("2024-08-11");
  const weekDates = getWeekStartAndEnd(inputDate);
  let firstday = new Date(weekDates.start);
  let lastday = new Date(weekDates.end);

  const [occChartData, setOccChartData] = useState(null);
  const [adrChartData, setAdrChartData] = useState(null);
  const [revParChartData, setRevParChartData] = useState(null);
  const [stayStartDate, setStayStartDate] = useState(new Date(weekDates.start));
  const [stayEndDate, setStayEndDate] = useState(new Date(weekDates.end));

  const handleStayDateChange = (dates) => {
    const [start, end] = dates;
    setStayStartDate(start);
    setStayEndDate(end ? end : "");
    setFileImportLoading(true);
    if (start && end) {
      getMonthlyReport(start, end);
    }
  };

  const occChartOption = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
    plugins: {
      tooltip: {
        mode: "index",
        intersect: false,
      },
    },
    maintainAspectRatio: false,
  };

  const getWeeklyReport = async () => {
    setLoading(true);
    let weeklyReportResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.SnapShot +
          API_URL.STR_REPORT_REVPAK.GET_WEEKLY_REPORT,
        body: {
          propertyCode: para?.propertyCode,
          selectedDate: commonService.getDateForNode("2024-08-11"),
          // selectedDate: stayStartDate
          // ? commonService.getDateForNode(stayStartDate)
          // : "",
        },
      },
      false
    );

    let response = apiResponse(false, weeklyReportResponse);

    if (response?.isValidate) {
      tableDataRef.current = [];
      datesArrRef.current = commonService.getDateBetweenTwoDates(
        new Date(firstday),
        new Date(lastday)
      );
      let labels = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
      let occCurrentYearMpi = {
          label: "Current Year MPI",
          data: [],
          backgroundColor: "#1CC26A",
          borderColor: "#1CC26A",
          // borderWidth: 1,
        },
        occLastYearMpi = {
          label: "Last Year MPI",
          data: [],
          backgroundColor: "#1893E5",
          borderColor: "#1893E5",
          // borderWidth: 1,
        },
        adrCurrentYearMpi = {
          label: "Current Year MPI",
          data: [],
          backgroundColor: "#1CC26A",
          borderColor: "#1CC26A",
          // borderWidth: 1,
        },
        adrLastYearMpi = {
          label: "Last Year MPI",
          data: [],
          backgroundColor: "#1893E5",
          borderColor: "#1893E5",
          // borderWidth: 1,
        },
        revParCurrentYearMpi = {
          label: "Current Year MPI",
          data: [],
          backgroundColor: "#1CC26A",
          borderColor: "#1CC26A",
          // borderWidth: 1,
        },
        revParLastYearMpi = {
          label: "Last Year MPI",
          data: [],
          backgroundColor: "#1893E5",
          borderColor: "#1893E5",
          // borderWidth: 1,
        };

      datesArrRef.current?.map((item, index) => {
        let findObj = response?.data?.data?.filter(
          (x) =>
            moment(x?.StarReportDate).format("YYYY-MM-DD") ===
            moment(item).format("YYYY-MM-DD")
        );
        let dateKey = moment(item).format("YYYY-MM-DD");

        if (findObj?.[0]) {
          tableDataRef.current = [...tableDataRef.current, findObj?.[0]];
          occCurrentYearMpi.data.push(
            findObj?.[0]?.OccIndex ? findObj?.[0]?.OccIndex : 0
          );
          occLastYearMpi.data.push(
            findObj?.[0]?.OccIndexLy ? findObj?.[0]?.OccIndexLy : 0
          );

          adrCurrentYearMpi.data.push(
            findObj?.[0]?.AdrIndex ? findObj?.[0]?.AdrIndex : 0
          );
          adrLastYearMpi.data.push(
            findObj?.[0]?.AdrIndexLy ? findObj?.[0]?.AdrIndexLy : 0
          );

          revParCurrentYearMpi.data.push(
            findObj?.[0]?.RevparIndex ? findObj?.[0]?.RevparIndex : 0
          );
          revParLastYearMpi.data.push(
            findObj?.[0]?.RevparIndexLy ? findObj?.[0]?.RevparIndexLy : 0
          );
        } else {
          tableDataRef.current = [
            ...tableDataRef.current,
            {
              propertyCode: para?.propertyCode,
              AsOfDate: para?.asOfDate,
              StarReportDate: moment(item).format("YYYY-MM-DD"),
              OccMyProperty: null,
              OccMyPropertyChange: null,
              OccMyPropertyLy: null,
              OccCompset: null,
              OccCompsetChange: null,
              OccCompsetLy: null,
              OccIndex: null,
              OccIndexChange: null,
              OccIndexLy: null,
              AdrMyProperty: null,
              AdrMyPropertyChange: null,
              AdrMyPropertyLy: null,
              AdrCompset: null,
              AdrCompsetChange: null,
              AdrCompsetLy: null,
              AdrIndex: null,
              AdrIndexChange: null,
              AdrIndexLy: null,
              RevparMyProperty: null,
              RevparMyPropertyChange: null,
              RevparMyPropertyLy: null,
              RevparCompset: null,
              RevparCompsetChange: null,
              RevparCompsetLy: null,
              RevparIndex: null,
              RevparIndexChange: null,
              RevparIndexLy: null,
            },
          ];
          occCurrentYearMpi.data.push(0);
          occLastYearMpi.data.push(0);

          adrCurrentYearMpi.data.push(0);
          adrLastYearMpi.data.push(0);

          revParCurrentYearMpi.data.push(0);
          revParLastYearMpi.data.push(0);
        }
      });

      let totalData = response?.data?.data?.filter(
        (x) => x.StarReportDate === null
      );
      if (totalData?.length > 0) {
        tableDataRef.current = [...tableDataRef.current, totalData?.[0]];
      }
      setReportData(tableDataRef.current);
      setOccChartData({
        labels: labels,
        datasets: [occCurrentYearMpi, occLastYearMpi],
      });
      setAdrChartData({
        labels: labels,
        datasets: [adrCurrentYearMpi, adrLastYearMpi],
      });
      setRevParChartData({
        labels: labels,
        datasets: [revParCurrentYearMpi, revParLastYearMpi],
      });
      reportDataRef.current = tableDataRef.current;
      setLoading(false);
    }

    if (!response?.isValidate) {
      tableDataRef.current = [];
      datesArrRef.current = commonService.getDateBetweenTwoDates(
        new Date(firstday),
        new Date(lastday)
      );
      datesArrRef.current?.map((item, index) => {
        let findObj = response?.data?.data?.filter(
          (x) =>
            moment(x?.StarReportDate).format("YYYY-MM-DD") ===
            moment(item).format("YYYY-MM-DD")
        );
        let dateKey = moment(item).format("YYYY-MM-DD");

        if (findObj?.[0]) {
          tableDataRef.current = [...tableDataRef.current, findObj?.[0]];
        } else {
          tableDataRef.current = [
            ...tableDataRef.current,
            {
              propertyCode: para?.propertyCode,
              AsOfDate: para?.asOfDate,
              StarReportDate: moment(item).format("YYYY-MM-DD"),
              OccMyProperty: null,
              OccMyPropertyChange: null,
              OccMyPropertyLy: null,
              OccCompset: null,
              OccCompsetChange: null,
              OccCompsetLy: null,
              OccIndex: null,
              OccIndexChange: null,
              OccIndexLy: null,
              AdrMyProperty: null,
              AdrMyPropertyChange: null,
              AdrMyPropertyLy: null,
              AdrCompset: null,
              AdrCompsetChange: null,
              AdrCompsetLy: null,
              AdrIndex: null,
              AdrIndexChange: null,
              AdrIndexLy: null,
              RevparMyProperty: null,
              RevparMyPropertyChange: null,
              RevparMyPropertyLy: null,
              RevparCompset: null,
              RevparCompsetChange: null,
              RevparCompsetLy: null,
              RevparIndex: null,
              RevparIndexChange: null,
              RevparIndexLy: null,
            },
          ];
        }
      });
      tableDataRef.current = [
        ...tableDataRef.current,
        {
          propertyCode: para?.propertyCode,
          AsOfDate: para?.asOfDate,
          StarReportDate: null,
          OccMyProperty: null,
          OccMyPropertyChange: null,
          OccMyPropertyLy: null,
          OccCompset: null,
          OccCompsetChange: null,
          OccCompsetLy: null,
          OccIndex: null,
          OccIndexChange: null,
          OccIndexLy: null,
          AdrMyProperty: null,
          AdrMyPropertyChange: null,
          AdrMyPropertyLy: null,
          AdrCompset: null,
          AdrCompsetChange: null,
          AdrCompsetLy: null,
          AdrIndex: null,
          AdrIndexChange: null,
          AdrIndexLy: null,
          RevparMyProperty: null,
          RevparMyPropertyChange: null,
          RevparMyPropertyLy: null,
          RevparCompset: null,
          RevparCompsetChange: null,
          RevparCompsetLy: null,
          RevparIndex: null,
          RevparIndexChange: null,
          RevparIndexLy: null,
        },
      ];
      setReportData(tableDataRef.current);
      reportDataRef.current = tableDataRef.current;
      setLoading(false);
    }
  };

  const getMonthlyReport = async (start, end) => {
    setLoading(true);
    let monthlyReportResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.SnapShot +
          API_URL.STR_REPORT_REVPAK.GET_MONTHLY_REPORT,
        body: {
          propertyCode: para?.propertyCode,
          startDate: start ? commonService.getDateForNode(start) : "",
          endDate: end ? commonService.getDateForNode(end) : "",
        },
      },
      false
    );

    let response = apiResponse(false, monthlyReportResponse);

    if (response?.isValidate) {
      let dataFromApi = response?.data?.data;
      tableDataRef.current = [];
      let labels = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
      let occCurrentYearMpi = {
          label: "Current Year MPI",
          data: [],
          backgroundColor: "#1CC26A",
          borderColor: "#1CC26A",
        },
        occLastYearMpi = {
          label: "Last Year MPI",
          data: [],
          backgroundColor: "#1893E5",
          borderColor: "#1893E5",
        },
        adrCurrentYearMpi = {
          label: "Current Year MPI",
          data: [],
          backgroundColor: "#1CC26A",
          borderColor: "#1CC26A",
        },
        adrLastYearMpi = {
          label: "Last Year MPI",
          data: [],
          backgroundColor: "#1893E5",
          borderColor: "#1893E5",
        },
        revParCurrentYearMpi = {
          label: "Current Year MPI",
          data: [],
          backgroundColor: "#1CC26A",
          borderColor: "#1CC26A",
        },
        revParLastYearMpi = {
          label: "Last Year MPI",
          data: [],
          backgroundColor: "#1893E5",
          borderColor: "#1893E5",
        };

      dayNames?.forEach((item) => {
        let findData = dataFromApi?.filter((x) => x?.weekday?.trim() === item);

        if (findData?.length > 0) {
          tableDataRef.current = [...tableDataRef.current, findData?.[0]];
          occCurrentYearMpi.data.push(
            findData?.[0]?.OccIndex ? findData?.[0]?.OccIndex : 0
          );
          occLastYearMpi.data.push(
            findData?.[0]?.OccIndexLy ? findData?.[0]?.OccIndexLy : 0
          );

          adrCurrentYearMpi.data.push(
            findData?.[0]?.AdrIndex ? findData?.[0]?.AdrIndex : 0
          );
          adrLastYearMpi.data.push(
            findData?.[0]?.AdrIndexLy ? findData?.[0]?.AdrIndexLy : 0
          );

          revParCurrentYearMpi.data.push(
            findData?.[0]?.RevparIndex ? findData?.[0]?.RevparIndex : 0
          );
          revParLastYearMpi.data.push(
            findData?.[0]?.RevparIndexLy ? findData?.[0]?.RevparIndexLy : 0
          );
        } else {
          tableDataRef.current = [
            ...tableDataRef.current,
            {
              propertyCode: para?.propertyCode,
              AsOfDate: para?.asOfDate,
              StarReportDate: moment(new Date()).format("YYYY-MM-DD"),
              OccMyProperty: null,
              OccMyPropertyChange: null,
              OccMyPropertyLy: null,
              OccCompset: null,
              OccCompsetChange: null,
              OccCompsetLy: null,
              OccIndex: null,
              OccIndexChange: null,
              OccIndexLy: null,
              AdrMyProperty: null,
              AdrMyPropertyChange: null,
              AdrMyPropertyLy: null,
              AdrCompset: null,
              AdrCompsetChange: null,
              AdrCompsetLy: null,
              AdrIndex: null,
              AdrIndexChange: null,
              AdrIndexLy: null,
              RevparMyProperty: null,
              RevparMyPropertyChange: null,
              RevparMyPropertyLy: null,
              RevparCompset: null,
              RevparCompsetChange: null,
              RevparCompsetLy: null,
              RevparIndex: null,
              RevparIndexChange: null,
              RevparIndexLy: null,
            },
          ];

          occCurrentYearMpi.data.push(0);
          occLastYearMpi.data.push(0);

          adrCurrentYearMpi.data.push(0);
          adrLastYearMpi.data.push(0);

          revParCurrentYearMpi.data.push(0);
          revParLastYearMpi.data.push(0);
        }
      });
      let totalData = response?.data?.data?.filter((x) => x.weekday === null);
      if (totalData?.length > 0) {
        tableDataRef.current = [...tableDataRef.current, totalData?.[0]];
      }
      setReportData(tableDataRef.current);
      setOccChartData({
        labels: labels,
        datasets: [occCurrentYearMpi, occLastYearMpi],
      });
      setAdrChartData({
        labels: labels,
        datasets: [adrCurrentYearMpi, adrLastYearMpi],
      });
      setRevParChartData({
        labels: labels,
        datasets: [revParCurrentYearMpi, revParLastYearMpi],
      });
      reportDataRef.current = tableDataRef.current;
      setLoading(false);
    }

    if (!response?.isValidate) {
      tableDataRef.current = [];
      dayNames?.forEach((item) => {
        tableDataRef.current = [
          ...tableDataRef.current,
          {
            propertyCode: para?.propertyCode,
            AsOfDate: para?.asOfDate,
            StarReportDate: moment(new Date()).format("YYYY-MM-DD"),
            OccMyProperty: null,
            OccMyPropertyChange: null,
            OccMyPropertyLy: null,
            OccCompset: null,
            OccCompsetChange: null,
            OccCompsetLy: null,
            OccIndex: null,
            OccIndexChange: null,
            OccIndexLy: null,
            AdrMyProperty: null,
            AdrMyPropertyChange: null,
            AdrMyPropertyLy: null,
            AdrCompset: null,
            AdrCompsetChange: null,
            AdrCompsetLy: null,
            AdrIndex: null,
            AdrIndexChange: null,
            AdrIndexLy: null,
            RevparMyProperty: null,
            RevparMyPropertyChange: null,
            RevparMyPropertyLy: null,
            RevparCompset: null,
            RevparCompsetChange: null,
            RevparCompsetLy: null,
            RevparIndex: null,
            RevparIndexChange: null,
            RevparIndexLy: null,
          },
        ];
      });

      setReportData(tableDataRef.current);
      reportDataRef.current = tableDataRef.current;
      setLoading(false);
    }
  };

  const clickOutSide = (e) => {
    if (e.target.nodeName.toLowerCase() === "input") {
      return;
    }
    setClickedTr({
      mainRow: "",
      subRow: "",
      day: "",
      field: "",
    });
  };

  useEffect(() => {
    document.addEventListener("click", clickOutSide);

    return () => {
      document.removeEventListener("click", clickOutSide);
    };
  }, []);

  const [tblids, settblids] = useState({
    weeklyStr: commonService.GenerateGUID(),
  });

  useEffect(() => {
    if (api === 0) {
    } else {
      if (para?.propertyCode) {
        getWeeklyReport();
        // if (stayStartDate && stayEndDate) {
        //   getMonthlyReport();
        // }
      }
    }
  }, [selectedDate, para?.propertyCode]);

  const handleSave = async () => {
    setSubmitLoading(true);
    let newReportData = reportData?.map((item, index) => {
      Object.keys(item)?.map((keyItem, keyIndex) => {
        if (item[keyItem] === "" || item[keyItem] === undefined) {
          item[keyItem] = null;
        }
      });
      return item;
    });
    let saveResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.SnapShot +
          API_URL.STR_REPORT_REVPAK.WEEKLY_STR_SAVE,
        body: {
          // report_data: reportData,
          report_data: newReportData,
          propertyCode: para?.propertyCode,
        },
      },
      false
    );

    let response = apiResponse(true, saveResponse, setSubmitLoading);

    if (response?.isValidate) {
    }

    if (!response?.isValidate) {
    }
  };

  const handleImport = async (file) => {
    setFileImportLoading(true);
    let fileResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.RevenueManagement +
          API_URL.STR_REPORT_REVPAK.READ_STR_ANALYSIS,
        body: {
          file: file,
        },
      },
      true
    );

    let response = apiResponse(false, fileResponse);

    if (response?.isValidate) {
      let obj = {
        0: {
          0: "OccMyProperty",
          1: "OccMyPropertyChange",
          2: "OccMyPropertyLy",
        },
        1: {
          0: "OccCompset",
          1: "OccCompsetChange",
          2: "OccCompsetLy",
        },
        2: {
          0: "OccIndex",
          1: "OccIndexChange",
          2: "OccIndexLy",
        },
        3: {
          0: "AdrMyProperty",
          1: "AdrMyPropertyChange",
          2: "AdrMyPropertyLy",
        },
        4: {
          0: "AdrCompset",
          1: "AdrCompsetChange",
          2: "AdrCompsetLy",
        },
        5: {
          0: "AdrIndex",
          1: "AdrIndexChange",
          2: "AdrIndexLy",
        },
        6: {
          0: "RevparMyProperty",
          1: "RevparMyPropertyChange",
          2: "RevparMyPropertyLy",
        },
        7: {
          0: "RevparCompset",
          1: "RevparCompsetChange",
          2: "RevparCompsetLy",
        },
        8: {
          0: "RevparIndex",
          1: "RevparIndexChange",
          2: "RevparIndexLy",
        },
      };
      let dataFromApi = response?.data?.data;

      let savedData = [...tableDataRef.current];

      dataFromApi?.forEach((item, index) => {
        let arrays = [],
          size = 3;

        while (item.length > 0) {
          arrays.push(item.splice(0, size));
        }
        arrays?.forEach((nestedItem, nestedIndex) => {
          nestedItem?.forEach((mainNestedItem, mainNestedIndex) => {
            let value = 0;
            if (mainNestedItem) {
              value = parseFloat(mainNestedItem)?.toFixed(2);
            }
            savedData[nestedIndex][obj[index][mainNestedIndex]] = value;
            // mainNestedItem?parseFloat(mainNestedItem)?.toFixed:0;
          });
        });
      });
      tableDataRef.current = savedData;
      setReportData(tableDataRef.current);
      reportDataRef.current = tableDataRef.current;

      let newReportData = reportDataRef.current?.map((item, index) => {
        Object.keys(item)?.map((keyItem, keyIndex) => {
          if (item[keyItem] === "" || item[keyItem] === undefined) {
            item[keyItem] = null;
          }
        });
        item.propertyCode = para?.propertyCode;
        item.AsOfDate = para?.asOfDate;
        return item;
      });

      //NEW LOGIC CODE

      // let dataFromApi = response?.data?.data;
      // let groupingJson = [];

      // dataFromApi?.forEach((item) => {
      //   if (item?.[0]) {
      //     let obj = {
      //       propertyCode: para?.propertyCode,
      //       AsOfDate: para?.asOfDate,
      //       StarReportDate: moment(item?.[0], "DD/MM/YYYY").format(
      //         "YYYY-MM-DD"
      //       ),
      //       OccMyProperty: item?.[1] ? item?.[1] : null,
      //       OccMyPropertyChange: item?.[2] ? item?.[2] : null,
      //       OccMyPropertyLy: item?.[3] ? item?.[3] : null,
      //       OccCompset: item?.[4] ? item?.[4] : null,
      //       OccCompsetChange: item?.[5] ? item?.[5] : null,
      //       OccCompsetLy: item?.[6] ? item?.[6] : null,
      //       OccIndex: item?.[7] ? item?.[7] : null,
      //       OccIndexChange: item?.[8] ? item?.[8] : null,
      //       OccIndexLy: item?.[9] ? item?.[9] : null,
      //       AdrMyProperty: item?.[10] ? item?.[10] : null,
      //       AdrMyPropertyChange: item?.[11] ? item?.[11] : null,
      //       AdrMyPropertyLy: item?.[12] ? item?.[12] : null,
      //       AdrCompset: item?.[13] ? item?.[13] : null,
      //       AdrCompsetChange: item?.[14] ? item?.[14] : null,
      //       AdrCompsetLy: item?.[15] ? item?.[15] : null,
      //       AdrIndex: item?.[16] ? item?.[16] : null,
      //       AdrIndexChange: item?.[17] ? item?.[17] : null,
      //       AdrIndexLy: item?.[18] ? item?.[18] : null,
      //       RevparMyProperty: item?.[19] ? item?.[19] : null,
      //       RevparMyPropertyChange: item?.[20] ? item?.[20] : null,
      //       RevparMyPropertyLy: item?.[21] ? item?.[21] : null,
      //       RevparCompset: item?.[22] ? item?.[22] : null,
      //       RevparCompsetChange: item?.[23] ? item?.[23] : null,
      //       RevparCompsetLy: item?.[24] ? item?.[24] : null,
      //       RevparIndex: item?.[25] ? item?.[25] : null,
      //       RevparIndexChange: item?.[26] ? item?.[26] : null,
      //       RevparIndexLy: item?.[27] ? item?.[27] : null,
      //     };
      //     groupingJson.push(obj);
      //   }
      // });

      let saveResponse = await apiCall(
        {
          method: "POST",
          url:
            API_URL.BASE_API_URL.SnapShot +
            API_URL.STR_REPORT_REVPAK.WEEKLY_STR_SAVE,
          body: {
            report_data: newReportData,
            propertyCode: para?.propertyCode,
          },
        },
        false
      );

      let savingresponse = apiResponse(true, saveResponse, setSubmitLoading);

      if (savingresponse?.isValidate) {
      }

      if (!savingresponse?.isValidate) {
      }
      setFileImportLoading(false);
    }

    if (!response?.isValidate) {
      setFileImportLoading(false);
    }
    inputFile.current.value = "";
    inputFile.current.type = "text";
    inputFile.current.type = "file";
  };

  return (
    <div className="widget-container">
      <div className="weekly-report-div mb-3">
        {/* <div className="width100">
          <DatePicker
            id="startDate"
            maxDate={new Date()}
            selected={commonService.convertDateInTimezone(selectedDate)}
            onChange={(date) => {
              setSelectedDate(date);
              setWeeklyDate(date);
            }}
          />
        </div> */}
        <div className="form-groups from-groups no-border-break meghaDatePicker width225">
          <DatePicker
            onChange={handleStayDateChange}
            startDate={stayStartDate}
            endDate={stayEndDate}
            selectsRange
            monthsShown={2}
          />
        </div>

        <div className="weekly-report-heading">
          <p>{`${commonService.getDateInFormat(
            // new Date(firstday)
            stayStartDate
          )} - ${commonService.getDateInFormat(
            // new Date(lastday)
            stayEndDate
          )}`}</p>
          {/* <p>
            WARNING: You must click the "Save" button if you make any changes
          </p> */}
        </div>
        <div className="d-flex">
          <div
            className={`special-btn-input-type-file ${
              fileImportLoading ? "disable-special-btn-input-type-file" : ""
            }`}
          >
            <input
              ref={inputFile}
              type="file"
              accept=".pdf,.doc,.xlsx,.xls"
              onChange={(e) => {
                if (e?.target?.files?.[0]) {
                  // setFile(e?.target?.files?.[0]);
                  handleImport(e?.target?.files?.[0]);
                }
              }}
              disabled={fileImportLoading}
            />
          </div>
          {/* <button
            className="default-btn primary-btn"
            onClick={() => {
              const link = document.createElement("a");

              link.href = exampleFile;
              link.download = "STR_analysis.xlsx";
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            }}
          >
            Download
          </button> */}
        </div>
        {/* {submitLoading ? (
          <button
            className="default-btn primary-btn width75"
            type="button"
            disabled
          >
            <span
              className="spinner-border spinner-border-sm "
              role="status"
              aria-hidden="true"
            ></span>
          </button>
        ) : (
          <button
            className="default-btn primary-btn width75"
            onClick={() => {
              handleSave();
            }}
          >
            Save
          </button>
        )} */}
      </div>
      {api !== 0 && (
        <div className="gap-1 widget-container-menu" style={{ right: "70px" }}>
          <Button className="heading-menu-btns" type="button" title="Send Mail">
            <img
              src={emailIcon}
              alt="emailIcon"
              onClick={() => {
                commonService.fnSendWidgetMail(
                  tblids.weeklyStr,
                  "Weekly Report",
                  snapshotId,
                  defaultAsOfDateFromRTK,
                  "",
                  para?.widgetId
                );
              }}
            />
          </Button>
          <Button className="heading-menu-btns" type="button">
            <img
              src={infoIcon}
              alt="info"
              onClick={() => {
                widgetDetailRef.current = {
                  widgetdetails: "",
                  usernote: "",
                  compName: compName?.weeklyStrReport,
                  widgetId: para?.widgetId,
                };
                setWidgetModal(true);
              }}
            />
          </Button>
          <Button className="heading-menu-btns" type="button">
            <img
              src={downloadIcon}
              alt="menuIcon"
              import={tblids.weeklyStr}
              onClick={() =>
                commonService.fnExportInCSV(
                  tblids.weeklyStr,
                  `${para?.propertyCode}-Weekly Report-${moment(
                    new Date()
                  ).format("MMDDYYYY")}`
                )
              }
            />
          </Button>
        </div>
      )}

      <div className="ViewPropertySetupTable">
        <div className="stripped__table table-responsive">
          <Table id={tblids.weeklyStr} responsive>
            <thead>
              <tr>
                <th className="border_right width50"></th>
                <th className="border_right width90"></th>
                {dayNames?.map((item, index) => {
                  return (
                    <th
                      className="border_right text-center width50"
                      key={`${item}firstth`}
                      colSpan={3}
                    >
                      {/* {moment(item).format("dddd")} */}
                      {item}
                    </th>
                  );
                })}
                <th className="width50">Avg</th>
              </tr>
              <tr>
                <th className="border_right"></th>
                <th className="border_right"></th>
                {dayNames?.map((item, index) => {
                  return (
                    <React.Fragment key={`${item}secondth`}>
                      <th className="width50"></th>
                      <th className="width50">%Chg</th>
                      <th className="border_right width50">LY</th>
                    </React.Fragment>
                  );
                })}
                <th className="width50"></th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr style={{ height: "80px", position: "relative" }}>
                  <Loader />
                </tr>
              ) : reportData ? (
                <>
                  {/* OCC */}
                  <tr className="shadowOnHover">
                    <td rowSpan={3} className="th-with-rowspan border_right">
                      Occupancy
                    </td>
                    <td className="border_right">My Property (%)</td>
                    <td
                    // onDoubleClick={() => {
                    //   setClickedTr({
                    //     mainRow: "occ",
                    //     subRow: "myProperty",
                    //     field: "curr",
                    //     day: "sunday",
                    //   });
                    // }}
                    >
                      {clickedTr.mainRow === "occ" &&
                      clickedTr.subRow === "myProperty" &&
                      clickedTr.field === "curr" &&
                      clickedTr.day === "sunday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current[0]?.OccMyProperty}
                          onChange={(e) => {
                            let lyValue = "";
                            reportDataRef.current = reportDataRef.current?.map(
                              (item, index) => {
                                if (index === 0) {
                                  item.OccMyProperty = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let changeValue = item?.OccMyPropertyChange
                                    ? parseFloat(item.OccMyPropertyChange)
                                    : 0;
                                  lyValue = (
                                    (newValue * 100) /
                                    (100 + changeValue)
                                  )?.toFixed(2);
                                  item.OccMyPropertyLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current[0].OccMyProperty =
                              e.target.value;
                            tableDataRef.current[0].OccMyPropertyLy = lyValue;
                            setReportData(reportDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current[0]?.OccMyProperty ? (
                        tableDataRef.current[0]?.OccMyProperty
                      ) : (
                        0
                      )}
                    </td>
                    <td
                    // onDoubleClick={() => {
                    //   setClickedTr({
                    //     mainRow: "occ",
                    //     subRow: "myProperty",
                    //     field: "change",
                    //     day: "sunday",
                    //   });
                    // }}
                    >
                      {clickedTr.mainRow === "occ" &&
                      clickedTr.subRow === "myProperty" &&
                      clickedTr.field === "change" &&
                      clickedTr.day === "sunday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current[0]?.OccMyPropertyChange}
                          onChange={(e) => {
                            let lyValue = "";
                            reportDataRef.current = reportDataRef.current?.map(
                              (item, index) => {
                                if (index === 0) {
                                  item.OccMyPropertyChange = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let currValue = item?.OccMyProperty
                                    ? parseFloat(item.OccMyProperty)
                                    : 0;
                                  lyValue = (
                                    (currValue * 100) /
                                    (100 + newValue)
                                  )?.toFixed(2);
                                  item.OccMyPropertyLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current[0].OccMyPropertyChange =
                              e.target.value;
                            tableDataRef.current[0].OccMyPropertyLy = lyValue;
                            setReportData(reportDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current[0]?.OccMyPropertyChange ? (
                        tableDataRef.current[0]?.OccMyPropertyChange
                      ) : (
                        0
                      )}
                    </td>
                    <td className="border_right">
                      {tableDataRef.current[0]?.OccMyPropertyLy
                        ? tableDataRef.current[0]?.OccMyPropertyLy
                        : 0}
                    </td>

                    <td
                    // onDoubleClick={() => {
                    //   setClickedTr({
                    //     mainRow: "occ",
                    //     subRow: "myProperty",
                    //     field: "curr",
                    //     day: "monday",
                    //   });
                    // }}
                    >
                      {clickedTr.mainRow === "occ" &&
                      clickedTr.subRow === "myProperty" &&
                      clickedTr.field === "curr" &&
                      clickedTr.day === "monday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current[1]?.OccMyProperty}
                          onChange={(e) => {
                            let lyValue = "";
                            reportDataRef.current = reportDataRef.current?.map(
                              (item, index) => {
                                if (index === 1) {
                                  item.OccMyProperty = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let changeValue = item?.OccMyPropertyChange
                                    ? parseFloat(item.OccMyPropertyChange)
                                    : 0;
                                  lyValue = (
                                    (newValue * 100) /
                                    (100 + changeValue)
                                  )?.toFixed(2);
                                  item.OccMyPropertyLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current[1].OccMyProperty =
                              e.target.value;
                            tableDataRef.current[1].OccMyPropertyLy = lyValue;
                            setReportData(reportDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current[1]?.OccMyProperty ? (
                        tableDataRef.current[1]?.OccMyProperty
                      ) : (
                        0
                      )}
                    </td>
                    <td
                    // onDoubleClick={() => {
                    //   setClickedTr({
                    //     mainRow: "occ",
                    //     subRow: "myProperty",
                    //     field: "change",
                    //     day: "monday",
                    //   });
                    // }}
                    >
                      {clickedTr.mainRow === "occ" &&
                      clickedTr.subRow === "myProperty" &&
                      clickedTr.field === "change" &&
                      clickedTr.day === "monday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current[1]?.OccMyPropertyChange}
                          onChange={(e) => {
                            let lyValue = "";
                            reportDataRef.current = reportDataRef.current?.map(
                              (item, index) => {
                                if (index === 1) {
                                  item.OccMyPropertyChange = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let currValue = item?.OccMyProperty
                                    ? parseFloat(item.OccMyProperty)
                                    : 0;
                                  lyValue = (
                                    (currValue * 100) /
                                    (100 + newValue)
                                  )?.toFixed(2);
                                  item.OccMyPropertyLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current[1].OccMyPropertyChange =
                              e.target.value;
                            tableDataRef.current[1].OccMyPropertyLy = lyValue;
                            setReportData(reportDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current[1]?.OccMyPropertyChange ? (
                        tableDataRef.current[1]?.OccMyPropertyChange
                      ) : (
                        0
                      )}
                    </td>
                    <td className="border_right">
                      {tableDataRef.current[1]?.OccMyPropertyLy
                        ? tableDataRef.current[1]?.OccMyPropertyLy
                        : 0}
                    </td>

                    <td
                    // onDoubleClick={() => {
                    //   setClickedTr({
                    //     mainRow: "occ",
                    //     subRow: "myProperty",
                    //     field: "curr",
                    //     day: "tuseday",
                    //   });
                    // }}
                    >
                      {clickedTr.mainRow === "occ" &&
                      clickedTr.subRow === "myProperty" &&
                      clickedTr.field === "curr" &&
                      clickedTr.day === "tuseday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current[2]?.OccMyProperty}
                          onChange={(e) => {
                            let lyValue = "";
                            reportDataRef.current = reportDataRef.current?.map(
                              (item, index) => {
                                if (index === 2) {
                                  item.OccMyProperty = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let changeValue = item?.OccMyPropertyChange
                                    ? parseFloat(item.OccMyPropertyChange)
                                    : 0;
                                  lyValue = (
                                    (newValue * 100) /
                                    (100 + changeValue)
                                  )?.toFixed(2);
                                  item.OccMyPropertyLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current[2].OccMyProperty =
                              e.target.value;
                            tableDataRef.current[2].OccMyPropertyLy = lyValue;
                            setReportData(reportDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current[2]?.OccMyProperty ? (
                        tableDataRef.current[2]?.OccMyProperty
                      ) : (
                        0
                      )}
                    </td>
                    <td
                    // onDoubleClick={() => {
                    //   setClickedTr({
                    //     mainRow: "occ",
                    //     subRow: "myProperty",
                    //     field: "change",
                    //     day: "tuesday",
                    //   });
                    // }}
                    >
                      {clickedTr.mainRow === "occ" &&
                      clickedTr.subRow === "myProperty" &&
                      clickedTr.field === "change" &&
                      clickedTr.day === "tuesday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current[2]?.OccMyPropertyChange}
                          onChange={(e) => {
                            let lyValue = "";
                            reportDataRef.current = reportDataRef.current?.map(
                              (item, index) => {
                                if (index === 2) {
                                  item.OccMyPropertyChange = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let currValue = item?.OccMyProperty
                                    ? parseFloat(item.OccMyProperty)
                                    : 0;
                                  lyValue = (
                                    (currValue * 100) /
                                    (100 + newValue)
                                  )?.toFixed(2);
                                  item.OccMyPropertyLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current[2].OccMyPropertyChange =
                              e.target.value;
                            tableDataRef.current[2].OccMyPropertyLy = lyValue;
                            setReportData(reportDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current[2]?.OccMyPropertyChange ? (
                        tableDataRef.current[2]?.OccMyPropertyChange
                      ) : (
                        0
                      )}
                    </td>
                    <td className="border_right">
                      {tableDataRef.current[2]?.OccMyPropertyLy
                        ? tableDataRef.current[2]?.OccMyPropertyLy
                        : 0}
                    </td>

                    <td
                    // onDoubleClick={() => {
                    //   setClickedTr({
                    //     mainRow: "occ",
                    //     subRow: "myProperty",
                    //     field: "curr",
                    //     day: "wednesday",
                    //   });
                    // }}
                    >
                      {clickedTr.mainRow === "occ" &&
                      clickedTr.subRow === "myProperty" &&
                      clickedTr.field === "curr" &&
                      clickedTr.day === "wednesday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current[3]?.OccMyProperty}
                          onChange={(e) => {
                            let lyValue = "";
                            reportDataRef.current = reportDataRef.current?.map(
                              (item, index) => {
                                if (index === 3) {
                                  item.OccMyProperty = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let changeValue = item?.OccMyPropertyChange
                                    ? parseFloat(item.OccMyPropertyChange)
                                    : 0;
                                  lyValue = (
                                    (newValue * 100) /
                                    (100 + changeValue)
                                  )?.toFixed(2);
                                  item.OccMyPropertyLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current[3].OccMyProperty =
                              e.target.value;
                            tableDataRef.current[3].OccMyPropertyLy = lyValue;
                            setReportData(reportDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current[3]?.OccMyProperty ? (
                        tableDataRef.current[3]?.OccMyProperty
                      ) : (
                        0
                      )}
                    </td>
                    <td
                    // onDoubleClick={() => {
                    //   setClickedTr({
                    //     mainRow: "occ",
                    //     subRow: "myProperty",
                    //     field: "change",
                    //     day: "wednesday",
                    //   });
                    // }}
                    >
                      {clickedTr.mainRow === "occ" &&
                      clickedTr.subRow === "myProperty" &&
                      clickedTr.field === "change" &&
                      clickedTr.day === "wednesday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current[3]?.OccMyPropertyChange}
                          onChange={(e) => {
                            let lyValue = "";
                            reportDataRef.current = reportDataRef.current?.map(
                              (item, index) => {
                                if (index === 3) {
                                  item.OccMyPropertyChange = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let currValue = item?.OccMyProperty
                                    ? parseFloat(item.OccMyProperty)
                                    : 0;
                                  lyValue = (
                                    (currValue * 100) /
                                    (100 + newValue)
                                  )?.toFixed(2);
                                  item.OccMyPropertyLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current[3].OccMyPropertyChange =
                              e.target.value;
                            tableDataRef.current[3].OccMyPropertyLy = lyValue;
                            setReportData(reportDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current[3]?.OccMyPropertyChange ? (
                        tableDataRef.current[3]?.OccMyPropertyChange
                      ) : (
                        0
                      )}
                    </td>
                    <td className="border_right">
                      {tableDataRef.current[3]?.OccMyPropertyLy
                        ? tableDataRef.current[3]?.OccMyPropertyLy
                        : 0}
                    </td>

                    <td
                    // onDoubleClick={() => {
                    //   setClickedTr({
                    //     mainRow: "occ",
                    //     subRow: "myProperty",
                    //     field: "curr",
                    //     day: "thursday",
                    //   });
                    // }}
                    >
                      {clickedTr.mainRow === "occ" &&
                      clickedTr.subRow === "myProperty" &&
                      clickedTr.field === "curr" &&
                      clickedTr.day === "thursday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current[4]?.OccMyProperty}
                          onChange={(e) => {
                            let lyValue = "";
                            reportDataRef.current = reportDataRef.current?.map(
                              (item, index) => {
                                if (index === 4) {
                                  item.OccMyProperty = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let changeValue = item?.OccMyPropertyChange
                                    ? parseFloat(item.OccMyPropertyChange)
                                    : 0;
                                  lyValue = (
                                    (newValue * 100) /
                                    (100 + changeValue)
                                  )?.toFixed(2);
                                  item.OccMyPropertyLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current[4].OccMyProperty =
                              e.target.value;
                            tableDataRef.current[4].OccMyPropertyLy = lyValue;
                            setReportData(reportDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current[4]?.OccMyProperty ? (
                        tableDataRef.current[4]?.OccMyProperty
                      ) : (
                        0
                      )}
                    </td>
                    <td
                    // onDoubleClick={() => {
                    //   setClickedTr({
                    //     mainRow: "occ",
                    //     subRow: "myProperty",
                    //     field: "change",
                    //     day: "thursday",
                    //   });
                    // }}
                    >
                      {clickedTr.mainRow === "occ" &&
                      clickedTr.subRow === "myProperty" &&
                      clickedTr.field === "change" &&
                      clickedTr.day === "thursday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current[4]?.OccMyPropertyChange}
                          onChange={(e) => {
                            let lyValue = "";
                            reportDataRef.current = reportDataRef.current?.map(
                              (item, index) => {
                                if (index === 4) {
                                  item.OccMyPropertyChange = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let currValue = item?.OccMyProperty
                                    ? parseFloat(item.OccMyProperty)
                                    : 0;
                                  lyValue = (
                                    (currValue * 100) /
                                    (100 + newValue)
                                  )?.toFixed(2);
                                  item.OccMyPropertyLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current[4].OccMyPropertyChange =
                              e.target.value;
                            tableDataRef.current[4].OccMyPropertyLy = lyValue;
                            setReportData(reportDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current[4]?.OccMyPropertyChange ? (
                        tableDataRef.current[4]?.OccMyPropertyChange
                      ) : (
                        0
                      )}
                    </td>
                    <td className="border_right">
                      {tableDataRef.current[4]?.OccMyPropertyLy
                        ? tableDataRef.current[4]?.OccMyPropertyLy
                        : 0}
                    </td>

                    <td
                    // onDoubleClick={() => {
                    //   setClickedTr({
                    //     mainRow: "occ",
                    //     subRow: "myProperty",
                    //     field: "curr",
                    //     day: "friday",
                    //   });
                    // }}
                    >
                      {clickedTr.mainRow === "occ" &&
                      clickedTr.subRow === "myProperty" &&
                      clickedTr.field === "curr" &&
                      clickedTr.day === "friday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current[5]?.OccMyProperty}
                          onChange={(e) => {
                            let lyValue = "";
                            reportDataRef.current = reportDataRef.current?.map(
                              (item, index) => {
                                if (index === 5) {
                                  item.OccMyProperty = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let changeValue = item?.OccMyPropertyChange
                                    ? parseFloat(item.OccMyPropertyChange)
                                    : 0;
                                  lyValue = (
                                    (newValue * 100) /
                                    (100 + changeValue)
                                  )?.toFixed(2);
                                  item.OccMyPropertyLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current[5].OccMyProperty =
                              e.target.value;
                            tableDataRef.current[5].OccMyPropertyLy = lyValue;
                            setReportData(reportDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current[5]?.OccMyProperty ? (
                        tableDataRef.current[5]?.OccMyProperty
                      ) : (
                        0
                      )}
                    </td>
                    <td
                    // onDoubleClick={() => {
                    //   setClickedTr({
                    //     mainRow: "occ",
                    //     subRow: "myProperty",
                    //     field: "change",
                    //     day: "friday",
                    //   });
                    // }}
                    >
                      {clickedTr.mainRow === "occ" &&
                      clickedTr.subRow === "myProperty" &&
                      clickedTr.field === "change" &&
                      clickedTr.day === "friday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current[5]?.OccMyPropertyChange}
                          onChange={(e) => {
                            let lyValue = "";
                            reportDataRef.current = reportDataRef.current?.map(
                              (item, index) => {
                                if (index === 5) {
                                  item.OccMyPropertyChange = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let currValue = item?.OccMyProperty
                                    ? parseFloat(item.OccMyProperty)
                                    : 0;
                                  lyValue = (
                                    (currValue * 100) /
                                    (100 + newValue)
                                  )?.toFixed(2);
                                  item.OccMyPropertyLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current[5].OccMyPropertyChange =
                              e.target.value;
                            tableDataRef.current[5].OccMyPropertyLy = lyValue;
                            setReportData(reportDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current[5]?.OccMyPropertyChange ? (
                        tableDataRef.current[5]?.OccMyPropertyChange
                      ) : (
                        0
                      )}
                    </td>
                    <td className="border_right">
                      {tableDataRef.current[5]?.OccMyPropertyLy
                        ? tableDataRef.current[5]?.OccMyPropertyLy
                        : 0}
                    </td>

                    <td
                    // onDoubleClick={() => {
                    //   setClickedTr({
                    //     mainRow: "occ",
                    //     subRow: "myProperty",
                    //     field: "curr",
                    //     day: "saturday",
                    //   });
                    // }}
                    >
                      {clickedTr.mainRow === "occ" &&
                      clickedTr.subRow === "myProperty" &&
                      clickedTr.field === "curr" &&
                      clickedTr.day === "saturday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current[6]?.OccMyProperty}
                          onChange={(e) => {
                            let lyValue = "";
                            reportDataRef.current = reportDataRef.current?.map(
                              (item, index) => {
                                if (index === 6) {
                                  item.OccMyProperty = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let changeValue = item?.OccMyPropertyChange
                                    ? parseFloat(item.OccMyPropertyChange)
                                    : 0;
                                  lyValue = (
                                    (newValue * 100) /
                                    (100 + changeValue)
                                  )?.toFixed(2);
                                  item.OccMyPropertyLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current[6].OccMyProperty =
                              e.target.value;
                            tableDataRef.current[6].OccMyPropertyLy = lyValue;
                            setReportData(reportDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current[6]?.OccMyProperty ? (
                        tableDataRef.current[6]?.OccMyProperty
                      ) : (
                        0
                      )}
                    </td>
                    <td
                    // onDoubleClick={() => {
                    //   setClickedTr({
                    //     mainRow: "occ",
                    //     subRow: "myProperty",
                    //     field: "change",
                    //     day: "saturday",
                    //   });
                    // }}
                    >
                      {clickedTr.mainRow === "occ" &&
                      clickedTr.subRow === "myProperty" &&
                      clickedTr.field === "change" &&
                      clickedTr.day === "saturday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current[6]?.OccMyPropertyChange}
                          onChange={(e) => {
                            let lyValue = "";
                            reportDataRef.current = reportDataRef.current?.map(
                              (item, index) => {
                                if (index === 6) {
                                  item.OccMyPropertyChange = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let currValue = item?.OccMyProperty
                                    ? parseFloat(item.OccMyProperty)
                                    : 0;
                                  lyValue = (
                                    (currValue * 100) /
                                    (100 + newValue)
                                  )?.toFixed(2);
                                  item.OccMyPropertyLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current[6].OccMyPropertyChange =
                              e.target.value;
                            tableDataRef.current[6].OccMyPropertyLy = lyValue;
                            setReportData(reportDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current[6]?.OccMyPropertyChange ? (
                        tableDataRef.current[6]?.OccMyPropertyChange
                      ) : (
                        0
                      )}
                    </td>
                    <td className="border_right">
                      {tableDataRef.current[6]?.OccMyPropertyLy
                        ? tableDataRef.current[6]?.OccMyPropertyLy
                        : 0}
                    </td>
                    <td className="fontBold">
                      {tableDataRef.current[7]?.OccMyProperty
                        ? tableDataRef.current[7]?.OccMyProperty
                        : 0}
                    </td>
                  </tr>
                  <tr className="shadowOnHover">
                    <td className="border_right">Comp Set (%)</td>
                    <td
                    // onDoubleClick={() => {
                    //   setClickedTr({
                    //     mainRow: "occ",
                    //     subRow: "compSet",
                    //     field: "curr",
                    //     day: "sunday",
                    //   });
                    // }}
                    >
                      {clickedTr.mainRow === "occ" &&
                      clickedTr.subRow === "compSet" &&
                      clickedTr.field === "curr" &&
                      clickedTr.day === "sunday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current[0]?.OccCompset}
                          onChange={(e) => {
                            let lyValue = "";
                            reportDataRef.current = reportDataRef.current?.map(
                              (item, index) => {
                                if (index === 0) {
                                  item.OccCompset = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let changeValue = item?.OccCompsetChange
                                    ? parseFloat(item.OccCompsetChange)
                                    : 0;
                                  lyValue = (
                                    (newValue * 100) /
                                    (100 + changeValue)
                                  )?.toFixed(2);
                                  item.OccCompsetLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current[0].OccCompset = e.target.value;
                            tableDataRef.current[0].OccCompsetLy = lyValue;
                            setReportData(reportDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current[0]?.OccCompset ? (
                        tableDataRef.current[0]?.OccCompset
                      ) : (
                        0
                      )}
                    </td>
                    <td
                    // onDoubleClick={() => {
                    //   setClickedTr({
                    //     mainRow: "occ",
                    //     subRow: "compSet",
                    //     field: "change",
                    //     day: "sunday",
                    //   });
                    // }}
                    >
                      {clickedTr.mainRow === "occ" &&
                      clickedTr.subRow === "compSet" &&
                      clickedTr.field === "change" &&
                      clickedTr.day === "sunday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current[0]?.OccCompsetChange}
                          onChange={(e) => {
                            let lyValue = "";
                            reportDataRef.current = reportDataRef.current?.map(
                              (item, index) => {
                                if (index === 0) {
                                  item.OccCompsetChange = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let currValue = item?.OccCompset
                                    ? parseFloat(item.OccCompset)
                                    : 0;
                                  lyValue = (
                                    (currValue * 100) /
                                    (100 + newValue)
                                  )?.toFixed(2);
                                  item.OccCompsetLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current[0].OccCompsetChange =
                              e.target.value;
                            tableDataRef.current[0].OccCompsetLy = lyValue;
                            setReportData(reportDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current[0]?.OccCompsetChange ? (
                        tableDataRef.current[0]?.OccCompsetChange
                      ) : (
                        0
                      )}
                    </td>
                    <td className="border_right">
                      {tableDataRef.current[0]?.OccCompsetLy
                        ? tableDataRef.current[0]?.OccCompsetLy
                        : 0}
                    </td>

                    <td
                    // onDoubleClick={() => {
                    //   setClickedTr({
                    //     mainRow: "occ",
                    //     subRow: "compSet",
                    //     field: "curr",
                    //     day: "monday",
                    //   });
                    // }}
                    >
                      {clickedTr.mainRow === "occ" &&
                      clickedTr.subRow === "compSet" &&
                      clickedTr.field === "curr" &&
                      clickedTr.day === "monday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current[1]?.OccCompset}
                          onChange={(e) => {
                            let lyValue = "";
                            reportDataRef.current = reportDataRef.current?.map(
                              (item, index) => {
                                if (index === 1) {
                                  item.OccCompset = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let changeValue = item?.OccCompsetChange
                                    ? parseFloat(item.OccCompsetChange)
                                    : 0;
                                  lyValue = (
                                    (newValue * 100) /
                                    (100 + changeValue)
                                  )?.toFixed(2);
                                  item.OccCompsetLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current[1].OccCompset = e.target.value;
                            tableDataRef.current[1].OccCompsetLy = lyValue;
                            setReportData(reportDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current[1]?.OccCompset ? (
                        tableDataRef.current[1]?.OccCompset
                      ) : (
                        0
                      )}
                    </td>
                    <td
                    // onDoubleClick={() => {
                    //   setClickedTr({
                    //     mainRow: "occ",
                    //     subRow: "compSet",
                    //     field: "change",
                    //     day: "monday",
                    //   });
                    // }}
                    >
                      {clickedTr.mainRow === "occ" &&
                      clickedTr.subRow === "compSet" &&
                      clickedTr.field === "change" &&
                      clickedTr.day === "monday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current[1]?.OccCompsetChange}
                          onChange={(e) => {
                            let lyValue = "";
                            reportDataRef.current = reportDataRef.current?.map(
                              (item, index) => {
                                if (index === 1) {
                                  item.OccCompsetChange = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let currValue = item?.OccCompset
                                    ? parseFloat(item.OccCompset)
                                    : 0;
                                  lyValue = (
                                    (currValue * 100) /
                                    (100 + newValue)
                                  )?.toFixed(2);
                                  item.OccCompsetLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current[1].OccCompsetChange =
                              e.target.value;
                            tableDataRef.current[1].OccCompsetLy = lyValue;
                            setReportData(reportDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current[1]?.OccCompsetChange ? (
                        tableDataRef.current[1]?.OccCompsetChange
                      ) : (
                        0
                      )}
                    </td>
                    <td className="border_right">
                      {tableDataRef.current[1]?.OccCompsetLy
                        ? tableDataRef.current[1]?.OccCompsetLy
                        : 0}
                    </td>

                    <td
                    // onDoubleClick={() => {
                    //   setClickedTr({
                    //     mainRow: "occ",
                    //     subRow: "compSet",
                    //     field: "curr",
                    //     day: "tuesday",
                    //   });
                    // }}
                    >
                      {clickedTr.mainRow === "occ" &&
                      clickedTr.subRow === "compSet" &&
                      clickedTr.field === "curr" &&
                      clickedTr.day === "tuesday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current[2]?.OccCompset}
                          onChange={(e) => {
                            let lyValue = "";
                            reportDataRef.current = reportDataRef.current?.map(
                              (item, index) => {
                                if (index === 2) {
                                  item.OccCompset = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let changeValue = item?.OccCompsetChange
                                    ? parseFloat(item.OccCompsetChange)
                                    : 0;
                                  lyValue = (
                                    (newValue * 100) /
                                    (100 + changeValue)
                                  )?.toFixed(2);
                                  item.OccCompsetLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current[2].OccCompset = e.target.value;
                            tableDataRef.current[2].OccCompsetLy = lyValue;
                            setReportData(reportDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current[2]?.OccCompset ? (
                        tableDataRef.current[2]?.OccCompset
                      ) : (
                        0
                      )}
                    </td>
                    <td
                    // onDoubleClick={() => {
                    //   setClickedTr({
                    //     mainRow: "occ",
                    //     subRow: "compSet",
                    //     field: "change",
                    //     day: "tuesday",
                    //   });
                    // }}
                    >
                      {clickedTr.mainRow === "occ" &&
                      clickedTr.subRow === "compSet" &&
                      clickedTr.field === "change" &&
                      clickedTr.day === "tuesday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current[2]?.OccCompsetChange}
                          onChange={(e) => {
                            let lyValue = "";
                            reportDataRef.current = reportDataRef.current?.map(
                              (item, index) => {
                                if (index === 2) {
                                  item.OccCompsetChange = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let currValue = item?.OccCompset
                                    ? parseFloat(item.OccCompset)
                                    : 0;
                                  lyValue = (
                                    (currValue * 100) /
                                    (100 + newValue)
                                  )?.toFixed(2);
                                  item.OccCompsetLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current[2].OccCompsetChange =
                              e.target.value;
                            tableDataRef.current[2].OccCompsetLy = lyValue;
                            setReportData(reportDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current[2]?.OccCompsetChange ? (
                        tableDataRef.current[2]?.OccCompsetChange
                      ) : (
                        0
                      )}
                    </td>
                    <td className="border_right">
                      {tableDataRef.current[2]?.OccCompsetLy
                        ? tableDataRef.current[2]?.OccCompsetLy
                        : 0}
                    </td>

                    <td
                    // onDoubleClick={() => {
                    //   setClickedTr({
                    //     mainRow: "occ",
                    //     subRow: "compSet",
                    //     field: "curr",
                    //     day: "wednesday",
                    //   });
                    // }}
                    >
                      {clickedTr.mainRow === "occ" &&
                      clickedTr.subRow === "compSet" &&
                      clickedTr.field === "curr" &&
                      clickedTr.day === "wednesday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current[3]?.OccCompset}
                          onChange={(e) => {
                            let lyValue = "";
                            reportDataRef.current = reportDataRef.current?.map(
                              (item, index) => {
                                if (index === 3) {
                                  item.OccCompset = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let changeValue = item?.OccCompsetChange
                                    ? parseFloat(item.OccCompsetChange)
                                    : 0;
                                  lyValue = (
                                    (newValue * 100) /
                                    (100 + changeValue)
                                  )?.toFixed(2);
                                  item.OccCompsetLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current[3].OccCompset = e.target.value;
                            tableDataRef.current[3].OccCompsetLy = lyValue;
                            setReportData(reportDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current[3]?.OccCompset ? (
                        tableDataRef.current[3]?.OccCompset
                      ) : (
                        0
                      )}
                    </td>
                    <td
                    // onDoubleClick={() => {
                    //   setClickedTr({
                    //     mainRow: "occ",
                    //     subRow: "compSet",
                    //     field: "change",
                    //     day: "wednesday",
                    //   });
                    // }}
                    >
                      {clickedTr.mainRow === "occ" &&
                      clickedTr.subRow === "compSet" &&
                      clickedTr.field === "change" &&
                      clickedTr.day === "wednesday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current[3]?.OccCompsetChange}
                          onChange={(e) => {
                            let lyValue = "";
                            reportDataRef.current = reportDataRef.current?.map(
                              (item, index) => {
                                if (index === 3) {
                                  item.OccCompsetChange = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let currValue = item?.OccCompset
                                    ? parseFloat(item.OccCompset)
                                    : 0;
                                  lyValue = (
                                    (currValue * 100) /
                                    (100 + newValue)
                                  )?.toFixed(2);
                                  item.OccCompsetLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current[3].OccCompsetChange =
                              e.target.value;
                            tableDataRef.current[3].OccCompsetLy = lyValue;
                            setReportData(reportDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current[3]?.OccCompsetChange ? (
                        tableDataRef.current[3]?.OccCompsetChange
                      ) : (
                        0
                      )}
                    </td>
                    <td className="border_right">
                      {tableDataRef.current[3]?.OccCompsetLy
                        ? tableDataRef.current[3]?.OccCompsetLy
                        : 0}
                    </td>

                    <td
                    // onDoubleClick={() => {
                    //   setClickedTr({
                    //     mainRow: "occ",
                    //     subRow: "compSet",
                    //     field: "curr",
                    //     day: "thursday",
                    //   });
                    // }}
                    >
                      {clickedTr.mainRow === "occ" &&
                      clickedTr.subRow === "compSet" &&
                      clickedTr.field === "curr" &&
                      clickedTr.day === "thursday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current[4]?.OccCompset}
                          onChange={(e) => {
                            let lyValue = "";
                            reportDataRef.current = reportDataRef.current?.map(
                              (item, index) => {
                                if (index === 4) {
                                  item.OccCompset = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let changeValue = item?.OccCompsetChange
                                    ? parseFloat(item.OccCompsetChange)
                                    : 0;
                                  lyValue = (
                                    (newValue * 100) /
                                    (100 + changeValue)
                                  )?.toFixed(2);
                                  item.OccCompsetLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current[4].OccCompset = e.target.value;
                            tableDataRef.current[4].OccCompsetLy = lyValue;
                            setReportData(reportDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current[4]?.OccCompset ? (
                        tableDataRef.current[4]?.OccCompset
                      ) : (
                        0
                      )}
                    </td>
                    <td
                    // onDoubleClick={() => {
                    //   setClickedTr({
                    //     mainRow: "occ",
                    //     subRow: "compSet",
                    //     field: "change",
                    //     day: "thursday",
                    //   });
                    // }}
                    >
                      {clickedTr.mainRow === "occ" &&
                      clickedTr.subRow === "compSet" &&
                      clickedTr.field === "change" &&
                      clickedTr.day === "thursday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current[4]?.OccCompsetChange}
                          onChange={(e) => {
                            let lyValue = "";
                            reportDataRef.current = reportDataRef.current?.map(
                              (item, index) => {
                                if (index === 4) {
                                  item.OccCompsetChange = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let currValue = item?.OccCompset
                                    ? parseFloat(item.OccCompset)
                                    : 0;
                                  lyValue = (
                                    (currValue * 100) /
                                    (100 + newValue)
                                  )?.toFixed(2);
                                  item.OccCompsetLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current[4].OccCompsetChange =
                              e.target.value;
                            tableDataRef.current[4].OccCompsetLy = lyValue;
                            setReportData(reportDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current[4]?.OccCompsetChange ? (
                        tableDataRef.current[4]?.OccCompsetChange
                      ) : (
                        0
                      )}
                    </td>
                    <td className="border_right">
                      {tableDataRef.current[4]?.OccCompsetLy
                        ? tableDataRef.current[4]?.OccCompsetLy
                        : 0}
                    </td>

                    <td
                    // onDoubleClick={() => {
                    //   setClickedTr({
                    //     mainRow: "occ",
                    //     subRow: "compSet",
                    //     field: "curr",
                    //     day: "friday",
                    //   });
                    // }}
                    >
                      {clickedTr.mainRow === "occ" &&
                      clickedTr.subRow === "compSet" &&
                      clickedTr.field === "curr" &&
                      clickedTr.day === "friday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current[5]?.OccCompset}
                          onChange={(e) => {
                            let lyValue = "";
                            reportDataRef.current = reportDataRef.current?.map(
                              (item, index) => {
                                if (index === 5) {
                                  item.OccCompset = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let changeValue = item?.OccCompsetChange
                                    ? parseFloat(item.OccCompsetChange)
                                    : 0;
                                  lyValue = (
                                    (newValue * 100) /
                                    (100 + changeValue)
                                  )?.toFixed(2);
                                  item.OccCompsetLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current[5].OccCompset = e.target.value;
                            tableDataRef.current[5].OccCompsetLy = lyValue;
                            setReportData(reportDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current[5]?.OccCompset ? (
                        tableDataRef.current[5]?.OccCompset
                      ) : (
                        0
                      )}
                    </td>
                    <td
                    // onDoubleClick={() => {
                    //   setClickedTr({
                    //     mainRow: "occ",
                    //     subRow: "compSet",
                    //     field: "change",
                    //     day: "friday",
                    //   });
                    // }}
                    >
                      {clickedTr.mainRow === "occ" &&
                      clickedTr.subRow === "compSet" &&
                      clickedTr.field === "change" &&
                      clickedTr.day === "friday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current[5]?.OccCompsetChange}
                          onChange={(e) => {
                            let lyValue = "";
                            reportDataRef.current = reportDataRef.current?.map(
                              (item, index) => {
                                if (index === 5) {
                                  item.OccCompsetChange = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let currValue = item?.OccCompset
                                    ? parseFloat(item.OccCompset)
                                    : 0;
                                  lyValue = (
                                    (currValue * 100) /
                                    (100 + newValue)
                                  )?.toFixed(2);
                                  item.OccCompsetLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current[5].OccCompsetChange =
                              e.target.value;
                            tableDataRef.current[5].OccCompsetLy = lyValue;
                            setReportData(reportDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current[5]?.OccCompsetChange ? (
                        tableDataRef.current[5]?.OccCompsetChange
                      ) : (
                        0
                      )}
                    </td>
                    <td className="border_right">
                      {tableDataRef.current[5]?.OccCompsetLy
                        ? tableDataRef.current[5]?.OccCompsetLy
                        : 0}
                    </td>

                    <td
                    // onDoubleClick={() => {
                    //   setClickedTr({
                    //     mainRow: "occ",
                    //     subRow: "compSet",
                    //     field: "curr",
                    //     day: "saturday",
                    //   });
                    // }}
                    >
                      {clickedTr.mainRow === "occ" &&
                      clickedTr.subRow === "compSet" &&
                      clickedTr.field === "curr" &&
                      clickedTr.day === "saturday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current[6]?.OccCompset}
                          onChange={(e) => {
                            let lyValue = "";
                            reportDataRef.current = reportDataRef.current?.map(
                              (item, index) => {
                                if (index === 6) {
                                  item.OccCompset = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let changeValue = item?.OccCompsetChange
                                    ? parseFloat(item.OccCompsetChange)
                                    : 0;
                                  lyValue = (
                                    (newValue * 100) /
                                    (100 + changeValue)
                                  )?.toFixed(2);
                                  item.OccCompsetLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current[6].OccCompset = e.target.value;
                            tableDataRef.current[6].OccCompsetLy = lyValue;
                            setReportData(reportDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current[6]?.OccCompset ? (
                        tableDataRef.current[6]?.OccCompset
                      ) : (
                        0
                      )}
                    </td>
                    <td
                    // onDoubleClick={() => {
                    //   setClickedTr({
                    //     mainRow: "occ",
                    //     subRow: "compSet",
                    //     field: "change",
                    //     day: "saturday",
                    //   });
                    // }}
                    >
                      {clickedTr.mainRow === "occ" &&
                      clickedTr.subRow === "compSet" &&
                      clickedTr.field === "change" &&
                      clickedTr.day === "saturday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current[6]?.OccCompsetChange}
                          onChange={(e) => {
                            let lyValue = "";
                            reportDataRef.current = reportDataRef.current?.map(
                              (item, index) => {
                                if (index === 6) {
                                  item.OccCompsetChange = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let currValue = item?.OccCompset
                                    ? parseFloat(item.OccCompset)
                                    : 0;
                                  lyValue = (
                                    (currValue * 100) /
                                    (100 + newValue)
                                  )?.toFixed(2);
                                  item.OccCompsetLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current[6].OccCompsetChange =
                              e.target.value;
                            tableDataRef.current[6].OccCompsetLy = lyValue;
                            setReportData(reportDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current[6]?.OccCompsetChange ? (
                        tableDataRef.current[6]?.OccCompsetChange
                      ) : (
                        0
                      )}
                    </td>
                    <td className="border_right">
                      {tableDataRef.current[6]?.OccCompsetLy
                        ? tableDataRef.current[6]?.OccCompsetLy
                        : 0}
                    </td>
                    <td className="fontBold">
                      {tableDataRef.current[7]?.OccCompset
                        ? tableDataRef.current[7]?.OccCompset
                        : 0}
                    </td>
                  </tr>
                  <tr className="shadowOnHover">
                    <td className="border_right">Index (MPI)</td>
                    <td
                    // onDoubleClick={() => {
                    //   setClickedTr({
                    //     mainRow: "occ",
                    //     subRow: "index",
                    //     field: "curr",
                    //     day: "sunday",
                    //   });
                    // }}
                    >
                      {clickedTr.mainRow === "occ" &&
                      clickedTr.subRow === "index" &&
                      clickedTr.field === "curr" &&
                      clickedTr.day === "sunday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current[0]?.OccIndex}
                          onChange={(e) => {
                            let lyValue = "";
                            reportDataRef.current = reportDataRef.current?.map(
                              (item, index) => {
                                if (index === 0) {
                                  item.OccIndex = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let changeValue = item?.OccIndexChange
                                    ? parseFloat(item.OccIndexChange)
                                    : 0;
                                  lyValue = (
                                    (newValue * 100) /
                                    (100 + changeValue)
                                  )?.toFixed(2);
                                  item.OccIndexLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current[0].OccIndex = e.target.value;
                            tableDataRef.current[0].OccIndexLy = lyValue;
                            setReportData(reportDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current[0]?.OccIndex ? (
                        tableDataRef.current[0]?.OccIndex
                      ) : (
                        0
                      )}
                    </td>
                    <td
                    // onDoubleClick={() => {
                    //   setClickedTr({
                    //     mainRow: "occ",
                    //     subRow: "index",
                    //     field: "change",
                    //     day: "sunday",
                    //   });
                    // }}
                    >
                      {clickedTr.mainRow === "occ" &&
                      clickedTr.subRow === "index" &&
                      clickedTr.field === "change" &&
                      clickedTr.day === "sunday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current[0]?.OccIndexChange}
                          onChange={(e) => {
                            let lyValue = "";
                            reportDataRef.current = reportDataRef.current?.map(
                              (item, index) => {
                                if (index === 0) {
                                  item.OccIndexChange = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let currValue = item?.OccIndex
                                    ? parseFloat(item.OccIndex)
                                    : 0;
                                  lyValue = (
                                    (currValue * 100) /
                                    (100 + newValue)
                                  )?.toFixed(2);
                                  item.OccIndexLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current[0].OccIndexChange =
                              e.target.value;
                            tableDataRef.current[0].OccIndexLy = lyValue;
                            setReportData(reportDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current[0]?.OccIndexChange ? (
                        tableDataRef.current[0]?.OccIndexChange
                      ) : (
                        0
                      )}
                    </td>
                    <td className="border_right">
                      {tableDataRef.current[0]?.OccIndexLy
                        ? tableDataRef.current[0]?.OccIndexLy
                        : 0}
                    </td>

                    <td
                    // onDoubleClick={() => {
                    //   setClickedTr({
                    //     mainRow: "occ",
                    //     subRow: "index",
                    //     field: "curr",
                    //     day: "monday",
                    //   });
                    // }}
                    >
                      {clickedTr.mainRow === "occ" &&
                      clickedTr.subRow === "index" &&
                      clickedTr.field === "curr" &&
                      clickedTr.day === "monday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current[1]?.OccIndex}
                          onChange={(e) => {
                            let lyValue = "";
                            reportDataRef.current = reportDataRef.current?.map(
                              (item, index) => {
                                if (index === 1) {
                                  item.OccIndex = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let changeValue = item?.OccIndexChange
                                    ? parseFloat(item.OccIndexChange)
                                    : 0;
                                  lyValue = (
                                    (newValue * 100) /
                                    (100 + changeValue)
                                  )?.toFixed(2);
                                  item.OccIndexLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current[1].OccIndex = e.target.value;
                            tableDataRef.current[1].OccIndexLy = lyValue;
                            setReportData(reportDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current[1]?.OccIndex ? (
                        tableDataRef.current[1]?.OccIndex
                      ) : (
                        0
                      )}
                    </td>
                    <td
                    // onDoubleClick={() => {
                    //   setClickedTr({
                    //     mainRow: "occ",
                    //     subRow: "index",
                    //     field: "change",
                    //     day: "monday",
                    //   });
                    // }}
                    >
                      {clickedTr.mainRow === "occ" &&
                      clickedTr.subRow === "index" &&
                      clickedTr.field === "change" &&
                      clickedTr.day === "monday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current[1]?.OccIndexChange}
                          onChange={(e) => {
                            let lyValue = "";
                            reportDataRef.current = reportDataRef.current?.map(
                              (item, index) => {
                                if (index === 1) {
                                  item.OccIndexChange = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let currValue = item?.OccIndex
                                    ? parseFloat(item.OccIndex)
                                    : 0;
                                  lyValue = (
                                    (currValue * 100) /
                                    (100 + newValue)
                                  )?.toFixed(2);
                                  item.OccIndexLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current[1].OccIndexChange =
                              e.target.value;
                            tableDataRef.current[1].OccIndexLy = lyValue;
                            setReportData(reportDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current[1]?.OccIndexChange ? (
                        tableDataRef.current[1]?.OccIndexChange
                      ) : (
                        0
                      )}
                    </td>
                    <td className="border_right">
                      {tableDataRef.current[1]?.OccIndexLy
                        ? tableDataRef.current[1]?.OccIndexLy
                        : 0}
                    </td>

                    <td
                    // onDoubleClick={() => {
                    //   setClickedTr({
                    //     mainRow: "occ",
                    //     subRow: "index",
                    //     field: "curr",
                    //     day: "tuesday",
                    //   });
                    // }}
                    >
                      {clickedTr.mainRow === "occ" &&
                      clickedTr.subRow === "index" &&
                      clickedTr.field === "curr" &&
                      clickedTr.day === "tuesday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current[2]?.OccIndex}
                          onChange={(e) => {
                            let lyValue = "";
                            reportDataRef.current = reportDataRef.current?.map(
                              (item, index) => {
                                if (index === 2) {
                                  item.OccIndex = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let changeValue = item?.OccIndexChange
                                    ? parseFloat(item.OccIndexChange)
                                    : 0;
                                  lyValue = (
                                    (newValue * 100) /
                                    (100 + changeValue)
                                  )?.toFixed(2);
                                  item.OccIndexLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current[2].OccIndex = e.target.value;
                            tableDataRef.current[2].OccIndexLy = lyValue;
                            setReportData(reportDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current[2]?.OccIndex ? (
                        tableDataRef.current[2]?.OccIndex
                      ) : (
                        0
                      )}
                    </td>
                    <td
                    // onDoubleClick={() => {
                    //   setClickedTr({
                    //     mainRow: "occ",
                    //     subRow: "index",
                    //     field: "change",
                    //     day: "tuesday",
                    //   });
                    // }}
                    >
                      {clickedTr.mainRow === "occ" &&
                      clickedTr.subRow === "index" &&
                      clickedTr.field === "change" &&
                      clickedTr.day === "tuesday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current[2]?.OccIndexChange}
                          onChange={(e) => {
                            let lyValue = "";
                            reportDataRef.current = reportDataRef.current?.map(
                              (item, index) => {
                                if (index === 2) {
                                  item.OccIndexChange = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let currValue = item?.OccIndex
                                    ? parseFloat(item.OccIndex)
                                    : 0;
                                  lyValue = (
                                    (currValue * 100) /
                                    (100 + newValue)
                                  )?.toFixed(2);
                                  item.OccIndexLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current[2].OccIndexChange =
                              e.target.value;
                            tableDataRef.current[2].OccIndexLy = lyValue;
                            setReportData(reportDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current[2]?.OccIndexChange ? (
                        tableDataRef.current[2]?.OccIndexChange
                      ) : (
                        0
                      )}
                    </td>
                    <td className="border_right">
                      {tableDataRef.current[2]?.OccIndexLy
                        ? tableDataRef.current[2]?.OccIndexLy
                        : 0}
                    </td>

                    <td
                    // onDoubleClick={() => {
                    //   setClickedTr({
                    //     mainRow: "occ",
                    //     subRow: "index",
                    //     field: "curr",
                    //     day: "wednesday",
                    //   });
                    // }}
                    >
                      {clickedTr.mainRow === "occ" &&
                      clickedTr.subRow === "index" &&
                      clickedTr.field === "curr" &&
                      clickedTr.day === "wednesday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current[3]?.OccIndex}
                          onChange={(e) => {
                            let lyValue = "";
                            reportDataRef.current = reportDataRef.current?.map(
                              (item, index) => {
                                if (index === 3) {
                                  item.OccIndex = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let changeValue = item?.OccIndexChange
                                    ? parseFloat(item.OccIndexChange)
                                    : 0;
                                  lyValue = (
                                    (newValue * 100) /
                                    (100 + changeValue)
                                  )?.toFixed(2);
                                  item.OccIndexLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current[3].OccIndex = e.target.value;
                            tableDataRef.current[3].OccIndexLy = lyValue;
                            setReportData(reportDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current[3]?.OccIndex ? (
                        tableDataRef.current[3]?.OccIndex
                      ) : (
                        0
                      )}
                    </td>
                    <td
                    // onDoubleClick={() => {
                    //   setClickedTr({
                    //     mainRow: "occ",
                    //     subRow: "index",
                    //     field: "change",
                    //     day: "wednesday",
                    //   });
                    // }}
                    >
                      {clickedTr.mainRow === "occ" &&
                      clickedTr.subRow === "index" &&
                      clickedTr.field === "change" &&
                      clickedTr.day === "wednesday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current[3]?.OccIndexChange}
                          onChange={(e) => {
                            let lyValue = "";
                            reportDataRef.current = reportDataRef.current?.map(
                              (item, index) => {
                                if (index === 3) {
                                  item.OccIndexChange = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let currValue = item?.OccIndex
                                    ? parseFloat(item.OccIndex)
                                    : 0;
                                  lyValue = (
                                    (currValue * 100) /
                                    (100 + newValue)
                                  )?.toFixed(2);
                                  item.OccIndexLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current[3].OccIndexChange =
                              e.target.value;
                            tableDataRef.current[3].OccIndexLy = lyValue;
                            setReportData(reportDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current[3]?.OccIndexChange ? (
                        tableDataRef.current[3]?.OccIndexChange
                      ) : (
                        0
                      )}
                    </td>
                    <td className="border_right">
                      {tableDataRef.current[3]?.OccIndexLy
                        ? tableDataRef.current[3]?.OccIndexLy
                        : 0}
                    </td>

                    <td
                    // onDoubleClick={() => {
                    //   setClickedTr({
                    //     mainRow: "occ",
                    //     subRow: "index",
                    //     field: "curr",
                    //     day: "thursday",
                    //   });
                    // }}
                    >
                      {clickedTr.mainRow === "occ" &&
                      clickedTr.subRow === "index" &&
                      clickedTr.field === "curr" &&
                      clickedTr.day === "thursday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current[4]?.OccIndex}
                          onChange={(e) => {
                            let lyValue = "";
                            reportDataRef.current = reportDataRef.current?.map(
                              (item, index) => {
                                if (index === 4) {
                                  item.OccIndex = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let changeValue = item?.OccIndexChange
                                    ? parseFloat(item.OccIndexChange)
                                    : 0;
                                  lyValue = (
                                    (newValue * 100) /
                                    (100 + changeValue)
                                  )?.toFixed(2);
                                  item.OccIndexLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current[4].OccIndex = e.target.value;
                            tableDataRef.current[4].OccIndexLy = lyValue;
                            setReportData(reportDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current[4]?.OccIndex ? (
                        tableDataRef.current[4]?.OccIndex
                      ) : (
                        0
                      )}
                    </td>
                    <td
                    // onDoubleClick={() => {
                    //   setClickedTr({
                    //     mainRow: "occ",
                    //     subRow: "index",
                    //     field: "change",
                    //     day: "thursday",
                    //   });
                    // }}
                    >
                      {clickedTr.mainRow === "occ" &&
                      clickedTr.subRow === "index" &&
                      clickedTr.field === "change" &&
                      clickedTr.day === "thursday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current[4]?.OccIndexChange}
                          onChange={(e) => {
                            let lyValue = "";
                            reportDataRef.current = reportDataRef.current?.map(
                              (item, index) => {
                                if (index === 4) {
                                  item.OccIndexChange = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let currValue = item?.OccIndex
                                    ? parseFloat(item.OccIndex)
                                    : 0;
                                  lyValue = (
                                    (currValue * 100) /
                                    (100 + newValue)
                                  )?.toFixed(2);
                                  item.OccIndexLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current[4].OccIndexChange =
                              e.target.value;
                            tableDataRef.current[4].OccIndexLy = lyValue;
                            setReportData(reportDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current[4]?.OccIndexChange ? (
                        tableDataRef.current[4]?.OccIndexChange
                      ) : (
                        0
                      )}
                    </td>
                    <td className="border_right">
                      {tableDataRef.current[4]?.OccIndexLy
                        ? tableDataRef.current[4]?.OccIndexLy
                        : 0}
                    </td>

                    <td
                    // onDoubleClick={() => {
                    //   setClickedTr({
                    //     mainRow: "occ",
                    //     subRow: "index",
                    //     field: "curr",
                    //     day: "friday",
                    //   });
                    // }}
                    >
                      {clickedTr.mainRow === "occ" &&
                      clickedTr.subRow === "index" &&
                      clickedTr.field === "curr" &&
                      clickedTr.day === "friday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current[5]?.OccIndex}
                          onChange={(e) => {
                            let lyValue = "";
                            reportDataRef.current = reportDataRef.current?.map(
                              (item, index) => {
                                if (index === 5) {
                                  item.OccIndex = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let changeValue = item?.OccIndexChange
                                    ? parseFloat(item.OccIndexChange)
                                    : 0;
                                  lyValue = (
                                    (newValue * 100) /
                                    (100 + changeValue)
                                  )?.toFixed(2);
                                  item.OccIndexLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current[5].OccIndex = e.target.value;
                            tableDataRef.current[5].OccIndexLy = lyValue;
                            setReportData(reportDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current[5]?.OccIndex ? (
                        tableDataRef.current[5]?.OccIndex
                      ) : (
                        0
                      )}
                    </td>
                    <td
                    // onDoubleClick={() => {
                    //   setClickedTr({
                    //     mainRow: "occ",
                    //     subRow: "index",
                    //     field: "change",
                    //     day: "friday",
                    //   });
                    // }}
                    >
                      {clickedTr.mainRow === "occ" &&
                      clickedTr.subRow === "index" &&
                      clickedTr.field === "change" &&
                      clickedTr.day === "friday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current[5]?.OccIndexChange}
                          onChange={(e) => {
                            let lyValue = "";
                            reportDataRef.current = reportDataRef.current?.map(
                              (item, index) => {
                                if (index === 5) {
                                  item.OccIndexChange = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let currValue = item?.OccIndex
                                    ? parseFloat(item.OccIndex)
                                    : 0;
                                  lyValue = (
                                    (currValue * 100) /
                                    (100 + newValue)
                                  )?.toFixed(2);
                                  item.OccIndexLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current[5].OccIndexChange =
                              e.target.value;
                            tableDataRef.current[5].OccIndexLy = lyValue;
                            setReportData(reportDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current[5]?.OccIndexChange ? (
                        tableDataRef.current[5]?.OccIndexChange
                      ) : (
                        0
                      )}
                    </td>
                    <td className="border_right">
                      {tableDataRef.current[5]?.OccIndexLy
                        ? tableDataRef.current[5]?.OccIndexLy
                        : 0}
                    </td>

                    <td
                    // onDoubleClick={() => {
                    //   setClickedTr({
                    //     mainRow: "occ",
                    //     subRow: "index",
                    //     field: "curr",
                    //     day: "saturday",
                    //   });
                    // }}
                    >
                      {clickedTr.mainRow === "occ" &&
                      clickedTr.subRow === "index" &&
                      clickedTr.field === "curr" &&
                      clickedTr.day === "saturday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current[6]?.OccIndex}
                          onChange={(e) => {
                            let lyValue = "";
                            reportDataRef.current = reportDataRef.current?.map(
                              (item, index) => {
                                if (index === 6) {
                                  item.OccIndex = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let changeValue = item?.OccIndexChange
                                    ? parseFloat(item.OccIndexChange)
                                    : 0;
                                  lyValue = (
                                    (newValue * 100) /
                                    (100 + changeValue)
                                  )?.toFixed(2);
                                  item.OccIndexLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current[6].OccIndex = e.target.value;
                            tableDataRef.current[6].OccIndexLy = lyValue;
                            setReportData(reportDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current[6]?.OccIndex ? (
                        tableDataRef.current[6]?.OccIndex
                      ) : (
                        0
                      )}
                    </td>
                    <td
                    // onDoubleClick={() => {
                    //   setClickedTr({
                    //     mainRow: "occ",
                    //     subRow: "index",
                    //     field: "change",
                    //     day: "saturday",
                    //   });
                    // }}
                    >
                      {clickedTr.mainRow === "occ" &&
                      clickedTr.subRow === "index" &&
                      clickedTr.field === "change" &&
                      clickedTr.day === "saturday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current[6]?.OccIndexChange}
                          onChange={(e) => {
                            let lyValue = "";
                            reportDataRef.current = reportDataRef.current?.map(
                              (item, index) => {
                                if (index === 6) {
                                  item.OccIndexChange = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let currValue = item?.OccIndex
                                    ? parseFloat(item.OccIndex)
                                    : 0;
                                  lyValue = (
                                    (currValue * 100) /
                                    (100 + newValue)
                                  )?.toFixed(2);
                                  item.OccIndexLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current[6].OccIndexChange =
                              e.target.value;
                            tableDataRef.current[6].OccIndexLy = lyValue;
                            setReportData(reportDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current[6]?.OccIndexChange ? (
                        tableDataRef.current[6]?.OccIndexChange
                      ) : (
                        0
                      )}
                    </td>
                    <td className="border_right">
                      {tableDataRef.current[6]?.OccIndexLy
                        ? tableDataRef.current[6]?.OccIndexLy
                        : 0}
                    </td>
                    <td className="fontBold">
                      {tableDataRef.current[7]?.OccIndex
                        ? tableDataRef.current[7]?.OccIndex
                        : 0}
                    </td>
                  </tr>

                  {/* ADR */}
                  <tr className="shadowOnHover">
                    <td rowSpan={3} className="th-with-rowspan border_right">
                      ADR
                    </td>
                    <td className="border_right">My Property</td>
                    <td
                    // onDoubleClick={() => {
                    //   setClickedTr({
                    //     mainRow: "adr",
                    //     subRow: "myProperty",
                    //     field: "curr",
                    //     day: "sunday",
                    //   });
                    // }}
                    >
                      {clickedTr.mainRow === "adr" &&
                      clickedTr.subRow === "myProperty" &&
                      clickedTr.field === "curr" &&
                      clickedTr.day === "sunday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current[0]?.AdrMyProperty}
                          onChange={(e) => {
                            let lyValue = "";
                            reportDataRef.current = reportDataRef.current?.map(
                              (item, index) => {
                                if (index === 0) {
                                  item.AdrMyProperty = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let changeValue = item?.AdrMyPropertyChange
                                    ? parseFloat(item.AdrMyPropertyChange)
                                    : 0;
                                  lyValue = (
                                    (newValue * 100) /
                                    (100 + changeValue)
                                  )?.toFixed(2);
                                  item.AdrMyPropertyLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current[0].AdrMyProperty =
                              e.target.value;
                            tableDataRef.current[0].AdrMyPropertyLy = lyValue;
                            setReportData(reportDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current[0]?.AdrMyProperty ? (
                        tableDataRef.current[0]?.AdrMyProperty
                      ) : (
                        0
                      )}
                    </td>
                    <td
                    // onDoubleClick={() => {
                    //   setClickedTr({
                    //     mainRow: "adr",
                    //     subRow: "myProperty",
                    //     field: "change",
                    //     day: "sunday",
                    //   });
                    // }}
                    >
                      {clickedTr.mainRow === "adr" &&
                      clickedTr.subRow === "myProperty" &&
                      clickedTr.field === "change" &&
                      clickedTr.day === "sunday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current[0]?.AdrMyPropertyChange}
                          onChange={(e) => {
                            let lyValue = "";
                            reportDataRef.current = reportDataRef.current?.map(
                              (item, index) => {
                                if (index === 0) {
                                  item.AdrMyPropertyChange = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let currValue = item?.AdrMyProperty
                                    ? parseFloat(item.AdrMyProperty)
                                    : 0;
                                  lyValue = (
                                    (currValue * 100) /
                                    (100 + newValue)
                                  )?.toFixed(2);
                                  item.AdrMyPropertyLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current[0].AdrMyPropertyChange =
                              e.target.value;
                            tableDataRef.current[0].AdrMyPropertyLy = lyValue;
                            setReportData(reportDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current[0]?.AdrMyPropertyChange ? (
                        tableDataRef.current[0]?.AdrMyPropertyChange
                      ) : (
                        0
                      )}
                    </td>
                    <td className="border_right">
                      {tableDataRef.current[0]?.AdrMyPropertyLy
                        ? tableDataRef.current[0]?.AdrMyPropertyLy
                        : 0}
                    </td>

                    <td
                    // onDoubleClick={() => {
                    //   setClickedTr({
                    //     mainRow: "adr",
                    //     subRow: "myProperty",
                    //     field: "curr",
                    //     day: "monday",
                    //   });
                    // }}
                    >
                      {clickedTr.mainRow === "adr" &&
                      clickedTr.subRow === "myProperty" &&
                      clickedTr.field === "curr" &&
                      clickedTr.day === "monday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current[1]?.AdrMyProperty}
                          onChange={(e) => {
                            let lyValue = "";
                            reportDataRef.current = reportDataRef.current?.map(
                              (item, index) => {
                                if (index === 1) {
                                  item.AdrMyProperty = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let changeValue = item?.AdrMyPropertyChange
                                    ? parseFloat(item.AdrMyPropertyChange)
                                    : 0;
                                  lyValue = (
                                    (newValue * 100) /
                                    (100 + changeValue)
                                  )?.toFixed(2);
                                  item.AdrMyPropertyLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current[1].AdrMyProperty =
                              e.target.value;
                            tableDataRef.current[1].AdrMyPropertyLy = lyValue;
                            setReportData(reportDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current[1]?.AdrMyProperty ? (
                        tableDataRef.current[1]?.AdrMyProperty
                      ) : (
                        0
                      )}
                    </td>
                    <td
                    // onDoubleClick={() => {
                    //   setClickedTr({
                    //     mainRow: "adr",
                    //     subRow: "myProperty",
                    //     field: "change",
                    //     day: "monday",
                    //   });
                    // }}
                    >
                      {clickedTr.mainRow === "adr" &&
                      clickedTr.subRow === "myProperty" &&
                      clickedTr.field === "change" &&
                      clickedTr.day === "monday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current[1]?.AdrMyPropertyChange}
                          onChange={(e) => {
                            let lyValue = "";
                            reportDataRef.current = reportDataRef.current?.map(
                              (item, index) => {
                                if (index === 1) {
                                  item.AdrMyPropertyChange = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let currValue = item?.AdrMyProperty
                                    ? parseFloat(item.AdrMyProperty)
                                    : 0;
                                  lyValue = (
                                    (currValue * 100) /
                                    (100 + newValue)
                                  )?.toFixed(2);
                                  item.AdrMyPropertyLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current[1].AdrMyPropertyChange =
                              e.target.value;
                            tableDataRef.current[1].AdrMyPropertyLy = lyValue;
                            setReportData(reportDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current[1]?.AdrMyPropertyChange ? (
                        tableDataRef.current[1]?.AdrMyPropertyChange
                      ) : (
                        0
                      )}
                    </td>
                    <td className="border_right">
                      {tableDataRef.current[1]?.AdrMyPropertyLy
                        ? tableDataRef.current[1]?.AdrMyPropertyLy
                        : 0}
                    </td>

                    <td
                    // onDoubleClick={() => {
                    //   setClickedTr({
                    //     mainRow: "adr",
                    //     subRow: "myProperty",
                    //     field: "curr",
                    //     day: "tuseday",
                    //   });
                    // }}
                    >
                      {clickedTr.mainRow === "adr" &&
                      clickedTr.subRow === "myProperty" &&
                      clickedTr.field === "curr" &&
                      clickedTr.day === "tuseday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current[2]?.AdrMyProperty}
                          onChange={(e) => {
                            let lyValue = "";
                            reportDataRef.current = reportDataRef.current?.map(
                              (item, index) => {
                                if (index === 2) {
                                  item.AdrMyProperty = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let changeValue = item?.AdrMyPropertyChange
                                    ? parseFloat(item.AdrMyPropertyChange)
                                    : 0;
                                  lyValue = (
                                    (newValue * 100) /
                                    (100 + changeValue)
                                  )?.toFixed(2);
                                  item.AdrMyPropertyLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current[2].AdrMyProperty =
                              e.target.value;
                            tableDataRef.current[2].AdrMyPropertyLy = lyValue;
                            setReportData(reportDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current[2]?.AdrMyProperty ? (
                        tableDataRef.current[2]?.AdrMyProperty
                      ) : (
                        0
                      )}
                    </td>
                    <td
                    // onDoubleClick={() => {
                    //   setClickedTr({
                    //     mainRow: "Adr",
                    //     subRow: "myProperty",
                    //     field: "change",
                    //     day: "tuesday",
                    //   });
                    // }}
                    >
                      {clickedTr.mainRow === "Adr" &&
                      clickedTr.subRow === "myProperty" &&
                      clickedTr.field === "change" &&
                      clickedTr.day === "tuesday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current[2]?.AdrMyPropertyChange}
                          onChange={(e) => {
                            let lyValue = "";
                            reportDataRef.current = reportDataRef.current?.map(
                              (item, index) => {
                                if (index === 2) {
                                  item.AdrMyPropertyChange = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let currValue = item?.AdrMyProperty
                                    ? parseFloat(item.AdrMyProperty)
                                    : 0;
                                  lyValue = (
                                    (currValue * 100) /
                                    (100 + newValue)
                                  )?.toFixed(2);
                                  item.AdrMyPropertyLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current[2].AdrMyPropertyChange =
                              e.target.value;
                            tableDataRef.current[2].AdrMyPropertyLy = lyValue;
                            setReportData(reportDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current[2]?.AdrMyPropertyChange ? (
                        tableDataRef.current[2]?.AdrMyPropertyChange
                      ) : (
                        0
                      )}
                    </td>
                    <td className="border_right">
                      {tableDataRef.current[2]?.AdrMyPropertyLy
                        ? tableDataRef.current[2]?.AdrMyPropertyLy
                        : 0}
                    </td>

                    <td
                    // onDoubleClick={() => {
                    //   setClickedTr({
                    //     mainRow: "Adr",
                    //     subRow: "myProperty",
                    //     field: "curr",
                    //     day: "wednesday",
                    //   });
                    // }}
                    >
                      {clickedTr.mainRow === "Adr" &&
                      clickedTr.subRow === "myProperty" &&
                      clickedTr.field === "curr" &&
                      clickedTr.day === "wednesday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current[3]?.AdrMyProperty}
                          onChange={(e) => {
                            let lyValue = "";
                            reportDataRef.current = reportDataRef.current?.map(
                              (item, index) => {
                                if (index === 3) {
                                  item.AdrMyProperty = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let changeValue = item?.AdrMyPropertyChange
                                    ? parseFloat(item.AdrMyPropertyChange)
                                    : 0;
                                  lyValue = (
                                    (newValue * 100) /
                                    (100 + changeValue)
                                  )?.toFixed(2);
                                  item.AdrMyPropertyLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current[3].AdrMyProperty =
                              e.target.value;
                            tableDataRef.current[3].AdrMyPropertyLy = lyValue;
                            setReportData(reportDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current[3]?.AdrMyProperty ? (
                        tableDataRef.current[3]?.AdrMyProperty
                      ) : (
                        0
                      )}
                    </td>
                    <td
                    // onDoubleClick={() => {
                    //   setClickedTr({
                    //     mainRow: "Adr",
                    //     subRow: "myProperty",
                    //     field: "change",
                    //     day: "wednesday",
                    //   });
                    // }}
                    >
                      {clickedTr.mainRow === "Adr" &&
                      clickedTr.subRow === "myProperty" &&
                      clickedTr.field === "change" &&
                      clickedTr.day === "wednesday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current[3]?.AdrMyPropertyChange}
                          onChange={(e) => {
                            let lyValue = "";
                            reportDataRef.current = reportDataRef.current?.map(
                              (item, index) => {
                                if (index === 3) {
                                  item.AdrMyPropertyChange = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let currValue = item?.AdrMyProperty
                                    ? parseFloat(item.AdrMyProperty)
                                    : 0;
                                  lyValue = (
                                    (currValue * 100) /
                                    (100 + newValue)
                                  )?.toFixed(2);
                                  item.AdrMyPropertyLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current[3].AdrMyPropertyChange =
                              e.target.value;
                            tableDataRef.current[3].AdrMyPropertyLy = lyValue;
                            setReportData(reportDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current[3]?.AdrMyPropertyChange ? (
                        tableDataRef.current[3]?.AdrMyPropertyChange
                      ) : (
                        0
                      )}
                    </td>
                    <td className="border_right">
                      {tableDataRef.current[3]?.AdrMyPropertyLy
                        ? tableDataRef.current[3]?.AdrMyPropertyLy
                        : 0}
                    </td>

                    <td
                    // onDoubleClick={() => {
                    //   setClickedTr({
                    //     mainRow: "Adr",
                    //     subRow: "myProperty",
                    //     field: "curr",
                    //     day: "thursday",
                    //   });
                    // }}
                    >
                      {clickedTr.mainRow === "Adr" &&
                      clickedTr.subRow === "myProperty" &&
                      clickedTr.field === "curr" &&
                      clickedTr.day === "thursday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current[4]?.AdrMyProperty}
                          onChange={(e) => {
                            let lyValue = "";
                            reportDataRef.current = reportDataRef.current?.map(
                              (item, index) => {
                                if (index === 4) {
                                  item.AdrMyProperty = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let changeValue = item?.AdrMyPropertyChange
                                    ? parseFloat(item.AdrMyPropertyChange)
                                    : 0;
                                  lyValue = (
                                    (newValue * 100) /
                                    (100 + changeValue)
                                  )?.toFixed(2);
                                  item.AdrMyPropertyLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current[4].AdrMyProperty =
                              e.target.value;
                            tableDataRef.current[4].AdrMyPropertyLy = lyValue;
                            setReportData(reportDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current[4]?.AdrMyProperty ? (
                        tableDataRef.current[4]?.AdrMyProperty
                      ) : (
                        0
                      )}
                    </td>
                    <td
                    // onDoubleClick={() => {
                    //   setClickedTr({
                    //     mainRow: "Adr",
                    //     subRow: "myProperty",
                    //     field: "change",
                    //     day: "thursday",
                    //   });
                    // }}
                    >
                      {clickedTr.mainRow === "Adr" &&
                      clickedTr.subRow === "myProperty" &&
                      clickedTr.field === "change" &&
                      clickedTr.day === "thursday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current[4]?.AdrMyPropertyChange}
                          onChange={(e) => {
                            let lyValue = "";
                            reportDataRef.current = reportDataRef.current?.map(
                              (item, index) => {
                                if (index === 4) {
                                  item.AdrMyPropertyChange = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let currValue = item?.AdrMyProperty
                                    ? parseFloat(item.AdrMyProperty)
                                    : 0;
                                  lyValue = (
                                    (currValue * 100) /
                                    (100 + newValue)
                                  )?.toFixed(2);
                                  item.AdrMyPropertyLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current[4].AdrMyPropertyChange =
                              e.target.value;
                            tableDataRef.current[4].AdrMyPropertyLy = lyValue;
                            setReportData(reportDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current[4]?.AdrMyPropertyChange ? (
                        tableDataRef.current[4]?.AdrMyPropertyChange
                      ) : (
                        0
                      )}
                    </td>
                    <td className="border_right">
                      {tableDataRef.current[4]?.AdrMyPropertyLy
                        ? tableDataRef.current[4]?.AdrMyPropertyLy
                        : 0}
                    </td>

                    <td
                    // onDoubleClick={() => {
                    //   setClickedTr({
                    //     mainRow: "Adr",
                    //     subRow: "myProperty",
                    //     field: "curr",
                    //     day: "friday",
                    //   });
                    // }}
                    >
                      {clickedTr.mainRow === "Adr" &&
                      clickedTr.subRow === "myProperty" &&
                      clickedTr.field === "curr" &&
                      clickedTr.day === "friday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current[5]?.AdrMyProperty}
                          onChange={(e) => {
                            let lyValue = "";
                            reportDataRef.current = reportDataRef.current?.map(
                              (item, index) => {
                                if (index === 5) {
                                  item.AdrMyProperty = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let changeValue = item?.AdrMyPropertyChange
                                    ? parseFloat(item.AdrMyPropertyChange)
                                    : 0;
                                  lyValue = (
                                    (newValue * 100) /
                                    (100 + changeValue)
                                  )?.toFixed(2);
                                  item.AdrMyPropertyLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current[5].AdrMyProperty =
                              e.target.value;
                            tableDataRef.current[5].AdrMyPropertyLy = lyValue;
                            setReportData(reportDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current[5]?.AdrMyProperty ? (
                        tableDataRef.current[5]?.AdrMyProperty
                      ) : (
                        0
                      )}
                    </td>
                    <td
                    // onDoubleClick={() => {
                    //   setClickedTr({
                    //     mainRow: "Adr",
                    //     subRow: "myProperty",
                    //     field: "change",
                    //     day: "friday",
                    //   });
                    // }}
                    >
                      {clickedTr.mainRow === "Adr" &&
                      clickedTr.subRow === "myProperty" &&
                      clickedTr.field === "change" &&
                      clickedTr.day === "friday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current[5]?.AdrMyPropertyChange}
                          onChange={(e) => {
                            let lyValue = "";
                            reportDataRef.current = reportDataRef.current?.map(
                              (item, index) => {
                                if (index === 5) {
                                  item.AdrMyPropertyChange = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let currValue = item?.AdrMyProperty
                                    ? parseFloat(item.AdrMyProperty)
                                    : 0;
                                  lyValue = (
                                    (currValue * 100) /
                                    (100 + newValue)
                                  )?.toFixed(2);
                                  item.AdrMyPropertyLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current[5].AdrMyPropertyChange =
                              e.target.value;
                            tableDataRef.current[5].AdrMyPropertyLy = lyValue;
                            setReportData(reportDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current[5]?.AdrMyPropertyChange ? (
                        tableDataRef.current[5]?.AdrMyPropertyChange
                      ) : (
                        0
                      )}
                    </td>
                    <td className="border_right">
                      {tableDataRef.current[5]?.AdrMyPropertyLy
                        ? tableDataRef.current[5]?.AdrMyPropertyLy
                        : 0}
                    </td>

                    <td
                    // onDoubleClick={() => {
                    //   setClickedTr({
                    //     mainRow: "Adr",
                    //     subRow: "myProperty",
                    //     field: "curr",
                    //     day: "saturday",
                    //   });
                    // }}
                    >
                      {clickedTr.mainRow === "Adr" &&
                      clickedTr.subRow === "myProperty" &&
                      clickedTr.field === "curr" &&
                      clickedTr.day === "saturday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current[6]?.AdrMyProperty}
                          onChange={(e) => {
                            let lyValue = "";
                            reportDataRef.current = reportDataRef.current?.map(
                              (item, index) => {
                                if (index === 6) {
                                  item.AdrMyProperty = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let changeValue = item?.AdrMyPropertyChange
                                    ? parseFloat(item.AdrMyPropertyChange)
                                    : 0;
                                  lyValue = (
                                    (newValue * 100) /
                                    (100 + changeValue)
                                  )?.toFixed(2);
                                  item.AdrMyPropertyLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current[6].AdrMyProperty =
                              e.target.value;
                            tableDataRef.current[6].AdrMyPropertyLy = lyValue;
                            setReportData(reportDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current[6]?.AdrMyProperty ? (
                        tableDataRef.current[6]?.AdrMyProperty
                      ) : (
                        0
                      )}
                    </td>
                    <td
                    // onDoubleClick={() => {
                    //   setClickedTr({
                    //     mainRow: "Adr",
                    //     subRow: "myProperty",
                    //     field: "change",
                    //     day: "saturday",
                    //   });
                    // }}
                    >
                      {clickedTr.mainRow === "Adr" &&
                      clickedTr.subRow === "myProperty" &&
                      clickedTr.field === "change" &&
                      clickedTr.day === "saturday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current[6]?.AdrMyPropertyChange}
                          onChange={(e) => {
                            let lyValue = "";
                            reportDataRef.current = reportDataRef.current?.map(
                              (item, index) => {
                                if (index === 6) {
                                  item.AdrMyPropertyChange = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let currValue = item?.AdrMyProperty
                                    ? parseFloat(item.AdrMyProperty)
                                    : 0;
                                  lyValue = (
                                    (currValue * 100) /
                                    (100 + newValue)
                                  )?.toFixed(2);
                                  item.AdrMyPropertyLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current[6].AdrMyPropertyChange =
                              e.target.value;
                            tableDataRef.current[6].AdrMyPropertyLy = lyValue;
                            setReportData(reportDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current[6]?.AdrMyPropertyChange ? (
                        tableDataRef.current[6]?.AdrMyPropertyChange
                      ) : (
                        0
                      )}
                    </td>
                    <td className="border_right">
                      {tableDataRef.current[6]?.AdrMyPropertyLy
                        ? tableDataRef.current[6]?.AdrMyPropertyLy
                        : 0}
                    </td>
                    <td className="fontBold">
                      {tableDataRef.current[7]?.AdrMyProperty
                        ? tableDataRef.current[7]?.AdrMyProperty
                        : 0}
                    </td>
                  </tr>
                  <tr className="shadowOnHover">
                    <td className="border_right">Comp Set</td>
                    <td
                    // onDoubleClick={() => {
                    //   setClickedTr({
                    //     mainRow: "Adr",
                    //     subRow: "compSet",
                    //     field: "curr",
                    //     day: "sunday",
                    //   });
                    // }}
                    >
                      {clickedTr.mainRow === "Adr" &&
                      clickedTr.subRow === "compSet" &&
                      clickedTr.field === "curr" &&
                      clickedTr.day === "sunday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current[0]?.AdrCompset}
                          onChange={(e) => {
                            let lyValue = "";
                            reportDataRef.current = reportDataRef.current?.map(
                              (item, index) => {
                                if (index === 0) {
                                  item.AdrCompset = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let changeValue = item?.AdrCompsetChange
                                    ? parseFloat(item.AdrCompsetChange)
                                    : 0;
                                  lyValue = (
                                    (newValue * 100) /
                                    (100 + changeValue)
                                  )?.toFixed(2);
                                  item.AdrCompsetLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current[0].AdrCompset = e.target.value;
                            tableDataRef.current[0].AdrCompsetLy = lyValue;
                            setReportData(reportDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current[0]?.AdrCompset ? (
                        tableDataRef.current[0]?.AdrCompset
                      ) : (
                        0
                      )}
                    </td>
                    <td
                    // onDoubleClick={() => {
                    //   setClickedTr({
                    //     mainRow: "Adr",
                    //     subRow: "compSet",
                    //     field: "change",
                    //     day: "sunday",
                    //   });
                    // }}
                    >
                      {clickedTr.mainRow === "Adr" &&
                      clickedTr.subRow === "compSet" &&
                      clickedTr.field === "change" &&
                      clickedTr.day === "sunday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current[0]?.AdrCompsetChange}
                          onChange={(e) => {
                            let lyValue = "";
                            reportDataRef.current = reportDataRef.current?.map(
                              (item, index) => {
                                if (index === 0) {
                                  item.AdrCompsetChange = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let currValue = item?.AdrCompset
                                    ? parseFloat(item.AdrCompset)
                                    : 0;
                                  lyValue = (
                                    (currValue * 100) /
                                    (100 + newValue)
                                  )?.toFixed(2);
                                  item.AdrCompsetLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current[0].AdrCompsetChange =
                              e.target.value;
                            tableDataRef.current[0].AdrCompsetLy = lyValue;
                            setReportData(reportDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current[0]?.AdrCompsetChange ? (
                        tableDataRef.current[0]?.AdrCompsetChange
                      ) : (
                        0
                      )}
                    </td>
                    <td className="border_right">
                      {tableDataRef.current[0]?.AdrCompsetLy
                        ? tableDataRef.current[0]?.AdrCompsetLy
                        : 0}
                    </td>

                    <td
                    // onDoubleClick={() => {
                    //   setClickedTr({
                    //     mainRow: "Adr",
                    //     subRow: "compSet",
                    //     field: "curr",
                    //     day: "monday",
                    //   });
                    // }}
                    >
                      {clickedTr.mainRow === "Adr" &&
                      clickedTr.subRow === "compSet" &&
                      clickedTr.field === "curr" &&
                      clickedTr.day === "monday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current[1]?.AdrCompset}
                          onChange={(e) => {
                            let lyValue = "";
                            reportDataRef.current = reportDataRef.current?.map(
                              (item, index) => {
                                if (index === 1) {
                                  item.AdrCompset = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let changeValue = item?.AdrCompsetChange
                                    ? parseFloat(item.AdrCompsetChange)
                                    : 0;
                                  lyValue = (
                                    (newValue * 100) /
                                    (100 + changeValue)
                                  )?.toFixed(2);
                                  item.AdrCompsetLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current[1].AdrCompset = e.target.value;
                            tableDataRef.current[1].AdrCompsetLy = lyValue;
                            setReportData(reportDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current[1]?.AdrCompset ? (
                        tableDataRef.current[1]?.AdrCompset
                      ) : (
                        0
                      )}
                    </td>
                    <td
                    // onDoubleClick={() => {
                    //   setClickedTr({
                    //     mainRow: "Adr",
                    //     subRow: "compSet",
                    //     field: "change",
                    //     day: "monday",
                    //   });
                    // }}
                    >
                      {clickedTr.mainRow === "Adr" &&
                      clickedTr.subRow === "compSet" &&
                      clickedTr.field === "change" &&
                      clickedTr.day === "monday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current[1]?.AdrCompsetChange}
                          onChange={(e) => {
                            let lyValue = "";
                            reportDataRef.current = reportDataRef.current?.map(
                              (item, index) => {
                                if (index === 1) {
                                  item.AdrCompsetChange = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let currValue = item?.AdrCompset
                                    ? parseFloat(item.AdrCompset)
                                    : 0;
                                  lyValue = (
                                    (currValue * 100) /
                                    (100 + newValue)
                                  )?.toFixed(2);
                                  item.AdrCompsetLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current[1].AdrCompsetChange =
                              e.target.value;
                            tableDataRef.current[1].AdrCompsetLy = lyValue;
                            setReportData(reportDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current[1]?.AdrCompsetChange ? (
                        tableDataRef.current[1]?.AdrCompsetChange
                      ) : (
                        0
                      )}
                    </td>
                    <td className="border_right">
                      {tableDataRef.current[1]?.AdrCompsetLy
                        ? tableDataRef.current[1]?.AdrCompsetLy
                        : 0}
                    </td>

                    <td
                    // onDoubleClick={() => {
                    //   setClickedTr({
                    //     mainRow: "Adr",
                    //     subRow: "compSet",
                    //     field: "curr",
                    //     day: "tuesday",
                    //   });
                    // }}
                    >
                      {clickedTr.mainRow === "Adr" &&
                      clickedTr.subRow === "compSet" &&
                      clickedTr.field === "curr" &&
                      clickedTr.day === "tuesday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current[2]?.AdrCompset}
                          onChange={(e) => {
                            let lyValue = "";
                            reportDataRef.current = reportDataRef.current?.map(
                              (item, index) => {
                                if (index === 2) {
                                  item.AdrCompset = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let changeValue = item?.AdrCompsetChange
                                    ? parseFloat(item.AdrCompsetChange)
                                    : 0;
                                  lyValue = (
                                    (newValue * 100) /
                                    (100 + changeValue)
                                  )?.toFixed(2);
                                  item.AdrCompsetLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current[2].AdrCompset = e.target.value;
                            tableDataRef.current[2].AdrCompsetLy = lyValue;
                            setReportData(reportDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current[2]?.AdrCompset ? (
                        tableDataRef.current[2]?.AdrCompset
                      ) : (
                        0
                      )}
                    </td>
                    <td
                    // onDoubleClick={() => {
                    //   setClickedTr({
                    //     mainRow: "Adr",
                    //     subRow: "compSet",
                    //     field: "change",
                    //     day: "tuesday",
                    //   });
                    // }}
                    >
                      {clickedTr.mainRow === "Adr" &&
                      clickedTr.subRow === "compSet" &&
                      clickedTr.field === "change" &&
                      clickedTr.day === "tuesday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current[2]?.AdrCompsetChange}
                          onChange={(e) => {
                            let lyValue = "";
                            reportDataRef.current = reportDataRef.current?.map(
                              (item, index) => {
                                if (index === 2) {
                                  item.AdrCompsetChange = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let currValue = item?.AdrCompset
                                    ? parseFloat(item.AdrCompset)
                                    : 0;
                                  lyValue = (
                                    (currValue * 100) /
                                    (100 + newValue)
                                  )?.toFixed(2);
                                  item.AdrCompsetLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current[2].AdrCompsetChange =
                              e.target.value;
                            tableDataRef.current[2].AdrCompsetLy = lyValue;
                            setReportData(reportDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current[2]?.AdrCompsetChange ? (
                        tableDataRef.current[2]?.AdrCompsetChange
                      ) : (
                        0
                      )}
                    </td>
                    <td className="border_right">
                      {tableDataRef.current[2]?.AdrCompsetLy
                        ? tableDataRef.current[2]?.AdrCompsetLy
                        : 0}
                    </td>

                    <td
                    // onDoubleClick={() => {
                    //   setClickedTr({
                    //     mainRow: "Adr",
                    //     subRow: "compSet",
                    //     field: "curr",
                    //     day: "wednesday",
                    //   });
                    // }}
                    >
                      {clickedTr.mainRow === "Adr" &&
                      clickedTr.subRow === "compSet" &&
                      clickedTr.field === "curr" &&
                      clickedTr.day === "wednesday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current[3]?.AdrCompset}
                          onChange={(e) => {
                            let lyValue = "";
                            reportDataRef.current = reportDataRef.current?.map(
                              (item, index) => {
                                if (index === 3) {
                                  item.AdrCompset = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let changeValue = item?.AdrCompsetChange
                                    ? parseFloat(item.AdrCompsetChange)
                                    : 0;
                                  lyValue = (
                                    (newValue * 100) /
                                    (100 + changeValue)
                                  )?.toFixed(2);
                                  item.AdrCompsetLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current[3].AdrCompset = e.target.value;
                            tableDataRef.current[3].AdrCompsetLy = lyValue;
                            setReportData(reportDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current[3]?.AdrCompset ? (
                        tableDataRef.current[3]?.AdrCompset
                      ) : (
                        0
                      )}
                    </td>
                    <td
                    // onDoubleClick={() => {
                    //   setClickedTr({
                    //     mainRow: "Adr",
                    //     subRow: "compSet",
                    //     field: "change",
                    //     day: "wednesday",
                    //   });
                    // }}
                    >
                      {clickedTr.mainRow === "Adr" &&
                      clickedTr.subRow === "compSet" &&
                      clickedTr.field === "change" &&
                      clickedTr.day === "wednesday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current[3]?.AdrCompsetChange}
                          onChange={(e) => {
                            let lyValue = "";
                            reportDataRef.current = reportDataRef.current?.map(
                              (item, index) => {
                                if (index === 3) {
                                  item.AdrCompsetChange = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let currValue = item?.AdrCompset
                                    ? parseFloat(item.AdrCompset)
                                    : 0;
                                  lyValue = (
                                    (currValue * 100) /
                                    (100 + newValue)
                                  )?.toFixed(2);
                                  item.AdrCompsetLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current[3].AdrCompsetChange =
                              e.target.value;
                            tableDataRef.current[3].AdrCompsetLy = lyValue;
                            setReportData(reportDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current[3]?.AdrCompsetChange ? (
                        tableDataRef.current[3]?.AdrCompsetChange
                      ) : (
                        0
                      )}
                    </td>
                    <td className="border_right">
                      {tableDataRef.current[3]?.AdrCompsetLy
                        ? tableDataRef.current[3]?.AdrCompsetLy
                        : 0}
                    </td>

                    <td
                    // onDoubleClick={() => {
                    //   setClickedTr({
                    //     mainRow: "Adr",
                    //     subRow: "compSet",
                    //     field: "curr",
                    //     day: "thursday",
                    //   });
                    // }}
                    >
                      {clickedTr.mainRow === "Adr" &&
                      clickedTr.subRow === "compSet" &&
                      clickedTr.field === "curr" &&
                      clickedTr.day === "thursday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current[4]?.AdrCompset}
                          onChange={(e) => {
                            let lyValue = "";
                            reportDataRef.current = reportDataRef.current?.map(
                              (item, index) => {
                                if (index === 4) {
                                  item.AdrCompset = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let changeValue = item?.AdrCompsetChange
                                    ? parseFloat(item.AdrCompsetChange)
                                    : 0;
                                  lyValue = (
                                    (newValue * 100) /
                                    (100 + changeValue)
                                  )?.toFixed(2);
                                  item.AdrCompsetLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current[4].AdrCompset = e.target.value;
                            tableDataRef.current[4].AdrCompsetLy = lyValue;
                            setReportData(reportDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current[4]?.AdrCompset ? (
                        tableDataRef.current[4]?.AdrCompset
                      ) : (
                        0
                      )}
                    </td>
                    <td
                    // onDoubleClick={() => {
                    //   setClickedTr({
                    //     mainRow: "Adr",
                    //     subRow: "compSet",
                    //     field: "change",
                    //     day: "thursday",
                    //   });
                    // }}
                    >
                      {clickedTr.mainRow === "Adr" &&
                      clickedTr.subRow === "compSet" &&
                      clickedTr.field === "change" &&
                      clickedTr.day === "thursday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current[4]?.AdrCompsetChange}
                          onChange={(e) => {
                            let lyValue = "";
                            reportDataRef.current = reportDataRef.current?.map(
                              (item, index) => {
                                if (index === 4) {
                                  item.AdrCompsetChange = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let currValue = item?.AdrCompset
                                    ? parseFloat(item.AdrCompset)
                                    : 0;
                                  lyValue = (
                                    (currValue * 100) /
                                    (100 + newValue)
                                  )?.toFixed(2);
                                  item.AdrCompsetLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current[4].AdrCompsetChange =
                              e.target.value;
                            tableDataRef.current[4].AdrCompsetLy = lyValue;
                            setReportData(reportDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current[4]?.AdrCompsetChange ? (
                        tableDataRef.current[4]?.AdrCompsetChange
                      ) : (
                        0
                      )}
                    </td>
                    <td className="border_right">
                      {tableDataRef.current[4]?.AdrCompsetLy
                        ? tableDataRef.current[4]?.AdrCompsetLy
                        : 0}
                    </td>

                    <td
                    // onDoubleClick={() => {
                    //   setClickedTr({
                    //     mainRow: "Adr",
                    //     subRow: "compSet",
                    //     field: "curr",
                    //     day: "friday",
                    //   });
                    // }}
                    >
                      {clickedTr.mainRow === "Adr" &&
                      clickedTr.subRow === "compSet" &&
                      clickedTr.field === "curr" &&
                      clickedTr.day === "friday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current[5]?.AdrCompset}
                          onChange={(e) => {
                            let lyValue = "";
                            reportDataRef.current = reportDataRef.current?.map(
                              (item, index) => {
                                if (index === 5) {
                                  item.AdrCompset = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let changeValue = item?.AdrCompsetChange
                                    ? parseFloat(item.AdrCompsetChange)
                                    : 0;
                                  lyValue = (
                                    (newValue * 100) /
                                    (100 + changeValue)
                                  )?.toFixed(2);
                                  item.AdrCompsetLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current[5].AdrCompset = e.target.value;
                            tableDataRef.current[5].AdrCompsetLy = lyValue;
                            setReportData(reportDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current[5]?.AdrCompset ? (
                        tableDataRef.current[5]?.AdrCompset
                      ) : (
                        0
                      )}
                    </td>
                    <td
                    // onDoubleClick={() => {
                    //   setClickedTr({
                    //     mainRow: "Adr",
                    //     subRow: "compSet",
                    //     field: "change",
                    //     day: "friday",
                    //   });
                    // }}
                    >
                      {clickedTr.mainRow === "Adr" &&
                      clickedTr.subRow === "compSet" &&
                      clickedTr.field === "change" &&
                      clickedTr.day === "friday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current[5]?.AdrCompsetChange}
                          onChange={(e) => {
                            let lyValue = "";
                            reportDataRef.current = reportDataRef.current?.map(
                              (item, index) => {
                                if (index === 5) {
                                  item.AdrCompsetChange = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let currValue = item?.AdrCompset
                                    ? parseFloat(item.AdrCompset)
                                    : 0;
                                  lyValue = (
                                    (currValue * 100) /
                                    (100 + newValue)
                                  )?.toFixed(2);
                                  item.AdrCompsetLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current[5].AdrCompsetChange =
                              e.target.value;
                            tableDataRef.current[5].AdrCompsetLy = lyValue;
                            setReportData(reportDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current[5]?.AdrCompsetChange ? (
                        tableDataRef.current[5]?.AdrCompsetChange
                      ) : (
                        0
                      )}
                    </td>
                    <td className="border_right">
                      {tableDataRef.current[5]?.AdrCompsetLy
                        ? tableDataRef.current[5]?.AdrCompsetLy
                        : 0}
                    </td>

                    <td
                    // onDoubleClick={() => {
                    //   setClickedTr({
                    //     mainRow: "Adr",
                    //     subRow: "compSet",
                    //     field: "curr",
                    //     day: "saturday",
                    //   });
                    // }}
                    >
                      {clickedTr.mainRow === "Adr" &&
                      clickedTr.subRow === "compSet" &&
                      clickedTr.field === "curr" &&
                      clickedTr.day === "saturday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current[6]?.AdrCompset}
                          onChange={(e) => {
                            let lyValue = "";
                            reportDataRef.current = reportDataRef.current?.map(
                              (item, index) => {
                                if (index === 6) {
                                  item.AdrCompset = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let changeValue = item?.AdrCompsetChange
                                    ? parseFloat(item.AdrCompsetChange)
                                    : 0;
                                  lyValue = (
                                    (newValue * 100) /
                                    (100 + changeValue)
                                  )?.toFixed(2);
                                  item.AdrCompsetLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current[6].AdrCompset = e.target.value;
                            tableDataRef.current[6].AdrCompsetLy = lyValue;
                            setReportData(reportDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current[6]?.AdrCompset ? (
                        tableDataRef.current[6]?.AdrCompset
                      ) : (
                        0
                      )}
                    </td>
                    <td
                    // onDoubleClick={() => {
                    //   setClickedTr({
                    //     mainRow: "Adr",
                    //     subRow: "compSet",
                    //     field: "change",
                    //     day: "saturday",
                    //   });
                    // }}
                    >
                      {clickedTr.mainRow === "Adr" &&
                      clickedTr.subRow === "compSet" &&
                      clickedTr.field === "change" &&
                      clickedTr.day === "saturday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current[6]?.AdrCompsetChange}
                          onChange={(e) => {
                            let lyValue = "";
                            reportDataRef.current = reportDataRef.current?.map(
                              (item, index) => {
                                if (index === 6) {
                                  item.AdrCompsetChange = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let currValue = item?.AdrCompset
                                    ? parseFloat(item.AdrCompset)
                                    : 0;
                                  lyValue = (
                                    (currValue * 100) /
                                    (100 + newValue)
                                  )?.toFixed(2);
                                  item.AdrCompsetLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current[6].AdrCompsetChange =
                              e.target.value;
                            tableDataRef.current[6].AdrCompsetLy = lyValue;
                            setReportData(reportDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current[6]?.AdrCompsetChange ? (
                        tableDataRef.current[6]?.AdrCompsetChange
                      ) : (
                        0
                      )}
                    </td>
                    <td className="border_right">
                      {tableDataRef.current[6]?.AdrCompsetLy
                        ? tableDataRef.current[6]?.AdrCompsetLy
                        : 0}
                    </td>
                    <td className="fontBold">
                      {tableDataRef.current[7]?.AdrCompset
                        ? tableDataRef.current[7]?.AdrCompset
                        : 0}
                    </td>
                  </tr>
                  <tr className="shadowOnHover">
                    <td className="border_right">Index (MPI)</td>
                    <td
                    // onDoubleClick={() => {
                    //   setClickedTr({
                    //     mainRow: "Adr",
                    //     subRow: "index",
                    //     field: "curr",
                    //     day: "sunday",
                    //   });
                    // }}
                    >
                      {clickedTr.mainRow === "Adr" &&
                      clickedTr.subRow === "index" &&
                      clickedTr.field === "curr" &&
                      clickedTr.day === "sunday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current[0]?.AdrIndex}
                          onChange={(e) => {
                            let lyValue = "";
                            reportDataRef.current = reportDataRef.current?.map(
                              (item, index) => {
                                if (index === 0) {
                                  item.AdrIndex = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let changeValue = item?.AdrIndexChange
                                    ? parseFloat(item.AdrIndexChange)
                                    : 0;
                                  lyValue = (
                                    (newValue * 100) /
                                    (100 + changeValue)
                                  )?.toFixed(2);
                                  item.AdrIndexLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current[0].AdrIndex = e.target.value;
                            tableDataRef.current[0].AdrIndexLy = lyValue;
                            setReportData(reportDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current[0]?.AdrIndex ? (
                        tableDataRef.current[0]?.AdrIndex
                      ) : (
                        0
                      )}
                    </td>
                    <td
                    // onDoubleClick={() => {
                    //   setClickedTr({
                    //     mainRow: "Adr",
                    //     subRow: "index",
                    //     field: "change",
                    //     day: "sunday",
                    //   });
                    // }}
                    >
                      {clickedTr.mainRow === "Adr" &&
                      clickedTr.subRow === "index" &&
                      clickedTr.field === "change" &&
                      clickedTr.day === "sunday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current[0]?.AdrIndexChange}
                          onChange={(e) => {
                            let lyValue = "";
                            reportDataRef.current = reportDataRef.current?.map(
                              (item, index) => {
                                if (index === 0) {
                                  item.AdrIndexChange = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let currValue = item?.AdrIndex
                                    ? parseFloat(item.AdrIndex)
                                    : 0;
                                  lyValue = (
                                    (currValue * 100) /
                                    (100 + newValue)
                                  )?.toFixed(2);
                                  item.AdrIndexLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current[0].AdrIndexChange =
                              e.target.value;
                            tableDataRef.current[0].AdrIndexLy = lyValue;
                            setReportData(reportDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current[0]?.AdrIndexChange ? (
                        tableDataRef.current[0]?.AdrIndexChange
                      ) : (
                        0
                      )}
                    </td>
                    <td className="border_right">
                      {tableDataRef.current[0]?.AdrIndexLy
                        ? tableDataRef.current[0]?.AdrIndexLy
                        : 0}
                    </td>

                    <td
                    // onDoubleClick={() => {
                    //   setClickedTr({
                    //     mainRow: "Adr",
                    //     subRow: "index",
                    //     field: "curr",
                    //     day: "monday",
                    //   });
                    // }}
                    >
                      {clickedTr.mainRow === "Adr" &&
                      clickedTr.subRow === "index" &&
                      clickedTr.field === "curr" &&
                      clickedTr.day === "monday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current[1]?.AdrIndex}
                          onChange={(e) => {
                            let lyValue = "";
                            reportDataRef.current = reportDataRef.current?.map(
                              (item, index) => {
                                if (index === 1) {
                                  item.AdrIndex = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let changeValue = item?.AdrIndexChange
                                    ? parseFloat(item.AdrIndexChange)
                                    : 0;
                                  lyValue = (
                                    (newValue * 100) /
                                    (100 + changeValue)
                                  )?.toFixed(2);
                                  item.AdrIndexLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current[1].AdrIndex = e.target.value;
                            tableDataRef.current[1].AdrIndexLy = lyValue;
                            setReportData(reportDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current[1]?.AdrIndex ? (
                        tableDataRef.current[1]?.AdrIndex
                      ) : (
                        0
                      )}
                    </td>
                    <td
                    // onDoubleClick={() => {
                    //   setClickedTr({
                    //     mainRow: "Adr",
                    //     subRow: "index",
                    //     field: "change",
                    //     day: "monday",
                    //   });
                    // }}
                    >
                      {clickedTr.mainRow === "Adr" &&
                      clickedTr.subRow === "index" &&
                      clickedTr.field === "change" &&
                      clickedTr.day === "monday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current[1]?.AdrIndexChange}
                          onChange={(e) => {
                            let lyValue = "";
                            reportDataRef.current = reportDataRef.current?.map(
                              (item, index) => {
                                if (index === 1) {
                                  item.AdrIndexChange = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let currValue = item?.AdrIndex
                                    ? parseFloat(item.AdrIndex)
                                    : 0;
                                  lyValue = (
                                    (currValue * 100) /
                                    (100 + newValue)
                                  )?.toFixed(2);
                                  item.AdrIndexLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current[1].AdrIndexChange =
                              e.target.value;
                            tableDataRef.current[1].AdrIndexLy = lyValue;
                            setReportData(reportDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current[1]?.AdrIndexChange ? (
                        tableDataRef.current[1]?.AdrIndexChange
                      ) : (
                        0
                      )}
                    </td>
                    <td className="border_right">
                      {tableDataRef.current[1]?.AdrIndexLy
                        ? tableDataRef.current[1]?.AdrIndexLy
                        : 0}
                    </td>

                    <td
                    // onDoubleClick={() => {
                    //   setClickedTr({
                    //     mainRow: "Adr",
                    //     subRow: "index",
                    //     field: "curr",
                    //     day: "tuesday",
                    //   });
                    // }}
                    >
                      {clickedTr.mainRow === "Adr" &&
                      clickedTr.subRow === "index" &&
                      clickedTr.field === "curr" &&
                      clickedTr.day === "tuesday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current[2]?.AdrIndex}
                          onChange={(e) => {
                            let lyValue = "";
                            reportDataRef.current = reportDataRef.current?.map(
                              (item, index) => {
                                if (index === 2) {
                                  item.AdrIndex = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let changeValue = item?.AdrIndexChange
                                    ? parseFloat(item.AdrIndexChange)
                                    : 0;
                                  lyValue = (
                                    (newValue * 100) /
                                    (100 + changeValue)
                                  )?.toFixed(2);
                                  item.AdrIndexLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current[2].AdrIndex = e.target.value;
                            tableDataRef.current[2].AdrIndexLy = lyValue;
                            setReportData(reportDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current[2]?.AdrIndex ? (
                        tableDataRef.current[2]?.AdrIndex
                      ) : (
                        0
                      )}
                    </td>
                    <td
                    // onDoubleClick={() => {
                    //   setClickedTr({
                    //     mainRow: "Adr",
                    //     subRow: "index",
                    //     field: "change",
                    //     day: "tuesday",
                    //   });
                    // }}
                    >
                      {clickedTr.mainRow === "Adr" &&
                      clickedTr.subRow === "index" &&
                      clickedTr.field === "change" &&
                      clickedTr.day === "tuesday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current[2]?.AdrIndexChange}
                          onChange={(e) => {
                            let lyValue = "";
                            reportDataRef.current = reportDataRef.current?.map(
                              (item, index) => {
                                if (index === 2) {
                                  item.AdrIndexChange = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let currValue = item?.AdrIndex
                                    ? parseFloat(item.AdrIndex)
                                    : 0;
                                  lyValue = (
                                    (currValue * 100) /
                                    (100 + newValue)
                                  )?.toFixed(2);
                                  item.AdrIndexLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current[2].AdrIndexChange =
                              e.target.value;
                            tableDataRef.current[2].AdrIndexLy = lyValue;
                            setReportData(reportDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current[2]?.AdrIndexChange ? (
                        tableDataRef.current[2]?.AdrIndexChange
                      ) : (
                        0
                      )}
                    </td>
                    <td className="border_right">
                      {tableDataRef.current[2]?.AdrIndexLy
                        ? tableDataRef.current[2]?.AdrIndexLy
                        : 0}
                    </td>

                    <td
                    // onDoubleClick={() => {
                    //   setClickedTr({
                    //     mainRow: "Adr",
                    //     subRow: "index",
                    //     field: "curr",
                    //     day: "wednesday",
                    //   });
                    // }}
                    >
                      {clickedTr.mainRow === "Adr" &&
                      clickedTr.subRow === "index" &&
                      clickedTr.field === "curr" &&
                      clickedTr.day === "wednesday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current[3]?.AdrIndex}
                          onChange={(e) => {
                            let lyValue = "";
                            reportDataRef.current = reportDataRef.current?.map(
                              (item, index) => {
                                if (index === 3) {
                                  item.AdrIndex = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let changeValue = item?.AdrIndexChange
                                    ? parseFloat(item.AdrIndexChange)
                                    : 0;
                                  lyValue = (
                                    (newValue * 100) /
                                    (100 + changeValue)
                                  )?.toFixed(2);
                                  item.AdrIndexLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current[3].AdrIndex = e.target.value;
                            tableDataRef.current[3].AdrIndexLy = lyValue;
                            setReportData(reportDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current[3]?.AdrIndex ? (
                        tableDataRef.current[3]?.AdrIndex
                      ) : (
                        0
                      )}
                    </td>
                    <td
                    // onDoubleClick={() => {
                    //   setClickedTr({
                    //     mainRow: "Adr",
                    //     subRow: "index",
                    //     field: "change",
                    //     day: "wednesday",
                    //   });
                    // }}
                    >
                      {clickedTr.mainRow === "Adr" &&
                      clickedTr.subRow === "index" &&
                      clickedTr.field === "change" &&
                      clickedTr.day === "wednesday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current[3]?.AdrIndexChange}
                          onChange={(e) => {
                            let lyValue = "";
                            reportDataRef.current = reportDataRef.current?.map(
                              (item, index) => {
                                if (index === 3) {
                                  item.AdrIndexChange = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let currValue = item?.AdrIndex
                                    ? parseFloat(item.AdrIndex)
                                    : 0;
                                  lyValue = (
                                    (currValue * 100) /
                                    (100 + newValue)
                                  )?.toFixed(2);
                                  item.AdrIndexLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current[3].AdrIndexChange =
                              e.target.value;
                            tableDataRef.current[3].AdrIndexLy = lyValue;
                            setReportData(reportDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current[3]?.AdrIndexChange ? (
                        tableDataRef.current[3]?.AdrIndexChange
                      ) : (
                        0
                      )}
                    </td>
                    <td className="border_right">
                      {tableDataRef.current[3]?.AdrIndexLy
                        ? tableDataRef.current[3]?.AdrIndexLy
                        : 0}
                    </td>

                    <td
                    // onDoubleClick={() => {
                    //   setClickedTr({
                    //     mainRow: "Adr",
                    //     subRow: "index",
                    //     field: "curr",
                    //     day: "thursday",
                    //   });
                    // }}
                    >
                      {clickedTr.mainRow === "Adr" &&
                      clickedTr.subRow === "index" &&
                      clickedTr.field === "curr" &&
                      clickedTr.day === "thursday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current[4]?.AdrIndex}
                          onChange={(e) => {
                            let lyValue = "";
                            reportDataRef.current = reportDataRef.current?.map(
                              (item, index) => {
                                if (index === 4) {
                                  item.AdrIndex = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let changeValue = item?.AdrIndexChange
                                    ? parseFloat(item.AdrIndexChange)
                                    : 0;
                                  lyValue = (
                                    (newValue * 100) /
                                    (100 + changeValue)
                                  )?.toFixed(2);
                                  item.AdrIndexLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current[4].AdrIndex = e.target.value;
                            tableDataRef.current[4].AdrIndexLy = lyValue;
                            setReportData(reportDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current[4]?.AdrIndex ? (
                        tableDataRef.current[4]?.AdrIndex
                      ) : (
                        0
                      )}
                    </td>
                    <td
                    // onDoubleClick={() => {
                    //   setClickedTr({
                    //     mainRow: "Adr",
                    //     subRow: "index",
                    //     field: "change",
                    //     day: "thursday",
                    //   });
                    // }}
                    >
                      {clickedTr.mainRow === "Adr" &&
                      clickedTr.subRow === "index" &&
                      clickedTr.field === "change" &&
                      clickedTr.day === "thursday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current[4]?.AdrIndexChange}
                          onChange={(e) => {
                            let lyValue = "";
                            reportDataRef.current = reportDataRef.current?.map(
                              (item, index) => {
                                if (index === 4) {
                                  item.AdrIndexChange = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let currValue = item?.AdrIndex
                                    ? parseFloat(item.AdrIndex)
                                    : 0;
                                  lyValue = (
                                    (currValue * 100) /
                                    (100 + newValue)
                                  )?.toFixed(2);
                                  item.AdrIndexLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current[4].AdrIndexChange =
                              e.target.value;
                            tableDataRef.current[4].AdrIndexLy = lyValue;
                            setReportData(reportDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current[4]?.AdrIndexChange ? (
                        tableDataRef.current[4]?.AdrIndexChange
                      ) : (
                        0
                      )}
                    </td>
                    <td className="border_right">
                      {tableDataRef.current[4]?.AdrIndexLy
                        ? tableDataRef.current[4]?.AdrIndexLy
                        : 0}
                    </td>

                    <td
                    // onDoubleClick={() => {
                    //   setClickedTr({
                    //     mainRow: "Adr",
                    //     subRow: "index",
                    //     field: "curr",
                    //     day: "friday",
                    //   });
                    // }}
                    >
                      {clickedTr.mainRow === "Adr" &&
                      clickedTr.subRow === "index" &&
                      clickedTr.field === "curr" &&
                      clickedTr.day === "friday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current[5]?.AdrIndex}
                          onChange={(e) => {
                            let lyValue = "";
                            reportDataRef.current = reportDataRef.current?.map(
                              (item, index) => {
                                if (index === 5) {
                                  item.AdrIndex = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let changeValue = item?.AdrIndexChange
                                    ? parseFloat(item.AdrIndexChange)
                                    : 0;
                                  lyValue = (
                                    (newValue * 100) /
                                    (100 + changeValue)
                                  )?.toFixed(2);
                                  item.AdrIndexLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current[5].AdrIndex = e.target.value;
                            tableDataRef.current[5].AdrIndexLy = lyValue;
                            setReportData(reportDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current[5]?.AdrIndex ? (
                        tableDataRef.current[5]?.AdrIndex
                      ) : (
                        0
                      )}
                    </td>
                    <td
                    // onDoubleClick={() => {
                    //   setClickedTr({
                    //     mainRow: "Adr",
                    //     subRow: "index",
                    //     field: "change",
                    //     day: "friday",
                    //   });
                    // }}
                    >
                      {clickedTr.mainRow === "Adr" &&
                      clickedTr.subRow === "index" &&
                      clickedTr.field === "change" &&
                      clickedTr.day === "friday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current[5]?.AdrIndexChange}
                          onChange={(e) => {
                            let lyValue = "";
                            reportDataRef.current = reportDataRef.current?.map(
                              (item, index) => {
                                if (index === 5) {
                                  item.AdrIndexChange = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let currValue = item?.AdrIndex
                                    ? parseFloat(item.AdrIndex)
                                    : 0;
                                  lyValue = (
                                    (currValue * 100) /
                                    (100 + newValue)
                                  )?.toFixed(2);
                                  item.AdrIndexLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current[5].AdrIndexChange =
                              e.target.value;
                            tableDataRef.current[5].AdrIndexLy = lyValue;
                            setReportData(reportDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current[5]?.AdrIndexChange ? (
                        tableDataRef.current[5]?.AdrIndexChange
                      ) : (
                        0
                      )}
                    </td>
                    <td className="border_right">
                      {tableDataRef.current[5]?.AdrIndexLy
                        ? tableDataRef.current[5]?.AdrIndexLy
                        : 0}
                    </td>

                    <td
                    // onDoubleClick={() => {
                    //   setClickedTr({
                    //     mainRow: "Adr",
                    //     subRow: "index",
                    //     field: "curr",
                    //     day: "saturday",
                    //   });
                    // }}
                    >
                      {clickedTr.mainRow === "Adr" &&
                      clickedTr.subRow === "index" &&
                      clickedTr.field === "curr" &&
                      clickedTr.day === "saturday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current[6]?.AdrIndex}
                          onChange={(e) => {
                            let lyValue = "";
                            reportDataRef.current = reportDataRef.current?.map(
                              (item, index) => {
                                if (index === 6) {
                                  item.AdrIndex = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let changeValue = item?.AdrIndexChange
                                    ? parseFloat(item.AdrIndexChange)
                                    : 0;
                                  lyValue = (
                                    (newValue * 100) /
                                    (100 + changeValue)
                                  )?.toFixed(2);
                                  item.AdrIndexLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current[6].AdrIndex = e.target.value;
                            tableDataRef.current[6].AdrIndexLy = lyValue;
                            setReportData(reportDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current[6]?.AdrIndex ? (
                        tableDataRef.current[6]?.AdrIndex
                      ) : (
                        0
                      )}
                    </td>
                    <td
                    // onDoubleClick={() => {
                    //   setClickedTr({
                    //     mainRow: "Adr",
                    //     subRow: "index",
                    //     field: "change",
                    //     day: "saturday",
                    //   });
                    // }}
                    >
                      {clickedTr.mainRow === "Adr" &&
                      clickedTr.subRow === "index" &&
                      clickedTr.field === "change" &&
                      clickedTr.day === "saturday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current[6]?.AdrIndexChange}
                          onChange={(e) => {
                            let lyValue = "";
                            reportDataRef.current = reportDataRef.current?.map(
                              (item, index) => {
                                if (index === 6) {
                                  item.AdrIndexChange = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let currValue = item?.AdrIndex
                                    ? parseFloat(item.AdrIndex)
                                    : 0;
                                  lyValue = (
                                    (currValue * 100) /
                                    (100 + newValue)
                                  )?.toFixed(2);
                                  item.AdrIndexLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current[6].AdrIndexChange =
                              e.target.value;
                            tableDataRef.current[6].AdrIndexLy = lyValue;
                            setReportData(reportDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current[6]?.AdrIndexChange ? (
                        tableDataRef.current[6]?.AdrIndexChange
                      ) : (
                        0
                      )}
                    </td>
                    <td className="border_right">
                      {tableDataRef.current[6]?.AdrIndexLy
                        ? tableDataRef.current[6]?.AdrIndexLy
                        : 0}
                    </td>
                    <td className="fontBold">
                      {tableDataRef.current[7]?.AdrIndex
                        ? tableDataRef.current[7]?.AdrIndex
                        : 0}
                    </td>
                  </tr>

                  {/* REVPAR */}
                  <tr className="shadowOnHover">
                    <td rowSpan={3} className="th-with-rowspan border_right">
                      RevPAR
                    </td>
                    <td className="border_right">My Property</td>
                    <td
                    // onDoubleClick={() => {
                    //   setClickedTr({
                    //     mainRow: "Revpar",
                    //     subRow: "myProperty",
                    //     field: "curr",
                    //     day: "sunday",
                    //   });
                    // }}
                    >
                      {clickedTr.mainRow === "Revpar" &&
                      clickedTr.subRow === "myProperty" &&
                      clickedTr.field === "curr" &&
                      clickedTr.day === "sunday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current[0]?.RevparMyProperty}
                          onChange={(e) => {
                            let lyValue = "";
                            reportDataRef.current = reportDataRef.current?.map(
                              (item, index) => {
                                if (index === 0) {
                                  item.RevparMyProperty = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let changeValue = item?.RevparMyPropertyChange
                                    ? parseFloat(item.RevparMyPropertyChange)
                                    : 0;
                                  lyValue = (
                                    (newValue * 100) /
                                    (100 + changeValue)
                                  )?.toFixed(2);
                                  item.RevparMyPropertyLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current[0].RevparMyProperty =
                              e.target.value;
                            tableDataRef.current[0].RevparMyPropertyLy =
                              lyValue;
                            setReportData(reportDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current[0]?.RevparMyProperty ? (
                        tableDataRef.current[0]?.RevparMyProperty
                      ) : (
                        0
                      )}
                    </td>
                    <td
                    // onDoubleClick={() => {
                    //   setClickedTr({
                    //     mainRow: "Revpar",
                    //     subRow: "myProperty",
                    //     field: "change",
                    //     day: "sunday",
                    //   });
                    // }}
                    >
                      {clickedTr.mainRow === "Revpar" &&
                      clickedTr.subRow === "myProperty" &&
                      clickedTr.field === "change" &&
                      clickedTr.day === "sunday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={
                            tableDataRef.current[0]?.RevparMyPropertyChange
                          }
                          onChange={(e) => {
                            let lyValue = "";
                            reportDataRef.current = reportDataRef.current?.map(
                              (item, index) => {
                                if (index === 0) {
                                  item.RevparMyPropertyChange = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let currValue = item?.RevparMyProperty
                                    ? parseFloat(item.RevparMyProperty)
                                    : 0;
                                  lyValue = (
                                    (currValue * 100) /
                                    (100 + newValue)
                                  )?.toFixed(2);
                                  item.RevparMyPropertyLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current[0].RevparMyPropertyChange =
                              e.target.value;
                            tableDataRef.current[0].RevparMyPropertyLy =
                              lyValue;
                            setReportData(reportDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current[0]?.RevparMyPropertyChange ? (
                        tableDataRef.current[0]?.RevparMyPropertyChange
                      ) : (
                        0
                      )}
                    </td>
                    <td className="border_right">
                      {tableDataRef.current[0]?.RevparMyPropertyLy
                        ? tableDataRef.current[0]?.RevparMyPropertyLy
                        : 0}
                    </td>

                    <td
                    // onDoubleClick={() => {
                    //   setClickedTr({
                    //     mainRow: "Revpar",
                    //     subRow: "myProperty",
                    //     field: "curr",
                    //     day: "monday",
                    //   });
                    // }}
                    >
                      {clickedTr.mainRow === "Revpar" &&
                      clickedTr.subRow === "myProperty" &&
                      clickedTr.field === "curr" &&
                      clickedTr.day === "monday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current[1]?.RevparMyProperty}
                          onChange={(e) => {
                            let lyValue = "";
                            reportDataRef.current = reportDataRef.current?.map(
                              (item, index) => {
                                if (index === 1) {
                                  item.RevparMyProperty = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let changeValue = item?.RevparMyPropertyChange
                                    ? parseFloat(item.RevparMyPropertyChange)
                                    : 0;
                                  lyValue = (
                                    (newValue * 100) /
                                    (100 + changeValue)
                                  )?.toFixed(2);
                                  item.RevparMyPropertyLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current[1].RevparMyProperty =
                              e.target.value;
                            tableDataRef.current[1].RevparMyPropertyLy =
                              lyValue;
                            setReportData(reportDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current[1]?.RevparMyProperty ? (
                        tableDataRef.current[1]?.RevparMyProperty
                      ) : (
                        0
                      )}
                    </td>
                    <td
                    // onDoubleClick={() => {
                    //   setClickedTr({
                    //     mainRow: "Revpar",
                    //     subRow: "myProperty",
                    //     field: "change",
                    //     day: "monday",
                    //   });
                    // }}
                    >
                      {clickedTr.mainRow === "Revpar" &&
                      clickedTr.subRow === "myProperty" &&
                      clickedTr.field === "change" &&
                      clickedTr.day === "monday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={
                            tableDataRef.current[1]?.RevparMyPropertyChange
                          }
                          onChange={(e) => {
                            let lyValue = "";
                            reportDataRef.current = reportDataRef.current?.map(
                              (item, index) => {
                                if (index === 1) {
                                  item.RevparMyPropertyChange = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let currValue = item?.RevparMyProperty
                                    ? parseFloat(item.RevparMyProperty)
                                    : 0;
                                  lyValue = (
                                    (currValue * 100) /
                                    (100 + newValue)
                                  )?.toFixed(2);
                                  item.RevparMyPropertyLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current[1].RevparMyPropertyChange =
                              e.target.value;
                            tableDataRef.current[1].RevparMyPropertyLy =
                              lyValue;
                            setReportData(reportDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current[1]?.RevparMyPropertyChange ? (
                        tableDataRef.current[1]?.RevparMyPropertyChange
                      ) : (
                        0
                      )}
                    </td>
                    <td className="border_right">
                      {tableDataRef.current[1]?.RevparMyPropertyLy
                        ? tableDataRef.current[1]?.RevparMyPropertyLy
                        : 0}
                    </td>

                    <td
                    // onDoubleClick={() => {
                    //   setClickedTr({
                    //     mainRow: "Revpar",
                    //     subRow: "myProperty",
                    //     field: "curr",
                    //     day: "tuseday",
                    //   });
                    // }}
                    >
                      {clickedTr.mainRow === "Revpar" &&
                      clickedTr.subRow === "myProperty" &&
                      clickedTr.field === "curr" &&
                      clickedTr.day === "tuseday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current[2]?.RevparMyProperty}
                          onChange={(e) => {
                            let lyValue = "";
                            reportDataRef.current = reportDataRef.current?.map(
                              (item, index) => {
                                if (index === 2) {
                                  item.RevparMyProperty = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let changeValue = item?.RevparMyPropertyChange
                                    ? parseFloat(item.RevparMyPropertyChange)
                                    : 0;
                                  lyValue = (
                                    (newValue * 100) /
                                    (100 + changeValue)
                                  )?.toFixed(2);
                                  item.RevparMyPropertyLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current[2].RevparMyProperty =
                              e.target.value;
                            tableDataRef.current[2].RevparMyPropertyLy =
                              lyValue;
                            setReportData(reportDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current[2]?.RevparMyProperty ? (
                        tableDataRef.current[2]?.RevparMyProperty
                      ) : (
                        0
                      )}
                    </td>
                    <td
                    // onDoubleClick={() => {
                    //   setClickedTr({
                    //     mainRow: "Revpar",
                    //     subRow: "myProperty",
                    //     field: "change",
                    //     day: "tuesday",
                    //   });
                    // }}
                    >
                      {clickedTr.mainRow === "Revpar" &&
                      clickedTr.subRow === "myProperty" &&
                      clickedTr.field === "change" &&
                      clickedTr.day === "tuesday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={
                            tableDataRef.current[2]?.RevparMyPropertyChange
                          }
                          onChange={(e) => {
                            let lyValue = "";
                            reportDataRef.current = reportDataRef.current?.map(
                              (item, index) => {
                                if (index === 2) {
                                  item.RevparMyPropertyChange = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let currValue = item?.RevparMyProperty
                                    ? parseFloat(item.RevparMyProperty)
                                    : 0;
                                  lyValue = (
                                    (currValue * 100) /
                                    (100 + newValue)
                                  )?.toFixed(2);
                                  item.RevparMyPropertyLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current[2].RevparMyPropertyChange =
                              e.target.value;
                            tableDataRef.current[2].RevparMyPropertyLy =
                              lyValue;
                            setReportData(reportDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current[2]?.RevparMyPropertyChange ? (
                        tableDataRef.current[2]?.RevparMyPropertyChange
                      ) : (
                        0
                      )}
                    </td>
                    <td className="border_right">
                      {tableDataRef.current[2]?.RevparMyPropertyLy
                        ? tableDataRef.current[2]?.RevparMyPropertyLy
                        : 0}
                    </td>

                    <td
                    // onDoubleClick={() => {
                    //   setClickedTr({
                    //     mainRow: "Revpar",
                    //     subRow: "myProperty",
                    //     field: "curr",
                    //     day: "wednesday",
                    //   });
                    // }}
                    >
                      {clickedTr.mainRow === "Revpar" &&
                      clickedTr.subRow === "myProperty" &&
                      clickedTr.field === "curr" &&
                      clickedTr.day === "wednesday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current[3]?.RevparMyProperty}
                          onChange={(e) => {
                            let lyValue = "";
                            reportDataRef.current = reportDataRef.current?.map(
                              (item, index) => {
                                if (index === 3) {
                                  item.RevparMyProperty = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let changeValue = item?.RevparMyPropertyChange
                                    ? parseFloat(item.RevparMyPropertyChange)
                                    : 0;
                                  lyValue = (
                                    (newValue * 100) /
                                    (100 + changeValue)
                                  )?.toFixed(2);
                                  item.RevparMyPropertyLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current[3].RevparMyProperty =
                              e.target.value;
                            tableDataRef.current[3].RevparMyPropertyLy =
                              lyValue;
                            setReportData(reportDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current[3]?.RevparMyProperty ? (
                        tableDataRef.current[3]?.RevparMyProperty
                      ) : (
                        0
                      )}
                    </td>
                    <td
                    // onDoubleClick={() => {
                    //   setClickedTr({
                    //     mainRow: "Revpar",
                    //     subRow: "myProperty",
                    //     field: "change",
                    //     day: "wednesday",
                    //   });
                    // }}
                    >
                      {clickedTr.mainRow === "Revpar" &&
                      clickedTr.subRow === "myProperty" &&
                      clickedTr.field === "change" &&
                      clickedTr.day === "wednesday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={
                            tableDataRef.current[3]?.RevparMyPropertyChange
                          }
                          onChange={(e) => {
                            let lyValue = "";
                            reportDataRef.current = reportDataRef.current?.map(
                              (item, index) => {
                                if (index === 3) {
                                  item.RevparMyPropertyChange = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let currValue = item?.RevparMyProperty
                                    ? parseFloat(item.RevparMyProperty)
                                    : 0;
                                  lyValue = (
                                    (currValue * 100) /
                                    (100 + newValue)
                                  )?.toFixed(2);
                                  item.RevparMyPropertyLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current[3].RevparMyPropertyChange =
                              e.target.value;
                            tableDataRef.current[3].RevparMyPropertyLy =
                              lyValue;
                            setReportData(reportDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current[3]?.RevparMyPropertyChange ? (
                        tableDataRef.current[3]?.RevparMyPropertyChange
                      ) : (
                        0
                      )}
                    </td>
                    <td className="border_right">
                      {tableDataRef.current[3]?.RevparMyPropertyLy
                        ? tableDataRef.current[3]?.RevparMyPropertyLy
                        : 0}
                    </td>

                    <td
                    // onDoubleClick={() => {
                    //   setClickedTr({
                    //     mainRow: "Revpar",
                    //     subRow: "myProperty",
                    //     field: "curr",
                    //     day: "thursday",
                    //   });
                    // }}
                    >
                      {clickedTr.mainRow === "Revpar" &&
                      clickedTr.subRow === "myProperty" &&
                      clickedTr.field === "curr" &&
                      clickedTr.day === "thursday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current[4]?.RevparMyProperty}
                          onChange={(e) => {
                            let lyValue = "";
                            reportDataRef.current = reportDataRef.current?.map(
                              (item, index) => {
                                if (index === 4) {
                                  item.RevparMyProperty = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let changeValue = item?.RevparMyPropertyChange
                                    ? parseFloat(item.RevparMyPropertyChange)
                                    : 0;
                                  lyValue = (
                                    (newValue * 100) /
                                    (100 + changeValue)
                                  )?.toFixed(2);
                                  item.RevparMyPropertyLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current[4].RevparMyProperty =
                              e.target.value;
                            tableDataRef.current[4].RevparMyPropertyLy =
                              lyValue;
                            setReportData(reportDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current[4]?.RevparMyProperty ? (
                        tableDataRef.current[4]?.RevparMyProperty
                      ) : (
                        0
                      )}
                    </td>
                    <td
                    // onDoubleClick={() => {
                    //   setClickedTr({
                    //     mainRow: "Revpar",
                    //     subRow: "myProperty",
                    //     field: "change",
                    //     day: "thursday",
                    //   });
                    // }}
                    >
                      {clickedTr.mainRow === "Revpar" &&
                      clickedTr.subRow === "myProperty" &&
                      clickedTr.field === "change" &&
                      clickedTr.day === "thursday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={
                            tableDataRef.current[4]?.RevparMyPropertyChange
                          }
                          onChange={(e) => {
                            let lyValue = "";
                            reportDataRef.current = reportDataRef.current?.map(
                              (item, index) => {
                                if (index === 4) {
                                  item.RevparMyPropertyChange = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let currValue = item?.RevparMyProperty
                                    ? parseFloat(item.RevparMyProperty)
                                    : 0;
                                  lyValue = (
                                    (currValue * 100) /
                                    (100 + newValue)
                                  )?.toFixed(2);
                                  item.RevparMyPropertyLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current[4].RevparMyPropertyChange =
                              e.target.value;
                            tableDataRef.current[4].RevparMyPropertyLy =
                              lyValue;
                            setReportData(reportDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current[4]?.RevparMyPropertyChange ? (
                        tableDataRef.current[4]?.RevparMyPropertyChange
                      ) : (
                        0
                      )}
                    </td>
                    <td className="border_right">
                      {tableDataRef.current[4]?.RevparMyPropertyLy
                        ? tableDataRef.current[4]?.RevparMyPropertyLy
                        : 0}
                    </td>

                    <td
                    // onDoubleClick={() => {
                    //   setClickedTr({
                    //     mainRow: "Revpar",
                    //     subRow: "myProperty",
                    //     field: "curr",
                    //     day: "friday",
                    //   });
                    // }}
                    >
                      {clickedTr.mainRow === "Revpar" &&
                      clickedTr.subRow === "myProperty" &&
                      clickedTr.field === "curr" &&
                      clickedTr.day === "friday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current[5]?.RevparMyProperty}
                          onChange={(e) => {
                            let lyValue = "";
                            reportDataRef.current = reportDataRef.current?.map(
                              (item, index) => {
                                if (index === 5) {
                                  item.RevparMyProperty = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let changeValue = item?.RevparMyPropertyChange
                                    ? parseFloat(item.RevparMyPropertyChange)
                                    : 0;
                                  lyValue = (
                                    (newValue * 100) /
                                    (100 + changeValue)
                                  )?.toFixed(2);
                                  item.RevparMyPropertyLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current[5].RevparMyProperty =
                              e.target.value;
                            tableDataRef.current[5].RevparMyPropertyLy =
                              lyValue;
                            setReportData(reportDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current[5]?.RevparMyProperty ? (
                        tableDataRef.current[5]?.RevparMyProperty
                      ) : (
                        0
                      )}
                    </td>
                    <td
                    // onDoubleClick={() => {
                    //   setClickedTr({
                    //     mainRow: "Revpar",
                    //     subRow: "myProperty",
                    //     field: "change",
                    //     day: "friday",
                    //   });
                    // }}
                    >
                      {clickedTr.mainRow === "Revpar" &&
                      clickedTr.subRow === "myProperty" &&
                      clickedTr.field === "change" &&
                      clickedTr.day === "friday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={
                            tableDataRef.current[5]?.RevparMyPropertyChange
                          }
                          onChange={(e) => {
                            let lyValue = "";
                            reportDataRef.current = reportDataRef.current?.map(
                              (item, index) => {
                                if (index === 5) {
                                  item.RevparMyPropertyChange = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let currValue = item?.RevparMyProperty
                                    ? parseFloat(item.RevparMyProperty)
                                    : 0;
                                  lyValue = (
                                    (currValue * 100) /
                                    (100 + newValue)
                                  )?.toFixed(2);
                                  item.RevparMyPropertyLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current[5].RevparMyPropertyChange =
                              e.target.value;
                            tableDataRef.current[5].RevparMyPropertyLy =
                              lyValue;
                            setReportData(reportDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current[5]?.RevparMyPropertyChange ? (
                        tableDataRef.current[5]?.RevparMyPropertyChange
                      ) : (
                        0
                      )}
                    </td>
                    <td className="border_right">
                      {tableDataRef.current[5]?.RevparMyPropertyLy
                        ? tableDataRef.current[5]?.RevparMyPropertyLy
                        : 0}
                    </td>

                    <td
                    // onDoubleClick={() => {
                    //   setClickedTr({
                    //     mainRow: "Revpar",
                    //     subRow: "myProperty",
                    //     field: "curr",
                    //     day: "saturday",
                    //   });
                    // }}
                    >
                      {clickedTr.mainRow === "Revpar" &&
                      clickedTr.subRow === "myProperty" &&
                      clickedTr.field === "curr" &&
                      clickedTr.day === "saturday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current[6]?.RevparMyProperty}
                          onChange={(e) => {
                            let lyValue = "";
                            reportDataRef.current = reportDataRef.current?.map(
                              (item, index) => {
                                if (index === 6) {
                                  item.RevparMyProperty = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let changeValue = item?.RevparMyPropertyChange
                                    ? parseFloat(item.RevparMyPropertyChange)
                                    : 0;
                                  lyValue = (
                                    (newValue * 100) /
                                    (100 + changeValue)
                                  )?.toFixed(2);
                                  item.RevparMyPropertyLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current[6].RevparMyProperty =
                              e.target.value;
                            tableDataRef.current[6].RevparMyPropertyLy =
                              lyValue;
                            setReportData(reportDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current[6]?.RevparMyProperty ? (
                        tableDataRef.current[6]?.RevparMyProperty
                      ) : (
                        0
                      )}
                    </td>
                    <td
                    // onDoubleClick={() => {
                    //   setClickedTr({
                    //     mainRow: "Revpar",
                    //     subRow: "myProperty",
                    //     field: "change",
                    //     day: "saturday",
                    //   });
                    // }}
                    >
                      {clickedTr.mainRow === "Revpar" &&
                      clickedTr.subRow === "myProperty" &&
                      clickedTr.field === "change" &&
                      clickedTr.day === "saturday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={
                            tableDataRef.current[6]?.RevparMyPropertyChange
                          }
                          onChange={(e) => {
                            let lyValue = "";
                            reportDataRef.current = reportDataRef.current?.map(
                              (item, index) => {
                                if (index === 6) {
                                  item.RevparMyPropertyChange = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let currValue = item?.RevparMyProperty
                                    ? parseFloat(item.RevparMyProperty)
                                    : 0;
                                  lyValue = (
                                    (currValue * 100) /
                                    (100 + newValue)
                                  )?.toFixed(2);
                                  item.RevparMyPropertyLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current[6].RevparMyPropertyChange =
                              e.target.value;
                            tableDataRef.current[6].RevparMyPropertyLy =
                              lyValue;
                            setReportData(reportDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current[6]?.RevparMyPropertyChange ? (
                        tableDataRef.current[6]?.RevparMyPropertyChange
                      ) : (
                        0
                      )}
                    </td>
                    <td className="border_right">
                      {tableDataRef.current[6]?.RevparMyPropertyLy
                        ? tableDataRef.current[6]?.RevparMyPropertyLy
                        : 0}
                    </td>
                    <td className="fontBold">
                      {tableDataRef.current[7]?.RevparMyProperty
                        ? tableDataRef.current[7]?.RevparMyProperty
                        : 0}
                    </td>
                  </tr>
                  <tr className="shadowOnHover">
                    <td className="border_right">Comp Set</td>
                    <td
                    // onDoubleClick={() => {
                    //   setClickedTr({
                    //     mainRow: "Revpar",
                    //     subRow: "compSet",
                    //     field: "curr",
                    //     day: "sunday",
                    //   });
                    // }}
                    >
                      {clickedTr.mainRow === "Revpar" &&
                      clickedTr.subRow === "compSet" &&
                      clickedTr.field === "curr" &&
                      clickedTr.day === "sunday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current[0]?.RevparCompset}
                          onChange={(e) => {
                            let lyValue = "";
                            reportDataRef.current = reportDataRef.current?.map(
                              (item, index) => {
                                if (index === 0) {
                                  item.RevparCompset = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let changeValue = item?.RevparCompsetChange
                                    ? parseFloat(item.RevparCompsetChange)
                                    : 0;
                                  lyValue = (
                                    (newValue * 100) /
                                    (100 + changeValue)
                                  )?.toFixed(2);
                                  item.RevparCompsetLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current[0].RevparCompset =
                              e.target.value;
                            tableDataRef.current[0].RevparCompsetLy = lyValue;
                            setReportData(reportDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current[0]?.RevparCompset ? (
                        tableDataRef.current[0]?.RevparCompset
                      ) : (
                        0
                      )}
                    </td>
                    <td
                    // onDoubleClick={() => {
                    //   setClickedTr({
                    //     mainRow: "Revpar",
                    //     subRow: "compSet",
                    //     field: "change",
                    //     day: "sunday",
                    //   });
                    // }}
                    >
                      {clickedTr.mainRow === "Revpar" &&
                      clickedTr.subRow === "compSet" &&
                      clickedTr.field === "change" &&
                      clickedTr.day === "sunday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current[0]?.RevparCompsetChange}
                          onChange={(e) => {
                            let lyValue = "";
                            reportDataRef.current = reportDataRef.current?.map(
                              (item, index) => {
                                if (index === 0) {
                                  item.RevparCompsetChange = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let currValue = item?.RevparCompset
                                    ? parseFloat(item.RevparCompset)
                                    : 0;
                                  lyValue = (
                                    (currValue * 100) /
                                    (100 + newValue)
                                  )?.toFixed(2);
                                  item.RevparCompsetLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current[0].RevparCompsetChange =
                              e.target.value;
                            tableDataRef.current[0].RevparCompsetLy = lyValue;
                            setReportData(reportDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current[0]?.RevparCompsetChange ? (
                        tableDataRef.current[0]?.RevparCompsetChange
                      ) : (
                        0
                      )}
                    </td>
                    <td className="border_right">
                      {tableDataRef.current[0]?.RevparCompsetLy
                        ? tableDataRef.current[0]?.RevparCompsetLy
                        : 0}
                    </td>

                    <td
                    // onDoubleClick={() => {
                    //   setClickedTr({
                    //     mainRow: "Revpar",
                    //     subRow: "compSet",
                    //     field: "curr",
                    //     day: "monday",
                    //   });
                    // }}
                    >
                      {clickedTr.mainRow === "Revpar" &&
                      clickedTr.subRow === "compSet" &&
                      clickedTr.field === "curr" &&
                      clickedTr.day === "monday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current[1]?.RevparCompset}
                          onChange={(e) => {
                            let lyValue = "";
                            reportDataRef.current = reportDataRef.current?.map(
                              (item, index) => {
                                if (index === 1) {
                                  item.RevparCompset = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let changeValue = item?.RevparCompsetChange
                                    ? parseFloat(item.RevparCompsetChange)
                                    : 0;
                                  lyValue = (
                                    (newValue * 100) /
                                    (100 + changeValue)
                                  )?.toFixed(2);
                                  item.RevparCompsetLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current[1].RevparCompset =
                              e.target.value;
                            tableDataRef.current[1].RevparCompsetLy = lyValue;
                            setReportData(reportDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current[1]?.RevparCompset ? (
                        tableDataRef.current[1]?.RevparCompset
                      ) : (
                        0
                      )}
                    </td>
                    <td
                    // onDoubleClick={() => {
                    //   setClickedTr({
                    //     mainRow: "Revpar",
                    //     subRow: "compSet",
                    //     field: "change",
                    //     day: "monday",
                    //   });
                    // }}
                    >
                      {clickedTr.mainRow === "Revpar" &&
                      clickedTr.subRow === "compSet" &&
                      clickedTr.field === "change" &&
                      clickedTr.day === "monday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current[1]?.RevparCompsetChange}
                          onChange={(e) => {
                            let lyValue = "";
                            reportDataRef.current = reportDataRef.current?.map(
                              (item, index) => {
                                if (index === 1) {
                                  item.RevparCompsetChange = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let currValue = item?.RevparCompset
                                    ? parseFloat(item.RevparCompset)
                                    : 0;
                                  lyValue = (
                                    (currValue * 100) /
                                    (100 + newValue)
                                  )?.toFixed(2);
                                  item.RevparCompsetLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current[1].RevparCompsetChange =
                              e.target.value;
                            tableDataRef.current[1].RevparCompsetLy = lyValue;
                            setReportData(reportDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current[1]?.RevparCompsetChange ? (
                        tableDataRef.current[1]?.RevparCompsetChange
                      ) : (
                        0
                      )}
                    </td>
                    <td className="border_right">
                      {tableDataRef.current[1]?.RevparCompsetLy
                        ? tableDataRef.current[1]?.RevparCompsetLy
                        : 0}
                    </td>

                    <td
                    // onDoubleClick={() => {
                    //   setClickedTr({
                    //     mainRow: "Revpar",
                    //     subRow: "compSet",
                    //     field: "curr",
                    //     day: "tuesday",
                    //   });
                    // }}
                    >
                      {clickedTr.mainRow === "Revpar" &&
                      clickedTr.subRow === "compSet" &&
                      clickedTr.field === "curr" &&
                      clickedTr.day === "tuesday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current[2]?.RevparCompset}
                          onChange={(e) => {
                            let lyValue = "";
                            reportDataRef.current = reportDataRef.current?.map(
                              (item, index) => {
                                if (index === 2) {
                                  item.RevparCompset = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let changeValue = item?.RevparCompsetChange
                                    ? parseFloat(item.RevparCompsetChange)
                                    : 0;
                                  lyValue = (
                                    (newValue * 100) /
                                    (100 + changeValue)
                                  )?.toFixed(2);
                                  item.RevparCompsetLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current[2].RevparCompset =
                              e.target.value;
                            tableDataRef.current[2].RevparCompsetLy = lyValue;
                            setReportData(reportDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current[2]?.RevparCompset ? (
                        tableDataRef.current[2]?.RevparCompset
                      ) : (
                        0
                      )}
                    </td>
                    <td
                    // onDoubleClick={() => {
                    //   setClickedTr({
                    //     mainRow: "Revpar",
                    //     subRow: "compSet",
                    //     field: "change",
                    //     day: "tuesday",
                    //   });
                    // }}
                    >
                      {clickedTr.mainRow === "Revpar" &&
                      clickedTr.subRow === "compSet" &&
                      clickedTr.field === "change" &&
                      clickedTr.day === "tuesday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current[2]?.RevparCompsetChange}
                          onChange={(e) => {
                            let lyValue = "";
                            reportDataRef.current = reportDataRef.current?.map(
                              (item, index) => {
                                if (index === 2) {
                                  item.RevparCompsetChange = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let currValue = item?.RevparCompset
                                    ? parseFloat(item.RevparCompset)
                                    : 0;
                                  lyValue = (
                                    (currValue * 100) /
                                    (100 + newValue)
                                  )?.toFixed(2);
                                  item.RevparCompsetLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current[2].RevparCompsetChange =
                              e.target.value;
                            tableDataRef.current[2].RevparCompsetLy = lyValue;
                            setReportData(reportDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current[2]?.RevparCompsetChange ? (
                        tableDataRef.current[2]?.RevparCompsetChange
                      ) : (
                        0
                      )}
                    </td>
                    <td className="border_right">
                      {tableDataRef.current[2]?.RevparCompsetLy
                        ? tableDataRef.current[2]?.RevparCompsetLy
                        : 0}
                    </td>

                    <td
                    // onDoubleClick={() => {
                    //   setClickedTr({
                    //     mainRow: "Revpar",
                    //     subRow: "compSet",
                    //     field: "curr",
                    //     day: "wednesday",
                    //   });
                    // }}
                    >
                      {clickedTr.mainRow === "Revpar" &&
                      clickedTr.subRow === "compSet" &&
                      clickedTr.field === "curr" &&
                      clickedTr.day === "wednesday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current[3]?.RevparCompset}
                          onChange={(e) => {
                            let lyValue = "";
                            reportDataRef.current = reportDataRef.current?.map(
                              (item, index) => {
                                if (index === 3) {
                                  item.RevparCompset = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let changeValue = item?.RevparCompsetChange
                                    ? parseFloat(item.RevparCompsetChange)
                                    : 0;
                                  lyValue = (
                                    (newValue * 100) /
                                    (100 + changeValue)
                                  )?.toFixed(2);
                                  item.RevparCompsetLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current[3].RevparCompset =
                              e.target.value;
                            tableDataRef.current[3].RevparCompsetLy = lyValue;
                            setReportData(reportDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current[3]?.RevparCompset ? (
                        tableDataRef.current[3]?.RevparCompset
                      ) : (
                        0
                      )}
                    </td>
                    <td
                    // onDoubleClick={() => {
                    //   setClickedTr({
                    //     mainRow: "Revpar",
                    //     subRow: "compSet",
                    //     field: "change",
                    //     day: "wednesday",
                    //   });
                    // }}
                    >
                      {clickedTr.mainRow === "Revpar" &&
                      clickedTr.subRow === "compSet" &&
                      clickedTr.field === "change" &&
                      clickedTr.day === "wednesday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current[3]?.RevparCompsetChange}
                          onChange={(e) => {
                            let lyValue = "";
                            reportDataRef.current = reportDataRef.current?.map(
                              (item, index) => {
                                if (index === 3) {
                                  item.RevparCompsetChange = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let currValue = item?.RevparCompset
                                    ? parseFloat(item.RevparCompset)
                                    : 0;
                                  lyValue = (
                                    (currValue * 100) /
                                    (100 + newValue)
                                  )?.toFixed(2);
                                  item.RevparCompsetLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current[3].RevparCompsetChange =
                              e.target.value;
                            tableDataRef.current[3].RevparCompsetLy = lyValue;
                            setReportData(reportDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current[3]?.RevparCompsetChange ? (
                        tableDataRef.current[3]?.RevparCompsetChange
                      ) : (
                        0
                      )}
                    </td>
                    <td className="border_right">
                      {tableDataRef.current[3]?.RevparCompsetLy
                        ? tableDataRef.current[3]?.RevparCompsetLy
                        : 0}
                    </td>

                    <td
                    // onDoubleClick={() => {
                    //   setClickedTr({
                    //     mainRow: "Revpar",
                    //     subRow: "compSet",
                    //     field: "curr",
                    //     day: "thursday",
                    //   });
                    // }}
                    >
                      {clickedTr.mainRow === "Revpar" &&
                      clickedTr.subRow === "compSet" &&
                      clickedTr.field === "curr" &&
                      clickedTr.day === "thursday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current[4]?.RevparCompset}
                          onChange={(e) => {
                            let lyValue = "";
                            reportDataRef.current = reportDataRef.current?.map(
                              (item, index) => {
                                if (index === 4) {
                                  item.RevparCompset = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let changeValue = item?.RevparCompsetChange
                                    ? parseFloat(item.RevparCompsetChange)
                                    : 0;
                                  lyValue = (
                                    (newValue * 100) /
                                    (100 + changeValue)
                                  )?.toFixed(2);
                                  item.RevparCompsetLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current[4].RevparCompset =
                              e.target.value;
                            tableDataRef.current[4].RevparCompsetLy = lyValue;
                            setReportData(reportDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current[4]?.RevparCompset ? (
                        tableDataRef.current[4]?.RevparCompset
                      ) : (
                        0
                      )}
                    </td>
                    <td
                    // onDoubleClick={() => {
                    //   setClickedTr({
                    //     mainRow: "Revpar",
                    //     subRow: "compSet",
                    //     field: "change",
                    //     day: "thursday",
                    //   });
                    // }}
                    >
                      {clickedTr.mainRow === "Revpar" &&
                      clickedTr.subRow === "compSet" &&
                      clickedTr.field === "change" &&
                      clickedTr.day === "thursday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current[4]?.RevparCompsetChange}
                          onChange={(e) => {
                            let lyValue = "";
                            reportDataRef.current = reportDataRef.current?.map(
                              (item, index) => {
                                if (index === 4) {
                                  item.RevparCompsetChange = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let currValue = item?.RevparCompset
                                    ? parseFloat(item.RevparCompset)
                                    : 0;
                                  lyValue = (
                                    (currValue * 100) /
                                    (100 + newValue)
                                  )?.toFixed(2);
                                  item.RevparCompsetLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current[4].RevparCompsetChange =
                              e.target.value;
                            tableDataRef.current[4].RevparCompsetLy = lyValue;
                            setReportData(reportDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current[4]?.RevparCompsetChange ? (
                        tableDataRef.current[4]?.RevparCompsetChange
                      ) : (
                        0
                      )}
                    </td>
                    <td className="border_right">
                      {tableDataRef.current[4]?.RevparCompsetLy
                        ? tableDataRef.current[4]?.RevparCompsetLy
                        : 0}
                    </td>

                    <td
                    // onDoubleClick={() => {
                    //   setClickedTr({
                    //     mainRow: "Revpar",
                    //     subRow: "compSet",
                    //     field: "curr",
                    //     day: "friday",
                    //   });
                    // }}
                    >
                      {clickedTr.mainRow === "Revpar" &&
                      clickedTr.subRow === "compSet" &&
                      clickedTr.field === "curr" &&
                      clickedTr.day === "friday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current[5]?.RevparCompset}
                          onChange={(e) => {
                            let lyValue = "";
                            reportDataRef.current = reportDataRef.current?.map(
                              (item, index) => {
                                if (index === 5) {
                                  item.RevparCompset = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let changeValue = item?.RevparCompsetChange
                                    ? parseFloat(item.RevparCompsetChange)
                                    : 0;
                                  lyValue = (
                                    (newValue * 100) /
                                    (100 + changeValue)
                                  )?.toFixed(2);
                                  item.RevparCompsetLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current[5].RevparCompset =
                              e.target.value;
                            tableDataRef.current[5].RevparCompsetLy = lyValue;
                            setReportData(reportDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current[5]?.RevparCompset ? (
                        tableDataRef.current[5]?.RevparCompset
                      ) : (
                        0
                      )}
                    </td>
                    <td
                    // onDoubleClick={() => {
                    //   setClickedTr({
                    //     mainRow: "Revpar",
                    //     subRow: "compSet",
                    //     field: "change",
                    //     day: "friday",
                    //   });
                    // }}
                    >
                      {clickedTr.mainRow === "Revpar" &&
                      clickedTr.subRow === "compSet" &&
                      clickedTr.field === "change" &&
                      clickedTr.day === "friday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current[5]?.RevparCompsetChange}
                          onChange={(e) => {
                            let lyValue = "";
                            reportDataRef.current = reportDataRef.current?.map(
                              (item, index) => {
                                if (index === 5) {
                                  item.RevparCompsetChange = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let currValue = item?.RevparCompset
                                    ? parseFloat(item.RevparCompset)
                                    : 0;
                                  lyValue = (
                                    (currValue * 100) /
                                    (100 + newValue)
                                  )?.toFixed(2);
                                  item.RevparCompsetLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current[5].RevparCompsetChange =
                              e.target.value;
                            tableDataRef.current[5].RevparCompsetLy = lyValue;
                            setReportData(reportDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current[5]?.RevparCompsetChange ? (
                        tableDataRef.current[5]?.RevparCompsetChange
                      ) : (
                        0
                      )}
                    </td>
                    <td className="border_right">
                      {tableDataRef.current[5]?.RevparCompsetLy
                        ? tableDataRef.current[5]?.RevparCompsetLy
                        : 0}
                    </td>

                    <td
                    // onDoubleClick={() => {
                    //   setClickedTr({
                    //     mainRow: "Revpar",
                    //     subRow: "compSet",
                    //     field: "curr",
                    //     day: "saturday",
                    //   });
                    // }}
                    >
                      {clickedTr.mainRow === "Revpar" &&
                      clickedTr.subRow === "compSet" &&
                      clickedTr.field === "curr" &&
                      clickedTr.day === "saturday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current[6]?.RevparCompset}
                          onChange={(e) => {
                            let lyValue = "";
                            reportDataRef.current = reportDataRef.current?.map(
                              (item, index) => {
                                if (index === 6) {
                                  item.RevparCompset = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let changeValue = item?.RevparCompsetChange
                                    ? parseFloat(item.RevparCompsetChange)
                                    : 0;
                                  lyValue = (
                                    (newValue * 100) /
                                    (100 + changeValue)
                                  )?.toFixed(2);
                                  item.RevparCompsetLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current[6].RevparCompset =
                              e.target.value;
                            tableDataRef.current[6].RevparCompsetLy = lyValue;
                            setReportData(reportDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current[6]?.RevparCompset ? (
                        tableDataRef.current[6]?.RevparCompset
                      ) : (
                        0
                      )}
                    </td>
                    <td
                    // onDoubleClick={() => {
                    //   setClickedTr({
                    //     mainRow: "Revpar",
                    //     subRow: "compSet",
                    //     field: "change",
                    //     day: "saturday",
                    //   });
                    // }}
                    >
                      {clickedTr.mainRow === "Revpar" &&
                      clickedTr.subRow === "compSet" &&
                      clickedTr.field === "change" &&
                      clickedTr.day === "saturday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current[6]?.RevparCompsetChange}
                          onChange={(e) => {
                            let lyValue = "";
                            reportDataRef.current = reportDataRef.current?.map(
                              (item, index) => {
                                if (index === 6) {
                                  item.RevparCompsetChange = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let currValue = item?.RevparCompset
                                    ? parseFloat(item.RevparCompset)
                                    : 0;
                                  lyValue = (
                                    (currValue * 100) /
                                    (100 + newValue)
                                  )?.toFixed(2);
                                  item.RevparCompsetLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current[6].RevparCompsetChange =
                              e.target.value;
                            tableDataRef.current[6].RevparCompsetLy = lyValue;
                            setReportData(reportDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current[6]?.RevparCompsetChange ? (
                        tableDataRef.current[6]?.RevparCompsetChange
                      ) : (
                        0
                      )}
                    </td>
                    <td className="border_right">
                      {tableDataRef.current[6]?.RevparCompsetLy
                        ? tableDataRef.current[6]?.RevparCompsetLy
                        : 0}
                    </td>
                    <td className="fontBold">
                      {tableDataRef.current[7]?.RevparCompset
                        ? tableDataRef.current[7]?.RevparCompset
                        : 0}
                    </td>
                  </tr>
                  <tr className="shadowOnHover">
                    <td className="border_right">Index (MPI)</td>
                    <td
                    // onDoubleClick={() => {
                    //   setClickedTr({
                    //     mainRow: "Revpar",
                    //     subRow: "index",
                    //     field: "curr",
                    //     day: "sunday",
                    //   });
                    // }}
                    >
                      {clickedTr.mainRow === "Revpar" &&
                      clickedTr.subRow === "index" &&
                      clickedTr.field === "curr" &&
                      clickedTr.day === "sunday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current[0]?.RevparIndex}
                          onChange={(e) => {
                            let lyValue = "";
                            reportDataRef.current = reportDataRef.current?.map(
                              (item, index) => {
                                if (index === 0) {
                                  item.RevparIndex = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let changeValue = item?.RevparIndexChange
                                    ? parseFloat(item.RevparIndexChange)
                                    : 0;
                                  lyValue = (
                                    (newValue * 100) /
                                    (100 + changeValue)
                                  )?.toFixed(2);
                                  item.RevparIndexLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current[0].RevparIndex =
                              e.target.value;
                            tableDataRef.current[0].RevparIndexLy = lyValue;
                            setReportData(reportDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current[0]?.RevparIndex ? (
                        tableDataRef.current[0]?.RevparIndex
                      ) : (
                        0
                      )}
                    </td>
                    <td
                    // onDoubleClick={() => {
                    //   setClickedTr({
                    //     mainRow: "Revpar",
                    //     subRow: "index",
                    //     field: "change",
                    //     day: "sunday",
                    //   });
                    // }}
                    >
                      {clickedTr.mainRow === "Revpar" &&
                      clickedTr.subRow === "index" &&
                      clickedTr.field === "change" &&
                      clickedTr.day === "sunday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current[0]?.RevparIndexChange}
                          onChange={(e) => {
                            let lyValue = "";
                            reportDataRef.current = reportDataRef.current?.map(
                              (item, index) => {
                                if (index === 0) {
                                  item.RevparIndexChange = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let currValue = item?.RevparIndex
                                    ? parseFloat(item.RevparIndex)
                                    : 0;
                                  lyValue = (
                                    (currValue * 100) /
                                    (100 + newValue)
                                  )?.toFixed(2);
                                  item.RevparIndexLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current[0].RevparIndexChange =
                              e.target.value;
                            tableDataRef.current[0].RevparIndexLy = lyValue;
                            setReportData(reportDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current[0]?.RevparIndexChange ? (
                        tableDataRef.current[0]?.RevparIndexChange
                      ) : (
                        0
                      )}
                    </td>
                    <td className="border_right">
                      {tableDataRef.current[0]?.RevparIndexLy
                        ? tableDataRef.current[0]?.RevparIndexLy
                        : 0}
                    </td>

                    <td
                    // onDoubleClick={() => {
                    //   setClickedTr({
                    //     mainRow: "Revpar",
                    //     subRow: "index",
                    //     field: "curr",
                    //     day: "monday",
                    //   });
                    // }}
                    >
                      {clickedTr.mainRow === "Revpar" &&
                      clickedTr.subRow === "index" &&
                      clickedTr.field === "curr" &&
                      clickedTr.day === "monday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current[1]?.RevparIndex}
                          onChange={(e) => {
                            let lyValue = "";
                            reportDataRef.current = reportDataRef.current?.map(
                              (item, index) => {
                                if (index === 1) {
                                  item.RevparIndex = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let changeValue = item?.RevparIndexChange
                                    ? parseFloat(item.RevparIndexChange)
                                    : 0;
                                  lyValue = (
                                    (newValue * 100) /
                                    (100 + changeValue)
                                  )?.toFixed(2);
                                  item.RevparIndexLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current[1].RevparIndex =
                              e.target.value;
                            tableDataRef.current[1].RevparIndexLy = lyValue;
                            setReportData(reportDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current[1]?.RevparIndex ? (
                        tableDataRef.current[1]?.RevparIndex
                      ) : (
                        0
                      )}
                    </td>
                    <td
                    // onDoubleClick={() => {
                    //   setClickedTr({
                    //     mainRow: "Revpar",
                    //     subRow: "index",
                    //     field: "change",
                    //     day: "monday",
                    //   });
                    // }}
                    >
                      {clickedTr.mainRow === "Revpar" &&
                      clickedTr.subRow === "index" &&
                      clickedTr.field === "change" &&
                      clickedTr.day === "monday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current[1]?.RevparIndexChange}
                          onChange={(e) => {
                            let lyValue = "";
                            reportDataRef.current = reportDataRef.current?.map(
                              (item, index) => {
                                if (index === 1) {
                                  item.RevparIndexChange = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let currValue = item?.RevparIndex
                                    ? parseFloat(item.RevparIndex)
                                    : 0;
                                  lyValue = (
                                    (currValue * 100) /
                                    (100 + newValue)
                                  )?.toFixed(2);
                                  item.RevparIndexLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current[1].RevparIndexChange =
                              e.target.value;
                            tableDataRef.current[1].RevparIndexLy = lyValue;
                            setReportData(reportDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current[1]?.RevparIndexChange ? (
                        tableDataRef.current[1]?.RevparIndexChange
                      ) : (
                        0
                      )}
                    </td>
                    <td className="border_right">
                      {tableDataRef.current[1]?.RevparIndexLy
                        ? tableDataRef.current[1]?.RevparIndexLy
                        : 0}
                    </td>

                    <td
                    // onDoubleClick={() => {
                    //   setClickedTr({
                    //     mainRow: "Revpar",
                    //     subRow: "index",
                    //     field: "curr",
                    //     day: "tuesday",
                    //   });
                    // }}
                    >
                      {clickedTr.mainRow === "Revpar" &&
                      clickedTr.subRow === "index" &&
                      clickedTr.field === "curr" &&
                      clickedTr.day === "tuesday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current[2]?.RevparIndex}
                          onChange={(e) => {
                            let lyValue = "";
                            reportDataRef.current = reportDataRef.current?.map(
                              (item, index) => {
                                if (index === 2) {
                                  item.RevparIndex = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let changeValue = item?.RevparIndexChange
                                    ? parseFloat(item.RevparIndexChange)
                                    : 0;
                                  lyValue = (
                                    (newValue * 100) /
                                    (100 + changeValue)
                                  )?.toFixed(2);
                                  item.RevparIndexLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current[2].RevparIndex =
                              e.target.value;
                            tableDataRef.current[2].RevparIndexLy = lyValue;
                            setReportData(reportDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current[2]?.RevparIndex ? (
                        tableDataRef.current[2]?.RevparIndex
                      ) : (
                        0
                      )}
                    </td>
                    <td
                    // onDoubleClick={() => {
                    //   setClickedTr({
                    //     mainRow: "Revpar",
                    //     subRow: "index",
                    //     field: "change",
                    //     day: "tuesday",
                    //   });
                    // }}
                    >
                      {clickedTr.mainRow === "Revpar" &&
                      clickedTr.subRow === "index" &&
                      clickedTr.field === "change" &&
                      clickedTr.day === "tuesday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current[2]?.RevparIndexChange}
                          onChange={(e) => {
                            let lyValue = "";
                            reportDataRef.current = reportDataRef.current?.map(
                              (item, index) => {
                                if (index === 2) {
                                  item.RevparIndexChange = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let currValue = item?.RevparIndex
                                    ? parseFloat(item.RevparIndex)
                                    : 0;
                                  lyValue = (
                                    (currValue * 100) /
                                    (100 + newValue)
                                  )?.toFixed(2);
                                  item.RevparIndexLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current[2].RevparIndexChange =
                              e.target.value;
                            tableDataRef.current[2].RevparIndexLy = lyValue;
                            setReportData(reportDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current[2]?.RevparIndexChange ? (
                        tableDataRef.current[2]?.RevparIndexChange
                      ) : (
                        0
                      )}
                    </td>
                    <td className="border_right">
                      {tableDataRef.current[2]?.RevparIndexLy
                        ? tableDataRef.current[2]?.RevparIndexLy
                        : 0}
                    </td>

                    <td
                    // onDoubleClick={() => {
                    //   setClickedTr({
                    //     mainRow: "Revpar",
                    //     subRow: "index",
                    //     field: "curr",
                    //     day: "wednesday",
                    //   });
                    // }}
                    >
                      {clickedTr.mainRow === "Revpar" &&
                      clickedTr.subRow === "index" &&
                      clickedTr.field === "curr" &&
                      clickedTr.day === "wednesday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current[3]?.RevparIndex}
                          onChange={(e) => {
                            let lyValue = "";
                            reportDataRef.current = reportDataRef.current?.map(
                              (item, index) => {
                                if (index === 3) {
                                  item.RevparIndex = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let changeValue = item?.RevparIndexChange
                                    ? parseFloat(item.RevparIndexChange)
                                    : 0;
                                  lyValue = (
                                    (newValue * 100) /
                                    (100 + changeValue)
                                  )?.toFixed(2);
                                  item.RevparIndexLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current[3].RevparIndex =
                              e.target.value;
                            tableDataRef.current[3].RevparIndexLy = lyValue;
                            setReportData(reportDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current[3]?.RevparIndex ? (
                        tableDataRef.current[3]?.RevparIndex
                      ) : (
                        0
                      )}
                    </td>
                    <td
                    // onDoubleClick={() => {
                    //   setClickedTr({
                    //     mainRow: "Revpar",
                    //     subRow: "index",
                    //     field: "change",
                    //     day: "wednesday",
                    //   });
                    // }}
                    >
                      {clickedTr.mainRow === "Revpar" &&
                      clickedTr.subRow === "index" &&
                      clickedTr.field === "change" &&
                      clickedTr.day === "wednesday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current[3]?.RevparIndexChange}
                          onChange={(e) => {
                            let lyValue = "";
                            reportDataRef.current = reportDataRef.current?.map(
                              (item, index) => {
                                if (index === 3) {
                                  item.RevparIndexChange = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let currValue = item?.RevparIndex
                                    ? parseFloat(item.RevparIndex)
                                    : 0;
                                  lyValue = (
                                    (currValue * 100) /
                                    (100 + newValue)
                                  )?.toFixed(2);
                                  item.RevparIndexLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current[3].RevparIndexChange =
                              e.target.value;
                            tableDataRef.current[3].RevparIndexLy = lyValue;
                            setReportData(reportDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current[3]?.RevparIndexChange ? (
                        tableDataRef.current[3]?.RevparIndexChange
                      ) : (
                        0
                      )}
                    </td>
                    <td className="border_right">
                      {tableDataRef.current[3]?.RevparIndexLy
                        ? tableDataRef.current[3]?.RevparIndexLy
                        : 0}
                    </td>

                    <td
                    // onDoubleClick={() => {
                    //   setClickedTr({
                    //     mainRow: "Revpar",
                    //     subRow: "index",
                    //     field: "curr",
                    //     day: "thursday",
                    //   });
                    // }}
                    >
                      {clickedTr.mainRow === "Revpar" &&
                      clickedTr.subRow === "index" &&
                      clickedTr.field === "curr" &&
                      clickedTr.day === "thursday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current[4]?.RevparIndex}
                          onChange={(e) => {
                            let lyValue = "";
                            reportDataRef.current = reportDataRef.current?.map(
                              (item, index) => {
                                if (index === 4) {
                                  item.RevparIndex = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let changeValue = item?.RevparIndexChange
                                    ? parseFloat(item.RevparIndexChange)
                                    : 0;
                                  lyValue = (
                                    (newValue * 100) /
                                    (100 + changeValue)
                                  )?.toFixed(2);
                                  item.RevparIndexLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current[4].RevparIndex =
                              e.target.value;
                            tableDataRef.current[4].RevparIndexLy = lyValue;
                            setReportData(reportDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current[4]?.RevparIndex ? (
                        tableDataRef.current[4]?.RevparIndex
                      ) : (
                        0
                      )}
                    </td>
                    <td
                    // onDoubleClick={() => {
                    //   setClickedTr({
                    //     mainRow: "Revpar",
                    //     subRow: "index",
                    //     field: "change",
                    //     day: "thursday",
                    //   });
                    // }}
                    >
                      {clickedTr.mainRow === "Revpar" &&
                      clickedTr.subRow === "index" &&
                      clickedTr.field === "change" &&
                      clickedTr.day === "thursday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current[4]?.RevparIndexChange}
                          onChange={(e) => {
                            let lyValue = "";
                            reportDataRef.current = reportDataRef.current?.map(
                              (item, index) => {
                                if (index === 4) {
                                  item.RevparIndexChange = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let currValue = item?.RevparIndex
                                    ? parseFloat(item.RevparIndex)
                                    : 0;
                                  lyValue = (
                                    (currValue * 100) /
                                    (100 + newValue)
                                  )?.toFixed(2);
                                  item.RevparIndexLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current[4].RevparIndexChange =
                              e.target.value;
                            tableDataRef.current[4].RevparIndexLy = lyValue;
                            setReportData(reportDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current[4]?.RevparIndexChange ? (
                        tableDataRef.current[4]?.RevparIndexChange
                      ) : (
                        0
                      )}
                    </td>
                    <td className="border_right">
                      {tableDataRef.current[4]?.RevparIndexLy
                        ? tableDataRef.current[4]?.RevparIndexLy
                        : 0}
                    </td>

                    <td
                    // onDoubleClick={() => {
                    //   setClickedTr({
                    //     mainRow: "Revpar",
                    //     subRow: "index",
                    //     field: "curr",
                    //     day: "friday",
                    //   });
                    // }}
                    >
                      {clickedTr.mainRow === "Revpar" &&
                      clickedTr.subRow === "index" &&
                      clickedTr.field === "curr" &&
                      clickedTr.day === "friday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current[5]?.RevparIndex}
                          onChange={(e) => {
                            let lyValue = "";
                            reportDataRef.current = reportDataRef.current?.map(
                              (item, index) => {
                                if (index === 5) {
                                  item.RevparIndex = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let changeValue = item?.RevparIndexChange
                                    ? parseFloat(item.RevparIndexChange)
                                    : 0;
                                  lyValue = (
                                    (newValue * 100) /
                                    (100 + changeValue)
                                  )?.toFixed(2);
                                  item.RevparIndexLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current[5].RevparIndex =
                              e.target.value;
                            tableDataRef.current[5].RevparIndexLy = lyValue;
                            setReportData(reportDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current[5]?.RevparIndex ? (
                        tableDataRef.current[5]?.RevparIndex
                      ) : (
                        0
                      )}
                    </td>
                    <td
                    // onDoubleClick={() => {
                    //   setClickedTr({
                    //     mainRow: "Revpar",
                    //     subRow: "index",
                    //     field: "change",
                    //     day: "friday",
                    //   });
                    // }}
                    >
                      {clickedTr.mainRow === "Revpar" &&
                      clickedTr.subRow === "index" &&
                      clickedTr.field === "change" &&
                      clickedTr.day === "friday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current[5]?.RevparIndexChange}
                          onChange={(e) => {
                            let lyValue = "";
                            reportDataRef.current = reportDataRef.current?.map(
                              (item, index) => {
                                if (index === 5) {
                                  item.RevparIndexChange = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let currValue = item?.RevparIndex
                                    ? parseFloat(item.RevparIndex)
                                    : 0;
                                  lyValue = (
                                    (currValue * 100) /
                                    (100 + newValue)
                                  )?.toFixed(2);
                                  item.RevparIndexLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current[5].RevparIndexChange =
                              e.target.value;
                            tableDataRef.current[5].RevparIndexLy = lyValue;
                            setReportData(reportDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current[5]?.RevparIndexChange ? (
                        tableDataRef.current[5]?.RevparIndexChange
                      ) : (
                        0
                      )}
                    </td>
                    <td className="border_right">
                      {tableDataRef.current[5]?.RevparIndexLy
                        ? tableDataRef.current[5]?.RevparIndexLy
                        : 0}
                    </td>

                    <td
                    // onDoubleClick={() => {
                    //   setClickedTr({
                    //     mainRow: "Revpar",
                    //     subRow: "index",
                    //     field: "curr",
                    //     day: "saturday",
                    //   });
                    // }}
                    >
                      {clickedTr.mainRow === "Revpar" &&
                      clickedTr.subRow === "index" &&
                      clickedTr.field === "curr" &&
                      clickedTr.day === "saturday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current[6]?.RevparIndex}
                          onChange={(e) => {
                            let lyValue = "";
                            reportDataRef.current = reportDataRef.current?.map(
                              (item, index) => {
                                if (index === 6) {
                                  item.RevparIndex = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let changeValue = item?.RevparIndexChange
                                    ? parseFloat(item.RevparIndexChange)
                                    : 0;
                                  lyValue = (
                                    (newValue * 100) /
                                    (100 + changeValue)
                                  )?.toFixed(2);
                                  item.RevparIndexLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current[6].RevparIndex =
                              e.target.value;
                            tableDataRef.current[6].RevparIndexLy = lyValue;
                            setReportData(reportDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current[6]?.RevparIndex ? (
                        tableDataRef.current[6]?.RevparIndex
                      ) : (
                        0
                      )}
                    </td>
                    <td
                    // onDoubleClick={() => {
                    //   setClickedTr({
                    //     mainRow: "Revpar",
                    //     subRow: "index",
                    //     field: "change",
                    //     day: "saturday",
                    //   });
                    // }}
                    >
                      {clickedTr.mainRow === "Revpar" &&
                      clickedTr.subRow === "index" &&
                      clickedTr.field === "change" &&
                      clickedTr.day === "saturday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current[6]?.RevparIndexChange}
                          onChange={(e) => {
                            let lyValue = "";
                            reportDataRef.current = reportDataRef.current?.map(
                              (item, index) => {
                                if (index === 6) {
                                  item.RevparIndexChange = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let currValue = item?.RevparIndex
                                    ? parseFloat(item.RevparIndex)
                                    : 0;
                                  lyValue = (
                                    (currValue * 100) /
                                    (100 + newValue)
                                  )?.toFixed(2);
                                  item.RevparIndexLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current[6].RevparIndexChange =
                              e.target.value;
                            tableDataRef.current[6].RevparIndexLy = lyValue;
                            setReportData(reportDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current[6]?.RevparIndexChange ? (
                        tableDataRef.current[6]?.RevparIndexChange
                      ) : (
                        0
                      )}
                    </td>
                    <td className="border_right">
                      {tableDataRef.current[6]?.RevparIndexLy
                        ? tableDataRef.current[6]?.RevparIndexLy
                        : 0}
                    </td>
                    <td className="fontBold">
                      {tableDataRef.current[7]?.RevparIndex
                        ? tableDataRef.current[7]?.RevparIndex
                        : 0}
                    </td>
                  </tr>
                </>
              ) : (
                <tr>
                  <td>No Data Found</td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </div>

      <Col xl={12} className="mt-2">
        <Row>
          <Col xl={4}>
            <div className="mt-2">
              <h6>Occupancy</h6>
              {loading ? (
                <Loader />
              ) : (
                <Col xl={12}>
                  {occChartData ? (
                    <div className="snapShotBarWithLineChartInner">
                      <Bar
                        data={occChartData}
                        options={occChartOption}
                        style={{ height: "400px" }}
                      />
                    </div>
                  ) : (
                    <div className="snapShotBarWithLineChartInner text-center">
                      No Data Found
                    </div>
                  )}
                </Col>
              )}
            </div>
          </Col>
          <Col xl={4}>
            <div className="mt-2">
              <h6>ADR</h6>
              {loading ? (
                <Loader />
              ) : (
                <Col xl={12}>
                  {adrChartData ? (
                    <div className="snapShotBarWithLineChartInner">
                      <Bar
                        data={adrChartData}
                        options={occChartOption}
                        style={{ height: "400px" }}
                      />
                    </div>
                  ) : (
                    <div className="snapShotBarWithLineChartInner text-center">
                      No Data Found
                    </div>
                  )}
                </Col>
              )}
            </div>
          </Col>
          <Col xl={4}>
            <div className="mt-2">
              <h6>RevPAR</h6>
              {loading ? (
                <Loader />
              ) : (
                <Col xl={12}>
                  {revParChartData ? (
                    <div className="snapShotBarWithLineChartInner">
                      <Bar
                        data={revParChartData}
                        options={occChartOption}
                        style={{ height: "400px" }}
                      />
                    </div>
                  ) : (
                    <div className="snapShotBarWithLineChartInner text-center">
                      No Data Found
                    </div>
                  )}
                </Col>
              )}
            </div>
          </Col>
        </Row>
      </Col>

      {showNoteSidebar && (
        <AddWidgetModal
          showModal={showNoteSidebar}
          closeModal={closeNoteSideBar}
          widgetId={para?.widgetId}
          propertyId={propertyDetailFromRTK?.propertyid}
          rowTitle={para?.rowTitle}
        />
      )}
      {widgetModal && (
        <WidgetDetailModal
          showModal={widgetModal}
          closeModal={closeWidgetModal}
          data={widgetDetailRef.current}
        />
      )}
    </div>
  );
};

export default WeeklyStar;
