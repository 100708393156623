import React from "react";
import Header from "../Header/Header";
import HomeSidebar from "../HomeSidebar/HomeSidebar";
import Loader from "../Loader/Loader";
import { commonService } from "../../utils/commonService";
import { Navigate } from "react-router-dom";

const Dashboard = React.lazy(() => import("./Dashboard"));

const LayoutDashboard = () => {
  const token = commonService.getItem("token");
  return (
    <div className="mainRoot">
      <Header />
      <HomeSidebar />
      <main className="page-body">
        {token ? (
          <React.Suspense fallback={<Loader />}>
            <Dashboard />
          </React.Suspense>
        ) : (
          <Navigate to="/" replace />
        )}
      </main>
    </div>
  );
};

export default LayoutDashboard;
