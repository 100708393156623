import React, { useEffect, useRef, useState } from "react";
import { Row, Form, Col, Button, Container } from "react-bootstrap";
import pdfIcon from "../../../Assets/Images/CRM/pdf.svg";
import { API_URL } from "../../../utils/apiRoutes";
import { validationRule } from "../../../utils/constant";
import { hotelListCommon } from "../Hotels/HotelListCommon";
import { AccountListCommon } from "../Contacts/AccountListCommon";
import { checkValidation } from "../../../utils/helper";
import { apiCall } from "../../../utils/axiosService";
import { apiResponse } from "../../../utils/apiResponse";
import filterIcon from "../../../Assets/Images/CRM/filter.png";
import { successToastMessage } from "../../../utils/toastMessage";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { useSelector } from "react-redux";
import { commonService } from "../../../utils/commonService";
import Loader from "../../Loader/Loader";
import { Link } from "react-router-dom";

const WeeklyReport = ({ title }) => {
  const [moduleWisePropertyDropDown, setModuleWisePropertyDropDown] = useState(
    []
  );
  const [selectedModuleWiseProperty, setSelectedModuleWiseProperty] = useState(
    []
  );
  const userIdList = useRef("");
  const setPdfRef = useRef("");
  const [showPdf, setShowPdf] = useState(false);
  const [loading, setLoading] = useState(false);
  const [hotelList, setHotelList] = useState(null);
  const [showAllReport, setshowAllReport] = useState(false);
  const isDefaultApiCalledRef = useRef(false);
  const handleshowAllReport = () => {
    setshowAllReport(!showAllReport);
  };
  const [errorMessage, setErrorMessage] = useState(false);
  const crmPropertyDetailFromRTK = useSelector(
    (store) => store.headerProperty.crmPropertyDetail
  );

  const userDetails = commonService.getLoginUserData();

  let propertyIdPayload = "";
  if (crmPropertyDetailFromRTK) {
    if (crmPropertyDetailFromRTK?.propertyid === "") {
      let propertyPayload = crmPropertyDetailFromRTK?.allPropertyId;
      propertyIdPayload = propertyPayload.slice(0, -1);
    } else {
      propertyIdPayload = crmPropertyDetailFromRTK?.propertyid?.toString();
    }
  }

  let startDate = new Date();
  let endDate = new Date(startDate.getTime() - 7 * 24 * 60 * 60 * 1000);

  let validationObj = {
    startDate: endDate,
    endDate: startDate,
    propertyID: "",
    userID: "",
    isIncludeActivity: false,

    validationRule: [
      {
        fieldName: "startDate",
        rule: validationRule.required,
        message: "Start Date is required",
      },
      {
        fieldName: "endDate",
        rule: validationRule.required,
        message: "End Date is required",
      },
      {
        fieldName: "propertyID",
        rule: validationRule.required,
        message: "Property Id is required",
      },
      {
        fieldName: "userID",
        rule: validationRule.required,
        message: "User selection   required",
      },
    ],
    errorObj: {},
    isValidate: true,
  };
  const [weeklyReportData, setWeeklyReportData] = useState(validationObj);
  const weeklyReportDataRef = useRef(weeklyReportData);

  const handleDateChange = (dates) => {
    const [start, end] = dates;
    weeklyReportDataRef.current.startDate = start;
    weeklyReportDataRef.current.endDate = end;
    setWeeklyReportData({
      ...weeklyReportData,
      startDate: start,
      endDate: end,
    });
  };

  const getHotelList = async () => {
    let response = await hotelListCommon(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.CRM +
          API_URL.PROPERTY_TERM.GET_PROPERTY_LIST_BY_USER_ID,
        body: {
          id: userDetails?.userid,
        },
      },
      false
    );

    if (response?.isValidate) {
      setHotelList(response?.data);

      if (crmPropertyDetailFromRTK?.propertyid !== "") {
        weeklyReportDataRef.current.propertyID =
          crmPropertyDetailFromRTK?.propertyid;
        setWeeklyReportData(weeklyReportDataRef.current);
      }
    }
  };

  const getAccountRuleList = async (propertyId) => {
    let accountResponse = await AccountListCommon(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.UserManagement +
          API_URL.USER_ROLE_PROPERTY.GET_USER_LIST_BY_MODULE_MULTI_PROPERTY,
        body: {
          propertyID: weeklyReportData.propertyID?.toString(),
          modulename: "crm",
        },
      },
      false
    );

    if (accountResponse?.isValidate) {
      let userDropDownList = [],
        userIds = [];
      accountResponse?.data?.map((item) => {
        userDropDownList = [
          ...userDropDownList,
          {
            label: item?.displayname,
            value: item?.userid,
          },
        ];
        userIds = [...userIds, item?.userid];
      });
      setModuleWisePropertyDropDown(userDropDownList);
      setSelectedModuleWiseProperty(userDropDownList);
      weeklyReportDataRef.current.userID = userIds?.join(",");
      setWeeklyReportData(weeklyReportDataRef.current);
    }
  };

  const handleUserSelection = (e) => {
    if (e?.length <= 0) {
      setSelectedModuleWiseProperty([]);
    }
    setSelectedModuleWiseProperty(e);
    let propertyList = [];
    e?.map((item) => {
      propertyList = [...propertyList, item?.value?.toString()];
    });
    userIdList.current = propertyList?.join(",");
    weeklyReportDataRef.current.userID = userIdList.current;
    setWeeklyReportData(weeklyReportDataRef.current);
  };

  const iframeloader = () => {
    setLoading(false);
  };

  const GenerateReportbtn = async () => {
    setShowPdf(false);
    setLoading(true);
    let validation = checkValidation(weeklyReportData);

    if (!validation.isValidate) {
      weeklyReportDataRef.current = validation;
      setWeeklyReportData(validation);
      setLoading(false);
      return;
    }

    let weeklyReport = await apiCall(
      {
        method: "POST",
        url: API_URL.BASE_API_URL.CRM + API_URL.CRM_REPORT.WEEKLY_REPORT,
        body: {
          propertyID: weeklyReportData.propertyID?.toString(),
          startDate: commonService.getDateInDBFormat(
            weeklyReportData.startDate
          ),
          endDate: commonService.getDateInDBFormat(weeklyReportData.endDate),
          userID: userIdList.current,
          isIncludeActivity: weeklyReportData.isIncludeActivity,
        },
      },
      false
    );

    let response = apiResponse(false, weeklyReport);

    if (response?.isValidate) {
      isDefaultApiCalledRef.current = true;
      // setLoading(false);
      // Exportpdf(response.data);
      setshowAllReport(false);
      setErrorMessage(false);
      // successToastMessage(response?.message);
      setPdfRef.current =
        process.env.REACT_APP_APIBASEPATH_CRM_REPORT_PDF + response.data.data;
      setShowPdf(true);
    }

    if (!response?.isValidate) {
      isDefaultApiCalledRef.current = true;
      setshowAllReport(true);
      setLoading(false);
      setErrorMessage(true);
    }
  };

  useEffect(() => {
    if (weeklyReportData.propertyID !== "") {
      if (!isDefaultApiCalledRef.current && weeklyReportData.userID !== "") {
        GenerateReportbtn();
      }
    }
  }, [weeklyReportData.propertyID, weeklyReportData.userID]);

  // useEffect(() => {
  //   getHotelList();
  // }, []);
  useEffect(() => {
    if (crmPropertyDetailFromRTK?.propertyid === "") {
      isDefaultApiCalledRef.current = true;
      setshowAllReport(true);
    }

    getHotelList();
  }, []);

  useEffect(() => {
    if (weeklyReportData.propertyID !== "") {
      getAccountRuleList();
    }
  }, [weeklyReportData.propertyID]);

  return (
    <div className="all-reports-main ToolsDisplacementCalcMain">
      <Container fluid>
        <Row>
          <Col xl={12}>
            <div className="ProspectDashboardTitleWraper  d-flex justify-content-between align-items-center marginBottom20">
              <h2 className="d-flex align-items-center gap-1">
                <Link to="/crm/dashboard">Dashboard - </Link>{" "}
                <span className="tab-title-main">{title}</span>
              </h2>
              <div className="heading-menu-btns">
                <img
                  src={filterIcon}
                  alt="filterIcon"
                  title="Filter Report"
                  className="cursorPointer report-filter"
                  onClick={handleshowAllReport}
                />
              </div>
            </div>
          </Col>
        </Row>

        {showAllReport && (
          <div className="displacementCalcWrapper width400">
            <div className="from-groups no-border-break mb-3 small-height-select">
              <Form.Label>Select Property</Form.Label>
              <Form.Select
                aria-label="Default select example"
                value={weeklyReportData?.propertyID}
                onChange={(e) => {
                  weeklyReportDataRef.current.propertyID = e.target.value;
                  setWeeklyReportData({
                    ...weeklyReportData,
                    propertyID: e.target.value,
                  });
                }}
              >
                <option value="">Select Property</option>
                {hotelList?.map((item, index) => (
                  <option key={index} value={item?.propertyid}>
                    {item?.propertyname}
                  </option>
                ))}
              </Form.Select>

              {weeklyReportData?.errorObj?.propertyID &&
                weeklyReportData?.propertyID === "" && (
                  <small className="text-danger">
                    {weeklyReportData?.errorObj?.propertyID}
                  </small>
                )}
            </div>
            <div className="form-check mb-2">
              <input
                type="checkbox"
                className="form-check-input"
                name="isIncludeActivity"
                checked={weeklyReportData?.isIncludeActivity}
                onChange={(e) => {
                  weeklyReportDataRef.current.isIncludeActivity =
                    e.target.checked;
                  setWeeklyReportData({
                    ...weeklyReportData,
                    isIncludeActivity: e.target.checked,
                  });
                }}
                id="Option7"
              />
              <Form.Check.Label htmlFor="Option7" className="label-radio">
                Include Activity
              </Form.Check.Label>
            </div>

            <div className="from-groups no-border-break mb-2 small-height-select">
              <div className="form-groups small-height-select">
                <Form.Label>Select User</Form.Label>
                <Select
                  isMulti
                  value={selectedModuleWiseProperty}
                  options={moduleWisePropertyDropDown}
                  onChange={(e) => {
                    handleUserSelection(e);
                  }}
                />
                {weeklyReportData?.errorObj?.userID &&
                  weeklyReportData?.userID === "" && (
                    <small className="text-danger">
                      {weeklyReportData?.errorObj?.userID}
                    </small>
                  )}
              </div>
            </div>
            <div className="startFrom from-groups no-border-break mb-4 meghaDatePicker">
              <Form.Label htmlFor="startDate">Date Range</Form.Label>
              <DatePicker
                onChange={handleDateChange}
                startDate={weeklyReportData.startDate}
                endDate={weeklyReportData.endDate}
                selectsRange
                monthsShown={2}
              />
            </div>

            <div className="d-flex align-items-center justify-content-end gap-3">
              {loading ? (
                <button
                  className="default-btn primary-btn"
                  type="button"
                  disabled
                >
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Loading...
                </button>
              ) : (
                <button
                  className="default-btn primary-btn d-flex gap-1 align-items-center"
                  onClick={() => {
                    GenerateReportbtn();
                  }}
                >
                  <span className="revpak-icon">
                    <img src={pdfIcon} alt="" />
                  </span>
                  <span>Generate Report</span>
                </button>
              )}
            </div>
          </div>
        )}
        <p className="text-center">{errorMessage && " No Record Found"}</p>
        {loading && <Loader />}
        <div className="pdf-is-showing-here mt-3">
          {showPdf && setPdfRef.current && (
            <iframe
              onLoad={iframeloader}
              title="Report Doc"
              src={setPdfRef.current}
              width="100%"
              height="800px"
            />
          )}
        </div>
        {/* {loading ? (
          <Loader />
        ) : (
          <div className="pdf-is-showing-here mt-3">
            {showPdf && setPdfRef.current && (
              <iframe
                title="Report Doc"
                src={setPdfRef.current}
                width="100%"
                height="800px"
              />
            )}
          </div>
        )} */}
      </Container>
    </div>
  );
};

export default WeeklyReport;
