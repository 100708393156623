import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Container, Row, Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import { apiCall } from "../../../../utils/axiosService";
import { commonService } from "../../../../utils/commonService";
import { apiResponse } from "../../../../utils/apiResponse";
import { API_URL } from "../../../../utils/apiRoutes";
import { Line } from "react-chartjs-2";
import DatePicker from "react-datepicker";
import {
  Chart,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  Filler,
} from "chart.js";
import moment from "moment";
import Loader from "../../../Loader/Loader";
import { FixColumn } from "../../../../utils/dataTable";
import { compName } from "../../../../utils/constant";
import NoteIcon from "../../../../Assets/Images/CRM/notes.svg";
import downloadIcon from "../../../../Assets/Images/Operation/downloadIcon.svg";
import infoIcon from "../../../../Assets/Images/Action Icons/infoIcon..svg";
import emailIcon from "../../../../Assets/Images/Email.svg";
import { useParams } from "react-router-dom";
import AddWidgetModal from "../../AddWidgetModal";
import WidgetDetailModal from "../../WidgetDetailModal";
import Comments from "../Comments/Comment";

const BookingPaceComparison = ({
  setCompDataFromChild,
  rowIndex,
  colIndex,
  api,
  para,
  setting,
}) => {
  const propertyDetailFromRTK = useSelector(
    (store) => store.headerProperty.propertyDetail
  );
  const [chartData, setChartData] = useState(null);
  const [date, setDate] = useState(
    para?.asOfDate ? new Date(para?.asOfDate) : new Date()
  );
  const [loading, setLoading] = useState(false);
  const [apiData, setApiData] = useState(null);
  const [tableData, setTableData] = useState(null);
  const totalRef = useRef();

  const [showNoteSidebar, setShowNoteSidebar] = useState(false);
  const [widgetModal, setWidgetModal] = useState(false);
  const widgetDetailRef = useRef();
  const defaultAsOfDateFromRTK = useSelector(
    (store) => store.headerProperty.defaultAsOfDate
  );
  const { snapshottitle, propertyCode, snapshotId } = useParams();
  const maxValueForInventoryRef = useRef();
  const closeWidgetModal = () => {
    setWidgetModal(false);
  };
  const closeNoteSideBar = () => {
    setShowNoteSidebar(false);
  };

  let dummyArr = Array.from(Array(91).keys());

  const getChartData = async () => {
    setLoading(true);
    let chartDetail = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.SnapShot +
          API_URL.BOOKING_PACE.CANCELLATION_CHART,
        body: {
          AsOfDate: para?.asOfDate,
          propertyid: propertyDetailFromRTK?.propertyid,
          SelectedDate: commonService.getDateForNode(date),
          propertyCode: para?.propertyCode,
        },
      },
      false
    );

    let response = apiResponse(false, chartDetail);

    if (response?.isValidate) {
      let mainTable = [],
        chartTable = [],
        weekWiseTable = {
          Sunday: { count: 0, otb: 0 },
          Monday: { count: 0, otb: 0 },
          Tuesday: { count: 0, otb: 0 },
          Wednesday: { count: 0, otb: 0 },
          Thursday: { count: 0, otb: 0 },
          Friday: { count: 0, otb: 0 },
          Saturday: { count: 0, otb: 0 },
        },
        dataFromApi = response?.data?.data?.result,
        selectedDate = new Date(date),
        maxvalueForChart = 0;
      let total = {
        dayCount: 0,
        pu_90: 0,
        pu_89: 0,
        pu_88: 0,
        pu_87: 0,
        pu_86: 0,
        pu_85: 0,
        pu_84: 0,
        pu_83: 0,
        pu_82: 0,
        pu_81: 0,
        pu_80: 0,
        pu_79: 0,
        pu_78: 0,
        pu_77: 0,
        pu_76: 0,
        pu_75: 0,
        pu_74: 0,
        pu_73: 0,
        pu_72: 0,
        pu_71: 0,
        pu_70: 0,
        pu_69: 0,
        pu_68: 0,
        pu_67: 0,
        pu_66: 0,
        pu_65: 0,
        pu_64: 0,
        pu_63: 0,
        pu_62: 0,
        pu_61: 0,
        pu_60: 0,
        pu_59: 0,
        pu_58: 0,
        pu_57: 0,
        pu_56: 0,
        pu_55: 0,
        pu_54: 0,
        pu_53: 0,
        pu_52: 0,
        pu_51: 0,
        pu_50: 0,
        pu_49: 0,
        pu_48: 0,
        pu_47: 0,
        pu_46: 0,
        pu_45: 0,
        pu_44: 0,
        pu_43: 0,
        pu_42: 0,
        pu_41: 0,
        pu_40: 0,
        pu_39: 0,
        pu_38: 0,
        pu_37: 0,
        pu_36: 0,
        pu_35: 0,
        pu_34: 0,
        pu_33: 0,
        pu_32: 0,
        pu_31: 0,
        pu_30: 0,
        pu_29: 0,
        pu_28: 0,
        pu_27: 0,
        pu_26: 0,
        pu_25: 0,
        pu_24: 0,
        pu_23: 0,
        pu_22: 0,
        pu_21: 0,
        pu_20: 0,
        pu_19: 0,
        pu_18: 0,
        pu_17: 0,
        pu_16: 0,
        pu_15: 0,
        pu_14: 0,
        pu_13: 0,
        pu_12: 0,
        pu_11: 0,
        pu_10: 0,
        pu_9: 0,
        pu_8: 0,
        pu_7: 0,
        pu_6: 0,
        pu_5: 0,
        pu_4: 0,
        pu_3: 0,
        pu_2: 0,
        pu_1: 0,
        pu_0: 0,
        dba_90: 0,
        dba_89: 0,
        dba_88: 0,
        dba_87: 0,
        dba_86: 0,
        dba_85: 0,
        dba_84: 0,
        dba_83: 0,
        dba_82: 0,
        dba_81: 0,
        dba_80: 0,
        dba_79: 0,
        dba_78: 0,
        dba_77: 0,
        dba_76: 0,
        dba_75: 0,
        dba_74: 0,
        dba_73: 0,
        dba_72: 0,
        dba_71: 0,
        dba_70: 0,
        dba_69: 0,
        dba_68: 0,
        dba_67: 0,
        dba_66: 0,
        dba_65: 0,
        dba_64: 0,
        dba_63: 0,
        dba_62: 0,
        dba_61: 0,
        dba_60: 0,
        dba_59: 0,
        dba_58: 0,
        dba_57: 0,
        dba_56: 0,
        dba_55: 0,
        dba_54: 0,
        dba_53: 0,
        dba_52: 0,
        dba_51: 0,
        dba_50: 0,
        dba_49: 0,
        dba_48: 0,
        dba_47: 0,
        dba_46: 0,
        dba_45: 0,
        dba_44: 0,
        dba_43: 0,
        dba_42: 0,
        dba_41: 0,
        dba_40: 0,
        dba_39: 0,
        dba_38: 0,
        dba_37: 0,
        dba_36: 0,
        dba_35: 0,
        dba_34: 0,
        dba_33: 0,
        dba_32: 0,
        dba_31: 0,
        dba_30: 0,
        dba_29: 0,
        dba_28: 0,
        dba_27: 0,
        dba_26: 0,
        dba_25: 0,
        dba_24: 0,
        dba_23: 0,
        dba_22: 0,
        dba_21: 0,
        dba_20: 0,
        dba_19: 0,
        dba_18: 0,
        dba_17: 0,
        dba_16: 0,
        dba_15: 0,
        dba_14: 0,
        dba_13: 0,
        dba_12: 0,
        dba_11: 0,
        dba_10: 0,
        dba_9: 0,
        dba_8: 0,
        dba_7: 0,
        dba_6: 0,
        dba_5: 0,
        dba_4: 0,
        dba_3: 0,
        dba_2: 0,
        dba_1: 0,
        dba_0: 0,
      };
      let OTB = {
          type: "line",
          label: `OTB`,
          borderColor: "#1686F9",
          backgroundColor: "#1686F9",
          data: [],
          stack: "stack1" || "default",
        },
        Forecast = {
          type: "line",
          label: `Forecast`,
          borderColor: "#25A855",
          backgroundColor: "#25A855",
          data: [],
          stack: "stack2" || "default",
          hidden: true,
        },
        RSA = {
          type: "line",
          label: `RSA`,
          borderColor: "#E64839",
          backgroundColor: "#E64839",
          data: [],
          stack: "stack3" || "default",
        },
        LY_OTB = {
          type: "line",
          label: `LY OTB`,
          borderColor: "#000000",
          backgroundColor: "#000000",
          data: [],
          stack: "stack4" || "default",
          hidden: true,
        },
        labels = [],
        prevDateData = "";

      const setWeekWiseData = (data) => {
        weekWiseTable[data.Weekday].count =
          weekWiseTable[data.Weekday]?.count + 1;
        weekWiseTable[data.Weekday].otb =
          weekWiseTable[data.Weekday]?.otb + data?.total_booking;

        dummyArr?.map((item, index) => {
          if (weekWiseTable[data.Weekday]?.[`dba_${index}`]) {
            weekWiseTable[data.Weekday][`dba_${index}`] =
              weekWiseTable[data.Weekday]?.[`dba_${index}`] +
              data?.[`dba_${index}`];
          } else {
            weekWiseTable[data.Weekday][`dba_${index}`] =
              data?.[`dba_${index}`];
          }

          if (weekWiseTable[data.Weekday]?.[`pu_${index}`]) {
            weekWiseTable[data.Weekday][`pu_${index}`] =
              weekWiseTable[data.Weekday]?.[`pu_${index}`] +
              data?.[`pu_${index}`];
          } else {
            weekWiseTable[data.Weekday][`pu_${index}`] = data?.[`pu_${index}`];
          }
        });
      };

      dataFromApi?.map((item, index) => {
        // let itemDate = new Date(item?.staydate);
        let itemDate = commonService.convertDateInTimezone(item?.staydate);
        // START GROUPING FOR MAIN & WEEKWISE TABLE
        if (selectedDate > itemDate) {
          if (item?.day_diff == 0) {
            return;
          }
          setWeekWiseData(item);

          mainTable = [...mainTable, item];
          total.dayCount = total.dayCount + 1;
          total.pu_90 = total.pu_90 + (item?.pu_90 ? item?.pu_90 : 0);
          total.pu_89 = total.pu_89 + (item?.pu_89 ? item?.pu_89 : 0);
          total.pu_88 = total.pu_88 + (item?.pu_88 ? item?.pu_88 : 0);
          total.pu_87 = total.pu_87 + (item?.pu_87 ? item?.pu_87 : 0);
          total.pu_86 = total.pu_86 + (item?.pu_86 ? item?.pu_86 : 0);
          total.pu_85 = total.pu_85 + (item?.pu_85 ? item?.pu_85 : 0);
          total.pu_84 = total.pu_84 + (item?.pu_84 ? item?.pu_84 : 0);
          total.pu_83 = total.pu_83 + (item?.pu_83 ? item?.pu_83 : 0);
          total.pu_82 = total.pu_82 + (item?.pu_82 ? item?.pu_82 : 0);
          total.pu_81 = total.pu_81 + (item?.pu_81 ? item?.pu_81 : 0);
          total.pu_80 = total.pu_80 + (item?.pu_80 ? item?.pu_80 : 0);
          total.pu_79 = total.pu_79 + (item?.pu_79 ? item?.pu_79 : 0);
          total.pu_78 = total.pu_78 + (item?.pu_78 ? item?.pu_78 : 0);
          total.pu_77 = total.pu_77 + (item?.pu_77 ? item?.pu_77 : 0);
          total.pu_76 = total.pu_76 + (item?.pu_76 ? item?.pu_76 : 0);
          total.pu_75 = total.pu_75 + (item?.pu_75 ? item?.pu_75 : 0);
          total.pu_74 = total.pu_74 + (item?.pu_74 ? item?.pu_74 : 0);
          total.pu_73 = total.pu_73 + (item?.pu_73 ? item?.pu_73 : 0);
          total.pu_72 = total.pu_72 + (item?.pu_72 ? item?.pu_72 : 0);
          total.pu_71 = total.pu_71 + (item?.pu_71 ? item?.pu_71 : 0);
          total.pu_70 = total.pu_70 + (item?.pu_70 ? item?.pu_70 : 0);
          total.pu_69 = total.pu_69 + (item?.pu_69 ? item?.pu_69 : 0);
          total.pu_68 = total.pu_68 + (item?.pu_68 ? item?.pu_68 : 0);
          total.pu_67 = total.pu_67 + (item?.pu_67 ? item?.pu_67 : 0);
          total.pu_66 = total.pu_66 + (item?.pu_66 ? item?.pu_66 : 0);
          total.pu_65 = total.pu_65 + (item?.pu_65 ? item?.pu_65 : 0);
          total.pu_64 = total.pu_64 + (item?.pu_64 ? item?.pu_64 : 0);
          total.pu_63 = total.pu_63 + (item?.pu_63 ? item?.pu_63 : 0);
          total.pu_62 = total.pu_62 + (item?.pu_62 ? item?.pu_62 : 0);
          total.pu_61 = total.pu_61 + (item?.pu_61 ? item?.pu_61 : 0);
          total.pu_60 = total.pu_60 + (item?.pu_60 ? item?.pu_60 : 0);
          total.pu_59 = total.pu_59 + (item?.pu_59 ? item?.pu_59 : 0);
          total.pu_58 = total.pu_58 + (item?.pu_58 ? item?.pu_58 : 0);
          total.pu_57 = total.pu_57 + (item?.pu_57 ? item?.pu_57 : 0);
          total.pu_56 = total.pu_56 + (item?.pu_56 ? item?.pu_56 : 0);
          total.pu_55 = total.pu_55 + (item?.pu_55 ? item?.pu_55 : 0);
          total.pu_54 = total.pu_54 + (item?.pu_54 ? item?.pu_54 : 0);
          total.pu_53 = total.pu_53 + (item?.pu_53 ? item?.pu_53 : 0);
          total.pu_52 = total.pu_52 + (item?.pu_52 ? item?.pu_52 : 0);
          total.pu_51 = total.pu_51 + (item?.pu_51 ? item?.pu_51 : 0);
          total.pu_50 = total.pu_50 + (item?.pu_50 ? item?.pu_50 : 0);
          total.pu_49 = total.pu_49 + (item?.pu_49 ? item?.pu_49 : 0);
          total.pu_48 = total.pu_48 + (item?.pu_48 ? item?.pu_48 : 0);
          total.pu_47 = total.pu_47 + (item?.pu_47 ? item?.pu_47 : 0);
          total.pu_46 = total.pu_46 + (item?.pu_46 ? item?.pu_46 : 0);
          total.pu_45 = total.pu_45 + (item?.pu_45 ? item?.pu_45 : 0);
          total.pu_44 = total.pu_44 + (item?.pu_44 ? item?.pu_44 : 0);
          total.pu_43 = total.pu_43 + (item?.pu_43 ? item?.pu_43 : 0);
          total.pu_42 = total.pu_42 + (item?.pu_42 ? item?.pu_42 : 0);
          total.pu_41 = total.pu_41 + (item?.pu_41 ? item?.pu_41 : 0);
          total.pu_40 = total.pu_40 + (item?.pu_40 ? item?.pu_40 : 0);
          total.pu_39 = total.pu_39 + (item?.pu_39 ? item?.pu_39 : 0);
          total.pu_38 = total.pu_38 + (item?.pu_38 ? item?.pu_38 : 0);
          total.pu_37 = total.pu_37 + (item?.pu_37 ? item?.pu_37 : 0);
          total.pu_36 = total.pu_36 + (item?.pu_36 ? item?.pu_36 : 0);
          total.pu_35 = total.pu_35 + (item?.pu_35 ? item?.pu_35 : 0);
          total.pu_34 = total.pu_34 + (item?.pu_34 ? item?.pu_34 : 0);
          total.pu_33 = total.pu_33 + (item?.pu_33 ? item?.pu_33 : 0);
          total.pu_32 = total.pu_32 + (item?.pu_32 ? item?.pu_32 : 0);
          total.pu_31 = total.pu_31 + (item?.pu_31 ? item?.pu_31 : 0);
          total.pu_30 = total.pu_30 + (item?.pu_30 ? item?.pu_30 : 0);
          total.pu_29 = total.pu_29 + (item?.pu_29 ? item?.pu_29 : 0);
          total.pu_28 = total.pu_28 + (item?.pu_28 ? item?.pu_28 : 0);
          total.pu_27 = total.pu_27 + (item?.pu_27 ? item?.pu_27 : 0);
          total.pu_26 = total.pu_26 + (item?.pu_26 ? item?.pu_26 : 0);
          total.pu_25 = total.pu_25 + (item?.pu_25 ? item?.pu_25 : 0);
          total.pu_24 = total.pu_24 + (item?.pu_24 ? item?.pu_24 : 0);
          total.pu_23 = total.pu_23 + (item?.pu_23 ? item?.pu_23 : 0);
          total.pu_22 = total.pu_22 + (item?.pu_22 ? item?.pu_22 : 0);
          total.pu_21 = total.pu_21 + (item?.pu_21 ? item?.pu_21 : 0);
          total.pu_20 = total.pu_20 + (item?.pu_20 ? item?.pu_20 : 0);
          total.pu_19 = total.pu_19 + (item?.pu_19 ? item?.pu_19 : 0);
          total.pu_18 = total.pu_18 + (item?.pu_18 ? item?.pu_18 : 0);
          total.pu_17 = total.pu_17 + (item?.pu_17 ? item?.pu_17 : 0);
          total.pu_16 = total.pu_16 + (item?.pu_16 ? item?.pu_16 : 0);
          total.pu_15 = total.pu_15 + (item?.pu_15 ? item?.pu_15 : 0);
          total.pu_14 = total.pu_14 + (item?.pu_14 ? item?.pu_14 : 0);
          total.pu_13 = total.pu_13 + (item?.pu_13 ? item?.pu_13 : 0);
          total.pu_12 = total.pu_12 + (item?.pu_12 ? item?.pu_12 : 0);
          total.pu_11 = total.pu_11 + (item?.pu_11 ? item?.pu_11 : 0);
          total.pu_10 = total.pu_10 + (item?.pu_10 ? item?.pu_10 : 0);
          total.pu_9 = total.pu_9 + (item?.pu_9 ? item?.pu_9 : 0);
          total.pu_8 = total.pu_8 + (item?.pu_8 ? item?.pu_8 : 0);
          total.pu_7 = total.pu_7 + (item?.pu_7 ? item?.pu_7 : 0);
          total.pu_6 = total.pu_6 + (item?.pu_6 ? item?.pu_6 : 0);
          total.pu_5 = total.pu_5 + (item?.pu_5 ? item?.pu_5 : 0);
          total.pu_4 = total.pu_4 + (item?.pu_4 ? item?.pu_4 : 0);
          total.pu_3 = total.pu_3 + (item?.pu_3 ? item?.pu_3 : 0);
          total.pu_2 = total.pu_2 + (item?.pu_2 ? item?.pu_2 : 0);
          total.pu_1 = total.pu_1 + (item?.pu_1 ? item?.pu_1 : 0);
          total.pu_0 = total.pu_0 + (item?.pu_0 ? item?.pu_0 : 0);

          total.dba_90 = total.dba_90 + (item?.dba_90 ? item?.dba_90 : 0);
          total.dba_89 = total.dba_89 + (item?.dba_89 ? item?.dba_89 : 0);
          total.dba_88 = total.dba_88 + (item?.dba_88 ? item?.dba_88 : 0);
          total.dba_87 = total.dba_87 + (item?.dba_87 ? item?.dba_87 : 0);
          total.dba_86 = total.dba_86 + (item?.dba_86 ? item?.dba_86 : 0);
          total.dba_85 = total.dba_85 + (item?.dba_85 ? item?.dba_85 : 0);
          total.dba_84 = total.dba_84 + (item?.dba_84 ? item?.dba_84 : 0);
          total.dba_83 = total.dba_83 + (item?.dba_83 ? item?.dba_83 : 0);
          total.dba_82 = total.dba_82 + (item?.dba_82 ? item?.dba_82 : 0);
          total.dba_81 = total.dba_81 + (item?.dba_81 ? item?.dba_81 : 0);
          total.dba_80 = total.dba_80 + (item?.dba_80 ? item?.dba_80 : 0);
          total.dba_79 = total.dba_79 + (item?.dba_79 ? item?.dba_79 : 0);
          total.dba_78 = total.dba_78 + (item?.dba_78 ? item?.dba_78 : 0);
          total.dba_77 = total.dba_77 + (item?.dba_77 ? item?.dba_77 : 0);
          total.dba_76 = total.dba_76 + (item?.dba_76 ? item?.dba_76 : 0);
          total.dba_75 = total.dba_75 + (item?.dba_75 ? item?.dba_75 : 0);
          total.dba_74 = total.dba_74 + (item?.dba_74 ? item?.dba_74 : 0);
          total.dba_73 = total.dba_73 + (item?.dba_73 ? item?.dba_73 : 0);
          total.dba_72 = total.dba_72 + (item?.dba_72 ? item?.dba_72 : 0);
          total.dba_71 = total.dba_71 + (item?.dba_71 ? item?.dba_71 : 0);
          total.dba_70 = total.dba_70 + (item?.dba_70 ? item?.dba_70 : 0);
          total.dba_69 = total.dba_69 + (item?.dba_69 ? item?.dba_69 : 0);
          total.dba_68 = total.dba_68 + (item?.dba_68 ? item?.dba_68 : 0);
          total.dba_67 = total.dba_67 + (item?.dba_67 ? item?.dba_67 : 0);
          total.dba_66 = total.dba_66 + (item?.dba_66 ? item?.dba_66 : 0);
          total.dba_65 = total.dba_65 + (item?.dba_65 ? item?.dba_65 : 0);
          total.dba_64 = total.dba_64 + (item?.dba_64 ? item?.dba_64 : 0);
          total.dba_63 = total.dba_63 + (item?.dba_63 ? item?.dba_63 : 0);
          total.dba_62 = total.dba_62 + (item?.dba_62 ? item?.dba_62 : 0);
          total.dba_61 = total.dba_61 + (item?.dba_61 ? item?.dba_61 : 0);
          total.dba_60 = total.dba_60 + (item?.dba_60 ? item?.dba_60 : 0);
          total.dba_59 = total.dba_59 + (item?.dba_59 ? item?.dba_59 : 0);
          total.dba_58 = total.dba_58 + (item?.dba_58 ? item?.dba_58 : 0);
          total.dba_57 = total.dba_57 + (item?.dba_57 ? item?.dba_57 : 0);
          total.dba_56 = total.dba_56 + (item?.dba_56 ? item?.dba_56 : 0);
          total.dba_55 = total.dba_55 + (item?.dba_55 ? item?.dba_55 : 0);
          total.dba_54 = total.dba_54 + (item?.dba_54 ? item?.dba_54 : 0);
          total.dba_53 = total.dba_53 + (item?.dba_53 ? item?.dba_53 : 0);
          total.dba_52 = total.dba_52 + (item?.dba_52 ? item?.dba_52 : 0);
          total.dba_51 = total.dba_51 + (item?.dba_51 ? item?.dba_51 : 0);
          total.dba_50 = total.dba_50 + (item?.dba_50 ? item?.dba_50 : 0);
          total.dba_49 = total.dba_49 + (item?.dba_49 ? item?.dba_49 : 0);
          total.dba_48 = total.dba_48 + (item?.dba_48 ? item?.dba_48 : 0);
          total.dba_47 = total.dba_47 + (item?.dba_47 ? item?.dba_47 : 0);
          total.dba_46 = total.dba_46 + (item?.dba_46 ? item?.dba_46 : 0);
          total.dba_45 = total.dba_45 + (item?.dba_45 ? item?.dba_45 : 0);
          total.dba_44 = total.dba_44 + (item?.dba_44 ? item?.dba_44 : 0);
          total.dba_43 = total.dba_43 + (item?.dba_43 ? item?.dba_43 : 0);
          total.dba_42 = total.dba_42 + (item?.dba_42 ? item?.dba_42 : 0);
          total.dba_41 = total.dba_41 + (item?.dba_41 ? item?.dba_41 : 0);
          total.dba_40 = total.dba_40 + (item?.dba_40 ? item?.dba_40 : 0);
          total.dba_39 = total.dba_39 + (item?.dba_39 ? item?.dba_39 : 0);
          total.dba_38 = total.dba_38 + (item?.dba_38 ? item?.dba_38 : 0);
          total.dba_37 = total.dba_37 + (item?.dba_37 ? item?.dba_37 : 0);
          total.dba_36 = total.dba_36 + (item?.dba_36 ? item?.dba_36 : 0);
          total.dba_35 = total.dba_35 + (item?.dba_35 ? item?.dba_35 : 0);
          total.dba_34 = total.dba_34 + (item?.dba_34 ? item?.dba_34 : 0);
          total.dba_33 = total.dba_33 + (item?.dba_33 ? item?.dba_33 : 0);
          total.dba_32 = total.dba_32 + (item?.dba_32 ? item?.dba_32 : 0);
          total.dba_31 = total.dba_31 + (item?.dba_31 ? item?.dba_31 : 0);
          total.dba_30 = total.dba_30 + (item?.dba_30 ? item?.dba_30 : 0);
          total.dba_29 = total.dba_29 + (item?.dba_29 ? item?.dba_29 : 0);
          total.dba_28 = total.dba_28 + (item?.dba_28 ? item?.dba_28 : 0);
          total.dba_27 = total.dba_27 + (item?.dba_27 ? item?.dba_27 : 0);
          total.dba_26 = total.dba_26 + (item?.dba_26 ? item?.dba_26 : 0);
          total.dba_25 = total.dba_25 + (item?.dba_25 ? item?.dba_25 : 0);
          total.dba_24 = total.dba_24 + (item?.dba_24 ? item?.dba_24 : 0);
          total.dba_23 = total.dba_23 + (item?.dba_23 ? item?.dba_23 : 0);
          total.dba_22 = total.dba_22 + (item?.dba_22 ? item?.dba_22 : 0);
          total.dba_21 = total.dba_21 + (item?.dba_21 ? item?.dba_21 : 0);
          total.dba_20 = total.dba_20 + (item?.dba_20 ? item?.dba_20 : 0);
          total.dba_19 = total.dba_19 + (item?.dba_19 ? item?.dba_19 : 0);
          total.dba_18 = total.dba_18 + (item?.dba_18 ? item?.dba_18 : 0);
          total.dba_17 = total.dba_17 + (item?.dba_17 ? item?.dba_17 : 0);
          total.dba_16 = total.dba_16 + (item?.dba_16 ? item?.dba_16 : 0);
          total.dba_15 = total.dba_15 + (item?.dba_15 ? item?.dba_15 : 0);
          total.dba_14 = total.dba_14 + (item?.dba_14 ? item?.dba_14 : 0);
          total.dba_13 = total.dba_13 + (item?.dba_13 ? item?.dba_13 : 0);
          total.dba_12 = total.dba_12 + (item?.dba_12 ? item?.dba_12 : 0);
          total.dba_11 = total.dba_11 + (item?.dba_11 ? item?.dba_11 : 0);
          total.dba_10 = total.dba_10 + (item?.dba_10 ? item?.dba_10 : 0);
          total.dba_9 = total.dba_9 + (item?.dba_9 ? item?.dba_9 : 0);
          total.dba_8 = total.dba_8 + (item?.dba_8 ? item?.dba_8 : 0);
          total.dba_7 = total.dba_7 + (item?.dba_7 ? item?.dba_7 : 0);
          total.dba_6 = total.dba_6 + (item?.dba_6 ? item?.dba_6 : 0);
          total.dba_5 = total.dba_5 + (item?.dba_5 ? item?.dba_5 : 0);
          total.dba_4 = total.dba_4 + (item?.dba_4 ? item?.dba_4 : 0);
          total.dba_3 = total.dba_3 + (item?.dba_3 ? item?.dba_3 : 0);
          total.dba_2 = total.dba_2 + (item?.dba_2 ? item?.dba_2 : 0);
          total.dba_1 = total.dba_1 + (item?.dba_1 ? item?.dba_1 : 0);
          total.dba_0 = total.dba_0 + (item?.dba_0 ? item?.dba_0 : 0);
        }
        // END GROUPING FOR MAIN & WEEKWISE TABLE

        //START GROUPING FOR CHART AND CHART TABLE
        const getLabelForChart = (date) => {
          let currentLabel = `${moment(date).format("MM/DD")}`;
          if (
            (date && moment(new Date(date), "l").format("ddd") === "Fri") ||
            moment(new Date(date), "l").format("ddd") === "Sat"
          ) {
            currentLabel = `${moment(date).format("MM/DD")} ${moment(
              new Date(date),
              "l"
            ).format("ddd")}`;
          }

          return currentLabel;
        };

        if (
          // moment(item?.staydate).format("YYYY-MM-DD") ===
          // moment(date).format("YYYY-MM-DD")
          commonService.getDateInFormat(
            commonService.convertDateInTimezone(item?.staydate)
          ) ===
          commonService.getDateInFormat(
            commonService.convertDateInTimezone(date)
          )
        ) {
          if (!prevDateData) {
            prevDateData = index;
            let lastIndex = index - 1;
            chartTable = [...chartTable, dataFromApi?.[lastIndex]];
            OTB.data = [
              ...OTB.data,
              dataFromApi?.[lastIndex]?.total_booking
                ? dataFromApi?.[lastIndex]?.total_booking
                : 0,
            ];
            Forecast.data = [
              ...Forecast.data,
              dataFromApi?.[lastIndex]?.forcastroom
                ? dataFromApi?.[lastIndex]?.forcastroom
                : 0,
            ];
            RSA.data = [
              ...RSA.data,
              dataFromApi?.[lastIndex]?.dba_avg
                ? dataFromApi?.[lastIndex]?.dba_avg
                : 0,
            ];
            LY_OTB.data = [
              ...LY_OTB.data,
              dataFromApi?.[lastIndex]?.ly_booking
                ? dataFromApi?.[lastIndex]?.ly_booking
                : 0,
            ];
            labels = [
              ...labels,
              dataFromApi?.[lastIndex]?.day_diff,
              // getLabelForChart(dataFromApi?.[lastIndex]?.staydate),
            ];

            chartTable = [...chartTable, dataFromApi?.[index]];
            OTB.data = [
              ...OTB.data,
              dataFromApi?.[index]?.total_booking
                ? dataFromApi?.[index]?.total_booking
                : 0,
            ];
            Forecast.data = [
              ...Forecast.data,
              dataFromApi?.[index]?.forcastroom
                ? dataFromApi?.[index]?.forcastroom
                : 0,
            ];
            RSA.data = [
              ...RSA.data,
              dataFromApi?.[index]?.dba_avg ? dataFromApi?.[index]?.dba_avg : 0,
            ];
            LY_OTB.data = [
              ...LY_OTB.data,
              dataFromApi?.[index]?.ly_booking
                ? dataFromApi?.[index]?.ly_booking
                : 0,
            ];
            labels = [
              ...labels,
              dataFromApi?.[index]?.day_diff,
              // getLabelForChart(dataFromApi?.[index]?.staydate),
            ];
          }
        }
        if (
          // new Date(commonService.getDateInFormat(selectedDate)) <
          // new Date(commonService.getDateInFormat(itemDate))
          moment(commonService.getDateInFormat(selectedDate)).toDate() <
          moment(commonService.getDateInFormat(itemDate))
        ) {
          chartTable = [...chartTable, item];
          OTB.data = [
            ...OTB.data,
            item?.total_booking ? item?.total_booking : 0,
          ];
          Forecast.data = [
            ...Forecast.data,
            item?.forcastroom ? item?.forcastroom : 0,
          ];
          RSA.data = [...RSA.data, item?.dba_avg ? item?.dba_avg : 0];
          LY_OTB.data = [
            ...LY_OTB.data,
            item?.ly_booking ? item?.ly_booking : 0,
          ];
          labels = [
            ...labels,
            item?.day_diff,
            // getLabelForChart(item?.staydate),
          ];
        }
        // END GROUPING FOR CHART AND CHART TABLE
      });

      let chartDetail = {
        labels: labels,
        datasets: [OTB, Forecast, RSA, LY_OTB],
      };
      chartTable?.map((item, index) => {
        if (item?.total_booking > maxvalueForChart) {
          maxvalueForChart = item?.total_booking;
        }
        if (item?.forcastroom > maxvalueForChart) {
          maxvalueForChart = item?.forcastroom;
        }
        if (item?.dba_avg > maxvalueForChart) {
          maxvalueForChart = item?.dba_avg;
        }
        if (item?.ly_booking > maxvalueForChart) {
          maxvalueForChart = item?.ly_booking;
        }
      });
      maxValueForInventoryRef.current = maxvalueForChart;
      let tableData = {
        mainTable,
        chartTable,
        weekWiseTable,
      };
      totalRef.current = total;
      setChartData(chartDetail);
      setTableData(tableData);
      setApiData(response?.data?.data);
      setLoading(false);
    }

    if (!response?.isValidate) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (api === 0) {
    } else {
      if (para?.asOfDate) {
        getChartData();
      }
    }
  }, [para?.asOfDate, date]);

  let chartOptions = {
    indexAxis: "x",
    scales: {
      x: {
        stacked: true,
        title: {
          display: true,
          text: "Days Out",
        },
        ticks: {
          align: "start",
          autoSkip: false,
        },
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: "Inventory",
        },
        grid: {
          display: true,
        },
        offset: true,
        ticks: {
          callback: function (value) {
            return value;
          },
        },
        max: maxValueForInventoryRef.current,
        min: 0,
        stepSize: 10,
      },
    },
    elements: {
      line: {
        fill: false,
      },
      point: {
        radius: 0,
      },
    },

    plugins: {
      legend: {
        display: true,
        align: "end",
        position: "top",
        maxWidth: 20,
        maxHeight: 100,
        labels: {
          boxWidth: 10,
          boxHeight: 10,
        },
      },
      tooltip: {
        enabled: true,
        mode: "index",
        callbacks: {
          title: function (context) {
            return context[0].label;
          },
          label: function (context) {
            let label = context.dataset.label;
            let value = context.formattedValue;
            return `${label}: ${value}`;
          },
        },
      },
    },
    responsive: true,
    barThickness: 12,
    categorySpacing: 0.3,
    barPercentage: 0.8,
    categoryPercentage: 0.9,
  };

  // const customLabelStylePlugin = {
  //   afterDraw: function (chart) {
  //     const ctx = chart.ctx;
  //     const xAxis = chart.scales['x'];
  //     const labels = chart.data.labels;

  //     // Clear existing x-axis labels
  //     xAxis.ticks.forEach((tick, index) => {
  //       const x = xAxis.getPixelForTick(index);
  //       const y = chart.chartArea.bottom;
  //       ctx.clearRect(x - ctx.measureText(tick.label).width / 2, y + 5, ctx.measureText(tick.label).width, 20);
  //     });

  //     // Draw new styled labels
  //     labels.forEach((label, index) => {
  //       const x = xAxis.getPixelForTick(index);
  //       const y = chart.chartArea.bottom + 20; // Adjust y to position the label correctly

  //       ctx.save();
  //       if (label.toLowerCase().includes('fri')) {
  //         ctx.fillStyle = 'red'; // Change this to your desired color
  //       } else {
  //         ctx.fillStyle = 'black'; // Default color for other labels
  //       }
  //       ctx.textAlign = 'center';
  //       ctx.fillText(label, x, y);
  //       ctx.restore();
  //     });
  //   }
  // };

  Chart.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Tooltip,
    Legend,
    PointElement,
    LineElement,
    Filler
  );

  const [tblids, settblids] = useState({
    mainTbl: commonService.GenerateGUID(),
    weekWiseTbl: commonService.GenerateGUID(),
    forecastTbl: commonService.GenerateGUID(),
  });
  FixColumn(tblids.mainTbl, [1, 2, 3, 4]);
  FixColumn(tblids.weekWiseTbl, [1, 2]);

  return (
    <div>
      <div>
        <Col xl={2}>
          <DatePicker
            id="startDate"
            selected={commonService.convertDateInTimezone(date)}
            // maxDate={new Date(para?.asOfDate)}
            onChange={(clickedDate) => {
              setDate(clickedDate);
            }}
            placeholderText="Select Date"
          />
        </Col>
      </div>
      <div className="maintbl-widget-container">
        {api !== 0 && (
          <div className="gap-1 maintbl-widget-container-menu">
            <Button
              className="heading-menu-btns"
              type="button"
              title="Send Mail"
            >
              <img
                src={emailIcon}
                alt="emailIcon"
                onClick={() => {
                  commonService.fnSendWidgetMail(
                    tblids.mainTbl,
                    "Booking Pace Comparison",
                    snapshotId,
                    defaultAsOfDateFromRTK,
                    "",
                    para?.widgetId
                  );
                }}
              />
            </Button>
            <Button className="heading-menu-btns" type="button">
              <img
                src={infoIcon}
                alt="info"
                onClick={() => {
                  widgetDetailRef.current = {
                    widgetdetails: "",
                    usernote: "",
                    compName: compName?.bookingPaceComparison,
                    widgetId: para?.widgetId,
                  };
                  setWidgetModal(true);
                }}
              />
            </Button>
            <Button className="heading-menu-btns" type="button">
              <img
                src={downloadIcon}
                alt="menuIcon"
                import={tblids.mainTbl}
                onClick={() =>
                  commonService.fnExportInCSV(
                    tblids.mainTbl,
                    // "Booking Pace Comparison"
                    `${para?.propertyCode}-Booking Pace Comparison-${moment(
                      new Date()
                    ).format("MMDDYYYY")}`
                  )
                }
              />
            </Button>
          </div>
        )}
        <Col xl={12}>
          <div className="ViewPropertySetupTable mt-3">
            <div className="stripped__table">
              <div className="table-responsive tblDynamicPickupSticky">
                <Table id={tblids.mainTbl}>
                  <thead className="tbl-header-sticky">
                    <tr className="table-main-header-tr">
                      <th></th>
                      <th></th>
                      <th></th>
                      <th className="border_right"></th>
                      {dummyArr?.map((item, index) => (
                        <th className="text-center border_right" colSpan={2}>
                          {90 - index}
                        </th>
                      ))}
                    </tr>
                    <tr className="table-main-header-tr">
                      {tableData?.mainTable && (
                        <>
                          <th className="border_right width75">staydate</th>
                          <th className="border_right width75">DayOfWeek</th>
                          <th className="border_right">Day Diff</th>
                          <th className="border_right width50 text-center">
                            OTB
                          </th>
                          {Object?.keys(tableData?.mainTable?.[0])?.map(
                            (item, index) => {
                              return (
                                <>
                                  {item !== "staydate" &&
                                    item !== "day_diff" &&
                                    item !== "total_booking" &&
                                    item !== "forcastroom" &&
                                    item !== "pu_avg" &&
                                    item !== "dba_avg" &&
                                    item !== "Weekday" && (
                                      <th
                                        key={index}
                                        className={`${
                                          item === "staydate"
                                            ? "text-left"
                                            : "text-center"
                                        } ${
                                          index % 2 !== 0 ? "border_right" : ""
                                        }`}
                                      >
                                        {item?.split("_")?.[0]}
                                      </th>
                                    )}
                                </>
                              );
                            }
                          )}
                        </>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <tr style={{ height: "80px", position: "relative" }}>
                        <Loader />
                      </tr>
                    ) : (
                      tableData?.mainTable &&
                      tableData?.mainTable?.map((item, index) => {
                        let showBgColor = false;

                        if (
                          item?.Weekday === "Friday" ||
                          item?.Weekday === "Saturday"
                        ) {
                          showBgColor = true;
                        }
                        return (
                          <>
                            <tr
                              key={index}
                              className={`${
                                showBgColor ? "backgroundTr" : ""
                              } shadowOnHover`}
                            >
                              <td className="border_right">
                                {commonService.getDateInFormat(
                                  commonService.convertDateInTimezone(
                                    item?.["staydate"]
                                  )
                                  // new Date(item?.["staydate"])
                                )}
                              </td>
                              <td className="text-center border_right">
                                {item?.Weekday}
                              </td>
                              <td className="text-center border_right">
                                {item?.day_diff}
                              </td>
                              <td className="text-center border_right">
                                {commonService.formateRoom(item?.total_booking)}
                              </td>
                              {Object.keys(item)?.map((objItem, objIndex) => {
                                return (
                                  <>
                                    {objItem !== "staydate" &&
                                      objItem !== "day_diff" &&
                                      objItem !== "total_booking" &&
                                      objItem !== "forcastroom" &&
                                      objItem !== "pu_avg" &&
                                      objItem !== "dba_avg" &&
                                      objItem !== "Weekday" && (
                                        <td
                                          className={`${
                                            objItem === "staydate"
                                              ? "text-left"
                                              : "text-center"
                                          } ${
                                            objIndex % 2 !== 0
                                              ? "border_right"
                                              : ""
                                          }`}
                                        >
                                          {item?.[objItem]}
                                        </td>
                                      )}
                                  </>
                                );
                              })}
                            </tr>
                            {index === tableData?.mainTable?.length - 1 && (
                              <tr className="shadowOnHover">
                                <td className="boldTd">AVG</td>
                                <td className="boldTd"></td>
                                <td className="boldTd"></td>
                                <td className="boldTd border_right"></td>

                                {dummyArr?.map((item, index) => {
                                  let dataOf = 90 - index;
                                  return (
                                    <>
                                      <td className="boldTd">
                                        {Math.round(
                                          totalRef.current?.[`dba_${dataOf}`] /
                                            totalRef.current?.dayCount
                                        )}
                                      </td>
                                      <td className="boldTd border_right">
                                        {Math.round(
                                          totalRef.current?.[`pu_${dataOf}`] /
                                            totalRef.current?.dayCount
                                        )}
                                      </td>
                                    </>
                                  );
                                })}
                              </tr>
                            )}
                          </>
                        );
                      })
                    )}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </Col>
      </div>

      <div className="weekwisetbl-widget-container">
        {api !== 0 && (
          <div className="gap-1 weekwisetbl-widget-container-menu">
            {/* <Button className="heading-menu-btns" type="button">
              <img
                src={NoteIcon}
                alt="menuIcon"
                onClick={() => {
                  setShowNoteSidebar(true);
                }}
              />
            </Button> */}
            <Button
              className="heading-menu-btns"
              type="button"
              title="Send Mail"
            >
              <img
                src={emailIcon}
                alt="emailIcon"
                onClick={() => {
                  commonService.fnSendWidgetMail(
                    tblids.weekWiseTbl,
                    "Booking Pace Comparison",
                    snapshotId,
                    defaultAsOfDateFromRTK,
                    "",
                    para?.widgetId
                  );
                }}
              />
            </Button>
            <Button className="heading-menu-btns" type="button">
              <img
                src={infoIcon}
                alt="info"
                onClick={() => {
                  widgetDetailRef.current = {
                    widgetdetails: "",
                    usernote: "",
                    compName: compName?.bookingPaceComparison,
                    widgetId: para?.widgetId,
                  };
                  setWidgetModal(true);
                }}
              />
            </Button>
            <Button className="heading-menu-btns" type="button">
              <img
                src={downloadIcon}
                alt="menuIcon"
                import={tblids.weekWiseTbl}
                onClick={() =>
                  commonService.fnExportInCSV(
                    tblids.weekWiseTbl,
                    // "Booking Pace Comparison"
                    `${para?.propertyCode}-Booking Pace Comparison-${moment(
                      new Date()
                    ).format("MMDDYYYY")}`
                  )
                }
              />
            </Button>
          </div>
        )}
        <Col xl={12}>
          <div className="ViewPropertySetupTable mt-3">
            <div className="stripped__table">
              <div className="table-responsive">
                <Table id={tblids.weekWiseTbl} responsive>
                  <thead>
                    <tr>
                      <th className="border_right"></th>
                      <th className="border_right"></th>
                      {dummyArr?.map((item, index) => (
                        <th className="text-center border_right" colSpan={2}>
                          {90 - index}
                        </th>
                      ))}
                    </tr>
                    <tr className="table-main-header-tr">
                      {tableData?.mainTable && (
                        <>
                          <th className="border_right">date</th>
                          <th className="border_right width50 text-center">
                            otb
                          </th>
                          {Object?.keys(tableData?.mainTable?.[0])?.map(
                            (item, index) => {
                              return (
                                <>
                                  {item !== "staydate" &&
                                    item !== "day_diff" &&
                                    item !== "total_booking" &&
                                    item !== "forcastroom" &&
                                    item !== "pu_avg" &&
                                    item !== "dba_avg" &&
                                    item !== "Weekday" && (
                                      <th
                                        key={index}
                                        className={`${
                                          item === "staydate"
                                            ? "text-left"
                                            : "text-center"
                                        } ${
                                          index % 2 !== 0 ? "border_right" : ""
                                        }`}
                                      >
                                        {item?.split("_")?.[0]}
                                      </th>
                                    )}
                                </>
                              );
                            }
                          )}
                        </>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <tr style={{ height: "80px", position: "relative" }}>
                        <Loader />
                      </tr>
                    ) : tableData?.weekWiseTable ? (
                      Object.entries(tableData?.weekWiseTable)?.map(
                        ([key, value], index) => {
                          return (
                            <tr className="shadowOnHover">
                              <td className="border_right">{key}</td>
                              <td className="border_right">
                                {Math.round(value?.otb / value?.count)}
                              </td>
                              {dummyArr.map((dummyItem, dummyIndex) => {
                                let indexReverse = 90 - dummyIndex;
                                return (
                                  <>
                                    <td>
                                      {Math.round(
                                        value?.[`dba_${indexReverse}`] /
                                          value?.count
                                      )}
                                    </td>
                                    <td className="border_right">
                                      {Math.round(
                                        value?.[`pu_${indexReverse}`] /
                                          value?.count
                                      )}
                                    </td>
                                  </>
                                );
                              })}
                            </tr>
                          );
                        }
                      )
                    ) : (
                      <tr>
                        <td>No Data Found</td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </Col>
      </div>

      <Col xl={12} className="mt-3">
        <Row>
          <Col xl={4}>
            <div className="weekwisetbl-widget-container">
              {api !== 0 && (
                <div className="gap-1 weekwisetbl-widget-container-menu">
                  <Button
                    className="heading-menu-btns"
                    type="button"
                    title="Send Mail"
                  >
                    <img
                      src={emailIcon}
                      alt="emailIcon"
                      onClick={() => {
                        commonService.fnSendWidgetMail(
                          tblids.forecastTbl,
                          "Booking Pace Comparison",
                          snapshotId,
                          defaultAsOfDateFromRTK,
                          "",
                          para?.widgetId
                        );
                      }}
                    />
                  </Button>
                  <Button className="heading-menu-btns" type="button">
                    <img
                      src={infoIcon}
                      alt="info"
                      onClick={() => {
                        widgetDetailRef.current = {
                          widgetdetails: "",
                          usernote: "",
                          compName: compName?.bookingPaceComparison,
                          widgetId: para?.widgetId,
                        };
                        setWidgetModal(true);
                      }}
                    />
                  </Button>
                  <Button className="heading-menu-btns" type="button">
                    <img
                      src={downloadIcon}
                      alt="menuIcon"
                      import={tblids.forecastTbl}
                      onClick={() =>
                        commonService.fnExportInCSV(
                          tblids.forecastTbl,
                          `${
                            para?.propertyCode
                          }-Booking Pace Comparison-${moment(new Date()).format(
                            "MMDDYYYY"
                          )}`
                        )
                      }
                    />
                  </Button>
                </div>
              )}
              <div className="ViewPropertySetupTable">
                <div className="stripped__table">
                  <Table id={tblids?.forecastTbl}>
                    <thead>
                      <tr className="table-main-header-tr">
                        <th className="border_right">Date</th>
                        <th className="border_right">Day</th>
                        <th className="border_right">Day Diff</th>
                        <th className="border_right">OTB</th>
                        <th className="border_right">Forecast</th>
                        <th className="border_right">RSA</th>
                        <th className="border_right">Diff(RSA-OTB)</th>
                        <th>Ly OTB</th>
                      </tr>
                    </thead>
                    <tbody>
                      {loading ? (
                        <tr style={{ height: "80px", position: "relative" }}>
                          <Loader />
                        </tr>
                      ) : tableData?.chartTable ? (
                        tableData?.chartTable?.map((item) => {
                          let showBgColor = false;

                          if (
                            item?.Weekday === "Friday" ||
                            item?.Weekday === "Saturday"
                          ) {
                            showBgColor = true;
                          }
                          let rsaOtbDiff =
                            (item?.dba_avg ? item?.dba_avg : 0) -
                            (item?.total_booking ? item?.total_booking : 0);
                          return (
                            <tr
                              className={`${
                                showBgColor ? "backgroundTr" : ""
                              } shadowOnHover`}
                            >
                              <td className="border_right">
                                {commonService.getDateInFormat(
                                  commonService.convertDateInTimezone(
                                    item?.staydate
                                  )
                                )}
                              </td>
                              <td className="border_right">{item?.Weekday}</td>
                              <td className="border_right">{item?.day_diff}</td>
                              <td className="border_right">
                                {item?.total_booking}
                              </td>
                              <td className="border_right">
                                {item?.forcastroom}
                              </td>
                              <td className="border_right">{item?.dba_avg}</td>
                              <td className="border_right">{rsaOtbDiff}</td>
                              <td>{item?.ly_booking}</td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={7} className="text-center">
                            No Data Found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </Col>
          <Col xl={8}>
            {loading ? (
              <Loader />
            ) : chartData ? (
              <div>
                <Line data={chartData} options={chartOptions} />
              </div>
            ) : (
              <div>No Data Found</div>
            )}
          </Col>
        </Row>
      </Col>
      {api !== 0 && <Comments widgetDetails={para} editorId={"BookingPaceComparison"}/>}

      {showNoteSidebar && (
        <AddWidgetModal
          showModal={showNoteSidebar}
          closeModal={closeNoteSideBar}
          widgetId={para?.widgetId}
          propertyId={propertyDetailFromRTK?.propertyid}
          rowTitle={para?.rowTitle}
        />
      )}
      {widgetModal && (
        <WidgetDetailModal
          showModal={widgetModal}
          closeModal={closeWidgetModal}
          data={widgetDetailRef.current}
        />
      )}
    </div>
  );
};

export default BookingPaceComparison;
