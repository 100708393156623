import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend,
} from "chart.js";

const HorizontalBarChart = ({
  labels,
  datasets,
  maxPercentage,
  xAxisTitle,
  yAxisTitle,
  barThickness,
}) => {
  const chartData = {
    labels: labels,
    datasets: datasets.map((dataset, index) => ({
      label: dataset.label,
      backgroundColor: dataset.backgroundColor,
      data: dataset.data,
      // barThickness: barThickness,
      stack: dataset.stack || "default",
    })),
  };

  const chartOptions = {
    indexAxis: "y",
    scales: {
      x: {
        beginAtZero: true,
        // max: maxPercentage,
        stacked: true,
        title: {
          display: true,
          text: xAxisTitle,
        },
        categorySpacing: 100,
      },
      y: {
        title: {
          display: true,
          text: yAxisTitle,
        },
        grid: {
          display: false,
        },
        offset: true,
        ticks: {
          align: "start",
          autoSkip: false,
        },
      },
    },

    plugins: {
      legend: {
        display: true,
        align: "end",
        position: "top",
        maxWidth: 20,
        maxHeight: 100,
        labels: {
          boxWidth: 10,
          boxHeight: 10,
        },
      },
      tooltip: {
        enabled: true,
        mode: "index",
        callbacks: {
          title: function (context) {
            // You can provide a title for the tooltip here
            return context[0].label;
          },
          label: function (context) {
            // Use context.datasetIndex to differentiate between datasets
            let label = context.dataset.label;
            let value = context.formattedValue;
            return `${label}: ${value}`;
          },
        },
      },
    },
    // aspectRatio: 1,
    responsive: true,
    // maintainAspectRatio: false,
    // barThickness: barThickness,
    categorySpacing: 0.3,
    barPercentage: 0.8,
    categoryPercentage: 0.9,
  };

  Chart.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend);

  return <Bar data={chartData} options={chartOptions} />;
};

export default HorizontalBarChart;
