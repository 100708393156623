import React, { useEffect, useRef, useState } from "react";
import { Badge, Col, Offcanvas, Row, Tab, Tabs } from "react-bootstrap";
import greenTick from "../../Assets/Images/Revenue-management/greenTick.svg";
import Accordion from "react-bootstrap/Accordion";
import { apiCall } from "../../utils/axiosService";
import { API_URL } from "../../utils/apiRoutes";
import { commonService } from "../../utils/commonService";
import { useDispatch, useSelector } from "react-redux";
import imageIcon from "../../Assets/Images/Configuration/image_placeholder.svg";
import pdfIcon from "../../Assets/Images/CRM/pdf.svg";
import folderIcon from "../../Assets/Images/Configuration/folder.svg";
import { apiResponse } from "../../utils/apiResponse";
import DatePicker from "react-datepicker";
import ViewWidgetNoteModal from "./ViewWidgetNoteModal";
import moment from "moment";
import Loader from "../Loader/Loader";
import { useNavigate } from "react-router-dom";
import ReactDOM from "react-dom";
import searchIcon from "../../Assets/Images/Configuration/search.svg";
import filter from "../../Assets/Images/CRM/filter.png";
import { setAllNotificationCount } from "../../utils/slices/headerPropertySlice";

function RmInsight({ show, handleClose, propertyId }) {
  const dispatch = useDispatch();
  const [noteDetail, setNoteDetail] = useState(null);
  const defaultAsOfDateFromRTK = useSelector(
    (store) => store.headerProperty.defaultAsOfDate
  );
  const propertyDetailFromRTK = useSelector(
    (store) => store.headerProperty.propertyDetail
  );
  const notificationType = useSelector(
    (store) => store.headerProperty.allNotificationCount
  );
  const [startRangeDate, setStartRangeDate] = useState(
    propertyId
      ? ""
      : defaultAsOfDateFromRTK
      ? new Date(defaultAsOfDateFromRTK)
      : ""
  );
  const [endRangeDate, setEndRangeDate] = useState(
    propertyId
      ? ""
      : defaultAsOfDateFromRTK
      ? new Date(defaultAsOfDateFromRTK)
      : ""
  );
  const startRangeDateRef = useRef();
  const endRangeDateRef = useRef();
  const [viewWidgetNote, setViewWidgetNote] = useState(false);
  const clickedNoteDetail = useRef();
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("Insights");
  const [insightList, setInsightList] = useState([]);
  const [start, setstart] = useState(0);
  const [node, setNode] = useState(null);
  const [insightLoading, setInsightLoading] = useState(false);
  const [input, setInput] = useState("");
  const navigate = useNavigate();
  const [activeAccrodian, setActiveAccrodian] = useState(null);
  const userDetail = commonService.getLoginUserData();

  const refForScroll = useRef();
  const closeViewWidgetNoteModal = () => {
    setViewWidgetNote(false);
  };

  const handleDateChange = (event) => {
    const [start, end] = event;
    let startSelectedDate = "",
      endSelectedDate = "";
    if (start) {
      let date = new Date(start);
      let month =
        date.getMonth() + 1 > 9
          ? date.getMonth() + 1
          : `0${date.getMonth() + 1}`;
      let dateFormat =
        date?.getDate() > 9 ? date.getDate() : `0${date.getDate()}`;
      startSelectedDate = `${date.getFullYear()}-${month}-${dateFormat}`;
      startRangeDateRef.current = startSelectedDate;
    }
    if (end) {
      let date = new Date(end);
      let month =
        date.getMonth() + 1 > 9
          ? date.getMonth() + 1
          : `0${date.getMonth() + 1}`;
      let dateFormat =
        date?.getDate() > 9 ? date.getDate() : `0${date.getDate()}`;
      endSelectedDate = `${date.getFullYear()}-${month}-${dateFormat}`;
      endRangeDateRef.current = endSelectedDate;
    }

    setStartRangeDate(event[0]);
    setEndRangeDate(event[1]);
    if (event[0] !== null && event[1] !== null) {
      getNotesDetail();
    }
  };

  const getNotesDetail = async () => {
    setLoading(true);
    let noteResponse = await apiCall(
      {
        method: "POST",
        url: API_URL.BASE_API_URL.Configuration + API_URL.WIDGET_NOTE.GET_LIST,
        body: {
          propertyid: propertyId
            ? propertyId
            : propertyDetailFromRTK?.propertyid,
          widgetid: 0,
          startdate: propertyId
            ? null
            : startRangeDateRef.current
            ? commonService.getDateInDBFormat(startRangeDateRef.current)
            : commonService.getDateInDBFormat(defaultAsOfDateFromRTK),
          enddate: propertyId
            ? null
            : endRangeDateRef.current
            ? commonService.getDateInDBFormat(endRangeDateRef.current)
            : commonService.getDateInDBFormat(defaultAsOfDateFromRTK),
        },
      },
      false
    );

    let response = apiResponse(false, noteResponse);

    if (response?.isValidate) {
      let dataFromApi = response?.data?.data?.notedata;
      let groupingJson = {};

      for (let i = 0; i < dataFromApi?.length; i++) {
        let dateKey = moment(dataFromApi?.[i]?.asofdate).format("YYYY-MM-DD");
        if (groupingJson?.hasOwnProperty(dateKey)) {
          groupingJson[dateKey] = [...groupingJson[dateKey], dataFromApi?.[i]];
        } else {
          groupingJson = {
            ...groupingJson,
            [dateKey]: [dataFromApi?.[i]],
          };
        }
      }

      let sortByKey = {};
      Object.keys(groupingJson)
        .sort((a, b) => {
          return new Date(a) - new Date(b);
        })
        .forEach(function (v, i) {
          let finalJson = groupingJson?.[v]?.sort((a, b) => {
            return new Date(a.createdon) - new Date(b.createdon);
          });
          sortByKey = { ...sortByKey, [v]: finalJson };
        });
      setNoteDetail({
        notedata: sortByKey,
        filedata: response?.data?.data?.filedata,
      });
      setLoading(false);
    }

    if (!response?.isValidate) {
      setNoteDetail(null);
      setLoading(false);
    }
  };

  const htmlToText = (html) => {
    const tempElement = document.createElement("div");
    tempElement.innerHTML = html;
    const plainText = tempElement.innerText;
    return plainText;
  };

  const getInsightList = async () => {
    setInsightLoading(true);
    let getInsightLiastResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.Notification +
          API_URL.NOTIFICATION.GET_INSIGHT_LIST,
        body: {
          title: "",
          notificationstartdate: "",
          notificationenddate: "",
          notificationtoid: "",
          propertycode: propertyDetailFromRTK
            ? propertyDetailFromRTK?.propertycode
            : "",
          draw: 1,
          start: start,
          length: 10,
          sortcolumn: "notificationid",
          sortdirection: "desc",
          searchvalue: "",
          columns: "",
          "search.value": "",
          "search.regex": "",
          order: "",
          associationtype: activeAccrodian,
        },
      },
      true
    );

    if (getInsightLiastResponse?.status_code === 0) {
    } else if (getInsightLiastResponse?.status_code === 1) {
      setInsightList((prev) => [...prev, ...getInsightLiastResponse?.data]);
    } else if (getInsightLiastResponse?.status_code === 2) {
      localStorage.clear();
      window.location.href =
        API_URL.BASE_API_URL.EMA_MAIN_DOMAIN + "?logout=true";
    }
    setInsightLoading(false);
  };

  const nodeData = useRef();
  // let body = document.getElementById("canvas");
  let body = document.getElementById(`${activeAccrodian}`);
  nodeData.current = ReactDOM.findDOMNode(body);

  useEffect(() => {
    setNode(nodeData.current);
  }, [nodeData.current]);

  useEffect(() => {
    if (nodeData.current) {
      nodeData.current.addEventListener("scroll", () => {
        const scrollableHeight =
          nodeData.current?.scrollHeight - nodeData.current?.clientHeight;

        if (nodeData.current?.scrollTop >= scrollableHeight) {
          setstart((prev) => prev + 10);
        }
      });
    }
  }, [node]);

  useEffect(() => {
    if (activeAccrodian) {
      getInsightList();
    }
  }, [start, activeAccrodian]);

  // useEffect(() => {
  //   getInsightList();
  // }, []);

  useEffect(() => {
    if (propertyDetailFromRTK || propertyId) {
      if (activeTab === "Notes") {
        getNotesDetail();
      }
    }
  }, [propertyDetailFromRTK, activeTab]);

  useEffect(() => {
    if (noteDetail) {
      refForScroll.current?.scrollIntoView({
        block: "end",
      });
    }
  }, [noteDetail]);

  const getDateKeysArr = () => {
    let arr = Object.keys(noteDetail?.notedata);
    return arr;
  };

  const getNotificationCount = async () => {
    let notificationCountResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.Notification +
          API_URL.NOTIFICATION.GET_NOTIFICATION_READ_COUNT,
        body: {
          propertycode: propertyDetailFromRTK
            ? propertyDetailFromRTK?.propertycode
            : "",
        },
      },
      true
    );

    let response = apiResponse(false, notificationCountResponse);

    if (response?.isValidate) {
      dispatch(setAllNotificationCount(response?.data?.data));
    }
  };

  const updateNotificationView = async (id) => {
    let notificationUpdateResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.Notification +
          API_URL.NOTIFICATION.UPDATE_VIEW_BY,
        body: {
          notificationbyid: id,
          userid: userDetail?.userid,
        },
      },
      true
    );

    let response = apiResponse(false, notificationUpdateResponse);

    if (response?.isValidate) {
      getNotificationCount();
    }
  };

  const isElementVisible = () => {
    insightList?.forEach((item) => {
      let ele = document.getElementById(`${item?.notificationid}`);
      const rect = ele.getBoundingClientRect();

      if (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <=
          (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <=
          (window.innerWidth || document.documentElement.clientWidth) &&
        item?.isread === false
      ) {
        updateNotificationView(item?.notificationid);
      }
    });
  };

  useEffect(() => {
    isElementVisible();
  }, [insightList]);

  return (
    <div className="insightOffcanvasWrapper">
      <Offcanvas
        show={show}
        onHide={handleClose}
        placement="end"
        className="insightOffcanvasWrapper"
      >
        <Offcanvas.Header closeButton className="insightOffcanvasMain">
          <Offcanvas.Title>Insights</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body id="canvas">
          <Tabs
            defaultActiveKey="Insights"
            id="uncontrolled-tab-example"
            variant="underline"
            className="mb-3"
            onSelect={(k) => {
              setActiveTab(k);
            }}
          >
            <Tab eventKey="Insights" title="Insights" className="mt-3">
              {activeTab === "Insights" && (
                <Row>
                  <Col xl={12}>
                    <div className="insightsAccordion">
                      <Accordion
                        onSelect={(k) => {
                          setInsightList([]);
                          setActiveAccrodian(k);
                          setstart(0);
                        }}
                      >
                        {notificationType?.map((item) => {
                          if (item?.associationtype !== "All") {
                            return (
                              <Accordion.Item eventKey={item?.associationtype}>
                                <Accordion.Header>
                                  {item?.associationtype}{" "}
                                  {item?.noofcount ? (
                                    <span className={"unreadnotificationcount"}>
                                      {item?.noofcount}
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </Accordion.Header>
                                <Accordion.Body
                                  style={{
                                    maxHeight: "400px",
                                    overflowY: "auto",
                                  }}
                                  id={item?.associationtype}
                                >
                                  {insightList?.length > 0
                                    ? insightList?.map(
                                        (notificationData, index) => {
                                          let notificationTime = "";
                                          if (
                                            commonService.getDateInFormat(
                                              new Date(new Date().toUTCString())
                                            ) ===
                                            commonService.getDateInFormat(
                                              notificationData?.notificationdatetime
                                            )
                                          ) {
                                            let dif =
                                              new Date(
                                                new Date().getUTCFullYear(),
                                                new Date().getUTCMonth(),
                                                new Date().getUTCDate(),
                                                new Date().getUTCHours(),
                                                new Date().getUTCMinutes()
                                              ) -
                                              new Date(
                                                notificationData?.notificationdatetime
                                              );
                                            let totalMinutes = Math.round(
                                              dif / 1000 / 60
                                            );
                                            if (totalMinutes > 59) {
                                              notificationTime =
                                                Math.round(totalMinutes / 60) +
                                                " hours ago";
                                            } else {
                                              if (totalMinutes > 0) {
                                                notificationTime =
                                                  totalMinutes + " min ago";
                                              } else {
                                                notificationTime = "sec ago";
                                              }
                                            }
                                          } else {
                                            notificationTime =
                                              commonService.getDateInFormat(
                                                notificationData?.notificationdatetime
                                              );
                                          }

                                          let notificationDetail =
                                            notificationData?.details;
                                          let title =
                                            notificationData?.title?.split(
                                              "]:"
                                            );
                                          let color = "#7F8C8D";
                                          return (
                                            <>
                                              <div
                                                className={`notificationMain ${
                                                  notificationData?.isread ===
                                                  false
                                                    ? "unreadnotification"
                                                    : ""
                                                } `}
                                                id={
                                                  notificationData?.notificationid
                                                }
                                              >
                                                <div className="notification-wrapper">
                                                  <div className="notification-name-time">
                                                    <p className="notification-name">
                                                      {
                                                        notificationData?.notificationbyname
                                                      }
                                                    </p>
                                                    <p>|</p>
                                                    <p className="notification-date">
                                                      {notificationTime}
                                                    </p>
                                                  </div>
                                                  <div className="notification-ttile-detail">
                                                    {title?.length > 1 ? (
                                                      <h4>
                                                        <span>{`${title?.[0]}]:`}</span>
                                                        {title?.[1]}
                                                      </h4>
                                                    ) : (
                                                      <h4>
                                                        {
                                                          notificationData?.title
                                                        }
                                                      </h4>
                                                    )}
                                                    <div
                                                      className="notification-detail"
                                                      dangerouslySetInnerHTML={{
                                                        __html:
                                                          notificationData?.details,
                                                      }}
                                                    />
                                                    {/* <p className="notification-detail">
                                                      {
                                                        notificationData?.details
                                                      }
                                                    </p> */}
                                                  </div>
                                                </div>
                                              </div>
                                            </>
                                          );
                                        }
                                      )
                                    : !insightLoading && (
                                        <div className="text-center">
                                          No Data Found
                                        </div>
                                      )}
                                  {insightLoading && <Loader />}
                                </Accordion.Body>
                              </Accordion.Item>
                            );
                          }
                        })}
                      </Accordion>
                    </div>
                  </Col>
                </Row>
              )}
            </Tab>
            <Tab eventKey="Notes" title="Notes">
              {activeTab === "Notes" && (
                <Row>
                  <Col xl={12} className="insightStickyDatePicker">
                    <DatePicker
                      onChange={(event) => handleDateChange(event)}
                      startDate={startRangeDate}
                      endDate={endRangeDate}
                      selectsRange
                      monthsShown={2}
                    />
                  </Col>
                  <Col xl={12} className="mt-3">
                    <div className="insightNotesMain-inner-wrapper">
                      {loading ? (
                        <Loader />
                      ) : noteDetail?.notedata ? (
                        <Accordion
                          defaultActiveKey={getDateKeysArr()}
                          alwaysOpen
                        >
                          {Object.entries(noteDetail?.notedata)?.map(
                            ([key, value], index) => {
                              return (
                                <Accordion.Item eventKey={key}>
                                  <Accordion.Header title={key}>
                                    {/* 22/11 */}
                                    {moment(key).format("MM/DD")}
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <div className="all-insights-notes-wrapper">
                                      {value?.map((item, index) => {
                                        let fileData =
                                          noteDetail?.filedata?.filter(
                                            (x) =>
                                              x?.associationid ===
                                              item?.widgetnoteid
                                          );
                                        return (
                                          <div className="insights-notes-single-notes d-block">
                                            <div className="insights-notes-single-notes-inner">
                                              <div className="insightNotesInnerLogo">
                                                <img
                                                  src={item?.profilepic}
                                                  alt="profilepic"
                                                />
                                              </div>
                                              <div className="insightNotesInnerContent">
                                                <div className="insightNotesInnerContent d-flex justify-content-between align-items-start w-100">
                                                  <div className="insightNotesInnerEma">
                                                    <h5>{item?.displayname}</h5>
                                                    <p className="d-flex gap-1 flex-wrap align-items-center">
                                                      <Badge
                                                        className="default-badge orange-badge"
                                                        title={
                                                          item?.propertyname
                                                        }
                                                      >
                                                        {item?.propertyname
                                                          ?.length > 10
                                                          ? `${item?.propertyname?.slice(
                                                              0,
                                                              9
                                                            )}...`
                                                          : item?.propertyname}
                                                      </Badge>{" "}
                                                      <Badge
                                                        className="default-badge tomato-badge"
                                                        title={item?.widgetname}
                                                      >
                                                        {item?.widgetname
                                                          ?.length > 10
                                                          ? `${item?.widgetname?.slice(
                                                              0,
                                                              9
                                                            )}...`
                                                          : item?.widgetname}
                                                      </Badge>
                                                    </p>
                                                  </div>
                                                  <div className="insightNotesInnerEmaDate d-flex align-items-center gap-2">
                                                    <p>
                                                      {moment(
                                                        item?.createdon
                                                      ).format("MM/DD/YYYY")}
                                                    </p>
                                                    <p>
                                                      {/* {commonService.getDateInFormat(
                                                      item?.createdon
                                                    )} */}

                                                      {moment(
                                                        item?.createdon
                                                      ).format("h:mm a")}
                                                    </p>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            {htmlToText(item?.widgetnotes)
                                              ?.length > 100 ? (
                                              <div className="insights-notes-detail">
                                                {`${htmlToText(
                                                  item?.widgetnotes
                                                ).slice(0, 100)}`}
                                                <span
                                                  className="read-more-device ps-2"
                                                  onClick={() => {
                                                    clickedNoteDetail.current =
                                                      item;
                                                    setViewWidgetNote(true);
                                                  }}
                                                >
                                                  More...
                                                </span>
                                              </div>
                                            ) : (
                                              <div className="insights-notes-detail">{`${htmlToText(
                                                item?.widgetnotes
                                              )}`}</div>
                                            )}
                                            {/* <div
                                            className="insights-notes-detail"
                                            dangerouslySetInnerHTML={{
                                              __html:
                                                item?.widgetnotes?.length > 100
                                                  ? item?.widgetnotes?.slice(
                                                      0,
                                                      100
                                                    ) + "More.."
                                                  : item?.widgetnotes,
                                            }}
                                            title={item?.widgetnotes}
                                          /> */}
                                            <div className="d-flex align-items-center gap-2 mt-2">
                                              {fileData?.map(
                                                (fileItem, fileIndex) => {
                                                  if (
                                                    fileItem?.filetype ===
                                                    "FILE"
                                                  ) {
                                                    return (
                                                      <a
                                                        target="_blank"
                                                        href={
                                                          fileItem?.attachmentpath
                                                        }
                                                      >
                                                        <img
                                                          src={pdfIcon}
                                                          className="fileIcon"
                                                        />
                                                      </a>
                                                    );
                                                  } else if (
                                                    fileItem?.filetype ===
                                                    "IMAGE"
                                                  ) {
                                                    return (
                                                      <a
                                                        target="_blank"
                                                        href={
                                                          fileItem?.attachmentpath
                                                        }
                                                      >
                                                        <img
                                                          src={imageIcon}
                                                          className="fileIcon"
                                                        />
                                                      </a>
                                                    );
                                                  } else {
                                                    return (
                                                      <a
                                                        target="_blank"
                                                        href={
                                                          fileItem?.attachmentpath
                                                        }
                                                      >
                                                        <img
                                                          src={folderIcon}
                                                          className="fileIcon"
                                                        />
                                                      </a>
                                                    );
                                                  }
                                                }
                                              )}
                                            </div>
                                            {/* <div className="d-flex align-items-center gap-2 mt-2">
                                            <a
                                              target="_blank"
                                              // href={fileItem?.attachmentpath}
                                            >
                                              <img
                                                src={pdfIcon}
                                                className="fileIcon"
                                              />
                                            </a>
                                            <a
                                              target="_blank"
                                              // href={fileItem?.attachmentpath}
                                            >
                                              <img
                                                src={folderIcon}
                                                className="fileIcon"
                                              />
                                            </a>
                                            <a
                                              target="_blank"
                                              // href={fileItem?.attachmentpath}
                                            >
                                              <img
                                                src={imageIcon}
                                                className="fileIcon"
                                              />
                                            </a>
                                          </div> */}
                                          </div>
                                        );
                                      })}
                                    </div>
                                  </Accordion.Body>
                                </Accordion.Item>
                              );
                            }
                          )}
                        </Accordion>
                      ) : (
                        <div className="d-flex justify-content-center">
                          <p>No Data Found</p>
                        </div>
                      )}
                    </div>
                    <div ref={refForScroll}></div>

                    {/* <div className="insightNotesMain">
                    <Row>
                      <Col xl={2}>
                        <div className="insightNotesInnerLogo">
                          <img src={logo} alt="logo" />
                        </div>
                      </Col>
                      <Col xl={10} className="m-auto ps-0">
                        <div className="insightNotesInnerContent d-flex justify-content-between align-items-center">
                          <div className="insightNotesInnerEma">
                            <h5>EMA Admin</h5>
                            <p>04:15PM</p>
                          </div>
                          <div className="insightNotesInnerEmaDate">
                            <p>08-17-2023</p>
                          </div>
                        </div>
                      </Col>
                      <Col xl={2}></Col>
                      <Col xl={10} className="m-auto ps-0">
                        <p className="insightNotesInnerBottom">
                          Today's ADR is $76 very much close to ForecastedADR
                          and also near value compare with BudgetedADR. for next
                          few days just need to compare with Competitor Future
                          Day Rates
                        </p>
                      </Col>
                    </Row>
                  </div> */}
                  </Col>
                </Row>
              )}
            </Tab>
          </Tabs>
        </Offcanvas.Body>
      </Offcanvas>
      {viewWidgetNote && (
        <ViewWidgetNoteModal
          showModal={viewWidgetNote}
          closeModal={closeViewWidgetNoteModal}
          noteData={clickedNoteDetail.current}
        />
      )}
    </div>
  );
}

export default RmInsight;
