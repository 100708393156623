import React, { useEffect, useRef, useState } from "react";
import { apiCall } from "../../../../utils/axiosService";
import { API_URL } from "../../../../utils/apiRoutes";
import { apiResponse } from "../../../../utils/apiResponse";
import { commonService } from "../../../../utils/commonService";
import { Button, Col } from "react-bootstrap";
import HorizontalBarChart from "../../../CommonComponent/HorizontalBarChart";
import { Bar } from "react-chartjs-2";
import {
  Chart,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
} from "chart.js";
import { compName, month } from "../../../../utils/constant";
import { useSelector } from "react-redux";
import WidgetDetailModal from "../../WidgetDetailModal";
import WidgetNote from "../../WidgetNote";
import infoIcon from "../../../../Assets/Images/Action Icons/infoIcon..svg";
import NoteIcon from "../../../../Assets/Images/CRM/notes.svg";
import AddWidgetModal from "../../AddWidgetModal";
import Comments from "../Comments/Comment";

const AnnualSummaryChart = ({
  setCompDataFromChild,
  rowIndex,
  colIndex,
  api,
  para,
  setting,
}) => {
  const yearsArr = [
    ...commonService.getPastYearForDropDown(5),
    ...commonService.getFutureYearForDropDown(5),
    new Date().getFullYear(),
  ]?.sort(function (a, b) {
    return a - b;
  });
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const labels = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  let EmaSpider = {
    label: "Forecast Revenue",
    backgroundColor: "#4F98CA",
    data: [],
    stack: "stack1",
  };
  let OTB = {
    label: "OTB Revenue",
    backgroundColor: "#3EB978",
    data: [],
    stack: "stack1",
  };

  let Budget = {
    label: "Budget",
    backgroundColor: "#8C8C8C",
    data: [],
  };

  const [chartData, setChartData] = useState(null);
  const [hoverValue, setHoverValue] = useState(null);

  const [loading, setLoading] = useState(false);
  const [showNoteSidebar, setShowNoteSidebar] = useState(false);
  const [widgetModal, setWidgetModal] = useState(false);
  const widgetDetailRef = useRef();
  const propertyDetailFromRTK = useSelector(
    (store) => store.headerProperty.propertyDetail
  );
  const closeWidgetModal = () => {
    setWidgetModal(false);
  };
  const closeNoteSideBar = () => {
    setShowNoteSidebar(false);
  };

  const getChartDetail = async () => {
    let chartDetailResponse = await apiCall(
      {
        method: "POST",
        url: API_URL.BASE_API_URL.SnapShot + API_URL.ANNUAL_SUMMARY.CHART,
        body: {
          AsOfDate: para?.asOfDate,
          year: `${new Date().getFullYear()}`,
          propertyCode: para?.propertyCode,
        },
      },
      false
    );

    let response = apiResponse(false, chartDetailResponse);

    if (response?.isValidate) {
      let dataFromApi = response?.data?.data;
      let spiderPr = [],
        otbPr = [],
        budgetPr = [];

      let spiderHover = [],
        otbHover = [],
        budgetHover = [];

      for (let i = 0; i < month?.length; i++) {
        var spiderPrItem = dataFromApi.find((item) => {
          if (item.result_type === "fc_EMA" && item.month?.trim() == month[i]) {
            return item;
          }
          return null;
        });
        spiderPr.push(spiderPrItem ? spiderPrItem.rev : 0);

        var OTBItem = dataFromApi.find((item) => {
          if (item.result_type === "OTB" && item.month?.trim() == month[i]) {
            return item;
          }
          return null;
        });
        otbPr.push(OTBItem ? OTBItem.rev : 0);

        var budgetItem = dataFromApi.find((item) => {
          if (item.result_type === "budget" && item.month?.trim() == month[i]) {
            return item;
          }
          return null;
        });
        budgetPr.push(budgetItem ? budgetItem.rev : 0);
      }

      otbPr = otbPr.map(function (x, index) {
        otbHover.push(x);
        return Math.round((x * 100) / budgetPr[index], 2);
      });

      spiderPr = spiderPr.map(function (x, index) {
        spiderHover.push(x);
        return Math.round((x * 100) / budgetPr[index], 2);
      });

      budgetPr = budgetPr.map(function (x, index) {
        budgetHover.push(x);
        return 100;
      });
      EmaSpider.data = [];
      Budget.data = [];
      OTB.data = [];
      EmaSpider.data = spiderPr;
      Budget.data = budgetPr;
      OTB.data = otbPr;
      setChartData([EmaSpider, Budget, OTB]);
      setHoverValue({
        spiderHover,
        otbHover,
        budgetHover,
      });
    }

    if (!response?.isValidate) {
      let arr = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      EmaSpider.data = arr;
      Budget.data = arr;
      OTB.data = arr;

      let spiderHover = [10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10],
        otbHover = [10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10],
        budgetHover = [10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10];
      setChartData([EmaSpider, Budget, OTB]);
      setHoverValue({
        spiderHover,
        otbHover,
        budgetHover,
      });
    }
  };

  useEffect(() => {
    if (api === 0) {
      Budget.data = [
        100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100,
      ];
      EmaSpider.data = [
        100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100,
      ];
      OTB.data = [100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100];
      setChartData([EmaSpider, Budget, OTB]);
    } else {
      if (para?.asOfDate) {
        getChartDetail();
      }
    }
  }, [selectedYear, para?.asOfDate]);
  let chartDetail = {};
  if (chartData) {
    chartDetail = {
      labels: labels,
      datasets: [
        {
          type: "bar",
          label: "OTB Revenue",
          backgroundColor: "#3EB978",
          data: chartData?.[2].data,
          stack: chartData?.[2].stack || "default",
        },
        {
          type: "bar",
          label: "Forecast Revenue",
          backgroundColor: "#4F98CA",
          data: chartData?.[0].data,
          stack: chartData?.[0].stack || "default",
        },
        {
          type: "line",
          label: "Budget Revenue",
          backgroundColor: "#8C8C8C",
          data: chartData?.[1].data,
          stack: chartData?.[1].stack || "default",
        },
      ],
    };
  }

  let chartOptions = {
    indexAxis: "y",
    scales: {
      x: {
        beginAtZero: true,
        stacked: false,
        title: {
          display: true,
          text: "",
        },
        categorySpacing: 100000,
        ticks: {
          callback: function (value) {
            return value + "%";
          },
        },
      },
      y: {
        title: {
          display: true,
          text: "",
        },
        grid: {
          display: false,
        },
        offset: true,
        ticks: {
          align: "start",
          autoSkip: false,
        },
      },
    },

    plugins: {
      legend: {
        display: true,
        align: "end",
        position: "top",
        maxWidth: 20,
        maxHeight: 100,
        labels: {
          boxWidth: 10,
          boxHeight: 10,
        },
      },
      tooltip: {
        enabled: true,
        mode: "index",
        callbacks: {
          title: function (context) {
            return context[0].label;
          },
          label: function (context) {
            let label = context.dataset.label;
            let displayValue;
            if (label === "Forecast Revenue") {
              displayValue = hoverValue?.spiderHover[context.dataIndex];
            } else if (label === "OTB Revenue") {
              displayValue = hoverValue?.otbHover[context.dataIndex];
            } else if (label === "Budget Revenue") {
              displayValue = hoverValue?.budgetHover[context.dataIndex];
            }

            return `${label}: ${displayValue}`;
          },
        },
      },
    },
    responsive: true,
    barThickness: 12,
    categorySpacing: 0.3,
    barPercentage: 0.8,
    categoryPercentage: 0.9,
  };

  Chart.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Tooltip,
    Legend,
    PointElement,
    LineElement
  );

  return (
    <div className="widget-container">
      {api !== 0 && (
        <div className="gap-1 widget-container-menu">
          {/* <Button className="heading-menu-btns" type="button">
            <img
              src={NoteIcon}
              alt="menuIcon"
              onClick={() => {
                setShowNoteSidebar(true);
              }}
            />
          </Button> */}
          <Button className="heading-menu-btns" type="button">
            <img
              src={infoIcon}
              alt="info"
              onClick={() => {
                widgetDetailRef.current = {
                  widgetdetails: "",
                  usernote: "",
                  compName: compName?.annualSummaryChart,
                  widgetId: para?.widgetId,
                };
                setWidgetModal(true);
              }}
            />
          </Button>
          {/* <Button className="heading-menu-btns" type="button">
            <img src={downloadIcon} alt="menuIcon" />
          </Button> */}
        </div>
      )}
      {chartData && (
        <>
          <div className="ForecastHorizontalChartInner">
            <Bar data={chartDetail} options={chartOptions} />
          </div>
          {api !== 0 && (
            <Comments widgetDetails={para} editorId={"AnnualSummaryChart"} />
          )}
        </>
      )}
      {/* {showNoteSidebar && (
        <WidgetNote
          show={showNoteSidebar}
          handleClose={closeNoteSideBar}
          widgetId={para?.widgetId}
          propertyId={propertyDetailFromRTK?.propertyid}
          rowTitle={para?.rowTitle}
        />
      )} */}
      {showNoteSidebar && (
        <AddWidgetModal
          showModal={showNoteSidebar}
          closeModal={closeNoteSideBar}
          widgetId={para?.widgetId}
          propertyId={propertyDetailFromRTK?.propertyid}
          rowTitle={para?.rowTitle}
        />
      )}
      {widgetModal && (
        <WidgetDetailModal
          showModal={widgetModal}
          closeModal={closeWidgetModal}
          data={widgetDetailRef.current}
        />
      )}
    </div>
  );
};

export default AnnualSummaryChart;
