import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { apiCall } from "../../../../utils/axiosService";
import { commonService } from "../../../../utils/commonService";
import { API_URL } from "../../../../utils/apiRoutes";
import { apiResponse } from "../../../../utils/apiResponse";
import { compName, month } from "../../../../utils/constant";
import { Button, Table } from "react-bootstrap";
import Loader from "../../../Loader/Loader";
import downArrow from "../../../../Assets/Images/Header/downArrow.svg";
import downloadIcon from "../../../../Assets/Images/Operation/downloadIcon.svg";
import infoIcon from "../../../../Assets/Images/Action Icons/infoIcon..svg";
import emailIcon from "../../../../Assets/Images/Email.svg";
import AddWidgetModal from "../../AddWidgetModal";
import WidgetDetailModal from "../../WidgetDetailModal";
import Comments from "../Comments/Comment";
import moment from "moment";

const ChannelMonthlyDrilldown = ({
  setCompDataFromChild,
  rowIndex,
  colIndex,
  api,
  para,
  setting,
}) => {
  const propertyDetailFromRTK = useSelector(
    (store) => store.headerProperty.propertyDetail
  );
  const defaultAsOfDateFromRTK = useSelector(
    (store) => store.headerProperty.defaultAsOfDate
  );
  const { snapshottitle, propertyCode, snapshotId } = useParams();
  const currentYear = new Date().getFullYear();
  const yearStartDate = new Date(currentYear, 0, 1);
  const yearEndDate = new Date(currentYear, 11, 31);
  let date = new Date(para?.asOfDate);
  let findstartDateMonth = new Date(date.setMonth(date.getMonth() - 11));

  let startDate = new Date(
    findstartDateMonth.getFullYear(),
    findstartDateMonth.getMonth(),
    1
  );

  let dateForEnd = new Date(para?.asOfDate);
  var endDate = new Date(
    dateForEnd.getFullYear(),
    dateForEnd.getMonth() + 1,
    0
  );
  const startDateRef = useRef();
  const endDateRef = useRef();
  startDateRef.current = new Date(startDate);
  endDateRef.current = new Date(endDate);
  const [dateRange, setDateRange] = useState({
    startDate: new Date(startDate),
    endDate: new Date(endDate),
  });
  const [loading, setLoading] = useState(false);
  const rateCodeDataRef = useRef(false);
  const companyDataRef = useRef(false);
  const guestDataRef = useRef(false);
  const [segmentDrilldownData, setSegmentDrilldownData] = useState(null);
  const clickedTrRef = useRef();
  const selectedMarketSegmentRef = useRef();
  const selectedRateCodeRef = useRef();
  const selectedCompanyRef = useRef();
  const calledMarketSegmentRef = useRef([]);
  const [msLoading, setMsLoading] = useState(false);
  const calledRateCodeRef = useRef([]);
  const [rateCodeLoading, setRateCodeLoading] = useState(false);
  const calledCompanyRef = useRef([]);
  const [companyLoading, setCompanyLoading] = useState(false);
  const [showNoteSidebar, setShowNoteSidebar] = useState(false);
  const [widgetModal, setWidgetModal] = useState(false);
  const widgetDetailRef = useRef();
  const monthArrRef = useRef();
  const showOnApiCallTrRef = useRef();
  const maxRef = useRef();
  const minRef = useRef();

  const closeWidgetModal = () => {
    setWidgetModal(false);
  };
  const closeNoteSideBar = () => {
    setShowNoteSidebar(false);
  };

  const showOnApiCall = (idArray) => {
    const classList = document.getElementsByClassName(idArray);
    for (let i = 0; i < classList?.length; i++) {
      if (classList[i].classList.contains("nestedTableRowNone")) {
        classList[i].classList.remove("nestedTableRowNone");
        classList[i].classList.add("singleDateSummmaryTr");
      }
    }
  };

  const handleShowHideRow = (className, itemDetail) => {
    if (document.getElementById(className) !== null) {
      if (document.getElementById(className).classList.contains("active")) {
        document.getElementById(className).classList.remove("active");
      } else {
        document.getElementById(className).classList.add("active");
      }
    }

    const removeClass = (classNameDetail) => {
      const classList = document.getElementsByClassName(classNameDetail);
      for (let i = 0; i < classList?.length; i++) {
        if (classList[i].classList.contains("nestedTableRowNone")) {
          classList[i].classList.remove("nestedTableRowNone");
          classList[i].classList.add("singleDateSummmaryTr");
        } else {
          classList[i].classList.add("nestedTableRowNone");
          classList[i].classList.remove("singleDateSummmaryTr");
        }
      }
    };

    removeClass(className);

    const addChildClass = (childClassName) => {
      const classList = document.getElementsByClassName(childClassName);
      for (let i = 0; i < classList?.length; i++) {
        if (!classList[i].classList.contains("nestedTableRowNone")) {
          classList[i].classList.add("nestedTableRowNone");
          classList[i].classList.remove("singleDateSummmaryTr");
        }
      }
    };

    const removeAllClass = (childItems) => {
      for (let i = 0; i < childItems?.length; i++) {
        addChildClass(childItems[i]?.guid);

        if (
          Array.isArray(childItems[i]?.items) &&
          childItems[i]?.items?.length > 0
        ) {
          removeAllClass(childItems[i]?.items);
        }
      }
    };

    if (Array.isArray(itemDetail?.items) && itemDetail?.items?.length > 0) {
      removeAllClass(itemDetail?.items);
    }
  };

  const checkActiveRow = (e, guid, itemDetail) => {
    if (e.currentTarget.classList.contains("active")) {
      e.currentTarget.classList.remove("active");
      e.currentTarget.classList.remove("singleDateSummmaryTr");
    } else {
      e.currentTarget.classList.add("active");
      e.currentTarget.classList.add("singleDateSummmaryTr");
    }
  };

  const getMarketSegment = async () => {
    setLoading(true);
    let msResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.SnapShot +
          API_URL.CHANNEL_SEGMENT_DRILLDOWN.GET_MONTHLY_CHANNEL,
        body: {
          AsOfDate: para?.asOfDate,
          startDate: commonService.getDateForNode(startDateRef.current),
          endDate: commonService.getDateForNode(endDateRef.current),
          propertyCode: para?.propertyCode,
        },
      },
      false
    );

    let response = apiResponse(false, msResponse);

    if (response?.isValidate) {
      let dataFromApi = response?.data?.data;
      let monthArr = [];
      for (let i = 0; i < 12; i++) {
        let date = new Date(startDateRef.current);
        date.setMonth(date.getMonth() + i);
        let month = date.toLocaleString("en-US", { month: "long" });
        let year = date.getFullYear();
        monthArr = [...monthArr, `${month}-${year}`];
      }
      monthArrRef.current = monthArr.reverse();
      let groupingJson = [];
      let max = {
        January: "",
        February: "",
        March: "",
        April: "",
        May: "",
        June: "",
        July: "",
        August: "",
        September: "",
        October: "",
        November: "",
        December: "",
      };
      let min = {
        January: "",
        February: "",
        March: "",
        April: "",
        May: "",
        June: "",
        July: "",
        August: "",
        September: "",
        October: "",
        November: "",
        December: "",
      };
      let maxValue = {
        January: "",
        February: "",
        March: "",
        April: "",
        May: "",
        June: "",
        July: "",
        August: "",
        September: "",
        October: "",
        November: "",
        December: "",
      };
      let minValue = {
        January: "",
        February: "",
        March: "",
        April: "",
        May: "",
        June: "",
        July: "",
        August: "",
        September: "",
        October: "",
        November: "",
        December: "",
      };
      dataFromApi?.map((item, index) => {
        let itemExists = groupingJson?.filter(
          (x) => x?.name === item?.channelpartner
        );

        if (maxValue[item?.Month] === "") {
          if (item?.ADR && item?.ADR != 0) {
            maxValue[item?.Month] = parseInt(item?.ADR);
            max[item?.Month] = item?.channelpartner;
          }
        } else {
          if (item?.ADR) {
            if (parseInt(item?.ADR) > maxValue[item?.Month]) {
              maxValue[item?.Month] = parseInt(item?.ADR);
              max[item?.Month] = item?.channelpartner;
            }
          }
        }

        if (minValue[item?.Month] === "") {
          if (item?.ADR && item?.ADR != 0) {
            minValue[item?.Month] = parseInt(item?.ADR);
            min[item?.Month] = item?.channelpartner;
          }
        } else {
          if (item?.ADR) {
            if (parseInt(item?.ADR) < minValue[item?.Month]) {
              minValue[item?.Month] = parseInt(item?.ADR);
              min[item?.Month] = item?.channelpartner;
            }
          }
        }

        if (itemExists?.length <= 0) {
          let msData = dataFromApi?.filter(
            (x) => x?.channelpartner === item?.channelpartner
          );
          let monthWiseObj = {};
          let total = {
            RMS: 0,
            REV: 0,
            ADR: 0,
          };

          month?.map((monthName) => {
            let findMonthOfMs = msData?.filter(
              (x) => x?.Month?.trim() === monthName
            );
            monthWiseObj[monthName] = {
              RMS: findMonthOfMs?.[0]?.RMS ? findMonthOfMs?.[0]?.RMS : 0,
              REV: findMonthOfMs?.[0]?.REV ? findMonthOfMs?.[0]?.REV : 0,
              ADR: findMonthOfMs?.[0]?.ADR ? findMonthOfMs?.[0]?.ADR : 0,
            };

            total.RMS =
              total.RMS +
              (findMonthOfMs?.[0]?.RMS ? parseInt(findMonthOfMs?.[0]?.RMS) : 0);

            total.REV =
              total.REV +
              (findMonthOfMs?.[0]?.REV ? parseInt(findMonthOfMs?.[0]?.REV) : 0);

            total.ADR =
              total.ADR +
              (findMonthOfMs?.[0]?.ADR ? parseInt(findMonthOfMs?.[0]?.ADR) : 0);
          });
          item.items = [];
          item.guid = commonService.GenerateGUID();
          item.name = item?.channelpartner;
          item.total = total;
          let msDetail = { ...item, ...monthWiseObj };

          groupingJson = [...groupingJson, msDetail];
        }
      });
      maxRef.current = max;
      minRef.current = min;
      setSegmentDrilldownData(groupingJson);
      setLoading(false);
    }

    if (!response?.isValidate) {
      setSegmentDrilldownData(null);
      setLoading(false);
    }
  };

  const getRateCodeData = async () => {
    setMsLoading(true);
    let rateCodeResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.SnapShot +
          API_URL.CHANNEL_SEGMENT_DRILLDOWN.GET_MONTHLY_RATECODE,
        body: {
          AsOfDate: para?.asOfDate,
          startDate: commonService.getDateForNode(startDateRef.current),
          endDate: commonService.getDateForNode(endDateRef.current),
          propertyCode: para?.propertyCode,
          selectedChannelpartner: selectedMarketSegmentRef.current,
        },
      },
      false
    );

    let response = apiResponse(false, rateCodeResponse);

    if (response?.isValidate) {
      let dataFromApi = response?.data?.data;

      let groupingJson = [...segmentDrilldownData];
      let rateCodeGroup = [];

      dataFromApi?.forEach((item) => {
        let findMarketsegment = groupingJson?.findIndex(
          (x) => x?.name === item?.channelpartner
        );

        if (findMarketsegment >= 0) {
          let itemExists = rateCodeGroup?.filter(
            (x) => x?.name === item?.RateCode
          );
          if (itemExists?.length <= 0) {
            let msData = dataFromApi?.filter(
              (x) => x?.RateCode === item?.RateCode
            );
            let monthWiseObj = {};
            let total = {
              RMS: 0,
              REV: 0,
              ADR: 0,
            };
            month?.map((monthName) => {
              let findMonthOfMs = msData?.filter(
                (x) => x?.Month?.trim() === monthName
              );
              monthWiseObj[monthName] = {
                RMS: findMonthOfMs?.[0]?.RMS ? findMonthOfMs?.[0]?.RMS : 0,
                REV: findMonthOfMs?.[0]?.REV ? findMonthOfMs?.[0]?.REV : 0,
                ADR: findMonthOfMs?.[0]?.ADR ? findMonthOfMs?.[0]?.ADR : 0,
              };
              total.RMS =
                total.RMS +
                (findMonthOfMs?.[0]?.RMS
                  ? parseInt(findMonthOfMs?.[0]?.RMS)
                  : 0);
              total.REV =
                total.REV +
                (findMonthOfMs?.[0]?.REV
                  ? parseInt(findMonthOfMs?.[0]?.REV)
                  : 0);
              total.ADR =
                total.ADR +
                (findMonthOfMs?.[0]?.ADR
                  ? parseInt(findMonthOfMs?.[0]?.ADR)
                  : 0);
            });
            item.name = item?.RateCode;
            item.items = [];
            item.guid = commonService.GenerateGUID();
            item.total = total;
            let ratecodeDetail = { ...item, ...monthWiseObj };
            rateCodeGroup = [...rateCodeGroup, ratecodeDetail];

            groupingJson[findMarketsegment].items = [
              ...groupingJson?.[findMarketsegment]?.items,
              ratecodeDetail,
            ];
          }
        }
      });
      calledMarketSegmentRef.current = [
        ...calledMarketSegmentRef.current,
        selectedMarketSegmentRef.current,
      ];

      setSegmentDrilldownData(groupingJson);
      setMsLoading(false);
    }

    if (!response?.isValidate) {
      setMsLoading(false);
    }
  };

  const getCompanyData = async () => {
    setRateCodeLoading(true);
    let rateCodeResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.SnapShot +
          API_URL.CHANNEL_SEGMENT_DRILLDOWN.GET_MONTHLY_COMPANY,
        body: {
          AsOfDate: para?.asOfDate,
          startDate: commonService.getDateForNode(startDateRef.current),
          endDate: commonService.getDateForNode(endDateRef.current),
          propertyCode: para?.propertyCode,
          selectedChannelpartner: selectedMarketSegmentRef.current,
          selectedRateCode: selectedRateCodeRef.current,
        },
      },
      false
    );

    let response = apiResponse(false, rateCodeResponse);

    if (response?.isValidate) {
      let dataFromApi = response?.data?.data;

      let groupingJson = [...segmentDrilldownData];
      let companyGroup = [];

      dataFromApi?.forEach((item) => {
        let findMarketsegment = groupingJson?.findIndex(
          (x) => x?.name === item?.channelpartner
        );
        let findRatecode = groupingJson[findMarketsegment].items?.findIndex(
          (x) => x?.name === item?.RateCode
        );

        if (findRatecode >= 0) {
          let itemExists = companyGroup?.filter(
            (x) => x?.name === item?.Company
          );
          if (itemExists?.length <= 0) {
            let msData = dataFromApi?.filter(
              (x) => x?.Company === item?.Company
            );
            let monthWiseObj = {};
            let total = {
              RMS: 0,
              REV: 0,
              ADR: 0,
            };
            month?.map((monthName) => {
              let findMonthOfMs = msData?.filter(
                (x) => x?.Month?.trim() === monthName
              );
              monthWiseObj[monthName] = {
                RMS: findMonthOfMs?.[0]?.RMS ? findMonthOfMs?.[0]?.RMS : 0,
                REV: findMonthOfMs?.[0]?.REV ? findMonthOfMs?.[0]?.REV : 0,
                ADR: findMonthOfMs?.[0]?.ADR ? findMonthOfMs?.[0]?.ADR : 0,
              };
              total.RMS =
                total.RMS +
                (findMonthOfMs?.[0]?.RMS
                  ? parseInt(findMonthOfMs?.[0]?.RMS)
                  : 0);
              total.REV =
                total.REV +
                (findMonthOfMs?.[0]?.REV
                  ? parseInt(findMonthOfMs?.[0]?.REV)
                  : 0);
              total.ADR =
                total.ADR +
                (findMonthOfMs?.[0]?.ADR
                  ? parseInt(findMonthOfMs?.[0]?.ADR)
                  : 0);
            });
            item.name = item?.Company;
            item.items = [];
            item.guid = commonService.GenerateGUID();
            item.total = total;
            let companyDetail = { ...item, ...monthWiseObj };
            companyGroup = [...companyGroup, companyDetail];

            groupingJson[findMarketsegment].items[findRatecode].items = [
              ...groupingJson[findMarketsegment].items[findRatecode].items,
              companyDetail,
            ];
          }
        }
      });

      calledRateCodeRef.current = [
        ...calledRateCodeRef.current,
        {
          ms: selectedMarketSegmentRef.current,
          rc: selectedRateCodeRef.current,
        },
      ];
      setSegmentDrilldownData(groupingJson);
      setRateCodeLoading(false);
    }

    if (!response?.isValidate) {
      setRateCodeLoading(false);
    }
  };

  const getGuestData = async () => {
    setCompanyLoading(true);
    let guestResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.SnapShot +
          API_URL.CHANNEL_SEGMENT_DRILLDOWN.GET_MONTHLY_GUEST,
        body: {
          AsOfDate: para?.asOfDate,
          startDate: commonService.getDateForNode(startDateRef.current),
          endDate: commonService.getDateForNode(endDateRef.current),
          propertyCode: para?.propertyCode,
          selectedChannelpartner: selectedMarketSegmentRef.current,
          selectedRateCode: selectedRateCodeRef.current,
          selectedCompany: selectedCompanyRef.current,
        },
      },
      false
    );

    let response = apiResponse(false, guestResponse);

    if (response?.isValidate) {
      let dataFromApi = response?.data?.data;

      let groupingJson = [...segmentDrilldownData];
      let guestGroup = [];

      dataFromApi?.forEach((item) => {
        let findMarketsegment = groupingJson?.findIndex(
          (x) => x?.name === item?.channelpartner
        );
        let findRatecode = groupingJson[findMarketsegment].items?.findIndex(
          (x) => x?.name === item?.RateCode
        );

        let findCompany = groupingJson[findMarketsegment].items[
          findRatecode
        ].items?.findIndex((x) => x?.name === item?.Company);

        if (findCompany >= 0) {
          let itemExists = guestGroup?.filter(
            (x) => x?.name === item?.GuestName
          );
          if (itemExists?.length <= 0) {
            let msData = dataFromApi?.filter(
              (x) => x?.GuestName === item?.GuestName
            );
            let monthWiseObj = {};
            let total = {
              RMS: 0,
              REV: 0,
              ADR: 0,
            };
            month?.map((monthName) => {
              let findMonthOfMs = msData?.filter(
                (x) => x?.Month?.trim() === monthName
              );
              monthWiseObj[monthName] = {
                RMS: findMonthOfMs?.[0]?.RMS ? findMonthOfMs?.[0]?.RMS : 0,
                REV: findMonthOfMs?.[0]?.REV ? findMonthOfMs?.[0]?.REV : 0,
                ADR: findMonthOfMs?.[0]?.ADR ? findMonthOfMs?.[0]?.ADR : 0,
              };
              total.RMS =
                total.RMS +
                (findMonthOfMs?.[0]?.RMS
                  ? parseInt(findMonthOfMs?.[0]?.RMS)
                  : 0);
              total.REV =
                total.REV +
                (findMonthOfMs?.[0]?.REV
                  ? parseInt(findMonthOfMs?.[0]?.REV)
                  : 0);
              total.ADR =
                total.ADR +
                (findMonthOfMs?.[0]?.ADR
                  ? parseInt(findMonthOfMs?.[0]?.ADR)
                  : 0);
            });
            item.name = item?.GuestName;
            item.items = [];
            item.guid = commonService.GenerateGUID();
            item.total = total;
            let guestDetail = { ...item, ...monthWiseObj };
            guestGroup = [...guestGroup, guestDetail];
            groupingJson[findMarketsegment].items[findRatecode].items[
              findCompany
            ].items = [
              ...groupingJson[findMarketsegment].items[findRatecode].items[
                findCompany
              ].items,
              guestDetail,
            ];
          }
        }
      });

      calledCompanyRef.current = [
        ...calledCompanyRef.current,
        {
          ms: selectedMarketSegmentRef.current,
          rc: selectedRateCodeRef.current,
          company: selectedCompanyRef.current,
        },
      ];
      setSegmentDrilldownData(groupingJson);
      setCompanyLoading(false);
    }

    if (!response?.isValidate) {
      setCompanyLoading(false);
    }
  };

  useEffect(() => {
    if (api === 0) {
    } else {
      if (para?.asOfDate) {
        getMarketSegment();
      }
    }
  }, [para?.asOfDate]);

  useLayoutEffect(() => {
    if (showOnApiCallTrRef.current) {
      showOnApiCall(showOnApiCallTrRef.current);
    }
  }, [
    rateCodeDataRef.current,
    companyDataRef.current,
    guestDataRef.current,
    segmentDrilldownData,
  ]);

  const tdData = (item) => {
    return (
      <>
        <td className="month-drilldown-color">
          {item?.total?.RMS ? commonService.formateRoom(item?.total?.RMS) : 0}
        </td>
        <td className="text-end month-drilldown-color">
          {item?.total?.ADR ? commonService.formateAmount(parseInt(item?.total?.ADR / 12)) : commonService.formateAmount(0)}
        </td>
        <td className="text-end border_right month-drilldown-color">
          {item?.total?.REV ? commonService.formateAmount(item?.total?.REV) : commonService.formateAmount(0)}
        </td>

        {monthArrRef.current?.map((monthItem, monthIndex) => {
          let showBgColor = false;
          let showGreenColor = false,
            showRedColor = false;
          if (monthIndex % 2 !== 0) {
            showBgColor = true;
          }
          let monthName = monthItem?.split("-")?.[0];
          if (maxRef.current[monthName] === item?.channelpartner) {
            showGreenColor = true;
          }
          if (minRef.current[monthName] === item?.channelpartner) {
            showRedColor = true;
          }
          return (
            <>
              <td
                className={`${showBgColor ? "month-drilldown-color" : ""} ${
                  showGreenColor ? "greenText" : ""
                } ${showRedColor ? "redText" : ""}`}
              >
                {item?.[monthName]?.RMS ? commonService.formateRoom(item?.[monthName]?.RMS) : 0}
              </td>
              <td
                className={`text-end ${
                  showBgColor ? "month-drilldown-color" : ""
                } ${showGreenColor ? "greenText" : ""} ${
                  showRedColor ? "redText" : ""
                }`}
              >
                {item?.[monthName]?.ADR ? commonService.formateAmount(item?.[monthName]?.ADR) : commonService.formateAmount(0)}
              </td>
              <td
                className={`text-end border_right ${
                  showBgColor ? "month-drilldown-color" : ""
                } ${showGreenColor ? "greenText" : ""} ${
                  showRedColor ? "redText" : ""
                }`}
              >
                {item?.[monthName]?.REV ? commonService.formateAmount(item?.[monthName]?.REV) : commonService.formateAmount(0)}
              </td>
            </>
          );
        })}
      </>
    );
  };

  const [tblids, settblids] = useState({
    channelWiseDropdown: commonService.GenerateGUID(),
  });
  return (
    <div className="widget-container">
      {api !== 0 && (
        <div className="gap-1 widget-container-menu">
          <Button className="heading-menu-btns" type="button" title="Send Mail">
            <img
              src={emailIcon}
              alt="emailIcon"
              onClick={() => {
                commonService.fnSendWidgetMail(
                  tblids.channelWiseDropdown,
                  "Channel Segment Drilldown",
                  snapshotId,
                  defaultAsOfDateFromRTK,
                  "",
                  para?.widgetId
                );
              }}
            />
          </Button>
          <Button className="heading-menu-btns" type="button">
            <img
              src={infoIcon}
              alt="info"
              onClick={() => {
                widgetDetailRef.current = {
                  widgetdetails: "",
                  usernote: "",
                  compName: compName?.monthlySegmentDrilldown,
                  widgetId: para?.widgetId,
                };
                setWidgetModal(true);
              }}
            />
          </Button>
          <Button className="heading-menu-btns" type="button">
            <img
              src={downloadIcon}
              alt="menuIcon"
              import={tblids.channelWiseDropdown}
              onClick={() =>
                commonService.fnExportInCSV(
                  tblids.channelWiseDropdown,
                  // "Channel Segment Drilldown"
                  `${para?.propertyCode}-Channel Segment Drilldown-${moment(
                    new Date()
                  ).format("MMDDYYYY")}`
                )
              }
            />
          </Button>
        </div>
      )}
      <div className="ViewPropertySetupTable">
        <div className="table__container">
          <div className="stripped__table">
            <Table id={tblids.channelWiseDropdown} responsive>
              <thead>
                <tr>
                  <th className="border_right width175"></th>
                  <th colSpan={3} className="border_right text-center">
                    Total
                  </th>
                  {monthArrRef.current?.map((item, index) => {
                    return (
                      <th
                        className="border_right text-center"
                        colSpan={3}
                        key={`${item}-${index}`}
                      >
                        {item}
                      </th>
                    );
                  })}
                </tr>
                <tr>
                  <th className="border_right">Channel</th>
                  <th>RMS</th>
                  <th className="text-end">ADR</th>
                  <th className="border_right text-end">REV</th>
                  {monthArrRef.current?.map((item, index) => {
                    return (
                      <>
                        <th>RMS</th>
                        <th className="text-end">ADR</th>
                        <th className="border_right text-end">REV</th>
                      </>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr style={{ height: "80px", position: "relative" }}>
                    <Loader />
                  </tr>
                ) : segmentDrilldownData ? (
                  segmentDrilldownData?.map((item, index) => {
                    return (
                      <>
                        <tr id={item?.guid} className="shadowOnHover">
                          <td className="border_right">
                            <div
                              className={`d-flex gap-2`}
                              onClick={() => {
                                let findSelectedMarketSegment =
                                  calledMarketSegmentRef.current?.filter(
                                    (x) => x === item?.name
                                  );

                                if (findSelectedMarketSegment?.length > 0) {
                                  const classList =
                                    document.getElementsByClassName(item?.guid);
                                  if (
                                    classList?.[0]?.classList?.contains(
                                      "nestedTableRowNone"
                                    )
                                  ) {
                                    handleShowHideRow(item?.guid);
                                  } else {
                                    handleShowHideRow(item?.guid, item);
                                  }
                                } else {
                                  if (!msLoading) {
                                    selectedMarketSegmentRef.current =
                                      item?.name;
                                    clickedTrRef.current = [item?.guid];
                                    showOnApiCallTrRef.current = item?.guid;
                                    getRateCodeData();
                                  }
                                }
                              }}
                            >
                              <p>{item?.name}</p>
                              <img src={downArrow} />

                              {msLoading &&
                                selectedMarketSegmentRef.current ===
                                  item?.name && (
                                  <div
                                    class="spinner-border text-primary ms-2"
                                    role="status"
                                    style={{
                                      height: "13px",
                                      width: "13px",
                                      color: "#3565FC",
                                    }}
                                  >
                                    <span class="sr-only"></span>
                                  </div>
                                )}
                            </div>
                          </td>
                          {tdData(item)}
                        </tr>
                        {Array.isArray(item?.items) &&
                          item?.items?.length > 0 &&
                          item?.items?.map((rateCodeItem, rateCodeIndex) => {
                            return (
                              <>
                                <tr
                                  className={`${item?.guid} nestedTableRowNone shadowOnHover`}
                                  id={rateCodeItem?.guid}
                                >
                                  <td className="border_right">
                                    <div
                                      style={{ paddingLeft: "10px" }}
                                      className="d-flex gap-2"
                                      onClick={() => {
                                        if (!rateCodeLoading) {
                                          selectedMarketSegmentRef.current =
                                            item?.name;
                                          selectedRateCodeRef.current =
                                            rateCodeItem?.name;
                                        }
                                        let findSelectedRateCode =
                                          calledRateCodeRef.current?.filter(
                                            (x) =>
                                              x?.ms ===
                                                selectedMarketSegmentRef.current &&
                                              x?.rc ===
                                                selectedRateCodeRef.current
                                          );

                                        if (findSelectedRateCode?.length > 0) {
                                          const classList =
                                            document.getElementsByClassName(
                                              rateCodeItem?.guid
                                            );

                                          if (
                                            classList?.[0]?.classList?.contains(
                                              "nestedTableRowNone"
                                            )
                                          ) {
                                            handleShowHideRow(
                                              rateCodeItem?.guid
                                            );
                                          } else {
                                            handleShowHideRow(
                                              rateCodeItem?.guid,
                                              rateCodeItem
                                            );
                                          }
                                        } else {
                                          if (!rateCodeLoading) {
                                            clickedTrRef.current = [
                                              ...clickedTrRef.current,
                                              rateCodeItem?.guid,
                                            ];
                                            showOnApiCallTrRef.current =
                                              rateCodeItem?.guid;
                                            getCompanyData();
                                          }
                                        }
                                      }}
                                    >
                                      <p>{rateCodeItem?.name}</p>
                                      <img src={downArrow} />
                                      {rateCodeLoading &&
                                        selectedMarketSegmentRef.current ===
                                          item?.name &&
                                        selectedRateCodeRef.current ===
                                          rateCodeItem?.name && (
                                          <div
                                            class="spinner-border text-primary ms-2"
                                            role="status"
                                            style={{
                                              height: "13px",
                                              width: "13px",
                                              color: "#3565FC",
                                            }}
                                          >
                                            <span class="sr-only"></span>
                                          </div>
                                        )}
                                    </div>
                                  </td>
                                  {tdData(rateCodeItem)}
                                </tr>
                                {Array.isArray(rateCodeItem?.items) &&
                                  rateCodeItem?.items?.length > 0 &&
                                  rateCodeItem?.items?.map(
                                    (companyItem, companyIndex) => {
                                      return (
                                        <>
                                          <tr
                                            className={`${rateCodeItem?.guid} nestedTableRowNone shadowOnHover`}
                                            id={companyItem?.guid}
                                          >
                                            <td className="border_right">
                                              <div
                                                style={{
                                                  paddingLeft: "20px",
                                                }}
                                                className="d-flex gap-2"
                                                onClick={() => {
                                                  if (!companyLoading) {
                                                    selectedMarketSegmentRef.current =
                                                      item?.name;
                                                    selectedRateCodeRef.current =
                                                      rateCodeItem?.name;
                                                    selectedCompanyRef.current =
                                                      companyItem?.name;
                                                  }
                                                  let findSelectedCompany =
                                                    calledCompanyRef.current?.filter(
                                                      (x) =>
                                                        x?.ms ===
                                                          selectedMarketSegmentRef.current &&
                                                        x?.rc ===
                                                          selectedRateCodeRef.current &&
                                                        x?.company ===
                                                          selectedCompanyRef.current
                                                    );

                                                  if (
                                                    findSelectedCompany?.length >
                                                    0
                                                  ) {
                                                    const classList =
                                                      document.getElementsByClassName(
                                                        companyItem?.guid
                                                      );

                                                    if (
                                                      classList?.[0]?.classList?.contains(
                                                        "nestedTableRowNone"
                                                      )
                                                    ) {
                                                      handleShowHideRow(
                                                        companyItem?.guid
                                                      );
                                                    } else {
                                                      handleShowHideRow(
                                                        companyItem?.guid,
                                                        companyItem
                                                      );
                                                    }
                                                  } else {
                                                    if (!companyLoading) {
                                                      clickedTrRef.current = [
                                                        ...clickedTrRef.current,
                                                        companyItem?.guid,
                                                      ];
                                                      showOnApiCallTrRef.current =
                                                        companyItem?.guid;
                                                      getGuestData();
                                                    }
                                                  }
                                                }}
                                              >
                                                <p>{companyItem?.name}</p>
                                                <img src={downArrow} />
                                                {companyLoading &&
                                                  selectedMarketSegmentRef.current ===
                                                    item?.name &&
                                                  selectedRateCodeRef.current ===
                                                    rateCodeItem?.name &&
                                                  selectedCompanyRef.current ===
                                                    companyItem?.name && (
                                                    <div
                                                      class="spinner-border text-primary ms-2"
                                                      role="status"
                                                      style={{
                                                        height: "13px",
                                                        width: "13px",
                                                        color: "#3565FC",
                                                      }}
                                                    >
                                                      <span class="sr-only"></span>
                                                    </div>
                                                  )}
                                              </div>
                                            </td>
                                            {tdData(companyItem)}
                                          </tr>
                                          {Array.isArray(companyItem?.items) &&
                                            companyItem?.items?.length > 0 &&
                                            companyItem?.items?.map(
                                              (guestItem, guestIndex) => {
                                                return (
                                                  <tr
                                                    className={`${companyItem?.guid} nestedTableRowNone shadowOnHover`}
                                                    onClick={(e) => {
                                                      checkActiveRow(e);
                                                    }}
                                                  >
                                                    <td className="border_right">
                                                      <div
                                                        style={{
                                                          paddingLeft: "30px",
                                                        }}
                                                        className="d-flex gap-2"
                                                      >
                                                        <p>{guestItem?.name}</p>
                                                      </div>
                                                    </td>
                                                    {tdData(guestItem)}
                                                  </tr>
                                                );
                                              }
                                            )}
                                        </>
                                      );
                                    }
                                  )}
                              </>
                            );
                          })}
                      </>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={40} className="text-center">
                      No Data Found
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
      {api !== 0 && <Comments widgetDetails={para} editorId={"ChannelMonthlyDrilldown"}/>}

      {showNoteSidebar && (
        <AddWidgetModal
          showModal={showNoteSidebar}
          closeModal={closeNoteSideBar}
          widgetId={para?.widgetId}
          propertyId={propertyDetailFromRTK?.propertyid}
          rowTitle={para?.rowTitle}
        />
      )}
      {widgetModal && (
        <WidgetDetailModal
          showModal={widgetModal}
          closeModal={closeWidgetModal}
          data={widgetDetailRef.current}
        />
      )}
    </div>
  );
};

export default ChannelMonthlyDrilldown;
