import React, { useEffect, useRef, useState } from "react";
import Loader from "../../Loader/Loader";
import { Table } from "react-bootstrap";
import { apiCall } from "../../../utils/axiosService";
import { API_URL } from "../../../utils/apiRoutes";
import { useSelector } from "react-redux";
import { apiResponse } from "../../../utils/apiResponse";
import { commonService } from "../../../utils/commonService";
import moment from "moment";
import EventViewModal from "../../CommonComponent/EventViewModal";
import ticket from "../../../Assets/Images/Revenue-management/tableTicket.svg";

const YieldOccModal = ({ closeModal, data, handleEventClick }) => {
  const propertyDetailFromRTK = useSelector(
    (store) => store.headerProperty.propertyDetail
  );
  const asOfDateFromRTK = useSelector((store) => store.headerProperty.asOfDate);
  const [loading, setLoading] = useState(false);
  const [occData, setOccData] = useState(null);
  const [eventList, setEventList] = useState(null);
  const eventListRef = useRef();
  const currentEventDataRef = useRef();
  const startDateRef = useRef();

  const getEventList = async () => {
    let eventList = await apiCall(
      {
        method: "POST",
        url: API_URL.BASE_API_URL.RevenueManagement + API_URL.EVENTS.GET_LIST,
        body: {
          pageNum: 1,
          pageSize: 10,
          sortColumn: "",
          sortDirection: "asc",
          searchValue: "",
          propertyid: parseInt(propertyDetailFromRTK?.propertyid),
          startdate: null,
          enddate: null,
          eventname: "",
          eventtypeterm: "",
          yearterm: ``,
        },
      },
      false
    );

    let eventListResponse = apiResponse(false, eventList);

    if (eventListResponse?.isValidate) {
      let eventListArr = [];

      eventListResponse?.data?.data?.forEach((item) => {
        let singleEventObj = {
          id: item?.eventid ? item?.eventid : 0,
          name: item?.eventname,
          detail: item?.eventdetails,
          startDate: item?.startdate,
          endDate: item?.enddate,
          eventJson: item?.eventjson,
          eventResourceId: item?.eventsourceid,
        };
        let eventJsonData = "";
        if (item?.eventjson) {
          var decodedString = decodeURIComponent(item?.eventjson);
          const jsonStringWithoutQuotes = decodedString.slice(1, -1);
          const jsonStringWithSingleQuotes = `${jsonStringWithoutQuotes}`;
          eventJsonData = JSON.parse(jsonStringWithSingleQuotes);
          let desc = eventJsonData?.description?.replaceAll("+", " ");
          singleEventObj = {
            ...singleEventObj,
            labels: eventJsonData?.labels ? eventJsonData?.labels : "",
            rank: eventJsonData?.rank ? eventJsonData?.rank : 0,
            local_rank: eventJsonData?.local_rank
              ? eventJsonData?.local_rank
              : 0,
            phq_attendance: eventJsonData?.phq_attendance
              ? eventJsonData?.phq_attendance
              : 0,
            geo_lat: eventJsonData?.geo_lat ? eventJsonData?.geo_lat : "",
            geo_long: eventJsonData?.geo_long ? eventJsonData?.geo_long : "",
            detail: desc,
          };
        }
        eventListArr.push(singleEventObj);
      });
      eventListRef.current = eventListArr;
      setEventList(eventListArr);
      let checkEvent = eventListRef.current?.filter((x) => {
        let checkingStartDate = moment(data?.[0]?.startdate);
        let checkingEndDate = moment(data?.[0]?.enddate);
        let startingDate = moment(x?.startDate);
        let endingDate = moment(x?.endDate);
        if (
          checkingStartDate.isSameOrAfter(startingDate) &&
          checkingEndDate.isSameOrBefore(endingDate)
        ) {
          return x;
        }
      });
      currentEventDataRef.current = checkEvent;
    }

    if (!eventListResponse?.isValidate) {
      setEventList([]);
      eventListRef.current = [];
      currentEventDataRef.current = [];
    }
  };

  const getOccData = async () => {
    setLoading(true);
    await getEventList();
    startDateRef.current = data?.[0]?.startdate;

    let occDataResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.SnapShot +
          API_URL.RATEIQ_YIELD.GET_OCCUPANCY_BY_DATES,
        body: {
          AsOfDate: asOfDateFromRTK,
          startDate: data?.[0]?.startdate,
          endDate: data?.[0]?.enddate,
          propertyCode: propertyDetailFromRTK?.propertycode,
        },
      },
      false
    );

    let response = apiResponse(false, occDataResponse);

    if (response?.isValidate) {
      setLoading(false);
      setOccData(response?.data?.data);
    }

    if (!response?.isValidate) {
      setLoading(false);
      setOccData(null);
    }
  };

  useEffect(() => {
    getOccData();
  }, [data]);
  return (
    <div className="GroupNameModalMain YieldModalMain singleDateSummaryModalMain ToolsSingleTicketPopUpMain">
      <div className="GroupNameModalHeader">
        <h2></h2>
        <button className="btn-close" onClick={closeModal}></button>
      </div>
      <div className="singleDateSummaryModalContent">
        <div className="ViewPropertySetupTable">
          <div className="table__container">
            <div className="stripped__table">
              <Table responsive>
                <thead>
                  <tr>
                    <th className="border_right text-end">Adr</th>
                    <th className="border_right text-end">BAR</th>
                    <th className="border_right text-end">Avg Compset</th>
                    <th className="border_right">Occupancy</th>
                    <th className="border_right">Net Stly</th>
                    <th className="border_right">LY</th>
                    <th>Event</th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr style={{ height: "80px", position: "relative" }}>
                      <Loader />
                    </tr>
                  ) : occData ? (
                    <tr>
                      <td className="border_right text-end">
                        {occData?.[0]?.ADR
                          ? commonService.formateAmount(occData?.[0]?.ADR)
                          : commonService.formateAmount(0)}
                      </td>
                      <td className="border_right text-end">
                        {occData?.[0]?.BAR
                          ? commonService.formateAmount(occData?.[0]?.BAR)
                          : commonService.formateAmount(0)}
                      </td>
                      <td className="border_right text-end">
                        {occData?.[0]?.AVG_Compset
                          ? commonService.formateAmount(
                              occData?.[0]?.AVG_Compset
                            )
                          : commonService.formateAmount(0)}
                      </td>
                      <td className="border_right">
                        {occData?.[0]?.RMS
                          ? commonService.formateRoom(occData?.[0]?.RMS)
                          : commonService.formateRoom(0)}
                      </td>
                      <td className="border_right">
                        {occData?.[0]?.STLY_RMS
                          ? commonService.formateRoom(occData?.[0]?.STLY_RMS)
                          : commonService.formateRoom(0)}
                      </td>
                      <td className="border_right">
                        {occData?.[0]?.LY_RMS
                          ? commonService.formateRoom(occData?.[0]?.LY_RMS)
                          : commonService.formateRoom(0)}
                      </td>
                      <td className="d-flex gap-3">
                        {currentEventDataRef?.current?.length}
                        {currentEventDataRef.current?.length > 0 && (
                          <img
                            style={{ height: "11px", width: "11px" }}
                            className="iconcolor cursorPointer"
                            src={ticket}
                            alt="ticket"
                            onClick={() => {
                              handleEventClick({
                                eventDetails: currentEventDataRef.current,
                                selectedDate: startDateRef.current,
                                getEventList,
                              });
                            }}
                          />
                        )}
                      </td>
                    </tr>
                  ) : (
                    <tr className="text-center">
                      <td colSpan={7}>No Data found</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default YieldOccModal;
