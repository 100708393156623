import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { validationRule } from "../../utils/constant";
import { apiCall } from "../../utils/axiosService";
import { API_URL } from "../../utils/apiRoutes";
import { apiResponse } from "../../utils/apiResponse";
import { checkValidation } from "../../utils/helper";
import { commonService } from "../../utils/commonService";

const AddReminderModal = ({
  showModal,
  closeModal,
  reminderid,
  propertyid,
  getList,
}) => {
  let validationObj = {
    reminderid: reminderid,
    startdate: "",
    remindername: "",
    reminderdetails: "",
    remindertypeterm: "",
    remindersourceterm: "Reminder",
    propertyid: propertyid ? parseInt(propertyid) : "",
    // propertyid: "",
    usernote: "",
    enddate: "",
    validationRule: [
      {
        fieldName: "startdate",
        rule: validationRule.required,
        message: "Start Date is required",
      },
      {
        fieldName: "remindername",
        rule: validationRule.required,
        message: "Reminder Name is required",
      },
      {
        fieldName: "remindertypeterm",
        rule: validationRule.required,
        message: "Reminder Type is required",
      },
      {
        fieldName: "enddate",
        rule: validationRule.required,
        message: "End Date is required",
      },
      {
        fieldName: "propertyid",
        rule: validationRule.required,
        message: "propertyid  is required",
      },
    ],
    errorObj: {},
    isValidate: true,
  };

  const [reminderData, setReminderData] = useState(validationObj);
  const [termList, setTermList] = useState(null);
  const [loading, setLoading] = useState(false);
  const [propertyList, setPropertyList] = useState(null);

  const getTermCategoryList = async () => {
    let termCategoryListResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.Configuration +
          API_URL.PROJECT_TERM.GET_LIST_BY_MULTIPLE_CATEGORY,
        body: {
          categorynamebycomma: "Reminder Type, Reminder Source",
        },
      },
      false
    );

    let response = apiResponse(false, termCategoryListResponse);

    if (response?.isValidate) {
      setTermList(response?.data?.data);
    }
  };

  const getDetailByPrimaryKey = async () => {
    let getDetailByPrimaryKeyResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.RevenueManagement +
          API_URL.REMINDER.GET_BY_PRIMARY_KEY,
        body: {
          id: parseInt(reminderid),
        },
      },
      false
    );

    let response = apiResponse(false, getDetailByPrimaryKeyResponse);

    if (response?.isValidate) {
      setReminderData({
        ...reminderData,
        startdate: response?.data?.data?.startdate
          ? response?.data?.data?.startdate
          : "",
        remindername: response?.data?.data?.remindername
          ? response?.data?.data?.remindername
          : "",
        reminderdetails: response?.data?.data?.reminderdetails
          ? response?.data?.data?.reminderdetails
          : "",
        remindertypeterm: response?.data?.data?.remindertypeterm
          ? response?.data?.data?.remindertypeterm
          : "",

        remindersourceterm: "Reminder",

        usernote: response?.data?.data?.usernote
          ? response?.data?.data?.usernote
          : "",
        enddate: response?.data?.data?.enddate
          ? response?.data?.data?.enddate
          : "",
        propertyid: response?.data?.data?.propertyid
          ? response?.data?.data?.propertyid
          : "",
      });
    }

    if (!response?.isValidate) {
      setReminderData(validationObj);
    }
  };
  const getPropertyList = async () => {
    let propertyListResponse = await apiCall(
      {
        method: "POST",
        url: API_URL.BASE_API_URL.Configuration + API_URL.PROPERTY.GET_LIST,
        body: {
          pageNum: 1,
          pageSize: 100,
          sortColumn: "",
          sortDirection: "asc",
          propertycode: "",
          propertyname: "",
          displayname: "",
          brand_term: "",
          frenchise_term: "",
          pms_term: "",
          chain_term: "",
          pmstype_term: "",
          status_term: "Active",
          search: "",
          propertiesid: "",
        },
      },
      false
    );

    let response = apiResponse(false, propertyListResponse);
    if (response?.isValidate) {
      setPropertyList(response?.data?.data);
    }
  };
  const handleSubmit = async () => {
    setLoading(true);

    if (reminderid > 0) {
      reminderData.propertyid = commonService.checkPropertyIsAvailable(
        propertyList,
        parseInt(reminderData?.propertyid)
      );
    }

    let validation = checkValidation(reminderData);

    if (!validation.isValidate) {
      setReminderData(validation);
      setLoading(false);
      return;
    }

    const saveResponse = await apiCall(
      {
        method: "POST",
        url: API_URL.BASE_API_URL.RevenueManagement + API_URL.REMINDER.SAVE,
        body: {
          reminderid: reminderData?.reminderid,
          startdate: commonService.getDateInDBFormat(reminderData?.startdate),
          remindername: reminderData?.remindername,
          reminderdetails: reminderData?.reminderdetails,
          remindertypeterm: reminderData?.remindertypeterm,

          remindersourceterm: "Reminder",
          //   propertyid: reminderData?.propertyid,
          usernote: reminderData?.usernote,
          enddate: commonService.getDateInDBFormat(reminderData?.enddate),
          propertyid: reminderData?.propertyid,
        },
      },
      true
    );

    let response = apiResponse(true, saveResponse, setLoading);

    if (response?.isValidate) {
      setLoading(false);
      closeModal();
      if (getList) {
        getList();
      }
    }

    if (!response?.isValidate) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getTermCategoryList();
    getPropertyList();
    if (reminderid > 0) {
      getDetailByPrimaryKey();
    }
  }, []);
  return (
    <>
      <Modal
        className="AddPropertySetupModalMain"
        size="lg"
        centered
        show={showModal}
        onHide={closeModal}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {`${reminderid > 0 ? "Edit" : "Add"} Reminder`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xl={12}>
              <div className="from-groups no-border-break mb-3">
                <Form.Label>Title</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Title"
                  value={reminderData?.remindername}
                  onChange={(e) => {
                    setReminderData({
                      ...reminderData,
                      remindername: e.target.value,
                    });
                  }}
                />
                {reminderData?.errorObj?.remindername && (
                  <small className="text-danger">
                    {reminderData?.errorObj?.remindername}
                  </small>
                )}
              </div>
            </Col>
            <Col xl={6}>
              <div className="flotingLableGroup">
                <div className="from-groups no-border-break mb-3">
                  <Form.Label>Reminder Type</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    value={reminderData?.remindertypeterm}
                    onChange={(e) => {
                      setReminderData({
                        ...reminderData,
                        remindertypeterm: e.target.value,
                      });
                    }}
                  >
                    <option value="">Select Reminder Type</option>
                    {termList?.map((item) => {
                      if (item?.termcategoryname === "Reminder Type") {
                        return (
                          <option key={item?.term} value={item?.term}>
                            {item?.term}
                          </option>
                        );
                      }
                    })}
                  </Form.Select>
                  {reminderData?.errorObj?.remindertypeterm && (
                    <small className="text-danger">
                      {reminderData?.errorObj?.remindertypeterm}
                    </small>
                  )}
                </div>
              </div>
            </Col>
            <Col xl={6}>
              <div className="from-groups no-border-break mb-3">
                <Form.Label>Property</Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  value={reminderData?.propertyid}
                  onChange={(e) => {
                    setReminderData({
                      ...reminderData,
                      propertyid: e.target.value,
                    });
                  }}
                >
                  <option value="">Select Property</option>
                  {propertyList?.map((item, index) => (
                    <option key={index} value={item?.propertyid}>
                      {item?.propertyname}
                    </option>
                  ))}
                </Form.Select>
                {reminderData?.errorObj?.propertyid && (
                  <small className="text-danger">
                    {reminderData?.errorObj?.propertyid}
                  </small>
                )}
              </div>
            </Col>
            <Col xl={6}>
              <div className="from-groups no-border-break mb-3">
                <Form.Label>Start Date</Form.Label>
                <DatePicker
                  id="startDate"
                  selected={
                    reminderData?.startdate
                      ? new Date(reminderData?.startdate)
                      : ""
                  }
                  onChange={(date) => {
                    let finalEndDate = reminderData.enddate;

                    if (reminderData.enddate) {
                      let sDate = new Date(date);
                      let eDate = new Date(reminderData.enddate);

                      if (sDate > eDate) {
                        finalEndDate = "";
                      }
                    }

                    setReminderData({
                      ...reminderData,
                      startdate: date,
                      enddate: finalEndDate,
                    });
                  }}
                  placeholderText="Select Start Date"
                />
                {reminderData?.errorObj?.startdate && (
                  <small className="text-danger">
                    {reminderData?.errorObj?.startdate}
                  </small>
                )}
              </div>
            </Col>
            <Col xl={6}>
              <div className="from-groups no-border-break mb-3">
                <Form.Label>End Date</Form.Label>
                <DatePicker
                  id="endDate"
                  minDate={reminderData.startdate}
                  selected={
                    reminderData?.enddate ? new Date(reminderData?.enddate) : ""
                  }
                  onChange={(date) => {
                    setReminderData({
                      ...reminderData,
                      enddate: date,
                    });
                  }}
                  placeholderText="Select End Date"
                />
                {reminderData?.errorObj?.enddate && (
                  <small className="text-danger">
                    {reminderData?.errorObj?.enddate}
                  </small>
                )}
              </div>
            </Col>

            <Col xl={12}>
              <Row>
                <Col xl={6}>
                  <div className="from-groups no-border-break mb-2">
                    <Form.Label>Reminder Detail</Form.Label>
                    <Form.Control
                      as="textarea"
                      placeholder="Enter Reminder Detail"
                      maxLength="3271"
                      style={{ height: "200px" }}
                      value={reminderData?.reminderdetails}
                      onChange={(e) => {
                        setReminderData({
                          ...reminderData,
                          reminderdetails: e.target.value,
                        });
                      }}
                    />
                  </div>
                </Col>
                <Col xl={6}>
                  <div className="from-groups no-border-break mb-2">
                    <Form.Label>User Note</Form.Label>
                    <Form.Control
                      as="textarea"
                      placeholder="Enter User Note"
                      maxLength="3271"
                      style={{ height: "200px" }}
                      value={reminderData?.usernote}
                      onChange={(e) => {
                        setReminderData({
                          ...reminderData,
                          usernote: e.target.value,
                        });
                      }}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          {loading ? (
            <button className="default-btn primary-btn" type="button" disabled>
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              Loading...
            </button>
          ) : (
            <button
              className="default-btn primary-btn"
              onClick={() => {
                handleSubmit();
              }}
            >
              Submit
            </button>
          )}

          <Button className="default-btn secondary-btn" onClick={closeModal}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddReminderModal;
