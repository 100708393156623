import React, { useEffect, useRef, useState } from "react";
import {
    Badge,
    Button,
    Col,
    Container,
    Form,
    Row,
    Table,
} from "react-bootstrap";
import { apiCall } from "../../utils/axiosService";
import { API_URL } from "../../utils/apiRoutes";
import { apiResponse } from "../../utils/apiResponse";
import { useSelector } from "react-redux";
import moment from "moment";
import { errorToastMessage } from "../../utils/toastMessage";
import DatePicker from "react-datepicker";
import Loader from "../Loader/Loader";
import { dataTable } from "../../utils/dataTable";
import { commonService } from "../../utils/commonService";
import calendarIcon from "../../Assets/Images/Revenue-management/calendarIcon.svg";
import AddToCalendarModal from "./AddToCalendarModal";

function FlightPage() {
    const propertyDetailFromRTK = useSelector(
        (store) => store.headerProperty.propertyDetail
    );

    const [airportData, setAirportData] = useState(null);
    const [selectedAirport, setSelectedAirport] = useState(null);
    const [flightData, setFlightData] = useState(null);
    const [mileInput, setMileInput] = useState(50);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [loading, setLoading] = useState(false);
    const [searchLoading, setSearchLoading] = useState(false);
    const [delay, setDelay] = useState(60);
    const [showAddToCalModal, setShowAddToCalModal] = useState(false);
    const noteDetailRef = useRef();
    const [flightStatus, setFlightStatus] = useState({
        displayValue: "Cancelled",
        payloadValue: "cancelled",
    });

    const flightStatusDropdownValue = [
        {
            displayValue: "All",
            payloadValue: "scheduled,cancelled,incident,diverted,redirected,active",
        },
        {
            displayValue: "Cancelled",
            payloadValue: "cancelled",
        },
        {
            displayValue: "Delay",
            payloadValue: "delay",
        },
    ];

    const closeAddToCalModal = () => {
        commonService.SendPushNotification(
            noteDetailRef.current.title,
            noteDetailRef.current.dates + " : " + noteDetailRef.current.detail,
            propertyDetailFromRTK.propertyid,
            "Flight",
            ""
        );
        setShowAddToCalModal(false);
    };

    const getAirportData = async () => {
        if (
            propertyDetailFromRTK?.geolocation !== null &&
            propertyDetailFromRTK?.geolocation !== undefined &&
            propertyDetailFromRTK?.geolocation !== ""
        ) {
            setSearchLoading(true);
            let airportDataResponse = await apiCall(
                {
                    method: "POST",
                    url:
                        API_URL.BASE_API_URL.RevenueManagement +
                        API_URL.FLIGHT.NEARBYAIRPORT,
                    body: {
                        lng: parseFloat(propertyDetailFromRTK?.geolocation?.split(",")[1]),
                        lat: parseFloat(propertyDetailFromRTK?.geolocation?.split(",")[0]),
                        distance: propertyDetailFromRTK?.flightradiousmiles
                            ? propertyDetailFromRTK?.flightradiousmiles
                            : 10,
                    },
                },
                false
            );

            let response = apiResponse(false, airportDataResponse);

            if (response?.isValidate) {
                setAirportData(response?.data?.data);
                setSearchLoading(false);
            }

            if (!response?.isValidate) {
                setAirportData(null);
                setSearchLoading(false);
            }
        } else {
            errorToastMessage("Property geolocation not set in configuration");
        }
    };

    const getFlightData = async () => {
        setLoading(true);
        let currentDate = new Date(selectedDate);
        let flightDataResponse = await apiCall(
            {
                method: "POST",
                url:
                    API_URL.BASE_API_URL.RevenueManagement +
                    API_URL.FLIGHT.AIRPORTTIMETABLESEARCHMODEL,
                body: {
                    // iataCode: selectedAirport,
                    iataCode: propertyDetailFromRTK?.airport,
                    type: "departure",
                    delayminutes: delay ? delay : 60,
                    status: flightStatus?.payloadValue,
                },
            },
            false
        );

        let response = apiResponse(false, flightDataResponse);

        if (response?.isValidate) {
            setFlightData(response?.data?.data);
            setLoading(false);
        }

        if (!response?.isValidate) {
            setFlightData(null);
            setLoading(false);
        }

        // dataTable("flightDataTbl");
    };

    // useEffect(() => {
    //   getAirportData();
    // }, [propertyDetailFromRTK]);

    // useEffect(() => {
    //   if (selectedAirport && selectedDate) {
    //     getFlightData();
    //   }
    // }, [selectedAirport, selectedDate]);

    useEffect(() => {
        getFlightData();
    }, [propertyDetailFromRTK]);

    function convertMinutes(totalMinutes) {
        const hours = Math.floor(totalMinutes / 60);
        const minutes = totalMinutes % 60;
        return `${hours}h ${minutes}m`;
    }

    const getFlightStatus = (status) => {
        let flightStatus = "";
        if (status === "active") {
            return (
                <span className="default-badge active-badge badge bg-primary with-extra-padding">
                    Active
                </span>
            );
        } else if (status === "cancelled") {
            return (
                <span className="default-badge inactive-badge badge bg-primary with-extra-padding">
                    Cancelled
                </span>
            );
        } else if (status === "diverted") {
            return (
                <span className="default-badge badge bg-info with-extra-padding">
                    Diverted
                </span>
            );
        } else if (status === "data needed") {
            // flightStatus = "Data Needed";
            return (
                <span className="default-badge lYellow-badge badge bg-secondary with-extra-padding">
                    Data Needed
                </span>
            );
        } else if (status === "landed") {
            return (
                <span className="default-badge dblue-badge badge bg-primary with-extra-padding">
                    Landed
                </span>
            );
        } else if (status === "incident") {
            return (
                <span className="default-badge badge bg-secondary with-extra-padding">
                    Incident
                </span>
            );
        } else if (status === "redirected") {
            return (
                <span className="default-badge lblue-badge badge bg-primary with-extra-padding">
                    Redirected
                </span>
            );
        } else if (status === "scheduled") {
            return (
                <span className="default-badge lgreen-badge badge bg-primary with-extra-padding">
                    Scheduled
                </span>
            );
        } else {
            return (
                <span className="default-badge badge bg-dark with-extra-padding">
                    {status}
                </span>
            );
        }

        return flightStatus;
    };

    return (
        <div className="weather-page-main">
            {propertyDetailFromRTK ? (
                propertyDetailFromRTK?.airport ? (
                    <Container fluid>
                        <Row>
                            <Col xl={12}>
                                <div className="ProspectDashboardTitleWraper  d-flex justify-content-between  align-items-center mb-4">
                                    <h2>
                                        Dashboard-{" "}
                                        <span className="tab-title-main">Flight Status</span>
                                    </h2>
                                </div>
                            </Col>
                        </Row>
                        <Row className="">
                            <Col
                                xl={12}
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "5px",
                                }}
                            >
                                <div className="from-groups no-border-break width200">
                                    <Form.Select
                                        aria-label="Default select example"
                                        value={flightStatus?.displayValue}
                                        onChange={(e) => {
                                            let findData = flightStatusDropdownValue?.filter(
                                                (x) => x?.displayValue === e.target.value
                                            );

                                            setFlightStatus({
                                                displayValue: findData?.[0]?.displayValue
                                                    ? findData?.[0]?.displayValue
                                                    : "",
                                                payloadValue: findData?.[0]?.payloadValue
                                                    ? findData?.[0]?.payloadValue
                                                    : "",
                                            });
                                        }}
                                    >
                                        {flightStatusDropdownValue?.map((item, index) => (
                                            <option
                                                key={item?.displayValue}
                                                value={item?.displayValue}
                                            >
                                                {item?.displayValue}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </div>
                                <div className="from-groups no-border-break width150">
                                    <Form.Control
                                        type="text"
                                        placeholder="Delay in minutes" 
                                        pattern="[0-9]*"
                                        value={delay}
                                        onChange={(e) => {
                                            if (e.target.validity.valid) {
                                                setDelay(parseInt(e.target.value));
                                            }

                                            if (e.target.value === "") {
                                                setDelay("");
                                            }
                                        }}
                                    />
                                </div>
                                <div>
                                    <Button
                                        className="default-btn primary-btn gobtnwidth"
                                        onClick={() => {
                                            getFlightData();
                                        }}
                                    >
                                        Search
                                    </Button>
                                </div>
                            </Col>
                            <Col xl={12}>
                                <div
                                    className={`table__container mt-2 ${loading ? "" : "d-none"}`}
                                >
                                    <div className="stripped__table">
                                        <Table responsive>
                                            <thead>
                                                <tr>
                                                    <th>Airline</th>
                                                    <th>Flight No</th>
                                                    <th>Scheduled Time</th>
                                                    <th>Delay</th>
                                                    <th>Estimated Time</th>
                                                    <th>Destination</th>
                                                    <th>Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr style={{ height: "80px", position: "relative" }}>
                                                    <Loader />
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>

                                <div
                                    className={`table__container mt-2 ${loading ? "d-none" : ""}`}
                                >
                                    <div className="stripped__table">
                                        <Table id="flightDataTbl" responsive>
                                            <thead>
                                                <tr>
                                                    <th>Airline</th>
                                                    <th>Flight No</th>
                                                    <th>Scheduled Time</th>
                                                    <th>Delay</th>
                                                    <th>Estimated Time</th>
                                                    <th>Destination</th>
                                                    <th>Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {flightData ? (
                                                    flightData?.map((item) => {
                                                        return (
                                                            <tr>
                                                                <td>
                                                                    {item?.airline?.name} (
                                                                    {item?.airline?.icaoCode})
                                                                </td>
                                                                <td>
                                                                    {item?.flight?.iataNumber}
                                                                </td>
                                                                <td>
                                                                    {item?.departure?.scheduledTime
                                                                        ? commonService.getTimeInFormat(
                                                                            item.departure.scheduledTime
                                                                        )
                                                                        : ""}
                                                                </td>
                                                                <td>
                                                                    {item?.departure?.scheduledTime
                                                                        ? item?.departure?.delay
                                                                            ? <span style={{ color: "red" }}>{convertMinutes(item?.departure?.delay)}</span>
                                                                            : ""
                                                                        : ""}
                                                                </td>
                                                                <td>
                                                                    {item?.departure?.delay ? item?.departure?.estimatedTime
                                                                        ? <span style={{ color: "green" }}>{commonService.getTimeInFormat(
                                                                            item.departure.estimatedTime
                                                                        )}</span>
                                                                        : "" : ""}
                                                                </td>
                                                                <td>
                                                                    {item?.arrival?.iataCodeName} ({item?.arrival?.iataCode	})
                                                                </td>
                                                                <td>
                                                                    <div className="d-flex justify-content-between">
                                                                        {getFlightStatus(item?.status)}
                                                                        <img
                                                                            className="cursorPointer"
                                                                            src={calendarIcon}
                                                                            title="Add To Calendar"
                                                                            onClick={() => {
                                                                                noteDetailRef.current = {
                                                                                    dates: item?.departure?.scheduledTime
                                                                                        ? commonService.getDateTimeInFormat(
                                                                                            item.departure.scheduledTime
                                                                                        )
                                                                                        : "",
                                                                                    detail: `${item?.departure?.scheduledTime
                                                                                        ? item?.departure?.delay
                                                                                            ? `Flight Delay :${convertMinutes(
                                                                                                item?.departure?.delay
                                                                                            )}, `
                                                                                            : ""
                                                                                        : ""
                                                                                        }Airline: ${item?.airline?.name
                                                                                            ? item?.airline?.name
                                                                                            : "-"
                                                                                        }, Arrival: ${item?.arrival?.iataCode
                                                                                            ? item?.arrival?.iataCode
                                                                                            : "-"
                                                                                        }, Departure: ${item?.departure?.iataCode
                                                                                            ? item?.departure?.iataCode
                                                                                            : "-"
                                                                                        }`,
                                                                                    //   title: `${
                                                                                    //     item?.departure?.scheduledTime
                                                                                    //       ? item?.departure?.delay
                                                                                    //         ? `Flight ${
                                                                                    //             item?.status
                                                                                    //               ? item?.status
                                                                                    //               : "-"
                                                                                    //           } :${convertMinutes(
                                                                                    //             item?.departure?.delay
                                                                                    //           )}`
                                                                                    //         : ""
                                                                                    //       : ""
                                                                                    //   }`,
                                                                                    title: `Flight ${item?.status ? item?.status : ""
                                                                                        }`,
                                                                                    associationType: "flight",
                                                                                };
                                                                                setShowAddToCalModal(true);
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })
                                                ) : (
                                                    <tr>
                                                        <td className="text-center" colSpan={12}>
                                                            No Data Found
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                ) : (
                    <Container>
                        <div className="d-flex justify-content-center">
                            <h5>Please add aiport in configuration</h5>
                        </div>
                    </Container>
                )
            ) : (
                <Container>
                    <div className="d-flex justify-content-center">
                        <h5>Please Select Property</h5>
                    </div>
                </Container>
            )}
            {showAddToCalModal && (
                <AddToCalendarModal
                    showModal={showAddToCalModal}
                    closeModal={closeAddToCalModal}
                    noteDetail={noteDetailRef.current}
                />
            )}
        </div>
    );
}

export default FlightPage;
