import React, { useEffect, useRef, useState } from "react";
import { apiResponse } from "../../../../utils/apiResponse";
import { apiCall } from "../../../../utils/axiosService";
import { Button, Col, Form, Row, Table } from "react-bootstrap";
import { API_URL } from "../../../../utils/apiRoutes";
import { compName, month } from "../../../../utils/constant";
import NoteIcon from "../../../../Assets/Images/CRM/notes.svg";
import downloadIcon from "../../../../Assets/Images/Operation/downloadIcon.svg";
import { commonService } from "../../../../utils/commonService";
import Loader from "../../../Loader/Loader";
import { componentSetting } from "../../../../utils/componentSetting";
import WidgetDetailModal from "../../WidgetDetailModal";
import infoIcon from "../../../../Assets/Images/Action Icons/infoIcon..svg";
import WidgetNote from "../../WidgetNote";
import { useSelector } from "react-redux";
import AddWidgetModal from "../../AddWidgetModal";
import { useParams } from "react-router-dom";
import emailIcon from "../../../../Assets/Images/Email.svg";
import moment from "moment";

const EMAForecast = ({
  setCompDataFromChild,
  rowIndex,
  colIndex,
  api,
  para,
  setting,
}) => {
  const yearsArr = [
    ...commonService.getPastYearForDropDown(10),
    ...commonService.getFutureYearForDropDown(10),
    new Date().getFullYear(),
  ]?.sort(function (a, b) {
    return a - b;
  });
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedForecast, setSelectedForecast] = useState("ema");
  const tableData = [
    { month: "January", occ: 0, rms: 0, adr: 0, rev: 0, var: 0 },
    { month: "February", occ: 0, rms: 0, adr: 0, rev: 0, var: 0 },
    { month: "March", occ: 0, rms: 0, adr: 0, rev: 0, var: 0 },
    { month: "April", occ: 0, rms: 0, adr: 0, rev: 0, var: 0 },
    { month: "May", occ: 0, rms: 0, adr: 0, rev: 0, var: 0 },
    { month: "June", occ: 0, rms: 0, adr: 0, rev: 0, var: 0 },
    { month: "July", occ: 0, rms: 0, adr: 0, rev: 0, var: 0 },
    { month: "August", occ: 0, rms: 0, adr: 0, rev: 0, var: 0 },
    { month: "September", occ: 0, rms: 0, adr: 0, rev: 0, var: 0 },
    { month: "October", occ: 0, rms: 0, adr: 0, rev: 0, var: 0 },
    { month: "November", occ: 0, rms: 0, adr: 0, rev: 0, var: 0 },
    { month: "December", occ: 0, rms: 0, adr: 0, rev: 0, var: 0 },
  ];
  let occ = 0,
    rms = 0,
    adr = 0,
    rev = 0,
    tableVar = 0;

  const [forecastData, setForecastData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [widgetModal, setWidgetModal] = useState(false);
  const widgetDetailRef = useRef();
  const [showNoteSidebar, setShowNoteSidebar] = useState(false);
  const propertyDetailFromRTK = useSelector(
    (store) => store.headerProperty.propertyDetail
  );
  const defaultAsOfDateFromRTK = useSelector(
    (store) => store.headerProperty.defaultAsOfDate
  );
  const { snapshottitle, propertyCode, snapshotId } = useParams();
  const closeNoteSideBar = () => {
    setShowNoteSidebar(false);
  };

  const closeWidgetModal = () => {
    setWidgetModal(false);
  };

  const getRmsDetail = async () => {
    setLoading(true);
    let rmsDetailResponse = await apiCall(
      {
        method: "POST",
        url: API_URL.BASE_API_URL.SnapShot + API_URL.FORECAST.RMS_FORECAST,
        body: {
          AsOfDate: para?.asOfDate,
          year: selectedYear,
          propertyCode: para?.propertyCode,
        },
      },
      false
    );

    let response = apiResponse(false, rmsDetailResponse);
    if (response?.isValidate) {
      setForecastData(response?.data?.data);
      setLoading(false);
    }

    if (!response?.isValidate) {
      setForecastData(tableData);
      setLoading(false);
    }
  };

  const getEmaForecastDetail = async () => {
    setLoading(true);
    let budgetDetailResponse = await apiCall(
      {
        method: "POST",
        url: API_URL.BASE_API_URL.SnapShot + API_URL.FORECAST.SPIDER_FORECAST,
        body: {
          AsOfDate: para?.asOfDate,
          year: selectedYear,
          propertyCode: para?.propertyCode,
        },
      },
      false
    );

    let response = apiResponse(false, budgetDetailResponse);
    if (response?.isValidate) {
      setForecastData(response?.data?.data);
      setLoading(false);
    }

    if (!response?.isValidate) {
      setForecastData(tableData);
      setLoading(false);
    }
  };

  const getUserDetail = async () => {
    setLoading(true);
    let userDetailResponse = await apiCall(
      {
        method: "POST",
        url: API_URL.BASE_API_URL.SnapShot + API_URL.FORECAST.USER_FORECAST,
        body: {
          AsOfDate: para?.asOfDate,
          year: selectedYear,
          propertyCode: para?.propertyCode,
        },
      },
      false
    );

    let response = apiResponse(false, userDetailResponse);
    if (response?.isValidate) {
      setForecastData(response?.data?.data);
      setLoading(false);
    }

    if (!response?.isValidate) {
      setForecastData(tableData);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (api === 0) {
      setForecastData(tableData);
    } else {
      if (para?.asOfDate) {
        getEmaForecastDetail();
      }
    }
  }, [para?.asOfDate]);

  const [tblids, settblids] = useState({
    emaForecast: commonService.GenerateGUID(),
  });

  useEffect(() => {
    if (api === 1 && para?.asOfDate) {
      if (selectedForecast === "ema") {
        getEmaForecastDetail();
      } else if (selectedForecast === "rms") {
        getRmsDetail();
      } else if (selectedForecast === "user") {
        getUserDetail();
      }
    }
  }, [selectedYear, selectedForecast]);
  commonService.setBackColorPerByTDValue(
    "Green",
    "Red",
    `#${tblids.emaForecast} .perbackground-rev`
  );

  let objSetting, _month, _occ, _rms, _adr, _rev, _var;
  if (setting) {
    objSetting =
      setting !== null && setting !== ""
        ? JSON.parse(setting)
        : JSON.parse(componentSetting["EMAForecast"]);
    _month = objSetting.header[1].filter((i) => i.datacolname === "month")[0]
      .isshow;
    _occ = objSetting.header[1].filter((i) => i.datacolname === "occ")[0]
      .isshow;
    _rms = objSetting.header[1].filter((i) => i.datacolname === "rms")[0]
      .isshow;
    _adr = objSetting.header[1].filter((i) => i.datacolname === "adr")[0]
      .isshow;
    _rev = objSetting.header[1].filter((i) => i.datacolname === "rev")[0]
      .isshow;
    _var = objSetting.header[1].filter((i) => i.datacolname === "var")[0]
      .isshow;
  }

  return (
    <div className="widget-container">
      {api !== 0 && (
        <div className="gap-1 widget-container-menu">
          <Button className="heading-menu-btns" type="button">
            <img
              src={NoteIcon}
              alt="menuIcon"
              onClick={() => {
                setShowNoteSidebar(true);
              }}
            />
          </Button>
          <Button className="heading-menu-btns" type="button" title="Send Mail">
            <img
              src={emailIcon}
              alt="emailIcon"
              onClick={() => {
                commonService.fnSendWidgetMail(
                  tblids.emaForecast,
                  "System Forecast",
                  snapshotId,
                  defaultAsOfDateFromRTK,
                  "",
                  para?.widgetId
                );
              }}
            />
          </Button>
          <Button className="heading-menu-btns" type="button">
            <img
              src={infoIcon}
              alt="info"
              onClick={() => {
                widgetDetailRef.current = {
                  widgetdetails: "",
                  usernote: "",
                  compName: compName?.emaForecast,
                  widgetId: para?.widgetId,
                };
                setWidgetModal(true);
              }}
            />
          </Button>
          <Button className="heading-menu-btns" type="button">
            <img
              src={downloadIcon}
              alt="menuIcon"
              import={tblids.emaForecast}
              onClick={() =>
                commonService.fnExportInCSV(
                  tblids.emaForecast,
                  // "System Forecast"
                  `${para?.propertyCode}-System Forecast-${moment(
                    new Date()
                  ).format("MMDDYYYY")}`
                )
              }
            />
          </Button>
        </div>
      )}
      <div className="ViewPropertySetupTable">
        <div className="stripped__table">
          <Table id={tblids.emaForecast} responsive>
            <thead>
              <tr>
                <th
                  colSpan={setting ? objSetting.header[0][0].colspan : 5}
                  className="text-center"
                >
                  <Row>
                    <Col xl={4}>
                      {" "}
                      <Form.Select
                        value={selectedYear}
                        onChange={(e) => {
                          setSelectedYear(e.target.value);
                        }}
                      >
                        <option value="">Select</option>
                        {yearsArr?.map((item, index) => (
                          <option key={index} value={item}>
                            {item}
                          </option>
                        ))}
                      </Form.Select>
                      <span style={{ display: "none" }}>{selectedYear}</span>
                    </Col>
                    <Col xl={8}>
                      <Form.Select
                        value={selectedForecast}
                        onChange={(e) => {
                          setSelectedForecast(e.target.value);
                        }}
                      >
                        <option value={"ema"}>System Forecast</option>
                        <option value={"rms"}>RMS Forecast</option>
                        <option value={"user"}>User Forecast</option>
                      </Form.Select>
                      <span style={{ display: "none" }}>
                        {selectedForecast}
                      </span>
                    </Col>
                  </Row>
                </th>
              </tr>
              {setting ? (
                <tr>
                  {_month && <th>Month</th>}
                  {_occ && <th>OCC</th>}
                  {_rms && <th className="text-center">RMS</th>}
                  {_adr && <th className="text-end">ADR</th>}
                  {_rev && <th className="text-end">REV</th>}
                  {/* {_var && <th>VAR</th>} */}
                </tr>
              ) : (
                <tr>
                  <th>Month</th>
                  <th>OCC</th>
                  <th className="text-center">RMS</th>
                  <th className="text-end">ADR</th>
                  <th className="text-end">REV</th>
                  {/* <th>VAR</th> */}
                </tr>
              )}
            </thead>
            <tbody>
              {api === 0 ? (
                forecastData?.map((item, index) => (
                  <React.Fragment key={index}>
                    {setting ? (
                      <tr key={index}>
                        {_month && <td>{item?.month}</td>}
                        {_occ && <td>{item?.occ}%</td>}
                        {_rms && <td className="text-center">{item?.rms}</td>}
                        {_adr && <td className="text-end">${item?.adr}</td>}
                        {_rev && (
                          <td
                            className="text-end perbackground perbackground-rev"
                            value={item?.rev}
                          >
                            ${item?.rev}
                          </td>
                        )}
                        {/* {_var && <td>{item?.var}%</td>} */}
                      </tr>
                    ) : (
                      <tr key={index}>
                        <td>{item?.month}</td>
                        <td>{item?.occ}%</td>
                        <td className="text-center">{item?.rms}</td>
                        <td className="text-end">${item?.adr}</td>
                        <td
                          className="text-end perbackground perbackground-rev"
                          value={item?.rev}
                        >
                          ${item?.rev}
                        </td>
                        {/* <td>{item?.var}%</td> */}
                      </tr>
                    )}
                    {index === 11 && (
                      <>
                        {setting ? (
                          <tr>
                            {_month && <td className="boldTd">Total</td>}
                            {_occ && <td className="boldTd">0%</td>}
                            {_rms && <td className="boldTd text-center">0</td>}
                            {_adr && <td className="boldTd text-end">$0</td>}
                            {_rev && <td className="boldTd text-end">$0</td>}
                            {/* {_var && <td className="boldTd">0%</td>} */}
                          </tr>
                        ) : (
                          <tr>
                            <td className="boldTd">Total</td>
                            <td className="boldTd">0%</td>
                            <td className="boldTd text-center">0</td>
                            <td className="boldTd text-end">$0</td>
                            <td className="boldTd text-end">$0</td>
                            {/* <td className="boldTd">0%</td> */}
                          </tr>
                        )}
                      </>
                    )}
                  </React.Fragment>
                ))
              ) : loading ? (
                <tr style={{ height: "80px", position: "relative" }}>
                  <Loader />
                </tr>
              ) : (
                forecastData &&
                month?.map((item, index) => {
                  let findMonthData = forecastData?.filter(
                    (x) => x.month === item
                  );

                  occ =
                    occ +
                    (findMonthData?.[0]?.occ ? findMonthData?.[0]?.occ : 0);
                  rms =
                    rms +
                    (findMonthData?.[0]?.rms ? findMonthData?.[0]?.rms : 0);
                  adr =
                    adr +
                    (findMonthData?.[0]?.adr ? findMonthData?.[0]?.adr : 0);
                  rev =
                    rev +
                    (findMonthData?.[0]?.rev ? findMonthData?.[0]?.rev : 0);
                  tableVar =
                    tableVar +
                    (findMonthData?.[0]?.var ? findMonthData?.[0]?.var : 0);

                  return (
                    <React.Fragment key={index}>
                      <tr key={index} className="shadowOnHover">
                        {_month && <td>{item}</td>}
                        {_occ && (
                          <td>
                            {findMonthData?.[0]?.occ
                              ? findMonthData?.[0]?.occ
                              : 0}
                            %
                          </td>
                        )}
                        {_rms && (
                          <td className="text-center">
                            {findMonthData?.[0]?.rms
                              ? commonService.formateRoom(findMonthData?.[0]?.rms)
                              : 0}
                          </td>
                        )}
                        {_adr && (
                          <td className="text-end">
                            {findMonthData?.[0]?.adr
                              ? commonService.formateAmount(
                                  findMonthData?.[0]?.adr
                                )
                              : commonService.formateAmount(0)}
                          </td>
                        )}
                        {_rev && (
                          <td
                            className="text-end perbackground perbackground-rev"
                            value={findMonthData?.[0]?.rev}
                          >
                            {findMonthData?.[0]?.rev
                              ? commonService.formateAmount(
                                  findMonthData?.[0]?.rev
                                )
                              : commonService.formateAmount(0)}
                          </td>
                        )}
                        {/* {_var && <td>{findMonthData?.[0]?.var}%</td>} */}
                      </tr>
                      {index === 11 && (
                        <tr className="shadowOnHover">
                          {_month && <td className="boldTd">Total</td>}
                          {_occ && (
                            <td className="boldTd">
                              {occ > 0 ? parseInt(occ / 12) : 0}%
                            </td>
                          )}
                          {_rms && (
                            <td className="boldTd text-center">{commonService.formateRoom(rms)}</td>
                          )}
                          {_adr && (
                            <td className="boldTd text-end">
                              {rev !== 0 && rms !== 0
                                ? commonService.formateAmount(
                                    parseInt(rev / rms)
                                  )
                                : commonService.formateAmount(0)}
                            </td>
                          )}
                          {_rev && (
                            <td className="boldTd text-end">
                              {commonService.formateAmount(rev)}
                            </td>
                          )}
                          {/* {_var && (
                            <td className="boldTd">
                              {tableVar > 0 ? parseInt(tableVar / 12) : 0}%
                            </td>
                          )} */}
                        </tr>
                      )}
                    </React.Fragment>
                  );
                })
              )}
            </tbody>
          </Table>
        </div>
      </div>
      {/* {showNoteSidebar && (
        <WidgetNote
          show={showNoteSidebar}
          handleClose={closeNoteSideBar}
          widgetId={para?.widgetId}
          propertyId={propertyDetailFromRTK?.propertyid}
          rowTitle={para?.rowTitle}
        />
      )} */}
      {showNoteSidebar && (
        <AddWidgetModal
          showModal={showNoteSidebar}
          closeModal={closeNoteSideBar}
          widgetId={para?.widgetId}
          propertyId={propertyDetailFromRTK?.propertyid}
          rowTitle={para?.rowTitle}
        />
      )}
      {widgetModal && (
        <WidgetDetailModal
          showModal={widgetModal}
          closeModal={closeWidgetModal}
          data={widgetDetailRef.current}
        />
      )}
    </div>
  );
};

export default EMAForecast;
