import React, { useEffect, useRef, useState } from "react";
import { Accordion, Badge, Button, Col, Offcanvas, Row } from "react-bootstrap";
import { apiCall } from "../../utils/axiosService";
import { API_URL } from "../../utils/apiRoutes";
import { apiResponse } from "../../utils/apiResponse";
import logo from "../../Assets/Images/Revenue-management/smallLogo.svg";
import plusIcon from "../../Assets/Images/FormCheck/plusIcon.png";
import AddWidgetModal from "./AddWidgetModal";
import { commonService } from "../../utils/commonService";
import imageIcon from "../../Assets/Images/Configuration/image_placeholder.svg";
import pdfIcon from "../../Assets/Images/CRM/pdf.svg";
import folderIcon from "../../Assets/Images/Configuration/folder.svg";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";
import Loader from "../Loader/Loader";

const WidgetNote = ({
  show,
  handleClose,
  widgetId,
  propertyId,
  rowTitle,
  dateForNote,
  dateForApiCall,
}) => {
  const [noteDetail, setNoteDetail] = useState(null);
  const [addNoteModal, setAddNoteModal] = useState(false);
  const [viewWidgetNote, setViewWidgetNote] = useState(null);
  const clickedNoteDetail = useRef();
  const [loading, setLoading] = useState(false);
  const refForScroll = useRef();

  const closeViewWidgetNoteModal = () => {
    setViewWidgetNote(false);
  };

  const closeAddNoteModal = () => {
    setAddNoteModal(false);
  };
  const defaultAsOfDateFromRTK = useSelector(
    (store) => store.headerProperty.defaultAsOfDate
  );

  const getNoteDetail = async () => {
    setLoading(true);
    let noteDetailResponse = await apiCall(
      {
        method: "POST",
        url: API_URL.BASE_API_URL.Configuration + API_URL.WIDGET_NOTE.GET_LIST,
        body: {
          propertyid: propertyId,
          widgetid: widgetId,
          startdate: dateForApiCall
            ? dateForApiCall
            : commonService.getDateInDBFormat(defaultAsOfDateFromRTK),
          enddate: dateForApiCall
            ? dateForApiCall
            : commonService.getDateInDBFormat(defaultAsOfDateFromRTK),
        },
      },
      false
    );

    let response = apiResponse(false, noteDetailResponse);

    if (response?.isValidate) {
      // setNoteDetail(response?.data?.data);

      let dataFromApi = response?.data?.data?.notedata;
      let groupingJson = {};

      for (let i = 0; i < dataFromApi?.length; i++) {
        let dateKey = moment(dataFromApi?.[i]?.asofdate).format("YYYY-MM-DD");
        if (groupingJson?.hasOwnProperty(dateKey)) {
          groupingJson[dateKey] = [...groupingJson[dateKey], dataFromApi?.[i]];
        } else {
          groupingJson = {
            ...groupingJson,
            [dateKey]: [dataFromApi?.[i]],
          };
        }
      }

      let sortByKey = {};
      Object.keys(groupingJson)
        .sort((a, b) => {
          return new Date(a) - new Date(b);
        })
        .forEach(function (v, i) {
          let finalJson = groupingJson?.[v]?.sort((a, b) => {
            return new Date(a.createdon) - new Date(b.createdon);
          });
          sortByKey = { ...sortByKey, [v]: finalJson };
        });
      setNoteDetail({
        notedata: sortByKey,
        filedata: response?.data?.data?.filedata,
      });
      setLoading(false);
    }

    if (!response?.isValidate) {
      setNoteDetail(null);
      setLoading(false);
    }
  };

  const getNoteByDataList = async () => {
    setLoading(true);
    let noteByDataListResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.Configuration +
          API_URL.WIDGET_NOTE.GET_BY_DATA_LIST,
        body: {
          propertyid: propertyId,
          widgetid: widgetId,
          startdate: commonService.getDateInDBFormat(dateForNote),
          enddate: commonService.getDateInDBFormat(dateForNote),
        },
      },
      false
    );

    let response = apiResponse(false, noteByDataListResponse);

    // if (response?.isValidate) {
    //   setNoteDetail(response?.data?.data);
    // }

    // if (!response?.isValidate) {
    //   setNoteDetail(null);
    // }

    if (response?.isValidate) {
      let dataFromApi = response?.data?.data?.notedata;
      let groupingJson = {};

      for (let i = 0; i < dataFromApi?.length; i++) {
        let dateKey = moment(dataFromApi?.[i]?.asofdate).format("YYYY-MM-DD");
        if (groupingJson?.hasOwnProperty(dateKey)) {
          groupingJson[dateKey] = [...groupingJson[dateKey], dataFromApi?.[i]];
        } else {
          groupingJson = {
            ...groupingJson,
            [dateKey]: [dataFromApi?.[i]],
          };
        }
      }

      let sortByKey = {};
      Object.keys(groupingJson)
        .sort((a, b) => {
          return new Date(a) - new Date(b);
        })
        .forEach(function (v, i) {
          let finalJson = groupingJson?.[v]?.sort((a, b) => {
            return new Date(a.createdon) - new Date(b.createdon);
          });
          sortByKey = { ...sortByKey, [v]: finalJson };
        });
      setNoteDetail({
        notedata: sortByKey,
        filedata: response?.data?.data?.filedata,
      });
      setLoading(false);
    }

    if (!response?.isValidate) {
      setNoteDetail(null);
      setLoading(false);
    }
  };

  const htmlToText = (html) => {
    const tempElement = document.createElement("div");
    tempElement.innerHTML = html;
    const plainText = tempElement.innerText;
    return plainText;
  };

  const getDateKeysArr = () => {
    let arr = Object.keys(noteDetail?.notedata);
    return arr;
  };

  useEffect(() => {
    if (dateForNote) {
      getNoteByDataList();
    } else {
      getNoteDetail();
    }
  }, []);

  useEffect(() => {
    if (noteDetail) {
      refForScroll.current?.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }
  }, [noteDetail]);

  return (
    <div className="insightOffcanvasWrapper">
      <Offcanvas
        show={show}
        onHide={handleClose}
        placement="end"
        className="insightOffcanvasWrapper"
      >
        <Offcanvas.Header closeButton className="">
          <Offcanvas.Title>{`Note ${
            dateForNote
              ? `For ${commonService.getDateInFormat(dateForNote)}`
              : ""
          }`}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Row>
            {/* <Col xl={12} className="widgetNoteListDiv">
              {noteDetail?.notedata?.map((item, index) => {
                let fileData = noteDetail?.filedata?.filter(
                  (x) => x?.associationid === item?.widgetnoteid
                );
                return (
                  <div className="insightNotesMain">
                    <Row>
                      <Col xl={2}>
                        <div className="insightNotesInnerLogo">
                          <img src={item?.profilepic} alt="logo" />
                        </div>
                      </Col>
                      <Col xl={10} className="m-auto ps-0">
                        <div className="insightNotesInnerContent d-flex justify-content-between align-items-center">
                          <div className="insightNotesInnerEma">
                            <h5>{item?.displayname}</h5>
                            <p>{item?.widgetname}</p>
                          </div>
                          <div className="insightNotesInnerEmaDate">
                            <p>
                              {commonService.getDateInFormat(item?.createdon)}
                            </p>
                          </div>
                        </div>
                      </Col>
                      <Col xl={2}></Col>
                      <Col
                        xl={10}
                        className={`m-auto ps-0 ${
                          index !== noteDetail?.notedata?.length - 1
                            ? "insightNotesInnerBottom"
                            : ""
                        }`}
                      >
                        <p
                          dangerouslySetInnerHTML={{
                            __html: item?.widgetnotes,
                          }}
                        />
                        <div className="fileDiv ">
                          {fileData?.map((fileItem, fileIndex) => {
                            if (fileItem?.filetype === "FILE") {
                              return (
                                <a
                                  target="_blank"
                                  href={fileItem?.attachmentpath}
                                >
                                  <img src={pdfIcon} className="fileIcon" />
                                </a>
                              );
                            } else if (fileItem?.filetype === "IMAGE") {
                              return (
                                <a
                                  target="_blank"
                                  href={fileItem?.attachmentpath}
                                >
                                  <img src={imageIcon} className="fileIcon" />
                                </a>
                              );
                            } else {
                              return (
                                <a
                                  target="_blank"
                                  href={fileItem?.attachmentpath}
                                >
                                  <img src={folderIcon} className="fileIcon" />
                                </a>
                              );
                            }
                          })}
                        </div>
                      </Col>
                    </Row>
                  </div>
                );
              })}
            </Col> */}

            <Col xl={12} className="widgetNoteListDiv mt-2">
              <div className="insightNotesMain-inner-wrapper">
                {loading ? (
                  <Loader />
                ) : noteDetail?.notedata ? (
                  <Accordion defaultActiveKey={getDateKeysArr()} alwaysOpen>
                    {Object.entries(noteDetail?.notedata)?.map(
                      ([key, value], index) => {
                        return (
                          <Accordion.Item eventKey={key}>
                            <Accordion.Header title={key}>
                              {moment(key).format("MM/DD")}
                            </Accordion.Header>
                            <Accordion.Body>
                              <div className="all-insights-notes-wrapper">
                                {value?.map((item, index) => {
                                  let fileData = noteDetail?.filedata?.filter(
                                    (x) =>
                                      x?.associationid === item?.widgetnoteid
                                  );
                                  let notificationTime = "";
                                  if (
                                    commonService.getDateInFormat(
                                      new Date(new Date().toUTCString())
                                    ) ===
                                    commonService.getDateInFormat(
                                      item?.createdon
                                    )
                                  ) {
                                    let dif =
                                      new Date(
                                        new Date().getUTCFullYear(),
                                        new Date().getUTCMonth(),
                                        new Date().getUTCDate(),
                                        new Date().getUTCHours(),
                                        new Date().getUTCMinutes()
                                      ) - new Date(item?.createdon);
                                    let totalMinutes = Math.round(
                                      dif / 1000 / 60
                                    );
                                    if (totalMinutes > 59) {
                                      notificationTime =
                                        Math.round(totalMinutes / 60) +
                                        " hours ago";
                                    } else {
                                      if (totalMinutes > 0) {
                                        notificationTime =
                                          totalMinutes + " min ago";
                                      } else {
                                        notificationTime = "sec ago";
                                      }
                                    }
                                  } else {
                                    notificationTime =
                                      commonService.getDateInFormat(
                                        item?.createdon
                                      );
                                  }
                                  return (
                                    <div className="insights-notes-single-notes d-block">
                                      <div className="insights-notes-single-notes-inner">
                                        <div className="insightNotesInnerLogo">
                                          <img
                                            src={item?.profilepic}
                                            alt="profilepic"
                                          />
                                        </div>
                                        <div className="insightNotesInnerContent">
                                          <div className="insightNotesInnerContent d-flex justify-content-between align-items-start w-100">
                                            <div className="insightNotesInnerEma">
                                              <h5>{item?.displayname}</h5>
                                              <p className="d-flex gap-1 flex-wrap align-items-center">
                                                <Badge
                                                  className="default-badge orange-badge"
                                                  title={item?.propertyname}
                                                >
                                                  {item?.propertyname?.length >
                                                  10
                                                    ? `${item?.propertyname?.slice(
                                                        0,
                                                        9
                                                      )}...`
                                                    : item?.propertyname}
                                                </Badge>{" "}
                                                <Badge
                                                  className="default-badge tomato-badge"
                                                  title={item?.widgetname}
                                                >
                                                  {item?.widgetname?.length > 10
                                                    ? `${item?.widgetname?.slice(
                                                        0,
                                                        9
                                                      )}...`
                                                    : item?.widgetname}
                                                </Badge>
                                              </p>
                                            </div>
                                            <div className="insightNotesInnerEmaDate">
                                              <p>
                                                {notificationTime}
                                                {/* {moment(item?.createdon).format(
                                                  "MM/DD/YYYY h:mm a"
                                                )} */}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      {viewWidgetNote === item?.widgetnoteid ? (
                                        // <div className="insights-notes-detail">{`${htmlToText(
                                        //   item?.widgetnotes
                                        // )}`}</div>
                                        <div
                                          dangerouslySetInnerHTML={{
                                            __html: item?.widgetnotes,
                                          }}
                                        ></div>
                                      ) : htmlToText(item?.widgetnotes)
                                          ?.length > 100 ? (
                                        <div className="insights-notes-detail">
                                          {`${htmlToText(
                                            item?.widgetnotes
                                          ).slice(0, 100)}`}
                                          <span
                                            className="read-more-device ps-2"
                                            onClick={() => {
                                              clickedNoteDetail.current = item;
                                              setViewWidgetNote(
                                                item?.widgetnoteid
                                              );
                                            }}
                                          >
                                            More...
                                          </span>
                                        </div>
                                      ) : (
                                        <div className="insights-notes-detail">{`${htmlToText(
                                          item?.widgetnotes
                                        )}`}</div>
                                      )}
                                      <div className="d-flex align-items-center gap-2 mt-2">
                                        {fileData?.map(
                                          (fileItem, fileIndex) => {
                                            if (fileItem?.filetype === "FILE") {
                                              return (
                                                <a
                                                  target="_blank"
                                                  href={
                                                    fileItem?.attachmentpath
                                                  }
                                                >
                                                  <img
                                                    src={pdfIcon}
                                                    className="fileIcon"
                                                  />
                                                </a>
                                              );
                                            } else if (
                                              fileItem?.filetype === "IMAGE"
                                            ) {
                                              return (
                                                <a
                                                  target="_blank"
                                                  href={
                                                    fileItem?.attachmentpath
                                                  }
                                                >
                                                  <img
                                                    src={imageIcon}
                                                    className="fileIcon"
                                                  />
                                                </a>
                                              );
                                            } else {
                                              return (
                                                <a
                                                  target="_blank"
                                                  href={
                                                    fileItem?.attachmentpath
                                                  }
                                                >
                                                  <img
                                                    src={folderIcon}
                                                    className="fileIcon"
                                                  />
                                                </a>
                                              );
                                            }
                                          }
                                        )}
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        );
                      }
                    )}
                  </Accordion>
                ) : (
                  <div className="d-flex justify-content-center">
                    <p>No Data Found</p>
                  </div>
                )}
              </div>
              <div ref={refForScroll}></div>
            </Col>
            <Col xl={12} className="noteactionbutton">
              <div className="mt-2">
                <Button
                  className="widget-note-add-btn-plus w-100"
                  type="button"
                  title="Add"
                  onClick={() => {
                    setAddNoteModal(true);
                  }}
                >
                  <img src={plusIcon} alt="plus" /> Add Note
                </Button>
              </div>
            </Col>
          </Row>
        </Offcanvas.Body>
      </Offcanvas>
      {addNoteModal && (
        <AddWidgetModal
          showModal={addNoteModal}
          closeModal={closeAddNoteModal}
          widgetId={widgetId}
          propertyId={propertyId}
          getList={dateForNote ? getNoteByDataList : getNoteDetail}
          rowTitle={rowTitle}
          dateForNote={dateForNote}
        />
      )}
    </div>
  );
};

export default WidgetNote;
