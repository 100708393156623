import React, { useEffect, useRef, useState } from "react";
import { Row, Form, Col, Button, Container } from "react-bootstrap";
import pdfIcon from "../../../Assets/Images/CRM/pdf.svg";
import { commonService } from "../../../utils/commonService";
import DatePicker from "react-datepicker";
import { API_URL } from "../../../utils/apiRoutes";
import { validationRule } from "../../../utils/constant";
import { hotelListCommon } from "../Hotels/HotelListCommon";
import { AccountListCommon } from "../Contacts/AccountListCommon";
import { checkValidation } from "../../../utils/helper";
import { apiCall } from "../../../utils/axiosService";
import { apiResponse } from "../../../utils/apiResponse";
import { successToastMessage } from "../../../utils/toastMessage";
import filterIcon from "../../../Assets/Images/CRM/filter.png";
import { useSelector } from "react-redux";
import Loader from "../../Loader/Loader";
import { Link } from "react-router-dom";

const MeetingCateringbyDateReport = ({ title }) => {
  const [loading, setLoading] = useState(false);
  const [hotelList, setHotelList] = useState(null);
  const userDetails = commonService.getLoginUserData();

  const crmPropertyDetailFromRTK = useSelector(
    (store) => store.headerProperty.crmPropertyDetail
  );

  let propertyIdPayload = "";
  if (crmPropertyDetailFromRTK) {
    if (crmPropertyDetailFromRTK?.propertyid === "") {
      let propertyPayload = crmPropertyDetailFromRTK?.allPropertyId;
      propertyIdPayload = propertyPayload.slice(0, -1);
    } else {
      propertyIdPayload = crmPropertyDetailFromRTK?.propertyid?.toString();
    }
  }

  let startDate = new Date();
  let endDate = new Date(startDate.getTime() - 7 * 24 * 60 * 60 * 1000);

  let validationObj = {
    propertyID: "",
    startDate: endDate,
    endDate: startDate,

    validationRule: [
      {
        fieldName: "propertyID",
        rule: validationRule.required,
        message: "Property Id required",
      },
      {
        fieldName: "startDate",
        rule: validationRule.required,
        message: "Start Date is required",
      },
      {
        fieldName: "endDate",
        rule: validationRule.required,
        message: "End Date is required",
      },
    ],
    errorObj: {},
    isValidate: true,
  };
  const [monthlyRollUpData, setMonthlyRollUpData] = useState(validationObj);
  const setPdfRef = useRef("");
  const [showPdf, setShowPdf] = useState(false);
  const [showAllReport, setshowAllReport] = useState(false);
  const isDefaultApiCalledRef = useRef(false);
  const [errorMessage, setErrorMessage] = useState(false);

  const handleshowAllReport = () => {
    setshowAllReport(!showAllReport);
  };

  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setMonthlyRollUpData({
      ...monthlyRollUpData,
      startDate: start,
      endDate: end,
    });
  };

  const getHotelList = async () => {
    let response = await hotelListCommon(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.CRM +
          API_URL.PROPERTY_TERM.GET_PROPERTY_LIST_BY_USER_ID,
        body: {
          id: userDetails?.userid,
        },
      },
      false
    );

    if (response?.isValidate) {
      setHotelList(response?.data);

      if (crmPropertyDetailFromRTK?.propertyid !== "") {
        setMonthlyRollUpData({
          ...monthlyRollUpData,
          propertyID: crmPropertyDetailFromRTK?.propertyid,
        });
      }
    }
  };

  const iframeloader = () => {
    setLoading(false);
  };

  const GenerateReportbtn = async () => {
    setShowPdf(false);
    setLoading(true);
    let validation = checkValidation(monthlyRollUpData);

    if (!validation.isValidate) {
      setMonthlyRollUpData(validation);
      setLoading(false);
      return;
    }

    let ActivitiesReport = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.CRM +
          API_URL.CRM_REPORT.MEETING_AND_CATERING_BY_DATE_REPORT,
        body: {
          propertyID: parseInt(monthlyRollUpData.propertyID),
          startDate: commonService.getDateInDBFormat(
            monthlyRollUpData.startDate
          ),
          endDate: commonService.getDateInDBFormat(monthlyRollUpData.endDate),
        },
      },
      false
    );

    let response = apiResponse(false, ActivitiesReport);
    if (response?.isValidate) {
      isDefaultApiCalledRef.current = true;
      // setLoading(false);
      setshowAllReport(false);
      setErrorMessage(false);
      // successToastMessage(response?.message);
      setPdfRef.current =
        process.env.REACT_APP_APIBASEPATH_CRM_REPORT_PDF + response.data.data;
      setShowPdf(true);
    }

    if (!response?.isValidate) {
      isDefaultApiCalledRef.current = true;
      setshowAllReport(true);
      setLoading(false);
      setErrorMessage(true);
    }
  };

  useEffect(() => {
    if (crmPropertyDetailFromRTK?.propertyid === "") {
      isDefaultApiCalledRef.current = true;
      setshowAllReport(true);
    }
    getHotelList();
  }, []);

  useEffect(() => {
    if (monthlyRollUpData.propertyID !== "" && !isDefaultApiCalledRef.current) {
      GenerateReportbtn();
    }
  }, [monthlyRollUpData.propertyID]);

  return (
    <div className="all-reports-main ToolsDisplacementCalcMain">
      <Container fluid>
        <Row>
          <Col xl={12}>
            <div className="ProspectDashboardTitleWraper  d-flex justify-content-between align-items-center marginBottom20">
              <h2 className="d-flex align-items-center gap-1">
                <Link to="/crm/dashboard">Dashboard - </Link>{" "}
                <span className="tab-title-main">{title}</span>
              </h2>
              <div className="heading-menu-btns">
                <img
                  src={filterIcon}
                  alt="filterIcon"
                  title="Filter Report"
                  className="cursorPointer report-filter"
                  onClick={handleshowAllReport}
                />
              </div>
            </div>
          </Col>
        </Row>

        {showAllReport && (
          <div className="displacementCalcWrapper width400">
            <div className="from-groups no-border-break mb-2 small-height-select">
              <Form.Label>Select Property</Form.Label>
              <Form.Select
                aria-label="Default select example"
                value={monthlyRollUpData?.propertyID}
                onChange={(e) => {
                  setMonthlyRollUpData({
                    ...monthlyRollUpData,
                    propertyID: e.target.value,
                  });
                }}
              >
                <option value={0}>Select Property</option>
                {hotelList?.map((item, index) => (
                  <option key={index} value={item?.propertyid}>
                    {item?.propertyname}
                  </option>
                ))}
              </Form.Select>
              {monthlyRollUpData?.errorObj?.propertyID &&
                monthlyRollUpData?.propertyID === "" && (
                  <small className="text-danger">
                    {monthlyRollUpData?.errorObj?.propertyID}
                  </small>
                )}
            </div>
            <div className="startFrom from-groups no-border-break mb-4 meghaDatePicker">
              <Form.Label htmlFor="startDate">Date Range</Form.Label>
              <DatePicker
                onChange={handleDateChange}
                startDate={monthlyRollUpData.startDate}
                endDate={monthlyRollUpData.endDate}
                selectsRange
                monthsShown={2}
              />
            </div>
            <div className="d-flex gap-3 align-items-center justify-content-end ">
              {loading ? (
                <button
                  className="default-btn primary-btn d-flex gap-1 align-items-center"
                  type="button"
                  disabled
                >
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Loading...
                </button>
              ) : (
                <button
                  className="default-btn primary-btn d-flex gap-1 align-items-center"
                  onClick={() => {
                    GenerateReportbtn();
                  }}
                >
                  <span className="revpak-icon">
                    <img src={pdfIcon} alt="" />
                  </span>
                  <span>Generate Report</span>
                </button>
              )}
            </div>
          </div>
        )}
        <p className="text-center">{errorMessage && " No Record Found"}</p>
        {/* {loading ? (
          <Loader />
        ) : (
          <div className="pdf-is-showing-here mt-3">
            {showPdf && setPdfRef.current && (
              <iframe
                title="Report Doc"
                src={setPdfRef.current}
                width="100%"
                height="800px"
              />
            )}
          </div>
        )} */}
        {loading && <Loader />}
        <div className="pdf-is-showing-here mt-3">
          {showPdf && setPdfRef.current && (
            <iframe
              onLoad={iframeloader}
              title="Report Doc"
              src={setPdfRef.current}
              width="100%"
              height="800px"
            />
          )}
        </div>
      </Container>
    </div>
  );
};

export default MeetingCateringbyDateReport;
