import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { apiCall } from "../../../../utils/axiosService";
import { API_URL } from "../../../../utils/apiRoutes";
import { apiResponse } from "../../../../utils/apiResponse";
import DoughnutChart from "../../../CommonComponent/DoughnutChart ";
import { useSelector } from "react-redux";
import { compName } from "../../../../utils/constant";
import WidgetDetailModal from "../../WidgetDetailModal";
import WidgetNote from "../../WidgetNote";
import infoIcon from "../../../../Assets/Images/Action Icons/infoIcon..svg";
import NoteIcon from "../../../../Assets/Images/CRM/notes.svg";
import downloadIcon from "../../../../Assets/Images/Operation/downloadIcon.svg";
import AddWidgetModal from "../../AddWidgetModal";
import Comments from "../Comments/Comment";

const MarketSegmentChart = ({
  setCompDataFromChild,
  rowIndex,
  colIndex,
  api,
  para,
  setting,
}) => {
  const [marketSegmentData, setMarketSegmentData] = useState(null);
  const [prevYeaMarketSegmentData, setPrevYearMarketSegmentData] =
    useState(null);
  const [showNoteSidebar, setShowNoteSidebar] = useState(false);
  const [widgetModal, setWidgetModal] = useState(false);
  const widgetDetailRef = useRef();
  const propertyDetailFromRTK = useSelector(
    (store) => store.headerProperty.propertyDetail
  );
  const closeWidgetModal = () => {
    setWidgetModal(false);
  };
  const closeNoteSideBar = () => {
    setShowNoteSidebar(false);
  };

  const details = {
    "Local Negotiated": {
      color: "#4AB8F9",
    },
    "Corporate Discount": {
      color: "#719112",
    },
    Group: {
      color: "#FFD036",
    },
    "Qualified Discount": {
      color: "#4AFFFF",
    },
    Government: {
      color: "#333399",
    },
    "Brand Discount": {
      color: "#C0C0C0",
    },
    "Opaque & Package": {
      color: "#9C4E98",
    },
    Retail: { color: "#FF791D" },
    Employee: { color: "#0275F4" },

    "OTA Discount": { color: "#B0DDF2" },
    "Corporate Negotiated": { color: "#FFEDBB" },
    "OTA Retail": { color: "#E8FFC6" },
    Redemption: { color: "#FFC6C6" },
    Unmapped: { color: "#FFCDF7" },
  };

  const getMarketSegment = async () => {
    let marketSegmentResponse = await apiCall(
      {
        method: "POST",
        url: API_URL.BASE_API_URL.SnapShot + API_URL.MARKET_SEGMENT.CHART,
        body: {
          startDate: `${new Date().getFullYear()}-01-01`,
          endDate: `${new Date().getFullYear()}-12-31`,
          propertyCode: para?.propertyCode,
        },
      },
      false
    );

    let response = apiResponse(false, marketSegmentResponse);

    if (response?.isValidate) {
      let dataFromApi = response?.data?.data;
      let doughnutData = {
        labels: [],
        datasets: [
          {
            data: [],
            backgroundColor: [],
            hoverBackgroundColor: [],
          },
        ],
      };
      for (let i = 0; i < dataFromApi?.length; i++) {
        let color = Math.floor(Math.random() * 16777215).toString(16);
        // if (dataFromApi[i].MarketSegment !== "") {
        let marketSegmentName = dataFromApi[i].MarketSegment;
        let marketSegmentPr = dataFromApi[i].percent;
        doughnutData.labels.push(marketSegmentName);
        doughnutData.datasets[0].data.push(marketSegmentPr);
        doughnutData.datasets[0].backgroundColor.push(`#${color}`);
        doughnutData.datasets[0].hoverBackgroundColor.push(`#${color}`);
        // } else {
        //   let marketSegmentName = "Unmapped";
        //   let marketSegmentPr = dataFromApi[i].percent;
        //   doughnutData.labels.push(marketSegmentName);
        //   doughnutData.datasets[0].data.push(marketSegmentPr);
        //   doughnutData.datasets[0].backgroundColor.push(
        //     `#${Math.floor(Math.random() * 16777215).toString(16)}`
        //   );
        //   doughnutData.datasets[0].hoverBackgroundColor.push(
        //     `#${Math.floor(Math.random() * 16777215).toString(16)}`
        //   );
        // }
      }

      setMarketSegmentData(doughnutData);
    }
  };

  const getPrevYearMarketSegment = async () => {
    let marketSegmentResponse = await apiCall(
      {
        method: "POST",
        url: API_URL.BASE_API_URL.SnapShot + API_URL.MARKET_SEGMENT.CHART,
        body: {
          startDate: `${new Date().getFullYear() - 1}-01-01`,
          endDate: `${new Date().getFullYear() - 1}-12-31`,
          propertyCode: para?.propertyCode,
        },
      },
      false
    );

    let response = apiResponse(false, marketSegmentResponse);

    if (response?.isValidate) {
      let dataFromApi = response?.data?.data;
      let doughnutData = {
        labels: [],
        datasets: [
          {
            data: [],
            backgroundColor: [],
            hoverBackgroundColor: [],
          },
        ],
      };
      for (let i = 0; i < dataFromApi?.length; i++) {
        let color = Math.floor(Math.random() * 16777215).toString(16);
        // if (dataFromApi[i].MarketSegment !== "") {
        let marketSegmentName = dataFromApi[i].MarketSegment;
        let marketSegmentPr = dataFromApi[i].percent;
        doughnutData.labels.push(marketSegmentName);
        doughnutData.datasets[0].data.push(marketSegmentPr);
        doughnutData.datasets[0].backgroundColor.push(`#${color}`);
        doughnutData.datasets[0].hoverBackgroundColor.push(`#${color}`);
        // } else {
        //   let marketSegmentName = "Unmapped";
        //   let marketSegmentPr = dataFromApi[i].percent;
        //   doughnutData.labels.push(marketSegmentName);
        //   doughnutData.datasets[0].data.push(marketSegmentPr);
        //   doughnutData.datasets[0].backgroundColor.push(
        //     `#${Math.floor(Math.random() * 16777215).toString(16)}`
        //   );
        //   doughnutData.datasets[0].hoverBackgroundColor.push(
        //     `#${Math.floor(Math.random() * 16777215).toString(16)}`
        //   );
        // }
      }
      setPrevYearMarketSegmentData(doughnutData);
    }
  };

  useEffect(() => {
    if (api === 0) {
    } else {
      getMarketSegment();
      getPrevYearMarketSegment();
    }
  }, []);

  const chartDoughnutOptions = {
    plugins: {
      legend: {
        display: false,
      },
    },
    elements: {
      arc: {
        borderWidth: 0,
        spacing: 0,
      },
    },
    legend: {
      display: true,
      position: "right",
    },
  };

  return (
    <div className="widget-container">
      {api !== 0 && (
        <div className="gap-1 widget-container-menu">
          {/* <Button className="heading-menu-btns" type="button">
            <img
              src={NoteIcon}
              alt="menuIcon"
              onClick={() => {
                setShowNoteSidebar(true);
              }}
            />
          </Button> */}
          <Button className="heading-menu-btns" type="button">
            <img
              src={infoIcon}
              alt="info"
              onClick={() => {
                widgetDetailRef.current = {
                  widgetdetails: "",
                  usernote: "",
                  compName: compName?.marketSegmentChart,
                  widgetId: para?.widgetId,
                };
                setWidgetModal(true);
              }}
            />
          </Button>
          {/* <Button className="heading-menu-btns" type="button">
            <img src={downloadIcon} alt="menuIcon" />
          </Button> */}
        </div>
      )}
      <Row>
        <Col xxl={6}>
          {marketSegmentData && (
            <div className="snapshotDoughnutChartMain">
              <h2>May Market Segment Mix</h2>
              <div className="snapshotDoughnutChartMainInner">
                <DoughnutChart
                  data={marketSegmentData}
                  options={chartDoughnutOptions}
                />
              </div>
            </div>
          )}
        </Col>
        <Col xxl={6}>
          {prevYeaMarketSegmentData && (
            <div className="snapshotDoughnutChartMain">
              <h2>May Market Segment Mix {new Date().getFullYear() - 1}</h2>
              <div className="snapshotDoughnutChartMainInner">
                <DoughnutChart
                  data={prevYeaMarketSegmentData}
                  options={chartDoughnutOptions}
                />
              </div>
            </div>
          )}
        </Col>
      </Row>
      {api !== 0 && <Comments widgetDetails={para} editorId={"MarketSegmentChart"}/>}

      {/* {showNoteSidebar && (
        <WidgetNote
          show={showNoteSidebar}
          handleClose={closeNoteSideBar}
          widgetId={para?.widgetId}
          propertyId={propertyDetailFromRTK?.propertyid}
          rowTitle={para?.rowTitle}
        />
      )} */}
      {showNoteSidebar && (
        <AddWidgetModal
          showModal={showNoteSidebar}
          closeModal={closeNoteSideBar}
          widgetId={para?.widgetId}
          propertyId={propertyDetailFromRTK?.propertyid}
          rowTitle={para?.rowTitle}
        />
      )}
      {widgetModal && (
        <WidgetDetailModal
          showModal={widgetModal}
          closeModal={closeWidgetModal}
          data={widgetDetailRef.current}
        />
      )}
    </div>
  );
};

export default MarketSegmentChart;
