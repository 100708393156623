import React, { useEffect, useState } from "react";
import { apiCall } from "../../../../utils/axiosService";
import { apiResponse } from "../../../../utils/apiResponse";
import { Line } from "react-chartjs-2";
import {
  Chart,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  Filler,
} from "chart.js";
import { API_URL } from "../../../../utils/apiRoutes";
import { commonService } from "../../../../utils/commonService";
import moment from "moment";
import Loader from "../../../Loader/Loader";
import DatePicker from "react-datepicker";
import { Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import Comments from "../Comments/Comment";

const BookingPaceComparisonChart = ({
  setCompDataFromChild,
  rowIndex,
  colIndex,
  api,
  para,
  setting,
}) => {
  const propertyDetailFromRTK = useSelector(
    (store) => store.headerProperty.propertyDetail
  );
  const [chartData, setChartData] = useState(null);
  const [date, setDate] = useState(
    para?.asOfDate ? new Date(para?.asOfDate) : new Date()
  );
  const [loading, setLoading] = useState(false);
  const [apiData, setApiData] = useState(null);
  const chartLabels = [
    -1, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
    20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38,
    39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57,
    58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76,
    77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90,
  ];
  let xArray = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100];

  let chartOptions = {
    indexAxis: "x",
    scales: {
      x: {
        stacked: true,
        title: {
          display: true,
          text: "",
        },
        ticks: {
          align: "start",
          autoSkip: false,
        },
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: "",
        },
        grid: {
          display: true,
        },
        offset: true,
        ticks: {
          callback: function (value) {
            return value;
          },
        },
        max: apiData?.Inventry?.[0]?.totalrooms,
        min: 0,
        stepSize: 10,
      },
    },
    elements: {
      line: {
        fill: false,
      },
      point: {
        radius: 0,
      },
    },

    plugins: {
      legend: {
        display: true,
        align: "end",
        position: "top",
        maxWidth: 20,
        maxHeight: 100,
        labels: {
          boxWidth: 10,
          boxHeight: 10,
        },
      },
      tooltip: {
        enabled: true,
        mode: "index",
        callbacks: {
          title: function (context) {
            return context[0].label;
          },
          label: function (context) {
            let label = context.dataset.label;
            let value = context.formattedValue;
            return `${label}: ${value}`;
          },
        },
      },
    },
    responsive: true,
    barThickness: 12,
    categorySpacing: 0.3,
    barPercentage: 0.8,
    categoryPercentage: 0.9,
  };

  Chart.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Tooltip,
    Legend,
    PointElement,
    LineElement,
    Filler
  );

  const getChartData = async () => {
    setLoading(true);
    let chartDetail = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.SnapShot +
          API_URL.BOOKING_PACE.CANCELLATION_CHART,
        body: {
          AsOfDate: para?.asOfDate,
          propertyid: propertyDetailFromRTK?.propertyid,
          SelectedDate: commonService.getDateForNode(date),
          propertyCode: para?.propertyCode,
        },
      },
      false
    );

    let response = apiResponse(false, chartDetail);

    if (response?.isValidate) {
      let dayInfuture = {
          type: "line",
          label: `Forecast`,
          borderColor: "#25A855",
          backgroundColor: "#25A855",
          data: [],
          stack: "stack1" || "default",
        },
        last30Day = {
          type: "line",
          label: `Last 30 Day Avg`,
          borderColor: "#E64839",
          backgroundColor: "#E64839",
          data: [],
          stack: "stack2" || "default",
        },
        selectedDate = {
          type: "line",
          label: `Total Booking`,
          borderColor: "#1686F9",
          backgroundColor: "#1686F9",
          data: [],
          stack: "stack3" || "default",
        },
        labels = [];

      let prevDateData = "";
      let dataFromApi = response?.data?.data?.result;

      for (let i = 0; i < dataFromApi?.length; i++) {
        if (
          moment(dataFromApi[i].staydate).format("YYYY-MM-DD") ===
          moment(date).format("YYYY-MM-DD")
        ) {
          prevDateData = i;
        }
      }

      let lastIndex = prevDateData - 1;
      dayInfuture.data = [
        ...dayInfuture.data,
        dataFromApi?.[lastIndex]?.forcastroom
          ? dataFromApi?.[lastIndex]?.forcastroom
          : 0,
      ];
      last30Day.data = [
        ...last30Day.data,
        dataFromApi?.[lastIndex]?.pu_avg ? dataFromApi?.[lastIndex]?.pu_avg : 0,
      ];
      selectedDate.data = [
        ...selectedDate.data,
        dataFromApi?.[lastIndex]?.total_booking
          ? dataFromApi?.[lastIndex]?.total_booking
          : 0,
      ];
      labels = [
        ...labels,
        dataFromApi?.[lastIndex]?.day_diff % 2 == 0
          ? dataFromApi?.[lastIndex]?.day_diff
          : "",
      ];

      for (let i = prevDateData; i < dataFromApi?.length; i++) {
        dayInfuture.data = [
          ...dayInfuture.data,
          dataFromApi?.[i]?.forcastroom ? dataFromApi?.[i]?.forcastroom : 0,
        ];
        last30Day.data = [
          ...last30Day.data,
          dataFromApi?.[i]?.pu_avg ? dataFromApi?.[i]?.pu_avg : 0,
        ];
        selectedDate.data = [
          ...selectedDate.data,
          dataFromApi?.[i]?.total_booking ? dataFromApi?.[i]?.total_booking : 0,
        ];
        labels = [
          ...labels,
          dataFromApi?.[i]?.day_diff % 2 == 0 ? dataFromApi?.[i]?.day_diff : "",
        ];
      }

      let chartDetail = {
        // labels: chartLabels,
        labels: labels,
        datasets: [dayInfuture, last30Day, selectedDate],
      };

      setChartData(chartDetail);
      setApiData(response?.data?.data);
      setLoading(false);
    }

    if (!response?.isValidate) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (api === 0) {
    } else {
      if (para?.asOfDate) {
        getChartData();
      }
    }
  }, [para?.propertyCode, date]);

  return (
    <div>
      <div>
        <Col xl={2}>
          <DatePicker
            id="startDate"
            selected={commonService.convertDateInTimezone(date)}
            onChange={(clickedDate) => {
              setDate(clickedDate);
            }}
            placeholderText="Select Date"
          />
        </Col>
      </div>
      {loading ? (
        <Loader />
      ) : chartData ? (
        <>
          <div className="mt-2">
            <Line data={chartData} options={chartOptions} />
          </div>
          {api !== 0 && <Comments widgetDetails={para} editorId={"BookingPaceComparisonChart"}/>}
        </>
      ) : (
        <div className="mt-2">No Data Found</div>
      )}
    </div>
  );
};

export default BookingPaceComparisonChart;
