import React, { useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import cloud from "../../Assets/Images/CRM/cloud.svg";
import DatePicker from "react-datepicker";
import placeholder from "../../Assets/Images/Configuration/image_placeholder.svg";


function AddCompetitorModal({
  showAddCompetitorModal,
  closeAddCompetitorModal,
}) {
  const [selectedImage, setSelectedImage] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  return (
    <>
      <Modal
        className="AddCompetitorModalMain"
        size="md"
        centered
        show={showAddCompetitorModal}
        onHide={closeAddCompetitorModal}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add Competitor
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xl={12}>
              <div>
                <div className="customFileInput">
                    <span>Image</span>
                  <label htmlFor="fileInput">
                    <div className="inputBox">
                      {selectedImage ? (
                        <img src={selectedImage} alt="Selected" className="image-select"/>
                      ) : <img src={placeholder} alt="Selected" className="image-select"/>}
                    </div>
                  </label>
                  <input
                    type="file"
                    id="fileInput"
                    onChange={handleFileChange}
                    accept="image/*"
                  />
                </div>
              </div>
            </Col>
            <Col xl={6}>
              <div className="from-groups no-border-break mb-3">
                <Form.Label>Hotel Name</Form.Label>
                <Form.Control
                  type="text"
                  maxLength="15"
                  placeholder="Enter Hotel Name"
                />
              </div>
            </Col>
            <Col xl={6}>
              <div className="from-groups no-border-break mb-3">
                <Form.Label>Hotel Brand</Form.Label>
                <Form.Select aria-label="Default select example">
                  <option>Select Hotel Brand</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </Form.Select>
              </div>
            </Col>
            <Col xl={6}>
              <div className="from-groups no-border-break mb-3">
                <Form.Label>Business URL</Form.Label>
                <Form.Control
                  type="text"
                  maxLength="15"
                  placeholder="Enter Business URL"
                />
              </div>
            </Col>
            <Col xl={6}>
              <div className="from-groups no-border-break mb-3">
                <Form.Label>Rate Shop URL</Form.Label>
                <Form.Control
                  type="text"
                  maxLength="15"
                  placeholder="Enter Rate Shop URL"
                />
              </div>
            </Col>
            <Col xl={12}>
              <Form.Group
                className="mb-3 from-groups no-border-break"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Hotel Address</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={4}
                  placeholder="Enter Hotel Address"
                />
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button className="default-btn primary-btn">Submit</Button>
          <Button
            className="default-btn secondary-btn"
            onClick={closeAddCompetitorModal}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AddCompetitorModal;
