import React, { useRef, useState } from "react";
import ThirtyDayChild from "./ThirtyDayChild";
import SixtyDayChild from "./SixtyDayChild";
import NinentyDayChild from "./NinentyDayChild";

const NinentyDayOutLook = ({
  setCompDataFromChild,
  rowIndex,
  colIndex,
  api,
  para,
  setting,
}) => {
  return (
    <div className="widget-container">
      <ThirtyDayChild api={api} para={para} />
      <SixtyDayChild api={api} para={para} />
      <NinentyDayChild api={api} para={para} />
    </div>
  );
};

export default NinentyDayOutLook;
