import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import HorizontalBarChart from "../../../CommonComponent/HorizontalBarChart";
import { commonService } from "../../../../utils/commonService";
import { apiCall } from "../../../../utils/axiosService";
import { API_URL } from "../../../../utils/apiRoutes";
import { apiResponse } from "../../../../utils/apiResponse";
import { compName, month } from "../../../../utils/constant";
import infoIcon from "../../../../Assets/Images/Action Icons/infoIcon..svg";
import NoteIcon from "../../../../Assets/Images/CRM/notes.svg";
import downloadIcon from "../../../../Assets/Images/Operation/downloadIcon.svg";
import { useSelector } from "react-redux";
import WidgetDetailModal from "../../WidgetDetailModal";
import WidgetNote from "../../WidgetNote";
import AddWidgetModal from "../../AddWidgetModal";
import Comments from "../Comments/Comment";

const PickupChart = ({
  setCompDataFromChild,
  rowIndex,
  colIndex,
  api,
  para,
  setting,
}) => {
  const yearsArr = [
    ...commonService.getPastYearForDropDown(10),
    ...commonService.getFutureYearForDropDown(10),
    new Date().getFullYear(),
  ]?.sort(function (a, b) {
    return a - b;
  });
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const labels = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const [oneChartData, setOneChartData] = useState(null);
  const [sevenChartData, setSevenChartData] = useState(null);
  const [showNoteSidebar, setShowNoteSidebar] = useState(false);
  const [widgetModal, setWidgetModal] = useState(false);
  const widgetDetailRef = useRef();
  const propertyDetailFromRTK = useSelector(
    (store) => store.headerProperty.propertyDetail
  );
  const closeWidgetModal = () => {
    setWidgetModal(false);
  };
  const closeNoteSideBar = () => {
    setShowNoteSidebar(false);
  };

  const oneDayPickup = {
    label: "1 day pickup",
    backgroundColor: "#3fb978",
    // data: [150, 150, 300, 450, 600, 750, 900, 1050, 900, 900, 600, 300],
    data: [],
    stack: "stack1",
  };

  const oneDayPickupForecast = {
    label: "1 day forecast change",
    backgroundColor: "#4f98ca",
    // data: [150, 150, 300, 450, 600, 750, 900, 1050, 900, 900, 600, 300],
    data: [],
    stack: "stack2",
  };

  const sevenDayPickup = {
    label: "7 day pickup",
    backgroundColor: "#3fb978",
    // data: [150, 150, 300, 450, 600, 750, 900, 1050, 900, 900, 600, 300],
    data: [],
    stack: "stack3",
  };

  const sevenDayPickupForecast = {
    label: "7 day forecast change",
    backgroundColor: "#4f98ca",
    // data: [150, 150, 300, 450, 600, 750, 900, 1050, 900, 900, 600, 300],
    data: [],
    stack: "stack4",
  };

  const getOneDayChartDetail = async () => {
    let oneDayChartResponse = await apiCall(
      {
        method: "POST",
        url: API_URL.BASE_API_URL.SnapShot + API_URL.PICK_UP.ONE_DAY_CHART,
        body: {
          AsOfDate: para?.asOfDate,
          year: `${selectedYear}`,
          propertyCode: para?.propertyCode,
        },
      },
      false
    );

    let response = apiResponse(false, oneDayChartResponse);

    if (response?.isValidate) {
      let dataFromApi = response?.data?.data;
      oneDayPickup.data = [];
      oneDayPickupForecast.data = [];

      for (let i = 0; i < month?.length; i++) {
        var oneDayItem = dataFromApi.find((item) => {
          if (
            item.result_type === "PicOneDay" &&
            item.month?.trim() == month[i]
          ) {
            return item;
          }
          return null;
        });
        oneDayPickup.data.push(oneDayItem ? oneDayItem.rev : 0);

        var oneDayForecastItem = dataFromApi.find((item) => {
          if (
            item.result_type === "PickOneDayForcast" &&
            item.month?.trim() == month[i]
          ) {
            return item;
          }
          return null;
        });
        oneDayPickupForecast.data.push(
          oneDayForecastItem ? oneDayForecastItem.rev : 0
        );
      }
      setOneChartData([oneDayPickup, oneDayPickupForecast]);
    }

    if (!response?.isValidate) {
      oneDayPickup.data = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      oneDayPickupForecast.data = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

      setOneChartData([oneDayPickup, oneDayPickupForecast]);
    }
  };

  const getSevenDayChartDetail = async () => {
    let sevenDayChartResponse = await apiCall(
      {
        method: "POST",
        url: API_URL.BASE_API_URL.SnapShot + API_URL.PICK_UP.SEVEN_DAY_CHART,
        body: {
          AsOfDate: para?.asOfDate,
          year: `${selectedYear}`,
          propertyCode: para?.propertyCode,
        },
      },
      false
    );

    let response = apiResponse(false, sevenDayChartResponse);

    if (response?.isValidate) {
      let dataFromApi = response?.data?.data;
      sevenDayPickup.data = [];
      sevenDayPickupForecast.data = [];

      for (let i = 0; i < month?.length; i++) {
        var sevenDayItem = dataFromApi.find((item) => {
          if (
            item.result_type === "PicOneDay" &&
            item.month?.trim() == month[i]
          ) {
            return item;
          }
          return null;
        });
        sevenDayPickup.data.push(sevenDayItem ? sevenDayItem.rev : 0);

        var sevenDayForecastItem = dataFromApi.find((item) => {
          if (
            item.result_type === "PickOneDayForcast" &&
            item.month?.trim() == month[i]
          ) {
            return item;
          }
          return null;
        });
        sevenDayPickupForecast.data.push(
          sevenDayForecastItem ? sevenDayForecastItem.rev : 0
        );
      }
      setSevenChartData([sevenDayPickup, sevenDayPickupForecast]);
    }

    if (!response?.isValidate) {
      sevenDayPickup.data = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      sevenDayPickupForecast.data = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      setSevenChartData([sevenDayPickup, sevenDayPickupForecast]);
    }
  };

  useEffect(() => {
    if (api === 0) {
      oneDayPickup.data = [
        150, 150, 300, 450, 600, 750, 900, 1050, 900, 900, 600, 300,
      ];
      oneDayPickupForecast.data = [
        150, 150, 300, 450, 600, 750, 900, 1050, 900, 900, 600, 300,
      ];
      sevenDayPickup.data = [
        150, 150, 300, 450, 600, 750, 900, 1050, 900, 900, 600, 300,
      ];
      sevenDayPickupForecast.data = [
        150, 150, 300, 450, 600, 750, 900, 1050, 900, 900, 600, 300,
      ];

      setOneChartData([oneDayPickup, oneDayPickupForecast]);
      setSevenChartData([sevenDayPickup, sevenDayPickupForecast]);
    } else {
      if (para?.asOfDate) {
        getOneDayChartDetail();
        getSevenDayChartDetail();
      }
    }
  }, [para?.asOfDate, selectedYear]);

  return (
    <div className="widget-container">
      {api !== 0 && (
        <div className="gap-1 widget-container-menu">
          {/* <Button className="heading-menu-btns" type="button">
            <img
              src={NoteIcon}
              alt="menuIcon"
              onClick={() => {
                setShowNoteSidebar(true);
              }}
            />
          </Button> */}
          <Button className="heading-menu-btns" type="button">
            <img
              src={infoIcon}
              alt="info"
              onClick={() => {
                widgetDetailRef.current = {
                  widgetdetails: "",
                  usernote: "",
                  compName: compName?.pickupChart,
                  widgetId: para?.widgetId,
                };
                setWidgetModal(true);
              }}
            />
          </Button>
          {/* <Button className="heading-menu-btns" type="button">
            <img src={downloadIcon} alt="menuIcon" />
          </Button> */}
        </div>
      )}
      <Row>
        <Col xl={12}>
          <Col xl={1}>
            <div className="from-groups no-border-break width70">
              <Form.Select
                aria-label="Default select example"
                value={selectedYear}
                onChange={(e) => {
                  setSelectedYear(e.target.value);
                }}
              >
                {yearsArr?.map((item, index) => (
                  <option key={item} value={item}>
                    {item}
                  </option>
                ))}
              </Form.Select>
            </div>
          </Col>
        </Col>
        {oneChartData && (
          <Col xl={6}>
            <div className="snapShotHorizontalChart">
              <h4>1 Day Variance</h4>
              <div className="ForecastHorizontalChartInner">
                <HorizontalBarChart
                  labels={labels}
                  datasets={oneChartData}
                  maxPercentage={""}
                  barThickness={12}
                />
              </div>
            </div>
          </Col>
        )}
        {sevenChartData && (
          <Col xl={6}>
            <div className="snapShotHorizontalChart">
              <h4>7 Day Variance</h4>
              <div className="ForecastHorizontalChartInner">
                <HorizontalBarChart
                  labels={labels}
                  datasets={sevenChartData}
                  maxPercentage={""}
                  barThickness={12}
                />
              </div>
            </div>
          </Col>
        )}
      </Row>
      {api !== 0 && <Comments widgetDetails={para} editorId={"PickupChart"}/>}

      {/* {showNoteSidebar && (
        <WidgetNote
          show={showNoteSidebar}
          handleClose={closeNoteSideBar}
          widgetId={para?.widgetId}
          propertyId={propertyDetailFromRTK?.propertyid}
          rowTitle={para?.rowTitle}
        />
      )} */}
      {showNoteSidebar && (
        <AddWidgetModal
          showModal={showNoteSidebar}
          closeModal={closeNoteSideBar}
          widgetId={para?.widgetId}
          propertyId={propertyDetailFromRTK?.propertyid}
          rowTitle={para?.rowTitle}
        />
      )}
      {widgetModal && (
        <WidgetDetailModal
          showModal={widgetModal}
          closeModal={closeWidgetModal}
          data={widgetDetailRef.current}
        />
      )}
    </div>
  );
};

export default PickupChart;
