import React from "react";
import { commonService } from "../../../../utils/commonService";
import { apiCall } from "../../../../utils/axiosService";
import { API_URL } from "../../../../utils/apiRoutes";
import { apiResponse } from "../../../../utils/apiResponse";
import { Line } from "react-chartjs-2";
import {
    Chart,
    CategoryScale,
    LinearScale,
    BarElement,
    Tooltip,
    Legend,
    PointElement,
    LineElement,
    Filler,
} from "chart.js";
import { useState } from "react";
import { month } from "../../../../utils/constant";
import { Col, Form, Row, Table } from "react-bootstrap";
import moment from "moment";
import { useEffect } from "react";
import { useSelector } from "react-redux";

const AdrVsRateChart = ({
    setCompDataFromChild,
    rowIndex,
    colIndex,
    api,
    para,
    setting,
}) => {
    const propertyDetailFromRTK = useSelector(
        (store) => store.headerProperty.propertyDetail
    );
    const yearsArr = [
        ...commonService.getPastYearForDropDown(10),
        ...commonService.getFutureYearForDropDown(10),
        new Date().getFullYear(),
    ]?.sort(function (a, b) {
        return a - b;
    });
    let datesArray = commonService.getAllDatesInMonth(
        new Date().getFullYear(),
        new Date().getMonth()
    );
    const date = new Date();
    const startDate = commonService.getDateInDBFormat(
        new Date(date.getFullYear(), date.getMonth(), 1)
    );
    const endDate = commonService.getDateInDBFormat(
        new Date(date.getFullYear(), date.getMonth() + 1, 0)
    );
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
    const [dateRange, setDateRange] = useState({
        start: startDate,
        end: endDate,
    });
    const [chartData, setChartData] = useState(null);
    const [chartTableData, setChartTableData] = useState(null);

    const usedColorArr = [
        "#3565FC",
        "#CFDAFE",
        "#4A84C2",
        "#197EB4",
        "#CDD2B5",
        "#B0A4F6",
        "#CA4D46",
        "#DE9234",
        "#FDDFBC",
        "#FDDFBC",
    ];

    const generateColor = () => {
        let colorFromService = commonService?.generateRandomColor();
        let isColorExists = usedColorArr?.filter((x) => x === colorFromService);
        while (isColorExists?.length > 0) {
            generateColor();
        }
        usedColorArr.push(colorFromService);
        return colorFromService;
    };

    const getChartDetail = async () => {
        let month =
            selectedMonth >= 9 ? selectedMonth + 1 : `0${selectedMonth + 1}`;
        const chartDetailResponse = await apiCall(
            {
                method: "POST",
                url:
                    API_URL.BASE_API_URL.SnapShot +
                    API_URL.WIDGET_MODULE.ADR_VS_RATE_CHART,
                body: {
                    startDate: dateRange?.start,
                    AsOfDate: para?.asOfDate,
                    endDate: dateRange?.end,
                    propertyCode: para?.propertyCode,
                    propertyid: window.location.href.includes("crm")
                        ? para?.propertyId
                        : propertyDetailFromRTK?.propertyid
                            ? propertyDetailFromRTK?.propertyid
                            : "",
                    ChannelName: para?.propertyDetail?.chain_term,
                },
            },
            false
        );

        let response = apiResponse(false, chartDetailResponse);

        if (response?.isValidate) {
            let dataFromApi = response?.data?.data;
            let chartDataSet = {
                OCCData: {
                    type: "bar",
                    label: "OCC %",
                    data: [],
                    fill: false,
                    backgroundColor: "#3565FC",
                    borderColor: "#3565FC",
                    barThickness: 10,
                    borderWidth: 0,
                    tension: 0.4,
                    yAxisID: "y2",
                    order: 1,
                },
                propertyData: {
                    type: "bar",
                    label: "$ ADR",
                    data: [],
                    fill: false,
                    backgroundColor: "#CFDAFE",
                    borderColor: "#CFDAFE",
                    barThickness: 10,
                    borderWidth: 0,
                    tension: 0.4,
                    yAxisID: "y",
                    order: 1,
                },
                selfData: {
                    type: "line",
                    label: "$ Self",
                    data: [],
                    fill: false,
                    backgroundColor: "#4A84C2",
                    borderColor: "#4A84C2",
                    borderWidth: 3,
                    order: 0,
                    pointRadius: 4,
                    pointBorderWidth: 2,
                    pointBorderColor: "#ffffff",
                },
                otb: {
                    type: "line",
                    label: "OTB",
                    data: [],
                    fill: false,
                    backgroundColor: "#197EB4",
                    borderColor: "#197EB4",
                    borderWidth: 3,
                    order: 0,
                    pointRadius: 4,
                    pointBorderWidth: 2,
                    pointBorderColor: "#ffffff",
                },
                capacity: {
                    type: "line",
                    label: "Capacity",
                    data: [],
                    fill: false,
                    backgroundColor: "#CDD2B5",
                    borderColor: "#CDD2B5",
                    borderWidth: 3,
                    order: 0,
                    pointRadius: 4,
                    pointBorderWidth: 2,
                    pointBorderColor: "#ffffff",
                },
                effectiveCapacity: {
                    type: "line",
                    label: "Effective Capacity",
                    data: [],
                    fill: false,
                    backgroundColor: "#B0A4F6",
                    borderColor: "#B0A4F6",
                    borderWidth: 3,
                    order: 0,
                    pointRadius: 4,
                    pointBorderWidth: 2,
                    pointBorderColor: "#ffffff",
                },
                occForecast: {
                    type: "line",
                    label: "Occupancy Forecast",
                    data: [],
                    fill: false,
                    backgroundColor: "#CA4D46",
                    borderColor: "#CA4D46",
                    borderWidth: 3,
                    order: 0,
                    pointRadius: 4,
                    pointBorderWidth: 2,
                    pointBorderColor: "#ffffff",
                },
                avgData: {
                    type: "line",
                    label: "$ Avg",
                    data: [],
                    fill: false,
                    backgroundColor: "#DE9234",
                    borderColor: "#DE9234",
                    borderWidth: 2,
                    order: 0,
                    pointRadius: 4,
                    pointBorderWidth: 2,
                    pointBorderColor: "#ffffff",
                },
                minData: {
                    type: "line",
                    stacked: true,
                    label: "Min",
                    data: [],
                    fill: "+1",
                    backgroundColor: "#FDDFBC",
                    borderColor: "#FDDFBC",
                    borderWidth: 0,
                    tension: 0.2,
                    order: 4,
                },
                maxData: {
                    type: "line",
                    stacked: true,
                    label: "Max",
                    data: [],
                    fill: 1,
                    backgroundColor: "#FDDFBC",
                    borderColor: "#FDDFBC",
                    borderWidth: 0,
                    tension: 0.2,
                    order: 5,
                },
            };
            let groupingForTable = {
                date: [],
                OTB: [],
                Capacity: [],
                "Effective Capacity": [],
                Group: [],
                "Occupancy Forecast": [],
            };
            for (let i = 0; i < datesArray?.length; i++) {
                let month =
                    selectedMonth >= 9 ? selectedMonth + 1 : `0${selectedMonth + 1}`;
                let iDate = i >= 9 ? i + 1 : `0${i + 1}`;
                let date = `${selectedYear}-${month}-${iDate}`;
                let findDataForAdr = dataFromApi?.adr?.filter(
                    (x) => x.Dates === moment(date).format("YYYY-MM-DD")
                );
                let adrValue =
                    findDataForAdr?.length > 0
                        ? findDataForAdr[0]?.ADR
                            ? findDataForAdr[0]?.ADR
                            : 0
                        : 0;
                let occPercentageValue =
                    findDataForAdr?.length > 0
                        ? findDataForAdr[0]?.Occperc
                            ? findDataForAdr[0]?.Occperc
                            : 0
                        : 0;
                let otbValue =
                    findDataForAdr?.length > 0
                        ? findDataForAdr[0]?.RoomSold
                            ? findDataForAdr[0]?.RoomSold
                            : 0
                        : 0;
                let capacityValue =
                    findDataForAdr?.length > 0
                        ? findDataForAdr[0]?.Inventory
                            ? findDataForAdr[0]?.Inventory
                            : 0
                        : 0;
                let effectiveCapacityValue =
                    findDataForAdr?.length > 0
                        ? findDataForAdr[0]?.RoomsOfAvailable
                            ? findDataForAdr[0]?.RoomsOfAvailable
                            : 0
                        : 0;
                let occForecastValue =
                    findDataForAdr?.length > 0
                        ? findDataForAdr[0]?.Forecast_RMS
                            ? findDataForAdr[0]?.Forecast_RMS
                            : 0
                        : 0;

                let groupValue =
                    findDataForAdr?.length > 0
                        ? findDataForAdr[0]?.GroupOTB
                            ? findDataForAdr[0]?.GroupOTB
                            : 0
                        : 0;

                groupingForTable.date = [...groupingForTable?.date, date];
                groupingForTable.OTB = [...groupingForTable?.OTB, otbValue];
                groupingForTable.Capacity = [
                    ...groupingForTable?.Capacity,
                    capacityValue,
                ];
                groupingForTable["Effective Capacity"] = [
                    ...groupingForTable?.["Effective Capacity"],
                    effectiveCapacityValue,
                ];
                groupingForTable.Group = [...groupingForTable?.Group, groupValue];
                groupingForTable["Occupancy Forecast"] = [
                    ...groupingForTable?.["Occupancy Forecast"],
                    occForecastValue,
                ];

                chartDataSet?.propertyData?.data.push(adrValue);
                chartDataSet?.OCCData?.data.push(occPercentageValue);
                chartDataSet?.otb?.data.push(otbValue);
                chartDataSet?.capacity?.data.push(capacityValue);
                chartDataSet?.effectiveCapacity?.data.push(effectiveCapacityValue);
                chartDataSet?.occForecast?.data.push(occForecastValue);

                let findCompetitorData = dataFromApi?.compitiore?.filter(
                    (x) => x?.CheckInDate === moment(date).format("YYYY-MM-DD")
                );
                let findDataForAvgCount = dataFromApi?.compitiore?.filter(
                    (x) =>
                        x?.CheckInDate === moment(date).format("YYYY-MM-DD") &&
                        x?.IsSelf !== true &&
                        parseFloat(x?.RATE) > 0
                );
                if (findCompetitorData?.length > 0) {
                    let findSelfData = findCompetitorData?.filter(
                        (x) => x?.IsSelf === true
                      );
                      chartDataSet?.selfData?.data?.push(
                        findSelfData[0]?.RATE ? findSelfData[0]?.RATE : 0
                      );
                    let minValue = dataFromApi?.compitiore?.filter(
                        (x) => parseFloat(x.RATE) > 0 && x?.IsSelf !== true
                    );
                    let total = 0,
                        min = minValue[0]?.RATE ? parseFloat(minValue[0]?.RATE) : "",
                        max = findCompetitorData[0]?.RATE
                            ? parseFloat(findCompetitorData[0]?.RATE)
                            : 0;
                    for (let i = 0; i < findCompetitorData?.length; i++) {
                        min =
                            findCompetitorData[i]?.IsSelf === true
                                ? min
                                : findCompetitorData[i]?.RATE > 0
                                    ? parseFloat(findCompetitorData[i]?.RATE) < min
                                        ? parseFloat(findCompetitorData[i]?.RATE)
                                        : min
                                    : min;
                        //minArr
                        max =
                            findCompetitorData[i]?.IsSelf === true
                                ? max
                                : findCompetitorData[i]?.RATE
                                    ? parseFloat(findCompetitorData[i]?.RATE) > max
                                        ? parseFloat(findCompetitorData[i]?.RATE)
                                        : max
                                    : findCompetitorData[i]?.RATE;
                        // if (findCompetitorData[i]?.IsSelf === true) {
                        //     chartDataSet?.selfData?.data?.push(
                        //         findCompetitorData[i]?.RATE ? findCompetitorData[i]?.RATE : 0
                        //     );
                        // }
                        total =
                            findCompetitorData[i]?.IsSelf === true
                                ? total
                                : total +
                                parseFloat(
                                    findCompetitorData[i]?.RATE
                                        ? findCompetitorData[i]?.RATE
                                        : 0
                                );
                    }
                    chartDataSet?.avgData?.data?.push(
                        total > 0 ? Math.round(total / findDataForAvgCount?.length) : 0
                    );
                    chartDataSet?.minData?.data?.push(min);
                    chartDataSet?.maxData?.data?.push(max);
                } else {
                    chartDataSet?.selfData?.data?.push(0);
                    chartDataSet?.avgData?.data?.push(0);
                    chartDataSet?.minData?.data?.push(0);
                    chartDataSet?.maxData?.data?.push(0);
                }

                dataFromApi?.compitiore_list?.forEach((compItem) => {
                    let isCompetitorExists = findCompetitorData?.filter(
                        (x) => x?.competiterName === compItem?.competiterpropertyname
                    );
                    // let randomColor = generateColor();
                    let randomColor = commonService.CreateColor(`${compItem?.competiterpropertyname}`).BGColor

                    if (chartDataSet[compItem?.competiterpropertyname]) {
                        chartDataSet[compItem?.competiterpropertyname].data.push(
                            isCompetitorExists?.[0]?.RATE ? isCompetitorExists?.[0]?.RATE : 0
                        );
                    } else {
                        chartDataSet[compItem?.competiterpropertyname] = {
                            type: "line",
                            label: `${compItem?.competiterpropertyname}`,
                            data: [
                                isCompetitorExists?.[0]?.RATE
                                    ? isCompetitorExists?.[0]?.RATE
                                    : 0,
                            ],
                            fill: false,
                            backgroundColor: randomColor,
                            borderColor: randomColor,
                            borderWidth: 3,
                            order: 0,
                            pointRadius: 4,
                            pointBorderWidth: 2,
                            pointBorderColor: "#ffffff",
                        };
                    }
                });
            }
            let datasetForchart = [];
            Object?.keys(chartDataSet)?.forEach((key) => {
                datasetForchart.push(chartDataSet[key]);
            });

            setChartTableData(groupingForTable);
            setChartData({
                labels: datesArray,
                datasets: datasetForchart,
            });
        }

        if (!response?.isValidate) {
            let dummyChartDetail = {
                labels: datesArray,
                datasets: [
                    {
                        type: "bar",
                        label: "ADR",
                        data: [
                            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                            0, 0, 0, 0, 0, 0, 0, 0, 0,
                        ],
                        fill: false,
                        backgroundColor: "#cfdafe",
                        borderColor: "#cfdafe",
                        barThickness: 10,
                        borderWidth: 0,
                        tension: 0.4,
                        yAxisID: "y",
                        order: 1,
                    },
                    {
                        type: "bar",
                        label: "Occ %",
                        data: [
                            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                            0, 0, 0, 0, 0, 0, 0, 0, 0,
                        ],
                        fill: false,
                        backgroundColor: "#cfdafe",
                        borderColor: "#cfdafe",
                        barThickness: 10,
                        borderWidth: 0,
                        tension: 0.4,
                        yAxisID: "y",
                        order: 1,
                    },
                    {
                        type: "line",
                        label: "Self",
                        data: [
                            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                            0, 0, 0, 0, 0, 0, 0, 0, 0,
                        ],
                        fill: false,
                        backgroundColor: "#4a84c2",
                        borderColor: "#4a84c2",
                        borderWidth: 3,
                        order: 0,
                        pointRadius: 4,
                        pointBorderWidth: 2,
                        pointBorderColor: "#ffffff",
                    },
                    {
                        type: "line",
                        label: "Avg",
                        data: [
                            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                            0, 0, 0, 0, 0, 0, 0, 0, 0,
                        ],
                        fill: false,
                        backgroundColor: "#de9234",
                        borderColor: "#000",
                        borderWidth: 2,
                        order: 0,
                        pointRadius: 4,
                        pointBorderWidth: 2,
                        pointBorderColor: "#ffffff",
                    },
                    {
                        type: "line",
                        stacked: true,
                        label: "Min",
                        data: [
                            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                            0, 0, 0, 0, 0, 0, 0, 0, 0,
                        ],
                        fill: 1,
                        backgroundColor: "#FDDFBC",
                        borderColor: "#FDDFBC",
                        borderWidth: 0,
                        tension: 0.2,
                        order: 2,
                    },
                    {
                        type: "line",
                        stacked: true,
                        label: "Max",
                        data: [
                            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                            0, 0, 0, 0, 0, 0, 0, 0, 0,
                        ],
                        fill: 1,
                        backgroundColor: "#FDDFBC",
                        borderColor: "#FDDFBC",
                        borderWidth: 0,
                        tension: 0.2,
                        order: 2,
                    },
                ],
            };
            setChartData(dummyChartDetail);
        }
    };

    useEffect(() => {
        if (api === 0) {
            let dummyChartDetail = {
                labels: datesArray,
                datasets: [
                    {
                        type: "bar",
                        label: "Occ %",
                        data: [
                            240, 160, 145, 120, 48, 225, 47, 52, 99, 140, 160, 180, 180, 275,
                            225, 145, 145, 120, 48, 225, 47, 52, 99, 140, 160, 180, 180, 160,
                            120, 190, 120,
                        ],
                        fill: false,
                        backgroundColor: "#cfdafe",
                        borderColor: "#cfdafe",
                        barThickness: 10,
                        borderWidth: 0,
                        // pointRadius: 4,
                        // pointBorderWidth: 0,
                        // pointBorderColor: '#ffffff',
                        tension: 0.4,
                        yAxisID: "y",
                        order: 3,
                    },
                    {
                        type: "bar",
                        label: "Property",
                        data: [
                            240, 160, 145, 120, 48, 225, 47, 52, 99, 140, 160, 180, 180, 275,
                            225, 145, 145, 120, 48, 225, 47, 52, 99, 140, 160, 180, 180, 160,
                            120, 190, 120,
                        ],
                        fill: false,
                        backgroundColor: "#cfdafe",
                        borderColor: "#cfdafe",
                        barThickness: 10,
                        borderWidth: 0,
                        // pointRadius: 4,
                        // pointBorderWidth: 0,
                        // pointBorderColor: '#ffffff',
                        tension: 0.4,
                        yAxisID: "y",
                        order: 1,
                    },
                    {
                        type: "line",
                        label: "Self",
                        data: [
                            140, 160, 145, 120, 48, 225, 47, 52, 99, 140, 160, 180, 180, 275,
                            225, 145, 145, 120, 48, 225, 47, 52, 99, 140, 160, 180, 180, 160,
                            120, 190, 120,
                        ],
                        fill: false,
                        backgroundColor: "#4a84c2",
                        borderColor: "#4a84c2",
                        borderWidth: 3,
                        order: 0,
                        pointRadius: 4,
                        pointBorderWidth: 2,
                        pointBorderColor: "#ffffff",
                    },
                    {
                        type: "line",
                        label: "Avg",
                        data: [
                            160, 180, 180, 275, 225, 180, 180, 160, 120, 190, 120, 145, 145,
                            120, 48, 225, 47, 140, 160, 145, 120, 48, 225, 47, 52, 99, 140,
                            52, 99, 140, 160,
                        ],
                        fill: false,
                        backgroundColor: "#de9234",
                        borderColor: "#000",
                        borderWidth: 2,
                        order: 0,
                        pointRadius: 4,
                        pointBorderWidth: 2,
                        pointBorderColor: "#ffffff",
                    },
                    {
                        type: "line",
                        stacked: true,
                        label: "Min",
                        data: [
                            170, 190, 185, 295, 245, 190, 190, 170, 140, 210, 125, 165, 175,
                            140, 78, 255, 77, 150, 170, 155, 120, 68, 245, 87, 82, 109, 150,
                            72, 109, 150, 170,
                        ],
                        fill: 1,
                        backgroundColor: "#FDDFBC",
                        borderColor: "#FDDFBC",
                        borderWidth: 0,
                        tension: 0.2,
                        order: 2,
                        // pointRadius: 4,
                        // pointBorderWidth: 2,
                        // pointBorderColor: '#ffffff',
                    },
                    {
                        type: "line",
                        stacked: true,
                        label: "Max",
                        data: [
                            100, 120, 135, 90, 28, 165, 37, 42, 78, 120, 100, 110, 120, 100,
                            40, 125, 35, 110, 38, 125, 27, 32, 69, 30, 35, 80, 30, 20, 25,
                            100, 80,
                        ],
                        fill: 1,
                        backgroundColor: "#FDDFBC",
                        borderColor: "#FDDFBC",
                        borderWidth: 0,
                        tension: 0.2,
                        order: 2,
                        // pointRadius: 4,
                        // pointBorderWidth: 2,
                        // pointBorderColor: '#ffffff',
                    },
                ],
            };
            setChartData(dummyChartDetail);
        } else {
            if (para?.asOfDate) {
                getChartDetail();
            }
        }
    }, [para?.asOfDate, selectedYear, selectedMonth]);

    let chartOptions = {
        elements: {
            point: {
                radius: 0,
            },
        },
        scales: {
            x: {
                title: {
                    display: false,
                    text: "Dates",
                },
                grid: {
                    display: false,
                },
            },
            y: {
                stacked: false,
                beginAtZero: true,
                type: "linear",
                position: "left",
                title: {
                    display: true,
                    text: "Rate",
                },
                ticks: {
                    callback: function (value, index, values) {
                        // return `$${value}`;
                        return `${value}`;
                    },
                },
            },
            y2: {
                stacked: false,
                beginAtZero: true,
                min: 0,
                max: 100,
                type: "linear",
                position: "right",
                title: {
                    display: true,
                    text: "OCC%",
                },
                grid: {
                    drawOnChartArea: false,
                },
                ticks: {
                    min: 0,
                    max: 100,
                    stepSize: 10,
                    callback: function (value, index, values) {
                        return `${value} %`;
                    },
                },
                // ticks: {
                //   callback: function (value, index, values) {
                //     return `${value * 100} %`;
                //   },
                // },
            },
        },
        plugins: {
            //   title: {
            //     display: true,
            //     text: "Market Demand",
            //   },
            tooltip: {
                mode: "index",
                intersect: false,
            },
            legend: {
                display: true,
                align: "center",
                position: "bottom",
                paddingTop: 10,
                maxWidth: 20,
                maxHeight: 100,
                labels: {
                    boxWidth: 10,
                    boxHeight: 10,
                },
            },
            // filler: {
            //   propagate: true, // Propagate fill to previous data points
            // },
        },
        responsive: true,
        maintainAspectRatio: true,
        aspectRatio: 4 / 1,
    };

    Chart.register(
        CategoryScale,
        LinearScale,
        BarElement,
        Tooltip,
        Legend,
        PointElement,
        LineElement,
        Filler
    );
    return (
        <div>
            <Col xl={12}>
                <Row>
                    <Col xl={3}>
                        <Form.Select
                            value={selectedYear}
                            onChange={(e) => {
                                let d = new Date();
                                d.setMonth(parseInt(selectedMonth));
                                let startDate = commonService.getDateInDBFormat(
                                    new Date(e.target.value, d.getMonth(), 1)
                                );
                                let endDate = commonService.getDateInDBFormat(
                                    new Date(e.target.value, d.getMonth() + 1, 0)
                                );
                                setDateRange({
                                    start: startDate,
                                    end: endDate,
                                });
                                setSelectedYear(e.target.value);
                            }}
                        >
                            {yearsArr?.map((item, index) => (
                                <option key={item} value={item}>
                                    {item}
                                </option>
                            ))}
                        </Form.Select>
                    </Col>
                    <Col xl={3}>
                        <Form.Select
                            value={selectedMonth}
                            onChange={(e) => {
                                let d = new Date();
                                d.setMonth(parseInt(e.target.value));
                                let startDate = commonService.getDateInDBFormat(
                                    new Date(selectedYear, d.getMonth(), 1)
                                );
                                let endDate = commonService.getDateInDBFormat(
                                    new Date(selectedYear, d.getMonth() + 1, 0)
                                );
                                setDateRange({
                                    start: startDate,
                                    end: endDate,
                                });
                                setSelectedMonth(parseInt(e.target.value));
                            }}
                        >
                            {month?.map((item, index) => (
                                <option key={item} value={index}>
                                    {item}
                                </option>
                            ))}
                        </Form.Select>
                    </Col>
                </Row>
            </Col>
            {chartData && (
                <div className="ForecastHorizontalChartInner mt-2">
                    <Line data={chartData} options={chartOptions} />
                </div>
            )}
        </div>
    );
};

export default AdrVsRateChart;
