import React, { useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import profileImage from "../../../../Assets/Images/Configuration/userNo1.png";
import send from "../../../../Assets/Images/Revenue-management/send.svg";
import { apiCall } from "../../../../utils/axiosService";
import { apiResponse } from "../../../../utils/apiResponse";
import { API_URL } from "../../../../utils/apiRoutes";
import moment from "moment";
import { commonService } from "../../../../utils/commonService";
import { useSelector } from "react-redux";
import { create_ckeditor } from "../../../../utils/create_ckeditor";

const Comments = ({ widgetDetails, editorId }) => {
    const [commentsList, setCommentsList] = useState([]);
    const [commentText, setCommentText] = useState(null);
    const [userList, setUserList] = useState(null);
    const [filteredApiList, setFilteredApiList] = useState([]);
    const [btnDisable, setBtnDisable] = useState(false);
    const asOfDateFromRTK = useSelector((store) => store.headerProperty.asOfDate);
    const userRolePropertyListFromRTK = useSelector(
        (store) => store.headerProperty.userRolePropertyList
    );

    const getCommentsList = async () => {
        setCommentsList([]);
        let startend_date = asOfDateFromRTK
            ? asOfDateFromRTK
            : widgetDetails.asOfDate;
        if (
            startend_date !== null &&
            startend_date !== "" &&
            startend_date !== undefined
        ) {
            let filterDataResponse = await apiCall(
                {
                    method: "POST",
                    url:
                        API_URL.BASE_API_URL.Configuration + API_URL.WIDGET_NOTE.GET_LIST,
                    body: {
                        propertyid: widgetDetails?.propertyDetail?.propertyid,
                        widgetid: widgetDetails?.widgetId,
                        // startdate: commonService.getDateInDBFormat(startend_date),
                        // enddate: commonService.getDateInDBFormat(startend_date),
                        startdate:null,
                        enddate:null
                    },
                },
                false
            );
            let response = apiResponse(false, filterDataResponse);
            if (response?.isValidate) {
                setCommentsList(
                    commonService.ShortArray(response?.data?.data?.notedata, "createdon")
                );
            }
        }
    };

    const saveComment = async () => {
        setBtnDisable(true);
        // if (commentText) {
        let saveResponse = await apiCall(
            {
                method: "POST",
                url: API_URL.BASE_API_URL.Configuration + API_URL.WIDGET_NOTE.SAVE,
                body: {
                    propertyid: widgetDetails?.propertyDetail?.propertyid,
                    widgetid: widgetDetails?.widgetId,
                    asofdate: widgetDetails?.asOfDate,
                    title: widgetDetails?.rowTitle
                        ? widgetDetails?.rowTitle
                        : widgetDetails?.associationtype,
                    // widgetnotes: commentText,
                    widgetnotes: window.CKEDITOR.instances[editorId].getData(),
                },
            },
            true
        );
        let response = apiResponse(true, saveResponse);
        if (response?.isValidate) {
            setBtnDisable(false);
            const saveResponse = response?.data?.data;
            if (
                saveResponse.widgetnotes !== null &&
                saveResponse.widgetnotes !== "" &&
                saveResponse.widgetnotes !== undefined
            ) {
                let selectedUserId = [];
                let SplitByAT = saveResponse.widgetnotes.replace(/<[^>]*>|/g, '').match(/\@(.*?)\ /g);
                if (
                    userRolePropertyListFromRTK !== null &&
                    SplitByAT !== null &&
                    userRolePropertyListFromRTK.length > 0 &&
                    SplitByAT.length > 0
                ) {
                    SplitByAT.map((userdisplayname) => {
                        let FindData = userRolePropertyListFromRTK.filter(
                            (i) =>
                                "@" + i.displayname.replaceAll(" ", "").toLowerCase().trim() ===
                                userdisplayname.toLowerCase().trim()
                        );
                        if (FindData !== null && FindData.length > 0) {
                            selectedUserId.push(FindData[0].userid);
                        }
                    });
                }

                if (selectedUserId !== null && selectedUserId.length > 0) {
                    let WN = widgetDetails?.rowTitle
                        ? widgetDetails?.rowTitle
                        : widgetDetails?.widgetname;
                    commonService.SendPushNotification(
                        "New comment on " +
                        widgetDetails?.propertyDetail?.propertyname +
                        " property > " +
                        widgetDetails?.snapshottitle +
                        " snapshot > " +
                        WN +
                        " of " +
                        commonService.getDateInFormat(widgetDetails?.asOfDate),
                        saveResponse.widgetnotes,
                        saveResponse.widgetid,
                        saveResponse?.associationtype,
                        selectedUserId ? selectedUserId.join(",") : ""
                    );
                }
                setCommentText("");
                getCommentsList();
            }
            window.CKEDITOR.instances[editorId].setData('');
        } else {
            setBtnDisable(false);
        }
        // }
        setBtnDisable(false);
    };

    const selectUserNameFromList = (selectedUser) => {
        const commentParts = window.CKEDITOR.instances[editorId].getData()?.split("@");
        commentParts[
            commentParts.length - 1
        ] = `${selectedUser.displayname.replaceAll(" ", "")} `;
        window.CKEDITOR.instances[editorId].setData(commentParts.join("@"));
        setUserList(null);
        setFilteredApiList([]);
    };



    useEffect(() => {
        if (widgetDetails) {
            getCommentsList();
        }
    }, [widgetDetails]);

    useEffect(() => {
        create_ckeditor(`${editorId}`);

        let TableTime = setInterval(function () {
            clearInterval(TableTime);
            if (window.CKEDITOR.instances[editorId] !== null && window.CKEDITOR.instances[editorId] !== undefined) {
                window.CKEDITOR.instances[editorId].on('key', function (e) {
                    if (e.data.domEvent.$.key === "@") {

                    }
                    else if (e.data.domEvent.$.keyCode === 13 && filteredApiList !== undefined && filteredApiList !== null && filteredApiList.length > 0) {
                        //const commentParts = window.CKEDITOR.instances[editorId].getData()?.split("@");
                        //commentParts[
                        //    commentParts.length - 1
                        //] = `${filteredApiList[0].displayname.replaceAll(" ", "")} `;
                        //window.CKEDITOR.instances[editorId].setData(commentParts.join("@"));
                        //setUserList(null);
                        //setFilteredApiList([]);
                    }
                    else if (e.data.domEvent.$.keyCode === 13 && (filteredApiList === undefined || filteredApiList === null || filteredApiList.length === 0)) {

                    }
                    else {
                        let value = window.CKEDITOR.instances[editorId].getData().replace(/<[^>]*>|\s/g, '');
                        const array = value.split("@");
                        if (array.length > 1) {
                            const lastElement = array.pop();
                            const sanitizedValue = lastElement.replace(/<[^>]*>|\s/g, '');

                            const filteredList = userRolePropertyListFromRTK?.filter(
                                (api) =>
                                    ((api.displayname.replace(/<[^>]*>|\s/g, '').toLowerCase())).indexOf((sanitizedValue.toLowerCase())) === 0
                            );
                            setFilteredApiList(filteredList);
                        }
                        else {
                            setFilteredApiList([]);
                        }
                    }
                });
            }
        }, 400);
    }, []);

    return (
        <div className="make-comment-in-widget">
            <Accordion>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>
                        {commentsList?.length}{" "}
                        {commentsList?.length <= 1 ? "Comment" : "Comments"}
                    </Accordion.Header>
                    <Accordion.Body>
                        {commentsList?.map((d, i) => {
                            return (
                                <div key={i} className="all-comments-in-widget mb-2">
                                    <div className="single-comment-in-widget">
                                        <div className="single-comment-in-widget-inner">
                                            <img src={d?.profilepic} alt="profileImage" />
                                            <div>
                                                <span>
                                                    {d?.displayname}, {"  "}
                                                    {moment(d?.createdon).format("MM/DD")}
                                                </span>
                                                {/* <p>{htmlToText(d?.widgetnotes)}</p> */}
                                                <div
                                                    dangerouslySetInnerHTML={{ __html: d?.widgetnotes }}
                                                ></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                        <div className="enter-comment-here align-items-end position-relative">
                            {/* <input
                type="text"
                value={commentText}
                disabled={btnDisable}
                onKeyDown={(e) => handleKeyPress(e)}
                placeholder="Write a comment..."
                className="enter-comment-input w-100"
                onChange={(e) => handleInputChange(e.target.value)}
              /> */}
                            <textarea
                                id={`${editorId}`}
                                style={{ visibility: "hidden", display: "none" }}
                                placeholder="Message Body"
                                rows={3}
                                className="wysiwyg_ckeditor w-100"
                                defaultValue={commentText}
                            ></textarea>

                            <button disabled={btnDisable} className="send-comment-btn">
                                <img onClick={() => saveComment()} src={send} alt="send" />
                            </button>

                            {filteredApiList?.length > 0 && (
                                <div className="enter-comment-suggestions">
                                    <ul>
                                        {filteredApiList?.map((j, i) => (
                                            <li key={i} onClick={() => selectUserNameFromList(j)}>
                                                {j.displayname}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </div>
    );
};

export default Comments;
