import React, { useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { apiCall } from "../../utils/axiosService";
import { API_URL } from "../../utils/apiRoutes";
import { useSelector } from "react-redux";
import { apiResponse } from "../../utils/apiResponse";
import { commonService } from "../../utils/commonService";
import DeleteModal from "./DeleteModal";

const AddToCalendarModal = ({
  showModal,
  closeModal,
  noteDetail,
  showOnly,
  getCalendarEventList,
}) => {
  const propertyDetailFromRTK = useSelector(
    (store) => store.headerProperty.propertyDetail
  );
  let validationObj = {
    propertyid: propertyDetailFromRTK?.propertyid,
    propertycode: propertyDetailFromRTK?.propertycode,
    dates: noteDetail?.dates ? new Date(noteDetail?.dates) : "",
    details: noteDetail?.detail,
    title: noteDetail?.title,
    associationid: noteDetail?.associationid ? noteDetail?.associationid : 0,
    associationtype: noteDetail?.associationType,
    validationRule: [],
    errorObj: {},
    isValidate: true,
  };

  const [formData, setFormData] = useState(validationObj);
  const [loading, setLoading] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const closeDeleteModal = () => {
    setDeleteModal(false);
    closeModal()
  };

  const handleSubmit = async () => {
    setLoading(true);
    let saveResponse = await apiCall(
      {
        method: "POST",
        url: API_URL.BASE_API_URL.Configuration + API_URL.OTHER_NOTES.SAVE,
        body: {
          propertyid: formData?.propertyid,
          propertycode: formData?.propertycode,
          dates: formData?.dates
            ? commonService.getDateInDBFormat(formData?.dates)
            : "",
          details: formData?.details,
          title: formData?.title,
          associationid: formData?.associationid,
          associationtype: formData?.associationtype,
        },
      },
      true
    );

    let response = apiResponse(true, saveResponse, setLoading);

    if (response?.isValidate) {
      setLoading(false);
      closeModal();
    }

    if (!response?.isValidate) {
      setLoading(false);
    }
  };
  return (
    <>
      <Modal
        className="AddPropertySetupModalMain"
        size="lg"
        centered
        show={showModal}
        onHide={closeModal}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {showOnly !== true && `Add To Calendar`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xl={6}>
              <div className="from-groups no-border-break mb-3">
                <Form.Label>Title</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Title"
                  value={formData?.title}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      title: e.target.value,
                    });
                  }}
                />
              </div>
            </Col>
            <Col xl={6}>
              <div className="from-groups no-border-break mb-3">
                <Form.Label>Date</Form.Label>
                <DatePicker
                  selected={formData?.dates}
                  onChange={(date) => {
                    setFormData({
                      ...formData,
                      dates: date,
                    });
                  }}
                  placeholderText="Select Date"
                />
              </div>
            </Col>
            <Col xl={12}>
              <div className="from-groups no-border-break mb-2">
                <Form.Label>Detail</Form.Label>
                <Form.Control
                  as="textarea"
                  placeholder="Enter Detail"
                  maxLength="3271"
                  style={{ height: "200px" }}
                  value={formData?.details}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      details: e.target.value,
                    });
                  }}
                />
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          {loading ? (
            <button className="default-btn primary-btn" type="button" disabled>
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              Loading...
            </button>
          ) : showOnly !== true ? (
            <button
              className="default-btn primary-btn"
              onClick={() => {
                handleSubmit();
              }}
            >
              Submit
            </button>
          ) : (
            <button
              className="default-btn primary-btn red-btn"
              onClick={() => {
                setDeleteModal(true);
              }}
            >
              Delete
            </button>
          )}

          <Button className="default-btn secondary-btn" onClick={closeModal}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>

      {deleteModal && (
        <DeleteModal
          showDeleteItemModal={deleteModal}
          closeDeleteItemModal={closeDeleteModal}
          payload={{
            id: parseInt(formData?.associationid),
            date: commonService.getDateInDBFormat(new Date()),
            type: formData?.associationtype,
          }}
          getList={getCalendarEventList}
          url={
            API_URL.BASE_API_URL.RevenueManagement +
            API_URL.EVENTS.DELETE_REVENUE_DASHBOARD_EVENT
          }
        />
      )}
    </>
  );
};

export default AddToCalendarModal;
