import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Table } from "react-bootstrap";
import { colValue, compName, month } from "../../../../utils/constant";
import { apiCall } from "../../../../utils/axiosService";
import { API_URL } from "../../../../utils/apiRoutes";
import { apiResponse } from "../../../../utils/apiResponse";
import NoteIcon from "../../../../Assets/Images/CRM/notes.svg";
import downloadIcon from "../../../../Assets/Images/Operation/downloadIcon.svg";
import { commonService } from "../../../../utils/commonService";
import Loader from "../../../Loader/Loader";
import WidgetDetailModal from "../../WidgetDetailModal";
import infoIcon from "../../../../Assets/Images/Action Icons/infoIcon..svg";
import { useSelector } from "react-redux";
import WidgetNote from "../../WidgetNote";
import AddWidgetModal from "../../AddWidgetModal";
import { useParams } from "react-router-dom";
import emailIcon from "../../../../Assets/Images/Email.svg";
import Comments from "../Comments/Comment";
import moment from "moment";
const GroupLastYear = ({
  setCompDataFromChild,
  rowIndex,
  colIndex,
  api,
  para,
  setting,
}) => {
  const yearsArr = [
    ...commonService.getPastYearForDropDown(10),
    ...commonService.getFutureYearForDropDown(10),
    new Date().getFullYear(),
  ]?.sort(function (a, b) {
    return a - b;
  });
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [col, setCol] = useState();
  const tableData = [
    { month: "January", rms: 0, adr: 0, rev: 0, blk: 0 },
    { month: "February", rms: 0, adr: 0, rev: 0, blk: 0 },
    { month: "March", rms: 0, adr: 0, rev: 0, blk: 0 },
    { month: "April", rms: 0, adr: 0, rev: 0, blk: 0 },
    { month: "May", rms: 0, adr: 0, rev: 0, blk: 0 },
    { month: "June", rms: 0, adr: 0, rev: 0, blk: 0 },
    { month: "July", rms: 0, adr: 0, rev: 0, blk: 0 },
    { month: "August", rms: 0, adr: 0, rev: 0, blk: 0 },
    { month: "September", rms: 0, adr: 0, rev: 0, blk: 0 },
    { month: "October", rms: 0, adr: 0, rev: 0, blk: 0 },
    { month: "November", rms: 0, adr: 0, rev: 0, blk: 0 },
    { month: "December", rms: 0, adr: 0, rev: 0, blk: 0 },
  ];
  let rms = 0,
    blk = 0,
    adr = 0,
    rev = 0;

  const [groupLastYearData, setGroupLastYearData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [widgetModal, setWidgetModal] = useState(false);
  const widgetDetailRef = useRef();
  const [showNoteSidebar, setShowNoteSidebar] = useState(false);
  const propertyDetailFromRTK = useSelector(
    (store) => store.headerProperty.propertyDetail
  );
  const defaultAsOfDateFromRTK = useSelector(
    (store) => store.headerProperty.defaultAsOfDate
  );
  const { snapshottitle, propertyCode, snapshotId } = useParams();
  const closeNoteSideBar = () => {
    setShowNoteSidebar(false);
  };

  const closeWidgetModal = () => {
    setWidgetModal(false);
  };

  const getGroupLastYearDetail = async () => {
    setLoading(true);
    let groupLastYearDetailResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.SnapShot +
          API_URL.TRANSIENT_GROUP.GROUP_LAST_YEAR,
        body: {
          AsOfDate: para?.asOfDate,
          year: selectedYear,
          propertyCode: para?.propertyCode,
        },
      },
      false
    );

    let response = apiResponse(false, groupLastYearDetailResponse);

    if (response?.isValidate) {
      setGroupLastYearData(response?.data?.data);
      setLoading(false);
    }

    if (!response?.isValidate) {
      setGroupLastYearData(tableData);
      setLoading(false);
    }
  };

  const [tblids, settblids] = useState({
    tblGroupLastYear: commonService.GenerateGUID(),
  });

  useEffect(() => {
    if (api === 0) {
      setGroupLastYearData(tableData);
    } else {
      if (para?.asOfDate) {
        getGroupLastYearDetail();
      }
    }
  }, [selectedYear, para?.asOfDate]);
  commonService.setBackColorPerByTDValue(
    "Green",
    "Red",
    `#${tblids.tblGroupLastYear} .perbackground-rev`
  );

  return (
    <div className="widget-container">
      {api !== 0 && (
        <div className="gap-1 widget-container-menu">
          {/* <Button className="heading-menu-btns" type="button">
            <img
              src={NoteIcon}
              alt="menuIcon"
              onClick={() => {
                setShowNoteSidebar(true);
              }}
            />
          </Button> */}
          <Button className="heading-menu-btns" type="button" title="Send Mail">
            <img
              src={emailIcon}
              alt="emailIcon"
              onClick={() => {
                commonService.fnSendWidgetMail(
                  tblids.tblGroupLastYear,
                  "Group Last Year",
                  snapshotId,
                  defaultAsOfDateFromRTK,
                  "",
                  para?.widgetId
                );
              }}
            />
          </Button>
          <Button className="heading-menu-btns" type="button">
            <img
              src={infoIcon}
              alt="info"
              onClick={() => {
                widgetDetailRef.current = {
                  widgetdetails: "",
                  usernote: "",
                  compName: compName?.groupLastYear,
                  widgetId: para?.widgetId,
                };
                setWidgetModal(true);
              }}
            />
          </Button>
          <Button className="heading-menu-btns" type="button">
            <img
              src={downloadIcon}
              alt="menuIcon"
              import={tblids.tblGroupLastYear}
              onClick={() =>
                commonService.fnExportInCSV(
                  tblids.tblGroupLastYear,
                  // "Group Last Year"
                  `${para?.propertyCode}-Group Last Year-${moment(
                    new Date()
                  ).format("MMDDYYYY")}`
                )
              }
            />
          </Button>
        </div>
      )}
      <div className="ViewPropertySetupTable">
        <div className="stripped__table">
          <Table responsive id={tblids.tblGroupLastYear}>
            <thead>
              <tr>
                <th>
                  <div>
                    <Form.Select
                      value={selectedYear}
                      onChange={(e) => {
                        setSelectedYear(e.target.value);
                      }}
                    >
                      <option value="">Select</option>
                      {yearsArr?.map((item, index) => (
                        <option key={index} value={item}>
                          {item}
                        </option>
                      ))}
                    </Form.Select>
                    <span style={{ display: "none" }}>{selectedYear}</span>
                  </div>
                </th>
                <th colSpan={3} className="text-center">
                  Group Last Year
                </th>
              </tr>
              <tr>
                <th>Month</th>
                <th>OTB/BLK</th>
                <th className="text-end">ADR</th>
                <th className="text-end">REV</th>
              </tr>
            </thead>
            <tbody>
              {api === 0 ? (
                groupLastYearData?.map((item, index) => (
                  <React.Fragment key={index}>
                    <tr key={index}>
                      <td>{item?.month}</td>
                      <td>
                        {item?.rms}/{item?.blk}
                      </td>
                      <td className="text-end">${item?.adr}</td>
                      <td
                        className="text-end perbackground perbackground-rev"
                        value={item?.rev}
                      >
                        ${item?.rev}
                      </td>
                    </tr>
                    {index === groupLastYearData?.length - 1 && (
                      <tr>
                        <td className="boldTd">Total</td>
                        <td className="boldTd">0/0</td>
                        <td className="boldTd text-end">$0</td>
                        <td className="boldTd text-end">$0</td>
                      </tr>
                    )}
                  </React.Fragment>
                ))
              ) : loading ? (
                <tr style={{ height: "80px", position: "relative" }}>
                  <Loader />
                </tr>
              ) : (
                groupLastYearData &&
                month?.map((item, index) => {
                  let findMonthData = groupLastYearData?.filter(
                    (x) => x.month === item
                  );

                  if (findMonthData?.[0]?.rms) {
                    rms = rms + findMonthData?.[0]?.rms;
                  }
                  if (findMonthData?.[0]?.blk) {
                    blk = blk + findMonthData?.[0]?.blk;
                  }
                  if (findMonthData?.[0]?.adr) {
                    adr = adr + findMonthData?.[0]?.adr;
                  }
                  if (findMonthData?.[0]?.rev) {
                    rev = rev + findMonthData?.[0]?.rev;
                  }

                  return (
                    <React.Fragment key={index}>
                      <tr key={index} className="shadowOnHover">
                        <td>{item}</td>
                        <td>
                          {findMonthData?.[0]?.rms
                            ? commonService.formateRoom(findMonthData?.[0]?.rms)
                            : 0}
                          /
                          {findMonthData?.[0]?.blk
                            ? commonService.formateRoom(findMonthData?.[0]?.blk)
                            : 0}
                        </td>
                        <td className="text-end">
                          {findMonthData?.[0]?.adr
                            ? commonService.formateAmount(
                                findMonthData?.[0]?.adr
                              )
                            : commonService.formateAmount(0)}
                        </td>
                        <td
                          className="text-end perbackground perbackground-rev"
                          value={findMonthData?.[0]?.rev}
                        >
                          {findMonthData?.[0]?.rev
                            ? commonService.formateAmount(
                                findMonthData?.[0]?.rev
                              )
                            : commonService.formateAmount(0)}
                        </td>
                      </tr>
                      {index === 11 && (
                        <tr className="shadowOnHover">
                          <td className="boldTd">Total</td>
                          <td className="boldTd">
                            {commonService.formateRoom(rms)}/{commonService.formateRoom(blk)}
                          </td>
                          <td className="boldTd text-end">
                            {rev !== 0 && rms !== 0
                              ? commonService.formateAmount(parseInt(rev / rms))
                              : commonService.formateAmount(0)}
                          </td>
                          <td className="boldTd text-end">
                            {commonService.formateAmount(rev)}
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  );
                })
              )}
            </tbody>
          </Table>
        </div>
      </div>
      {api !== 0 && (
        <Comments widgetDetails={para} editorId={"GroupLastYear"} />
      )}

      {/* {showNoteSidebar && (
        <WidgetNote
          show={showNoteSidebar}
          handleClose={closeNoteSideBar}
          widgetId={para?.widgetId}
          propertyId={propertyDetailFromRTK?.propertyid}
          rowTitle={para?.rowTitle}
        />
      )} */}
      {showNoteSidebar && (
        <AddWidgetModal
          showModal={showNoteSidebar}
          closeModal={closeNoteSideBar}
          widgetId={para?.widgetId}
          propertyId={propertyDetailFromRTK?.propertyid}
          rowTitle={para?.rowTitle}
        />
      )}
      {widgetModal && (
        <WidgetDetailModal
          showModal={widgetModal}
          closeModal={closeWidgetModal}
          data={widgetDetailRef.current}
        />
      )}
    </div>
  );
};

export default GroupLastYear;
