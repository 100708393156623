import React, { useEffect, useRef, useState } from "react";
import { Row, Form, Col, Button, Container } from "react-bootstrap";
import pdfIcon from "../../../Assets/Images/CRM/pdf.svg";
import { commonService } from "../../../utils/commonService";
import { API_URL } from "../../../utils/apiRoutes";
import { validationRule } from "../../../utils/constant";
import { hotelListCommon } from "../Hotels/HotelListCommon";
import { AccountListCommon } from "../Contacts/AccountListCommon";
import { checkValidation } from "../../../utils/helper";
import { apiCall } from "../../../utils/axiosService";
import { apiResponse } from "../../../utils/apiResponse";
import {
  errorToastMessage,
  infoToastMessage,
  successToastMessage,
} from "../../../utils/toastMessage";
import Select from "react-select";
import filterIcon from "../../../Assets/Images/CRM/filter.png";
import { useSelector } from "react-redux";
import Loader from "../../Loader/Loader";

const DashboardWeeklyReport = ({ title }) => {
  const [moduleWisePropertyDropDown, setModuleWisePropertyDropDown] = useState(
    []
  );
  const [selectedModuleWiseProperty, setSelectedModuleWiseProperty] = useState(
    []
  );
  const propertyIdList = useRef("");
  const useridList = useRef("");
  const [loading, setLoading] = useState(false);
  const [hotelList, setHotelList] = useState(null);
  const userDetails = commonService.getLoginUserData();

  const crmPropertyDetailFromRTK = useSelector(
    (store) => store.headerProperty.crmPropertyDetail
  );

  let propertyIdPayload = "";
  if (crmPropertyDetailFromRTK) {
    if (crmPropertyDetailFromRTK?.propertyid === "") {
      let propertyPayload = crmPropertyDetailFromRTK?.allPropertyId;
      propertyIdPayload = propertyPayload.slice(0, -1);
    } else {
      propertyIdPayload = crmPropertyDetailFromRTK?.propertyid?.toString();
    }
  }
  let validationObj = {
    clientid: userDetails?.clientid ? userDetails?.clientid : 0,
    propertyid: "",
    userid: useridList.current,

    validationRule: [
      {
        fieldName: "clientid",
        rule: validationRule.required,
        message: "Client is required",
      },
      {
        fieldName: "propertyid",
        rule: validationRule.required,
        message: "Property Id required",
      },
      {
        fieldName: "userid",
        rule: validationRule.required,
        message: "User selection required",
      },
    ],
    errorObj: {},
    isValidate: true,
  };
  const [weeklyReportData, setWeeklyReportData] = useState(validationObj);
  const WeeklyDataRef = useRef(weeklyReportData);
  const setPdfRef = useRef("");
  const isDefaultApiCalledRef = useRef(false);
  const [showPdf, setShowPdf] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [showAllReport, setshowAllReport] = useState(false);

  const handleshowAllReport = () => {
    setshowAllReport(!showAllReport);
  };

  const getHotelList = async () => {
    let response = await hotelListCommon(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.CRM +
          API_URL.PROPERTY_TERM.GET_PROPERTY_LIST_BY_USER_ID,
        body: {
          id: userDetails?.userid,
        },
      },
      false
    );

    if (response?.isValidate) {
      if (crmPropertyDetailFromRTK?.propertyid !== "") {
        propertyIdList.current = `${crmPropertyDetailFromRTK?.propertyid}`;
        setWeeklyReportData(
          {
            ...weeklyReportData,
            propertyid: propertyIdList.current,
          }
          // getAccountRuleList(e.target.value)
        );
      }
      setHotelList(response?.data);
    }
  };

  const getAccountRuleList = async (propertyId) => {
    if (weeklyReportData.propertyid != "") {
      let accountResponse = await AccountListCommon(
        {
          method: "POST",
          url:
            API_URL.BASE_API_URL.UserManagement +
            API_URL.USER_ROLE_PROPERTY.GET_USER_LIST_BY_MODULE_MULTI_PROPERTY,
          body: {
            propertyid: weeklyReportData.propertyid,
            modulename: "crm",
          },
        },
        false
      );

      if (accountResponse?.isValidate) {
        let hotelDropDownList = [];
        let userId = [];
        accountResponse?.data?.map((item) => {
          userId = [...userId, item?.userid];
          hotelDropDownList = [
            ...hotelDropDownList,
            {
              label: item?.displayname,
              value: item?.userid,
            },
          ];
        });
        setModuleWisePropertyDropDown(hotelDropDownList);
        setSelectedModuleWiseProperty(hotelDropDownList);
        // propertyIdList.current = userId?.join(",");

        if (weeklyReportData.propertyid !== "") {
          useridList.current = userId?.join(",");
          setWeeklyReportData({
            ...weeklyReportData,
            userid: useridList.current,
          });
        }
      }
    }
  };

  const handlePropertySelection = (e) => {
    if (e?.length <= 0) {
      setSelectedModuleWiseProperty([]);
    }
    setSelectedModuleWiseProperty(e);
    let propertyList = [];
    e?.map((item) => {
      propertyList = [...propertyList, item?.value?.toString()];
      propertyIdList.current = propertyList?.join(",");
      setWeeklyReportData({
        ...weeklyReportData,
        userid: propertyIdList.current,
      });
    });
  };
  const iframeloader = () => {
    setLoading(false);
  };

  const GenerateReportbtn = async () => {
    setShowPdf(false);
    setLoading(true);
    let validation = checkValidation(weeklyReportData);

    if (!validation.isValidate) {
      setWeeklyReportData(validation);
      setLoading(false);
      return;
    }

    let weeklyReport = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.CRM + API_URL.CRM_REPORT.DASHBOARD_WEEKLY_REPORT,
        body: {
          propertyid: parseInt(weeklyReportData.propertyid),
          clientid: parseInt(weeklyReportData.clientid),
          userid: weeklyReportData.userid
            ? weeklyReportData.userid
            : useridList.current,
        },
      },
      false
    );

    let response = apiResponse(false, weeklyReport);

    if (response?.isValidate) {
      isDefaultApiCalledRef.current = true;
      // setLoading(false);
      setshowAllReport(false);
      // successToastMessage(response.data?.message);
      setErrorMessage(false);
      setPdfRef.current =
        process.env.REACT_APP_APIBASEPATH_CRM_REPORT_PDF + response.data.data;
      setShowPdf(true);
    }
    if (!response?.isValidate) {
      isDefaultApiCalledRef.current = true;
      setshowAllReport(true);
      setLoading(false);
      setErrorMessage(true);
    }
  };
  // !isDefaultApiCalledRef.current &&
  useEffect(() => {
    if (weeklyReportData.propertyid !== "") {
      if (weeklyReportData.userid !== "" && !isDefaultApiCalledRef.current) {
        GenerateReportbtn();
      }
    }
  }, [weeklyReportData.propertyid, weeklyReportData.userid]);

  useEffect(() => {
    if (crmPropertyDetailFromRTK?.propertyid === "") {
      // isDefaultApiCalledRef.current = true;
      setshowAllReport(true);
    }
    getHotelList();
  }, []);

  useEffect(() => {
    if (weeklyReportData.propertyid !== "") {
      getAccountRuleList();
    }
  }, [weeklyReportData.propertyid]);

  return (
    <div className="all-reports-main ToolsDisplacementCalcMain">
      <Container fluid>
        <Row>
          <Col xl={12}>
            <div className="ProspectDashboardTitleWraper  d-flex justify-content-between align-items-center marginBottom20">
              <h2>
                Dashboard- <span className="tab-title-main">{title}</span>
              </h2>
              <div className="heading-menu-btns">
                <img
                  src={filterIcon}
                  alt="filterIcon"
                  title="Filter Report"
                  className="cursorPointer report-filter"
                  onClick={handleshowAllReport}
                />
              </div>
            </div>
          </Col>
        </Row>
        {showAllReport && (
          <div className="displacementCalcWrapper  width400">
            <div className="from-groups no-border-break mb-2 small-height-select">
              <Form.Label>Select Property</Form.Label>
              <Form.Select
                aria-label="Default select example"
                value={weeklyReportData?.propertyid}
                onChange={(e) => {
                  setWeeklyReportData(
                    {
                      ...weeklyReportData,
                      propertyid: e.target.value,
                    }
                    // getAccountRuleList(e.target.value)
                  );
                }}
              >
                <option value="">Select Property</option>
                {hotelList?.map((item, index) => (
                  <option key={index} value={item?.propertyid}>
                    {item?.propertyname}
                  </option>
                ))}
              </Form.Select>

              {weeklyReportData?.errorObj?.propertyid &&
                weeklyReportData?.propertyid === "" && (
                  <small className="text-danger">
                    {weeklyReportData?.errorObj?.propertyid}
                  </small>
                )}
            </div>
            <div className="from-groups no-border-break mb-4 small-height-select">
              <Form.Label>Select User</Form.Label>

              {/* <Form.Select
          
              aria-label="Default select example"
              value={weeklyReportData?.userid}
              onChange={(e) => {
                setWeeklyReportData({
                  ...weeklyReportData,
                  userid: e.target.value,
                });
              }}
            >
              <option value="">Select Userid</option>
              {accountList?.map((item, index) => (
                <option key={index} value={item?.userid}>
                  {item?.displayname}
                </option>
              ))}
            </Form.Select> */}

              <Select
                isMulti
                value={selectedModuleWiseProperty}
                options={moduleWisePropertyDropDown}
                onChange={(e) => {
                  handlePropertySelection(e);
                }}
              />
              {weeklyReportData?.errorObj?.userid &&
                weeklyReportData?.userid === "" && (
                  <small className="text-danger">
                    {weeklyReportData?.errorObj?.userid}
                  </small>
                )}
            </div>
            <div className="d-flex align-items-center justify-content-end gap-3">
              {loading ? (
                <button
                  className="default-btn primary-btn"
                  type="button"
                  disabled
                >
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Loading...
                </button>
              ) : (
                <button
                  className="default-btn primary-btn d-flex gap-1 align-items-center"
                  onClick={() => {
                    GenerateReportbtn();
                  }}
                >
                  <span className="revpak-icon">
                    <img src={pdfIcon} alt="pdf" />
                  </span>
                  <span>Generate Report</span>
                </button>
              )}
            </div>
          </div>
        )}
        <p className="text-center">{errorMessage && " No Record Found"}</p>
        {loading && <Loader />}
        <div className="pdf-is-showing-here mt-3">
          {showPdf && setPdfRef.current && (
            <iframe
              onLoad={iframeloader}
              title="Report Doc"
              src={setPdfRef.current}
              width="100%"
              height="800px"
            />
          )}
        </div>
        {/* {loading ? (
          <Loader />
        ) : (
          <div className="pdf-is-showing-here mt-3">
            {showPdf && setPdfRef.current && (
              <iframe
                title="Report Doc"
                src={setPdfRef.current}
                width="100%"
                height="800px"
              />
            )}
          </div>
        )} */}
      </Container>
    </div>
  );
};

export default DashboardWeeklyReport;
