import React, { useEffect, useState } from "react";
import { apiCall } from "../../../../utils/axiosService";
import { API_URL } from "../../../../utils/apiRoutes";
import { apiResponse } from "../../../../utils/apiResponse";
import { Col, Row, Table } from "react-bootstrap";
import { commonService } from "../../../../utils/commonService";
import Loader from "../../../Loader/Loader";
import { Bar } from "react-chartjs-2";
import {
  Chart,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
} from "chart.js";

const RevenueByRoomType = ({
  setCompDataFromChild,
  rowIndex,
  colIndex,
  api,
  para,
  setting,
}) => {
  const [revData, setRevData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [chartData, setChartData] = useState(null);

  const getRevData = async () => {
    setLoading(true);
    let revDataResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.SnapShot +
          API_URL.CRM_SNAPSHOT.REVENUE_BY_ROOMTYPE,
        body: { propertyCode: para?.propertyCode, AsOfDate: para?.asOfDate },
      },
      false
    );

    let response = apiResponse(false, revDataResponse);

    if (response?.isValidate) {
      let totalRoomSold = {
        label: "Total Room Sold",
        data: [],
        backgroundColor: "#4F98CA",
        stack: "Stack 2",
        type: "bar",
        order: 1,
      };
      let totalRevenue = {
        label: "Total Revenue",
        data: [],
        backgroundColor: "#3EB978",
        stack: "Stack 1",
        type: "bar",
        order: 1,
      };
      let labels = [];
      response?.data?.data?.forEach((item) => {
        labels = [...labels, item?.RoomType];
        totalRoomSold.data = [...totalRoomSold.data, item?.TotalRoom];
        totalRevenue.data = [...totalRevenue.data, item?.TotalRev];
      });
      let chartObj = {
        labels: labels,
        datasets: [totalRoomSold, totalRevenue],
      };
      setChartData(chartObj);
      setRevData(response?.data?.data);
      setLoading(false);
    }

    if (!response?.isValidate) {
      setChartData(null);
      setRevData(null);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (api === 0) {
    } else {
      if (para?.asOfDate) {
        getRevData();
      }
    }
  }, [para?.asOfDate]);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      tooltip: {
        mode: "index",
        intersect: false,
        callbacks: {
          label: function (context) {
            let label = context.dataset.label || "";
            let value = context.formattedValue;

            if (label === "Total Room Sold") {
              label += ": " + value;
            } else {
              label += ": $" + value;
            }

            return label;
          },
        },
      },
      title: {
        display: true,
        text: "Revenue By RoomType",
      },
    },
  };

  Chart.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Tooltip,
    Legend,
    PointElement,
    LineElement
  );

  const [tblids, settblids] = useState({
    tblRevByCompany: commonService.GenerateGUID(),
  });

  return (
    <div className="widget-container">
      <Row>
        <Col xl={4}>
          <div className="ViewPropertySetupTable">
            <div className="stripped__table">
              <Table id={tblids.tblRevByCompany} responsive>
                <thead>
                  <tr>
                    <th className="text-center" colSpan={3}>
                      Revenue By RoomType
                    </th>
                  </tr>
                  <tr>
                    <th className="border_right text-center">RoomType</th>
                    <th className="border_right text-center">
                      Total Room Sold
                    </th>
                    <th className="text-end">Total Revenue</th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr style={{ height: "80px", position: "relative" }}>
                      <Loader />
                    </tr>
                  ) : revData ? (
                    revData?.map((item) => {
                      return (
                        <tr className="shadowOnHover">
                          <td className="border_right text-center">
                            {item?.RoomType}
                          </td>
                          <td className="border_right text-center">
                            {commonService.formateRoom(item?.TotalRoom)}
                          </td>
                          <td className="text-end">
                            {commonService.formateAmount(item?.TotalRev)}
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={3}>No Data Found</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          </div>
        </Col>
        <Col xl={8}>
          {loading ? (
            <Loader />
          ) : chartData ? (
            <Bar data={chartData} options={options} />
          ) : (
            <div>No Data Found</div>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default RevenueByRoomType;
