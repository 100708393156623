import React from "react";
import { Bar, Line } from "react-chartjs-2";
import {
  Chart,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  Filler,
} from "chart.js";
import { commonService } from "../../utils/commonService";

function RateShopMultiAxisChart() {
  let datesArray = commonService.getAllDatesInMonth(
    new Date().getFullYear(),
    new Date().getMonth()
  );
  const options = {
    elements: {
      point: {
        radius: 0,
      },
    },
    scales: {
      x: {
        title: {
          display: false,
          text: "Months",
        },
        grid: {
          display: false,
        },
      },
      y: {
        stacked: false,
        beginAtZero: true,
        type: "linear",
        position: "left",
      },
      y2: {
        stacked: false,
        beginAtZero: true,
        type: "linear",
        position: "right",
        grid: {
          drawOnChartArea: false,
        },
        ticks: {
          callback: function (value, index, values) {
            return `${value * 100} %`;
          },
        },
      },
    },
    plugins: {
      tooltip: {
        mode: "index",
        intersect: false,
      },
      legend: {
        display: true,
        align: "center",
        position: "bottom",
        paddingTop: 10,
        maxWidth: 20,
        maxHeight: 100,
        labels: {
          boxWidth: 10,
          boxHeight: 10,
        },
      },
    },
    responsive: true,
    maintainAspectRatio: true,
    aspectRatio: 4 / 1,
  };

  const mergedData = {
    // labels: ["07/01", "07/02", "07/03", "07/04", "07/05", "07/06", "07/07", "07/08", "07/09", "07/10", "07/11", "07/12", "07/13", "07/14", "07/15", "07/16", "07/17", "07/18", "07/19", "07/20", "07/21", "07/22", "07/23", "07/24", "07/25", "07/26", "07/27", "07/28", "07/29", "07/30", "07/31"],
    labels: datesArray,
    datasets: [
      {
        type: "bar",
        label: "Hyatt Place Chicago/Itasca",
        data: [
          140, 160, 145, 120, 48, 225, 47, 52, 99, 140, 160, 180, 180, 275, 225,
          145, 145, 120, 48, 225, 47, 52, 99, 140, 160, 180, 180, 160, 120, 190,
          120,
        ],
        fill: false,
        backgroundColor: "#cfdafe",
        borderColor: "#cfdafe",
        barThickness: 10,
        borderWidth: 0,
        // pointRadius: 4,
        // pointBorderWidth: 0,
        // pointBorderColor: '#ffffff',
        tension: 0.4,
        yAxisID: "y",
        order: 1,
      },
      {
        type: "line",
        label: "DoubleTree Chicago Wood Dale - Elk Grove",
        data: [
          140, 160, 145, 120, 48, 225, 47, 52, 99, 140, 160, 180, 180, 275, 225,
          145, 145, 120, 48, 225, 47, 52, 99, 140, 160, 180, 180, 160, 120, 190,
          120,
        ],
        fill: false,
        backgroundColor: "#4a84c2",
        borderColor: "#4a84c2",
        borderWidth: 3,
        order: 0,
        pointRadius: 4,
        pointBorderWidth: 2,
        pointBorderColor: "#ffffff",
      },
      {
        type: "line",
        label: "Courtyard Chicago Wood Dale/Itasca",
        data: [
          160, 180, 180, 275, 225, 180, 180, 160, 120, 190, 120, 145, 145, 120,
          48, 225, 47, 140, 160, 145, 120, 48, 225, 47, 52, 99, 140, 52, 99,
          140, 160,
        ],
        fill: false,
        backgroundColor: "#de9234",
        borderColor: "#000",
        borderWidth: 2,
        order: 0,
        pointRadius: 4,
        pointBorderWidth: 2,
        pointBorderColor: "#ffffff",
      },
      {
        type: "line",
        stacked: true,
        label: "min",
        data: [
          170, 190, 185, 295, 245, 190, 190, 170, 140, 210, 125, 165, 175, 140,
          78, 255, 77, 150, 170, 155, 120, 68, 245, 87, 82, 109, 150, 72, 109,
          150, 170,
        ],
        fill: 1,
        backgroundColor: "#FDDFBC",
        borderColor: "#FDDFBC",
        borderWidth: 0,
        tension: 0.2,
        order: 2,
        // pointRadius: 4,
        // pointBorderWidth: 2,
        // pointBorderColor: '#ffffff',
      },
      {
        type: "line",
        stacked: true,
        label: "max",
        data: [
          100, 120, 135, 90, 28, 165, 37, 42, 78, 120, 100, 110, 120, 100, 40,
          125, 35, 110, 38, 125, 27, 32, 69, 30, 35, 80, 30, 20, 25, 100, 80,
        ],
        fill: 1,
        backgroundColor: "#FDDFBC",
        borderColor: "#FDDFBC",
        borderWidth: 0,
        tension: 0.2,
        order: 2,
        // pointRadius: 4,
        // pointBorderWidth: 2,
        // pointBorderColor: '#ffffff',
      },
    ],
  };
  Chart.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Tooltip,
    Legend,
    PointElement,
    LineElement,
    Filler
  );
  return (
    <div>
      <Line data={mergedData} options={options} />
    </div>
  );
}

export default RateShopMultiAxisChart;
