import React, { useState } from "react";
import { Col, Container, Row, Form, Button, InputGroup } from "react-bootstrap";
import loginLeft from "../../Assets/Images/LogIn/login-left.png";
import loginLogo from "../../Assets/Images/LogIn/loginLogo.png";
import { Link, Navigate, useNavigate, useSearchParams } from "react-router-dom";
import gplusIcon from "../../Assets/Images/LogIn/Gplus.png";
import OpenPass from "../../Assets/Images/FormCheck/openeye.svg";
import ClosePass from "../../Assets/Images/FormCheck/closeeye.svg";
import { checkValidation } from "../../utils/helper";
import { validationRule } from "../../utils/constant";
import { apiCall } from "../../utils/axiosService";
import { API_URL } from "../../utils/apiRoutes";
import {
    errorToastMessage,
    successToastMessage,
} from "../../utils/toastMessage";
import { commonService } from "../../utils/commonService";
import ForgotPasswordModal from "./ForgotPasswordModal";

const UpdatePassword = () => {
    let validationObj = {
        password: "",
        verifypassword: "",
        validationRule: [
            {
                fieldName: "password",
                rule: validationRule.required,
                message: "Password is required",
            },
            {
                fieldName: "verifypassword",
                rule: validationRule.required,
                message: "Confirm password is required",
            },
        ],
        errorObj: {},
        isValidate: true,
    };

    const [passwordForm, setPasswordForm] = useState(validationObj);
    const [loading, setLoading] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();

    const showHidePass = (e) => {
        let button = document.getElementById("showHidePassBtn");
        let PassInput = document.querySelector(".password-show-hide");
        const open = button.querySelector(".open-icon");
        const close = button.querySelector(".close-icon");
        const currentValue = PassInput.getAttribute("type");
        const newValue = currentValue === "password" ? "text" : "password";
        PassInput.setAttribute("type", newValue);
        if (newValue == "password") {
            open.style.display = "inline-flex";
            close.style.display = "none";
        } else if (newValue == "text") {
            close.style.display = "inline-flex";
            open.style.display = "none";
        } else {
            open.style.display = "inline-flex";
            close.style.display = "none";
        }
    };

    const confirmShowHidePass = (e) => {
        let button = document.getElementById("confirmShowHidePassBtn");
        let PassInput = document.querySelector(".confirm-password-show-hide");
        const open = button.querySelector(".open-icon");
        const close = button.querySelector(".close-icon");
        const currentValue = PassInput.getAttribute("type");
        const newValue = currentValue === "password" ? "text" : "password";
        PassInput.setAttribute("type", newValue);
        if (newValue == "password") {
            open.style.display = "inline-flex";
            close.style.display = "none";
        } else if (newValue == "text") {
            close.style.display = "inline-flex";
            open.style.display = "none";
        } else {
            open.style.display = "inline-flex";
            close.style.display = "none";
        }
    };

    const handleSubmit = async () => {
        setLoading(true);

        let validation = checkValidation(passwordForm);
        if (!validation?.isValidate) {
            setLoading(false);
            setPasswordForm(validation);
            return;
        }
        if (passwordForm?.password !== passwordForm?.verifypassword) {
            setLoading(false);
            setPasswordForm({
                ...passwordForm,
                errorObj: {
                    verifypassword: "Password and confirm password is not match",
                },
            });
        }

        let updatePasswordResponse = await apiCall(
            {
                method: "POST",
                url: API_URL.BASE_API_URL.Configuration + API_URL.UPDATE_PASSWORD,
                body: {
                    t: searchParams.get("t") ? searchParams.get("t") : "",
                    o: searchParams.get("o") ? searchParams.get("o") : "",
                    password: passwordForm.password,
                    verifypassword: passwordForm.verifypassword,
                    requestfrom: "web",
                },
            },
            true
        );

        if (updatePasswordResponse?.status_code === 0) {
            setLoading(false);
            return errorToastMessage(updatePasswordResponse?.message);
        } else if (updatePasswordResponse?.status_code === 1) {
            localStorage.clear()
            successToastMessage(updatePasswordResponse?.message);
            window.location.href = API_URL.BASE_API_URL.EMA_MAIN_DOMAIN + "?logout=true";
        } else if (updatePasswordResponse?.status_code === 2) {
            localStorage.clear();
            window.location.href = API_URL.BASE_API_URL.EMA_MAIN_DOMAIN + "?logout=true";
        }
    };
    return (
        <div className="new-sign-in-page-main">
            <Container>
                <Row className="login-main-inner">
                    <Col xl={12}>
                        <div className="login-right">
                            <div className="login-right-main">
                                <div className="login-form-title">
                                    <img src={loginLogo} alt="logo" />
                                    <h2>Update your account password</h2>
                                    {/*<p>*/}
                                    {/*    Login to your account so you can continue building and editing*/}
                                    {/*    your onboarding flows.*/}
                                    {/*</p>*/}
                                </div>
                                <div className="login-form">
                                    <div className="show-password from-groups no-border-break">
                                        <Form.Label>Password</Form.Label>
                                        <InputGroup className="password-group">
                                            <Form.Control
                                                type="password"
                                                className="password-show-hide"
                                                placeholder="Enter Password"
                                                value={passwordForm?.password}
                                                onChange={(e) =>
                                                    setPasswordForm({
                                                        ...passwordForm,
                                                        password: e.target.value,
                                                    })
                                                }
                                            />
                                            <Button
                                                className="border-0"
                                                id="showHidePassBtn"
                                                onClick={showHidePass}
                                            >
                                                <img src={OpenPass} className="open-icon" alt="OpenPass" />
                                                <img src={ClosePass} className="close-icon" alt="ClosePass" />
                                            </Button>
                                        </InputGroup>
                                        {passwordForm?.errorObj?.password && (
                                            <small className="text-danger">
                                                {passwordForm?.errorObj?.password}
                                            </small>
                                        )}
                                    </div>
                                    <div className="show-password from-groups no-border-break">
                                        <Form.Label>Confirm Password</Form.Label>
                                        <InputGroup className="password-group">
                                            <Form.Control
                                                type="password"
                                                className="confirm-password-show-hide"
                                                placeholder="Enter Confirm Password"
                                                value={passwordForm?.verifypassword}
                                                onChange={(e) =>
                                                    setPasswordForm({
                                                        ...passwordForm,
                                                        verifypassword: e.target.value,
                                                    })
                                                }
                                            />
                                            <Button
                                                className="border-0"
                                                id="confirmShowHidePassBtn"
                                                onClick={confirmShowHidePass}
                                            >
                                                <img src={OpenPass} className="open-icon" alt="OpenPass" />
                                                <img src={ClosePass} className="close-icon" alt="ClosePass" />
                                            </Button>
                                        </InputGroup>
                                        {passwordForm?.errorObj?.verifypassword && (
                                            <small className="text-danger">
                                                {passwordForm?.errorObj?.verifypassword}
                                            </small>
                                        )}
                                    </div>
                                </div>
                                <div className="forgot-password">
                                    <p
                                        onClick={() => {
                                            localStorage.clear();
                                            window.location.href = API_URL.BASE_API_URL.EMA_MAIN_DOMAIN + "?logout=true";
                                        }}
                                    >
                                        Back To Login
                                    </p>
                                </div>
                                <div className="login-buttons">
                                    <button
                                        className="default-btn primary-btn"
                                        onClick={() => {
                                            handleSubmit();
                                        }}
                                    >
                                        Update Password
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>

        </div>
    );
};

export default UpdatePassword;
