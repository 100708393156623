import React, { useEffect, useRef, useState } from "react";
import { apiCall } from "../../../../utils/axiosService";
import { API_URL } from "../../../../utils/apiRoutes";
import { apiResponse } from "../../../../utils/apiResponse";
import { Button, Col, Table } from "react-bootstrap";
import NoteIcon from "../../../../Assets/Images/CRM/notes.svg";
import downloadIcon from "../../../../Assets/Images/Operation/downloadIcon.svg";
import { commonService } from "../../../../utils/commonService";
import Loader from "../../../Loader/Loader";
import DatePicker from "react-datepicker";
import $ from "jquery";
import WidgetDetailModal from "../../WidgetDetailModal";
import { compName } from "../../../../utils/constant";
import infoIcon from "../../../../Assets/Images/Action Icons/infoIcon..svg";
import WidgetNote from "../../WidgetNote";
import { useSelector } from "react-redux";
import AddWidgetModal from "../../AddWidgetModal";
import { useParams } from "react-router-dom";
import emailIcon from "../../../../Assets/Images/Email.svg";
import moment from "moment";
const MstReservation = ({
  setCompDataFromChild,
  rowIndex,
  colIndex,
  api,
  para,
  setting,
}) => {
  const tableData = [
    {
      id: 277143,
      propertyCode: "AC32AW",
      propertyId: "1",
      pullDateId: "495",
      AsOfDate: "2023-08-16",
      RoomType: "NDD",
      RoomNo: 334,
      StayDate: "2014-01-03",
      RateCode: "SRD Rate",
      RateCodeDescript: null,
      MarketSegment: "Retail",
      Source: null,
      BookingDate: "2014-01-02",
      BookingTime: null,
      Company: "Leisure",
      Pace: "PACE",
      RoomNight: 1,
      Revenue: "0.00",
      Rate: "0.00",
      ADR: "0.0",
      PayType: null,
      LeadTime: 1,
      ForecastType: null,
      LOS: 1,
      ExtendedStay1: 1,
      ExtendedStay2: 0,
      ExtendedStay3: 0,
      ExtendedStay4: 0,
      Account: "319260703",
      Status: "O",
      ForecastTypeRevenue: null,
      GuestName: "MAUPIN, KATHY",
      GuestType: null,
      ArrivalDate: "2014-01-03",
      DepartureDate: "2014-01-04",
      LOS1: 1,
      LOS2: null,
      LOS3: null,
      LOS4: null,
      LOS5: null,
      LOS6: null,
      User: "CRS",
      BarBased: null,
      TravelAgent: null,
      ModifiedUser: null,
      CombinedChannel: null,
      CTSName: null,
      Intermediaries: null,
      GroupName: null,
      LOSDetails: 1,
      ArrivalLOS1: 1,
      ArrivalLOS2: null,
      ArrivalLOS3Plus: null,
      MarketSegmentCategory: null,
      MarketSegmentPrefix: null,
      ResvNameID: "319260703",
      CancellationDate: null,
      CancellationTime: null,
      CancellationBy: "",
      RefResNo: "58422235",
      EventID: null,
    },
    {
      id: 277143,
      propertyCode: "AC32AW",
      propertyId: "1",
      pullDateId: "495",
      AsOfDate: "2023-08-16",
      RoomType: "NDD",
      RoomNo: 334,
      StayDate: "2014-01-03",
      RateCode: "SRD Rate",
      RateCodeDescript: null,
      MarketSegment: "Retail",
      Source: null,
      BookingDate: "2014-01-02",
      BookingTime: null,
      Company: "Leisure",
      Pace: "PACE",
      RoomNight: 1,
      Revenue: "0.00",
      Rate: "0.00",
      ADR: "0.0",
      PayType: null,
      LeadTime: 1,
      ForecastType: null,
      LOS: 1,
      ExtendedStay1: 1,
      ExtendedStay2: 0,
      ExtendedStay3: 0,
      ExtendedStay4: 0,
      Account: "319260703",
      Status: "O",
      ForecastTypeRevenue: null,
      GuestName: "MAUPIN, KATHY",
      GuestType: null,
      ArrivalDate: "2014-01-03",
      DepartureDate: "2014-01-04",
      LOS1: 1,
      LOS2: null,
      LOS3: null,
      LOS4: null,
      LOS5: null,
      LOS6: null,
      User: "CRS",
      BarBased: null,
      TravelAgent: null,
      ModifiedUser: null,
      CombinedChannel: null,
      CTSName: null,
      Intermediaries: null,
      GroupName: null,
      LOSDetails: 1,
      ArrivalLOS1: 1,
      ArrivalLOS2: null,
      ArrivalLOS3Plus: null,
      MarketSegmentCategory: null,
      MarketSegmentPrefix: null,
      ResvNameID: "319260703",
      CancellationDate: null,
      CancellationTime: null,
      CancellationBy: "",
      RefResNo: "58422235",
      EventID: null,
    },
    {
      id: 277143,
      propertyCode: "AC32AW",
      propertyId: "1",
      pullDateId: "495",
      AsOfDate: "2023-08-16",
      RoomType: "NDD",
      RoomNo: 334,
      StayDate: "2014-01-03",
      RateCode: "SRD Rate",
      RateCodeDescript: null,
      MarketSegment: "Retail",
      Source: null,
      BookingDate: "2014-01-02",
      BookingTime: null,
      Company: "Leisure",
      Pace: "PACE",
      RoomNight: 1,
      Revenue: "0.00",
      Rate: "0.00",
      ADR: "0.0",
      PayType: null,
      LeadTime: 1,
      ForecastType: null,
      LOS: 1,
      ExtendedStay1: 1,
      ExtendedStay2: 0,
      ExtendedStay3: 0,
      ExtendedStay4: 0,
      Account: "319260703",
      Status: "O",
      ForecastTypeRevenue: null,
      GuestName: "MAUPIN, KATHY",
      GuestType: null,
      ArrivalDate: "2014-01-03",
      DepartureDate: "2014-01-04",
      LOS1: 1,
      LOS2: null,
      LOS3: null,
      LOS4: null,
      LOS5: null,
      LOS6: null,
      User: "CRS",
      BarBased: null,
      TravelAgent: null,
      ModifiedUser: null,
      CombinedChannel: null,
      CTSName: null,
      Intermediaries: null,
      GroupName: null,
      LOSDetails: 1,
      ArrivalLOS1: 1,
      ArrivalLOS2: null,
      ArrivalLOS3Plus: null,
      MarketSegmentCategory: null,
      MarketSegmentPrefix: null,
      ResvNameID: "319260703",
      CancellationDate: null,
      CancellationTime: null,
      CancellationBy: "",
      RefResNo: "58422235",
      EventID: null,
    },
    {
      id: 277143,
      propertyCode: "AC32AW",
      propertyId: "1",
      pullDateId: "495",
      AsOfDate: "2023-08-16",
      RoomType: "NDD",
      RoomNo: 334,
      StayDate: "2014-01-03",
      RateCode: "SRD Rate",
      RateCodeDescript: null,
      MarketSegment: "Retail",
      Source: null,
      BookingDate: "2014-01-02",
      BookingTime: null,
      Company: "Leisure",
      Pace: "PACE",
      RoomNight: 1,
      Revenue: "0.00",
      Rate: "0.00",
      ADR: "0.0",
      PayType: null,
      LeadTime: 1,
      ForecastType: null,
      LOS: 1,
      ExtendedStay1: 1,
      ExtendedStay2: 0,
      ExtendedStay3: 0,
      ExtendedStay4: 0,
      Account: "319260703",
      Status: "O",
      ForecastTypeRevenue: null,
      GuestName: "MAUPIN, KATHY",
      GuestType: null,
      ArrivalDate: "2014-01-03",
      DepartureDate: "2014-01-04",
      LOS1: 1,
      LOS2: null,
      LOS3: null,
      LOS4: null,
      LOS5: null,
      LOS6: null,
      User: "CRS",
      BarBased: null,
      TravelAgent: null,
      ModifiedUser: null,
      CombinedChannel: null,
      CTSName: null,
      Intermediaries: null,
      GroupName: null,
      LOSDetails: 1,
      ArrivalLOS1: 1,
      ArrivalLOS2: null,
      ArrivalLOS3Plus: null,
      MarketSegmentCategory: null,
      MarketSegmentPrefix: null,
      ResvNameID: "319260703",
      CancellationDate: null,
      CancellationTime: null,
      CancellationBy: "",
      RefResNo: "58422235",
      EventID: null,
    },
    {
      id: 277143,
      propertyCode: "AC32AW",
      propertyId: "1",
      pullDateId: "495",
      AsOfDate: "2023-08-16",
      RoomType: "NDD",
      RoomNo: 334,
      StayDate: "2014-01-03",
      RateCode: "SRD Rate",
      RateCodeDescript: null,
      MarketSegment: "Retail",
      Source: null,
      BookingDate: "2014-01-02",
      BookingTime: null,
      Company: "Leisure",
      Pace: "PACE",
      RoomNight: 1,
      Revenue: "0.00",
      Rate: "0.00",
      ADR: "0.0",
      PayType: null,
      LeadTime: 1,
      ForecastType: null,
      LOS: 1,
      ExtendedStay1: 1,
      ExtendedStay2: 0,
      ExtendedStay3: 0,
      ExtendedStay4: 0,
      Account: "319260703",
      Status: "O",
      ForecastTypeRevenue: null,
      GuestName: "MAUPIN, KATHY",
      GuestType: null,
      ArrivalDate: "2014-01-03",
      DepartureDate: "2014-01-04",
      LOS1: 1,
      LOS2: null,
      LOS3: null,
      LOS4: null,
      LOS5: null,
      LOS6: null,
      User: "CRS",
      BarBased: null,
      TravelAgent: null,
      ModifiedUser: null,
      CombinedChannel: null,
      CTSName: null,
      Intermediaries: null,
      GroupName: null,
      LOSDetails: 1,
      ArrivalLOS1: 1,
      ArrivalLOS2: null,
      ArrivalLOS3Plus: null,
      MarketSegmentCategory: null,
      MarketSegmentPrefix: null,
      ResvNameID: "319260703",
      CancellationDate: null,
      CancellationTime: null,
      CancellationBy: "",
      RefResNo: "58422235",
      EventID: null,
    },
    {
      id: 277143,
      propertyCode: "AC32AW",
      propertyId: "1",
      pullDateId: "495",
      AsOfDate: "2023-08-16",
      RoomType: "NDD",
      RoomNo: 334,
      StayDate: "2014-01-03",
      RateCode: "SRD Rate",
      RateCodeDescript: null,
      MarketSegment: "Retail",
      Source: null,
      BookingDate: "2014-01-02",
      BookingTime: null,
      Company: "Leisure",
      Pace: "PACE",
      RoomNight: 1,
      Revenue: "0.00",
      Rate: "0.00",
      ADR: "0.0",
      PayType: null,
      LeadTime: 1,
      ForecastType: null,
      LOS: 1,
      ExtendedStay1: 1,
      ExtendedStay2: 0,
      ExtendedStay3: 0,
      ExtendedStay4: 0,
      Account: "319260703",
      Status: "O",
      ForecastTypeRevenue: null,
      GuestName: "MAUPIN, KATHY",
      GuestType: null,
      ArrivalDate: "2014-01-03",
      DepartureDate: "2014-01-04",
      LOS1: 1,
      LOS2: null,
      LOS3: null,
      LOS4: null,
      LOS5: null,
      LOS6: null,
      User: "CRS",
      BarBased: null,
      TravelAgent: null,
      ModifiedUser: null,
      CombinedChannel: null,
      CTSName: null,
      Intermediaries: null,
      GroupName: null,
      LOSDetails: 1,
      ArrivalLOS1: 1,
      ArrivalLOS2: null,
      ArrivalLOS3Plus: null,
      MarketSegmentCategory: null,
      MarketSegmentPrefix: null,
      ResvNameID: "319260703",
      CancellationDate: null,
      CancellationTime: null,
      CancellationBy: "",
      RefResNo: "58422235",
      EventID: null,
    },
    {
      id: 277143,
      propertyCode: "AC32AW",
      propertyId: "1",
      pullDateId: "495",
      AsOfDate: "2023-08-16",
      RoomType: "NDD",
      RoomNo: 334,
      StayDate: "2014-01-03",
      RateCode: "SRD Rate",
      RateCodeDescript: null,
      MarketSegment: "Retail",
      Source: null,
      BookingDate: "2014-01-02",
      BookingTime: null,
      Company: "Leisure",
      Pace: "PACE",
      RoomNight: 1,
      Revenue: "0.00",
      Rate: "0.00",
      ADR: "0.0",
      PayType: null,
      LeadTime: 1,
      ForecastType: null,
      LOS: 1,
      ExtendedStay1: 1,
      ExtendedStay2: 0,
      ExtendedStay3: 0,
      ExtendedStay4: 0,
      Account: "319260703",
      Status: "O",
      ForecastTypeRevenue: null,
      GuestName: "MAUPIN, KATHY",
      GuestType: null,
      ArrivalDate: "2014-01-03",
      DepartureDate: "2014-01-04",
      LOS1: 1,
      LOS2: null,
      LOS3: null,
      LOS4: null,
      LOS5: null,
      LOS6: null,
      User: "CRS",
      BarBased: null,
      TravelAgent: null,
      ModifiedUser: null,
      CombinedChannel: null,
      CTSName: null,
      Intermediaries: null,
      GroupName: null,
      LOSDetails: 1,
      ArrivalLOS1: 1,
      ArrivalLOS2: null,
      ArrivalLOS3Plus: null,
      MarketSegmentCategory: null,
      MarketSegmentPrefix: null,
      ResvNameID: "319260703",
      CancellationDate: null,
      CancellationTime: null,
      CancellationBy: "",
      RefResNo: "58422235",
      EventID: null,
    },
    {
      id: 277143,
      propertyCode: "AC32AW",
      propertyId: "1",
      pullDateId: "495",
      AsOfDate: "2023-08-16",
      RoomType: "NDD",
      RoomNo: 334,
      StayDate: "2014-01-03",
      RateCode: "SRD Rate",
      RateCodeDescript: null,
      MarketSegment: "Retail",
      Source: null,
      BookingDate: "2014-01-02",
      BookingTime: null,
      Company: "Leisure",
      Pace: "PACE",
      RoomNight: 1,
      Revenue: "0.00",
      Rate: "0.00",
      ADR: "0.0",
      PayType: null,
      LeadTime: 1,
      ForecastType: null,
      LOS: 1,
      ExtendedStay1: 1,
      ExtendedStay2: 0,
      ExtendedStay3: 0,
      ExtendedStay4: 0,
      Account: "319260703",
      Status: "O",
      ForecastTypeRevenue: null,
      GuestName: "MAUPIN, KATHY",
      GuestType: null,
      ArrivalDate: "2014-01-03",
      DepartureDate: "2014-01-04",
      LOS1: 1,
      LOS2: null,
      LOS3: null,
      LOS4: null,
      LOS5: null,
      LOS6: null,
      User: "CRS",
      BarBased: null,
      TravelAgent: null,
      ModifiedUser: null,
      CombinedChannel: null,
      CTSName: null,
      Intermediaries: null,
      GroupName: null,
      LOSDetails: 1,
      ArrivalLOS1: 1,
      ArrivalLOS2: null,
      ArrivalLOS3Plus: null,
      MarketSegmentCategory: null,
      MarketSegmentPrefix: null,
      ResvNameID: "319260703",
      CancellationDate: null,
      CancellationTime: null,
      CancellationBy: "",
      RefResNo: "58422235",
      EventID: null,
    },
    {
      id: 277143,
      propertyCode: "AC32AW",
      propertyId: "1",
      pullDateId: "495",
      AsOfDate: "2023-08-16",
      RoomType: "NDD",
      RoomNo: 334,
      StayDate: "2014-01-03",
      RateCode: "SRD Rate",
      RateCodeDescript: null,
      MarketSegment: "Retail",
      Source: null,
      BookingDate: "2014-01-02",
      BookingTime: null,
      Company: "Leisure",
      Pace: "PACE",
      RoomNight: 1,
      Revenue: "0.00",
      Rate: "0.00",
      ADR: "0.0",
      PayType: null,
      LeadTime: 1,
      ForecastType: null,
      LOS: 1,
      ExtendedStay1: 1,
      ExtendedStay2: 0,
      ExtendedStay3: 0,
      ExtendedStay4: 0,
      Account: "319260703",
      Status: "O",
      ForecastTypeRevenue: null,
      GuestName: "MAUPIN, KATHY",
      GuestType: null,
      ArrivalDate: "2014-01-03",
      DepartureDate: "2014-01-04",
      LOS1: 1,
      LOS2: null,
      LOS3: null,
      LOS4: null,
      LOS5: null,
      LOS6: null,
      User: "CRS",
      BarBased: null,
      TravelAgent: null,
      ModifiedUser: null,
      CombinedChannel: null,
      CTSName: null,
      Intermediaries: null,
      GroupName: null,
      LOSDetails: 1,
      ArrivalLOS1: 1,
      ArrivalLOS2: null,
      ArrivalLOS3Plus: null,
      MarketSegmentCategory: null,
      MarketSegmentPrefix: null,
      ResvNameID: "319260703",
      CancellationDate: null,
      CancellationTime: null,
      CancellationBy: "",
      RefResNo: "58422235",
      EventID: null,
    },
    {
      id: 277143,
      propertyCode: "AC32AW",
      propertyId: "1",
      pullDateId: "495",
      AsOfDate: "2023-08-16",
      RoomType: "NDD",
      RoomNo: 334,
      StayDate: "2014-01-03",
      RateCode: "SRD Rate",
      RateCodeDescript: null,
      MarketSegment: "Retail",
      Source: null,
      BookingDate: "2014-01-02",
      BookingTime: null,
      Company: "Leisure",
      Pace: "PACE",
      RoomNight: 1,
      Revenue: "0.00",
      Rate: "0.00",
      ADR: "0.0",
      PayType: null,
      LeadTime: 1,
      ForecastType: null,
      LOS: 1,
      ExtendedStay1: 1,
      ExtendedStay2: 0,
      ExtendedStay3: 0,
      ExtendedStay4: 0,
      Account: "319260703",
      Status: "O",
      ForecastTypeRevenue: null,
      GuestName: "MAUPIN, KATHY",
      GuestType: null,
      ArrivalDate: "2014-01-03",
      DepartureDate: "2014-01-04",
      LOS1: 1,
      LOS2: null,
      LOS3: null,
      LOS4: null,
      LOS5: null,
      LOS6: null,
      User: "CRS",
      BarBased: null,
      TravelAgent: null,
      ModifiedUser: null,
      CombinedChannel: null,
      CTSName: null,
      Intermediaries: null,
      GroupName: null,
      LOSDetails: 1,
      ArrivalLOS1: 1,
      ArrivalLOS2: null,
      ArrivalLOS3Plus: null,
      MarketSegmentCategory: null,
      MarketSegmentPrefix: null,
      ResvNameID: "319260703",
      CancellationDate: null,
      CancellationTime: null,
      CancellationBy: "",
      RefResNo: "58422235",
      EventID: null,
    },
    {
      id: 277143,
      propertyCode: "AC32AW",
      propertyId: "1",
      pullDateId: "495",
      AsOfDate: "2023-08-16",
      RoomType: "NDD",
      RoomNo: 334,
      StayDate: "2014-01-03",
      RateCode: "SRD Rate",
      RateCodeDescript: null,
      MarketSegment: "Retail",
      Source: null,
      BookingDate: "2014-01-02",
      BookingTime: null,
      Company: "Leisure",
      Pace: "PACE",
      RoomNight: 1,
      Revenue: "0.00",
      Rate: "0.00",
      ADR: "0.0",
      PayType: null,
      LeadTime: 1,
      ForecastType: null,
      LOS: 1,
      ExtendedStay1: 1,
      ExtendedStay2: 0,
      ExtendedStay3: 0,
      ExtendedStay4: 0,
      Account: "319260703",
      Status: "O",
      ForecastTypeRevenue: null,
      GuestName: "MAUPIN, KATHY",
      GuestType: null,
      ArrivalDate: "2014-01-03",
      DepartureDate: "2014-01-04",
      LOS1: 1,
      LOS2: null,
      LOS3: null,
      LOS4: null,
      LOS5: null,
      LOS6: null,
      User: "CRS",
      BarBased: null,
      TravelAgent: null,
      ModifiedUser: null,
      CombinedChannel: null,
      CTSName: null,
      Intermediaries: null,
      GroupName: null,
      LOSDetails: 1,
      ArrivalLOS1: 1,
      ArrivalLOS2: null,
      ArrivalLOS3Plus: null,
      MarketSegmentCategory: null,
      MarketSegmentPrefix: null,
      ResvNameID: "319260703",
      CancellationDate: null,
      CancellationTime: null,
      CancellationBy: "",
      RefResNo: "58422235",
      EventID: null,
    },
  ];

  const date = new Date();
  const startDate = commonService.getDateInDBFormat(
    new Date(date.getFullYear(), date.getMonth(), 1)
  );
  const endDate = commonService.getDateInDBFormat(
    new Date(date.getFullYear(), date.getMonth() + 1, 0)
  );

  const [mstReservationData, setMstReservationData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [startRangeDate, setStartRangeDate] = useState();
  const [endRangeDate, setEndRangeDate] = useState();
  const startRangeDateRef = useRef();
  const endRangeDateRef = useRef();
  const [widgetModal, setWidgetModal] = useState(false);
  const widgetDetailRef = useRef();
  const [showNoteSidebar, setShowNoteSidebar] = useState(false);
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const propertyDetailFromRTK = useSelector(
    (store) => store.headerProperty.propertyDetail
  );
  const defaultAsOfDateFromRTK = useSelector(
    (store) => store.headerProperty.defaultAsOfDate
  );
  const { snapshottitle, propertyCode, snapshotId } = useParams();
  const closeNoteSideBar = () => {
    setShowNoteSidebar(false);
  };

  const closeWidgetModal = () => {
    setWidgetModal(false);
  };
  const handleDateChange = (event) => {
    const [start, end] = event;
    let startSelectedDate = "",
      endSelectedDate = "";
    if (start) {
      let date = new Date(start);
      let month =
        date.getMonth() + 1 > 9
          ? date.getMonth() + 1
          : `0${date.getMonth() + 1}`;
      let dateFormat =
        date?.getDate() > 9 ? date.getDate() : `0${date.getDate()}`;
      startSelectedDate = `${date.getFullYear()}-${month}-${dateFormat}`;
      startRangeDateRef.current = startSelectedDate;
    }
    if (end) {
      let date = new Date(end);
      let month =
        date.getMonth() + 1 > 9
          ? date.getMonth() + 1
          : `0${date.getMonth() + 1}`;
      let dateFormat =
        date?.getDate() > 9 ? date.getDate() : `0${date.getDate()}`;
      endSelectedDate = `${date.getFullYear()}-${month}-${dateFormat}`;
      endRangeDateRef.current = endSelectedDate;
    }

    setStartRangeDate(event[0]);
    setEndRangeDate(event[1]);
    if (event[0] !== null && event[1] !== null) {
      // getMstReservation();
    }
  };

  const getMstReservation = async () => {
    setLoading(true);
    let mstReservationResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.SnapShot +
          API_URL.MST_RESERVATION.MST_RESERVATION,
        body: {
          AsOfDate: para?.asOfDate,
          startDate: startRangeDateRef.current
            ? startRangeDateRef.current
            : startDate,
          endDate: endRangeDateRef.current ? endRangeDateRef.current : endDate,
          propertyCode: para?.propertyCode,
        },
      },
      false
    );

    let response = apiResponse(false, mstReservationResponse);

    if (response?.isValidate) {
      setMstReservationData(response?.data?.data);
      setLoading(false);
    }

    if (!response?.isValidate) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (api === 0) {
      setMstReservationData(tableData);
    } else {
      if (para?.asOfDate) {
        getMstReservation();
      }
    }
  }, [para?.asOfDate]);
  // const [isSticky, setIsSticky] = useState(false);

  // useEffect(() => {
  //   const header = document.getElementById("tblMstReservationThead");

  //   const handleScroll = () => {
  //     const headerTop = header.getBoundingClientRect();
  //     setIsSticky(headerTop.top - 83 <= 0);
  //   };

  //   window.addEventListener("scroll", handleScroll);

  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);

  // const headerClassName = isSticky ? "sticky-header" : "";
  // useEffect(() => {
  //   const handleScroll = () => {
  //     const divElement = document.querySelector('#tblMstReservation');

  //     if (divElement) {
  //       const divRect = divElement.getBoundingClientRect();

  //     }
  //   };

  //   window.addEventListener('scroll', handleScroll);

  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, []);

  const [tblids, settblids] = useState({
    tblMstReservation: commonService.GenerateGUID(),
  });

  return (
    <div className="widget-container">
      {api !== 0 && (
        <div className="gap-1 widget-container-menu">
          <Button className="heading-menu-btns" type="button">
            <img
              src={NoteIcon}
              alt="menuIcon"
              onClick={() => {
                setShowNoteSidebar(true);
              }}
            />
          </Button>
          <Button className="heading-menu-btns" type="button" title="Send Mail">
            <img
              src={emailIcon}
              alt="emailIcon"
              onClick={() => {
                commonService.fnSendWidgetMail(
                  tblids.tblMstReservation,
                  "Mst Reservation",
                  snapshotId,
                  defaultAsOfDateFromRTK,
                  "",
                  para?.widgetId
                );
              }}
            />
          </Button>
          <Button className="heading-menu-btns" type="button">
            <img
              src={infoIcon}
              alt="info"
              onClick={() => {
                widgetDetailRef.current = {
                  widgetdetails: "",
                  usernote: "",
                  compName: compName?.mstReservation,
                  widgetId: para?.widgetId,
                };
                setWidgetModal(true);
              }}
            />
          </Button>
          <Button className="heading-menu-btns" type="button">
            <img
              src={downloadIcon}
              alt="menuIcon"
              import={tblids.tblMstReservation}
              onClick={() =>
                commonService.fnExportInCSV(
                  tblids.tblMstReservation,
                  // "Mst Reservation"
                  `${para?.propertyCode}-Mst Reservation-${moment(
                    new Date()
                  ).format("MMDDYYYY")}`
                )
              }
            />
          </Button>
        </div>
      )}
      <div className="ViewPropertySetupTable">
        <div className="stripped__table">
        <div className="table-responsive tblDynamicPickupSticky">
          <Table id={tblids.tblMstReservation} className="">
            <thead id="tblMstReservationThead" className="tbl-header-sticky">
              <tr className="table-main-header-tr">
                <th
                  colSpan={60}
                  className="text-center"
                  onClick={() => {
                    setOpenDatePicker(true);
                  }}
                >
                  <div
                    className="d-flex gap-5 align-items-center"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    <div className="d-flex justify-content-start align-items-center gap-3">
                      <p className="m-0 d-flex align-items-center">Date </p>
                      <DatePicker
                        onChange={(event) => handleDateChange(event)}
                        startDate={startRangeDate}
                        endDate={endRangeDate}
                        selectsRange
                        monthsShown={2}
                        open={openDatePicker}
                        shouldCloseOnSelect={false}
                      >
                        <div
                          className="w-100"
                          style={{ display: "inline-block" }}
                        >
                          <div className="content-inside-calendar">
                            <p>
                              {startRangeDate
                                ? commonService.getDateInFormat(startRangeDate)
                                : ""}
                              {startRangeDate && endRangeDate ? " - " : ""}
                              {endRangeDate
                                ? commonService.getDateInFormat(endRangeDate)
                                : ""}
                            </p>
                            <div className="d-flex align-items-center gap-2">
                              <button
                                className="secondary-btn"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setStartRangeDate("");
                                  setEndRangeDate("");
                                  setOpenDatePicker(false);
                                }}
                              >
                                Cancel
                              </button>
                              <button
                                disabled={
                                  startRangeDate && endRangeDate ? false : true
                                }
                                onClick={(e) => {
                                  e.stopPropagation();
                                  if (
                                    startRangeDateRef.current &&
                                    endRangeDateRef.current
                                  ) {
                                    getMstReservation();
                                  }

                                  setOpenDatePicker(false);
                                }}
                                className="primary-btn"
                              >
                                Apply
                              </button>
                            </div>
                          </div>
                        </div>
                      </DatePicker>
                      <span
                        style={{ display: "none" }}
                      >{`${startRangeDate} - ${endRangeDate}`}</span>
                    </div>
                    <div className="d-flex align-items-center">
                      Mst Reservation
                    </div>
                  </div>
                </th>
              </tr>
              <tr className="table-main-header-tr">
                {/* <th className="width150">property Code</th>
                <th className="width150">property Id</th>
                <th className="width150">pullDate Id</th> */}
                <th className="width150">As Of Date</th>
                <th className="width150">Room Type</th>
                <th className="width150 text-center">Room No</th>
                <th className="width150">Stay Date</th>
                <th className="width150">Rate Code</th>
                <th className="width150">Rate Code Descript</th>
                <th className="width150">Market Segment</th>
                <th className="width150">Source</th>
                <th className="width150">Booking Date</th>
                <th className="width150">Booking Time</th>
                <th className="width150">Company</th>
                <th className="width150">Pace</th>
                <th className="width150 text-center">Room Night</th>
                <th className="width150 text-center">Revenue</th>
                <th className="width150 text-center">Rate</th>
                <th className="width150 text-center">ADR</th>
                <th className="width150">Pay Type</th>
                <th className="width150 text-center">Lead Time</th>
                <th className="width150">Forecast Type</th>
                <th className="width150 text-center">LOS</th>
                <th className="width150 text-center">Extended Stay1</th>
                <th className="width150 text-center">Extended Stay2</th>
                <th className="width150 text-center">Extended Stay3</th>
                <th className="width150 text-center">Extended Stay4</th>
                <th className="width150">Account</th>
                <th className="width150 text-center">Status</th>
                <th className="width150">Forecast Type Revenue</th>
                <th className="width150">Guest Name</th>
                <th className="width150">Guest Type</th>
                <th className="width150">Arrival Date</th>
                <th className="width150">Departure Date</th>
                <th className="width150 text-center">LOS1</th>
                <th className="width150 text-center">LOS2</th>
                <th className="width150 text-center">LOS3</th>
                <th className="width150 text-center">LOS4</th>
                <th className="width150 text-center">LOS5</th>
                <th className="width150 text-center">LOS6</th>
                <th className="width150">User</th>
                <th className="width150">Bar Based</th>
                <th className="width150">Travel Agent</th>
                <th className="width150">Modified User</th>
                <th className="width150">Combined Channel</th>
                <th className="width150">CTS Name</th>
                <th className="width150">Intermediaries</th>
                <th className="width150">Group Name</th>
                <th className="width150 text-center">LOS Details</th>
                <th className="width150 text-center">Arrival LOS1</th>
                <th className="width150 text-center">Arrival LOS2</th>
                <th className="width150 text-center">Arrival LOS3 Plus</th>
                <th className="width150">Market Segment Category</th>
                <th className="width150">Market Segment Prefix</th>
                <th className="width150">Resv Name ID</th>
                <th className="width150">Cancellation Date</th>
                <th className="width150">Cancellation Time</th>
                <th className="width150">Cancellation By</th>
                <th className="width150">Ref Res No</th>
                <th className="width150">Event ID</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr style={{ height: "80px", position: "relative" }}>
                  <Loader />
                </tr>
              ) : mstReservationData ? (
                mstReservationData?.map((item, index) => (
                  <tr key={index} className="shadowOnHover">
                    {/* <td className="">{item?.propertyCode}</td>
                    <td className="text-center">{item?.propertyId}</td>
                    <td className="text-center">{item?.pullDateId}</td> */}
                    <td className="">
                      {commonService?.getDateInFormat(item?.AsOfDate)}
                    </td>
                    <td className="">{item?.RoomType}</td>
                    <td className="text-center">{item?.RoomNo}</td>
                    <td className="">
                      {commonService?.getDateInFormat(item?.StayDate)}
                    </td>
                    <td className="">{item?.RateCode}</td>
                    <td className="">{item?.RateCodeDescript}</td>
                    <td className="">{item?.MarketSegment}</td>
                    <td className="">{item?.Source}</td>
                    <td className="">
                      {commonService.getDateInFormat(item?.BookingDate)}
                    </td>
                    <td className="">{item?.BookingTime}</td>
                    <td className="">{item?.Company}</td>
                    <td className="">{item?.Pace}</td>
                    <td className="text-center">{item?.RoomNight}</td>
                    <td className="text-center">{item?.Revenue}</td>
                    <td className="text-center">{item?.Rate}</td>
                    <td className="text-center">{item?.ADR}</td>
                    <td className="">{item?.PayType}</td>
                    <td className="text-center">{item?.LeadTime}</td>
                    <td className="">{item?.ForecastType}</td>
                    <td className="text-center">{item?.LOS}</td>
                    <td className="text-center">{item?.ExtendedStay1}</td>
                    <td className="text-center">{item?.ExtendedStay2}</td>
                    <td className="text-center">{item?.ExtendedStay3}</td>
                    <td className="text-center">{item?.ExtendedStay4}</td>
                    <td className="">{item?.Account}</td>
                    <td className="text-center">{item?.Status}</td>
                    <td className="">{item?.ForecastTypeRevenue}</td>
                    <td className="">{item?.GuestName}</td>
                    <td className="">{item?.GuestType}</td>
                    <td className="">
                      {commonService?.getDateInFormat(item?.ArrivalDate)}
                    </td>
                    <td className="">
                      {commonService?.getDateInFormat(item?.DepartureDate)}
                    </td>
                    <td className="text-center">{item?.LOS1}</td>
                    <td className="text-center">{item?.LOS2}</td>
                    <td className="text-center">{item?.LOS3}</td>
                    <td className="text-center">{item?.LOS4}</td>
                    <td className="text-center">{item?.LOS5}</td>
                    <td className="text-center">{item?.LOS6}</td>
                    <td className="">{item?.User}</td>
                    <td className="">{item?.BarBased}</td>
                    <td className="">{item?.TravelAgent}</td>
                    <td className="">{item?.ModifiedUser}</td>
                    <td className="">{item?.CombinedChannel}</td>
                    <td className="">{item?.CTSName}</td>
                    <td className="">{item?.Intermediaries}</td>
                    <td className="">{item?.GroupName}</td>
                    <td className="text-center">{item?.LOSDetails}</td>
                    <td className="text-center">{item?.ArrivalLOS1}</td>
                    <td className="text-center">{item?.ArrivalLOS2}</td>
                    <td className="text-center">{item?.ArrivalLOS3Plus}</td>
                    <td className="">{item?.MarketSegmentCategory}</td>
                    <td className="">{item?.MarketSegmentPrefix}</td>
                    <td className="">{item?.ResvNameID}</td>
                    <td className="">{item?.CancellationDate}</td>
                    <td className="">{item?.CancellationTime}</td>
                    <td className="">{item?.CancellationBy}</td>
                    <td className="">{item?.RefResNo}</td>
                    <td className="">{item?.EventID}</td>
                  </tr>
                ))
              ) : (
                <tr>No Data Found</tr>
              )}
            </tbody>
          </Table>
          </div>
        </div>
      </div>
      {/* {showNoteSidebar && (
                <WidgetNote
                    show={showNoteSidebar}
                    handleClose={closeNoteSideBar}
                    widgetId={para?.widgetId}
                    propertyId={propertyDetailFromRTK?.propertyid}
                    rowTitle={para?.rowTitle}
                />
            )} */}
      {showNoteSidebar && (
        <AddWidgetModal
          showModal={showNoteSidebar}
          closeModal={closeNoteSideBar}
          widgetId={para?.widgetId}
          propertyId={propertyDetailFromRTK?.propertyid}
          rowTitle={para?.rowTitle}
        />
      )}
      {widgetModal && (
        <WidgetDetailModal
          showModal={widgetModal}
          closeModal={closeWidgetModal}
          data={widgetDetailRef.current}
        />
      )}
    </div>
  );
};

export default MstReservation;
