import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { Editor } from "react-draft-wysiwyg";
import {
  ContentState,
  EditorState,
  convertFromRaw,
  convertToRaw,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import placeholder from "../../Assets/Images/Configuration/image_placeholder.svg";
import { apiCall } from "../../utils/axiosService";
import { API_URL } from "../../utils/apiRoutes";
import { apiResponse } from "../../utils/apiResponse";
import { commonService } from "../../utils/commonService";
import axios from "axios";
import crossIcon from "../../Assets/Images/Revenue-management/white-close.svg";
import pdfIcon from "../../Assets/Images/CRM/pdf.svg";
import { useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import { create_ckeditor } from "../../utils/create_ckeditor";

const AddWidgetModal = ({
  showModal,
  closeModal,
  widgetId,
  propertyId,
  getList,
  rowTitle,
  dateForNote,
}) => {
  const asOfDateFromRTK = useSelector((store) => store.headerProperty.asOfDate);
  let validationObj = {
    widgetnoteid: 0,
    widgetid: widgetId,
    widgetnotes: "",
    parentwidgetnoteid: 0,
    propertyid: propertyId,
    files: [],
    asofdate: commonService.getDateInDBFormat(asOfDateFromRTK),
    title: `${
      dateForNote
        ? commonService.getDateInFormat(dateForNote)
        : commonService.getDateInFormat(asOfDateFromRTK)
    } - ${rowTitle}`,
    istodo: false,
    assigntouser: "",
    tododate: "",
    error: false,
  };
  const [noteData, setNoteData] = useState(validationObj);
  const [loading, setLoading] = useState(false);
  const inputFile = useRef();
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const onEditorStateChange = (newEditorState) => {
    setEditorState(newEditorState);
    setNoteData({
      ...noteData,
      widgetnotes: draftToHtml(convertToRaw(editorState.getCurrentContent())),
    });
  };
  const propertyDetailFromRTK = useSelector(
    (store) => store.headerProperty.propertyDetail
  );
  const [userDropDown, setUserDropDown] = useState(null);

  const handleSubmit = async () => {
    setLoading(true);
    try {
      let formData = new FormData();
      let body = {
        widgetnoteid: noteData?.widgetnoteid,
        widgetid: noteData?.widgetid,
        // widgetnotes: noteData?.widgetnotes,
        widgetnotes: window.CKEDITOR.instances.widgetNotes.getData(),
        parentwidgetnoteid: noteData?.parentwidgetnoteid,
        propertyid: noteData?.propertyid,
        files: noteData?.files,
        asofdate: noteData?.asofdate,
        title: noteData?.title,
        istodo: noteData?.istodo,
      };
      if (noteData?.istodo) {
        if (!noteData?.tododate || !noteData?.assigntouser) {
          setNoteData({
            ...noteData,
            error: true,
          });
          setLoading(false);
          return;
        } else {
          body = {
            ...body,
            assigntouser: noteData?.assigntouser,
            tododate: commonService.getDateInDBFormat(noteData?.tododate),
          };
        }
      }
      if (
        !noteData?.title ||
        !window.CKEDITOR.instances.widgetNotes.getData()
      ) {
        setNoteData({
          ...noteData,
          error: true,
        });
        setLoading(false);
        return;
      }
      if (dateForNote) {
        body = {
          ...body,
          datadate: commonService.getDateInDBFormat(dateForNote),
        };
      }

      for (const [key, value] of Object.entries(body)) {
        if (typeof value === "object" && Array.isArray(value)) {
          value?.map((item, index) => {
            formData.append(`${key}`, item);
          });
        } else {
          formData.append(`${key}`, value);
        }
      }

      let response = await axios({
        method: "POST",
        url: API_URL.BASE_API_URL.Configuration + API_URL.WIDGET_NOTE.SAVE,
        data: formData,
        headers: commonService.getHeadersFromData(),
      });
      let finalResponse = apiResponse(true, response?.data, setLoading);

      if (finalResponse?.isValidate) {
        if (getList) {
          getList();
        }

        closeModal();
      }

      if (!finalResponse?.isValidate) {
        setLoading(false);
      }
    } catch (error) {
      return error;
    }
  };

  const getUserDropDownList = async () => {
    let userRolePropertyResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.UserManagement +
          API_URL.USER_ROLE_PROPERTY.GET_LIST_BY_PROPERTY_AND_MODULE,
        body: {
          propertyid: parseInt(propertyDetailFromRTK?.propertyid),
          modulename: "revenue",
        },
      },
      false
    );

    let response = apiResponse(false, userRolePropertyResponse);

    if (response?.isValidate) {
      setUserDropDown(response?.data?.data);
    }

    if (!response?.isValidate) {
      setUserDropDown(null);
    }
  };

  useEffect(() => {
    if (noteData?.istodo) {
      getUserDropDownList();
    }
  }, [noteData.istodo]);

  useEffect(() => {
    create_ckeditor("widgetNotes");
  }, []);

  return (
    <>
      <Modal
        className="AddPropertySetupModalMain"
        size="xl"
        centered
        show={showModal}
        onHide={closeModal}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {`Add Widget Note`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Col xl={12}>
            <div className="from-groups no-border-break">
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                maxLength="67"
                placeholder="Enter Title"
                value={noteData?.title}
                onChange={(e) => {
                  setNoteData({
                    ...noteData,
                    title: e.target.value,
                  });
                }}
              />
              {noteData?.error && !noteData?.title && (
                <small className="text-danger">Please Enter Title</small>
              )}
            </div>
          </Col>
          <Col xl={12} className="EmailTempConfigModalMain-inputs">
            {/* <div className="form-groups textEditor">
              <Form.Label className="ps-0">Note</Form.Label>
              <Editor
                editorState={editorState}
                wrapperClassName="editorWrapper"
                toolbarClassName="editorToolBar"
                onEditorStateChange={onEditorStateChange}
              />
              {noteData?.error && !noteData?.widgetnotes && (
                <small className="text-danger">Please Enter Note</small>
              )}
            </div> */}
            <div className="form-groups textEditor mt-2">
              <textarea
                id="widgetNotes"
                style={{ visibility: "hidden", display: "none" }}
                placeholder="Message Body"
                className="wysiwyg_ckeditor"
                defaultValue={noteData.widgetnotes}
              ></textarea>
              {noteData?.error && !noteData?.widgetnotes && (
                <small className="text-danger">Please Enter Note</small>
              )}
            </div>
          </Col>
          <Col xl={12} className="mt-2">
            <Row>
              {/* <Col xl={4}>
                <div className="from-groups no-border-break">
                  <Form.Control
                    type="text"
                    maxLength="67"
                    placeholder="Enter Title"
                    value={noteData?.title}
                    onChange={(e) => {
                      setNoteData({
                        ...noteData,
                        title: e.target.value,
                      });
                    }}
                  />
                </div>
              </Col> */}
              <Col xl={3}>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="isTodoNote"
                    checked={noteData?.istodo}
                    onChange={() => {
                      setNoteData({
                        ...noteData,
                        istodo: !noteData?.istodo,
                      });
                    }}
                  />
                  <label className="form-check-label" htmlFor="isTodoNote">
                    Is Action Note?
                  </label>
                </div>
              </Col>
              {noteData?.istodo && (
                <>
                  <Col xl={3}>
                    <div className="from-groups no-border-break">
                      <Form.Select
                        aria-label="Default select example"
                        value={noteData?.assigntouser}
                        onChange={(e) => {
                          setNoteData({
                            ...noteData,
                            assigntouser: e.target.value,
                          });
                        }}
                      >
                        <option value="">Select User</option>
                        {userDropDown?.map((item, index) => (
                          <option key={index} value={item?.userid}>
                            {item?.displayname}
                          </option>
                        ))}
                      </Form.Select>
                      {noteData?.error && !noteData?.assigntouser && (
                        <small className="text-danger">
                          Please Select User
                        </small>
                      )}
                    </div>
                  </Col>
                  <Col xl={3}>
                    <div className="from-groups no-border-break">
                      <DatePicker
                        id="todoDate"
                        selected={noteData?.tododate}
                        onChange={(date) => {
                          setNoteData({
                            ...noteData,
                            tododate: date,
                          });
                        }}
                        placeholderText="Select Todo Date"
                      />
                      {noteData?.error && !noteData?.tododate && (
                        <small className="text-danger">
                          Please Select Todo Date
                        </small>
                      )}
                    </div>
                  </Col>
                </>
              )}
            </Row>
          </Col>
          <Col xl={12} md={4}>
            <div className="multiFileContainer mt-3">
              {noteData?.files?.map((item, index) => {
                return (
                  <div>
                    {item?.type?.includes("image") ? (
                      <div className="multiFile">
                        <img
                          style={{ height: "100%" }}
                          src={URL.createObjectURL(item)}
                          alt=""
                        />
                        <div className="closeIconDiv">
                          <img
                            src={crossIcon}
                            className="closeFile"
                            onClick={() => {
                              let filterData = noteData?.files?.filter(
                                (x, i) => i !== index
                              );

                              setNoteData({
                                ...noteData,
                                files: filterData,
                              });
                            }}
                          />
                        </div>
                      </div>
                    ) : (
                      <div className="multiFile">
                        <img src={pdfIcon} alt="" style={{ height: "100%" }} />
                        <div className="closeIconDiv">
                          <img
                            src={crossIcon}
                            className="closeFile"
                            onClick={() => {
                              let filterData = noteData?.files?.filter(
                                (x, i) => i !== index
                              );

                              setNoteData({
                                ...noteData,
                                files: filterData,
                              });
                            }}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}
              <input
                type="file"
                className="form-control d-none"
                id="profileImg"
                ref={inputFile}
                multiple
                onChange={(e) => {
                  let filterData = [];
                  if (e.target.files) {
                    filterData = Object.entries(e.target.files)?.map(
                      ([key, value], index) => {
                        return value;
                      }
                    );
                  }

                  setNoteData({
                    ...noteData,
                    files: [...noteData.files, ...filterData],
                  });
                }}
              />
              <img
                src={placeholder}
                alt=""
                type="file"
                className="noteFile"
                onClick={() => {
                  inputFile.current.click();
                }}
              />
            </div>
          </Col>
        </Modal.Body>
        <Modal.Footer>
          {loading ? (
            <button className="default-btn primary-btn" type="button" disabled>
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              Loading...
            </button>
          ) : (
            <button
              className="default-btn primary-btn"
              onClick={() => {
                handleSubmit();
              }}
            >
              Submit
            </button>
          )}

          <Button className="default-btn secondary-btn" onClick={closeModal}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddWidgetModal;
