import { compName } from "./constant";
export const componentSetting = {
    [compName?.budget]: JSON.stringify({
        header: [
            [
                {
                    name: "Budget",
                    colspan: 5,
                    parentid: "0",
                    id: "1",
                    datacolname: "",
                    isshow: true,
                    class: ""

                }
            ],
            [
                {
                    name: "Month",
                    colspan: 1,
                    parentid: "1",
                    id: "1_1",
                    datacolname: "month",
                    isshow: true,
                    class: ""
                },
                {
                    name: "OCC",
                    colspan: 1,
                    parentid: "1",
                    id: "2_1",
                    datacolname: "occ",
                    isshow: true,
                    class: ""
                },
                {
                    name: "RMS",
                    colspan: 1,
                    parentid: "1",
                    id: "2_2",
                    datacolname: "rms",
                    isshow: true,
                    class: ""
                },
                {
                    name: "ADR",
                    colspan: 1,
                    parentid: "1",
                    id: "2_3",
                    datacolname: "adr",
                    isshow: true,
                    class: ""
                },
                {
                    name: "REV",
                    colspan: 1,
                    parentid: "1",
                    id: "2_4",
                    datacolname: "rev",
                    isshow: true,
                    class: ""
                }
            ]
        ]
    }),
    [compName?.netStly]: JSON.stringify({
        header: [
            [
                {
                    name: "Net Stly",
                    colspan: 5,
                    parentid: "0",
                    id: "1",
                    datacolname: "Net Stly",
                    isshow: true,
                    class: ""
                },
            ],
            [
                {
                    name: "Month",
                    colspan: 1,
                    parentid: "1",
                    id: "1_1",
                    datacolname: "month",
                    isshow: true,
                    class: ""
                },
                {
                    name: "OCC",
                    colspan: 1,
                    parentid: "1",
                    id: "1_2",
                    datacolname: "occ",
                    isshow: true,
                    class: ""
                },
                {
                    name: "RMS",
                    colspan: 1,
                    parentid: "1",
                    id: "1_3",
                    datacolname: "rms",
                    isshow: true,
                    class: ""
                },
                {
                    name: "ADR",
                    colspan: 1,
                    parentid: "1",
                    id: "1_4",
                    datacolname: "adr",
                    isshow: true,
                    class: ""
                },
                {
                    name: "REV",
                    colspan: 1,
                    parentid: "1",
                    id: "1_5",
                    datacolname: "rev",
                    isshow: true,
                    class: ""
                }
            ],
        ]
    }),
    [compName?.onTheBook]: JSON.stringify({
        header: [
            [
                {
                    name: "ON THE BOOK",
                    colspan: 5,
                    parentid: "0",
                    id: "1",
                    datacolname: "",
                    isshow: true,
                    class: ""
                },
            ],
            [
                {
                    name: "Month",
                    colspan: 1,
                    parentid: "1",
                    id: "1_1",
                    datacolname: "month",
                    isshow: true,
                    class: ""
                },
                {
                    name: "OCC",
                    colspan: 1,
                    parentid: "1",
                    id: "1_2",
                    datacolname: "occ",
                    isshow: true,
                    class: ""
                },
                {
                    name: "RMS",
                    colspan: 1,
                    parentid: "1",
                    id: "1_3",
                    datacolname: "rms",
                    isshow: true,
                    class: ""
                },
                {
                    name: "ADR",
                    colspan: 1,
                    parentid: "1",
                    id: "1_4",
                    datacolname: "adr",
                    isshow: true,
                    class: ""
                },
                {
                    name: "REV",
                    colspan: 1,
                    parentid: "1",
                    id: "1_5",
                    datacolname: "rev",
                    isshow: true,
                    class: ""
                }
            ],
        ]
    }),
    [compName?.reservationActivity]: "",
    [compName?.totalLy]: JSON.stringify({
        header: [
            [
                {
                    name: "Total LY",
                    colspan: 5,
                    parentid: "0",
                    id: "1",
                    datacolname: "",
                    isshow: true,
                    class: ""
                },
            ],
            [
                {
                    name: "Month",
                    colspan: 1,
                    parentid: "1",
                    id: "1_1",
                    datacolname: "month",
                    isshow: true,
                    class: ""
                },
                {
                    name: "OCC",
                    colspan: 1,
                    parentid: "1",
                    id: "1_2",
                    datacolname: "occ",
                    isshow: true,
                    class: ""
                },
                {
                    name: "RMS",
                    colspan: 1,
                    parentid: "1",
                    id: "1_3",
                    datacolname: "rms",
                    isshow: true,
                    class: ""
                },
                {
                    name: "ADR",
                    colspan: 1,
                    parentid: "1",
                    id: "1_4",
                    datacolname: "adr",
                    isshow: true,
                    class: ""
                },
                {
                    name: "REV",
                    colspan: 1,
                    parentid: "1",
                    id: "1_5",
                    datacolname: "rev",
                    isshow: true,
                    class: ""
                }
            ],
        ]
    }),
    [compName?.oneDay]: "",
    [compName?.oneDayForecastChange]: "",
    [compName?.sevenDay]: "",
    [compName?.sevenDayForecastChange]: "",
    [compName?.group]: "",
    [compName?.groupLastYear]: "",
    [compName?.transient]: "",
    [compName?.transientLastYear]: "",
    [compName?.org]: "",
    [compName?.pickupFrom]: "",
    [compName?.reservationActivity]: "",
    [compName?.heatMap]: "",
    [compName?.widgetBudgetGraph]: "",
    [compName?.spiderForecast]: "",
    [compName?.rmsForecast]: "",
    [compName?.userForecast]: "",
    [compName?.choiceRes]: "",
    [compName?.mstReservation]: "",
    [compName?.dailyDataTransaction]: "",
    [compName?.emaForecast]: JSON.stringify({
        header: [
            [
                {
                    name: "Forecast",
                    colspan: 6,
                    parentid: "0",
                    id: "1",
                    datacolname: "",
                    isshow: true,
                    class: ""
                },
            ],
            [
                {
                    name: "Month",
                    colspan: 1,
                    parentid: "1",
                    id: "1_1",
                    datacolname: "month",
                    isshow: true,
                    class: ""
                },
                {
                    name: "OCC",
                    colspan: 1,
                    parentid: "1",
                    id: "1_2",
                    datacolname: "occ",
                    isshow: true,
                    class: ""
                },
                {
                    name: "RMS",
                    colspan: 1,
                    parentid: "1",
                    id: "1_3",
                    datacolname: "rms",
                    isshow: true,
                    class: ""
                },
                {
                    name: "ADR",
                    colspan: 1,
                    parentid: "1",
                    id: "1_4",
                    datacolname: "adr",
                    isshow: true,
                    class: ""
                },
                {
                    name: "REV",
                    colspan: 1,
                    parentid: "1",
                    id: "1_5",
                    datacolname: "rev",
                    isshow: true,
                    class: ""
                }
                ,
                {
                    name: "VAR",
                    colspan: 1,
                    parentid: "1",
                    id: "1_6",
                    datacolname: "var",
                    isshow: true,
                    class: ""
                }
            ],
        ]
    }),
    [compName?.annualSummaryChart]: "",
    [compName?.forecastChart]: "",
    [compName?.pickupChart]: "",
    [compName?.groupChart]: "",
    [compName?.bookingPaceChart]: "",
    [compName?.marketSegmentChart]: "",
    [compName?.widgetChoiceOccupancy]: "",
    [compName?.widgetHotelKeyReservation]: "",
    [compName?.widgetHotelKeyOccupancySnapshot]: "",
    [compName?.widgetBestWesternGuestName]: "",
    [compName?.widgetBestWesternReservationAgent]: "",
    [compName?.widgetBestWesternReservationForecast]: "",
    [compName?.widgetBestWesternRoomMaintaince]: "",
    [compName?.widgetChoiceCancelationSummary]: "",
    [compName?.adrVsRateChart]: "",
    [compName?.widgetPickup]: "",
    [compName?.bookingPaceTable]:  "",
    [compName?.annualSummary]: "",
    [compName?.widgetChoiceBusinessOTB]: "",
    [compName?.widgetChoiceRevenue]: "",
    [compName?.marketSegmentDayByDay]: "",
    [compName?.marketSegmentRoomType]: "",
    [compName?.ratePlanDayByDay]: "",
    [compName?.dynamicPickup]: "",
    [compName?.losDayOfWeek]: "",
    [compName?.top10Chart]: "",
    [compName?.dayByDayGroup]: "",
    [compName?.sevenDayBookingReservation]: "",
    [compName?.sevenDayBookingExpected]: "",
    [compName?.sevenDayBookingPace]: "",
};