import $ from "jquery";

export const create_ckeditor = (textareaid) => {
    $(document).ready(function () {
        let TableTime = setInterval(function () {
            clearInterval(TableTime);
            window.CKEDITOR.replace(textareaid);
        }, 200);
    });
};
