// import FullCalendar from "@fullcalendar/react";
import { useEffect, useRef, useState } from "react";
import { Col, FloatingLabel, Row } from "react-bootstrap";
// import { useSelector } from "react-redux";
import Select from "react-select";
// import PageLoader from "../../Component/Loader/PageLoader";
// import AddActivity from "../../Model/AddActivity";
// import { ToasterError } from "../../Services/CommonServices";
// import { GetListForCalendar } from "../../Services/DashboardServices";
// import { getUserListData } from "../../Services/Setup&Config/UserSetupService";
// import ActivityCalenderModal from "./ActivityCalenderModal";
// import FullCalender from "./FullCalender";
import CustomFullCalendar from "./CustomFullCalendar";

const Calendar = ({ viewDetail }) => {
  //   const SelectedPropertyIDs: any = useSelector((state: any) => state.dashboard);

  //   const hotelsData = useSelector((item: any) => item.header.Hotels);
  //   const dataSelector: any = useSelector((state: any) => state.session);
  //   const isData = dataSelector.auth_UserDetail;

  //   let userID: any = dataSelector.auth_UserDetail.U_UserID;

  const [users, setUsers] = useState([]);
  //   const [selectedUser, setSelectedUser] = useState < any > [];
  const [selectedUser, setSelectedUser] = useState([]);

  //   const clientID: any = dataSelector.auth_UserDetail.C_ClientID;

  const [modalShowActivity, setModalShowActivity] = useState(false);

  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState({
    addModal: false,
    editModal: false,
  });

  let eventId = useRef();

  // const [showModal, setShowModal] = useState(false);
  //   const [EditActivityId, setEditActivityId]: any = useState(
  //     "00000000-0000-0000-0000-000000000000"
  //   );
  const [EditActivityId, setEditActivityId] = useState(
    "00000000-0000-0000-0000-000000000000"
  );

  //   const [CalOption, setCalOption]: any = useState({
  //     date: new Date(),
  //     viewtype: "Month",
  //     data: [],
  //   });

  let Events = [
    {
      id: 1,
      date: "2023-06-06T18:30:00.000Z",
      title: "supe223423r@admin.com",
      description: "super@admin.com",
      type: "",
    },
    {
      id: 2,
      date: "2023-06-06T19:00:00.000Z",
      title: "prakash@gmail.com",
      description: "",
      type: "",
    },
    {
      id: 3,
      date: "2023-06-06T19:30:00.000Z",
      title: "ewrwerwer",
      description: "werwer",
      type: "",
    },
    {
      id: 4,
      date: "2023-06-07T07:45:00.000Z",
      title: "werwer",
      description: "werw",
      type: "",
    },
    {
      id: 5,
      date: "2023-06-07T13:15:00.000Z",
      title: "hix46514@gmail.com",
      description: "",
      type: "",
    },
    {
      id: 6,
      date: "2023-06-06T18:30:00.000Z",
      title: "ii",
      description: "ii",
      type: "",
    },
    {
      id: 7,
      date: "2023-06-06T18:30:00.000Z",
      title: "oo",
      description: "oo",
      type: "",
    },
    {
      id: 8,
      date: "2023-06-07T18:30:00.000Z",
      title: "vnvbnbn",
      description: "vbnvbnvbn",
      type: "",
    },
    {
      id: 9,
      date: "2023-06-21T05:30:00.000Z",
      title: "lorem",
      description: "",
      type: "",
    },
    {
      id: 10,
      date: "2023-07-03T18:30:00.000Z",
      title: "ytuytu",
      description: "tyuyt",
      type: "",
    },
    {
      id: 11,
      date: "2023-06-10T18:30:00.000Z",
      title: "fghgfh",
      description: "fghfgh",
      type: "",
    },
    {
      id: 12,
      date: "2023-07-03T23:15:00.000Z",
      title: "ghjghj",
      description: "ghjghj",
      type: "",
    },
    {
      id: 13,
      date: "2023-07-06T21:45:00.000Z",
      title: "iiiiiiii",
      description: "ghjghj",
      type: "",
    },
    {
      id: 14,
      date: "2023-05-10T01:00:00.000Z",
      title: "ghjjghjgh",
      description: "gghjghj",
      type: "",
    },
    {
      id: 15,
      date: "2023-05-13T00:15:00.000Z",
      title: "lljklj",
      description: "jklkjljk",
      type: "",
    },
    {
      id: 16,
      date: "2023-05-29T19:15:00.000Z",
      title: "hjghjghj",
      description: "ghjghj",
      type: "",
    },
    {
      id: 17,
      date: "2023-05-29T18:30:00.000Z",
      title: "jhkhjk",
      description: "hjkjhk",
      type: "",
    },
    {
      id: 18,
      date: "2023-05-29T18:30:00.000Z",
      title: "jhkhjk",
      description: "jhkjhk",
      type: "",
    },
    {
      id: 19,
      date: "2023-06-11T18:30:00.000Z",
      title: "super@admin.com",
      description: "",
      type: "",
    },
    {
      id: 20,
      date: "2023-05-27T18:30:00.000Z",
      title: "hgjghj",
      description: "gh",
      type: "",
    },
    {
      id: 21,
      date: "2023-06-14T20:00:00.000Z",
      title: "jerrwr",
      description: "saas",
      type: "",
    },
    {
      id: 22,
      date: "2023-08-07T18:30:00.000Z",
      title: "ghjgj",
      description: "ghjghj",
      type: "",
    },
    {
      id: 23,
      date: "2023-06-07T19:30:00.000Z",
      title: "all day",
      description: "ewwe",
      type: "allDay",
    },
    {
      id: 24,
      date: "2023-06-12T19:15:00.000Z",
      title: "sdsdfsdfsdfsfds@gmail.com",
      description: "",
      type: "allDay",
    },
    {
      id: 25,
      date: "2023-06-11T18:30:00.000Z",
      title: "fghfgh",
      description: "",
      type: "allDay",
    },
    {
      id: 26,
      date: "2023-06-05T18:30:00.000Z",
      title: "all-day",
      description: "",
      type: "allDay",
    },
    {
      id: 27,
      date: "2023-06-07T19:15:00.000Z",
      title: "hello",
      description: "",
      type: "allDay",
    },
    {
      id: 28,
      date: "2023-06-06T18:30:00.000Z",
      title: "as",
      description: "as",
      type: "",
    },
    {
      id: 29,
      date: "2023-04-10T18:30:00.000Z",
      title: "6",
      description: "",
      type: "",
    },
    {
      id: 30,
      date: "2023-10-19T09:45:00.000Z",
      title: "aaaa",
      description: "aaaa",
      type: "",
    },
    {
      id: 31,
      date: "2023-06-04T18:30:00.000Z",
      title: "opio",
      description: "iop",
      type: "",
    },
    {
      id: 32,
      date: "2023-06-08T18:30:00.000Z",
      title: "sdfsd",
      description: "",
      type: "",
    },
    {
      id: 33,
      date: "2023-06-06T18:30:00.000Z",
      title: "ioiu",
      description: "",
      type: "",
    },
    {
      id: 34,
      date: "2023-06-07T00:30:00.000Z",
      title: "uytut",
      description: "",
      type: "",
    },
    {
      id: 35,
      date: "2023-06-12T18:30:00.000Z",
      title: "ghj",
      description: "",
      type: "allDay",
    },
    {
      id: 36,
    },
    {
      id: 37,
    },
    {
      id: 38,
    },
    {
      id: 39,
    },
    {
      id: 40,
      date: "2023-06-02T18:30:00.000Z",
      title: "fdg",
      description: "",
      type: "",
    },
    {
      id: 41,
    },
    {
      id: 42,
    },
    {
      id: 43,
    },
    {
      id: 44,
    },
    {
      id: 45,
    },
    {
      id: 46,
    },
    {
      id: 47,
    },
    {
      id: 48,
    },
  ];

  const [CalOption, setCalOption] = useState({
    date: new Date(),
    viewtype: "Month",
    data: [
      // { id: 1, title: "abc", date: "Sun Jun 25 2023 11:42:38 GMT+0530" },
      // { id: 2, title: "jfdhfdj", date: "Mon Jun 26 2023 10:42:38 GMT+0530" },
      // { id: 3, title: "three", date: "Mon Jun 26 2023 13:42:38 GMT+0530" },

      {
        id: 1,
        date: "2023-09-01T19:00:00.000Z",
        title: "supe223423r@admin.com",
        description: "super@admin.com",
        type: "",
      },
      {
        id: 2,
        date: "2023-09-02T19:00:00.000Z",
        title: "first",
        description: "",
        type: "",
      },
      {
        id: 2222222222222222,
        date: "2023-09-02T19:00:00.000Z",
        title: "second",
        description: "",
        type: "",
      },
      {
        id: 22222233333333,
        date: "2023-09-02T19:00:00.000Z",
        title: "third",
        description: "",
        type: "",
      },
      {
        id: 22225555555555,
        date: "2023-09-02T19:00:00.000Z",
        title: "four",
        description: "",
        type: "",
      },
      {
        id: 32222255555555,
        date: "2023-09-03T19:00:00.000Z",
        title: "ewrwerwer",
        description: "werwer",
        type: "",
      },
      {
        id: 4,
        date: "2023-09-04T07:45:00.000Z",
        title: "werwer",
        description: "werw",
        type: "",
      },
      {
        id: 5,
        date: "2023-09-05T13:15:00.000Z",
        title: "hix46514@gmail.com",
        description: "",
        type: "",
      },
      {
        id: 6,
        date: "2023-09-06T18:30:00.000Z",
        title: "ii",
        description: "ii",
        type: "",
      },
      {
        id: 7,
        date: "2023-09-07T18:30:00.000Z",
        title: "oo",
        description: "oo",
        type: "",
      },
      {
        id: 8,
        date: "2023-09-08T18:30:00.000Z",
        title: "vnvbnbn",
        description: "vbnvbnvbn",
        type: "",
      },
      {
        id: 9,
        date: "2023-09-09T05:30:00.000Z",
        title: "lorem",
        description: "",
        type: "",
      },
      {
        id: 10,
        date: "2023-09-10T18:30:00.000Z",
        title: "ytuytu",
        description: "tyuyt",
        type: "",
      },
      {
        id: 11,
        date: "2023-09-11T18:30:00.000Z",
        title: "fghgfh",
        description: "fghfgh",
        type: "",
      },
      {
        id: 12,
        date: "2023-09-12T23:15:00.000Z",
        title: "ghjghj",
        description: "ghjghj",
        type: "",
      },
      {
        id: 13,
        date: "2023-09-13T21:45:00.000Z",
        title: "iiiiiiii",
        description: "ghjghj",
        type: "",
      },
      {
        id: 14,
        date: "2023-09-14T01:00:00.000Z",
        title: "ghjjghjgh",
        description: "gghjghj",
        type: "",
      },
      {
        id: 15,
        date: "2023-09-15T00:15:00.000Z",
        title: "lljklj",
        description: "jklkjljk",
        type: "",
      },
      {
        id: 16,
        date: "2023-09-16T19:15:00.000Z",
        title: "hjghjghj",
        description: "ghjghj",
        type: "",
      },
      {
        id: 17,
        date: "2023-09-17T18:30:00.000Z",
        title: "jhkhjk",
        description: "hjkjhk",
        type: "",
      },
      {
        id: 18,
        date: "2023-09-18T18:30:00.000Z",
        title: "jhkhjk",
        description: "jhkjhk",
        type: "",
      },
      {
        id: 19,
        date: "2023-09-19T18:30:00.000Z",
        title: "super@admin.com",
        description: "",
        type: "",
      },
      {
        id: 20,
        date: "2023-09-20T18:30:00.000Z",
        title: "hgjghj",
        description: "gh",
        type: "",
      },
      {
        id: 21,
        date: "2023-09-21T20:00:00.000Z",
        title: "jerrwr",
        description: "saas",
        type: "",
      },
      {
        id: 22,
        date: "2023-09-22T18:30:00.000Z",
        title: "ghjgj",
        description: "ghjghj",
        type: "",
      },
      {
        id: 23,
        date: "2023-09-23T19:30:00.000Z",
        title: "all day",
        description: "ewwe",
        type: "allDay",
      },
      {
        id: 24,
        date: "2023-09-24T19:15:00.000Z",
        title: "sdsdfsdfsdfsfds@gmail.com",
        description: "",
        type: "allDay",
      },
      {
        id: 25,
        date: "2023-09-25T18:30:00.000Z",
        title: "fghfgh",
        description: "",
        type: "allDay",
      },
      {
        id: 26,
        date: "2023-09-26T18:30:00.000Z",
        title: "all-day",
        description: "",
        type: "allDay",
      },
      {
        id: 27,
        date: "2023-09-27T19:15:00.000Z",
        title: "hello",
        description: "",
        type: "allDay",
      },
      {
        id: 28,
        date: "2023-09-28T18:30:00.000Z",
        title: "as",
        description: "as",
        type: "",
      },
    ],
  });
  //   const Token = dataSelector.access_Token;
  let iniSTR = "";
  //   if (Array.isArray(hotelsData)) {
  //     let count = 0;
  //     hotelsData.forEach((itm) => {
  //       iniSTR = count ? iniSTR + "," + itm.PropertyID : itm.PropertyID;
  //       count = count + 1;
  //     });
  //   }
  function CalChange(data) {
    let input = {
      userIDs: selectedUser,
      startDate: data.StartDate,
      endDate: data.EndDate,
      //   hotelIDs: SelectedPropertyIDs.SelectedPropertyID,
      activityStatus_Term: null,
    };
    // fetchAllActivities(input, data.StartDate, data.viewType);
  }
  //   const fetchAllActivities = async (input, date, viewtype) => {
  //     setLoading(true);

  //     let res = await GetListForCalendar(input, Token);

  //     if (res.status === 200) {
  //       if (res.data.success) {
  //         if (typeof res.data.data !== "undefined" && res.data.data) {
  //           setLoading(false);
  //           setCalOption({
  //             date: date,
  //             viewtype: viewtype,
  //             data: res.data.data.dataList1,
  //           });
  //         } else {
  //           setLoading(false);

  //           setCalOption({
  //             date: date,
  //             viewtype: viewtype,
  //             data: [],
  //           });
  //         }
  //       } else {
  //         setLoading(false);
  //         ToasterError(res.data.message);
  //       }
  //     } else {
  //       setLoading(false);
  //       ToasterError(res.data.message);
  //     }
  //   };

  //   const fetchUsers = async () => {
  //     let inputUser: any = {
  //       clientID: clientID,
  //       userName: "",
  //       displayName: "",
  //       emailID: "",
  //       gmailEmailID: "",
  //       fName: "",
  //       lName: "",
  //     };

  //     let res: any = await getUserListData(inputUser, Token);

  //     if (res.data.success) {
  //       if (res.data.data !== undefined && res.data.data !== null) {
  //         setUsers(
  //           res.data.data.dataList1 && res.data.data.dataList1.length
  //             ? res.data.data.dataList1.filter(
  //                 (obj) =>
  //                   obj.UserTypeID === "3d8522c8-ebff-470f-95dd-b8c2ad1b678f"
  //               )
  //             : []
  //         );
  //       } else {
  //         setUsers([]);
  //       }
  //     } else {
  //     }
  //   };
  const handleDateClick = (e) => {
    setShowModal(true);
  };

  //   useEffect(() => {
  //     getUserData();
  //     setUserSelect(userID);
  //     if (
  //       SelectedPropertyIDs.SelectedPropertyID.length &&
  //       SelectedPropertyIDs.SelectedPropertyID &&
  //       SelectedPropertyIDs.SelectedPropertyID !== ""
  //     ) {
  //       var date = new Date();
  //       var StartDate = new Date(date.getFullYear(), date.getMonth(), 1);

  //       var EndDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);

  //       let MultiUserID = [];
  //       for (let i = 0; i < selectedUser.length; i++) {
  //         MultiUserID.push(selectedUser[i].value);
  //       }

  //       let input = {
  //         userIDs: MultiUserID.toString(),
  //         startDate: StartDate,
  //         endDate: EndDate,
  //         hotelIDs: SelectedPropertyIDs.SelectedPropertyID,
  //         activityStatus_Term: null,
  //       };
  //       fetchAllActivities(input, StartDate, "Month");
  //     }
  //   }, [SelectedPropertyIDs.SelectedPropertyID, selectedUser]);

  //   const [UserListData, setUserListData]: any = useState([]);
  //   const [userSelect, setUserSelect]: any = useState(userID);

  const [UserListData, setUserListData] = useState([]);
  const [userSelect, setUserSelect] = useState("");

  //   async function getUserData() {
  //     let input: any = {
  //       clientID: clientID,
  //       userName: "",
  //       displayName: "",
  //       emailID: "",
  //       gmailEmailID: "",
  //       fName: "",
  //       lName: "",
  //     };
  //     let res: any = await getUserListData(input, Token);
  //     if (res.data.success) {
  //       if (res.data.data !== undefined && res.data.data !== null) {
  //         if (SelectedPropertyIDs.SelectedPropertyID) {
  //           setUserListData(res.data.data.dataList1);
  //         }
  //       }
  //     }
  //   }

  //   let initialUser: any = [
  //     {
  //       label: "Select User",
  //       value: "",
  //     },
  //     {
  //       label:
  //         dataSelector.auth_UserDetail.U_FName +
  //         dataSelector.auth_UserDetail.U_LName,
  //       value: userID,
  //     },
  //   ];

  //   UserListData !== undefined &&
  //     UserListData &&
  //     UserListData.length > 0 &&
  //     UserListData.map((item: any) => {
  //       initialUser.push({
  //         label: item.UserName,
  //         value: item.UserID,
  //       });
  //     });

  return (
    <>
      {/* <Row>
        <Col xs={6} className="mb-3">
          <FloatingLabel
            controlId="floatingSelectGrid"
            label=""
            className="calenderlist"
          >
            <Select
              className="basic-multi-select"
              placeholder="Select User"
              onChange={(e) => {
                setSelectedUser(e);
              }}
              isMulti={true}
              options={initialUser}
            />
          </FloatingLabel>
        </Col>
      </Row> */}
      <div className="meeting-catering-calendar">
        <CustomFullCalendar
          option={CalOption}
          changeCallBack={CalChange}
          setShowModal={setShowModal}
          showModal={showModal}
          viewDetail={viewDetail}
          eventId={eventId}
        />
      </div>
      {/* {loading ? (
        <div className="calender-loader-main">
          <PageLoader />
        </div>
      ) : (
        ""
      )} */}
      {showModal.addModal && alert("Add new Data")}
      {showModal.editModal && alert("Edit existing data")}

      {/* {modalShowActivity && (
        <AddActivity
          Activity={"MainActivity"}
          show={modalShowActivity}
        //   getEditActivitytdetails={fetchAllActivities}
          EditActivityId={EditActivityId}
          onHide={() => {
            setModalShowActivity(false);
            setEditActivityId("00000000-0000-0000-0000-000000000000");
          }}
        ></AddActivity>
      )}
      {showModal && (
        <ActivityCalenderModal
          showModal={showModal}
          hotelIds={iniSTR}
          setShowModal={setShowModal}
        />
      )} */}
    </>
  );
};

export default Calendar;
