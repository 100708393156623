import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Table } from "react-bootstrap";
import { commonService } from "../../../../utils/commonService";
import { apiCall } from "../../../../utils/axiosService";
import { API_URL } from "../../../../utils/apiRoutes";
import { apiResponse } from "../../../../utils/apiResponse";
import Loader from "../../../Loader/Loader";
import DatePicker from "react-datepicker";
import NoteIcon from "../../../../Assets/Images/CRM/notes.svg";
import downloadIcon from "../../../../Assets/Images/Operation/downloadIcon.svg";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import emailIcon from "../../../../Assets/Images/Email.svg";
import moment from "moment";

const HotelKeyReservation = ({
  setCompDataFromChild,
  rowIndex,
  colIndex,
  api,
  para,
  setting,
}) => {
  const tableData = [
    {
      id: 1,
      propertyCode: "4030",
      pullDateId: "0",
      AsOfDate: "2023-08-31",
      BookingInfo: "2023-06-02",
      ConfirmationNo: "0",
      GuestName: "0",
      ArrivalDate: "2023-06-02",
      Nights: "0",
      RoomType: "0",
      Source: "0",
      RateCode: "0",
      RoomRent: "$0",
      Status: "0",
      CancellationDate: "0",
      BookedBy: "0",
    },
    {
      id: 2,
      propertyCode: "4030",
      pullDateId: "0",
      AsOfDate: "2023-08-31",
      BookingInfo: "2023-06-02",
      ConfirmationNo: "0",
      GuestName: "0",
      ArrivalDate: "2023-06-02",
      Nights: "0",
      RoomType: "0",
      Source: "0",
      RateCode: "0",
      RoomRent: "$0",
      Status: "0",
      CancellationDate: "0",
      BookedBy: "0",
    },
    {
      id: 3,
      propertyCode: "4030",
      pullDateId: "0",
      AsOfDate: "2023-08-31",
      BookingInfo: "2023-06-02",
      ConfirmationNo: "0",
      GuestName: "0",
      ArrivalDate: "2023-06-02",
      Nights: "0",
      RoomType: "0",
      Source: "0",
      RateCode: "0",
      RoomRent: "$0",
      Status: "0",
      CancellationDate: "0",
      BookedBy: "0",
    },
    {
      id: 4,
      propertyCode: "4030",
      pullDateId: "0",
      AsOfDate: "2023-08-31",
      BookingInfo: "2023-06-02",
      ConfirmationNo: "0",
      GuestName: "0",
      ArrivalDate: "2023-06-02",
      Nights: "0",
      RoomType: "0",
      Source: "0",
      RateCode: "0",
      RoomRent: "$0",
      Status: "0",
      CancellationDate: "0",
      BookedBy: "0",
    },
    {
      id: 5,
      propertyCode: "4030",
      pullDateId: "0",
      AsOfDate: "2023-08-31",
      BookingInfo: "2023-06-02",
      ConfirmationNo: "0",
      GuestName: "0",
      ArrivalDate: "2023-06-02",
      Nights: "0",
      RoomType: "0",
      Source: "0",
      RateCode: "0",
      RoomRent: "$0",
      Status: "0",
      CancellationDate: "0",
      BookedBy: "0",
    },
    {
      id: 6,
      propertyCode: "4030",
      pullDateId: "0",
      AsOfDate: "2023-08-31",
      BookingInfo: "2023-06-02",
      ConfirmationNo: "0",
      GuestName: "0",
      ArrivalDate: "2023-06-02",
      Nights: "0",
      RoomType: "0",
      Source: "0",
      RateCode: "0",
      RoomRent: "$0",
      Status: "0",
      CancellationDate: "0",
      BookedBy: "0",
    },
    {
      id: 7,
      propertyCode: "4030",
      pullDateId: "0",
      AsOfDate: "2023-08-31",
      BookingInfo: "2023-06-02",
      ConfirmationNo: "0",
      GuestName: "0",
      ArrivalDate: "2023-06-02",
      Nights: "0",
      RoomType: "0",
      Source: "0",
      RateCode: "0",
      RoomRent: "$0",
      Status: "0",
      CancellationDate: "0",
      BookedBy: "0",
    },
    {
      id: 8,
      propertyCode: "4030",
      pullDateId: "0",
      AsOfDate: "2023-08-31",
      BookingInfo: "2023-06-02",
      ConfirmationNo: "0",
      GuestName: "0",
      ArrivalDate: "2023-06-02",
      Nights: "0",
      RoomType: "0",
      Source: "0",
      RateCode: "0",
      RoomRent: "$0",
      Status: "0",
      CancellationDate: "0",
      BookedBy: "0",
    },
    {
      id: 9,
      propertyCode: "4030",
      pullDateId: "0",
      AsOfDate: "2023-08-31",
      BookingInfo: "2023-06-02",
      ConfirmationNo: "0",
      GuestName: "0",
      ArrivalDate: "2023-06-02",
      Nights: "0",
      RoomType: "0",
      Source: "0",
      RateCode: "0",
      RoomRent: "$0",
      Status: "0",
      CancellationDate: "0",
      BookedBy: "0",
    },
    {
      id: 10,
      propertyCode: "4030",
      pullDateId: "0",
      AsOfDate: "2023-08-31",
      BookingInfo: "2023-06-02",
      ConfirmationNo: "0",
      GuestName: "0",
      ArrivalDate: "2023-06-02",
      Nights: "0",
      RoomType: "0",
      Source: "0",
      RateCode: "0",
      RoomRent: "$0",
      Status: "0",
      CancellationDate: "0",
      BookedBy: "0",
    },
  ];

  const [reservationData, setReservationData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [startRangeDate, setStartRangeDate] = useState();
  const [endRangeDate, setEndRangeDate] = useState();
  const startRangeDateRef = useRef();
  const endRangeDateRef = useRef();
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const defaultAsOfDateFromRTK = useSelector(
    (store) => store.headerProperty.defaultAsOfDate
  );
  const { snapshottitle, propertyCode, snapshotId } = useParams();
  const handleDateChange = (event) => {
    const [start, end] = event;
    let startSelectedDate = "",
      endSelectedDate = "";
    if (start) {
      let date = new Date(start);
      let month =
        date.getMonth() + 1 > 9
          ? date.getMonth() + 1
          : `0${date.getMonth() + 1}`;
      let dateFormat =
        date?.getDate() > 9 ? date.getDate() : `0${date.getDate()}`;
      startSelectedDate = `${date.getFullYear()}-${month}-${dateFormat}`;
      startRangeDateRef.current = startSelectedDate;
    }
    if (end) {
      let date = new Date(end);
      let month =
        date.getMonth() + 1 > 9
          ? date.getMonth() + 1
          : `0${date.getMonth() + 1}`;
      let dateFormat =
        date?.getDate() > 9 ? date.getDate() : `0${date.getDate()}`;
      endSelectedDate = `${date.getFullYear()}-${month}-${dateFormat}`;
      endRangeDateRef.current = endSelectedDate;
    }

    setStartRangeDate(event[0]);
    setEndRangeDate(event[1]);
    if (event[0] !== null && event[1] !== null) {
      // getReservationDetail();
    }
  };

  const getReservationDetail = async () => {
    setLoading(true);
    let payload = {
      AsOfDate: para?.asOfDate,
      propertyCode: para?.propertyCode,
    };
    if (startRangeDateRef.current && endRangeDateRef.current) {
      payload = {
        ...payload,
        startDate: startRangeDateRef.current,
        endDate: endRangeDateRef.current,
      };
    }
    let reservationResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.SnapShot +
          API_URL.WIDGET_MODULE.HOTEL_KEY_RESERVATION,
        body: payload,
      },
      false
    );

    let response = apiResponse(false, reservationResponse);

    if (response?.isValidate) {
      setLoading(false);
      setReservationData(response?.data?.data);
    }

    if (!response?.isValidate) {
      setLoading(false);
      setReservationData(null);
    }
  };

  useEffect(() => {
    if (api === 0) {
      setReservationData(tableData);
    } else {
      if (para?.asOfDate) {
        getReservationDetail();
      }
    }
  }, [para?.asOfDate]);

  const [tblids, settblids] = useState({
    tblHotelKeyReservation: commonService.GenerateGUID(),
  });

  return (
    <div className="widget-container">
      {api !== 0 && (
        <div className="gap-1 widget-container-menu">
          <Button className="heading-menu-btns" type="button">
            <img src={NoteIcon} alt="menuIcon" />
          </Button>
          <Button className="heading-menu-btns" type="button" title="Send Mail">
            <img
              src={emailIcon}
              alt="emailIcon"
              onClick={() => {
                commonService.fnSendWidgetMail(
                  tblids.tblHotelKeyReservation,
                  "Hotel Key Reservation",
                  snapshotId,
                  defaultAsOfDateFromRTK,
                  "",
                  para?.widgetId
                );
              }}
            />
          </Button>
          <Button className="heading-menu-btns" type="button">
            <img
              src={downloadIcon}
              alt="menuIcon"
              import={tblids.tblHotelKeyReservation}
              onClick={() =>
                commonService.fnExportInCSV(
                  tblids.tblHotelKeyReservation,
                  // "Hotel Key Reservation"
                  `${para?.propertyCode}-Hotel Key Reservation-${moment(
                    new Date()
                  ).format("MMDDYYYY")}`
                )
              }
            />
          </Button>
        </div>
      )}
      <div className="ViewPropertySetupTable">
        <div className="stripped__table">
          <Table
            responsive
            id={tblids.tblHotelKeyReservation}
            className="positionStaticTable"
          >
            <thead>
              <tr>
                <th
                  className="width175"
                  onClick={() => {
                    setOpenDatePicker(true);
                  }}
                >
                  Date{" "}
                  <DatePicker
                    onChange={(event) => handleDateChange(event)}
                    startDate={startRangeDate}
                    endDate={endRangeDate}
                    selectsRange
                    monthsShown={2}
                    open={openDatePicker}
                    shouldCloseOnSelect={false}
                  >
                    <div className="w-100" style={{ display: "inline-block" }}>
                      <div className="content-inside-calendar">
                        <p>
                          {startRangeDate
                            ? commonService.getDateInFormat(startRangeDate)
                            : ""}
                          {startRangeDate && endRangeDate ? " - " : ""}
                          {endRangeDate
                            ? commonService.getDateInFormat(endRangeDate)
                            : ""}
                        </p>
                        <div className="d-flex align-items-center gap-2">
                          <button
                            className="secondary-btn"
                            onClick={(e) => {
                              e.stopPropagation();
                              setStartRangeDate("");
                              setEndRangeDate("");
                              setOpenDatePicker(false);
                            }}
                          >
                            Cancel
                          </button>
                          <button
                            disabled={
                              startRangeDate && endRangeDate ? false : true
                            }
                            onClick={(e) => {
                              e.stopPropagation();
                              if (
                                startRangeDateRef.current &&
                                endRangeDateRef.current
                              ) {
                                getReservationDetail();
                              }

                              setOpenDatePicker(false);
                            }}
                            className="primary-btn"
                          >
                            Apply
                          </button>
                        </div>
                      </div>
                    </div>
                  </DatePicker>
                  {startRangeDate && endRangeDate && (
                    <span
                      style={{ display: "none" }}
                    >{`${commonService.getDateInFormat(
                      startRangeDate
                    )} - ${commonService.getDateInFormat(endRangeDate)}`}</span>
                  )}
                </th>
                <th colSpan={14}></th>
              </tr>
              <tr>
                {/* <th className="width100">property Code</th>
                <th className="width100">pull Date Id</th> */}
                <th className="width100 text-start">As Of Date</th>
                <th className="width100">Booking Info</th>
                <th className="width100 text-center">Confirmation No</th>
                <th className="width100 text-center">Guest Name</th>
                <th className="width100 ">Arrival Date</th>
                <th className="width100 text-center">Nights</th>
                <th className="width100 text-center">Room Type</th>
                <th className="width100 text-center">Source</th>
                <th className="width100 text-center">Rate Code</th>
                <th className="width100 text-center">Room Rent</th>
                <th className="width100 text-center">Status</th>
                <th className="width100">Cancellation Date</th>
                <th className="width100 text-center">Booked By</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr style={{ height: "80px", position: "relative" }}>
                  <Loader />
                </tr>
              ) : reservationData && reservationData?.length > 0 ? (
                reservationData?.map((item, index) => (
                  <tr key={item?.id} className="shadowOnHover">
                    {/* <td>{item?.propertyCode}</td>
                    <td>{item?.pullDateId}</td> */}
                    <td>{commonService.getDateInFormat(item?.AsOfDate)}</td>
                    <td>
                      {item?.BookingInfo
                        ? commonService.getDateInFormat(item?.BookingInfo)
                        : item?.BookingInfo}
                    </td>
                    <td className="text-center">{item?.ConfirmationNo}</td>
                    <td className="text-center">{item?.GuestName}</td>
                    <td>
                      {item?.ArrivalDate
                        ? commonService.getDateInFormat(item?.ArrivalDate)
                        : item?.ArrivalDate}
                    </td>
                    <td className="text-center">{item?.Nights}</td>
                    <td className="text-center">{item?.RoomType}</td>
                    <td className="text-center">{item?.Source}</td>
                    <td className="text-center">{item?.RateCode}</td>
                    <td className="text-center">{item?.RoomRent}</td>
                    <td className="text-center">{item?.Status}</td>
                    <td>
                      {item?.CancellationDate
                        ? commonService.getDateInFormat(item?.CancellationDate)
                        : item?.CancellationDate}
                    </td>
                    <td className="text-center">{item?.BookedBy}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={15} className="text-center">
                    No Data Found
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default HotelKeyReservation;
