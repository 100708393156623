import { createSlice } from "@reduxjs/toolkit";

const callApiAgainSlice = createSlice({
  name: "callApi",
  initialState: {
    snapShotApi: false,
  },
  reducers: {
    callApiOfSnapShot: (state, action) => {
      state.snapShotApi = !state.snapShotApi;
    },
  },
});

export const { callApiOfSnapShot } = callApiAgainSlice.actions;

export default callApiAgainSlice.reducer;
