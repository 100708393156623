import React, { useEffect, useRef, useState } from "react";
import { Row, Form, Col, Button, Container } from "react-bootstrap";
import pdfIcon from "../../../Assets/Images/CRM/pdf.svg";
import { commonService } from "../../../utils/commonService";
import DatePicker from "react-datepicker";
import { API_URL } from "../../../utils/apiRoutes";
import { validationRule } from "../../../utils/constant";
import { hotelListCommon } from "../Hotels/HotelListCommon";
import { AccountListCommon } from "../Contacts/AccountListCommon";
import { checkValidation } from "../../../utils/helper";
import { apiCall } from "../../../utils/axiosService";
import { apiResponse } from "../../../utils/apiResponse";
import { successToastMessage } from "../../../utils/toastMessage";
import Select from "react-select";
import filterIcon from "../../../Assets/Images/CRM/filter.png";
import Loader from "../../Loader/Loader";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const SalesFunnelGridReport = ({ title }) => {
  const [moduleWisePropertyDropDown, setModuleWisePropertyDropDown] = useState(
    []
  );
  const [selectedModuleWiseProperty, setSelectedModuleWiseProperty] = useState(
    []
  );
  const propertyIdList = useRef("");
  const useridList = useRef("");
  const isDefaultApiCalledRef = useRef(false);
  const [selectUser, setSelectUser] = useState([]);
  const [userValue, setUserValue] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hotelList, setHotelList] = useState(null);
  const [accountList, setAccountList] = useState(null);
  const userDetails = commonService.getLoginUserData();
  const salesFunnelStatus = ["Prospect", "Tentative", "Definite"];
  const [errorMessage, setErrorMessage] = useState(false);
  const setPdfRef = useRef("");
  const [showPdf, setShowPdf] = useState(false);
  const [showAllReport, setshowAllReport] = useState(false);

  const handleshowAllReport = () => {
    setshowAllReport(!showAllReport);
  };

  const yearsArr = [
    ...commonService.getPastYearForDropDown(10),
    ...commonService.getFutureYearForDropDown(10),
    new Date().getFullYear(),
  ]?.sort(function (a, b) {
    return a - b;
  });

  const crmPropertyDetailFromRTK = useSelector(
    (store) => store.headerProperty.crmPropertyDetail
  );

  let propertyIdPayload = "";
  if (crmPropertyDetailFromRTK) {
    if (crmPropertyDetailFromRTK?.propertyid === "") {
      let propertyPayload = crmPropertyDetailFromRTK?.allPropertyId;
      propertyIdPayload = propertyPayload.slice(0, -1);
    } else {
      propertyIdPayload = crmPropertyDetailFromRTK?.propertyid?.toString();
    }
  }

  let validationObj = {
    clientid: userDetails?.clientid ? userDetails?.clientid : 0,
    propertyID: "",
    userid: "",
    year: new Date().getFullYear(),
    status: salesFunnelStatus[0],

    validationRule: [
      {
        fieldName: "propertyID",
        rule: validationRule.required,
        message: "Property Id required",
      },
      {
        fieldName: "userid",
        rule: validationRule.required,
        message: "User selection required",
      },
      {
        fieldName: "year",
        rule: validationRule.required,
        message: "Year is required",
      },
      {
        fieldName: "status",
        rule: validationRule.required,
        message: "Status is required",
      },
    ],
    errorObj: {},
    isValidate: true,
  };
  const [salesFunnelData, setSalesFunnelData] = useState(validationObj);
  const salesFunnelDataRef = useRef(salesFunnelData);

  const getHotelList = async () => {
    let response = await hotelListCommon(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.CRM +
          API_URL.PROPERTY_TERM.GET_PROPERTY_LIST_BY_USER_ID,
        body: {
          id: userDetails?.userid,
        },
      },
      false
    );

    if (response?.isValidate) {
      setHotelList(response?.data);
      let propertyListarry = [];
      response?.data?.map((item) => {
        propertyListarry = [
          ...propertyListarry,
          {
            label: item?.propertyname,
            value: item?.propertyid,
          },
        ];
      });
      setModuleWisePropertyDropDown(propertyListarry);
      if (crmPropertyDetailFromRTK?.propertyid != "") {
        setSelectedModuleWiseProperty([
          {
            label: crmPropertyDetailFromRTK?.propertyname,
            value: crmPropertyDetailFromRTK?.propertyid,
          },
        ]);

        propertyIdList.current = `${crmPropertyDetailFromRTK?.propertyid}`;
        salesFunnelDataRef.current.propertyID = propertyIdList.current;
        setSalesFunnelData(salesFunnelDataRef.current);
      }
    }
  };

  const getUserByPropertyList = async (propertyId) => {
    // if (propertyId !== 0) {
    let accountResponse = await AccountListCommon(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.UserManagement +
          API_URL.USER_ROLE_PROPERTY.GET_USER_LIST_BY_MODULE_MULTI_PROPERTY,
        body: {
          propertyid: salesFunnelData.propertyID.toString(),
          modulename: "crm",
        },
      },
      false
    );

    if (accountResponse?.isValidate) {
      setAccountList(accountResponse?.data);
      let userDropList = [];
      let userId = [];
      accountResponse?.data?.map((item) => {
        userId = [...userId, item?.userid];
        userDropList = [
          ...userDropList,
          {
            label: item?.displayname,
            value: item?.userid,
          },
        ];
      });
      setSelectUser(userDropList);
      setUserValue(userDropList);
      useridList.current = userId?.join(",");
      setSalesFunnelData({
        ...salesFunnelData,
        userid: useridList.current,
      });

      // if (crmPropertyDetailFromRTK?.propertyid != "") {
      //   useridList.current = userId?.join(",");
      //   setSalesFunnelData({
      //     ...salesFunnelData,
      //     userid: useridList.current,
      //   });
      // }
    }
    // }
  };

  const handlePropertySelection = (e) => {
    if (e?.length <= 0) {
      setSelectedModuleWiseProperty([]);
    }
    setSelectedModuleWiseProperty(e);
    let propertyList = [];
    e?.map((item) => {
      propertyList = [...propertyList, item?.value?.toString()];
      propertyIdList.current = propertyList?.join(",");
      setSalesFunnelData({
        ...salesFunnelData,
        propertyID: propertyIdList.current,
      });
      // getUserByPropertyList(propertyIdList.current);
    });
  };
  // select of user
  const HandleUserSelection = (e) => {
    if (e.length <= 0) {
      setUserValue([]);
    }
    setUserValue(e);
    let userListItm = [];
    e?.map((item) => {
      userListItm = [...userListItm, item?.value?.toString()];
      useridList.current = userListItm?.join(",");
      setSalesFunnelData({
        ...salesFunnelData,
        userid: useridList.current,
      });
    });
  };

  const iframeloader = () => {
    setLoading(false);
  };
  const GenerateReportbtn = async () => {
    setShowPdf(false);
    setLoading(true);
    let validation = checkValidation(salesFunnelData);

    if (!validation.isValidate) {
      setSalesFunnelData(validation);
      setLoading(false);
      return;
    }

    let ActivitiesReport = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.CRM +
          API_URL.CRM_REPORT.SALES_FUNNEL_GRID_REPORT,
        body: {
          propertyID: salesFunnelData.propertyID,
          userid: salesFunnelData.userid.toString(),
          year: parseInt(salesFunnelData.year),
          status: salesFunnelData.status,
        },
      },
      false
    );

    let response = apiResponse(false, ActivitiesReport);

    if (response?.isValidate) {
      isDefaultApiCalledRef.current = true;
      // setLoading(false);
      setshowAllReport(false);
      setErrorMessage(false);
      // successToastMessage(response?.message);
      setPdfRef.current =
        process.env.REACT_APP_APIBASEPATH_CRM_REPORT_PDF + response.data.data;
      setShowPdf(true);
    }
    if (!response?.isValidate) {
      isDefaultApiCalledRef.current = true;
      setshowAllReport(true);
      setLoading(false);
      setErrorMessage(true);
    }
  };

  useEffect(() => {
    if (crmPropertyDetailFromRTK?.propertyid === "") {
      isDefaultApiCalledRef.current = true;
      setshowAllReport(true);
    }
    getHotelList();
  }, []);

  useEffect(() => {
    if (salesFunnelData.propertyID !== "") {
      if (!isDefaultApiCalledRef.current && salesFunnelData.userid !== "") {
        GenerateReportbtn();
      }
    }
  }, [salesFunnelData.propertyID, salesFunnelData.userid]);

  useEffect(() => {
    if (salesFunnelData.propertyID !== "") {
      getUserByPropertyList();
    }
  }, [salesFunnelData.propertyID]);

  return (
    <div className="all-reports-main ToolsDisplacementCalcMain">
      <Container fluid>
        <Row>
          <Col xl={12}>
            <div className="ProspectDashboardTitleWraper  d-flex justify-content-between align-items-center marginBottom20">
              <h2 className="d-flex align-items-center gap-1">
                <Link to="/crm/dashboard">Dashboard - </Link>{" "}
                <span className="tab-title-main">{title}</span>
              </h2>
              <div className="heading-menu-btns">
                <img
                  src={filterIcon}
                  alt="filterIcon"
                  title="Filter Report"
                  className="cursorPointer report-filter"
                  onClick={handleshowAllReport}
                />
              </div>
            </div>
          </Col>
        </Row>

        {showAllReport && (
          <div className="displacementCalcWrapper width400">
            <div className="from-groups no-border-break mb-2 small-height-select">
              <Form.Label>Select Property</Form.Label>
              {/* <Form.Select
              aria-label="Default select example"
              value={salesFunnelData?.propertyID}
              onChange={(e) => {
                setSalesFunnelData(
                  {
                    ...salesFunnelData,
                    propertyID: e.target.value,
                  },

                  getUserByPropertyList(e.target.value)
                );
              }}
            >
              <option value={0}>Select Property</option>
              {hotelList?.map((item, index) => (
                <option key={index} value={item?.propertyid}>
                  {item?.propertyname}
                </option>
              ))}
            </Form.Select> */}
              <Select
                isMulti
                value={selectedModuleWiseProperty}
                options={moduleWisePropertyDropDown}
                onChange={(e) => {
                  handlePropertySelection(e);
                }}
              />
              {salesFunnelData?.errorObj?.propertyID &&
                salesFunnelData?.propertyID === "" && (
                  <small className="text-danger">
                    {salesFunnelData?.errorObj?.propertyID}
                  </small>
                )}
            </div>
            <div className="from-groups no-border-break mb-2 small-height-select">
              <Form.Label>Select User</Form.Label>
              {/* <Form.Select
              aria-label="Default select example"
              value={salesFunnelData?.userid}
              onChange={(e) => {
                setSalesFunnelData({
                  ...salesFunnelData,
                  userid: e.target.value,
                });
              }}
            >
              <option value={0}>Select User</option>
              {accountList?.map((item, index) => (
                <option key={index} value={item?.userid}>
                  {item?.displayname}
                </option>
              ))}
            </Form.Select> */}
              <Select
                isMulti
                value={userValue}
                options={selectUser}
                onChange={(e) => {
                  HandleUserSelection(e);
                }}
              />
              {salesFunnelData?.errorObj?.userid &&
                salesFunnelData?.userid === "" && (
                  <small className="text-danger">
                    {salesFunnelData?.errorObj?.userid}
                  </small>
                )}
            </div>
            <div className="startFrom from-groups no-border-break mb-2">
              <Form.Label htmlFor="startDate">Year</Form.Label>

              <Form.Select
                value={salesFunnelData.year}
                aria-label="Default select example"
                className="blue-colored-select"
                onChange={(e) => {
                  setSalesFunnelData({
                    ...salesFunnelData,
                    year: e.target.value,
                  });
                }}
              >
                {yearsArr?.map((item, index) => (
                  <option key={index} value={item}>
                    {item}
                  </option>
                ))}
              </Form.Select>
              {salesFunnelData?.errorObj?.year &&
                salesFunnelData?.year === "" && (
                  <small className="text-danger">
                    {salesFunnelData?.errorObj?.year}
                  </small>
                )}
            </div>
            <div className="from-groups no-border-break mb-4 small-height-select">
              <Form.Label htmlFor="endDate">
                Sales Opportunity Status
              </Form.Label>

              <Form.Select
                value={salesFunnelData.status}
                aria-label="Default select example"
                className="blue-colored-select"
                onChange={(e) => {
                  setSalesFunnelData({
                    ...salesFunnelData,
                    status: e.target.value,
                  });
                }}
              >
                {salesFunnelStatus?.map((item, index) => (
                  <option key={index} value={item}>
                    {item}
                  </option>
                ))}
              </Form.Select>
              {salesFunnelData?.errorObj?.status &&
                salesFunnelData?.status === "" && (
                  <small className="text-danger">
                    {salesFunnelData?.errorObj?.status}
                  </small>
                )}
            </div>
            <div className="d-flex gap-3 align-items-center justify-content-end ">
              {loading ? (
                <button
                  className="default-btn primary-btn d-flex gap-1 align-items-center"
                  type="button"
                  disabled
                >
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Loading...
                </button>
              ) : (
                <button
                  className="default-btn primary-btn d-flex gap-1 align-items-center"
                  onClick={() => {
                    GenerateReportbtn();
                  }}
                >
                  <span className="revpak-icon">
                    <img src={pdfIcon} alt="" />
                  </span>
                  <span>Generate Report</span>
                </button>
              )}
            </div>
          </div>
        )}
        <p className="text-center">{errorMessage && " No Record Found"}</p>
        {loading && <Loader />}
        <div className="pdf-is-showing-here mt-3">
          {showPdf && setPdfRef.current && (
            <iframe
              onLoad={iframeloader}
              title="Report Doc"
              src={setPdfRef.current}
              width="100%"
              height="800px"
            />
          )}
        </div>
        {/* {loading ? (
          <Loader />
        ) : (
          <div className="pdf-is-showing-here mt-3">
            {showPdf && (
              <iframe
                title="Report Doc"
                src={setPdfRef.current}
                width="100%"
                height="800px"
              />
            )}
          </div>
        )} */}
      </Container>
    </div>
  );
};

export default SalesFunnelGridReport;
