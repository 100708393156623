import React from "react";
import { NavLink } from "react-router-dom";
import accounting from "../../Assets/Images/Home-side-bar/Accounting.svg";
import crm from "../../Assets/Images/Home-side-bar/CRM.svg";
import configuration from "../../Assets/Images/Home-side-bar/Configuration.svg";
import kms from "../../Assets/Images/Home-side-bar/KMS.svg";
import chart from "../../Assets/Images/Home-side-bar/chart.svg";
import exitIcon from "../../Assets/Images/Home-side-bar/exit.svg";
import formIcon from "../../Assets/Images/Home-side-bar/fi-rr-form.svg";
import homeIcon from "../../Assets/Images/Home-side-bar/home-icon.svg";
import people from "../../Assets/Images/Home-side-bar/people.svg";
import operation from "../../Assets/Images/Home-side-bar/Operation.svg";

function HomeSidebar() {
    return (
        <aside className="home-aside">
            <ul className="list-unstyled p-0 m-0">
                <li>
                    <NavLink to="/dashboard">
                        <img src={homeIcon} alt="homeIcon"  title="Dashboard"/>
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/configuration/configuration"  title="Configuration">
                        <img src={configuration} alt="configuration" />
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/revenue/revenue-management-dashboard"  title="Revenue Management">
                        <img src={chart} alt="chart" />
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/operation/dashboard"  title="Operation">
                        <img src={operation} alt="operation" />
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/crm/dashboard" title="CRM">
                        <img src={crm} alt="crm" />
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/">
                        <img src={accounting} alt="accounting" />
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/">
                        <img src={people} alt="people" />
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/">
                        <img src={kms} alt="kms" />
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/">
                        <img src={formIcon} alt="formIcon" />
                    </NavLink>
                </li>
                {/* <li>
            <NavLink to="/">
              <img src={exitIcon} alt="exitIcon" />
            </NavLink>
          </li> */}
            </ul>
        </aside>
    );
}

export default HomeSidebar;
