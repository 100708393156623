import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Container, Form, Row, Table } from "react-bootstrap";
import { apiResponse } from "../../../../utils/apiResponse";
import { apiCall } from "../../../../utils/axiosService";
import { API_URL } from "../../../../utils/apiRoutes";
import { commonService } from "../../../../utils/commonService";
import NoteIcon from "../../../../Assets/Images/CRM/notes.svg";
import downloadIcon from "../../../../Assets/Images/Operation/downloadIcon.svg";
import { compName } from "../../../../utils/constant";
import WidgetDetailModal from "../../WidgetDetailModal";
import Loader from "../../../Loader/Loader";
import infoIcon from "../../../../Assets/Images/Action Icons/infoIcon..svg";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import emailIcon from "../../../../Assets/Images/Email.svg";
import Comments from "../Comments/Comment";
import { FixColumn } from "../../../../utils/dataTable";
import moment from "moment";
import DatePicker from "react-datepicker";

const CancellationDayByDay = ({
  setCompDataFromChild,
  rowIndex,
  colIndex,
  api,
  para,
  setting,
}) => {
  //   let filterObj = {
  //     marketSegment: {},
  //     roomType: {},
  //     company: {},
  //     rateCode: {},
  //   };

  //   const filterRef = useRef(filterObj);
  const yearsArr = [
    ...commonService.getPastYearForDropDown(10),
    ...commonService.getFutureYearForDropDown(10),
    new Date().getFullYear(),
  ]?.sort(function (a, b) {
    return a - b;
  });
  const tableData = [
    {
      StayDate: new Date(),
      GuestName: "-",
      MarketSegment: "-",
      RateCode: "-",
      RoomType: "-",
      Company: "-",
      Cancellation_leadtime: 0,
      Rate: "0",
      ADR: "0",
      diff: "0",
    },
    {
      StayDate: new Date(),
      GuestName: "-",
      MarketSegment: "-",
      RateCode: "-",
      RoomType: "-",
      Company: "-",
      Cancellation_leadtime: 0,
      Rate: "0",
      ADR: "0",
      diff: "0",
    },
    {
      StayDate: new Date(),
      GuestName: "-",
      MarketSegment: "-",
      RateCode: "-",
      RoomType: "-",
      Company: "-",
      Cancellation_leadtime: 0,
      Rate: "0",
      ADR: "0",
      diff: "0",
    },
    {
      StayDate: new Date(),
      GuestName: "-",
      MarketSegment: "-",
      RateCode: "-",
      RoomType: "-",
      Company: "-",
      Cancellation_leadtime: 0,
      Rate: "0",
      ADR: "0",
      diff: "0",
    },
    {
      StayDate: new Date(),
      GuestName: "-",
      MarketSegment: "-",
      RateCode: "-",
      RoomType: "-",
      Company: "-",
      Cancellation_leadtime: 0,
      Rate: "0",
      ADR: "0",
      diff: "0",
    },
    {
      StayDate: new Date(),
      GuestName: "-",
      MarketSegment: "-",
      RateCode: "-",
      RoomType: "-",
      Company: "-",
      Cancellation_leadtime: 0,
      Rate: "0",
      ADR: "0",
      diff: "0",
    },
    {
      StayDate: new Date(),
      GuestName: "-",
      MarketSegment: "-",
      RateCode: "-",
      RoomType: "-",
      Company: "-",
      Cancellation_leadtime: 0,
      Rate: "0",
      ADR: "0",
      diff: "0",
    },
    {
      StayDate: new Date(),
      GuestName: "-",
      MarketSegment: "-",
      RateCode: "-",
      RoomType: "-",
      Company: "-",
      Cancellation_leadtime: 0,
      Rate: "0",
      ADR: "0",
      diff: "0",
    },
    {
      StayDate: new Date(),
      GuestName: "-",
      MarketSegment: "-",
      RateCode: "-",
      RoomType: "-",
      Company: "-",
      Cancellation_leadtime: 0,
      Rate: "0",
      ADR: "0",
      diff: "0",
    },
    {
      StayDate: new Date(),
      GuestName: "-",
      MarketSegment: "-",
      RateCode: "-",
      RoomType: "-",
      Company: "-",
      Cancellation_leadtime: 0,
      Rate: "0",
      ADR: "0",
      diff: "0",
    },
  ];

  let monthWithValue = [
    {
      name: "January",
      value: "01",
    },
    {
      name: "February",
      value: "02",
    },
    {
      name: "March",
      value: "03",
    },
    {
      name: "April",
      value: "04",
    },
    {
      name: "May",
      value: "05",
    },
    {
      name: "June",
      value: "06",
    },
    {
      name: "July",
      value: "07",
    },
    {
      name: "August",
      value: "08",
    },
    {
      name: "September",
      value: "09",
    },
    {
      name: "October",
      value: "10",
    },
    {
      name: "November",
      value: "11",
    },
    {
      name: "December",
      value: "12",
    },
  ];
  let filterObj = {
    year: `${new Date().getFullYear()}`,
    month: `${
      new Date().getMonth() + 1 <= 9
        ? `0${new Date().getMonth() + 1}`
        : `${new Date().getMonth() + 1}`
    }`,
    marketSegment: "",
    // marketSegmentValue: "",
    roomType: "",
    // roomTypeValue: "",
    company: "",
    // companyValue: "",
    rateCode: "",
    // rateCodeValue: "",
  };

  const filterRef = useRef({
    marketSegmentValue: "",
    roomTypeValue: "",
    companyValue: "",
    rateCodeValue: "",
  });
  const [filterData, setFilterData] = useState(filterObj);
  const [filterFromDb, setFilterFromDb] = useState(null);
  const [cancellationData, setCancellationData] = useState(null);
  const [loading, setLoading] = useState(false);
  const isFilterApiCall = useRef();
  const [widgetModal, setWidgetModal] = useState(false);
  const widgetDetailRef = useRef();
  const [showNoteSidebar, setShowNoteSidebar] = useState(false);
  const propertyDetailFromRTK = useSelector(
    (store) => store.headerProperty.propertyDetail
  );
  const defaultAsOfDateFromRTK = useSelector(
    (store) => store.headerProperty.defaultAsOfDate
  );
  const { snapshottitle, propertyCode, snapshotId } = useParams();
  const date = new Date();
  const startDate = commonService.getDateInDBFormat(
    new Date(date.getFullYear(), date.getMonth(), 1)
  );
  const endDate = commonService.getDateInDBFormat(
    new Date(date.getFullYear(), date.getMonth() + 1, 0)
  );

  const [stayStartDate, setStayStartDate] = useState(new Date(startDate));
  const [stayEndDate, setStayEndDate] = useState(new Date(endDate));
  const closeNoteSideBar = () => {
    setShowNoteSidebar(false);
  };

  const closeWidgetModal = () => {
    setWidgetModal(false);
  };
  const getKeyFromObj = (obj) => {
    let selectedValue = [];

    Object?.entries(obj)?.map(([key, value]) => {
      if (value) {
        selectedValue = [...selectedValue, key];
      }
    });

    return selectedValue?.toString();
  };

  const getFilterData = async () => {
    let filterDataResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.SnapShot +
          API_URL.SNAPSHOT_ACCOUNT_WIDGET.GET_FILTER,
        body: { propertyCode: para?.propertyCode },
      },
      false
    );

    let response = apiResponse(false, filterDataResponse);

    if (response?.isValidate) {
      let dataFromApi = response?.data?.data;
      let companyObj = "",
        marketSegment = "",
        rateCode = "",
        roomTypeObj = "";

      dataFromApi?.Company?.map((item, index) => {
        companyObj = companyObj + `${index === 0 ? "" : ","}${item?.Company}`;
      });

      dataFromApi?.RoomType?.map((item, index) => {
        roomTypeObj =
          roomTypeObj + `${index === 0 ? "" : ","}${item?.roomtypecode}`;
      });

      dataFromApi?.Marketsegments?.map((item, index) => {
        marketSegment =
          marketSegment +
          `${index === 0 ? "" : ","}${item?.market_segmentation}`;
      });

      dataFromApi?.RateCode?.map((item, index) => {
        rateCode = rateCode + `${index === 0 ? "" : ","}${item?.RateCode}`;
      });
      isFilterApiCall.current = true;
      setFilterData({
        ...filterData,
        marketSegment: "ALL",
        // marketSegmentValue: marketSegment,
        roomType: "ALL",
        // roomTypeValue: roomTypeObj,
        company: "ALL",
        // companyValue: companyObj,
        rateCode: "ALL",
        // rateCodeValue: rateCode,
      });
      filterRef.current.companyValue = companyObj;
      filterRef.current.marketSegmentValue = marketSegment;
      filterRef.current.roomTypeValue = roomTypeObj;
      filterRef.current.rateCodeValue = rateCode;
      setFilterFromDb(response?.data?.data);
    }
  };

  const getCancellationData = async () => {
    setLoading(true);
    let cancellationResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.SnapShot +
          API_URL.CANCELLATION.GET_CANCELLATION_DAY_BY_DAY,
        body: {
          propertyCode: para?.propertyCode,
          AsOfDate: para?.asOfDate,
          // year: filterData?.year,
          // month: filterData?.month,
          startDate: stayStartDate,
          endDate: stayEndDate,
          MarketSegment: filterRef.current?.marketSegmentValue,
          RoomType: filterRef.current?.roomTypeValue,
          Company: filterRef.current?.companyValue,
          RateCode: filterRef.current?.rateCodeValue,
        },
      },
      false
    );

    let response = apiResponse(false, cancellationResponse);

    if (response?.isValidate) {
      setCancellationData(response?.data?.data);
      setLoading(false);
    }

    if (!response?.isValidate) {
      setCancellationData(null);
      setLoading(false);
    }
  };

  const callBothApi = async () => {
    await getFilterData();
    await getCancellationData();
  };

  useEffect(() => {
    if (api === 0) {
      setCancellationData(tableData);
    } else {
      if (para?.asOfDate) {
        if (isFilterApiCall.current === true) {
          if (stayStartDate && stayEndDate) {
            getCancellationData();
          }
        } else {
          if (stayStartDate && stayEndDate) {
            callBothApi();
          }
        }
      }
    }
  }, [para?.asOfDate, filterData, stayEndDate]);

  const [tblids, settblids] = useState({
    dayByDayCancellationTable: commonService.GenerateGUID(),
  });

  FixColumn(tblids.dayByDayCancellationTable, [1]);

  const handleStayDateChange = (dates) => {
    const [start, end] = dates;
    setStayStartDate(start);
    setStayEndDate(end ? end : "");
  };

  return (
    <div className="widget-container">
      <Container fluid>
        <Col xl={12}>
          <Row>
            {/* <Col xl={2}>
              <div className="from-groups no-border-break">
                <Form.Label>Year</Form.Label>
                <Form.Select
                  value={filterData.year}
                  onChange={(e) => {
                    setFilterData({
                      ...filterData,
                      year: e.target.value,
                    });
                  }}
                >
                  {yearsArr?.map((item, index) => (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  ))}
                </Form.Select>
              </div>
            </Col>
            <Col xl={2}>
              <div className="from-groups no-border-break">
                <Form.Label>Month</Form.Label>
                <Form.Select
                  value={filterData.month}
                  onChange={(e) => {
                    setFilterData({
                      ...filterData,
                      month: e.target.value,
                    });
                  }}
                >
                  {monthWithValue?.map((item, index) => (
                    <option key={index} value={item?.value}>
                      {item?.name}
                    </option>
                  ))}
                </Form.Select>
              </div>
            </Col> */}
            <div className="form-groups from-groups no-border-break meghaDatePicker width225">
              <Form.Label>Date</Form.Label>
              <DatePicker
                onChange={handleStayDateChange}
                startDate={stayStartDate}
                endDate={stayEndDate}
                selectsRange
                monthsShown={2}
              />
            </div>
            <Col xl={2}>
              <div className="from-groups no-border-break">
                <Form.Label>Market Segment</Form.Label>
                <Form.Select
                  value={filterData.marketSegment}
                  onChange={(e) => {
                    if (e.target.value === "ALL") {
                      let payloadString = "";
                      filterFromDb?.Marketsegments?.map((item, index) => {
                        payloadString =
                          payloadString +
                          `${index === 0 ? "" : ","}${
                            item?.market_segmentation
                          }`;
                      });
                      setFilterData({
                        ...filterData,
                        marketSegment: "ALL",
                      });
                      filterRef.current.marketSegmentValue = payloadString;
                    } else {
                      setFilterData({
                        ...filterData,
                        marketSegment: e.target.value,
                      });
                      filterRef.current.marketSegmentValue = e.target.value;
                    }
                  }}
                >
                  <option value="ALL">ALL</option>
                  {filterFromDb?.Marketsegments?.map((item, index) => (
                    <option key={index} value={item?.market_segmentation}>
                      {item?.market_segmentation}
                    </option>
                  ))}
                </Form.Select>
              </div>
            </Col>
            <Col xl={2}>
              <div className="from-groups no-border-break">
                <Form.Label>Room Type</Form.Label>
                <Form.Select
                  value={filterData.roomType}
                  onChange={(e) => {
                    if (e.target.value === "ALL") {
                      let payloadString = "";
                      filterFromDb?.RoomType?.map((item, index) => {
                        payloadString =
                          payloadString +
                          `${index === 0 ? "" : ","}${item?.roomtypecode}`;
                      });
                      setFilterData({
                        ...filterData,
                        roomType: "ALL",
                      });
                      filterRef.current.roomTypeValue = payloadString;
                    } else {
                      setFilterData({
                        ...filterData,
                        roomType: e.target.value,
                      });
                      filterRef.current.roomTypeValue = e.target.value;
                    }
                  }}
                >
                  <option value="ALL">ALL</option>
                  {filterFromDb?.RoomType?.map((item, index) => (
                    <option key={index} value={item?.roomtypecode}>
                      {item?.roomtypecode}
                    </option>
                  ))}
                </Form.Select>
              </div>
            </Col>
            <Col xl={2}>
              <div className="from-groups no-border-break">
                <Form.Label>Company</Form.Label>
                <Form.Select
                  value={filterData.company}
                  onChange={(e) => {
                    if (e.target.value === "ALL") {
                      let payloadString = "";
                      filterFromDb?.Company?.map((item, index) => {
                        payloadString =
                          payloadString +
                          `${index === 0 ? "" : ","}${item?.Company}`;
                      });
                      setFilterData({
                        ...filterData,
                        company: "ALL",
                      });
                      filterRef.current.companyValue = payloadString;
                    } else {
                      setFilterData({
                        ...filterData,
                        company: e.target.value,
                      });
                      filterRef.current.companyValue = e.target.value;
                    }
                  }}
                >
                  <option value="ALL">ALL</option>
                  {filterFromDb?.Company?.map((item, index) => (
                    <option key={index} value={item?.Company}>
                      {item?.Company}
                    </option>
                  ))}
                </Form.Select>
              </div>
            </Col>
            <Col xl={2}>
              <div className="from-groups no-border-break">
                <Form.Label>Rate Code</Form.Label>
                <Form.Select
                  value={filterData.rateCode}
                  onChange={(e) => {
                    if (e.target.value === "ALL") {
                      let payloadString = "";
                      filterFromDb?.RateCode?.map((item, index) => {
                        payloadString =
                          payloadString +
                          `${index === 0 ? "" : ","}${item?.RateCode}`;
                      });
                      setFilterData({
                        ...filterData,
                        rateCode: "ALL",
                      });
                      filterRef.current.rateCodeValue = payloadString;
                    } else {
                      setFilterData({
                        ...filterData,
                        rateCode: e.target.value,
                      });
                      filterRef.current.rateCodeValue = e.target.value;
                    }
                  }}
                >
                  <option value="ALL">ALL</option>
                  {filterFromDb?.RateCode?.map((item, index) => (
                    <option key={index} value={item?.RateCode}>
                      {item?.RateCode}
                    </option>
                  ))}
                </Form.Select>
              </div>
            </Col>
          </Row>
        </Col>
        <Col xl={12}>
          {api !== 0 && (
            <div className="gap-1 widget-container-menu">
              {/* <Button className="heading-menu-btns" type="button">
                <img
                  src={NoteIcon}
                  alt="menuIcon"
                  onClick={() => {
                    setShowNoteSidebar(true);
                  }}
                />
              </Button> */}
              <Button className="heading-menu-btns" type="button">
                <img
                  src={infoIcon}
                  alt="info"
                  onClick={() => {
                    widgetDetailRef.current = {
                      widgetdetails: "",
                      usernote: "",
                      compName: compName?.cancellationDayByDay,
                      widgetId: para?.widgetId,
                    };
                    setWidgetModal(true);
                  }}
                />
              </Button>
              <Button
                className="heading-menu-btns"
                type="button"
                title="Send Mail"
              >
                <img
                  src={emailIcon}
                  alt="emailIcon"
                  onClick={() => {
                    commonService.fnSendWidgetMail(
                      tblids.dayByDayCancellationTable,
                      "Day By Day Cancellation",
                      snapshotId,
                      defaultAsOfDateFromRTK,
                      "",
                      para?.widgetId
                    );
                  }}
                />
              </Button>
              <Button className="heading-menu-btns" type="button">
                <img
                  src={downloadIcon}
                  alt="menuIcon"
                  import={tblids.dayByDayCancellationTable}
                  onClick={() =>
                    commonService.fnExportInCSV(
                      tblids.dayByDayCancellationTable,
                      // "Day By Day Cancellation"
                      `${para?.propertyCode}-Day By Day Cancellation-${moment(
                        new Date()
                      ).format("MMDDYYYY")}`
                    )
                  }
                />
              </Button>
            </div>
          )}
          <div className="ViewPropertySetupTable mt-3">
            <div className="stripped__table">
              <div className="table-responsive tblDynamicPickupSticky">
                <Table id={tblids.dayByDayCancellationTable}>
                  <thead className="tbl-header-sticky">
                    <tr className="table-main-header-tr">
                      <th className="text-center" colSpan={14}>
                        CANCELLATION DAY BY DAY
                      </th>
                    </tr>

                    <tr className="table-main-header-tr">
                      <th className="text-center border_right">Stay Date</th>
                      <th className="text-center border_right">Booking Date</th>
                      <th className="text-center border_right">Arrival Date</th>
                      <th className="text-center border_right">
                        Departure Date
                      </th>
                      <th className="text-center border_right">
                        Cancellation Date
                      </th>
                      <th className="text-center border_right">Guest Name</th>
                      <th className="text-center border_right">
                        Market Segment
                      </th>
                      <th className="text-center border_right">Rate Code</th>
                      <th className="text-center border_right">Room Type</th>
                      <th className="text-center">Company</th>
                      <th className="text-end border_right">Rate</th>
                      <th className="text-end border_right">ADR</th>
                      <th className="text-center border_right">
                        Diff (ADR - Rate)
                      </th>
                      <th className="text-center border_right">Lead Days</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <tr style={{ height: "80px", position: "relative" }}>
                        <Loader />
                      </tr>
                    ) : cancellationData ? (
                      cancellationData?.map((item, index) => {
                        return (
                          <tr className="shadowOnHover">
                            <td className="text-center border_right">
                              {item?.StayDate
                                ? commonService.getDateInFormat(item?.StayDate)
                                : "-"}
                            </td>
                            <td className="text-center border_right">
                              {item?.BookingDate
                                ? commonService.getDateInFormat(
                                    item?.BookingDate
                                  )
                                : "-"}
                            </td>
                            <td className="text-center border_right">
                              {item?.ArrivalDate
                                ? commonService.getDateInFormat(
                                    item?.ArrivalDate
                                  )
                                : "-"}
                            </td>
                            <td className="text-center border_right">
                              {item?.DepartureDate
                                ? commonService.getDateInFormat(
                                    item?.DepartureDate
                                  )
                                : "-"}
                            </td>
                            <td className="text-center border_right">
                              {item?.CancellationDate
                                ? commonService.getDateInFormat(
                                    item?.CancellationDate
                                  )
                                : "-"}
                            </td>
                            <td className="text-center border_right">
                              {item?.GuestName}
                            </td>
                            <td className="text-center border_right">
                              {item?.MarketSegment}
                            </td>
                            <td className="text-center border_right">
                              {item?.RateCode}
                            </td>
                            <td className="text-center border_right">
                              {item?.RoomType}
                            </td>
                            <td className="text-center border_right">
                              {item?.Company}
                            </td>
                            <td className="text-end border_right">
                              {commonService.formateAmount(item?.Rate)}
                            </td>
                            <td className="text-end border_right">
                              {commonService.formateAmount(item?.ADR)}
                            </td>
                            <td className="text-center border_right">
                              {item?.diff}
                            </td>
                            <td className="text-center">
                              {commonService.formateRoom(item?.Cancellation_leadtime)}
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td className="text-center" colSpan={14}>
                          No Data Found
                        </td>
                      </tr>
                    )}
                    {/* {loading ? (
                    <tr style={{ height: "80px", position: "relative" }}>
                      <Loader />
                    </tr>
                  ) : (
                    cancellationData?.map((item, index) => {
                      return (
                        <tr>
                          <td className="text-center border_right">
                            {item?.StayDate
                              ? commonService.getDateInFormat(item?.StayDate)
                              : "-"}
                          </td>
                          <td className="text-center border_right">
                            {item?.GuestName}
                          </td>
                          <td className="text-center border_right">
                            {item?.MarketSegment}
                          </td>
                          <td className="text-center border_right">
                            {item?.RateCode}
                          </td>
                          <td className="text-center border_right">
                            {item?.RoomType}
                          </td>
                          <td className="text-center border_right">
                            {item?.Company}
                          </td>
                          <td className="text-end border_right">
                            ${item?.Rate}
                          </td>
                          <td className="text-end border_right">
                            ${item?.ADR}
                          </td>
                          <td className="text-center border_right">
                            {item?.diff}
                          </td>
                          <td className="text-center">
                            {item?.Cancellation_leadtime}
                          </td>
                        </tr>
                      );
                    })
                  )} */}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
          {api !== 0 && (
            <Comments widgetDetails={para} editorId={"CancellationDayByDay"} />
          )}
        </Col>
      </Container>
      {widgetModal && (
        <WidgetDetailModal
          showModal={widgetModal}
          closeModal={closeWidgetModal}
          data={widgetDetailRef.current}
        />
      )}
    </div>
  );
};

export default CancellationDayByDay;
