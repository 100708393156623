import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import WeatherComonent from "../CommonComponent/WeatherComonent";
import { useSelector } from "react-redux";

function WeatherPage() {
  const propertyDetailFromRTK = useSelector(
    (store) => store.headerProperty.propertyDetail
  );
  return (
    <>
      <div className="weather-page-main">
        {propertyDetailFromRTK ? (
          propertyDetailFromRTK?.geolocation ? (
            <Container fluid>
              <Row>
                <Col xl={12}>
                  <div className="ProspectDashboardTitleWraper  d-flex justify-content-between  align-items-center mb-4">
                    <h2>
                      Dashboard- <span className="tab-title-main">Weather</span>
                    </h2>
                  </div>
                </Col>
              </Row>
              <WeatherComonent />
            </Container>
          ) : (
            <Container>
              <div className="d-flex justify-content-center">
                <h5>Please add location in configuration</h5>
              </div>
            </Container>
          )
        ) : (
          <Container>
            <div className="d-flex justify-content-center">
              <h5>Please Select Property</h5>
            </div>
          </Container>
        )}
      </div>
    </>
  );
}

export default WeatherPage;
