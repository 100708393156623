import React, { useEffect, useRef, useState } from "react";
import { apiCall } from "../../../../utils/axiosService";
import { apiResponse } from "../../../../utils/apiResponse";
import { commonService } from "../../../../utils/commonService";
import { API_URL } from "../../../../utils/apiRoutes";
import Loader from "../../../Loader/Loader";
import DatePicker from "react-datepicker";
import {
  Chart,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  Filler,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { Form } from "react-bootstrap";
import moment from "moment";

const Rates = ({
  setCompDataFromChild,
  rowIndex,
  colIndex,
  api,
  para,
  setting,
}) => {
  const date = new Date();
  const startDate = new Date(date.getFullYear(), date.getMonth(), 1);
  const endDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  const [startRangeDate, setStartRangeDate] = useState(startDate);
  const [endRangeDate, setEndRangeDate] = useState(endDate);
  const [loading, setLoading] = useState(false);
  const [ratingFrom, setRatingFrom] = useState(null);
  const [selectedRatingFrom, setSelectedRatingFrom] = useState();
  const [chartData, setChartData] = useState(null);
  const ratingDataObjRef = useRef();
  const datesArrRef = useRef();

  const getRatesData = async () => {
    setLoading(true);
    let ratesResponse = await apiCall(
      {
        method: "POST",
        url: API_URL.BASE_API_URL.SnapShot + API_URL.ANALYTICS.RATES,
        body: {
          startDate: commonService.getDateForNode(startRangeDate),
          endDate: commonService.getDateForNode(endRangeDate),
          propertyCode: para?.propertyCode,
        },
      },
      false
    );

    let response = apiResponse(false, ratesResponse);

    if (response?.isValidate) {
      let ratingfrom = [],
        ratingFromObj = {},
        dataFromApi = response?.data?.data;

      let datesArr = commonService.getDateBetweenTwoDates(
        new Date(startRangeDate),
        new Date(endRangeDate)
      );
      datesArrRef.current = datesArr;

      let labels = [],
        datasets = [],
        propertyObj = {};

      dataFromApi?.forEach((item) => {
        if (!ratingFromObj[item?.ratefrom]) {
          ratingfrom.push(item?.ratefrom);
          ratingFromObj[item?.ratefrom] = {};
        }

        if (!propertyObj[item?.propertyname]) {
          propertyObj[item?.propertyname] = [];
          datasets.push({
            type: "line",
            label: item?.propertyname,
            data: [],
            fill: false,
            backgroundColor: commonService.CreateColor(item?.propertyname)
              ?.BGColor,
            borderColor: commonService.CreateColor(item?.propertyname)?.BGColor,
            borderWidth: 2,
            pointRadius: 1,
            pointBorderWidth: 4,
          });
        }
      });

      ratingFromObj = {};
      ratingfrom?.forEach((item) => {
        let groupingJson = {};
        Object.keys(propertyObj)?.forEach((propertyItem) => {
          groupingJson[`${propertyItem}-${item}`] = [];
        });
        ratingFromObj[item] = groupingJson;
      });

      datesArr?.forEach((item) => {
        labels.push(moment(item).format("MM/DD"));
        let findData = dataFromApi?.filter(
          (x) =>
            commonService.getDateInFormat(x?.ratedate) ===
            commonService.getDateInFormat(item)
        );
        if (findData?.length > 0) {
          let dummyObj = {};
          findData?.forEach((findDataItem) => {
            let findByPropertyName = findData?.filter(
              (x) => x?.propertyname === findDataItem?.propertyname
            );
            if (findByPropertyName?.length === ratingfrom?.length) {
              ratingFromObj[findDataItem?.ratefrom][
                `${findDataItem?.propertyname}-${findDataItem?.ratefrom}`
              ]?.push(findDataItem?.rates);
            } else {
              if (!dummyObj[findDataItem?.propertyname]) {
                ratingfrom?.forEach((ratingItem) => {
                  let findValue = findByPropertyName?.filter(
                    (x) => x?.ratefrom === ratingItem
                  );
                  if (findValue?.length > 0) {
                    ratingFromObj[ratingItem][
                      `${findDataItem?.propertyname}-${ratingItem}`
                    ]?.push(findDataItem?.rates);
                  } else {
                    ratingFromObj[ratingItem][
                      `${findDataItem?.propertyname}-${ratingItem}`
                    ]?.push(0);
                  }
                });
              }
              dummyObj[findDataItem?.propertyname] = findDataItem?.propertyname;
            }
          });
        } else {
          Object?.entries(ratingFromObj)?.forEach(([key, value], index) => {
            Object.keys(value)?.forEach((valueItem) => {
              ratingFromObj[key][`${valueItem}`]?.push(0);
            });
          });
        }
      });
      ratingDataObjRef.current = ratingFromObj;

      setSelectedRatingFrom(ratingfrom[0]);
      datasets = datasets?.map((dataSetItem) => {
        dataSetItem.data =
          ratingFromObj[ratingfrom[0]][
            `${dataSetItem?.label}-${ratingfrom[0]}`
          ];
        return dataSetItem;
      });

      setChartData({
        labels,
        datasets,
      });
      setRatingFrom(ratingfrom);
      setSelectedRatingFrom(ratingfrom?.[0]);
      setLoading(false);
    }

    if (!response?.isValidate) {
      setChartData(null);
      setLoading(false);
    }
  };

  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setStartRangeDate(start);
    setEndRangeDate(end);
  };

  const handleRatingFromOnChange = (selectedValue) => {
    let data = chartData?.datasets;
    let updatedDataSet = data?.map((item) => {
      item.data =
        ratingDataObjRef.current[selectedValue][
          `${item?.label}-${selectedValue}`
        ];

      return item;
    });
    setChartData({
      ...chartData,
      datasets: updatedDataSet,
    });
  };

  const chartOptions = {
    scales: {
      x: {
        beginAtZero: true,
      },
      y: {
        beginAtZero: true,
        ticks: {
          callback: function (value) {
            return "$" + value;
          },
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        enabled: true,
        mode: "index",
        callbacks: {
          title: function (context) {
            return context[0].label;
          },
          label: function (context) {
            let label = context.dataset.label;
            let value = context.formattedValue;
            return `${label}: $${value}`;
          },
        },
      },
    },
  };

  useEffect(() => {
    if (api === 0) {
    } else {
      if (startRangeDate && endRangeDate) {
        getRatesData();
      }
    }
  }, [startRangeDate, endRangeDate]);

  Chart.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Tooltip,
    Legend,
    PointElement,
    LineElement,
    Filler
  );

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <div className="width175">
              <DatePicker
                onChange={(event) => handleDateChange(event)}
                startDate={startRangeDate}
                endDate={endRangeDate}
                selectsRange
                monthsShown={2}
              />
            </div>
            <div className="from-groups no-border-break width150">
              <Form.Select
                aria-label="Default select example"
                value={selectedRatingFrom}
                onChange={(e) => {
                  setSelectedRatingFrom(e.target.value);
                  handleRatingFromOnChange(e.target.value);
                }}
              >
                {ratingFrom?.map((item) => {
                  return (
                    <option key={item} value={item}>
                      {item}
                    </option>
                  );
                })}
              </Form.Select>
            </div>
          </div>
          {chartData ? (
            <>
              <div style={{ height: "400px" }}>
                <Line data={chartData} options={chartOptions} />
              </div>
            </>
          ) : (
            <div className="d-flex justify-content-center">
              <p>No Data Found</p>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Rates;
