import React, { useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import configuration from "../../Assets/Images/Configuration/Configuration.svg";
import profile from "../../Assets/Images/Configuration/profile.svg";
import bag from "../../Assets/Images/Configuration/bag.svg";
import property from "../../Assets/Images/Configuration/property.svg";
import { Link, NavLink } from "react-router-dom";
import { commonService } from "../../utils/commonService";

function AsideConfiguration() {
  const [activeRoute, setActiveRoute] = useState("/");
  return (
    <aside className="revenue-management-aside-main asideConfigurationMain container-menu-bar">
      <ul className="list-unstyled p-0 m-0">
        {/* {commonService.checkUserRole(
                    "Configuration",
                    "Setup & Configuration",
                    "isview"
                ) && (
                        <li>
                            <Accordion>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header className="configurationAccordionHeader  having-children">
                                        <img src={configuration} alt="configuration" />
                                        <span className="main-menu-text">Setup &
                                            Configuration</span>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                            {commonService.checkUserRole(
                                                "Configuration",
                                                "Client Settings",
                                                "isview"
                                            ) && (
                                                    <li>
                                                        <NavLink to="/configuration/configuration">
                                                            Client Settings
                                                        </NavLink>
                                                    </li>
                                                )}

                                            {commonService.checkUserRole(
                                                "Configuration",
                                                "General Term Setup",
                                                "isview"
                                            ) && (
                                                    <li>
                                                        <NavLink to="/configuration/general-term-setup">
                                                            General Term Setup
                                                        </NavLink>
                                                    </li>
                                                )}

                                            {commonService.checkUserRole(
                                                "Configuration",
                                                "Email Configuration",
                                                "isview"
                                            ) && (
                                                    <li>
                                                        <NavLink to="/configuration/email-configuration">
                                                            Email Configuration
                                                        </NavLink>
                                                    </li>
                                                )}

                                            {commonService.checkUserRole(
                                                "Configuration",
                                                "Email Template Configuration",
                                                "isview"
                                            ) && (
                                                    <li>
                                                        <NavLink to="/configuration/email-template-configuration">
                                                            Email Template Configuration
                                                        </NavLink>
                                                    </li>
                                                )}
                                        </ul>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </li>
                    )}


                {commonService.checkUserRole(
                    "Configuration",
                    "User Management",
                    "isview"
                ) && (
                        <li>
                            <Accordion>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header className="configurationAccordionHeader  having-children">
                                        <img src={profile} alt="configuration" />
                                        <span className="main-menu-text">User Management</span>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                            {commonService.checkUserRole(
                                                "Configuration",
                                                "Employee Management",
                                                "isview"
                                            ) && (
                                                    <li>
                                                        <NavLink
                                                            to="/configuration/employee-management"
                                                            className="currentConfiguration"
                                                        >
                                                            Employee Management
                                                        </NavLink>
                                                    </li>
                                                )}

                                            {commonService.checkUserRole(
                                                "Configuration",
                                                "Role Management",
                                                "isview"
                                            ) && (
                                                    <li>
                                                        <NavLink to="/configuration/role-management">
                                                            Role Management
                                                        </NavLink>
                                                    </li>
                                                )}

                                            {commonService.checkUserRole(
                                                "Configuration",
                                                "User Management",
                                                "isview"
                                            ) && (
                                                    <li>
                                                        <NavLink to="/configuration/user-management">
                                                            User Management
                                                        </NavLink>
                                                    </li>
                                                )}

                                            {commonService.checkUserRole(
                                                "Configuration",
                                                "Login Logs",
                                                "isview"
                                            ) && (
                                                    <li>
                                                        <NavLink to="/configuration/login-logs">Login Logs</NavLink>
                                                    </li>
                                                )}

                                            {commonService.checkUserRole(
                                                "Configuration",
                                                "Action Logs",
                                                "isview"
                                            ) && (
                                                    <li>
                                                        <NavLink to="/configuration/action-logs">
                                                            Action Logs
                                                        </NavLink>
                                                    </li>
                                                )}
                                        </ul>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </li>
                    )} */}

        <Accordion>
          <Accordion.Item eventKey="1">
            <Accordion.Header className="configurationAccordionHeader  having-children">
              <img src={configuration} alt="configuration" />
              <span className="main-menu-text">Setup & Configuration</span>
            </Accordion.Header>
            <Accordion.Body>
              <ul>
                {commonService.checkUserRole(
                  "Configuration",
                  "Client Settings",
                  "isview"
                ) && (
                  <li>
                    <NavLink to="/configuration/configuration">
                      Client Settings
                    </NavLink>
                  </li>
                )}

                {commonService.checkUserRole(
                  "Configuration",
                  "General Term Setup",
                  "isview"
                ) && (
                  <li>
                    <NavLink to="/configuration/general-term-setup">
                      General Term Setup
                    </NavLink>
                  </li>
                )}

                {commonService.checkUserRole(
                  "Configuration",
                  "Email Configuration",
                  "isview"
                ) && (
                  <li>
                    <NavLink to="/configuration/email-configuration">
                      Email Configuration
                    </NavLink>
                  </li>
                )}

                {commonService.checkUserRole(
                  "Configuration",
                  "Email Template Configuration",
                  "isview"
                ) && (
                  <li>
                    <NavLink to="/configuration/email-template-configuration">
                      Email Template Configuration
                    </NavLink>
                  </li>
                )}
              </ul>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="2">
            <Accordion.Header className="configurationAccordionHeader  having-children">
              <img src={profile} alt="configuration" />
              <span className="main-menu-text">User Management</span>
            </Accordion.Header>
            <Accordion.Body>
              <ul>
                {commonService.checkUserRole(
                  "Configuration",
                  "Employee Management",
                  "isview"
                ) && (
                  <li>
                    <NavLink
                      to="/configuration/employee-management"
                      className="currentConfiguration"
                    >
                      Employee Management
                    </NavLink>
                  </li>
                )}

                {commonService.checkUserRole(
                  "Configuration",
                  "Role Management",
                  "isview"
                ) && (
                  <li>
                    <NavLink to="/configuration/role-management">
                      Role Management
                    </NavLink>
                  </li>
                )}

                {commonService.checkUserRole(
                  "Configuration",
                  "User Management",
                  "isview"
                ) && (
                  <li>
                    <NavLink to="/configuration/user-management">
                      User Management
                    </NavLink>
                  </li>
                )}

                {commonService.checkUserRole(
                  "Configuration",
                  "Login Logs",
                  "isview"
                ) && (
                  <li>
                    <NavLink to="/configuration/login-logs">Login Logs</NavLink>
                  </li>
                )}

                {commonService.checkUserRole(
                  "Configuration",
                  "Action Logs",
                  "isview"
                ) && (
                  <li>
                    <NavLink to="/configuration/action-logs">
                      Action Logs
                    </NavLink>
                  </li>
                )}
              </ul>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>

        {commonService.checkUserRole(
          "Configuration",
          "Portfolio Setup",
          "isview"
        ) && (
          <li>
            <NavLink
              to="/configuration/portfolio-setup"
              className="revenue-aside-links"
            >
              <img src={bag} alt="configuration" />
              <span className="main-menu-text">Portfolio Setup</span>
            </NavLink>
          </li>
        )}

        {commonService.checkUserRole(
          "Configuration",
          "Property Setup",
          "isview"
        ) && (
          <li>
            <NavLink
              to="/configuration/property-setup"
              className="revenue-aside-links"
            >
              <img src={property} alt="configuration" />
              <span className="main-menu-text">Property Setup</span>
            </NavLink>
          </li>
        )}
      </ul>
    </aside>
  );
}

export default AsideConfiguration;
