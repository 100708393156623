import React, { useEffect, useRef, useState } from "react";
import { Row, Form, Col, Button, Container } from "react-bootstrap";
import pdfIcon from "../../../Assets/Images/CRM/pdf.svg";
import { commonService } from "../../../utils/commonService";
import DatePicker from "react-datepicker";
import { API_URL } from "../../../utils/apiRoutes";
import { validationRule } from "../../../utils/constant";
import { hotelListCommon } from "../Hotels/HotelListCommon";
import { AccountListCommon } from "../Contacts/AccountListCommon";
import { checkValidation } from "../../../utils/helper";
import { apiCall } from "../../../utils/axiosService";
import { apiResponse } from "../../../utils/apiResponse";
import { successToastMessage } from "../../../utils/toastMessage";
import Select from "react-select";
import filterIcon from "../../../Assets/Images/CRM/filter.png";
import { useSelector } from "react-redux";
import Loader from "../../Loader/Loader";
import { Link } from "react-router-dom";

const LostBussinessGridReport = ({ title }) => {
  const [moduleWisePropertyDropDown, setModuleWisePropertyDropDown] = useState(
    []
  );
  const [selectedModuleWiseProperty, setSelectedModuleWiseProperty] = useState(
    []
  );
  const propertyIdList = useRef("");
  const useridList = useRef("");
  const isDefaultApiCalledRef = useRef(false);

  const [selectUser, setSelectUser] = useState([]);
  const [userValue, setUserValue] = useState([]);
  const [loading, setLoading] = useState(false);

  const [hotelList, setHotelList] = useState(null);
  const [accountList, setAccountList] = useState(null);
  const userDetails = commonService.getLoginUserData();
  const setPdfRef = useRef("");
  const [showPdf, setShowPdf] = useState(false);
  const [showAllReport, setshowAllReport] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  const handleshowAllReport = () => {
    setshowAllReport(!showAllReport);
  };

  const crmPropertyDetailFromRTK = useSelector(
    (store) => store.headerProperty.crmPropertyDetail
  );

  let propertyIdPayload = "";
  if (crmPropertyDetailFromRTK) {
    if (crmPropertyDetailFromRTK?.propertyid === "") {
      let propertyPayload = crmPropertyDetailFromRTK?.allPropertyId;
      propertyIdPayload = propertyPayload.slice(0, -1);
    } else {
      propertyIdPayload = crmPropertyDetailFromRTK?.propertyid?.toString();
    }
  }

  let startDate = new Date();
  let endDate = new Date(startDate.getTime() - 7 * 24 * 60 * 60 * 1000);

  let validationObj = {
    clientid: userDetails?.clientid ? userDetails?.clientid : 0,
    propertyID: "",
    userid: "",
    startDate: endDate,
    endDate: startDate,

    validationRule: [
      {
        fieldName: "propertyID",
        rule: validationRule.required,
        message: "Property Id required",
      },
      {
        fieldName: "userid",
        rule: validationRule.required,
        message: "User selection required",
      },
      {
        fieldName: "startDate",
        rule: validationRule.required,
        message: "Start Date is required",
      },
      {
        fieldName: "endDate",
        rule: validationRule.required,
        message: "End Date is required",
      },
    ],
    errorObj: {},
    isValidate: true,
  };
  const [lostBussinessData, setLossBussinessData] = useState(validationObj);
  const lostBussinessRef = useRef(lostBussinessData);

  const getHotelList = async () => {
    let response = await hotelListCommon(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.CRM +
          API_URL.PROPERTY_TERM.GET_PROPERTY_LIST_BY_USER_ID,
        body: {
          id: userDetails?.userid,
        },
      },
      false
    );

    if (response?.isValidate) {
      let propertyListarry = [];
      response?.data?.map((item) => {
        propertyListarry = [
          ...propertyListarry,
          {
            label: item?.propertyname,
            value: item?.propertyid,
          },
        ];
      });
      setModuleWisePropertyDropDown(propertyListarry);
      if (crmPropertyDetailFromRTK?.propertyid != "") {
        setSelectedModuleWiseProperty([
          {
            label: crmPropertyDetailFromRTK?.propertyname,
            value: crmPropertyDetailFromRTK?.propertyid,
          },
        ]);

        propertyIdList.current = `${crmPropertyDetailFromRTK?.propertyid}`;
        lostBussinessRef.current.propertyID = propertyIdList?.current;
        setLossBussinessData(lostBussinessRef.current);
      }
    }
  };

  // select dropdown
  const handlePropertySelection = (e) => {
    if (e?.length <= 0) {
      setSelectedModuleWiseProperty([]);
    }
    setSelectedModuleWiseProperty(e);
    let propertyList = [];
    e?.map((item) => {
      propertyList = [...propertyList, item?.value?.toString()];
      propertyIdList.current = propertyList?.join(",");
      setLossBussinessData({
        ...lostBussinessData,
        propertyID: propertyIdList.current,
      });
      getUserByPropertyList(propertyIdList.current);
    });
  };

  //select  user by property
  const getUserByPropertyList = async (propertyId) => {
    // if (propertyId !== 0) {
    let accountResponse = await AccountListCommon(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.UserManagement +
          API_URL.USER_ROLE_PROPERTY.GET_USER_LIST_BY_MODULE_MULTI_PROPERTY,
        body: {
          propertyid: lostBussinessData?.propertyID?.toString(),
          modulename: "crm",
        },
      },
      false
    );

    if (accountResponse?.isValidate) {
      setAccountList(accountResponse?.data);
      let userDropList = [];
      let userId = [];
      accountResponse?.data?.map((item) => {
        userId = [...userId, item?.userid];
        userDropList = [
          ...userDropList,
          {
            label: item?.displayname,
            value: item?.userid,
          },
        ];
      });
      setSelectUser(userDropList);
      setUserValue(userDropList);
      useridList.current = userId?.join(",");
      setLossBussinessData({
        ...lostBussinessData,
        userid: useridList.current,
      });
      // if (crmPropertyDetailFromRTK?.propertyid != "") {
      //   useridList.current = userId?.join(",");
      //   setLossBussinessData({
      //     ...lostBussinessData,
      //     userid: useridList.current,
      //   });
      // }
    }
    // }
  };

  // handle user
  const HandleUserSelection = (e) => {
    if (e.length <= 0) {
      setUserValue([]);
    }
    setUserValue(e);
    let userListItm = [];
    e?.map((item) => {
      userListItm = [...userListItm, item?.value?.toString()];
      useridList.current = userListItm?.join(",");
      setLossBussinessData({
        ...lostBussinessData,
        userid: useridList.current,
      });
    });
  };

  //handle Date change
  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setLossBussinessData({
      ...lostBussinessData,
      startDate: start,
      endDate: end,
    });
  };

  const iframeloader = () => {
    setLoading(false);
  };

  const GenerateReportbtn = async () => {
    setShowPdf(false);
    setLoading(true);
    let validation = checkValidation(lostBussinessData);

    if (!validation.isValidate) {
      setLossBussinessData(validation);
      setLoading(false);
      return;
    }

    let LostBussinessReport = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.CRM +
          API_URL.CRM_REPORT.LOST_BUSSINESS_GRID_REPORT,
        body: {
          propertyID: lostBussinessData.propertyID,
          userid: lostBussinessData.userid.toString(),
          startDate: commonService?.getDateInDBFormat(
            lostBussinessData.startDate
          ),
          endDate: commonService?.getDateInDBFormat(lostBussinessData.endDate),
        },
      },
      false
    );

    let response = apiResponse(false, LostBussinessReport);

    if (response?.isValidate) {
      isDefaultApiCalledRef.current = true;
      // setLoading(false);
      setshowAllReport(false);
      setErrorMessage(false);
      // successToastMessage(response?.message);
      setPdfRef.current =
        process.env.REACT_APP_APIBASEPATH_CRM_REPORT_PDF + response.data.data;
      setShowPdf(true);
    }
    if (!response?.isValidate) {
      isDefaultApiCalledRef.current = true;
      setshowAllReport(true);
      setLoading(false);
      setErrorMessage(true);
    }
  };

  useEffect(() => {
    if (crmPropertyDetailFromRTK?.propertyid === "") {
      isDefaultApiCalledRef.current = true;
      setshowAllReport(true);
    }
    getHotelList();
  }, []);

  useEffect(() => {
    if (lostBussinessData.propertyID !== "") {
      if (!isDefaultApiCalledRef.current && lostBussinessData.userid !== "") {
        GenerateReportbtn();
      }
    }
  }, [lostBussinessData.propertyID, lostBussinessData.userid]);

  useEffect(() => {
    if (lostBussinessData.propertyID !== "") {
      getUserByPropertyList();
    }
  }, [lostBussinessData.propertyID]);

  return (
    <div className="all-reports-main ToolsDisplacementCalcMain">
      <Container fluid>
        <Row>
          <Col xl={12}>
            <div className="ProspectDashboardTitleWraper  d-flex justify-content-between align-items-center marginBottom20">
              <h2 className="d-flex align-items-center gap-1">
                <Link to="/crm/dashboard">Dashboard - </Link>{" "}
                <span className="tab-title-main">{title}</span>
              </h2>
              <div className="heading-menu-btns">
                <img
                  src={filterIcon}
                  alt="filterIcon"
                  title="Filter Report"
                  className="cursorPointer report-filter"
                  onClick={handleshowAllReport}
                />
              </div>
            </div>
          </Col>
        </Row>

        {showAllReport && (
          <div className="displacementCalcWrapper width400">
            <div className="from-groups no-border-break mb-2 small-height-select">
              <Form.Label>Select Property</Form.Label>
              {/* <Form.Select
              aria-label="Default select example"
              value={lostBussinessData?.propertyID}
              onChange={(e) => {
                setLossBussinessData(
                  {
                    ...lostBussinessData,
                    propertyID: e.target.value,
                  },

                  getUserByPropertyList(e.target.value)
                );
              }}
            >
              <option value={0}>Select Property</option>
              {hotelList?.map((item, index) => (
                <option key={index} value={item?.propertyid}>
                  {item?.propertyname}
                </option>
              ))}
            </Form.Select> */}
              <Select
                isMulti
                value={selectedModuleWiseProperty}
                options={moduleWisePropertyDropDown}
                onChange={(e) => {
                  handlePropertySelection(e);
                }}
              />
              {lostBussinessData?.errorObj?.propertyID &&
                lostBussinessData?.propertyID === "" && (
                  <small className="text-danger">
                    {lostBussinessData?.errorObj?.propertyID}
                  </small>
                )}
            </div>

            <div className="from-groups no-border-break mb-2 small-height-select">
              <Form.Label>Select User</Form.Label>
              {/* <Form.Select
              aria-label="Default select example"
              value={lostBussinessData?.userID}
              onChange={(e) => {
                setLossBussinessData({
                  ...lostBussinessData,
                  userID: e.target.value,
                });
              }}
            >
              <option value={0}>Select User</option>
              {accountList?.map((item, index) => (
                <option key={index} value={item?.userid}>
                  {item?.displayname}
                </option>
              ))}
            </Form.Select> */}
              <Select
                isMulti
                value={userValue}
                options={selectUser}
                onChange={(e) => {
                  HandleUserSelection(e);
                }}
              />
              {lostBussinessData?.errorObj?.userid &&
                lostBussinessData?.userid === "" && (
                  <small className="text-danger">
                    {lostBussinessData?.errorObj?.userid}
                  </small>
                )}
            </div>

            {/* <div className="startFrom from-groups no-border-break mb-2">
              <Form.Label htmlFor="startDate">Start Date</Form.Label>

              <DatePicker
                id="startDate"
                selected={
                  lostBussinessData.startDate
                    ? new Date(lostBussinessData.startDate)
                    : ""
                }
                selectsStart
                onChange={(date) => {
                  setLossBussinessData({
                    ...lostBussinessData,
                    startDate: date,
                  });
                }}
                placeholderText="MM/DD/YYYY"
              />
              {lostBussinessData?.errorObj?.startDate &&
                lostBussinessData?.startDate === "" && (
                  <small className="text-danger">
                    {lostBussinessData?.errorObj?.startDate}
                  </small>
                )}
            </div>

            <div className="endDate from-groups no-border-break mb-2">
              <Form.Label htmlFor="endDate">End Date</Form.Label>

              <DatePicker
                id="endDate"
                selected={
                  lostBussinessData.endDate
                    ? new Date(lostBussinessData.endDate)
                    : ""
                }
                disabled={lostBussinessData.startDate ? false : true}
                minDate={lostBussinessData.startDate}
                selectsEnd
                onChange={(date) => {
                  setLossBussinessData({
                    ...lostBussinessData,
                    endDate: date,
                  });
                }}
                placeholderText="MM/DD/YYYY"
              />
              {lostBussinessData?.errorObj?.endDate &&
                lostBussinessData?.endDate === "" && (
                  <small className="text-danger">
                    {lostBussinessData?.errorObj?.endDate}
                  </small>
                )}
            </div> */}
            <div className="startFrom from-groups no-border-break mb-4 meghaDatePicker">
              <Form.Label htmlFor="startDate">Date Range</Form.Label>

              <DatePicker
                onChange={handleDateChange}
                startDate={lostBussinessData.startDate}
                endDate={lostBussinessData.endDate}
                selectsRange
                monthsShown={2}
              />
            </div>

            <div className="d-flex gap-3 align-items-center justify-content-end ">
              {loading ? (
                <button
                  className="default-btn primary-btn d-flex gap-1 align-items-center"
                  type="button"
                  disabled
                >
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Loading...
                </button>
              ) : (
                <button
                  className="default-btn primary-btn d-flex gap-1 align-items-center"
                  onClick={() => {
                    GenerateReportbtn();
                  }}
                >
                  <span className="revpak-icon">
                    <img src={pdfIcon} alt="" />
                  </span>
                  <span>Generate Report</span>
                </button>
              )}
            </div>
          </div>
        )}
        <p className="text-center">{errorMessage && " No Record Found"}</p>
        {loading && <Loader />}
        <div className="pdf-is-showing-here mt-3">
          {showPdf && setPdfRef.current && (
            <iframe
              onLoad={iframeloader}
              title="Report Doc"
              src={setPdfRef.current}
              width="100%"
              height="800px"
            />
          )}
        </div>
        {/* {loading ? (
          <Loader />
        ) : (
          <div className="pdf-is-showing-here mt-3">
            {showPdf && (
              <iframe
                title="Report Doc"
                src={setPdfRef.current}
                width="100%"
                height="800px"
              />
            )}
          </div>
        )} */}
      </Container>
    </div>
  );
};

export default LostBussinessGridReport;
