import React, { useEffect, useState } from "react";
import { apiCall } from "../../../../utils/axiosService";
import { apiResponse } from "../../../../utils/apiResponse";
import Loader from "../../../Loader/Loader";
import { API_URL } from "../../../../utils/apiRoutes";
import { Button, Table } from "react-bootstrap";
import NoteIcon from "../../../../Assets/Images/CRM/notes.svg";
import downloadIcon from "../../../../Assets/Images/Operation/downloadIcon.svg";
import { commonService } from "../../../../utils/commonService";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import emailIcon from "../../../../Assets/Images/Email.svg";
import moment from "moment";

const ChoiceRevenue = ({
  setCompDataFromChild,
  rowIndex,
  colIndex,
  api,
  para,
  setting,
}) => {
  const tableData = [
    {
      id: 1,
      propertyCode: "-",
      pullDateId: "0",
      AsOfDate: "",
      IDS_RATE_CODE: "-",
      RoomNights: "0",
      RoomNightsPer: "0",
      RoomRevenue: "0",
      RoomRevenuePer: "0",
      DailyAVG: "0",
      PTDRoomNights: "0",
      PTDRoomNightsPer: "0",
      PTDRoomRevenue: "0",
      PTDRoomRevenuePer: "0",
      PTD_AVG: "0",
      YTDRoomNights: "0",
      YTDRoomNightsPer: "0",
      YTDRoomRevenue: "0",
      YTDRoomRevenuePer: "0",
      YTD_AVG: "0",
    },
    {
      id: 2,
      propertyCode: "-",
      pullDateId: "0",
      AsOfDate: "",
      IDS_RATE_CODE: "-",
      RoomNights: "0",
      RoomNightsPer: "0",
      RoomRevenue: "0",
      RoomRevenuePer: "0",
      DailyAVG: "0",
      PTDRoomNights: "0",
      PTDRoomNightsPer: "0",
      PTDRoomRevenue: "0",
      PTDRoomRevenuePer: "0",
      PTD_AVG: "0",
      YTDRoomNights: "0",
      YTDRoomNightsPer: "0",
      YTDRoomRevenue: "0",
      YTDRoomRevenuePer: "0",
      YTD_AVG: "0",
    },
    {
      id: 3,
      propertyCode: "-",
      pullDateId: "0",
      AsOfDate: "",
      IDS_RATE_CODE: "-",
      RoomNights: "0",
      RoomNightsPer: "0",
      RoomRevenue: "0",
      RoomRevenuePer: "0",
      DailyAVG: "0",
      PTDRoomNights: "0",
      PTDRoomNightsPer: "0",
      PTDRoomRevenue: "0",
      PTDRoomRevenuePer: "0",
      PTD_AVG: "0",
      YTDRoomNights: "0",
      YTDRoomNightsPer: "0",
      YTDRoomRevenue: "0",
      YTDRoomRevenuePer: "0",
      YTD_AVG: "0",
    },
    {
      id: 4,
      propertyCode: "-",
      pullDateId: "0",
      AsOfDate: "",
      IDS_RATE_CODE: "-",
      RoomNights: "0",
      RoomNightsPer: "0",
      RoomRevenue: "0",
      RoomRevenuePer: "0",
      DailyAVG: "0",
      PTDRoomNights: "0",
      PTDRoomNightsPer: "0",
      PTDRoomRevenue: "0",
      PTDRoomRevenuePer: "0",
      PTD_AVG: "0",
      YTDRoomNights: "0",
      YTDRoomNightsPer: "0",
      YTDRoomRevenue: "0",
      YTDRoomRevenuePer: "0",
      YTD_AVG: "0",
    },
    {
      id: 5,
      propertyCode: "-",
      pullDateId: "0",
      AsOfDate: "",
      IDS_RATE_CODE: "-",
      RoomNights: "0",
      RoomNightsPer: "0",
      RoomRevenue: "0",
      RoomRevenuePer: "0",
      DailyAVG: "0",
      PTDRoomNights: "0",
      PTDRoomNightsPer: "0",
      PTDRoomRevenue: "0",
      PTDRoomRevenuePer: "0",
      PTD_AVG: "0",
      YTDRoomNights: "0",
      YTDRoomNightsPer: "0",
      YTDRoomRevenue: "0",
      YTDRoomRevenuePer: "0",
      YTD_AVG: "0",
    },
    {
      id: 6,
      propertyCode: "-",
      pullDateId: "0",
      AsOfDate: "",
      IDS_RATE_CODE: "-",
      RoomNights: "0",
      RoomNightsPer: "0",
      RoomRevenue: "0",
      RoomRevenuePer: "0",
      DailyAVG: "0",
      PTDRoomNights: "0",
      PTDRoomNightsPer: "0",
      PTDRoomRevenue: "0",
      PTDRoomRevenuePer: "0",
      PTD_AVG: "0",
      YTDRoomNights: "0",
      YTDRoomNightsPer: "0",
      YTDRoomRevenue: "0",
      YTDRoomRevenuePer: "0",
      YTD_AVG: "0",
    },
    {
      id: 7,
      propertyCode: "-",
      pullDateId: "0",
      AsOfDate: "",
      IDS_RATE_CODE: "-",
      RoomNights: "0",
      RoomNightsPer: "0",
      RoomRevenue: "0",
      RoomRevenuePer: "0",
      DailyAVG: "0",
      PTDRoomNights: "0",
      PTDRoomNightsPer: "0",
      PTDRoomRevenue: "0",
      PTDRoomRevenuePer: "0",
      PTD_AVG: "0",
      YTDRoomNights: "0",
      YTDRoomNightsPer: "0",
      YTDRoomRevenue: "0",
      YTDRoomRevenuePer: "0",
      YTD_AVG: "0",
    },
    {
      id: 8,
      propertyCode: "-",
      pullDateId: "0",
      AsOfDate: "",
      IDS_RATE_CODE: "-",
      RoomNights: "0",
      RoomNightsPer: "0",
      RoomRevenue: "0",
      RoomRevenuePer: "0",
      DailyAVG: "0",
      PTDRoomNights: "0",
      PTDRoomNightsPer: "0",
      PTDRoomRevenue: "0",
      PTDRoomRevenuePer: "0",
      PTD_AVG: "0",
      YTDRoomNights: "0",
      YTDRoomNightsPer: "0",
      YTDRoomRevenue: "0",
      YTDRoomRevenuePer: "0",
      YTD_AVG: "0",
    },
    {
      id: 9,
      propertyCode: "-",
      pullDateId: "0",
      AsOfDate: "",
      IDS_RATE_CODE: "-",
      RoomNights: "0",
      RoomNightsPer: "0",
      RoomRevenue: "0",
      RoomRevenuePer: "0",
      DailyAVG: "0",
      PTDRoomNights: "0",
      PTDRoomNightsPer: "0",
      PTDRoomRevenue: "0",
      PTDRoomRevenuePer: "0",
      PTD_AVG: "0",
      YTDRoomNights: "0",
      YTDRoomNightsPer: "0",
      YTDRoomRevenue: "0",
      YTDRoomRevenuePer: "0",
      YTD_AVG: "0",
    },
    {
      id: 10,
      propertyCode: "-",
      pullDateId: "0",
      AsOfDate: "",
      IDS_RATE_CODE: "-",
      RoomNights: "0",
      RoomNightsPer: "0",
      RoomRevenue: "0",
      RoomRevenuePer: "0",
      DailyAVG: "0",
      PTDRoomNights: "0",
      PTDRoomNightsPer: "0",
      PTDRoomRevenue: "0",
      PTDRoomRevenuePer: "0",
      PTD_AVG: "0",
      YTDRoomNights: "0",
      YTDRoomNightsPer: "0",
      YTDRoomRevenue: "0",
      YTDRoomRevenuePer: "0",
      YTD_AVG: "0",
    },
  ];

  const [choiceRevenueData, setChoiceRevenueData] = useState(null);
  const [loading, setLoading] = useState(false);
  const defaultAsOfDateFromRTK = useSelector(
    (store) => store.headerProperty.defaultAsOfDate
  );
  const { snapshottitle, propertyCode, snapshotId } = useParams();

  const getChoiceRevenueDetail = async () => {
    setLoading(true);
    let choiceRevenueResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.SnapShot + API_URL.WIDGET_MODULE.CHOICE_REVENUE,
        body: {
          AsOfDate: para?.asOfDate,
          propertyCode: para?.propertyCode,
        },
      },
      false
    );

    let response = apiResponse(false, choiceRevenueResponse);

    if (response?.isValidate) {
      setChoiceRevenueData(response?.data?.data);
      setLoading(false);
    }

    if (!response?.isValidate) {
      setChoiceRevenueData(null);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (api === 0) {
      setChoiceRevenueData(tableData);
    } else {
      if (para?.asOfDate) {
        getChoiceRevenueDetail();
      }
    }
  }, [para?.asOfDate]);

  const [tblids, settblids] = useState({
    tblChoiceRevenue: commonService.GenerateGUID(),
  });

  return (
    <div className="widget-container">
      {api !== 0 && (
        <div className="gap-1 widget-container-menu">
          <Button className="heading-menu-btns" type="button">
            <img src={NoteIcon} alt="menuIcon" />
          </Button>
          <Button className="heading-menu-btns" type="button" title="Send Mail">
            <img
              src={emailIcon}
              alt="emailIcon"
              onClick={() => {
                commonService.fnSendWidgetMail(
                  tblids.tblChoiceRevenue,
                  "Choice Revenue",
                  snapshotId,
                  defaultAsOfDateFromRTK,
                  "",
                  para?.widgetId
                );
              }}
            />
          </Button>
          <Button className="heading-menu-btns" type="button">
            <img
              src={downloadIcon}
              alt="menuIcon"
              import={tblids.tblChoiceRevenue}
              onClick={() =>
                commonService.fnExportInCSV(
                  tblids.tblChoiceRevenue,
                  // "Choice Revenue"
                  `${para?.propertyCode}-Choice Revenue-${moment(
                    new Date()
                  ).format("MMDDYYYY")}`
                )
              }
            />
          </Button>
        </div>
      )}
      <div className="ViewPropertySetupTable">
        <div className="stripped__table">
          <Table
            responsive
            id={tblids.tblChoiceRevenue}
            className="positionStaticTable"
          >
            <thead>
              <tr>
                <th colSpan={19}>Choice Revenue</th>
              </tr>
              <tr>
                {/* <th className="width100">property Code</th>
                <th className="width100">pull Date Id</th> */}
                <th className="width100 ">As Of Date</th>
                <th className="width100">Room Nights</th>
                <th className="width100 text-center">IDS RATE CODE</th>
                <th className="width100 text-center">Room Nights Per</th>
                <th className="width100 text-center">Room Revenue</th>

                <th className="width100 text-center">Room Revenue Per</th>
                <th className="width100 text-center">Daily AVG</th>
                <th className="width100 text-center">PTD Room Nights</th>
                <th className="width100 text-center">PTD Room Nights Per</th>
                <th className="width100 text-center">PTD Room Revenue</th>
                <th className="width100 text-center">PTD Room Revenue Per</th>
                <th className="width100 text-center">PTD AVG</th>
                <th className="width100 text-center">YTD Room Nights</th>
                <th className="width100 text-center">YTD Room Nights Per</th>
                <th className="width100 text-center">YTD Room Revenue</th>
                <th className="width100 text-center">YTD Room Revenue Per</th>
                <th className="width100 text-center">YTD AVG</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr style={{ height: "80px", position: "relative" }}>
                  <Loader />
                </tr>
              ) : choiceRevenueData && choiceRevenueData?.length > 0 ? (
                choiceRevenueData?.map((item) => (
                  <tr key={item?.id} className="shadowOnHover">
                    {/* <td>{item?.propertyCode}</td>
                    <td>{item?.pullDateId}</td> */}
                    <td>
                      {item?.AsOfDate
                        ? commonService.getDateInFormat(item?.AsOfDate)
                        : "-"}
                    </td>
                    <td>{item?.IDS_RATE_CODE}</td>
                    <td className="text-center">{item?.RoomNights}</td>
                    <td className="text-center">{item?.RoomNightsPer}</td>
                    <td className="text-center">{item?.RoomRevenue}</td>
                    <td className="text-center">{item?.RoomRevenuePer}</td>
                    <td className="text-center">{item?.DailyAVG}</td>
                    <td className="text-center">{item?.PTDRoomNights}</td>
                    <td className="text-center">{item?.PTDRoomNightsPer}</td>
                    <td className="text-center">{item?.PTDRoomRevenue}</td>
                    <td className="text-center">{item?.PTDRoomRevenuePer}</td>
                    <td className="text-center">{item?.PTD_AVG}</td>{" "}
                    <td className="text-center">{item?.YTDRoomNights}</td>
                    <td className="text-center">{item?.YTDRoomNightsPer}</td>
                    <td className="text-center">{item?.YTDRoomRevenue}</td>
                    <td className="text-center">{item?.YTDRoomRevenuePer}</td>
                    <td className="text-center">{item?.YTD_AVG}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td className="text-center" colSpan={19}>
                    No Data Found
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default ChoiceRevenue;
