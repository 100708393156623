import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Header from "../Header/Header";
import Loader from "../Loader/Loader";
import { commonService } from "../../utils/commonService";
import AsideOperation from "./AsideOperation";
const OperationRates = React.lazy(() => import("./OperationRates"));
const OperationCostCalculator = React.lazy(() => import("./OperationCostCalculator"));
const OperationStatus = React.lazy(() => import("./OperationStatus"));
const OperationNightAudit = React.lazy(() => import("./OperationNightAudit"));
const OperationReserveReconciliation = React.lazy(() => import("./OperationReserveReconciliation"));
const OperationAccountReconciliation = React.lazy(() => import("./OperationAccountReconciliation"));
const OperationCapexRequests = React.lazy(() => import("./OperationCapexRequests"));
const OperationVendor = React.lazy(() => import("./OperationVendor"));
const OperationSchedule = React.lazy(() => import("./OperationSchedule"));
const RoasterManagement = React.lazy(() => import("./RoasterManagement"));
const OperationTaskGridView = React.lazy(() => import("./OperationTaskGridView"));
const OperationTask = React.lazy(() => import("./OperationTask"));
const OperationChecklist = React.lazy(() => import("./OperationChecklist"));
const RoomAttendant = React.lazy(() => import("./RoomAttendant"));
const Maintenance = React.lazy(() => import("./Maintenance"));
const HouseKeeping = React.lazy(() => import("./HouseKeeping"));
const OperationHistory = React.lazy(() => import("./OperationHistory"));
const ArrivalANdDeparture = React.lazy(() => import("./ArrivalANdDeparture"));
const OperationDashboard = React.lazy(() => import("./OperationDashboard"));
const OperationProperty = React.lazy(() => import("./OperationProperty"));

function LayoutOperation() {
  const token = commonService.getItem("token");
  return (
    <div className="mainRoot">
      <Header />
      <AsideOperation />
      <main className="operation-page-body">
        <Routes>
          <Route
            path="/dashboard"
            element={
              token ? (
                <React.Suspense fallback={<Loader />}>
                  <OperationDashboard />
                </React.Suspense>
              ) : (
                <Navigate to="/" replace />
              )
            }
          />
          <Route
            path="/property"
            element={
              token ? (
                <React.Suspense fallback={<Loader />}>
                  <OperationProperty />
                </React.Suspense>
              ) : (
                <Navigate to="/" replace />
              )
            }
          />
          <Route
            path="/arrival-and-departure"
            element={
              token ? (
                <React.Suspense fallback={<Loader />}>
                  <ArrivalANdDeparture />
                </React.Suspense>
              ) : (
                <Navigate to="/" replace />
              )
            }
          />
          <Route
            path="/history"
            element={
              token ? (
                <React.Suspense fallback={<Loader />}>
                  <OperationHistory />
                </React.Suspense>
              ) : (
                <Navigate to="/" replace />
              )
            }
          />
          <Route
            path="/housekeeping"
            element={
              token ? (
                <React.Suspense fallback={<Loader />}>
                  <HouseKeeping />
                </React.Suspense>
              ) : (
                <Navigate to="/" replace />
              )
            }
          />
          <Route
            path="/maintenance"
            element={
              token ? (
                <React.Suspense fallback={<Loader />}>
                  <Maintenance />
                </React.Suspense>
              ) : (
                <Navigate to="/" replace />
              )
            }
          />
          <Route
            path="/maintenance/task"
            element={
              token ? (
                <React.Suspense fallback={<Loader />}>
                  <OperationTask />
                </React.Suspense>
              ) : (
                <Navigate to="/" replace />
              )
            }
          />
          <Route
            path="/maintenance/vendor"
            element={
              token ? (
                <React.Suspense fallback={<Loader />}>
                  <OperationVendor />
                </React.Suspense>
              ) : (
                <Navigate to="/" replace />
              )
            }
          />
          <Route
            path="/maintenance/capexrequests"
            element={
              token ? (
                <React.Suspense fallback={<Loader />}>
                  <OperationCapexRequests />
                </React.Suspense>
              ) : (
                <Navigate to="/" replace />
              )
            }
          />
          <Route
            path="/housekeeping/room-attendant"
            element={
              token ? (
                <React.Suspense fallback={<Loader />}>
                  <RoomAttendant />
                </React.Suspense>
              ) : (
                <Navigate to="/" replace />
              )
            }
          />
          <Route
            path="/housekeeping/checklist"
            element={
              token ? (
                <React.Suspense fallback={<Loader />}>
                  <OperationChecklist />
                </React.Suspense>
              ) : (
                <Navigate to="/" replace />
              )
            }
          />
          <Route
            path="/maintenance/task/grid-view"
            element={
              token ? (
                <React.Suspense fallback={<Loader />}>
                  <OperationTaskGridView />
                </React.Suspense>
              ) : (
                <Navigate to="/" replace />
              )
            }
          />
          <Route
            path="/housekeeping/roaster-management"
            element={
              token ? (
                <React.Suspense fallback={<Loader />}>
                  <RoasterManagement />
                </React.Suspense>
              ) : (
                <Navigate to="/" replace />
              )
            }
          />
          <Route
            path="/housekeeping/schedule"
            element={
              token ? (
                <React.Suspense fallback={<Loader />}>
                  <OperationSchedule />
                </React.Suspense>
              ) : (
                <Navigate to="/" replace />
              )
            }
          />
          <Route
            path="/housekeeping/costcalculator"
            element={
              token ? (
                <React.Suspense fallback={<Loader />}>
                  <OperationCostCalculator />
                </React.Suspense>
              ) : (
                <Navigate to="/" replace />
              )
            }
          />
          <Route
            path="/housekeeping/status"
            element={
              token ? (
                <React.Suspense fallback={<Loader />}>
                  <OperationStatus />
                </React.Suspense>
              ) : (
                <Navigate to="/" replace />
              )
            }
          />
          <Route
            path="/generalmanager/nightaudit"
            element={
              token ? (
                <React.Suspense fallback={<Loader />}>
                  <OperationNightAudit />
                </React.Suspense>
              ) : (
                <Navigate to="/" replace />
              )
            }
          />
          <Route
            path="/generalmanager/reservereconciliation"
            element={
              token ? (
                <React.Suspense fallback={<Loader />}>
                  <OperationReserveReconciliation />
                </React.Suspense>
              ) : (
                <Navigate to="/" replace />
              )
            }
          />
          <Route
            path="/generalmanager/accountreconciliation"
            element={
              token ? (
                <React.Suspense fallback={<Loader />}>
                  <OperationAccountReconciliation />
                </React.Suspense>
              ) : (
                <Navigate to="/" replace />
              )
            }
          />
          <Route
            path="/generalmanager/rates"
            element={
              token ? (
                <React.Suspense fallback={<Loader />}>
                  <OperationRates />
                </React.Suspense>
              ) : (
                <Navigate to="/" replace />
              )
            }
          />
        </Routes>
      </main>
    </div>
  );
}

export default LayoutOperation;
