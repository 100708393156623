import React, { useEffect, useRef, useState } from "react";
import { apiCall } from "../../../../utils/axiosService";
import { API_URL } from "../../../../utils/apiRoutes";
import { apiResponse } from "../../../../utils/apiResponse";
import { Button, Form, Table } from "react-bootstrap";
import { commonService } from "../../../../utils/commonService";
import NoteIcon from "../../../../Assets/Images/CRM/notes.svg";
import downloadIcon from "../../../../Assets/Images/Operation/downloadIcon.svg";
import Loader from "../../../Loader/Loader";
import { compName } from "../../../../utils/constant";
import WidgetDetailModal from "../../WidgetDetailModal";
import infoIcon from "../../../../Assets/Images/Action Icons/infoIcon..svg";
import { useSelector } from "react-redux";
import WidgetNote from "../../WidgetNote";
import AddWidgetModal from "../../AddWidgetModal";
import { useParams } from "react-router-dom";
import emailIcon from "../../../../Assets/Images/Email.svg";
import moment from "moment";

const Top50ByRevenue = ({
  setCompDataFromChild,
  rowIndex,
  colIndex,
  api,
  para,
  setting,
}) => {
  const tableData = [
    {
      GuestName: "-",
      CY_RMS: "0",
      CY_REV: "0",
      LY_RMS: "0",
      LY_REV: "0",
      PRE2_RMS: 0,
      PRE2_REV: 0,
      PRE3_RMS: 0,
      PRE3_REV: 0,
      PRE4_RMS: 0,
      PRE4_REV: 0,
    },
    {
      GuestName: "-",
      CY_RMS: "0",
      CY_REV: "0",
      LY_RMS: "0",
      LY_REV: "0",
      PRE2_RMS: 0,
      PRE2_REV: 0,
      PRE3_RMS: 0,
      PRE3_REV: 0,
      PRE4_RMS: 0,
      PRE4_REV: 0,
    },
    {
      GuestName: "-",
      CY_RMS: "0",
      CY_REV: "0",
      LY_RMS: "0",
      LY_REV: "0",
      PRE2_RMS: 0,
      PRE2_REV: 0,
      PRE3_RMS: 0,
      PRE3_REV: 0,
      PRE4_RMS: 0,
      PRE4_REV: 0,
    },
    {
      GuestName: "-",
      CY_RMS: "0",
      CY_REV: "0",
      LY_RMS: "0",
      LY_REV: "0",
      PRE2_RMS: 0,
      PRE2_REV: 0,
      PRE3_RMS: 0,
      PRE3_REV: 0,
      PRE4_RMS: 0,
      PRE4_REV: 0,
    },
    {
      GuestName: "-",
      CY_RMS: "0",
      CY_REV: "0",
      LY_RMS: "0",
      LY_REV: "0",
      PRE2_RMS: 0,
      PRE2_REV: 0,
      PRE3_RMS: 0,
      PRE3_REV: 0,
      PRE4_RMS: 0,
      PRE4_REV: 0,
    },
    {
      GuestName: "-",
      CY_RMS: "0",
      CY_REV: "0",
      LY_RMS: "0",
      LY_REV: "0",
      PRE2_RMS: 0,
      PRE2_REV: 0,
      PRE3_RMS: 0,
      PRE3_REV: 0,
      PRE4_RMS: 0,
      PRE4_REV: 0,
    },
    {
      GuestName: "-",
      CY_RMS: "0",
      CY_REV: "0",
      LY_RMS: "0",
      LY_REV: "0",
      PRE2_RMS: 0,
      PRE2_REV: 0,
      PRE3_RMS: 0,
      PRE3_REV: 0,
      PRE4_RMS: 0,
      PRE4_REV: 0,
    },
    {
      GuestName: "-",
      CY_RMS: "0",
      CY_REV: "0",
      LY_RMS: "0",
      LY_REV: "0",
      PRE2_RMS: 0,
      PRE2_REV: 0,
      PRE3_RMS: 0,
      PRE3_REV: 0,
      PRE4_RMS: 0,
      PRE4_REV: 0,
    },
    {
      GuestName: "-",
      CY_RMS: "0",
      CY_REV: "0",
      LY_RMS: "0",
      LY_REV: "0",
      PRE2_RMS: 0,
      PRE2_REV: 0,
      PRE3_RMS: 0,
      PRE3_REV: 0,
      PRE4_RMS: 0,
      PRE4_REV: 0,
    },
    {
      GuestName: "-",
      CY_RMS: "0",
      CY_REV: "0",
      LY_RMS: "0",
      LY_REV: "0",
      PRE2_RMS: 0,
      PRE2_REV: 0,
      PRE3_RMS: 0,
      PRE3_REV: 0,
      PRE4_RMS: 0,
      PRE4_REV: 0,
    },
  ];

  const yearsArr = [
    ...commonService.getPastYearForDropDown(10),
    ...commonService.getFutureYearForDropDown(10),
    new Date().getFullYear(),
  ]?.sort(function (a, b) {
    return a - b;
  });

  const [loading, setLoading] = useState(false);
  const [top50Data, setTop50Data] = useState(null);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [widgetModal, setWidgetModal] = useState(false);
  const widgetDetailRef = useRef();
  const [showNoteSidebar, setShowNoteSidebar] = useState(false);
  const propertyDetailFromRTK = useSelector(
    (store) => store.headerProperty.propertyDetail
  );
  const defaultAsOfDateFromRTK = useSelector(
    (store) => store.headerProperty.defaultAsOfDate
  );
  const { snapshottitle, propertyCode, snapshotId } = useParams();
  const closeNoteSideBar = () => {
    setShowNoteSidebar(false);
  };

  const closeWidgetModal = () => {
    setWidgetModal(false);
  };

  const getTop50Guest = async () => {
    setLoading(true);
    let top50GuestResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.SnapShot +
          API_URL.TOP_50_BY_REVENUE.GET_TOP_50_BY_REVENUE,
        body: {
          propertyCode: para?.propertyCode,
          AsOfDate: para?.asOfDate,
          year: `${selectedYear}`,
        },
      },
      false
    );

    let response = apiResponse(false, top50GuestResponse);

    if (response?.isValidate) {
      setTop50Data(response?.data?.data);
      setLoading(false);
    }

    if (!response?.isValidate) {
      setTop50Data(tableData);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (api === 0) {
      setTop50Data(tableData);
    } else {
      if (para?.asOfDate) {
        getTop50Guest();
      }
    }
  }, [para?.asOfDate, selectedYear]);

  const [tblids, settblids] = useState({
    top50ByRevenueTable: commonService.GenerateGUID(),
  });

  return (
    <div className="widget-container">
      {api !== 0 && (
        <div className="gap-1 widget-container-menu">
          <Button className="heading-menu-btns" type="button">
            <img
              src={NoteIcon}
              alt="menuIcon"
              onClick={() => {
                setShowNoteSidebar(true);
              }}
            />
          </Button>
          <Button className="heading-menu-btns" type="button" title="Send Mail">
            <img
              src={emailIcon}
              alt="emailIcon"
              onClick={() => {
                commonService.fnSendWidgetMail(
                  tblids.top50ByRevenueTable,
                  "Top 50 By Revenue",
                  snapshotId,
                  defaultAsOfDateFromRTK,
                  "",
                  para?.widgetId
                );
              }}
            />
          </Button>
          <Button className="heading-menu-btns" type="button">
            <img
              src={infoIcon}
              alt="info"
              onClick={() => {
                widgetDetailRef.current = {
                  widgetdetails: "",
                  usernote: "",
                  compName: compName?.top50ByRevenue,
                  widgetId: para?.widgetId,
                };
                setWidgetModal(true);
              }}
            />
          </Button>
          <Button className="heading-menu-btns" type="button">
            <img
              src={downloadIcon}
              alt="menuIcon"
              import={tblids.top50ByRevenueTable}
              onClick={() =>
                commonService.fnExportInCSV(
                  tblids.top50ByRevenueTable,
                  // "Top 50 By Revenue"
                  `${para?.propertyCode}-Top 50 By Revenue-${moment(
                    new Date()
                  ).format("MMDDYYYY")}`
                )
              }
            />
          </Button>
        </div>
      )}
      <div className="ViewPropertySetupTable">
        <div className="stripped__table">
        <div className="table-responsive tblDynamicPickupSticky">
          <Table id={tblids.top50ByRevenueTable}>
            <thead className="tbl-header-sticky">
              <tr className="table-main-header-tr">
                <th className="text-center" colSpan={11}>
                  Top 50 by Revenue
                </th>
              </tr>
              <tr className="table-main-header-tr">
                <th className="border_right">
                  <div>
                    <Form.Select
                      value={selectedYear}
                      onChange={(e) => {
                        setSelectedYear(e.target.value);
                      }}
                    >
                      {yearsArr?.map((item, index) => (
                        <option key={index} value={item}>
                          {item}
                        </option>
                      ))}
                    </Form.Select>
                    <span style={{ display: "none" }}>{selectedYear}</span>
                  </div>
                </th>
                <th colSpan={2} className="text-center border_right">
                  {selectedYear}
                </th>
                <th colSpan={2} className="text-center border_right">
                  {selectedYear - 1}
                </th>
                <th colSpan={2} className="text-center border_right">
                  {selectedYear - 2}
                </th>
                <th colSpan={2} className="text-center border_right">
                  {selectedYear - 3}
                </th>
                <th colSpan={2} className="text-center">
                  {selectedYear - 4}
                </th>
              </tr>
              <tr className="table-main-header-tr">
                <th className="border_right">Guest Name</th>
                <th className="text-center">Total Rooms Sold</th>
                <th className="text-end border_right">Total Revenue</th>
                <th className="text-center">Total Rooms Sold</th>
                <th className="text-end border_right">Total Revenue</th>
                <th className="text-center">Total Rooms Sold</th>
                <th className="text-end border_right">Total Revenue</th>
                <th className="text-center">Total Rooms Sold</th>
                <th className="text-end border_right">Total Revenue</th>
                <th className="text-center">Total Rooms Sold</th>
                <th className="text-end">Total Revenue</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr style={{ height: "80px", position: "relative" }}>
                  <Loader />
                </tr>
              ) : (
                top50Data?.map((item, index) => {
                  return (
                    <tr className="shadowOnHover">
                      <td className="border_right">
                        {item?.GuestName ? item?.GuestName : "-"}
                      </td>
                      <td className="text-center">
                        {item?.CY_RMS ? commonService.formateRoom(item?.CY_RMS) : 0}
                      </td>
                      <td className="text-end border_right">
                        {item?.CY_REV
                          ? commonService.formateAmount(item?.CY_REV)
                          : commonService.formateAmount(0)}
                      </td>

                      <td className="text-center">
                        {item?.LY_RMS ? commonService.formateRoom(item?.LY_RMS) : 0}
                      </td>
                      <td className="text-end border_right">
                        {item?.LY_REV
                          ? commonService.formateAmount(item?.LY_REV)
                          : commonService.formateAmount(0)}
                      </td>

                      <td className="text-center">
                        {item?.PRE2_RMS ? commonService.formateRoom(item?.PRE2_RMS) : 0}
                      </td>
                      <td className="text-end border_right">
                        {item?.PRE2_REV
                          ? commonService.formateAmount(item?.PRE2_REV)
                          : commonService.formateAmount(0)}
                      </td>

                      <td className="text-center">
                        {item?.PRE3_RMS ? commonService.formateRoom(item?.PRE3_RMS) : 0}
                      </td>
                      <td className="text-end border_right">
                        {item?.PRE3_REV
                          ? commonService.formateAmount(item?.PRE3_REV)
                          : commonService.formateAmount(0)}
                      </td>

                      <td className="text-center">
                        {item?.PRE4_RMS ? commonService.formateRoom(item?.PRE4_RMS) : 0}
                      </td>
                      <td className="text-end">
                        {item?.PRE4_REV
                          ? commonService.formateAmount(item?.PRE4_REV)
                          : commonService.formateAmount(0)}
                      </td>
                    </tr>
                  );
                })
              )}
            </tbody>
          </Table>
        </div></div>
      </div>
      {/* {showNoteSidebar && (
        <WidgetNote
          show={showNoteSidebar}
          handleClose={closeNoteSideBar}
          widgetId={para?.widgetId}
          propertyId={propertyDetailFromRTK?.propertyid}
          rowTitle={para?.rowTitle}
        />
      )} */}
      {showNoteSidebar && (
        <AddWidgetModal
          showModal={showNoteSidebar}
          closeModal={closeNoteSideBar}
          widgetId={para?.widgetId}
          propertyId={propertyDetailFromRTK?.propertyid}
          rowTitle={para?.rowTitle}
        />
      )}
      {widgetModal && (
        <WidgetDetailModal
          showModal={widgetModal}
          closeModal={closeWidgetModal}
          data={widgetDetailRef.current}
        />
      )}
    </div>
  );
};

export default Top50ByRevenue;
