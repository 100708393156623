import React, { useEffect, useRef, useState } from "react";
import { Button, Form, Offcanvas } from "react-bootstrap";
import { apiCall } from "../../utils/axiosService";
import { API_URL } from "../../utils/apiRoutes";
import { apiResponse } from "../../utils/apiResponse";
import { commonService } from "../../utils/commonService";
import moment from "moment";
import { successToastMessage } from "../../utils/toastMessage";
import Loader from "../Loader/Loader";
import viewIcon from "../../Assets/Images/Action Icons/viewaction.svg";
import TodoDetailModal from "./TodoDetailModal";
import plusIcon from "../../Assets/Images/FormCheck/plusIcon.png";
import AddTodoModal from "./AddTodoModal";

function HeaderToDoList({ show, handleClose }) {
  const [todoList, setTodoList] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const todoIdRef = useRef();
  const [addTodoModal, setAddTodoModal] = useState(false);

  const closeTodoModal = () => {
    setShowModal(false);
  };

  const closeAddTodoModal = () => {
    setAddTodoModal(false);
  };

  const getTodoList = async () => {
    setLoading(true);
    let todoListResponse = await apiCall(
      {
        method: "POST",
        url: API_URL.BASE_API_URL.Configuration + API_URL.TODO.GET_LIST,
        body: {
          startdate: null,
          enddate: null,
          status: "",
        },
      },
      false
    );

    let response = apiResponse(false, todoListResponse);

    if (response?.isValidate) {
      let dataFromApi = response?.data?.data?.notedata;
      let groupingJson = {};
      for (let i = 0; i < dataFromApi?.length; i++) {
        let dateKey = commonService.getDateInFormat(dataFromApi?.[i]?.tododate);

        if (groupingJson?.hasOwnProperty(dateKey)) {
          groupingJson = {
            ...groupingJson,
            [dateKey]: [...groupingJson[dateKey], dataFromApi?.[i]],
          };
        } else {
          groupingJson = { ...groupingJson, [dateKey]: [dataFromApi?.[i]] };
        }
      }
      setTodoList(groupingJson);
      setLoading(false);
    }

    if (!response?.isValidate) {
      setTodoList(null);
      setLoading(false);
    }
  };

  const updateTodoList = async (todoId, todostatus) => {
    let updateTodoListResponse = await apiCall(
      {
        method: "POST",
        url: API_URL.BASE_API_URL.Configuration + API_URL.TODO.UPDATE_TODO,
        body: {
          todoid: todoId,
          status: todostatus,
        },
      },
      false
    );

    let response = apiResponse(false, updateTodoListResponse);

    if (response?.isValidate) {
      getTodoList();
      successToastMessage(response?.data?.message);
    }
  };

  useEffect(() => {
    getTodoList();
  }, []);

  return (
    <Offcanvas show={show} onHide={handleClose} placement="end">
      <Offcanvas.Header closeButton>
        <div className="todo-header">
          <Offcanvas.Title>To-Do List</Offcanvas.Title>
          <Button
            className="add-btn-plus"
            type="button"
            title="Add"
            onClick={() => {
              todoIdRef.current = 0;
              setAddTodoModal(true);
            }}
          >
            <img src={plusIcon} alt="plusIcon" /> Add
          </Button>
        </div>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <div className="headerToDoListMainWrapper">
          <div className="form-groups">
            {loading ? (
              <Loader />
            ) : (
              todoList &&
              Object.entries(todoList).map(([key, value], index) => {
                return (
                  <div className="checkbox-container" key={`${key}`}>
                    <h5>{moment(new Date(key)).format("MMM DD, dddd")}</h5>
                    {value?.map((valueItem, valueIndex) => {
                      return (
                        <div className="singleTodo">
                          <div
                            className="form-checkbox"
                            style={{margin:0}}
                            key={valueItem?.todoid}
                          >
                            <input
                              type="checkbox"
                              className="form-check-input"
                              value="Option 1"
                              id={valueItem?.todoid}
                              checked={
                                valueItem?.status === "Not Done" ? false : true
                              }
                              onChange={() => {
                                let status = "Done";
                                if (valueItem?.status === "Done") {
                                  status = "Not Done";
                                }
                                updateTodoList(valueItem?.todoid, status);
                              }}
                            />
                            <Form.Check.Label htmlFor={valueItem?.todoid}>
                              <span>{valueItem?.title}</span>
                            </Form.Check.Label>
                          </div>
                          <div>
                            <img
                              src={viewIcon}
                              alt="viewIcon"
                              style={{ cursor: "pointer" }}
                              onClick={(e) => {
                                todoIdRef.current = valueItem?.todoid;
                                setShowModal(true);
                              }}
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                );
              })
            )}
          </div>
          {showModal && (
            <TodoDetailModal
              showModal={showModal}
              closeModal={closeTodoModal}
              todoId={todoIdRef.current}
              getList={getTodoList}
            />
          )}
          {addTodoModal && (
            <AddTodoModal
              showModal={addTodoModal}
              closeModal={closeAddTodoModal}
              todoId={todoIdRef.current}
              getList={getTodoList}
            />
          )}
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
}

export default HeaderToDoList;
