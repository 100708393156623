import React, { useEffect, useMemo, useRef, useState } from "react";
import { commonService } from "../../../../utils/commonService";
import { apiCall } from "../../../../utils/axiosService";
import { apiResponse } from "../../../../utils/apiResponse";
import { useSelector } from "react-redux";
import moment from "moment";
import { API_URL } from "../../../../utils/apiRoutes";
import { useParams } from "react-router-dom";
import { Col, Form, Overlay, Popover, Row } from "react-bootstrap";
import DaywiseModal from "./DaywiseModal";
import $ from "jquery";
import OutlookDayWise from "./OutlookDayWise";
import calendarIcon from "../../../../Assets/Images/Revenue-management/calendarIcon.svg";
import { style } from "d3";

const HeatMapDaywise = ({
  setCompDataFromChild,
  rowIndex,
  colIndex,
  api,
  para,
  setting,
}) => {
  const userDetail = commonService.getLoginUserData();
  const yearsArr = [
    ...commonService.getPastYearForDropDown(5),
    ...commonService.getFutureYearForDropDown(2),
    new Date().getFullYear(),
  ]?.sort(function (a, b) {
    return a - b;
  });
  const [selectedYear, setSelectedYear] = useState(
    `${new Date().getFullYear()}`
    // "Default"
  );

  let monthNumberFromName = {
    January: 1,
    February: 2,
    March: 3,
    April: 4,
    May: 5,
    June: 6,
    July: 7,
    August: 8,
    September: 9,
    October: 10,
    November: 11,
    December: 12,
  };

  const [heatMapDetail, setHeatMapDetail] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedType, setSelectedType] = useState({
    isPickupFrom: false,
    isOnTheBook: true,
  });
  const [maxDateForPickupFrom, setMaxDateForPickupFrom] = useState();
  const maxDateRef = useRef();
  if (maxDateForPickupFrom) {
    let date = new Date(maxDateForPickupFrom);
    maxDateRef.current = date.setDate(date.getDate() - 1);
  }
  const monthNameDetail = useRef([]);
  const date = new Date();
  let startDate = commonService.getDateInDBFormat(
    new Date(date.getFullYear(), date.getMonth(), 1)
  );

  let endDate = commonService.getDateInDBFormat(
    date.setFullYear(date.getFullYear() + 1, date.getMonth() + 1, 0)
  );

  let currentYearStartDate = `${new Date().getFullYear()}-01-01`;
  let currentYearEndDate = `${new Date().getFullYear()}-12-31`;

  let currentWeekStartDate = commonService.getDateForNode(
    moment().startOf("week")
  );
  let currentWeekEndDate = commonService.getDateForNode(moment().endOf("week"));

  const [payloadStartDate, setPayloadStartDate] =
    useState(currentYearStartDate);
  const [payloadEndDate, setPayloadEndDate] = useState(currentYearEndDate);
  const [selectPickupDate, setSelectPickupDate] = useState();
  const [selectGroupType, setSelectGroupType] = useState("All");
  const [totalData, setTotalData] = useState(null);
  const selectedTdIndexRef = useRef();
  const singleDateSelectedDateRef = useRef();
  const pickupDateForTotalOTB = useRef();
  const [maxRoom, setMaxRoom] = useState(0);
  const [dateRange, setDateRange] = useState({
    startDate: commonService.convertDateInTimezone(currentWeekStartDate),
    endDate: commonService.convertDateInTimezone(currentWeekEndDate),
  });
  function selectWeek(date) {
    return Array(7)
      .fill(new Date(date))
      .map((el, idx) =>
        moment(new Date(el.setDate(el.getDate() - el.getDay() + idx))).format(
          "YYYY-MM-DD"
        )
      );
  }
  let currentWeekArr = selectWeek(new Date());
  const [widgetModal, setWidgetModal] = useState(false);
  const weekDayObjRef = useRef();
  const [openCalendar, setOpenCalendar] = useState(false);
  const [elementId, setElementId] = useState(null);

  const getMonthNameFromMonthNumber = (dateFromResponse) => {
    let date = moment(dateFromResponse).toDate();
    return date.toLocaleString("en-US", { month: "long" });
  };

  const setDateForApi = (value) => {
    if (value === "Default") {
      setPayloadStartDate(startDate);
      setPayloadEndDate(endDate);
    } else {
      setPayloadStartDate(`${value}-01-01`);
      setPayloadEndDate(`${value}-12-31`);
    }
    setSelectedYear(value);
  };

  const getHeatMapDetail = async () => {
    setHeatMapDetail(null);
    weekDayObjRef.current = {};
    let pickupDate = new Date(para?.asOfDate);
    pickupDate.setDate(pickupDate.getDate() - 1);
    setLoading(true);
    let heatMapResponse = await apiCall(
      {
        method: "POST",
        url: API_URL.BASE_API_URL.SnapShot + API_URL.HEAT_MAP,
        body: {
          AsOfDate: para?.asOfDate,
          startDate: payloadStartDate,
          endDate: payloadEndDate,
          propertyCode: para?.propertyCode,
          heatMapType: selectedType?.isPickupFrom ? "PICKUPFROM" : "ONTHEBOOK",
          pickupDate: selectPickupDate ? selectPickupDate : pickupDate,
          hm_tg_type: selectGroupType,
        },
      },
      false
    );

    let response = apiResponse(false, heatMapResponse);

    if (response?.isValidate) {
      monthNameDetail.current = [];
      if (selectedYear === "Default") {
        let currentDate = new Date();
        let dateForMonth = moment(
          new Date(currentDate.getFullYear(), currentDate.getMonth(), 1)
        ).toDate();
        // let dateForMonth = moment(new Date()).toDate();
        for (let i = 0; i <= 12; i++) {
          let monthName = getMonthNameFromMonthNumber(dateForMonth);
          monthNameDetail.current = [
            ...monthNameDetail.current,
            `${monthName}/${dateForMonth?.getFullYear()}`,
          ];

          dateForMonth.setMonth(dateForMonth.getMonth() + 1);
        }
      } else {
        let dateForMonth = moment(new Date(selectedYear, 0, 1)).toDate();
        for (let i = 0; i <= 11; i++) {
          let monthName = getMonthNameFromMonthNumber(dateForMonth);
          monthNameDetail.current = [
            ...monthNameDetail.current,
            `${monthName}/${dateForMonth?.getFullYear()}`,
          ];

          dateForMonth.setMonth(dateForMonth.getMonth() + 1);
        }
      }

      let responseData = response?.data?.data?.result;
      let splitMonth = monthNameDetail.current?.[0]?.split("/");
      singleDateSelectedDateRef.current = new Date();

      let groupingJson = {},
        totalData = {};
      responseData?.sort(function (a, b) {
        return (
          moment(new Date(a.Dates)).toDate() -
          moment(new Date(b.Dates)).toDate()
        );
      });

      for (let i = 0; i < responseData?.length; i++) {
        let dateKey = moment(responseData?.[i]?.Dates).toDate();
        let monthName = getMonthNameFromMonthNumber(responseData?.[i]?.Dates);
        let monthIndex = monthNameDetail.current?.findIndex(
          (x) => x === `${monthName}/${dateKey?.getFullYear()}`
        );
        let percentage = 0;
        if (responseData?.[i]?.RMS === 0) {
          percentage = 0;
        } else {
          percentage =
            (100 * responseData?.[i]?.RMS) / response?.data?.data?.maxRoom;
        }
        if (monthIndex < 0) {
          monthNameDetail.current = [
            ...monthNameDetail.current,
            `${monthName}/${dateKey?.getFullYear()}`,
          ];
        }
        if (groupingJson?.hasOwnProperty(dateKey?.getDate())) {
          groupingJson[dateKey.getDate()] = {
            ...groupingJson?.[dateKey?.getDate()],
            [`${monthName}/${dateKey?.getFullYear()}`]: {
              ...responseData?.[i],
              percentage,
            },
          };
        } else {
          groupingJson = {
            ...groupingJson,
            [dateKey?.getDate()]: {
              [`${monthName}/${dateKey?.getFullYear()}`]: {
                ...responseData?.[i],
                percentage,
              },
            },
          };
        }
        if (
          totalData?.rms?.hasOwnProperty(
            `${monthName}/${dateKey?.getFullYear()}`
          )
        ) {
          totalData.rms[`${monthName}/${dateKey?.getFullYear()}`].rms =
            totalData.rms[`${monthName}/${dateKey?.getFullYear()}`].rms +
            (responseData?.[i]?.RMS ? responseData?.[i]?.RMS : 0);

          totalData.rev[`${monthName}/${dateKey?.getFullYear()}`].rev =
            totalData.rev[`${monthName}/${dateKey?.getFullYear()}`].rev +
            (responseData?.[i]?.RMS ? responseData?.[i]?.RMS : 0) *
              (responseData?.[i]?.ADR ? responseData?.[i]?.ADR : 0);

          totalData.adr[`${monthName}/${dateKey?.getFullYear()}`].adr = 0;
        } else {
          totalData = {
            rms: {
              ...totalData.rms,
              [`${monthName}/${dateKey?.getFullYear()}`]: {
                rms: responseData?.[i]?.RMS ? responseData?.[i]?.RMS : 0,
              },
            },
            rev: {
              ...totalData.rev,
              [`${monthName}/${dateKey?.getFullYear()}`]: {
                rev:
                  (responseData?.[i]?.RMS ? responseData?.[i]?.RMS : 0) *
                  (responseData?.[i]?.ADR ? responseData?.[i]?.ADR : 0),
              },
            },
            adr: {
              ...totalData.adr,
              [`${monthName}/${dateKey?.getFullYear()}`]: {
                adr: 0,
              },
            },
          };
        }
      }
      setMaxRoom(response?.data?.data?.maxRoom);
      setHeatMapDetail(groupingJson);
      setTotalData(totalData);
      setLoading(false);
    }

    if (!response?.isValidate) {
      setLoading(false);
      setHeatMapDetail(null);
    }
  };

  useEffect(() => {
    if (api === 0) {
    } else {
      if (para?.asOfDate) {
        let d = moment(new Date(para?.asOfDate)).toDate();
        d.setDate(d.getDate() - 1);
        pickupDateForTotalOTB.current = moment(d).format("YYYY-MM-DD");
        setMaxDateForPickupFrom(new Date(para?.defaultAsOfDate));
        getHeatMapDetail();
      }
    }
  }, [
    para?.asOfDate,
    selectedType,
    selectedYear,
    selectGroupType,
    selectPickupDate,
  ]);

  const renderCalendar = (calDate, monthName) => {
    const calendar = [];
    let MonthFirstDate = new Date(calDate.getFullYear(), calDate.getMonth(), 1);
    let MonthLastDate = new Date(
      calDate.getFullYear(),
      calDate.getMonth() + 1,
      0
    );

    let CalFirstDate = new Date(calDate.getFullYear(), calDate.getMonth(), 1);
    CalFirstDate = new Date(
      CalFirstDate.setDate(CalFirstDate.getDate() - CalFirstDate.getDay())
    );
    let monthNumber = moment().month(monthName?.split("/")?.[0]).format("M");

    for (let i = 0; i < 6; i++) {
      let row = [],
        weekNumber = "",
        weekTrValue = "",
        startDateForWeek = "",
        endDateForWeek = "";
      for (let j = 0; j < 7; j++) {
        // let createdDateMonthWise=new Date(CalFirstDate.getFullYear(),)
        let DTDate = commonService.convertDateInTimezone(CalFirstDate);
        if (j === 0) {
          weekNumber = moment(commonService.getDateInFormat(DTDate)).week();
          weekTrValue = `${moment(
            commonService.getDateInFormat(DTDate)
          ).week()}/${new Date(
            commonService.getDateInFormat(DTDate)
          ).getFullYear()}`;
          startDateForWeek = commonService.getDateForNode(DTDate);
        }
        if (j === 6) {
          endDateForWeek = commonService.getDateForNode(DTDate);
        }
        let className = "lightGrayColor",
          fontBold = false;
        // if (CalFirstDate >= MonthFirstDate && CalFirstDate <= MonthLastDate) {
        // if (weekDayObjRef.current[commonService.getDateInFormat(DTDate)]) {
        // } else {
        // weekDayObjRef.current[commonService.getDateInFormat(DTDate)] =
        //   commonService.getDateInFormat(DTDate);
        let month = getMonthNameFromMonthNumber(CalFirstDate);
        let dataForCurrentdate =
          heatMapDetail[CalFirstDate.getDate()][
            `${month}/${CalFirstDate.getFullYear()}`
          ];

        if (
          dataForCurrentdate?.percentage >= 0 &&
          dataForCurrentdate?.percentage <= 24
        ) {
          className = "lightGrayColor";
        } else if (
          dataForCurrentdate?.percentage >= 25 &&
          dataForCurrentdate?.percentage <= 49
        ) {
          className = "lightBlueColor";
        } else if (
          dataForCurrentdate?.percentage >= 50 &&
          dataForCurrentdate?.percentage <= 74
        ) {
          className = "yellowColor";
        } else if (
          dataForCurrentdate?.percentage >= 75 &&
          dataForCurrentdate?.percentage <= 100
        ) {
          className = "orangeColor";
        }
        let isDateFromCurrentWeek = currentWeekArr?.filter(
          (x) => x === moment(DTDate).format("YYYY-MM-DD")
        );

        if (isDateFromCurrentWeek?.length > 0) {
          fontBold = true;
        }
        row.push(
          <td
            key={`${i}-${j}`}
            className={`${className} text-center cursorPointer ${
              fontBold ? "heatMapBold" : ""
            }`}
            onClick={(e) => {
              weekDayObjRef.current = {};
              singleDateSelectedDateRef.current = moment(
                new Date(DTDate)
              ).format("YYYY-MM-DD");
              setDateRange({
                startDate: commonService.convertDateInTimezone(
                  commonService.getDateForNode(DTDate)
                ),
                endDate: commonService.convertDateInTimezone(
                  commonService.getDateForNode(DTDate)
                ),
              });
              // if (dayWiseCompList?.length > 2) {
              //   setDayWiseCompList([singleDateSelectedDateRef.current]);
              // } else {
              //   setDayWiseCompList((prev) => [
              //     ...prev,
              //     singleDateSelectedDateRef.current,
              //   ]);
              // }
            }}
          >
            {CalFirstDate.getDate()}
          </td>
        );
        CalFirstDate = new Date(
          CalFirstDate.setDate(CalFirstDate.getDate() + 1)
        );
      }
      let currentWeek =
        moment(new Date()).week() === weekNumber &&
        new Date().getFullYear() == weekTrValue?.split("/")?.[1];
      // row.push(
      //   <td
      //     className={`${currentWeek ? "heatMapBold" : ""} cursorPointer`}
      //   >{`Week ${weekNumber}`}</td>
      // );
      row.push(
        <td
          className={`${
            currentWeek ? "heatMapBold" : ""
          } cursorPointer text-center`}
        >
          <input
            onClick={(e) => {
              setDateRange({
                startDate:
                  commonService.convertDateInTimezone(startDateForWeek),
                endDate: commonService.convertDateInTimezone(endDateForWeek),
              });
            }}
            type="radio"
            className="form-check-input cursorPointer"
            name="heatMapDaywiseRadioGroup"
          />
        </td>
      );
      calendar.push(
        <tr value={weekTrValue} key={i}>
          {row}
        </tr>
      );
    }
    return calendar;
  };

  const removeDuplicateTr = () => {
    $(document).ready(function () {
      let mainDiv = document.querySelectorAll("#heamMapDayWiseTbl");
      let existingWeek = {};
      mainDiv?.forEach((item) => {
        let table = item?.querySelector("table");
        let tbody = table?.querySelectorAll("tbody tr");

        tbody?.forEach((trItem, trIndex) => {
          let weekNumber = trItem?.attributes?.value?.nodeValue;
          if (existingWeek[weekNumber]) {
            trItem?.remove();
          } else {
            existingWeek[weekNumber] = weekNumber;
          }
        });
      });
      // let elementId = `${new Date().toLocaleString("en-US", {
      //   month: "long",
      // })}${new Date().getFullYear()}`;
      // let element = document.getElementById(`${elementId}`);
      // if (element) {
      //   element?.scrollIntoView({ behavior: "smooth" });
      // }
    });
  };

  const removeTr = useMemo(() => removeDuplicateTr(), [heatMapDetail]);

  const daysOfWeek = ["S", "M", "T", "W", "T", "F", "S", ""];

  // useEffect(() => {
  //   let element = document.getElementById(`${elementId}`);
  //   if (element) {
  //     element?.scrollIntoView({ behavior: "smooth" });
  //   }
  // }, [elementId]);

  return (
    <div>
      <Row>
        <Col xl={8}>
          <Row>
            <div className="from-groups no-border-break width110">
              <Form.Select
                aria-label="Default select example"
                value={selectedYear}
                onChange={(e) => {
                  setDateForApi(e.target.value);
                }}
              >
                <option value="Default">Default</option>
                {yearsArr?.map((item, index) => (
                  <option key={item} value={item}>
                    {item}
                  </option>
                ))}
              </Form.Select>
            </div>
            <div className="from-groups no-border-break width125">
              <Form.Select
                value={selectGroupType}
                onChange={(e) => {
                  setSelectGroupType(e.target.value);
                }}
              >
                <option value="All">All</option>
                <option value="Transient">Transient</option>
                <option value="Group">Group</option>
              </Form.Select>
            </div>
          </Row>
        </Col>
        {/*<Col xl={4}>*/}
        {/*    <div*/}
        {/*        className="generalTermSetup-heading-btns"*/}
        {/*        style={{ justifyContent: "end" }}*/}
        {/*    >*/}
        {/*        <div*/}
        {/*            className="heading-menu-btns cursorPointer"*/}
        {/*            onClick={(e) => {*/}
        {/*                let elementId = `${new Date().toLocaleString("en-US", {*/}
        {/*                    month: "long",*/}
        {/*                })}${new Date().getFullYear()}`;*/}
        {/*                setOpenCalendar(!openCalendar);*/}
        {/*                setElementId(elementId);*/}
        {/*            }}*/}
        {/*        >*/}
        {/*            <img src={calendarIcon} />*/}
        {/*        </div>*/}
        {/*    </div>*/}
        {/*</Col>*/}
      </Row>
      <Row>
        <Col className="asidecalendar">
          <div
            style={{
              width: "100%",
              maxHeight: "calc(100vh - 190px)",
              overflowY: "auto",
              //zIndex: 999999,
              background: "white",
              marginTop: "5px",
              border: "1px solid #3565fc80",
              borderRadius: "5px",
            }}
            className="asidecalendar-inner"
          >
            {heatMapDetail &&
              monthNameDetail.current?.map((item) => {
                let month = item;
                let monthname = month?.split("/");
                let dateString = `${monthname?.[1]}-${monthname?.[0]}-1`;
                let formatDate = moment(
                  // `${monthname?.[1]}-${monthname?.[0]}-1`
                  commonService.convertDateInTimezone(dateString)
                ).format("YYYY-MM-DD");
                return (
                  <div
                    id="heamMapDayWiseTbl"
                    style={{ width: "100%" }}
                    className="mt-2"
                  >
                    <p
                      className="text-center asidecalendar-month-name"
                      id={`${monthname?.[0]}${monthname?.[1]}`}
                    >
                      {item}
                    </p>
                    <table>
                      <thead>
                        <tr>
                          {daysOfWeek.map((day) => (
                            <th
                              style={{ fontSize: "14px" }}
                              className="text-center"
                              key={day}
                            >
                              {day}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {renderCalendar(
                          commonService.convertDateInTimezone(
                            new Date(`${formatDate} 00:00:00`)
                          ),
                          item
                        )}
                      </tbody>
                    </table>
                  </div>
                );
              })}
            {removeTr}
          </div>
        </Col>
        <Col className="asidecalendartable">
          <div style={{ maxHeight: "79vh", overflow: "auto" }}>
            {para?.propertyCode &&
              para?.asOfDate &&
              pickupDateForTotalOTB.current &&
              singleDateSelectedDateRef.current &&
              dateRange && (
                <div className="mt-2">
                  <DaywiseModal
                    asOfDate={para?.asOfDate}
                    pickupDate={
                      selectedType?.isPickupFrom
                        ? selectPickupDate
                        : pickupDateForTotalOTB.current
                    }
                    propertyDetail={{ propertyCode: para?.propertyCode }}
                    dateRange={dateRange}
                  />
                </div>
              )}
          </div>
          <div>
            {para?.propertyCode &&
              para?.asOfDate &&
              pickupDateForTotalOTB.current &&
              singleDateSelectedDateRef.current &&
              dateRange && (
                <div className="mt-2">
                  <OutlookDayWise api={api} para={para} dateRange={dateRange} />
                </div>
              )}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default HeatMapDaywise;
