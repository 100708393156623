import React, { useEffect, useState } from "react";
import { Button, Col, Form, Offcanvas, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { apiCall } from "../../../../utils/axiosService";
import { API_URL } from "../../../../utils/apiRoutes";
import { apiResponse } from "../../../../utils/apiResponse";
import { commonService } from "../../../../utils/commonService";

const FilterModal = ({
  showModal,
  closeModal,
  propertyCode,
  leftFilterProp,
  rightFilterProp,
  updateFilter,
}) => {
  let statusValue = {
    C: "Cancelled",
    I: "Checked In",
    N: "No Show",
    O: "Checked Out",
    R: "Reserved",
  };

  const [leftFilter, setLeftFilter] = useState(leftFilterProp);
  const [rightFilter, setRightFilter] = useState(rightFilterProp);
  const [leftStartDate, setLeftStartDate] = useState();
  const [leftEndDate, setLeftEndDate] = useState();
  const [rightStartDate, setRightStartDate] = useState();
  const [rightEndDate, setRightEndDate] = useState();
  const [leftBookingStart, setLeftBookingStart] = useState();
  const [leftBookingEnd, setLeftBookingEnd] = useState();
  const [rightBookingStart, setRightBookingStart] = useState();
  const [rightBookingEnd, setRightBookingEnd] = useState();
  const handleDateChange = (event, filterSide) => {
    const [start, end] = event;
    let startSelectedDate = "",
      endSelectedDate = "";
    if (start) {
      let date = new Date(start);
      let month =
        date.getMonth() + 1 > 9
          ? date.getMonth() + 1
          : `0${date.getMonth() + 1}`;
      let dateFormat =
        date?.getDate() > 9 ? date.getDate() : `0${date.getDate()}`;
      startSelectedDate = `${date.getFullYear()}-${month}-${dateFormat}`;
      //   startRangeDateRef.current = startSelectedDate;
    }
    if (end) {
      let date = new Date(end);
      let month =
        date.getMonth() + 1 > 9
          ? date.getMonth() + 1
          : `0${date.getMonth() + 1}`;
      let dateFormat =
        date?.getDate() > 9 ? date.getDate() : `0${date.getDate()}`;
      endSelectedDate = `${date.getFullYear()}-${month}-${dateFormat}`;
      //   endRangeDateRef.current = endSelectedDate;
    }

    if (filterSide === "left") {
      setLeftStartDate(event[0]);
      setLeftEndDate(event[1]);
    } else if (filterSide === "right") {
      setRightStartDate(event[0]);
      setRightEndDate(event[1]);
    } else if (filterSide === "bookingLeft") {
      setLeftBookingStart(event[0]);
      setLeftBookingEnd(event[1]);
    } else if (filterSide === "bookingRight") {
      setRightBookingStart(event[0]);
      setRightBookingEnd(event[1]);
    }
    if (event[0] !== null && event[1] !== null) {
      //Call Api Here
    }
  };

  useEffect(() => {
    setLeftFilter(leftFilterProp);
    setRightFilter(rightFilterProp);
    setLeftStartDate(leftFilterProp?.stayDateStart);
    setLeftEndDate(leftFilterProp?.stayDateEnd);
    setLeftBookingStart(leftFilterProp?.bookStartDate);
    setLeftBookingEnd(leftFilterProp?.bookEndDate);

    setRightStartDate(rightFilterProp?.stayDateStart);
    setRightEndDate(rightFilterProp?.stayDateEnd);
    setRightBookingStart(rightFilterProp?.bookStartDate);
    setRightBookingEnd(rightFilterProp?.bookEndDate);
  }, []);

  return (
    <Offcanvas
      className="notification-canvas"
      show={showModal}
      onHide={closeModal}
      placement="end"
    >
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>
          <Button
            className="default-btn primary-btn"
            onClick={() => {
              if (leftStartDate && leftEndDate) {
                leftFilter.stayDateStart =
                  commonService.getDateForNode(leftStartDate);
                leftFilter.stayDateEnd =
                  commonService.getDateForNode(leftEndDate);
              }

              if (leftBookingStart && leftBookingEnd) {
                leftFilter.bookStartDate =
                  commonService.getDateForNode(leftBookingStart);
                leftFilter.bookEndDate =
                  commonService.getDateForNode(leftBookingEnd);
              }

              if (rightStartDate && rightEndDate) {
                rightFilter.stayDateStart =
                  commonService.getDateForNode(rightStartDate);
                rightFilter.stayDateEnd =
                  commonService.getDateForNode(rightEndDate);
              }

              if (rightBookingStart && rightBookingEnd) {
                rightFilter.bookStartDate =
                  commonService.getDateForNode(rightBookingStart);
                rightFilter.bookEndDate =
                  commonService.getDateForNode(rightBookingEnd);
              }
              updateFilter(leftFilter, rightFilter);
              closeModal();
            }}
          >
            Apply
          </Button>
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body id="canvas">
        <Row>
          <Col xl={6}>
            <div>
              <p>Left Filter</p>

              <div className="startFrom from-groups no-border-break mb-3">
                <Form.Label htmlFor="startFrom">
                  Reservation OTB as of
                </Form.Label>
                <DatePicker
                  id="asOfDateLeft"
                  selected={
                    leftFilter?.asOfDate
                      ? commonService.convertDateInTimezone(
                          leftFilter?.asOfDate
                        )
                      : ""
                  }
                  onChange={(date) => {
                    setLeftFilter({
                      ...leftFilter,
                      asOfDate: date,
                    });
                  }}
                />
              </div>

              <div className="startFrom from-groups no-border-break mb-3">
                <Form.Label htmlFor="startFrom">Stay Date</Form.Label>
                <DatePicker
                  onChange={(event) => handleDateChange(event, "left")}
                  startDate={
                    leftStartDate
                      ? commonService.convertDateInTimezone(leftStartDate)
                      : ""
                  }
                  endDate={
                    leftEndDate
                      ? commonService.convertDateInTimezone(leftEndDate)
                      : ""
                  }
                  selectsRange
                  monthsShown={2}
                />
              </div>

              <div className="startFrom from-groups no-border-break mb-3">
                <Form.Label htmlFor="startFrom">Booking Date</Form.Label>
                <DatePicker
                  onChange={(event) => handleDateChange(event, "bookingLeft")}
                  startDate={leftBookingStart ? new Date(leftBookingStart) : ""}
                  endDate={leftBookingEnd ? new Date(leftBookingEnd) : ""}
                  selectsRange
                  monthsShown={2}
                />
              </div>

              <div>
                <p>Market Segment</p>
                <div>
                  {Object?.entries(leftFilter?.marketSegment)?.map(
                    ([key, value], index) => {
                      return (
                        <div className="form-groups">
                          <div className="checkbox-container">
                            <div className="form-checkbox">
                              <input
                                id={`left${key}`}
                                type="checkbox"
                                className="form-check-input"
                                checked={value}
                                onChange={(e) => {
                                  setLeftFilter({
                                    ...leftFilter,
                                    marketSegment: {
                                      ...leftFilter.marketSegment,
                                      [key]: e.target.checked,
                                    },
                                  });
                                }}
                              />
                              <Form.Check.Label htmlFor={`left${key}`}>
                                {key}
                              </Form.Check.Label>
                            </div>
                          </div>
                        </div>
                      );
                    }
                  )}
                </div>
              </div>

              <div>
                <p>Room Type</p>
                <div>
                  {Object?.entries(leftFilter?.roomType)?.map(
                    ([key, value]) => {
                      return (
                        <div className="form-groups">
                          <div className="checkbox-container">
                            <div className="form-checkbox">
                              <input
                                id={`leftRoomType${key}`}
                                type="checkbox"
                                className="form-check-input"
                                checked={value}
                                onChange={(e) => {
                                  setLeftFilter({
                                    ...leftFilter,
                                    roomType: {
                                      ...leftFilter.roomType,
                                      [key]: e.target.checked,
                                    },
                                  });
                                }}
                              />
                              <Form.Check.Label htmlFor={`leftRoomType${key}`}>
                                {key}
                              </Form.Check.Label>
                            </div>
                          </div>
                        </div>
                      );
                    }
                  )}
                </div>
              </div>

              <div>
                <p>Reservation Status</p>
                <div>
                  {Object?.entries(leftFilter?.status)?.map(
                    ([key, value], index) => {
                      return (
                        <div className="form-groups">
                          <div className="checkbox-container">
                            <div className="form-checkbox">
                              <input
                                id={`leftStatus${key}`}
                                type="checkbox"
                                className="form-check-input"
                                checked={value}
                                onChange={(e) => {
                                  setLeftFilter({
                                    ...leftFilter,
                                    status: {
                                      ...leftFilter.status,
                                      [key]: e.target.checked,
                                    },
                                  });
                                }}
                              />
                              <Form.Check.Label htmlFor={`leftStatus${key}`}>
                                {statusValue[key]}
                              </Form.Check.Label>
                            </div>
                          </div>
                        </div>
                      );
                    }
                  )}
                </div>
              </div>

              <div>
                <p>WeekDay</p>
                <div>
                  {Object?.entries(leftFilter?.weekDays)?.map(
                    ([key, value], index) => {
                      return (
                        <div className="form-groups">
                          <div className="checkbox-container">
                            <div className="form-checkbox">
                              <input
                                id={`leftweekDay${key}`}
                                type="checkbox"
                                className="form-check-input"
                                checked={value}
                                onChange={(e) => {
                                  setLeftFilter({
                                    ...leftFilter,
                                    weekDays: {
                                      ...leftFilter.weekDays,
                                      [key]: e.target.checked,
                                    },
                                  });
                                }}
                              />
                              <Form.Check.Label htmlFor={`leftweekDay${key}`}>
                                {key}
                              </Form.Check.Label>
                            </div>
                          </div>
                        </div>
                      );
                    }
                  )}
                </div>
              </div>

              <div>
                <p>Lead Time</p>
                <Row>
                  <Col xl={6}>
                    <div className="from-groups no-border-break">
                      <Form.Select
                        aria-label="Default select example"
                        value={leftFilter?.leadTimeOperator}
                        onChange={(e) => {
                          setLeftFilter({
                            ...leftFilter,
                            leadTimeOperator: e.target.value,
                          });
                        }}
                      >
                        <option value=">=">{">="}</option>
                        <option value="<=">{"<="}</option>
                        <option value="=">{"="}</option>
                      </Form.Select>
                    </div>
                  </Col>
                  <Col xl={6}>
                    <div className="from-groups no-border-break">
                      <Form.Control
                        type="text"
                        value={leftFilter?.leadTime}
                        onChange={(e) => {
                          setLeftFilter({
                            ...leftFilter,
                            leadTime: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              </div>

              <div>
                <p>LOS</p>
                <Row>
                  <Col xl={6}>
                    <div className="from-groups no-border-break">
                      <Form.Select
                        aria-label="Default select example"
                        value={leftFilter?.losOperator}
                        onChange={(e) => {
                          setLeftFilter({
                            ...leftFilter,
                            losOperator: e.target.value,
                          });
                        }}
                      >
                        <option value=">=">{">="}</option>
                        <option value="<=">{"<="}</option>
                        <option value="=">{"="}</option>
                      </Form.Select>
                    </div>
                  </Col>
                  <Col xl={6}>
                    <div className="from-groups no-border-break">
                      <Form.Control
                        type="text"
                        value={leftFilter?.los}
                        onChange={(e) => {
                          setLeftFilter({
                            ...leftFilter,
                            los: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
          <Col xl={6}>
            <div>
              <p>Right Filter</p>

              <div className="startFrom from-groups no-border-break mb-3">
                <Form.Label htmlFor="startFrom">
                  Reservation OTB as of
                </Form.Label>
                <DatePicker
                  id="asOfDateRight"
                  selected={
                    rightFilter?.asOfDate
                      ? commonService.convertDateInTimezone(
                          rightFilter?.asOfDate
                        )
                      : ""
                  }
                  onChange={(date) => {
                    setRightFilter({
                      ...rightFilter,
                      asOfDate: date,
                    });
                  }}
                />
              </div>
              <div className="startFrom from-groups no-border-break mb-3">
                <Form.Label htmlFor="startFrom">Stay Date</Form.Label>
                <DatePicker
                  onChange={(event) => handleDateChange(event, "right")}
                  startDate={
                    rightStartDate
                      ? commonService.convertDateInTimezone(rightStartDate)
                      : ""
                  }
                  endDate={
                    rightEndDate
                      ? commonService.convertDateInTimezone(rightEndDate)
                      : ""
                  }
                  selectsRange
                  monthsShown={2}
                />
              </div>
              <div className="startFrom from-groups no-border-break mb-3">
                <Form.Label htmlFor="startFrom">Booking Date</Form.Label>
                <DatePicker
                  onChange={(event) => handleDateChange(event, "bookingRight")}
                  startDate={
                    rightBookingStart ? new Date(rightBookingStart) : ""
                  }
                  endDate={rightBookingEnd ? new Date(rightBookingEnd) : ""}
                  selectsRange
                  monthsShown={2}
                />
              </div>

              <div>
                <p>Market Segment</p>
                <div>
                  {Object?.entries(rightFilter?.marketSegment)?.map(
                    ([key, value], index) => {
                      return (
                        <div className="form-groups">
                          <div className="checkbox-container">
                            <div className="form-checkbox">
                              <input
                                id={`right${key}`}
                                type="checkbox"
                                className="form-check-input"
                                checked={value}
                                onChange={(e) => {
                                  setRightFilter({
                                    ...rightFilter,
                                    marketSegment: {
                                      ...rightFilter.marketSegment,
                                      [key]: e.target.checked,
                                    },
                                  });
                                }}
                              />
                              <Form.Check.Label htmlFor={`right${key}`}>
                                {key}
                              </Form.Check.Label>
                            </div>
                          </div>
                        </div>
                      );
                    }
                  )}
                </div>
              </div>

              <div>
                <p>Room Type</p>
                <div>
                  {Object?.entries(rightFilter?.roomType)?.map(
                    ([key, value]) => {
                      return (
                        <div className="form-groups">
                          <div className="checkbox-container">
                            <div className="form-checkbox">
                              <input
                                id={`rightRoomType${key}`}
                                type="checkbox"
                                className="form-check-input"
                                checked={value}
                                onChange={(e) => {
                                  setRightFilter({
                                    ...rightFilter,
                                    roomType: {
                                      ...rightFilter.roomType,
                                      [key]: e.target.checked,
                                    },
                                  });
                                }}
                              />
                              <Form.Check.Label htmlFor={`rightRoomType${key}`}>
                                {key}
                              </Form.Check.Label>
                            </div>
                          </div>
                        </div>
                      );
                    }
                  )}
                </div>
              </div>

              <div>
                <p>Reservation Status</p>
                <div>
                  {Object?.entries(rightFilter?.status)?.map(
                    ([key, value], index) => {
                      return (
                        <div className="form-groups">
                          <div className="checkbox-container">
                            <div className="form-checkbox">
                              <input
                                id={`rightStatus${key}`}
                                type="checkbox"
                                className="form-check-input"
                                checked={value}
                                onChange={(e) => {
                                  setRightFilter({
                                    ...rightFilter,
                                    status: {
                                      ...rightFilter.status,
                                      [key]: e.target.checked,
                                    },
                                  });
                                }}
                              />
                              <Form.Check.Label htmlFor={`rightStatus${key}`}>
                                {statusValue[key]}
                              </Form.Check.Label>
                            </div>
                          </div>
                        </div>
                      );
                    }
                  )}
                </div>
              </div>

              <div>
                <p>WeekDay</p>
                <div>
                  {Object?.entries(rightFilter?.weekDays)?.map(
                    ([key, value], index) => {
                      return (
                        <div className="form-groups">
                          <div className="checkbox-container">
                            <div className="form-checkbox">
                              <input
                                id={`rightweekDay${key}`}
                                type="checkbox"
                                className="form-check-input"
                                checked={value}
                                onChange={(e) => {
                                  setRightFilter({
                                    ...rightFilter,
                                    weekDays: {
                                      ...rightFilter.weekDays,
                                      [key]: e.target.checked,
                                    },
                                  });
                                }}
                              />
                              <Form.Check.Label htmlFor={`rightweekDay${key}`}>
                                {key}
                              </Form.Check.Label>
                            </div>
                          </div>
                        </div>
                      );
                    }
                  )}
                </div>
              </div>

              <div>
                <p>Lead Time</p>
                <Row>
                  <Col xl={6}>
                    <div className="from-groups no-border-break">
                      <Form.Select
                        aria-label="Default select example"
                        value={rightFilter?.leadTimeOperator}
                        onChange={(e) => {
                          setRightFilter({
                            ...rightFilter,
                            leadTimeOperator: e.target.value,
                          });
                        }}
                      >
                        <option value=">=">{">="}</option>
                        <option value="<=">{"<="}</option>
                        <option value="=">{"="}</option>
                      </Form.Select>
                    </div>
                  </Col>
                  <Col xl={6}>
                    <div className="from-groups no-border-break">
                      <Form.Control
                        type="text"
                        value={rightFilter?.leadTime}
                        onChange={(e) => {
                          setRightFilter({
                            ...rightFilter,
                            leadTime: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              </div>

              <div>
                <p>LOS</p>
                <Row>
                  <Col xl={6}>
                    <div className="from-groups no-border-break">
                      <Form.Select
                        aria-label="Default select example"
                        value={rightFilter?.losOperator}
                        onChange={(e) => {
                          setRightFilter({
                            ...rightFilter,
                            losOperator: e.target.value,
                          });
                        }}
                      >
                        <option value=">=">{">="}</option>
                        <option value="<=">{"<="}</option>
                        <option value="=">{"="}</option>
                      </Form.Select>
                    </div>
                  </Col>
                  <Col xl={6}>
                    <div className="from-groups no-border-break">
                      <Form.Control
                        type="text"
                        value={rightFilter?.los}
                        onChange={(e) => {
                          setRightFilter({
                            ...rightFilter,
                            los: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default FilterModal;
