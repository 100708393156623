import React, { useEffect, useRef, useState } from "react";
import { apiCall } from "../../../../utils/axiosService";
import { API_URL } from "../../../../utils/apiRoutes";
import { apiResponse } from "../../../../utils/apiResponse";
import NoteIcon from "../../../../Assets/Images/CRM/notes.svg";
import downloadIcon from "../../../../Assets/Images/Operation/downloadIcon.svg";
import DatePicker from "react-datepicker";
import { Button, Table } from "react-bootstrap";
import { commonService } from "../../../../utils/commonService";
import Loader from "../../../Loader/Loader";
import WidgetDetailModal from "../../WidgetDetailModal";
import WidgetNote from "../../WidgetNote";
import { compName } from "../../../../utils/constant";
import { useSelector } from "react-redux";
import infoIcon from "../../../../Assets/Images/Action Icons/infoIcon..svg";
import AddWidgetModal from "../../AddWidgetModal";
import { useParams } from "react-router-dom";
import emailIcon from "../../../../Assets/Images/Email.svg";
import moment from "moment";

const ChoiceBusinessOnTheBook = ({
  setCompDataFromChild,
  rowIndex,
  colIndex,
  api,
  para,
  setting,
}) => {
  const tableData = [
    {
      id: 1,
      propertyCode: "-",
      pullDateId: "0",
      AsOfDate: "",
      Date: "",
      TransientRooms: "0",
      TransientPPL: " 0 /  0",
      GroupRooms: "0",
      GroupPPL: " 0 /  0",
      DefiniteNotPickup: "0",
      TentativeNotPickup: "0",
      TotalOccupiedRooms: "0",
      TotalPPL: " 0 /  0",
      Occ: "0 %",
      COMPS: "0",
      RoomRev: "0",
      RevPar: "0",
      ADR: "0",
    },
    {
      id: 2,
      propertyCode: "-",
      pullDateId: "0",
      AsOfDate: "",
      Date: "",
      TransientRooms: "0",
      TransientPPL: " 0 /  0",
      GroupRooms: "0",
      GroupPPL: " 0 /  0",
      DefiniteNotPickup: "0",
      TentativeNotPickup: "0",
      TotalOccupiedRooms: "0",
      TotalPPL: " 0 /  0",
      Occ: "0 %",
      COMPS: "0",
      RoomRev: "0",
      RevPar: "0",
      ADR: "0",
    },
    {
      id: 3,
      propertyCode: "-",
      pullDateId: "0",
      AsOfDate: "",
      Date: "",
      TransientRooms: "0",
      TransientPPL: " 0 /  0",
      GroupRooms: "0",
      GroupPPL: " 0 /  0",
      DefiniteNotPickup: "0",
      TentativeNotPickup: "0",
      TotalOccupiedRooms: "0",
      TotalPPL: " 0 /  0",
      Occ: "0 %",
      COMPS: "0",
      RoomRev: "0",
      RevPar: "0",
      ADR: "0",
    },
    {
      id: 4,
      propertyCode: "-",
      pullDateId: "0",
      AsOfDate: "",
      Date: "",
      TransientRooms: "0",
      TransientPPL: " 0 /  0",
      GroupRooms: "0",
      GroupPPL: " 0 /  0",
      DefiniteNotPickup: "0",
      TentativeNotPickup: "0",
      TotalOccupiedRooms: "0",
      TotalPPL: " 0 /  0",
      Occ: "0 %",
      COMPS: "0",
      RoomRev: "0",
      RevPar: "0",
      ADR: "0",
    },
    {
      id: 5,
      propertyCode: "-",
      pullDateId: "0",
      AsOfDate: "",
      Date: "",
      TransientRooms: "0",
      TransientPPL: " 0 /  0",
      GroupRooms: "0",
      GroupPPL: " 0 /  0",
      DefiniteNotPickup: "0",
      TentativeNotPickup: "0",
      TotalOccupiedRooms: "0",
      TotalPPL: " 0 /  0",
      Occ: "0 %",
      COMPS: "0",
      RoomRev: "0",
      RevPar: "0",
      ADR: "0",
    },
    {
      id: 6,
      propertyCode: "-",
      pullDateId: "0",
      AsOfDate: "",
      Date: "",
      TransientRooms: "0",
      TransientPPL: " 0 /  0",
      GroupRooms: "0",
      GroupPPL: " 0 /  0",
      DefiniteNotPickup: "0",
      TentativeNotPickup: "0",
      TotalOccupiedRooms: "0",
      TotalPPL: " 0 /  0",
      Occ: "0 %",
      COMPS: "0",
      RoomRev: "0",
      RevPar: "0",
      ADR: "0",
    },
    {
      id: 7,
      propertyCode: "-",
      pullDateId: "0",
      AsOfDate: "",
      Date: "",
      TransientRooms: "0",
      TransientPPL: " 0 /  0",
      GroupRooms: "0",
      GroupPPL: " 0 /  0",
      DefiniteNotPickup: "0",
      TentativeNotPickup: "0",
      TotalOccupiedRooms: "0",
      TotalPPL: " 0 /  0",
      Occ: "0 %",
      COMPS: "0",
      RoomRev: "0",
      RevPar: "0",
      ADR: "0",
    },
    {
      id: 8,
      propertyCode: "-",
      pullDateId: "0",
      AsOfDate: "",
      Date: "",
      TransientRooms: "0",
      TransientPPL: " 0 /  0",
      GroupRooms: "0",
      GroupPPL: " 0 /  0",
      DefiniteNotPickup: "0",
      TentativeNotPickup: "0",
      TotalOccupiedRooms: "0",
      TotalPPL: " 0 /  0",
      Occ: "0 %",
      COMPS: "0",
      RoomRev: "0",
      RevPar: "0",
      ADR: "0",
    },
    {
      id: 9,
      propertyCode: "-",
      pullDateId: "0",
      AsOfDate: "",
      Date: "",
      TransientRooms: "0",
      TransientPPL: " 0 /  0",
      GroupRooms: "0",
      GroupPPL: " 0 /  0",
      DefiniteNotPickup: "0",
      TentativeNotPickup: "0",
      TotalOccupiedRooms: "0",
      TotalPPL: " 0 /  0",
      Occ: "0 %",
      COMPS: "0",
      RoomRev: "0",
      RevPar: "0",
      ADR: "0",
    },
    {
      id: 10,
      propertyCode: "-",
      pullDateId: "0",
      AsOfDate: "",
      Date: "",
      TransientRooms: "0",
      TransientPPL: " 0 /  0",
      GroupRooms: "0",
      GroupPPL: " 0 /  0",
      DefiniteNotPickup: "0",
      TentativeNotPickup: "0",
      TotalOccupiedRooms: "0",
      TotalPPL: " 0 /  0",
      Occ: "0 %",
      COMPS: "0",
      RoomRev: "0",
      RevPar: "0",
      ADR: "0",
    },
  ];
  const [choiceOTBData, setChoiceOTBData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [startRangeDate, setStartRangeDate] = useState();
  const [endRangeDate, setEndRangeDate] = useState();
  const startRangeDateRef = useRef();
  const endRangeDateRef = useRef();
  const [showNoteSidebar, setShowNoteSidebar] = useState(false);
  const [widgetModal, setWidgetModal] = useState(false);
  const widgetDetailRef = useRef();
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const propertyDetailFromRTK = useSelector(
    (store) => store.headerProperty.propertyDetail
  );
  const defaultAsOfDateFromRTK = useSelector(
    (store) => store.headerProperty.defaultAsOfDate
  );
  const { snapshottitle, propertyCode, snapshotId } = useParams();
  const closeWidgetModal = () => {
    setWidgetModal(false);
  };
  const closeNoteSideBar = () => {
    setShowNoteSidebar(false);
  };
  const handleDateChange = (event) => {
    const [start, end] = event;
    let startSelectedDate = "",
      endSelectedDate = "";
    if (start) {
      let date = new Date(start);
      let month =
        date.getMonth() + 1 > 9
          ? date.getMonth() + 1
          : `0${date.getMonth() + 1}`;
      let dateFormat =
        date?.getDate() > 9 ? date.getDate() : `0${date.getDate()}`;
      startSelectedDate = `${date.getFullYear()}-${month}-${dateFormat}`;
      startRangeDateRef.current = startSelectedDate;
    }
    if (end) {
      let date = new Date(end);
      let month =
        date.getMonth() + 1 > 9
          ? date.getMonth() + 1
          : `0${date.getMonth() + 1}`;
      let dateFormat =
        date?.getDate() > 9 ? date.getDate() : `0${date.getDate()}`;
      endSelectedDate = `${date.getFullYear()}-${month}-${dateFormat}`;
      endRangeDateRef.current = endSelectedDate;
    }

    setStartRangeDate(event[0]);
    setEndRangeDate(event[1]);
    if (event[0] !== null && event[1] !== null) {
      // getChoiceOTBData();
    }
  };

  const getChoiceOTBData = async () => {
    setLoading(true);
    let payload = {
      AsOfDate: para?.asOfDate,
      propertyCode: para?.propertyCode,
    };

    if (startRangeDateRef.current && endRangeDateRef.current) {
      payload = {
        ...payload,
        startDate: startRangeDateRef.current,
        endDate: endRangeDateRef.current,
      };
    }
    let choiceOTBResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.SnapShot +
          API_URL.WIDGET_MODULE.CHOICE_BUSINESS_ON_THE_BOOK,
        body: payload,
      },
      false
    );

    let response = apiResponse(false, choiceOTBResponse);
    if (response?.isValidate) {
      setChoiceOTBData(response?.data?.data);
      setLoading(false);
    }

    if (!response?.isValidate) {
      setChoiceOTBData(null);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (api === 0) {
      setChoiceOTBData(tableData);
    } else {
      if (para?.asOfDate) {
        getChoiceOTBData();
      }
    }
  }, [para?.asOfDate]);

  const [tblids, settblids] = useState({
    tblChoiceBusinessOTB: commonService.GenerateGUID(),
  });

  return (
    <div className="widget-container">
      {api !== 0 && (
        <div className="gap-1 widget-container-menu">
          <Button className="heading-menu-btns" type="button">
            <img
              src={NoteIcon}
              alt="menuIcon"
              onClick={() => {
                setShowNoteSidebar(true);
              }}
            />
          </Button>
          <Button className="heading-menu-btns" type="button" title="Send Mail">
            <img
              src={emailIcon}
              alt="emailIcon"
              onClick={() => {
                commonService.fnSendWidgetMail(
                  tblids.tblChoiceBusinessOTB,
                  "Choice Business On The Book",
                  snapshotId,
                  defaultAsOfDateFromRTK,
                  "",
                  para?.widgetId
                );
              }}
            />
          </Button>
          <Button className="heading-menu-btns" type="button">
            <img
              src={infoIcon}
              alt="info"
              onClick={() => {
                widgetDetailRef.current = {
                  widgetdetails: "",
                  usernote: "",
                  compName: compName?.widgetChoiceBusinessOTB,
                  widgetId: para?.widgetId,
                };
                setWidgetModal(true);
              }}
            />
          </Button>
          <Button className="heading-menu-btns" type="button">
            <img
              src={downloadIcon}
              alt="menuIcon"
              import={tblids.tblChoiceBusinessOTB}
              onClick={() =>
                commonService.fnExportInCSV(
                  tblids.tblChoiceBusinessOTB,
                  // "Choice Business On The Book"
                  `${para?.propertyCode}-Choice Business On The Book-${moment(
                    new Date()
                  ).format("MMDDYYYY")}`
                )
              }
            />
          </Button>
        </div>
      )}
      <div className="ViewPropertySetupTable">
        <div className="stripped__table">
          <Table
            responsive
            id={tblids.tblChoiceBusinessOTB}
            className="positionStaticTable"
          >
            <thead>
              <tr>
                <th
                  colSpan={17}
                  className=""
                  onClick={() => {
                    setOpenDatePicker(true);
                  }}
                >
                  <div className="d-flex gap-5">
                    <div className="d-flex justify-content-start gap-3">
                      <p className="m-0 d-flex align-items-center">Date </p>
                      <DatePicker
                        onChange={(event) => handleDateChange(event)}
                        startDate={startRangeDate}
                        endDate={endRangeDate}
                        selectsRange
                        monthsShown={2}
                        open={openDatePicker}
                        shouldCloseOnSelect={false}
                      >
                        <div
                          className="w-100"
                          style={{ display: "inline-block" }}
                        >
                          <div className="content-inside-calendar">
                            <p>
                              {startRangeDate
                                ? commonService.getDateInFormat(startRangeDate)
                                : ""}
                              {startRangeDate && endRangeDate ? " - " : ""}
                              {endRangeDate
                                ? commonService.getDateInFormat(endRangeDate)
                                : ""}
                            </p>
                            <div className="d-flex align-items-center gap-2">
                              <button
                                className="secondary-btn"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setStartRangeDate("");
                                  setEndRangeDate("");
                                  setOpenDatePicker(false);
                                }}
                              >
                                Cancel
                              </button>
                              <button
                                disabled={
                                  startRangeDate && endRangeDate ? false : true
                                }
                                onClick={(e) => {
                                  e.stopPropagation();
                                  if (
                                    startRangeDateRef.current &&
                                    endRangeDateRef.current
                                  ) {
                                    getChoiceOTBData();
                                  }

                                  setOpenDatePicker(false);
                                }}
                                className="primary-btn"
                              >
                                Apply
                              </button>
                            </div>
                          </div>
                        </div>
                      </DatePicker>
                      <span
                        style={{ display: "none" }}
                      >{`${startRangeDate} - ${endRangeDate}`}</span>
                    </div>
                    <div>Choice Business On The Book</div>
                  </div>
                </th>
              </tr>
              <tr>
                {/* <th className="width100">property Code</th>
                <th className="width100">pull Date Id</th> */}
                <th className="width100 text-start">As Of Date</th>
                <th className="width100 text-start">Date</th>
                <th className="width100 text-center">Transient Rooms</th>
                <th className="width100 text-center">Transient PPL</th>
                <th className="width100 text-center">Group Rooms</th>
                <th className="width100 text-center">Group PPL</th>
                <th className="width100 text-center">Definite Not Pickup</th>
                <th className="width100 text-center">Tentative Not Pickup</th>
                <th className="width100 text-center">Total Occupied Rooms</th>
                <th className="width100 text-center">Total PPL</th>
                <th className="width100 text-center">Occ</th>
                <th className="width100 text-center">COMPS</th>
                <th className="width100 text-center">Room Rev</th>
                <th className="width100 text-center">Rev Par</th>
                <th className="width100 text-center">ADR</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr style={{ height: "80px", position: "relative" }}>
                  <Loader />
                </tr>
              ) : choiceOTBData && choiceOTBData?.length > 0 ? (
                choiceOTBData?.map((item) => (
                  <tr key={item?.id} className="shadowOnHover">
                    {/* <td > {item?.propertyCode}</td>
                    <td>{item?.pullDateId}</td> */}
                    <td className="text-start">
                      {item?.AsOfDate
                        ? commonService.getDateInFormat(item?.AsOfDate)
                        : "-"}
                    </td>
                    <td className="text-start">
                      {item?.Date
                        ? commonService.getDateInFormat(item?.Date)
                        : "-"}
                    </td>
                    <td className="text-start">{item?.TransientRooms}</td>
                    <td className="text-center">{item?.TransientPPL}</td>
                    <td className="text-center">{item?.GroupRooms}</td>
                    <td className="text-center">{item?.GroupPPL}</td>
                    <td className="text-center">{item?.DefiniteNotPickup}</td>
                    <td className="text-center">{item?.TentativeNotPickup}</td>
                    <td className="text-center">{item?.TotalOccupiedRooms}</td>
                    <td className="text-center">{item?.TotalPPL}</td>
                    <td className="text-center">{item?.Occ}</td>
                    <td className="text-center">{item?.COMPS}</td>
                    <td className="text-center">{item?.RoomRev}</td>
                    <td className="text-center">{item?.RevPar}</td>
                    <td className="text-center">{item?.ADR}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td className="text-center" colSpan={17}>
                    No Data Found
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </div>
      {/* {showNoteSidebar && (
        <WidgetNote
          show={showNoteSidebar}
          handleClose={closeNoteSideBar}
          widgetId={para?.widgetId}
          propertyId={propertyDetailFromRTK?.propertyid}
          rowTitle={para?.rowTitle}
        />
      )} */}
      {showNoteSidebar && (
        <AddWidgetModal
          showModal={showNoteSidebar}
          closeModal={closeNoteSideBar}
          widgetId={para?.widgetId}
          propertyId={propertyDetailFromRTK?.propertyid}
          rowTitle={para?.rowTitle}
        />
      )}
      {widgetModal && (
        <WidgetDetailModal
          showModal={widgetModal}
          closeModal={closeWidgetModal}
          data={widgetDetailRef.current}
        />
      )}
    </div>
  );
};

export default ChoiceBusinessOnTheBook;
