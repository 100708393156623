import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Calendar from "../Configuration/FullCalendar";

function HeaderCalendar() {
  // const events = [
  //   {
  //     title: "Event 1",
  //     start: "2023-06-21",
  //     priority: "high",
  //   },
  //   {
  //     title: "Event 2",
  //     start: "2023-06-21",
  //     priority: "medium",
  //   },
  //   {
  //     title: "Event 3",
  //     start: "2023-06-21",
  //     priority: "low",
  //   },
  //   {
  //     title: "Event 4",
  //     start: "2023-06-10",
  //     priority: "high",
  //   },
  //   {
  //     title: "Event 5",
  //     start: "2023-06-10",
  //     priority: "medium",
  //   },
  //   {
  //     title: "Event 6",
  //     start: "2023-06-10",
  //     priority: "low",
  //   },
  // ];
  // const customLeftHeader = (
  //   <div>
  //     <span>Custom Text</span>
  //     <button>Custom Button</button>
  //   </div>
  // );
  // const eventClassNames = (event) => {
  //   let classNames = "";

  //   if (event.priority === "high") {
  //     classNames += "event-high-priority";
  //   } else if (event.priority === "medium") {
  //     classNames += "event-medium-priority";
  //   } else if (event.priority === "low") {
  //     classNames += "event-low-priority";
  //   }

  //   return classNames;
  // };
  // const eventContent = (eventInfo) => {
  //   const classNames = eventClassNames(eventInfo.event.extendedProps);

  //   return (
  //     <div className={classNames}>
  //       <div>{eventInfo.event.title}</div>
  //     </div>
  //   );
  // };
  // const headerToolbarConfig = {
  //   right: "prev,next today",
  //   left: "title",
  //   center: "",
  // };
  return (
    <div className="headerCalendarMain">
      <Container fluid>
        <Row>
          <Col xl={12}>
            <div className="headerCalendarWrapper">
              {/* <Calendar
                events={events}
                eventContent={eventContent}
                headerToolbar={headerToolbarConfig}
              /> */}
              Calendar
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default HeaderCalendar;
