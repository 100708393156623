import React, { useEffect, useState } from "react";
import { apiCall } from "../../../../utils/axiosService";
import { API_URL } from "../../../../utils/apiRoutes";
import { apiResponse } from "../../../../utils/apiResponse";
import { Bar, Line } from "react-chartjs-2";
import {
  Chart,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
} from "chart.js";

const Top10Chart = ({
  setCompDataFromChild,
  rowIndex,
  colIndex,
  api,
  para,
  setting,
}) => {
  const tableData = [];
  const [chartData, setChartData] = useState(null);

  let currentYearRev = {
    type: "bar",
    label: `${new Date().getFullYear()} Rev`,
    data: [],
    backgroundColor: "#4F79FF",
    borderColor: "#4F79FF",
    borderWidth: 1,
    barThickness: 10,
    order: 1,
  };

  let previousYearRev = {
    type: "bar",
    label: `${new Date().getFullYear() - 1} Rev`,
    data: [],
    backgroundColor: "#B8C8FC",
    borderColor: "#B8C8FC",
    borderWidth: 1,
    barThickness: 10,
    order: 1,
  };

  let currentYearAdr = {
    type: "line",
    label: `${new Date().getFullYear()} ADR`,
    data: [],
    fill: false,
    backgroundColor: "#1E4BD7",
    borderColor: "#1E4BD7",
    borderWidth: 1,
    yAxisID: "y2",
    order: 0,
  };

  let previousYearAdr = {
    type: "line",
    label: `${new Date().getFullYear() - 1} ADR`,
    data: [],
    fill: false,
    backgroundColor: "#36C3FF",
    borderColor: "#36C3FF",
    borderWidth: 1,
    yAxisID: "y2",
    order: 0,
  };

  const getChartData = async () => {
    let mergedData = {
      labels: [],
      datasets: [],
    };
    let chartDataResponse = await apiCall(
      {
        method: "POST",
        url: API_URL.BASE_API_URL.SnapShot + API_URL.RATEPLAN.TOP_10_CHART,
        body: {
          AsOfDate: para?.asOfDate,
          propertyCode: para?.propertyCode,
        },
      },
      false
    );

    let response = apiResponse(false, chartDataResponse);

    if (response?.isValidate) {
      setChartData(response?.data?.data);
      response?.data?.data?.forEach((item, index) => {
        mergedData.labels.push(item?.RateCode);
        currentYearAdr.data.push(item?.cy_ADR);
        previousYearAdr.data.push(item?.ly_ADR);
        currentYearRev.data.push(item?.cy_revenue);
        previousYearRev.data.push(item?.ly_revenue);
      });
      mergedData.datasets = [
        currentYearAdr,
        previousYearAdr,
        currentYearRev,
        previousYearRev,
      ];
      setChartData(mergedData);
    }

    if (!response?.isValidate) {
      mergedData.labels = ["A", "B", "C", "D", "E"];
      currentYearAdr.data = [0, 0, 0, 0, 0];
      previousYearAdr.data = [0, 0, 0, 0, 0];
      currentYearRev.data = [0, 0, 0, 0, 0];
      previousYearRev.data = [0, 0, 0, 0, 0];
      mergedData.datasets = [
        currentYearAdr,
        previousYearAdr,
        currentYearRev,
        previousYearRev,
      ];
      setChartData(mergedData);
    }
  };

  useEffect(() => {
    if (api === 0) {
      let mergedData = {
        labels: ["A", "B", "C", "D", "E"],
        datasets: [],
      };
      currentYearAdr.data = [0, 0, 0, 0, 0];
      previousYearAdr.data = [0, 0, 0, 0, 0];
      currentYearRev.data = [0, 0, 0, 0, 0];
      previousYearRev.data = [0, 0, 0, 0, 0];
      mergedData.datasets = [
        currentYearAdr,
        previousYearAdr,
        currentYearRev,
        previousYearRev,
      ];
      setChartData(mergedData);
    } else {
      if (para?.asOfDate) {
        getChartData();
      }
    }
  }, [para?.asOfDate]);

  const options = {
    scales: {
      x: {
        title: {
          display: false,
          text: "Months",
        },
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
        title: {
          display: false,
          text: "Percentage",
        },
        ticks: {
          callback: function (value) {
            return `$${value}`;
          },
        },
        layout: {
          padding: {
            left: 20,
            right: 20,
            top: 50,
            bottom: 50,
          },
        },
      },
      y2: {
        stacked: false,
        beginAtZero: true,
        type: "linear",
        position: "right",
        grid: {
          drawOnChartArea: false,
        },
        ticks: {
          callback: function (value, index, values) {
            return `$${value}`;
          },
        },
      },
    },
    plugins: {
      tooltip: {
        mode: "index",
        intersect: false,
      },
      legend: {
        display: true,
        align: "end",
        position: "top",
        maxWidth: 20,
        maxHeight: 100,
        labels: {
          boxWidth: 10,
          boxHeight: 10,
        },
      },
    },
    responsive: true,
  };

  Chart.register(
    LineElement,
    PointElement,
    CategoryScale,
    LinearScale,
    BarElement,
    Tooltip,
    Legend
  );

  return <div>{chartData && <Bar data={chartData} options={options} />}</div>;
};

export default Top10Chart;
