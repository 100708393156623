import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import {
  Chart,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  Filler,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { commonService } from "../../../../utils/commonService";
import { apiCall } from "../../../../utils/axiosService";
import { API_URL } from "../../../../utils/apiRoutes";
import { apiResponse } from "../../../../utils/apiResponse";
import Loader from "../../../Loader/Loader";
import leftArrow from "../../../../Assets/Images/Revenue-management/leftArrow.svg";
import rightArrow from "../../../../Assets/Images/Revenue-management/rightArrow.svg";
// import ff from "../../../../Assets"

const RateShopHistoryChartModal = ({
  showModal,
  closeModal,
  checkInDate,
  propertyCode,
  channel,
  propertyId,
}) => {
  const [chartData, setChartData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState(
    moment(new Date(checkInDate)).format("MMMM DD YYYY")
  );
  const datesArrRef = useRef();
  const isSelfRef = useRef();

  const calculateLabels = () => {
    let checkDate = moment(checkInDate).format("MM DD YYYY");
    let currentDate = moment(new Date()).format("MM DD YYYY");
    let timeForCheckDate = new Date(checkDate).getTime();
    let timeForCurrentDate = new Date(currentDate).getTime();
    let labelsArr = [],
      datesArr = [];

    if (timeForCheckDate > timeForCurrentDate) {
      const diffDayStartDate = new Date(
        commonService.getDateInFormat(currentDate)
      );
      const diffDayEndDate = new Date(commonService.getDateInFormat(checkDate));
      const diffDayInTime =
        diffDayEndDate.getTime() - diffDayStartDate.getTime();
      let diffDays = diffDayInTime / (1000 * 3600 * 24);
      let i = 0;
      let startingDate = new Date();
      while (i <= 30) {
        if (diffDays % 2 === 0) {
          labelsArr.push(diffDays);
        } else {
          labelsArr.push("");
        }
        // labelsArr.push(diffDays);
        datesArr.push(moment(startingDate).format("YYYY-MM-DD"));
        startingDate.setDate(startingDate.getDate() - 1);
        diffDays++;
        i++;
      }
      datesArrRef.current = datesArr.reverse();
      labelsArr.reverse();
    } else {
      // labelsArr = [
      //   30, 29, 28, 27, 26, 25, 24, 23, 22, 21, 20, 19, 18, 17, 16, 15, 14, 13,
      //   12, 11, 10, 9, 8, 7, 6, 5, 4, 3, 2, 1, 0,
      // ];
      labelsArr = [
        30,
        "",
        28,
        "",
        26,
        "",
        24,
        "",
        22,
        "",
        20,
        "",
        18,
        "",
        16,
        "",
        14,
        "",
        12,
        "",
        10,
        "",
        8,
        "",
        6,
        "",
        4,
        "",
        2,
        "",
        0,
      ];
      let i = 0,
        datesArr = [];
      let startingDate = new Date(checkInDate);
      while (i <= 30) {
        datesArr.push(moment(startingDate).format("YYYY-MM-DD"));
        startingDate.setDate(startingDate.getDate() - 1);
        i++;
      }
      datesArrRef.current = datesArr.reverse();
    }

    return labelsArr;
  };

  const getChartDetail = async () => {
    setChartData(null);
    setLoading(true);
    let asOfDateResponse = await apiCall(
      {
        method: "POST",
        url: API_URL.BASE_API_URL.SnapShot + API_URL.AS_OF_DATE.AS_OF_DATE,
        // body: { propertyCode: "AC32AW" },
        body: { propertyCode: propertyCode },
      },
      false
    );

    if (asOfDateResponse?.status_code === 1) {
      let chartDetailResponse = await apiCall(
        {
          method: "POST",
          url: API_URL.BASE_API_URL.SnapShot + API_URL.RATESHOP.HISTORY_CHART,
          body: {
            AsOfDate: asOfDateResponse?.data?.[0]?.last_data_refresh
              ? asOfDateResponse?.data?.[0]?.last_data_refresh
              : `${commonService.getDateForNode(new Date())} 00:00:00`,
            CheckInDate: moment(date).format("YYYY-MM-DD"),
            propertyCode: propertyCode,
            propertyid: propertyId,
            Channel: channel,
          },
        },
        false
      );

      let response = apiResponse(false, chartDetailResponse);

      if (response?.isValidate) {
        let dataFromApi = response?.data?.data;
        let groupingJson = {};
        for (let i = 0; i < dataFromApi?.length; i++) {
          if (dataFromApi?.[i]?.IsSelf) {
            isSelfRef.current = dataFromApi?.[i]?.competiterpropertyname;
          }
          if (groupingJson[dataFromApi?.[i]?.competiterpropertyname]) {
            groupingJson[dataFromApi?.[i]?.competiterpropertyname].push(
              parseFloat(dataFromApi?.[i]?.Rate)
            );
          } else {
            groupingJson = {
              ...groupingJson,
              [dataFromApi?.[i]?.competiterpropertyname]: [
                parseFloat(dataFromApi?.[i]?.Rate),
              ],
            };
          }
        }
        setChartData(groupingJson);
        setLoading(false);
      }

      if (!response?.isValidate) {
        setChartData({
          "Super 8 by Wyndham Pevely": [
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0,
          ],
          "Holiday Inn Express Hotel & Suites FESTUS - SOUTH ST. LOUIS- an IHG Hotel":
            [
              0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
              0, 0, 0, 0,
            ],
          "La Quinta by Wyndham Festus - St. Louis South": [
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0,
          ],
          "Quality Inn Festus": [
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0,
          ],
          "Comfort Inn Festus-St Louis South": [
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0,
          ],
        });
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    getChartDetail();
  }, [date]);

  let mergedData = {},
    options = {};
  if (chartData) {
    options = {
      elements: {
        point: {
          radius: 0,
        },
      },
      scales: {
        x: {
          title: {
            display: true,
            text: "LeadTime",
          },
          grid: {
            display: false,
          },
        },
        y: {
          stacked: false,
          beginAtZero: true,
          type: "linear",
          position: "left",
          ticks: {
            stepSize: 10,
            callback: function (value, index, values) {
              return `$ ${value}`;
            },
          },
        },
      },
      plugins: {
        legend: {
          display: true,
          align: "center",
          position: "bottom",
          paddingTop: 10,
          maxWidth: 20,
          maxHeight: 100,
          labels: {
            boxWidth: 10,
            boxHeight: 10,
          },
        },
        tooltip: {
          enabled: true,
          mode: "index",
          callbacks: {
            title: function (context) {
              let labelText = context[0].label || "";
              let index = context[0].dataIndex;
              let date = datesArrRef.current[index];
              let formatedDate = moment(date).format("dddd MMMM D YYYY");
              return `Leadtime ${labelText} ${formatedDate}`;
            },
            label: function (context) {
              let label = context.dataset.label || "";
              return `${label}: $${context.raw}`;
            },
          },
        },
      },
      responsive: true,
      maintainAspectRatio: true,
      aspectRatio: 4 / 1,
    };
    let dataSetDetail = Object?.entries(chartData)?.map(([key, value]) => {
      let color = Math.floor(Math.random() * 16777215).toString(16);
      return {
        type: "line",
        label: key,
        data: value?.reverse(),
        fill: false,
        backgroundColor: isSelfRef.current === key ? "#134AFF" : `#${color}`,
        borderColor: isSelfRef.current === key ? "#134AFF" : `#${color}`,
        borderWidth: isSelfRef.current === key ? 5 : 2,
        order: 0,
        // pointRadius: 3,
        // pointBorderWidth: 1,
        // pointBorderColor: "#ffffff",

        pointRadius: 5,
        pointBorderWidth: 4,
        pointBorderColor: "#ffffff",
        pointBorderRadius: 10,
      };
    });

    mergedData = {
      labels: calculateLabels(),
      datasets: dataSetDetail,
    };
  }
  Chart.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Tooltip,
    Legend,
    PointElement,
    LineElement,
    Filler
  );

  return (
    <>
      <Modal
        className="ToolsSummaryModalMain"
        size="xl"
        centered
        show={showModal}
        onHide={closeModal}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {/* {moment(checkInDate).format("dddd MMMM D YYYY")} */}
            <div className="dateWithArrow">
              <img
                src={leftArrow}
                alt="leftArrow"
                onClick={() => {
                  let d = new Date(commonService.getDateInFormat(date));
                  d.setDate(d.getDate() - 1);
                  setDate(moment(d).format("MMMM DD YYYY"));
                }}
              />
              <p>{date}</p>
              <img
                src={rightArrow}
                alt="rightArrow"
                onClick={() => {
                  let d = new Date(commonService.getDateInFormat(date));
                  d.setDate(d.getDate() + 1);
                  setDate(moment(d).format("MMMM DD YYYY"));
                }}
              />
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading ? (
            <Loader />
          ) : chartData ? (
            <div className="GroupNameModalBody">
              <Line data={mergedData} options={options} />
            </div>
          ) : (
            <>
              <p>No Data Found</p>
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default RateShopHistoryChartModal;
