import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { apiCall } from "../../../../utils/axiosService";
import { commonService } from "../../../../utils/commonService";
import { API_URL } from "../../../../utils/apiRoutes";
import { apiResponse } from "../../../../utils/apiResponse";
import { compName, month } from "../../../../utils/constant";
import { Button, Table } from "react-bootstrap";
import Loader from "../../../Loader/Loader";
import downloadIcon from "../../../../Assets/Images/Operation/downloadIcon.svg";
import infoIcon from "../../../../Assets/Images/Action Icons/infoIcon..svg";
import emailIcon from "../../../../Assets/Images/Email.svg";
import AddWidgetModal from "../../AddWidgetModal";
import WidgetDetailModal from "../../WidgetDetailModal";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { FixColumn } from "../../../../utils/dataTable";
import moment from "moment";

const CompanyMonthlyDrilldown = ({
  setCompDataFromChild,
  rowIndex,
  colIndex,
  api,
  para,
  setting,
}) => {
  const propertyDetailFromRTK = useSelector(
    (store) => store.headerProperty.propertyDetail
  );
  const defaultAsOfDateFromRTK = useSelector(
    (store) => store.headerProperty.defaultAsOfDate
  );
  const { snapshottitle, propertyCode, snapshotId } = useParams();
  let date = new Date(para?.asOfDate);
  let findstartDateMonth = new Date(date.setMonth(date.getMonth() - 11));

  let startDate = new Date(
    findstartDateMonth.getFullYear(),
    findstartDateMonth.getMonth(),
    1
  );

  let dateForEnd = new Date(para?.asOfDate);
  var endDate = new Date(
    dateForEnd.getFullYear(),
    dateForEnd.getMonth() + 1,
    0
  );
  const startDateRef = useRef();
  const endDateRef = useRef();
  startDateRef.current = new Date(startDate);
  endDateRef.current = new Date(endDate);

  const [loading, setLoading] = useState(false);
  const [companyData, setCompanyData] = useState(null);
  const monthArrRef = useRef();
  const [widgetModal, setWidgetModal] = useState(false);
  const widgetDetailRef = useRef();

  const closeWidgetModal = () => {
    setWidgetModal(false);
  };

  const tdData = (item) => {
    return (
      <>
        <td className="month-drilldown-color">
          {item?.total?.RMS ? item?.total?.RMS : 0}
        </td>
        <td className="text-end month-drilldown-color">
          {item?.total?.ADR
            ? commonService.formateAmount(parseInt(item?.total?.ADR / 12))
            : commonService.formateAmount(0)}
        </td>
        <td className="text-end border_right month-drilldown-color">
          {item?.total?.REV
            ? commonService.formateAmount(item?.total?.REV)
            : commonService.formateAmount(0)}
        </td>

        {monthArrRef.current?.map((monthItem, monthIndex) => {
          let showBgColor = false;
          let showGreenColor = false,
            showRedColor = false;
          if (monthIndex % 2 !== 0) {
            showBgColor = true;
          }
          let monthName = monthItem?.split("-")?.[0];
          return (
            <>
              <td
                className={`${showBgColor ? "month-drilldown-color" : ""} ${
                  showGreenColor ? "greenText" : ""
                } ${showRedColor ? "redText" : ""}`}
              >
                {item?.[monthName]?.RMS
                  ? commonService.formateRoom(item?.[monthName]?.RMS)
                  : 0}
              </td>
              <td
                className={`text-end ${
                  showBgColor ? "month-drilldown-color" : ""
                } ${showGreenColor ? "greenText" : ""} ${
                  showRedColor ? "redText" : ""
                }`}
              >
                {item?.[monthName]?.ADR
                  ? commonService.formateAmount(item?.[monthName]?.ADR)
                  : commonService.formateAmount(0)}
              </td>
              <td
                className={`text-end border_right ${
                  showBgColor ? "month-drilldown-color" : ""
                } ${showGreenColor ? "greenText" : ""} ${
                  showRedColor ? "redText" : ""
                }`}
              >
                {item?.[monthName]?.REV
                  ? commonService.formateAmount(item?.[monthName]?.REV)
                  : commonService.formateAmount(0)}
              </td>
            </>
          );
        })}
      </>
    );
  };

  const [tblids, settblids] = useState({
    companyWiseDropdown: commonService.GenerateGUID(),
  });

  const getCompanyData = async () => {
    setLoading(true);
    let companyDataResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.SnapShot +
          API_URL.CRM_SNAPSHOT.COMPANY_DRILLDOWN,
        body: {
          propertyCode: para?.propertyCode,
          AsOfDate: para?.asOfDate,
          startDate: commonService.getDateForNode(startDateRef.current),
          endDate: commonService.getDateForNode(endDateRef.current),
        },
      },
      false
    );

    let response = apiResponse(false, companyDataResponse);

    if (response?.isValidate) {
      let dataFromApi = response?.data?.data;
      let monthArr = [];
      for (let i = 0; i < 12; i++) {
        let date = new Date(startDateRef.current);
        date.setMonth(date.getMonth() + i);
        let month = date.toLocaleString("en-US", { month: "long" });
        let year = date.getFullYear();
        monthArr = [...monthArr, `${month}-${year}`];
      }
      monthArrRef.current = monthArr.reverse();
      let groupingJson = [];
      dataFromApi?.map((item, index) => {
        let itemExists = groupingJson?.filter((x) => x?.name === item?.Company);

        if (itemExists?.length <= 0) {
          let msData = dataFromApi?.filter((x) => x?.Company === item?.Company);
          let monthWiseObj = {};
          let total = {
            RMS: 0,
            REV: 0,
            ADR: 0,
          };

          month?.map((monthName) => {
            let findMonthOfMs = msData?.filter(
              (x) => x?.Month?.trim() === monthName
            );
            monthWiseObj[monthName] = {
              RMS: findMonthOfMs?.[0]?.RMS ? findMonthOfMs?.[0]?.RMS : 0,
              REV: findMonthOfMs?.[0]?.REV ? findMonthOfMs?.[0]?.REV : 0,
              ADR: findMonthOfMs?.[0]?.ADR ? findMonthOfMs?.[0]?.ADR : 0,
            };

            total.RMS =
              total.RMS +
              (findMonthOfMs?.[0]?.RMS ? parseInt(findMonthOfMs?.[0]?.RMS) : 0);

            total.REV =
              total.REV +
              (findMonthOfMs?.[0]?.REV ? parseInt(findMonthOfMs?.[0]?.REV) : 0);

            total.ADR =
              total.ADR +
              (findMonthOfMs?.[0]?.ADR ? parseInt(findMonthOfMs?.[0]?.ADR) : 0);
          });
          item.name = item?.Company;
          item.total = total;
          let msDetail = { ...item, ...monthWiseObj };

          groupingJson = [...groupingJson, msDetail];
        }
      });
      setCompanyData(groupingJson);
      setLoading(false);
    }

    if (!response?.isValidate) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (api === 0) {
    } else {
      if (para?.asOfDate) {
        getCompanyData();
      }
    }
  }, [para?.asOfDate]);
  FixColumn(tblids.companyWiseDropdown, [1]);

  return (
    <div className="widget-container">
      {api !== 0 && (
        <div className="gap-1 widget-container-menu">
          <Button className="heading-menu-btns" type="button" title="Send Mail">
            <img
              src={emailIcon}
              alt="emailIcon"
              onClick={() => {
                commonService.fnSendWidgetMail(
                  tblids.companyWiseDropdown,
                  "Company Monthly Drilldown",
                  snapshotId,
                  defaultAsOfDateFromRTK,
                  "",
                  para?.widgetId
                );
              }}
            />
          </Button>
          <Button className="heading-menu-btns" type="button">
            <img
              src={infoIcon}
              alt="info"
              onClick={() => {
                widgetDetailRef.current = {
                  widgetdetails: "",
                  usernote: "",
                  compName: compName?.crmCompanyDrilldown,
                  widgetId: para?.widgetId,
                };
                setWidgetModal(true);
              }}
            />
          </Button>
          <Button className="heading-menu-btns" type="button">
            <img
              src={downloadIcon}
              alt="menuIcon"
              import={tblids.companyWiseDropdown}
              onClick={() =>
                commonService.fnExportInCSV(
                  tblids.companyWiseDropdown,
                  // "Company Monthly Drilldown"
                  `${para?.propertyCode}-Company Monthly Drilldown-${moment(
                    new Date()
                  ).format("MMDDYYYY")}`
                )
              }
            />
          </Button>
        </div>
      )}
      <div className="ViewPropertySetupTable">
        <div className="table__container">
          <div className="stripped__table">
            <Table id={tblids.companyWiseDropdown} responsive>
              <thead>
                <tr className="table-main-header-tr">
                  <th className="border_right width175"></th>
                  <th colSpan={3} className="border_right text-center">
                    Actualized
                  </th>
                  {monthArrRef.current?.map((item, index) => {
                    return (
                      <th
                        className="border_right text-center"
                        colSpan={3}
                        key={`${item}-${index}`}
                      >
                        {item}
                      </th>
                    );
                  })}
                </tr>
                <tr className="table-main-header-tr">
                  <th className="border_right">Company</th>
                  <th>RMS</th>
                  <th className="text-end">ADR</th>
                  <th className="border_right text-end">REV</th>
                  {monthArrRef.current?.map((item, index) => {
                    return (
                      <>
                        <th>RMS</th>
                        <th className="text-end">ADR</th>
                        <th className="border_right text-end">REV</th>
                      </>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr style={{ height: "80px", position: "relative" }}>
                    <Loader />
                  </tr>
                ) : companyData ? (
                  companyData?.map((item, index) => {
                    return (
                      <>
                        <tr className="shadowOnHover">
                          <td>{item?.name}</td>
                          {tdData(item)}
                        </tr>
                      </>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={40} className="text-center">
                      No Data Found
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
      {widgetModal && (
        <WidgetDetailModal
          showModal={widgetModal}
          closeModal={closeWidgetModal}
          data={widgetDetailRef.current}
        />
      )}
    </div>
  );
};

export default CompanyMonthlyDrilldown;
