import React from "react";
import { Modal } from "react-bootstrap";
import RMRateIqCalenderView from "./RMRateIqCalenderView";

const RateIQPopupCalender = ({ showModal, closeModal }) => {
  return (
    <>
      <Modal
        fullscreen
        centered
        show={showModal}
        onHide={closeModal}
        className="viewListingModalMain"
      >
        <Modal.Header closeButton>
          {window.location.href.includes("/crm")
            ? "CRM - Tools - RateIQ"
            : " Revenue Management - Tools - RateIQ"}
        </Modal.Header>
        <Modal.Body>
          <RMRateIqCalenderView />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default RateIQPopupCalender;
