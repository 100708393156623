import React, { useState } from "react";
import deleteImg from "../../Assets/Images/Configuration/delete-modal.png";
import { Button, Modal } from "react-bootstrap";
import { apiCall } from "../../utils/axiosService";
import { API_URL } from "../../utils/apiRoutes";
import { commonService } from "../../utils/commonService";
import { useNavigate } from "react-router-dom";
import {
  errorToastMessage,
  successToastMessage,
} from "../../utils/toastMessage";
import { useDispatch } from "react-redux";
import { callApiOfSnapShot } from "../../utils/slices/callApiAgainSlice";

function DeleteItemModal({
  showDeleteItemModal,
  closeDeleteItemModal,
  id,
  getList,
  url,
  callSnapShotApi,
  DeleteWithOutApi,
}) {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const token = commonService.getItem("token");
  const dispatch = useDispatch();

  const handleDelete = async () => {
    if (DeleteWithOutApi) {
      DeleteWithOutApi();
    } else {
      setLoading(true);
      let deleteCategoryListResponse = await apiCall(
        {
          method: "POST",
          url: url,
          body: {
            id: id,
            date: new Date(),
          },
        },
        false
      );
      if (deleteCategoryListResponse?.status_code === 0) {
        errorToastMessage(deleteCategoryListResponse?.message);
        if (getList) {
          getList();
        }
      } else if (deleteCategoryListResponse?.status_code === 1) {
        setLoading(true);
        successToastMessage(deleteCategoryListResponse?.message);
        if (callSnapShotApi) {
          dispatch(callApiOfSnapShot());
        }
        if (getList) {
          getList();
        }
      } else if (deleteCategoryListResponse?.status_code === 2) {
        localStorage.clear();
          window.location.href = API_URL.BASE_API_URL.EMA_MAIN_DOMAIN + "?logout=true";
      } else if (deleteCategoryListResponse?.name === "AxiosError") {
        let errMsg = "";
        for (const [key, value] of Object.entries(
          deleteCategoryListResponse?.response?.data?.errors
        )) {
          value?.map((errorItem) => {
            errMsg = errMsg + errorItem;
          });
        }
        errorToastMessage(errMsg);
      }
    }
    setLoading(false);
    closeDeleteItemModal();
  };

  return (
    <>
      <Modal
        className="DeleteItemModalMain"
        centered
        show={showDeleteItemModal}
        onHide={closeDeleteItemModal}
      >
        <Modal.Body>
          <div className="delete-modal-content">
            <img src={deleteImg} alt="deleteImg" />
            <h2>Are You Sure?</h2>
            <p>You want to delete this!</p>
            <div className="delete-modal-content-btns">
              {loading ? (
                <button className="default-btn red-btn" type="button" disabled>
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Loading...
                </button>
              ) : (
                <button
                  className="default-btn red-btn"
                  onClick={() => {
                    handleDelete();
                  }}
                >
                  Delete
                </button>
              )}
              {/* <Button
                className="default-btn red-btn"
                onClick={closeDeleteItemModal}
              >
                Delete
              </Button> */}

              <Button
                className="default-btn secondary-btn"
                onClick={closeDeleteItemModal}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default DeleteItemModal;
