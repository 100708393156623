import React, { useEffect, useState } from "react";
import {
  Badge,
  Button,
  Col,
  FloatingLabel,
  Form,
  Modal,
  Row,
  Tab,
  Table,
  Tabs,
} from "react-bootstrap";

function GroupReservationsModal({
  showGroupReservationsModal,
  closeGroupReservationsModal,
}) {
  const [activeAccordionIndex, setActiveAccordionIndex] = useState(null);

  const handleAccordionClick = (index) => {
    if (activeAccordionIndex === index) {
      setActiveAccordionIndex(null); // Close the clicked accordion
    } else {
      setActiveAccordionIndex(index); // Open the clicked accordion
    }
  };

  return (
    <>
      <Modal
        className="GroupReservationsPopupMain"
        size="xl"
        centered
        show={showGroupReservationsModal}
        onHide={closeGroupReservationsModal}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Group Reservations
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="GroupNameModalBody">
            <div className="table__container">
              <div className="stripped__table">
                <Table
                //   id="strategyTable"
                  className="table-with-spacing GroupReservationsPopupTable"
                  responsive
                >
                  <thead>
                    <tr className="diff-bg-tr">
                      <th className="border_right">Group Name</th>
                      <th className="">Rooms</th>
                      <th className="border_right">LY</th>
                      <th className="">arr date</th>
                      <th className="">LOS</th>
                      <th className="">
                        Pickup from
                        <br />
                        08/22/2023
                      </th>
                      <th className="border_right">ADR Pickup</th>
                      <th className="">ADR</th>
                      <th className="border_right">LY</th>
                      <th className="">revenue</th>
                      <th className="">LY</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className=" border_right its_accordion_btn_main">
                        <a
                          className={`its_accordion_btn ${
                            activeAccordionIndex === 0
                              ? "its_accordion_btn_show"
                              : ""
                          }`}
                          onClick={() => handleAccordionClick(0)}
                        >
                          G-KYN2 - Keyence Summer Group 2
                        </a>
                      </td>
                      <td className="text-center">57</td>
                      <td className="border_right text-center">0</td>
                      <td></td>
                      <td></td>
                      <td className="text-center">0</td>
                      <td className="border_right text-center">$0</td>
                      <td className="text-center">$92</td>
                      <td className="border_right text-center">$0</td>
                      <td className="text-center">$92</td>
                      <td className="text-center">$0</td>
                    </tr>
                    <tr
                      className={`its_accordion_content ${
                        activeAccordionIndex === 0 ? "" : "hidden"
                      }`}
                    >
                      <td className=" border_right its_accordion_btn_main">
                        <a
                          className={`its_accordion_btn ${
                            activeAccordionIndex === 3
                              ? "its_accordion_btn_show"
                              : ""
                          }`}
                          onClick={() => handleAccordionClick(3)}
                        >
                         UNKNOWN
                        </a>
                      </td>
                      <td className="text-center">57</td>
                      <td className="border_right text-center">0</td>
                      <td></td>
                      <td></td>
                      <td className="text-center">0</td>
                      <td className="border_right text-center">$0</td>
                      <td className="text-center">$92</td>
                      <td className="border_right text-center">$0</td>
                      <td className="text-center">$92</td>
                      <td className="text-center">$0</td>
                    </tr>

                    <tr
                      className={`its_accordion_content ${
                        activeAccordionIndex === 3 ? "" : "hidden"
                      }`}
                    >
                      <td className="border_right ">Annette Black</td>
                      <td className="text-center">57</td>
                      <td className="border_right text-center">0</td>
                      <td></td>
                      <td></td>
                      <td className="text-center">0</td>
                      <td className="border_right text-center">$0</td>
                      <td className="text-center">$92</td>
                      <td className="border_right text-center">$0</td>
                      <td className="text-center">$92</td>
                      <td className="text-center">$0</td>
                    </tr>
                    <tr
                      className={`its_accordion_content ${
                        activeAccordionIndex === 3 ? "" : "hidden"
                      }`}
                    >
                      <td className="border_right ">Robert Fox</td>
                      <td className="text-center">57</td>
                      <td className="border_right text-center">0</td>
                      <td></td>
                      <td></td>
                      <td className="text-center">0</td>
                      <td className="border_right text-center">$0</td>
                      <td className="text-center">$92</td>
                      <td className="border_right text-center">$0</td>
                      <td className="text-center">$92</td>
                      <td className="text-center">$0</td>
                    </tr>
                    <tr
                      className={`its_accordion_content ${
                        activeAccordionIndex === 3 ? "" : "hidden"
                      }`}
                    >
                      <td className="border_right">Esther Howard</td>
                      <td className="text-center">57</td>
                      <td className="border_right text-center">0</td>
                      <td></td>
                      <td></td>
                      <td className="text-center">0</td>
                      <td className="border_right text-center">$0</td>
                      <td className="text-center">$92</td>
                      <td className="border_right text-center">$0</td>
                      <td className="text-center">$92</td>
                      <td className="text-center">$0</td>
                    </tr>
                    <tr
                      className={`its_accordion_content ${
                        activeAccordionIndex === 3 ? "" : "hidden"
                      }`}
                    >
                      <td className="border_right">Guy Hawkins</td>
                      <td className="text-center">57</td>
                      <td className="border_right text-center">0</td>
                      <td></td>
                      <td></td>
                      <td className="text-center">0</td>
                      <td className="border_right text-center">$0</td>
                      <td className="text-center">$92</td>
                      <td className="border_right text-center">$0</td>
                      <td className="text-center">$92</td>
                      <td className="text-center">$0</td>
                    </tr>
                    <tr
                      className={`its_accordion_content ${
                        activeAccordionIndex === 3 ? "" : "hidden"
                      }`}
                    >
                      <td className="border_right">Kristin Watson</td>
                      <td className="text-center">57</td>
                      <td className="border_right text-center">0</td>
                      <td></td>
                      <td></td>
                      <td className="text-center">0</td>
                      <td className="border_right text-center">$0</td>
                      <td className="text-center">$92</td>
                      <td className="border_right text-center">$0</td>
                      <td className="text-center">$92</td>
                      <td className="text-center">$0</td>
                    </tr>
                    <tr
                      className={`its_accordion_content ${
                        activeAccordionIndex === 3 ? "" : "hidden"
                      }`}
                    >
                      <td className="border_right">Darlene Robertson</td>
                      <td className="text-center">57</td>
                      <td className="border_right text-center">0</td>
                      <td></td>
                      <td></td>
                      <td className="text-center">0</td>
                      <td className="border_right text-center">$0</td>
                      <td className="text-center">$92</td>
                      <td className="border_right text-center">$0</td>
                      <td className="text-center">$92</td>
                      <td className="text-center">$0</td>
                    </tr>
                    <tr>
                      <td className=" border_right its_accordion_btn_main">
                        <a
                          className={`its_accordion_btn ${
                            activeAccordionIndex === 1
                              ? "its_accordion_btn_show"
                              : ""
                          }`}
                          onClick={() => handleAccordionClick(1)}
                        >
                          Optimal
                        </a>
                      </td>
                      <td className="text-center">57</td>
                      <td className="border_right text-center">0</td>
                      <td></td>
                      <td></td>
                      <td className="text-center">0</td>
                      <td className="border_right text-center">$0</td>
                      <td className="text-center">$92</td>
                      <td className="border_right text-center">$0</td>
                      <td className="text-center">$92</td>
                      <td className="text-center">$0</td>
                    </tr>
                    <tr
                      className={`its_accordion_content ${
                        activeAccordionIndex === 1 ? "" : "hidden"
                      }`}
                    >
                      <td className=" border_right its_accordion_btn_main">
                        <a
                          className={`its_accordion_btn ${
                            activeAccordionIndex === 4
                              ? "its_accordion_btn_show"
                              : ""
                          }`}
                          onClick={() => handleAccordionClick(4)}
                        >
                         UNKNOWN
                        </a>
                      </td>
                      <td className="text-center">57</td>
                      <td className="border_right text-center">0</td>
                      <td></td>
                      <td></td>
                      <td className="text-center">0</td>
                      <td className="border_right text-center">$0</td>
                      <td className="text-center">$92</td>
                      <td className="border_right text-center">$0</td>
                      <td className="text-center">$92</td>
                      <td className="text-center">$0</td>
                    </tr>
                    <tr
                      className={`its_accordion_content ${
                        activeAccordionIndex === 4 ? "" : "hidden"
                      }`}
                    >
                      <td className="border_right">Cody Fisher</td>
                      <td className="text-center">57</td>
                      <td className="border_right text-center">0</td>
                      <td></td>
                      <td></td>
                      <td className="text-center">0</td>
                      <td className="border_right text-center">$0</td>
                      <td className="text-center">$92</td>
                      <td className="border_right text-center">$0</td>
                      <td className="text-center">$92</td>
                      <td className="text-center">$0</td>
                    </tr>
                    <tr
                      className={`its_accordion_content ${
                        activeAccordionIndex === 4 ? "" : "hidden"
                      }`}
                    >
                      <td className="border_right">Jacob Jones</td>
                      <td className="text-center">57</td>
                      <td className="border_right text-center">0</td>
                      <td></td>
                      <td></td>
                      <td className="text-center">0</td>
                      <td className="border_right text-center">$0</td>
                      <td className="text-center">$92</td>
                      <td className="border_right text-center">$0</td>
                      <td className="text-center">$92</td>
                      <td className="text-center">$0</td>
                    </tr>
                    <tr
                      className={`its_accordion_content ${
                        activeAccordionIndex === 4 ? "" : "hidden"
                      }`}
                    >
                      <td className="border_right">Darrell Steward</td>
                      <td className="text-center">57</td>
                      <td className="border_right text-center">0</td>
                      <td></td>
                      <td></td>
                      <td className="text-center">0</td>
                      <td className="border_right text-center">$0</td>
                      <td className="text-center">$92</td>
                      <td className="border_right text-center">$0</td>
                      <td className="text-center">$92</td>
                      <td className="text-center">$0</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default GroupReservationsModal;
