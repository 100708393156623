import React, { useEffect, useRef, useState } from "react";
import {
  Table,
  Form,
  Button,
  Col,
  OverlayTrigger,
  Overlay,
  Popover,
} from "react-bootstrap";
import { commonService } from "../../../../utils/commonService";
import { apiCall } from "../../../../utils/axiosService";
import { API_URL } from "../../../../utils/apiRoutes";
import { apiResponse } from "../../../../utils/apiResponse";
import moment from "moment";
import NoteIcon from "../../../../Assets/Images/CRM/notes.svg";
import downloadIcon from "../../../../Assets/Images/Operation/downloadIcon.svg";
import Loader from "../../../Loader/Loader";
import DatePicker from "react-datepicker";
import SingleDateSummaryModal from "../../SingleDateSummaryModal";
import SingleDayGroupModal from "../../SingleDayGroupModal";
import { useParams } from "react-router-dom";
import editIcon from "../../../../Assets/Images/Action Icons/editaction.svg";
import ticket from "../../../../Assets/Images/Revenue-management/tableTicket.svg";
import {
  errorToastMessage,
  successToastMessage,
} from "../../../../utils/toastMessage";
import ToolsSingleTicketPopUp from "../../../CommonComponent/ToolsSingleTicketPopUp";
import { useSelector } from "react-redux";
import infoIcon from "../../../../Assets/Images/Action Icons/infoIcon..svg";
import WidgetNote from "../../WidgetNote";
import { compName } from "../../../../utils/constant";
import WidgetDetailModal from "../../WidgetDetailModal";
import AddWidgetModal from "../../AddWidgetModal";
import emailIcon from "../../../../Assets/Images/Email.svg";
import Comments from "../Comments/Comment";
import { FixColumn } from "../../../../utils/dataTable";
import EventViewModal from "../../../CommonComponent/EventViewModal";

const DayByDay = ({
  setCompDataFromChild,
  rowIndex,
  colIndex,
  api,
  para,
  setting,
}) => {
  let ooo = 0,
    RmsAvailable = 0,
    leftToSell = 0,
    otb = 0,
    occPercentage = 0,
    adr = 0,
    rev = 0,
    revPar = 0,
    groupOTB = 0,
    groupBlock = 0,
    pickupRooms = 0,
    pickupAdr = 0,
    dayName = false,
    barBasedOTB = 0,
    baeBasedRollingAvg = 0,
    pickupAdrCount = 0;
  const { snapshottitle, propertyCode, snapshotId } = useParams();
  const date = new Date();
  const startDate = commonService.getDateInDBFormat(
    new Date(date.getFullYear(), date.getMonth(), 1)
  );
  const endDate = commonService.getDateInDBFormat(
    new Date(date.getFullYear(), date.getMonth() + 1, 0)
  );
  const asOfDateFromRTK = useSelector((store) => store.headerProperty.asOfDate);
  const [orgData, setOrgData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectAsOfDate, setSelectAsOfDate] = useState();
  const [startRangeDate, setStartRangeDate] = useState();
  const [endRangeDate, setEndRangeDate] = useState();
  const [selectPickupDate, setSelectPickupDate] = useState();
  const [pickupFromData, setPickupFromData] = useState(null);
  const [rateShopListData, setRateShopListData] = useState(null);
  const startRangeDateRef = useRef();
  const endRangeDateRef = useRef();
  const [maxDateForPickupFrom, setMaxDateForPickupFrom] = useState();
  const [showSingleDate, setShowSingleDate] = useState(false);
  const maxDateRef = useRef();
  const singleDatePickupDateRef = useRef();
  const singleDateSelectedDateRef = useRef();
  const selectedTdIndexRef = useRef();
  const dateForNoteRef = useRef();
  const userNoteRef = useRef();
  const eventListRef = useRef();
  const eventListPropsRef = useRef();
  const selectedEventDateRef = useRef();
  const [showTicketModal, setShowTicketModal] = useState(false);
  const [noteUpdate, setNoteUpdate] = useState(false);
  const [noteLoading, setNoteLoading] = useState(false);
  if (maxDateForPickupFrom) {
    let date = new Date(maxDateForPickupFrom);
    maxDateRef.current = date.setDate(date.getDate() - 1);
  }
  const startDateArrayRef = useRef([]);
  const [forecastData, setForecastData] = useState(null);
  const [forecastLoading, setForecastLoading] = useState(null);
  const [selectedForecast, setSelectedForecast] = useState("EMA");
  const [barBasedData, setBarBasedData] = useState(null);
  const [showNoteSidebar, setShowNoteSidebar] = useState(false);
  const propertyDetailFromRTK = useSelector(
    (store) => store.headerProperty.propertyDetail
  );
  const defaultAsOfDateFromRTK = useSelector(
    (store) => store.headerProperty.defaultAsOfDate
  );

  const [widgetModal, setWidgetModal] = useState(false);
  const widgetDetailRef = useRef();
  const closeWidgetModal = () => {
    setWidgetModal(false);
  };
  const [addNoteModal, setAddNoteModal] = useState(false);
  const closeNoteModal = () => {
    setAddNoteModal(false);
  };
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const clickedDateForNote = useRef();
  const [noteDetail, setNoteDetail] = useState(null);
  const [strDbd, setStrDbd] = useState(null);
  const [strLoading, setStrLoading] = useState(false);

  const handleDateChange = (event) => {
    const [start, end] = event;
    let startSelectedDate = "",
      endSelectedDate = "";
    if (start) {
      let date = new Date(start);
      let month =
        date.getMonth() + 1 > 9
          ? date.getMonth() + 1
          : `0${date.getMonth() + 1}`;
      let dateFormat =
        date?.getDate() > 9 ? date.getDate() : `0${date.getDate()}`;
      startSelectedDate = `${date.getFullYear()}-${month}-${dateFormat}`;
      startRangeDateRef.current = commonService.getDateForNode(
        commonService.convertDateInTimezone(startSelectedDate)
      );
    }
    if (end) {
      let date = new Date(end);
      let month =
        date.getMonth() + 1 > 9
          ? date.getMonth() + 1
          : `0${date.getMonth() + 1}`;
      let dateFormat =
        date?.getDate() > 9 ? date.getDate() : `0${date.getDate()}`;
      endSelectedDate = `${date.getFullYear()}-${month}-${dateFormat}`;
      endRangeDateRef.current = commonService.getDateForNode(
        commonService.convertDateInTimezone(endSelectedDate)
      );
    }

    setStartRangeDate(event[0]);
    setEndRangeDate(event[1]);
    if (event[0] !== null && event[1] !== null) {
    }
  };

  const htmlToText = (html) => {
    const tempElement = document.createElement("div");
    tempElement.innerHTML = html;
    const plainText = tempElement.innerText;
    return plainText;
  };

  const closeTicketModal = () => {
    setShowTicketModal(false);
  };

  const closeNoteSideBar = () => {
    setShowNoteSidebar(false);
  };
  const diffDayStartDate = new Date(
    commonService.getDateInFormat(
      startRangeDateRef.current ? startRangeDateRef.current : startDate
    )
  );
  const diffDayEndDate = new Date(
    commonService.getDateInFormat(
      endRangeDateRef.current ? endRangeDateRef.current : endDate
    )
  );
  const diffDayInTime = diffDayEndDate.getTime() - diffDayStartDate.getTime();
  const diffDays = diffDayInTime / (1000 * 3600 * 24);

  const getEventList = async () => {
    let eventList = await apiCall(
      {
        method: "POST",
        url: API_URL.BASE_API_URL.RevenueManagement + API_URL.EVENTS.GET_LIST,
        body: {
          pageNum: 1,
          pageSize: 10,
          sortColumn: "",
          sortDirection: "asc",
          searchValue: "",
          propertyid: parseInt(propertyDetailFromRTK?.propertyid),
          startdate: null,
          enddate: null,
          eventname: "",
          eventtypeterm: "",
          yearterm: ``,
        },
      },
      false
    );

    let eventListResponse = apiResponse(false, eventList);

    if (eventListResponse?.isValidate) {
      let eventListArr = [];

      eventListResponse?.data?.data?.forEach((item) => {
        let singleEventObj = {
          id: item?.eventid ? item?.eventid : 0,
          name: item?.eventname,
          detail: item?.eventdetails,
          startDate: item?.startdate,
          endDate: item?.enddate,
          eventJson: item?.eventjson,
          eventResourceId: item?.eventsourceid,
        };
        let eventJsonData = "";
        if (item?.eventjson) {
          var decodedString = decodeURIComponent(item?.eventjson);
          const jsonStringWithoutQuotes = decodedString.slice(1, -1);
          const jsonStringWithSingleQuotes = `${jsonStringWithoutQuotes}`;
          eventJsonData = JSON.parse(jsonStringWithSingleQuotes);
          let desc = eventJsonData?.description?.replaceAll("+", " ");
          singleEventObj = {
            ...singleEventObj,
            labels: eventJsonData?.labels ? eventJsonData?.labels : "",
            rank: eventJsonData?.rank ? eventJsonData?.rank : 0,
            local_rank: eventJsonData?.local_rank
              ? eventJsonData?.local_rank
              : 0,
            phq_attendance: eventJsonData?.phq_attendance
              ? eventJsonData?.phq_attendance
              : 0,
            geo_lat: eventJsonData?.geo_lat ? eventJsonData?.geo_lat : "",
            geo_long: eventJsonData?.geo_long ? eventJsonData?.geo_long : "",
            detail: desc,
          };
        }
        eventListArr.push(singleEventObj);
      });
      eventListRef.current = eventListArr;
    }

    if (!eventListResponse?.isValidate) {
      eventListRef.current = [];
    }
  };

  const getORGDetail = async () => {
    setLoading(true);

    await getEventList();

    let orgDetailResponse = await apiCall(
      {
        method: "POST",
        url: API_URL.BASE_API_URL.SnapShot + API_URL.DAILY_SUMMARY.ORG,
        body: {
          AsOfDate: selectAsOfDate ? selectAsOfDate : para?.asOfDate,
          startDate: startRangeDateRef.current
            ? startRangeDateRef.current
            : startDate,
          endDate: endRangeDateRef.current ? endRangeDateRef.current : endDate,
          propertyCode: para?.propertyCode,
        },
      },
      false
    );

    let response = apiResponse(false, orgDetailResponse);

    if (response?.isValidate) {
      setOrgData(response?.data?.data);
      setLoading(false);
    }

    if (!response?.isValidate) {
      setOrgData(null);
      setLoading(false);
    }
  };

  const getStrDayByDay = async () => {
    setStrLoading(true);
    let strDbdResponse = await apiCall(
      {
        method: "POST",
        url: API_URL.BASE_API_URL.SnapShot + API_URL.STR.DAY_BY_DAY,
        body: {
          AsOfDate: selectAsOfDate ? selectAsOfDate : para?.asOfDate,
          startDate: startRangeDateRef.current
            ? startRangeDateRef.current
            : startDate,
          endDate: endRangeDateRef.current ? endRangeDateRef.current : endDate,
          propertyCode: para?.propertyCode,
        },
      },
      false
    );

    let response = apiResponse(false, strDbdResponse);

    if (response?.isValidate) {
      setStrDbd(response?.data?.data);
      setStrLoading(false);
    }

    if (!response?.isValidate) {
      setStrDbd(null);
      setStrLoading(false);
    }
  };

  const callBothApi = () => {
    getORGDetail();
    getStrDayByDay();
  };

  useEffect(() => {
    if (api === 0) {
      let datesArr = commonService.getDateBetweenTwoDates(
        commonService.convertDateInTimezone(startDate),
        commonService.convertDateInTimezone(endDate)
      );
      startDateArrayRef.current = datesArr;
      let d = new Date(para?.asOfDate);
      d.setDate(d.getDate() - 1);
      singleDatePickupDateRef.current = commonService.convertDateInTimezone(d);
      setOrgData(null);
    } else {
      if (para?.asOfDate) {
        let datesArr = commonService.getDateBetweenTwoDates(
          commonService.convertDateInTimezone(startDate),
          commonService.convertDateInTimezone(endDate)
        );
        startDateArrayRef.current = datesArr;
        let d = new Date(para?.asOfDate);
        d.setDate(d.getDate() - 1);
        singleDatePickupDateRef.current =
          commonService.convertDateInTimezone(d);
        setMaxDateForPickupFrom(new Date(para?.defaultAsOfDate));
        callBothApi();
      }
    }
    if (!selectAsOfDate) {
      setSelectAsOfDate(para?.asOfDate);
    }
  }, [para?.asOfDate, selectAsOfDate, selectedForecast]);

  useEffect(() => {
    setSelectAsOfDate(asOfDateFromRTK);
  }, [asOfDateFromRTK]);

  const [tblids, settblids] = useState({
    OrgStrategyTable: commonService.GenerateGUID(),
  });
  commonService.setBackColorPerByTDValue(
    "#638EC6",
    "Red",
    `#${tblids.OrgStrategyTable} .perbackground-otb`
  );
  commonService.setBackColorPerByTDValue(
    "Green",
    "Red",
    `#${tblids.OrgStrategyTable} .perbackground-adr`
  );
  commonService.setBackColorPerByTDValue(
    "#638EC6",
    "Red",
    `#${tblids.OrgStrategyTable} .perbackground-room`
  );
  commonService.setBackColorPerByTDValue(
    "#FFC140",
    "Red",
    `#${tblids.OrgStrategyTable} .perbackground-GroupOTB`
  );

  const [ONTHEBOOKS, setONTHEBOOKS] = useState({
    IsDisplay: false,
    Target: null,
    Position: "top",
  });
  const orgStrategyTableRef = useRef(null);
  const [groupModal, setGroupModal] = useState({
    IsDisplay: false,
    Target: null,
    Position: "top",
  });
  useEffect(() => {
    if (orgStrategyTableRef.current) {
      orgStrategyTableRef.current.addEventListener(
        "click",
        handleOrgStrategyTableClick
      );
    }

    return () => {
      if (orgStrategyTableRef.current) {
        orgStrategyTableRef.current.removeEventListener(
          "click",
          handleOrgStrategyTableClick
        );
      }
    };
  }, []);

  const handleOrgStrategyTableClick = (event) => {};

  const handleFilterBtnClick = (event) => {
    let Position = "bottom";
    let Top = event.nativeEvent.layerY - 2;
    if (Top + 400 > window.innerHeight) {
      Position = "top";
    }
    setONTHEBOOKS({
      IsDisplay: true,
      Target: event.target,
      Position: Position,
    });
  };
  const closeSingleDate = () => {
    selectedTdIndexRef.current = -1;
    setONTHEBOOKS({
      IsDisplay: false,
      Target: null,
      Position: "bottom",
    });
  };

  const handleGroupBtnClick = (event) => {
    let Position = "bottom";
    let Top = event.nativeEvent.layerY - 2;
    if (Top + 400 > window.innerHeight) {
      Position = "top";
    }
    setGroupModal({
      IsDisplay: true,
      Target: event.target,
      Position: Position,
    });
  };

  const closeGroupDate = () => {
    selectedTdIndexRef.current = -1;
    setGroupModal({
      IsDisplay: false,
      Target: null,
      Position: "bottom",
    });
  };

  FixColumn(tblids.OrgStrategyTable, [1]);

  return (
    <div className="widget-container">
      {api !== 0 && (
        <div className="gap-1 widget-container-menu">
          {/* <Button
            className="heading-menu-btns"
            type="button"
            onClick={() => {
              setShowNoteSidebar(true);
            }}
          >
            <img src={NoteIcon} alt="note" />
          </Button> */}
          <Button className="heading-menu-btns" type="button">
            <img
              src={infoIcon}
              alt="info"
              onClick={() => {
                widgetDetailRef.current = {
                  widgetdetails: "",
                  usernote: "",
                  compName: compName?.org,
                  widgetId: para?.widgetId,
                };
                setWidgetModal(true);
              }}
            />
          </Button>
          <Button className="heading-menu-btns" type="button" title="Send Mail">
            <img
              src={emailIcon}
              alt="emailIcon"
              onClick={() => {
                commonService.fnSendWidgetMail(
                  tblids.OrgStrategyTable,
                  "STR Day By Day",
                  snapshotId,
                  defaultAsOfDateFromRTK,
                  "",
                  para?.widgetId
                );
              }}
            />
          </Button>
          <Button className="heading-menu-btns" type="button">
            <img
              src={downloadIcon}
              alt="download"
              import={tblids.OrgStrategyTable}
              onClick={() =>
                commonService.fnExportInCSV(
                  tblids.OrgStrategyTable,
                  // "STR Day By Day"
                  `${para?.propertyCode}-STR Day By Day-${moment(
                    new Date()
                  ).format("MMDDYYYY")}`
                )
              }
            />
          </Button>
        </div>
      )}
      <div className="ViewPropertySetupTable">
        <div className="stripped__table">
          <div className="table-responsive tblDynamicPickupSticky">
            <Table
              id={tblids.OrgStrategyTable}
              className="table-with-spacing positionStaticTable"
              ref={orgStrategyTableRef}
            >
              <thead className="tbl-header-sticky">
                <tr className="table-main-header-tr">
                  <th className="border_right">Date</th>
                  <th colSpan={5} className="border_right text-center">
                    On The Books
                  </th>
                  <th colSpan={2} className="border_right text-center">
                    Revenue
                  </th>
                  <th colSpan={3} className="border_right text-center">
                    Current Year
                  </th>
                  <th colSpan={3} className="border_right text-center">
                    Prior Year
                  </th>
                </tr>
                <tr className="table-main-header-tr">
                  <th
                    className="width175 border_right"
                    onClick={() => {
                      setOpenDatePicker(true);
                    }}
                  >
                    Date{" "}
                    <DatePicker
                      onChange={(event) => handleDateChange(event)}
                      startDate={startRangeDate}
                      endDate={endRangeDate}
                      selectsRange
                      monthsShown={2}
                      open={openDatePicker}
                      shouldCloseOnSelect={false}
                    >
                      <div
                        className="w-100"
                        style={{ display: "inline-block" }}
                      >
                        <div className="content-inside-calendar">
                          <p>
                            {startRangeDate
                              ? commonService.getDateInFormat(startRangeDate)
                              : ""}
                            {startRangeDate && endRangeDate ? " - " : ""}
                            {endRangeDate
                              ? commonService.getDateInFormat(endRangeDate)
                              : ""}
                          </p>
                          <div className="d-flex align-items-center gap-2">
                            <button
                              className="secondary-btn"
                              onClick={(e) => {
                                e.stopPropagation();
                                setStartRangeDate("");
                                setEndRangeDate("");
                                setOpenDatePicker(false);
                              }}
                            >
                              Cancel
                            </button>
                            <button
                              disabled={
                                startRangeDate && endRangeDate ? false : true
                              }
                              onClick={(e) => {
                                e.stopPropagation();
                                if (
                                  startRangeDateRef.current &&
                                  endRangeDateRef.current
                                ) {
                                  let datesArr =
                                    commonService.getDateBetweenTwoDates(
                                      commonService.convertDateInTimezone(
                                        startRangeDateRef.current
                                      ),
                                      commonService.convertDateInTimezone(
                                        endRangeDateRef.current
                                      )
                                    );
                                  startDateArrayRef.current = datesArr;
                                  callBothApi();
                                }

                                setOpenDatePicker(false);
                              }}
                              className="primary-btn"
                            >
                              Apply
                            </button>
                          </div>
                        </div>
                      </div>
                    </DatePicker>
                    {startRangeDate && endRangeDate && (
                      <span
                        style={{ display: "none" }}
                      >{`${commonService.getDateInFormat(
                        startRangeDate
                      )} - ${commonService.getDateInFormat(
                        endRangeDate
                      )}`}</span>
                    )}
                  </th>
                  <th className="">OOO/Rooms Available</th>
                  <th className="text-center ">Left To sell</th>
                  <th className="text-center">On The Books</th>
                  <th>Total OCC%</th>
                  <th className="text-end border_right">ADR</th>
                  <th className="text-center">Rev</th>
                  <th className="text-end border_right">RevPAR</th>
                  <th>MPI</th>
                  <th>ARI</th>
                  <th className="border_right">RGI</th>
                  <th>MPI</th>
                  <th>ARI</th>
                  <th>RGI</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr style={{ height: "80px", position: "relative" }}>
                    <Loader />
                  </tr>
                ) : (
                  startDateArrayRef.current?.map((item, index) => {
                    dayName = false;
                    if (
                      moment(
                        commonService.convertDateInTimezone(item),
                        "l"
                      ).format("ddd") === "Fri" ||
                      moment(
                        commonService.convertDateInTimezone(item),
                        "l"
                      ).format("ddd") === "Sat"
                    ) {
                      dayName = true;
                    }

                    let singleOTBData = orgData?.filter(
                      (x) =>
                        moment(x.Date).format("YYYY-MM-DD") ===
                        moment(item).format("YYYY-MM-DD")
                    );

                    let singleStrData = strDbd?.filter(
                      (x) =>
                        moment(x.StarReportDate).format("YYYY-MM-DD") ===
                        moment(item).format("YYYY-MM-DD")
                    );

                    ooo =
                      ooo +
                      (singleOTBData?.[0]?.OOO ? singleOTBData?.[0]?.OOO : 0);
                    RmsAvailable =
                      RmsAvailable +
                      (singleOTBData?.[0]?.RoomAvailable
                        ? singleOTBData?.[0]?.RoomAvailable
                        : 0);
                    leftToSell =
                      leftToSell +
                      (singleOTBData?.[0]?.LeftToSell
                        ? singleOTBData?.[0]?.LeftToSell
                        : 0);
                    otb =
                      otb +
                      (singleOTBData?.[0]?.OnTheBook
                        ? singleOTBData?.[0]?.OnTheBook
                        : 0);
                    occPercentage =
                      occPercentage +
                      (singleOTBData?.[0]?.TotalOCCPercentage
                        ? singleOTBData?.[0]?.TotalOCCPercentage
                        : 0);
                    adr =
                      adr +
                      (singleOTBData?.[0]?.ADR ? singleOTBData?.[0]?.ADR : 0);
                    rev =
                      rev +
                      (singleOTBData?.[0]?.REV ? singleOTBData?.[0]?.REV : 0);
                    revPar =
                      revPar +
                      (singleOTBData?.[0]?.RevPAR
                        ? singleOTBData?.[0]?.RevPAR
                        : 0);

                    let checkEvent = eventListRef.current?.filter((x) => {
                      let checkingDate = moment(item);
                      let startingDate = moment(x?.startDate);
                      let endingDate = moment(x?.endDate);
                      if (
                        checkingDate.isSameOrAfter(startingDate) &&
                        checkingDate.isSameOrBefore(endingDate)
                      ) {
                        return x;
                      }
                    });
                    return (
                      <React.Fragment key={index}>
                        <tr
                          key={index}
                          className={`${
                            dayName ? "backgroundTr" : ""
                          } shadowOnHover`}
                        >
                          <td className="border_right">
                            <div className="d-flex gap-4">
                              {commonService.getDateInFormat(
                                // startDateArrayRef.current[index]
                                commonService.convertDateInTimezone(item)
                              )}{" "}
                              {moment(
                                // new Date(startDateArrayRef.current[index]),
                                commonService.convertDateInTimezone(item),
                                "l"
                              ).format("ddd")}
                              <div className="d-flex justify-content-between align-items-center gap-2 ticketAlarmReload">
                                {checkEvent?.length > 0 && (
                                  <img
                                    style={{ height: "11px", width: "11px" }}
                                    className="iconcolor"
                                    src={ticket}
                                    alt="ticket"
                                    onClick={() => {
                                      selectedEventDateRef.current = item;
                                      eventListPropsRef.current = checkEvent;
                                      setShowTicketModal(true);
                                    }}
                                  />
                                )}
                              </div>
                            </div>
                          </td>
                          <td className="width100">
                            {singleOTBData?.[0]?.OOO
                              ? commonService.formateRoom(singleOTBData?.[0]?.OOO)
                              : 0}
                            /
                            {singleOTBData?.[0]?.RoomAvailable
                              ? commonService.formateRoom(singleOTBData?.[0]?.RoomAvailable)
                              : 0}
                          </td>
                          <td className="text-center">
                            {singleOTBData?.[0]?.LeftToSell
                              ? commonService.formateRoom(singleOTBData?.[0]?.LeftToSell)
                              : 0}
                          </td>
                          <td
                            className="text-primary text-center perbackground perbackground-otb"
                            value={
                              singleOTBData?.[0]?.OnTheBook
                                ? singleOTBData?.[0]?.OnTheBook
                                : 0
                            }
                          >
                            <span
                              className={`${
                                selectedTdIndexRef.current === index
                                  ? "singleDateSummarySpan"
                                  : ""
                              }`}
                              onClick={(e) => {
                                singleDateSelectedDateRef.current = item;
                                // startDateArrayRef.current[index];
                                handleFilterBtnClick(e);
                                selectedTdIndexRef.current = index;
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              {singleOTBData?.[0]?.OnTheBook
                                ? commonService.formateRoom(singleOTBData?.[0]?.OnTheBook)
                                : 0}
                            </span>
                          </td>

                          <td>
                            {singleOTBData?.[0]?.TotalOCCPercentage
                              ? singleOTBData?.[0]?.TotalOCCPercentage
                              : 0}
                            %
                          </td>
                          <td
                            className="text-end perbackground perbackground-adr border_right"
                            value={singleOTBData?.[0]?.ADR}
                          >
                            {singleOTBData?.[0]?.ADR
                              ? commonService.formateAmount(
                                  singleOTBData?.[0]?.ADR
                                )
                              : commonService.formateAmount(0)}
                          </td>
                          <td className="text-end">
                            {singleOTBData?.[0]?.REV
                              ? commonService.formateAmount(
                                  singleOTBData?.[0]?.REV
                                )
                              : commonService.formateAmount(0)}
                          </td>
                          <td className="text-end border_right">
                            {singleOTBData?.[0]?.RevPAR
                              ? commonService.formateAmount(
                                  singleOTBData?.[0]?.RevPAR
                                )
                              : commonService.formateAmount(0)}
                          </td>
                          <td>
                            {singleStrData?.[0]?.CY_MPI
                              ? commonService.formateRoom(singleStrData?.[0]?.CY_MPI)
                              : 0}
                          </td>
                          <td>
                            {singleStrData?.[0]?.CY_ADI
                              ? commonService.formateRoom(singleStrData?.[0]?.CY_ADI)
                              : 0}
                          </td>
                          <td className="border_right">
                            {singleStrData?.[0]?.CY_RGI
                              ? commonService.formateRoom(singleStrData?.[0]?.CY_RGI)
                              : 0}
                          </td>
                          <td>
                            {singleStrData?.[0]?.LY_MPI
                              ? commonService.formateRoom(singleStrData?.[0]?.LY_MPI)
                              : 0}
                          </td>
                          <td>
                            {singleStrData?.[0]?.LY_ADI
                              ? commonService.formateRoom(singleStrData?.[0]?.LY_ADI)
                              : 0}
                          </td>
                          <td>
                            {singleStrData?.[0]?.LY_RGI
                              ? commonService.formateRoom(singleStrData?.[0]?.LY_RGI)
                              : 0}
                          </td>
                        </tr>
                        {index === startDateArrayRef.current?.length - 1 && (
                          <tr className="shadowOnHover">
                            <td className="boldTd border_right">Total</td>
                            <td className="boldTd ">
                              {commonService.formateRoom(ooo)}/{commonService.formateRoom(RmsAvailable)}
                            </td>
                            <td className="boldTd text-center">{commonService.formateRoom(leftToSell)}</td>
                            <td className="boldTd text-center">{commonService.formateRoom(otb)}</td>
                            <td className="boldTd">
                              {occPercentage > 0
                                ? `${parseInt(occPercentage / diffDays)}%`
                                : `0%`}
                            </td>
                            <td className="boldTd text-end border_right">
                              {rev > 0 && otb > 0
                                ? `${commonService.formateAmount(
                                    parseInt(rev / otb)
                                  )}`
                                : `${commonService.formateAmount(0)}`}
                            </td>
                            <td className="boldTd text-end">
                              {commonService.formateAmount(rev)}
                            </td>
                            <td className="boldTd text-end border_right">
                              {revPar > 0
                                ? `${commonService.formateAmount(
                                    parseInt(revPar / diffDays)
                                  )}`
                                : `${commonService.formateAmount(0)}`}
                            </td>
                            <td className="boldTd"></td>
                            <td className="boldTd"></td>
                            <td className="boldTd border_right"></td>
                            <td className="boldTd"></td>
                            <td className="boldTd"></td>
                            <td className="boldTd"></td>
                          </tr>
                        )}
                      </React.Fragment>
                    );
                  })
                )}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
      {api !== 0 && <Comments widgetDetails={para} editorId={"DayByDayStr"} />}
      <Overlay
        show={ONTHEBOOKS.IsDisplay}
        target={ONTHEBOOKS.Target}
        placement={ONTHEBOOKS.Position}
      >
        <Popover
          id="popover-contained"
          className="SingleDateSummaryModalPopoverMain"
          style={{ zIndex: 999999 }}
        >
          <div className="horizontal-menu-option active">
            <SingleDateSummaryModal
              closeModal={closeSingleDate}
              asOfDate={para?.asOfDate}
              selectedDate={singleDateSelectedDateRef.current}
              pickupDate={
                selectPickupDate
                  ? commonService.convertDateInTimezone(selectPickupDate)
                  : commonService.convertDateInTimezone(
                      singleDatePickupDateRef.current
                    )
              }
              propertyDetail={{ propertyCode: para?.propertyCode }}
            />
          </div>
        </Popover>
      </Overlay>
      {showTicketModal && (
        <EventViewModal
          showModal={showTicketModal}
          closeModal={closeTicketModal}
          eventDetails={eventListPropsRef.current}
          selectedDate={selectedEventDateRef.current}
          getEventList={getEventList}
        />
      )}
      {showNoteSidebar && (
        <AddWidgetModal
          showModal={showNoteSidebar}
          closeModal={closeNoteSideBar}
          widgetId={para?.widgetId}
          propertyId={propertyDetailFromRTK?.propertyid}
          rowTitle={para?.rowTitle}
        />
      )}
      {widgetModal && (
        <WidgetDetailModal
          showModal={widgetModal}
          closeModal={closeWidgetModal}
          data={widgetDetailRef.current}
        />
      )}
    </div>
  );
};

export default DayByDay;
