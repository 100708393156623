import React, { useEffect, useRef, useState } from "react";
import { apiCall } from "../../../../utils/axiosService";
import { API_URL } from "../../../../utils/apiRoutes";
import { apiResponse } from "../../../../utils/apiResponse";
import { Button, Table } from "react-bootstrap";
import { commonService } from "../../../../utils/commonService";
import downloadIcon from "../../../../Assets/Images/Operation/downloadIcon.svg";
import Loader from "../../../Loader/Loader";
import { compName } from "../../../../utils/constant";
import WidgetDetailModal from "../../WidgetDetailModal";
import infoIcon from "../../../../Assets/Images/Action Icons/infoIcon..svg";
import AddWidgetModal from "../../AddWidgetModal";
import emailIcon from "../../../../Assets/Images/Email.svg";
import lowPrio from "../../../../Assets/Images/Revenue-management/lowPriority.svg";
import highPrio from "../../../../Assets/Images/Revenue-management/highPriority.svg";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import moment from "moment";

const CancellationActivity = ({
  setCompDataFromChild,
  rowIndex,
  colIndex,
  api,
  para,
  setting,
}) => {
  const propertyDetailFromRTK = useSelector(
    (store) => store.headerProperty.propertyDetail
  );
  const defaultAsOfDateFromRTK = useSelector(
    (store) => store.headerProperty.defaultAsOfDate
  );
  const { snapshottitle, propertyCode, snapshotId } = useParams();

  const [loading, setLoading] = useState(false);
  const [activityData, setActivityData] = useState(null);
  const [cancellationRateTitle, setCancellationRateTitle] = useState(null);
  const [widgetModal, setWidgetModal] = useState(false);
  const widgetDetailRef = useRef();

  const closeWidgetModal = () => {
    setWidgetModal(false);
  };

  const findDiffRate = (adr, rate, min, max) => {
    let diff = ((rate - adr) / adr) * 100;
    if (diff < 0) {
      diff = -diff;
      if (min < diff) {
        return {
          status: 1,
          diff: diff,
        };
      }
    }
    if (diff > 0) {
      if (max < diff) {
        return {
          status: 2,
          diff: diff,
        };
      } else {
        return 0;
      }
    }
  };

  const getActivityData = async () => {
    setLoading(true);
    let activityDataResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.SnapShot +
          API_URL.CRM_SNAPSHOT.CANCELLATION_ACTIVITY,
        body: {
          propertyCode: para?.propertyCode,
          AsOfDate: para?.asOfDate,
        },
      },
      false
    );

    let response = apiResponse(false, activityDataResponse);

    if (response?.isValidate) {
      let updatedData = response?.data?.data.map((d) => {
        d.RateStatus = findDiffRate(
          d.ADR,
          d.Rate,
          JSON.parse(d?.rulevalues)?.[0]?.min,
          JSON.parse(d?.rulevalues)?.[0]?.max
        );
        return d;
      });

      setCancellationRateTitle({
        min: JSON.parse(response?.data?.data?.[0].rulevalues)?.[0]?.min,
        max: JSON.parse(response?.data?.data?.[0].rulevalues)?.[0]?.max,
      });

      setActivityData(updatedData);
      setLoading(false);
    }

    if (!response?.isValidate) {
      setCancellationRateTitle(null);
      setActivityData(null);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (api === 0) {
    } else {
      if (para?.asOfDate) {
        getActivityData();
      }
    }
  }, [para?.asOfDate]);

  const [tblids, settblids] = useState({
    tblCancellationActivity: commonService.GenerateGUID(),
  });

  return (
    <div className="widget-container">
      {api !== 0 && (
        <div className="gap-1 widget-container-menu">
          <Button className="heading-menu-btns" type="button" title="Send Mail">
            <img
              src={emailIcon}
              alt="emailIcon"
              onClick={() => {
                commonService.fnSendWidgetMail(
                  tblids.tblCancellationActivity,
                  "Cancellation Activity",
                  snapshotId,
                  defaultAsOfDateFromRTK,
                  "",
                  para?.widgetId
                );
              }}
            />
          </Button>
          <Button className="heading-menu-btns" type="button">
            <img
              src={infoIcon}
              alt="info"
              onClick={() => {
                widgetDetailRef.current = {
                  widgetdetails: "",
                  usernote: "",
                  compName: compName?.crmCancellationActivity,
                  widgetId: para?.widgetId,
                };
                setWidgetModal(true);
              }}
            />
          </Button>
          <Button className="heading-menu-btns" type="button">
            <img
              src={downloadIcon}
              alt="menuIcon"
              import={tblids.tblCancellationActivity}
              onClick={() =>
                commonService.fnExportInCSV(
                  tblids.tblCancellationActivity,
                  // "Cancellation Activity"
                  `${para?.propertyCode}-Cancellation Activity-${moment(
                    new Date()
                  ).format("MMDDYYYY")}`
                )
              }
            />
          </Button>
        </div>
      )}
      <div className="ViewPropertySetupTable">
        <div className="stripped__table">
          <Table id={tblids.tblCancellationActivity} responsive>
            <thead id="tblreservationActivitySnapShotHead">
              <tr>
                <th className="text-center" colSpan={16}>
                  Cancellation Activity
                  <span style={{ marginLeft: "10px", fontSize: "7px" }}>
                    {`${
                      cancellationRateTitle
                        ? `(Cancellation Rate < ${cancellationRateTitle.min} % or > ${cancellationRateTitle.max} % by Rate of the Day (ADR))`
                        : ""
                    }`}
                  </span>
                </th>
              </tr>
              <tr>
                <th>Rate Code</th>
                <th>Rate Plan Description</th>
                <th>Company</th>
                <th>Group Name</th>
                <th>Guest Name</th>
                <th>Confirmation Number</th>
                <th>Arrival Date</th>
                <th className="text-center">LOS</th>
                <th>Status</th>
                <th className="text-end">ADR</th>
                <th className="text-end">Rate</th>
                <th>Room Type</th>
                <th>Channel</th>
                <th>Source</th>
                <th>Booking Date</th>
                <th>Cancelation Date</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr style={{ height: "80px", position: "relative" }}>
                  <Loader />
                </tr>
              ) : activityData ? (
                activityData?.map((item, index) => {
                  let status = "";
                  if (item?.Status === "R") {
                    status = "reserved";
                  } else if (item?.Status === "I") {
                    status = "in_house";
                  } else if (item?.Status === "O") {
                    status = "checked_out";
                  }

                  let textColor = "";
                  if (item?.RateStatus?.status == 1) {
                    textColor = "redText";
                  }
                  if (item?.RateStatus?.status == 2) {
                    textColor = "greenText";
                  }

                  return (
                    <tr key={index} className="shadowOnHover">
                      <td>{item?.RateCode}</td>
                      <td>
                        {item?.RateCodeDescript ? item?.RateCodeDescript : "-"}
                      </td>
                      <td>{item?.Company}</td>
                      <td>{item?.GroupName ? item?.GroupName : "UNKNOWN"}</td>
                      <td>{item?.GuestName}</td>
                      <td>{item?.Account}</td>
                      <td>
                        {commonService.getDateInFormat(item?.ArrivalDate)}
                      </td>
                      <td className="text-center">{item?.LOS}</td>
                      <td>{status}</td>
                      <td className="text-end">
                        {commonService.formateAmount(item?.ADR)}
                      </td>
                      <td className={`text-end ${textColor}`}>
                        {item?.RateStatus?.status == 1 ? (
                          <img
                            src={lowPrio}
                            title={item?.RateStatus?.diff?.toFixed(2) + "%"}
                          />
                        ) : item?.RateStatus?.status == 2 ? (
                          <img
                            src={highPrio}
                            title={item?.RateStatus?.diff?.toFixed(2) + "%"}
                          />
                        ) : null}
                        {commonService.formateAmount(item?.Rate)}
                      </td>
                      <td>{item?.RoomType}</td>
                      <td>{item?.CombinedChannel}</td>
                      <td>{item?.User}</td>
                      <td>
                        {item?.BookingDate &&
                          commonService.getDateInFormat(item?.BookingDate)}
                      </td>
                      <td>
                        {item?.CancellationDate &&
                          commonService.getDateInFormat(item?.CancellationDate)}
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td className="text-center" colSpan={15}>
                    No Data Found
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </div>
      {widgetModal && (
        <WidgetDetailModal
          showModal={widgetModal}
          closeModal={closeWidgetModal}
          data={widgetDetailRef.current}
        />
      )}
    </div>
  );
};

export default CancellationActivity;
