import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { validationRule } from "../../utils/constant";
import { apiCall } from "../../utils/axiosService";
import { API_URL } from "../../utils/apiRoutes";
import { apiResponse } from "../../utils/apiResponse";
import DatePicker from "react-datepicker";
import { checkValidation } from "../../utils/helper";
import { commonService } from "../../utils/commonService";

const AddTodoModal = ({ showModal, closeModal, todoId, getList }) => {
  let modalObj = {
    todoid: todoId,
    tododate: "",
    title: "",
    details: "",
    assigntouser: "",
    status: "Not Done",
    validationRule: [
      {
        fieldName: "tododate",
        rule: validationRule.required,
        message: "Date is required",
      },
      {
        fieldName: "title",
        rule: validationRule.required,
        message: "Title is required",
      },
      {
        fieldName: "assigntouser",
        rule: validationRule.required,
        message: "Assigned to user is required",
      },
    ],
    errorObj: {},
    isValidate: true,
  };

  const [loading, setLoading] = useState(false);
  const [todoForm, setTodoForm] = useState(modalObj);
  const [userList, setUserList] = useState(null);

  const getUserList = async () => {
    let moduleName = "revenue";
    if (window.location.href?.includes("configuration")) {
      moduleName = "configuration";
    } else if (window.location.href?.includes("revenue")) {
      moduleName = "revenue";
    } else if (window.location.href?.includes("crm")) {
      moduleName = "crm";
    }
    let userListResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.UserManagement + API_URL.USER.GET_LIST_BY_MODULE,
        body: {
          pageNum: 1,
          pageSize: 10,
          sortColumn: "",
          sortDirection: "asc",
          searchValue: "",
          email: "",
          mobileno: "",
          displayname: "",
          firstname: "",
          lastname: "",
          statusterm: "",
          isdefault: null,
          search: "",
          modulename: moduleName,
        },
      },
      false
    );

    let response = apiResponse(false, userListResponse);

    if (response?.isValidate) {
      setUserList(response?.data?.data);
    }

    if (!response?.isValidate) {
      setUserList(null);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setTodoForm({
      ...todoForm,
      [name]: value,
    });
  };

  const handleSubmit = async () => {
    setLoading(true);
    let validation = checkValidation(todoForm);

    if (!validation?.isValidate) {
      setTodoForm(validation);
      setLoading(false);
      return;
    }

    let saveResponse = await apiCall(
      {
        method: "POST",
        url: API_URL.BASE_API_URL.Configuration + API_URL.TODO.SAVE,
        body: {
          todoid: todoForm?.todoid,
          tododate: commonService.getDateInDBFormat(todoForm?.tododate),
          title: todoForm?.title,
          details: todoForm?.details,
          assigntouser: todoForm?.assigntouser,
          status: todoForm?.status,
        },
      },
      true
    );

    let response = apiResponse(true, saveResponse, setLoading);

    if (response?.isValidate) {
      if (getList) {
        getList();
      }
      closeModal();
      setLoading(false);
    }

    if (!response?.isValidate) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getUserList();
  }, []);

  return (
    <>
      <Modal
        className="AddPropertySetupModalMain todo-detail-modal-main-wrapper"
        size="lg"
        centered
        show={showModal}
        onHide={closeModal}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {`${todoId > 0 ? "Edit" : "Add"} Todo`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xl={6} md={12}>
              <div className="from-groups no-border-break mb-3">
                <Form.Label>Title</Form.Label>
                <Form.Control
                  type="text"
                  maxLength="321"
                  placeholder="Enter Title"
                  value={todoForm?.title}
                  name="title"
                  onChange={(e) => {
                    handleInputChange(e);
                  }}
                />
                {todoForm?.errorObj?.title && !todoForm?.title && (
                  <small className="text-danger">
                    {todoForm?.errorObj?.title}
                  </small>
                )}
              </div>
            </Col>
            <Col xl={6} md={12}>
              <div className="from-groups no-border-break mb-3">
                <Form.Label>Assigned To User</Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  value={todoForm?.assigntouser}
                  name="assigntouser"
                  onChange={(e) => {
                    handleInputChange(e);
                  }}
                >
                  <option value="">Select Assigned To User</option>
                  {userList?.map((item) => (
                    <option key={item?.userid} value={item?.userid}>
                      {item?.displayname}
                    </option>
                  ))}
                </Form.Select>
                {todoForm?.errorObj?.assigntouser &&
                  !todoForm?.assigntouser && (
                    <small className="text-danger">
                      {todoForm?.errorObj?.assigntouser}
                    </small>
                  )}
              </div>
            </Col>
            <Col xl={6} md={12}>
              <div className="from-groups no-border-break mb-3">
                <Form.Label>Todo Date</Form.Label>
                <DatePicker
                  id="startDate"
                  selected={todoForm?.tododate}
                  onChange={(date) => {
                    setTodoForm({
                      ...todoForm,
                      tododate: date,
                    });
                  }}
                />
                {todoForm?.errorObj?.tododate && !todoForm?.tododate && (
                  <small className="text-danger">
                    {todoForm?.errorObj?.tododate}
                  </small>
                )}
              </div>
            </Col>
            <Col xl={12} md={12}>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Detail</Form.Label>
                <Form.Control
                  as="textarea"
                  maxLength="3270"
                  rows={3}
                  value={todoForm?.details}
                  name="details"
                  onChange={(e) => {
                    handleInputChange(e);
                  }}
                />
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          {loading ? (
            <button className="default-btn primary-btn" type="button" disabled>
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              Loading...
            </button>
          ) : (
            <button
              className="default-btn primary-btn"
              onClick={() => {
                handleSubmit();
              }}
            >
              Submit
            </button>
          )}

          <Button className="default-btn secondary-btn" onClick={closeModal}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddTodoModal;
