import React, { useEffect, useRef, useState } from "react";
import { apiCall } from "../../../../utils/axiosService";
import { apiResponse } from "../../../../utils/apiResponse";
import { API_URL } from "../../../../utils/apiRoutes";
import { Button, Col, Form, Row, Table } from "react-bootstrap";
import downloadIcon from "../../../../Assets/Images/Operation/downloadIcon.svg";
import emailIcon from "../../../../Assets/Images/Email.svg";
import infoIcon from "../../../../Assets/Images/Action Icons/infoIcon..svg";
import { commonService } from "../../../../utils/commonService";
import moment from "moment";
import Loader from "../../../Loader/Loader";
import downArrow from "../../../../Assets/Images/Header/downArrow.svg";
import DatePicker from "react-datepicker";
import { compName } from "../../../../utils/constant";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import WidgetDetailModal from "../../WidgetDetailModal";
import { Chart, LinearScale, CategoryScale } from "chart.js";
import {
  LineWithErrorBarsChart,
  LineWithErrorBarsController,
  PointWithErrorBar,
} from "chartjs-chart-error-bars";
import EventViewModal from "../../../CommonComponent/EventViewModal";
import ticket from "../../../../Assets/Images/Revenue-management/tableTicket.svg";

Chart.register(
  LineWithErrorBarsController,
  LineWithErrorBarsChart,
  PointWithErrorBar,
  LinearScale,
  CategoryScale
);

const BenchMarking = ({
  setCompDataFromChild,
  rowIndex,
  colIndex,
  api,
  para,
  setting,
}) => {
  const defaultAsOfDateFromRTK = useSelector(
    (store) => store.headerProperty.defaultAsOfDate
  );
  const propertyDetailFromRTK = useSelector(
    (store) => store.headerProperty.propertyDetail
  );
  const { snapshottitle, propertyCode, snapshotId } = useParams();
  const endDateForState = new Date();
  endDateForState.setDate(new Date().getDate() + 14);
  const [loading, setLoading] = useState(false);
  const [benchMarkingData, setBenchMarkingData] = useState(null);
  const [asOfDate, setAsOfDate] = useState(para?.asOfDate);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(endDateForState);
  const asOfDatesArr = useRef();
  const [openRows, setOpenRows] = useState({});
  const [widgetModal, setWidgetModal] = useState(false);
  const widgetDetailRef = useRef();
  const [showCompTr, setShowCompTr] = useState({});
  const checkInDatesArrRef = useRef();
  const eventListRef = useRef();
  const eventListPropsRef = useRef();
  const selectedEventDateRef = useRef();
  const [showTicketModal, setShowTicketModal] = useState(false);

  const closeWidgetModal = () => {
    setWidgetModal(false);
  };

  const closeTicketModal = () => {
    setShowTicketModal(false);
  };

  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const getEventList = async () => {
    let eventList = await apiCall(
      {
        method: "POST",
        url: API_URL.BASE_API_URL.RevenueManagement + API_URL.EVENTS.GET_LIST,
        body: {
          pageNum: 1,
          pageSize: 10,
          sortColumn: "",
          sortDirection: "asc",
          searchValue: "",
          propertyid: parseInt(propertyDetailFromRTK?.propertyid),
          startdate: null,
          enddate: null,
          eventname: "",
          eventtypeterm: "",
          yearterm: ``,
        },
      },
      false
    );

    let eventListResponse = apiResponse(false, eventList);

    if (eventListResponse?.isValidate) {
      let eventListArr = [];

      eventListResponse?.data?.data?.forEach((item) => {
        let singleEventObj = {
          id: item?.eventid ? item?.eventid : 0,
          name: item?.eventname,
          detail: item?.eventdetails,
          startDate: item?.startdate,
          endDate: item?.enddate,
          eventJson: item?.eventjson,
          eventResourceId: item?.eventsourceid,
        };
        let eventJsonData = "";
        if (item?.eventjson) {
          var decodedString = decodeURIComponent(item?.eventjson);
          const jsonStringWithoutQuotes = decodedString.slice(1, -1);
          const jsonStringWithSingleQuotes = `${jsonStringWithoutQuotes}`;
          eventJsonData = JSON.parse(jsonStringWithSingleQuotes);
          let desc = eventJsonData?.description?.replaceAll("+", " ");
          singleEventObj = {
            ...singleEventObj,
            labels: eventJsonData?.labels ? eventJsonData?.labels : "",
            rank: eventJsonData?.rank ? eventJsonData?.rank : 0,
            local_rank: eventJsonData?.local_rank
              ? eventJsonData?.local_rank
              : 0,
            phq_attendance: eventJsonData?.phq_attendance
              ? eventJsonData?.phq_attendance
              : 0,
            geo_lat: eventJsonData?.geo_lat ? eventJsonData?.geo_lat : "",
            geo_long: eventJsonData?.geo_long ? eventJsonData?.geo_long : "",
            detail: desc,
          };
        }
        eventListArr.push(singleEventObj);
      });
      eventListRef.current = eventListArr;
    }

    if (!eventListResponse?.isValidate) {
      eventListRef.current = [];
    }
  };

  const getBenchMarkingData = async () => {
    setLoading(true);
    await getEventList();
    let benchMarkingResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.SnapShot + API_URL.RATESHOP.GET_RATE_EVOLUTION,
        body: {
          AsOfDate: commonService.getDateForNode(asOfDate),
          checkinstartdate: commonService.getDateForNode(startDate),
          checkinenddate: commonService.getDateForNode(endDate),
          propertyCode: para?.propertyCode,
        },
      },
      false
    );

    let response = apiResponse(false, benchMarkingResponse);

    if (response?.isValidate) {
      let dataFromApi = response?.data?.data;
      let groupingJson = { high: 0, low: 0, data: [] };
      let checkInDatesObj = {};
      let asOfDateWiseArr = [];
      for (let i = 0; i <= 14; i++) {
        let date = new Date(commonService.getDateInFormat(asOfDate));
        date.setDate(date.getDate() - i);
        asOfDateWiseArr.push(moment(date).format("YYYY-MM-DD"));
      }
      asOfDatesArr.current = asOfDateWiseArr;
      // dataFromApi?.forEach((item) => {
      //   if (!checkInDatesObj[item?.checkindate]) {
      //     if (item?.isself === true || item?.isself === "true") {
      //       let obj = { ...item },
      //         high = 0,
      //         low = 0;
      //       let findCompetitorData = dataFromApi?.filter(
      //         (x) => x?.checkindate === item?.checkindate && x?.isself === false
      //       );
      //       let highLowObj = {};
      //       findCompetitorData?.forEach((compItem) => {
      //         asOfDateWiseArr?.forEach((asOfDateItem) => {
      //           if (
      //             highLowObj[`${asOfDateItem}Rate`] &&
      //             highLowObj[`${asOfDateItem}Count`]
      //           ) {
      //             highLowObj[`${asOfDateItem}Rate`] =
      //               highLowObj[`${asOfDateItem}Rate`] +
      //               (compItem[asOfDateItem]
      //                 ? Math.round(compItem[asOfDateItem])
      //                 : 0);
      //             highLowObj[`${asOfDateItem}Count`] = compItem[asOfDateItem]
      //               ? highLowObj[`${asOfDateItem}Count`] + 1
      //               : highLowObj[`${asOfDateItem}Count`];
      //           } else {
      //             highLowObj[`${asOfDateItem}Rate`] = compItem[asOfDateItem]
      //               ? Math.round(compItem[asOfDateItem])
      //               : 0;
      //             highLowObj[`${asOfDateItem}Count`] = compItem[asOfDateItem]
      //               ? 1
      //               : 0;
      //           }
      //         });
      //       });

      //       asOfDateWiseArr.forEach((highLowItem) => {
      //         let rate =
      //           highLowObj[`${highLowItem}Rate`] /
      //           highLowObj[`${highLowItem}Count`];
      //         let selfRate = item[highLowItem]
      //           ? Math.round(item[highLowItem])
      //           : 0;
      //         if (selfRate > rate) {
      //           high = high + 1;
      //           obj[`${highLowItem}-isHigh`] = true;
      //         } else {
      //           low = low + 1;
      //           obj[`${highLowItem}-isHigh`] = false;
      //         }
      //       });
      //       obj.high = high;
      //       obj.low = low;

      //       obj.children = findCompetitorData;
      //       groupingJson?.data.push(obj);
      //     }
      //   }
      // });
      // asOfDatesArr.current?.reverse();
      // let updatedData = groupingJson?.data?.map((item) => {
      //   let chartData = [],
      //     avgData = [],
      //     labels = [],
      //     compAvg = {};
      //   asOfDatesArr.current?.map((asOfDateItem) => {
      //     let totalOfCompetitor = 0,
      //       countForAvg = 0,
      //       min = Infinity,
      //       max = -Infinity;
      //     item?.children?.forEach((compItem) => {
      //       if (compItem?.[asOfDateItem]) {
      //         min =
      //           parseInt(compItem?.[asOfDateItem]) < min
      //             ? Math.round(compItem?.[asOfDateItem])
      //             : min;
      //         max =
      //           parseInt(compItem?.[asOfDateItem]) > max
      //             ? parseInt(compItem?.[asOfDateItem])
      //             : max;
      //         countForAvg = countForAvg + 1;
      //         totalOfCompetitor =
      //           totalOfCompetitor +
      //           (compItem?.[asOfDateItem]
      //             ? parseInt(compItem?.[asOfDateItem])
      //             : 0);
      //       }
      //     });
      //     let avgOfCompetitor = Math.round(totalOfCompetitor / countForAvg);
      //     compAvg[asOfDateItem] = avgOfCompetitor;
      //     chartData.push({
      //       y: item?.[asOfDateItem] ? Math.round(item?.[asOfDateItem]) : 0,
      //       y1: avgOfCompetitor,
      //       yMin: min,
      //       yMax: max,
      //     });
      //     avgData.push(avgOfCompetitor);
      //     labels.push("");
      //   });
      //   return {
      //     ...item,
      //     compAvg,
      //     avgData,
      //     labels,
      //     chartData,
      //   };
      // });
      // groupingJson.data = updatedData;
      // setBenchMarkingData(groupingJson);
      checkInDatesArrRef.current = commonService.getDateBetweenTwoDates(
        startDate,
        endDate
      );
      setBenchMarkingData(response?.data?.data);
      setLoading(false);
    }

    if (!response?.isValidate) {
      asOfDatesArr.current = [];
      setBenchMarkingData(null);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (api !== 0) {
      if (asOfDate && startDate && endDate) {
        getBenchMarkingData();
      }
    }
  }, [asOfDate, startDate, endDate]);

  useEffect(() => {
    if (para?.asOfDate) {
      setAsOfDate(para?.asOfDate);
    }
  }, [para?.asOfDate]);
  // useEffect(() => {
  //   benchMarkingData?.data?.map((item, index) => {
  //     let dataSet = {
  //       labels: item?.labels,
  //       datasets: [
  //         {
  //           data: item?.chartData,
  //           label: "",
  //           borderColor: "rgba(75, 192, 192, 1)",
  //           borderWidth: 1,
  //           backgroundColor: "rgba(75, 192, 192, 0.2)",
  //           errorBars: true,
  //         },
  //       ],
  //     };

  //     const chart = new Chart(
  //       document.getElementById(`${index}`)?.getContext("2d"),
  //       {
  //         type: LineWithErrorBarsController.id,
  //         data: dataSet,
  //         options: {
  //           plugins: {
  //             tooltip: {
  //               callbacks: {
  //                 label: function (context) {
  //                   const dataPoint = context.raw;
  //                   return `Self: ${dataPoint.y}, Avg: ${dataPoint.y1}, Min: ${dataPoint.yMin}, Max: ${dataPoint.yMax}`;
  //                 },
  //               },
  //             },
  //           },
  //         },
  //       }
  //     );
  //   });
  // }, [benchMarkingData]);

  useEffect(() => {
    asOfDatesArr.current?.forEach((item) => {
      let findValue = document.getElementById(`${item}-chart`);
      let labels = [],
        barChart = JSON.parse(findValue.getAttribute("value")),
        avgChart = JSON.parse(findValue.getAttribute("avgValue"));
      checkInDatesArrRef.current?.forEach((i) => {
        labels.push("");
      });
      let dataSet = {
        labels,
        datasets: [
          {
            data: barChart,
            label: "",
            borderColor: "green",
            borderWidth: 1,
            backgroundColor: "rgba(75, 192, 192, 0.2)",
            errorBars: true,
          },
          {
            data: avgChart,
            label: "",
            borderColor: "#000000",
            borderWidth: 1,
            pointRadius: 0,
            backgroundColor: "#000000",
            errorBars: false,
          },
        ],
      };

      const chart = new Chart(
        document.getElementById(`${item}-chart`)?.getContext("2d"),
        {
          type: LineWithErrorBarsController.id,
          data: dataSet,
          options: {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
              y: {
                ticks: {
                  stepSize: 50,
                },
              },
            },
            plugins: {
              tooltip: {
                callbacks: {
                  label: function (context) {
                    const dataIndex = context.dataIndex;
                    return `Self: ${barChart?.[dataIndex].y}, Avg: ${barChart?.[dataIndex].y1}, Min: ${barChart?.[dataIndex].yMin}, Max: ${barChart?.[dataIndex].yMax}`;
                  },
                },
              },
            },
          },
        }
      );
    });
  }, [benchMarkingData]);

  const [tblids, settblids] = useState({
    tblBenchMarking: commonService.GenerateGUID(),
  });

  return (
    <div className="widget-container">
      <Row>
        <div className="form-groups from-groups no-border-break benchMarkingDatePicker width150">
          <Form.Label>As of Date</Form.Label>
          <DatePicker
            selected={asOfDate ? new Date(asOfDate) : ""}
            onChange={(date) => {
              setAsOfDate(date);
            }}
          />
        </div>
        <div className="form-groups from-groups no-border-break meghaDatePicker width225">
          <Form.Label>Check In Dates</Form.Label>
          <DatePicker
            onChange={handleDateChange}
            startDate={startDate}
            endDate={endDate}
            selectsRange
            monthsShown={2}
          />
        </div>
      </Row>
      {api !== 0 && (
        <div className="gap-1 widget-container-menu">
          <Button className="heading-menu-btns" type="button" title="Send Mail">
            <img
              src={emailIcon}
              alt="emailIcon"
              onClick={() => {
                commonService.fnSendWidgetMail(
                  tblids.tblBenchMarking,
                  "Rateshop Benchmarking",
                  snapshotId,
                  defaultAsOfDateFromRTK,
                  "",
                  para?.widgetId
                );
              }}
            />
          </Button>
          <Button className="heading-menu-btns" type="button">
            <img
              src={infoIcon}
              alt="info"
              onClick={() => {
                widgetDetailRef.current = {
                  widgetdetails: "",
                  usernote: "",
                  compName: compName?.rateShopBenchmarking,
                  widgetId: para?.widgetId,
                };
                setWidgetModal(true);
              }}
            />
          </Button>
          <Button className="heading-menu-btns" type="button">
            <img
              src={downloadIcon}
              alt="menuIcon"
              import={tblids.tblBenchMarking}
              onClick={() => {
                commonService.fnExportInCSV(
                  tblids.tblBenchMarking,
                  // "Rateshop Benchmarking"
                  `${para?.propertyCode}-Rate Shop Benchmarking-${moment(
                    new Date()
                  ).format("MMDDYYYY")}`
                );
              }}
            />
          </Button>
        </div>
      )}
      <div className="ViewPropertySetupTable mt-2">
        <div className="stripped__table">
          <div className="table-responsive tblDynamicPickupSticky">
            <Table id={tblids?.tblBenchMarking}>
              <thead className="tbl-header-sticky">
                <tr className="table-main-header-tr">
                  {/* <th className="border_right">High</th>
                <th className="border_right">Low</th>
                {asOfDatesArr.current?.map((item) => {
                  return (
                    <th className="border_right text-end width50" key={item}>
                      {item ? moment(item).format("MM/DD") : ""}
                    </th>
                  );
                })}
                <th className="width100">Check In Date</th> */}
                  <th className="border_right">As Of Date</th>
                  {checkInDatesArrRef.current?.map((item) => {
                    return (
                      <th
                        className="border_right text-center width50"
                        key={item}
                      >
                        {item ? moment(item).format("MM/DD") : ""}
                      </th>
                    );
                  })}
                  <th className="border_right text-center width50">High</th>
                  <th className="text-center width50">Low</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr style={{ height: "80px", position: "relative" }}>
                    <Loader />
                  </tr>
                ) : benchMarkingData?.length > 0 ? (
                  // benchMarkingData?.data?.map((item, index) => {
                  //   let classForChildren = "benchMarkingCompetitorRow";
                  //   if (openRows[item?.checkindate]) {
                  //     classForChildren = "benchMarkingTr";
                  //   }

                  //   let classForCompData = "compRow";
                  //   if (showCompTr[item?.checkindate]) {
                  //     classForCompData = "compTr";
                  //   }
                  //   return (
                  //     <>
                  //       <tr
                  //         onClick={() => {
                  //           if (openRows[item?.checkindate]) {
                  //             setOpenRows({});
                  //           } else {
                  //             setOpenRows({
                  //               [item?.checkindate]: item?.checkindate,
                  //             });
                  //           }

                  //           if (showCompTr[item?.checkindate]) {
                  //             setShowCompTr({});
                  //           }
                  //         }}
                  //         className="shadowOnHover"
                  //       >
                  //         <td className="border_right">{item?.high}</td>
                  //         <td className="border_right">{item?.low}</td>
                  //         {asOfDatesArr.current?.map((asOfDateItem) => {
                  //           let backgroundColor = "";
                  //           if (item[`${asOfDateItem}-isHigh`]) {
                  //             backgroundColor = "red";
                  //           } else {
                  //             backgroundColor = "green";
                  //           }
                  //           return (
                  //             <td
                  //               key={asOfDateItem}
                  //               className="border_right text-end"
                  //             >
                  //               <p
                  //                 style={{
                  //                   color: backgroundColor,
                  //                   fontWeight: "bold",
                  //                 }}
                  //               >
                  //                 {item?.[asOfDateItem]
                  //                   ? `$${Math.round(item?.[asOfDateItem])}`
                  //                   : ""}
                  //               </p>
                  //               <p
                  //                 style={{ fontWeight: "bold", marginTop: "5px" }}
                  //               >
                  //                 {item?.compAvg?.[asOfDateItem]
                  //                   ? `$${Math.round(
                  //                       item?.compAvg?.[asOfDateItem]
                  //                     )}`
                  //                   : ""}
                  //               </p>
                  //             </td>
                  //           );
                  //         })}
                  //         <td className="cursorPointer">
                  //           <div className={`d-flex justify-content-between`}>
                  //             <p>
                  //               {item?.checkindate
                  //                 ? moment(item?.checkindate).format("MM/DD")
                  //                 : ""}
                  //             </p>
                  //             <img src={downArrow} />
                  //           </div>
                  //         </td>
                  //       </tr>
                  //       <tr className={`${classForChildren}`}>
                  //         <td
                  //           style={{
                  //             verticalAlign: "bottom",
                  //             fontWeight: "bold",
                  //             cursor: "pointer",
                  //           }}
                  //         >
                  //           <p
                  //             onClick={(e) => {
                  //               if (showCompTr[item?.checkindate]) {
                  //                 setShowCompTr({});
                  //               } else {
                  //                 setShowCompTr({
                  //                   [item?.checkindate]: item?.checkindate,
                  //                 });
                  //               }
                  //             }}
                  //           >
                  //             Show Competitor Data
                  //           </p>
                  //         </td>
                  //         <td></td>
                  //         <td colSpan={15}>
                  //           <div style={{ height: "400px" }}>
                  //             <canvas id={`${index}`}></canvas>
                  //           </div>
                  //         </td>
                  //         <td></td>
                  //       </tr>
                  //       {item?.children?.map((childrenItem) => {
                  //         return (
                  //           <tr className={`${classForCompData}`}>
                  //             <td className="border_right" colSpan={2}>
                  //               {childrenItem?.competiterpropertyname}
                  //             </td>
                  //             {asOfDatesArr.current?.map((childrenAsOfDate) => {
                  //               return (
                  //                 <td
                  //                   key={`children-${childrenAsOfDate}`}
                  //                   className="border_right text-end"
                  //                 >
                  //                   {childrenItem?.[childrenAsOfDate]
                  //                     ? `$${Math.round(
                  //                         childrenItem?.[childrenAsOfDate]
                  //                       )}`
                  //                     : ""}
                  //                 </td>
                  //               );
                  //             })}
                  //             <td></td>
                  //           </tr>
                  //         );
                  //       })}
                  //     </>
                  //   );
                  // })
                  <>
                    {asOfDatesArr.current?.map((asofDateItem) => {
                      const competiterpropertyname = benchMarkingData.reduce(
                        (acc, obj) => {
                          if (
                            !acc.includes(obj.competiterpropertyname) &&
                            obj?.isself === false
                          ) {
                            acc.push(obj.competiterpropertyname);
                          }
                          return acc;
                        },
                        []
                      );

                      let high = 0,
                        low = 0,
                        chartData = [],
                        avgData = [];
                      let classForChildren = "benchMarkingCompetitorRow";
                      if (openRows[asofDateItem]) {
                        classForChildren = "benchMarkingTr";
                      }

                      let classForCompData = "compRow";
                      if (showCompTr[asofDateItem]) {
                        classForCompData = "compTr";
                      }

                      return (
                        <>
                          <tr
                            onClick={() => {
                              if (openRows[asofDateItem]) {
                                setOpenRows({});
                              } else {
                                setOpenRows({
                                  [asofDateItem]: asofDateItem,
                                });
                              }

                              setShowCompTr({});
                            }}
                            className="shadowOnHover"
                          >
                            <td className="border_right">
                              <div className={`d-flex gap-2`}>
                                <p>
                                  {asofDateItem
                                    ? moment(asofDateItem).format("MM/DD")
                                    : ""}
                                </p>
                                <img src={downArrow} />
                              </div>
                            </td>
                            {checkInDatesArrRef.current?.map(
                              (checkInDateItem) => {
                                let currentCheckInData =
                                  benchMarkingData?.filter(
                                    (x) =>
                                      commonService.getDateInFormat(
                                        x?.checkindate
                                      ) ===
                                      commonService.getDateInFormat(
                                        checkInDateItem
                                      )
                                  );

                                let selfData = currentCheckInData?.filter(
                                  (x) => x?.isself === true
                                );
                                let competitorData = currentCheckInData?.filter(
                                  (x) => x?.isself === false
                                );

                                let totalOfCompetitor = 0,
                                  countForAvg = 0,
                                  min = Infinity,
                                  max = -Infinity;

                                competitorData?.forEach((compItem) => {
                                  if (compItem?.[asofDateItem]) {
                                    totalOfCompetitor =
                                      totalOfCompetitor +
                                      parseInt(compItem?.[asofDateItem]);
                                    countForAvg = countForAvg + 1;
                                    min =
                                      (compItem?.[asofDateItem]
                                        ? parseInt(compItem?.[asofDateItem])
                                        : 0) < min
                                        ? parseInt(compItem?.[asofDateItem])
                                        : min;
                                    max =
                                      (compItem?.[asofDateItem]
                                        ? parseInt(compItem?.[asofDateItem])
                                        : 0) > max
                                        ? parseInt(compItem?.[asofDateItem])
                                        : max;
                                  }
                                });

                                let compAvg =
                                  totalOfCompetitor && countForAvg
                                    ? Math.round(
                                        totalOfCompetitor / countForAvg
                                      )
                                    : 0;

                                chartData.push({
                                  y: selfData?.[0]?.[asofDateItem]
                                    ? Math.round(selfData?.[0]?.[asofDateItem])
                                    : 0,
                                  y1: compAvg,
                                  yMin: min === Infinity ? 0 : min,
                                  yMax: max === -Infinity ? 0 : max,
                                });
                                avgData.push(compAvg);

                                let backgroundColor = "";
                                if (selfData?.[0]?.[asofDateItem] > compAvg) {
                                  backgroundColor = "red";
                                  high = high + 1;
                                } else {
                                  backgroundColor = "green";
                                  low = low + 1;
                                }

                                return (
                                  <>
                                    <td className="border_right text-center">
                                      <p
                                        style={{
                                          color: backgroundColor,
                                          fontWeight: "bold",
                                        }}
                                      >
                                        {selfData?.[0]?.[asofDateItem]
                                          ? `${commonService.formateAmount(
                                              Math.round(
                                                selfData?.[0]?.[asofDateItem]
                                              )
                                            )}`
                                          : ""}
                                      </p>
                                      <p
                                        style={{
                                          fontWeight: "bold",
                                          marginTop: "5px",
                                        }}
                                      >
                                        {compAvg
                                          ? `${commonService.formateAmount(
                                              Math.round(compAvg)
                                            )}`
                                          : ""}
                                      </p>
                                    </td>
                                  </>
                                );
                              }
                            )}
                            <td className="border_right text-center">{high}</td>
                            <td className="text-center">{low}</td>
                          </tr>
                          <tr className={`${classForChildren} shadowOnHover`}>
                            <td
                              style={{
                                verticalAlign: "bottom",
                                fontWeight: "bold",
                                cursor: "pointer",
                              }}
                            >
                              <p
                                onClick={(e) => {
                                  if (showCompTr[asofDateItem]) {
                                    setShowCompTr({});
                                  } else {
                                    setShowCompTr({
                                      [asofDateItem]: asofDateItem,
                                    });
                                  }
                                }}
                              >
                                Show Competitor Data
                              </p>
                            </td>
                            <td colSpan={checkInDatesArrRef.current?.length}>
                              <div style={{ height: "200px" }}>
                                <canvas
                                  id={`${asofDateItem}-chart`}
                                  value={JSON.stringify(chartData)}
                                  avgValue={JSON.stringify(avgData)}
                                ></canvas>
                              </div>
                            </td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr className={`${classForChildren} shadowOnHover`}>
                            <td className="border_right"></td>
                            {checkInDatesArrRef.current?.map((eventItem) => {
                              let checkEvent = eventListRef.current?.filter(
                                (x) => {
                                  let checkingDate = moment(eventItem);
                                  let startingDate = moment(x?.startDate);
                                  let endingDate = moment(x?.endDate);
                                  if (
                                    checkingDate.isSameOrAfter(startingDate) &&
                                    checkingDate.isSameOrBefore(endingDate)
                                  ) {
                                    return x;
                                  }
                                }
                              );

                              return (
                                <>
                                  <td className="border_right">
                                    {checkEvent?.length > 0 && (
                                      <div className="d-flex align-items-center gap-2 cursorPointer">
                                        <img
                                          style={{
                                            height: "11px",
                                            width: "11px",
                                          }}
                                          className="iconcolor"
                                          src={ticket}
                                          alt="ticket"
                                          onClick={() => {
                                            selectedEventDateRef.current =
                                              eventItem;
                                            eventListPropsRef.current =
                                              checkEvent;
                                            setShowTicketModal(true);
                                          }}
                                        />
                                        <p>{`(${checkEvent?.length})`}</p>
                                      </div>
                                    )}
                                  </td>
                                </>
                              );
                            })}
                            <td className="border_right"></td>
                            <td></td>
                          </tr>
                          {competiterpropertyname.map((comname, index) => {
                            return (
                              <tr
                                className={`${classForCompData} shadowOnHover`}
                              >
                                <td className="border_right">{comname}</td>
                                {checkInDatesArrRef.current?.map(
                                  (checkInDateItem) => {
                                    let currentCompData =
                                      benchMarkingData?.filter(
                                        (x) =>
                                          commonService.getDateInFormat(
                                            x?.checkindate
                                          ) ===
                                            commonService.getDateInFormat(
                                              checkInDateItem
                                            ) &&
                                          x?.isself === false &&
                                          x?.competiterpropertyname === comname
                                      );
                                    return (
                                      <td className="border_right text-center">
                                        {currentCompData?.[0]?.[asofDateItem]
                                          ? `${commonService.formateAmount(
                                              Math.round(
                                                currentCompData?.[0]?.[
                                                  asofDateItem
                                                ]
                                              )
                                            )}`
                                          : ""}
                                      </td>
                                    );
                                  }
                                )}
                                <td className="border_right"></td>
                                <td></td>
                              </tr>
                            );
                          })}
                        </>
                      );
                    })}
                  </>
                ) : (
                  <tr>
                    <td className="text-center" colSpan={50}>
                      No Data Found
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
      {widgetModal && (
        <WidgetDetailModal
          showModal={widgetModal}
          closeModal={closeWidgetModal}
          data={widgetDetailRef.current}
        />
      )}
      {showTicketModal && (
        <EventViewModal
          showModal={showTicketModal}
          closeModal={closeTicketModal}
          eventDetails={eventListPropsRef.current}
          selectedDate={selectedEventDateRef.current}
          getEventList={getEventList}
        />
      )}
    </div>
  );
};

export default BenchMarking;
