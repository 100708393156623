import React, { useEffect, useRef, useState } from "react";
import { Button, Table } from "react-bootstrap";
import Loader from "../../../Loader/Loader";
import NoteIcon from "../../../../Assets/Images/CRM/notes.svg";
import downloadIcon from "../../../../Assets/Images/Operation/downloadIcon.svg";
import { commonService } from "../../../../utils/commonService";
import { apiCall } from "../../../../utils/axiosService";
import { API_URL } from "../../../../utils/apiRoutes";
import { apiResponse } from "../../../../utils/apiResponse";
import { FixColumn } from "../../../../utils/dataTable";
import moment from "moment";
import { compName } from "../../../../utils/constant";
import WidgetDetailModal from "../../WidgetDetailModal";
import WidgetNote from "../../WidgetNote";
import { useSelector } from "react-redux";
import infoIcon from "../../../../Assets/Images/Action Icons/infoIcon..svg";
import AddWidgetModal from "../../AddWidgetModal";
import { useParams } from "react-router-dom";
import emailIcon from "../../../../Assets/Images/Email.svg";
import Comments from "../Comments/Comment";

const DynamicPickup = ({
  setCompDataFromChild,
  rowIndex,
  colIndex,
  api,
  para,
  setting,
}) => {
  const tableData = [
    {
      staydate: 1688083200000,
      day_diff: 1,
      total_booking: 0,
      forcastroom: 0,
      pu_avg: 0,
      dba_90: 0,
      pu_90: 0,
      dba_89: 0,
      pu_89: 0,
      dba_88: 0,
      pu_88: 0,
      dba_87: 0,
      pu_87: 0,
      dba_86: 0,
      pu_86: 0,
      dba_85: 0,
      pu_85: 0,
      dba_84: 0,
      pu_84: 0,
      dba_83: 0,
      pu_83: 0,
      dba_82: 0,
      pu_82: 0,
      dba_81: 0,
      pu_81: 0,
      dba_80: 0,
      pu_80: 0,
      dba_79: 0,
      pu_79: 0,
      dba_78: 0,
      pu_78: 0,
      dba_77: 0,
      pu_77: 0,
      dba_76: 0,
      pu_76: 0,
      dba_75: 0,
      pu_75: 0,
      dba_74: 0,
      pu_74: 0,
      dba_73: 0,
      pu_73: 0,
      dba_72: 0,
      pu_72: 0,
      dba_71: 0,
      pu_71: 0,
      dba_70: 0,
      pu_70: 0,
      dba_69: 0,
      pu_69: 0,
      dba_68: 0,
      pu_68: 0,
      dba_67: 0,
      pu_67: 0,
      dba_66: 0,
      pu_66: 0,
      dba_65: 0,
      pu_65: 0,
      dba_64: 0,
      pu_64: 0,
      dba_63: 0,
      pu_63: 0,
      dba_62: 0,
      pu_62: 0,
      dba_61: 1,
      pu_61: 0,
      dba_60: 0,
      pu_60: 0,
      dba_59: 0,
      pu_59: 0,
      dba_58: 0,
      pu_58: 0,
      dba_57: 0,
      pu_57: 0,
      dba_56: 0,
      pu_56: 0,
      dba_55: 0,
      pu_55: 0,
      dba_54: 0,
      pu_54: 0,
      dba_53: 0,
      pu_53: 0,
      dba_52: 0,
      pu_52: 0,
      dba_51: 0,
      pu_51: 0,
      dba_50: 0,
      pu_50: 0,
      dba_49: 0,
      pu_49: 0,
      dba_48: 0,
      pu_48: 0,
      dba_47: 0,
      pu_47: 0,
      dba_46: 0,
      pu_46: 0,
      dba_45: 0,
      pu_45: 0,
      dba_44: 0,
      pu_44: 0,
      dba_43: 0,
      pu_43: 0,
      dba_42: 0,
      pu_42: 0,
      dba_41: 0,
      pu_41: 0,
      dba_40: 0,
      pu_40: 0,
      dba_39: 0,
      pu_39: 0,
      dba_38: 0,
      pu_38: 0,
      dba_37: 0,
      pu_37: 0,
      dba_36: 0,
      pu_36: 0,
      dba_35: 0,
      pu_35: 0,
      dba_34: 0,
      pu_34: 0,
      dba_33: 0,
      pu_33: 0,
      dba_32: 0,
      pu_32: 0,
      dba_31: 0,
      pu_31: 0,
      dba_30: 0,
      pu_30: 0,
      dba_29: 0,
      pu_29: 0,
      dba_28: 0,
      pu_28: 0,
      dba_27: 0,
      pu_27: 0,
      dba_26: 0,
      pu_26: 0,
      dba_25: 0,
      pu_25: 0,
      dba_24: 0,
      pu_24: 0,
      dba_23: 0,
      pu_23: 0,
      dba_22: 0,
      pu_22: 0,
      dba_21: 0,
      pu_21: 0,
      dba_20: 0,
      pu_20: 0,
      dba_19: 0,
      pu_19: 0,
      dba_18: 0,
      pu_18: 0,
      dba_17: 0,
      pu_17: 0,
      dba_16: 0,
      pu_16: 0,
      dba_15: 0,
      pu_15: 0,
      dba_14: 0,
      pu_14: 0,
      dba_13: 0,
      pu_13: 0,
      dba_12: 0,
      pu_12: 0,
      dba_11: 0,
      pu_11: 0,
      dba_10: 0,
      pu_10: 0,
      dba_9: 0,
      pu_9: 0,
      dba_8: 0,
      pu_8: 0,
      dba_7: 0,
      pu_7: 0,
      dba_6: 0,
      pu_6: 0,
      dba_5: 0,
      pu_5: 0,
      dba_4: 0,
      pu_4: 0,
      dba_3: 0,
      pu_3: 0,
      dba_2: 0,
      pu_2: 0,
      dba_1: 0,
      pu_1: 0,
      dba_0: 0,
      pu_0: 0,
    },
    {
      staydate: 1688083200000,
      day_diff: 1,
      total_booking: 0,
      forcastroom: 0,
      pu_avg: 0,
      dba_90: 0,
      pu_90: 0,
      dba_89: 0,
      pu_89: 0,
      dba_88: 0,
      pu_88: 0,
      dba_87: 0,
      pu_87: 0,
      dba_86: 0,
      pu_86: 0,
      dba_85: 0,
      pu_85: 0,
      dba_84: 0,
      pu_84: 0,
      dba_83: 0,
      pu_83: 0,
      dba_82: 0,
      pu_82: 0,
      dba_81: 0,
      pu_81: 0,
      dba_80: 0,
      pu_80: 0,
      dba_79: 0,
      pu_79: 0,
      dba_78: 0,
      pu_78: 0,
      dba_77: 0,
      pu_77: 0,
      dba_76: 0,
      pu_76: 0,
      dba_75: 0,
      pu_75: 0,
      dba_74: 0,
      pu_74: 0,
      dba_73: 0,
      pu_73: 0,
      dba_72: 0,
      pu_72: 0,
      dba_71: 0,
      pu_71: 0,
      dba_70: 0,
      pu_70: 0,
      dba_69: 0,
      pu_69: 0,
      dba_68: 0,
      pu_68: 0,
      dba_67: 0,
      pu_67: 0,
      dba_66: 0,
      pu_66: 0,
      dba_65: 0,
      pu_65: 0,
      dba_64: 0,
      pu_64: 0,
      dba_63: 0,
      pu_63: 0,
      dba_62: 0,
      pu_62: 0,
      dba_61: 1,
      pu_61: 0,
      dba_60: 0,
      pu_60: 0,
      dba_59: 0,
      pu_59: 0,
      dba_58: 0,
      pu_58: 0,
      dba_57: 0,
      pu_57: 0,
      dba_56: 0,
      pu_56: 0,
      dba_55: 0,
      pu_55: 0,
      dba_54: 0,
      pu_54: 0,
      dba_53: 0,
      pu_53: 0,
      dba_52: 0,
      pu_52: 0,
      dba_51: 0,
      pu_51: 0,
      dba_50: 0,
      pu_50: 0,
      dba_49: 0,
      pu_49: 0,
      dba_48: 0,
      pu_48: 0,
      dba_47: 0,
      pu_47: 0,
      dba_46: 0,
      pu_46: 0,
      dba_45: 0,
      pu_45: 0,
      dba_44: 0,
      pu_44: 0,
      dba_43: 0,
      pu_43: 0,
      dba_42: 0,
      pu_42: 0,
      dba_41: 0,
      pu_41: 0,
      dba_40: 0,
      pu_40: 0,
      dba_39: 0,
      pu_39: 0,
      dba_38: 0,
      pu_38: 0,
      dba_37: 0,
      pu_37: 0,
      dba_36: 0,
      pu_36: 0,
      dba_35: 0,
      pu_35: 0,
      dba_34: 0,
      pu_34: 0,
      dba_33: 0,
      pu_33: 0,
      dba_32: 0,
      pu_32: 0,
      dba_31: 0,
      pu_31: 0,
      dba_30: 0,
      pu_30: 0,
      dba_29: 0,
      pu_29: 0,
      dba_28: 0,
      pu_28: 0,
      dba_27: 0,
      pu_27: 0,
      dba_26: 0,
      pu_26: 0,
      dba_25: 0,
      pu_25: 0,
      dba_24: 0,
      pu_24: 0,
      dba_23: 0,
      pu_23: 0,
      dba_22: 0,
      pu_22: 0,
      dba_21: 0,
      pu_21: 0,
      dba_20: 0,
      pu_20: 0,
      dba_19: 0,
      pu_19: 0,
      dba_18: 0,
      pu_18: 0,
      dba_17: 0,
      pu_17: 0,
      dba_16: 0,
      pu_16: 0,
      dba_15: 0,
      pu_15: 0,
      dba_14: 0,
      pu_14: 0,
      dba_13: 0,
      pu_13: 0,
      dba_12: 0,
      pu_12: 0,
      dba_11: 0,
      pu_11: 0,
      dba_10: 0,
      pu_10: 0,
      dba_9: 0,
      pu_9: 0,
      dba_8: 0,
      pu_8: 0,
      dba_7: 0,
      pu_7: 0,
      dba_6: 0,
      pu_6: 0,
      dba_5: 0,
      pu_5: 0,
      dba_4: 0,
      pu_4: 0,
      dba_3: 0,
      pu_3: 0,
      dba_2: 0,
      pu_2: 0,
      dba_1: 0,
      pu_1: 0,
      dba_0: 0,
      pu_0: 0,
    },
    {
      staydate: 1688083200000,
      day_diff: 1,
      total_booking: 0,
      forcastroom: 0,
      pu_avg: 0,
      dba_90: 0,
      pu_90: 0,
      dba_89: 0,
      pu_89: 0,
      dba_88: 0,
      pu_88: 0,
      dba_87: 0,
      pu_87: 0,
      dba_86: 0,
      pu_86: 0,
      dba_85: 0,
      pu_85: 0,
      dba_84: 0,
      pu_84: 0,
      dba_83: 0,
      pu_83: 0,
      dba_82: 0,
      pu_82: 0,
      dba_81: 0,
      pu_81: 0,
      dba_80: 0,
      pu_80: 0,
      dba_79: 0,
      pu_79: 0,
      dba_78: 0,
      pu_78: 0,
      dba_77: 0,
      pu_77: 0,
      dba_76: 0,
      pu_76: 0,
      dba_75: 0,
      pu_75: 0,
      dba_74: 0,
      pu_74: 0,
      dba_73: 0,
      pu_73: 0,
      dba_72: 0,
      pu_72: 0,
      dba_71: 0,
      pu_71: 0,
      dba_70: 0,
      pu_70: 0,
      dba_69: 0,
      pu_69: 0,
      dba_68: 0,
      pu_68: 0,
      dba_67: 0,
      pu_67: 0,
      dba_66: 0,
      pu_66: 0,
      dba_65: 0,
      pu_65: 0,
      dba_64: 0,
      pu_64: 0,
      dba_63: 0,
      pu_63: 0,
      dba_62: 0,
      pu_62: 0,
      dba_61: 1,
      pu_61: 0,
      dba_60: 0,
      pu_60: 0,
      dba_59: 0,
      pu_59: 0,
      dba_58: 0,
      pu_58: 0,
      dba_57: 0,
      pu_57: 0,
      dba_56: 0,
      pu_56: 0,
      dba_55: 0,
      pu_55: 0,
      dba_54: 0,
      pu_54: 0,
      dba_53: 0,
      pu_53: 0,
      dba_52: 0,
      pu_52: 0,
      dba_51: 0,
      pu_51: 0,
      dba_50: 0,
      pu_50: 0,
      dba_49: 0,
      pu_49: 0,
      dba_48: 0,
      pu_48: 0,
      dba_47: 0,
      pu_47: 0,
      dba_46: 0,
      pu_46: 0,
      dba_45: 0,
      pu_45: 0,
      dba_44: 0,
      pu_44: 0,
      dba_43: 0,
      pu_43: 0,
      dba_42: 0,
      pu_42: 0,
      dba_41: 0,
      pu_41: 0,
      dba_40: 0,
      pu_40: 0,
      dba_39: 0,
      pu_39: 0,
      dba_38: 0,
      pu_38: 0,
      dba_37: 0,
      pu_37: 0,
      dba_36: 0,
      pu_36: 0,
      dba_35: 0,
      pu_35: 0,
      dba_34: 0,
      pu_34: 0,
      dba_33: 0,
      pu_33: 0,
      dba_32: 0,
      pu_32: 0,
      dba_31: 0,
      pu_31: 0,
      dba_30: 0,
      pu_30: 0,
      dba_29: 0,
      pu_29: 0,
      dba_28: 0,
      pu_28: 0,
      dba_27: 0,
      pu_27: 0,
      dba_26: 0,
      pu_26: 0,
      dba_25: 0,
      pu_25: 0,
      dba_24: 0,
      pu_24: 0,
      dba_23: 0,
      pu_23: 0,
      dba_22: 0,
      pu_22: 0,
      dba_21: 0,
      pu_21: 0,
      dba_20: 0,
      pu_20: 0,
      dba_19: 0,
      pu_19: 0,
      dba_18: 0,
      pu_18: 0,
      dba_17: 0,
      pu_17: 0,
      dba_16: 0,
      pu_16: 0,
      dba_15: 0,
      pu_15: 0,
      dba_14: 0,
      pu_14: 0,
      dba_13: 0,
      pu_13: 0,
      dba_12: 0,
      pu_12: 0,
      dba_11: 0,
      pu_11: 0,
      dba_10: 0,
      pu_10: 0,
      dba_9: 0,
      pu_9: 0,
      dba_8: 0,
      pu_8: 0,
      dba_7: 0,
      pu_7: 0,
      dba_6: 0,
      pu_6: 0,
      dba_5: 0,
      pu_5: 0,
      dba_4: 0,
      pu_4: 0,
      dba_3: 0,
      pu_3: 0,
      dba_2: 0,
      pu_2: 0,
      dba_1: 0,
      pu_1: 0,
      dba_0: 0,
      pu_0: 0,
    },
    {
      staydate: 1688083200000,
      day_diff: 1,
      total_booking: 0,
      forcastroom: 0,
      pu_avg: 0,
      dba_90: 0,
      pu_90: 0,
      dba_89: 0,
      pu_89: 0,
      dba_88: 0,
      pu_88: 0,
      dba_87: 0,
      pu_87: 0,
      dba_86: 0,
      pu_86: 0,
      dba_85: 0,
      pu_85: 0,
      dba_84: 0,
      pu_84: 0,
      dba_83: 0,
      pu_83: 0,
      dba_82: 0,
      pu_82: 0,
      dba_81: 0,
      pu_81: 0,
      dba_80: 0,
      pu_80: 0,
      dba_79: 0,
      pu_79: 0,
      dba_78: 0,
      pu_78: 0,
      dba_77: 0,
      pu_77: 0,
      dba_76: 0,
      pu_76: 0,
      dba_75: 0,
      pu_75: 0,
      dba_74: 0,
      pu_74: 0,
      dba_73: 0,
      pu_73: 0,
      dba_72: 0,
      pu_72: 0,
      dba_71: 0,
      pu_71: 0,
      dba_70: 0,
      pu_70: 0,
      dba_69: 0,
      pu_69: 0,
      dba_68: 0,
      pu_68: 0,
      dba_67: 0,
      pu_67: 0,
      dba_66: 0,
      pu_66: 0,
      dba_65: 0,
      pu_65: 0,
      dba_64: 0,
      pu_64: 0,
      dba_63: 0,
      pu_63: 0,
      dba_62: 0,
      pu_62: 0,
      dba_61: 1,
      pu_61: 0,
      dba_60: 0,
      pu_60: 0,
      dba_59: 0,
      pu_59: 0,
      dba_58: 0,
      pu_58: 0,
      dba_57: 0,
      pu_57: 0,
      dba_56: 0,
      pu_56: 0,
      dba_55: 0,
      pu_55: 0,
      dba_54: 0,
      pu_54: 0,
      dba_53: 0,
      pu_53: 0,
      dba_52: 0,
      pu_52: 0,
      dba_51: 0,
      pu_51: 0,
      dba_50: 0,
      pu_50: 0,
      dba_49: 0,
      pu_49: 0,
      dba_48: 0,
      pu_48: 0,
      dba_47: 0,
      pu_47: 0,
      dba_46: 0,
      pu_46: 0,
      dba_45: 0,
      pu_45: 0,
      dba_44: 0,
      pu_44: 0,
      dba_43: 0,
      pu_43: 0,
      dba_42: 0,
      pu_42: 0,
      dba_41: 0,
      pu_41: 0,
      dba_40: 0,
      pu_40: 0,
      dba_39: 0,
      pu_39: 0,
      dba_38: 0,
      pu_38: 0,
      dba_37: 0,
      pu_37: 0,
      dba_36: 0,
      pu_36: 0,
      dba_35: 0,
      pu_35: 0,
      dba_34: 0,
      pu_34: 0,
      dba_33: 0,
      pu_33: 0,
      dba_32: 0,
      pu_32: 0,
      dba_31: 0,
      pu_31: 0,
      dba_30: 0,
      pu_30: 0,
      dba_29: 0,
      pu_29: 0,
      dba_28: 0,
      pu_28: 0,
      dba_27: 0,
      pu_27: 0,
      dba_26: 0,
      pu_26: 0,
      dba_25: 0,
      pu_25: 0,
      dba_24: 0,
      pu_24: 0,
      dba_23: 0,
      pu_23: 0,
      dba_22: 0,
      pu_22: 0,
      dba_21: 0,
      pu_21: 0,
      dba_20: 0,
      pu_20: 0,
      dba_19: 0,
      pu_19: 0,
      dba_18: 0,
      pu_18: 0,
      dba_17: 0,
      pu_17: 0,
      dba_16: 0,
      pu_16: 0,
      dba_15: 0,
      pu_15: 0,
      dba_14: 0,
      pu_14: 0,
      dba_13: 0,
      pu_13: 0,
      dba_12: 0,
      pu_12: 0,
      dba_11: 0,
      pu_11: 0,
      dba_10: 0,
      pu_10: 0,
      dba_9: 0,
      pu_9: 0,
      dba_8: 0,
      pu_8: 0,
      dba_7: 0,
      pu_7: 0,
      dba_6: 0,
      pu_6: 0,
      dba_5: 0,
      pu_5: 0,
      dba_4: 0,
      pu_4: 0,
      dba_3: 0,
      pu_3: 0,
      dba_2: 0,
      pu_2: 0,
      dba_1: 0,
      pu_1: 0,
      dba_0: 0,
      pu_0: 0,
    },
    {
      staydate: 1688083200000,
      day_diff: 1,
      total_booking: 0,
      forcastroom: 0,
      pu_avg: 0,
      dba_90: 0,
      pu_90: 0,
      dba_89: 0,
      pu_89: 0,
      dba_88: 0,
      pu_88: 0,
      dba_87: 0,
      pu_87: 0,
      dba_86: 0,
      pu_86: 0,
      dba_85: 0,
      pu_85: 0,
      dba_84: 0,
      pu_84: 0,
      dba_83: 0,
      pu_83: 0,
      dba_82: 0,
      pu_82: 0,
      dba_81: 0,
      pu_81: 0,
      dba_80: 0,
      pu_80: 0,
      dba_79: 0,
      pu_79: 0,
      dba_78: 0,
      pu_78: 0,
      dba_77: 0,
      pu_77: 0,
      dba_76: 0,
      pu_76: 0,
      dba_75: 0,
      pu_75: 0,
      dba_74: 0,
      pu_74: 0,
      dba_73: 0,
      pu_73: 0,
      dba_72: 0,
      pu_72: 0,
      dba_71: 0,
      pu_71: 0,
      dba_70: 0,
      pu_70: 0,
      dba_69: 0,
      pu_69: 0,
      dba_68: 0,
      pu_68: 0,
      dba_67: 0,
      pu_67: 0,
      dba_66: 0,
      pu_66: 0,
      dba_65: 0,
      pu_65: 0,
      dba_64: 0,
      pu_64: 0,
      dba_63: 0,
      pu_63: 0,
      dba_62: 0,
      pu_62: 0,
      dba_61: 1,
      pu_61: 0,
      dba_60: 0,
      pu_60: 0,
      dba_59: 0,
      pu_59: 0,
      dba_58: 0,
      pu_58: 0,
      dba_57: 0,
      pu_57: 0,
      dba_56: 0,
      pu_56: 0,
      dba_55: 0,
      pu_55: 0,
      dba_54: 0,
      pu_54: 0,
      dba_53: 0,
      pu_53: 0,
      dba_52: 0,
      pu_52: 0,
      dba_51: 0,
      pu_51: 0,
      dba_50: 0,
      pu_50: 0,
      dba_49: 0,
      pu_49: 0,
      dba_48: 0,
      pu_48: 0,
      dba_47: 0,
      pu_47: 0,
      dba_46: 0,
      pu_46: 0,
      dba_45: 0,
      pu_45: 0,
      dba_44: 0,
      pu_44: 0,
      dba_43: 0,
      pu_43: 0,
      dba_42: 0,
      pu_42: 0,
      dba_41: 0,
      pu_41: 0,
      dba_40: 0,
      pu_40: 0,
      dba_39: 0,
      pu_39: 0,
      dba_38: 0,
      pu_38: 0,
      dba_37: 0,
      pu_37: 0,
      dba_36: 0,
      pu_36: 0,
      dba_35: 0,
      pu_35: 0,
      dba_34: 0,
      pu_34: 0,
      dba_33: 0,
      pu_33: 0,
      dba_32: 0,
      pu_32: 0,
      dba_31: 0,
      pu_31: 0,
      dba_30: 0,
      pu_30: 0,
      dba_29: 0,
      pu_29: 0,
      dba_28: 0,
      pu_28: 0,
      dba_27: 0,
      pu_27: 0,
      dba_26: 0,
      pu_26: 0,
      dba_25: 0,
      pu_25: 0,
      dba_24: 0,
      pu_24: 0,
      dba_23: 0,
      pu_23: 0,
      dba_22: 0,
      pu_22: 0,
      dba_21: 0,
      pu_21: 0,
      dba_20: 0,
      pu_20: 0,
      dba_19: 0,
      pu_19: 0,
      dba_18: 0,
      pu_18: 0,
      dba_17: 0,
      pu_17: 0,
      dba_16: 0,
      pu_16: 0,
      dba_15: 0,
      pu_15: 0,
      dba_14: 0,
      pu_14: 0,
      dba_13: 0,
      pu_13: 0,
      dba_12: 0,
      pu_12: 0,
      dba_11: 0,
      pu_11: 0,
      dba_10: 0,
      pu_10: 0,
      dba_9: 0,
      pu_9: 0,
      dba_8: 0,
      pu_8: 0,
      dba_7: 0,
      pu_7: 0,
      dba_6: 0,
      pu_6: 0,
      dba_5: 0,
      pu_5: 0,
      dba_4: 0,
      pu_4: 0,
      dba_3: 0,
      pu_3: 0,
      dba_2: 0,
      pu_2: 0,
      dba_1: 0,
      pu_1: 0,
      dba_0: 0,
      pu_0: 0,
    },
    {
      staydate: 1688083200000,
      day_diff: 1,
      total_booking: 0,
      forcastroom: 0,
      pu_avg: 0,
      dba_90: 0,
      pu_90: 0,
      dba_89: 0,
      pu_89: 0,
      dba_88: 0,
      pu_88: 0,
      dba_87: 0,
      pu_87: 0,
      dba_86: 0,
      pu_86: 0,
      dba_85: 0,
      pu_85: 0,
      dba_84: 0,
      pu_84: 0,
      dba_83: 0,
      pu_83: 0,
      dba_82: 0,
      pu_82: 0,
      dba_81: 0,
      pu_81: 0,
      dba_80: 0,
      pu_80: 0,
      dba_79: 0,
      pu_79: 0,
      dba_78: 0,
      pu_78: 0,
      dba_77: 0,
      pu_77: 0,
      dba_76: 0,
      pu_76: 0,
      dba_75: 0,
      pu_75: 0,
      dba_74: 0,
      pu_74: 0,
      dba_73: 0,
      pu_73: 0,
      dba_72: 0,
      pu_72: 0,
      dba_71: 0,
      pu_71: 0,
      dba_70: 0,
      pu_70: 0,
      dba_69: 0,
      pu_69: 0,
      dba_68: 0,
      pu_68: 0,
      dba_67: 0,
      pu_67: 0,
      dba_66: 0,
      pu_66: 0,
      dba_65: 0,
      pu_65: 0,
      dba_64: 0,
      pu_64: 0,
      dba_63: 0,
      pu_63: 0,
      dba_62: 0,
      pu_62: 0,
      dba_61: 1,
      pu_61: 0,
      dba_60: 0,
      pu_60: 0,
      dba_59: 0,
      pu_59: 0,
      dba_58: 0,
      pu_58: 0,
      dba_57: 0,
      pu_57: 0,
      dba_56: 0,
      pu_56: 0,
      dba_55: 0,
      pu_55: 0,
      dba_54: 0,
      pu_54: 0,
      dba_53: 0,
      pu_53: 0,
      dba_52: 0,
      pu_52: 0,
      dba_51: 0,
      pu_51: 0,
      dba_50: 0,
      pu_50: 0,
      dba_49: 0,
      pu_49: 0,
      dba_48: 0,
      pu_48: 0,
      dba_47: 0,
      pu_47: 0,
      dba_46: 0,
      pu_46: 0,
      dba_45: 0,
      pu_45: 0,
      dba_44: 0,
      pu_44: 0,
      dba_43: 0,
      pu_43: 0,
      dba_42: 0,
      pu_42: 0,
      dba_41: 0,
      pu_41: 0,
      dba_40: 0,
      pu_40: 0,
      dba_39: 0,
      pu_39: 0,
      dba_38: 0,
      pu_38: 0,
      dba_37: 0,
      pu_37: 0,
      dba_36: 0,
      pu_36: 0,
      dba_35: 0,
      pu_35: 0,
      dba_34: 0,
      pu_34: 0,
      dba_33: 0,
      pu_33: 0,
      dba_32: 0,
      pu_32: 0,
      dba_31: 0,
      pu_31: 0,
      dba_30: 0,
      pu_30: 0,
      dba_29: 0,
      pu_29: 0,
      dba_28: 0,
      pu_28: 0,
      dba_27: 0,
      pu_27: 0,
      dba_26: 0,
      pu_26: 0,
      dba_25: 0,
      pu_25: 0,
      dba_24: 0,
      pu_24: 0,
      dba_23: 0,
      pu_23: 0,
      dba_22: 0,
      pu_22: 0,
      dba_21: 0,
      pu_21: 0,
      dba_20: 0,
      pu_20: 0,
      dba_19: 0,
      pu_19: 0,
      dba_18: 0,
      pu_18: 0,
      dba_17: 0,
      pu_17: 0,
      dba_16: 0,
      pu_16: 0,
      dba_15: 0,
      pu_15: 0,
      dba_14: 0,
      pu_14: 0,
      dba_13: 0,
      pu_13: 0,
      dba_12: 0,
      pu_12: 0,
      dba_11: 0,
      pu_11: 0,
      dba_10: 0,
      pu_10: 0,
      dba_9: 0,
      pu_9: 0,
      dba_8: 0,
      pu_8: 0,
      dba_7: 0,
      pu_7: 0,
      dba_6: 0,
      pu_6: 0,
      dba_5: 0,
      pu_5: 0,
      dba_4: 0,
      pu_4: 0,
      dba_3: 0,
      pu_3: 0,
      dba_2: 0,
      pu_2: 0,
      dba_1: 0,
      pu_1: 0,
      dba_0: 0,
      pu_0: 0,
    },
    {
      staydate: 1688083200000,
      day_diff: 1,
      total_booking: 0,
      forcastroom: 0,
      pu_avg: 0,
      dba_90: 0,
      pu_90: 0,
      dba_89: 0,
      pu_89: 0,
      dba_88: 0,
      pu_88: 0,
      dba_87: 0,
      pu_87: 0,
      dba_86: 0,
      pu_86: 0,
      dba_85: 0,
      pu_85: 0,
      dba_84: 0,
      pu_84: 0,
      dba_83: 0,
      pu_83: 0,
      dba_82: 0,
      pu_82: 0,
      dba_81: 0,
      pu_81: 0,
      dba_80: 0,
      pu_80: 0,
      dba_79: 0,
      pu_79: 0,
      dba_78: 0,
      pu_78: 0,
      dba_77: 0,
      pu_77: 0,
      dba_76: 0,
      pu_76: 0,
      dba_75: 0,
      pu_75: 0,
      dba_74: 0,
      pu_74: 0,
      dba_73: 0,
      pu_73: 0,
      dba_72: 0,
      pu_72: 0,
      dba_71: 0,
      pu_71: 0,
      dba_70: 0,
      pu_70: 0,
      dba_69: 0,
      pu_69: 0,
      dba_68: 0,
      pu_68: 0,
      dba_67: 0,
      pu_67: 0,
      dba_66: 0,
      pu_66: 0,
      dba_65: 0,
      pu_65: 0,
      dba_64: 0,
      pu_64: 0,
      dba_63: 0,
      pu_63: 0,
      dba_62: 0,
      pu_62: 0,
      dba_61: 1,
      pu_61: 0,
      dba_60: 0,
      pu_60: 0,
      dba_59: 0,
      pu_59: 0,
      dba_58: 0,
      pu_58: 0,
      dba_57: 0,
      pu_57: 0,
      dba_56: 0,
      pu_56: 0,
      dba_55: 0,
      pu_55: 0,
      dba_54: 0,
      pu_54: 0,
      dba_53: 0,
      pu_53: 0,
      dba_52: 0,
      pu_52: 0,
      dba_51: 0,
      pu_51: 0,
      dba_50: 0,
      pu_50: 0,
      dba_49: 0,
      pu_49: 0,
      dba_48: 0,
      pu_48: 0,
      dba_47: 0,
      pu_47: 0,
      dba_46: 0,
      pu_46: 0,
      dba_45: 0,
      pu_45: 0,
      dba_44: 0,
      pu_44: 0,
      dba_43: 0,
      pu_43: 0,
      dba_42: 0,
      pu_42: 0,
      dba_41: 0,
      pu_41: 0,
      dba_40: 0,
      pu_40: 0,
      dba_39: 0,
      pu_39: 0,
      dba_38: 0,
      pu_38: 0,
      dba_37: 0,
      pu_37: 0,
      dba_36: 0,
      pu_36: 0,
      dba_35: 0,
      pu_35: 0,
      dba_34: 0,
      pu_34: 0,
      dba_33: 0,
      pu_33: 0,
      dba_32: 0,
      pu_32: 0,
      dba_31: 0,
      pu_31: 0,
      dba_30: 0,
      pu_30: 0,
      dba_29: 0,
      pu_29: 0,
      dba_28: 0,
      pu_28: 0,
      dba_27: 0,
      pu_27: 0,
      dba_26: 0,
      pu_26: 0,
      dba_25: 0,
      pu_25: 0,
      dba_24: 0,
      pu_24: 0,
      dba_23: 0,
      pu_23: 0,
      dba_22: 0,
      pu_22: 0,
      dba_21: 0,
      pu_21: 0,
      dba_20: 0,
      pu_20: 0,
      dba_19: 0,
      pu_19: 0,
      dba_18: 0,
      pu_18: 0,
      dba_17: 0,
      pu_17: 0,
      dba_16: 0,
      pu_16: 0,
      dba_15: 0,
      pu_15: 0,
      dba_14: 0,
      pu_14: 0,
      dba_13: 0,
      pu_13: 0,
      dba_12: 0,
      pu_12: 0,
      dba_11: 0,
      pu_11: 0,
      dba_10: 0,
      pu_10: 0,
      dba_9: 0,
      pu_9: 0,
      dba_8: 0,
      pu_8: 0,
      dba_7: 0,
      pu_7: 0,
      dba_6: 0,
      pu_6: 0,
      dba_5: 0,
      pu_5: 0,
      dba_4: 0,
      pu_4: 0,
      dba_3: 0,
      pu_3: 0,
      dba_2: 0,
      pu_2: 0,
      dba_1: 0,
      pu_1: 0,
      dba_0: 0,
      pu_0: 0,
    },
    {
      staydate: 1688083200000,
      day_diff: 1,
      total_booking: 0,
      forcastroom: 0,
      pu_avg: 0,
      dba_90: 0,
      pu_90: 0,
      dba_89: 0,
      pu_89: 0,
      dba_88: 0,
      pu_88: 0,
      dba_87: 0,
      pu_87: 0,
      dba_86: 0,
      pu_86: 0,
      dba_85: 0,
      pu_85: 0,
      dba_84: 0,
      pu_84: 0,
      dba_83: 0,
      pu_83: 0,
      dba_82: 0,
      pu_82: 0,
      dba_81: 0,
      pu_81: 0,
      dba_80: 0,
      pu_80: 0,
      dba_79: 0,
      pu_79: 0,
      dba_78: 0,
      pu_78: 0,
      dba_77: 0,
      pu_77: 0,
      dba_76: 0,
      pu_76: 0,
      dba_75: 0,
      pu_75: 0,
      dba_74: 0,
      pu_74: 0,
      dba_73: 0,
      pu_73: 0,
      dba_72: 0,
      pu_72: 0,
      dba_71: 0,
      pu_71: 0,
      dba_70: 0,
      pu_70: 0,
      dba_69: 0,
      pu_69: 0,
      dba_68: 0,
      pu_68: 0,
      dba_67: 0,
      pu_67: 0,
      dba_66: 0,
      pu_66: 0,
      dba_65: 0,
      pu_65: 0,
      dba_64: 0,
      pu_64: 0,
      dba_63: 0,
      pu_63: 0,
      dba_62: 0,
      pu_62: 0,
      dba_61: 1,
      pu_61: 0,
      dba_60: 0,
      pu_60: 0,
      dba_59: 0,
      pu_59: 0,
      dba_58: 0,
      pu_58: 0,
      dba_57: 0,
      pu_57: 0,
      dba_56: 0,
      pu_56: 0,
      dba_55: 0,
      pu_55: 0,
      dba_54: 0,
      pu_54: 0,
      dba_53: 0,
      pu_53: 0,
      dba_52: 0,
      pu_52: 0,
      dba_51: 0,
      pu_51: 0,
      dba_50: 0,
      pu_50: 0,
      dba_49: 0,
      pu_49: 0,
      dba_48: 0,
      pu_48: 0,
      dba_47: 0,
      pu_47: 0,
      dba_46: 0,
      pu_46: 0,
      dba_45: 0,
      pu_45: 0,
      dba_44: 0,
      pu_44: 0,
      dba_43: 0,
      pu_43: 0,
      dba_42: 0,
      pu_42: 0,
      dba_41: 0,
      pu_41: 0,
      dba_40: 0,
      pu_40: 0,
      dba_39: 0,
      pu_39: 0,
      dba_38: 0,
      pu_38: 0,
      dba_37: 0,
      pu_37: 0,
      dba_36: 0,
      pu_36: 0,
      dba_35: 0,
      pu_35: 0,
      dba_34: 0,
      pu_34: 0,
      dba_33: 0,
      pu_33: 0,
      dba_32: 0,
      pu_32: 0,
      dba_31: 0,
      pu_31: 0,
      dba_30: 0,
      pu_30: 0,
      dba_29: 0,
      pu_29: 0,
      dba_28: 0,
      pu_28: 0,
      dba_27: 0,
      pu_27: 0,
      dba_26: 0,
      pu_26: 0,
      dba_25: 0,
      pu_25: 0,
      dba_24: 0,
      pu_24: 0,
      dba_23: 0,
      pu_23: 0,
      dba_22: 0,
      pu_22: 0,
      dba_21: 0,
      pu_21: 0,
      dba_20: 0,
      pu_20: 0,
      dba_19: 0,
      pu_19: 0,
      dba_18: 0,
      pu_18: 0,
      dba_17: 0,
      pu_17: 0,
      dba_16: 0,
      pu_16: 0,
      dba_15: 0,
      pu_15: 0,
      dba_14: 0,
      pu_14: 0,
      dba_13: 0,
      pu_13: 0,
      dba_12: 0,
      pu_12: 0,
      dba_11: 0,
      pu_11: 0,
      dba_10: 0,
      pu_10: 0,
      dba_9: 0,
      pu_9: 0,
      dba_8: 0,
      pu_8: 0,
      dba_7: 0,
      pu_7: 0,
      dba_6: 0,
      pu_6: 0,
      dba_5: 0,
      pu_5: 0,
      dba_4: 0,
      pu_4: 0,
      dba_3: 0,
      pu_3: 0,
      dba_2: 0,
      pu_2: 0,
      dba_1: 0,
      pu_1: 0,
      dba_0: 0,
      pu_0: 0,
    },
    {
      staydate: 1688083200000,
      day_diff: 1,
      total_booking: 0,
      forcastroom: 0,
      pu_avg: 0,
      dba_90: 0,
      pu_90: 0,
      dba_89: 0,
      pu_89: 0,
      dba_88: 0,
      pu_88: 0,
      dba_87: 0,
      pu_87: 0,
      dba_86: 0,
      pu_86: 0,
      dba_85: 0,
      pu_85: 0,
      dba_84: 0,
      pu_84: 0,
      dba_83: 0,
      pu_83: 0,
      dba_82: 0,
      pu_82: 0,
      dba_81: 0,
      pu_81: 0,
      dba_80: 0,
      pu_80: 0,
      dba_79: 0,
      pu_79: 0,
      dba_78: 0,
      pu_78: 0,
      dba_77: 0,
      pu_77: 0,
      dba_76: 0,
      pu_76: 0,
      dba_75: 0,
      pu_75: 0,
      dba_74: 0,
      pu_74: 0,
      dba_73: 0,
      pu_73: 0,
      dba_72: 0,
      pu_72: 0,
      dba_71: 0,
      pu_71: 0,
      dba_70: 0,
      pu_70: 0,
      dba_69: 0,
      pu_69: 0,
      dba_68: 0,
      pu_68: 0,
      dba_67: 0,
      pu_67: 0,
      dba_66: 0,
      pu_66: 0,
      dba_65: 0,
      pu_65: 0,
      dba_64: 0,
      pu_64: 0,
      dba_63: 0,
      pu_63: 0,
      dba_62: 0,
      pu_62: 0,
      dba_61: 1,
      pu_61: 0,
      dba_60: 0,
      pu_60: 0,
      dba_59: 0,
      pu_59: 0,
      dba_58: 0,
      pu_58: 0,
      dba_57: 0,
      pu_57: 0,
      dba_56: 0,
      pu_56: 0,
      dba_55: 0,
      pu_55: 0,
      dba_54: 0,
      pu_54: 0,
      dba_53: 0,
      pu_53: 0,
      dba_52: 0,
      pu_52: 0,
      dba_51: 0,
      pu_51: 0,
      dba_50: 0,
      pu_50: 0,
      dba_49: 0,
      pu_49: 0,
      dba_48: 0,
      pu_48: 0,
      dba_47: 0,
      pu_47: 0,
      dba_46: 0,
      pu_46: 0,
      dba_45: 0,
      pu_45: 0,
      dba_44: 0,
      pu_44: 0,
      dba_43: 0,
      pu_43: 0,
      dba_42: 0,
      pu_42: 0,
      dba_41: 0,
      pu_41: 0,
      dba_40: 0,
      pu_40: 0,
      dba_39: 0,
      pu_39: 0,
      dba_38: 0,
      pu_38: 0,
      dba_37: 0,
      pu_37: 0,
      dba_36: 0,
      pu_36: 0,
      dba_35: 0,
      pu_35: 0,
      dba_34: 0,
      pu_34: 0,
      dba_33: 0,
      pu_33: 0,
      dba_32: 0,
      pu_32: 0,
      dba_31: 0,
      pu_31: 0,
      dba_30: 0,
      pu_30: 0,
      dba_29: 0,
      pu_29: 0,
      dba_28: 0,
      pu_28: 0,
      dba_27: 0,
      pu_27: 0,
      dba_26: 0,
      pu_26: 0,
      dba_25: 0,
      pu_25: 0,
      dba_24: 0,
      pu_24: 0,
      dba_23: 0,
      pu_23: 0,
      dba_22: 0,
      pu_22: 0,
      dba_21: 0,
      pu_21: 0,
      dba_20: 0,
      pu_20: 0,
      dba_19: 0,
      pu_19: 0,
      dba_18: 0,
      pu_18: 0,
      dba_17: 0,
      pu_17: 0,
      dba_16: 0,
      pu_16: 0,
      dba_15: 0,
      pu_15: 0,
      dba_14: 0,
      pu_14: 0,
      dba_13: 0,
      pu_13: 0,
      dba_12: 0,
      pu_12: 0,
      dba_11: 0,
      pu_11: 0,
      dba_10: 0,
      pu_10: 0,
      dba_9: 0,
      pu_9: 0,
      dba_8: 0,
      pu_8: 0,
      dba_7: 0,
      pu_7: 0,
      dba_6: 0,
      pu_6: 0,
      dba_5: 0,
      pu_5: 0,
      dba_4: 0,
      pu_4: 0,
      dba_3: 0,
      pu_3: 0,
      dba_2: 0,
      pu_2: 0,
      dba_1: 0,
      pu_1: 0,
      dba_0: 0,
      pu_0: 0,
    },
    {
      staydate: 1688083200000,
      day_diff: 1,
      total_booking: 0,
      forcastroom: 0,
      pu_avg: 0,
      dba_90: 0,
      pu_90: 0,
      dba_89: 0,
      pu_89: 0,
      dba_88: 0,
      pu_88: 0,
      dba_87: 0,
      pu_87: 0,
      dba_86: 0,
      pu_86: 0,
      dba_85: 0,
      pu_85: 0,
      dba_84: 0,
      pu_84: 0,
      dba_83: 0,
      pu_83: 0,
      dba_82: 0,
      pu_82: 0,
      dba_81: 0,
      pu_81: 0,
      dba_80: 0,
      pu_80: 0,
      dba_79: 0,
      pu_79: 0,
      dba_78: 0,
      pu_78: 0,
      dba_77: 0,
      pu_77: 0,
      dba_76: 0,
      pu_76: 0,
      dba_75: 0,
      pu_75: 0,
      dba_74: 0,
      pu_74: 0,
      dba_73: 0,
      pu_73: 0,
      dba_72: 0,
      pu_72: 0,
      dba_71: 0,
      pu_71: 0,
      dba_70: 0,
      pu_70: 0,
      dba_69: 0,
      pu_69: 0,
      dba_68: 0,
      pu_68: 0,
      dba_67: 0,
      pu_67: 0,
      dba_66: 0,
      pu_66: 0,
      dba_65: 0,
      pu_65: 0,
      dba_64: 0,
      pu_64: 0,
      dba_63: 0,
      pu_63: 0,
      dba_62: 0,
      pu_62: 0,
      dba_61: 1,
      pu_61: 0,
      dba_60: 0,
      pu_60: 0,
      dba_59: 0,
      pu_59: 0,
      dba_58: 0,
      pu_58: 0,
      dba_57: 0,
      pu_57: 0,
      dba_56: 0,
      pu_56: 0,
      dba_55: 0,
      pu_55: 0,
      dba_54: 0,
      pu_54: 0,
      dba_53: 0,
      pu_53: 0,
      dba_52: 0,
      pu_52: 0,
      dba_51: 0,
      pu_51: 0,
      dba_50: 0,
      pu_50: 0,
      dba_49: 0,
      pu_49: 0,
      dba_48: 0,
      pu_48: 0,
      dba_47: 0,
      pu_47: 0,
      dba_46: 0,
      pu_46: 0,
      dba_45: 0,
      pu_45: 0,
      dba_44: 0,
      pu_44: 0,
      dba_43: 0,
      pu_43: 0,
      dba_42: 0,
      pu_42: 0,
      dba_41: 0,
      pu_41: 0,
      dba_40: 0,
      pu_40: 0,
      dba_39: 0,
      pu_39: 0,
      dba_38: 0,
      pu_38: 0,
      dba_37: 0,
      pu_37: 0,
      dba_36: 0,
      pu_36: 0,
      dba_35: 0,
      pu_35: 0,
      dba_34: 0,
      pu_34: 0,
      dba_33: 0,
      pu_33: 0,
      dba_32: 0,
      pu_32: 0,
      dba_31: 0,
      pu_31: 0,
      dba_30: 0,
      pu_30: 0,
      dba_29: 0,
      pu_29: 0,
      dba_28: 0,
      pu_28: 0,
      dba_27: 0,
      pu_27: 0,
      dba_26: 0,
      pu_26: 0,
      dba_25: 0,
      pu_25: 0,
      dba_24: 0,
      pu_24: 0,
      dba_23: 0,
      pu_23: 0,
      dba_22: 0,
      pu_22: 0,
      dba_21: 0,
      pu_21: 0,
      dba_20: 0,
      pu_20: 0,
      dba_19: 0,
      pu_19: 0,
      dba_18: 0,
      pu_18: 0,
      dba_17: 0,
      pu_17: 0,
      dba_16: 0,
      pu_16: 0,
      dba_15: 0,
      pu_15: 0,
      dba_14: 0,
      pu_14: 0,
      dba_13: 0,
      pu_13: 0,
      dba_12: 0,
      pu_12: 0,
      dba_11: 0,
      pu_11: 0,
      dba_10: 0,
      pu_10: 0,
      dba_9: 0,
      pu_9: 0,
      dba_8: 0,
      pu_8: 0,
      dba_7: 0,
      pu_7: 0,
      dba_6: 0,
      pu_6: 0,
      dba_5: 0,
      pu_5: 0,
      dba_4: 0,
      pu_4: 0,
      dba_3: 0,
      pu_3: 0,
      dba_2: 0,
      pu_2: 0,
      dba_1: 0,
      pu_1: 0,
      dba_0: 0,
      pu_0: 0,
    },
    {
      staydate: 1688083200000,
      day_diff: 1,
      total_booking: 0,
      forcastroom: 0,
      pu_avg: 0,
      dba_90: 0,
      pu_90: 0,
      dba_89: 0,
      pu_89: 0,
      dba_88: 0,
      pu_88: 0,
      dba_87: 0,
      pu_87: 0,
      dba_86: 0,
      pu_86: 0,
      dba_85: 0,
      pu_85: 0,
      dba_84: 0,
      pu_84: 0,
      dba_83: 0,
      pu_83: 0,
      dba_82: 0,
      pu_82: 0,
      dba_81: 0,
      pu_81: 0,
      dba_80: 0,
      pu_80: 0,
      dba_79: 0,
      pu_79: 0,
      dba_78: 0,
      pu_78: 0,
      dba_77: 0,
      pu_77: 0,
      dba_76: 0,
      pu_76: 0,
      dba_75: 0,
      pu_75: 0,
      dba_74: 0,
      pu_74: 0,
      dba_73: 0,
      pu_73: 0,
      dba_72: 0,
      pu_72: 0,
      dba_71: 0,
      pu_71: 0,
      dba_70: 0,
      pu_70: 0,
      dba_69: 0,
      pu_69: 0,
      dba_68: 0,
      pu_68: 0,
      dba_67: 0,
      pu_67: 0,
      dba_66: 0,
      pu_66: 0,
      dba_65: 0,
      pu_65: 0,
      dba_64: 0,
      pu_64: 0,
      dba_63: 0,
      pu_63: 0,
      dba_62: 0,
      pu_62: 0,
      dba_61: 1,
      pu_61: 0,
      dba_60: 0,
      pu_60: 0,
      dba_59: 0,
      pu_59: 0,
      dba_58: 0,
      pu_58: 0,
      dba_57: 0,
      pu_57: 0,
      dba_56: 0,
      pu_56: 0,
      dba_55: 0,
      pu_55: 0,
      dba_54: 0,
      pu_54: 0,
      dba_53: 0,
      pu_53: 0,
      dba_52: 0,
      pu_52: 0,
      dba_51: 0,
      pu_51: 0,
      dba_50: 0,
      pu_50: 0,
      dba_49: 0,
      pu_49: 0,
      dba_48: 0,
      pu_48: 0,
      dba_47: 0,
      pu_47: 0,
      dba_46: 0,
      pu_46: 0,
      dba_45: 0,
      pu_45: 0,
      dba_44: 0,
      pu_44: 0,
      dba_43: 0,
      pu_43: 0,
      dba_42: 0,
      pu_42: 0,
      dba_41: 0,
      pu_41: 0,
      dba_40: 0,
      pu_40: 0,
      dba_39: 0,
      pu_39: 0,
      dba_38: 0,
      pu_38: 0,
      dba_37: 0,
      pu_37: 0,
      dba_36: 0,
      pu_36: 0,
      dba_35: 0,
      pu_35: 0,
      dba_34: 0,
      pu_34: 0,
      dba_33: 0,
      pu_33: 0,
      dba_32: 0,
      pu_32: 0,
      dba_31: 0,
      pu_31: 0,
      dba_30: 0,
      pu_30: 0,
      dba_29: 0,
      pu_29: 0,
      dba_28: 0,
      pu_28: 0,
      dba_27: 0,
      pu_27: 0,
      dba_26: 0,
      pu_26: 0,
      dba_25: 0,
      pu_25: 0,
      dba_24: 0,
      pu_24: 0,
      dba_23: 0,
      pu_23: 0,
      dba_22: 0,
      pu_22: 0,
      dba_21: 0,
      pu_21: 0,
      dba_20: 0,
      pu_20: 0,
      dba_19: 0,
      pu_19: 0,
      dba_18: 0,
      pu_18: 0,
      dba_17: 0,
      pu_17: 0,
      dba_16: 0,
      pu_16: 0,
      dba_15: 0,
      pu_15: 0,
      dba_14: 0,
      pu_14: 0,
      dba_13: 0,
      pu_13: 0,
      dba_12: 0,
      pu_12: 0,
      dba_11: 0,
      pu_11: 0,
      dba_10: 0,
      pu_10: 0,
      dba_9: 0,
      pu_9: 0,
      dba_8: 0,
      pu_8: 0,
      dba_7: 0,
      pu_7: 0,
      dba_6: 0,
      pu_6: 0,
      dba_5: 0,
      pu_5: 0,
      dba_4: 0,
      pu_4: 0,
      dba_3: 0,
      pu_3: 0,
      dba_2: 0,
      pu_2: 0,
      dba_1: 0,
      pu_1: 0,
      dba_0: 0,
      pu_0: 0,
    },
  ];
  const [dynamicPickupData, setDynamicPickupData] = useState(null);
  const [dynamicTotal, setDynamicTotal] = useState(null);
  const [loading, setLoading] = useState(false);
  const [widgetModal, setWidgetModal] = useState(false);
  const widgetDetailRef = useRef();
  const [showNoteSidebar, setShowNoteSidebar] = useState(false);
  const propertyDetailFromRTK = useSelector(
    (store) => store.headerProperty.propertyDetail
  );
  const defaultAsOfDateFromRTK = useSelector(
    (store) => store.headerProperty.defaultAsOfDate
  );
  const { snapshottitle, propertyCode, snapshotId } = useParams();
  const closeNoteSideBar = () => {
    setShowNoteSidebar(false);
  };

  const closeWidgetModal = () => {
    setWidgetModal(false);
  };
  let dummyArr = Array.from(Array(91).keys());

  const getDynamicPickupData = async () => {
    setLoading(true);
    let dynamicPickupResponse = await apiCall(
      {
        method: "POST",
        url: API_URL.BASE_API_URL.SnapShot + API_URL.PICK_UP.DYNAMIC_PICKUP,
        body: {
          AsOfDate: para?.asOfDate,
          propertyCode: para?.propertyCode,
        },
      },
      false
    );

    let response = apiResponse(false, dynamicPickupResponse);

    if (response?.isValidate) {
      let dataFromApi = response?.data?.data?.[0];
      let groupingJson = [];
      let totalJson = [];
      //   let currentDate = moment(new Date()).format("MM-DD-YYYY");
      for (let i = 0; i < dataFromApi?.PickUpDataJson?.length; i++) {
        // let date = new Date(dataFromApi?.PickUpDataJson[i]?.staydate);
        // let formateDate = moment(date).format("MM-DD-YYYY");
        // let timeForCurrentDate = new Date(currentDate).getTime();
        // let timeForResponseDate = new Date(formateDate).getTime();
        // if (timeForCurrentDate >= timeForResponseDate) {
        //   groupingJson.push(dataFromApi?.PickUpDataJson[i]);
        // }
        if (dataFromApi?.PickUpDataJson[i]?.day_diff > 0) {
          groupingJson.push(dataFromApi?.PickUpDataJson[i]);
          totalJson.push({
            day_diff: dataFromApi?.PickUpDataJson?.[i]?.day_diff,
            pu_avg: dataFromApi?.PickUpDataJson?.[i]?.pu_avg,
          });
        }
      }
      let sortedTotalJson = totalJson.sort(
        (a, b) => parseFloat(b.day_diff) - parseFloat(a.day_diff)
      );
      setLoading(false);
      setDynamicTotal(sortedTotalJson);
      setDynamicPickupData(groupingJson);
    }

    if (!response?.isValidate) {
      setLoading(false);
      setDynamicPickupData(tableData);
    }
  };

  useEffect(() => {
    if (api === 0) {
      setDynamicPickupData(tableData);
    } else {
      if (para?.asOfDate) {
        getDynamicPickupData();
      }
    }
  }, [para?.asOfDate]);

  const [tblids, settblids] = useState({
    tblDynamicPickup: commonService.GenerateGUID(),
  });
  FixColumn(tblids.tblDynamicPickup, [1, 2, 3, 4]);

  return (
    <div className="widget-container">
      {api !== 0 && (
        <div className="gap-1 widget-container-menu">
          {/* <Button className="heading-menu-btns" type="button">
                        <img
                            src={NoteIcon}
                            alt="menuIcon"
                            onClick={() => {
                                setShowNoteSidebar(true);
                            }}
                        />
                    </Button> */}
          <Button className="heading-menu-btns" type="button" title="Send Mail">
            <img
              src={emailIcon}
              alt="emailIcon"
              onClick={() => {
                commonService.fnSendWidgetMail(
                  tblids.tblDynamicPickup,
                  "Dynamic Pickup",
                  snapshotId,
                  defaultAsOfDateFromRTK,
                  "",
                  para?.widgetId
                );
              }}
            />
          </Button>
          <Button className="heading-menu-btns" type="button">
            <img
              src={infoIcon}
              alt="info"
              onClick={() => {
                widgetDetailRef.current = {
                  widgetdetails: "",
                  usernote: "",
                  compName: compName?.dynamicPickup,
                  widgetId: para?.widgetId,
                };
                setWidgetModal(true);
              }}
            />
          </Button>
          <Button className="heading-menu-btns" type="button">
            <img
              src={downloadIcon}
              alt="menuIcon"
              import={tblids.tblDynamicPickup}
              onClick={() =>
                commonService.fnExportInCSV(
                  tblids.tblDynamicPickup,
                  // "Dynamic Pickup"
                  `${para?.propertyCode}-Dynamic Pickup-${moment(
                    new Date()
                  ).format("MMDDYYYY")}`
                )
              }
            />
          </Button>
        </div>
      )}
      <div className="ViewPropertySetupTable">
        <div className="stripped__table">
          <div className="table-responsive tblDynamicPickupSticky">
            <Table id={tblids.tblDynamicPickup}>
              <thead className="tbl-header-sticky">
                <tr className="table-main-header-tr">
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  {dummyArr?.map((item, index) => (
                    <th className="text-center border-right" colSpan={2}>
                      {90 - index}
                    </th>
                  ))}
                </tr>
                <tr className="table-main-header-tr">
                  {dynamicPickupData?.length > 0 && (
                    <>
                      <th>staydate</th>
                      <th>day diff</th>
                      <th>total booking</th>
                      <th>forcast room</th>
                      {Object?.keys(dynamicPickupData?.[0])?.map(
                        (item, index) => {
                          return (
                            <>
                              {item !== "staydate" &&
                                item !== "day_diff" &&
                                item !== "total_booking" &&
                                item !== "forcastroom" &&
                                item !== "pu_avg" && (
                                  <th
                                    key={index}
                                    className={`${
                                      item === "staydate"
                                        ? "text-left"
                                        : "text-center"
                                    } ${index % 2 !== 0 ? "border-right" : ""}`}
                                  >
                                    {/* {item?.replace("_", " ")} */}
                                    {item?.split("_")?.[0]}
                                  </th>
                                )}
                            </>
                          );
                        }
                      )}
                    </>
                  )}
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr style={{ height: "80px", position: "relative" }}>
                    <Loader />
                  </tr>
                ) : (
                  dynamicPickupData &&
                  dynamicPickupData?.map((item, index) => {
                    return (
                      <>
                        <tr key={index} className="shadowOnHover">
                          {/* <td>{item?.staydate}</td> */}
                          <td>
                            {commonService.getDateInFormat(
                              new Date(item?.["staydate"])
                            )}
                          </td>
                          <td className="text-center">{item?.day_diff}</td>
                          <td className="text-center">{item?.total_booking}</td>
                          <td className="text-center">{item?.forcastroom}</td>
                          {Object.keys(item)?.map((objItem, objIndex) => {
                            return (
                              <>
                                {objItem !== "staydate" &&
                                  objItem !== "day_diff" &&
                                  objItem !== "total_booking" &&
                                  objItem !== "forcastroom" &&
                                  objItem !== "pu_avg" && (
                                    <td
                                      className={`${
                                        objItem === "staydate"
                                          ? "text-left"
                                          : "text-center"
                                      } ${
                                        objIndex % 2 !== 0 ? "border-right" : ""
                                      }`}
                                    >
                                      {item?.[objItem]}
                                    </td>
                                  )}
                              </>
                            );
                          })}
                        </tr>
                        {index === dynamicPickupData?.length - 1 && (
                          <tr className="shadowOnHover">
                            <th className="boldTd">90 Day Avg</th>
                            <th className="boldTd"></th>
                            <th className="boldTd"></th>
                            <th className="boldTd"></th>
                            {dynamicTotal?.map((item, index) => {
                              return (
                                <>
                                  <td className="boldTd"></td>
                                  <td className="boldTd">{item?.pu_avg}</td>
                                </>
                              );
                            })}
                          </tr>
                        )}
                      </>
                    );
                  })
                )}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
      {api !== 0 && <Comments widgetDetails={para} editorId={"DynamicPickup"}/>}

      {/* {showNoteSidebar && (
                <WidgetNote
                    show={showNoteSidebar}
                    handleClose={closeNoteSideBar}
                    widgetId={para?.widgetId}
                    propertyId={propertyDetailFromRTK?.propertyid}
                    rowTitle={para?.rowTitle}
                />
            )} */}
      {showNoteSidebar && (
        <AddWidgetModal
          showModal={showNoteSidebar}
          closeModal={closeNoteSideBar}
          widgetId={para?.widgetId}
          propertyId={propertyDetailFromRTK?.propertyid}
          rowTitle={para?.rowTitle}
        />
      )}
      {widgetModal && (
        <WidgetDetailModal
          showModal={widgetModal}
          closeModal={closeWidgetModal}
          data={widgetDetailRef.current}
        />
      )}
    </div>
  );
};

export default DynamicPickup;
