import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Table } from "react-bootstrap";
import { apiCall } from "../../../../utils/axiosService";
import { API_URL } from "../../../../utils/apiRoutes";
import { apiResponse } from "../../../../utils/apiResponse";
import { commonService } from "../../../../utils/commonService";
import moment from "moment";
import NoteIcon from "../../../../Assets/Images/CRM/notes.svg";
import downloadIcon from "../../../../Assets/Images/Operation/downloadIcon.svg";
import Loader from "../../../Loader/Loader";
import DatePicker from "react-datepicker";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import emailIcon from "../../../../Assets/Images/Email.svg";

const PickupFrom = ({
  setCompDataFromChild,
  rowIndex,
  colIndex,
  api,
  para,
  setting,
}) => {
  const tableData = [
    {
      date: "2023-08-01",
      RMS: 0,
      ADR: 0,
    },
    {
      date: "2023-08-01",
      RMS: 0,
      ADR: 0,
    },
    {
      date: "2023-08-01",
      RMS: 0,
      ADR: 0,
    },
    {
      date: "2023-08-01",
      RMS: 0,
      ADR: 0,
    },
    {
      date: "2023-08-01",
      RMS: 0,
      ADR: 0,
    },
    {
      date: "2023-08-01",
      RMS: 0,
      ADR: 0,
    },
    {
      date: "2023-08-01",
      RMS: 0,
      ADR: 0,
    },
    {
      date: "2023-08-01",
      RMS: 0,
      ADR: 0,
    },
    {
      date: "2023-08-01",
      RMS: 0,
      ADR: 0,
    },
    {
      date: "2023-08-01",
      RMS: 0,
      ADR: 0,
    },
  ];
  let rooms = 0,
    adr = 0;
  const date = new Date();
  const startDate = commonService.getDateInDBFormat(
    new Date(date.getFullYear(), date.getMonth(), 1)
  );
  const endDate = commonService.getDateInDBFormat(
    new Date(date.getFullYear(), date.getMonth() + 1, 0)
  );
  const [maxDateForPickupFrom, setMaxDateForPickupFrom] = useState();
  const maxDateRef = useRef();
  if (maxDateForPickupFrom) {
    let date = new Date(maxDateForPickupFrom);
    maxDateRef.current = date.setDate(date.getDate() - 1);
  }

  const [pickupFromData, setPickupFromData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectAsOfDate, setSelectAsOfDate] = useState();
  const [selectPickupDate, setSelectPickupDate] = useState();
  const [startRangeDate, setStartRangeDate] = useState();
  const [endRangeDate, setEndRangeDate] = useState();
  const startRangeDateRef = useRef();
  const endRangeDateRef = useRef();
  const defaultAsOfDateFromRTK = useSelector(
    (store) => store.headerProperty.defaultAsOfDate
  );
  const { snapshottitle, propertyCode, snapshotId } = useParams();
  const handleDateChange = (event) => {
    const [start, end] = event;
    let startSelectedDate = "",
      endSelectedDate = "";
    if (start) {
      let date = new Date(start);
      let month = date.getMonth > 9 ? date.getMonth() : `0${date.getMonth()}`;
      let dateFormat =
        date?.getDate() > 9 ? date.getDate() : `0${date.getDate()}`;
      startSelectedDate = `${date.getFullYear()}-${month}-${dateFormat}`;
      startRangeDateRef.current = startSelectedDate;
    }
    if (end) {
      let date = new Date(end);
      let month = date.getMonth > 9 ? date.getMonth() : `0${date.getMonth()}`;
      let dateFormat =
        date?.getDate() > 9 ? date.getDate() : `0${date.getDate()}`;
      endSelectedDate = `${date.getFullYear()}-${month}-${dateFormat}`;
      endRangeDateRef.current = endSelectedDate;
    }

    setStartRangeDate(event[0]);
    setEndRangeDate(event[1]);
    if (event[0] !== null && event[1] !== null) {
      getPickupFromData();
    }
  };

  const diffDayStartDate = new Date(commonService.getDateInFormat(startDate));
  const diffDayEndDate = new Date(commonService.getDateInFormat(endDate));
  const diffDayInTime = diffDayEndDate.getTime() - diffDayStartDate.getTime();
  const diffDays = diffDayInTime / (1000 * 3600 * 24);

  const getPickupFromData = async () => {
    setLoading(true);
    let pickupDate = new Date(para?.asOfDate);
    pickupDate.setDate(pickupDate.getDate() - 1);
    let pickupFromDataResponse = await apiCall(
      {
        method: "POST",
        url: API_URL.BASE_API_URL.SnapShot + API_URL.DAILY_SUMMARY.PICKUP_FROM,
        body: {
          AsOfDate: selectAsOfDate ? selectAsOfDate : para?.asOfDate,
          startDate: startRangeDateRef.current
            ? startRangeDateRef.current
            : startDate,
          endDate: endRangeDateRef.current ? endRangeDateRef.current : endDate,
          pickupDate: selectPickupDate ? selectPickupDate : pickupDate,
          propertyCode: "AC32AW",
        },
      },
      false
    );

    let response = apiResponse(false, pickupFromDataResponse);

    if (response?.isValidate) {
      let pickupFromData = response?.data?.data?.map((item, index) => {
        // let dateForTd = new Date(
        //   startDateFromParent ? startDateFromParent : startDate
        // );
        // item.date = commonService.getDateInDBFormat(
        //   dateForTd.setDate(dateForTd.getDate() + index)
        // );

        let dateForTd = startRangeDate
          ? new Date(startRangeDate)
          : new Date(startDate);
        item.date = commonService.getDateInDBFormat(
          dateForTd.setDate(dateForTd.getDate() + index)
        );
        return item;
      });

      setPickupFromData(pickupFromData);
      setLoading(false);
    }

    if (!response?.isValidate) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (api === 0) {
      setPickupFromData(tableData);
    } else {
      if (para?.asOfDate) {
        setMaxDateForPickupFrom(new Date(para?.asOfDate));
        getPickupFromData();
      }
    }
    if (!selectAsOfDate) {
      setSelectAsOfDate(para?.asOfDate);
    }
  }, [para?.asOfDate, selectAsOfDate, selectPickupDate]);

  return (
    <div className="widget-container">
      {api !== 0 && (
        <div className="gap-1 widget-container-menu">
          <Button className="heading-menu-btns" type="button">
            <img src={NoteIcon} alt="menuIcon" />
          </Button>
          <Button className="heading-menu-btns" type="button" title="Send Mail">
            <img
              src={emailIcon}
              alt="emailIcon"
              onClick={() => {
                commonService.fnSendWidgetMail(
                  "pickupFromWidget",
                  "Pickup From",
                  snapshotId,
                  defaultAsOfDateFromRTK,
                  "",
                  para?.widgetId
                );
              }}
            />
          </Button>
          <Button className="heading-menu-btns" type="button">
            <img
              src={downloadIcon}
              alt="menuIcon"
              onClick={() =>
                commonService.fnExportInCSV(
                  "pickupFromWidget",
                  `${para?.propertyCode}-Pickup From-${moment(
                    new Date()
                  ).format("MMDDYYYY")}`
                )
              }
            />
          </Button>
        </div>
      )}
      <div className="ViewPropertySetupTable widget-container">
        <div className="stripped__table">
          <Table
            id="pickupFromWidget"
            responsive
            className="positionStaticTable"
          >
            <thead>
              <tr>
                <th className="width200">
                  AsOfDate
                  <DatePicker
                    id="asOfDate"
                    maxDate={new Date(para?.asOfDate)}
                    selected={
                      selectAsOfDate
                        ? commonService.convertDateInTimezone(selectAsOfDate)
                        : ""
                    }
                    onChange={(date) => {
                      let month =
                        date.getMonth > 9
                          ? date.getMonth()
                          : `0${date.getMonth()}`;
                      let dateFormat =
                        date?.getDate() > 9
                          ? date.getDate()
                          : `0${date.getDate()}`;
                      let selectedDate = `${date.getFullYear()}-${month}-${dateFormat}`;
                      setSelectAsOfDate(selectedDate);
                    }}
                  />
                </th>
                <th className="border-right" colSpan={2}>
                  Pickup From{" "}
                  <DatePicker
                    id="pickupDate"
                    maxDate={new Date(maxDateRef.current)}
                    selected={
                      selectPickupDate
                        ? commonService.convertDateInTimezone(selectPickupDate)
                        : commonService.convertDateInTimezone(
                            maxDateRef.current
                          )
                    }
                    onChange={(date) => {
                      let month =
                        date.getMonth > 9
                          ? date.getMonth()
                          : `0${date.getMonth()}`;
                      let dateFormat =
                        date?.getDate() > 9
                          ? date.getDate()
                          : `0${date.getDate()}`;
                      let selectedDate = `${date.getFullYear()}-${month}-${dateFormat}`;
                      setSelectPickupDate(selectedDate);
                    }}
                  />
                </th>
              </tr>
              <tr>
                <th>
                  {/* {startDateFromParent
                    ? commonService?.getDateInFormat(startDateFromParent)
                    : commonService?.getDateInFormat(startDate)}{" "}
                  -{" "}
                  {endDateFromParent
                    ? commonService?.getDateInFormat(endDateFromParent)
                    : commonService?.getDateInFormat(endDate)} */}
                  {/* {commonService?.getDateInFormat(startDate)} -{" "}
                  {commonService?.getDateInFormat(endDate)} */}
                  Date{" "}
                  <DatePicker
                    onChange={handleDateChange}
                    startDate={startRangeDate}
                    endDate={endRangeDate}
                    selectsRange
                    monthsShown={2}
                  />
                </th>
                <th className="border-right text-center">Rooms</th>
                <th className="text-end">ADR P/U</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr style={{ height: "80px", position: "relative" }}>
                  <Loader />
                </tr>
              ) : (
                pickupFromData &&
                pickupFromData?.map((item, index) => {
                  rooms = rooms + item?.RMS;
                  adr = adr + item?.ADR;

                  return (
                    <React.Fragment key={index}>
                      <tr key={index}>
                        <td>
                          {commonService?.getDateInFormat(item?.date)}{" "}
                          {moment(new Date(item?.date), "l").format("ddd")}
                        </td>
                        <td className="border-right text-center">
                          {item?.RMS}
                        </td>
                        <td className="text-end">${item?.ADR}</td>
                      </tr>
                      {index === pickupFromData?.length - 1 && (
                        <tr>
                          <td>Total</td>
                          <td className="border-right text-center">{rooms}</td>
                          <td className="text-end">
                            ${adr > 0 ? parseInt(adr / diffDays) : 0}
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  );
                })
              )}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default PickupFrom;
