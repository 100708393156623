import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { validationRule } from "../../../utils/constant";
import { apiCall } from "../../../utils/axiosService";
import { API_URL } from "../../../utils/apiRoutes";
import { apiResponse } from "../../../utils/apiResponse";
import { checkValidation } from "../../../utils/helper";
import { commonService } from "../../../utils/commonService";

const AddEventModal = ({
  showModal,
  closeModal,
  eventId,
  propertyId,
  getList,
}) => {
  let validationObj = {
    eventid: eventId,
    startdate: "",
    eventname: "",
    eventdetails: "",
    eventtypeterm: "",
    eventimpactterm: "",
    expectedoccupencyimpact: "",
    expectedrevenueimpact: "",
    eventsourceterm: "",
    propertyid: parseInt(propertyId),
    usernote: "",
    enddate: "",
    validationRule: [
      {
        fieldName: "startdate",
        rule: validationRule.required,
        message: "Start Date is required",
      },
      {
        fieldName: "eventname",
        rule: validationRule.required,
        message: "Event Name is required",
      },
      {
        fieldName: "eventtypeterm",
        rule: validationRule.required,
        message: "Event Type is required",
      },
      {
        fieldName: "enddate",
        rule: validationRule.required,
        message: "End Date is required",
      },
    ],
    errorObj: {},
    isValidate: true,
  };

  const [eventData, setEventData] = useState(validationObj);
  const [termList, setTermList] = useState(null);
  const [loading, setLoading] = useState(false);

  const getTermCategoryList = async () => {
    let termCategoryListResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.Configuration +
          API_URL.PROJECT_TERM.GET_LIST_BY_MULTIPLE_CATEGORY,
        body: {
          categorynamebycomma: "Event Type,Event Impact,Event Source",
        },
      },
      false
    );

    let response = apiResponse(false, termCategoryListResponse);

    if (response?.isValidate) {
      setTermList(response?.data?.data);
    }
  };

  const getDetailByPrimaryKey = async () => {
    let getDetailByPrimaryKeyResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.RevenueManagement +
          API_URL.EVENTS.GET_BY_PRIMARY_KEY,
        body: {
          id: parseInt(eventId),
        },
      },
      false
    );

    let response = apiResponse(false, getDetailByPrimaryKeyResponse);

    if (response?.isValidate) {
      setEventData({
        ...eventData,
        startdate: response?.data?.data?.startdate
          ? response?.data?.data?.startdate
          : "",
        eventname: response?.data?.data?.eventname
          ? response?.data?.data?.eventname
          : "",
        eventdetails: response?.data?.data?.eventdetails
          ? response?.data?.data?.eventdetails
          : "",
        eventtypeterm: response?.data?.data?.eventtypeterm
          ? response?.data?.data?.eventtypeterm
          : "",
        eventimpactterm: response?.data?.data?.eventimpactterm
          ? response?.data?.data?.eventimpactterm
          : "",
        expectedoccupencyimpact: response?.data?.data?.expectedoccupencyimpact
          ? response?.data?.data?.expectedoccupencyimpact
          : "",
        expectedrevenueimpact: response?.data?.data?.expectedrevenueimpact
          ? response?.data?.data?.expectedrevenueimpact
          : "",
        eventsourceterm: response?.data?.data?.eventsourceterm
          ? response?.data?.data?.eventsourceterm
          : "",
        usernote: response?.data?.data?.usernote
          ? response?.data?.data?.usernote
          : "",
        enddate: response?.data?.data?.enddate
          ? response?.data?.data?.enddate
          : "",
      });
    }

    if (!response?.isValidate) {
      setEventData(validationObj);
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    let validation = checkValidation(eventData);

    if (!validation.isValidate) {
      setEventData(validation);
      setLoading(false);
      return;
    }

    const saveResponse = await apiCall(
      {
        method: "POST",
        url: API_URL.BASE_API_URL.RevenueManagement + API_URL.EVENTS.SAVE,
        body: {
          eventid: eventData?.eventid,
          startdate: commonService.getDateInDBFormat(eventData?.startdate),
          eventname: eventData?.eventname,
          eventdetails: eventData?.eventdetails,
          eventtypeterm: eventData?.eventtypeterm,
          eventimpactterm: eventData?.eventimpactterm,
          expectedoccupencyimpact: eventData?.expectedoccupencyimpact,
          expectedrevenueimpact: eventData?.expectedrevenueimpact,
          eventsourceterm: eventData?.eventsourceterm,
          propertyid: eventData?.propertyid,
          usernote: eventData?.usernote,
          enddate: commonService.getDateInDBFormat(eventData?.enddate),
        },
      },
      true
    );

    let response = apiResponse(true, saveResponse, setLoading);

    if (response?.isValidate) {
      setLoading(false);
      closeModal();
      if (getList) {
        getList();
      }
    }

    if (!response?.isValidate) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getTermCategoryList();
    if (eventId > 0) {
      getDetailByPrimaryKey();
    }
  }, []);
  return (
    <>
      <Modal
        className="AddPropertySetupModalMain"
        size="xl"
        centered
        show={showModal}
        onHide={closeModal}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {`${eventId > 0 ? "Edit" : "Add"} Event`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xl={4}>
              <div className="from-groups no-border-break mb-3">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Name"
                  value={eventData?.eventname}
                  onChange={(e) => {
                    setEventData({
                      ...eventData,
                      eventname: e.target.value,
                    });
                  }}
                />
                {eventData?.errorObj?.eventname && (
                  <small className="text-danger">
                    {eventData?.errorObj?.eventname}
                  </small>
                )}
              </div>
            </Col>
            <Col xl={4}>
              <div className="flotingLableGroup">
                <div className="from-groups no-border-break mb-3">
                  <Form.Label>Event Type</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    value={eventData?.eventtypeterm}
                    onChange={(e) => {
                      setEventData({
                        ...eventData,
                        eventtypeterm: e.target.value,
                      });
                    }}
                  >
                    <option value="">Select Event Type</option>
                    {termList?.map((item) => {
                      if (item?.termcategoryname === "Event Type") {
                        return (
                          <option key={item?.term} value={item?.term}>
                            {item?.term}
                          </option>
                        );
                      }
                    })}
                  </Form.Select>
                  {eventData?.errorObj?.eventtypeterm && (
                    <small className="text-danger">
                      {eventData?.errorObj?.eventtypeterm}
                    </small>
                  )}
                </div>
              </div>
            </Col>
            <Col xl={4}>
              <div className="flotingLableGroup">
                <div className="from-groups no-border-break mb-3">
                  <Form.Label>Event Impact</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    value={eventData?.eventimpactterm}
                    onChange={(e) => {
                      setEventData({
                        ...eventData,
                        eventimpactterm: e.target.value,
                      });
                    }}
                  >
                    <option value="">Select Event Impact</option>
                    {termList?.map((item) => {
                      if (item?.termcategoryname === "Event Impact") {
                        return (
                          <option key={item?.term} value={item?.term}>
                            {item?.term}
                          </option>
                        );
                      }
                    })}
                  </Form.Select>
                </div>
              </div>
            </Col>
            <Col xl={4}>
              <div className="flotingLableGroup">
                <div className="from-groups no-border-break mb-3">
                  <Form.Label>Event Source</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    value={eventData?.eventsourceterm}
                    onChange={(e) => {
                      setEventData({
                        ...eventData,
                        eventsourceterm: e.target.value,
                      });
                    }}
                  >
                    <option value="">Select Event Source</option>
                    {termList?.map((item) => {
                      if (item?.termcategoryname === "Event Source") {
                        return (
                          <option key={item?.term} value={item?.term}>
                            {item?.term}
                          </option>
                        );
                      }
                    })}
                  </Form.Select>
                </div>
              </div>
            </Col>
            <Col xl={4}>
              <div className="from-groups no-border-break mb-3">
                <Form.Label>Start Date</Form.Label>
                <DatePicker
                  id="startDate"
                  selected={
                    eventData?.startdate
                      ? commonService.convertDateInTimezone(
                          eventData?.startdate
                        )
                      : ""
                  }
                  onChange={(date) => {
                    setEventData({
                      ...eventData,
                      startdate: date,
                    });
                  }}
                  placeholderText="Select Start Date"
                />
                {eventData?.errorObj?.startdate && (
                  <small className="text-danger">
                    {eventData?.errorObj?.startdate}
                  </small>
                )}
              </div>
            </Col>
            <Col xl={4}>
              <div className="from-groups no-border-break mb-3">
                <Form.Label>End Date</Form.Label>
                <DatePicker
                  id="endDate"
                  selected={
                    eventData?.enddate
                      ? commonService.convertDateInTimezone(eventData?.enddate)
                      : ""
                  }
                  onChange={(date) => {
                    setEventData({
                      ...eventData,
                      enddate: date,
                    });
                  }}
                  placeholderText="Select End Date"
                />
                {eventData?.errorObj?.enddate && (
                  <small className="text-danger">
                    {eventData?.errorObj?.enddate}
                  </small>
                )}
              </div>
            </Col>
            <Col xl={4}>
              <div className="from-groups no-border-break mb-3">
                <Form.Label>Expected Occupancy Impact</Form.Label>
                <Form.Control
                  type="text"
                  pattern="[0-9]*"
                  placeholder="Enter Expected Occupancy Impact"
                  value={eventData?.expectedoccupencyimpact}
                  onChange={(e) => {
                    if (e.target.validity.valid) {
                      setEventData({
                        ...eventData,
                        expectedoccupencyimpact: e.target.value,
                      });
                    }
                  }}
                />
              </div>
            </Col>
            <Col xl={4}>
              <div className="from-groups no-border-break mb-3">
                <Form.Label>Expected Revenue Impact</Form.Label>
                <Form.Control
                  type="text"
                  pattern="[0-9]*"
                  placeholder="Enter Expected Revenue Impact"
                  value={eventData?.expectedrevenueimpact}
                  onChange={(e) => {
                    if (e.target.validity.valid) {
                      setEventData({
                        ...eventData,
                        expectedrevenueimpact: e.target.value,
                      });
                    }
                  }}
                />
              </div>
            </Col>
            <Col xl={12}>
              <Row>
                <Col xl={6}>
                  <div className="from-groups no-border-break mb-2">
                    <Form.Label>Event Detail</Form.Label>
                    <Form.Control
                      as="textarea"
                      placeholder="Enter Event Detail"
                      maxLength="3271"
                      style={{ height: "200px" }}
                      value={eventData?.eventdetails}
                      onChange={(e) => {
                        setEventData({
                          ...eventData,
                          eventdetails: e.target.value,
                        });
                      }}
                    />
                  </div>
                </Col>
                <Col xl={6}>
                  <div className="from-groups no-border-break mb-2">
                    <Form.Label>User Note</Form.Label>
                    <Form.Control
                      as="textarea"
                      placeholder="Enter User Note"
                      maxLength="3271"
                      style={{ height: "200px" }}
                      value={eventData?.usernote}
                      onChange={(e) => {
                        setEventData({
                          ...eventData,
                          usernote: e.target.value,
                        });
                      }}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          {loading ? (
            <button className="default-btn primary-btn" type="button" disabled>
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              Loading...
            </button>
          ) : (
            <button
              className="default-btn primary-btn"
              onClick={() => {
                handleSubmit();
              }}
            >
              Submit
            </button>
          )}

          <Button className="default-btn secondary-btn" onClick={closeModal}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddEventModal;
