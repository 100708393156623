import { Accordion, Button, Col, Form, Offcanvas } from "react-bootstrap";
import { apiCall } from "../../utils/axiosService";
import { API_URL } from "../../utils/apiRoutes";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import ReactDOM from "react-dom";
import Loader from "../Loader/Loader";
import { commonService } from "../../utils/commonService";
import searchIcon from "../../Assets/Images/Configuration/search.svg";
import filter from "../../Assets/Images/CRM/filter.png";
import { useDispatch, useSelector } from "react-redux";
import { setAllNotificationCount } from "../../utils/slices/headerPropertySlice";
import { apiResponse } from "../../utils/apiResponse";

const NotificationList = ({ showSideBar, closeSideBar }) => {
  const notificationType = useSelector(
    (store) => store.headerProperty.allNotificationCount
  );
  const allPropertyDetails = useSelector(
    (store) => store.headerProperty.allPropertyDetails
  );
  const propertyDetailFromRTK = useSelector(
    (store) => store.headerProperty.propertyDetail
  );
  const dispatch = useDispatch();

  const [notificationList, setNotificationList] = useState([]);
  const [start, setstart] = useState(0);
  const [node, setNode] = useState(null);
  const [loading, setLoading] = useState(false);
  const [input, setInput] = useState("");
  const navigate = useNavigate();
  const userDetail = commonService.getLoginUserData();
  const [activeAccrodian, setActiveAccrodian] = useState(null);
  const [selectedProperty, setSelectedProperty] = useState(propertyDetailFromRTK);

  const getNotificationList = async () => {
    setLoading(true);
    let notificationListResponse = await apiCall(
      {
        method: "POST",
        url: API_URL.BASE_API_URL.Notification + API_URL.NOTIFICATION.GET_LIST,
        body: {
          title: input,
          notificationstartdate: "",
          notificationenddate: "",
          notificationtoid: "",
          draw: 1,
          start: start,
          length: 10,
          sortcolumn: "notificationid",
          sortdirection: "desc",
          searchvalue: "",
          columns: "",
          "search.value": "",
          "search.regex": "",
          order: "",
          associationtype: activeAccrodian,
        },
      },
      true
    );

    if (notificationListResponse?.status_code === 0) {
    } else if (notificationListResponse?.status_code === 1) {
      setNotificationList((prev) => [
        ...prev,
        ...notificationListResponse?.data,
      ]);
    } else if (notificationListResponse?.status_code === 2) {
      localStorage.clear();
      window.location.href =
        API_URL.BASE_API_URL.EMA_MAIN_DOMAIN + "?logout=true";
    }
    setLoading(false);
  };

  const getInsightList = async () => {
    setLoading(true);
    let getInsightLiastResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.Notification +
          API_URL.NOTIFICATION.GET_INSIGHT_LIST,
        body: {
          title: "",
          notificationstartdate: "",
          notificationenddate: "",
          notificationtoid: "",
          propertycode: selectedProperty ? selectedProperty?.propertycode : "",
          draw: 1,
          start: start,
          length: 10,
          sortcolumn: "notificationid",
          sortdirection: "desc",
          searchvalue: "",
          columns: "",
          "search.value": "",
          "search.regex": "",
          order: "",
          associationtype: activeAccrodian,
        },
      },
      true
    );

    if (getInsightLiastResponse?.status_code === 0) {
    } else if (getInsightLiastResponse?.status_code === 1) {
      setNotificationList((prev) => [
        ...prev,
        ...getInsightLiastResponse?.data,
      ]);
    } else if (getInsightLiastResponse?.status_code === 2) {
      localStorage.clear();
      window.location.href =
        API_URL.BASE_API_URL.EMA_MAIN_DOMAIN + "?logout=true";
    }
    setLoading(false);
  };

  const nodeData = useRef();
  // let body = document.getElementById("canvas");
  let body = document.getElementById(`${activeAccrodian}`);
  nodeData.current = ReactDOM.findDOMNode(body);

  useEffect(() => {
    setNode(nodeData.current);
  }, [nodeData.current]);

  useEffect(() => {
    if (nodeData.current) {
      nodeData.current.addEventListener("scroll", () => {
        const scrollableHeight =
          nodeData.current?.scrollHeight - nodeData.current?.clientHeight;

        if (nodeData.current?.scrollTop >= scrollableHeight) {
          setstart((prev) => prev + 10);
        }
      });
    }
  }, [node]);

  useEffect(() => {
    if (activeAccrodian) {
      if (selectedProperty) {
        getInsightList();
      } else {
        getNotificationList();
      }
    }
  }, [start, activeAccrodian, selectedProperty]);

  const getNotificationCount = async () => {
    let notificationCountResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.Notification +
          API_URL.NOTIFICATION.GET_NOTIFICATION_READ_COUNT,
        body: {
          propertycode: selectedProperty ? selectedProperty?.propertycode : "",
        },
      },
      true
    );

    let response = apiResponse(false, notificationCountResponse);

    if (response?.isValidate) {
      dispatch(setAllNotificationCount(response?.data?.data));
    }
  };

  const updateNotificationView = async (id) => {
    let notificationUpdateResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.Notification +
          API_URL.NOTIFICATION.UPDATE_VIEW_BY,
        body: {
          notificationbyid: id,
          userid: userDetail?.userid,
        },
      },
      true
    );

    let response = apiResponse(false, notificationUpdateResponse);

    if (response?.isValidate) {
      getNotificationCount();
    }
  };

  const isElementVisible = () => {
    notificationList?.forEach((item) => {
      let ele = document.getElementById(`${item?.notificationid}`);
      const rect = ele.getBoundingClientRect();

      if (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <=
          (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <=
          (window.innerWidth || document.documentElement.clientWidth) &&
        item?.isread === false
      ) {
        updateNotificationView(item?.notificationid);
      }
    });
  };

  useEffect(() => {
    isElementVisible();
  }, [notificationList]);

  useEffect(() => {
    getNotificationCount();
  }, [selectedProperty]);

  return (
    <Offcanvas
      className="notification-canvas"
      show={showSideBar}
      onHide={closeSideBar}
      placement="end"
    >
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Notification List</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body id="canvas">
        <Col xl={12}>
          <div className="from-groups no-border-break mb-3">
            <Form.Select
              aria-label="Default select example"
              value={selectedProperty?.propertyid}
              onChange={(e) => {
                setNotificationList([]);
                if (e.target.value === "") {
                  setSelectedProperty("");
                } else {
                  let findSelectedProperty = allPropertyDetails?.filter(
                    (x) => x?.propertyid == e.target.value
                  );
                  setSelectedProperty(findSelectedProperty?.[0]);
                }
              }}
            >
              <option value="">Select Property</option>
              {allPropertyDetails?.map((item) => {
                return (
                  <option key={item?.propertyid} value={item?.propertyid}>
                    {item?.propertyname}
                  </option>
                );
              })}
            </Form.Select>
          </div>

          <div className="insightsAccordion">
            <Accordion
              onSelect={(k) => {
                setNotificationList([]);
                setActiveAccrodian(k);
                setstart(0);
              }}
            >
              {notificationType?.map((item) => {
                if (item?.associationtype !== "All") {
                  return (
                    <Accordion.Item eventKey={item?.associationtype}>
                      <Accordion.Header>
                        {item?.associationtype}{" "}
                        {item?.noofcount ? (
                          <span className={"unreadnotificationcount"}>
                            {item?.noofcount}
                          </span>
                        ) : (
                          ""
                        )}
                      </Accordion.Header>
                      <Accordion.Body
                        style={{
                          maxHeight: "400px",
                          overflowY: "auto",
                        }}
                        id={item?.associationtype}
                      >
                        {notificationList?.length > 0
                          ? notificationList?.map((notificationData, index) => {
                              let notificationTime = "";
                              if (
                                commonService.getDateInFormat(
                                  new Date(new Date().toUTCString())
                                ) ===
                                commonService.getDateInFormat(
                                  notificationData?.notificationdatetime
                                )
                              ) {
                                let dif =
                                  new Date(
                                    new Date().getUTCFullYear(),
                                    new Date().getUTCMonth(),
                                    new Date().getUTCDate(),
                                    new Date().getUTCHours(),
                                    new Date().getUTCMinutes()
                                  ) -
                                  new Date(
                                    notificationData?.notificationdatetime
                                  );
                                let totalMinutes = Math.round(dif / 1000 / 60);
                                if (totalMinutes > 59) {
                                  notificationTime =
                                    Math.round(totalMinutes / 60) +
                                    " hours ago";
                                } else {
                                  if (totalMinutes > 0) {
                                    notificationTime =
                                      totalMinutes + " min ago";
                                  } else {
                                    notificationTime = "sec ago";
                                  }
                                }
                              } else {
                                notificationTime =
                                  commonService.getDateInFormat(
                                    notificationData?.notificationdatetime
                                  );
                              }

                              let notificationDetail =
                                notificationData?.details;
                              let title = notificationData?.title?.split("]:");
                              let color = "#7F8C8D";

                              return (
                                <>
                                  <div
                                    className={`notificationMain ${
                                      notificationData?.isread === false
                                        ? "unreadnotification"
                                        : ""
                                    } `}
                                    id={notificationData?.notificationid}
                                  >
                                    <div className="notification-wrapper">
                                      <div className="notification-name-time">
                                        <p className="notification-name">
                                          {notificationData?.notificationbyname}
                                        </p>
                                        <p>|</p>
                                        <p className="notification-date">
                                          {notificationTime}
                                        </p>
                                      </div>
                                      <div className="notification-ttile-detail">
                                        {title?.length > 1 ? (
                                          <h4>
                                            <span>{`${title?.[0]}]:`}</span>
                                            {title?.[1]}
                                          </h4>
                                        ) : (
                                          <h4>{notificationData?.title}</h4>
                                        )}
                                        <div className="notification-detail" dangerouslySetInnerHTML={{__html:notificationData?.details}}/>
                                        {/* <p className="notification-detail">
                                          {notificationData?.details}
                                        </p> */}
                                      </div>
                                    </div>
                                  </div>
                                </>
                              );
                            })
                          : !loading && (
                              <div className="text-center">No Data Found</div>
                            )}
                        {loading && <Loader />}
                      </Accordion.Body>
                    </Accordion.Item>
                  );
                }
              })}
            </Accordion>
          </div>
        </Col>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default NotificationList;
