import React from "react";
import { Bar, Line } from "react-chartjs-2";
import {
  Chart,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
} from "chart.js";
const ThreeBarAndLineChart = ({
  barData1,
  barData2,
  barData3,
  lineData,
  aspectRatio,
}) => {
  const options = {
    scales: {
      x: {
        title: {
          display: false,
          text: "Months",
        },
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
        title: {
          display: false,
          text: "Percentage",
        },
        ticks: {
          callback: function (value) {
            return value;
            // return value + "%";
          },
        },
        layout: {
          padding: {
            left: 20,
            right: 20,
            top: 50,
            bottom: 50,
          },
        },
      },
    },
    plugins: {
      tooltip: {
        mode: "index",
        intersect: false,
      },
      legend: {
        display: true,
        align: "end",
        position: "top",
        maxWidth: 20,
        maxHeight: 100,
        labels: {
          boxWidth: 10,
          boxHeight: 10,
        },
      },
    },
    responsive: true,
  };

  const mergedData = {
    labels: lineData.labels,
    datasets: [
      {
        type: "bar",
        label: barData1.label,
        data: barData1.data,
        backgroundColor: "#4F79FF",
        borderColor: "#4F79FF",
        borderWidth: 1,
        barThickness: 10,
        order: 1,
      },
      {
        type: "bar",
        label: barData2.label,
        data: barData2.data,
        backgroundColor: "#B8C8FC",
        borderColor: "#B8C8FC",
        borderWidth: 1,
        barThickness: 10,
        order: 1,
      },
      {
        type: "bar",
        label: barData3.label,
        data: barData3.data,
        backgroundColor: "#0F3FD4",
        borderColor: "#0F3FD4",
        borderWidth: 1,
        barThickness: 10,
        order: 1,
      },
      {
        type: "line",
        label: lineData.label,
        data: lineData.data,
        fill: false,
        backgroundColor: "#3565FC",
        borderColor: "#36C3FF",
        borderWidth: 1,
        order: 0,
      },
    ],
  };
  Chart.register(
    LineElement,
    PointElement,
    CategoryScale,
    LinearScale,
    BarElement,
    Tooltip,
    Legend
  );
  return (
    <div className="Occupancymain">
      <Bar data={mergedData} options={options} />
    </div>
  );
};

export default ThreeBarAndLineChart;
