import React from "react";
import { Card, Modal } from "react-bootstrap";

const RateIqViewModal = ({ showModal, closeModal, data }) => {
  return (
    <div className="allReminder-main-wrapper">
      <div className="my-special-header">Predicated Rate Calculation</div>
      <div className="allReminder-main-inner-main">
        {data &&
          Object?.entries(data)?.map(([key, value], index) => {
            return (
              <>
                <div
                  className={`d-flex justify-content-between align-items-center allReminder-main-inner-main-internal ${
                    value?.type === "Final Rate" ? "predicationRateBold" : ""
                  }`}
                >
                  <p>{value?.type}</p>
                  <p>{value?.calculation}</p>
                </div>
                {index === Object.keys(data)?.length - 2 && (
                  <hr style={{ margin: 0, marginBottom: "5px" }} />
                )}
              </>
            );
          })}
      </div>
    </div>
  );
};

export default RateIqViewModal;
