import React, { useEffect, useRef, useState } from "react";
import { Row, Form, Col, Button, Container } from "react-bootstrap";
import pdfIcon from "../../../Assets/Images/CRM/pdf.svg";
import { commonService } from "../../../utils/commonService";
import DatePicker from "react-datepicker";
import { API_URL } from "../../../utils/apiRoutes";
import { validationRule } from "../../../utils/constant";
import { hotelListCommon } from "../Hotels/HotelListCommon";
import { AccountListCommon } from "../Contacts/AccountListCommon";
import { checkValidation } from "../../../utils/helper";
import { apiCall } from "../../../utils/axiosService";
import { apiResponse } from "../../../utils/apiResponse";
import { successToastMessage } from "../../../utils/toastMessage";
import Select from "react-select";
import filterIcon from "../../../Assets/Images/CRM/filter.png";
import { useSelector } from "react-redux";
import Loader from "../../Loader/Loader";
import { Link } from "react-router-dom";

const OpportunityBySourceReport = ({ title }) => {
  const [selectSourceTerm, setSourceTerm] = useState([]);
  const [termList, setTermList] = useState([]);
  const [selectProperty, setSelectProperty] = useState([]);
  const [propertyValue, setPropertyValue] = useState([]);
  const [SelectUser, setSelectUser] = useState([]);
  const [UserValue, setUserValue] = useState([]);
  const [errorMessage, setErrorMessage] = useState(false);
  const userIdList = useRef("");
  const statusList = useRef("");
  const propertyIdList = useRef("");
  const isDefaultApiCalledRef = useRef(false);
  const [loading, setLoading] = useState(false);
  const setPdfRef = useRef("");
  const [showPdf, setShowPdf] = useState(false);
  let startDate = new Date();
  let endDate = new Date(startDate.getTime() - 7 * 24 * 60 * 60 * 1000);
  const userDetails = commonService.getLoginUserData();

  const crmPropertyDetailFromRTK = useSelector(
    (store) => store.headerProperty.crmPropertyDetail
  );

  let propertyIdPayload = "";
  if (crmPropertyDetailFromRTK) {
    if (crmPropertyDetailFromRTK?.propertyid === "") {
      let propertyPayload = crmPropertyDetailFromRTK?.allPropertyId;
      propertyIdPayload = propertyPayload.slice(0, -1);
    } else {
      propertyIdPayload = crmPropertyDetailFromRTK?.propertyid?.toString();
    }
  }

  let validationObj = {
    propertyID: "",
    userId: "",
    sources: "",
    startDate: endDate,
    endDate: startDate,

    validationRule: [
      {
        fieldName: "propertyID",
        rule: validationRule.required,
        message: "Property Id required",
      },
      {
        fieldName: "userId",
        rule: validationRule.required,
        message: "User selection required",
      },
      {
        fieldName: "startDate",
        rule: validationRule.required,
        message: "Start Date is required",
      },
      {
        fieldName: "endDate",
        rule: validationRule.required,
        message: "End Date is required",
      },
      {
        fieldName: "sources",
        rule: validationRule.required,
        message: "Source is required",
      },
    ],
    errorObj: {},
    isValidate: true,
  };
  const [opprtunityBySource, setopprtunityBySource] = useState(validationObj);
  const opprtunityBySourceRef = useRef(opprtunityBySource);
  const [showAllReport, setshowAllReport] = useState(false);

  const handleshowAllReport = () => {
    setshowAllReport(!showAllReport);
  };

  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setopprtunityBySource({
      ...opprtunityBySource,
      startDate: start,
      endDate: end,
    });
  };
  //get Property List
  const getHotelList = async () => {
    let response = await hotelListCommon(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.CRM +
          API_URL.PROPERTY_TERM.GET_PROPERTY_LIST_BY_USER_ID,
        body: {
          id: userDetails?.userid,
        },
      },
      false
    );

    if (response?.isValidate) {
      let propertyListarry = [];
      response?.data?.map((item) => {
        propertyListarry = [
          ...propertyListarry,
          {
            label: item?.propertyname,
            value: item?.propertyid,
          },
        ];
      });
      setSelectProperty(propertyListarry);
      if (crmPropertyDetailFromRTK?.propertyid !== "") {
        setPropertyValue([
          {
            label: crmPropertyDetailFromRTK?.propertyname,
            value: crmPropertyDetailFromRTK?.propertyid,
          },
        ]);
        propertyIdList.current = `${crmPropertyDetailFromRTK?.propertyid}`;
        opprtunityBySourceRef.current.propertyID = propertyIdList.current;
        setopprtunityBySource(opprtunityBySourceRef.current);
      }
    }
  };

  //get Account/User List
  const getUserList = async (propertyId) => {
    let userResponse = await AccountListCommon(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.UserManagement +
          API_URL.USER_ROLE_PROPERTY.GET_USER_LIST_BY_MODULE_MULTI_PROPERTY,
        body: {
          propertyid: opprtunityBySource.propertyID,
          modulename: "crm",
        },
      },
      false
    );

    if (userResponse?.isValidate) {
      let userDropList = [];
      let userId = [];

      userResponse?.data?.map((item) => {
        userId = [...userId, item?.userid?.toString()];
        userDropList = [
          ...userDropList,
          {
            label: item?.displayname,
            value: item?.userid,
          },
        ];
      });
      setSelectUser(userDropList);
      setUserValue(userDropList);
      userIdList.current = userId?.join(",");
      setopprtunityBySource({
        ...opprtunityBySource,
        userId: userIdList.current,
      });

      // if (crmPropertyDetailFromRTK?.propertyid != "") {
      //   userIdList.current = userId?.join(",");
      //   setopprtunityBySource({
      //     ...opprtunityBySource,
      //     userId: userIdList.current,
      //   });
      // }
      // userIdList.current = userId?.join(",");
      // opprtunityBySourceRef.current.userId = userIdList.current;
      // setopprtunityBySource(opprtunityBySourceRef.current);
    }
  };

  //Opportunity Status
  const getTermListByMulCategory = async () => {
    let termListByMulCategoryResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.Configuration +
          API_URL.PROJECT_TERM.GET_LIST_BY_MULTIPLE_CATEGORY,
        body: {
          categorynamebycomma: "Source",
        },
      },
      false
    );

    let response = apiResponse(false, termListByMulCategoryResponse);

    if (response?.isValidate) {
      // setTermList(response?.data?.data);
      let userDropList = [];
      let sourceList = [];
      response?.data?.data.map((item) => {
        sourceList = [...sourceList, item?.term];
        userDropList = [
          ...userDropList,
          {
            label: item?.term,
            value: item?.term,
          },
        ];
      });
      setTermList(userDropList);
      setSourceTerm(userDropList);
      statusList.current = sourceList?.join(",");
      opprtunityBySourceRef.current.sources = statusList.current;
      setopprtunityBySource(opprtunityBySourceRef.current);
    }
  };

  //on account/user dropdown
  const HandleUserSelection = (e) => {
    if (e?.length <= 0) {
      setUserValue([]);
    }
    setUserValue(e);
    let userListItm = [];
    e?.map((item) => {
      userListItm = [...userListItm, item?.value?.toString()];
      userIdList.current = userListItm?.join(",");
      setopprtunityBySource({
        ...opprtunityBySource,
        userId: userIdList.current,
      });
    });
  };

  //select status drop
  const handleStatusSelection = (e) => {
    if (e?.length <= 0) {
      setSourceTerm([]);
    }
    setSourceTerm(e);
    let propertyList = [];
    e?.map((item) => {
      propertyList = [...propertyList, item?.value?.toString()];
      statusList.current = propertyList?.join(",");
      setopprtunityBySource({
        ...opprtunityBySource,
        sources: statusList.current,
      });
    });
  };

  //select multi property
  const handlePropertySelectionFc = (e) => {
    if (e?.length <= 0) {
      setPropertyValue([]);
    }
    setPropertyValue(e);
    let propertyList = [];
    e?.map((item) => {
      propertyList = [...propertyList, item?.value?.toString()];
      propertyIdList.current = propertyList?.join(",");
      setopprtunityBySource({
        ...opprtunityBySource,
        propertyID: propertyIdList.current,
      });
      // getUserList(propertyIdList.current);
    });
  };

  const iframeloader = () => {
    setLoading(false);
  };
  const GenerateReportbtn = async () => {
    setShowPdf(false);
    setLoading(true);
    let validation = checkValidation(opprtunityBySource);

    if (!validation.isValidate) {
      setopprtunityBySource(validation);
      setLoading(false);
      return;
    }

    let ActivitiesReport = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.CRM +
          API_URL.CRM_REPORT.OPPORTUNITY_BY_SOURCE_REPORT,
        body: {
          propertyID: opprtunityBySource.propertyID,
          userId: opprtunityBySource.userId.toString(),
          sources: opprtunityBySource.sources,
          startDate: opprtunityBySource.startDate,
          endDate: opprtunityBySource.endDate,
        },
      },
      false
    );
    let response = apiResponse(false, ActivitiesReport);

    if (response?.isValidate) {
      isDefaultApiCalledRef.current = true;
      // setLoading(false);
      setshowAllReport(false);
      setErrorMessage(false);
      // successToastMessage(response?.message);
      setPdfRef.current =
        process.env.REACT_APP_APIBASEPATH_CRM_REPORT_PDF + response.data.data;
      setShowPdf(true);
    }
    if (!response?.isValidate) {
      isDefaultApiCalledRef.current = true;
      setshowAllReport(true);
      setLoading(false);
      setErrorMessage(true);
    }
  };

  useEffect(() => {
    if (crmPropertyDetailFromRTK?.propertyid === "") {
      isDefaultApiCalledRef.current = true;
      setshowAllReport(true);
    }
    getTermListByMulCategory();
    getHotelList();
  }, []);

  useEffect(() => {
    if (opprtunityBySource.propertyID !== "") {
      getUserList();
    }
  }, [opprtunityBySource.propertyID]);

  useEffect(() => {
    if (opprtunityBySource.propertyID !== "") {
      if (
        !isDefaultApiCalledRef.current &&
        opprtunityBySource.userId !== "" &&
        opprtunityBySource.statuses !== ""
      ) {
        GenerateReportbtn();
      }
    }
  }, [
    opprtunityBySource.propertyID,
    opprtunityBySource.userId,
    opprtunityBySource.statuses,
  ]);

  return (
    <div className="all-reports-main ToolsDisplacementCalcMain">
      <Container fluid>
        <Row>
          <Col xl={12}>
            <div className="ProspectDashboardTitleWraper  d-flex justify-content-between align-items-center marginBottom20">
              <h2 className="d-flex align-items-center gap-1">
                <Link to="/crm/dashboard">Dashboard - </Link>{" "}
                <span className="tab-title-main">{title}</span>
              </h2>
              <div className="heading-menu-btns">
                <img
                  src={filterIcon}
                  alt="filterIcon"
                  title="Filter Report"
                  className="cursorPointer report-filter"
                  onClick={handleshowAllReport}
                />
              </div>
            </div>
          </Col>
        </Row>

        {showAllReport && (
          <div className="displacementCalcWrapper width400">
            <div className="from-groups no-border-break mb-2 small-height-select">
              <Form.Label>Select Property</Form.Label>
              {/* <Form.Select
              aria-label="Default select example"
              value={opprtunityBySource?.propertyID}
              onChange={(e) => {
                handlePropertySelectionFc(e);
              }}
              // onChange={(e) => {
              //   setopprtunityBySource(
              //     {
              //       ...opprtunityBySource,
              //       propertyID: e.target.value,
              //     },

              //     getAccountRuleList(e.target.value)
              //   );
              // }}
            >
              <option value={0}>Select Property</option>
              {hotelList?.map((item, index) => (
                <option key={index} value={item?.propertyid}>
                  {item?.propertyname}
                </option>
              ))}
            </Form.Select> */}
              <Select
                isMulti
                value={propertyValue}
                options={selectProperty}
                onChange={(e) => {
                  handlePropertySelectionFc(e);
                }}
              />
              {opprtunityBySource?.errorObj?.propertyID &&
                opprtunityBySource?.propertyID === "" && (
                  <small className="text-danger">
                    {opprtunityBySource?.errorObj?.propertyID}
                  </small>
                )}
            </div>

            <div className="from-groups no-border-break mb-2 small-height-select">
              <Form.Label>Select User</Form.Label>
              <Select
                isMulti
                value={UserValue}
                options={SelectUser}
                onChange={(e) => {
                  HandleUserSelection(e);
                }}
              />
              {opprtunityBySource?.errorObj?.userId &&
                opprtunityBySource?.userId === "" && (
                  <small className="text-danger">
                    {opprtunityBySource?.errorObj?.userId}
                  </small>
                )}
            </div>

            <div className="from-groups no-border-break mb-2 small-height-select">
              <Form.Label>Select Opportunity Source</Form.Label>
              <Select
                isMulti
                value={selectSourceTerm}
                options={termList}
                onChange={(e) => {
                  handleStatusSelection(e);
                }}
              />
              {opprtunityBySource?.errorObj?.sources &&
                opprtunityBySource?.sources === "" && (
                  <small className="text-danger">
                    {opprtunityBySource?.errorObj?.sources}
                  </small>
                )}
            </div>

            {/* <div className="startFrom from-groups no-border-break mb-2">
              <Form.Label htmlFor="startDate">Start Date</Form.Label>

              <DatePicker
                id="startDate"
                selected={
                  opprtunityBySource.startDate ? new Date(opprtunityBySource.startDate) : ""
                }
                selectsStart
                // startDate={opprtunityBySource.startdate}
                // endDate={opprtunityBySource.enddate}
                onChange={(date) => {
                  setopprtunityBySource({
                    ...opprtunityBySource,
                    startDate: date,
                  });
                }}
                placeholderText="MM/DD/YYYY"
              />
              {opprtunityBySource?.errorObj?.startDate &&
                opprtunityBySource?.startDate === "" && (
                  <small className="text-danger">
                    {opprtunityBySource?.errorObj?.startDate}
                  </small>
                )}
            </div>

            <div className="endDate from-groups no-border-break mb-2">
              <Form.Label htmlFor="endDate">End Date</Form.Label>

              <DatePicker
                id="endDate"
                selected={opprtunityBySource.endDate ? new Date(opprtunityBySource.endDate) : ""}
                disabled={opprtunityBySource.startDate ? false : true}
                minDate={opprtunityBySource.startDate}
                selectsEnd
                onChange={(date) => {
                  setopprtunityBySource({
                    ...opprtunityBySource,
                    endDate: date,
                  });
                }}
                placeholderText="MM/DD/YYYY"
              />
              {opprtunityBySource?.errorObj?.endDate && opprtunityBySource?.endDate === "" && (
                <small className="text-danger">
                  {opprtunityBySource?.errorObj?.endDate}
                </small>
              )}
            </div> */}
            <div className="startFrom from-groups no-border-break mb-4 meghaDatePicker">
              <Form.Label htmlFor="startDate">Date Range</Form.Label>

              <DatePicker
                onChange={handleDateChange}
                startDate={opprtunityBySource.startDate}
                endDate={opprtunityBySource.endDate}
                selectsRange
                monthsShown={2}
              />
            </div>
            <div className="d-flex gap-3 align-items-center justify-content-end ">
              {loading ? (
                <button
                  className="default-btn primary-btn d-flex gap-1 align-items-center"
                  type="button"
                  disabled
                >
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Loading...
                </button>
              ) : (
                <button
                  className="default-btn primary-btn d-flex gap-1 align-items-center"
                  onClick={() => {
                    GenerateReportbtn();
                  }}
                >
                  <span className="revpak-icon">
                    <img src={pdfIcon} alt="" />
                  </span>
                  <span>Generate Report</span>
                </button>
              )}
            </div>
          </div>
        )}
        <p className="text-center">{errorMessage && " No Record Found"}</p>
        {loading && <Loader />}
        <div className="pdf-is-showing-here mt-3">
          {showPdf && setPdfRef.current && (
            <iframe
              onLoad={iframeloader}
              title="Report Doc"
              src={setPdfRef.current}
              width="100%"
              height="800px"
            />
          )}
        </div>
        {/* {loading ? (
          <Loader />
        ) : (
          <div className="pdf-is-showing-here mt-3">
            {showPdf && setPdfRef.current && (
              <iframe
                title="Report Doc"
                src={setPdfRef.current}
                width="100%"
                height="800px"
              />
            )}
          </div>
        )} */}
      </Container>
    </div>
  );
};

export default OpportunityBySourceReport;
