import React, { useEffect, useRef, useState } from "react";
import { Row, Form, Col, Button, Container } from "react-bootstrap";
import pdfIcon from "../../../Assets/Images/CRM/pdf.svg";
import { commonService } from "../../../utils/commonService";
import { API_URL } from "../../../utils/apiRoutes";
import { validationRule } from "../../../utils/constant";
import { hotelListCommon } from "../Hotels/HotelListCommon";
import { AccountListCommon } from "../Contacts/AccountListCommon";
import { checkValidation } from "../../../utils/helper";
import { apiCall } from "../../../utils/axiosService";
import { apiResponse } from "../../../utils/apiResponse";
import { successToastMessage } from "../../../utils/toastMessage";
import Select from "react-select";
import filterIcon from "../../../Assets/Images/CRM/filter.png";
import { useSelector } from "react-redux";
import Loader from "../../Loader/Loader";
import { Link } from "react-router-dom";

const MarketingPlanReport = ({ title }) => {
  const [loading, setLoading] = useState(false);
  const [hotelList, setHotelList] = useState(null);
  const userDetails = commonService.getLoginUserData();
  const [errorMessage, setErrorMessage] = useState(false);
  const yearsArr = [
    ...commonService.getPastYearForDropDown(10),
    ...commonService.getFutureYearForDropDown(10),
    new Date().getFullYear(),
  ]?.sort(function (a, b) {
    return a - b;
  });

  const crmPropertyDetailFromRTK = useSelector(
    (store) => store.headerProperty.crmPropertyDetail
  );

  let propertyIdPayload = "";
  if (crmPropertyDetailFromRTK) {
    if (crmPropertyDetailFromRTK?.propertyid === "") {
      let propertyPayload = crmPropertyDetailFromRTK?.allPropertyId;
      propertyIdPayload = propertyPayload.slice(0, -1);
    } else {
      propertyIdPayload = crmPropertyDetailFromRTK?.propertyid?.toString();
    }
  }

  let validationObj = {
    clientid: userDetails?.clientid ? userDetails?.clientid : 0,
    propertyid: "",
    year: new Date().getFullYear(),
    prevyear: "",
    isincludemarketsegment: false,

    validationRule: [
      {
        fieldName: "propertyid",
        rule: validationRule.required,
        message: "Property Id required",
      },
      {
        fieldName: "year",
        rule: validationRule.required,
        message: "Year is required",
      },
    ],
    errorObj: {},
    isValidate: true,
  };
  const [marketingPlanData, setMarketingPlanData] = useState(validationObj);
  const setPdfRef = useRef("");
  const [showPdf, setShowPdf] = useState(false);
  const getHotelList = async () => {
    let response = await hotelListCommon(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.CRM +
          API_URL.PROPERTY_TERM.GET_PROPERTY_LIST_BY_USER_ID,
        body: {
          id: userDetails?.userid,
        },
      },
      false
    );

    if (response?.isValidate) {
      setHotelList(response?.data);

      if (crmPropertyDetailFromRTK?.propertyid !== "") {
        setMarketingPlanData({
          ...marketingPlanData,
          propertyid: crmPropertyDetailFromRTK?.propertyid,
        });
      }
    }
  };
  const [showAllReport, setshowAllReport] = useState(false);
  const isDefaultApiCalledRef = useRef(false);
  const handleshowAllReport = () => {
    setshowAllReport(!showAllReport);
  };

  // const Exportpdf = (url) => {
  //   return (
  //     <a
  //       target="_blank"
  //       href={API_URL.BASE_API_URL.CRM_REPORT_PDF_BASEPATH / url.data}
  //     />
  //   );
  // };

  const iframeloader = () => {
    setLoading(false);
  };
  const GenerateReportbtn = async () => {
    setShowPdf(false);
    setLoading(true);
    let validation = checkValidation(marketingPlanData);

    if (!validation.isValidate) {
      setMarketingPlanData(validation);
      setLoading(false);
      return;
    }

    let marketingPlan = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.CRM + API_URL.CRM_REPORT.MARKETING_PLAN_REPORT,
        body: {
          propertyid: parseInt(marketingPlanData.propertyid),
          clientid: parseInt(marketingPlanData.clientid),
          year: marketingPlanData.year.toString(),
          prevyear: (marketingPlanData.year - 1).toString(),
          isincludemarketsegment: marketingPlanData.isincludemarketsegment,
        },
      },
      false
    );

    let response = apiResponse(false, marketingPlan);

    if (response?.isValidate) {
      isDefaultApiCalledRef.current = true;
      // setLoading(false);
      // Exportpdf(response.data);
      setErrorMessage(false);
      setshowAllReport(false);
      // successToastMessage(response?.message);
      setPdfRef.current =
        process.env.REACT_APP_APIBASEPATH_CRM_REPORT_PDF + response.data.data;
      setShowPdf(true);
    }

    if (!response?.isValidate) {
      isDefaultApiCalledRef.current = true;
      setshowAllReport(true);
      setLoading(false);
      setErrorMessage(true);
    }
  };

  useEffect(() => {
    if (crmPropertyDetailFromRTK?.propertyid === "") {
      isDefaultApiCalledRef.current = true;
      setshowAllReport(true);
    }
    getHotelList();
  }, []);

  useEffect(() => {
    if (marketingPlanData.propertyid !== "" && !isDefaultApiCalledRef.current) {
      GenerateReportbtn();
    }
  }, [marketingPlanData.propertyid]);
  const [startRangeDate, setStartRangeDate] = useState(new Date());
  const [endRangeDate, setEndRangeDate] = useState(new Date());

  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setStartRangeDate(start);
    setEndRangeDate(end);
  };
  return (
    <div className="all-reports-main ToolsDisplacementCalcMain">
      <Container fluid>
        <Row>
          <Col xl={12}>
            <div className="ProspectDashboardTitleWraper  d-flex justify-content-between align-items-center marginBottom20">
              <h2 className="d-flex align-items-center gap-1">
                <Link to="/crm/dashboard">Dashboard - </Link>{" "}
                <span className="tab-title-main">{title}</span>
              </h2>
              <div className="heading-menu-btns">
                <img
                  src={filterIcon}
                  alt="filterIcon"
                  title="Filter Report"
                  className="cursorPointer report-filter"
                  onClick={handleshowAllReport}
                />
              </div>
            </div>
          </Col>
        </Row>

        {showAllReport && (
          <div className="displacementCalcWrapper width400">
            <div className="from-groups no-border-break mb-3 small-height-select">
              <Form.Label>Select Property</Form.Label>
              <Form.Select
                aria-label="Default select example"
                value={marketingPlanData?.propertyid}
                onChange={(e) => {
                  setMarketingPlanData({
                    ...marketingPlanData,
                    propertyid: e.target.value,
                  });
                }}
              >
                <option value="">Select Property</option>
                {hotelList?.map((item, index) => (
                  <option key={index} value={item?.propertyid}>
                    {item?.propertyname}
                  </option>
                ))}
              </Form.Select>

              {marketingPlanData?.errorObj?.propertyid &&
                marketingPlanData?.propertyid === "" && (
                  <small className="text-danger">
                    {marketingPlanData?.errorObj?.propertyid}
                  </small>
                )}
            </div>
            <div className="form-check  mb-2">
              <input
                type="checkbox"
                className="form-check-input"
                name="isincludemarketsegment"
                checked={marketingPlanData?.isincludemarketsegment}
                onChange={(e) =>
                  setMarketingPlanData({
                    ...marketingPlanData,
                    isincludemarketsegment: e.target.checked,
                  })
                }
                id="Option7"
              />
              <Form.Check.Label htmlFor="Option7" className="label-radio">
                Include Market Segment Details
              </Form.Check.Label>
            </div>

            <div className="startFrom from-groups no-border-break mb-4">
              <Form.Label htmlFor="startDate">Year</Form.Label>
              <Form.Select
                value={marketingPlanData.year}
                aria-label="Default select example"
                className="blue-colored-select"
                onChange={(e) => {
                  setMarketingPlanData({
                    ...marketingPlanData,
                    year: e.target.value,
                  });
                }}
              >
                {yearsArr?.map((item, index) => (
                  <option key={index} value={item}>
                    {item}
                  </option>
                ))}
              </Form.Select>
              {marketingPlanData?.errorObj?.year &&
                marketingPlanData?.year === "" && (
                  <small className="text-danger">
                    {marketingPlanData?.errorObj?.year}
                  </small>
                )}
            </div>

            <div className="d-flex align-items-center justify-content-end gap-3">
              {loading ? (
                <button
                  className="default-btn primary-btn"
                  type="button"
                  disabled
                >
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Loading...
                </button>
              ) : (
                <button
                  className="default-btn primary-btn d-flex gap-1 align-items-center"
                  onClick={() => {
                    GenerateReportbtn();
                  }}
                >
                  <span className="revpak-icon">
                    <img src={pdfIcon} alt="" />
                  </span>
                  <span>Generate Report</span>
                </button>
              )}
            </div>
          </div>
        )}
        <p className="text-center">{errorMessage && " No Record Found"}</p>
        {loading && <Loader />}
        <div className="pdf-is-showing-here mt-3">
          {showPdf && setPdfRef.current && (
            <iframe
              onLoad={iframeloader}
              title="Report Doc"
              src={setPdfRef.current}
              width="100%"
              height="800px"
            />
          )}
        </div>
        {/* {loading ? (
          <Loader />
        ) : (
          <div className="pdf-is-showing-here mt-3">
            {showPdf && setPdfRef.current && (
              <iframe
                title="Report Doc"
                src={setPdfRef.current}
                width="100%"
                height="800px"
              />
            )}
          </div>
        )} */}
      </Container>
    </div>
  );
};

export default MarketingPlanReport;
