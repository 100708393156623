import React, { useState } from "react";
import { useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import { apiCall } from "../../../../utils/axiosService";
import { API_URL } from "../../../../utils/apiRoutes";
import { apiResponse } from "../../../../utils/apiResponse";
import { Button, Col, Container, Form, Row, Table } from "react-bootstrap";
import { commonService } from "../../../../utils/commonService";
import Loader from "../../../Loader/Loader";
import { checkValidation } from "../../../../utils/helper";
import { validationRule } from "../../../../utils/constant";

const DisplacementCalculator = ({
  setCompDataFromChild,
  rowIndex,
  colIndex,
  api,
  para,
  setting,
}) => {
  const propertyDetailFromRTK = useSelector(
    (store) => store.headerProperty.propertyDetail
  );
  const date = new Date();
  const startDate = commonService.getDateInDBFormat(
    new Date(date.getFullYear(), date.getMonth(), 1)
  );
  const endDate = commonService.getDateInDBFormat(
    new Date(date.getFullYear(), date.getMonth() + 1, 0)
  );
  let validationObj = {
    checkIn: "",
    checkOut: "",
    requestedRooms: "",
    validationRule: [
      {
        fieldName: "checkIn",
        rule: validationRule.required,
        message: "CheckIn is required",
      },
      {
        fieldName: "checkOut",
        rule: validationRule.required,
        message: "CheckOut is required",
      },
      {
        fieldName: "requestedRooms",
        rule: validationRule.required,
        message: "Requested Rooms is required",
      },
    ],
    errorObj: {},
    isValidate: true,
  };
  const [filter, setFilter] = useState(validationObj);
  const [displacementData, setDisplacementData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({
    checkIn: "",
    checkOut: "",
    room: "",
  });

  let requestedRoom = 0,
    roomBooked = 0,
    roomsAva = 0,
    forecastRoomsold = 0,
    forecastAdr = 0,
    roomDisplaced = 0,
    revDisplaced = 0,
    recMinRate = 0,
    occupancy = 0,
    barRate = 0;

  const getDisplacementData = async () => {
    setLoading(true);
    let displacementDataResponse = await apiCall(
      {
        method: "POST",
        url: API_URL.BASE_API_URL.SnapShot + API_URL.DISPLACEMENT.GET_LIST,
        body: {
          AsOfDate: para?.asOfDate,
          startDate: commonService.getDateForNode(filter.checkIn),
          endDate: commonService.getDateForNode(filter.checkOut),
          propertyCode: propertyDetailFromRTK?.propertycode,
        },
      },
      false
    );

    let response = apiResponse(false, displacementDataResponse);

    if (response?.isValidate) {
      let dataFromApi = response?.data?.data;
      calculateTableData(
        dataFromApi,
        filter?.requestedRooms ? filter?.requestedRooms : 0
      );
      setLoading(false);
    }

    if (!response?.isValidate) {
      setLoading(false);
    }
  };

  const calculateTableData = (data, requestedRooms) => {
    let groupingJson = data?.map((item) => {
      let roomDisplaced =
        parseInt(requestedRooms) -
        ((item?.["LEFT TO SELL"] ? parseInt(item?.["LEFT TO SELL"]) : 0) -
          (item?.["Forecasted ROOM"]
            ? parseInt(item?.["Forecasted ROOM"])
            : 0));
      let revDisplaced =
        roomDisplaced *
        (item?.["Forecasted ADR"] ? parseInt(item?.["Forecasted ADR"]) : 0);
      let recMinRate = "";
      if (revDisplaced && requestedRooms) {
        recMinRate = revDisplaced / requestedRooms;
      }

      item.requestedRooms = requestedRooms;
      item.roomDisplaced = roomDisplaced;
      item.revDisplaced = revDisplaced;
      item.recMinRate = recMinRate;
      return item;
    });
    setDisplacementData(groupingJson);
  };

  const handleRequestedRoomChange = (index, e) => {
    let newData = [...displacementData];

    let requestedRooms = e.target.value;
    let roomDisplaced =
      parseInt(requestedRooms) -
      ((newData[index]?.["LEFT TO SELL"]
        ? parseInt(newData[index]?.["LEFT TO SELL"])
        : 0) -
        (newData[index]?.["Forecasted ROOM"]
          ? parseInt(newData[index]?.["Forecasted ROOM"])
          : 0));
    let revDisplaced =
      roomDisplaced *
      (newData[index]?.["Forecasted ADR"]
        ? parseInt(newData[index]?.["Forecasted ADR"])
        : 0);
    let recMinRate = revDisplaced / requestedRooms;

    newData[index].requestedRooms = requestedRooms;
    newData[index].roomDisplaced = roomDisplaced;
    newData[index].revDisplaced = revDisplaced;
    newData[index].recMinRate = recMinRate;

    setDisplacementData(newData);
  };

  const handleCalculate = () => {
    if (api !== 0) {
      let validation = checkValidation(filter);

      if (!validation.isValidate) {
        setFilter(validation);
        return;
      }

      getDisplacementData();
    }
  };

  return (
    <div className="RMRateShopMain">
      {propertyDetailFromRTK ? (
        <Container fluid>
          <Row>
            <Col xl={12}>
              <div className="ProspectDashboardTitleWraper  d-flex justify-content-between align-items-center">
                <h2>
                  Dashboard-{" "}
                  <span className="tab-title-main">
                    Revenue Management - Tools - Displacement Calculator
                  </span>
                </h2>
              </div>
            </Col>
            {/* <Col xl={9} className="mt-3"> */}
            <div className="d-flex align-items-start gap-4 conditional-class mt-3">
              <div className="width200">
                <div className="from-groups no-border-break">
                  <Form.Label>Check In</Form.Label>
                  <DatePicker
                    minDate={new Date()}
                    selected={filter.checkIn}
                    onChange={(date) => {
                      let selectedDate = new Date(date);
                      selectedDate.setDate(selectedDate.getDate() + 1);
                      setFilter({
                        ...filter,
                        checkIn: date,
                        checkOut: selectedDate,
                      });
                    }}
                  />
                  {filter?.errorObj?.checkIn && (
                    <small className="text-danger">
                      {filter?.errorObj?.checkIn}
                    </small>
                  )}
                </div>
              </div>
              <div className="width200">
                <div className="from-groups no-border-break">
                  <Form.Label>Check Out</Form.Label>
                  <DatePicker
                    minDate={filter.checkIn}
                    selected={filter.checkOut}
                    onChange={(date) => {
                      setFilter({
                        ...filter,
                        checkOut: date,
                      });
                    }}
                  />
                  {filter?.errorObj?.checkOut && (
                    <small className="text-danger">
                      {filter?.errorObj?.checkOut}
                    </small>
                  )}
                </div>
              </div>
              <div className="width200">
                <div className="from-groups no-border-break">
                  <Form.Label>Requested Rooms</Form.Label>
                  <Form.Control
                    type="text"
                    maxLength={16}
                    value={filter.requestedRooms}
                    pattern="[0-9]*"
                    onChange={(e) => {
                      if (e.target.validity.valid) {
                        setFilter({
                          ...filter,
                          requestedRooms: e.target.value,
                        });
                        calculateTableData(
                          displacementData,
                          e.target.value ? e.target.value : 0
                        );
                      }
                    }}
                  />
                  {filter?.errorObj?.requestedRooms && (
                    <small className="text-danger">
                      {filter?.errorObj?.requestedRooms}
                    </small>
                  )}
                </div>
              </div>
              <div className="marign-condition-btn">
                <Button
                  className="default-btn primary-btn gobtnwidth"
                  onClick={() => {
                    handleCalculate();
                  }}
                >
                  Calculate
                </Button>
              </div>
            </div>
            {/* </Col> */}
            <Col xl={12}>
              <div className="RateShopTableViewTable mt-3">
                <div className="ProspectDashboardTitleWraper">
                  <h2>
                    <span className="tab-title-main">
                      Displacement Day By Day
                    </span>
                  </h2>
                </div>
                <div className="table__container mt-2">
                  <div className="stripped__table">
                    <div className="table-responsive tblDynamicPickupSticky">
                      <Table>
                        <thead className="tbl-header-sticky">
                          <tr>
                            <th>Date</th>
                            {/* <th>Requested Rooms</th> */}
                            <th className="text-center">no. of rooms booked</th>
                            <th className="text-center">Effective Capacity</th>
                            <th className="text-center">Forecast Rooms Sold</th>
                            <th className="text-end">Forecasted ADR</th>
                            <th className="text-center">Rooms Displaced</th>
                            <th className="text-end">REV Displaced</th>
                            <th className="text-end">REC Min Rate</th>
                            <th className="text-center">Occupancy</th>
                            <th className="text-end">Bar Rate</th>
                          </tr>
                        </thead>
                        <tbody>
                          {loading ? (
                            <tr
                              style={{ height: "80px", position: "relative" }}
                            >
                              <Loader />
                            </tr>
                          ) : displacementData ? (
                            displacementData?.map((item, index) => {
                              roomBooked =
                                roomBooked +
                                (item?.OTB ? parseInt(item?.OTB) : 0);
                              roomsAva =
                                roomsAva +
                                (item?.["LEFT TO SELL"]
                                  ? parseInt(item?.["LEFT TO SELL"])
                                  : 0);
                              forecastRoomsold =
                                forecastRoomsold +
                                (item?.["Forecasted ROOM"]
                                  ? parseInt(item?.["Forecasted ROOM"])
                                  : 0);
                              forecastAdr =
                                forecastAdr +
                                (item?.["Forecasted ADR"]
                                  ? parseInt(item?.["Forecasted ADR"])
                                  : 0);
                              roomDisplaced =
                                roomDisplaced +
                                (item?.roomDisplaced
                                  ? parseInt(item?.roomDisplaced)
                                  : 0);
                              revDisplaced =
                                revDisplaced +
                                (item?.revDisplaced
                                  ? parseInt(item?.revDisplaced)
                                  : 0);
                              recMinRate =
                                recMinRate +
                                (item?.recMinRate
                                  ? parseInt(item?.recMinRate)
                                  : 0);
                              occupancy =
                                occupancy +
                                (item?.OCC ? parseInt(item?.OCC) : 0);
                              barRate =
                                barRate +
                                (item?.["Bar Rate"]
                                  ? parseInt(item?.["Bar Rate"])
                                  : 0);
                              return (
                                <>
                                  <tr
                                    key={item?.Dates}
                                    className={`shadowOnHover ${
                                      item?.roomDisplaced > 0
                                        ? "backgroundTrRed"
                                        : ""
                                    }`}
                                  >
                                    <td>
                                      {commonService.getDateInFormat(
                                        item?.Dates
                                      )}
                                    </td>
                                    <td className="text-center">
                                      {item?.OTB
                                        ? commonService.formateRoom(
                                            Math.round(item?.OTB)
                                          )
                                        : 0}
                                    </td>
                                    <td className="text-center">
                                      {item?.["LEFT TO SELL"]
                                        ? commonService.formateRoom(
                                            Math.round(item?.["LEFT TO SELL"])
                                          )
                                        : 0}
                                    </td>
                                    <td className="text-center">
                                      {item?.["Forecasted ROOM"]
                                        ? commonService.formateRoom(
                                            Math.round(
                                              item?.["Forecasted ROOM"]
                                            )
                                          )
                                        : 0}
                                    </td>
                                    <td className="text-end">
                                      {item?.["Forecasted ADR"]
                                        ? commonService.formateAmount(
                                            Math.round(item?.["Forecasted ADR"])
                                          )
                                        : commonService.formateAmount(0)}
                                    </td>
                                    <td className="text-center">
                                      {item?.roomDisplaced
                                        ? commonService.formateRoom(
                                            Math.round(item?.roomDisplaced)
                                          )
                                        : 0}
                                    </td>
                                    <td className="text-end">
                                      {item?.revDisplaced
                                        ? commonService.formateAmount(
                                            Math.round(item?.revDisplaced)
                                          )
                                        : commonService.formateAmount(0)}
                                    </td>
                                    <td className="text-end">
                                      {item?.recMinRate
                                        ? commonService.formateAmount(
                                            Math.round(item?.recMinRate)
                                          )
                                        : commonService.formateAmount(0)}
                                    </td>
                                    <td className="text-center">
                                      {item?.OCC ? Math.round(item?.OCC) : 0}%
                                    </td>
                                    <td className="text-end">
                                      {item?.["Bar Rate"]
                                        ? commonService.formateAmount(
                                            Math.round(item?.["Bar Rate"])
                                          )
                                        : commonService.formateAmount(0)}
                                    </td>
                                  </tr>
                                  {index === displacementData?.length - 1 && (
                                    <tr className="shadowOnHover">
                                      <td className="boldTd">Total</td>
                                      {/* <td className="boldTd">{requestedRoom}</td> */}
                                      <td className="boldTd text-center">
                                        {commonService.formateRoom(roomBooked)}
                                      </td>
                                      <td className="boldTd text-center">
                                        {commonService.formateRoom(roomsAva)}
                                      </td>
                                      <td className="boldTd text-center">
                                        {commonService.formateRoom(
                                          forecastRoomsold
                                        )}
                                      </td>
                                      <td className="boldTd text-end">
                                        {commonService.formateAmount(
                                          Math.round(
                                            forecastAdr /
                                              displacementData?.length
                                          )
                                        )}
                                      </td>
                                      <td className="boldTd text-center">
                                        {commonService.formateRoom(
                                          roomDisplaced
                                        )}
                                      </td>
                                      <td className="boldTd text-end">
                                        {commonService.formateAmount(
                                          revDisplaced
                                        )}
                                      </td>
                                      <td className="boldTd text-end">
                                        {commonService.formateAmount(
                                          recMinRate
                                        )}
                                      </td>
                                      <td className="boldTd text-center">
                                        {Math.round(
                                          occupancy / displacementData?.length
                                        )}
                                        %
                                      </td>
                                      <td className="boldTd text-end">
                                        {commonService.formateAmount(
                                          Math.round(
                                            barRate / displacementData?.length
                                          )
                                        )}
                                      </td>
                                    </tr>
                                  )}
                                </>
                              );
                            })
                          ) : (
                            <tr>
                              <td colSpan={10} className="text-center">
                                No Data Found
                              </td>
                            </tr>
                          )}
                          <tr></tr>
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      ) : (
        <Container>
          <div className="d-flex justify-content-center">
            <h5>Please Select Property</h5>
          </div>
        </Container>
      )}
    </div>
  );
};

export default DisplacementCalculator;
