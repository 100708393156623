import React, { useEffect, useRef, useState } from "react";
import { Row, Form, Col, Button, Container } from "react-bootstrap";
import pdfIcon from "../../../Assets/Images/CRM/pdf.svg";
import { commonService } from "../../../utils/commonService";
import DatePicker from "react-datepicker";
import { API_URL } from "../../../utils/apiRoutes";
import { validationRule } from "../../../utils/constant";
import { hotelListCommon } from "../Hotels/HotelListCommon";
import { AccountListCommon } from "../Contacts/AccountListCommon";
import { checkValidation } from "../../../utils/helper";
import { apiCall } from "../../../utils/axiosService";
import { apiResponse } from "../../../utils/apiResponse";
import { successToastMessage } from "../../../utils/toastMessage";
import Select from "react-select";
import filterIcon from "../../../Assets/Images/CRM/filter.png";
import { useSelector } from "react-redux";
import Loader from "../../Loader/Loader";
import { Link } from "react-router-dom";

const TraceReport = ({ title }) => {
  const [selectProperty, setSelectProperty] = useState([]);
  const [propertyValue, setpropertyValue] = useState([]);
  const propertyIdList = useRef("");
  const useridList = useRef("");
  const isDefaultApiCalledRef = useRef(false);
  const [selectUser, setSelectuser] = useState([]);
  const [userValue, setUserValue] = useState([]);
  const [loading, setLoading] = useState(false);
  const setPdfRef = useRef("");
  const [showPdf, setShowPdf] = useState(false);
  const [showAllReport, setshowAllReport] = useState(false);
  const userDetails = commonService.getLoginUserData();
  let startDate = new Date();
  let endDate = new Date(startDate.getTime() - 7 * 24 * 60 * 60 * 1000);
  const [errorMessage, setErrorMessage] = useState(false);
  const crmPropertyDetailFromRTK = useSelector(
    (store) => store.headerProperty.crmPropertyDetail
  );

  let propertyIdPayload = "";
  if (crmPropertyDetailFromRTK) {
    if (crmPropertyDetailFromRTK?.propertyid === "") {
      let propertyPayload = crmPropertyDetailFromRTK?.allPropertyId;
      propertyIdPayload = propertyPayload.slice(0, -1);
    } else {
      propertyIdPayload = crmPropertyDetailFromRTK?.propertyid?.toString();
    }
  }

  let validationObj = {
    clientid: userDetails?.clientid ? userDetails?.clientid : 0,
    propertyID: "",
    userID: "",
    startDate: endDate,
    endDate: startDate,

    validationRule: [
      {
        fieldName: "propertyID",
        rule: validationRule.required,
        message: "Property Id required",
      },
      {
        fieldName: "userID",
        rule: validationRule.required,
        message: "User selection required",
      },
      {
        fieldName: "startDate",
        rule: validationRule.required,
        message: "Start Date is required",
      },
      {
        fieldName: "endDate",
        rule: validationRule.required,
        message: "End Date is required",
      },
    ],
    errorObj: {},
    isValidate: true,
  };
  const [traceData, setTraceData] = useState(validationObj);
  const traceDataRef = useRef(traceData);

  const handleshowAllReport = () => {
    setshowAllReport(!showAllReport);
  };

  const handleDateChange = (dates) => {
    const [start, end] = dates;
    traceDataRef.current.startDate = start;
    traceDataRef.current.endDate = end;
    setTraceData({
      ...traceData,
      startDate: start,
      endDate: end,
    });
  };

  //get property List
  const getHotelList = async () => {
    let response = await hotelListCommon(
      {
        method: "POST",
        url:
        API_URL.BASE_API_URL.CRM +
        API_URL.PROPERTY_TERM.GET_PROPERTY_LIST_BY_USER_ID,
      body: {
        id: userDetails?.userid,
      },
      },
      false
    );

    if (response?.isValidate) {
      let propertyListarry = [];
      response?.data?.map((item) => {
        propertyListarry = [
          ...propertyListarry,
          {
            label: item?.propertyname,
            value: item?.propertyid,
          },
        ];
      });
      setSelectProperty(propertyListarry);

      if (crmPropertyDetailFromRTK?.propertyid != "") {
        setpropertyValue([
          {
            label: crmPropertyDetailFromRTK?.propertyname,
            value: crmPropertyDetailFromRTK?.propertyid,
          },
        ]);

        propertyIdList.current = `${crmPropertyDetailFromRTK?.propertyid}`;
        traceDataRef.current.propertyID = propertyIdList.current;
        setTraceData(traceDataRef.current);
      }
    }
  };

  //get User By PropertyList
  const getUserByPropertyList = async (propertyId) => {
    // if (propertyId !== 0) {
    let accountResponse = await AccountListCommon(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.UserManagement +
          API_URL.USER_ROLE_PROPERTY.GET_USER_LIST_BY_MODULE_MULTI_PROPERTY,
        body: {
          propertyid: traceData.propertyID,
          modulename: "crm",
        },
      },
      false
    );

    if (accountResponse?.isValidate) {
      let userDropList = [];
      let userId = [];
      accountResponse?.data?.map((item) => {
        userDropList = [
          ...userDropList,
          {
            label: item?.displayname,
            value: item?.userid,
          },
        ];
        userId = [...userId, item?.userid];
      });
      setSelectuser(userDropList);
      setUserValue(userDropList);
      useridList.current = userId?.join(",");
      traceDataRef.current.userID = useridList.current;
      setTraceData(traceDataRef.current);
    }
  };

  //  select Property
  const handlePropertySelection = (e) => {
    if (e?.length <= 0) {
      setpropertyValue([]);
      setUserValue([]);
      traceDataRef.current.userID = "";
      traceDataRef.current.propertyID = "";
      setTraceData(traceDataRef.current);
      return;
    }
    setpropertyValue(e);
    let propertyList = [];
    e?.map((item) => {
      propertyList = [...propertyList, item?.value?.toString()];
    });
    propertyIdList.current = propertyList?.join(",");
    traceDataRef.current.propertyID = propertyIdList.current;
    setTraceData({
      ...traceData,
      propertyID: propertyIdList.current,
    });
  };
  // select  user
  const HandleUserSelection = (e) => {
    if (e?.length <= 0) {
      setUserValue([]);
      traceDataRef.current.userID = "";
      setTraceData(traceDataRef.current);
      return;
    }
    setUserValue(e);
    let userListItm = [];
    e?.map((item) => {
      userListItm = [...userListItm, item?.value?.toString()];
    });
    useridList.current = userListItm?.join(",");
    useridList.current.userID = useridList.current;
    setTraceData({
      ...traceData,
      userID: useridList.current,
    });
  };
  // const handleDateChange = (dates) => {
  //   const [start, end] = dates;
  //   setTraceData({
  //     ...traceData,
  //     startDate: start,
  //     endDate: end,
  //   });
  // };

  const iframeloader = () => {
    setLoading(false)
  };
  const GenerateReportbtn = async () => {
    setShowPdf(false);
    setLoading(true);
    let validation = checkValidation(traceData);

    if (!validation.isValidate) {
      setTraceData(validation);
      setLoading(false);
      return;
    }

    let traceReport = await apiCall(
      {
        method: "POST",
        url: API_URL.BASE_API_URL.CRM + API_URL.CRM_REPORT.TRACE_REPORT,
        body: {
          propertyID: traceData.propertyID,
          userID: traceData.userID.toString(),
          startDate: traceData.startDate,
          endDate: traceData.endDate,
        },
      },
      false
    );

    let response = apiResponse(false, traceReport);
    if (response?.isValidate) {
      isDefaultApiCalledRef.current = true;
      // setLoading(false);
      setshowAllReport(false);
      setErrorMessage(false);
      // successToastMessage(response?.message);
      setPdfRef.current =
        process.env.REACT_APP_APIBASEPATH_CRM_REPORT_PDF + response.data.data;
      setShowPdf(true);
    }
    if (!response?.isValidate) {
      isDefaultApiCalledRef.current = true;
      setshowAllReport(true);
      setLoading(false);
      setErrorMessage(true);
    }
  };

  useEffect(() => {
    if (crmPropertyDetailFromRTK?.propertyid === "") {
      isDefaultApiCalledRef.current = true;
      setshowAllReport(true);
    }
    getHotelList();
  }, []);

  useEffect(() => {
    if (traceData.propertyID !== "") {
      if (!isDefaultApiCalledRef.current && traceData.userID !== "") {
        GenerateReportbtn();
      }
    }
  }, [traceData.propertyID, traceData.userID]);

  useEffect(() => {
    if (traceData.propertyID !== "") {
      getUserByPropertyList();
    }
  }, [traceData.propertyID]);

  return (
    <div className="all-reports-main ToolsDisplacementCalcMain">
      <Container fluid>
        <Row>
          <Col xl={12}>
            <div className="ProspectDashboardTitleWraper  d-flex justify-content-between align-items-center marginBottom20">
              <h2 className="d-flex align-items-center gap-1">
                <Link to="/crm/dashboard">Dashboard - </Link>{" "}
                <span className="tab-title-main">{title}</span>
              </h2>
              <div className="heading-menu-btns">
                <img
                  src={filterIcon}
                  alt="filterIcon"
                  title="Filter Report"
                  className="cursorPointer report-filter"
                  onClick={handleshowAllReport}
                />
              </div>
            </div>
          </Col>
        </Row>

        {showAllReport && (
          <div className="displacementCalcWrapper width400">
            <div className="from-groups no-border-break mb-2 small-height-select">
              <Form.Label>Select Property</Form.Label>
              {/* <Form.Select
              aria-label="Default select example"
              value={traceData?.propertyID}
              onChange={(e) => {
                setTraceData(
                  {
                    ...traceData,
                    propertyID: e.target.value,
                  },

                  getUserByPropertyList(e.target.value)
                );
              }}
            >
              <option value={0}>Select Property</option>
              {hotelList?.map((item, index) => (
                <option key={index} value={item?.propertyid}>
                  {item?.propertyname}
                </option>
              ))}
            </Form.Select> */}
              <Select
                isMulti
                value={propertyValue}
                options={selectProperty}
                onChange={(e) => {
                  handlePropertySelection(e);
                }}
              />
              {traceData?.errorObj?.propertyID &&
                traceData?.propertyID === "" && (
                  <small className="text-danger">
                    {traceData?.errorObj?.propertyID}
                  </small>
                )}
            </div>
            <div className="from-groups no-border-break mb-2 small-height-select">
              <Form.Label>Select User</Form.Label>
              {/* <Form.Select
              aria-label="Default select example"
              value={traceData?.userID}
              onChange={(e) => {
                setTraceData({
                  ...traceData,
                  userID: e.target.value,
                });
              }}
            >
              <option value={0}>Select User</option>
              {accountList?.map((item, index) => (
                <option key={index} value={item?.userid}>
                  {item?.displayname}
                </option>
              ))}
            </Form.Select> */}
              <Select
                isMulti
                value={userValue}
                options={selectUser}
                onChange={(e) => {
                  HandleUserSelection(e);
                }}
              />
              {traceData?.errorObj?.userID && traceData?.userID === "" && (
                <small className="text-danger">
                  {traceData?.errorObj?.userID}
                </small>
              )}
            </div>
            {/* <div className="startFrom from-groups no-border-break mb-3">
              <Form.Label htmlFor="startDate">Start Date</Form.Label>

              <DatePicker
                id="startDate"
                selected={
                  traceData.startDate ? new Date(traceData.startDate) : ""
                }
                selectsStart
                onChange={(date) => {
                  setTraceData({
                    ...traceData,
                    startDate: date,
                  });
                }}
                placeholderText="MM/DD/YYYY"
              />
              {traceData?.errorObj?.startDate &&
                traceData?.startDate === "" && (
                  <small className="text-danger">
                    {traceData?.errorObj?.startDate}
                  </small>
                )}
            </div>
            <div className="endDate from-groups no-border-break mb-4">
              <Form.Label htmlFor="endDate">End Date</Form.Label>

              <DatePicker
                id="endDate"
                selected={traceData.endDate ? new Date(traceData.endDate) : ""}
                disabled={traceData.startDate ? false : true}
                minDate={traceData.startDate}
                selectsEnd
                onChange={(date) => {
                  setTraceData({
                    ...traceData,
                    endDate: date,
                  });
                }}
                placeholderText="MM/DD/YYYY"
              />
              {traceData?.errorObj?.endDate && traceData?.endDate === "" && (
                <small className="text-danger">
                  {traceData?.errorObj?.endDate}
                </small>
              )}
            </div> */}
            <div className="startFrom from-groups no-border-break mb-4 meghaDatePicker">
              <Form.Label htmlFor="startDate">Date Range</Form.Label>

              <DatePicker
                onChange={handleDateChange}
                startDate={traceData.startDate}
                endDate={traceData.endDate}
                selectsRange
                monthsShown={2}
              />
            </div>
            <div className="d-flex gap-3 align-items-center justify-content-end">
              {loading ? (
                <button
                  className="default-btn primary-btn d-flex gap-1 align-items-center"
                  type="button"
                  disabled
                >
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Loading...
                </button>
              ) : (
                <button
                  className="default-btn primary-btn d-flex gap-1 align-items-center"
                  onClick={() => {
                    GenerateReportbtn();
                  }}
                >
                  <span className="revpak-icon">
                    <img src={pdfIcon} alt="" />
                  </span>
                  <span>Generate Report</span>
                </button>
              )}
            </div>
          </div>
        )}
        <p className="text-center">{errorMessage && " No Record Found"}</p>
        {loading && <Loader />}
        <div className="pdf-is-showing-here mt-3">
          {showPdf && setPdfRef.current && (
            <iframe
              onLoad={iframeloader}
              title="Report Doc"
              src={setPdfRef.current}
              width="100%"
              height="800px"
            />
          )}
        </div>
        {/* {loading ? (
          <Loader />
        ) : (
          <div className="pdf-is-showing-here mt-3">
            {showPdf && setPdfRef.current && (
              <iframe
                title="Report Doc"
                src={setPdfRef.current}
                width="100%"
                height="800px"
              />
            )}
          </div>
        )} */}
      </Container>
    </div>
  );
};

export default TraceReport;
