import React, { useEffect, useRef, useState } from "react";
import SunburstChart from "sunburst-chart";
import * as d3 from "d3";
import { commonService } from "../../../../utils/commonService";
import { API_URL } from "../../../../utils/apiRoutes";
import { apiCall } from "../../../../utils/axiosService";
import { apiResponse } from "../../../../utils/apiResponse";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { compName, month } from "../../../../utils/constant";
import Loader from "../../../Loader/Loader";
import WidgetDetailModal from "../../WidgetDetailModal";
import WidgetNote from "../../WidgetNote";
import { useSelector } from "react-redux";
import infoIcon from "../../../../Assets/Images/Action Icons/infoIcon..svg";
import NoteIcon from "../../../../Assets/Images/CRM/notes.svg";
import downloadIcon from "../../../../Assets/Images/Operation/downloadIcon.svg";
import AddWidgetModal from "../../AddWidgetModal";
import Comments from "../Comments/Comment";

const MonthlyCancellationPaceChart = ({
  setCompDataFromChild,
  rowIndex,
  colIndex,
  api,
  para,
  setting,
}) => {
  let nameOfGroup = {
    CP0: "0 Day",
    CP1: "1 Day",
    CP2TO7: "2 to 7 Days",
    CP8TO15: "8 to 15 Days",
    CP16TO30: "16 to 30 Days",
    CP31TO60: "31 to 60 Days",
    CP61TO90: "61 to 90 Days",
    CP91TOUP: "90+ Days",
  };

  const yearsArr = [
    ...commonService.getPastYearForDropDown(10),
    ...commonService.getFutureYearForDropDown(10),
    new Date().getFullYear(),
  ]?.sort(function (a, b) {
    return a - b;
  });
  const chartRef = useRef();
  const [loading, setLoading] = useState(false);
  const [chartData, setChartData] = useState(null);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [showNoteSidebar, setShowNoteSidebar] = useState(false);
  const [widgetModal, setWidgetModal] = useState(false);
  const widgetDetailRef = useRef();
  const propertyDetailFromRTK = useSelector(
    (store) => store.headerProperty.propertyDetail
  );
  const closeWidgetModal = () => {
    setWidgetModal(false);
  };
  const closeNoteSideBar = () => {
    setShowNoteSidebar(false);
  };

  const getMonthlyCancellationData = async () => {
    setLoading(true);
    let monthlyCancellationResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.SnapShot +
          API_URL.CANCELLATION.MONTHLY_CANCELLATION_PACE,
        body: {
          propertyCode: para?.propertyCode,
          AsOfDate: para?.asOfDate,
          year: selectedYear,
        },
      },
      false
    );

    let response = apiResponse(false, monthlyCancellationResponse);

    if (response?.isValidate) {
      let dataFromApi = response?.data?.data;
      let groupingJson = {
        name: `${selectedYear}`,
        children: [
          {
            name: "January",
            children: [
              {
                name: "0 Day",
                children: [
                  { name: "Cancelled Nights", size: 0 },
                  { name: "ADR", size: 0 },
                  { name: "Revenue Loss", size: 0 },
                ],
              },
              {
                name: "1 Day",
                children: [
                  { name: "Cancelled Nights", size: 0 },
                  { name: "ADR", size: 0 },
                  { name: "Revenue Loss", size: 0 },
                ],
              },
              {
                name: "2 to 7 Days",
                children: [
                  { name: "Cancelled Nights", size: 0 },
                  { name: "ADR", size: 0 },
                  { name: "Revenue Loss", size: 0 },
                ],
              },
              {
                name: "8 to 15 Days",
                children: [
                  { name: "Cancelled Nights", size: 0 },
                  { name: "ADR", size: 0 },
                  { name: "Revenue Loss", size: 0 },
                ],
              },
              {
                name: "16 to 30 Days",
                children: [
                  { name: "Cancelled Nights", size: 0 },
                  { name: "ADR", size: 0 },
                  { name: "Revenue Loss", size: 0 },
                ],
              },
              {
                name: "31 to 60 Days",
                children: [
                  { name: "Cancelled Nights", size: 0 },
                  { name: "ADR", size: 0 },
                  { name: "Revenue Loss", size: 0 },
                ],
              },
              {
                name: "61 to 90 Days",
                children: [
                  { name: "Cancelled Nights", size: 0 },
                  { name: "ADR", size: 0 },
                  { name: "Revenue Loss", size: 0 },
                ],
              },
              {
                name: "90+ Days",
                children: [
                  { name: "Cancelled Nights", size: 0 },
                  { name: "ADR", size: 0 },
                  { name: "Revenue Loss", size: 0 },
                ],
              },
            ],
          },
          {
            name: "February",
            children: [
              {
                name: "0 Day",
                children: [
                  { name: "Cancelled Nights", size: 0 },
                  { name: "ADR", size: 0 },
                  { name: "Revenue Loss", size: 0 },
                ],
              },
              {
                name: "1 Day",
                children: [
                  { name: "Cancelled Nights", size: 0 },
                  { name: "ADR", size: 0 },
                  { name: "Revenue Loss", size: 0 },
                ],
              },
              {
                name: "2 to 7 Days",
                children: [
                  { name: "Cancelled Nights", size: 0 },
                  { name: "ADR", size: 0 },
                  { name: "Revenue Loss", size: 0 },
                ],
              },
              {
                name: "8 to 15 Days",
                children: [
                  { name: "Cancelled Nights", size: 0 },
                  { name: "ADR", size: 0 },
                  { name: "Revenue Loss", size: 0 },
                ],
              },
              {
                name: "16 to 30 Days",
                children: [
                  { name: "Cancelled Nights", size: 0 },
                  { name: "ADR", size: 0 },
                  { name: "Revenue Loss", size: 0 },
                ],
              },
              {
                name: "31 to 60 Days",
                children: [
                  { name: "Cancelled Nights", size: 0 },
                  { name: "ADR", size: 0 },
                  { name: "Revenue Loss", size: 0 },
                ],
              },
              {
                name: "61 to 90 Days",
                children: [
                  { name: "Cancelled Nights", size: 0 },
                  { name: "ADR", size: 0 },
                  { name: "Revenue Loss", size: 0 },
                ],
              },
              {
                name: "90+ Days",
                children: [
                  { name: "Cancelled Nights", size: 0 },
                  { name: "ADR", size: 0 },
                  { name: "Revenue Loss", size: 0 },
                ],
              },
            ],
          },
          {
            name: "March",
            children: [
              {
                name: "0 Day",
                children: [
                  { name: "Cancelled Nights", size: 0 },
                  { name: "ADR", size: 0 },
                  { name: "Revenue Loss", size: 0 },
                ],
              },
              {
                name: "1 Day",
                children: [
                  { name: "Cancelled Nights", size: 0 },
                  { name: "ADR", size: 0 },
                  { name: "Revenue Loss", size: 0 },
                ],
              },
              {
                name: "2 to 7 Days",
                children: [
                  { name: "Cancelled Nights", size: 0 },
                  { name: "ADR", size: 0 },
                  { name: "Revenue Loss", size: 0 },
                ],
              },
              {
                name: "8 to 15 Days",
                children: [
                  { name: "Cancelled Nights", size: 0 },
                  { name: "ADR", size: 0 },
                  { name: "Revenue Loss", size: 0 },
                ],
              },
              {
                name: "16 to 30 Days",
                children: [
                  { name: "Cancelled Nights", size: 0 },
                  { name: "ADR", size: 0 },
                  { name: "Revenue Loss", size: 0 },
                ],
              },
              {
                name: "31 to 60 Days",
                children: [
                  { name: "Cancelled Nights", size: 0 },
                  { name: "ADR", size: 0 },
                  { name: "Revenue Loss", size: 0 },
                ],
              },
              {
                name: "61 to 90 Days",
                children: [
                  { name: "Cancelled Nights", size: 0 },
                  { name: "ADR", size: 0 },
                  { name: "Revenue Loss", size: 0 },
                ],
              },
              {
                name: "90+ Days",
                children: [
                  { name: "Cancelled Nights", size: 0 },
                  { name: "ADR", size: 0 },
                  { name: "Revenue Loss", size: 0 },
                ],
              },
            ],
          },
          {
            name: "April",
            children: [
              {
                name: "0 Day",
                children: [
                  { name: "Cancelled Nights", size: 0 },
                  { name: "ADR", size: 0 },
                  { name: "Revenue Loss", size: 0 },
                ],
              },
              {
                name: "1 Day",
                children: [
                  { name: "Cancelled Nights", size: 0 },
                  { name: "ADR", size: 0 },
                  { name: "Revenue Loss", size: 0 },
                ],
              },
              {
                name: "2 to 7 Days",
                children: [
                  { name: "Cancelled Nights", size: 0 },
                  { name: "ADR", size: 0 },
                  { name: "Revenue Loss", size: 0 },
                ],
              },
              {
                name: "8 to 15 Days",
                children: [
                  { name: "Cancelled Nights", size: 0 },
                  { name: "ADR", size: 0 },
                  { name: "Revenue Loss", size: 0 },
                ],
              },
              {
                name: "16 to 30 Days",
                children: [
                  { name: "Cancelled Nights", size: 0 },
                  { name: "ADR", size: 0 },
                  { name: "Revenue Loss", size: 0 },
                ],
              },
              {
                name: "31 to 60 Days",
                children: [
                  { name: "Cancelled Nights", size: 0 },
                  { name: "ADR", size: 0 },
                  { name: "Revenue Loss", size: 0 },
                ],
              },
              {
                name: "61 to 90 Days",
                children: [
                  { name: "Cancelled Nights", size: 0 },
                  { name: "ADR", size: 0 },
                  { name: "Revenue Loss", size: 0 },
                ],
              },
              {
                name: "90+ Days",
                children: [
                  { name: "Cancelled Nights", size: 0 },
                  { name: "ADR", size: 0 },
                  { name: "Revenue Loss", size: 0 },
                ],
              },
            ],
          },
          {
            name: "May",
            children: [
              {
                name: "0 Day",
                children: [
                  { name: "Cancelled Nights", size: 0 },
                  { name: "ADR", size: 0 },
                  { name: "Revenue Loss", size: 0 },
                ],
              },
              {
                name: "1 Day",
                children: [
                  { name: "Cancelled Nights", size: 0 },
                  { name: "ADR", size: 0 },
                  { name: "Revenue Loss", size: 0 },
                ],
              },
              {
                name: "2 to 7 Days",
                children: [
                  { name: "Cancelled Nights", size: 0 },
                  { name: "ADR", size: 0 },
                  { name: "Revenue Loss", size: 0 },
                ],
              },
              {
                name: "8 to 15 Days",
                children: [
                  { name: "Cancelled Nights", size: 0 },
                  { name: "ADR", size: 0 },
                  { name: "Revenue Loss", size: 0 },
                ],
              },
              {
                name: "16 to 30 Days",
                children: [
                  { name: "Cancelled Nights", size: 0 },
                  { name: "ADR", size: 0 },
                  { name: "Revenue Loss", size: 0 },
                ],
              },
              {
                name: "31 to 60 Days",
                children: [
                  { name: "Cancelled Nights", size: 0 },
                  { name: "ADR", size: 0 },
                  { name: "Revenue Loss", size: 0 },
                ],
              },
              {
                name: "61 to 90 Days",
                children: [
                  { name: "Cancelled Nights", size: 0 },
                  { name: "ADR", size: 0 },
                  { name: "Revenue Loss", size: 0 },
                ],
              },
              {
                name: "90+ Days",
                children: [
                  { name: "Cancelled Nights", size: 0 },
                  { name: "ADR", size: 0 },
                  { name: "Revenue Loss", size: 0 },
                ],
              },
            ],
          },
          {
            name: "June",
            children: [
              {
                name: "0 Day",
                children: [
                  { name: "Cancelled Nights", size: 0 },
                  { name: "ADR", size: 0 },
                  { name: "Revenue Loss", size: 0 },
                ],
              },
              {
                name: "1 Day",
                children: [
                  { name: "Cancelled Nights", size: 0 },
                  { name: "ADR", size: 0 },
                  { name: "Revenue Loss", size: 0 },
                ],
              },
              {
                name: "2 to 7 Days",
                children: [
                  { name: "Cancelled Nights", size: 0 },
                  { name: "ADR", size: 0 },
                  { name: "Revenue Loss", size: 0 },
                ],
              },
              {
                name: "8 to 15 Days",
                children: [
                  { name: "Cancelled Nights", size: 0 },
                  { name: "ADR", size: 0 },
                  { name: "Revenue Loss", size: 0 },
                ],
              },
              {
                name: "16 to 30 Days",
                children: [
                  { name: "Cancelled Nights", size: 0 },
                  { name: "ADR", size: 0 },
                  { name: "Revenue Loss", size: 0 },
                ],
              },
              {
                name: "31 to 60 Days",
                children: [
                  { name: "Cancelled Nights", size: 0 },
                  { name: "ADR", size: 0 },
                  { name: "Revenue Loss", size: 0 },
                ],
              },
              {
                name: "61 to 90 Days",
                children: [
                  { name: "Cancelled Nights", size: 0 },
                  { name: "ADR", size: 0 },
                  { name: "Revenue Loss", size: 0 },
                ],
              },
              {
                name: "90+ Days",
                children: [
                  { name: "Cancelled Nights", size: 0 },
                  { name: "ADR", size: 0 },
                  { name: "Revenue Loss", size: 0 },
                ],
              },
            ],
          },
          {
            name: "July",
            children: [
              {
                name: "0 Day",
                children: [
                  { name: "Cancelled Nights", size: 0 },
                  { name: "ADR", size: 0 },
                  { name: "Revenue Loss", size: 0 },
                ],
              },
              {
                name: "1 Day",
                children: [
                  { name: "Cancelled Nights", size: 0 },
                  { name: "ADR", size: 0 },
                  { name: "Revenue Loss", size: 0 },
                ],
              },
              {
                name: "2 to 7 Days",
                children: [
                  { name: "Cancelled Nights", size: 0 },
                  { name: "ADR", size: 0 },
                  { name: "Revenue Loss", size: 0 },
                ],
              },
              {
                name: "8 to 15 Days",
                children: [
                  { name: "Cancelled Nights", size: 0 },
                  { name: "ADR", size: 0 },
                  { name: "Revenue Loss", size: 0 },
                ],
              },
              {
                name: "16 to 30 Days",
                children: [
                  { name: "Cancelled Nights", size: 0 },
                  { name: "ADR", size: 0 },
                  { name: "Revenue Loss", size: 0 },
                ],
              },
              {
                name: "31 to 60 Days",
                children: [
                  { name: "Cancelled Nights", size: 0 },
                  { name: "ADR", size: 0 },
                  { name: "Revenue Loss", size: 0 },
                ],
              },
              {
                name: "61 to 90 Days",
                children: [
                  { name: "Cancelled Nights", size: 0 },
                  { name: "ADR", size: 0 },
                  { name: "Revenue Loss", size: 0 },
                ],
              },
              {
                name: "90+ Days",
                children: [
                  { name: "Cancelled Nights", size: 0 },
                  { name: "ADR", size: 0 },
                  { name: "Revenue Loss", size: 0 },
                ],
              },
            ],
          },
          {
            name: "August",
            children: [
              {
                name: "0 Day",
                children: [
                  { name: "Cancelled Nights", size: 0 },
                  { name: "ADR", size: 0 },
                  { name: "Revenue Loss", size: 0 },
                ],
              },
              {
                name: "1 Day",
                children: [
                  { name: "Cancelled Nights", size: 0 },
                  { name: "ADR", size: 0 },
                  { name: "Revenue Loss", size: 0 },
                ],
              },
              {
                name: "2 to 7 Days",
                children: [
                  { name: "Cancelled Nights", size: 0 },
                  { name: "ADR", size: 0 },
                  { name: "Revenue Loss", size: 0 },
                ],
              },
              {
                name: "8 to 15 Days",
                children: [
                  { name: "Cancelled Nights", size: 0 },
                  { name: "ADR", size: 0 },
                  { name: "Revenue Loss", size: 0 },
                ],
              },
              {
                name: "16 to 30 Days",
                children: [
                  { name: "Cancelled Nights", size: 0 },
                  { name: "ADR", size: 0 },
                  { name: "Revenue Loss", size: 0 },
                ],
              },
              {
                name: "31 to 60 Days",
                children: [
                  { name: "Cancelled Nights", size: 0 },
                  { name: "ADR", size: 0 },
                  { name: "Revenue Loss", size: 0 },
                ],
              },
              {
                name: "61 to 90 Days",
                children: [
                  { name: "Cancelled Nights", size: 0 },
                  { name: "ADR", size: 0 },
                  { name: "Revenue Loss", size: 0 },
                ],
              },
              {
                name: "90+ Days",
                children: [
                  { name: "Cancelled Nights", size: 0 },
                  { name: "ADR", size: 0 },
                  { name: "Revenue Loss", size: 0 },
                ],
              },
            ],
          },
          {
            name: "September",
            children: [
              {
                name: "0 Day",
                children: [
                  { name: "Cancelled Nights", size: 0 },
                  { name: "ADR", size: 0 },
                  { name: "Revenue Loss", size: 0 },
                ],
              },
              {
                name: "1 Day",
                children: [
                  { name: "Cancelled Nights", size: 0 },
                  { name: "ADR", size: 0 },
                  { name: "Revenue Loss", size: 0 },
                ],
              },
              {
                name: "2 to 7 Days",
                children: [
                  { name: "Cancelled Nights", size: 0 },
                  { name: "ADR", size: 0 },
                  { name: "Revenue Loss", size: 0 },
                ],
              },
              {
                name: "8 to 15 Days",
                children: [
                  { name: "Cancelled Nights", size: 0 },
                  { name: "ADR", size: 0 },
                  { name: "Revenue Loss", size: 0 },
                ],
              },
              {
                name: "16 to 30 Days",
                children: [
                  { name: "Cancelled Nights", size: 0 },
                  { name: "ADR", size: 0 },
                  { name: "Revenue Loss", size: 0 },
                ],
              },
              {
                name: "31 to 60 Days",
                children: [
                  { name: "Cancelled Nights", size: 0 },
                  { name: "ADR", size: 0 },
                  { name: "Revenue Loss", size: 0 },
                ],
              },
              {
                name: "61 to 90 Days",
                children: [
                  { name: "Cancelled Nights", size: 0 },
                  { name: "ADR", size: 0 },
                  { name: "Revenue Loss", size: 0 },
                ],
              },
              {
                name: "90+ Days",
                children: [
                  { name: "Cancelled Nights", size: 0 },
                  { name: "ADR", size: 0 },
                  { name: "Revenue Loss", size: 0 },
                ],
              },
            ],
          },
          {
            name: "October",
            children: [
              {
                name: "0 Day",
                children: [
                  { name: "Cancelled Nights", size: 0 },
                  { name: "ADR", size: 0 },
                  { name: "Revenue Loss", size: 0 },
                ],
              },
              {
                name: "1 Day",
                children: [
                  { name: "Cancelled Nights", size: 0 },
                  { name: "ADR", size: 0 },
                  { name: "Revenue Loss", size: 0 },
                ],
              },
              {
                name: "2 to 7 Days",
                children: [
                  { name: "Cancelled Nights", size: 0 },
                  { name: "ADR", size: 0 },
                  { name: "Revenue Loss", size: 0 },
                ],
              },
              {
                name: "8 to 15 Days",
                children: [
                  { name: "Cancelled Nights", size: 0 },
                  { name: "ADR", size: 0 },
                  { name: "Revenue Loss", size: 0 },
                ],
              },
              {
                name: "16 to 30 Days",
                children: [
                  { name: "Cancelled Nights", size: 0 },
                  { name: "ADR", size: 0 },
                  { name: "Revenue Loss", size: 0 },
                ],
              },
              {
                name: "31 to 60 Days",
                children: [
                  { name: "Cancelled Nights", size: 0 },
                  { name: "ADR", size: 0 },
                  { name: "Revenue Loss", size: 0 },
                ],
              },
              {
                name: "61 to 90 Days",
                children: [
                  { name: "Cancelled Nights", size: 0 },
                  { name: "ADR", size: 0 },
                  { name: "Revenue Loss", size: 0 },
                ],
              },
              {
                name: "90+ Days",
                children: [
                  { name: "Cancelled Nights", size: 0 },
                  { name: "ADR", size: 0 },
                  { name: "Revenue Loss", size: 0 },
                ],
              },
            ],
          },
          {
            name: "November",
            children: [
              {
                name: "0 Day",
                children: [
                  { name: "Cancelled Nights", size: 0 },
                  { name: "ADR", size: 0 },
                  { name: "Revenue Loss", size: 0 },
                ],
              },
              {
                name: "1 Day",
                children: [
                  { name: "Cancelled Nights", size: 0 },
                  { name: "ADR", size: 0 },
                  { name: "Revenue Loss", size: 0 },
                ],
              },
              {
                name: "2 to 7 Days",
                children: [
                  { name: "Cancelled Nights", size: 0 },
                  { name: "ADR", size: 0 },
                  { name: "Revenue Loss", size: 0 },
                ],
              },
              {
                name: "8 to 15 Days",
                children: [
                  { name: "Cancelled Nights", size: 0 },
                  { name: "ADR", size: 0 },
                  { name: "Revenue Loss", size: 0 },
                ],
              },
              {
                name: "16 to 30 Days",
                children: [
                  { name: "Cancelled Nights", size: 0 },
                  { name: "ADR", size: 0 },
                  { name: "Revenue Loss", size: 0 },
                ],
              },
              {
                name: "31 to 60 Days",
                children: [
                  { name: "Cancelled Nights", size: 0 },
                  { name: "ADR", size: 0 },
                  { name: "Revenue Loss", size: 0 },
                ],
              },
              {
                name: "61 to 90 Days",
                children: [
                  { name: "Cancelled Nights", size: 0 },
                  { name: "ADR", size: 0 },
                  { name: "Revenue Loss", size: 0 },
                ],
              },
              {
                name: "90+ Days",
                children: [
                  { name: "Cancelled Nights", size: 0 },
                  { name: "ADR", size: 0 },
                  { name: "Revenue Loss", size: 0 },
                ],
              },
            ],
          },
          {
            name: "December",
            children: [
              {
                name: "0 Day",
                children: [
                  { name: "Cancelled Nights", size: 0 },
                  { name: "ADR", size: 0 },
                  { name: "Revenue Loss", size: 0 },
                ],
              },
              {
                name: "1 Day",
                children: [
                  { name: "Cancelled Nights", size: 0 },
                  { name: "ADR", size: 0 },
                  { name: "Revenue Loss", size: 0 },
                ],
              },
              {
                name: "2 to 7 Days",
                children: [
                  { name: "Cancelled Nights", size: 0 },
                  { name: "ADR", size: 0 },
                  { name: "Revenue Loss", size: 0 },
                ],
              },
              {
                name: "8 to 15 Days",
                children: [
                  { name: "Cancelled Nights", size: 0 },
                  { name: "ADR", size: 0 },
                  { name: "Revenue Loss", size: 0 },
                ],
              },
              {
                name: "16 to 30 Days",
                children: [
                  { name: "Cancelled Nights", size: 0 },
                  { name: "ADR", size: 0 },
                  { name: "Revenue Loss", size: 0 },
                ],
              },
              {
                name: "31 to 60 Days",
                children: [
                  { name: "Cancelled Nights", size: 0 },
                  { name: "ADR", size: 0 },
                  { name: "Revenue Loss", size: 0 },
                ],
              },
              {
                name: "61 to 90 Days",
                children: [
                  { name: "Cancelled Nights", size: 0 },
                  { name: "ADR", size: 0 },
                  { name: "Revenue Loss", size: 0 },
                ],
              },
              {
                name: "90+ Days",
                children: [
                  { name: "Cancelled Nights", size: 0 },
                  { name: "ADR", size: 0 },
                  { name: "Revenue Loss", size: 0 },
                ],
              },
            ],
          },
        ],
      };
      month.map((item, index) => {
        let filterObj = dataFromApi?.filter(
          (x) => x?.MonthName?.trim()?.toLowerCase() === item?.toLowerCase()
        );

        if (filterObj?.[0]) {
          Object.entries(filterObj?.[0])?.map(([key, value]) => {
            if (key === "MonthName") return;
            let keyName = key?.split("_");
            let findNameIndex = groupingJson?.children?.[
              index
            ]?.children?.findIndex(
              (x) => x?.name === nameOfGroup[keyName?.[0]]
            );

            if (!keyName?.[1]) {
              let findValueIndex = groupingJson?.children?.[index]?.children?.[
                findNameIndex
              ]?.children?.findIndex((x) => x?.name === "Cancelled Nights");
              if (findValueIndex >= 0) {
                groupingJson.children[index].children[findNameIndex].children[
                  findValueIndex
                ].size = value;
              }
            }

            if (keyName?.[1] === "REV") {
              let findValueIndex = groupingJson?.children?.[index]?.children?.[
                findNameIndex
              ]?.children?.findIndex((x) => x?.name === "Revenue Loss");
              if (findValueIndex >= 0) {
                groupingJson.children[index].children[findNameIndex].children[
                  findValueIndex
                ].size = value;
              }
            }

            if (keyName?.[1] === "ADR") {
              let findValueIndex = groupingJson?.children?.[index]?.children?.[
                findNameIndex
              ]?.children?.findIndex((x) => x?.name === "ADR");
              if (findValueIndex >= 0) {
                groupingJson.children[index].children[findNameIndex].children[
                  findValueIndex
                ].size = value;
              }
            }
          });
        }
      });

      setChartData(groupingJson);
      setLoading(false);
    }

    if (!response?.isValidate) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (chartRef.current && chartData) {
      const color = d3.scaleOrdinal(d3.schemePaired);
      const sunburst = SunburstChart()(chartRef.current);

      sunburst
        .data(chartData)
        .label("name")
        .size("size")
        .height(500)
        .width(500)
        .color((d, parent) => color(parent ? parent.data.name : null))
        .tooltipContent((d, node) => `Revenue: <i>${node.value}</i>`);
    }
  }, [chartData]);

  useEffect(() => {
    if (api === 0) {
      //   setPaceData(tableData);
    } else {
      if (para?.asOfDate) {
        setChartData(null);
        getMonthlyCancellationData();
      }
    }
  }, [para?.asOfDate, selectedYear]);

  return (
    <div className="widget-container">
      {api !== 0 && (
        <div className="gap-1 widget-container-menu">
          {/* <Button className="heading-menu-btns" type="button">
            <img
              src={NoteIcon}
              alt="menuIcon"
              onClick={() => {
                setShowNoteSidebar(true);
              }}
            />
          </Button> */}
          <Button className="heading-menu-btns" type="button">
            <img
              src={infoIcon}
              alt="info"
              onClick={() => {
                widgetDetailRef.current = {
                  widgetdetails: "",
                  usernote: "",
                  compName: compName?.monthlyCancellationPaceChart,
                  widgetId: para?.widgetId,
                };
                setWidgetModal(true);
              }}
            />
          </Button>
          {/* <Button className="heading-menu-btns" type="button">
            <img src={downloadIcon} alt="menuIcon" />
          </Button> */}
        </div>
      )}
      <Container fluid>
        <Row>
          <Col xl={12}>
            <Col xl={4}>
              <div className="from-groups no-border-break">
                <Form.Select
                  aria-label="Default select example"
                  value={selectedYear}
                  onChange={(e) => {
                    setSelectedYear(e.target.value);
                  }}
                >
                  {yearsArr?.map((item, index) => (
                    <option key={item} value={item}>
                      {item}
                    </option>
                  ))}
                </Form.Select>
              </div>
            </Col>
          </Col>
          <Col xl={12}>
            {loading ? (
              <Loader />
            ) : chartData ? (
              <>
                <div className="d-flex justify-content-center mt-2 monthlyPaceChart">
                  <div ref={chartRef}></div>
                </div>
                {api !== 0 && <Comments widgetDetails={para} editorId={"MonthlyCancellationPaceChart"}/>}
              </>
            ) : (
              <div className="d-flex justify-content-center mt-3">
                <p>No Data Found</p>
              </div>
            )}
          </Col>
        </Row>
      </Container>
      {/* {showNoteSidebar && (
        <WidgetNote
          show={showNoteSidebar}
          handleClose={closeNoteSideBar}
          widgetId={para?.widgetId}
          propertyId={propertyDetailFromRTK?.propertyid}
          rowTitle={para?.rowTitle}
        />
      )} */}
      {showNoteSidebar && (
        <AddWidgetModal
          showModal={showNoteSidebar}
          closeModal={closeNoteSideBar}
          widgetId={para?.widgetId}
          propertyId={propertyDetailFromRTK?.propertyid}
          rowTitle={para?.rowTitle}
        />
      )}
      {widgetModal && (
        <WidgetDetailModal
          showModal={widgetModal}
          closeModal={closeWidgetModal}
          data={widgetDetailRef.current}
        />
      )}
    </div>
  );
};

export default MonthlyCancellationPaceChart;
