import React, { useEffect, useRef, useState } from "react";
import { Row, Form, Col, Button, Container } from "react-bootstrap";
import pdfIcon from "../../../Assets/Images/CRM/pdf.svg";
import { commonService } from "../../../utils/commonService";
import DatePicker from "react-datepicker";
import { API_URL } from "../../../utils/apiRoutes";
import { validationRule } from "../../../utils/constant";
import { hotelListCommon } from "../Hotels/HotelListCommon";
import { AccountListCommon } from "../Contacts/AccountListCommon";
import { checkValidation } from "../../../utils/helper";
import { apiCall } from "../../../utils/axiosService";
import { apiResponse } from "../../../utils/apiResponse";
import { successToastMessage } from "../../../utils/toastMessage";
import Select from "react-select";
import filterIcon from "../../../Assets/Images/CRM/filter.png";
import { useSelector } from "react-redux";
import Loader from "../../Loader/Loader";
import { Link } from "react-router-dom";

const ActiviesReport = ({ title }) => {
  const [moduleWisePropertyDropDown, setModuleWisePropertyDropDown] = useState(
    []
  );
  const [selectedModuleWiseProperty, setSelectedModuleWiseProperty] = useState(
    []
  );
  const accountIdList = useRef("");
  const propertyIdList = useRef("");
  const [loading, setLoading] = useState(false);
  const [hotelList, setHotelList] = useState(null);
  const [accountList, setAccountList] = useState(null);
  const userDetails = commonService.getLoginUserData();
  const setPdfRef = useRef("");
  const [showPdf, setShowPdf] = useState(false);
  const [showAllReport, setshowAllReport] = useState(false);
  const [startRangeDate, setStartRangeDate] = useState(new Date());
  const [errorMessage, setErrorMessage] = useState(false);
  const [endRangeDate, setEndRangeDate] = useState(
    new Date(startRangeDate.getTime() - 7 * 24 * 60 * 60 * 1000)
  );
  const isDefaultApiCalledRef = useRef(false);
  const handleshowAllReport = () => {
    setshowAllReport(!showAllReport);
  };

  const crmPropertyDetailFromRTK = useSelector(
    (store) => store.headerProperty.crmPropertyDetail
  );

  let propertyIdPayload = "";
  if (crmPropertyDetailFromRTK) {
    if (crmPropertyDetailFromRTK?.propertyid === "") {
      let propertyPayload = crmPropertyDetailFromRTK?.allPropertyId;
      propertyIdPayload = propertyPayload.slice(0, -1);
    } else {
      propertyIdPayload = crmPropertyDetailFromRTK?.propertyid?.toString();
    }
  }

  let validationObj = {
    clientid: userDetails?.clientid ? userDetails?.clientid : 0,
    accountID: "",
    propertyID: "",
    startDate: endRangeDate,
    endDate: startRangeDate,

    validationRule: [
      {
        fieldName: "propertyID",
        rule: validationRule.required,
        message: "Property required",
      },
      {
        fieldName: "accountID",
        rule: validationRule.required,
        message: "Account  is required",
      },
      {
        fieldName: "startDate",
        rule: validationRule.required,
        message: "Start Date is required",
      },
      {
        fieldName: "endDate",
        rule: validationRule.required,
        message: "End Date is required",
      },
    ],
    errorObj: {},
    isValidate: true,
  };
  const [activities, setActivitiesData] = useState(validationObj);

  const getHotelList = async () => {
    let response = await hotelListCommon(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.CRM +
          API_URL.PROPERTY_TERM.GET_PROPERTY_LIST_BY_USER_ID,
        body: {
          id: userDetails?.userid,
        },
      },
      false
    );

    if (response?.isValidate) {
      if (crmPropertyDetailFromRTK?.propertyid !== "") {
        propertyIdList.current = `${crmPropertyDetailFromRTK?.propertyid}`;
        setActivitiesData(
          {
            ...activities,
            propertyID: propertyIdList.current,
          }
          // getAccountRuleList(e.target.value)
        );
      }
      setHotelList(response?.data);
    }
  };
  const iframeloader = () => {
    setLoading(false)
  };

  // const getAccountRuleList = async (propertyId) => {
  //   let accountResponse = await AccountListCommon(
  //     {
  //       method: "POST",
  //       url:
  //         API_URL.BASE_API_URL.UserManagement +
  //         API_URL.USER_ROLE_PROPERTY.GET_LIST_BY_PROPERTY_AND_MODULE,
  //       body: {
  //         propertyid: parseInt(propertyId),
  //         modulename: "crm",
  //       },
  //     },
  //     false
  //   );

  //   if (accountResponse?.isValidate) {
  //     setAccountList(accountResponse?.data);
  //   }
  // };

  const getAccountRuleList = async (propertyId) => {
    if (propertyId !== 0) {
      let accountResponse = await AccountListCommon(
        {
          method: "POST",
          url:
            API_URL.BASE_API_URL.CRM +
            API_URL.ACCOUNT.GET_ACCOUNT_DROPDOWN_LIST,
          body: {
            clientid: userDetails?.clientid,
            propertyid: parseInt(activities.propertyID),
          },
        },
        false
      );
      if (accountResponse?.isValidate) {
        let accountDropDownList = [];
        let accountId = [];
        accountResponse?.data?.map((item) => {
          accountId = [...accountId, item?.accountid];
          accountDropDownList = [
            ...accountDropDownList,
            {
              label: item?.accountname,
              value: item?.accountid,
            },
          ];
        });
        setModuleWisePropertyDropDown(accountDropDownList);
        setSelectedModuleWiseProperty(accountDropDownList);
        if (activities?.propertyID !== "") {
          accountIdList.current = accountId?.join(",");
          setActivitiesData({
            ...activities,
            accountID: accountIdList.current,
          });
        }
      }
    }
  };
  //account Dropdown
  const handleAccountSelection = (e) => {
    if (e?.length <= 0) {
      setSelectedModuleWiseProperty([]);
    }
    setSelectedModuleWiseProperty(e);
    let propertyList = [];
    e?.map((item) => {
      propertyList = [...propertyList, item?.value?.toString()];
      accountIdList.current = propertyList?.join(",");
      setActivitiesData({
        ...activities,
        accountID: accountIdList.current,
      });
    });
  };

  const GenerateReportbtn = async () => {
    setShowPdf(false);
    setLoading(true);
    let validation = checkValidation(activities);

    if (!validation.isValidate) {
      setActivitiesData(validation);
      setLoading(false);
      return;
    }
    let ActivitiesReport = await apiCall(
      {
        method: "POST",
        url: API_URL.BASE_API_URL.CRM + API_URL.CRM_REPORT.ACTIVITIES_REPORT,
        body: {
          propertyID: parseInt(activities.propertyID),
          accountID: activities.accountID.toString(),
          startDate: activities.startDate,
          endDate: activities.endDate,
        },
      },
      false
    );
    let response = apiResponse(false, ActivitiesReport);
    if (response?.isValidate) {
      isDefaultApiCalledRef.current = true;
      // setLoading(false);
      setshowAllReport(false);
      setErrorMessage(false);
      // successToastMessage(response.data?.message);
      setPdfRef.current =
        process.env.REACT_APP_APIBASEPATH_CRM_REPORT_PDF + response.data.data;
      setShowPdf(true);
    }
    if (!response?.isValidate) {
      isDefaultApiCalledRef.current = true;
      setLoading(false);
      setErrorMessage(true);
      setshowAllReport(true);
    }
  };

  useEffect(() => {
    if (activities.propertyID !== "") {
      getAccountRuleList();
    }
  }, [activities.propertyID]);

  useEffect(() => {
    if (activities.propertyID !== "") {
      if (!isDefaultApiCalledRef.current && activities.accountID !== "") {
        GenerateReportbtn();
      }
    }
  }, [activities.propertyID, activities.accountID]);

  useEffect(() => {
    if (crmPropertyDetailFromRTK?.propertyid === "") {
      isDefaultApiCalledRef.current = true;
      setshowAllReport(true);
    }
    getHotelList();
  }, []);

  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setStartRangeDate(start);
    setEndRangeDate(end);
  };
  return (
    <div className="all-reports-main ToolsDisplacementCalcMain">
      <Container fluid>
        <Row>
          <Col xl={12}>
            <div className="ProspectDashboardTitleWraper  d-flex justify-content-between align-items-center marginBottom20">
              <h2 className="d-flex align-items-center gap-1">
                <Link to="/crm/dashboard">Dashboard - </Link>{" "}
                <span className="tab-title-main">{title}</span>
              </h2>
              <div className="heading-menu-btns">
                <img
                  src={filterIcon}
                  alt="filterIcon"
                  title="Filter Report"
                  className="cursorPointer report-filter"
                  onClick={handleshowAllReport}
                />
              </div>
            </div>
          </Col>
        </Row>
        {showAllReport && (
          <div className="displacementCalcWrapper width400">
            <div className="from-groups no-border-break mb-2 small-height-select">
              <Form.Label>Select Property</Form.Label>
              <Form.Select
                aria-label="Default select example"
                value={activities?.propertyID}
                onChange={(e) => {
                  setActivitiesData(
                    {
                      ...activities,
                      propertyID: e.target.value,
                    }
                    // getAccountRuleList(e.target.value)
                  );
                }}
              >
                <option value={0}>Select Property</option>
                {hotelList?.map((item, index) => (
                  <option key={index} value={item?.propertyid}>
                    {item?.propertyname}
                  </option>
                ))}
              </Form.Select>
              {activities?.errorObj?.propertyID &&
                activities?.propertyID === "" && (
                  <small className="text-danger">
                    {activities?.errorObj?.propertyID}
                  </small>
                )}
            </div>

            <div className="from-groups no-border-break mb-2 small-height-select">
              <Form.Label>Select Account</Form.Label>
              {/* <Form.Select
              aria-label="Default select example"
              value={activities?.accountID}
              onChange={(e) => {
                setActivitiesData({
                  ...activities,
                  accountID: e.target.value,
                });
              }}
            >
              <option value={0}>Select Account</option>
              {accountList?.map((item, index) => (
                <option key={index} value={item?.accountid}>
                  {item?.accountname}
                </option>
              ))}
            </Form.Select> */}
              <Select
                isMulti
                value={selectedModuleWiseProperty}
                options={moduleWisePropertyDropDown}
                onChange={(e) => {
                  handleAccountSelection(e);
                }}
              />
              {activities?.errorObj?.accountID &&
                activities?.accountID === "" && (
                  <small className="text-danger">
                    {activities?.errorObj?.accountID}
                  </small>
                )}
            </div>

            {/* <div className="startFrom from-groups no-border-break mb-2">
              <Form.Label htmlFor="startDate">Start Date</Form.Label>

              <DatePicker
                id="startDate"
                selected={
                  activities.startDate ? new Date(activities.startDate) : ""
                }
                selectsStart
                // startDate={activities.startdate}
                // endDate={activities.enddate}
                onChange={(date) => {
                  setActivitiesData({
                    ...activities,
                    startDate: date,
                  });
                }}
                placeholderText="MM/DD/YYYY"
              />
              {activities?.errorObj?.startDate &&
                activities?.startDate === "" && (
                  <small className="text-danger">
                    {activities?.errorObj?.startDate}
                  </small>
                )}
            </div>

            <div className="endDate from-groups no-border-break mb-4">
              <Form.Label htmlFor="endDate">End Date</Form.Label>

              <DatePicker
                id="endDate"
                selected={
                  activities.endDate ? new Date(activities.endDate) : ""
                }
                disabled={activities.startDate ? false : true}
                minDate={activities.startDate}
                selectsEnd
                onChange={(date) => {
                  setActivitiesData({
                    ...activities,
                    endDate: date,
                  });
                }}
                placeholderText="MM/DD/YYYY"
              />
              {activities?.errorObj?.endDate && activities?.endDate === "" && (
                <small className="text-danger">
                  {activities?.errorObj?.endDate}
                </small>
              )}
            </div> */}
            <div className="startFrom from-groups no-border-break mb-4 meghaDatePicker">
              <Form.Label htmlFor="startDate">Date Range</Form.Label>

              <DatePicker
                onChange={handleDateChange}
                startDate={startRangeDate}
                endDate={endRangeDate}
                selectsRange
                monthsShown={2}
              />
            </div>

            <div className="d-flex gap-3 align-items-center justify-content-end ">
              {loading ? (
                <button
                  className="default-btn primary-btn d-flex gap-1 align-items-center"
                  type="button"
                  disabled
                >
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Loading...
                </button>
              ) : (
                <button
                  className="default-btn primary-btn d-flex gap-1 align-items-center"
                  onClick={() => {
                    GenerateReportbtn();
                  }}
                >
                  <span className="revpak-icon">
                    <img src={pdfIcon} alt="" />
                  </span>
                  <span>Generate Report</span>
                </button>
              )}
            </div>
          </div>
        )}
        <p className="text-center">{errorMessage && " No Record Found"}</p>
        {loading && <Loader />}
        <div className="pdf-is-showing-here mt-3">
          {showPdf && setPdfRef.current && (
            <iframe
              onLoad={iframeloader}
              title="Report Doc"
              src={setPdfRef.current}
              width="100%"
              height="800px"
            />
          )}
        </div>
        {/* {loading ? (
          <Loader />
        ) : (
          <div className="pdf-is-showing-here mt-3">
            {showPdf && setPdfRef.current && (
              <iframe
                title="Report Doc"
                src={setPdfRef.current}
                width="100%"
                height="800px"
                onLoad={() => {
                  setLoading(false);
                }}
              />
            )}
          </div>
        )} */}
      </Container>
    </div>
  );
};

export default ActiviesReport;
