import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Offcanvas, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { commonService } from "../../../../utils/commonService";

const AccountFilter = ({
  showModal,
  closeModal,
  filterData,
  updatePayload,
}) => {
  const startRangeDateRef = useRef();
  const endRangeDateRef = useRef();
  const [filter, setFilter] = useState(filterData);
  const [startRangeDate, setStartRangeDate] = useState();
  const [endRangeDate, setEndRangeDate] = useState();

  const handleDateChange = (event) => {
    const [start, end] = event;
    let startSelectedDate = "",
      endSelectedDate = "";
    if (start) {
      let date = new Date(start);
      let month =
        date.getMonth() + 1 > 9
          ? date.getMonth() + 1
          : `0${date.getMonth() + 1}`;
      let dateFormat =
        date?.getDate() > 9 ? date.getDate() : `0${date.getDate()}`;
      startSelectedDate = `${date.getFullYear()}-${month}-${dateFormat}`;
      startRangeDateRef.current = startSelectedDate;
    }
    if (end) {
      let date = new Date(end);
      let month =
        date.getMonth() + 1 > 9
          ? date.getMonth() + 1
          : `0${date.getMonth() + 1}`;
      let dateFormat =
        date?.getDate() > 9 ? date.getDate() : `0${date.getDate()}`;
      endSelectedDate = `${date.getFullYear()}-${month}-${dateFormat}`;
      endRangeDateRef.current = endSelectedDate;
    }

    setStartRangeDate(event[0]);
    setEndRangeDate(event[1]);
    if (event[0] !== null && event[1] !== null) {
      // call api when date change
    }
  };

  useEffect(() => {
    setFilter(filterData);
    setStartRangeDate(filterData?.stayDateStart);
    setEndRangeDate(filterData?.stayDateEnd);
  }, []);

  return (
    <Offcanvas
      className="notification-canvas"
      show={showModal}
      onHide={closeModal}
      placement="end"
    >
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>
          <Button
            className="default-btn primary-btn"
            onClick={() => {
              if (startRangeDate && endRangeDate) {
                filter.stayDateStart = startRangeDate;
                filter.stayDateEnd = endRangeDate;
              }
              updatePayload(filter);
              closeModal();
            }}
          >
            Apply
          </Button>
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body id="canvas">
        <div>
          <Col xl={12}>
            <Row>
              <Col xl={6}>
                <div className="startFrom from-groups no-border-break">
                  <Form.Label htmlFor="startFrom">
                    Reservation OTB as of
                  </Form.Label>
                  <DatePicker
                    id="asOfDateLeft"
                    selected={
                      filter?.asOfDate
                        ? commonService.convertDateInTimezone(filter?.asOfDate)
                        : ""
                    }
                    onChange={(date) => {
                      setFilter({
                        ...filter,
                        asOfDate: date,
                      });
                    }}
                  />
                </div>
              </Col>

              <Col xl={6}>
                <div className="startFrom from-groups no-border-break">
                  <Form.Label htmlFor="startFrom">Stay Date</Form.Label>
                  <DatePicker
                    onChange={(event) => handleDateChange(event)}
                    startDate={startRangeDate ? new Date(startRangeDate) : ""}
                    endDate={endRangeDate ? new Date(endRangeDate) : ""}
                    selectsRange
                    monthsShown={2}
                  />
                </div>
              </Col>

              <Col xl={6} className="mt-3">
                <div>
                  <p>Channel</p>
                  <div className="responsiveFilterDiv">
                    {Object?.entries(filter?.channel)?.map(
                      ([key, value], index) => {
                        return (
                          <div className="form-groups">
                            <div className="checkbox-container">
                              <div className="form-checkbox">
                                <input
                                  id={`channel${key}`}
                                  type="checkbox"
                                  className="form-check-input"
                                  checked={value}
                                  onChange={(e) => {
                                    setFilter({
                                      ...filter,
                                      channel: {
                                        ...filter.channel,
                                        [key]: e.target.checked,
                                      },
                                    });
                                  }}
                                />
                                <Form.Check.Label htmlFor={`channel${key}`}>
                                  {key}
                                </Form.Check.Label>
                              </div>
                            </div>
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
              </Col>

              <Col xl={6} className="mt-3">
                <div>
                  <p>Company</p>
                  <div className="responsiveFilterDiv">
                    {Object?.entries(filter?.company)?.map(
                      ([key, value], index) => {
                        return (
                          <div className="form-groups">
                            <div className="checkbox-container">
                              <div className="form-checkbox">
                                <input
                                  id={`company${key}`}
                                  type="checkbox"
                                  className="form-check-input"
                                  checked={value}
                                  onChange={(e) => {
                                    setFilter({
                                      ...filter,
                                      company: {
                                        ...filter.company,
                                        [key]: e.target.checked,
                                      },
                                    });
                                  }}
                                />
                                <Form.Check.Label htmlFor={`company${key}`}>
                                  {key}
                                </Form.Check.Label>
                              </div>
                            </div>
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
              </Col>

              <Col xl={6} className="mt-3">
                <div>
                  <p>Market Segment</p>
                  <div className="responsiveFilterDiv">
                    {Object?.entries(filter?.marketSegment)?.map(
                      ([key, value], index) => {
                        return (
                          <div className="form-groups">
                            <div className="checkbox-container">
                              <div className="form-checkbox">
                                <input
                                  id={`ms${key}`}
                                  type="checkbox"
                                  className="form-check-input"
                                  checked={value}
                                  onChange={(e) => {
                                    setFilter({
                                      ...filter,
                                      marketSegment: {
                                        ...filter.marketSegment,
                                        [key]: e.target.checked,
                                      },
                                    });
                                  }}
                                />
                                <Form.Check.Label htmlFor={`ms${key}`}>
                                  {key}
                                </Form.Check.Label>
                              </div>
                            </div>
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
              </Col>

              <Col xl={6} className="mt-3">
                <div>
                  <p>Rate Code</p>
                  <div className="responsiveFilterDiv">
                    {Object?.entries(filter?.rateCode)?.map(
                      ([key, value], index) => {
                        return (
                          <div className="form-groups">
                            <div className="checkbox-container">
                              <div className="form-checkbox">
                                <input
                                  id={`rc${key}`}
                                  type="checkbox"
                                  className="form-check-input"
                                  checked={value}
                                  onChange={(e) => {
                                    setFilter({
                                      ...filter,
                                      rateCode: {
                                        ...filter.rateCode,
                                        [key]: e.target.checked,
                                      },
                                    });
                                  }}
                                />
                                <Form.Check.Label htmlFor={`rc${key}`}>
                                  {key}
                                </Form.Check.Label>
                              </div>
                            </div>
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
              </Col>

              <Col xl={6} className="mt-3">
                <div>
                  <p>Rate Code Description</p>
                  <div className="responsiveFilterDiv">
                    {Object?.entries(filter?.rateCodeDescription)?.map(
                      ([key, value], index) => {
                        return (
                          <div className="form-groups">
                            <div className="checkbox-container">
                              <div className="form-checkbox">
                                <input
                                  id={`rcd${key}`}
                                  type="checkbox"
                                  className="form-check-input"
                                  checked={value}
                                  onChange={(e) => {
                                    setFilter({
                                      ...filter,
                                      rateCodeDescription: {
                                        ...filter.rateCodeDescription,
                                        [key]: e.target.checked,
                                      },
                                    });
                                  }}
                                />
                                <Form.Check.Label htmlFor={`rcd${key}`}>
                                  {key}
                                </Form.Check.Label>
                              </div>
                            </div>
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
              </Col>
              <Col xl={6}></Col>

              <Col xl={6} className="mt-3">
                <div>
                  <p>Lead Time</p>
                  <Row>
                    <Col xl={6}>
                      <div className="from-groups no-border-break">
                        <Form.Select
                          aria-label="Default select example"
                          value={filter?.leadTimeOperator}
                          onChange={(e) => {
                            setFilter({
                              ...filter,
                              leadTimeOperator: e.target.value,
                            });
                          }}
                        >
                          <option value=">=">{">="}</option>
                          <option value="<=">{"<="}</option>
                          <option value="=">{"="}</option>
                        </Form.Select>
                      </div>
                    </Col>
                    <Col xl={6}>
                      <div className="from-groups no-border-break">
                        <Form.Control
                          type="text"
                          value={filter?.leadTime}
                          onChange={(e) => {
                            setFilter({
                              ...filter,
                              leadTime: e.target.value,
                            });
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>

              <Col xl={6} className="mt-3">
                <div>
                  <p>LOS</p>
                  <Row>
                    <Col xl={6}>
                      <div className="from-groups no-border-break">
                        <Form.Select
                          aria-label="Default select example"
                          value={filter?.losOperator}
                          onChange={(e) => {
                            setFilter({
                              ...filter,
                              losOperator: e.target.value,
                            });
                          }}
                        >
                          <option value=">=">{">="}</option>
                          <option value="<=">{"<="}</option>
                          <option value="=">{"="}</option>
                        </Form.Select>
                      </div>
                    </Col>
                    <Col xl={6}>
                      <div className="from-groups no-border-break">
                        <Form.Control
                          type="text"
                          value={filter?.los}
                          onChange={(e) => {
                            setFilter({
                              ...filter,
                              los: e.target.value,
                            });
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Col>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default AccountFilter;
