import React from "react";
import { Route, Routes } from "react-router-dom";
import LayoutConfig from "./Configuration/LayoutConfig";
import LayoutRevenue from "./RevenueManagement/LayoutRevenueManagement";
import LayoutDashboard from "./Dashboard/LayoutDashboard";
import LayoutCRM from "./CRM/LayoutCRM";
import HeaderCalendar from "./Header/HeaderCalendar";
import LayoutOperation from "./Operation/LayoutOperation";
import { API_URL } from "../utils/apiRoutes";

function Layout() {

    return (
        <>
            <Routes>
                <Route path="/dashboard" element={<LayoutDashboard />} />
                <Route path="/configuration/*" element={<LayoutConfig />} />
                <Route path="/operation/*" element={<LayoutOperation />} />
                <Route path="/revenue/*" element={<LayoutRevenue />} />
                {/*{*/}
                {/*    (window.location.host === API_URL.BASE_API_URL.EMA_REV_SUB_DOMAIN || window.location.host === "localhost:3000") &&*/}
                {/*    <Route path="/revenue/*" element={<LayoutRevenue />} />*/}
                {/*}*/}
                <Route path="/crm/*" element={<LayoutCRM />} />
                <Route path="/header-events" element={<HeaderCalendar />} />
            </Routes>
        </>
    );
}

export default Layout;
