import { validationRule } from ".././utils/constant";

export const checkValidation = (obj) => {
  
  obj.errorObj = {};
  obj.isValidate = true;
  let emailValidationRegEx = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
  let phoneRegEx = /^\+[0-9]{1,4} [0-9]{6,15}(?:x.+)?$/;
  obj.validationRule?.map((validationItem) => {
    
    if (validationItem.rule === validationRule.required) {
      if (typeof obj[validationItem.fieldName] === "number") {
        let numberToString = obj[validationItem.fieldName].toString();
        if (numberToString.trim() === "") {
          obj = { ...obj, isValidate: false };
          obj.errorObj = {
            ...obj.errorObj,
            [validationItem.fieldName]: validationItem.message,
          };
        }
      } else if (
        typeof obj[validationItem.fieldName] === "string" &&
        obj[validationItem.fieldName].trim() === ""
      ) {
        obj = { ...obj, isValidate: false };
        obj.errorObj = {
          ...obj.errorObj,
          [validationItem.fieldName]: validationItem.message,
        };
      } else if (
        obj[validationItem.fieldName] === null ||
        obj[validationItem.fieldName] === undefined
      ) {
        obj = { ...obj, isValidate: false };
        obj.errorObj = {
          ...obj.errorObj,
          [validationItem.fieldName]: validationItem.message,
        };
      }
    }

    if (
      validationItem.rule === validationRule.email &&
      obj[validationItem.fieldName]?.length > 0 &&
      !emailValidationRegEx.test(obj[validationItem.fieldName])
    ) {
      obj = { ...obj, isValidate: false };
      if (!obj.errorObj[validationItem.fieldName]) {
        obj.errorObj = {
          ...obj.errorObj,
          [validationItem.fieldName]: validationItem.message,
        };
      }
    }

    if (
      validationItem.rule === validationRule.phone &&
      obj[validationItem.fieldName] !== "" &&
      !phoneRegEx.test(obj[validationItem.fieldName])
    ) {
      obj = { ...obj, isValidate: false };
      if (!obj.errorObj[validationItem.fieldName]) {
        obj.errorObj = {
          ...obj.errorObj,
          [validationItem.fieldName]: validationItem.message,
        };
      }
    }

    if (validationItem.rule === validationRule.dropDown) {
      if (
        obj[validationItem.fieldName] === "" ||
        obj[validationItem.fieldName] === "Select"
      ) {
        obj = { ...obj, isValidate: false };
        obj.errorObj = {
          ...obj.errorObj,
          [validationItem.fieldName]: validationItem.message,
        };
      }
    }

    if (
      validationItem.rule === validationRule.max &&
      obj[validationItem.fieldName] > obj?.max
    ) {
      obj = { ...obj, isValidate: false };
      if (!obj.errorObj[validationItem.fieldName]) {
        obj.errorObj = {
          ...obj.errorObj,
          [validationItem.fieldName]: validationItem.message,
        };
      }
    }
  });

  return obj;
};
