import React, { useState } from "react";
import { Col, Container, Modal, Tab, Tabs } from "react-bootstrap";
import Event from "./RateIqSetting/Event";
import WeekDay from "./RateIqSetting/WeekDay";
import CustomerType from "./RateIqSetting/CustomerType";
import Los from "./RateIqSetting/Los";
import Yield from "./RateIqSetting/Yield";

const RateIqSettingModal = ({ showModal, closeModal }) => {
  const [activeTab, setActiveTab] = useState("Yield");
  return (
    <>
      <Modal
        className="AddPropertyScheduleModalMain"
        size="xl"
        centered
        show={showModal}
        onHide={closeModal}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Configuration
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="overflow-hidden my-tab-content-height">
          <Tabs
            defaultActiveKey="Yield"
            id="uncontrolled-tab-example"
            className="mb-3"
            variant="underline"
            onSelect={(k) => {
              setActiveTab(k);
            }}
          >
            <Tab eventKey="Yield" title="Yield">
              {activeTab === "Yield" && <Yield />}
            </Tab>
            <Tab eventKey="LOS" title="LOS">
              {activeTab === "LOS" && <Los />}
            </Tab>
            <Tab eventKey="Customer Type" title="Customer Type">
              {activeTab === "Customer Type" && <CustomerType />}
            </Tab>
            <Tab eventKey="Week Day" title="Week Day">
              {activeTab === "Week Day" && <WeekDay />}
            </Tab>
            <Tab eventKey="Event" title="Event">
              {activeTab === "Event" && <Event />}
            </Tab>
          </Tabs>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default RateIqSettingModal;
