import React, { useEffect, useState } from "react";
import { apiCall } from "../../../../utils/axiosService";
import { apiResponse } from "../../../../utils/apiResponse";
import { commonService } from "../../../../utils/commonService";
import { API_URL } from "../../../../utils/apiRoutes";
import Loader from "../../../Loader/Loader";
import DatePicker from "react-datepicker";
import { Table } from "react-bootstrap";
import Star from "../../../../Assets/Images/Revenue-management/star.svg";

const RatesCount = ({
  setCompDataFromChild,
  rowIndex,
  colIndex,
  api,
  para,
  setting,
}) => {
  const date = new Date();
  const startDate = new Date(date.getFullYear(), date.getMonth(), 1);
  const endDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  const [startRangeDate, setStartRangeDate] = useState(startDate);
  const [endRangeDate, setEndRangeDate] = useState(endDate);
  const [loading, setLoading] = useState(false);
  const [ratesCountData, setRatesCountData] = useState(null);

  const getRatingCount = async () => {
    setLoading(true);
    let ratingCountResponse = await apiCall(
      {
        method: "POST",
        url: API_URL.BASE_API_URL.SnapShot + API_URL.ANALYTICS.RATING_COUNT,
        body: {
          startDate: commonService.getDateForNode(startRangeDate),
          endDate: commonService.getDateForNode(endRangeDate),
          propertyCode: para?.propertyCode,
        },
      },
      false
    );

    let response = apiResponse(false, ratingCountResponse);

    if (response?.isValidate) {
      setRatesCountData(response?.data?.data);
      setLoading(false);
    }

    if (!response?.isValidate) {
      setRatesCountData(null);
      setLoading(false);
    }
  };

  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setStartRangeDate(start);
    setEndRangeDate(end);
  };

  useEffect(() => {
    if (api === 0) {
    } else {
      if (startRangeDate && endRangeDate) {
        getRatingCount();
      }
    }
  }, [startRangeDate, endRangeDate]);

  return (
    <div>
      <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
        <div className="width175">
          <DatePicker
            onChange={(event) => handleDateChange(event)}
            startDate={startRangeDate}
            endDate={endRangeDate}
            selectsRange
            monthsShown={2}
          />
        </div>
      </div>

      <div className="table__container mt-3">
        <div className="stripped__table">
          <div className="table-responsive">
            <Table>
              <thead className="tbl-header-sticky my-tbl-header-sticky">
                <tr>
                  <th>Property</th>
                  <th className="width100">
                    <img src={Star} style={{ height: "15px" }} />
                    <img src={Star} style={{ height: "15px" }} />
                    <img src={Star} style={{ height: "15px" }} />
                    <img src={Star} style={{ height: "15px" }} />
                    <img src={Star} style={{ height: "15px" }} />
                  </th>
                  <th className="width100">
                    <img src={Star} style={{ height: "15px" }} />
                    <img src={Star} style={{ height: "15px" }} />
                    <img src={Star} style={{ height: "15px" }} />
                    <img src={Star} style={{ height: "15px" }} />
                  </th>
                  <th className="width100">
                    <img src={Star} style={{ height: "15px" }} />
                    <img src={Star} style={{ height: "15px" }} />
                    <img src={Star} style={{ height: "15px" }} />
                  </th>
                  <th className="width100">
                    <img src={Star} style={{ height: "15px" }} />
                    <img src={Star} style={{ height: "15px" }} />
                  </th>
                  <th className="width100">
                    <img src={Star} style={{ height: "15px" }} />
                  </th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr style={{ height: "80px", position: "relative" }}>
                    <Loader />
                  </tr>
                ) : (
                  ratesCountData?.map((item) => {
                    return (
                      <tr key={item?.propertyname} className="shadowOnHover">
                        <td>{item?.propertyname}</td>
                        <td>{item?.rating_5_star}</td>
                        <td>{item?.rating_4_star}</td>
                        <td>{item?.rating_3_star}</td>
                        <td>{item?.rating_2_star}</td>
                        <td>{item?.rating_1_star}</td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RatesCount;
