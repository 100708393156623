import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { apiCall } from "../../../utils/axiosService";
import { API_URL } from "../../../utils/apiRoutes";
import { apiResponse } from "../../../utils/apiResponse";
import { Button, Col, Form, Table } from "react-bootstrap";
import plusIcon from "../../../Assets/Images/FormCheck/plusIcon.png";
import deleteIcon from "../../../Assets/Images/Action Icons/deleteaction.svg";
import Loader from "../../Loader/Loader";
import Skeleton from "react-loading-skeleton";

const Los = () => {
  const propertyDetailFromRTK = useSelector(
    (store) => store.headerProperty.propertyDetail
  );
  const crmPropertyDetailFromRTK = useSelector(
    (store) => store.headerProperty.crmPropertyDetail
  );
  const [losData, setLosData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const losDataRef = useRef();
  losDataRef.current = losData;

  const getLosDetail = async () => {
    setLoading(true);
    let losResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.RevenueManagement + API_URL.RATEIQ_LOS.GET_LIST,
        body: {
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
        },
      },
      false
    );

    let response = apiResponse(false, losResponse);

    if (response?.isValidate) {
      setLosData(response?.data?.data);
      setLoading(false);
    }

    if (!response?.isValidate) {
      setLosData([]);
      setLoading(false);
    }
  };

  const handleSubmit = async () => {
    setSubmitLoading(true);
    let saveResponse = await apiCall(
      {
        method: "POST",
        url: API_URL.BASE_API_URL.RevenueManagement + API_URL.RATEIQ_LOS.SAVE,
        body: {
          F: losDataRef.current,
        },
      },
      true
    );

    let response = apiResponse(true, saveResponse, setSubmitLoading);

    if (response?.isValidate) {
    }

    if (!response?.isValidate) {
    }
  };

  useEffect(() => {
    getLosDetail();
  }, []);
  return (
    <div>
      <Col xl={12}>
        <div className="table__container">
          <div className="stripped__table">
            <div className="table-responsive maxHeight400">
              <Table>
                <thead className="tbl-header-sticky my-tbl-header-sticky">
                  <tr>
                    <th>Min</th>
                    <th>Max</th>
                    <th>%In Rate</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr>
                      <td>
                        <Skeleton count={5} />
                      </td>
                      <td>
                        <Skeleton count={5} />
                      </td>
                      <td>
                        <Skeleton count={5} />
                      </td>
                      <td>
                        <Skeleton count={5} />
                      </td>
                    </tr>
                  ) : losData?.length > 0 ? (
                    losData?.map((item, index) => {
                      return (
                        <tr className="shadowOnHover">
                          <td>
                            <Form.Control
                              type="text"
                              maxLength={6}
                              defaultValue={item?.minlos}
                              onChange={(e) => {
                                losDataRef.current[index].minlos =
                                  e.target.value;
                              }}
                            />
                          </td>
                          <td>
                            <Form.Control
                              type="text"
                              maxLength={6}
                              defaultValue={item?.maxlos}
                              onChange={(e) => {
                                losDataRef.current[index].maxlos =
                                  e.target.value;
                              }}
                            />
                          </td>
                          <td>
                            <Form.Control
                              type="text"
                              maxLength={6}
                              defaultValue={item?.ratevalue}
                              onChange={(e) => {
                                losDataRef.current[index].ratevalue =
                                  e.target.value;
                              }}
                            />
                          </td>
                          <td>
                            <div className="Allactions">
                              <img
                                src={deleteIcon}
                                alt=""
                                onClick={() => {
                                  let updatedData = losDataRef.current?.filter(
                                    (x, i) => i !== index
                                  );

                                  setLosData(updatedData);
                                }}
                              />
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr className="text-center">
                      <td colSpan={4}>No Data Found</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </Col>
      <div className="rate-iq-setting-modal gap-2 footer-buttons pt-2">
        <Button
          className="add-btn-plus"
          type="button"
          title="Add"
          onClick={() => {
            setLosData([
              ...losData,
              {
                rateiqlosid: 0,
                minlos: "",
                maxlos: "",
                propertyid: window.location.href.includes("/crm")
                  ? crmPropertyDetailFromRTK?.propertyid
                  : propertyDetailFromRTK?.propertyid,
                ratevalue: "",
              },
            ]);
          }}
        >
          <img src={plusIcon} alt="plusIcon" /> Add
        </Button>
        {submitLoading ? (
          <button className="default-btn primary-btn" type="button" disabled>
            <span
              className="spinner-border spinner-border-sm me-3"
              role="status"
              aria-hidden="true"
            ></span>
            Loading...
          </button>
        ) : (
          <button
            className="default-btn primary-btn"
            onClick={() => {
              handleSubmit();
            }}
          >
            Submit
          </button>
        )}
      </div>
    </div>
  );
};

export default Los;
