import AsideRevenueManagement from "./AsideRevenueManagement";
import { Link, Navigate, Route, Routes } from "react-router-dom";
import Header from "../Header/Header";
import React from "react";
import botman from "../../Assets/Images/botgif.gif";
import Loader from "../Loader/Loader";
import { commonService } from "../../utils/commonService";
import { useSelector } from "react-redux";
import RevenueSidebar from "./RevenueSidebar";
import WeatherPage from "./WeatherPage";
import FlightPage from "./FlightPage";
const RevPakArchive = React.lazy(() =>
  import("../RevenueManagement/ListingComponent/RevPak/RevPakArchive")
);
const RmEventListingPage = React.lazy(() => import("./RmEventListingPage"));
const RmEventsWeekView = React.lazy(() => import("./RmEventsWeekView"));
const RmEvents = React.lazy(() => import("./RmEvents"));
const RateShopGraphView = React.lazy(() => import("./RateShopGraphView"));
const RateShopTableView = React.lazy(() => import("./RateShopTableView"));
const RMRateShop = React.lazy(() => import("./RMRateShop"));
const RmBenchMarking = React.lazy(() => import("./RmBenchMarking"));
const RmDashboard = React.lazy(() => import("./RmDashboard"));
const Snapshot = React.lazy(() => import("./Snapshot"));
const Strategy = React.lazy(() => import("./Strategy"));
const SegmentDrillDown = React.lazy(() => import("./SegmentDrillDown"));
const LengthOfStay = React.lazy(() => import("./LengthOfStay"));
const LyAndBudget = React.lazy(() => import("./LyAndBudget"));
const GroupEvent = React.lazy(() => import("./GroupEvent"));
const RatePlanAndCompanyAnalysis = React.lazy(() =>
  import("./RatePlanAndCompanyAnalysis")
);
const ForcastAndBudget = React.lazy(() => import("./ForecastAndBudget"));
const PortfolioSnapshot = React.lazy(() => import("./PortfolioSnapshot"));
const EditRMDashboard = React.lazy(() => import("./EditRMDashboard"));
const PropertySetting = React.lazy(() => import("./PropertySetting"));
const Setting = React.lazy(() => import("./propertySetting/Settings"));
const UserManagement = React.lazy(() => import("./UserManagement"));
const RoleManagement = React.lazy(() => import("./RoleManagement"));
const ViewListing = React.lazy(() => import("./ViewListing"));
const BusinessIntelligence = React.lazy(() => import("./BusinessIntelligence"));
const CustomizeComponent = React.lazy(() => import("./CustomizeComponent"));
const Reminder = React.lazy(() => import("./Reminder"));
const RateIq = React.lazy(() => import("./RMRateIq"));
const RateIqCalender = React.lazy(() => import("./RMRateIqCalenderView"));
const DisplacementCalc = React.lazy(() => import("./DisplacementCalc"));
const EventMapView = React.lazy(() => import("./EventMapView"));
const PerityCheck = React.lazy(() => import("./RMPerityCheck"));
const Chat = React.lazy(() => import("./ChatPage"));
const PortfolioRollup = React.lazy(() =>
  import("./PortfolioRollup/Portfoliorollup")
);
const RmRevenueGlance = React.lazy(() => import("./RmRevenueGlance"));
const RmRevPakArchive = React.lazy(() => import("./RmRevPakArchive"));
const StrReport = React.lazy(()=> import("./ListingComponent/Email/STRReport"))

function LayoutRevenueManagement() {
  const token = commonService.getItem("token");
  const sidebarOpen = useSelector(
    (store) => store.headerProperty.isSidebarOpen
  );
  return (
    <div
      className={`mainRoot ${sidebarOpen ? "full-menu" : "icon-toggle-menu"}`}
    >
      <Header />
      <AsideRevenueManagement />
      <main className={"revenue-management-page-body container-data-load"}>
        <Routes>
          <Route
            path="/revenue-management-dashboard"
            element={
              token ? (
                <React.Suspense fallback={<Loader />}>
                  <RmDashboard />
                </React.Suspense>
              ) : (
                <Navigate to="/" replace />
              )
            }
          />

          <Route
            path="/snapshot"
            element={
              token ? (
                <React.Suspense fallback={<Loader />}>
                  <Snapshot />
                </React.Suspense>
              ) : (
                <Navigate to="/" replace />
              )
            }
          />
          <Route
            path="/strategy"
            element={
              token ? (
                <React.Suspense fallback={<Loader />}>
                  <Strategy />
                </React.Suspense>
              ) : (
                <Navigate to="/" replace />
              )
            }
          />
          <Route
            path="/segment-drill-down"
            element={
              token ? (
                <React.Suspense fallback={<Loader />}>
                  <SegmentDrillDown />
                </React.Suspense>
              ) : (
                <Navigate to="/" replace />
              )
            }
          />
          <Route
            path="/archive"
            element={
              token ? (
                <React.Suspense fallback={<Loader />}>
                  <RevPakArchive />
                </React.Suspense>
              ) : (
                <Navigate to="/" replace />
              )
            }
          />
          <Route
            path="/length-of-stay"
            element={
              token ? (
                <React.Suspense fallback={<Loader />}>
                  <LengthOfStay />
                </React.Suspense>
              ) : (
                <Navigate to="/" replace />
              )
            }
          />
          <Route
            path="/ly-and-budget"
            element={
              token ? (
                <React.Suspense fallback={<Loader />}>
                  <LyAndBudget />
                </React.Suspense>
              ) : (
                <Navigate to="/" replace />
              )
            }
          />
          <Route
            path="/group-event"
            element={
              token ? (
                <React.Suspense fallback={<Loader />}>
                  <GroupEvent />
                </React.Suspense>
              ) : (
                <Navigate to="/" replace />
              )
            }
          />
          <Route
            path="/rate-plan-and-company-analysis"
            element={
              token ? (
                <React.Suspense fallback={<Loader />}>
                  <RatePlanAndCompanyAnalysis />
                </React.Suspense>
              ) : (
                <Navigate to="/" replace />
              )
            }
          />
          <Route
            path="/forcast-and-budget"
            element={
              token ? (
                <React.Suspense fallback={<Loader />}>
                  <ForcastAndBudget />
                </React.Suspense>
              ) : (
                <Navigate to="/" replace />
              )
            }
          />
          <Route
            path="/event"
            element={
              token ? (
                <React.Suspense fallback={<Loader />}>
                  <RmEvents />
                </React.Suspense>
              ) : (
                <Navigate to="/" replace />
              )
            }
          />
          <Route
            path="/rateIq"
            element={
              token ? (
                <React.Suspense fallback={<Loader />}>
                  <RateIq />
                </React.Suspense>
              ) : (
                <Navigate to="/" replace />
              )
            }
          />
          <Route
            path="/rateIq-calender"
            element={
              token ? (
                <React.Suspense fallback={<Loader />}>
                  <RateIqCalender />
                </React.Suspense>
              ) : (
                <Navigate to="/" replace />
              )
            }
          />
          <Route
            path="/event/listing-page"
            element={
              token ? (
                <React.Suspense fallback={<Loader />}>
                  <RmEventListingPage />
                </React.Suspense>
              ) : (
                <Navigate to="/" replace />
              )
            }
          />
          <Route
            path="/event/weekView"
            element={
              token ? (
                <React.Suspense fallback={<Loader />}>
                  <RmEventsWeekView />
                </React.Suspense>
              ) : (
                <Navigate to="/" replace />
              )
            }
          />
          <Route
            path="/event/mapView"
            element={
              token ? (
                <React.Suspense fallback={<Loader />}>
                  <EventMapView />
                </React.Suspense>
              ) : (
                <Navigate to="/" replace />
              )
            }
          />
          <Route
            path="/snapshot/portfolio-snapshot"
            element={
              token ? (
                <React.Suspense fallback={<Loader />}>
                  <PortfolioSnapshot />
                </React.Suspense>
              ) : (
                <Navigate to="/" replace />
              )
            }
          />
          <Route
            path="/edit"
            element={
              token ? (
                <React.Suspense fallback={<Loader />}>
                  <EditRMDashboard />
                </React.Suspense>
              ) : (
                <Navigate to="/" replace />
              )
            }
          />
          <Route
            path="/property-setting/:id"
            element={
              token ? (
                <React.Suspense fallback={<Loader />}>
                  <PropertySetting />
                  {/* <Setting /> */}
                </React.Suspense>
              ) : (
                <Navigate to="/" replace />
              )
            }
          />
          <Route
            path="/user-management"
            element={
              token ? (
                <React.Suspense fallback={<Loader />}>
                  <UserManagement />
                </React.Suspense>
              ) : (
                <Navigate to="/" replace />
              )
            }
          />
          <Route
            path="/role-management"
            element={
              token ? (
                <React.Suspense fallback={<Loader />}>
                  <RoleManagement />
                </React.Suspense>
              ) : (
                <Navigate to="/" replace />
              )
            }
          />
          <Route
            path="/business-intelligence"
            element={
              token ? (
                <React.Suspense fallback={<Loader />}>
                  <BusinessIntelligence moduleName={"Revenue"} />
                </React.Suspense>
              ) : (
                <Navigate to="/" replace />
              )
            }
          />
          <Route
            path="/rate-shop"
            element={
              token ? (
                <React.Suspense fallback={<Loader />}>
                  <RMRateShop />
                </React.Suspense>
              ) : (
                <Navigate to="/" replace />
              )
            }
          />
          <Route
            path="/bench-marking"
            element={
              token ? (
                <React.Suspense fallback={<Loader />}>
                  <RmBenchMarking />
                </React.Suspense>
              ) : (
                <Navigate to="/" replace />
              )
            }
          />
          <Route
            path="/rate-shop/rate-shop-table-view"
            element={
              token ? (
                <React.Suspense fallback={<Loader />}>
                  <RateShopTableView />
                </React.Suspense>
              ) : (
                <Navigate to="/" replace />
              )
            }
          />
          <Route
            path="/rate-shop/rate-shop-graphical-view"
            element={
              token ? (
                <React.Suspense fallback={<Loader />}>
                  <RateShopGraphView />
                </React.Suspense>
              ) : (
                <Navigate to="/" replace />
              )
            }
          />
          <Route
            path="/perity-check"
            element={
              token ? (
                <React.Suspense fallback={<Loader />}>
                  <PerityCheck />
                </React.Suspense>
              ) : (
                <Navigate to="/" replace />
              )
            }
          />
          <Route
            path="snapshot/:snapshottitle/:propertyCode/:snapshotId"
            element={
              token ? (
                <React.Suspense fallback={<Loader />}>
                  <CustomizeComponent />
                </React.Suspense>
              ) : (
                <Navigate to="/" replace />
              )
            }
          />
          <Route
            path="/reminder"
            element={
              token ? (
                <React.Suspense fallback={<Loader />}>
                  <Reminder />
                </React.Suspense>
              ) : (
                <Navigate to="/" replace />
              )
            }
          />
          <Route
            path="/displacement-calculator"
            element={
              token ? (
                <React.Suspense fallback={<Loader />}>
                  <DisplacementCalc />
                </React.Suspense>
              ) : (
                <Navigate to="/" replace />
              )
            }
          />
          <Route
            path="/weather"
            element={
              token ? (
                <React.Suspense fallback={<Loader />}>
                  <WeatherPage />
                </React.Suspense>
              ) : (
                <Navigate to="/" replace />
              )
            }
          />
          <Route
            path="/flight"
            element={
              token ? (
                <React.Suspense fallback={<Loader />}>
                  <FlightPage />
                </React.Suspense>
              ) : (
                <Navigate to="/" replace />
              )
            }
          />
          <Route
            path="/chat"
            element={
              token ? (
                <React.Suspense fallback={<Loader />}>
                  <Chat />
                </React.Suspense>
              ) : (
                <Navigate to="/" replace />
              )
            }
          />
          <Route
            path="/portfoliorollup/:portfolioId"
            element={
              token ? (
                <React.Suspense fallback={<Loader />}>
                  <PortfolioRollup />
                </React.Suspense>
              ) : (
                <Navigate to="/" replace />
              )
            }
          />
          <Route
            path="/revenueglance/:propertyCode/:propertyId"
            element={
              token ? (
                <React.Suspense fallback={<Loader />}>
                  <RmRevenueGlance />
                </React.Suspense>
              ) : (
                <Navigate to="/" replace />
              )
            }
          />
          <Route
            path="/revpak-archive"
            element={
              token ? (
                <React.Suspense fallback={<Loader />}>
                  <RmRevPakArchive />
                </React.Suspense>
              ) : (
                <Navigate to="/" replace />
              )
            }
          />
          <Route
            path="/str-report"
            element={
              token ? (
                <React.Suspense fallback={<Loader />}>
                  <StrReport />
                </React.Suspense>
              ) : (
                <Navigate to="/" replace />
              )
            }
          />
        </Routes>
        <Link to="/revenue/chat" className="common-chat-bot-nova">
          <div className="common-chat-bot-nova-inner">
            <img src={botman} alt="botman" />
            {/* <h6>Say Hello 👋 </h6> */}
            <h6>Nova</h6>
          </div>
        </Link>
      </main>
    </div>
  );
}

export default LayoutRevenueManagement;
