import React, { useEffect, useRef, useState } from "react";
import { apiCall } from "../../../../utils/axiosService";
import { API_URL } from "../../../../utils/apiRoutes";
import { apiResponse } from "../../../../utils/apiResponse";
import DatePicker from "react-datepicker";
import NoteIcon from "../../../../Assets/Images/CRM/notes.svg";
import downloadIcon from "../../../../Assets/Images/Operation/downloadIcon.svg";
import { commonService } from "../../../../utils/commonService";
import { Button, Form, Overlay, Popover, Table } from "react-bootstrap";
import Loader from "../../../Loader/Loader";
import moment from "moment";
import SingleDateSummaryModal from "../../SingleDateSummaryModal";
import SingleDateRMSModal from "../../SingleDateRMSModal";
import WidgetDetailModal from "../../WidgetDetailModal";
import { compName } from "../../../../utils/constant";
import infoIcon from "../../../../Assets/Images/Action Icons/infoIcon..svg";
import WidgetNote from "../../WidgetNote";
import { useSelector } from "react-redux";
import AddWidgetModal from "../../AddWidgetModal";
import { useParams } from "react-router-dom";
import emailIcon from "../../../../Assets/Images/Email.svg";
import { errorToastMessage } from "../../../../utils/toastMessage";
import { FixColumn } from "../../../../utils/dataTable";

const StrDayByDay = ({
  setCompDataFromChild,
  rowIndex,
  colIndex,
  api,
  para,
  setting,
  selectedDateForApiCall,
}) => {
  const otbTableData = [];
  const dbdTableData = [];

  let ooo = 0,
    RmsAvailable = 0,
    leftToSell = 0,
    otb = 0,
    occPercentage = 0,
    adr = 0,
    rev = 0,
    revPar = 0,
    forecastRms = 0;

  //   let curr = new Date(selectedDateForApiCall);
  //   let first = curr.getDate() - curr.getDay();
  //   let last = first + 6;

  //   let firstday = new Date(curr.setDate(first));
  //   let lastday = new Date(curr.setDate(last));

  function getWeekStartAndEnd(date) {
    const currentDate = new Date(date);

    const weekStart = new Date(currentDate);
    weekStart.setDate(currentDate.getDate() - currentDate.getDay());

    const weekEnd = new Date(currentDate);
    weekEnd.setDate(currentDate.getDate() + (6 - currentDate.getDay()));

    return {
      start: weekStart,
      end: weekEnd,
    };
  }

  const inputDate = new Date(selectedDateForApiCall);
  const weekDates = getWeekStartAndEnd(inputDate);
  let firstday = new Date(weekDates.start);
  let lastday = new Date(weekDates.end);
  const userDetail = commonService.getLoginUserData();

  const date = new Date();
  const startDate = commonService.getDateInDBFormat(
    //   new Date(date.getFullYear(), date.getMonth(), 1)
    new Date(firstday)
  );
  const endDate = commonService.getDateInDBFormat(
    //   new Date(date.getFullYear(), date.getMonth() + 1, 0)
    new Date(lastday)
  );
  const [dbdData, setDbdData] = useState(null);
  const [otbData, setOtbData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [otbLoading, setOtbLoading] = useState(false);
  const [startRangeDate, setStartRangeDate] = useState();
  const [endRangeDate, setEndRangeDate] = useState();
  const [selectedType, setSelectedType] = useState({
    netStly: true,
    pickup: false,
  });
  const [selectPickupDate, setSelectPickupDate] = useState();
  const startRangeDateRef = useRef();
  const endRangeDateRef = useRef();
  const [maxDateForPickupFrom, setMaxDateForPickupFrom] = useState();
  const [forecastData, setForecastData] = useState(null);
  const [forecastLoading, setForecastLoading] = useState(null);
  const [selectedForecast, setSelectedForecast] = useState("EMA");
  const [ONTHEBOOKS, setONTHEBOOKS] = useState({
    IsDisplay: false,
    Target: null,
    Position: "top",
  });
  const [rms, setRms] = useState({
    IsDisplay: false,
    Target: null,
    Position: "top",
  });
  const rmsSingleDateRef = useRef();
  const rmsSelectedTdIndexRef = useRef();
  const rmsSelectedMarketSegmentRef = useRef();
  const dateRangeObj = useRef();
  const maxDateRef = useRef();
  const marketSegmentData = useRef();
  const selectedTdIndexRef = useRef();
  const singleDateSelectedDateRef = useRef();
  const singleDatePickupDateRef = useRef();
  const marketSegmentTotal = useRef();
  const [widgetModal, setWidgetModal] = useState(false);
  const widgetDetailRef = useRef();
  const [showNoteSidebar, setShowNoteSidebar] = useState(false);
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const propertyDetailFromRTK = useSelector(
    (store) => store.headerProperty.propertyDetail
  );
  const defaultAsOfDateFromRTK = useSelector(
    (store) => store.headerProperty.defaultAsOfDate
  );
  const { snapshottitle, propertyCode, snapshotId } = useParams();
  const closeNoteSideBar = () => {
    setShowNoteSidebar(false);
  };

  const closeWidgetModal = () => {
    setWidgetModal(false);
  };
  if (maxDateForPickupFrom) {
    let date = new Date(maxDateForPickupFrom);
    maxDateRef.current = date.setDate(date.getDate() - 1);
  }

  const handleFilterBtnClick = (event) => {
    let Position = "bottom";
    let Top = event.nativeEvent.layerY - 2;
    if (Top + 400 > window.innerHeight) {
      Position = "top";
    }
    setONTHEBOOKS({
      IsDisplay: true,
      Target: event.target,
      Position: Position,
    });
  };
  const closeSingleDate = () => {
    selectedTdIndexRef.current = -1;
    setONTHEBOOKS({
      IsDisplay: false,
      Target: null,
      Position: "bottom",
    });
  };

  const handleRmsClick = (event) => {
    let Position = "bottom";
    let Top = event.nativeEvent.layerY - 2;
    if (Top + 400 > window.innerHeight) {
      Position = "top";
    }
    setRms({
      IsDisplay: true,
      Target: event.target,
      Position: Position,
    });
  };

  const closeRmsModal = () => {
    rmsSelectedTdIndexRef.current = -1;
    setRms({ IsDisplay: false, Target: null, Position: "bottom" });
  };

  const handleDateChange = (event) => {
    const [start, end] = event;
    let startSelectedDate = "",
      endSelectedDate = "";
    if (start) {
      let date = new Date(start);
      let month =
        date.getMonth() + 1 > 9
          ? date.getMonth() + 1
          : `0${date.getMonth() + 1}`;
      let dateFormat =
        date?.getDate() > 9 ? date.getDate() : `0${date.getDate()}`;
      startSelectedDate = `${date.getFullYear()}-${month}-${dateFormat}`;
      startRangeDateRef.current = startSelectedDate;
    }
    if (end) {
      let date = new Date(end);
      let month =
        date.getMonth() + 1 > 9
          ? date.getMonth() + 1
          : `0${date.getMonth() + 1}`;
      let dateFormat =
        date?.getDate() > 9 ? date.getDate() : `0${date.getDate()}`;
      endSelectedDate = `${date.getFullYear()}-${month}-${dateFormat}`;
      endRangeDateRef.current = endSelectedDate;
    }

    setStartRangeDate(event[0]);
    setEndRangeDate(event[1]);
    if (event[0] !== null && event[1] !== null) {
      // setDbdData(null);
      // setOtbData(null);
      // dateRangeObj.current = commonService.datesBetweenTwoDate(
      //   startRangeDateRef.current ? startRangeDateRef.current : startDate,
      //   endRangeDateRef.current ? endRangeDateRef.current : endDate
      // );
      // if (selectedType?.netStly) {
      //   getDbdStlyData();
      // } else {
      //   getDbdPickupData();
      // }
      // getORGDetail();
    }
  };

  const diffDayStartDate = new Date(
    commonService.getDateInFormat(
      startRangeDateRef.current ? startRangeDateRef.current : startDate
    )
  );
  const diffDayEndDate = new Date(
    commonService.getDateInFormat(
      endRangeDateRef.current ? endRangeDateRef.current : endDate
    )
  );
  const diffDayInTime = diffDayEndDate.getTime() - diffDayStartDate.getTime();
  const diffDays = diffDayInTime / (1000 * 3600 * 24);

  const getDbdPickupData = async () => {
    setLoading(true);
    let pickupDate = new Date(para?.asOfDate);
    pickupDate.setDate(pickupDate.getDate() - 1);
    let dbdPickupResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.SnapShot +
          API_URL.MARKET_SEGMENT.DAY_BY_DAY_PICKUP,
        body: {
          AsOfDate: para?.asOfDate,
          startDate: startRangeDateRef.current
            ? startRangeDateRef.current
            : startDate,
          endDate: endRangeDateRef.current ? endRangeDateRef.current : endDate,
          propertyCode: para?.propertyCode,
          PickUpDate: selectPickupDate
            ? moment(selectPickupDate).format("YYYY-MM-DD")
            : moment(pickupDate).format("YYYY-MM-DD"),
        },
      },
      false
    );

    let response = apiResponse(false, dbdPickupResponse);

    if (response?.isValidate) {
      marketSegmentData.current = [];
      marketSegmentTotal.current = {};
      response?.data?.data?.map((item, index) => {
        let marketSegmentExist = marketSegmentData.current?.filter(
          (x) => x === item?.marketSegmentation
        );
        if (marketSegmentExist?.length <= 0) {
          marketSegmentData.current = [
            ...marketSegmentData.current,
            item?.marketSegmentation,
          ];

          marketSegmentTotal.current[
            `${item?.marketSegmentation}CY_ROOMNIGHT`
          ] = 0;
          marketSegmentTotal.current[
            `${item?.marketSegmentation}PICKUP_FROM_ROOM`
          ] = 0;
          marketSegmentTotal.current[
            `${item?.marketSegmentation}CY_REVENUE`
          ] = 0;
          marketSegmentTotal.current[
            `${item?.marketSegmentation}PICKUP_REVENUE`
          ] = 0;
        }
      });
      setDbdData(response?.data?.data);
      setLoading(false);
    }

    if (!response?.isValidate) {
      setLoading(false);
    }
  };

  const getDbdStlyData = async () => {
    setLoading(true);
    let dbdStlyResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.SnapShot +
          API_URL.MARKET_SEGMENT.DAY_BY_DAY_STLY,
        body: {
          AsOfDate: para?.asOfDate,
          startDate: startRangeDateRef.current
            ? startRangeDateRef.current
            : startDate,
          endDate: endRangeDateRef.current ? endRangeDateRef.current : endDate,
          propertyCode: para?.propertyCode,
        },
      },
      false
    );

    let response = apiResponse(false, dbdStlyResponse);

    if (response?.isValidate) {
      marketSegmentData.current = [];
      marketSegmentTotal.current = {};
      response?.data?.data?.map((item, index) => {
        let marketSegmentExist = marketSegmentData.current?.filter(
          (x) => x === item?.marketSegmentation
        );
        if (marketSegmentExist?.length <= 0) {
          marketSegmentData.current = [
            ...marketSegmentData.current,
            item?.marketSegmentation,
          ];

          marketSegmentTotal.current[
            `${item?.marketSegmentation}CY_ROOMNIGHT`
          ] = 0;
          marketSegmentTotal.current[
            `${item?.marketSegmentation}LY_ROOMNIGHT`
          ] = 0;
          marketSegmentTotal.current[
            `${item?.marketSegmentation}CY_REVENUE`
          ] = 0;
          marketSegmentTotal.current[
            `${item?.marketSegmentation}LY_REVENUE`
          ] = 0;
        }
      });
      setDbdData(response?.data?.data);
      setLoading(false);
    }

    if (!response?.isValidate) {
      setLoading(false);
    }
  };

  const getORGDetail = async () => {
    setOtbLoading(true);
    let orgDetailResponse = await apiCall(
      {
        method: "POST",
        url: API_URL.BASE_API_URL.SnapShot + API_URL.DAILY_SUMMARY.ORG,
        body: {
          AsOfDate: para?.asOfDate,
          startDate: startRangeDateRef.current
            ? startRangeDateRef.current
            : startDate,
          endDate: endRangeDateRef.current ? endRangeDateRef.current : endDate,
          propertyCode: para?.propertyCode,
        },
      },
      false
    );

    let response = apiResponse(false, orgDetailResponse);

    if (response?.isValidate) {
      setOtbData(response?.data?.data);
      setOtbLoading(false);
    }

    if (!response?.isValidate) {
      setOtbLoading(false);
    }
  };

  const getForecastDetail = async () => {
    setForecastLoading(true);
    let forecastResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.SnapShot +
          API_URL.DAILY_SUMMARY.PRICING_FORECAST,
        body: {
          AsOfDate: para?.asOfDate,
          startDate: startRangeDateRef.current
            ? startRangeDateRef.current
            : startDate,
          endDate: endRangeDateRef.current ? endRangeDateRef.current : endDate,
          propertyCode: para?.propertyCode,
          ForcastType: selectedForecast,
        },
      },
      false
    );

    let response = apiResponse(false, forecastResponse);

    if (response?.isValidate) {
      setForecastData(response?.data?.data);
      setForecastLoading(false);
    }

    if (!response?.isValidate) {
      setForecastData([]);
      setForecastLoading(false);
    }
  };

  useEffect(() => {
    if (api === 0) {
      dateRangeObj.current = commonService.datesBetweenTwoDate(
        startRangeDateRef.current
          ? commonService.convertDateInTimezone(startRangeDateRef.current)
          : commonService.convertDateInTimezone(startDate),
        endRangeDateRef.current
          ? commonService.convertDateInTimezone(endRangeDateRef.current)
          : commonService.convertDateInTimezone(endDate)
      );
      let d = new Date(para?.asOfDate);
      d.setDate(d.getDate() - 1);
      setMaxDateForPickupFrom(new Date(para?.defaultAsOfDate));
      setOtbData([]);
      setDbdData([]);
    } else {
      if (para?.asOfDate) {
        dateRangeObj.current = commonService.datesBetweenTwoDate(
          startRangeDateRef.current
            ? commonService.convertDateInTimezone(startRangeDateRef.current)
            : commonService.convertDateInTimezone(startDate),
          endRangeDateRef.current
            ? commonService.convertDateInTimezone(endRangeDateRef.current)
            : commonService.convertDateInTimezone(endDate)
        );
        let d = new Date(para?.asOfDate);
        d.setDate(d.getDate() - 1);
        setMaxDateForPickupFrom(new Date(para?.defaultAsOfDate));
        getORGDetail();
        if (selectedType?.netStly) {
          getDbdStlyData();
        } else {
          getDbdPickupData();
        }
      }
    }
  }, [para?.asOfDate, selectedType, selectPickupDate, selectedDateForApiCall]);

  useEffect(() => {
    if (api === 0) {
      setForecastData([]);
    } else {
      if (para?.asOfDate) {
        getForecastDetail();
      }
    }
  }, [para?.asOfDate, selectedForecast, selectedDateForApiCall]);

  const [tblids, settblids] = useState({
    tblDayByDayMarketSegment: commonService.GenerateGUID(),
  });

  commonService.setBackColorPerByTDValue(
    "#638EC6",
    "Red",
    `#${tblids.tblDayByDayMarketSegment} .perbackground-otb`
  );
  commonService.setBackColorPerByTDValue(
    "Green",
    "Red",
    `#${tblids.tblDayByDayMarketSegment} .perbackground-adr`
  );

  const handleExport = async (type) => {
    const tableId = document?.querySelector(
      `#${tblids.tblDayByDayMarketSegment}`
    );

    if (tableId.querySelector(".loding-animation")) {
      errorToastMessage("Wait for the data loading.");
      return;
    }

    const groupingJson = {
      data: [
        {
          widgetname: "StrAnalysis",
          rowdata: [],
        },
      ],
      filename: `${para?.propertyCode}-strAnalysis-${moment(new Date()).format(
        "MMDDYYYY"
      )}`,
    };

    let groupingTable = [{ data: [] }, { data: [] }];
    let totalJson = [
      {
        widgetname: "BlankCommon",
        rowdata: [],
      },
    ];
    const getTable = document?.querySelector(
      `#${tblids.tblDayByDayMarketSegment}`
    );

    const findTblHeader = getTable?.querySelectorAll("thead tr");
    const findTblBody = getTable?.querySelectorAll("tbody tr");

    findTblHeader?.forEach((tblHeaderItem, tblHeaderIndex) => {
      const allThValue = [];
      const findAllTh = tblHeaderItem?.querySelectorAll("th");
      findAllTh?.forEach((thItem, thIndex) => {
        let thText = thItem?.innerText;
        if (tblHeaderIndex === 2 && thIndex === 8) {
          let tdSpan = thItem?.querySelector("span");
          thText = tdSpan?.innerText
            ? tdSpan?.innerText === "EMA"
              ? "System"
              : tdSpan?.innerText
            : "";
        }
        allThValue.push(thText ? thText : "");
      });
      groupingTable.push({ data: allThValue });
      if (tblHeaderIndex === 0) {
        groupingTable.push({ data: [] });
      }
    });

    findTblBody?.forEach((tblBodyItem, tblBodyIndex) => {
      const allTdValue = [],
        totalTdValue = [];
      const findAllTd = tblBodyItem?.querySelectorAll("td");
      findAllTd?.forEach((tdItem, tdIndex) => {
        let tdText = tdItem?.innerText;
        if (findAllTd[0].innerText === "Total") {
          totalTdValue.push(tdText ? tdText : "");
        } else {
          if (tdIndex === 6) {
            tdText = tdText?.replace("$", "");
          }
          allTdValue.push(tdText ? tdText : "");
        }
      });
      if (allTdValue?.length > 0) {
        groupingTable.push({ data: allTdValue });
      }
      if (totalTdValue?.length > 0) {
        totalJson[0].rowdata.push({ data: totalTdValue });
      }
    });

    if (groupingTable?.length > 0) {
      groupingJson.data[0].rowdata = [
        ...groupingJson.data[0].rowdata,
        ...groupingTable,
      ];
    }

    if (totalJson[0].rowdata?.length > 0) {
      groupingJson.data = [...groupingJson.data, ...totalJson];
    }
    groupingJson = {
      ...groupingJson,
      snapshotid: snapshotId ? parseInt(snapshotId) : 0,
      sendtoemails: userDetail?.email ? userDetail?.email : "",
      actiontype: type,
      asofdata: para?.asOfDate
        ? commonService.getDateInDBFormat(para?.asOfDate)
        : "",
    };
    await commonService.widgetWiseExportExcel(groupingJson);
  };

  FixColumn(tblids.tblDayByDayMarketSegment, [1]);

  return (
    <div className="widget-container">
      {api !== 0 && (
        <div className="gap-1 widget-container-menu">
          {/* <Button className="heading-menu-btns" type="button">
            <img
              src={NoteIcon}
              alt="menuIcon"
              onClick={() => {
                setShowNoteSidebar(true);
              }}
            />
          </Button> */}
          <Button className="heading-menu-btns" type="button" title="Send Mail">
            <img
              src={emailIcon}
              alt="emailIcon"
              onClick={() => {
                if (
                  window.location.href.includes("RevPAK%20Summary") ||
                  window.location.href.includes("STR%20Analysis")
                ) {
                  handleExport("EMAIL");
                } else {
                  commonService.fnSendWidgetMail(
                    tblids.tblDayByDayMarketSegment,
                    "Day By Day Market Segment",
                    snapshotId,
                    defaultAsOfDateFromRTK,
                    "",
                    para?.widgetId
                  );
                }
              }}
            />
          </Button>
          <Button className="heading-menu-btns" type="button">
            <img
              src={infoIcon}
              alt="info"
              onClick={() => {
                widgetDetailRef.current = {
                  widgetdetails: "",
                  usernote: "",
                  compName: compName?.marketSegmentDayByDay,
                  widgetId: para?.widgetId,
                };
                setWidgetModal(true);
              }}
            />
          </Button>
          <Button className="heading-menu-btns" type="button">
            <img
              src={downloadIcon}
              alt="menuIcon"
              import={tblids.tblDayByDayMarketSegment}
              onClick={() => {
                if (
                  window.location.href.includes("RevPAK%20Summary") ||
                  window.location.href.includes("STR%20Analysis")
                ) {
                  handleExport("DOWNLOAD");
                } else {
                  commonService.fnExportInCSV(
                    tblids.tblDayByDayMarketSegment,
                    // "Day By Day Market Segment"
                    `${para?.propertyCode}-Day By Day Market Segment-${moment(
                      new Date()
                    ).format("MMDDYYYY")}`
                  );
                }
              }}
            />
          </Button>
        </div>
      )}
      <div className="ViewPropertySetupTable">
        <div className="stripped__table">
          <Table id={tblids.tblDayByDayMarketSegment} responsive>
            <thead>
              <tr className="table-main-header-tr">
                <th>Date</th>
                <th className="text-center border-right" colSpan={5}>
                  On The Books
                </th>
                <th className="text-center border-right" colSpan={2}>
                  Revenue
                </th>
                <th className="text-center border-right">Forecast</th>
                <th
                  className="text-center border-right"
                  colSpan={marketSegmentData.current?.length * 4}
                >
                  <div className="form-groups form-groups-radio-btn d-flex justify-content-center">
                    <div className="d-flex heatmap-main align-item-center">
                      <div className="form-radio d-flex align-items-center">
                        <input
                          type="radio"
                          className="form-check-input"
                          name="marketSegmentDbDNetStly"
                          id="marketSegmentDbDNetStly"
                          value="Inactive"
                          checked={selectedType?.netStly}
                          onChange={() => {
                            marketSegmentData.current = [];
                            setSelectedType({
                              pickup: false,
                              netStly: true,
                            });
                          }}
                        />
                        <label
                          htmlFor="marketSegmentDbDNetStly"
                          className="label-radio form-check-label m-0"
                        >
                          Net Stly
                        </label>
                      </div>

                      <div className="d-flex align-items-center gap-3">
                        <div className="form-radio d-flex align-items-center">
                          <input
                            type="radio"
                            className="form-check-input"
                            name="marketSegmentDbDPickup"
                            id="marketSegmentDbDPickup"
                            value="Active"
                            checked={selectedType?.pickup}
                            onChange={() => {
                              marketSegmentData.current = [];
                              setSelectedType({
                                pickup: true,
                                netStly: false,
                              });
                            }}
                          />
                          <label
                            htmlFor="marketSegmentDbDPickup"
                            className="label-radio form-check-label m-0"
                          >
                            Pickup From
                          </label>
                        </div>
                        <div>
                          <DatePicker
                            id="asOfDate"
                            disabled={selectedType?.pickup ? false : true}
                            maxDate={new Date(maxDateRef.current)}
                            selected={
                              selectPickupDate
                                ? commonService.convertDateInTimezone(
                                    selectPickupDate
                                  )
                                : commonService.convertDateInTimezone(
                                    maxDateRef.current
                                  )
                            }
                            onChange={(date) => {
                              let year = date.getFullYear();
                              let month = date.getMonth();
                              let dateFormat = date.getDate();
                              // let month =
                              //   date.getMonth() + 1 > 9
                              //     ? date.getMonth() + 1
                              //     : `0${date.getMonth() + 1}`;
                              // let dateFormat =
                              //   date?.getDate() > 9
                              //     ? date.getDate()
                              //     : `0${date.getDate()}`;
                              // let selectedDate = `${date.getFullYear()}-${month}-${dateFormat}`;
                              let selectedDate = new Date(
                                year,
                                month,
                                dateFormat
                              );
                              setSelectPickupDate(selectedDate);
                            }}
                          />
                          <span style={{ display: "none" }}>
                            {selectedType?.pickup && selectPickupDate
                              ? commonService.getDateInFormat(
                                  new Date(selectPickupDate)
                                )
                              : commonService.getDateInFormat(
                                  new Date(maxDateRef.current)
                                )}
                          </span>
                        </div>
                        <span style={{ display: "none" }}>
                          {selectedType?.pickup ? "Pickup" : "NetStly"}
                        </span>
                      </div>
                    </div>
                  </div>
                </th>
              </tr>
              <tr className="table-main-header-tr">
                <th className="border-right"></th>
                <th className="border-right" colSpan={5}></th>
                <th className="border-right" colSpan={2}></th>
                <th className="border-right"></th>
                {marketSegmentData.current?.map((item) => (
                  <th
                    className="width200 text-center border-right"
                    colSpan={4}
                    key={item}
                  >
                    {item}
                  </th>
                ))}
              </tr>
              <tr className="table-main-header-tr">
                <th
                  className="width200"
                  onClick={() => {
                    setOpenDatePicker(true);
                  }}
                >
                  Date{" "}
                  <DatePicker
                    onChange={(event) => handleDateChange(event)}
                    startDate={startRangeDate}
                    endDate={endRangeDate}
                    selectsRange
                    monthsShown={2}
                    open={openDatePicker}
                    shouldCloseOnSelect={false}
                  >
                    <div className="w-100" style={{ display: "inline-block" }}>
                      <div className="content-inside-calendar">
                        <p>
                          {startRangeDate
                            ? commonService.getDateInFormat(startRangeDate)
                            : ""}
                          {startRangeDate && endRangeDate ? " - " : ""}
                          {endRangeDate
                            ? commonService.getDateInFormat(endRangeDate)
                            : ""}
                        </p>
                        <div className="d-flex align-items-center gap-2">
                          <button
                            className="secondary-btn"
                            onClick={(e) => {
                              e.stopPropagation();
                              setStartRangeDate("");
                              setEndRangeDate("");
                              setOpenDatePicker(false);
                            }}
                          >
                            Cancel
                          </button>
                          <button
                            disabled={
                              startRangeDate && endRangeDate ? false : true
                            }
                            onClick={(e) => {
                              e.stopPropagation();
                              if (
                                startRangeDateRef.current &&
                                endRangeDateRef.current
                              ) {
                                setDbdData(null);
                                setOtbData(null);
                                dateRangeObj.current =
                                  commonService.datesBetweenTwoDate(
                                    startRangeDateRef.current
                                      ? startRangeDateRef.current
                                      : startDate,
                                    endRangeDateRef.current
                                      ? endRangeDateRef.current
                                      : endDate
                                  );
                                if (selectedType?.netStly) {
                                  getDbdStlyData();
                                } else {
                                  getDbdPickupData();
                                }
                                getORGDetail();
                              }

                              setOpenDatePicker(false);
                            }}
                            className="primary-btn"
                          >
                            Apply
                          </button>
                        </div>
                      </div>
                    </div>
                  </DatePicker>
                  {startRangeDate && endRangeDate ? (
                    <span
                      style={{ display: "none" }}
                    >{`${commonService.getDateInFormat(
                      startRangeDate
                    )} - ${commonService.getDateInFormat(endRangeDate)}`}</span>
                  ) : (
                    ""
                  )}
                </th>
                <th className="width100 border-right">OOO\Rooms Available</th>
                <th className="width100 text-center">Left To sell</th>
                <th className="width100 text-center">On The Books</th>
                <th>Total OCC%</th>
                <th className="text-right">ADR</th>
                <th className="border-right text-right">Rev</th>
                <th>RevPAr</th>
                <th className="border-right text-center">
                  <div>
                    <Form.Select
                      value={selectedForecast}
                      onChange={(e) => {
                        setSelectedForecast(e.target.value);
                      }}
                    >
                      <option value="EMA">SYSTEM</option>
                      <option value="RMS">RMS</option>
                      <option value="User">USER</option>
                    </Form.Select>
                  </div>
                  <span style={{ display: "none" }}>{selectedForecast}</span>
                </th>
                {marketSegmentData.current?.map((item, index) => (
                  <React.Fragment key={index}>
                    <th className="border-right text-center">RMS</th>
                    <th className="text-center">
                      {selectedType?.netStly ? "STLY" : "Pickup"}
                    </th>
                    <th className="text-end">ADR</th>
                    <th className="text-end">
                      {selectedType?.netStly ? "STLY" : "Pickup"}
                    </th>
                  </React.Fragment>
                ))}
              </tr>
            </thead>
            <tbody>
              {loading === true ||
              otbLoading === true ||
              forecastLoading === true ? (
                <tr style={{ height: "80px", position: "relative" }}>
                  <Loader />
                </tr>
              ) : (
                // otbData &&
                // dbdData &&
                // forecastData &&
                dateRangeObj.current &&
                Object.keys(dateRangeObj.current)?.map((item, index) => {
                  let singleOTBData = otbData?.filter((x) => x?.Date === item);
                  let dayName = false;
                  let singleForecastData = forecastData?.filter(
                    (x) =>
                      moment(x.Dates).format("YYYY-MM-DD") ===
                      moment(item).format("YYYY-MM-DD")
                  );

                  ooo = ooo + singleOTBData?.[0]?.OOO;
                  RmsAvailable =
                    RmsAvailable + singleOTBData?.[0]?.RoomAvailable;
                  leftToSell = leftToSell + singleOTBData?.[0]?.LeftToSell;
                  otb = otb + singleOTBData?.[0]?.OnTheBook;
                  occPercentage =
                    occPercentage + singleOTBData?.[0]?.TotalOCCPercentage;
                  adr = adr + singleOTBData?.[0]?.ADR;
                  rev = rev + singleOTBData?.[0]?.REV;
                  revPar = revPar + singleOTBData?.[0]?.RevPAR;
                  if (singleForecastData?.[0]?.RMS) {
                    forecastRms = forecastRms + singleForecastData[0].RMS;
                  } else {
                    forecastRms = forecastRms + 0;
                  }

                  if (
                    moment(
                      commonService.convertDateInTimezone(item),
                      "l"
                    ).format("ddd") === "Fri" ||
                    moment(
                      commonService.convertDateInTimezone(item),
                      "l"
                    ).format("ddd") === "Sat"
                  ) {
                    dayName = true;
                  }

                  return (
                    <React.Fragment key={item}>
                      <tr
                        key={item}
                        className={`${
                          dayName ? "backgroundTr" : ""
                        } shadowOnHover`}
                      >
                        <td>
                          {commonService.getDateInFormat(
                            commonService.convertDateInTimezone(item)
                          )}{" "}
                          {moment(
                            commonService.convertDateInTimezone(item),
                            "l"
                          ).format("ddd")}
                        </td>
                        <td className="width100 border-right">
                          {singleOTBData?.[0]?.OOO
                            ? commonService.formateRoom(singleOTBData?.[0]?.OOO)
                            : 0}{" "}
                          \{" "}
                          {singleOTBData?.[0]?.RoomAvailable
                            ? commonService.formateRoom(singleOTBData?.[0]?.RoomAvailable)
                            : 0}
                        </td>
                        <td className="text-center">
                          {singleOTBData?.[0]?.LeftToSell
                            ? commonService.formateRoom(singleOTBData?.[0]?.LeftToSell)
                            : 0}
                        </td>
                        <td
                          className="text-primary text-center perbackground perbackground-otb"
                          value={singleOTBData?.[0]?.OnTheBook}
                        >
                          <span
                            className={`${
                              selectedTdIndexRef.current === index
                                ? "singleDateSummarySpan"
                                : ""
                            }`}
                            onClick={(e) => {
                              if (api !== 0) {
                                singleDateSelectedDateRef.current = item;
                                handleFilterBtnClick(e);
                                selectedTdIndexRef.current = index;
                              }
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            {singleOTBData?.[0]?.OnTheBook
                              ? commonService.formateRoom(singleOTBData?.[0]?.OnTheBook)
                              : 0}
                          </span>
                        </td>

                        <td>
                          {singleOTBData?.[0]?.TotalOCCPercentage
                            ? `${singleOTBData?.[0]?.TotalOCCPercentage}%`
                            : `0%`}
                        </td>
                        <td
                          className="text-end perbackground perbackground-adr"
                          value={singleOTBData?.[0]?.ADR}
                        >
                          {singleOTBData?.[0]?.ADR
                            ? `${commonService.formateAmount(
                                singleOTBData?.[0]?.ADR
                              )}`
                            : `${commonService.formateAmount(0)}`}
                        </td>
                        <td className="text-start border-right">
                          {singleOTBData?.[0]?.REV
                            ? `${commonService.formateAmount(
                                singleOTBData?.[0]?.REV
                              )}`
                            : `${commonService.formateAmount(0)}`}
                        </td>
                        <td className="text-end">
                          {singleOTBData?.[0]?.RevPAR
                            ? `${commonService.formateAmount(
                                singleOTBData?.[0]?.RevPAR
                              )}`
                            : `${commonService.formateAmount(0)}`}
                        </td>
                        <td className="border-right text-center">
                          {singleForecastData?.[0]?.RMS
                            ? commonService.formateRoom(singleForecastData?.[0]?.RMS)
                            : 0}
                        </td>
                        {marketSegmentData.current?.map(
                          (segmentItem, segmentIndex) => {
                            let findSegmentData = dbdData?.filter(
                              (x) =>
                                x?.staydate === item &&
                                x?.marketSegmentation === segmentItem
                            );

                            if (selectedType?.netStly) {
                              marketSegmentTotal.current[
                                `${findSegmentData?.[0]?.marketSegmentation}CY_ROOMNIGHT`
                              ] = findSegmentData?.[0]?.cy_roomnight
                                ? marketSegmentTotal.current[
                                    `${findSegmentData?.[0]?.marketSegmentation}CY_ROOMNIGHT`
                                  ] +
                                  parseFloat(findSegmentData?.[0]?.cy_roomnight)
                                : marketSegmentTotal.current[
                                    `${findSegmentData?.[0]?.marketSegmentation}CY_ROOMNIGHT`
                                  ];

                              marketSegmentTotal.current[
                                `${findSegmentData?.[0]?.marketSegmentation}LY_ROOMNIGHT`
                              ] = findSegmentData?.[0]?.ly_roomnight
                                ? marketSegmentTotal.current[
                                    `${findSegmentData?.[0]?.marketSegmentation}LY_ROOMNIGHT`
                                  ] +
                                  parseFloat(findSegmentData?.[0]?.ly_roomnight)
                                : marketSegmentTotal.current[
                                    `${findSegmentData?.[0]?.marketSegmentation}LY_ROOMNIGHT`
                                  ];

                              marketSegmentTotal.current[
                                `${findSegmentData?.[0]?.marketSegmentation}CY_REVENUE`
                              ] = findSegmentData?.[0]?.cy_revenue
                                ? marketSegmentTotal.current[
                                    `${findSegmentData?.[0]?.marketSegmentation}CY_REVENUE`
                                  ] +
                                  parseFloat(findSegmentData?.[0]?.cy_revenue)
                                : marketSegmentTotal.current[
                                    `${findSegmentData?.[0]?.marketSegmentation}CY_REVENUE`
                                  ];

                              marketSegmentTotal.current[
                                `${findSegmentData?.[0]?.marketSegmentation}LY_REVENUE`
                              ] = findSegmentData?.[0]?.ly_revenue
                                ? marketSegmentTotal.current[
                                    `${findSegmentData?.[0]?.marketSegmentation}LY_REVENUE`
                                  ] +
                                  parseFloat(findSegmentData?.[0]?.ly_revenue)
                                : marketSegmentTotal.current[
                                    `${findSegmentData?.[0]?.marketSegmentation}LY_REVENUE`
                                  ];
                            } else {
                              marketSegmentTotal.current[
                                `${findSegmentData?.[0]?.marketSegmentation}CY_ROOMNIGHT`
                              ] = findSegmentData?.[0]?.cy_roomnight
                                ? marketSegmentTotal.current[
                                    `${findSegmentData?.[0]?.marketSegmentation}CY_ROOMNIGHT`
                                  ] +
                                  parseFloat(findSegmentData?.[0]?.cy_roomnight)
                                : marketSegmentTotal.current[
                                    `${findSegmentData?.[0]?.marketSegmentation}CY_ROOMNIGHT`
                                  ];

                              marketSegmentTotal.current[
                                `${findSegmentData?.[0]?.marketSegmentation}PICKUP_FROM_ROOM`
                              ] = findSegmentData?.[0]?.PICKUP_FROM_ROOM
                                ? marketSegmentTotal.current[
                                    `${findSegmentData?.[0]?.marketSegmentation}PICKUP_FROM_ROOM`
                                  ] +
                                  parseFloat(
                                    findSegmentData?.[0]?.PICKUP_FROM_ROOM
                                  )
                                : marketSegmentTotal.current[
                                    `${findSegmentData?.[0]?.marketSegmentation}PICKUP_FROM_ROOM`
                                  ];

                              marketSegmentTotal.current[
                                `${findSegmentData?.[0]?.marketSegmentation}CY_REVENUE`
                              ] = findSegmentData?.[0]?.cy_revenue
                                ? marketSegmentTotal.current[
                                    `${findSegmentData?.[0]?.marketSegmentation}CY_REVENUE`
                                  ] +
                                  parseFloat(findSegmentData?.[0]?.cy_revenue)
                                : marketSegmentTotal.current[
                                    `${findSegmentData?.[0]?.marketSegmentation}CY_REVENUE`
                                  ];

                              marketSegmentTotal.current[
                                `${findSegmentData?.[0]?.marketSegmentation}PICKUP_REVENUE`
                              ] = findSegmentData?.[0]?.pickup_revenue
                                ? marketSegmentTotal.current[
                                    `${findSegmentData?.[0]?.marketSegmentation}PICKUP_REVENUE`
                                  ] +
                                  parseFloat(
                                    findSegmentData?.[0]?.pickup_revenue
                                  )
                                : marketSegmentTotal.current[
                                    `${findSegmentData?.[0]?.marketSegmentation}PICKUP_REVENUE`
                                  ];
                            }
                            return (
                              <React.Fragment key={`${segmentItem}${item}`}>
                                <td className="border-right text-primary text-center">
                                  <span
                                    className={`${
                                      rmsSelectedTdIndexRef.current === index
                                        ? "singleDateSummarySpan"
                                        : ""
                                    }`}
                                    onClick={(e) => {
                                      if (api !== 0) {
                                        rmsSingleDateRef.current = item;
                                        handleRmsClick(e);
                                        rmsSelectedTdIndexRef.current = index;
                                        rmsSelectedMarketSegmentRef.current =
                                          segmentItem;
                                      }
                                    }}
                                    style={{ cursor: "pointer" }}
                                  >
                                    {findSegmentData?.[0]?.cy_roomnight
                                      ? commonService.formateRoom(findSegmentData?.[0]?.cy_roomnight)
                                      : 0}
                                  </span>
                                </td>
                                <td className="text-center">
                                  {selectedType?.netStly
                                    ? findSegmentData?.[0]?.ly_roomnight
                                      ? commonService.formateRoom(findSegmentData?.[0]?.ly_roomnight)
                                      : 0
                                    : findSegmentData?.[0]?.PICKUP_FROM_ROOM
                                    ? commonService.formateRoom(findSegmentData?.[0]?.PICKUP_FROM_ROOM)
                                    : 0}
                                </td>
                                <td className="text-end">
                                  {selectedType?.netStly
                                    ? parseFloat(
                                        findSegmentData?.[0]?.cy_revenue
                                      ) > 0 &&
                                      parseFloat(
                                        findSegmentData?.[0]?.cy_roomnight
                                      ) > 0
                                      ? `${commonService.formateAmount(
                                          Math.round(
                                            parseFloat(
                                              findSegmentData?.[0]?.cy_revenue
                                            ) /
                                              parseFloat(
                                                findSegmentData?.[0]
                                                  ?.cy_roomnight
                                              )
                                          )
                                        )}`
                                      : `${commonService.formateAmount(0)}`
                                    : findSegmentData?.[0]?.cy_ADR
                                    ? `${commonService.formateAmount(
                                        findSegmentData?.[0]?.cy_ADR
                                      )}`
                                    : `${commonService.formateAmount(0)}`}
                                </td>
                                <td className="text-end">
                                  {selectedType?.netStly
                                    ? parseFloat(
                                        findSegmentData?.[0]?.ly_revenue
                                      ) > 0 &&
                                      parseFloat(
                                        findSegmentData?.[0]?.ly_roomnight
                                      ) > 0
                                      ? `${commonService.formateAmount(
                                          Math.round(
                                            parseFloat(
                                              findSegmentData?.[0]?.ly_revenue
                                            ) /
                                              parseFloat(
                                                findSegmentData?.[0]
                                                  ?.ly_roomnight
                                              )
                                          )
                                        )}`
                                      : `${commonService.formateAmount(0)}`
                                    : findSegmentData?.[0]?.ADR_PICKUP
                                    ? findSegmentData?.[0]?.ADR_PICKUP
                                    : 0}
                                </td>
                              </React.Fragment>
                            );
                          }
                        )}
                      </tr>
                      {index ===
                        Object.keys(dateRangeObj.current)?.length - 1 && (
                        <tr className="shadowOnHover">
                          <td className="boldTd">Total</td>
                          <td className="boldTd border-right ">
                            {commonService.formateRoom(ooo)} \ {commonService.formateRoom(RmsAvailable)}
                          </td>
                          <td className="boldTd text-center">{commonService.formateRoom(leftToSell)}</td>
                          <td className="boldTd text-center">{commonService.formateRoom(otb)}</td>
                          <td className="boldTd">
                            {occPercentage > 0
                              ? `${parseInt(occPercentage / diffDays)}%`
                              : `0%`}
                          </td>
                          <td className="boldTd text-end">
                            {rev > 0 && otb > 0
                              ? `${commonService.formateAmount(
                                  parseInt(rev / otb)
                                )}`
                              : `${commonService.formateAmount(0)}`}
                          </td>
                          <td className="boldTd text-end border-right">
                            {commonService.formateAmount(rev)}
                          </td>
                          <td className="boldTd text-end">
                            {revPar > 0
                              ? `${commonService.formateAmount(
                                  parseInt(revPar / diffDays)
                                )}`
                              : `${commonService.formateAmount(0)}`}
                          </td>
                          <td className="boldTd text-center border-right">
                            {commonService.formateRoom(forecastRms)}
                          </td>
                          {marketSegmentData.current?.map(
                            (totalItem, index) => {
                              let rms = 0,
                                adr = 0,
                                firstStly = 0,
                                secondStly = 0,
                                firstPickup = 0,
                                secondPickup = 0;
                              if (selectedType?.netStly) {
                                rms =
                                  marketSegmentTotal.current[
                                    `${totalItem}CY_ROOMNIGHT`
                                  ];
                                firstStly =
                                  marketSegmentTotal.current[
                                    `${totalItem}LY_ROOMNIGHT`
                                  ];

                                if (
                                  marketSegmentTotal.current[
                                    `${totalItem}CY_REVENUE`
                                  ] > 0 &&
                                  marketSegmentTotal.current[
                                    `${totalItem}CY_ROOMNIGHT`
                                  ] > 0
                                ) {
                                  adr =
                                    marketSegmentTotal.current[
                                      `${totalItem}CY_REVENUE`
                                    ] /
                                    marketSegmentTotal.current[
                                      `${totalItem}CY_ROOMNIGHT`
                                    ];
                                }

                                if (
                                  marketSegmentTotal.current[
                                    `${totalItem}LY_REVENUE`
                                  ] > 0 &&
                                  marketSegmentTotal.current[
                                    `${totalItem}LY_ROOMNIGHT`
                                  ] > 0
                                ) {
                                  secondStly =
                                    marketSegmentTotal.current[
                                      `${totalItem}LY_REVENUE`
                                    ] /
                                    marketSegmentTotal.current[
                                      `${totalItem}LY_ROOMNIGHT`
                                    ];
                                }
                              } else {
                                rms =
                                  marketSegmentTotal.current[
                                    `${totalItem}CY_ROOMNIGHT`
                                  ];

                                firstPickup =
                                  marketSegmentTotal.current[
                                    `${totalItem}PICKUP_FROM_ROOM`
                                  ];

                                if (
                                  marketSegmentTotal.current[
                                    `${item?.marketSegmentation}CY_REVENUE`
                                  ] > 0 &&
                                  marketSegmentTotal.current[
                                    `${item?.marketSegmentation}CY_ROOMNIGHT`
                                  ]
                                ) {
                                  adr =
                                    marketSegmentTotal.current[
                                      `${item?.marketSegmentation}CY_REVENUE`
                                    ] /
                                    marketSegmentTotal.current[
                                      `${item?.marketSegmentation}CY_ROOMNIGHT`
                                    ];
                                }

                                if (
                                  marketSegmentTotal.current[
                                    `${item?.marketSegmentation}PICKUP_REVENUE`
                                  ] > 0 &&
                                  marketSegmentTotal.current[
                                    `${item?.marketSegmentation}PICKUP_FROM_ROOM`
                                  ] > 0
                                ) {
                                  secondPickup =
                                    marketSegmentTotal.current[
                                      `${item?.marketSegmentation}PICKUP_REVENUE`
                                    ] /
                                    marketSegmentTotal.current[
                                      `${item?.marketSegmentation}PICKUP_FROM_ROOM`
                                    ];
                                }
                              }
                              return (
                                <>
                                  <td className="boldTd text-center border-right">
                                    {commonService.formateRoom(Math.round(rms))}
                                  </td>
                                  <td className="boldTd text-center ">
                                    {selectedType?.netStly
                                      ? commonService.formateRoom(Math.round(firstStly))
                                      : commonService.formateRoom(Math.round(firstPickup))}
                                  </td>
                                  <td className="boldTd text-end">
                                    {commonService.formateAmount(
                                      Math.round(adr)
                                    )}
                                  </td>
                                  <td className="boldTd text-end">
                                    {selectedType?.netStly
                                      ? commonService.formateAmount(
                                          Math.round(secondStly)
                                        )
                                      : commonService.formateAmount(
                                          Math.round(secondPickup)
                                        )}
                                  </td>
                                </>
                              );
                            }
                          )}
                        </tr>
                      )}
                    </React.Fragment>
                  );
                })
              )}
            </tbody>
          </Table>
        </div>
      </div>
      <Overlay
        show={rms.IsDisplay}
        target={rms.Target}
        placement={rms.Position}
      >
        <Popover
          id="popover-contained"
          className="SingleDateSummaryModalPopoverMain"
        >
          <div className="horizontal-menu-option active">
            <SingleDateRMSModal
              closeModal={closeRmsModal}
              asOfDate={para?.asOfDate}
              selectedDate={rmsSingleDateRef.current}
              pickupDate={
                selectPickupDate
                  ? commonService.convertDateInTimezone(selectPickupDate)
                  : commonService.convertDateInTimezone(maxDateRef.current)
              }
              propertyCode={para?.propertyCode}
              marketSegment={rmsSelectedMarketSegmentRef.current}
            />
          </div>
        </Popover>
      </Overlay>

      <Overlay
        show={ONTHEBOOKS.IsDisplay}
        target={ONTHEBOOKS.Target}
        placement={ONTHEBOOKS.Position}
      >
        <Popover
          id="popover-contained"
          className="SingleDateSummaryModalPopoverMain"
        >
          <div className="horizontal-menu-option active">
            <SingleDateSummaryModal
              closeModal={closeSingleDate}
              asOfDate={para?.asOfDate}
              selectedDate={singleDateSelectedDateRef.current}
              pickupDate={
                selectPickupDate
                  ? selectPickupDate
                  : singleDatePickupDateRef.current
              }
              propertyDetail={{ propertyCode: para?.propertyCode }}
            />
          </div>
        </Popover>
      </Overlay>

      {/* {showNoteSidebar && (
          <WidgetNote
            show={showNoteSidebar}
            handleClose={closeNoteSideBar}
            widgetId={para?.widgetId}
            propertyId={propertyDetailFromRTK?.propertyid}
            rowTitle={para?.rowTitle}
          />
        )} */}
      {showNoteSidebar && (
        <AddWidgetModal
          showModal={showNoteSidebar}
          closeModal={closeNoteSideBar}
          widgetId={para?.widgetId}
          propertyId={propertyDetailFromRTK?.propertyid}
          rowTitle={para?.rowTitle}
        />
      )}
      {widgetModal && (
        <WidgetDetailModal
          showModal={widgetModal}
          closeModal={closeWidgetModal}
          data={widgetDetailRef.current}
        />
      )}
    </div>
  );
};

export default StrDayByDay;
