import React, { useEffect, useRef, useState } from "react";
import { apiCall } from "../../../../utils/axiosService";
import { apiResponse } from "../../../../utils/apiResponse";
import { commonService } from "../../../../utils/commonService";
import { API_URL } from "../../../../utils/apiRoutes";
import Comments from "../Comments/Comment";
import { Line } from "react-chartjs-2";
import {
  Chart,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  Filler,
} from "chart.js";
import { Col, Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import Loader from "../../../Loader/Loader";

const PastForecastChart = ({
  setCompDataFromChild,
  rowIndex,
  colIndex,
  api,
  para,
  setting,
}) => {
  const [loading, setLoading] = useState(false);
  const [chartData, setChartData] = useState(null);
  const [selectedDate, setSelectedDate] = useState(para?.asOfDate);
  const datesArrayRef = useRef();
  let dateLabels = [
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
    21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
  ];

  let otb = {
    label: "OTB",
    backgroundColor: "#97C2E0",
    data: [],
    stack: "stack1",
  };

  let forecast = {
    label: "Forecast",
    backgroundColor: "#7ACFA2",
    data: [],
    stack: "stack2",
  };

  const getChartData = async () => {
    setLoading(true);
    let chartDataResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.SnapShot + API_URL.FORECAST.PAST_FORECAST_CHART,
        body: {
          SelectedDate: selectedDate
            ? commonService.getDateForNode(selectedDate)
            : "",
          //   SelectedDate: "2024-06-15",
          propertyCode: para?.propertyCode,
        },
      },
      false
    );

    let response = apiResponse(false, chartDataResponse);

    if (response?.isValidate) {
      let endDate = new Date(selectedDate);
      let startDate = new Date(selectedDate);
      //   let endDate = new Date("2024-06-15");
      //   let startDate = new Date("2024-06-15");
      startDate.setDate(startDate.getDate() - 31);
      let datesArr = commonService.getDateBetweenTwoDates(startDate, endDate);
      datesArr?.shift();
      datesArrayRef.current = datesArr;
      let dataFromApi = response?.data?.data;
      datesArr?.map((item) => {
        let findData = dataFromApi?.filter((x) => {
          if (
            commonService.getDateInFormat(item) ===
            commonService.getDateInFormat(x?.AsOfDate)
          ) {
            return x;
          }
        });
        otb.data = [
          ...otb.data,
          findData?.[0]?.PickUpDataJson?.[0]?.RoomSold
            ? findData?.[0]?.PickUpDataJson?.[0]?.RoomSold
            : 0,
        ];
        forecast.data = [
          ...forecast.data,
          findData?.[0]?.PickUpDataJson?.[0]?.ForecastRoom
            ? findData?.[0]?.PickUpDataJson?.[0]?.ForecastRoom
            : 0,
        ];
      });

      setChartData([otb, forecast]);

      setLoading(false);
    }

    if (!response?.isValidate) {
      setChartData(null);
      setLoading(false);
    }
  };

  let chartDetail = {};
  if (chartData) {
    chartDetail = {
      //   labels: datesArrayRef.current,
      labels: dateLabels?.reverse(),
      datasets: [
        {
          type: "line",
          label: `OTB`,
          backgroundColor: chartData?.[0]?.backgroundColor,
          data: chartData?.[0]?.data,
          stack: chartData?.[0]?.stack || "default",
          fill: {
            target: "origin",
            above: "rgba(151, 194, 224, 0.4)",
          },
        },
        {
          type: "line",
          label: `Forecast`,
          backgroundColor: chartData?.[1]?.backgroundColor,
          data: chartData?.[1]?.data,
          // fill: true,
          fill: {
            target: "origin",
            above: "rgba(122, 207, 162, 0.4)",
          },
          stack: chartData?.[1]?.stack || "default",
        },
      ],
    };
  }

  let chartOptions = {
    indexAxis: "x",
    scales: {
      x: {
        stacked: true,
        title: {
          display: true,
          text: "Days Out",
        },
        ticks: {
          align: "start",
          autoSkip: false,
        },
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: "",
        },
        grid: {
          display: true,
        },
        offset: true,
      },
    },

    plugins: {
      legend: {
        display: true,
        align: "end",
        position: "top",
        maxWidth: 20,
        maxHeight: 100,
        labels: {
          boxWidth: 10,
          boxHeight: 10,
        },
      },
      tooltip: {
        enabled: true,
        mode: "index",
        callbacks: {
          title: function (context) {
            // You can provide a title for the tooltip here
            return context[0].label;
            // return datesArrayRef.current[context[0].dataIndex];
          },
          label: function (context) {
            // Use context.datasetIndex to differentiate between datasets
            let label = context.dataset.label;
            let value = context.formattedValue;
            return `${label}: ${value}`;
          },
        },
      },
    },
    responsive: true,
    barThickness: 12,
    categorySpacing: 0.3,
    barPercentage: 0.8,
    categoryPercentage: 0.9,
  };

  Chart.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Tooltip,
    Legend,
    PointElement,
    LineElement,
    Filler
  );

  useEffect(() => {
    if (api === 0) {
    } else {
      if (para?.asOfDate && selectedDate) {
        getChartData();
      }
    }
  }, [para?.propertyCode, selectedDate]);

  return (
    <div>
      {/* {chartData && ( */}
      <>
        <Col xl={12}>
          <Col xl={1}>
            <div className="from-groups no-border-break">
              {/* <Form.Label>Check In Date</Form.Label> */}
              <DatePicker
                id="checkInDate"
                selected={selectedDate ? new Date(selectedDate) : ""}
                onChange={(date) => {
                  setSelectedDate(date);
                }}
              />
            </div>
          </Col>
        </Col>
        <div className="ForecastHorizontalChartInner mt-3">
          {loading ? (
            <Loader />
          ) : chartData ? (
            <Line data={chartDetail} options={chartOptions} />
          ) : (
            <div>
              <p className="text-center">No Data Found</p>
            </div>
          )}
        </div>
        {api !== 0 && <Comments widgetDetails={para} editorId={"PastForecastChart"}/>}
      </>
      {/* )} */}
    </div>
  );
};

export default PastForecastChart;
