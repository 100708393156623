import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { apiCall } from "../../utils/axiosService";
import { errorToastMessage, successToastMessage } from "../../utils/toastMessage";
import { API_URL } from "../../utils/apiRoutes";
import { commonService } from "../../utils/commonService";
import { Modal } from "react-bootstrap";
import deleteImg from "../../Assets/Images/Configuration/delete-modal.png";

const DeleteModal = ({
  showDeleteItemModal,
  closeDeleteItemModal,
  payload,
  getList,
  url,
}) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const token = commonService.getItem("token");
  const dispatch = useDispatch();

  const handleDelete = async () => {
    setLoading(true);
    let deleteCategoryListResponse = await apiCall(
      {
        method: "POST",
        url: url,
        body: payload,
      },
      false
    );
    if (deleteCategoryListResponse?.status_code === 0) {
      errorToastMessage(deleteCategoryListResponse?.message);
      if (getList) {
        getList();
      }
    } else if (deleteCategoryListResponse?.status_code === 1) {
      setLoading(true);
      successToastMessage(deleteCategoryListResponse?.message);
      if (getList) {
        getList();
      }
    } else if (deleteCategoryListResponse?.status_code === 2) {
      localStorage.clear();
      window.location.href =
        API_URL.BASE_API_URL.EMA_MAIN_DOMAIN + "?logout=true";
    } else if (deleteCategoryListResponse?.name === "AxiosError") {
      let errMsg = "";
      for (const [key, value] of Object.entries(
        deleteCategoryListResponse?.response?.data?.errors
      )) {
        value?.map((errorItem) => {
          errMsg = errMsg + errorItem;
        });
      }
      errorToastMessage(errMsg);
    }

    setLoading(false);
    closeDeleteItemModal();
  };

  return (
    <>
      <Modal
        className="DeleteItemModalMain"
        centered
        show={showDeleteItemModal}
        onHide={closeDeleteItemModal}
      >
        <Modal.Body>
          <div className="delete-modal-content">
            <img src={deleteImg} alt="deleteImg" />
            <h2>Are You Sure?</h2>
            <p>You want to delete this!</p>
            <div className="delete-modal-content-btns">
              {loading ? (
                <button className="default-btn red-btn" type="button" disabled>
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Loading...
                </button>
              ) : (
                <button
                  className="default-btn red-btn"
                  onClick={() => {
                    handleDelete();
                  }}
                >
                  Delete
                </button>
              )}

              <button
                className="default-btn secondary-btn"
                onClick={closeDeleteItemModal}
              >
                Cancel
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DeleteModal;
