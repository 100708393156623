import React from "react";
import WeatherComonent from "../../../CommonComponent/WeatherComonent";
import { Container } from "react-bootstrap";

const WeatherWidget = ({
  setCompDataFromChild,
  rowIndex,
  colIndex,
  api,
  para,
  setting,
}) => {
  return (
    <>
      <div className="weather-page-main">
        <Container fluid>
          <WeatherComonent />
        </Container>
      </div>
    </>
  );
};

export default WeatherWidget;
