import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import sunny from "../../Assets/Images/sunny.svg";
import cloudSun from "../../Assets/Images/cloudSun.svg";
import rainSun from "../../Assets/Images/rainSun.svg";
import rain from "../../Assets/Images/rain.svg";
import thunder from "../../Assets/Images/thunder.svg";
import windy from "../../Assets/Images/windy.svg";
import { useSelector } from "react-redux";
import moment from "moment";
import { commonService } from "../../utils/commonService";
import addicon from "../../Assets/Images/FormCheck/plusIcon.png";
import AddToCalendarModal from "../RevenueManagement/AddToCalendarModal";

const WeatherComonent = () => {
  const asOfDateFromRTK = useSelector((store) => store.headerProperty.asOfDate);
  const propertyDetailFromRTK = useSelector(
    (store) => store.headerProperty.propertyDetail
  );
  const [WeatherData, setWeatherData] = useState([]);
  const [showAddToCalModal, setShowAddToCalModal] = useState(false);
  const noteDetailRef = useRef();
  const todayWeatherDetailRef = useRef();

  const closeAddToCalModal = () => {
    setShowAddToCalModal(false);
  };

  let latitude = propertyDetailFromRTK?.geolocation?.split(",")[0],
    longitude = propertyDetailFromRTK?.geolocation?.split(",")[1];
  async function weatherData(e) {
    const data = await fetch(
      `https://api.openweathermap.org/data/2.5/forecast?lat=${latitude}&lon=${longitude}&appid=${process.env.REACT_APP_WEATHER_API_KEY}`
    )
      .then((res) => res.json())
      .then((data) => data);

    const groups = data?.list?.reduce((groups, elist) => {
      const date = moment(elist.dt_txt).format("MM-DD-yyyy");
      if (!groups[date]) {
        groups[date] = {
          date: date,
          otherdata: [],
        };
      }
      groups[date].otherdata.push(elist);
      return groups;
    }, {});

    setWeatherData(groups);
    let timelst = [];
    if (groups) {
      Object?.keys(groups)?.forEach((element) => {
        groups[element].otherdata
          .filter(
            (d) =>
              commonService.getDateInDBFormat(d.dt_txt) ===
              commonService.getDateInDBFormat(e)
          )
          .forEach((element) => {
            timelst.push({
              name: moment(element.dt_txt).format("LT"),
              celsius: Math.floor(element.main.temp - 273.15),
            });
          });
      });
    }
  }

  useEffect(() => {
    weatherData();
  }, [propertyDetailFromRTK]);
  return (
    <>
      <div className="weather-page-main">
        <Container fluid>
          <Row className="weather-page-main-content">
            <Col xl={12}>
              <div className="all-weathers-main">
                {WeatherData &&
                  Object?.keys(WeatherData)?.map((item, index) => {
                    if (
                      moment(WeatherData?.[item]?.date, "l").format("l") ===
                      moment(
                        commonService.getDateInDBFormat(new Date())
                      ).format("l")
                    ) {
                      todayWeatherDetailRef.current = {
                        dates: commonService.getDateInFormat(new Date()),
                        detail: `${
                          WeatherData?.[item]?.otherdata?.[0]?.weather?.[0]
                            ?.main
                        } - ${Math.floor(
                          (WeatherData?.[item]?.otherdata?.[0]?.main?.temp -
                            273.15) *
                            (9 / 5) +
                            32
                        )}°`,
                        title: `${
                          WeatherData?.[item]?.otherdata?.[0]?.weather?.[0]
                            ?.main
                        } - ${Math.floor(
                          (WeatherData?.[item]?.otherdata?.[0]?.main?.temp -
                            273.15) *
                            (9 / 5) +
                            32
                        )}°`,
                      };
                    }
                    return (
                      <div
                        className="single-weather-wrapper cursorPointer"
                        key={index}
                        onClick={() => {
                          noteDetailRef.current = {
                            dates: commonService.getDateInFormat(
                              WeatherData?.[item]?.date
                            ),
                            detail: `${
                              WeatherData?.[item]?.otherdata?.[0]?.weather?.[0]
                                ?.main
                            } - ${Math.floor(
                              (WeatherData?.[item]?.otherdata?.[0]?.main?.temp -
                                273.15) *
                                (9 / 5) +
                                32
                            )}°`,
                            title: `${
                              WeatherData?.[item]?.otherdata?.[0]?.weather?.[0]
                                ?.main
                            } - ${Math.floor(
                              (WeatherData?.[item]?.otherdata?.[0]?.main?.temp -
                                273.15) *
                                (9 / 5) +
                                32
                            )}°`,
                            associationType: "weather",
                          };
                          setShowAddToCalModal(true);
                        }}
                      >
                        <div className="single-weather-image">
                          {WeatherData?.[item]?.otherdata?.[0]?.weather?.[0]
                            ?.main === "Rain" ? (
                            <img src={rain} className="weather-img" />
                          ) : (
                            <img src={cloudSun} className="weather-img" />
                          )}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="icon icon-tabler icon-tabler-circle-plus weather-addicon"
                            width="60"
                            height="60"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="#000000"
                            fill="none"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0" />
                            <path d="M9 12h6" />
                            <path d="M12 9v6" />
                          </svg>
                        </div>
                        <h6>
                          {Math.floor(
                            (WeatherData?.[item]?.otherdata?.[0]?.main?.temp -
                              273.15) *
                              (9 / 5) +
                              32
                          )}{" "}
                          °
                        </h6>
                        <p>
                          {moment(WeatherData?.[item]?.date, "l").format(
                            "l"
                          ) ===
                          moment(
                            commonService.getDateInDBFormat(new Date())
                          ).format("l")
                            ? "Today"
                            : moment(WeatherData?.[item]?.date, "l").format(
                                "ddd, DD MMM"
                              )}
                        </p>
                      </div>
                    );
                  })}
              </div>
            </Col>
            <Col xl={12}>
              {WeatherData &&
                Object?.keys(WeatherData)?.map((item, index) => {
                  return (
                    moment(WeatherData?.[item]?.date, "l").format("l") ===
                      moment(
                        commonService.getDateInDBFormat(new Date())
                      ).format("l") && (
                      <div className="today-weather">
                        <div className="today-weather-left">
                          <p>{moment(new Date()).format("DD MMM, hh:mm A")}</p>
                          <div className="d-flex align-items-start mt-2">
                            <h1>
                              {Math.floor(
                                (WeatherData?.[item]?.otherdata?.[0]?.main
                                  ?.temp -
                                  273.15) *
                                  (9 / 5) +
                                  32
                              )}{" "}
                            </h1>
                            <span>°F</span>
                          </div>
                        </div>
                        <div className="today-weather-right">
                          {WeatherData?.[item]?.otherdata?.[0]?.weather?.[0]
                            ?.main === "Rain" ? (
                            <img src={rain} />
                          ) : (
                            <img src={cloudSun} />
                          )}
                        </div>
                      </div>
                    )
                  );
                })}
            </Col>
            <Col xl={12} className="mt-4">
              {WeatherData && (
                <Button
                  className="default-btn primary-btn w-100"
                  onClick={() => {
                    noteDetailRef.current = {
                      dates: todayWeatherDetailRef.current.dates,
                      detail: todayWeatherDetailRef.current.detail,
                      title: todayWeatherDetailRef.current.title,
                      associationType: "weather",
                    };
                    setShowAddToCalModal(true);
                  }}
                >
                  Add to Calendar
                </Button>
              )}
            </Col>
          </Row>
        </Container>
        {showAddToCalModal && (
          <AddToCalendarModal
            showModal={showAddToCalModal}
            closeModal={closeAddToCalModal}
            noteDetail={noteDetailRef.current}
          />
        )}
      </div>
    </>
  );
};

export default WeatherComonent;
