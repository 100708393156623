import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Col,
  Container,
  Row,
  Form,
  Table,
  Overlay,
  Popover,
} from "react-bootstrap";
import calendarIcon from "../../../../Assets/Images/Revenue-management/calendarIcon.svg";
import horizobtalBarIcon from "../../../../Assets/Images/Revenue-management/horizontal-bars.svg";
import ticket from "../../../../Assets/Images/Revenue-management/ticket.svg";
import alarm from "../../../../Assets/Images/Revenue-management/alarm.svg";
import premonth from "../../../../Assets/Images/Revenue-management/preMonth.svg";
import nextmonth from "../../../../Assets/Images/Revenue-management/nextMonth.svg";
import dateVerticalThreeDot from "../../../../Assets/Images/dateVerticalThreeDot.svg";
import graphIcon from "../../../../Assets/Images/Revenue-management/graphIcon.svg";
import ToolsSingleTicketPopUp from "../../../CommonComponent/ToolsSingleTicketPopUp";
import { Link } from "react-router-dom";
import AddCompetitorModal from "../.././AddCompetitorModal";
import ToolsSummaryModal from "../.././ToolsSummaryModal";
import GroupReservationsModal from "../.././GroupReservationsModal";
import { apiCall } from "../../../../utils/axiosService";
import { API_URL } from "../../../../utils/apiRoutes";
import { apiResponse } from "../../../../utils/apiResponse";
import placeHolder from "../../../../Assets/Images/Revenue-management/no-img.png";
import moment from "moment";
import { commonService } from "../../../../utils/commonService";
import { useSelector } from "react-redux";
import AddEventModal from "../.././propertySetting/AddEventModal";
import AddReminderModal from "../.././AddReminderModal";
import soldOutIcon from "../../../../Assets/Images/Revenue-management/soldout.svg";
import EventViewModal from "../../../CommonComponent/EventViewModal";
import RateShopGraphView from "../../RateShopGraphView";

const Rateshop = ({
  setCompDataFromChild,
  rowIndex,
  colIndex,
  api,
  para,
  setting,
}) => {
  const [showToolsSummaryPopUp, setToolsSummaryPopUp] = useState(false);
  const dateRef = useRef();
  const handleToolsSummaryPopUp = () => {
    setToolsSummaryPopUp(true);
  };
  const closeToolsSummaryPopUp = () => {
    setToolsSummaryPopUp(false);
  };
  const [showAddCompetitorModal, setAddCompetitorModal] = useState(false);
  const handleAddCompetitorModal = () => {
    setAddCompetitorModal(true);
  };

  const closeAddCompetitorModal = () => {
    setAddCompetitorModal(false);
  };
  const [showToolsSummaryModal, setToolsSummaryModal] = useState(false);
  // const handleToolsSummaryModal = () => {
  //   setToolsSummaryModal(true);
  // };

  const closeToolsSummaryModal = () => {
    setToolsSummaryModal(false);
  };
  const [popupPosition, setPopupPosition] = useState({ top: 0, left: 0 });
  const [showSingleTicketPopUp, setShowSingleTicketPopUp] = useState(false);
  const clikedDateRef = useRef();

  const handleTdClick = (event, date) => {
    dateRef.current = date;
    const calendarTable = document.getElementById("fullCustomTableCalendar");
    const ticketPopupPosition = calendarTable.getBoundingClientRect();

    const td = event.currentTarget;
    const tdRect = td.getBoundingClientRect();

    const tdCenterX = tdRect.left + tdRect.width / 2 - ticketPopupPosition.left;
    const tdCenterY =
      tdRect.top + tdRect.height / 2 - ticketPopupPosition.top + 13;

    const popupTop = tdCenterY + window.scrollY;
    const popupLeft = tdCenterX + window.scrollX;

    setPopupPosition({ top: popupTop, left: popupLeft });
    setShowSingleTicketPopUp(true);
  };

  const closeSingleTicketPopUp = () => {
    setShowSingleTicketPopUp(false);
  };

  const [showModal, setShowModal] = useState({
    IsDisplay: false,
    Target: null,
    Position: "top",
  });

  const handleFilterBtnClick = (event) => {
    let Position = "bottom";
    let Top = event.nativeEvent.layerY - 2;
    if (Top + 400 > window.innerHeight) {
      Position = "top";
    }
    setShowModal({
      IsDisplay: true,
      Target: event.target,
      Position: Position,
    });
  };
  const closeSingleDate = () => {
    setShowModal({
      IsDisplay: false,
      Target: null,
      Position: "bottom",
    });
  };

  const [dotPopUp, setdotPopUp] = useState({ top: 0, left: 0 });
  const [showDotsPopup, setshowDotsPopup] = useState(false);
  const asOfDateFromRTK = useSelector((store) => store.headerProperty.asOfDate);

  const handleDotsClick = (event) => {
    const calendarTable = document.getElementById("fullCustomTableCalendar");
    const ticketPopupPosition = calendarTable.getBoundingClientRect();
    const td = event.currentTarget;
    td.style.backgroundColor = "red";
    const tdRect = td.getBoundingClientRect();

    const tdCenterX = tdRect.left + tdRect.width / 2 - ticketPopupPosition.left;
    const tdCenterY =
      tdRect.top + tdRect.height / 2 - ticketPopupPosition.top + 13;

    const popupTop = tdCenterY + window.scrollY;
    const popupLeft = tdCenterX + window.scrollX;

    setdotPopUp({ top: popupTop, left: popupLeft });
    setshowDotsPopup(true);
  };

  const closedotPopUp = () => {
    setshowDotsPopup(false);
  };

  const [showGroupReservationsModal, setGroupReservationsModal] =
    useState(false);
  const handleGroupReservationsModal = () => {
    setGroupReservationsModal(true);
  };

  const closeGroupReservationsModal = () => {
    setGroupReservationsModal(false);
  };

  const date = new Date();
  const startDate = commonService.getDateInDBFormat(
    new Date(date.getFullYear(), date.getMonth(), 1)
  );
  const endDate = commonService.getDateInDBFormat(
    new Date(date.getFullYear(), date.getMonth() + 1, 0)
  );

  const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const [currentDate, setCurrentDate] = useState(new Date());
  const [competitorList, setCompetitorList] = useState(null);
  const [colorComparisonData, setColorComparisonData] = useState(null);
  const [selectedCompetitorList, setSelectedCompetitorList] = useState(null);
  const selectedCompetitorListRef = useRef();
  const [rateShopList, setRateShopList] = useState(null);
  const rateShopListRef = useRef();
  const selectedDateRef = useRef();
  const [diffType, setDiffType] = useState("avg");
  const [dateRange, setDateRange] = useState({
    start: commonService.convertDateInTimezone(startDate),
    end: commonService.convertDateInTimezone(endDate),
  });
  const [ClickedDate, setClickedDate] = useState(new Date());
  const [showEventModal, setShowEventModal] = useState(false);
  const [showReminderModal, setShowReminderModal] = useState(false);
  const propertyDetailFromRTK = useSelector(
    (store) => store.headerProperty.propertyDetail
  );
  const [showTicketModal, setShowTicketModal] = useState(false);
  const [callApi, setCallApi] = useState(false);
  const eventListRef = useRef();
  const reminderListRef = useRef();
  const isReminderRef = useRef();
  const eventListPropsRef = useRef();
  const selectedEventDateRef = useRef();
  const reminderListPropsRef = useRef();
  const [soldOutList, setSoldOutList] = useState(null);
  const isRateShopSoldOutRef = useRef();
  const [rateShopForecastData, setRateShopForecastData] = useState(null);
  const [viewReminderModal, setViewReminderModal] = useState(false);
  const [showAdrChart, setShowAdrChart] = useState(false);
  const closeAdrChart = () => {
    setShowAdrChart(false);
  };

  const closeTicketModal = () => {
    setShowTicketModal(false);
  };

  const closeViewReminderModal = () => {
    setViewReminderModal(false);
  };

  const closeReminderModal = () => {
    setShowReminderModal(false);
    setCallApi(!callApi);
  };

  const closeEventModal = () => {
    setShowEventModal(false);
    setCallApi(!callApi);
  };

  const getDaysInMonth = (date) => {
    const year = date.getFullYear();
    const month = date.getMonth();
    return new Date(year, month + 1, 0).getDate();
  };

  const getFirstDayOfMonth = (date) => {
    const year = date.getFullYear();
    const month = date.getMonth();
    return new Date(year, month, 1).getDay();
  };

  function GetRateshopCalendarCellColor(per) {
    if (per <= 5) {
      return "rgb(234, 226, 183, 0.1)";
    } else if (per <= 10) {
      return "rgb(234, 226, 183, 0.2)";
    } else if (per <= 15) {
      return "rgb(234, 226, 183, 0.3)";
    } else if (per <= 20) {
      return "rgb(234, 226, 183, 0.4)";
    } else if (per <= 25) {
      return "rgb(234, 226, 183, 0.5)";
    } else if (per <= 30) {
      return "rgb(234, 226, 183, 0.6)";
    } else if (per <= 35) {
      return "rgb(234, 226, 183, 0.7)";
    } else if (per <= 40) {
      return "rgb(234, 226, 183, 0.8)";
    } else if (per <= 45) {
      return "rgb(234, 226, 183, 0.9)";
    } else if (per <= 50) {
      return "rgb(234, 226, 183, 1)";
    } else if (per <= 54) {
      return "rgb(252, 191, 73, 0.2)";
    } else if (per <= 58) {
      return "rgb(252, 191, 73, 0.4)";
    } else if (per <= 62) {
      return "rgb(252, 191, 73, 0.6)";
    } else if (per <= 66) {
      return "rgb(252, 191, 73, 0.8)";
    } else if (per <= 70) {
      return "rgb(252, 191, 73, 1)";
    } else if (per <= 74) {
      return "rgb(247, 127, 0, 0.2)";
    } else if (per <= 78) {
      return "rgb(247, 127, 0, 0.4)";
    } else if (per <= 82) {
      return "rgb(247, 127, 0, 0.6)";
    } else if (per <= 86) {
      return "rgb(247, 127, 0, 0.8)";
    } else if (per <= 90) {
      return "rgb(247, 127, 0, 1)";
    } else if (per === 91) {
      return "rgb(214, 40, 40, 0.1)";
    } else if (per === 92) {
      return "rgb(214, 40, 40, 0.2)";
    } else if (per === 93) {
      return "rgb(214, 40, 40, 0.3)";
    } else if (per === 94) {
      return "rgb(214, 40, 40, 0.4)";
    } else if (per === 95) {
      return "rgb(214, 40, 40, 0.5)";
    } else if (per === 96) {
      return "rgb(214, 40, 40, 0.6)";
    } else if (per === 97) {
      return "rgb(214, 40, 40, 0.7)";
    } else if (per === 98) {
      return "rgb(214, 40, 40, 0.8)";
    } else if (per === 99) {
      return "rgb(214, 40, 40, 0.9)";
    } else if (per >= 100) {
      return "rgb(214, 40, 40, 1)";
    }
  }

  const renderCalendar = (calDate) => {
    const calendar = [];
    let MonthFirstDate = new Date(calDate.getFullYear(), calDate.getMonth(), 1);
    let MonthLastDate = new Date(
      calDate.getFullYear(),
      calDate.getMonth() + 1,
      0
    );

    let CalFirstDate = new Date(calDate.getFullYear(), calDate.getMonth(), 1);
    CalFirstDate = new Date(
      CalFirstDate.setDate(CalFirstDate.getDate() - CalFirstDate.getDay())
    );

    for (let i = 0; i < 6; i++) {
      const row = [];
      for (let j = 0; j < 7; j++) {
        let DTDate = commonService.convertDateInTimezone(CalFirstDate);
        let borderColor = "",
          showBorder = false,
          style = "",
          showThreeDot = false;

        // let style = showBorder ? borderColor : "";

        let findOccPercenatge = rateShopForecastData?.filter(
          (x) =>
            commonService.getDateInFormat(x?.Date) ===
            commonService.getDateInFormat(DTDate)
        );

        let checkEvent = eventListRef.current?.filter((x) => {
          let checkingDate = moment(DTDate);
          let startingDate = moment(x?.startDate);
          let endingDate = moment(x?.endDate);
          if (
            checkingDate.isSameOrAfter(startingDate) &&
            checkingDate.isSameOrBefore(endingDate)
          ) {
            return x;
          }
        });

        let checkReminder = reminderListRef.current?.filter((x) => {
          let checkingDate = moment(DTDate);
          let startingDate = moment(x?.startdate);
          let endingDate = moment(x?.enddate);
          if (
            checkingDate.isSameOrAfter(startingDate) &&
            checkingDate.isSameOrBefore(endingDate)
          ) {
            return x;
          }
        });

        let checkSoldOut = soldOutList?.filter((x) => {
          if (
            moment(x?.soldoutdate).format("YYYY-MM-DD") ===
            moment(DTDate).format("YYYY-MM-DD")
          ) {
            return x;
          }
        });

        if (CalFirstDate >= MonthFirstDate && MonthFirstDate <= MonthLastDate) {
          if (diffType === "avg") {
            if (
              rateShopList[CalFirstDate.getDate()]?.total > 0 &&
              rateShopList[CalFirstDate.getDate()]?.isSelf?.rate > 0
            ) {
              let avg =
                rateShopList[CalFirstDate.getDate()]?.total /
                rateShopList[CalFirstDate.getDate()]?.lengthForAvg;
              let findDiffFromCal =
                rateShopList[CalFirstDate.getDate()]?.isSelf?.rate - avg;
              let percentage = Math.round(
                (findDiffFromCal * 100) /
                  rateShopList[CalFirstDate.getDate()]?.isSelf?.rate
              );
              showBorder = true;
              colorComparisonData?.map((item, index) => {
                if (percentage >= item?.minper && percentage <= item?.maxper) {
                  style = showBorder ? `6px solid ${item?.indicatorcolor}` : "";
                }
              });
            }
          } else if (diffType === "max") {
            if (
              rateShopList[CalFirstDate.getDate()]?.max > 0 &&
              rateShopList[CalFirstDate.getDate()]?.isSelf?.rate > 0
            ) {
              let findDiffFromCal =
                rateShopList[CalFirstDate.getDate()]?.isSelf?.rate -
                rateShopList[CalFirstDate.getDate()]?.max;

              let percentage = Math.round(
                (findDiffFromCal * 100) /
                  rateShopList[CalFirstDate.getDate()]?.isSelf?.rate
              );
              showBorder = true;
              colorComparisonData?.map((item, index) => {
                if (percentage >= item?.minper && percentage <= item?.maxper) {
                  style = showBorder ? `6px solid ${item?.indicatorcolor}` : "";
                }
              });
            }
          } else if (diffType === "min") {
            if (
              rateShopList[CalFirstDate.getDate()]?.min > 0 &&
              rateShopList[CalFirstDate.getDate()]?.isSelf?.rate > 0
            ) {
              let findDiffFromCal =
                rateShopList[CalFirstDate.getDate()]?.isSelf?.rate -
                rateShopList[CalFirstDate.getDate()]?.min;

              let percentage = Math.round(
                (findDiffFromCal * 100) /
                  rateShopList[CalFirstDate.getDate()]?.isSelf?.rate
              );
              showBorder = true;
              colorComparisonData?.map((item, index) => {
                if (percentage >= item?.minper && percentage <= item?.maxper) {
                  style = showBorder ? `6px solid ${item?.indicatorcolor}` : "";
                }
              });
            }
          }
        }

        let colorOpacity = findOccPercenatge?.[0]?.OCC_Percentage
          ? (findOccPercenatge?.[0]?.OCC_Percentage / 100)?.toFixed(1)
          : 0;

        row.push(
          <td
            key={`${i}-${j}`}
            className={`main-date-box-with-bg my-custom-width-td color-opacity ${
              CalFirstDate?.getMonth() !== calDate.getMonth()
                ? "disableDate"
                : ""
            } ${
              commonService.getDateInDBFormat(CalFirstDate) ===
              commonService.getDateInDBFormat(new Date())
                ? "today todayDate"
                : ""
            }`}
            style={{
              borderBottom: style,
              background: GetRateshopCalendarCellColor(
                findOccPercenatge?.[0]?.OCC_Percentage
              ),
            }}
          >
            <div className="temp-calendar-td">
              {checkSoldOut?.length > 0 ? (
                <>
                  <div className="main-date-box-inner">
                    <p>{CalFirstDate.getDate()}</p>
                    {CalFirstDate?.getMonth() == calDate.getMonth() && (
                      <img
                        src={dateVerticalThreeDot}
                        onClick={(e) => {
                          if (
                            moment(clikedDateRef.current).format(
                              "YYYY-MM-DD"
                            ) === moment(DTDate).format("YYYY-MM-DD")
                          ) {
                            isRateShopSoldOutRef.current = checkSoldOut;
                            clikedDateRef.current = "";
                            closeSingleDate();
                          } else {
                            isRateShopSoldOutRef.current = checkSoldOut;
                            clikedDateRef.current = DTDate;
                            handleFilterBtnClick(e);
                          }
                        }}
                        alt="dateVerticalThreeDot"
                      />
                    )}
                  </div>

                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <img src={soldOutIcon} alt="soldOut" />
                  </div>
                </>
              ) : (
                <>
                  <div className="main-date-box-inner">
                    <p>{CalFirstDate.getDate()}</p>
                    <div className="main-date-box-all-icons">
                      {checkEvent?.length > 0 && (
                        <img
                          src={ticket}
                          alt="ticket"
                          onClick={() => {
                            selectedEventDateRef.current = DTDate;
                            eventListPropsRef.current = checkEvent;
                            setShowTicketModal(true);
                          }}
                        />
                      )}
                      {checkReminder?.length > 0 && (
                        <img
                          src={alarm}
                          alt="alarm"
                          onClick={() => {
                            reminderListPropsRef.current = checkReminder;
                            isReminderRef.current = true;
                            setViewReminderModal(true);
                          }}
                        />
                      )}
                    </div>

                    {CalFirstDate?.getMonth() == calDate.getMonth() && (
                      <img
                        src={dateVerticalThreeDot}
                        onClick={(e) => {
                          if (
                            moment(clikedDateRef.current).format(
                              "YYYY-MM-DD"
                            ) === moment(DTDate).format("YYYY-MM-DD")
                          ) {
                            isRateShopSoldOutRef.current = checkSoldOut;
                            clikedDateRef.current = "";
                            closeSingleDate();
                          } else {
                            isRateShopSoldOutRef.current = checkSoldOut;
                            clikedDateRef.current = DTDate;
                            handleFilterBtnClick(e);
                          }
                        }}
                        alt="dateVerticalThreeDot"
                      />
                    )}
                  </div>
                  {CalFirstDate?.getMonth() === calDate.getMonth() && (
                    <div
                      DTDate={DTDate}
                      className="revenueOnSameDate"
                      onClick={() => {
                        setClickedDate(DTDate);
                        setToolsSummaryModal(true);
                      }}
                      title="Rate"
                    >
                      {rateShopList[CalFirstDate.getDate()]?.isSelf?.rate
                        ? commonService.formateAmount(
                            Math.round(
                              rateShopList[CalFirstDate.getDate()]?.isSelf?.rate
                            )
                          )
                        : commonService.formateAmount(0)}
                    </div>
                  )}
                  <div className="percentage-data">
                    {findOccPercenatge?.[0]?.OnTheBook && (
                      <h6
                        title="OTB"
                        style={{
                          color: "#008000",
                          border: "1px solid #008000",
                          padding: "2px",
                        }}
                      >
                        {findOccPercenatge?.[0]?.OnTheBook
                          ? `${findOccPercenatge?.[0]?.OnTheBook}`
                          : ""}
                      </h6>
                    )}
                    {findOccPercenatge?.[0]?.ForecastRoom && (
                      <h6
                        title="Forecast"
                        style={{
                          color: "#0000FF",
                          border: "1px solid #0000FF",
                          padding: "2px",
                        }}
                      >
                        {findOccPercenatge?.[0]?.ForecastRoom
                          ? `${findOccPercenatge?.[0]?.ForecastRoom}`
                          : ""}
                      </h6>
                    )}
                  </div>
                </>
              )}
            </div>
          </td>
        );
        CalFirstDate = new Date(
          CalFirstDate.setDate(CalFirstDate.getDate() + 1)
        );
      }
      calendar.push(<tr key={i}>{row}</tr>);
    }

    return calendar;
  };

  const getRateShopSoldOutList = async () => {
    let soldOutListResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.RevenueManagement +
          API_URL.RATESHOP_SOLDOUT.GET_LIST,
        body: {
          propertyid: propertyDetailFromRTK?.propertyid,
          year: new Date(dateRange.start).getFullYear(),
          month: new Date(dateRange.start).getMonth() + 1,
        },
      },
      false
    );

    let response = apiResponse(false, soldOutListResponse);

    if (response?.isValidate) {
      setSoldOutList(response?.data?.data);
    }

    if (!response?.isValidate) {
      setSoldOutList(null);
    }
  };

  const soldOut = async () => {
    let soldOutResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.RevenueManagement +
          API_URL.RATESHOP_SOLDOUT.SAVE,
        body: {
          propertyid: propertyDetailFromRTK?.propertyid,
          soldoutdate: commonService.getDateInDBFormat(clikedDateRef.current),
          details: "",
          propertyname: "",
        },
      },
      true
    );

    let response = apiResponse(false, soldOutResponse);

    if (response?.isValidate) {
      closeSingleDate();
      getRateShopSoldOutList();
    }

    if (!response?.isValidate) {
      closeSingleDate();
    }
  };

  const unsoldOut = async (soldOutData) => {
    let unsoldRateShopResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.RevenueManagement +
          API_URL.RATESHOP_SOLDOUT.DELETE,
        body: {
          id: soldOutData?.[0]?.rateshopsoldoutdetailsid,
        },
      },
      false
    );

    let response = apiResponse(false, unsoldRateShopResponse);

    if (response?.isValidate) {
      closeSingleDate();
      getRateShopSoldOutList();
    }

    if (!response?.isValidate) {
      closeSingleDate();
    }
  };

  const handlePrevMonth = () => {
    setCurrentDate((prevDate) => {
      const prevMonth = new Date(prevDate);
      prevMonth.setMonth(prevMonth.getMonth() - 1);
      return prevMonth;
    });
  };

  const handleNextMonth = () => {
    setCurrentDate((prevDate) => {
      const nextMonth = new Date(prevDate);
      nextMonth.setMonth(nextMonth.getMonth() + 1);
      return nextMonth;
    });
  };

  const getCompetitorList = async () => {
    let competitorListResponse = await apiCall(
      {
        method: "POST",
        url: API_URL.BASE_API_URL.SnapShot + API_URL.RATESHOP.COMPETITOR_LIST,
        body: {
          propertyCode: propertyDetailFromRTK?.propertycode,
          propertyid: propertyDetailFromRTK?.propertyid,
        },
      },
      false
    );

    let response = apiResponse(false, competitorListResponse);

    if (response?.isValidate) {
      let dataFromApi = response?.data?.data;
      let competitorNameList = {};

      for (let i = 0; i < dataFromApi?.length; i++) {
        competitorNameList = {
          ...competitorNameList,
          [dataFromApi?.[i]?.competiterpropertyname]: true,
        };
      }
      setSelectedCompetitorList(competitorNameList);
      selectedCompetitorListRef.current = competitorNameList;
      setCompetitorList(response?.data?.data);
    }

    if (!response?.isValidate) {
      selectedCompetitorListRef.current = {};
      setSelectedCompetitorList(null);
      setCompetitorList(null);
    }
  };

  const getReminderList = async () => {
    let reminderListResponse = await apiCall(
      {
        method: "POST",
        url: API_URL.BASE_API_URL.RevenueManagement + API_URL.REMINDER.GET_LIST,
        body: {
          pageNum: 1,
          pageSize: 10,
          sortColumn: "",
          sortDirection: "asc",
          searchValue: "",
          propertyid: propertyDetailFromRTK?.propertyid,
          startdate: commonService.getDateInDBFormat(dateRange?.start),
          enddate: commonService.getDateInDBFormat(dateRange?.end),
          remindername: "",
          remindertypeterm: "",
        },
      },
      false
    );

    let reminderResponse = apiResponse(false, reminderListResponse);

    if (reminderResponse?.isValidate) {
      reminderListRef.current = reminderResponse?.data?.data;
    }
  };

  const getEventList = async () => {
    let eventList = await apiCall(
      {
        method: "POST",
        url: API_URL.BASE_API_URL.RevenueManagement + API_URL.EVENTS.GET_LIST,
        body: {
          pageNum: 1,
          pageSize: 10,
          sortColumn: "",
          sortDirection: "asc",
          searchValue: "",
          propertyid: parseInt(propertyDetailFromRTK?.propertyid),
          startdate: null,
          enddate: null,
          eventname: "",
          eventtypeterm: "",
          yearterm: ``,
        },
      },
      false
    );

    let eventListResponse = apiResponse(false, eventList);

    if (eventListResponse?.isValidate) {
      let eventListArr = [];

      eventListResponse?.data?.data?.forEach((item) => {
        let singleEventObj = {
          id: item?.eventid ? item?.eventid : 0,
          name: item?.eventname,
          detail: item?.eventdetails,
          startDate: item?.startdate,
          endDate: item?.enddate,
          eventJson: item?.eventjson,
          eventResourceId: item?.eventsourceid,
        };
        let eventJsonData = "";
        if (item?.eventjson) {
          var decodedString = decodeURIComponent(item?.eventjson);
          const jsonStringWithoutQuotes = decodedString.slice(1, -1);
          const jsonStringWithSingleQuotes = `${jsonStringWithoutQuotes}`;
          eventJsonData = JSON.parse(jsonStringWithSingleQuotes);
          let desc = eventJsonData?.description?.replaceAll("+", " ");
          singleEventObj = {
            ...singleEventObj,
            labels: eventJsonData?.labels ? eventJsonData?.labels : "",
            rank: eventJsonData?.rank ? eventJsonData?.rank : 0,
            local_rank: eventJsonData?.local_rank
              ? eventJsonData?.local_rank
              : 0,
            phq_attendance: eventJsonData?.phq_attendance
              ? eventJsonData?.phq_attendance
              : 0,
            geo_lat: eventJsonData?.geo_lat ? eventJsonData?.geo_lat : "",
            geo_long: eventJsonData?.geo_long ? eventJsonData?.geo_long : "",
            detail: desc,
          };
        }
        eventListArr.push(singleEventObj);
      });
      eventListRef.current = eventListArr;
    }

    if (!eventListResponse?.isValidate) {
      eventListRef.current = [];
    }
  };

  const getRateShopList = async () => {
    await getEventList();

    let byPropertyIdResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.RevenueManagement +
          API_URL.REVENUE_RATE_SHOP_CONFIG.GET_BY_PROPERTY_ID,
        body: {
          id: propertyDetailFromRTK?.propertyid,
        },
      },
      false
    );

    let propertyResponse = apiResponse(false, byPropertyIdResponse);

    let rateShopListResponse = await apiCall(
      {
        method: "POST",
        url: API_URL.BASE_API_URL.SnapShot + API_URL.RATESHOP.RATESHOP_LIST,
        body: {
          AsOfDate: asOfDateFromRTK?asOfDateFromRTK:"",
          propertyid: propertyDetailFromRTK?.propertyid,
          propertyCode: propertyDetailFromRTK?.propertycode,
          Channel: propertyResponse?.data?.data?.channel_term
            ? propertyResponse?.data?.data?.channel_term
            : "",
          startDate: moment(dateRange?.start).format("YYYY-MM-DD"),
          endDate: moment(dateRange?.end).format("YYYY-MM-DD"),
        },
      },
      false
    );

    let response = apiResponse(false, rateShopListResponse);

    if (response?.isValidate) {
      let dataFromApi = response?.data?.data?.RateShop;
      rateShopListRef.current = dataFromApi;
      let groupingJson = {};
      for (let i = 0; i < dataFromApi?.length; i++) {
        let keyName =
          dataFromApi[i]?.IsSelf === true
            ? "isSelf"
            : dataFromApi[i]?.competiterpropertyname;
        if (
          groupingJson.hasOwnProperty(
            moment(dataFromApi[i]?.CheckInDate).toDate().getDate()
            // new Date(dataFromApi[i]?.CheckInDate).getDate()
          )
        ) {
          groupingJson[moment(dataFromApi[i]?.CheckInDate).toDate().getDate()] =
            {
              ...groupingJson[
                moment(dataFromApi[i]?.CheckInDate).toDate().getDate()
              ],
              /* FOR ONLY COMPETITOR MIN VALUE */
              lengthForAvg:
                dataFromApi[i]?.IsSelf === true
                  ? groupingJson[
                      moment(dataFromApi[i]?.CheckInDate).toDate().getDate()
                    ]?.lengthForAvg
                  : parseInt(dataFromApi[i]?.Rate) > 0
                  ? groupingJson[
                      moment(dataFromApi[i]?.CheckInDate).toDate().getDate()
                    ]?.lengthForAvg + 1
                  : groupingJson[
                      moment(dataFromApi[i]?.CheckInDate).toDate().getDate()
                    ]?.lengthForAvg,
              total:
                dataFromApi[i]?.IsSelf === true
                  ? groupingJson[
                      moment(dataFromApi[i]?.CheckInDate).toDate().getDate()
                    ]?.total
                  : groupingJson[
                      moment(dataFromApi[i]?.CheckInDate).toDate().getDate()
                    ]?.total
                  ? groupingJson[
                      moment(dataFromApi[i]?.CheckInDate).toDate().getDate()
                    ]?.total + parseInt(dataFromApi[i]?.Rate)
                  : parseInt(dataFromApi[i]?.Rate),
              max:
                dataFromApi[i]?.IsSelf === true
                  ? groupingJson[
                      moment(dataFromApi[i]?.CheckInDate).toDate().getDate()
                    ]?.max
                  : groupingJson[
                      moment(dataFromApi[i]?.CheckInDate).toDate().getDate()
                    ]?.max
                  ? groupingJson[
                      moment(dataFromApi[i]?.CheckInDate).toDate().getDate()
                    ]?.max < parseInt(dataFromApi[i]?.Rate)
                    ? parseInt(dataFromApi[i]?.Rate)
                    : groupingJson[
                        moment(dataFromApi[i]?.CheckInDate).toDate().getDate()
                      ]?.max
                  : parseInt(dataFromApi[i]?.Rate),
              min:
                dataFromApi[i]?.IsSelf === true
                  ? groupingJson[
                      moment(dataFromApi[i]?.CheckInDate).toDate().getDate()
                    ]?.min
                  : groupingJson[
                      moment(dataFromApi[i]?.CheckInDate).toDate().getDate()
                    ]?.min
                  ? groupingJson[
                      moment(dataFromApi[i]?.CheckInDate).toDate().getDate()
                    ]?.min > parseInt(dataFromApi[i]?.Rate) &&
                    parseInt(dataFromApi[i]?.Rate) > 0
                    ? parseInt(dataFromApi[i]?.Rate)
                    : groupingJson[
                        moment(dataFromApi[i]?.CheckInDate).toDate().getDate()
                      ]?.min
                  : parseInt(dataFromApi[i]?.Rate) > 0
                  ? parseInt(dataFromApi[i]?.Rate)
                  : "",
              [keyName]: {
                competitorName: dataFromApi[i]?.competiterpropertyname,
                competitorCode: dataFromApi[i]?.CompetitorID,
                rateShopScheduleId: dataFromApi[i]?.RateShopScheduleID,
                propertyCode: dataFromApi[i]?.PropertyCode,
                checkInDate: dataFromApi[i]?.CheckInDate,
                rate: dataFromApi[i]?.Rate,
                discount: dataFromApi[i]?.Discount,
                memberDiscount: dataFromApi[i]?.MemberDiscount,
                channel: dataFromApi[i]?.Channel,
                guests: dataFromApi[i]?.Guests,
                los: dataFromApi[i]?.LOS,
                EventIDs: dataFromApi[i]?.EventIDs,
                roomType: dataFromApi[i]?.RoomType,
                isSelf: dataFromApi[i]?.IsSelf,
                isLowestRate: dataFromApi[i]?.IsLowestRate,
                isBarRate: dataFromApi[i]?.IsBarRate,
              },
            };
        } else {
          groupingJson = {
            ...groupingJson,
            [moment(dataFromApi[i]?.CheckInDate).toDate().getDate()]: {
              /* FOR ONLY COMPETITOR MIN VALUE */
              lengthForAvg:
                dataFromApi[i]?.IsSelf === true
                  ? 0
                  : parseInt(dataFromApi[i]?.Rate) > 0
                  ? 1
                  : 0,
              total:
                dataFromApi[i]?.IsSelf === true
                  ? ""
                  : parseInt(dataFromApi[i]?.Rate) > 0
                  ? parseInt(dataFromApi[i]?.Rate)
                  : "",
              max:
                dataFromApi[i]?.IsSelf === true
                  ? ""
                  : parseInt(dataFromApi[i]?.Rate) > 0
                  ? parseInt(dataFromApi[i]?.Rate)
                  : "",
              min:
                dataFromApi[i]?.IsSelf === true
                  ? ""
                  : parseInt(dataFromApi[i]?.Rate) > 0
                  ? parseInt(dataFromApi[i]?.Rate)
                  : "",
              [keyName]: {
                competitorName: dataFromApi[i]?.competiterpropertyname,
                competitorCode: dataFromApi[i]?.CompetitorID,
                rateShopScheduleId: dataFromApi[i]?.RateShopScheduleID,
                propertyCode: dataFromApi[i]?.PropertyCode,
                checkInDate: dataFromApi[i]?.CheckInDate,
                rate: dataFromApi[i]?.Rate,
                discount: dataFromApi[i]?.Discount,
                memberDiscount: dataFromApi[i]?.MemberDiscount,
                channel: dataFromApi[i]?.Channel,
                guests: dataFromApi[i]?.Guests,
                los: dataFromApi[i]?.LOS,
                EventIDs: dataFromApi[i]?.EventIDs,
                roomType: dataFromApi[i]?.RoomType,
                isSelf: dataFromApi[i]?.IsSelf,
                isLowestRate: dataFromApi[i]?.IsLowestRate,
                isBarRate: dataFromApi[i]?.IsBarRate,
              },
            },
          };
        }
      }
      setRateShopForecastData(response?.data?.data?.Forcast_data);
      setRateShopList(groupingJson);
    }

    if (!response?.isValidate) {
      setRateShopList({
        1: { isSelf: { Rate: 0 } },
        2: { isSelf: { Rate: 0 } },
        3: { isSelf: { Rate: 0 } },
        4: { isSelf: { Rate: 0 } },
        5: { isSelf: { Rate: 0 } },
        6: { isSelf: { Rate: 0 } },
        7: { isSelf: { Rate: 0 } },
        8: { isSelf: { Rate: 0 } },
        9: { isSelf: { Rate: 0 } },
        10: { isSelf: { Rate: 0 } },
        11: { isSelf: { Rate: 0 } },
        12: { isSelf: { Rate: 0 } },
        13: { isSelf: { Rate: 0 } },
        14: { isSelf: { Rate: 0 } },
        15: { isSelf: { Rate: 0 } },
        16: { isSelf: { Rate: 0 } },
        17: { isSelf: { Rate: 0 } },
        18: { isSelf: { Rate: 0 } },
        19: { isSelf: { Rate: 0 } },
        20: { isSelf: { Rate: 0 } },
        21: { isSelf: { Rate: 0 } },
        22: { isSelf: { Rate: 0 } },
        23: { isSelf: { Rate: 0 } },
        24: { isSelf: { Rate: 0 } },
        25: { isSelf: { Rate: 0 } },
        26: { isSelf: { Rate: 0 } },
        27: { isSelf: { Rate: 0 } },
        28: { isSelf: { Rate: 0 } },
        29: { isSelf: { Rate: 0 } },
        30: { isSelf: { Rate: 0 } },
        31: { isSelf: { Rate: 0 } },
      });
      setRateShopForecastData(null);
      rateShopListRef.current = null;
    }
  };

  const calculateCalDetail = () => {
    let dataFromApi = rateShopListRef.current;
    let groupingJson = {};
    for (let i = 0; i < dataFromApi?.length; i++) {
      let keyName =
        dataFromApi[i]?.IsSelf === true
          ? "isSelf"
          : dataFromApi[i]?.competiterpropertyname;
      if (
        groupingJson.hasOwnProperty(
          new Date(dataFromApi[i]?.CheckInDate).getDate()
        )
      ) {
        if (
          selectedCompetitorListRef.current?.[
            dataFromApi[i]?.competiterpropertyname
          ]
        ) {
          groupingJson[new Date(dataFromApi[i]?.CheckInDate).getDate()] = {
            ...groupingJson[new Date(dataFromApi[i]?.CheckInDate).getDate()],
            lengthForAvg:
              dataFromApi[i]?.IsSelf === true
                ? groupingJson[
                    moment(dataFromApi[i]?.CheckInDate).toDate().getDate()
                  ]?.lengthForAvg
                : parseInt(dataFromApi[i]?.Rate) > 0
                ? groupingJson[
                    moment(dataFromApi[i]?.CheckInDate).toDate().getDate()
                  ]?.lengthForAvg + 1
                : groupingJson[
                    moment(dataFromApi[i]?.CheckInDate).toDate().getDate()
                  ]?.lengthForAvg,
            total:
              dataFromApi[i]?.IsSelf === true
                ? groupingJson[
                    moment(dataFromApi[i]?.CheckInDate).toDate().getDate()
                  ]?.total
                : groupingJson[
                    moment(dataFromApi[i]?.CheckInDate).toDate().getDate()
                  ]?.total
                ? groupingJson[
                    moment(dataFromApi[i]?.CheckInDate).toDate().getDate()
                  ]?.total + parseInt(dataFromApi[i]?.Rate)
                : parseInt(dataFromApi[i]?.Rate),
            max:
              dataFromApi[i]?.IsSelf === true
                ? groupingJson[
                    moment(dataFromApi[i]?.CheckInDate).toDate().getDate()
                  ]?.max
                : groupingJson[
                    moment(dataFromApi[i]?.CheckInDate).toDate().getDate()
                  ]?.max
                ? groupingJson[
                    moment(dataFromApi[i]?.CheckInDate).toDate().getDate()
                  ]?.max < parseInt(dataFromApi[i]?.Rate)
                  ? parseInt(dataFromApi[i]?.Rate)
                  : groupingJson[
                      moment(dataFromApi[i]?.CheckInDate).toDate().getDate()
                    ]?.max
                : parseInt(dataFromApi[i]?.Rate),
            min:
              dataFromApi[i]?.IsSelf === true
                ? groupingJson[
                    moment(dataFromApi[i]?.CheckInDate).toDate().getDate()
                  ]?.min
                : groupingJson[
                    moment(dataFromApi[i]?.CheckInDate).toDate().getDate()
                  ]?.min
                ? groupingJson[
                    moment(dataFromApi[i]?.CheckInDate).toDate().getDate()
                  ]?.min > parseInt(dataFromApi[i]?.Rate) &&
                  parseInt(dataFromApi[i]?.Rate) > 0
                  ? parseInt(dataFromApi[i]?.Rate)
                  : groupingJson[
                      moment(dataFromApi[i]?.CheckInDate).toDate().getDate()
                    ]?.min
                : parseInt(dataFromApi[i]?.Rate) > 0
                ? parseInt(dataFromApi[i]?.Rate)
                : "",
            [keyName]: {
              competitorName: dataFromApi[i]?.competiterpropertyname,
              competitorCode: dataFromApi[i]?.CompetitorID,
              rateShopScheduleId: dataFromApi[i]?.RateShopScheduleID,
              propertyCode: dataFromApi[i]?.PropertyCode,
              checkInDate: dataFromApi[i]?.CheckInDate,
              rate: dataFromApi[i]?.Rate,
              discount: dataFromApi[i]?.Discount,
              memberDiscount: dataFromApi[i]?.MemberDiscount,
              channel: dataFromApi[i]?.Channel,
              guests: dataFromApi[i]?.Guests,
              los: dataFromApi[i]?.LOS,
              EventIDs: dataFromApi[i]?.EventIDs,
              roomType: dataFromApi[i]?.RoomType,
              isSelf: dataFromApi[i]?.IsSelf,
              isLowestRate: dataFromApi[i]?.IsLowestRate,
              isBarRate: dataFromApi[i]?.IsBarRate,
            },
          };
        }
      } else {
        if (
          selectedCompetitorListRef.current?.[
            dataFromApi[i]?.competiterpropertyname
          ]
        ) {
          groupingJson = {
            ...groupingJson,
            [new Date(dataFromApi[i]?.CheckInDate).getDate()]: {
              lengthForAvg:
                dataFromApi[i]?.IsSelf === true
                  ? 0
                  : parseInt(dataFromApi[i]?.Rate) > 0
                  ? 1
                  : 0,
              total:
                dataFromApi[i]?.IsSelf === true
                  ? ""
                  : parseInt(dataFromApi[i]?.Rate) > 0
                  ? parseInt(dataFromApi[i]?.Rate)
                  : "",
              max:
                dataFromApi[i]?.IsSelf === true
                  ? ""
                  : parseInt(dataFromApi[i]?.Rate) > 0
                  ? parseInt(dataFromApi[i]?.Rate)
                  : "",
              min:
                dataFromApi[i]?.IsSelf === true
                  ? ""
                  : parseInt(dataFromApi[i]?.Rate) > 0
                  ? parseInt(dataFromApi[i]?.Rate)
                  : "",
              [keyName]: {
                competitorName: dataFromApi[i]?.competiterpropertyname,
                competitorCode: dataFromApi[i]?.CompetitorID,
                rateShopScheduleId: dataFromApi[i]?.RateShopScheduleID,
                propertyCode: dataFromApi[i]?.PropertyCode,
                checkInDate: dataFromApi[i]?.CheckInDate,
                rate: dataFromApi[i]?.Rate,
                discount: dataFromApi[i]?.Discount,
                memberDiscount: dataFromApi[i]?.MemberDiscount,
                channel: dataFromApi[i]?.Channel,
                guests: dataFromApi[i]?.Guests,
                los: dataFromApi[i]?.LOS,
                EventIDs: dataFromApi[i]?.EventIDs,
                roomType: dataFromApi[i]?.RoomType,
                isSelf: dataFromApi[i]?.IsSelf,
                isLowestRate: dataFromApi[i]?.IsLowestRate,
                isBarRate: dataFromApi[i]?.IsBarRate,
              },
            },
          };
        }
      }
    }
    setRateShopList(groupingJson);
  };

  const getColorComparisonData = async () => {
    let colorComparisonResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.RevenueManagement +
          API_URL.REVENUE_RATE_SHOP_COMPARISON.GET_LIST,
        body: {
          pageNum: 1,
          pageSize: 10,
          sortColumn: "",
          sortDirection: "asc",
          searchValue: "",
          title: "",
          propertyid: propertyDetailFromRTK?.propertyid,
        },
      },
      false
    );

    let response = apiResponse(false, colorComparisonResponse);

    if (response?.isValidate) {
      let data = response?.data?.data?.reverse();
      setColorComparisonData(data);
    }

    if (!response?.isValidate) {
      setColorComparisonData(null);
    }
  };

  useEffect(() => {
    if (propertyDetailFromRTK && api !== 0) {
      getCompetitorList();
      getColorComparisonData();
    }
  }, [propertyDetailFromRTK]);

  useEffect(() => {
    if (propertyDetailFromRTK && api !== 0) {
      getReminderList();
      getRateShopSoldOutList();
    }
  }, [dateRange, propertyDetailFromRTK, callApi]);

  useEffect(() => {
    if (propertyDetailFromRTK && api !== 0 && asOfDateFromRTK) {
      getRateShopList();
    }
  }, [dateRange, propertyDetailFromRTK, callApi, asOfDateFromRTK]);

  return (
    <div className="RMRateShopMain">
      {propertyDetailFromRTK ? (
        <Container fluid>
          <Row>
            <Col xl={12}>
              <div className="ProspectDashboardTitleWraper  d-flex justify-content-end align-items-center">
                {/* <h2>
                  Dashboard-{" "}
                  <span className="tab-title-main">
                    Revenue Management - Tools - Rateshop
                  </span>
                </h2> */}
                <div className="generalTermSetup-heading-btns">
                  <div className="from-groups no-border-break diffBgSelect">
                    <Form.Select
                      aria-label="Default select example"
                      value={diffType}
                      onChange={(e) => setDiffType(e.target.value)}
                    >
                      <option value="avg">Average</option>
                      <option value="max">Max</option>
                      <option value="min">Min</option>
                    </Form.Select>
                  </div>
                  <Link to="/revenue/rate-shop">
                    <div className="heading-menu-btns">
                      <img src={calendarIcon} alt="calendarIcon" />
                    </div>
                  </Link>
                  <Link to="/revenue/rate-shop/rate-shop-table-view">
                    <div className="heading-menu-btns">
                      <img src={horizobtalBarIcon} alt="horizobtalBarIcon" />
                    </div>
                  </Link>
                  {/* <Link to="/revenue/rate-shop/rate-shop-graphical-view"> */}
                  <div
                    className="heading-menu-btns"
                    onClick={() => {
                      setShowAdrChart(true);
                    }}
                  >
                    <img src={graphIcon} alt="graphIcon" />
                  </div>
                  {/* </Link> */}
                </div>
              </div>
            </Col>
          </Row>
          <Row className="fullCustomCalendarMain-and-all-hotels-main">
            <Col xl={10}>
              <div className="fullCustomCalendarMain">
                <div className="fullCustomCalendarMain">
                  <div className="fullCustomCalendarTable">
                    <div className="calendar-container">
                      <div className="calendar-header"></div>
                      <Row className="fullCustomCalendarMainWrapper align-items-center">
                        <Col xl={4}>
                          <div style={{ display: "flex" }}>
                            <div className="allDateDetailPercentInner">
                              <div style={{ background: "#008000" }}></div>
                              <p>OTB</p>
                            </div>
                            <div className="allDateDetailPercentInner">
                              <div style={{ background: "#0000FF" }}></div>
                              <p>Forecast</p>
                            </div>
                          </div>
                        </Col>
                        <Col xl={4}>
                          <div
                            className="month-name-with-arrow mb-0"
                            style={{ justifyContent: "center", marginLeft: 0 }}
                          >
                            <button
                              onClick={() => {
                                let d = new Date(dateRange?.start);
                                d.setMonth(d.getMonth() - 1);
                                const startDate =
                                  commonService.getDateInDBFormat(
                                    new Date(d.getFullYear(), d.getMonth(), 1)
                                  );
                                const endDate = commonService.getDateInDBFormat(
                                  new Date(d.getFullYear(), d.getMonth() + 1, 0)
                                );
                                setDateRange({
                                  start:
                                    commonService.convertDateInTimezone(
                                      startDate
                                    ),
                                  end: commonService.convertDateInTimezone(
                                    endDate
                                  ),
                                });
                                handlePrevMonth();
                              }}
                            >
                              <img src={premonth} alt="premonth" />
                            </button>
                            <h4>
                              {currentDate.toLocaleDateString("en-US", {
                                month: "long",
                                year: "numeric",
                              })}
                            </h4>
                            <button
                              onClick={() => {
                                let d = new Date(dateRange?.start);
                                d.setMonth(d.getMonth() + 1);
                                const startDate =
                                  commonService.getDateInDBFormat(
                                    new Date(d.getFullYear(), d.getMonth(), 1)
                                  );
                                const endDate = commonService.getDateInDBFormat(
                                  new Date(d.getFullYear(), d.getMonth() + 1, 0)
                                );
                                setDateRange({
                                  start:
                                    commonService.convertDateInTimezone(
                                      startDate
                                    ),
                                  end: commonService.convertDateInTimezone(
                                    endDate
                                  ),
                                });
                                handleNextMonth();
                              }}
                            >
                              <img src={nextmonth} alt="nextmonth" />
                            </button>
                          </div>
                        </Col>
                        <Col xl={4}>
                          <div className="allDateDetailPercent justify-content-between">
                            {colorComparisonData?.map((item, index) => (
                              <div
                                key={item?.ratecomparisonid}
                                className="allDateDetailPercentInner"
                              >
                                <div
                                  style={{ background: item?.indicatorcolor }}
                                ></div>
                                <p>
                                  {item?.minper}% - {item?.maxper}%
                                </p>
                              </div>
                            ))}
                          </div>
                        </Col>
                      </Row>
                      <table className="calendar-table">
                        <thead>
                          <tr>
                            {daysOfWeek.map((day) => (
                              <th key={day}>{day}</th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {rateShopList && renderCalendar(currentDate)}
                        </tbody>
                        {showSingleTicketPopUp && (
                          <div
                            className="ToolsSingleTicketMain"
                            style={{
                              position: "absolute",
                              top: `${popupPosition.top}px`,
                              left: `${popupPosition.left}px`,
                              // transform: "translate(-76%, -21%)",
                            }}
                          >
                            <ToolsSingleTicketPopUp
                              closeSingleTicketPopUp={closeSingleTicketPopUp}
                              date={dateRef.current}
                            />
                          </div>
                        )}
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col xl={2}>
              <div className="rateShopRightMainContainer">
                {competitorList &&
                  competitorList?.map((item, index) => (
                    <div
                      key={index}
                      className="rateShopRightMain"
                      style={{
                        backgroundImage: `${
                          item?.thumbnailimage
                            ? item?.thumbnailimage
                            : `url(${placeHolder})`
                        }`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "contain",
                        backgroundPosition: "center",
                      }}
                    >
                      {item?.isself !== true ? (
                        <div className="singleCheckBox">
                          <Form.Check
                            checked={
                              selectedCompetitorList[
                                item?.competiterpropertyname
                              ]
                            }
                            onChange={(e) => {
                              selectedCompetitorListRef.current[
                                item?.competiterpropertyname
                              ] =
                                !selectedCompetitorList[
                                  item?.competiterpropertyname
                                ];
                              setSelectedCompetitorList(
                                selectedCompetitorListRef.current
                              );
                              calculateCalDetail();
                            }}
                            aria-label="option 1"
                          />
                        </div>
                      ) : (
                        <div className="rateShopSelfTag">Self</div>
                      )}
                      <p>
                        {item?.isself
                          ? `${item?.competiterpropertyname}`
                          : item?.competiterpropertyname}
                      </p>
                    </div>
                  ))}
              </div>
            </Col>
          </Row>
          <AddCompetitorModal
            showAddCompetitorModal={showAddCompetitorModal}
            closeAddCompetitorModal={closeAddCompetitorModal}
          />
          {showToolsSummaryModal && (
            <ToolsSummaryModal
              showToolsSummaryModal={showToolsSummaryModal}
              closeToolsSummaryModal={closeToolsSummaryModal}
              selectedDate={ClickedDate}
              propertyDetail={propertyDetailFromRTK}
            />
          )}
          <GroupReservationsModal
            showGroupReservationsModal={showGroupReservationsModal}
            closeGroupReservationsModal={closeGroupReservationsModal}
          />
        </Container>
      ) : (
        <Container>
          <div className="d-flex justify-content-center">
            <h5>Please Select Property</h5>
          </div>
        </Container>
      )}
      {showEventModal && (
        <AddEventModal
          showModal={showEventModal}
          closeModal={closeEventModal}
          eventId={0}
          propertyId={propertyDetailFromRTK?.propertyid}
        />
      )}

      {showReminderModal && (
        <AddReminderModal
          showModal={showReminderModal}
          closeModal={closeReminderModal}
          reminderid={0}
          propertyid={
            propertyDetailFromRTK?.propertyid
              ? parseInt(propertyDetailFromRTK?.propertyid)
              : 0
          }
        />
      )}
      {showTicketModal && (
        <EventViewModal
          showModal={showTicketModal}
          closeModal={closeTicketModal}
          eventDetails={eventListPropsRef.current}
          selectedDate={selectedEventDateRef.current}
          getEventList={getEventList}
        />
      )}
      {viewReminderModal && (
        <ToolsSingleTicketPopUp
          showModal={viewReminderModal}
          closeModal={closeViewReminderModal}
          eventDetails={reminderListPropsRef.current}
          isReminder={isReminderRef.current}
        />
      )}
      {showAdrChart && (
        <RateShopGraphView
          showModal={showAdrChart}
          closeModal={closeAdrChart}
        />
      )}

      <Overlay
        show={showModal.IsDisplay}
        target={showModal.Target}
        placement={showModal.Position}
      >
        <Popover
          id="popover-contained"
          className="SingleDateSummaryModalPopoverMain"
        >
          <div
            className="vertical-menu-option"
            style={{ borderRadius: "10px" }}
          >
            <p
              style={{ cursor: "pointer" }}
              onClick={() => {
                setShowReminderModal(true);
                setShowModal({
                  IsDisplay: false,
                  Target: null,
                  Position: "bottom",
                });
              }}
            >
              Reminder
            </p>
            <p
              style={{ cursor: "pointer" }}
              onClick={() => {
                setShowEventModal(true);
                setShowModal({
                  IsDisplay: false,
                  Target: null,
                  Position: "bottom",
                });
              }}
            >
              Event
            </p>
            {isRateShopSoldOutRef.current?.length > 0 ? (
              <p
                style={{ cursor: "pointer" }}
                onClick={() => {
                  unsoldOut(isRateShopSoldOutRef.current);
                }}
              >
                Unsold
              </p>
            ) : (
              <p
                style={{ cursor: "pointer" }}
                onClick={() => {
                  soldOut();
                }}
              >
                Sold Out
              </p>
            )}
          </div>
        </Popover>
      </Overlay>
    </div>
  );
};

export default Rateshop;
