import React from "react";
import deleteIcon from "../../Assets/Images/Action Icons/deleteaction.svg";
import closeBtn from "../../Assets/Images/Action Icons/closeIcon.svg";
import { Card, Modal } from "react-bootstrap";
import moment from "moment";

function ToolsSingleTicketPopUp({
  showModal,
  closeModal,
  eventDetails,
  isReminder,
}) {
  return (
    <Modal
      className="reminderModalMain"
      size="md"
      centered
      show={showModal}
      onHide={() => {
        closeModal();
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {isReminder ? "Reminders" : "Event"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="allReminder-main">
        {eventDetails?.map((item, index) => (
          <div
            key={isReminder ? item?.reminderid : item?.eventid}
            className="GroupNameModalBody"
          >
            <Card>
              <Card.Header>
                {isReminder
                  ? item?.remindername
                    ? item?.remindername
                    : ""
                  : item?.eventname
                  ? item?.eventname
                  : ""}
              </Card.Header>
              <Card.Body>
                <Card.Text>
                  <p className="ToolsSingleTicket">
                    {isReminder
                      ? item?.reminderdetails
                        ? item?.reminderdetails
                        : ""
                      : item?.eventdetails
                      ? item?.eventdetails
                      : ""}
                  </p>

                  <div>
                    <div className="dateAndBin">
                      {moment(item?.startdate).format("MMMM DD YYYY")} -{" "}
                      {moment(item?.enddate).format("MMMM DD YYYY")}
                    </div>
                  </div>
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
        ))}
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ToolsSingleTicketPopUp;
