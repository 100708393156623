import React, { useEffect, useState } from "react";
import { apiCall } from "../../../../utils/axiosService";
import { API_URL } from "../../../../utils/apiRoutes";
import { apiResponse } from "../../../../utils/apiResponse";
import { week } from "../../../../utils/constant";
import { Col, Container, Row, Table } from "react-bootstrap";
import { Bar } from "react-chartjs-2";
import {
  Chart,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
} from "chart.js";
import Loader from "../../../Loader/Loader";
import Comments from "../Comments/Comment";
import { commonService } from "../../../../utils/commonService";

const LosDayOfWeek = ({
  setCompDataFromChild,
  rowIndex,
  colIndex,
  api,
  para,
  setting,
}) => {
  let dummyLosData = {
    los1: {
      Sunday: "0",
      Monday: "0",
      Tuesday: "0",
      Wednesday: "0",
      Thursday: "0",
      Friday: "0",
      Saturday: "0",
    },
    los2: {
      Sunday: "0",
      Monday: "0",
      Tuesday: "0",
      Wednesday: "0",
      Thursday: "0",
      Friday: "0",
      Saturday: "0",
    },
    los3: {
      Sunday: "0",
      Monday: "0",
      Tuesday: "0",
      Wednesday: "0",
      Thursday: "0",
      Friday: "0",
      Saturday: "0",
    },
    los4: {
      Sunday: "0",
      Monday: "0",
      Tuesday: "0",
      Wednesday: "0",
      Thursday: "0",
      Friday: "0",
      Saturday: "0",
    },
    los6: {
      Sunday: "0",
      Monday: "0",
      Tuesday: "0",
      Wednesday: "0",
      Thursday: "0",
      Friday: "0",
      Saturday: "0",
    },
    occ: {
      Sunday: "0",
      Monday: "0",
      Tuesday: "0",
      Wednesday: "0",
      Thursday: "0",
      Friday: "0",
      Saturday: "0",
    },
    totalRevenue: {
      Sunday: "0",
      Monday: "0",
      Tuesday: "0",
      Wednesday: "0",
      Thursday: "0",
      Friday: "0",
      Saturday: "0",
    },
  };
  const [chartData, setChartData] = useState(null);
  const [losData, setLosData] = useState(null);
  const [loading, setLoading] = useState(null);

  let LOS1 = {
    label: "1 Night Stay",
    data: [0, 0, 0, 0, 0, 0, 0],
    backgroundColor: "#3DBA78",
    stack: "Stack 1",
    order: 1,
  };
  let LOS2 = {
    label: "2 Night Stay",
    data: [0, 0, 0, 0, 0, 0, 0],
    backgroundColor: "#4F98CA",
    stack: "Stack 1",
    order: 1,
  };
  let LOS3 = {
    label: "3-6 Night Stay",
    data: [0, 0, 0, 0, 0, 0, 0],
    backgroundColor: "#FE6666",
    stack: "Stack 1",
    order: 1,
  };
  let LOS4 = {
    label: "7-14 Night Stay",
    data: [0, 0, 0, 0, 0, 0, 0],
    backgroundColor: "#FEEA5C",
    stack: "Stack 1",
    order: 1,
  };
  let LOS5 = {
    label: "15-29",
    data: [0, 0, 0, 0, 0, 0, 0],
    backgroundColor: "#FEB938",
    stack: "Stack 1",
    order: 1,
  };
  let LOS6 = {
    label: "30+ Night Stay",
    data: [0, 0, 0, 0, 0, 0, 0],
    backgroundColor: "#AD56AD",
    stack: "Stack 1",
    order: 1,
  };
  let occPercentage = {
    label: "Occupancy",
    data: [0, 0, 0, 0, 0, 0, 0],
    borderColor: "#919191",
    fill: false,
    backgroundColor: "#919191",
    type: "line",
    yAxisID: "y2",
    order: 0,
  };

  const options = {
    responsive: true,
    barThickness: 100,
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },
      },
      y: {
        stacked: true,
        ticks: {
          stepSize: 50,
        },
        grid: {
          display: true,
        },
      },
      y2: {
        stacked: false,
        min: 0,
        max: 100,
        beginAtZero: true,
        type: "linear",
        position: "right",
        grid: {
          drawOnChartArea: false,
        },
        // ticks: {
        //   callback: function (value, index, values) {
        //     return `${value * 100} %`;
        //   },
        // },
        ticks: {
          min: 0,
          max: 100,
          stepSize: 10,
          callback: function (value, index, values) {
            return `${value} %`;
          },
        },
      },
    },
    plugins: {
      tooltip: {
        mode: "index",
        intersect: false,
      },
      legend: {
        display: true,
        align: "end",
        position: "top",
        maxWidth: 20,
        maxHeight: 100,
        labels: {
          boxWidth: 10,
          boxHeight: 10,
        },
      },
    },
    aspectRatio: 3.5 / 1,
    responsive: true,
    maintainAspectRatio: true,
  };

  const getLosData = async () => {
    setLoading(true);
    let losDataResponse = await apiCall(
      {
        method: "POST",
        url: API_URL.BASE_API_URL.SnapShot + API_URL.LOS.LOS_DAY_OF_WEEK,
        body: {
          AsOfDate: para?.asOfDate,
          propertyCode: para?.propertyCode,
        },
      },
      false
    );

    let response = apiResponse(false, losDataResponse);

    if (response?.isValidate) {
      LOS1.data = [];
      LOS2.data = [];
      LOS3.data = [];
      LOS4.data = [];
      LOS5.data = [];
      LOS6.data = [];
      occPercentage.data = [];
      let groupingJson = {
        los1: { total: 0 },
        los2: { total: 0 },
        los3: { total: 0 },
        los4: { total: 0 },
        los5: { total: 0 },
        los6: { total: 0 },
        occ: { total: 0 },
        totalRevenue: { total: 0 },
      };
      week?.map((item) => {
        let dayWiseDetail = response?.data?.data?.filter(
          (x) => x?.Day?.trim() === item
        );
        LOS1.data.push(dayWiseDetail?.[0]?.LOS1);
        LOS2.data.push(dayWiseDetail?.[0]?.LOS2);
        LOS3.data.push(dayWiseDetail?.[0]?.LOS3);
        LOS4.data.push(dayWiseDetail?.[0]?.LOS4);
        LOS5.data.push(dayWiseDetail?.[0]?.LOS5);
        LOS6.data.push(dayWiseDetail?.[0]?.LOS6);
        occPercentage.data.push(dayWiseDetail?.[0]?.Occperc);
        groupingJson = {
          los1: {
            ...groupingJson.los1,
            [item]: dayWiseDetail?.[0]?.LOS1,
            total:
              groupingJson.los1.total + parseFloat(dayWiseDetail?.[0]?.LOS1),
          },
          los2: {
            ...groupingJson.los2,
            [item]: dayWiseDetail?.[0]?.LOS2,
            total:
              groupingJson.los2.total + parseFloat(dayWiseDetail?.[0]?.LOS2),
          },
          los3: {
            ...groupingJson.los3,
            [item]: dayWiseDetail?.[0]?.LOS3,
            total:
              groupingJson.los3.total + parseFloat(dayWiseDetail?.[0]?.LOS3),
          },
          los4: {
            ...groupingJson.los4,
            [item]: dayWiseDetail?.[0]?.LOS4,
            total:
              groupingJson.los4.total + parseFloat(dayWiseDetail?.[0]?.LOS4),
          },
          los5: {
            ...groupingJson.los5,
            [item]: dayWiseDetail?.[0]?.LOS5,
            total:
              groupingJson.los4.total + parseFloat(dayWiseDetail?.[0]?.LOS5),
          },
          los6: {
            ...groupingJson.los6,
            [item]: dayWiseDetail?.[0]?.LOS6,
            total:
              groupingJson.los6.total + parseFloat(dayWiseDetail?.[0]?.LOS6),
          },
          occ: {
            ...groupingJson.occ,
            [item]: dayWiseDetail?.[0]?.Occperc,
            total:
              groupingJson.occ.total + parseFloat(dayWiseDetail?.[0]?.Occperc),
          },
          totalRevenue: {
            ...groupingJson.totalRevenue,
            [item]: dayWiseDetail?.[0]?.TotalRevenue,
            total:
              groupingJson.totalRevenue.total +
              parseFloat(dayWiseDetail?.[0]?.TotalRevenue),
          },
        };
      });
      let stackedData = {
        labels: [
          "Sunday",
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
        ],
        datasets: [LOS1, LOS2, LOS3, LOS4, LOS5, LOS6, occPercentage],
      };
      setLoading(false);
      setLosData(groupingJson);
      setChartData(stackedData);
    }

    if (!response?.isValidate) {
      let stackedData = {
        labels: [
          "Sunday",
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
        ],
        datasets: [LOS1, LOS2, LOS3, LOS4, LOS5, LOS6, occPercentage],
      };
      setLoading(false);
      setLosData(dummyLosData);
      setChartData(stackedData);
    }
  };

  useEffect(() => {
    if (api === 0) {
      let stackedData = {
        labels: [
          "Sunday",
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
        ],
        datasets: [LOS1, LOS2, LOS3, LOS4, LOS5, LOS6, occPercentage],
      };
      setLosData(dummyLosData);
      setChartData(stackedData);
    } else {
      if (para?.asOfDate) {
        getLosData();
      }
    }
  }, [para?.asOfDate]);
  Chart.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Tooltip,
    Legend,
    PointElement,
    LineElement
  );
  return (
    <div>
      {/* <Container> */}
      <Row>
        {loading ? (
          <Loader />
        ) : (
          chartData && (
            <Col xl={12}>
              <div className="snapShotBarWithLineChartInner">
                <Bar data={chartData} options={options} />
              </div>
            </Col>
          )
        )}
        {losData && (
          <>
            <Col xl={12}>
              {/* <div className="barWithLineChartTable mt-2"> */}
              <div className="ViewPropertySetupTable mt-2">
                <div className="stripped__table">
                  <Table responsive>
                    <thead>
                      <tr>
                        <td></td>
                        {week?.map((item, index) => (
                          <td>{item}</td>
                        ))}
                        <td>Total</td>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.entries(losData)?.map(([key, value], index) => {
                        let keyName = key;
                        if (key === "los1") {
                          keyName = "1 Night Stay";
                        } else if (key === "los2") {
                          keyName = "2 Night Stay";
                        } else if (key === "los3") {
                          keyName = "3-6 Night Stay";
                        } else if (key === "los4") {
                          keyName = "7-14 Night Stay";
                        } else if (key === "los5") {
                          keyName = "15-29 Night Stay";
                        } else if (key === "los6") {
                          keyName = "30+ Night Stay";
                        } else if (key === "occ") {
                          keyName = "Occupancy";
                        } else {
                          keyName = "Revenue";
                        }
                        return (
                          <tr>
                            <td>{keyName}</td>
                            {week?.map((item) => {
                              let data = commonService.formateRoom(losData[key][item]);
                              if (key === "occ") {
                                data = `${losData[key][item]}%`;
                              } else if (key === "totalRevenue") {
                                data = `${commonService.formateAmount(
                                  losData[key][item]
                                )}`;
                              }
                              return <td>{data}</td>;
                            })}
                            <td>
                              {key === "occ"
                                ? `${Math.round(losData[key].total / 7)}%`
                                : key === "totalRevenue"
                                ? `${commonService.formateAmount(
                                    losData[key].total
                                  )}`
                                : commonService.formateRoom(losData[key].total)}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
              </div>
              {/* </div> */}
            </Col>
          </>
        )}

        {/* {chartData && (
            <Col xl={12}>
              <div className="snapShotBarWithLineChartInner">
                <Bar data={chartData} options={options} />
              </div>
            </Col>
          )}
          {losData && (
            <Col xl={12}>
              <div className="barWithLineChartTable mt-2">
                <div className="table__container">
                  <div className="stripped__table">
                    <Table>
                      <thead>
                        <tr>
                          <td></td>
                          {week?.map((item, index) => (
                            <td>{item}</td>
                          ))}
                          <td>Total</td>
                        </tr>
                      </thead>
                      <tbody>
                        {Object.entries(losData)?.map(([key, value], index) => {
                          return (
                            <tr>
                              <td>{key}</td>
                              {week?.map((item) => {
                                let data = losData[key][item];
                                if (key === "occ") {
                                  data = `${losData[key][item]}%`;
                                } else if (key === "totalRevenue") {
                                  data = `$${losData[key][item]}`;
                                }
                                return <td>{data}</td>;
                              })}
                              <td>
                                {key === "occ"
                                  ? `${Math.round(losData[key].total / 7)}%`
                                  : key === "totalRevenue"
                                  ? `$${losData[key].total}`
                                  : losData[key].total}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            </Col>
          )} */}
      </Row>
      {/* </Container> */}
      {api !== 0 && <Comments widgetDetails={para} editorId={"LosDayOfWeek"} />}
    </div>
  );
};

export default LosDayOfWeek;
