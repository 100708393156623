// import "./styles.css";
import "./customCalender.css";
import moment, { weekdays } from "moment";
import { useEffect, useRef, useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import smallTent from "../../Assets/Images/Revenue-management/smallTent.svg";
import dateVerticalThreeDot from "../../Assets/Images/dateVerticalThreeDot.svg";
import { commonService } from "../../utils/commonService";
import tentIcon from "../../Assets/Images/Revenue-management/circus-tent.svg";
import RmToolEventCard from "../CommonComponent/RmToolEventCard";
// import { useSelector } from "react-redux";
// import AddActivity from "../../Model/AddActivity";
// import ActivityCalenderModal from "./ActivityCalenderModal";

const CustomFullCalendar = (props) => {
    const [EventListModalShow, setEventListModalShow] = useState(false);

    const [modalShowActivity, setModalShowActivity] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [EventListDate, setEventListDate] = useState([]);
    const selectedDateRef = useRef();
    //   const [EditActivityId, setEditActivityId]: any = useState(
    //     "00000000-0000-0000-0000-000000000000"
    //   );

    const [EditActivityId, setEditActivityId] = useState(
        "00000000-0000-0000-0000-000000000000"
    );

    const SameDay = new Date();

    let dayNames = [
        {
            F: "Sunday",
            M: "Sun",
            S: "Su",
        },
        {
            F: "Monday",
            M: "Mon",
            S: "MO",
        },
        {
            F: "Tuesday",
            M: "Tue",
            S: "Tu",
        },
        {
            F: "Wednesday",
            M: "Wed",
            S: "We",
        },
        {
            F: "Thursday",
            M: "Thu",
            S: "Th",
        },
        {
            F: "Friday",
            M: "Fri",
            S: "Fr",
        },
        {
            F: "Saturday",
            M: "Sat",
            S: "Sa",
        },
    ];

    let monthNames = [
        {
            F: "January",
            M: "Jan",
        },
        {
            F: "February",
            M: "Feb",
        },
        {
            F: "March",
            M: "Mar",
        },
        {
            F: "April",
            M: "Apr",
        },
        {
            F: "May",
            M: "May",
        },
        {
            F: "June",
            M: "Jun",
        },
        {
            F: "July",
            M: "Jul",
        },
        {
            F: "August",
            M: "Aug",
        },
        {
            F: "September",
            M: "Sep",
        },
        {
            F: "October",
            M: "Oct",
        },
        {
            F: "November",
            M: "Nov",
        },
        {
            F: "December",
            M: "Dec",
        },
    ];

    const [date, setdate] = useState(props.option.date);
    const [time, settime] = useState([
        0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
        21, 22, 23,
    ]);
    const [viewtype, setviewtype] = useState(props.option.viewtype);
    const data = props.option.data;
    const [CalDisplayLalbe, setCalDisplayLalbe] = useState(
        monthNames[date.getMonth()].F + "-" + date.getFullYear()
    );

    useEffect(() => {
        setviewtype(props.option.viewtype);
        setdate(props.option.date);
        let dates = new Date(props.option.date);
        if (props.option.viewtype === "Month") {
            dates = new Date(props.option.date);
            // dates.setMonth(dates.getMonth() + 1);
            // setdate(dates);
            // props?.setDateOfParent(dates);
            setCalDisplayLalbe(
                monthNames[dates.getMonth()].F + "-" + dates.getFullYear()
            );
        } else {
            dates = new Date(props.option.date);
            var first = dates.getDate() - dates.getDay();
            var firstDay = new Date(dates.setDate(first));
            // firstDay.setDate(dates.getDate() + 7);
            // setdate(firstDay);
            // props?.setDateOfParent(firstDay);
            dates = new Date(firstDay);
            moment(firstDay).weekday(6).format("MMMM DD, yyyy");
            setCalDisplayLalbe(
                monthNames[firstDay.getMonth()].F +
                " " +
                firstDay.getDate() +
                " - " +
                moment(firstDay).weekday(6).format("MMMM DD, yyyy")
            );
        }
    }, [props]);

    function fnEventListModal(item, c_date) {
        setEventListDate(item);
        selectedDateRef.current = c_date;
        setEventListModalShow(true);
    }

    function fnGenerateCal(date, viewtype, data) {
        if (viewtype === "Day") {
            return DayWeekView(date, data);
        } else if (viewtype === "Month") {
            return MonthView(date, data);
        } else if (viewtype === "Week") {
            return DayWeekView(date, data);
        } else if (viewtype === "List") {
            return ListView(date, data);
        }
    }

    function DayWeekView(date, data) {
        var lastDay = new Date(date);
        let d = new Date(date);
        d.setDate(d.getDate() - 1);
        var firstDay = new Date(d);
        if (viewtype === "Week") {
            let Dates = MakeFirstAndLastDate("Week", date);
            firstDay = Dates.StartDate;
            lastDay = Dates.EndDate;
        }
        let WeekDays = [];
        for (
            var dt = firstDay;
            dt <= lastDay;
            dt = new Date(dt.setDate(dt.getDate() + 1))
        ) {
            WeekDays.push(new Date(dt.getFullYear(), dt.getMonth(), dt.getDate()));
        }
        const WeekDay1 = WeekDays[1];
        return (
            <>
                {viewtype === "Day" && (
                    <table
                        className={`month-view-calender dayWeek-view-calender show-border-sticky viewtype-${viewtype}`}
                    >
                        <thead className="dayWeek_view_calender_thead">
                            <tr>
                                <th width={80} className="week_cell time_width"></th>
                                <th className="week_cell">
                                    {moment(new Date(WeekDay1)).format("ddd MM/DD")}
                                </th>
                            </tr>
                        </thead>
                        <tbody className="day_week_body">
                            {time.map((t_item, t_index) => (
                                <tr key={t_index}>
                                    <td
                                        className={`day_cell ${moment(t_item, ["HH"]).format("hh a") ===
                                                moment(SameDay, ["HH"]).format("hh a")
                                                ? "active"
                                                : ""
                                            }`}
                                    >
                                        {moment(t_item, ["HH"]).format("hh a")}
                                    </td>
                                    <td
                                        onClick={() => {
                                            props.setShowModal({ addModal: true, editModal: false });
                                        }}
                                        sd={new Date(new Date(WeekDay1).setHours(t_item))}
                                        ed={new Date(new Date(WeekDay1).setHours(t_item + 1))}
                                        className={`day_cell ${moment(WeekDay1).format("MM-DD-yyyy") ===
                                                moment(SameDay).format("MM-DD-yyyy")
                                                ? "dayno today"
                                                : "dayno"
                                            }`}
                                    >
                                        <div className="event-cell">
                                            {fnBindAllEvents(
                                                data.filter(
                                                    (e) =>
                                                        new Date(e.date) >=
                                                        new Date(new Date(WeekDay1).setHours(t_item)) &&
                                                        new Date(e.date) <
                                                        new Date(new Date(WeekDay1).setHours(t_item + 1))
                                                )
                                            )}
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}
                {viewtype === "Week" && (
                    // <table
                    //   className={`month-view-calender dayWeek-view-calender show-border-sticky viewtype-${viewtype}`}
                    // >
                    //   <thead className="dayWeek_view_calender_thead">
                    //     <tr>
                    //       <th width={80} className="week_cell time_width"></th>
                    //       {WeekDays.map((d, index) => {
                    //         // header date wise for weekly view
                    //         return (
                    //           <th className="week_cell" key={index}>
                    //             {moment(new Date(d)).format("ddd MM/DD")}
                    //           </th>
                    //         );
                    //       })}
                    //     </tr>
                    //   </thead>
                    //   <tbody className="day_week_body">
                    //     {time.map((t_item, t_index) => (
                    //       <tr key={t_index}>
                    //         <td
                    //           className={`day_cell ${
                    //             moment(t_item, ["HH"]).format("hh a") ===
                    //             moment(SameDay, ["HH"]).format("hh a")
                    //               ? "active"
                    //               : ""
                    //           }`}
                    //         >
                    //           {moment(t_item, ["HH"]).format("hh a")}
                    //         </td>
                    //         {WeekDays.map((d_item, index) => (
                    //           <td
                    //             onClick={() => {
                    //               props.setShowModal({
                    //                 addModal: true,
                    //                 editModal: false,
                    //               });
                    //             }}
                    //             key={index}
                    //             sd={new Date(new Date(d_item).setHours(t_item))}
                    //             ed={new Date(new Date(d_item).setHours(t_item + 1))}
                    //             className={`day_cell ${
                    //               moment(d_item).format("MM-DD-yyyy") ===
                    //               moment(SameDay).format("MM-DD-yyyy")
                    //                 ? "dayno today"
                    //                 : "dayno"
                    //             }`}
                    //           >
                    //             <div className="event-cell">
                    //               {fnBindAllEvents(
                    //                 data.filter(
                    //                   (e) =>
                    //                     new Date(e.date) >=
                    //                       new Date(new Date(d_item).setHours(t_item)) &&
                    //                     new Date(e.date) <
                    //                       new Date(new Date(d_item).setHours(t_item + 1))
                    //                 )
                    //               )}
                    //             </div>
                    //           </td>
                    //         ))}
                    //       </tr>
                    //     ))}
                    //   </tbody>
                    // </table>
                    <div className="RmEventsWeekViewInner">
                        {WeekDays?.map((weekItem, weekIndex) => {
                            return (
                                <div className="WeekViewDayMain">
                                    <h5>{moment(new Date(weekItem)).format("dddd")}</h5>
                                    <div className="WeekViewDayInner">
                                        <div className="WeekViewDayEventWrapper">
                                            <h6 className="WeekViewDayDate">
                                                {new Date(weekItem).getDate()}
                                            </h6>
                                            <div className="WeekViewDayEventCardWrapper">
                                                {fnBindAllEvents(
                                                    data.filter((e) => {
                                                        if (
                                                            weekItem >=
                                                            new Date(
                                                                commonService.getDateInFormat(
                                                                    e?.startdate || e?.start_date
                                                                )
                                                            ) &&
                                                            new Date(new Date(weekItem)) <=
                                                            new Date(
                                                                commonService.getDateInFormat(
                                                                    e?.enddate || e?.end_date
                                                                )
                                                            )
                                                        ) {
                                                            return e;
                                                        }
                                                    })
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                )}
            </>
        );
    }
    function fnBindAllEvents(data) {
        return (
            <>
                {data.map((ed, edindex) => {
                    return (
                        <div
                            className="RmToolEventCardMain"
                            // style={{ background: eventBg }}
                            onClick={() => {
                                props?.handleSingleevent(ed);
                            }}
                        >
                            <img src={tentIcon} alt="tentIcon" />
                            <p>{ed?.title || ed?.eventname}</p>
                        </div>
                    );
                })}
            </>
        );
    }

    function MakeFirstAndLastDate(fnviewType, date) {
        let StartDate = date;
        let EndDate = date;
        let CalenderViewStartDate = date;
        let CalenderViewEndDate = date;

        if (fnviewType === "Day") {
            EndDate = new Date(date);
            let d = new Date(date);
            d.setDate(d.getDate() - 1);
            StartDate = new Date(d);
            CalenderViewStartDate = new Date(date);
            CalenderViewEndDate = new Date(date);
        } else if (fnviewType === "Month" || fnviewType === "List") {
            StartDate = new Date(date.getFullYear(), date.getMonth(), 1);

            EndDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
            CalenderViewStartDate = new Date(
                new Date(StartDate).setDate(StartDate.getDate() - StartDate.getDay())
            );
            CalenderViewEndDate = new Date(
                new Date(StartDate).setDate(StartDate.getDate() - StartDate.getDay())
            );
            CalenderViewEndDate = new Date(
                CalenderViewEndDate.setDate(CalenderViewEndDate.getDate() + 41)
            );
        } else if (fnviewType === "Week") {
            var first = date.getDate() - date.getDay();
            var last = first + 6;
            StartDate = new Date(date.setDate(first));
            if (first < 0) {
                let newEndDate = date.getDate() + 6;
                EndDate = new Date(StartDate).setDate(newEndDate);
                // let newEndDate = new Date().setDate(date.getDate() + 6);
                // EndDate = new Date(newEndDate);
            } else {
                EndDate = new Date(date.setDate(last));
            }
            // first < 0
            //   ? (EndDate = new Date().setDate(date.getDate() + 6))
            //   : (EndDate = new Date(date.setDate(last)));

            /*   --------   */
            // EndDate = new Date().setDate(date.getDate() + 6);
            CalenderViewStartDate = StartDate;
            CalenderViewEndDate = EndDate;
        }

        return {
            viewType: fnviewType,
            StartDate: StartDate,
            EndDate: EndDate,
            CalenderViewStartDate: CalenderViewStartDate,
            CalenderViewEndDate: CalenderViewEndDate,
        };
    }

    //   const [TooltipState, setTooltipState]: any = useState({
    //     action: false,
    //     id: "",
    //   });

    const [TooltipState, setTooltipState] = useState({ action: false, id: "" });

    function MonthView(date, data) {
        let Dates = MakeFirstAndLastDate("Month", date);
        var firstDay = Dates.StartDate;
        var lastDay = Dates.EndDate;
        var CalStart = Dates.CalenderViewStartDate;
        var CalEnd = Dates.CalenderViewEndDate;
        let DateRowArray = [];
        for (let r = 0; r < 6; r++) {
            let DateColArray = [];
            for (let c = 0; c < 7; c++) {
                DateColArray.push(new Date(CalStart));
                CalStart = new Date(CalStart.setDate(CalStart.getDate() + 1));
            }
            DateRowArray.push(DateColArray);
        }
        function fnBindAllEvents(data, c_date) {
            return (
                <>
                    {data.map((ed, edindex) => {
                        if (edindex < 3) {
                            return (
                                <div
                                    style={{ cursor: "pointer" }}
                                    className="event-strip-with-bg"
                                    key={edindex}
                                    onClick={(e) => {
                                        // e.preventDefault();
                                        e.stopPropagation();
                                        props.setShowModal({ addModal: false, editModal: true });
                                    }}
                                >
                                    {TooltipState.action && TooltipState.id === ed.id ? (
                                        <div className="tooltip-calendar" key={edindex}>
                                            <div className="inner-tooltip-calendar">
                                                <div className="tooltip-calendar-data">
                                                    <p>{ed.title}</p>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                    <img src={smallTent} alt="smallTent" title="Event" />
                                    <p
                                        className="event_details Red"
                                        title={ed?.eventname || ed?.title}
                                        onClick={(e) => {
                                            props?.handleSingleevent(ed);
                                        }}
                                    >
                                        {ed?.eventname?.slice(0, 20) || ed?.title?.slice(0, 20)}{" "}
                                        {ed?.eventname?.length > 20 || ed?.title?.length > 20
                                            ? "..."
                                            : ""}
                                    </p>
                                </div>
                            );
                        }
                    })}
                    {data.length > 3 && (
                        <p
                            className="more_btn event_details"
                            onClick={() => {
                                // fnEventListModal(data);
                                fnEventListModal(data, c_date);
                            }}
                        >
                            {data.length - 3}+ more
                        </p>
                    )}
                </>
            );
        }
        function hasEventDetails() {
            const eventDetailsElement = document.querySelector(
                ".main-cell .event_details"
            );
            return eventDetailsElement !== null;
        }

        return (
            <>
                <table className="month-view-calender">
                    <thead>
                        <tr>
                            <th className="table_cell">{dayNames[0].M}</th>
                            <th className="table_cell">{dayNames[1].M}</th>
                            <th className="table_cell">{dayNames[2].M}</th>
                            <th className="table_cell">{dayNames[3].M}</th>
                            <th className="table_cell">{dayNames[4].M}</th>
                            <th className="table_cell">{dayNames[5].M}</th>
                            <th className="table_cell">{dayNames[6].M}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {DateRowArray.map((r_item, row) => (
                            <tr key={row}>
                                {r_item.map((c_item, col) => (
                                    <td
                                        sd={c_item}
                                        ed={
                                            new Date(new Date(c_item).setDate(c_item.getDate() + 1))
                                        }
                                        className={
                                            `${c_item >= firstDay && c_item <= lastDay
                                                ? ""
                                                : "disable table_body_cell"
                                            }` ||
                                            `table_body_cell ${moment(c_item).format("MM-DD-yyyy") ===
                                                moment(SameDay).format("MM-DD-yyyy")
                                                ? "dayno today"
                                                : "dayno"
                                            }`
                                        }
                                        key={col}
                                    >
                                        <div
                                            className="main-cell"
                                            onClick={(e) => {
                                                // e.preventDefault();
                                                props.setShowModal({
                                                    addModal: true,
                                                    editModal: false,
                                                });
                                            }}
                                        >
                                            <div className="dateVerticalThreeDotWrapper">
                                                <div
                                                    className={
                                                        hasEventDetails()
                                                            ? "date-cell has-event"
                                                            : "date-cell"
                                                    }
                                                >
                                                    {c_item.getDate()}
                                                </div>
                                                <div className="dateVerticalThreeDot">
                                                    <img
                                                        src={dateVerticalThreeDot}
                                                        alt="dateVerticalThreeDot"
                                                    />
                                                </div>
                                            </div>

                                            <div className="event-cell">
                                                {fnBindAllEvents(
                                                    data.filter((e, index) => {
                                                        // if (
                                                        //   new Date(e.date) >= c_item &&
                                                        //   new Date(e.date) <
                                                        //     new Date(
                                                        //       new Date(c_item).setDate(c_item.getDate() + 1)
                                                        //     )
                                                        // ) {
                                                        //   return e;
                                                        // }
                                                        if (
                                                            c_item >=
                                                            new Date(
                                                                commonService.getDateInFormat(
                                                                    e?.startdate || e?.start_date
                                                                )
                                                            ) &&
                                                            new Date(new Date(c_item)) <=
                                                            new Date(
                                                                commonService.getDateInFormat(
                                                                    e?.enddate || e?.end_date
                                                                )
                                                            )
                                                        ) {
                                                            return e;
                                                        }
                                                    }),
                                                    c_item
                                                    // data
                                                )}
                                            </div>
                                        </div>
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </>
        );
    }

    function fnNextPrev(value) {
        let dates = new Date(date);
        if (viewtype === "Month" || viewtype === "List") {
            if (value === 1) {
                dates = new Date(date);
                dates.setMonth(dates.getMonth() + 1);
                setdate(dates);
                props?.setDateOfParent(dates);
                setCalDisplayLalbe(
                    monthNames[dates.getMonth()].F + "-" + dates.getFullYear()
                );
            } else if (value === -1) {
                dates = new Date(date);
                dates.setMonth(dates.getMonth() - 1);
                setdate(dates);
                props?.setDateOfParent(dates);
                setCalDisplayLalbe(
                    monthNames[dates.getMonth()].F + "-" + dates.getFullYear()
                );
            } else if (value === 0) {
                dates = new Date();
                dates.setMonth(dates.getMonth());
                setdate(dates);
                props?.setDateOfParent(dates);
                setCalDisplayLalbe(
                    monthNames[dates.getMonth()].F + "-" + dates.getFullYear()
                );
            }
        } else if (viewtype === "Week") {
            if (value === 1) {
                dates = new Date(date);
                var first = dates.getDate() - dates.getDay();
                var firstDay = new Date(dates.setDate(first));
                firstDay.setDate(dates.getDate() + 7);
                setdate(firstDay);
                props?.setDateOfParent(firstDay);
                dates = new Date(firstDay);
                moment(firstDay).weekday(6).format("MMMM DD, yyyy");
                setCalDisplayLalbe(
                    monthNames[firstDay.getMonth()].F +
                    " " +
                    firstDay.getDate() +
                    " - " +
                    moment(firstDay).weekday(6).format("MMMM DD, yyyy")
                );
            } else if (value === -1) {
                dates = new Date(date);
                var first = dates.getDate() - dates.getDay();
                var firstDay = new Date(dates.setDate(first));
                firstDay.setDate(dates.getDate() - 7);
                setdate(firstDay);
                props?.setDateOfParent(firstDay);
                dates = new Date(firstDay);
                setCalDisplayLalbe(
                    monthNames[firstDay.getMonth()].F +
                    " " +
                    firstDay.getDate() +
                    " - " +
                    moment(firstDay).weekday(6).format("MMMM DD, yyyy")
                );
            } else if (value === 0) {
                dates = new Date();
                var first = dates.getDate() - dates.getDay();
                var firstDay = new Date(dates.setDate(first));
                firstDay.setDate(dates.getDate());
                setdate(firstDay);
                props?.setDateOfParent(firstDay);
                dates = new Date(firstDay);

                setCalDisplayLalbe(
                    monthNames[firstDay.getMonth()].F +
                    " " +
                    firstDay.getDate() +
                    " - " +
                    moment(firstDay).weekday(6).format("MMMM DD, yyyy")
                );
            }
        } else if (viewtype === "Day") {
            if (value === 1) {
                dates = new Date(date);
                dates.setDate(dates.getDate() + 1);
                setdate(dates);
                setCalDisplayLalbe(moment(dates).format("dddd, MMMM DD, yyyy"));
            } else if (value === -1) {
                dates = new Date(date);
                dates.setDate(dates.getDate() - 1);
                setdate(dates);
                setCalDisplayLalbe(moment(dates).format("dddd, MMMM DD, yyyy"));
            } else if (value === 0) {
                dates = new Date();
                dates.setDate(dates.getDate());
                setdate(dates);
                setCalDisplayLalbe(moment(dates).format("dddd, MMMM DD, yyyy"));
            }
        }

        if (props.changeCallBack !== undefined && props.changeCallBack !== null) {
            props.changeCallBack(MakeFirstAndLastDate(viewtype, dates));
        }
    }

    function formatAMPM(date) {
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var ampm = hours >= 12 ? "pm" : "am";
        hours = hours % 12;
        hours = hours ? hours : 12;
        minutes = minutes < 10 ? "0" + minutes : minutes;
        var strTime = hours + ":" + minutes + " " + ampm;
        return strTime;
    }

    function fnViewChange(value) {
        let dates = new Date(date);
        if (value === "Week") {
            dates = new Date(date);
            var first = dates.getDate() - dates.getDay();
            var firstDay = new Date(dates.setDate(first));
            firstDay.setDate(dates.getDate());
            setdate(firstDay);
            dates = new Date(firstDay);
            setCalDisplayLalbe(
                monthNames[firstDay.getMonth()].F +
                " " +
                firstDay.getDate() +
                " - " +
                moment(firstDay).weekday(6).format("MMMM DD, yyyy")
            );
        } else if (value === "Day") {
            dates = new Date(date);
            dates.setDate(dates.getDate());
            setdate(dates);

            setCalDisplayLalbe(moment(dates).format("dddd, MMMM DD, yyyy"));
        } else if (value === "Month" || value === "List") {
            dates = new Date(date);
            dates.setMonth(dates.getMonth());
            setdate(dates);
            props?.setDateOfParent(dates);
            setCalDisplayLalbe(
                monthNames[dates.getMonth()].F + "-" + dates.getFullYear()
            );
        }
        setviewtype(value);
        if (props.changeCallBack !== undefined && props.changeCallBack !== null) {
            props.changeCallBack(MakeFirstAndLastDate(value, dates));
        }
    }

    function ListView(date, data) {
        let Dates = MakeFirstAndLastDate("List", date);
        var firstDay = Dates.StartDate;
        var lastDay = Dates.EndDate;
        var CalStart = Dates.CalenderViewStartDate;
        var CalEnd = Dates.CalenderViewEndDate;

        let month = date.getMonth();
        let year = date.getFullYear();
        var date = new Date(year, month, 1);
        var days = [];
        while (date.getMonth() === month) {
            days.push(new Date(date));
            date.setDate(date.getDate() + 1);
        }

        let lst = [];
        data.forEach((el1) => {
            for (let index = 0; index < days.length; index++) {
                const el2 = days[index];
                if (
                    moment(el1.date).format("MM-DD-yyyy") ===
                    moment(el2).format("MM-DD-yyyy")
                ) {
                    lst.push(el1);
                } else {
                    lst.push();
                }
            }
        });

        // groupdata

        const groups = lst.reduce((groups, elist) => {
            const date = moment(elist.date).format("MM-DD-yyyy");
            if (!groups[date]) {
                groups[date] = {
                    date: date,
                    eventlist: [],
                };
            }
            groups[date].eventlist.push(elist);
            return groups;
        }, {});

        return (
            <>
                {Object.keys(groups).length > 0 ? (
                    Object.keys(groups).map((list_item, list_index) => (
                        <table
                            key={list_index}
                            className="month-view-calender dayWeek-view-calender list-view-calender"
                        >
                            <thead className="list_head">
                                <tr>
                                    <th className="">
                                        <span>{moment(groups[list_item].date).format("dddd")}</span>
                                        <span>
                                            {moment(groups[list_item].date).format("MMMM DD,yyyy")}
                                        </span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="list_body">
                                {groups[list_item].eventlist.length > 0 &&
                                    groups[list_item].eventlist.map((e_item, e_index) => (
                                        <tr key={e_index}>
                                            <td className="">
                                                <p>{formatAMPM(new Date(e_item.date))}</p>
                                                <p className="dot-blue">{e_item.title}</p>
                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    ))
                ) : (
                    <>
                        <div className="no_data">
                            <div>No Data Found.</div>
                        </div>
                    </>
                )}
            </>
        );
    }

    function EventListModal(props) {
        return (
            <Modal
                {...props}
                aria-labelledby="contained-modal-title-vcenter"
                size="md"
                centered
                className="popupForm rightsideModal"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {/* Event List (
            {moment(props.EventListDate[0].date).format("MM-DD-yyyy")}) */}
                        Event List ({commonService.getDateInFormat(selectedDateRef.current)}
                        )
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="">
                    {/* <Table bordered responsive>
            <thead>
              <tr>
                <th style={{ width: "100%" }}>Title</th>
                <th style={{ width: "20%" }}>Time</th>
              </tr>
            </thead>
            <tbody>
              {props.EventListDate.map((data_item, data_index) => (
                <tr>
                  <td
                    onClick={(e) => {
                      setEditActivityId(data_item.id);
                      setModalShowActivity(true);
                      props.onHide();
                    }}
                  >
                    {data_item?.title || data_item?.eventname}
                  </td>
                  <td>{moment(data_item.date).format("hh:mm a")}</td>
                </tr>
              ))}
            </tbody>
          </Table> */}
                    <div className="event-list-wrpper-main">
                        <div className=" d-block">
                            {props.EventListDate.map((data_item, data_index) => (
                                <div className="d-flex align-items-center event-strip-with-bg event-list-modal-list gap-2 mb-3">
                                    <img src={smallTent} alt="smallTent" />
                                    <p
                                        onClick={(e) => {
                                            setEditActivityId(data_item.id);
                                            setModalShowActivity(true);
                                            props.onHide();
                                        }}
                                        className="m-0"
                                    >
                                        {data_item?.title || data_item?.eventname}
                                    </p>
                                </div>
                            ))}
                        </div>
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <Button className="default-btn primary-btn w-100" onClick={() => props.onHide()}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }

    return (
        <>
            <div
                className={`${props?.viewDetail?.viewProperty
                        ? "viewProperty_calender_main"
                        : "calender_main"
                    }`}
            >
                <div className="calender_buttons">
                    <div className="text_start">
                        <button onClick={(e) => fnNextPrev(-1)} className="prev btn_css">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="icon icon-tabler icon-tabler-chevron-left"
                                width="21"
                                height="21"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="#ffffff"
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            >
                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                <path d="M15 6l-6 6l6 6" />
                            </svg>
                        </button>
                        <div className="text_center">
                            <h4> {CalDisplayLalbe}</h4>
                        </div>
                        <button onClick={(e) => fnNextPrev(1)} className="next btn_css">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="icon icon-tabler icon-tabler-chevron-right"
                                width="21"
                                height="21"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="#ffffff"
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            >
                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                <path d="M9 6l6 6l-6 6" />
                            </svg>
                        </button>
                        {!props?.viewDetail?.viewProperty && (
                            <button onClick={(e) => fnNextPrev(0)} className="btn_css">
                                Today
                            </button>
                        )}
                    </div>

                    {!props?.viewDetail?.viewProperty && (
                        <div className="text_end">
                            <button
                                onClick={(e) => fnViewChange("Month")}
                                className={`btn_css ${viewtype === "Month" ? "view_active" : "month"
                                    }`}
                            >
                                Month
                            </button>

                            <button
                                onClick={(e) => fnViewChange("Week")}
                                className={`btn_css ${viewtype === "Week" ? "view_active" : "week"
                                    }`}
                            >
                                Week
                            </button>

                            <button
                                onClick={(e) => fnViewChange("Day")}
                                className={`btn_css ${viewtype === "Day" ? "view_active" : "day"
                                    }`}
                            >
                                Day
                            </button>

                            <button
                                onClick={(e) => fnViewChange("List")}
                                className={`btn_css ${viewtype === "List" ? "view_active" : "list"
                                    }`}
                            >
                                List
                            </button>
                        </div>
                    )}
                    {/* <div className="text_end">
            <button
              onClick={(e) => fnViewChange("Month")}
              className={`btn_css ${
                viewtype === "Month" ? "view_active" : "month"
              }`}
            >
              Month
            </button>

            <button
              onClick={(e) => fnViewChange("Week")}
              className={`btn_css ${
                viewtype === "Week" ? "view_active" : "week"
              }`}
            >
              Week
            </button>

            <button
              onClick={(e) => fnViewChange("Day")}
              className={`btn_css ${
                viewtype === "Day" ? "view_active" : "day"
              }`}
            >
              Day
            </button>

            <button
              onClick={(e) => fnViewChange("List")}
              className={`btn_css ${
                viewtype === "List" ? "view_active" : "list"
              }`}
            >
              List
            </button>
          </div> */}
                </div>
                <div className="calender_body">
                    {fnGenerateCal(date, viewtype, data)}
                </div>
            </div>

            {EventListModalShow && (
                <EventListModal
                    show={EventListModalShow}
                    // bindList={MEvents}
                    EventListDate={EventListDate}
                    onHide={() => setEventListModalShow(false)}
                />
            )}

            {/* {modalShowActivity && (
        <AddActivity
          Activity={"MainActivity"}
          show={modalShowActivity}
          // getEditActivitytdetails={fetchAllActivities}
          EditActivityId={EditActivityId}
          onHide={() => {
            setModalShowActivity(false);
            setEditActivityId("00000000-0000-0000-0000-000000000000");
          }}
        ></AddActivity>
      )}
      {showModal && (
        <ActivityCalenderModal
          showModal={showModal}
          hotelIds={iniSTR}
          setShowModal={setShowModal}
        />
      )} */}
        </>
    );
};

export default CustomFullCalendar;
/**
 * Outside library ::
 * npm i json-server
 * json-server --watch db.json --p 5004
 * npm i moment
 */
