import React, { useEffect, useState } from "react";
import {
  Accordion,
  Button,
  Form,
  FormControl,
  InputGroup,
  Offcanvas,
  ProgressBar,
} from "react-bootstrap";
import SearchIcon from "../../Assets/Images/Header/search-icon.svg";
import progressDot from "../../Assets/Images/Revenue-management/progressDot.svg";
import plusIcon from "../../Assets/Images/FormCheck/plusIcon.png";
import closeIcon from "../../Assets/Images/Operation/closeIcon.svg";
import DatePicker from "react-datepicker";

function RmEventFilter({ show, handleClose }) {
  const [progress, setProgress] = useState(50);

  const handleProgressBarClick = (e) => {
    const progressBar = e.currentTarget;
    const clickX = e.clientX - progressBar.getBoundingClientRect().left;
    const progressBarWidth = progressBar.clientWidth;
    const newProgress = (clickX / progressBarWidth) * 100;
    setProgress(newProgress);
  };
  const [startFrom, setStartFrom] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  return (
    <Offcanvas
      show={show}
      onHide={handleClose}
      placement="end"
      className="RmEventFilterMain"
    >
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Filter</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <div className="RmEventFilterMainWrapper">
          <InputGroup className="search-group-for-table">
            <Button className="border-0">
              <img src={SearchIcon} />
            </Button>
            <FormControl
              className="border-0"
              placeholder=""
              aria-label="Search"
            />
          </InputGroup>
          <div className="RmEventFilterAccordion">
            <Accordion defaultActiveKey={["0"]} alwaysOpen>
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  Event Type <b>(18)</b>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="RmEventFilterAccordionWrapper">
                    <div className="RmEventFilterAccordionInner d-flex align-items-center justify-content-between">
                      <InputGroup className="search-group-for-table">
                        <Button className="border-0">
                          <img src={SearchIcon} />
                        </Button>
                        <FormControl
                          className="border-0"
                          placeholder=""
                          aria-label="Search"
                        />
                      </InputGroup>
                      <button className="clearAllBtn">Clear all</button>
                    </div>
                    <div className="mt-3">
                      <div className="singleCheckBox d-flex gap-2 align-items-center">
                        <Form.Check aria-label="option 1" id="Community" />
                        <Form.Check.Label
                          htmlFor="Community"
                          className="label-radio"
                        >
                          Community
                        </Form.Check.Label>
                      </div>
                      <div className="singleCheckBox d-flex gap-2 align-items-center mt-2">
                        <Form.Check aria-label="option 1" id="Concerts" />
                        <Form.Check.Label
                          htmlFor="Concerts"
                          className="label-radio"
                        >
                          Concerts
                        </Form.Check.Label>
                      </div>
                      <div className="singleCheckBox d-flex gap-2 align-items-center mt-2">
                        <Form.Check aria-label="option 1" id="Academic" />
                        <Form.Check.Label
                          htmlFor="Academic"
                          className="label-radio"
                        >
                          Academic
                        </Form.Check.Label>
                      </div>
                      <div className="singleCheckBox d-flex gap-2 align-items-center mt-2">
                        <Form.Check aria-label="option 1" id="Observances" />
                        <Form.Check.Label
                          htmlFor="Observances"
                          className="label-radio"
                        >
                          Observances
                        </Form.Check.Label>
                      </div>
                      <div className="singleCheckBox d-flex gap-2 align-items-center mt-2">
                        <Form.Check aria-label="option 1" id="Expos" />
                        <Form.Check.Label
                          htmlFor="Expos"
                          className="label-radio"
                        >
                          Expos
                        </Form.Check.Label>
                      </div>
                      <div className="singleCheckBox d-flex gap-2 align-items-center mt-2">
                        <Form.Check aria-label="option 1" id="Festivals" />
                        <Form.Check.Label
                          htmlFor="Festivals"
                          className="label-radio"
                        >
                          Festivals
                        </Form.Check.Label>
                      </div>
                      <div className="singleCheckBox d-flex gap-2 align-items-center mt-2">
                        <Form.Check aria-label="option 1" id="Sports" />
                        <Form.Check.Label
                          htmlFor="Sports"
                          className="label-radio"
                        >
                          Sports
                        </Form.Check.Label>
                      </div>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>Rank</Accordion.Header>
                <Accordion.Body>
                  <div>
                    <div className="RmEventFilterBottomPartInner">
                      <div className="singleCheckBox d-flex gap-2 align-items-center">
                        <Form.Check aria-label="option 1" id="Community" />
                        <Form.Check.Label
                          htmlFor="Community"
                          className="label-radio"
                        >
                          EMA Rank
                        </Form.Check.Label>
                      </div>
                      <span style={{ color: "#3565FC" }}>20 - 80</span>
                    </div>
                    <div
                      className="mt-3 RmEventFilterBottomPartProgress"
                      style={{ position: "relative" }}
                    >
                      <ProgressBar
                        now={progress}
                        onClick={handleProgressBarClick}
                      />
                      <div
                        className="circular-point"
                        style={{ left: `${progress - 1}% ` }}
                      ></div>
                    </div>
                    <div className="RmEventFilterBottomPartProgressLabels d-flex justify-content-between align-items-center mt-2 ">
                      <span>0</span>
                      <span>100</span>
                    </div>
                  </div>
                  <div>
                    <div className="RmEventFilterBottomPartInner">
                      <div className="singleCheckBox d-flex gap-2 align-items-center">
                        <Form.Check aria-label="option 1" id="Community" />
                        <Form.Check.Label
                          htmlFor="Community"
                          className="label-radio"
                        >
                          EMA Rank
                        </Form.Check.Label>
                      </div>
                      <span style={{ color: "#3565FC" }}>20 - 80</span>
                    </div>
                    <div
                      className="mt-3 RmEventFilterBottomPartProgress"
                      style={{ position: "relative" }}
                    >
                      <ProgressBar now={progress} />
                      <div
                        className="circular-point"
                        style={{ left: `${progress - 1}% ` }}
                      ></div>
                    </div>
                    <div className="RmEventFilterBottomPartProgressLabels d-flex justify-content-between align-items-center mt-2 border-0 pb-0">
                      <span>0</span>
                      <span>100</span>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  {" "}
                  Labels <b>(18)</b>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="RmEventFilterAccordionWrapper">
                    <div className="RmEventFilterAccordionInner d-flex align-items-center justify-content-between">
                      <InputGroup className="search-group-for-table">
                        <Button className="border-0">
                          <img src={SearchIcon} />
                        </Button>
                        <FormControl
                          className="border-0"
                          placeholder=""
                          aria-label="Search"
                        />
                      </InputGroup>
                      <button className="clearAllBtn">Clear all</button>
                    </div>
                    <div className="mt-3">
                      <div className="singleCheckBox d-flex gap-2 align-items-center">
                        <Form.Check aria-label="option 1" id="Community2" />
                        <Form.Check.Label
                          htmlFor="Community2"
                          className="label-radio"
                        >
                          Community
                        </Form.Check.Label>
                      </div>
                      <div className="singleCheckBox d-flex gap-2 align-items-center mt-2">
                        <Form.Check aria-label="option 1" id="Concerts2" />
                        <Form.Check.Label
                          htmlFor="Concerts2"
                          className="label-radio"
                        >
                          Concerts
                        </Form.Check.Label>
                      </div>
                      <div className="singleCheckBox d-flex gap-2 align-items-center mt-2">
                        <Form.Check aria-label="option 1" id="Academic2" />
                        <Form.Check.Label
                          htmlFor="Academic2"
                          className="label-radio"
                        >
                          Academic
                        </Form.Check.Label>
                      </div>
                      <div className="singleCheckBox d-flex gap-2 align-items-center mt-2">
                        <Form.Check aria-label="option 1" id="Observances2" />
                        <Form.Check.Label
                          htmlFor="Observances2"
                          className="label-radio"
                        >
                          Observances
                        </Form.Check.Label>
                      </div>
                      <div className="singleCheckBox d-flex gap-2 align-items-center mt-2">
                        <Form.Check aria-label="option 1" id="Expos2" />
                        <Form.Check.Label
                          htmlFor="Expos2"
                          className="label-radio"
                        >
                          Expos
                        </Form.Check.Label>
                      </div>
                      <div className="singleCheckBox d-flex gap-2 align-items-center mt-2">
                        <Form.Check aria-label="option 1" id="Festivals2" />
                        <Form.Check.Label
                          htmlFor="Festivals2"
                          className="label-radio"
                        >
                          Festivals
                        </Form.Check.Label>
                      </div>
                      <div className="singleCheckBox d-flex gap-2 align-items-center mt-2">
                        <Form.Check aria-label="option 1" id="Sports2" />
                        <Form.Check.Label
                          htmlFor="Sports2"
                          className="label-radio"
                        >
                          Sports
                        </Form.Check.Label>
                      </div>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>
                  Date <b>(09/01/2023 - 09/08/2023)</b>
                </Accordion.Header>
                <Accordion.Body>
                  <div className=" form-groups range-date d-flex from-groups no-border-break gap-3 mb-2">
                    <div className="startFrom">
                      <Form.Label htmlFor="startFrom">Start Date</Form.Label>
                      <DatePicker
                        id="startFrom"
                        selected={startFrom}
                        onChange={(date) => setStartFrom(date)}
                        selectsStart
                        startDate={startFrom}
                        endDate={endDate}
                      />
                    </div>
                    <div className="endDate">
                      <Form.Label htmlFor="endDate">End Date</Form.Label>
                      <DatePicker
                        id="endDate"
                        selected={endDate}
                        onChange={(date) => setEndDate(date)}
                        selectsEnd
                        startDate={startFrom}
                        endDate={endDate}
                        minDate={startFrom}
                      />
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header>Event Status</Accordion.Header>
                <Accordion.Body>
                  <div className="RmEventFilterAccordionWrapper">
                    <div className="RmEventFilterAccordionInner d-flex align-items-center justify-content-between">
                      <InputGroup className="search-group-for-table">
                        <Button className="border-0">
                          <img src={SearchIcon} />
                        </Button>
                        <FormControl
                          className="border-0"
                          placeholder=""
                          aria-label="Search"
                        />
                      </InputGroup>
                      <button className="clearAllBtn">Clear all</button>
                    </div>
                    <div className="mt-3">
                      <div className="singleCheckBox d-flex gap-2 align-items-center">
                        <Form.Check aria-label="option 1" id="Community3" />
                        <Form.Check.Label
                          htmlFor="Community3"
                          className="label-radio"
                        >
                          Community
                        </Form.Check.Label>
                      </div>
                      <div className="singleCheckBox d-flex gap-2 align-items-center mt-2">
                        <Form.Check aria-label="option 1" id="Concerts3" />
                        <Form.Check.Label
                          htmlFor="Concerts3"
                          className="label-radio"
                        >
                          Concerts
                        </Form.Check.Label>
                      </div>
                      <div className="singleCheckBox d-flex gap-2 align-items-center mt-2">
                        <Form.Check aria-label="option 1" id="Academic3" />
                        <Form.Check.Label
                          htmlFor="Academic3"
                          className="label-radio"
                        >
                          Academic
                        </Form.Check.Label>
                      </div>
                      <div className="singleCheckBox d-flex gap-2 align-items-center mt-2">
                        <Form.Check aria-label="option 1" id="Observances3" />
                        <Form.Check.Label
                          htmlFor="Observances3"
                          className="label-radio"
                        >
                          Observances
                        </Form.Check.Label>
                      </div>
                      <div className="singleCheckBox d-flex gap-2 align-items-center mt-2">
                        <Form.Check aria-label="option 1" id="Expos3" />
                        <Form.Check.Label
                          htmlFor="Expos3"
                          className="label-radio"
                        >
                          Expos
                        </Form.Check.Label>
                      </div>
                      <div className="singleCheckBox d-flex gap-2 align-items-center mt-2">
                        <Form.Check aria-label="option 1" id="Festivals3" />
                        <Form.Check.Label
                          htmlFor="Festivals3"
                          className="label-radio"
                        >
                          Festivals
                        </Form.Check.Label>
                      </div>
                      <div className="singleCheckBox d-flex gap-2 align-items-center mt-2">
                        <Form.Check aria-label="option 1" id="Sports3" />
                        <Form.Check.Label
                          htmlFor="Sports3"
                          className="label-radio"
                        >
                          Sports
                        </Form.Check.Label>
                      </div>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="5">
                <Accordion.Header>Location</Accordion.Header>
                <Accordion.Body>
                  <div className="RmEventFilterAccordionWrapper">
                    <div className="RmEventFilterAccordionInner d-flex align-items-center justify-content-between">
                      <InputGroup className="search-group-for-table">
                        <Button className="border-0">
                          <img src={SearchIcon} />
                        </Button>
                        <FormControl
                          className="border-0"
                          placeholder=""
                          aria-label="Search"
                        />
                      </InputGroup>
                      <Button className="add-btn-plus p-1" type="button" title="Add">
                        <img src={plusIcon} alt="plus" className="m-0" />
                      </Button>
                    </div>
                    <div className="mt-3">
                      <div className="form-check form-switch no-border-break from-groups ">
                        <input
                          className="form-check-input me-2"
                          type="checkbox"
                          role="switch"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="hasGuestName"
                        >
                          <b>Show my locations only</b>
                        </label>
                      </div>
                      <div className="RmEventFilterLocation">
                        <div className="mb-2 mt-2 RmEventFilterLocationInner">
                          <div className="d-flex justify-content-between align-items-center mb-1">
                            <div>
                              <span>Location</span>
                              <p
                                style={{
                                  color: "#3565FC",
                                  marginBottom: "8px",
                                }}
                              >
                                12A Bayfront Ave, Singapore 018970
                              </p>
                            </div>
                            <img src={closeIcon} alt="closeIcon" />
                          </div>
                        </div>
                        <div className="RmEventFilterLocationInner">
                          <div className="d-flex justify-content-between align-items-center mb-1">
                            <div>
                              <span>Location</span>
                              <p
                                style={{
                                  color: "#3565FC",
                                  marginBottom: "8px",
                                }}
                              >
                                12A Bayfront Ave, Singapore 018970
                              </p>
                            </div>
                            <img src={closeIcon} alt="closeIcon" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <div className="RmEventFilterBottomPart">
              <div className="RmEventFilterBottomPartInner">
                <span>Miles</span>
                <span style={{ color: "#3565FC" }}>
                  0 miles - {Math.round(progress)} miles
                </span>
              </div>
              <div
                className="mt-3 RmEventFilterBottomPartProgress"
                style={{ position: "relative" }}
              >
                <ProgressBar now={progress} onClick={handleProgressBarClick} />
                <div
                  className="circular-point"
                  style={{ left: `${progress - 1}% ` }}
                ></div>
              </div>
              <div className="RmEventFilterBottomPartProgressLabels d-flex justify-content-between align-items-center mt-2">
                <span>0 miles</span>
                <span>500 miles</span>
              </div>
              <div className="RmEventFilterBottomAttendance">
                <h6>EMA Attendance</h6>
                <div className="d-flex justify-content-center gap-4">
                  <div className="from-groups no-border-break ">
                    <Form.Label>From</Form.Label>
                    <Form.Control type="text" maxLength="67" placeholder="30" />
                  </div>
                  <div className="from-groups no-border-break ">
                    <Form.Label>To</Form.Label>
                    <Form.Control type="text" maxLength="67" placeholder="30" />
                  </div>
                </div>
              </div>
              <div className="RmEventFilterBottomFooterButton">
                <Button className="default-btn primary-btn">Apply</Button>
                <Button className="default-btn secondary-btn">Cancel</Button>
              </div>
            </div>
          </div>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
}

export default RmEventFilter;
