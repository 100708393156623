import React, { useEffect, useRef, useState } from "react";
import leftArrow from "../../Assets/Images/Revenue-management/leftArrow.svg";
import rightArrow from "../../Assets/Images/Revenue-management/rightArrow.svg";

import {
  Badge,
  Button,
  Col,
  FloatingLabel,
  Form,
  Modal,
  Row,
  Tab,
  Table,
  Tabs,
} from "react-bootstrap";
import moment from "moment";
import { commonService } from "../../utils/commonService";
import { apiResponse } from "../../utils/apiResponse";
import { apiCall } from "../../utils/axiosService";
import { API_URL } from "../../utils/apiRoutes";
import {
  Chart,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  Filler,
} from "chart.js";
import { Line } from "react-chartjs-2";
import RateShopHistoryChartModal from "./ListingComponent/RateShop/RateShopHistoryChartModal";
import Loader from "../Loader/Loader";
import { useSelector } from "react-redux";
import downArrow from "../../Assets/Images/Header/downArrow.svg";

function ToolsSummaryModal({
  showToolsSummaryModal,
  closeToolsSummaryModal,
  selectedDate,
  propertyDetail,
  ismlRateExist,
}) {
  const SummaryPopUpTblData = [
    {
      competitors: "Best Western Patriots",
      price: "$154",
      myPrice: "green",
    },
    {
      competitors: "Best Western Patriots",
      price: "$154",
      myPrice: "green",
    },
    {
      competitors: "Best Western Patriots",
      price: "$154",
      myPrice: "yellow",
    },
    {
      competitors: "Best Western Patriots",
      price: "$154",
      myPrice: "green",
    },
    {
      competitors: "Best Western Patriots",
      price: "$154",
      myPrice: "red",
    },
    {
      competitors: "Best Western Patriots",
      price: "$154",
      myPrice: "red",
    },
  ];
  const [date, setDate] = useState(
    moment(new Date(selectedDate)).format("MMMM DD YYYY")
  );
  const [rateShopList, setRateShopList] = useState(null);
  const [loading, setLoading] = useState(false);
  const [benchMarkingData, setBenchMarkingData] = useState(null);
  const [openRows, setOpenRows] = useState({});
  const companyListLengthRef = useRef();
  const [activeTab, setActiveTab] = useState("Summary");
  const asOfDateFromRTK = useSelector((store) => store.headerProperty.asOfDate);
  const asOfDatesArr = useRef();
  const allLastDates = useSelector((store) => store.headerProperty.allDates);

  const getRateShopList = async () => {
    setLoading(true);
    let byPropertyIdResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.RevenueManagement +
          API_URL.REVENUE_RATE_SHOP_CONFIG.GET_BY_PROPERTY_ID,
        body: {
          id: propertyDetail?.propertyid,
        },
      },
      false
    );

    let propertyResponse = apiResponse(false, byPropertyIdResponse);

    let rateShopListResponse = await apiCall(
      {
        method: "POST",
        url: API_URL.BASE_API_URL.SnapShot + API_URL.RATESHOP.RATESHOP_LIST,
        body: {
          AsOfDate: asOfDateFromRTK?asOfDateFromRTK:"",
          propertyid: propertyDetail?.propertyid,
          propertyCode: propertyDetail?.propertycode,
          Channel: propertyResponse?.data?.data?.channel_term
            ? propertyResponse?.data?.data?.channel_term
            : "",
          startDate: moment(date).format("YYYY-MM-DD"),
          endDate: moment(date).format("YYYY-MM-DD"),
        },
      },
      false
    );

    let response = apiResponse(false, rateShopListResponse);

    if (response?.isValidate) {
      let dataFromApi = response?.data?.data?.RateShop;
      companyListLengthRef.current = dataFromApi?.length;
      let minValueFromArr = dataFromApi?.filter(
        (x) => x?.IsSelf !== "1" && parseFloat(x?.Rate) > 0
      );
      let groupingJson = {
        total: 0,
        // max: parseInt(dataFromApi[0]?.Rate),
        // min: parseInt(dataFromApi[0]?.Rate),
        max: parseInt(minValueFromArr[0]?.Rate),
        min: parseInt(minValueFromArr[0]?.Rate),
      };
      let findDataForAvgCount = "";

      for (let i = 0; i < dataFromApi?.length; i++) {
        let keyName =
          dataFromApi[i]?.IsSelf === true
            ? "isSelf"
            : dataFromApi[i]?.competiterpropertyname;

        groupingJson = {
          ...groupingJson,
          lengthForAvg: minValueFromArr?.length,
          total:
            dataFromApi[i]?.IsSelf === true
              ? groupingJson?.total
              : parseInt(dataFromApi[i]?.Rate) > 0
              ? groupingJson.total + parseInt(dataFromApi[i]?.Rate)
              : groupingJson?.total,
          max:
            dataFromApi[i]?.IsSelf === true
              ? groupingJson.max
              : parseInt(dataFromApi[i]?.Rate) > 0
              ? groupingJson.max < parseInt(dataFromApi[i]?.Rate)
                ? parseInt(dataFromApi[i]?.Rate)
                : groupingJson.max
              : groupingJson.max,
          min:
            dataFromApi[i]?.IsSelf === true
              ? groupingJson.min
              : parseInt(dataFromApi[i]?.Rate) > 0
              ? groupingJson.min > parseInt(dataFromApi[i]?.Rate)
                ? parseInt(dataFromApi[i]?.Rate)
                : groupingJson.min
              : groupingJson.min,
          [keyName]: {
            price: dataFromApi[i]?.Rate,
          },
        };
      }
      setRateShopList(groupingJson);
      setLoading(false);
    }

    if (!response?.isValidate) {
      setRateShopList(null);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (activeTab === "Summary") {
      getRateShopList();
    }
  }, [date, activeTab]);

  const [chartData, setChartData] = useState(null);
  const datesArrRef = useRef();
  const isSelfRef = useRef();

  const getChartDetail = async () => {
    setChartData(null);
    let asOfDateResponse = await apiCall(
      {
        method: "POST",
        url: API_URL.BASE_API_URL.SnapShot + API_URL.AS_OF_DATE.AS_OF_DATE,
        body: { propertyCode: propertyDetail?.propertycode },
      },
      false
    );

    if (asOfDateResponse?.status_code === 1) {
      let chartDetailResponse = await apiCall(
        {
          method: "POST",
          url: API_URL.BASE_API_URL.SnapShot + API_URL.RATEIQ.GET_CHART,
          body: {
            AsOfDate: asOfDateResponse?.data?.[0]?.last_data_refresh
              ? asOfDateResponse?.data?.[0]?.last_data_refresh
              : `${commonService.getDateForNode(new Date())} 00:00:00`,
            CheckInDate: moment(date).format("YYYY-MM-DD"),
            propertyCode: propertyDetail?.propertycode,
            propertyid: propertyDetail?.propertyid,
            Channel: propertyDetail?.chain_term,
          },
        },
        false
      );

      let response = apiResponse(false, chartDetailResponse);

      if (response?.isValidate) {
        let startDate = commonService.convertDateInTimezone(
          asOfDateResponse?.data?.[0]?.last_data_refresh
            ? asOfDateResponse?.data?.[0]?.last_data_refresh
            : new Date()
        );
        let ed = moment(startDate).toDate();
        ed.setDate(ed.getDate() - 32);
        let endDate = commonService.convertDateInTimezone(ed);

        let datesArr = commonService.getDateBetweenTwoDates(
          commonService.convertDateInTimezone(endDate),
          commonService.convertDateInTimezone(startDate)
        );
        datesArrRef.current = datesArr;

        let dataFromApi = response?.data?.data?.rateiqchart;

        let groupingJson = {};
        // dataFromApi?.map((item) => {
        //   if (groupingJson[item?.competiterpropertyname]) {
        //   } else {
        //     groupingJson = {
        //       ...groupingJson,
        //       [item?.competiterpropertyname]: [],
        //     };
        //   }
        // });

        datesArr?.map((item) => {
          let findDataFromApi = dataFromApi?.filter(
            (x) =>
              moment(x?.AsOfDate).format("YYYY-MM-DD") ===
              moment(item).format("YYYY-MM-DD")
          );

          Object?.entries(findDataFromApi?.[0])?.map(([key, value]) => {
            if (key !== "CheckInDate" && key !== "AsOfDate") {
              if (groupingJson[key]) {
                groupingJson[key] = [...groupingJson[key], value ? value : 0];
              } else {
                groupingJson[key] = [value ? value : 0];
              }
            }
          });

          // if (findDataFromApi?.length <= 0) {
          //   Object.keys(groupingJson)?.map((i) => {
          //     groupingJson[i].push(0);
          //   });
          // }

          // findDataFromApi?.map((dataItem) => {
          //   if (dataItem?.IsSelf) {
          //     isSelfRef.current = dataItem?.competiterpropertyname;
          //   }
          //   if (groupingJson[dataItem?.competiterpropertyname]) {
          //     groupingJson[dataItem?.competiterpropertyname].push(
          //       dataItem?.Rate ? parseFloat(dataItem?.Rate) : 0
          //     );
          //   } else {
          //     groupingJson = {
          //       ...groupingJson,
          //       [dataItem?.competiterpropertyname]: [
          //         dataItem?.Rate ? parseFloat(dataItem?.Rate) : 0,
          //       ],
          //     };
          //   }
          // });
        });

        let dataForMl = response?.data?.data?.ml_rate;
        groupingJson = { ...groupingJson, "ML Rate": [] };

        datesArr?.map((item) => {
          let findDataFromApi = dataForMl?.filter(
            (x) =>
              moment(x?.AsOfDate).format("YYYY-MM-DD") ===
              moment(item).format("YYYY-MM-DD")
          );

          groupingJson["ML Rate"]?.push(
            findDataFromApi?.[0]?.ml_rate ? findDataFromApi?.[0]?.ml_rate : 0
          );
        });
        setChartData(groupingJson);
      }

      if (!response?.isValidate) {
        setChartData(null);
      }
    }
  };

  useEffect(() => {
    if (activeTab === "RateShop History Chart") {
      getChartDetail();
    }
  }, [date, activeTab]);

  const getBenchMarkingData = async () => {
    setLoading(true);
    let startDate = new Date(date);
    let endDate = new Date(date);
    endDate.setDate(startDate.getDate() + 14);
    let benchMarkingResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.SnapShot + API_URL.RATESHOP.GET_RATE_EVOLUTION,
        body: {
          AsOfDate: commonService.getDateForNode(asOfDateFromRTK),
          checkinstartdate: commonService.getDateForNode(startDate),
          checkinenddate: commonService.getDateForNode(endDate),
          propertyCode: propertyDetail?.propertycode,
        },
      },
      false
    );

    let response = apiResponse(false, benchMarkingResponse);

    if (response?.isValidate) {
      let dataFromApi = response?.data?.data;
      let groupingJson = { high: 0, low: 0, data: [] };
      let checkInDatesObj = {};
      let asOfDateWiseArr = [];
      for (let i = 0; i <= 14; i++) {
        let date = new Date(commonService.getDateInFormat(asOfDateFromRTK));
        date.setDate(date.getDate() - i);
        asOfDateWiseArr.push(moment(date).format("YYYY-MM-DD"));
      }
      asOfDatesArr.current = asOfDateWiseArr;
      dataFromApi?.forEach((item) => {
        if (!checkInDatesObj[item?.checkindate]) {
          if (item?.isself === true || item?.isself === "true") {
            let obj = { ...item },
              high = 0,
              low = 0;
            let findCompetitorData = dataFromApi?.filter(
              (x) => x?.checkindate === item?.checkindate && x?.isself === false
            );
            let highLowObj = {};
            findCompetitorData?.forEach((compItem) => {
              asOfDateWiseArr?.forEach((asOfDateItem) => {
                if (
                  highLowObj[`${asOfDateItem}Rate`] &&
                  highLowObj[`${asOfDateItem}Count`]
                ) {
                  highLowObj[`${asOfDateItem}Rate`] =
                    highLowObj[`${asOfDateItem}Rate`] +
                    (compItem[asOfDateItem]
                      ? Math.round(compItem[asOfDateItem])
                      : 0);
                  highLowObj[`${asOfDateItem}Count`] = compItem[asOfDateItem]
                    ? highLowObj[`${asOfDateItem}Count`] + 1
                    : highLowObj[`${asOfDateItem}Count`];
                } else {
                  highLowObj[`${asOfDateItem}Rate`] = compItem[asOfDateItem]
                    ? Math.round(compItem[asOfDateItem])
                    : 0;
                  highLowObj[`${asOfDateItem}Count`] = compItem[asOfDateItem]
                    ? 1
                    : 0;
                }
              });
            });

            asOfDateWiseArr.forEach((highLowItem) => {
              let rate =
                highLowObj[`${highLowItem}Rate`] /
                highLowObj[`${highLowItem}Count`];
              let selfRate = item[highLowItem]
                ? Math.round(item[highLowItem])
                : 0;
              if (selfRate > rate) {
                high = high + 1;
                obj[`${highLowItem}-isHigh`] = true;
              } else {
                low = low + 1;
                obj[`${highLowItem}-isHigh`] = false;
              }
            });
            obj.high = high;
            obj.low = low;

            obj.children = findCompetitorData;
            groupingJson?.data.push(obj);
          }
        }
      });
      setBenchMarkingData(groupingJson);
      setLoading(false);
    }

    if (!response?.isValidate) {
      asOfDatesArr.current = [];
      setBenchMarkingData(null);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (activeTab === "Bench Marking") {
      getBenchMarkingData();
    }
  }, [date, activeTab]);

  let mergedData = {},
    options = {};
  if (chartData) {
    options = {
      elements: {
        point: {
          radius: 0,
        },
      },
      scales: {
        x: {
          title: {
            display: true,
            text: "LeadTime",
          },
          grid: {
            display: false,
          },
        },
        y: {
          stacked: false,
          beginAtZero: true,
          type: "linear",
          position: "left",
          ticks: {
            stepSize: 10,
            callback: function (value, index, values) {
              return `$ ${value}`;
            },
          },
        },
      },
      plugins: {
        legend: {
          display: true,
          align: "center",
          position: "bottom",
          paddingTop: 10,
          maxWidth: 20,
          maxHeight: 100,
          labels: {
            boxWidth: 10,
            boxHeight: 10,
          },
        },
        tooltip: {
          enabled: true,
          mode: "index",
          callbacks: {
            title: function (context) {
              let labelText = context[0].label || "";
              let index = context[0].dataIndex;
              let date = datesArrRef.current[index];
              let formatedDate = moment(date).format("dddd MMMM D YYYY");
              return `Leadtime ${labelText} ${formatedDate}`;
            },
            label: function (context) {
              let label = context.dataset.label || "";
              return `${label}: $${context.raw}`;
            },
          },
        },
      },
      responsive: true,
      maintainAspectRatio: true,
      aspectRatio: 4 / 1,
    };
    let dataSetDetail = Object?.entries(chartData)?.map(([key, value]) => {
      // let color = Math.floor(Math.random() * 16777215).toString(16);
      let color = commonService.CreateColor(`${key}`).BGColor
      return {
        type: "line",
        label: key,
        data: value,
        fill: false,
        backgroundColor: isSelfRef.current === key ? "#134AFF" : `${color}`,
        borderColor: isSelfRef.current === key ? "#134AFF" : `${color}`,
        borderWidth: isSelfRef.current === key ? 5 : 2,
        order: 0,
        pointRadius: 5,
        pointBorderWidth: 4,
        pointBorderColor: "#ffffff",
        pointBorderRadius: 10,
      };
    });

    mergedData = {
      labels: [
        32, 31, 30, 29, 28, 27, 26, 25, 24, 23, 22, 21, 20, 19, 18, 17, 16, 15,
        14, 13, 12, 11, 10, 9, 8, 7, 6, 5, 4, 3, 2, 1, 0,
      ],
      datasets: dataSetDetail,
    };
  }
  Chart.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Tooltip,
    Legend,
    PointElement,
    LineElement,
    Filler
  );

  return (
    <>
      <Modal
        className="ToolsSummaryModalMain"
        size="xl"
        centered
        show={showToolsSummaryModal}
        onHide={closeToolsSummaryModal}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <div className="toolSummaryModalHeader">
              <div className="dateWithArrow m-0">
                <img
                  src={leftArrow}
                  alt="leftArrow"
                  onClick={() => {
                    let d = new Date(commonService.getDateInFormat(date));
                    d.setDate(d.getDate() - 1);
                    setDate(moment(d).format("MMMM DD YYYY"));
                  }}
                />
                <p>{date}</p>
                <img
                  src={rightArrow}
                  alt="rightArrow"
                  onClick={() => {
                    let d = new Date(commonService.getDateInFormat(date));
                    d.setDate(d.getDate() + 1);
                    setDate(moment(d).format("MMMM DD YYYY"));
                  }}
                />
              </div>
              <div>
                {allLastDates?.rateshopDate && (
                  <p>{allLastDates?.rateshopDate}</p>
                )}
              </div>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Tabs
            defaultActiveKey="Summary"
            transition={false}
            id="noanim-tab-example"
            className="mb-3"
            onSelect={(k) => {
              setActiveTab(k);
            }}
          >
            <Tab eventKey="Summary" title="Summary">
              {activeTab === "Summary" && (
                <div className="GroupNameModalBody">
                  <Row>
                    <Col xl={12}>
                      <div className="GroupNameModalBodyInner">
                        <div className="dateWithArrowBottom">
                          <div className="myRateCardsMain">
                            <p>My Price</p>
                            <p>
                              {rateShopList?.isSelf?.price
                                ? `${commonService.formateAmount(
                                    Math.round(rateShopList?.isSelf?.price)
                                  )}`
                                : ""}
                            </p>
                          </div>
                          <div
                            className="myRateCardsMain"
                            style={{ background: "#E0F2E9" }}
                          >
                            <p>Min Rate</p>
                            <p>
                              {rateShopList?.min
                                ? `${commonService.formateAmount(
                                    Math.round(rateShopList?.min)
                                  )}`
                                : ""}
                            </p>
                          </div>
                          <div
                            className="myRateCardsMain"
                            style={{ background: "#FFF6E0" }}
                          >
                            <p>Avg Rate</p>
                            <p>
                              {rateShopList?.total
                                ? `${commonService.formateAmount(
                                    Math.round(
                                      rateShopList?.total /
                                        rateShopList?.lengthForAvg
                                    )
                                  )}`
                                : ""}
                            </p>
                          </div>
                          <div
                            className="myRateCardsMain"
                            style={{ background: "#FCE5E2" }}
                          >
                            <p>Max Rate</p>
                            <p>
                              {rateShopList?.max
                                ? `${commonService.formateAmount(
                                    Math.round(rateShopList?.max)
                                  )}`
                                : ""}
                            </p>
                          </div>
                        </div>
                        <div className="ToolsSummaryPopUpTable">
                          <div className="table__container">
                            <div className="stripped__table">
                              <Table id="SummaryPopUpTbl" responsive>
                                <thead>
                                  <tr>
                                    <th className="">Competitors</th>
                                    <th className="width150 text-end">price</th>
                                    <th className="text-center">my price</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {rateShopList &&
                                    Object.entries(rateShopList)?.map(
                                      ([key, value]) => {
                                        return (
                                          <React.Fragment key={key}>
                                            {key !== "isSelf" &&
                                              key !== "total" &&
                                              key !== "max" &&
                                              key !== "min" &&
                                              key !== "lengthForAvg" && (
                                                <tr>
                                                  <td>{key}</td>
                                                  <td className="text-end">
                                                    {commonService.formateAmount(
                                                      value.price
                                                    )}
                                                  </td>
                                                  <td className="text-center">
                                                    {commonService.formateAmount(
                                                      rateShopList?.isSelf
                                                        ?.price
                                                    )}
                                                    {/* {item.myPrice === "green" ? (
                                            <Badge className="default-badge active-badge">
                                              $160
                                            </Badge>
                                          ) : item.myPrice === "yellow" ? (
                                            <Badge className="default-badge lYellow-badge">
                                              $160
                                            </Badge>
                                          ) : (
                                            <Badge className="default-badge inactive-badge">
                                              $160
                                            </Badge>
                                          )} */}
                                                  </td>
                                                </tr>
                                              )}
                                          </React.Fragment>
                                        );
                                      }
                                    )}
                                </tbody>
                              </Table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                    {/* <Col xl={4} className="GroupNameModalBodyRightMain">
                      <div className="GroupNameModalBodyRightInner">
                        <h5>Alerts</h5>
                        <div className="grpNameRightSingle">
                          <div className="form-groups">
                            <div className="radio-container">
                              <div className="form-radio">
                                <input
                                  type="radio"
                                  className="form-check-input"
                                  name="radioGroup"
                                  value="Option 1"
                                  id="Option1"
                                  defaultChecked
                                />
                                <Form.Check.Label htmlFor="Option1">
                                  WG02795 - Today's ADR is $88 close to
                                  ForecastedADR and also near value compare with
                                  BudgetedADR.
                                </Form.Check.Label>
                              </div>
                              <div className="form-radio">
                                <input
                                  type="radio"
                                  className="form-check-input"
                                  name="radioGroup"
                                  value="Option 2"
                                  id="Option2"
                                />
                                <Form.Check.Label htmlFor="Option2">
                                  WG02795 - Today's ADR is $76 very much close
                                  to ForecastedADR and also near value compare
                                  with BudgetedADR. for next few days just need
                                  to compare with Competitor Future Day Rates.
                                </Form.Check.Label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col> */}
                  </Row>
                </div>
              )}
            </Tab>
            <Tab
              eventKey="RateShop History Chart"
              title="RateShop History Chart"
            >
              {/* RateShop History Chart */}
              {activeTab === "RateShop History Chart" && loading ? (
                <Loader />
              ) : chartData ? (
                <div className="GroupNameModalBody">
                  <Line data={mergedData} options={options} />
                </div>
              ) : (
                <>
                  <p>No Data Found</p>
                </>
              )}
            </Tab>
          </Tabs>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ToolsSummaryModal;
