import React from 'react';
import { Bar, Line } from "react-chartjs-2";
import {
  Chart,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  Filler
} from "chart.js";

function RateShopLeadTimeChart() {
  const options = {
    elements: {
      point: {
        radius: 0
      }
    },
    scales: {
      x: {
        title: {
          display: false,
          text: "Months",
        },
        grid: {
          display: false,
        },

      },
      y: {
        stacked: false,
        beginAtZero: true,
        type: "linear",
        position: 'left',
        max: 195,
        min: 105,
        ticks: {
          stepSize: 10,
          callback: function (value, index, values) {
            return `$${value}`;
          }
        }
      },
    },
    plugins: {
      tooltip: {
        mode: "index",
        intersect: false,
      },
      legend: {
        display: true,
        align: "center",
        position: "bottom",
        paddingTop: 10,
        maxWidth: 20,
        maxHeight: 100,
        labels: {
          boxWidth: 10,
          boxHeight: 10,
        },
      },
    },
    responsive: true,
    maintainAspectRatio: true,
    aspectRatio: 4 / 1,
  };

  const mergedData = {
    labels: ["07/01", "07/02", "07/03", "07/04", "07/05", "07/06", "07/07", "07/08", "07/09", "07/10", "07/11", "07/12", "07/13", "07/14", "07/15", "07/16", "07/17", "07/18", "07/19", "07/20", "07/21", "07/22", "07/23", "07/24", "07/25", "07/26", "07/27", "07/28", "07/29", "07/30", "07/31"],
    datasets: [
      {
        type: "line",
        label: "DoubleTree Chicago Wood Dale - Elk Grove",
        data: [194, 194, 194, 194, 194, 194, 194, 194, 194, 194, 194, 194, 194, 150, 150, 150, 150, 150, 150, 150, 150, 150, 150, 160, 160, 160, 160, 170, 170, 170, 170],
        fill: false,
        backgroundColor: "#0F4FAE",
        borderColor: "#0F4FAE",
        borderWidth: 2,
        order: 0,
        pointRadius: 3,
        pointBorderWidth: 1,
        pointBorderColor: '#ffffff',
      },
      {
        type: "line",
        label: "Courtyard Chicago Wood Dale/Itasca",
        data: [185, 185, 185, 185, 185, 185, 185, 185, 185, 185, 185, 185, 185, 185, 185, 185, 185, 185, 185, 185, 185, 185, 185, 185, 185, 174, 174, 177, 163, 163, 163],
        fill: false,
        backgroundColor: "#0000A8",
        borderColor: "#0000A8",
        borderWidth: 2,
        order: 0,
        pointRadius: 3,
        pointBorderWidth: 1,
        pointBorderColor: '#ffffff',
      },
      {
        type: "line",
        label: "Courtyard Chicago Wood Dale/Itasca",
        data: [181, 181, 181, 181, 181, 181, 181, 165, 165, 165, 165, 165, 165, 150, 150, 150, 150, 150, 150, 150, 150, 150, 150, 160, 160, 140, 140, 140, 140, 140, 140],
        fill: false,
        backgroundColor: "#FF791D",
        borderColor: "#FF791D",
        borderWidth: 2,
        order: 0,
        pointRadius: 3,
        pointBorderWidth: 1,
        pointBorderColor: '#ffffff',
      },
      {
        type: "line",
        label: "Courtyard Chicago Wood Dale/Itasca",
        data: [173, 173, 173, 173, 173, 173, 173, 173, 173, 173, 173, 173, 173, 173, 173, 173, 173, 173, 173, 173, 173, 173, 173, 173, 173, 163, 163, 163, 163, 163, 163],
        fill: false,
        backgroundColor: "#00C8D4",
        borderColor: "#00C8D4",
        borderWidth: 2,
        order: 0,
        pointRadius: 3,
        pointBorderWidth: 1,
        pointBorderColor: '#ffffff',
      },
      {
        type: "line",
        label: "Courtyard Chicago Wood Dale/Itasca",
        data: [156, 156, 156, 156, 156, 156, 156, 156, 156, 156, 156, 156, 156, 156, 156, 156, 156, 156, 156, 156, 156, 156, 156, 156, 156, 156, 156, 156, 156, 156, 156],
        fill: false,
        backgroundColor: "#9C4E98",
        borderColor: "#9C4E98",
        borderWidth: 2,
        order: 0,
        pointRadius: 3,
        pointBorderWidth: 1,
        pointBorderColor: '#ffffff',
      },
      {
        type: "line",
        label: "Courtyard Chicago Wood Dale/Itasca",
        data: [145, 145, 145, 145, 145, 145, 145, 145, 145, 145, 145, 145, 145, 145, 145, 145, 145, 145, 145, 145, 145, 145, 145, 145, 145, 145, 145, 145, 145, 145, 145],
        fill: false,
        backgroundColor: "#719112",
        borderColor: "#719112",
        borderWidth: 3,
        order: 0,
        pointRadius: 4,
        pointBorderWidth: 1,
        pointBorderColor: '#ffffff',
      },
      {
        type: "line",
        label: "Courtyard Chicago Wood Dale/Itasca",
        data: [120, 120, 120, 120, 120, 120, 120, 120, 120, 120, 120, 120, 120, 120, 120, 120, 120, 120, 120, 120, 120, 120, 120, 120, 120, 120, 120, 120, 120, 120, 120],
        fill: false,
        backgroundColor: "#7ECFFF",
        borderColor: "#7ECFFF",
        borderWidth: 2,
        order: 0,
        pointRadius: 3,
        pointBorderWidth: 1,
        pointBorderColor: '#ffffff',
      },
    ],
  };
  Chart.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Tooltip,
    Legend,
    PointElement,
    LineElement,
    Filler
  );
  return (
    <div>
      <Line data={mergedData} options={options} />
    </div>
  );
}

export default RateShopLeadTimeChart