import React, { useEffect, useState } from "react";
import { apiCall } from "../../../../utils/axiosService";
import { API_URL } from "../../../../utils/apiRoutes";
import { apiResponse } from "../../../../utils/apiResponse";
import moment from "moment";
import { commonService } from "../../../../utils/commonService";
import { Chart } from "chart.js/auto";
import Loader from "../../../Loader/Loader";
import { Line } from "react-chartjs-2";

const BookingPaceForecastChart = ({
    setCompDataFromChild,
    rowIndex,
    colIndex,
    api,
    para,
    setting,
}) => {
    const [loading, setLoading] = useState(false);
    const [chartData, setChartData] = useState(null);

    const getChartData = async () => {
        setLoading(true);
        let dateRange = commonService.getWeekStartAndEndDate(new Date());
        let chartDataResponse = await apiCall(
            {
                method: "POST",
                url:
                    API_URL.BASE_API_URL.SnapShot + API_URL.BOOKING_PACE.COMPARISON_CHART,
                body: {
                    AsOfDate: para?.asOfDate,
                    startDate: commonService.getDateForNode(dateRange?.start),
                    endDate: commonService.getDateForNode(dateRange?.end),
                    propertyCode: para?.propertyCode,
                },
            },
            false
        );

        let response = apiResponse(false, chartDataResponse);

        if (response?.isValidate) {
            let dataFromApi = response?.data?.data;
            let chartDataSet = {
                label: [],
                otb: {
                    label: "OTB",
                    data: [],
                    borderColor: "gray",
                    fill: false,
                },
                high: {
                    label: "High",
                    data: [],
                    borderColor: "green",
                    borderDash: [5, 5],
                    fill: "+1",
                    backgroundColor: "rgba(0, 255, 0, 0.2)",
                    // pointRadius: 0,
                },
                mean: {
                    label: "Mean",
                    data: [],
                    borderColor: "black",
                    borderDash: [5, 5],
                    fill: false,
                    // pointRadius: 0,
                },
                low: {
                    label: "Low",
                    data: [],
                    borderColor: "red",
                    borderDash: [5, 5],
                    fill: "-1",
                    backgroundColor: "rgba(255, 0, 0, 0.2)",
                    // pointRadius: 0,
                },
            },
                min = dataFromApi?.[0]?.forcastroom,
                max = 0,
                totalForecastRoom = 0,
                countForForecastroomAvg = 0;

            dataFromApi?.forEach((item) => {
                if (item?.forcastroom) {
                    min = min > item?.forcastroom ? item?.forcastroom : min;
                }
                max = max < item?.forcastroom ? item?.forcastroom : max;
                totalForecastRoom =
                    totalForecastRoom +
                    (item?.forcastroom ? parseInt(item?.forcastroom) : 0);
                countForForecastroomAvg =
                    item?.forcastroom > 0
                        ? countForForecastroomAvg + 1
                        : countForForecastroomAvg;
                let stayDate = moment(item?.staydate);
                let currentDate = moment(new Date());
                if (stayDate.isSameOrBefore(currentDate)) {
                    chartDataSet?.label.push(item?.staydate);
                    chartDataSet?.otb?.data?.push(item?.total_booking);
                }
            });
            chartDataSet?.label.push("");
            let arr = new Array(chartDataSet?.otb?.data?.length).fill();
            chartDataSet.high.data = [...arr];
            chartDataSet.mean.data = [...arr];
            chartDataSet.low.data = [...arr];
            chartDataSet.high.data[chartDataSet.high.data?.length - 1] =
                chartDataSet.otb.data[chartDataSet.otb.data?.length - 1];
            chartDataSet.mean.data[chartDataSet.mean.data?.length - 1] =
                chartDataSet.otb.data[chartDataSet.otb.data?.length - 1];
            chartDataSet.low.data[chartDataSet.low.data?.length - 1] =
                chartDataSet.otb.data[chartDataSet.otb.data?.length - 1];
            let avg = Math.round(totalForecastRoom / countForForecastroomAvg);

            chartDataSet?.high?.data?.push(max);
            chartDataSet?.low?.data?.push(min);
            chartDataSet?.mean?.data?.push(avg);
            setChartData({
                label: chartDataSet?.label,
                dataset: [
                    chartDataSet?.otb,
                    chartDataSet?.high,
                    chartDataSet?.mean,
                    chartDataSet?.low,
                ],
            });
            setLoading(false);
        }

        if (!response?.isValidate) {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (api === 0) {
        } else {
            if (para?.asOfDate) {
                getChartData();
            }
        }
    }, [para?.asOfDate]);

    const customPluginData = {
        afterDraw: (chart) => {

            const ctx = chart.ctx;
            const xScale = chart.scales["x"];
            const yScale = chart.scales["y"];
            const fontSize = "bold 14";
            const fontColor = "black";
            let lable = chart.data.datasets[1].label;
            let data = chart.data.datasets[1].data;
            let maxValue = Math.max(...data);
            data.forEach((value, index) => {
                if (value && index === data.length - 1) {
                    const x = xScale.getPixelForValue(index);
                    const y = yScale.getPixelForValue(value);

                    let text = lable + " : " + value.toString();

                    const textWidth = ctx.measureText(text).width;

                    ctx.font = fontSize + "px Arial";
                    ctx.fillStyle = fontColor;

                    if (value === maxValue) {
                        ctx.fillText(text, x + 20 / 2, y + 15);
                    } else if (index === 0) {
                        ctx.fillText(text, x, y - 10);
                    } else if (index === data.length - 1) {
                        ctx.fillText(text, x - textWidth, y - 20);
                    } else {
                        ctx.fillText(text, x - textWidth / 2, y - 10);
                    }
                }
            });

            lable = chart.data.datasets[2].label;
            data = chart.data.datasets[2].data;
            maxValue = Math.max(...data);
            data.forEach((value, index) => {
                if (value && index === data.length - 1) {
                    const x = xScale.getPixelForValue(index);
                    const y = yScale.getPixelForValue(value);

                    let text = lable + " : " + value.toString();

                    const textWidth = ctx.measureText(text).width;

                    ctx.font = fontSize + "px Arial";
                    ctx.fillStyle = fontColor;

                    if (value === maxValue) {
                        ctx.fillText(text, x + 20 / 2, y + 15);
                    } else if (index === 0) {
                        ctx.fillText(text, x, y - 10);
                    } else if (index === data.length - 1) {
                        ctx.fillText(text, x - textWidth, y - 20);
                    } else {
                        ctx.fillText(text, x - textWidth / 2, y - 10);
                    }
                }
            });

            lable = chart.data.datasets[3].label;
            data = chart.data.datasets[3].data;
            maxValue = Math.max(...data);
            data.forEach((value, index) => {

                if (value && index === data.length - 1) {
                    const x = xScale.getPixelForValue(index);
                    const y = yScale.getPixelForValue(value);

                    let text = lable + " : " + value.toString();

                    const textWidth = ctx.measureText(text).width;

                    ctx.font = fontSize + "px Arial";
                    ctx.fillStyle = fontColor;

                    if (value === maxValue) {
                        ctx.fillText(text, x + 20 / 2, y + 15);
                    } else if (index === 0) {
                        ctx.fillText(text, x, y - 10);
                    } else if (index === data.length - 1) {
                        ctx.fillText(text, x - textWidth, y - 20);
                    } else {
                        ctx.fillText(text, x - textWidth / 2, y - 10);
                    }
                }
            });
        },
    };
    return (
        <div>
            {loading ? (
                <Loader />
            ) : chartData ? (
                <Line data={{
                    labels: chartData?.label,
                    datasets: chartData?.dataset,
                }} options={{
                    maintainAspectRatio: true,
                }} plugins={[customPluginData]} />
            ) : (
                <div>
                    <p>No Data Found</p>
                </div>
            )}
        </div>
    );
};

export default BookingPaceForecastChart;
