import React, { useEffect, useState } from "react";
import { apiCall } from "../../utils/axiosService";
import { apiResponse } from "../../utils/apiResponse";
import { API_URL } from "../../utils/apiRoutes";
import { Col, Modal, Row } from "react-bootstrap";

const ViewSnapShotDetailModal = ({ snapshotId, showModal, closeModal }) => {
  const [snapShotDetail, setSnapShotDetail] = useState(null);

  const getsnapShotDetail = async () => {
    let snapshotDetailResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.Configuration +
          API_URL.SNAPSHOT.GET_BY_PRIMARY_KEY,

        body: {
          id: parseInt(snapshotId),
        },
      },
      false
    );

    let response = apiResponse(false, snapshotDetailResponse);

    if (response?.isValidate) {
      setSnapShotDetail(response?.data?.data);
    }

    if (!response?.isValidate) {
      setSnapShotDetail(null);
    }
  };

  useEffect(() => {
    getsnapShotDetail();
  }, []);

  return (
    <>
      <Modal
        className="EmailTempConfigModalMain"
        size="lg"
        centered
        show={showModal}
        onHide={closeModal}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Snapshot Detail
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xl={12}>
              <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: snapShotDetail?.snapshotdetails,
                  }}
                ></div>
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ViewSnapShotDetailModal;
