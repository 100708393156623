import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { apiCall } from "../../../../utils/axiosService";
import { apiResponse } from "../../../../utils/apiResponse";
import { API_URL } from "../../../../utils/apiRoutes";
import { Button, Form, Table } from "react-bootstrap";
import Loader from "../../../Loader/Loader";
import { compName } from "../../../../utils/constant";
import { useParams } from "react-router-dom";
import emailIcon from "../../../../Assets/Images/Email.svg";
import NoteIcon from "../../../../Assets/Images/CRM/notes.svg";
import downloadIcon from "../../../../Assets/Images/Operation/downloadIcon.svg";
import infoIcon from "../../../../Assets/Images/Action Icons/infoIcon..svg";
import AddWidgetModal from "../../AddWidgetModal";
import WidgetDetailModal from "../../WidgetDetailModal";
import { commonService } from "../../../../utils/commonService";
import moment from "moment";

const RunningStar = ({
  setCompDataFromChild,
  rowIndex,
  colIndex,
  api,
  para,
  setting,
}) => {
  const propertyDetailFromRTK = useSelector(
    (store) => store.headerProperty.propertyDetail
  );
  const weekDayArr = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Total",
  ];
  const [strData, setStrData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const tableObj = {
    Sunday: {},
    Monday: {},
    Tuesday: {},
    Wednesday: {},
    Thursday: {},
    Friday: {},
    Saturday: {},
    Total: {},
  };
  const tableDataRef = useRef(tableObj);
  const strDataRef = useRef();
  const [clickedTr, setClickedTr] = useState({
    mainRow: "",
    subRow: "",
    day: "",
    field: "",
  });
  const [showNoteSidebar, setShowNoteSidebar] = useState(false);
  const [widgetModal, setWidgetModal] = useState(false);
  const widgetDetailRef = useRef();
  const defaultAsOfDateFromRTK = useSelector(
    (store) => store.headerProperty.defaultAsOfDate
  );
  const { snapshottitle, propertyCode, snapshotId } = useParams();
  const closeWidgetModal = () => {
    setWidgetModal(false);
  };
  const closeNoteSideBar = () => {
    setShowNoteSidebar(false);
  };

  const getStrReport = async () => {
    setLoading(true);
    let strReportResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.SnapShot +
          API_URL.STR_REPORT_REVPAK.GET_RUNNING_STR_REPORT,
        body: {
          propertyCode: para?.propertyCode,
        },
      },
      false
    );

    let response = apiResponse(false, strReportResponse);

    if (response?.isValidate) {
      weekDayArr?.map((item, index) => {
        if (item === "Total") {
          let findObj = response?.data?.data?.filter(
            (x) => x?.Weekday === null
          );
          tableDataRef.current[item] = findObj?.[0];
        } else {
          let findObj = response?.data?.data?.filter(
            (x) => x?.Weekday?.trim() === item
          );
          tableDataRef.current[item] = findObj?.[0];
        }
      });
      strDataRef.current = response?.data?.data;
      setStrData(response?.data?.data);
      setLoading(false);
    }

    if (!response?.isValidate) {
      let dummyData = [
        {
          Weekday: "Sunday",
          // id:0,
          propertyCode: para?.propertyCode,
          AsOfDate: para?.asOfDate,
          OccMyProperty: null,
          OccMyPropertyChange: null,
          OccMyPropertyLy: null,
          OccCompset: null,
          OccCompsetChange: null,
          OccCompsetLy: null,
          OccIndex: null,
          OccIndexChange: null,
          OccIndexLy: null,
          AdrMyProperty: null,
          AdrMyPropertyChange: null,
          AdrMyPropertyLy: null,
          AdrCompset: null,
          AdrCompsetChange: null,
          AdrCompsetLy: null,
          AdrIndex: null,
          AdrIndexChange: null,
          AdrIndexLy: null,
          RevparMyProperty: null,
          RevparMyPropertyChange: null,
          RevparMyPropertyLy: null,
          RevparCompset: null,
          RevparCompsetChange: null,
          RevparCompsetLy: null,
          RevparIndex: null,
          RevparIndexChange: null,
          RevparIndexLy: null,
        },
        {
          Weekday: "Monday",
          id: 0,
          OccMyProperty: null,
          OccMyPropertyChange: null,
          OccMyPropertyLy: null,
          OccCompset: null,
          OccCompsetChange: null,
          OccCompsetLy: null,
          OccIndex: null,
          OccIndexChange: null,
          OccIndexLy: null,
          AdrMyProperty: null,
          AdrMyPropertyChange: null,
          AdrMyPropertyLy: null,
          AdrCompset: null,
          AdrCompsetChange: null,
          AdrCompsetLy: null,
          AdrIndex: null,
          AdrIndexChange: null,
          AdrIndexLy: null,
          RevparMyProperty: null,
          RevparMyPropertyChange: null,
          RevparMyPropertyLy: null,
          RevparCompset: null,
          RevparCompsetChange: null,
          RevparCompsetLy: null,
          RevparIndex: null,
          RevparIndexChange: null,
          RevparIndexLy: null,
        },
        {
          Weekday: "Tuesday",
          id: 0,
          OccMyProperty: null,
          OccMyPropertyChange: null,
          OccMyPropertyLy: null,
          OccCompset: null,
          OccCompsetChange: null,
          OccCompsetLy: null,
          OccIndex: null,
          OccIndexChange: null,
          OccIndexLy: null,
          AdrMyProperty: null,
          AdrMyPropertyChange: null,
          AdrMyPropertyLy: null,
          AdrCompset: null,
          AdrCompsetChange: null,
          AdrCompsetLy: null,
          AdrIndex: null,
          AdrIndexChange: null,
          AdrIndexLy: null,
          RevparMyProperty: null,
          RevparMyPropertyChange: null,
          RevparMyPropertyLy: null,
          RevparCompset: null,
          RevparCompsetChange: null,
          RevparCompsetLy: null,
          RevparIndex: null,
          RevparIndexChange: null,
          RevparIndexLy: null,
        },
        {
          Weekday: "Wednesday",
          id: 0,
          OccMyProperty: null,
          OccMyPropertyChange: null,
          OccMyPropertyLy: null,
          OccCompset: null,
          OccCompsetChange: null,
          OccCompsetLy: null,
          OccIndex: null,
          OccIndexChange: null,
          OccIndexLy: null,
          AdrMyProperty: null,
          AdrMyPropertyChange: null,
          AdrMyPropertyLy: null,
          AdrCompset: null,
          AdrCompsetChange: null,
          AdrCompsetLy: null,
          AdrIndex: null,
          AdrIndexChange: null,
          AdrIndexLy: null,
          RevparMyProperty: null,
          RevparMyPropertyChange: null,
          RevparMyPropertyLy: null,
          RevparCompset: null,
          RevparCompsetChange: null,
          RevparCompsetLy: null,
          RevparIndex: null,
          RevparIndexChange: null,
          RevparIndexLy: null,
        },
        {
          Weekday: "Thursday",
          id: 0,
          OccMyProperty: null,
          OccMyPropertyChange: null,
          OccMyPropertyLy: null,
          OccCompset: null,
          OccCompsetChange: null,
          OccCompsetLy: null,
          OccIndex: null,
          OccIndexChange: null,
          OccIndexLy: null,
          AdrMyProperty: null,
          AdrMyPropertyChange: null,
          AdrMyPropertyLy: null,
          AdrCompset: null,
          AdrCompsetChange: null,
          AdrCompsetLy: null,
          AdrIndex: null,
          AdrIndexChange: null,
          AdrIndexLy: null,
          RevparMyProperty: null,
          RevparMyPropertyChange: null,
          RevparMyPropertyLy: null,
          RevparCompset: null,
          RevparCompsetChange: null,
          RevparCompsetLy: null,
          RevparIndex: null,
          RevparIndexChange: null,
          RevparIndexLy: null,
        },
        {
          Weekday: "Friday",
          id: 0,
          OccMyProperty: null,
          OccMyPropertyChange: null,
          OccMyPropertyLy: null,
          OccCompset: null,
          OccCompsetChange: null,
          OccCompsetLy: null,
          OccIndex: null,
          OccIndexChange: null,
          OccIndexLy: null,
          AdrMyProperty: null,
          AdrMyPropertyChange: null,
          AdrMyPropertyLy: null,
          AdrCompset: null,
          AdrCompsetChange: null,
          AdrCompsetLy: null,
          AdrIndex: null,
          AdrIndexChange: null,
          AdrIndexLy: null,
          RevparMyProperty: null,
          RevparMyPropertyChange: null,
          RevparMyPropertyLy: null,
          RevparCompset: null,
          RevparCompsetChange: null,
          RevparCompsetLy: null,
          RevparIndex: null,
          RevparIndexChange: null,
          RevparIndexLy: null,
        },
        {
          Weekday: "Saturday",
          id: 0,
          OccMyProperty: null,
          OccMyPropertyChange: null,
          OccMyPropertyLy: null,
          OccCompset: null,
          OccCompsetChange: null,
          OccCompsetLy: null,
          OccIndex: null,
          OccIndexChange: null,
          OccIndexLy: null,
          AdrMyProperty: null,
          AdrMyPropertyChange: null,
          AdrMyPropertyLy: null,
          AdrCompset: null,
          AdrCompsetChange: null,
          AdrCompsetLy: null,
          AdrIndex: null,
          AdrIndexChange: null,
          AdrIndexLy: null,
          RevparMyProperty: null,
          RevparMyPropertyChange: null,
          RevparMyPropertyLy: null,
          RevparCompset: null,
          RevparCompsetChange: null,
          RevparCompsetLy: null,
          RevparIndex: null,
          RevparIndexChange: null,
          RevparIndexLy: null,
        },
        {
          Weekday: "Total",
          id: 0,
          OccMyProperty: null,
          OccMyPropertyChange: null,
          OccMyPropertyLy: null,
          OccCompset: null,
          OccCompsetChange: null,
          OccCompsetLy: null,
          OccIndex: null,
          OccIndexChange: null,
          OccIndexLy: null,
          AdrMyProperty: null,
          AdrMyPropertyChange: null,
          AdrMyPropertyLy: null,
          AdrCompset: null,
          AdrCompsetChange: null,
          AdrCompsetLy: null,
          AdrIndex: null,
          AdrIndexChange: null,
          AdrIndexLy: null,
          RevparMyProperty: null,
          RevparMyPropertyChange: null,
          RevparMyPropertyLy: null,
          RevparCompset: null,
          RevparCompsetChange: null,
          RevparCompsetLy: null,
          RevparIndex: null,
          RevparIndexChange: null,
          RevparIndexLy: null,
        },
      ];
      weekDayArr?.map((item, index) => {
        let findObj = dummyData?.filter((x) => x?.Weekday?.trim() === item);
        tableDataRef.current[item] = findObj?.[0];
      });
      strDataRef.current = dummyData;
      setStrData(dummyData);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (api === 0) {
    } else {
      if (para?.propertyCode) {
        getStrReport();
      }
    }
  }, [para?.propertyCode]);

  const clickOutSide = (e) => {
    if (e.target.nodeName.toLowerCase() === "input") {
      return;
    }
    setClickedTr({
      mainRow: "",
      subRow: "",
      day: "",
      field: "",
    });
  };

  useEffect(() => {
    document.addEventListener("click", clickOutSide);

    return () => {
      document.removeEventListener("click", clickOutSide);
    };
  }, []);

  const [tblids, settblids] = useState({
    runningStr: commonService.GenerateGUID(),
  });

  const handleSave = async () => {
    setSubmitLoading(true);
    let newStrData = strData?.map((item, index) => {
      Object.keys(item)?.map((keyItem, keyIndex) => {
        if (item[keyItem] === "" || item[keyItem] === undefined) {
          item[keyItem] = null;
        }
      });
      return item;
    });
    let saveResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.SnapShot +
          API_URL.STR_REPORT_REVPAK.RUNNING_STR_SAVE,
        body: {
          report_data: newStrData,
          propertyCode: para?.propertyCode,
        },
      },
      false
    );

    let response = apiResponse(true, saveResponse, setSubmitLoading);

    if (response?.isValidate) {
    }

    if (!response?.isValidate) {
    }
  };

  const calculateAvg = (keyName) => {
    let total = 0;
    Object.entries(tableDataRef.current)?.map(([key, value]) => {
      if(key!=="Total"){
      total = total + parseFloat(value?.[keyName]);}
    });
    let avg = 0;
    if (total) {
      avg = total / 7;
      avg = avg?.toFixed(2);
    }

    tableDataRef.current.Total[keyName] = avg;
    strDataRef.current = strDataRef.current?.map((item, index) => {
      if (item?.Weekday === null) {
        item[keyName] = avg;
      }
      return item;
    });
    // return avg;
  };

  return (
    <div className="widget-container">
      <div className="running-report-div mb-3">
        <div></div>
        <div className="weekly-report-heading">
          <p>{`Running 28 Days (by Day of Week)`}</p>
          <p>
            WARNING: You must click the "Save" button if you make any changes
          </p>
        </div>
        {submitLoading ? (
          <button
            className="default-btn primary-btn width75"
            type="button"
            disabled
          >
            <span
              className="spinner-border spinner-border-sm "
              role="status"
              aria-hidden="true"
            ></span>
            {/* Loading... */}
          </button>
        ) : (
          <button
            className="default-btn primary-btn width75"
            onClick={() => {
              handleSave();
            }}
          >
            Save
          </button>
        )}
      </div>
      {api !== 0 && (
        <div className="gap-1 widget-container-menu" style={{ right: "70px" }}>
          {/* <Button className="heading-menu-btns" type="button">
            <img
              src={NoteIcon}
              alt="menuIcon"
              onClick={() => {
                setShowNoteSidebar(true);
              }}
            />
          </Button> */}
          <Button className="heading-menu-btns" type="button" title="Send Mail">
            <img
              src={emailIcon}
              alt="emailIcon"
              onClick={() => {
                commonService.fnSendWidgetMail(
                  tblids.runningStr,
                  "Running 28 Days",
                  snapshotId,
                  defaultAsOfDateFromRTK,
                  "",
                  para?.widgetId
                );
              }}
            />
          </Button>
          <Button className="heading-menu-btns" type="button">
            <img
              src={infoIcon}
              alt="info"
              onClick={() => {
                widgetDetailRef.current = {
                  widgetdetails: "",
                  usernote: "",
                  compName: compName?.runningStrReport,
                  widgetId: para?.widgetId,
                };
                setWidgetModal(true);
              }}
            />
          </Button>
          <Button className="heading-menu-btns" type="button">
            <img
              src={downloadIcon}
              alt="menuIcon"
              import={tblids.runningStr}
              onClick={() =>
                commonService.fnExportInCSV(
                  tblids.runningStr,
                  // "Running 28 Days"
                  `${para?.propertyCode}-Running 28 Days-${moment(
                    new Date()
                  ).format("MMDDYYYY")}`
                )
              }
            />
          </Button>
        </div>
      )}
      <div className="ViewPropertySetupTable">
        <div className="stripped__table table-responsive">
          <Table id={tblids.runningStr} responsive>
            <thead>
              <tr>
                <th className="border_right width50"></th>
                <th className="border_right width90"></th>
                {weekDayArr?.map((item, index) => {
                  if (item !== "Total") {
                    return (
                      <th
                        className="border_right text-center width50"
                        key={`${item}firstth`}
                        colSpan={3}
                      >
                        {item}
                      </th>
                    );
                  }
                })}
                <th className="width50 text-center" colSpan={3}>
                  Avg
                </th>
              </tr>
              <tr>
                <th className="border_right"></th>
                <th className="border_right"></th>
                {weekDayArr?.map((item, index) => {
                  if (item !== "Total") {
                    return (
                      <React.Fragment key={`${item}secondth`}>
                        <th className="width50"></th>
                        <th className="width50">%Chg</th>
                        <th className="border_right width50">LY</th>
                      </React.Fragment>
                    );
                  }
                })}
                <th className="width50"></th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr style={{ height: "80px", position: "relative" }}>
                  <Loader />
                </tr>
              ) : strData ? (
                <>
                  {/* OCC */}
                  <tr className="shadowOnHover">
                    <td rowSpan={3} className="th-with-rowspan border_right">
                      Occupancy
                    </td>
                    <td className="border_right">My Property (%)</td>
                    <td
                      onDoubleClick={() => {
                        setClickedTr({
                          mainRow: "occ",
                          subRow: "myProperty",
                          field: "curr",
                          day: "sunday",
                        });
                      }}
                    >
                      {clickedTr.mainRow === "occ" &&
                      clickedTr.subRow === "myProperty" &&
                      clickedTr.field === "curr" &&
                      clickedTr.day === "sunday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current.Sunday?.OccMyProperty}
                          onChange={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            let lyValue = "";
                            strDataRef.current = strDataRef.current?.map(
                              (item, index) => {
                                if (item?.Weekday === "Sunday") {
                                  item.OccMyProperty = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let changeValue = item?.OccMyPropertyChange
                                    ? parseFloat(item.OccMyPropertyChange)
                                    : 0;
                                  lyValue = (
                                    (newValue * 100) /
                                    (100 + changeValue)
                                  )?.toFixed(2);
                                  item.OccMyPropertyLy = lyValue;
                                }
                                return item;
                              }
                            );
                            calculateAvg("OccMyProperty")
                            tableDataRef.current.Sunday.OccMyProperty =
                              e.target.value;
                            tableDataRef.current.Sunday.OccMyPropertyLy =
                              lyValue;
                            setStrData(strDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current.Sunday?.OccMyProperty ? (
                        tableDataRef.current.Sunday?.OccMyProperty
                      ) : (
                        0
                      )}
                    </td>
                    <td
                      onDoubleClick={() => {
                        setClickedTr({
                          mainRow: "occ",
                          subRow: "myProperty",
                          field: "change",
                          day: "sunday",
                        });
                      }}
                    >
                      {clickedTr.mainRow === "occ" &&
                      clickedTr.subRow === "myProperty" &&
                      clickedTr.field === "change" &&
                      clickedTr.day === "sunday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={
                            tableDataRef.current.Sunday?.OccMyPropertyChange
                          }
                          onChange={(e) => {
                            let lyValue = "";
                            strDataRef.current = strDataRef.current?.map(
                              (item, index) => {
                                if (item?.Weekday === "Sunday") {
                                  item.OccMyPropertyChange = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let currValue = item?.OccMyProperty
                                    ? parseFloat(item.OccMyProperty)
                                    : 0;
                                  lyValue = (
                                    (currValue * 100) /
                                    (100 + newValue)
                                  )?.toFixed(2);
                                  item.OccMyPropertyLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current.Sunday.OccMyPropertyChange =
                              e.target.value;
                            tableDataRef.current.Sunday.OccMyPropertyLy =
                              lyValue;
                            setStrData(strDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current.Sunday?.OccMyPropertyChange ? (
                        tableDataRef.current.Sunday?.OccMyPropertyChange
                      ) : (
                        0
                      )}
                    </td>
                    <td className="border_right">
                      {tableDataRef.current.Sunday?.OccMyPropertyLy
                        ? tableDataRef.current.Sunday?.OccMyPropertyLy
                        : 0}
                    </td>

                    <td
                      onDoubleClick={() => {
                        setClickedTr({
                          mainRow: "occ",
                          subRow: "myProperty",
                          field: "curr",
                          day: "monday",
                        });
                      }}
                    >
                      {clickedTr.mainRow === "occ" &&
                      clickedTr.subRow === "myProperty" &&
                      clickedTr.field === "curr" &&
                      clickedTr.day === "monday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current.Monday?.OccMyProperty}
                          onChange={(e) => {
                            let lyValue = "";
                            strDataRef.current = strDataRef.current?.map(
                              (item, index) => {
                                if (item?.Weekday === "Monday") {
                                  item.OccMyProperty = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let changeValue = item?.OccMyPropertyChange
                                    ? parseFloat(item.OccMyPropertyChange)
                                    : 0;
                                  lyValue = (
                                    (newValue * 100) /
                                    (100 + changeValue)
                                  )?.toFixed(2);
                                  item.OccMyPropertyLy = lyValue;
                                }
                                return item;
                              }
                            );
                            calculateAvg("OccMyProperty")
                            tableDataRef.current.Monday.OccMyProperty =
                              e.target.value;
                            tableDataRef.current.Monday.OccMyPropertyLy =
                              lyValue;
                            setStrData(strDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current.Monday?.OccMyProperty ? (
                        tableDataRef.current.Monday?.OccMyProperty
                      ) : (
                        0
                      )}
                    </td>
                    <td
                      onDoubleClick={() => {
                        setClickedTr({
                          mainRow: "occ",
                          subRow: "myProperty",
                          field: "change",
                          day: "monday",
                        });
                      }}
                    >
                      {clickedTr.mainRow === "occ" &&
                      clickedTr.subRow === "myProperty" &&
                      clickedTr.field === "change" &&
                      clickedTr.day === "monday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={
                            tableDataRef.current.Monday?.OccMyPropertyChange
                          }
                          onChange={(e) => {
                            let lyValue = "";
                            strDataRef.current = strDataRef.current?.map(
                              (item, index) => {
                                if (item?.Weekday === "Monday") {
                                  item.OccMyPropertyChange = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let currValue = item?.OccMyProperty
                                    ? parseFloat(item.OccMyProperty)
                                    : 0;
                                  lyValue = (
                                    (currValue * 100) /
                                    (100 + newValue)
                                  )?.toFixed(2);
                                  item.OccMyPropertyLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current.Monday.OccMyPropertyChange =
                              e.target.value;
                            tableDataRef.current.Monday.OccMyPropertyLy =
                              lyValue;
                            setStrData(strDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current.Monday?.OccMyPropertyChange ? (
                        tableDataRef.current.Monday?.OccMyPropertyChange
                      ) : (
                        0
                      )}
                    </td>
                    <td className="border_right">
                      {tableDataRef.current.Monday?.OccMyPropertyLy
                        ? tableDataRef.current.Monday?.OccMyPropertyLy
                        : 0}
                    </td>

                    <td
                      onDoubleClick={() => {
                        setClickedTr({
                          mainRow: "occ",
                          subRow: "myProperty",
                          field: "curr",
                          day: "tuseday",
                        });
                      }}
                    >
                      {clickedTr.mainRow === "occ" &&
                      clickedTr.subRow === "myProperty" &&
                      clickedTr.field === "curr" &&
                      clickedTr.day === "tuseday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current.Tuesday?.OccMyProperty}
                          onChange={(e) => {
                            let lyValue = "";
                            strDataRef.current = strDataRef.current?.map(
                              (item, index) => {
                                if (item?.Weekday === "Tuesday") {
                                  item.OccMyProperty = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let changeValue = item?.OccMyPropertyChange
                                    ? parseFloat(item.OccMyPropertyChange)
                                    : 0;
                                  lyValue = (
                                    (newValue * 100) /
                                    (100 + changeValue)
                                  )?.toFixed(2);
                                  item.OccMyPropertyLy = lyValue;
                                }
                                return item;
                              }
                            );
                            calculateAvg("OccMyProperty")
                            tableDataRef.current.Tuesday.OccMyProperty =
                              e.target.value;
                            tableDataRef.current.Tuesday.OccMyPropertyLy =
                              lyValue;
                            setStrData(strDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current.Tuesday?.OccMyProperty ? (
                        tableDataRef.current.Tuesday?.OccMyProperty
                      ) : (
                        0
                      )}
                    </td>
                    <td
                      onDoubleClick={() => {
                        setClickedTr({
                          mainRow: "occ",
                          subRow: "myProperty",
                          field: "change",
                          day: "tuesday",
                        });
                      }}
                    >
                      {clickedTr.mainRow === "occ" &&
                      clickedTr.subRow === "myProperty" &&
                      clickedTr.field === "change" &&
                      clickedTr.day === "tuesday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={
                            tableDataRef.current.Tuesday?.OccMyPropertyChange
                          }
                          onChange={(e) => {
                            let lyValue = "";
                            strDataRef.current = strDataRef.current?.map(
                              (item, index) => {
                                if (item?.Weekday === "Tuesday") {
                                  item.OccMyPropertyChange = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let currValue = item?.OccMyProperty
                                    ? parseFloat(item.OccMyProperty)
                                    : 0;
                                  lyValue = (
                                    (currValue * 100) /
                                    (100 + newValue)
                                  )?.toFixed(2);
                                  item.OccMyPropertyLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current.Tuesday.OccMyPropertyChange =
                              e.target.value;
                            tableDataRef.current.Tuesday.OccMyPropertyLy =
                              lyValue;
                            setStrData(strDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current.Tuesday?.OccMyPropertyChange ? (
                        tableDataRef.current.Tuesday?.OccMyPropertyChange
                      ) : (
                        0
                      )}
                    </td>
                    <td className="border_right">
                      {tableDataRef.current.Tuesday?.OccMyPropertyLy
                        ? tableDataRef.current.Tuesday?.OccMyPropertyLy
                        : 0}
                    </td>

                    <td
                      onDoubleClick={() => {
                        setClickedTr({
                          mainRow: "occ",
                          subRow: "myProperty",
                          field: "curr",
                          day: "wednesday",
                        });
                      }}
                    >
                      {clickedTr.mainRow === "occ" &&
                      clickedTr.subRow === "myProperty" &&
                      clickedTr.field === "curr" &&
                      clickedTr.day === "wednesday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current.Wednesday?.OccMyProperty}
                          onChange={(e) => {
                            let lyValue = "";
                            strDataRef.current = strDataRef.current?.map(
                              (item, index) => {
                                if (item?.Weekday === "Wednesday") {
                                  item.OccMyProperty = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let changeValue = item?.OccMyPropertyChange
                                    ? parseFloat(item.OccMyPropertyChange)
                                    : 0;
                                  lyValue = (
                                    (newValue * 100) /
                                    (100 + changeValue)
                                  )?.toFixed(2);
                                  item.OccMyPropertyLy = lyValue;
                                }
                                return item;
                              }
                            );
                            calculateAvg("OccMyProperty")
                            tableDataRef.current.Wednesday.OccMyProperty =
                              e.target.value;
                            tableDataRef.current.Wednesday.OccMyPropertyLy =
                              lyValue;
                            setStrData(strDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current.Wednesday?.OccMyProperty ? (
                        tableDataRef.current.Wednesday?.OccMyProperty
                      ) : (
                        0
                      )}
                    </td>
                    <td
                      onDoubleClick={() => {
                        setClickedTr({
                          mainRow: "occ",
                          subRow: "myProperty",
                          field: "change",
                          day: "wednesday",
                        });
                      }}
                    >
                      {clickedTr.mainRow === "occ" &&
                      clickedTr.subRow === "myProperty" &&
                      clickedTr.field === "change" &&
                      clickedTr.day === "wednesday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={
                            tableDataRef.current.Wednesday?.OccMyPropertyChange
                          }
                          onChange={(e) => {
                            let lyValue = "";
                            strDataRef.current = strDataRef.current?.map(
                              (item, index) => {
                                if (item?.Weekday === "Wednesday") {
                                  item.OccMyPropertyChange = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let currValue = item?.OccMyProperty
                                    ? parseFloat(item.OccMyProperty)
                                    : 0;
                                  lyValue = (
                                    (currValue * 100) /
                                    (100 + newValue)
                                  )?.toFixed(2);
                                  item.OccMyPropertyLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current.Wednesday.OccMyPropertyChange =
                              e.target.value;
                            tableDataRef.current.Wednesday.OccMyPropertyLy =
                              lyValue;
                            setStrData(strDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current.Wednesday
                          ?.OccMyPropertyChange ? (
                        tableDataRef.current.Wednesday?.OccMyPropertyChange
                      ) : (
                        0
                      )}
                    </td>
                    <td className="border_right">
                      {tableDataRef.current.Wednesday?.OccMyPropertyLy
                        ? tableDataRef.current.Wednesday?.OccMyPropertyLy
                        : 0}
                    </td>

                    <td
                      onDoubleClick={() => {
                        setClickedTr({
                          mainRow: "occ",
                          subRow: "myProperty",
                          field: "curr",
                          day: "thursday",
                        });
                      }}
                    >
                      {clickedTr.mainRow === "occ" &&
                      clickedTr.subRow === "myProperty" &&
                      clickedTr.field === "curr" &&
                      clickedTr.day === "thursday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current.Thursday?.OccMyProperty}
                          onChange={(e) => {
                            let lyValue = "";
                            strDataRef.current = strDataRef.current?.map(
                              (item, index) => {
                                if (item?.Weekday === "Thursday") {
                                  item.OccMyProperty = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let changeValue = item?.OccMyPropertyChange
                                    ? parseFloat(item.OccMyPropertyChange)
                                    : 0;
                                  lyValue = (
                                    (newValue * 100) /
                                    (100 + changeValue)
                                  )?.toFixed(2);
                                  item.OccMyPropertyLy = lyValue;
                                }
                                return item;
                              }
                            );
                            calculateAvg("OccMyProperty")
                            tableDataRef.current.Thursday.OccMyProperty =
                              e.target.value;
                            tableDataRef.current.Thursday.OccMyPropertyLy =
                              lyValue;
                            setStrData(strDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current.Thursday?.OccMyProperty ? (
                        tableDataRef.current.Thursday?.OccMyProperty
                      ) : (
                        0
                      )}
                    </td>
                    <td
                      onDoubleClick={() => {
                        setClickedTr({
                          mainRow: "occ",
                          subRow: "myProperty",
                          field: "change",
                          day: "thursday",
                        });
                      }}
                    >
                      {clickedTr.mainRow === "occ" &&
                      clickedTr.subRow === "myProperty" &&
                      clickedTr.field === "change" &&
                      clickedTr.day === "thursday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={
                            tableDataRef.current.Thursday?.OccMyPropertyChange
                          }
                          onChange={(e) => {
                            let lyValue = "";
                            strDataRef.current = strDataRef.current?.map(
                              (item, index) => {
                                if (item?.Weekday === "Thursday") {
                                  item.OccMyPropertyChange = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let currValue = item?.OccMyProperty
                                    ? parseFloat(item.OccMyProperty)
                                    : 0;
                                  lyValue = (
                                    (currValue * 100) /
                                    (100 + newValue)
                                  )?.toFixed(2);
                                  item.OccMyPropertyLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current.Thursday.OccMyPropertyChange =
                              e.target.value;
                            tableDataRef.current.Thursday.OccMyPropertyLy =
                              lyValue;
                            setStrData(strDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current.Thursday?.OccMyPropertyChange ? (
                        tableDataRef.current.Thursday?.OccMyPropertyChange
                      ) : (
                        0
                      )}
                    </td>
                    <td className="border_right">
                      {tableDataRef.current.Thursday?.OccMyPropertyLy
                        ? tableDataRef.current.Thursday?.OccMyPropertyLy
                        : 0}
                    </td>

                    <td
                      onDoubleClick={() => {
                        setClickedTr({
                          mainRow: "occ",
                          subRow: "myProperty",
                          field: "curr",
                          day: "friday",
                        });
                      }}
                    >
                      {clickedTr.mainRow === "occ" &&
                      clickedTr.subRow === "myProperty" &&
                      clickedTr.field === "curr" &&
                      clickedTr.day === "friday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current.Friday?.OccMyProperty}
                          onChange={(e) => {
                            let lyValue = "";
                            strDataRef.current = strDataRef.current?.map(
                              (item, index) => {
                                if (item?.Weekday === "Friday") {
                                  item.OccMyProperty = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let changeValue = item?.OccMyPropertyChange
                                    ? parseFloat(item.OccMyPropertyChange)
                                    : 0;
                                  lyValue = (
                                    (newValue * 100) /
                                    (100 + changeValue)
                                  )?.toFixed(2);
                                  item.OccMyPropertyLy = lyValue;
                                }
                                return item;
                              }
                            );
                            calculateAvg("OccMyProperty")
                            tableDataRef.current.Friday.OccMyProperty =
                              e.target.value;
                            tableDataRef.current.Friday.OccMyPropertyLy =
                              lyValue;
                            setStrData(strDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current.Friday?.OccMyProperty ? (
                        tableDataRef.current.Friday?.OccMyProperty
                      ) : (
                        0
                      )}
                    </td>
                    <td
                      onDoubleClick={() => {
                        setClickedTr({
                          mainRow: "occ",
                          subRow: "myProperty",
                          field: "change",
                          day: "friday",
                        });
                      }}
                    >
                      {clickedTr.mainRow === "occ" &&
                      clickedTr.subRow === "myProperty" &&
                      clickedTr.field === "change" &&
                      clickedTr.day === "friday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={
                            tableDataRef.current.Friday?.OccMyPropertyChange
                          }
                          onChange={(e) => {
                            let lyValue = "";
                            strDataRef.current = strDataRef.current?.map(
                              (item, index) => {
                                if (item?.Weekday === "Friday") {
                                  item.OccMyPropertyChange = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let currValue = item?.OccMyProperty
                                    ? parseFloat(item.OccMyProperty)
                                    : 0;
                                  lyValue = (
                                    (currValue * 100) /
                                    (100 + newValue)
                                  )?.toFixed(2);
                                  item.OccMyPropertyLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current.Friday.OccMyPropertyChange =
                              e.target.value;
                            tableDataRef.current.Friday.OccMyPropertyLy =
                              lyValue;
                            setStrData(strDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current.Friday?.OccMyPropertyChange ? (
                        tableDataRef.current.Friday?.OccMyPropertyChange
                      ) : (
                        0
                      )}
                    </td>
                    <td className="border_right">
                      {tableDataRef.current.Friday?.OccMyPropertyLy
                        ? tableDataRef.current.Friday?.OccMyPropertyLy
                        : 0}
                    </td>

                    <td
                      onDoubleClick={() => {
                        setClickedTr({
                          mainRow: "occ",
                          subRow: "myProperty",
                          field: "curr",
                          day: "saturday",
                        });
                      }}
                    >
                      {clickedTr.mainRow === "occ" &&
                      clickedTr.subRow === "myProperty" &&
                      clickedTr.field === "curr" &&
                      clickedTr.day === "saturday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current.Saturday?.OccMyProperty}
                          onChange={(e) => {
                            let lyValue = "";
                            strDataRef.current = strDataRef.current?.map(
                              (item, index) => {
                                if (item?.Weekday === "Saturday") {
                                  item.OccMyProperty = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let changeValue = item?.OccMyPropertyChange
                                    ? parseFloat(item.OccMyPropertyChange)
                                    : 0;
                                  lyValue = (
                                    (newValue * 100) /
                                    (100 + changeValue)
                                  )?.toFixed(2);
                                  item.OccMyPropertyLy = lyValue;
                                }
                                return item;
                              }
                            );
                            calculateAvg("OccMyProperty")
                            tableDataRef.current.Saturday.OccMyProperty =
                              e.target.value;
                            tableDataRef.current.Saturday.OccMyPropertyLy =
                              lyValue;
                            setStrData(strDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current.Saturday?.OccMyProperty ? (
                        tableDataRef.current.Saturday?.OccMyProperty
                      ) : (
                        0
                      )}
                    </td>
                    <td
                      onDoubleClick={() => {
                        setClickedTr({
                          mainRow: "occ",
                          subRow: "myProperty",
                          field: "change",
                          day: "saturday",
                        });
                      }}
                    >
                      {clickedTr.mainRow === "occ" &&
                      clickedTr.subRow === "myProperty" &&
                      clickedTr.field === "change" &&
                      clickedTr.day === "saturday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={
                            tableDataRef.current.Saturday?.OccMyPropertyChange
                          }
                          onChange={(e) => {
                            let lyValue = "";
                            strDataRef.current = strDataRef.current?.map(
                              (item, index) => {
                                if (item?.Weekday === "Saturday") {
                                  item.OccMyPropertyChange = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let currValue = item?.OccMyProperty
                                    ? parseFloat(item.OccMyProperty)
                                    : 0;
                                  lyValue = (
                                    (currValue * 100) /
                                    (100 + newValue)
                                  )?.toFixed(2);
                                  item.OccMyPropertyLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current.Saturday.OccMyPropertyChange =
                              e.target.value;
                            tableDataRef.current.Saturday.OccMyPropertyLy =
                              lyValue;
                            setStrData(strDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current.Saturday?.OccMyPropertyChange ? (
                        tableDataRef.current.Saturday?.OccMyPropertyChange
                      ) : (
                        0
                      )}
                    </td>
                    <td className="border_right">
                      {tableDataRef.current.Saturday?.OccMyPropertyLy
                        ? tableDataRef.current.Saturday?.OccMyPropertyLy
                        : 0}
                    </td>
                    <td className="fontBold">
                      {/* {calculateAvg("OccMyProperty")} */}
                      {tableDataRef.current.Total?.OccMyProperty
                        ? parseFloat(
                            tableDataRef.current.Total?.OccMyProperty
                          )?.toFixed(2)
                        : 0}
                    </td>
                  </tr>
                  <tr className="shadowOnHover">
                    <td className="border_right">Comp Set (%)</td>
                    <td
                      onDoubleClick={() => {
                        setClickedTr({
                          mainRow: "occ",
                          subRow: "compSet",
                          field: "curr",
                          day: "sunday",
                        });
                      }}
                    >
                      {clickedTr.mainRow === "occ" &&
                      clickedTr.subRow === "compSet" &&
                      clickedTr.field === "curr" &&
                      clickedTr.day === "sunday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current.Sunday?.OccCompset}
                          onChange={(e) => {
                            let lyValue = "";
                            strDataRef.current = strDataRef.current?.map(
                              (item, index) => {
                                if (item?.Weekday === "Sunday") {
                                  item.OccCompset = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let changeValue = item?.OccCompsetChange
                                    ? parseFloat(item.OccCompsetChange)
                                    : 0;
                                  lyValue = (
                                    (newValue * 100) /
                                    (100 + changeValue)
                                  )?.toFixed(2);
                                  item.OccCompsetLy = lyValue;
                                }
                                return item;
                              }
                            );
                            calculateAvg("OccCompset")
                            tableDataRef.current.Sunday.OccCompset =
                              e.target.value;
                            tableDataRef.current.Sunday.OccCompsetLy = lyValue;
                            setStrData(strDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current.Sunday?.OccCompset ? (
                        tableDataRef.current.Sunday?.OccCompset
                      ) : (
                        0
                      )}
                    </td>
                    <td
                      onDoubleClick={() => {
                        setClickedTr({
                          mainRow: "occ",
                          subRow: "compSet",
                          field: "change",
                          day: "sunday",
                        });
                      }}
                    >
                      {clickedTr.mainRow === "occ" &&
                      clickedTr.subRow === "compSet" &&
                      clickedTr.field === "change" &&
                      clickedTr.day === "sunday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current.Sunday?.OccCompsetChange}
                          onChange={(e) => {
                            let lyValue = "";
                            strDataRef.current = strDataRef.current?.map(
                              (item, index) => {
                                if (item?.Weekday === "Sunday") {
                                  item.OccCompsetChange = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let currValue = item?.OccCompset
                                    ? parseFloat(item.OccCompset)
                                    : 0;
                                  lyValue = (
                                    (currValue * 100) /
                                    (100 + newValue)
                                  )?.toFixed(2);
                                  item.OccCompsetLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current.Sunday.OccCompsetChange =
                              e.target.value;
                            tableDataRef.current.Sunday.OccCompsetLy = lyValue;
                            setStrData(strDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current.Sunday?.OccCompsetChange ? (
                        tableDataRef.current.Sunday?.OccCompsetChange
                      ) : (
                        0
                      )}
                    </td>
                    <td className="border_right">
                      {tableDataRef.current.Sunday?.OccCompsetLy
                        ? tableDataRef.current.Sunday?.OccCompsetLy
                        : 0}
                    </td>

                    <td
                      onDoubleClick={() => {
                        setClickedTr({
                          mainRow: "occ",
                          subRow: "compSet",
                          field: "curr",
                          day: "monday",
                        });
                      }}
                    >
                      {clickedTr.mainRow === "occ" &&
                      clickedTr.subRow === "compSet" &&
                      clickedTr.field === "curr" &&
                      clickedTr.day === "monday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current.Monday?.OccCompset}
                          onChange={(e) => {
                            let lyValue = "";
                            strDataRef.current = strDataRef.current?.map(
                              (item, index) => {
                                if (item?.Weekday === "Monday") {
                                  item.OccCompset = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let changeValue = item?.OccCompsetChange
                                    ? parseFloat(item.OccCompsetChange)
                                    : 0;
                                  lyValue = (
                                    (newValue * 100) /
                                    (100 + changeValue)
                                  )?.toFixed(2);
                                  item.OccCompsetLy = lyValue;
                                }
                                return item;
                              }
                            );
                            calculateAvg("OccCompset")
                            tableDataRef.current.Monday.OccCompset =
                              e.target.value;
                            tableDataRef.current.Monday.OccCompsetLy = lyValue;
                            setStrData(strDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current.Monday?.OccCompset ? (
                        tableDataRef.current.Monday?.OccCompset
                      ) : (
                        0
                      )}
                    </td>
                    <td
                      onDoubleClick={() => {
                        setClickedTr({
                          mainRow: "occ",
                          subRow: "compSet",
                          field: "change",
                          day: "monday",
                        });
                      }}
                    >
                      {clickedTr.mainRow === "occ" &&
                      clickedTr.subRow === "compSet" &&
                      clickedTr.field === "change" &&
                      clickedTr.day === "monday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current.Monday?.OccCompsetChange}
                          onChange={(e) => {
                            let lyValue = "";
                            strDataRef.current = strDataRef.current?.map(
                              (item, index) => {
                                if (item?.Weekday === "Monday") {
                                  item.OccCompsetChange = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let currValue = item?.OccCompset
                                    ? parseFloat(item.OccCompset)
                                    : 0;
                                  lyValue = (
                                    (currValue * 100) /
                                    (100 + newValue)
                                  )?.toFixed(2);
                                  item.OccCompsetLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current.Monday.OccCompsetChange =
                              e.target.value;
                            tableDataRef.current.Monday.OccCompsetLy = lyValue;
                            setStrData(strDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current.Monday?.OccCompsetChange ? (
                        tableDataRef.current.Monday?.OccCompsetChange
                      ) : (
                        0
                      )}
                    </td>
                    <td className="border_right">
                      {tableDataRef.current.Monday?.OccCompsetLy
                        ? tableDataRef.current.Monday?.OccCompsetLy
                        : 0}
                    </td>

                    <td
                      onDoubleClick={() => {
                        setClickedTr({
                          mainRow: "occ",
                          subRow: "compSet",
                          field: "curr",
                          day: "tuesday",
                        });
                      }}
                    >
                      {clickedTr.mainRow === "occ" &&
                      clickedTr.subRow === "compSet" &&
                      clickedTr.field === "curr" &&
                      clickedTr.day === "tuesday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current.Tuesday?.OccCompset}
                          onChange={(e) => {
                            let lyValue = "";
                            strDataRef.current = strDataRef.current?.map(
                              (item, index) => {
                                if (item?.Weekday === "Tuesday") {
                                  item.OccCompset = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let changeValue = item?.OccCompsetChange
                                    ? parseFloat(item.OccCompsetChange)
                                    : 0;
                                  lyValue = (
                                    (newValue * 100) /
                                    (100 + changeValue)
                                  )?.toFixed(2);
                                  item.OccCompsetLy = lyValue;
                                }
                                return item;
                              }
                            );
                            calculateAvg("OccCompset")
                            tableDataRef.current.Tuesday.OccCompset =
                              e.target.value;
                            tableDataRef.current.Tuesday.OccCompsetLy = lyValue;
                            setStrData(strDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current.Tuesday?.OccCompset ? (
                        tableDataRef.current.Tuesday?.OccCompset
                      ) : (
                        0
                      )}
                    </td>
                    <td
                      onDoubleClick={() => {
                        setClickedTr({
                          mainRow: "occ",
                          subRow: "compSet",
                          field: "change",
                          day: "tuesday",
                        });
                      }}
                    >
                      {clickedTr.mainRow === "occ" &&
                      clickedTr.subRow === "compSet" &&
                      clickedTr.field === "change" &&
                      clickedTr.day === "tuesday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current.Tuesday?.OccCompsetChange}
                          onChange={(e) => {
                            let lyValue = "";
                            strDataRef.current = strDataRef.current?.map(
                              (item, index) => {
                                if (item?.Weekday === "Tuesday") {
                                  item.OccCompsetChange = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let currValue = item?.OccCompset
                                    ? parseFloat(item.OccCompset)
                                    : 0;
                                  lyValue = (
                                    (currValue * 100) /
                                    (100 + newValue)
                                  )?.toFixed(2);
                                  item.OccCompsetLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current.Tuesday.OccCompsetChange =
                              e.target.value;
                            tableDataRef.current.Tuesday.OccCompsetLy = lyValue;
                            setStrData(strDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current.Tuesday?.OccCompsetChange ? (
                        tableDataRef.current.Tuesday?.OccCompsetChange
                      ) : (
                        0
                      )}
                    </td>
                    <td className="border_right">
                      {tableDataRef.current.Tuesday?.OccCompsetLy
                        ? tableDataRef.current.Tuesday?.OccCompsetLy
                        : 0}
                    </td>

                    <td
                      onDoubleClick={() => {
                        setClickedTr({
                          mainRow: "occ",
                          subRow: "compSet",
                          field: "curr",
                          day: "wednesday",
                        });
                      }}
                    >
                      {clickedTr.mainRow === "occ" &&
                      clickedTr.subRow === "compSet" &&
                      clickedTr.field === "curr" &&
                      clickedTr.day === "wednesday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current.Wednesday?.OccCompset}
                          onChange={(e) => {
                            let lyValue = "";
                            strDataRef.current = strDataRef.current?.map(
                              (item, index) => {
                                if (item?.Weekday === "Wednesday") {
                                  item.OccCompset = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let changeValue = item?.OccCompsetChange
                                    ? parseFloat(item.OccCompsetChange)
                                    : 0;
                                  lyValue = (
                                    (newValue * 100) /
                                    (100 + changeValue)
                                  )?.toFixed(2);
                                  item.OccCompsetLy = lyValue;
                                }
                                return item;
                              }
                            );
                            calculateAvg("OccCompset")
                            tableDataRef.current.Wednesday.OccCompset =
                              e.target.value;
                            tableDataRef.current.Wednesday.OccCompsetLy =
                              lyValue;
                            setStrData(strDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current.Wednesday?.OccCompset ? (
                        tableDataRef.current.Wednesday?.OccCompset
                      ) : (
                        0
                      )}
                    </td>
                    <td
                      onDoubleClick={() => {
                        setClickedTr({
                          mainRow: "occ",
                          subRow: "compSet",
                          field: "change",
                          day: "wednesday",
                        });
                      }}
                    >
                      {clickedTr.mainRow === "occ" &&
                      clickedTr.subRow === "compSet" &&
                      clickedTr.field === "change" &&
                      clickedTr.day === "wednesday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={
                            tableDataRef.current.Wednesday?.OccCompsetChange
                          }
                          onChange={(e) => {
                            let lyValue = "";
                            strDataRef.current = strDataRef.current?.map(
                              (item, index) => {
                                if (item?.Weekday === "Wednesday") {
                                  item.OccCompsetChange = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let currValue = item?.OccCompset
                                    ? parseFloat(item.OccCompset)
                                    : 0;
                                  lyValue = (
                                    (currValue * 100) /
                                    (100 + newValue)
                                  )?.toFixed(2);
                                  item.OccCompsetLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current.Wednesday.OccCompsetChange =
                              e.target.value;
                            tableDataRef.current.Wednesday.OccCompsetLy =
                              lyValue;
                            setStrData(strDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current.Wednesday?.OccCompsetChange ? (
                        tableDataRef.current.Wednesday?.OccCompsetChange
                      ) : (
                        0
                      )}
                    </td>
                    <td className="border_right">
                      {tableDataRef.current.Wednesday?.OccCompsetLy
                        ? tableDataRef.current.Wednesday?.OccCompsetLy
                        : 0}
                    </td>

                    <td
                      onDoubleClick={() => {
                        setClickedTr({
                          mainRow: "occ",
                          subRow: "compSet",
                          field: "curr",
                          day: "thursday",
                        });
                      }}
                    >
                      {clickedTr.mainRow === "occ" &&
                      clickedTr.subRow === "compSet" &&
                      clickedTr.field === "curr" &&
                      clickedTr.day === "thursday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current.Thursday?.OccCompset}
                          onChange={(e) => {
                            let lyValue = "";
                            strDataRef.current = strDataRef.current?.map(
                              (item, index) => {
                                if (item?.Weekday === "Thursday") {
                                  item.OccCompset = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let changeValue = item?.OccCompsetChange
                                    ? parseFloat(item.OccCompsetChange)
                                    : 0;
                                  lyValue = (
                                    (newValue * 100) /
                                    (100 + changeValue)
                                  )?.toFixed(2);
                                  item.OccCompsetLy = lyValue;
                                }
                                return item;
                              }
                            );
                            calculateAvg("OccCompset")
                            tableDataRef.current.Thursday.OccCompset =
                              e.target.value;
                            tableDataRef.current.Thursday.OccCompsetLy =
                              lyValue;
                            setStrData(strDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current.Thursday?.OccCompset ? (
                        tableDataRef.current.Thursday?.OccCompset
                      ) : (
                        0
                      )}
                    </td>
                    <td
                      onDoubleClick={() => {
                        setClickedTr({
                          mainRow: "occ",
                          subRow: "compSet",
                          field: "change",
                          day: "thursday",
                        });
                      }}
                    >
                      {clickedTr.mainRow === "occ" &&
                      clickedTr.subRow === "compSet" &&
                      clickedTr.field === "change" &&
                      clickedTr.day === "thursday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={
                            tableDataRef.current.Thursday?.OccCompsetChange
                          }
                          onChange={(e) => {
                            let lyValue = "";
                            strDataRef.current = strDataRef.current?.map(
                              (item, index) => {
                                if (item?.Weekday === "Thursday") {
                                  item.OccCompsetChange = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let currValue = item?.OccCompset
                                    ? parseFloat(item.OccCompset)
                                    : 0;
                                  lyValue = (
                                    (currValue * 100) /
                                    (100 + newValue)
                                  )?.toFixed(2);
                                  item.OccCompsetLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current.Thursday.OccCompsetChange =
                              e.target.value;
                            tableDataRef.current.Thursday.OccCompsetLy =
                              lyValue;
                            setStrData(strDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current.Thursday?.OccCompsetChange ? (
                        tableDataRef.current.Thursday?.OccCompsetChange
                      ) : (
                        0
                      )}
                    </td>
                    <td className="border_right">
                      {tableDataRef.current.Thursday?.OccCompsetLy
                        ? tableDataRef.current.Thursday?.OccCompsetLy
                        : 0}
                    </td>

                    <td
                      onDoubleClick={() => {
                        setClickedTr({
                          mainRow: "occ",
                          subRow: "compSet",
                          field: "curr",
                          day: "friday",
                        });
                      }}
                    >
                      {clickedTr.mainRow === "occ" &&
                      clickedTr.subRow === "compSet" &&
                      clickedTr.field === "curr" &&
                      clickedTr.day === "friday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current.Friday?.OccCompset}
                          onChange={(e) => {
                            let lyValue = "";
                            strDataRef.current = strDataRef.current?.map(
                              (item, index) => {
                                if (item?.Weekday === "Friday") {
                                  item.OccCompset = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let changeValue = item?.OccCompsetChange
                                    ? parseFloat(item.OccCompsetChange)
                                    : 0;
                                  lyValue = (
                                    (newValue * 100) /
                                    (100 + changeValue)
                                  )?.toFixed(2);
                                  item.OccCompsetLy = lyValue;
                                }
                                return item;
                              }
                            );
                            calculateAvg("OccCompset")
                            tableDataRef.current.Friday.OccCompset =
                              e.target.value;
                            tableDataRef.current.Friday.OccCompsetLy = lyValue;
                            setStrData(strDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current.Friday?.OccCompset ? (
                        tableDataRef.current.Friday?.OccCompset
                      ) : (
                        0
                      )}
                    </td>
                    <td
                      onDoubleClick={() => {
                        setClickedTr({
                          mainRow: "occ",
                          subRow: "compSet",
                          field: "change",
                          day: "friday",
                        });
                      }}
                    >
                      {clickedTr.mainRow === "occ" &&
                      clickedTr.subRow === "compSet" &&
                      clickedTr.field === "change" &&
                      clickedTr.day === "friday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current.Friday?.OccCompsetChange}
                          onChange={(e) => {
                            let lyValue = "";
                            strDataRef.current = strDataRef.current?.map(
                              (item, index) => {
                                if (item?.Weekday === "Friday") {
                                  item.OccCompsetChange = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let currValue = item?.OccCompset
                                    ? parseFloat(item.OccCompset)
                                    : 0;
                                  lyValue = (
                                    (currValue * 100) /
                                    (100 + newValue)
                                  )?.toFixed(2);
                                  item.OccCompsetLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current.Friday.OccCompsetChange =
                              e.target.value;
                            tableDataRef.current.Friday.OccCompsetLy = lyValue;
                            setStrData(strDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current.Friday?.OccCompsetChange ? (
                        tableDataRef.current.Friday?.OccCompsetChange
                      ) : (
                        0
                      )}
                    </td>
                    <td className="border_right">
                      {tableDataRef.current.Friday?.OccCompsetLy
                        ? tableDataRef.current.Friday?.OccCompsetLy
                        : 0}
                    </td>

                    <td
                      onDoubleClick={() => {
                        setClickedTr({
                          mainRow: "occ",
                          subRow: "compSet",
                          field: "curr",
                          day: "saturday",
                        });
                      }}
                    >
                      {clickedTr.mainRow === "occ" &&
                      clickedTr.subRow === "compSet" &&
                      clickedTr.field === "curr" &&
                      clickedTr.day === "saturday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current.Saturday?.OccCompset}
                          onChange={(e) => {
                            let lyValue = "";
                            strDataRef.current = strDataRef.current?.map(
                              (item, index) => {
                                if (item?.Weekday === "Saturday") {
                                  item.OccCompset = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let changeValue = item?.OccCompsetChange
                                    ? parseFloat(item.OccCompsetChange)
                                    : 0;
                                  lyValue = (
                                    (newValue * 100) /
                                    (100 + changeValue)
                                  )?.toFixed(2);
                                  item.OccCompsetLy = lyValue;
                                }
                                return item;
                              }
                            );
                            calculateAvg("OccCompset")
                            tableDataRef.current.Saturday.OccCompset =
                              e.target.value;
                            tableDataRef.current.Saturday.OccCompsetLy =
                              lyValue;
                            setStrData(strDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current.Saturday?.OccCompset ? (
                        tableDataRef.current.Saturday?.OccCompset
                      ) : (
                        0
                      )}
                    </td>
                    <td
                      onDoubleClick={() => {
                        setClickedTr({
                          mainRow: "occ",
                          subRow: "compSet",
                          field: "change",
                          day: "saturday",
                        });
                      }}
                    >
                      {clickedTr.mainRow === "occ" &&
                      clickedTr.subRow === "compSet" &&
                      clickedTr.field === "change" &&
                      clickedTr.day === "saturday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={
                            tableDataRef.current.Saturday?.OccCompsetChange
                          }
                          onChange={(e) => {
                            let lyValue = "";
                            strDataRef.current = strDataRef.current?.map(
                              (item, index) => {
                                if (item?.Weekday === "Saturday") {
                                  item.OccCompsetChange = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let currValue = item?.OccCompset
                                    ? parseFloat(item.OccCompset)
                                    : 0;
                                  lyValue = (
                                    (currValue * 100) /
                                    (100 + newValue)
                                  )?.toFixed(2);
                                  item.OccCompsetLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current.Saturday.OccCompsetChange =
                              e.target.value;
                            tableDataRef.current.Saturday.OccCompsetLy =
                              lyValue;
                            setStrData(strDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current.Saturday?.OccCompsetChange ? (
                        tableDataRef.current.Saturday?.OccCompsetChange
                      ) : (
                        0
                      )}
                    </td>
                    <td className="border_right">
                      {tableDataRef.current.Saturday?.OccCompsetLy
                        ? tableDataRef.current.Saturday?.OccCompsetLy
                        : 0}
                    </td>
                    {/* <td className="fontBold">{calculateAvg("OccCompset")}</td> */}
                    <td className="fontBold">{
                      tableDataRef.current.Total?.OccCompset
                      ? parseFloat(tableDataRef.current.Total?.OccCompset)?.toFixed(2)
                      : 0}</td>
                  </tr>
                  <tr className="shadowOnHover">
                    <td className="border_right">Index (MPI)</td>
                    <td
                      onDoubleClick={() => {
                        setClickedTr({
                          mainRow: "occ",
                          subRow: "index",
                          field: "curr",
                          day: "sunday",
                        });
                      }}
                    >
                      {clickedTr.mainRow === "occ" &&
                      clickedTr.subRow === "index" &&
                      clickedTr.field === "curr" &&
                      clickedTr.day === "sunday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current.Sunday?.OccIndex}
                          onChange={(e) => {
                            let lyValue = "";
                            strDataRef.current = strDataRef.current?.map(
                              (item, index) => {
                                if (item?.Weekday === "Sunday") {
                                  item.OccIndex = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let changeValue = item?.OccIndexChange
                                    ? parseFloat(item.OccIndexChange)
                                    : 0;
                                  lyValue = (
                                    (newValue * 100) /
                                    (100 + changeValue)
                                  )?.toFixed(2);
                                  item.OccIndexLy = lyValue;
                                }
                                return item;
                              }
                            );
                            calculateAvg("OccIndex")
                            tableDataRef.current.Sunday.OccIndex =
                              e.target.value;
                            tableDataRef.current.Sunday.OccIndexLy = lyValue;
                            setStrData(strDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current.Sunday?.OccIndex ? (
                        tableDataRef.current.Sunday?.OccIndex
                      ) : (
                        0
                      )}
                    </td>
                    <td
                      onDoubleClick={() => {
                        setClickedTr({
                          mainRow: "occ",
                          subRow: "index",
                          field: "change",
                          day: "sunday",
                        });
                      }}
                    >
                      {clickedTr.mainRow === "occ" &&
                      clickedTr.subRow === "index" &&
                      clickedTr.field === "change" &&
                      clickedTr.day === "sunday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current.Sunday?.OccIndexChange}
                          onChange={(e) => {
                            let lyValue = "";
                            strDataRef.current = strDataRef.current?.map(
                              (item, index) => {
                                if (item?.Weekday === "Sunday") {
                                  item.OccIndexChange = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let currValue = item?.OccIndex
                                    ? parseFloat(item.OccIndex)
                                    : 0;
                                  lyValue = (
                                    (currValue * 100) /
                                    (100 + newValue)
                                  )?.toFixed(2);
                                  item.OccIndexLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current.Sunday.OccIndexChange =
                              e.target.value;
                            tableDataRef.current.Sunday.OccIndexLy = lyValue;
                            setStrData(strDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current.Sunday?.OccIndexChange ? (
                        tableDataRef.current.Sunday?.OccIndexChange
                      ) : (
                        0
                      )}
                    </td>
                    <td className="border_right">
                      {tableDataRef.current.Sunday?.OccIndexLy
                        ? tableDataRef.current.Sunday?.OccIndexLy
                        : 0}
                    </td>

                    <td
                      onDoubleClick={() => {
                        setClickedTr({
                          mainRow: "occ",
                          subRow: "index",
                          field: "curr",
                          day: "monday",
                        });
                      }}
                    >
                      {clickedTr.mainRow === "occ" &&
                      clickedTr.subRow === "index" &&
                      clickedTr.field === "curr" &&
                      clickedTr.day === "monday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current.Monday?.OccIndex}
                          onChange={(e) => {
                            let lyValue = "";
                            strDataRef.current = strDataRef.current?.map(
                              (item, index) => {
                                if (item?.Weekday === "Monday") {
                                  item.OccIndex = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let changeValue = item?.OccIndexChange
                                    ? parseFloat(item.OccIndexChange)
                                    : 0;
                                  lyValue = (
                                    (newValue * 100) /
                                    (100 + changeValue)
                                  )?.toFixed(2);
                                  item.OccIndexLy = lyValue;
                                }
                                return item;
                              }
                            );
                            calculateAvg("OccIndex")
                            tableDataRef.current.Monday.OccIndex =
                              e.target.value;
                            tableDataRef.current.Monday.OccIndexLy = lyValue;
                            setStrData(strDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current.Monday?.OccIndex ? (
                        tableDataRef.current.Monday?.OccIndex
                      ) : (
                        0
                      )}
                    </td>
                    <td
                      onDoubleClick={() => {
                        setClickedTr({
                          mainRow: "occ",
                          subRow: "index",
                          field: "change",
                          day: "monday",
                        });
                      }}
                    >
                      {clickedTr.mainRow === "occ" &&
                      clickedTr.subRow === "index" &&
                      clickedTr.field === "change" &&
                      clickedTr.day === "monday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current.Monday?.OccIndexChange}
                          onChange={(e) => {
                            let lyValue = "";
                            strDataRef.current = strDataRef.current?.map(
                              (item, index) => {
                                if (item?.Weekday === "Monday") {
                                  item.OccIndexChange = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let currValue = item?.OccIndex
                                    ? parseFloat(item.OccIndex)
                                    : 0;
                                  lyValue = (
                                    (currValue * 100) /
                                    (100 + newValue)
                                  )?.toFixed(2);
                                  item.OccIndexLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current.Monday.OccIndexChange =
                              e.target.value;
                            tableDataRef.current.Monday.OccIndexLy = lyValue;
                            setStrData(strDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current.Monday?.OccIndexChange ? (
                        tableDataRef.current.Monday?.OccIndexChange
                      ) : (
                        0
                      )}
                    </td>
                    <td className="border_right">
                      {tableDataRef.current.Monday?.OccIndexLy
                        ? tableDataRef.current.Monday?.OccIndexLy
                        : 0}
                    </td>

                    <td
                      onDoubleClick={() => {
                        setClickedTr({
                          mainRow: "occ",
                          subRow: "index",
                          field: "curr",
                          day: "tuesday",
                        });
                      }}
                    >
                      {clickedTr.mainRow === "occ" &&
                      clickedTr.subRow === "index" &&
                      clickedTr.field === "curr" &&
                      clickedTr.day === "tuesday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current.Tuesday?.OccIndex}
                          onChange={(e) => {
                            let lyValue = "";
                            strDataRef.current = strDataRef.current?.map(
                              (item, index) => {
                                if (item?.Weekday === "Tuesday") {
                                  item.OccIndex = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let changeValue = item?.OccIndexChange
                                    ? parseFloat(item.OccIndexChange)
                                    : 0;
                                  lyValue = (
                                    (newValue * 100) /
                                    (100 + changeValue)
                                  )?.toFixed(2);
                                  item.OccIndexLy = lyValue;
                                }
                                return item;
                              }
                            );
                            calculateAvg("OccIndex")
                            tableDataRef.current.Tuesday.OccIndex =
                              e.target.value;
                            tableDataRef.current.Tuesday.OccIndexLy = lyValue;
                            setStrData(strDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current.Tuesday?.OccIndex ? (
                        tableDataRef.current.Tuesday?.OccIndex
                      ) : (
                        0
                      )}
                    </td>
                    <td
                      onDoubleClick={() => {
                        setClickedTr({
                          mainRow: "occ",
                          subRow: "index",
                          field: "change",
                          day: "tuesday",
                        });
                      }}
                    >
                      {clickedTr.mainRow === "occ" &&
                      clickedTr.subRow === "index" &&
                      clickedTr.field === "change" &&
                      clickedTr.day === "tuesday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current.Tuesday?.OccIndexChange}
                          onChange={(e) => {
                            let lyValue = "";
                            strDataRef.current = strDataRef.current?.map(
                              (item, index) => {
                                if (item?.Weekday === "Tuesday") {
                                  item.OccIndexChange = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let currValue = item?.OccIndex
                                    ? parseFloat(item.OccIndex)
                                    : 0;
                                  lyValue = (
                                    (currValue * 100) /
                                    (100 + newValue)
                                  )?.toFixed(2);
                                  item.OccIndexLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current.Tuesday.OccIndexChange =
                              e.target.value;
                            tableDataRef.current.Tuesday.OccIndexLy = lyValue;
                            setStrData(strDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current.Tuesday?.OccIndexChange ? (
                        tableDataRef.current.Tuesday?.OccIndexChange
                      ) : (
                        0
                      )}
                    </td>
                    <td className="border_right">
                      {tableDataRef.current.Tuesday?.OccIndexLy
                        ? tableDataRef.current.Tuesday?.OccIndexLy
                        : 0}
                    </td>

                    <td
                      onDoubleClick={() => {
                        setClickedTr({
                          mainRow: "occ",
                          subRow: "index",
                          field: "curr",
                          day: "wednesday",
                        });
                      }}
                    >
                      {clickedTr.mainRow === "occ" &&
                      clickedTr.subRow === "index" &&
                      clickedTr.field === "curr" &&
                      clickedTr.day === "wednesday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current.Wednesday?.OccIndex}
                          onChange={(e) => {
                            let lyValue = "";
                            strDataRef.current = strDataRef.current?.map(
                              (item, index) => {
                                if (item?.Weekday === "Wednesday") {
                                  item.OccIndex = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let changeValue = item?.OccIndexChange
                                    ? parseFloat(item.OccIndexChange)
                                    : 0;
                                  lyValue = (
                                    (newValue * 100) /
                                    (100 + changeValue)
                                  )?.toFixed(2);
                                  item.OccIndexLy = lyValue;
                                }
                                return item;
                              }
                            );
                            calculateAvg("OccIndex")
                            tableDataRef.current.Wednesday.OccIndex =
                              e.target.value;
                            tableDataRef.current.Wednesday.OccIndexLy = lyValue;
                            setStrData(strDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current.Wednesday?.OccIndex ? (
                        tableDataRef.current.Wednesday?.OccIndex
                      ) : (
                        0
                      )}
                    </td>
                    <td
                      onDoubleClick={() => {
                        setClickedTr({
                          mainRow: "occ",
                          subRow: "index",
                          field: "change",
                          day: "wednesday",
                        });
                      }}
                    >
                      {clickedTr.mainRow === "occ" &&
                      clickedTr.subRow === "index" &&
                      clickedTr.field === "change" &&
                      clickedTr.day === "wednesday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current.Wednesday?.OccIndexChange}
                          onChange={(e) => {
                            let lyValue = "";
                            strDataRef.current = strDataRef.current?.map(
                              (item, index) => {
                                if (item?.Weekday === "Wednesday") {
                                  item.OccIndexChange = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let currValue = item?.OccIndex
                                    ? parseFloat(item.OccIndex)
                                    : 0;
                                  lyValue = (
                                    (currValue * 100) /
                                    (100 + newValue)
                                  )?.toFixed(2);
                                  item.OccIndexLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current.Wednesday.OccIndexChange =
                              e.target.value;
                            tableDataRef.current.Wednesday.OccIndexLy = lyValue;
                            setStrData(strDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current.Wednesday?.OccIndexChange ? (
                        tableDataRef.current.Wednesday?.OccIndexChange
                      ) : (
                        0
                      )}
                    </td>
                    <td className="border_right">
                      {tableDataRef.current.Wednesday?.OccIndexLy
                        ? tableDataRef.current.Wednesday?.OccIndexLy
                        : 0}
                    </td>

                    <td
                      onDoubleClick={() => {
                        setClickedTr({
                          mainRow: "occ",
                          subRow: "index",
                          field: "curr",
                          day: "thursday",
                        });
                      }}
                    >
                      {clickedTr.mainRow === "occ" &&
                      clickedTr.subRow === "index" &&
                      clickedTr.field === "curr" &&
                      clickedTr.day === "thursday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current.Thursday?.OccIndex}
                          onChange={(e) => {
                            let lyValue = "";
                            strDataRef.current = strDataRef.current?.map(
                              (item, index) => {
                                if (item?.Weekday === "Thursday") {
                                  item.OccIndex = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let changeValue = item?.OccIndexChange
                                    ? parseFloat(item.OccIndexChange)
                                    : 0;
                                  lyValue = (
                                    (newValue * 100) /
                                    (100 + changeValue)
                                  )?.toFixed(2);
                                  item.OccIndexLy = lyValue;
                                }
                                return item;
                              }
                            );
                            calculateAvg("OccIndex")
                            tableDataRef.current.Thursday.OccIndex =
                              e.target.value;
                            tableDataRef.current.Thursday.OccIndexLy = lyValue;
                            setStrData(strDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current.Thursday?.OccIndex ? (
                        tableDataRef.current.Thursday?.OccIndex
                      ) : (
                        0
                      )}
                    </td>
                    <td
                      onDoubleClick={() => {
                        setClickedTr({
                          mainRow: "occ",
                          subRow: "index",
                          field: "change",
                          day: "thursday",
                        });
                      }}
                    >
                      {clickedTr.mainRow === "occ" &&
                      clickedTr.subRow === "index" &&
                      clickedTr.field === "change" &&
                      clickedTr.day === "thursday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current.Thursday?.OccIndexChange}
                          onChange={(e) => {
                            let lyValue = "";
                            strDataRef.current = strDataRef.current?.map(
                              (item, index) => {
                                if (item?.Weekday === "Thursday") {
                                  item.OccIndexChange = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let currValue = item?.OccIndex
                                    ? parseFloat(item.OccIndex)
                                    : 0;
                                  lyValue = (
                                    (currValue * 100) /
                                    (100 + newValue)
                                  )?.toFixed(2);
                                  item.OccIndexLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current.Thursday.OccIndexChange =
                              e.target.value;
                            tableDataRef.current.Thursday.OccIndexLy = lyValue;
                            setStrData(strDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current.Thursday?.OccIndexChange ? (
                        tableDataRef.current.Thursday?.OccIndexChange
                      ) : (
                        0
                      )}
                    </td>
                    <td className="border_right">
                      {tableDataRef.current.Thursday?.OccIndexLy
                        ? tableDataRef.current.Thursday?.OccIndexLy
                        : 0}
                    </td>

                    <td
                      onDoubleClick={() => {
                        setClickedTr({
                          mainRow: "occ",
                          subRow: "index",
                          field: "curr",
                          day: "friday",
                        });
                      }}
                    >
                      {clickedTr.mainRow === "occ" &&
                      clickedTr.subRow === "index" &&
                      clickedTr.field === "curr" &&
                      clickedTr.day === "friday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current.Friday?.OccIndex}
                          onChange={(e) => {
                            let lyValue = "";
                            strDataRef.current = strDataRef.current?.map(
                              (item, index) => {
                                if (item?.Weekday === "Friday") {
                                  item.OccIndex = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let changeValue = item?.OccIndexChange
                                    ? parseFloat(item.OccIndexChange)
                                    : 0;
                                  lyValue = (
                                    (newValue * 100) /
                                    (100 + changeValue)
                                  )?.toFixed(2);
                                  item.OccIndexLy = lyValue;
                                }
                                return item;
                              }
                            );
                            calculateAvg("OccIndex")
                            tableDataRef.current.Friday.OccIndex =
                              e.target.value;
                            tableDataRef.current.Friday.OccIndexLy = lyValue;
                            setStrData(strDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current.Friday?.OccIndex ? (
                        tableDataRef.current.Friday?.OccIndex
                      ) : (
                        0
                      )}
                    </td>
                    <td
                      onDoubleClick={() => {
                        setClickedTr({
                          mainRow: "occ",
                          subRow: "index",
                          field: "change",
                          day: "friday",
                        });
                      }}
                    >
                      {clickedTr.mainRow === "occ" &&
                      clickedTr.subRow === "index" &&
                      clickedTr.field === "change" &&
                      clickedTr.day === "friday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current.Friday?.OccIndexChange}
                          onChange={(e) => {
                            let lyValue = "";
                            strDataRef.current = strDataRef.current?.map(
                              (item, index) => {
                                if (item?.Weekday === "Friday") {
                                  item.OccIndexChange = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let currValue = item?.OccIndex
                                    ? parseFloat(item.OccIndex)
                                    : 0;
                                  lyValue = (
                                    (currValue * 100) /
                                    (100 + newValue)
                                  )?.toFixed(2);
                                  item.OccIndexLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current.Friday.OccIndexChange =
                              e.target.value;
                            tableDataRef.current.Friday.OccIndexLy = lyValue;
                            setStrData(strDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current.Friday?.OccIndexChange ? (
                        tableDataRef.current.Friday?.OccIndexChange
                      ) : (
                        0
                      )}
                    </td>
                    <td className="border_right">
                      {tableDataRef.current.Friday?.OccIndexLy
                        ? tableDataRef.current.Friday?.OccIndexLy
                        : 0}
                    </td>

                    <td
                      onDoubleClick={() => {
                        setClickedTr({
                          mainRow: "occ",
                          subRow: "index",
                          field: "curr",
                          day: "saturday",
                        });
                      }}
                    >
                      {clickedTr.mainRow === "occ" &&
                      clickedTr.subRow === "index" &&
                      clickedTr.field === "curr" &&
                      clickedTr.day === "saturday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current.Saturday?.OccIndex}
                          onChange={(e) => {
                            let lyValue = "";
                            strDataRef.current = strDataRef.current?.map(
                              (item, index) => {
                                if (item?.Weekday === "Saturday") {
                                  item.OccIndex = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let changeValue = item?.OccIndexChange
                                    ? parseFloat(item.OccIndexChange)
                                    : 0;
                                  lyValue = (
                                    (newValue * 100) /
                                    (100 + changeValue)
                                  )?.toFixed(2);
                                  item.OccIndexLy = lyValue;
                                }
                                return item;
                              }
                            );
                            calculateAvg("OccIndex")
                            tableDataRef.current.Saturday.OccIndex =
                              e.target.value;
                            tableDataRef.current.Saturday.OccIndexLy = lyValue;
                            setStrData(strDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current.Saturday?.OccIndex ? (
                        tableDataRef.current.Saturday?.OccIndex
                      ) : (
                        0
                      )}
                    </td>
                    <td
                      onDoubleClick={() => {
                        setClickedTr({
                          mainRow: "occ",
                          subRow: "index",
                          field: "change",
                          day: "saturday",
                        });
                      }}
                    >
                      {clickedTr.mainRow === "occ" &&
                      clickedTr.subRow === "index" &&
                      clickedTr.field === "change" &&
                      clickedTr.day === "saturday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current.Saturday?.OccIndexChange}
                          onChange={(e) => {
                            let lyValue = "";
                            strDataRef.current = strDataRef.current?.map(
                              (item, index) => {
                                if (item?.Weekday === "Saturday") {
                                  item.OccIndexChange = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let currValue = item?.OccIndex
                                    ? parseFloat(item.OccIndex)
                                    : 0;
                                  lyValue = (
                                    (currValue * 100) /
                                    (100 + newValue)
                                  )?.toFixed(2);
                                  item.OccIndexLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current.Saturday.OccIndexChange =
                              e.target.value;
                            tableDataRef.current.Saturday.OccIndexLy = lyValue;
                            setStrData(strDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current.Saturday?.OccIndexChange ? (
                        tableDataRef.current.Saturday?.OccIndexChange
                      ) : (
                        0
                      )}
                    </td>
                    <td className="border_right">
                      {tableDataRef.current.Saturday?.OccIndexLy
                        ? tableDataRef.current.Saturday?.OccIndexLy
                        : 0}
                    </td>
                    {/* <td className="fontBold">{calculateAvg("OccIndex")}</td> */}
                    <td className="fontBold">{
                      tableDataRef.current.Total?.OccIndex
                      ? parseFloat(tableDataRef.current.Total?.OccIndex)?.toFixed(2)
                      : 0}</td>
                  </tr>

                  {/* ADR */}
                  <tr className="shadowOnHover">
                    <td rowSpan={3} className="th-with-rowspan border_right">
                      ADR
                    </td>
                    <td className="border_right">My Property</td>
                    <td
                      onDoubleClick={() => {
                        setClickedTr({
                          mainRow: "adr",
                          subRow: "myProperty",
                          field: "curr",
                          day: "sunday",
                        });
                      }}
                    >
                      {clickedTr.mainRow === "adr" &&
                      clickedTr.subRow === "myProperty" &&
                      clickedTr.field === "curr" &&
                      clickedTr.day === "sunday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current.Sunday?.AdrMyProperty}
                          onChange={(e) => {
                            let lyValue = "";
                            strDataRef.current = strDataRef.current?.map(
                              (item, index) => {
                                if (item?.Weekday === "Sunday") {
                                  item.AdrMyProperty = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let changeValue = item?.AdrMyPropertyChange
                                    ? parseFloat(item.AdrMyPropertyChange)
                                    : 0;
                                  lyValue = (
                                    (newValue * 100) /
                                    (100 + changeValue)
                                  )?.toFixed(2);
                                  item.AdrMyPropertyLy = lyValue;
                                }
                                return item;
                              }
                            );
                            calculateAvg("AdrMyProperty")
                            tableDataRef.current.Sunday.AdrMyProperty =
                              e.target.value;
                            tableDataRef.current.Sunday.AdrMyPropertyLy =
                              lyValue;
                            setStrData(strDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current.Sunday?.AdrMyProperty ? (
                        tableDataRef.current.Sunday?.AdrMyProperty
                      ) : (
                        0
                      )}
                    </td>
                    <td
                      onDoubleClick={() => {
                        setClickedTr({
                          mainRow: "adr",
                          subRow: "myProperty",
                          field: "change",
                          day: "sunday",
                        });
                      }}
                    >
                      {clickedTr.mainRow === "adr" &&
                      clickedTr.subRow === "myProperty" &&
                      clickedTr.field === "change" &&
                      clickedTr.day === "sunday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={
                            tableDataRef.current.Sunday?.AdrMyPropertyChange
                          }
                          onChange={(e) => {
                            let lyValue = "";
                            strDataRef.current = strDataRef.current?.map(
                              (item, index) => {
                                if (item?.Weekday === "Sunday") {
                                  item.AdrMyPropertyChange = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let currValue = item?.AdrMyProperty
                                    ? parseFloat(item.AdrMyProperty)
                                    : 0;
                                  lyValue = (
                                    (currValue * 100) /
                                    (100 + newValue)
                                  )?.toFixed(2);
                                  item.AdrMyPropertyLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current.Sunday.AdrMyPropertyChange =
                              e.target.value;
                            tableDataRef.current.Sunday.AdrMyPropertyLy =
                              lyValue;
                            setStrData(strDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current.Sunday?.AdrMyPropertyChange ? (
                        tableDataRef.current.Sunday?.AdrMyPropertyChange
                      ) : (
                        0
                      )}
                    </td>
                    <td className="border_right">
                      {tableDataRef.current.Sunday?.AdrMyPropertyLy
                        ? tableDataRef.current.Sunday?.AdrMyPropertyLy
                        : 0}
                    </td>

                    <td
                      onDoubleClick={() => {
                        setClickedTr({
                          mainRow: "adr",
                          subRow: "myProperty",
                          field: "curr",
                          day: "monday",
                        });
                      }}
                    >
                      {clickedTr.mainRow === "adr" &&
                      clickedTr.subRow === "myProperty" &&
                      clickedTr.field === "curr" &&
                      clickedTr.day === "monday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current.Monday?.AdrMyProperty}
                          onChange={(e) => {
                            let lyValue = "";
                            strDataRef.current = strDataRef.current?.map(
                              (item, index) => {
                                if (item?.Weekday === "Monday") {
                                  item.AdrMyProperty = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let changeValue = item?.AdrMyPropertyChange
                                    ? parseFloat(item.AdrMyPropertyChange)
                                    : 0;
                                  lyValue = (
                                    (newValue * 100) /
                                    (100 + changeValue)
                                  )?.toFixed(2);
                                  item.AdrMyPropertyLy = lyValue;
                                }
                                return item;
                              }
                            );
                            calculateAvg("AdrMyProperty")
                            tableDataRef.current.Monday.AdrMyProperty =
                              e.target.value;
                            tableDataRef.current.Monday.AdrMyPropertyLy =
                              lyValue;
                            setStrData(strDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current.Monday?.AdrMyProperty ? (
                        tableDataRef.current.Monday?.AdrMyProperty
                      ) : (
                        0
                      )}
                    </td>
                    <td
                      onDoubleClick={() => {
                        setClickedTr({
                          mainRow: "adr",
                          subRow: "myProperty",
                          field: "change",
                          day: "monday",
                        });
                      }}
                    >
                      {clickedTr.mainRow === "adr" &&
                      clickedTr.subRow === "myProperty" &&
                      clickedTr.field === "change" &&
                      clickedTr.day === "monday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={
                            tableDataRef.current.Monday?.AdrMyPropertyChange
                          }
                          onChange={(e) => {
                            let lyValue = "";
                            strDataRef.current = strDataRef.current?.map(
                              (item, index) => {
                                if (item?.Weekday === "Monday") {
                                  item.AdrMyPropertyChange = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let currValue = item?.AdrMyProperty
                                    ? parseFloat(item.AdrMyProperty)
                                    : 0;
                                  lyValue = (
                                    (currValue * 100) /
                                    (100 + newValue)
                                  )?.toFixed(2);
                                  item.AdrMyPropertyLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current.Monday.AdrMyPropertyChange =
                              e.target.value;
                            tableDataRef.current.Monday.AdrMyPropertyLy =
                              lyValue;
                            setStrData(strDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current.Monday?.AdrMyPropertyChange ? (
                        tableDataRef.current.Monday?.AdrMyPropertyChange
                      ) : (
                        0
                      )}
                    </td>
                    <td className="border_right">
                      {tableDataRef.current.Monday?.AdrMyPropertyLy
                        ? tableDataRef.current.Monday?.AdrMyPropertyLy
                        : 0}
                    </td>

                    <td
                      onDoubleClick={() => {
                        setClickedTr({
                          mainRow: "adr",
                          subRow: "myProperty",
                          field: "curr",
                          day: "tuseday",
                        });
                      }}
                    >
                      {clickedTr.mainRow === "adr" &&
                      clickedTr.subRow === "myProperty" &&
                      clickedTr.field === "curr" &&
                      clickedTr.day === "tuseday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current.Tuesday?.AdrMyProperty}
                          onChange={(e) => {
                            let lyValue = "";
                            strDataRef.current = strDataRef.current?.map(
                              (item, index) => {
                                if (item?.Weekday === "Tuesday") {
                                  item.AdrMyProperty = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let changeValue = item?.AdrMyPropertyChange
                                    ? parseFloat(item.AdrMyPropertyChange)
                                    : 0;
                                  lyValue = (
                                    (newValue * 100) /
                                    (100 + changeValue)
                                  )?.toFixed(2);
                                  item.AdrMyPropertyLy = lyValue;
                                }
                                return item;
                              }
                            );
                            calculateAvg("AdrMyProperty")
                            tableDataRef.current.Tuesday.AdrMyProperty =
                              e.target.value;
                            tableDataRef.current.Tuesday.AdrMyPropertyLy =
                              lyValue;
                            setStrData(strDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current.Tuesday?.AdrMyProperty ? (
                        tableDataRef.current.Tuesday?.AdrMyProperty
                      ) : (
                        0
                      )}
                    </td>
                    <td
                      onDoubleClick={() => {
                        setClickedTr({
                          mainRow: "adr",
                          subRow: "myProperty",
                          field: "change",
                          day: "tuesday",
                        });
                      }}
                    >
                      {clickedTr.mainRow === "adr" &&
                      clickedTr.subRow === "myProperty" &&
                      clickedTr.field === "change" &&
                      clickedTr.day === "tuesday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={
                            tableDataRef.current.Tuesday?.AdrMyPropertyChange
                          }
                          onChange={(e) => {
                            let lyValue = "";
                            strDataRef.current = strDataRef.current?.map(
                              (item, index) => {
                                if (item?.Weekday === "Tuesday") {
                                  item.AdrMyPropertyChange = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let currValue = item?.AdrMyProperty
                                    ? parseFloat(item.AdrMyProperty)
                                    : 0;
                                  lyValue = (
                                    (currValue * 100) /
                                    (100 + newValue)
                                  )?.toFixed(2);
                                  item.AdrMyPropertyLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current.Tuesday.AdrMyPropertyChange =
                              e.target.value;
                            tableDataRef.current.Tuesday.AdrMyPropertyLy =
                              lyValue;
                            setStrData(strDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current.Tuesday?.AdrMyPropertyChange ? (
                        tableDataRef.current.Tuesday?.AdrMyPropertyChange
                      ) : (
                        0
                      )}
                    </td>
                    <td className="border_right">
                      {tableDataRef.current.Tuesday?.AdrMyPropertyLy
                        ? tableDataRef.current.Tuesday?.AdrMyPropertyLy
                        : 0}
                    </td>

                    <td
                      onDoubleClick={() => {
                        setClickedTr({
                          mainRow: "adr",
                          subRow: "myProperty",
                          field: "curr",
                          day: "wednesday",
                        });
                      }}
                    >
                      {clickedTr.mainRow === "adr" &&
                      clickedTr.subRow === "myProperty" &&
                      clickedTr.field === "curr" &&
                      clickedTr.day === "wednesday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current.Wednesday?.AdrMyProperty}
                          onChange={(e) => {
                            let lyValue = "";
                            strDataRef.current = strDataRef.current?.map(
                              (item, index) => {
                                if (item?.Weekday === "Wednesday") {
                                  item.AdrMyProperty = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let changeValue = item?.AdrMyPropertyChange
                                    ? parseFloat(item.AdrMyPropertyChange)
                                    : 0;
                                  lyValue = (
                                    (newValue * 100) /
                                    (100 + changeValue)
                                  )?.toFixed(2);
                                  item.AdrMyPropertyLy = lyValue;
                                }
                                return item;
                              }
                            );
                            calculateAvg("AdrMyProperty")
                            tableDataRef.current.Wednesday.AdrMyProperty =
                              e.target.value;
                            tableDataRef.current.Wednesday.AdrMyPropertyLy =
                              lyValue;
                            setStrData(strDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current.Wednesday?.AdrMyProperty ? (
                        tableDataRef.current.Wednesday?.AdrMyProperty
                      ) : (
                        0
                      )}
                    </td>
                    <td
                      onDoubleClick={() => {
                        setClickedTr({
                          mainRow: "adr",
                          subRow: "myProperty",
                          field: "change",
                          day: "wednesday",
                        });
                      }}
                    >
                      {clickedTr.mainRow === "adr" &&
                      clickedTr.subRow === "myProperty" &&
                      clickedTr.field === "change" &&
                      clickedTr.day === "wednesday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={
                            tableDataRef.current.Wednesday?.AdrMyPropertyChange
                          }
                          onChange={(e) => {
                            let lyValue = "";
                            strDataRef.current = strDataRef.current?.map(
                              (item, index) => {
                                if (item?.Weekday === "Wednesday") {
                                  item.AdrMyPropertyChange = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let currValue = item?.AdrMyProperty
                                    ? parseFloat(item.AdrMyProperty)
                                    : 0;
                                  lyValue = (
                                    (currValue * 100) /
                                    (100 + newValue)
                                  )?.toFixed(2);
                                  item.AdrMyPropertyLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current.Wednesday.AdrMyPropertyChange =
                              e.target.value;
                            tableDataRef.current.Wednesday.AdrMyPropertyLy =
                              lyValue;
                            setStrData(strDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current.Wednesday
                          ?.AdrMyPropertyChange ? (
                        tableDataRef.current.Wednesday?.AdrMyPropertyChange
                      ) : (
                        0
                      )}
                    </td>
                    <td className="border_right">
                      {tableDataRef.current.Wednesday?.AdrMyPropertyLy
                        ? tableDataRef.current.Wednesday?.AdrMyPropertyLy
                        : 0}
                    </td>

                    <td
                      onDoubleClick={() => {
                        setClickedTr({
                          mainRow: "adr",
                          subRow: "myProperty",
                          field: "curr",
                          day: "thursday",
                        });
                      }}
                    >
                      {clickedTr.mainRow === "adr" &&
                      clickedTr.subRow === "myProperty" &&
                      clickedTr.field === "curr" &&
                      clickedTr.day === "thursday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current.Thursday?.AdrMyProperty}
                          onChange={(e) => {
                            let lyValue = "";
                            strDataRef.current = strDataRef.current?.map(
                              (item, index) => {
                                if (item?.Weekday === "Thursday") {
                                  item.AdrMyProperty = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let changeValue = item?.AdrMyPropertyChange
                                    ? parseFloat(item.AdrMyPropertyChange)
                                    : 0;
                                  lyValue = (
                                    (newValue * 100) /
                                    (100 + changeValue)
                                  )?.toFixed(2);
                                  item.AdrMyPropertyLy = lyValue;
                                }
                                return item;
                              }
                            );
                            calculateAvg("AdrMyProperty")
                            tableDataRef.current.Thursday.AdrMyProperty =
                              e.target.value;
                            tableDataRef.current.Thursday.AdrMyPropertyLy =
                              lyValue;
                            setStrData(strDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current.Thursday?.AdrMyProperty ? (
                        tableDataRef.current.Thursday?.AdrMyProperty
                      ) : (
                        0
                      )}
                    </td>
                    <td
                      onDoubleClick={() => {
                        setClickedTr({
                          mainRow: "adr",
                          subRow: "myProperty",
                          field: "change",
                          day: "thursday",
                        });
                      }}
                    >
                      {clickedTr.mainRow === "adr" &&
                      clickedTr.subRow === "myProperty" &&
                      clickedTr.field === "change" &&
                      clickedTr.day === "thursday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={
                            tableDataRef.current.Thursday?.AdrMyPropertyChange
                          }
                          onChange={(e) => {
                            let lyValue = "";
                            strDataRef.current = strDataRef.current?.map(
                              (item, index) => {
                                if (item?.Weekday === "Thursday") {
                                  item.AdrMyPropertyChange = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let currValue = item?.AdrMyProperty
                                    ? parseFloat(item.AdrMyProperty)
                                    : 0;
                                  lyValue = (
                                    (currValue * 100) /
                                    (100 + newValue)
                                  )?.toFixed(2);
                                  item.AdrMyPropertyLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current.Thursday.AdrMyPropertyChange =
                              e.target.value;
                            tableDataRef.current.Thursday.AdrMyPropertyLy =
                              lyValue;
                            setStrData(strDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current.Thursday?.AdrMyPropertyChange ? (
                        tableDataRef.current.Thursday?.AdrMyPropertyChange
                      ) : (
                        0
                      )}
                    </td>
                    <td className="border_right">
                      {tableDataRef.current.Thursday?.AdrMyPropertyLy
                        ? tableDataRef.current.Thursday?.AdrMyPropertyLy
                        : 0}
                    </td>

                    <td
                      onDoubleClick={() => {
                        setClickedTr({
                          mainRow: "adr",
                          subRow: "myProperty",
                          field: "curr",
                          day: "friday",
                        });
                      }}
                    >
                      {clickedTr.mainRow === "adr" &&
                      clickedTr.subRow === "myProperty" &&
                      clickedTr.field === "curr" &&
                      clickedTr.day === "friday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current.Friday?.AdrMyProperty}
                          onChange={(e) => {
                            let lyValue = "";
                            strDataRef.current = strDataRef.current?.map(
                              (item, index) => {
                                if (item?.Weekday === "Friday") {
                                  item.AdrMyProperty = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let changeValue = item?.AdrMyPropertyChange
                                    ? parseFloat(item.AdrMyPropertyChange)
                                    : 0;
                                  lyValue = (
                                    (newValue * 100) /
                                    (100 + changeValue)
                                  )?.toFixed(2);
                                  item.AdrMyPropertyLy = lyValue;
                                }
                                return item;
                              }
                            );
                            calculateAvg("AdrMyProperty")
                            tableDataRef.current.Friday.AdrMyProperty =
                              e.target.value;
                            tableDataRef.current.Friday.AdrMyPropertyLy =
                              lyValue;
                            setStrData(strDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current.Friday?.AdrMyProperty ? (
                        tableDataRef.current.Friday?.AdrMyProperty
                      ) : (
                        0
                      )}
                    </td>
                    <td
                      onDoubleClick={() => {
                        setClickedTr({
                          mainRow: "adr",
                          subRow: "myProperty",
                          field: "change",
                          day: "friday",
                        });
                      }}
                    >
                      {clickedTr.mainRow === "adr" &&
                      clickedTr.subRow === "myProperty" &&
                      clickedTr.field === "change" &&
                      clickedTr.day === "friday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={
                            tableDataRef.current.Friday?.AdrMyPropertyChange
                          }
                          onChange={(e) => {
                            let lyValue = "";
                            strDataRef.current = strDataRef.current?.map(
                              (item, index) => {
                                if (item?.Weekday === "Friday") {
                                  item.AdrMyPropertyChange = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let currValue = item?.AdrMyProperty
                                    ? parseFloat(item.AdrMyProperty)
                                    : 0;
                                  lyValue = (
                                    (currValue * 100) /
                                    (100 + newValue)
                                  )?.toFixed(2);
                                  item.AdrMyPropertyLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current.Friday.AdrMyPropertyChange =
                              e.target.value;
                            tableDataRef.current.Friday.AdrMyPropertyLy =
                              lyValue;
                            setStrData(strDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current.Friday?.AdrMyPropertyChange ? (
                        tableDataRef.current.Friday?.AdrMyPropertyChange
                      ) : (
                        0
                      )}
                    </td>
                    <td className="border_right">
                      {tableDataRef.current.Friday?.AdrMyPropertyLy
                        ? tableDataRef.current.Friday?.AdrMyPropertyLy
                        : 0}
                    </td>

                    <td
                      onDoubleClick={() => {
                        setClickedTr({
                          mainRow: "adr",
                          subRow: "myProperty",
                          field: "curr",
                          day: "saturday",
                        });
                      }}
                    >
                      {clickedTr.mainRow === "adr" &&
                      clickedTr.subRow === "myProperty" &&
                      clickedTr.field === "curr" &&
                      clickedTr.day === "saturday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current.Saturday?.AdrMyProperty}
                          onChange={(e) => {
                            let lyValue = "";
                            strDataRef.current = strDataRef.current?.map(
                              (item, index) => {
                                if (item?.Weekday === "Saturday") {
                                  item.AdrMyProperty = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let changeValue = item?.AdrMyPropertyChange
                                    ? parseFloat(item.AdrMyPropertyChange)
                                    : 0;
                                  lyValue = (
                                    (newValue * 100) /
                                    (100 + changeValue)
                                  )?.toFixed(2);
                                  item.AdrMyPropertyLy = lyValue;
                                }
                                return item;
                              }
                            );
                            calculateAvg("AdrMyProperty")
                            tableDataRef.current.Saturday.AdrMyProperty =
                              e.target.value;
                            tableDataRef.current.Saturday.AdrMyPropertyLy =
                              lyValue;
                            setStrData(strDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current.Saturday?.AdrMyProperty ? (
                        tableDataRef.current.Saturday?.AdrMyProperty
                      ) : (
                        0
                      )}
                    </td>
                    <td
                      onDoubleClick={() => {
                        setClickedTr({
                          mainRow: "adr",
                          subRow: "myProperty",
                          field: "change",
                          day: "saturday",
                        });
                      }}
                    >
                      {clickedTr.mainRow === "adr" &&
                      clickedTr.subRow === "myProperty" &&
                      clickedTr.field === "change" &&
                      clickedTr.day === "saturday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={
                            tableDataRef.current.Saturday?.AdrMyPropertyChange
                          }
                          onChange={(e) => {
                            let lyValue = "";
                            strDataRef.current = strDataRef.current?.map(
                              (item, index) => {
                                if (item?.Weekday === "Saturday") {
                                  item.AdrMyPropertyChange = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let currValue = item?.AdrMyProperty
                                    ? parseFloat(item.AdrMyProperty)
                                    : 0;
                                  lyValue = (
                                    (currValue * 100) /
                                    (100 + newValue)
                                  )?.toFixed(2);
                                  item.AdrMyPropertyLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current.Saturday.AdrMyPropertyChange =
                              e.target.value;
                            tableDataRef.current.Saturday.AdrMyPropertyLy =
                              lyValue;
                            setStrData(strDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current.Saturday?.AdrMyPropertyChange ? (
                        tableDataRef.current.Saturday?.AdrMyPropertyChange
                      ) : (
                        0
                      )}
                    </td>
                    <td className="border_right">
                      {tableDataRef.current.Saturday?.AdrMyPropertyLy
                        ? tableDataRef.current.Saturday?.AdrMyPropertyLy
                        : 0}
                    </td>
                    <td className="fontBold">
                      {/* {calculateAvg("AdrMyProperty")} */}
                      {
                      tableDataRef.current.Total?.AdrMyProperty
                      ? parseFloat(tableDataRef.current.Total?.AdrMyProperty)?.toFixed(2)
                      : 0}
                    </td>
                  </tr>
                  <tr className="shadowOnHover">
                    <td className="border_right">Comp Set</td>
                    <td
                      onDoubleClick={() => {
                        setClickedTr({
                          mainRow: "adr",
                          subRow: "compSet",
                          field: "curr",
                          day: "sunday",
                        });
                      }}
                    >
                      {clickedTr.mainRow === "adr" &&
                      clickedTr.subRow === "compSet" &&
                      clickedTr.field === "curr" &&
                      clickedTr.day === "sunday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current.Sunday?.AdrCompset}
                          onChange={(e) => {
                            let lyValue = "";
                            strDataRef.current = strDataRef.current?.map(
                              (item, index) => {
                                if (item?.Weekday === "Sunday") {
                                  item.AdrCompset = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let changeValue = item?.AdrCompsetChange
                                    ? parseFloat(item.AdrCompsetChange)
                                    : 0;
                                  lyValue = (
                                    (newValue * 100) /
                                    (100 + changeValue)
                                  )?.toFixed(2);
                                  item.AdrCompsetLy = lyValue;
                                }
                                return item;
                              }
                            );
                            calculateAvg("AdrCompset")
                            tableDataRef.current.Sunday.AdrCompset =
                              e.target.value;
                            tableDataRef.current.Sunday.AdrCompsetLy = lyValue;
                            setStrData(strDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current.Sunday?.AdrCompset ? (
                        tableDataRef.current.Sunday?.AdrCompset
                      ) : (
                        0
                      )}
                    </td>
                    <td
                      onDoubleClick={() => {
                        setClickedTr({
                          mainRow: "adr",
                          subRow: "compSet",
                          field: "change",
                          day: "sunday",
                        });
                      }}
                    >
                      {clickedTr.mainRow === "adr" &&
                      clickedTr.subRow === "compSet" &&
                      clickedTr.field === "change" &&
                      clickedTr.day === "sunday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current.Sunday?.AdrCompsetChange}
                          onChange={(e) => {
                            let lyValue = "";
                            strDataRef.current = strDataRef.current?.map(
                              (item, index) => {
                                if (item?.Weekday === "Sunday") {
                                  item.AdrCompsetChange = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let currValue = item?.AdrCompset
                                    ? parseFloat(item.AdrCompset)
                                    : 0;
                                  lyValue = (
                                    (currValue * 100) /
                                    (100 + newValue)
                                  )?.toFixed(2);
                                  item.AdrCompsetLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current.Sunday.AdrCompsetChange =
                              e.target.value;
                            tableDataRef.current.Sunday.AdrCompsetLy = lyValue;
                            setStrData(strDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current.Sunday?.AdrCompsetChange ? (
                        tableDataRef.current.Sunday?.AdrCompsetChange
                      ) : (
                        0
                      )}
                    </td>
                    <td className="border_right">
                      {tableDataRef.current.Sunday?.AdrCompsetLy
                        ? tableDataRef.current.Sunday?.AdrCompsetLy
                        : 0}
                    </td>

                    <td
                      onDoubleClick={() => {
                        setClickedTr({
                          mainRow: "adr",
                          subRow: "compSet",
                          field: "curr",
                          day: "monday",
                        });
                      }}
                    >
                      {clickedTr.mainRow === "adr" &&
                      clickedTr.subRow === "compSet" &&
                      clickedTr.field === "curr" &&
                      clickedTr.day === "monday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current.Monday?.AdrCompset}
                          onChange={(e) => {
                            let lyValue = "";
                            strDataRef.current = strDataRef.current?.map(
                              (item, index) => {
                                if (item?.Weekday === "Monday") {
                                  item.AdrCompset = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let changeValue = item?.AdrCompsetChange
                                    ? parseFloat(item.AdrCompsetChange)
                                    : 0;
                                  lyValue = (
                                    (newValue * 100) /
                                    (100 + changeValue)
                                  )?.toFixed(2);
                                  item.AdrCompsetLy = lyValue;
                                }
                                return item;
                              }
                            );
                            calculateAvg("AdrCompset")
                            tableDataRef.current.Monday.AdrCompset =
                              e.target.value;
                            tableDataRef.current.Monday.AdrCompsetLy = lyValue;
                            setStrData(strDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current.Monday?.AdrCompset ? (
                        tableDataRef.current.Monday?.AdrCompset
                      ) : (
                        0
                      )}
                    </td>
                    <td
                      onDoubleClick={() => {
                        setClickedTr({
                          mainRow: "adr",
                          subRow: "compSet",
                          field: "change",
                          day: "monday",
                        });
                      }}
                    >
                      {clickedTr.mainRow === "adr" &&
                      clickedTr.subRow === "compSet" &&
                      clickedTr.field === "change" &&
                      clickedTr.day === "monday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current.Monday?.AdrCompsetChange}
                          onChange={(e) => {
                            let lyValue = "";
                            strDataRef.current = strDataRef.current?.map(
                              (item, index) => {
                                if (item?.Weekday === "Monday") {
                                  item.AdrCompsetChange = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let currValue = item?.AdrCompset
                                    ? parseFloat(item.AdrCompset)
                                    : 0;
                                  lyValue = (
                                    (currValue * 100) /
                                    (100 + newValue)
                                  )?.toFixed(2);
                                  item.AdrCompsetLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current.Monday.AdrCompsetChange =
                              e.target.value;
                            tableDataRef.current.Monday.AdrCompsetLy = lyValue;
                            setStrData(strDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current.Monday?.AdrCompsetChange ? (
                        tableDataRef.current.Monday?.AdrCompsetChange
                      ) : (
                        0
                      )}
                    </td>
                    <td className="border_right">
                      {tableDataRef.current.Monday?.AdrCompsetLy
                        ? tableDataRef.current.Monday?.AdrCompsetLy
                        : 0}
                    </td>

                    <td
                      onDoubleClick={() => {
                        setClickedTr({
                          mainRow: "adr",
                          subRow: "compSet",
                          field: "curr",
                          day: "tuesday",
                        });
                      }}
                    >
                      {clickedTr.mainRow === "adr" &&
                      clickedTr.subRow === "compSet" &&
                      clickedTr.field === "curr" &&
                      clickedTr.day === "tuesday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current.Tuesday?.AdrCompset}
                          onChange={(e) => {
                            let lyValue = "";
                            strDataRef.current = strDataRef.current?.map(
                              (item, index) => {
                                if (item?.Weekday === "Tuesday") {
                                  item.AdrCompset = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let changeValue = item?.AdrCompsetChange
                                    ? parseFloat(item.AdrCompsetChange)
                                    : 0;
                                  lyValue = (
                                    (newValue * 100) /
                                    (100 + changeValue)
                                  )?.toFixed(2);
                                  item.AdrCompsetLy = lyValue;
                                }
                                return item;
                              }
                            );
                            calculateAvg("AdrCompset")
                            tableDataRef.current.Tuesday.AdrCompset =
                              e.target.value;
                            tableDataRef.current.Tuesday.AdrCompsetLy = lyValue;
                            setStrData(strDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current.Tuesday?.AdrCompset ? (
                        tableDataRef.current.Tuesday?.AdrCompset
                      ) : (
                        0
                      )}
                    </td>
                    <td
                      onDoubleClick={() => {
                        setClickedTr({
                          mainRow: "adr",
                          subRow: "compSet",
                          field: "change",
                          day: "tuesday",
                        });
                      }}
                    >
                      {clickedTr.mainRow === "adr" &&
                      clickedTr.subRow === "compSet" &&
                      clickedTr.field === "change" &&
                      clickedTr.day === "tuesday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current.Tuesday?.AdrCompsetChange}
                          onChange={(e) => {
                            let lyValue = "";
                            strDataRef.current = strDataRef.current?.map(
                              (item, index) => {
                                if (item?.Weekday === "Tuesday") {
                                  item.AdrCompsetChange = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let currValue = item?.AdrCompset
                                    ? parseFloat(item.AdrCompset)
                                    : 0;
                                  lyValue = (
                                    (currValue * 100) /
                                    (100 + newValue)
                                  )?.toFixed(2);
                                  item.AdrCompsetLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current.Tuesday.AdrCompsetChange =
                              e.target.value;
                            tableDataRef.current.Tuesday.AdrCompsetLy = lyValue;
                            setStrData(strDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current.Tuesday?.AdrCompsetChange ? (
                        tableDataRef.current.Tuesday?.AdrCompsetChange
                      ) : (
                        0
                      )}
                    </td>
                    <td className="border_right">
                      {tableDataRef.current.Tuesday?.AdrCompsetLy
                        ? tableDataRef.current.Tuesday?.AdrCompsetLy
                        : 0}
                    </td>

                    <td
                      onDoubleClick={() => {
                        setClickedTr({
                          mainRow: "adr",
                          subRow: "compSet",
                          field: "curr",
                          day: "wednesday",
                        });
                      }}
                    >
                      {clickedTr.mainRow === "adr" &&
                      clickedTr.subRow === "compSet" &&
                      clickedTr.field === "curr" &&
                      clickedTr.day === "wednesday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current.Wednesday?.AdrCompset}
                          onChange={(e) => {
                            let lyValue = "";
                            strDataRef.current = strDataRef.current?.map(
                              (item, index) => {
                                if (item?.Weekday === "Wednesday") {
                                  item.AdrCompset = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let changeValue = item?.AdrCompsetChange
                                    ? parseFloat(item.AdrCompsetChange)
                                    : 0;
                                  lyValue = (
                                    (newValue * 100) /
                                    (100 + changeValue)
                                  )?.toFixed(2);
                                  item.AdrCompsetLy = lyValue;
                                }
                                return item;
                              }
                            );
                            calculateAvg("AdrCompset")
                            tableDataRef.current.Wednesday.AdrCompset =
                              e.target.value;
                            tableDataRef.current.Wednesday.AdrCompsetLy =
                              lyValue;
                            setStrData(strDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current.Wednesday?.AdrCompset ? (
                        tableDataRef.current.Wednesday?.AdrCompset
                      ) : (
                        0
                      )}
                    </td>
                    <td
                      onDoubleClick={() => {
                        setClickedTr({
                          mainRow: "adr",
                          subRow: "compSet",
                          field: "change",
                          day: "wednesday",
                        });
                      }}
                    >
                      {clickedTr.mainRow === "adr" &&
                      clickedTr.subRow === "compSet" &&
                      clickedTr.field === "change" &&
                      clickedTr.day === "wednesday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={
                            tableDataRef.current.Wednesday?.AdrCompsetChange
                          }
                          onChange={(e) => {
                            let lyValue = "";
                            strDataRef.current = strDataRef.current?.map(
                              (item, index) => {
                                if (item?.Weekday === "Wednesday") {
                                  item.AdrCompsetChange = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let currValue = item?.AdrCompset
                                    ? parseFloat(item.AdrCompset)
                                    : 0;
                                  lyValue = (
                                    (currValue * 100) /
                                    (100 + newValue)
                                  )?.toFixed(2);
                                  item.AdrCompsetLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current.Wednesday.AdrCompsetChange =
                              e.target.value;
                            tableDataRef.current.Wednesday.AdrCompsetLy =
                              lyValue;
                            setStrData(strDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current.Wednesday?.AdrCompsetChange ? (
                        tableDataRef.current.Wednesday?.AdrCompsetChange
                      ) : (
                        0
                      )}
                    </td>
                    <td className="border_right">
                      {tableDataRef.current.Wednesday?.AdrCompsetLy
                        ? tableDataRef.current.Wednesday?.AdrCompsetLy
                        : 0}
                    </td>

                    <td
                      onDoubleClick={() => {
                        setClickedTr({
                          mainRow: "adr",
                          subRow: "compSet",
                          field: "curr",
                          day: "thursday",
                        });
                      }}
                    >
                      {clickedTr.mainRow === "adr" &&
                      clickedTr.subRow === "compSet" &&
                      clickedTr.field === "curr" &&
                      clickedTr.day === "thursday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current.Thursday?.AdrCompset}
                          onChange={(e) => {
                            let lyValue = "";
                            strDataRef.current = strDataRef.current?.map(
                              (item, index) => {
                                if (item?.Weekday === "Thursday") {
                                  item.AdrCompset = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let changeValue = item?.AdrCompsetChange
                                    ? parseFloat(item.AdrCompsetChange)
                                    : 0;
                                  lyValue = (
                                    (newValue * 100) /
                                    (100 + changeValue)
                                  )?.toFixed(2);
                                  item.AdrCompsetLy = lyValue;
                                }
                                return item;
                              }
                            );
                            calculateAvg("AdrCompset")
                            tableDataRef.current.Thursday.AdrCompset =
                              e.target.value;
                            tableDataRef.current.Thursday.AdrCompsetLy =
                              lyValue;
                            setStrData(strDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current.Thursday?.AdrCompset ? (
                        tableDataRef.current.Thursday?.AdrCompset
                      ) : (
                        0
                      )}
                    </td>
                    <td
                      onDoubleClick={() => {
                        setClickedTr({
                          mainRow: "adr",
                          subRow: "compSet",
                          field: "change",
                          day: "thursday",
                        });
                      }}
                    >
                      {clickedTr.mainRow === "adr" &&
                      clickedTr.subRow === "compSet" &&
                      clickedTr.field === "change" &&
                      clickedTr.day === "thursday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={
                            tableDataRef.current.Thursday?.AdrCompsetChange
                          }
                          onChange={(e) => {
                            let lyValue = "";
                            strDataRef.current = strDataRef.current?.map(
                              (item, index) => {
                                if (item?.Weekday === "Thursday") {
                                  item.AdrCompsetChange = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let currValue = item?.AdrCompset
                                    ? parseFloat(item.AdrCompset)
                                    : 0;
                                  lyValue = (
                                    (currValue * 100) /
                                    (100 + newValue)
                                  )?.toFixed(2);
                                  item.AdrCompsetLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current.Thursday.AdrCompsetChange =
                              e.target.value;
                            tableDataRef.current.Thursday.AdrCompsetLy =
                              lyValue;
                            setStrData(strDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current.Thursday?.AdrCompsetChange ? (
                        tableDataRef.current.Thursday?.AdrCompsetChange
                      ) : (
                        0
                      )}
                    </td>
                    <td className="border_right">
                      {tableDataRef.current.Thursday?.AdrCompsetLy
                        ? tableDataRef.current.Thursday?.AdrCompsetLy
                        : 0}
                    </td>

                    <td
                      onDoubleClick={() => {
                        setClickedTr({
                          mainRow: "adr",
                          subRow: "compSet",
                          field: "curr",
                          day: "friday",
                        });
                      }}
                    >
                      {clickedTr.mainRow === "adr" &&
                      clickedTr.subRow === "compSet" &&
                      clickedTr.field === "curr" &&
                      clickedTr.day === "friday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current.Friday?.AdrCompset}
                          onChange={(e) => {
                            let lyValue = "";
                            strDataRef.current = strDataRef.current?.map(
                              (item, index) => {
                                if (item?.Weekday === "Friday") {
                                  item.AdrCompset = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let changeValue = item?.AdrCompsetChange
                                    ? parseFloat(item.AdrCompsetChange)
                                    : 0;
                                  lyValue = (
                                    (newValue * 100) /
                                    (100 + changeValue)
                                  )?.toFixed(2);
                                  item.AdrCompsetLy = lyValue;
                                }
                                return item;
                              }
                            );
                            calculateAvg("AdrCompset")
                            tableDataRef.current.Friday.AdrCompset =
                              e.target.value;
                            tableDataRef.current.Friday.AdrCompsetLy = lyValue;
                            setStrData(strDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current.Friday?.AdrCompset ? (
                        tableDataRef.current.Friday?.AdrCompset
                      ) : (
                        0
                      )}
                    </td>
                    <td
                      onDoubleClick={() => {
                        setClickedTr({
                          mainRow: "adr",
                          subRow: "compSet",
                          field: "change",
                          day: "friday",
                        });
                      }}
                    >
                      {clickedTr.mainRow === "adr" &&
                      clickedTr.subRow === "compSet" &&
                      clickedTr.field === "change" &&
                      clickedTr.day === "friday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current.Friday?.AdrCompsetChange}
                          onChange={(e) => {
                            let lyValue = "";
                            strDataRef.current = strDataRef.current?.map(
                              (item, index) => {
                                if (item?.Weekday === "Friday") {
                                  item.AdrCompsetChange = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let currValue = item?.AdrCompset
                                    ? parseFloat(item.AdrCompset)
                                    : 0;
                                  lyValue = (
                                    (currValue * 100) /
                                    (100 + newValue)
                                  )?.toFixed(2);
                                  item.AdrCompsetLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current.Friday.AdrCompsetChange =
                              e.target.value;
                            tableDataRef.current.Friday.AdrCompsetLy = lyValue;
                            setStrData(strDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current.Friday?.AdrCompsetChange ? (
                        tableDataRef.current.Friday?.AdrCompsetChange
                      ) : (
                        0
                      )}
                    </td>
                    <td className="border_right">
                      {tableDataRef.current.Friday?.AdrCompsetLy
                        ? tableDataRef.current.Friday?.AdrCompsetLy
                        : 0}
                    </td>

                    <td
                      onDoubleClick={() => {
                        setClickedTr({
                          mainRow: "adr",
                          subRow: "compSet",
                          field: "curr",
                          day: "saturday",
                        });
                      }}
                    >
                      {clickedTr.mainRow === "adr" &&
                      clickedTr.subRow === "compSet" &&
                      clickedTr.field === "curr" &&
                      clickedTr.day === "saturday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current.Saturday?.AdrCompset}
                          onChange={(e) => {
                            let lyValue = "";
                            strDataRef.current = strDataRef.current?.map(
                              (item, index) => {
                                if (item?.Weekday === "Saturday") {
                                  item.AdrCompset = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let changeValue = item?.AdrCompsetChange
                                    ? parseFloat(item.AdrCompsetChange)
                                    : 0;
                                  lyValue = (
                                    (newValue * 100) /
                                    (100 + changeValue)
                                  )?.toFixed(2);
                                  item.AdrCompsetLy = lyValue;
                                }
                                return item;
                              }
                            );
                            calculateAvg("AdrCompset")
                            tableDataRef.current.Saturday.AdrCompset =
                              e.target.value;
                            tableDataRef.current.Saturday.AdrCompsetLy =
                              lyValue;
                            setStrData(strDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current.Saturday?.AdrCompset ? (
                        tableDataRef.current.Saturday?.AdrCompset
                      ) : (
                        0
                      )}
                    </td>
                    <td
                      onDoubleClick={() => {
                        setClickedTr({
                          mainRow: "adr",
                          subRow: "compSet",
                          field: "change",
                          day: "saturday",
                        });
                      }}
                    >
                      {clickedTr.mainRow === "adr" &&
                      clickedTr.subRow === "compSet" &&
                      clickedTr.field === "change" &&
                      clickedTr.day === "saturday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={
                            tableDataRef.current.Saturday?.AdrCompsetChange
                          }
                          onChange={(e) => {
                            let lyValue = "";
                            strDataRef.current = strDataRef.current?.map(
                              (item, index) => {
                                if (item?.Weekday === "Saturday") {
                                  item.AdrCompsetChange = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let currValue = item?.AdrCompset
                                    ? parseFloat(item.AdrCompset)
                                    : 0;
                                  lyValue = (
                                    (currValue * 100) /
                                    (100 + newValue)
                                  )?.toFixed(2);
                                  item.AdrCompsetLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current.Saturday.AdrCompsetChange =
                              e.target.value;
                            tableDataRef.current.Saturday.AdrCompsetLy =
                              lyValue;
                            setStrData(strDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current.Saturday?.AdrCompsetChange ? (
                        tableDataRef.current.Saturday?.AdrCompsetChange
                      ) : (
                        0
                      )}
                    </td>
                    <td className="border_right">
                      {tableDataRef.current.Saturday?.AdrCompsetLy
                        ? tableDataRef.current.Saturday?.AdrCompsetLy
                        : 0}
                    </td>
                    {/* <td className="fontBold">{calculateAvg("AdrCompset")}</td> */}
                    <td className="fontBold">{
                      tableDataRef.current.Total?.AdrCompset
                      ? parseFloat(tableDataRef.current.Total?.AdrCompset)?.toFixed(2)
                      : 0}</td>
                  </tr>
                  <tr className="shadowOnHover">
                    <td className="border_right">Index (MPI)</td>
                    <td
                      onDoubleClick={() => {
                        setClickedTr({
                          mainRow: "adr",
                          subRow: "index",
                          field: "curr",
                          day: "sunday",
                        });
                      }}
                    >
                      {clickedTr.mainRow === "adr" &&
                      clickedTr.subRow === "index" &&
                      clickedTr.field === "curr" &&
                      clickedTr.day === "sunday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current.Sunday?.AdrIndex}
                          onChange={(e) => {
                            let lyValue = "";
                            strDataRef.current = strDataRef.current?.map(
                              (item, index) => {
                                if (item?.Weekday === "Sunday") {
                                  item.AdrIndex = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let changeValue = item?.AdrIndexChange
                                    ? parseFloat(item.AdrIndexChange)
                                    : 0;
                                  lyValue = (
                                    (newValue * 100) /
                                    (100 + changeValue)
                                  )?.toFixed(2);
                                  item.AdrIndexLy = lyValue;
                                }
                                return item;
                              }
                            );
                            calculateAvg("AdrIndex")
                            tableDataRef.current.Sunday.AdrIndex =
                              e.target.value;
                            tableDataRef.current.Sunday.AdrIndexLy = lyValue;
                            setStrData(strDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current.Sunday?.AdrIndex ? (
                        tableDataRef.current.Sunday?.AdrIndex
                      ) : (
                        0
                      )}
                    </td>
                    <td
                      onDoubleClick={() => {
                        setClickedTr({
                          mainRow: "adr",
                          subRow: "index",
                          field: "change",
                          day: "sunday",
                        });
                      }}
                    >
                      {clickedTr.mainRow === "adr" &&
                      clickedTr.subRow === "index" &&
                      clickedTr.field === "change" &&
                      clickedTr.day === "sunday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current.Sunday?.AdrIndexChange}
                          onChange={(e) => {
                            let lyValue = "";
                            strDataRef.current = strDataRef.current?.map(
                              (item, index) => {
                                if (item?.Weekday === "Sunday") {
                                  item.AdrIndexChange = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let currValue = item?.AdrIndex
                                    ? parseFloat(item.AdrIndex)
                                    : 0;
                                  lyValue = (
                                    (currValue * 100) /
                                    (100 + newValue)
                                  )?.toFixed(2);
                                  item.AdrIndexLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current.Sunday.AdrIndexChange =
                              e.target.value;
                            tableDataRef.current.Sunday.AdrIndexLy = lyValue;
                            setStrData(strDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current.Sunday?.AdrIndexChange ? (
                        tableDataRef.current.Sunday?.AdrIndexChange
                      ) : (
                        0
                      )}
                    </td>
                    <td className="border_right">
                      {tableDataRef.current.Sunday?.AdrIndexLy
                        ? tableDataRef.current.Sunday?.AdrIndexLy
                        : 0}
                    </td>

                    <td
                      onDoubleClick={() => {
                        setClickedTr({
                          mainRow: "adr",
                          subRow: "index",
                          field: "curr",
                          day: "monday",
                        });
                      }}
                    >
                      {clickedTr.mainRow === "adr" &&
                      clickedTr.subRow === "index" &&
                      clickedTr.field === "curr" &&
                      clickedTr.day === "monday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current.Monday?.AdrIndex}
                          onChange={(e) => {
                            let lyValue = "";
                            strDataRef.current = strDataRef.current?.map(
                              (item, index) => {
                                if (item?.Weekday === "Monday") {
                                  item.AdrIndex = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let changeValue = item?.AdrIndexChange
                                    ? parseFloat(item.AdrIndexChange)
                                    : 0;
                                  lyValue = (
                                    (newValue * 100) /
                                    (100 + changeValue)
                                  )?.toFixed(2);
                                  item.AdrIndexLy = lyValue;
                                }
                                return item;
                              }
                            );
                            calculateAvg("AdrIndex")
                            tableDataRef.current.Monday.AdrIndex =
                              e.target.value;
                            tableDataRef.current.Monday.AdrIndexLy = lyValue;
                            setStrData(strDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current.Monday?.AdrIndex ? (
                        tableDataRef.current.Monday?.AdrIndex
                      ) : (
                        0
                      )}
                    </td>
                    <td
                      onDoubleClick={() => {
                        setClickedTr({
                          mainRow: "adr",
                          subRow: "index",
                          field: "change",
                          day: "monday",
                        });
                      }}
                    >
                      {clickedTr.mainRow === "adr" &&
                      clickedTr.subRow === "index" &&
                      clickedTr.field === "change" &&
                      clickedTr.day === "monday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current.Monday?.AdrIndexChange}
                          onChange={(e) => {
                            let lyValue = "";
                            strDataRef.current = strDataRef.current?.map(
                              (item, index) => {
                                if (item?.Weekday === "Monday") {
                                  item.AdrIndexChange = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let currValue = item?.AdrIndex
                                    ? parseFloat(item.AdrIndex)
                                    : 0;
                                  lyValue = (
                                    (currValue * 100) /
                                    (100 + newValue)
                                  )?.toFixed(2);
                                  item.AdrIndexLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current.Monday.AdrIndexChange =
                              e.target.value;
                            tableDataRef.current.Monday.AdrIndexLy = lyValue;
                            setStrData(strDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current.Monday?.AdrIndexChange ? (
                        tableDataRef.current.Monday?.AdrIndexChange
                      ) : (
                        0
                      )}
                    </td>
                    <td className="border_right">
                      {tableDataRef.current.Monday?.AdrIndexLy
                        ? tableDataRef.current.Monday?.AdrIndexLy
                        : 0}
                    </td>

                    <td
                      onDoubleClick={() => {
                        setClickedTr({
                          mainRow: "adr",
                          subRow: "index",
                          field: "curr",
                          day: "tuesday",
                        });
                      }}
                    >
                      {clickedTr.mainRow === "adr" &&
                      clickedTr.subRow === "index" &&
                      clickedTr.field === "curr" &&
                      clickedTr.day === "tuesday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current.Tuesday?.AdrIndex}
                          onChange={(e) => {
                            let lyValue = "";
                            strDataRef.current = strDataRef.current?.map(
                              (item, index) => {
                                if (item?.Weekday === "Tuesday") {
                                  item.AdrIndex = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let changeValue = item?.AdrIndexChange
                                    ? parseFloat(item.AdrIndexChange)
                                    : 0;
                                  lyValue = (
                                    (newValue * 100) /
                                    (100 + changeValue)
                                  )?.toFixed(2);
                                  item.AdrIndexLy = lyValue;
                                }
                                return item;
                              }
                            );
                            calculateAvg("AdrIndex")
                            tableDataRef.current.Tuesday.AdrIndex =
                              e.target.value;
                            tableDataRef.current.Tuesday.AdrIndexLy = lyValue;
                            setStrData(strDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current.Tuesday?.AdrIndex ? (
                        tableDataRef.current.Tuesday?.AdrIndex
                      ) : (
                        0
                      )}
                    </td>
                    <td
                      onDoubleClick={() => {
                        setClickedTr({
                          mainRow: "adr",
                          subRow: "index",
                          field: "change",
                          day: "tuesday",
                        });
                      }}
                    >
                      {clickedTr.mainRow === "adr" &&
                      clickedTr.subRow === "index" &&
                      clickedTr.field === "change" &&
                      clickedTr.day === "tuesday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current.Tuesday?.AdrIndexChange}
                          onChange={(e) => {
                            let lyValue = "";
                            strDataRef.current = strDataRef.current?.map(
                              (item, index) => {
                                if (item?.Weekday === "Tuesday") {
                                  item.AdrIndexChange = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let currValue = item?.AdrIndex
                                    ? parseFloat(item.AdrIndex)
                                    : 0;
                                  lyValue = (
                                    (currValue * 100) /
                                    (100 + newValue)
                                  )?.toFixed(2);
                                  item.AdrIndexLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current.Tuesday.AdrIndexChange =
                              e.target.value;
                            tableDataRef.current.Tuesday.AdrIndexLy = lyValue;
                            setStrData(strDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current.Tuesday?.AdrIndexChange ? (
                        tableDataRef.current.Tuesday?.AdrIndexChange
                      ) : (
                        0
                      )}
                    </td>
                    <td className="border_right">
                      {tableDataRef.current.Tuesday?.AdrIndexLy
                        ? tableDataRef.current.Tuesday?.AdrIndexLy
                        : 0}
                    </td>

                    <td
                      onDoubleClick={() => {
                        setClickedTr({
                          mainRow: "adr",
                          subRow: "index",
                          field: "curr",
                          day: "wednesday",
                        });
                      }}
                    >
                      {clickedTr.mainRow === "adr" &&
                      clickedTr.subRow === "index" &&
                      clickedTr.field === "curr" &&
                      clickedTr.day === "wednesday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current.Wednesday?.AdrIndex}
                          onChange={(e) => {
                            let lyValue = "";
                            strDataRef.current = strDataRef.current?.map(
                              (item, index) => {
                                if (item?.Weekday === "Wednesday") {
                                  item.AdrIndex = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let changeValue = item?.AdrIndexChange
                                    ? parseFloat(item.AdrIndexChange)
                                    : 0;
                                  lyValue = (
                                    (newValue * 100) /
                                    (100 + changeValue)
                                  )?.toFixed(2);
                                  item.AdrIndexLy = lyValue;
                                }
                                return item;
                              }
                            );
                            calculateAvg("AdrIndex")
                            tableDataRef.current.Wednesday.AdrIndex =
                              e.target.value;
                            tableDataRef.current.Wednesday.AdrIndexLy = lyValue;
                            setStrData(strDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current.Wednesday?.AdrIndex ? (
                        tableDataRef.current.Wednesday?.AdrIndex
                      ) : (
                        0
                      )}
                    </td>
                    <td
                      onDoubleClick={() => {
                        setClickedTr({
                          mainRow: "adr",
                          subRow: "index",
                          field: "change",
                          day: "wednesday",
                        });
                      }}
                    >
                      {clickedTr.mainRow === "adr" &&
                      clickedTr.subRow === "index" &&
                      clickedTr.field === "change" &&
                      clickedTr.day === "wednesday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current.Wednesday?.AdrIndexChange}
                          onChange={(e) => {
                            let lyValue = "";
                            strDataRef.current = strDataRef.current?.map(
                              (item, index) => {
                                if (item?.Weekday === "Wednesday") {
                                  item.AdrIndexChange = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let currValue = item?.AdrIndex
                                    ? parseFloat(item.AdrIndex)
                                    : 0;
                                  lyValue = (
                                    (currValue * 100) /
                                    (100 + newValue)
                                  )?.toFixed(2);
                                  item.AdrIndexLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current.Wednesday.AdrIndexChange =
                              e.target.value;
                            tableDataRef.current.Wednesday.AdrIndexLy = lyValue;
                            setStrData(strDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current.Wednesday?.AdrIndexChange ? (
                        tableDataRef.current.Wednesday?.AdrIndexChange
                      ) : (
                        0
                      )}
                    </td>
                    <td className="border_right">
                      {tableDataRef.current.Wednesday?.AdrIndexLy
                        ? tableDataRef.current.Wednesday?.AdrIndexLy
                        : 0}
                    </td>

                    <td
                      onDoubleClick={() => {
                        setClickedTr({
                          mainRow: "adr",
                          subRow: "index",
                          field: "curr",
                          day: "thursday",
                        });
                      }}
                    >
                      {clickedTr.mainRow === "adr" &&
                      clickedTr.subRow === "index" &&
                      clickedTr.field === "curr" &&
                      clickedTr.day === "thursday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current.Thursday?.AdrIndex}
                          onChange={(e) => {
                            let lyValue = "";
                            strDataRef.current = strDataRef.current?.map(
                              (item, index) => {
                                if (item?.Weekday === "Thursday") {
                                  item.AdrIndex = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let changeValue = item?.AdrIndexChange
                                    ? parseFloat(item.AdrIndexChange)
                                    : 0;
                                  lyValue = (
                                    (newValue * 100) /
                                    (100 + changeValue)
                                  )?.toFixed(2);
                                  item.AdrIndexLy = lyValue;
                                }
                                return item;
                              }
                            );
                            calculateAvg("AdrIndex")
                            tableDataRef.current.Thursday.AdrIndex =
                              e.target.value;
                            tableDataRef.current.Thursday.AdrIndexLy = lyValue;
                            setStrData(strDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current.Thursday?.AdrIndex ? (
                        tableDataRef.current.Thursday?.AdrIndex
                      ) : (
                        0
                      )}
                    </td>
                    <td
                      onDoubleClick={() => {
                        setClickedTr({
                          mainRow: "adr",
                          subRow: "index",
                          field: "change",
                          day: "thursday",
                        });
                      }}
                    >
                      {clickedTr.mainRow === "adr" &&
                      clickedTr.subRow === "index" &&
                      clickedTr.field === "change" &&
                      clickedTr.day === "thursday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current.Thursday?.AdrIndexChange}
                          onChange={(e) => {
                            let lyValue = "";
                            strDataRef.current = strDataRef.current?.map(
                              (item, index) => {
                                if (item?.Weekday === "Thursday") {
                                  item.AdrIndexChange = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let currValue = item?.AdrIndex
                                    ? parseFloat(item.AdrIndex)
                                    : 0;
                                  lyValue = (
                                    (currValue * 100) /
                                    (100 + newValue)
                                  )?.toFixed(2);
                                  item.AdrIndexLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current.Thursday.AdrIndexChange =
                              e.target.value;
                            tableDataRef.current.Thursday.AdrIndexLy = lyValue;
                            setStrData(strDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current.Thursday?.AdrIndexChange ? (
                        tableDataRef.current.Thursday?.AdrIndexChange
                      ) : (
                        0
                      )}
                    </td>
                    <td className="border_right">
                      {tableDataRef.current.Thursday?.AdrIndexLy
                        ? tableDataRef.current.Thursday?.AdrIndexLy
                        : 0}
                    </td>

                    <td
                      onDoubleClick={() => {
                        setClickedTr({
                          mainRow: "adr",
                          subRow: "index",
                          field: "curr",
                          day: "friday",
                        });
                      }}
                    >
                      {clickedTr.mainRow === "adr" &&
                      clickedTr.subRow === "index" &&
                      clickedTr.field === "curr" &&
                      clickedTr.day === "friday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current.Friday?.AdrIndex}
                          onChange={(e) => {
                            let lyValue = "";
                            strDataRef.current = strDataRef.current?.map(
                              (item, index) => {
                                if (item?.Weekday === "Friday") {
                                  item.AdrIndex = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let changeValue = item?.AdrIndexChange
                                    ? parseFloat(item.AdrIndexChange)
                                    : 0;
                                  lyValue = (
                                    (newValue * 100) /
                                    (100 + changeValue)
                                  )?.toFixed(2);
                                  item.AdrIndexLy = lyValue;
                                }
                                return item;
                              }
                            );
                            calculateAvg("AdrIndex")
                            tableDataRef.current.Friday.AdrIndex =
                              e.target.value;
                            tableDataRef.current.Friday.AdrIndexLy = lyValue;
                            setStrData(strDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current.Friday?.AdrIndex ? (
                        tableDataRef.current.Friday?.AdrIndex
                      ) : (
                        0
                      )}
                    </td>
                    <td
                      onDoubleClick={() => {
                        setClickedTr({
                          mainRow: "adr",
                          subRow: "index",
                          field: "change",
                          day: "friday",
                        });
                      }}
                    >
                      {clickedTr.mainRow === "adr" &&
                      clickedTr.subRow === "index" &&
                      clickedTr.field === "change" &&
                      clickedTr.day === "friday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current.Friday?.AdrIndexChange}
                          onChange={(e) => {
                            let lyValue = "";
                            strDataRef.current = strDataRef.current?.map(
                              (item, index) => {
                                if (item?.Weekday === "Friday") {
                                  item.AdrIndexChange = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let currValue = item?.AdrIndex
                                    ? parseFloat(item.AdrIndex)
                                    : 0;
                                  lyValue = (
                                    (currValue * 100) /
                                    (100 + newValue)
                                  )?.toFixed(2);
                                  item.AdrIndexLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current.Friday.AdrIndexChange =
                              e.target.value;
                            tableDataRef.current.Friday.AdrIndexLy = lyValue;
                            setStrData(strDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current.Friday?.AdrIndexChange ? (
                        tableDataRef.current.Friday?.AdrIndexChange
                      ) : (
                        0
                      )}
                    </td>
                    <td className="border_right">
                      {tableDataRef.current.Friday?.AdrIndexLy
                        ? tableDataRef.current.Friday?.AdrIndexLy
                        : 0}
                    </td>

                    <td
                      onDoubleClick={() => {
                        setClickedTr({
                          mainRow: "adr",
                          subRow: "index",
                          field: "curr",
                          day: "saturday",
                        });
                      }}
                    >
                      {clickedTr.mainRow === "adr" &&
                      clickedTr.subRow === "index" &&
                      clickedTr.field === "curr" &&
                      clickedTr.day === "saturday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current.Saturday?.AdrIndex}
                          onChange={(e) => {
                            let lyValue = "";
                            strDataRef.current = strDataRef.current?.map(
                              (item, index) => {
                                if (item?.Weekday === "Saturday") {
                                  item.AdrIndex = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let changeValue = item?.AdrIndexChange
                                    ? parseFloat(item.AdrIndexChange)
                                    : 0;
                                  lyValue = (
                                    (newValue * 100) /
                                    (100 + changeValue)
                                  )?.toFixed(2);
                                  item.AdrIndexLy = lyValue;
                                }
                                return item;
                              }
                            );
                            calculateAvg("AdrIndex")
                            tableDataRef.current.Saturday.AdrIndex =
                              e.target.value;
                            tableDataRef.current.Saturday.AdrIndexLy = lyValue;
                            setStrData(strDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current.Saturday?.AdrIndex ? (
                        tableDataRef.current.Saturday?.AdrIndex
                      ) : (
                        0
                      )}
                    </td>
                    <td
                      onDoubleClick={() => {
                        setClickedTr({
                          mainRow: "adr",
                          subRow: "index",
                          field: "change",
                          day: "saturday",
                        });
                      }}
                    >
                      {clickedTr.mainRow === "adr" &&
                      clickedTr.subRow === "index" &&
                      clickedTr.field === "change" &&
                      clickedTr.day === "saturday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current.Saturday?.AdrIndexChange}
                          onChange={(e) => {
                            let lyValue = "";
                            strDataRef.current = strDataRef.current?.map(
                              (item, index) => {
                                if (item?.Weekday === "Saturday") {
                                  item.AdrIndexChange = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let currValue = item?.AdrIndex
                                    ? parseFloat(item.AdrIndex)
                                    : 0;
                                  lyValue = (
                                    (currValue * 100) /
                                    (100 + newValue)
                                  )?.toFixed(2);
                                  item.AdrIndexLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current.Saturday.AdrIndexChange =
                              e.target.value;
                            tableDataRef.current.Saturday.AdrIndexLy = lyValue;
                            setStrData(strDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current.Saturday?.AdrIndexChange ? (
                        tableDataRef.current.Saturday?.AdrIndexChange
                      ) : (
                        0
                      )}
                    </td>
                    <td className="border_right">
                      {tableDataRef.current.Saturday?.AdrIndexLy
                        ? tableDataRef.current.Saturday?.AdrIndexLy
                        : 0}
                    </td>
                    {/* <td className="fontBold">{calculateAvg("AdrIndex")}</td> */}
                    <td className="fontBold">{
                      tableDataRef.current.Total?.AdrIndex
                      ? parseFloat(tableDataRef.current.Total?.AdrIndex)?.toFixed(2)
                      : 0}</td>
                  </tr>

                  {/* REVPAR */}
                  <tr className="shadowOnHover">
                    <td rowSpan={3} className="th-with-rowspan border_right">
                      RevPAR
                    </td>
                    <td className="border_right">My Property</td>
                    <td
                      onDoubleClick={() => {
                        setClickedTr({
                          mainRow: "rev",
                          subRow: "myProperty",
                          field: "curr",
                          day: "sunday",
                        });
                      }}
                    >
                      {clickedTr.mainRow === "rev" &&
                      clickedTr.subRow === "myProperty" &&
                      clickedTr.field === "curr" &&
                      clickedTr.day === "sunday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current.Sunday?.RevparMyProperty}
                          onChange={(e) => {
                            let lyValue = "";
                            strDataRef.current = strDataRef.current?.map(
                              (item, index) => {
                                if (item?.Weekday === "Sunday") {
                                  item.RevparMyProperty = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let changeValue = item?.RevparMyPropertyChange
                                    ? parseFloat(item.RevparMyPropertyChange)
                                    : 0;
                                  lyValue = (
                                    (newValue * 100) /
                                    (100 + changeValue)
                                  )?.toFixed(2);
                                  item.RevparMyPropertyLy = lyValue;
                                }
                                return item;
                              }
                            );
                            calculateAvg("RevparMyProperty")
                            tableDataRef.current.Sunday.RevparMyProperty =
                              e.target.value;
                            tableDataRef.current.Sunday.RevparMyPropertyLy =
                              lyValue;
                            setStrData(strDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current.Sunday?.RevparMyProperty ? (
                        tableDataRef.current.Sunday?.RevparMyProperty
                      ) : (
                        0
                      )}
                    </td>
                    <td
                      onDoubleClick={() => {
                        setClickedTr({
                          mainRow: "rev",
                          subRow: "myProperty",
                          field: "change",
                          day: "sunday",
                        });
                      }}
                    >
                      {clickedTr.mainRow === "rev" &&
                      clickedTr.subRow === "myProperty" &&
                      clickedTr.field === "change" &&
                      clickedTr.day === "sunday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={
                            tableDataRef.current.Sunday?.RevparMyPropertyChange
                          }
                          onChange={(e) => {
                            let lyValue = "";
                            strDataRef.current = strDataRef.current?.map(
                              (item, index) => {
                                if (item?.Weekday === "Sunday") {
                                  item.RevparMyPropertyChange = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let currValue = item?.RevparMyProperty
                                    ? parseFloat(item.RevparMyProperty)
                                    : 0;
                                  lyValue = (
                                    (currValue * 100) /
                                    (100 + newValue)
                                  )?.toFixed(2);
                                  item.RevparMyPropertyLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current.Sunday.RevparMyPropertyChange =
                              e.target.value;
                            tableDataRef.current.Sunday.RevparMyPropertyLy =
                              lyValue;
                            setStrData(strDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current.Sunday
                          ?.RevparMyPropertyChange ? (
                        tableDataRef.current.Sunday?.RevparMyPropertyChange
                      ) : (
                        0
                      )}
                    </td>
                    <td className="border_right">
                      {tableDataRef.current.Sunday?.RevparMyPropertyLy
                        ? tableDataRef.current.Sunday?.RevparMyPropertyLy
                        : 0}
                    </td>

                    <td
                      onDoubleClick={() => {
                        setClickedTr({
                          mainRow: "rev",
                          subRow: "myProperty",
                          field: "curr",
                          day: "monday",
                        });
                      }}
                    >
                      {clickedTr.mainRow === "rev" &&
                      clickedTr.subRow === "myProperty" &&
                      clickedTr.field === "curr" &&
                      clickedTr.day === "monday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current.Monday?.RevparMyProperty}
                          onChange={(e) => {
                            let lyValue = "";
                            strDataRef.current = strDataRef.current?.map(
                              (item, index) => {
                                if (item?.Weekday === "Monday") {
                                  item.RevparMyProperty = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let changeValue = item?.RevparMyPropertyChange
                                    ? parseFloat(item.RevparMyPropertyChange)
                                    : 0;
                                  lyValue = (
                                    (newValue * 100) /
                                    (100 + changeValue)
                                  )?.toFixed(2);
                                  item.RevparMyPropertyLy = lyValue;
                                }
                                return item;
                              }
                            );
                            calculateAvg("RevparMyProperty")
                            tableDataRef.current.Monday.RevparMyProperty =
                              e.target.value;
                            tableDataRef.current.Monday.RevparMyPropertyLy =
                              lyValue;
                            setStrData(strDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current.Monday?.RevparMyProperty ? (
                        tableDataRef.current.Monday?.RevparMyProperty
                      ) : (
                        0
                      )}
                    </td>
                    <td
                      onDoubleClick={() => {
                        setClickedTr({
                          mainRow: "rev",
                          subRow: "myProperty",
                          field: "change",
                          day: "monday",
                        });
                      }}
                    >
                      {clickedTr.mainRow === "rev" &&
                      clickedTr.subRow === "myProperty" &&
                      clickedTr.field === "change" &&
                      clickedTr.day === "monday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={
                            tableDataRef.current.Monday?.RevparMyPropertyChange
                          }
                          onChange={(e) => {
                            let lyValue = "";
                            strDataRef.current = strDataRef.current?.map(
                              (item, index) => {
                                if (item?.Weekday === "Monday") {
                                  item.RevparMyPropertyChange = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let currValue = item?.RevparMyProperty
                                    ? parseFloat(item.RevparMyProperty)
                                    : 0;
                                  lyValue = (
                                    (currValue * 100) /
                                    (100 + newValue)
                                  )?.toFixed(2);
                                  item.RevparMyPropertyLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current.Monday.RevparMyPropertyChange =
                              e.target.value;
                            tableDataRef.current.Monday.RevparMyPropertyLy =
                              lyValue;
                            setStrData(strDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current.Monday
                          ?.RevparMyPropertyChange ? (
                        tableDataRef.current.Monday?.RevparMyPropertyChange
                      ) : (
                        0
                      )}
                    </td>
                    <td className="border_right">
                      {tableDataRef.current.Monday?.RevparMyPropertyLy
                        ? tableDataRef.current.Monday?.RevparMyPropertyLy
                        : 0}
                    </td>

                    <td
                      onDoubleClick={() => {
                        setClickedTr({
                          mainRow: "rev",
                          subRow: "myProperty",
                          field: "curr",
                          day: "tuseday",
                        });
                      }}
                    >
                      {clickedTr.mainRow === "rev" &&
                      clickedTr.subRow === "myProperty" &&
                      clickedTr.field === "curr" &&
                      clickedTr.day === "tuseday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current.Tuesday?.RevparMyProperty}
                          onChange={(e) => {
                            let lyValue = "";
                            strDataRef.current = strDataRef.current?.map(
                              (item, index) => {
                                if (item?.Weekday === "Tuesday") {
                                  item.RevparMyProperty = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let changeValue = item?.RevparMyPropertyChange
                                    ? parseFloat(item.RevparMyPropertyChange)
                                    : 0;
                                  lyValue = (
                                    (newValue * 100) /
                                    (100 + changeValue)
                                  )?.toFixed(2);
                                  item.RevparMyPropertyLy = lyValue;
                                }
                                return item;
                              }
                            );
                            calculateAvg("RevparMyProperty")
                            tableDataRef.current.Tuesday.RevparMyProperty =
                              e.target.value;
                            tableDataRef.current.Tuesday.RevparMyPropertyLy =
                              lyValue;
                            setStrData(strDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current.Tuesday?.RevparMyProperty ? (
                        tableDataRef.current.Tuesday?.RevparMyProperty
                      ) : (
                        0
                      )}
                    </td>
                    <td
                      onDoubleClick={() => {
                        setClickedTr({
                          mainRow: "rev",
                          subRow: "myProperty",
                          field: "change",
                          day: "tuesday",
                        });
                      }}
                    >
                      {clickedTr.mainRow === "rev" &&
                      clickedTr.subRow === "myProperty" &&
                      clickedTr.field === "change" &&
                      clickedTr.day === "tuesday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={
                            tableDataRef.current.Tuesday?.RevparMyPropertyChange
                          }
                          onChange={(e) => {
                            let lyValue = "";
                            strDataRef.current = strDataRef.current?.map(
                              (item, index) => {
                                if (item?.Weekday === "Tuesday") {
                                  item.RevparMyPropertyChange = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let currValue = item?.RevparMyProperty
                                    ? parseFloat(item.RevparMyProperty)
                                    : 0;
                                  lyValue = (
                                    (currValue * 100) /
                                    (100 + newValue)
                                  )?.toFixed(2);
                                  item.RevparMyPropertyLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current.Tuesday.RevparMyPropertyChange =
                              e.target.value;
                            tableDataRef.current.Tuesday.RevparMyPropertyLy =
                              lyValue;
                            setStrData(strDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current.Tuesday
                          ?.RevparMyPropertyChange ? (
                        tableDataRef.current.Tuesday?.RevparMyPropertyChange
                      ) : (
                        0
                      )}
                    </td>
                    <td className="border_right">
                      {tableDataRef.current.Tuesday?.RevparMyPropertyLy
                        ? tableDataRef.current.Tuesday?.RevparMyPropertyLy
                        : 0}
                    </td>

                    <td
                      onDoubleClick={() => {
                        setClickedTr({
                          mainRow: "rev",
                          subRow: "myProperty",
                          field: "curr",
                          day: "wednesday",
                        });
                      }}
                    >
                      {clickedTr.mainRow === "rev" &&
                      clickedTr.subRow === "myProperty" &&
                      clickedTr.field === "curr" &&
                      clickedTr.day === "wednesday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={
                            tableDataRef.current.Wednesday?.RevparMyProperty
                          }
                          onChange={(e) => {
                            let lyValue = "";
                            strDataRef.current = strDataRef.current?.map(
                              (item, index) => {
                                if (item?.Weekday === "Wednesday") {
                                  item.RevparMyProperty = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let changeValue = item?.RevparMyPropertyChange
                                    ? parseFloat(item.RevparMyPropertyChange)
                                    : 0;
                                  lyValue = (
                                    (newValue * 100) /
                                    (100 + changeValue)
                                  )?.toFixed(2);
                                  item.RevparMyPropertyLy = lyValue;
                                }
                                return item;
                              }
                            );
                            calculateAvg("RevparMyProperty")
                            tableDataRef.current.Wednesday.RevparMyProperty =
                              e.target.value;
                            tableDataRef.current.Wednesday.RevparMyPropertyLy =
                              lyValue;
                            setStrData(strDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current.Wednesday?.RevparMyProperty ? (
                        tableDataRef.current.Wednesday?.RevparMyProperty
                      ) : (
                        0
                      )}
                    </td>
                    <td
                      onDoubleClick={() => {
                        setClickedTr({
                          mainRow: "rev",
                          subRow: "myProperty",
                          field: "change",
                          day: "wednesday",
                        });
                      }}
                    >
                      {clickedTr.mainRow === "rev" &&
                      clickedTr.subRow === "myProperty" &&
                      clickedTr.field === "change" &&
                      clickedTr.day === "wednesday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={
                            tableDataRef.current.Wednesday
                              ?.RevparMyPropertyChange
                          }
                          onChange={(e) => {
                            let lyValue = "";
                            strDataRef.current = strDataRef.current?.map(
                              (item, index) => {
                                if (item?.Weekday === "Wednesday") {
                                  item.RevparMyPropertyChange = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let currValue = item?.RevparMyProperty
                                    ? parseFloat(item.RevparMyProperty)
                                    : 0;
                                  lyValue = (
                                    (currValue * 100) /
                                    (100 + newValue)
                                  )?.toFixed(2);
                                  item.RevparMyPropertyLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current.Wednesday.RevparMyPropertyChange =
                              e.target.value;
                            tableDataRef.current.Wednesday.RevparMyPropertyLy =
                              lyValue;
                            setStrData(strDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current.Wednesday
                          ?.RevparMyPropertyChange ? (
                        tableDataRef.current.Wednesday?.RevparMyPropertyChange
                      ) : (
                        0
                      )}
                    </td>
                    <td className="border_right">
                      {tableDataRef.current.Wednesday?.RevparMyPropertyLy
                        ? tableDataRef.current.Wednesday?.RevparMyPropertyLy
                        : 0}
                    </td>

                    <td
                      onDoubleClick={() => {
                        setClickedTr({
                          mainRow: "rev",
                          subRow: "myProperty",
                          field: "curr",
                          day: "thursday",
                        });
                      }}
                    >
                      {clickedTr.mainRow === "rev" &&
                      clickedTr.subRow === "myProperty" &&
                      clickedTr.field === "curr" &&
                      clickedTr.day === "thursday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={
                            tableDataRef.current.Thursday?.RevparMyProperty
                          }
                          onChange={(e) => {
                            let lyValue = "";
                            strDataRef.current = strDataRef.current?.map(
                              (item, index) => {
                                if (item?.Weekday === "Thursday") {
                                  item.RevparMyProperty = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let changeValue = item?.RevparMyPropertyChange
                                    ? parseFloat(item.RevparMyPropertyChange)
                                    : 0;
                                  lyValue = (
                                    (newValue * 100) /
                                    (100 + changeValue)
                                  )?.toFixed(2);
                                  item.RevparMyPropertyLy = lyValue;
                                }
                                return item;
                              }
                            );
                            calculateAvg("RevparMyProperty")
                            tableDataRef.current.Thursday.RevparMyProperty =
                              e.target.value;
                            tableDataRef.current.Thursday.RevparMyPropertyLy =
                              lyValue;
                            setStrData(strDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current.Thursday?.RevparMyProperty ? (
                        tableDataRef.current.Thursday?.RevparMyProperty
                      ) : (
                        0
                      )}
                    </td>
                    <td
                      onDoubleClick={() => {
                        setClickedTr({
                          mainRow: "rev",
                          subRow: "myProperty",
                          field: "change",
                          day: "thursday",
                        });
                      }}
                    >
                      {clickedTr.mainRow === "rev" &&
                      clickedTr.subRow === "myProperty" &&
                      clickedTr.field === "change" &&
                      clickedTr.day === "thursday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={
                            tableDataRef.current.Thursday
                              ?.RevparMyPropertyChange
                          }
                          onChange={(e) => {
                            let lyValue = "";
                            strDataRef.current = strDataRef.current?.map(
                              (item, index) => {
                                if (item?.Weekday === "Thursday") {
                                  item.RevparMyPropertyChange = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let currValue = item?.RevparMyProperty
                                    ? parseFloat(item.RevparMyProperty)
                                    : 0;
                                  lyValue = (
                                    (currValue * 100) /
                                    (100 + newValue)
                                  )?.toFixed(2);
                                  item.RevparMyPropertyLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current.Thursday.RevparMyPropertyChange =
                              e.target.value;
                            tableDataRef.current.Thursday.RevparMyPropertyLy =
                              lyValue;
                            setStrData(strDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current.Thursday
                          ?.RevparMyPropertyChange ? (
                        tableDataRef.current.Thursday?.RevparMyPropertyChange
                      ) : (
                        0
                      )}
                    </td>
                    <td className="border_right">
                      {tableDataRef.current.Thursday?.RevparMyPropertyLy
                        ? tableDataRef.current.Thursday?.RevparMyPropertyLy
                        : 0}
                    </td>

                    <td
                      onDoubleClick={() => {
                        setClickedTr({
                          mainRow: "rev",
                          subRow: "myProperty",
                          field: "curr",
                          day: "friday",
                        });
                      }}
                    >
                      {clickedTr.mainRow === "rev" &&
                      clickedTr.subRow === "myProperty" &&
                      clickedTr.field === "curr" &&
                      clickedTr.day === "friday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current.Friday?.RevparMyProperty}
                          onChange={(e) => {
                            let lyValue = "";
                            strDataRef.current = strDataRef.current?.map(
                              (item, index) => {
                                if (item?.Weekday === "Friday") {
                                  item.RevparMyProperty = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let changeValue = item?.RevparMyPropertyChange
                                    ? parseFloat(item.RevparMyPropertyChange)
                                    : 0;
                                  lyValue = (
                                    (newValue * 100) /
                                    (100 + changeValue)
                                  )?.toFixed(2);
                                  item.RevparMyPropertyLy = lyValue;
                                }
                                return item;
                              }
                            );
                            calculateAvg("RevparMyProperty")
                            tableDataRef.current.Friday.RevparMyProperty =
                              e.target.value;
                            tableDataRef.current.Friday.RevparMyPropertyLy =
                              lyValue;
                            setStrData(strDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current.Friday?.RevparMyProperty ? (
                        tableDataRef.current.Friday?.RevparMyProperty
                      ) : (
                        0
                      )}
                    </td>
                    <td
                      onDoubleClick={() => {
                        setClickedTr({
                          mainRow: "rev",
                          subRow: "myProperty",
                          field: "change",
                          day: "friday",
                        });
                      }}
                    >
                      {clickedTr.mainRow === "rev" &&
                      clickedTr.subRow === "myProperty" &&
                      clickedTr.field === "change" &&
                      clickedTr.day === "friday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={
                            tableDataRef.current.Friday?.RevparMyPropertyChange
                          }
                          onChange={(e) => {
                            let lyValue = "";
                            strDataRef.current = strDataRef.current?.map(
                              (item, index) => {
                                if (item?.Weekday === "Friday") {
                                  item.RevparMyPropertyChange = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let currValue = item?.RevparMyProperty
                                    ? parseFloat(item.RevparMyProperty)
                                    : 0;
                                  lyValue = (
                                    (currValue * 100) /
                                    (100 + newValue)
                                  )?.toFixed(2);
                                  item.RevparMyPropertyLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current.Friday.RevparMyPropertyChange =
                              e.target.value;
                            tableDataRef.current.Friday.RevparMyPropertyLy =
                              lyValue;
                            setStrData(strDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current.Friday
                          ?.RevparMyPropertyChange ? (
                        tableDataRef.current.Friday?.RevparMyPropertyChange
                      ) : (
                        0
                      )}
                    </td>
                    <td className="border_right">
                      {tableDataRef.current.Friday?.RevparMyPropertyLy
                        ? tableDataRef.current.Friday?.RevparMyPropertyLy
                        : 0}
                    </td>

                    <td
                      onDoubleClick={() => {
                        setClickedTr({
                          mainRow: "rev",
                          subRow: "myProperty",
                          field: "curr",
                          day: "saturday",
                        });
                      }}
                    >
                      {clickedTr.mainRow === "rev" &&
                      clickedTr.subRow === "myProperty" &&
                      clickedTr.field === "curr" &&
                      clickedTr.day === "saturday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={
                            tableDataRef.current.Saturday?.RevparMyProperty
                          }
                          onChange={(e) => {
                            let lyValue = "";
                            strDataRef.current = strDataRef.current?.map(
                              (item, index) => {
                                if (item?.Weekday === "Saturday") {
                                  item.RevparMyProperty = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let changeValue = item?.RevparMyPropertyChange
                                    ? parseFloat(item.RevparMyPropertyChange)
                                    : 0;
                                  lyValue = (
                                    (newValue * 100) /
                                    (100 + changeValue)
                                  )?.toFixed(2);
                                  item.RevparMyPropertyLy = lyValue;
                                }
                                return item;
                              }
                            );
                            calculateAvg("RevparMyProperty")
                            tableDataRef.current.Saturday.RevparMyProperty =
                              e.target.value;
                            tableDataRef.current.Saturday.RevparMyPropertyLy =
                              lyValue;
                            setStrData(strDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current.Saturday?.RevparMyProperty ? (
                        tableDataRef.current.Saturday?.RevparMyProperty
                      ) : (
                        0
                      )}
                    </td>
                    <td
                      onDoubleClick={() => {
                        setClickedTr({
                          mainRow: "rev",
                          subRow: "myProperty",
                          field: "change",
                          day: "saturday",
                        });
                      }}
                    >
                      {clickedTr.mainRow === "rev" &&
                      clickedTr.subRow === "myProperty" &&
                      clickedTr.field === "change" &&
                      clickedTr.day === "saturday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={
                            tableDataRef.current.Saturday
                              ?.RevparMyPropertyChange
                          }
                          onChange={(e) => {
                            let lyValue = "";
                            strDataRef.current = strDataRef.current?.map(
                              (item, index) => {
                                if (item?.Weekday === "Saturday") {
                                  item.RevparMyPropertyChange = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let currValue = item?.RevparMyProperty
                                    ? parseFloat(item.RevparMyProperty)
                                    : 0;
                                  lyValue = (
                                    (currValue * 100) /
                                    (100 + newValue)
                                  )?.toFixed(2);
                                  item.RevparMyPropertyLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current.Saturday.RevparMyPropertyChange =
                              e.target.value;
                            tableDataRef.current.Saturday.RevparMyPropertyLy =
                              lyValue;
                            setStrData(strDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current.Saturday
                          ?.RevparMyPropertyChange ? (
                        tableDataRef.current.Saturday?.RevparMyPropertyChange
                      ) : (
                        0
                      )}
                    </td>
                    <td className="border_right">
                      {tableDataRef.current.Saturday?.RevparMyPropertyLy
                        ? tableDataRef.current.Saturday?.RevparMyPropertyLy
                        : 0}
                    </td>
                    <td className="fontBold">
                      {/* {calculateAvg("RevparMyProperty")} */}
                      {
                      tableDataRef.current.Total?.RevparMyProperty
                      ? parseFloat(tableDataRef.current.Total?.RevparMyProperty)?.toFixed(2)
                      : 0}
                    </td>
                  </tr>
                  <tr className="shadowOnHover">
                    <td className="border_right">Comp Set</td>
                    <td
                      onDoubleClick={() => {
                        setClickedTr({
                          mainRow: "rev",
                          subRow: "compSet",
                          field: "curr",
                          day: "sunday",
                        });
                      }}
                    >
                      {clickedTr.mainRow === "rev" &&
                      clickedTr.subRow === "compSet" &&
                      clickedTr.field === "curr" &&
                      clickedTr.day === "sunday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current.Sunday?.RevparCompset}
                          onChange={(e) => {
                            let lyValue = "";
                            strDataRef.current = strDataRef.current?.map(
                              (item, index) => {
                                if (item?.Weekday === "Sunday") {
                                  item.RevparCompset = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let changeValue = item?.RevparCompsetChange
                                    ? parseFloat(item.RevparCompsetChange)
                                    : 0;
                                  lyValue = (
                                    (newValue * 100) /
                                    (100 + changeValue)
                                  )?.toFixed(2);
                                  item.RevparCompsetLy = lyValue;
                                }
                                return item;
                              }
                            );
                            calculateAvg("RevparCompset")
                            tableDataRef.current.Sunday.RevparCompset =
                              e.target.value;
                            tableDataRef.current.Sunday.RevparCompsetLy =
                              lyValue;
                            setStrData(strDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current.Sunday?.RevparCompset ? (
                        tableDataRef.current.Sunday?.RevparCompset
                      ) : (
                        0
                      )}
                    </td>
                    <td
                      onDoubleClick={() => {
                        setClickedTr({
                          mainRow: "rev",
                          subRow: "compSet",
                          field: "change",
                          day: "sunday",
                        });
                      }}
                    >
                      {clickedTr.mainRow === "rev" &&
                      clickedTr.subRow === "compSet" &&
                      clickedTr.field === "change" &&
                      clickedTr.day === "sunday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={
                            tableDataRef.current.Sunday?.RevparCompsetChange
                          }
                          onChange={(e) => {
                            let lyValue = "";
                            strDataRef.current = strDataRef.current?.map(
                              (item, index) => {
                                if (item?.Weekday === "Sunday") {
                                  item.RevparCompsetChange = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let currValue = item?.RevparCompset
                                    ? parseFloat(item.RevparCompset)
                                    : 0;
                                  lyValue = (
                                    (currValue * 100) /
                                    (100 + newValue)
                                  )?.toFixed(2);
                                  item.RevparCompsetLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current.Sunday.RevparCompsetChange =
                              e.target.value;
                            tableDataRef.current.Sunday.RevparCompsetLy =
                              lyValue;
                            setStrData(strDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current.Sunday?.RevparCompsetChange ? (
                        tableDataRef.current.Sunday?.RevparCompsetChange
                      ) : (
                        0
                      )}
                    </td>
                    <td className="border_right">
                      {tableDataRef.current.Sunday?.RevparCompsetLy
                        ? tableDataRef.current.Sunday?.RevparCompsetLy
                        : 0}
                    </td>

                    <td
                      onDoubleClick={() => {
                        setClickedTr({
                          mainRow: "rev",
                          subRow: "compSet",
                          field: "curr",
                          day: "monday",
                        });
                      }}
                    >
                      {clickedTr.mainRow === "rev" &&
                      clickedTr.subRow === "compSet" &&
                      clickedTr.field === "curr" &&
                      clickedTr.day === "monday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current.Monday?.RevparCompset}
                          onChange={(e) => {
                            let lyValue = "";
                            strDataRef.current = strDataRef.current?.map(
                              (item, index) => {
                                if (item?.Weekday === "Monday") {
                                  item.RevparCompset = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let changeValue = item?.RevparCompsetChange
                                    ? parseFloat(item.RevparCompsetChange)
                                    : 0;
                                  lyValue = (
                                    (newValue * 100) /
                                    (100 + changeValue)
                                  )?.toFixed(2);
                                  item.RevparCompsetLy = lyValue;
                                }
                                return item;
                              }
                            );
                            calculateAvg("RevparCompset")
                            tableDataRef.current.Monday.RevparCompset =
                              e.target.value;
                            tableDataRef.current.Monday.RevparCompsetLy =
                              lyValue;
                            setStrData(strDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current.Monday?.RevparCompset ? (
                        tableDataRef.current.Monday?.RevparCompset
                      ) : (
                        0
                      )}
                    </td>
                    <td
                      onDoubleClick={() => {
                        setClickedTr({
                          mainRow: "rev",
                          subRow: "compSet",
                          field: "change",
                          day: "monday",
                        });
                      }}
                    >
                      {clickedTr.mainRow === "rev" &&
                      clickedTr.subRow === "compSet" &&
                      clickedTr.field === "change" &&
                      clickedTr.day === "monday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={
                            tableDataRef.current.Monday?.RevparCompsetChange
                          }
                          onChange={(e) => {
                            let lyValue = "";
                            strDataRef.current = strDataRef.current?.map(
                              (item, index) => {
                                if (item?.Weekday === "Monday") {
                                  item.RevparCompsetChange = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let currValue = item?.RevparCompset
                                    ? parseFloat(item.RevparCompset)
                                    : 0;
                                  lyValue = (
                                    (currValue * 100) /
                                    (100 + newValue)
                                  )?.toFixed(2);
                                  item.RevparCompsetLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current.Monday.RevparCompsetChange =
                              e.target.value;
                            tableDataRef.current.Monday.RevparCompsetLy =
                              lyValue;
                            setStrData(strDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current.Monday?.RevparCompsetChange ? (
                        tableDataRef.current.Monday?.RevparCompsetChange
                      ) : (
                        0
                      )}
                    </td>
                    <td className="border_right">
                      {tableDataRef.current.Monday?.RevparCompsetLy
                        ? tableDataRef.current.Monday?.RevparCompsetLy
                        : 0}
                    </td>

                    <td
                      onDoubleClick={() => {
                        setClickedTr({
                          mainRow: "rev",
                          subRow: "compSet",
                          field: "curr",
                          day: "tuesday",
                        });
                      }}
                    >
                      {clickedTr.mainRow === "rev" &&
                      clickedTr.subRow === "compSet" &&
                      clickedTr.field === "curr" &&
                      clickedTr.day === "tuesday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current.Tuesday?.RevparCompset}
                          onChange={(e) => {
                            let lyValue = "";
                            strDataRef.current = strDataRef.current?.map(
                              (item, index) => {
                                if (item?.Weekday === "Tuesday") {
                                  item.RevparCompset = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let changeValue = item?.RevparCompsetChange
                                    ? parseFloat(item.RevparCompsetChange)
                                    : 0;
                                  lyValue = (
                                    (newValue * 100) /
                                    (100 + changeValue)
                                  )?.toFixed(2);
                                  item.RevparCompsetLy = lyValue;
                                }
                                return item;
                              }
                            );
                            calculateAvg("RevparCompset")
                            tableDataRef.current.Tuesday.RevparCompset =
                              e.target.value;
                            tableDataRef.current.Tuesday.RevparCompsetLy =
                              lyValue;
                            setStrData(strDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current.Tuesday?.RevparCompset ? (
                        tableDataRef.current.Tuesday?.RevparCompset
                      ) : (
                        0
                      )}
                    </td>
                    <td
                      onDoubleClick={() => {
                        setClickedTr({
                          mainRow: "rev",
                          subRow: "compSet",
                          field: "change",
                          day: "tuesday",
                        });
                      }}
                    >
                      {clickedTr.mainRow === "rev" &&
                      clickedTr.subRow === "compSet" &&
                      clickedTr.field === "change" &&
                      clickedTr.day === "tuesday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={
                            tableDataRef.current.Tuesday?.RevparCompsetChange
                          }
                          onChange={(e) => {
                            let lyValue = "";
                            strDataRef.current = strDataRef.current?.map(
                              (item, index) => {
                                if (item?.Weekday === "Tuesday") {
                                  item.RevparCompsetChange = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let currValue = item?.RevparCompset
                                    ? parseFloat(item.RevparCompset)
                                    : 0;
                                  lyValue = (
                                    (currValue * 100) /
                                    (100 + newValue)
                                  )?.toFixed(2);
                                  item.RevparCompsetLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current.Tuesday.RevparCompsetChange =
                              e.target.value;
                            tableDataRef.current.Tuesday.RevparCompsetLy =
                              lyValue;
                            setStrData(strDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current.Tuesday?.RevparCompsetChange ? (
                        tableDataRef.current.Tuesday?.RevparCompsetChange
                      ) : (
                        0
                      )}
                    </td>
                    <td className="border_right">
                      {tableDataRef.current.Tuesday?.RevparCompsetLy
                        ? tableDataRef.current.Tuesday?.RevparCompsetLy
                        : 0}
                    </td>

                    <td
                      onDoubleClick={() => {
                        setClickedTr({
                          mainRow: "rev",
                          subRow: "compSet",
                          field: "curr",
                          day: "wednesday",
                        });
                      }}
                    >
                      {clickedTr.mainRow === "rev" &&
                      clickedTr.subRow === "compSet" &&
                      clickedTr.field === "curr" &&
                      clickedTr.day === "wednesday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current.Wednesday?.RevparCompset}
                          onChange={(e) => {
                            let lyValue = "";
                            strDataRef.current = strDataRef.current?.map(
                              (item, index) => {
                                if (item?.Weekday === "Wednesday") {
                                  item.RevparCompset = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let changeValue = item?.RevparCompsetChange
                                    ? parseFloat(item.RevparCompsetChange)
                                    : 0;
                                  lyValue = (
                                    (newValue * 100) /
                                    (100 + changeValue)
                                  )?.toFixed(2);
                                  item.RevparCompsetLy = lyValue;
                                }
                                return item;
                              }
                            );
                            calculateAvg("RevparCompset")
                            tableDataRef.current.Wednesday.RevparCompset =
                              e.target.value;
                            tableDataRef.current.Wednesday.RevparCompsetLy =
                              lyValue;
                            setStrData(strDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current.Wednesday?.RevparCompset ? (
                        tableDataRef.current.Wednesday?.RevparCompset
                      ) : (
                        0
                      )}
                    </td>
                    <td
                      onDoubleClick={() => {
                        setClickedTr({
                          mainRow: "rev",
                          subRow: "compSet",
                          field: "change",
                          day: "wednesday",
                        });
                      }}
                    >
                      {clickedTr.mainRow === "rev" &&
                      clickedTr.subRow === "compSet" &&
                      clickedTr.field === "change" &&
                      clickedTr.day === "wednesday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={
                            tableDataRef.current.Wednesday?.RevparCompsetChange
                          }
                          onChange={(e) => {
                            let lyValue = "";
                            strDataRef.current = strDataRef.current?.map(
                              (item, index) => {
                                if (item?.Weekday === "Wednesday") {
                                  item.RevparCompsetChange = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let currValue = item?.RevparCompset
                                    ? parseFloat(item.RevparCompset)
                                    : 0;
                                  lyValue = (
                                    (currValue * 100) /
                                    (100 + newValue)
                                  )?.toFixed(2);
                                  item.RevparCompsetLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current.Wednesday.RevparCompsetChange =
                              e.target.value;
                            tableDataRef.current.Wednesday.RevparCompsetLy =
                              lyValue;
                            setStrData(strDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current.Wednesday
                          ?.RevparCompsetChange ? (
                        tableDataRef.current.Wednesday?.RevparCompsetChange
                      ) : (
                        0
                      )}
                    </td>
                    <td className="border_right">
                      {tableDataRef.current.Wednesday?.RevparCompsetLy
                        ? tableDataRef.current.Wednesday?.RevparCompsetLy
                        : 0}
                    </td>

                    <td
                      onDoubleClick={() => {
                        setClickedTr({
                          mainRow: "rev",
                          subRow: "compSet",
                          field: "curr",
                          day: "thursday",
                        });
                      }}
                    >
                      {clickedTr.mainRow === "rev" &&
                      clickedTr.subRow === "compSet" &&
                      clickedTr.field === "curr" &&
                      clickedTr.day === "thursday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current.Thursday?.RevparCompset}
                          onChange={(e) => {
                            let lyValue = "";
                            strDataRef.current = strDataRef.current?.map(
                              (item, index) => {
                                if (item?.Weekday === "Thursday") {
                                  item.RevparCompset = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let changeValue = item?.RevparCompsetChange
                                    ? parseFloat(item.RevparCompsetChange)
                                    : 0;
                                  lyValue = (
                                    (newValue * 100) /
                                    (100 + changeValue)
                                  )?.toFixed(2);
                                  item.RevparCompsetLy = lyValue;
                                }
                                return item;
                              }
                            );
                            calculateAvg("RevparCompset")
                            tableDataRef.current.Thursday.RevparCompset =
                              e.target.value;
                            tableDataRef.current.Thursday.RevparCompsetLy =
                              lyValue;
                            setStrData(strDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current.Thursday?.RevparCompset ? (
                        tableDataRef.current.Thursday?.RevparCompset
                      ) : (
                        0
                      )}
                    </td>
                    <td
                      onDoubleClick={() => {
                        setClickedTr({
                          mainRow: "rev",
                          subRow: "compSet",
                          field: "change",
                          day: "thursday",
                        });
                      }}
                    >
                      {clickedTr.mainRow === "rev" &&
                      clickedTr.subRow === "compSet" &&
                      clickedTr.field === "change" &&
                      clickedTr.day === "thursday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={
                            tableDataRef.current.Thursday?.RevparCompsetChange
                          }
                          onChange={(e) => {
                            let lyValue = "";
                            strDataRef.current = strDataRef.current?.map(
                              (item, index) => {
                                if (item?.Weekday === "Thursday") {
                                  item.RevparCompsetChange = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let currValue = item?.RevparCompset
                                    ? parseFloat(item.RevparCompset)
                                    : 0;
                                  lyValue = (
                                    (currValue * 100) /
                                    (100 + newValue)
                                  )?.toFixed(2);
                                  item.RevparCompsetLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current.Thursday.RevparCompsetChange =
                              e.target.value;
                            tableDataRef.current.Thursday.RevparCompsetLy =
                              lyValue;
                            setStrData(strDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current.Thursday?.RevparCompsetChange ? (
                        tableDataRef.current.Thursday?.RevparCompsetChange
                      ) : (
                        0
                      )}
                    </td>
                    <td className="border_right">
                      {tableDataRef.current.Thursday?.RevparCompsetLy
                        ? tableDataRef.current.Thursday?.RevparCompsetLy
                        : 0}
                    </td>

                    <td
                      onDoubleClick={() => {
                        setClickedTr({
                          mainRow: "rev",
                          subRow: "compSet",
                          field: "curr",
                          day: "friday",
                        });
                      }}
                    >
                      {clickedTr.mainRow === "rev" &&
                      clickedTr.subRow === "compSet" &&
                      clickedTr.field === "curr" &&
                      clickedTr.day === "friday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current.Friday?.RevparCompset}
                          onChange={(e) => {
                            let lyValue = "";
                            strDataRef.current = strDataRef.current?.map(
                              (item, index) => {
                                if (item?.Weekday === "Friday") {
                                  item.RevparCompset = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let changeValue = item?.RevparCompsetChange
                                    ? parseFloat(item.RevparCompsetChange)
                                    : 0;
                                  lyValue = (
                                    (newValue * 100) /
                                    (100 + changeValue)
                                  )?.toFixed(2);
                                  item.RevparCompsetLy = lyValue;
                                }
                                return item;
                              }
                            );
                            calculateAvg("RevparCompset")
                            tableDataRef.current.Friday.RevparCompset =
                              e.target.value;
                            tableDataRef.current.Friday.RevparCompsetLy =
                              lyValue;
                            setStrData(strDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current.Friday?.RevparCompset ? (
                        tableDataRef.current.Friday?.RevparCompset
                      ) : (
                        0
                      )}
                    </td>
                    <td
                      onDoubleClick={() => {
                        setClickedTr({
                          mainRow: "rev",
                          subRow: "compSet",
                          field: "change",
                          day: "friday",
                        });
                      }}
                    >
                      {clickedTr.mainRow === "rev" &&
                      clickedTr.subRow === "compSet" &&
                      clickedTr.field === "change" &&
                      clickedTr.day === "friday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={
                            tableDataRef.current.Friday?.RevparCompsetChange
                          }
                          onChange={(e) => {
                            let lyValue = "";
                            strDataRef.current = strDataRef.current?.map(
                              (item, index) => {
                                if (item?.Weekday === "Friday") {
                                  item.RevparCompsetChange = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let currValue = item?.RevparCompset
                                    ? parseFloat(item.RevparCompset)
                                    : 0;
                                  lyValue = (
                                    (currValue * 100) /
                                    (100 + newValue)
                                  )?.toFixed(2);
                                  item.RevparCompsetLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current.Friday.RevparCompsetChange =
                              e.target.value;
                            tableDataRef.current.Friday.RevparCompsetLy =
                              lyValue;
                            setStrData(strDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current.Friday?.RevparCompsetChange ? (
                        tableDataRef.current.Friday?.RevparCompsetChange
                      ) : (
                        0
                      )}
                    </td>
                    <td className="border_right">
                      {tableDataRef.current.Friday?.RevparCompsetLy
                        ? tableDataRef.current.Friday?.RevparCompsetLy
                        : 0}
                    </td>

                    <td
                      onDoubleClick={() => {
                        setClickedTr({
                          mainRow: "rev",
                          subRow: "compSet",
                          field: "curr",
                          day: "saturday",
                        });
                      }}
                    >
                      {clickedTr.mainRow === "rev" &&
                      clickedTr.subRow === "compSet" &&
                      clickedTr.field === "curr" &&
                      clickedTr.day === "saturday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current.Saturday?.RevparCompset}
                          onChange={(e) => {
                            let lyValue = "";
                            strDataRef.current = strDataRef.current?.map(
                              (item, index) => {
                                if (item?.Weekday === "Saturday") {
                                  item.RevparCompset = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let changeValue = item?.RevparCompsetChange
                                    ? parseFloat(item.RevparCompsetChange)
                                    : 0;
                                  lyValue = (
                                    (newValue * 100) /
                                    (100 + changeValue)
                                  )?.toFixed(2);
                                  item.RevparCompsetLy = lyValue;
                                }
                                return item;
                              }
                            );
                            calculateAvg("RevparCompset")
                            tableDataRef.current.Saturday.RevparCompset =
                              e.target.value;
                            tableDataRef.current.Saturday.RevparCompsetLy =
                              lyValue;
                            setStrData(strDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current.Saturday?.RevparCompset ? (
                        tableDataRef.current.Saturday?.RevparCompset
                      ) : (
                        0
                      )}
                    </td>
                    <td
                      onDoubleClick={() => {
                        setClickedTr({
                          mainRow: "rev",
                          subRow: "compSet",
                          field: "change",
                          day: "saturday",
                        });
                      }}
                    >
                      {clickedTr.mainRow === "rev" &&
                      clickedTr.subRow === "compSet" &&
                      clickedTr.field === "change" &&
                      clickedTr.day === "saturday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={
                            tableDataRef.current.Saturday?.RevparCompsetChange
                          }
                          onChange={(e) => {
                            let lyValue = "";
                            strDataRef.current = strDataRef.current?.map(
                              (item, index) => {
                                if (item?.Weekday === "Saturday") {
                                  item.RevparCompsetChange = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let currValue = item?.RevparCompset
                                    ? parseFloat(item.RevparCompset)
                                    : 0;
                                  lyValue = (
                                    (currValue * 100) /
                                    (100 + newValue)
                                  )?.toFixed(2);
                                  item.RevparCompsetLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current.Saturday.RevparCompsetChange =
                              e.target.value;
                            tableDataRef.current.Saturday.RevparCompsetLy =
                              lyValue;
                            setStrData(strDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current.Saturday?.RevparCompsetChange ? (
                        tableDataRef.current.Saturday?.RevparCompsetChange
                      ) : (
                        0
                      )}
                    </td>
                    <td className="border_right">
                      {tableDataRef.current.Saturday?.RevparCompsetLy
                        ? tableDataRef.current.Saturday?.RevparCompsetLy
                        : 0}
                    </td>
                    <td className="fontBold">
                      {/* {calculateAvg("RevparCompset")} */}
                      {
                      tableDataRef.current.Total?.RevparCompset
                      ? parseFloat(tableDataRef.current.Total?.RevparCompset)?.toFixed(2)
                      : 0}
                    </td>
                  </tr>
                  <tr className="shadowOnHover">
                    <td className="border_right">Index (MPI)</td>
                    <td
                      onDoubleClick={() => {
                        setClickedTr({
                          mainRow: "rev",
                          subRow: "index",
                          field: "curr",
                          day: "sunday",
                        });
                      }}
                    >
                      {clickedTr.mainRow === "rev" &&
                      clickedTr.subRow === "index" &&
                      clickedTr.field === "curr" &&
                      clickedTr.day === "sunday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current.Sunday?.RevparIndex}
                          onChange={(e) => {
                            let lyValue = "";
                            strDataRef.current = strDataRef.current?.map(
                              (item, index) => {
                                if (item?.Weekday === "Sunday") {
                                  item.RevparIndex = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let changeValue = item?.RevparIndexChange
                                    ? parseFloat(item.RevparIndexChange)
                                    : 0;
                                  lyValue = (
                                    (newValue * 100) /
                                    (100 + changeValue)
                                  )?.toFixed(2);
                                  item.RevparIndexLy = lyValue;
                                }
                                return item;
                              }
                            );
                            calculateAvg("RevparIndex")
                            tableDataRef.current.Sunday.RevparIndex =
                              e.target.value;
                            tableDataRef.current.Sunday.RevparIndexLy = lyValue;
                            setStrData(strDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current.Sunday?.RevparIndex ? (
                        tableDataRef.current.Sunday?.RevparIndex
                      ) : (
                        0
                      )}
                    </td>
                    <td
                      onDoubleClick={() => {
                        setClickedTr({
                          mainRow: "rev",
                          subRow: "index",
                          field: "change",
                          day: "sunday",
                        });
                      }}
                    >
                      {clickedTr.mainRow === "rev" &&
                      clickedTr.subRow === "index" &&
                      clickedTr.field === "change" &&
                      clickedTr.day === "sunday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current.Sunday?.RevparIndexChange}
                          onChange={(e) => {
                            let lyValue = "";
                            strDataRef.current = strDataRef.current?.map(
                              (item, index) => {
                                if (item?.Weekday === "Sunday") {
                                  item.RevparIndexChange = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let currValue = item?.RevparIndex
                                    ? parseFloat(item.RevparIndex)
                                    : 0;
                                  lyValue = (
                                    (currValue * 100) /
                                    (100 + newValue)
                                  )?.toFixed(2);
                                  item.RevparIndexLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current.Sunday.RevparIndexChange =
                              e.target.value;
                            tableDataRef.current.Sunday.RevparIndexLy = lyValue;
                            setStrData(strDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current.Sunday?.RevparIndexChange ? (
                        tableDataRef.current.Sunday?.RevparIndexChange
                      ) : (
                        0
                      )}
                    </td>
                    <td className="border_right">
                      {tableDataRef.current.Sunday?.RevparIndexLy
                        ? tableDataRef.current.Sunday?.RevparIndexLy
                        : 0}
                    </td>

                    <td
                      onDoubleClick={() => {
                        setClickedTr({
                          mainRow: "rev",
                          subRow: "index",
                          field: "curr",
                          day: "monday",
                        });
                      }}
                    >
                      {clickedTr.mainRow === "rev" &&
                      clickedTr.subRow === "index" &&
                      clickedTr.field === "curr" &&
                      clickedTr.day === "monday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current.Monday?.RevparIndex}
                          onChange={(e) => {
                            let lyValue = "";
                            strDataRef.current = strDataRef.current?.map(
                              (item, index) => {
                                if (item?.Weekday === "Monday") {
                                  item.RevparIndex = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let changeValue = item?.RevparIndexChange
                                    ? parseFloat(item.RevparIndexChange)
                                    : 0;
                                  lyValue = (
                                    (newValue * 100) /
                                    (100 + changeValue)
                                  )?.toFixed(2);
                                  item.RevparIndexLy = lyValue;
                                }
                                return item;
                              }
                            );
                            calculateAvg("RevparIndex")
                            tableDataRef.current.Monday.RevparIndex =
                              e.target.value;
                            tableDataRef.current.Monday.RevparIndexLy = lyValue;
                            setStrData(strDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current.Monday?.RevparIndex ? (
                        tableDataRef.current.Monday?.RevparIndex
                      ) : (
                        0
                      )}
                    </td>
                    <td
                      onDoubleClick={() => {
                        setClickedTr({
                          mainRow: "rev",
                          subRow: "index",
                          field: "change",
                          day: "monday",
                        });
                      }}
                    >
                      {clickedTr.mainRow === "rev" &&
                      clickedTr.subRow === "index" &&
                      clickedTr.field === "change" &&
                      clickedTr.day === "monday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current.Monday?.RevparIndexChange}
                          onChange={(e) => {
                            let lyValue = "";
                            strDataRef.current = strDataRef.current?.map(
                              (item, index) => {
                                if (item?.Weekday === "Monday") {
                                  item.RevparIndexChange = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let currValue = item?.RevparIndex
                                    ? parseFloat(item.RevparIndex)
                                    : 0;
                                  lyValue = (
                                    (currValue * 100) /
                                    (100 + newValue)
                                  )?.toFixed(2);
                                  item.RevparIndexLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current.Monday.RevparIndexChange =
                              e.target.value;
                            tableDataRef.current.Monday.RevparIndexLy = lyValue;
                            setStrData(strDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current.Monday?.RevparIndexChange ? (
                        tableDataRef.current.Monday?.RevparIndexChange
                      ) : (
                        0
                      )}
                    </td>
                    <td className="border_right">
                      {tableDataRef.current.Monday?.RevparIndexLy
                        ? tableDataRef.current.Monday?.RevparIndexLy
                        : 0}
                    </td>

                    <td
                      onDoubleClick={() => {
                        setClickedTr({
                          mainRow: "rev",
                          subRow: "index",
                          field: "curr",
                          day: "tuesday",
                        });
                      }}
                    >
                      {clickedTr.mainRow === "rev" &&
                      clickedTr.subRow === "index" &&
                      clickedTr.field === "curr" &&
                      clickedTr.day === "tuesday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current.Tuesday?.RevparIndex}
                          onChange={(e) => {
                            let lyValue = "";
                            strDataRef.current = strDataRef.current?.map(
                              (item, index) => {
                                if (item?.Weekday === "Tuesday") {
                                  item.RevparIndex = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let changeValue = item?.RevparIndexChange
                                    ? parseFloat(item.RevparIndexChange)
                                    : 0;
                                  lyValue = (
                                    (newValue * 100) /
                                    (100 + changeValue)
                                  )?.toFixed(2);
                                  item.RevparIndexLy = lyValue;
                                }
                                return item;
                              }
                            );
                            calculateAvg("RevparIndex")
                            tableDataRef.current.Tuesday.RevparIndex =
                              e.target.value;
                            tableDataRef.current.Tuesday.RevparIndexLy =
                              lyValue;
                            setStrData(strDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current.Tuesday?.RevparIndex ? (
                        tableDataRef.current.Tuesday?.RevparIndex
                      ) : (
                        0
                      )}
                    </td>
                    <td
                      onDoubleClick={() => {
                        setClickedTr({
                          mainRow: "rev",
                          subRow: "index",
                          field: "change",
                          day: "tuesday",
                        });
                      }}
                    >
                      {clickedTr.mainRow === "rev" &&
                      clickedTr.subRow === "index" &&
                      clickedTr.field === "change" &&
                      clickedTr.day === "tuesday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={
                            tableDataRef.current.Tuesday?.RevparIndexChange
                          }
                          onChange={(e) => {
                            let lyValue = "";
                            strDataRef.current = strDataRef.current?.map(
                              (item, index) => {
                                if (item?.Weekday === "Tuesday") {
                                  item.RevparIndexChange = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let currValue = item?.RevparIndex
                                    ? parseFloat(item.RevparIndex)
                                    : 0;
                                  lyValue = (
                                    (currValue * 100) /
                                    (100 + newValue)
                                  )?.toFixed(2);
                                  item.RevparIndexLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current.Tuesday.RevparIndexChange =
                              e.target.value;
                            tableDataRef.current.Tuesday.RevparIndexLy =
                              lyValue;
                            setStrData(strDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current.Tuesday?.RevparIndexChange ? (
                        tableDataRef.current.Tuesday?.RevparIndexChange
                      ) : (
                        0
                      )}
                    </td>
                    <td className="border_right">
                      {tableDataRef.current.Tuesday?.RevparIndexLy
                        ? tableDataRef.current.Tuesday?.RevparIndexLy
                        : 0}
                    </td>

                    <td
                      onDoubleClick={() => {
                        setClickedTr({
                          mainRow: "rev",
                          subRow: "index",
                          field: "curr",
                          day: "wednesday",
                        });
                      }}
                    >
                      {clickedTr.mainRow === "rev" &&
                      clickedTr.subRow === "index" &&
                      clickedTr.field === "curr" &&
                      clickedTr.day === "wednesday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current.Wednesday?.RevparIndex}
                          onChange={(e) => {
                            let lyValue = "";
                            strDataRef.current = strDataRef.current?.map(
                              (item, index) => {
                                if (item?.Weekday === "Wednesday") {
                                  item.RevparIndex = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let changeValue = item?.RevparIndexChange
                                    ? parseFloat(item.RevparIndexChange)
                                    : 0;
                                  lyValue = (
                                    (newValue * 100) /
                                    (100 + changeValue)
                                  )?.toFixed(2);
                                  item.RevparIndexLy = lyValue;
                                }
                                return item;
                              }
                            );
                            calculateAvg("RevparIndex")
                            tableDataRef.current.Wednesday.RevparIndex =
                              e.target.value;
                            tableDataRef.current.Wednesday.RevparIndexLy =
                              lyValue;
                            setStrData(strDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current.Wednesday?.RevparIndex ? (
                        tableDataRef.current.Wednesday?.RevparIndex
                      ) : (
                        0
                      )}
                    </td>
                    <td
                      onDoubleClick={() => {
                        setClickedTr({
                          mainRow: "rev",
                          subRow: "index",
                          field: "change",
                          day: "wednesday",
                        });
                      }}
                    >
                      {clickedTr.mainRow === "rev" &&
                      clickedTr.subRow === "index" &&
                      clickedTr.field === "change" &&
                      clickedTr.day === "wednesday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={
                            tableDataRef.current.Wednesday?.RevparIndexChange
                          }
                          onChange={(e) => {
                            let lyValue = "";
                            strDataRef.current = strDataRef.current?.map(
                              (item, index) => {
                                if (item?.Weekday === "Wednesday") {
                                  item.RevparIndexChange = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let currValue = item?.RevparIndex
                                    ? parseFloat(item.RevparIndex)
                                    : 0;
                                  lyValue = (
                                    (currValue * 100) /
                                    (100 + newValue)
                                  )?.toFixed(2);
                                  item.RevparIndexLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current.Wednesday.RevparIndexChange =
                              e.target.value;
                            tableDataRef.current.Wednesday.RevparIndexLy =
                              lyValue;
                            setStrData(strDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current.Wednesday?.RevparIndexChange ? (
                        tableDataRef.current.Wednesday?.RevparIndexChange
                      ) : (
                        0
                      )}
                    </td>
                    <td className="border_right">
                      {tableDataRef.current.Wednesday?.RevparIndexLy
                        ? tableDataRef.current.Wednesday?.RevparIndexLy
                        : 0}
                    </td>

                    <td
                      onDoubleClick={() => {
                        setClickedTr({
                          mainRow: "rev",
                          subRow: "index",
                          field: "curr",
                          day: "thursday",
                        });
                      }}
                    >
                      {clickedTr.mainRow === "rev" &&
                      clickedTr.subRow === "index" &&
                      clickedTr.field === "curr" &&
                      clickedTr.day === "thursday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current.Thursday?.RevparIndex}
                          onChange={(e) => {
                            let lyValue = "";
                            strDataRef.current = strDataRef.current?.map(
                              (item, index) => {
                                if (item?.Weekday === "Thursday") {
                                  item.RevparIndex = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let changeValue = item?.RevparIndexChange
                                    ? parseFloat(item.RevparIndexChange)
                                    : 0;
                                  lyValue = (
                                    (newValue * 100) /
                                    (100 + changeValue)
                                  )?.toFixed(2);
                                  item.RevparIndexLy = lyValue;
                                }
                                return item;
                              }
                            );
                            calculateAvg("RevparIndex")
                            tableDataRef.current.Thursday.RevparIndex =
                              e.target.value;
                            tableDataRef.current.Thursday.RevparIndexLy =
                              lyValue;
                            setStrData(strDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current.Thursday?.RevparIndex ? (
                        tableDataRef.current.Thursday?.RevparIndex
                      ) : (
                        0
                      )}
                    </td>
                    <td
                      onDoubleClick={() => {
                        setClickedTr({
                          mainRow: "rev",
                          subRow: "index",
                          field: "change",
                          day: "thursday",
                        });
                      }}
                    >
                      {clickedTr.mainRow === "rev" &&
                      clickedTr.subRow === "index" &&
                      clickedTr.field === "change" &&
                      clickedTr.day === "thursday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={
                            tableDataRef.current.Thursday?.RevparIndexChange
                          }
                          onChange={(e) => {
                            let lyValue = "";
                            strDataRef.current = strDataRef.current?.map(
                              (item, index) => {
                                if (item?.Weekday === "Thursday") {
                                  item.RevparIndexChange = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let currValue = item?.RevparIndex
                                    ? parseFloat(item.RevparIndex)
                                    : 0;
                                  lyValue = (
                                    (currValue * 100) /
                                    (100 + newValue)
                                  )?.toFixed(2);
                                  item.RevparIndexLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current.Thursday.RevparIndexChange =
                              e.target.value;
                            tableDataRef.current.Thursday.RevparIndexLy =
                              lyValue;
                            setStrData(strDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current.Thursday?.RevparIndexChange ? (
                        tableDataRef.current.Thursday?.RevparIndexChange
                      ) : (
                        0
                      )}
                    </td>
                    <td className="border_right">
                      {tableDataRef.current.Thursday?.RevparIndexLy
                        ? tableDataRef.current.Thursday?.RevparIndexLy
                        : 0}
                    </td>

                    <td
                      onDoubleClick={() => {
                        setClickedTr({
                          mainRow: "rev",
                          subRow: "index",
                          field: "curr",
                          day: "friday",
                        });
                      }}
                    >
                      {clickedTr.mainRow === "rev" &&
                      clickedTr.subRow === "index" &&
                      clickedTr.field === "curr" &&
                      clickedTr.day === "friday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current.Friday?.RevparIndex}
                          onChange={(e) => {
                            let lyValue = "";
                            strDataRef.current = strDataRef.current?.map(
                              (item, index) => {
                                if (item?.Weekday === "Friday") {
                                  item.RevparIndex = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let changeValue = item?.RevparIndexChange
                                    ? parseFloat(item.RevparIndexChange)
                                    : 0;
                                  lyValue = (
                                    (newValue * 100) /
                                    (100 + changeValue)
                                  )?.toFixed(2);
                                  item.RevparIndexLy = lyValue;
                                }
                                return item;
                              }
                            );
                            calculateAvg("RevparIndex")
                            tableDataRef.current.Friday.RevparIndex =
                              e.target.value;
                            tableDataRef.current.Friday.RevparIndexLy = lyValue;
                            setStrData(strDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current.Friday?.RevparIndex ? (
                        tableDataRef.current.Friday?.RevparIndex
                      ) : (
                        0
                      )}
                    </td>
                    <td
                      onDoubleClick={() => {
                        setClickedTr({
                          mainRow: "rev",
                          subRow: "index",
                          field: "change",
                          day: "friday",
                        });
                      }}
                    >
                      {clickedTr.mainRow === "rev" &&
                      clickedTr.subRow === "index" &&
                      clickedTr.field === "change" &&
                      clickedTr.day === "friday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current.Friday?.RevparIndexChange}
                          onChange={(e) => {
                            let lyValue = "";
                            strDataRef.current = strDataRef.current?.map(
                              (item, index) => {
                                if (item?.Weekday === "Friday") {
                                  item.RevparIndexChange = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let currValue = item?.RevparIndex
                                    ? parseFloat(item.RevparIndex)
                                    : 0;
                                  lyValue = (
                                    (currValue * 100) /
                                    (100 + newValue)
                                  )?.toFixed(2);
                                  item.RevparIndexLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current.Friday.RevparIndexChange =
                              e.target.value;
                            tableDataRef.current.Friday.RevparIndexLy = lyValue;
                            setStrData(strDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current.Friday?.RevparIndexChange ? (
                        tableDataRef.current.Friday?.RevparIndexChange
                      ) : (
                        0
                      )}
                    </td>
                    <td className="border_right">
                      {tableDataRef.current.Friday?.RevparIndexLy
                        ? tableDataRef.current.Friday?.RevparIndexLy
                        : 0}
                    </td>

                    <td
                      onDoubleClick={() => {
                        setClickedTr({
                          mainRow: "rev",
                          subRow: "index",
                          field: "curr",
                          day: "saturday",
                        });
                      }}
                    >
                      {clickedTr.mainRow === "rev" &&
                      clickedTr.subRow === "index" &&
                      clickedTr.field === "curr" &&
                      clickedTr.day === "saturday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={tableDataRef.current.Saturday?.RevparIndex}
                          onChange={(e) => {
                            let lyValue = "";
                            strDataRef.current = strDataRef.current?.map(
                              (item, index) => {
                                if (item?.Weekday === "Saturday") {
                                  item.RevparIndex = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let changeValue = item?.RevparIndexChange
                                    ? parseFloat(item.RevparIndexChange)
                                    : 0;
                                  lyValue = (
                                    (newValue * 100) /
                                    (100 + changeValue)
                                  )?.toFixed(2);
                                  item.RevparIndexLy = lyValue;
                                }
                                return item;
                              }
                            );
                            calculateAvg("RevparIndex")
                            tableDataRef.current.Saturday.RevparIndex =
                              e.target.value;
                            tableDataRef.current.Saturday.RevparIndexLy =
                              lyValue;
                            setStrData(strDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current.Saturday?.RevparIndex ? (
                        tableDataRef.current.Saturday?.RevparIndex
                      ) : (
                        0
                      )}
                    </td>
                    <td
                      onDoubleClick={() => {
                        setClickedTr({
                          mainRow: "rev",
                          subRow: "index",
                          field: "change",
                          day: "saturday",
                        });
                      }}
                    >
                      {clickedTr.mainRow === "rev" &&
                      clickedTr.subRow === "index" &&
                      clickedTr.field === "change" &&
                      clickedTr.day === "saturday" ? (
                        <Form.Control
                          maxLength={8}
                          type="text"
                          className="small-padding width50"
                          value={
                            tableDataRef.current.Saturday?.RevparIndexChange
                          }
                          onChange={(e) => {
                            let lyValue = "";
                            strDataRef.current = strDataRef.current?.map(
                              (item, index) => {
                                if (item?.Weekday === "Saturday") {
                                  item.RevparIndexChange = e.target.value;

                                  let newValue = e.target.value
                                    ? parseFloat(e.target.value)
                                    : 0;
                                  let currValue = item?.RevparIndex
                                    ? parseFloat(item.RevparIndex)
                                    : 0;
                                  lyValue = (
                                    (currValue * 100) /
                                    (100 + newValue)
                                  )?.toFixed(2);
                                  item.RevparIndexLy = lyValue;
                                }
                                return item;
                              }
                            );
                            tableDataRef.current.Saturday.RevparIndexChange =
                              e.target.value;
                            tableDataRef.current.Saturday.RevparIndexLy =
                              lyValue;
                            setStrData(strDataRef.current);
                          }}
                        />
                      ) : tableDataRef.current.Saturday?.RevparIndexChange ? (
                        tableDataRef.current.Saturday?.RevparIndexChange
                      ) : (
                        0
                      )}
                    </td>
                    <td className="border_right">
                      {tableDataRef.current.Saturday?.RevparIndexLy
                        ? tableDataRef.current.Saturday?.RevparIndexLy
                        : 0}
                    </td>
                    {/* <td className="fontBold">{calculateAvg("RevparIndex")}</td> */}
                    <td className="fontBold">{
                      tableDataRef.current.Total?.RevparIndex
                      ? parseFloat(tableDataRef.current.Total?.RevparIndex)?.toFixed(2)
                      : 0}</td>
                  </tr>
                </>
              ) : (
                <tr>
                  <td>No Data Found</td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </div>
      {showNoteSidebar && (
        <AddWidgetModal
          showModal={showNoteSidebar}
          closeModal={closeNoteSideBar}
          widgetId={para?.widgetId}
          propertyId={propertyDetailFromRTK?.propertyid}
          rowTitle={para?.rowTitle}
        />
      )}
      {widgetModal && (
        <WidgetDetailModal
          showModal={widgetModal}
          closeModal={closeWidgetModal}
          data={widgetDetailRef.current}
        />
      )}
    </div>
  );
};

export default RunningStar;
