import React, { useEffect, useState } from "react";
import { apiCall } from "../../utils/axiosService";
import { API_URL } from "../../utils/apiRoutes";
import { apiResponse } from "../../utils/apiResponse";
import { commonService } from "../../utils/commonService";
import downArrow from "../../Assets/Images/Header/downArrow.svg";
import { Table } from "react-bootstrap";
import Loader from "../Loader/Loader";
import moment from "moment";

const SingleDateRoomTypeModal = ({
  showModal,
  closeModal,
  asOfDate,
  pickupDate,
  roomType,
  selectedDate,
  propertyCode,
}) => {
  const [singleData, setSingleData] = useState(null);
  const [loading, setLoading] = useState(false);

  const getSingleData = async () => {
    setLoading(true);
    let singleDataResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.SnapShot +
          API_URL.MARKET_SEGMENT.SINGLE_DATE_ROOM_TYPE_SUMMARY,
        body: {
          // AsOfDate: asOfDate,
          // SelectedDate: moment(selectedDate).format("YYYY-MM-DD"),
          AsOfDate: commonService.getDateForNode(
            commonService.convertDateInTimezone(asOfDate)
          ),
          SelectedDate: commonService.getDateForNode(
            commonService.convertDateInTimezone(selectedDate)
          ),
          propertyCode: propertyCode,
          // PickUpDate: moment(pickupDate).format("YYYY-MM-DD"),
          PickUpDate: commonService.getDateForNode(
            commonService.convertDateInTimezone(pickupDate)
          ),
          RoomType: roomType,
        },
      },
      false
    );

    let response = apiResponse(false, singleDataResponse);

    if (response?.isValidate) {
      let dataFromApi = response?.data?.data;
      let groupingJson = [];

      const checkMarketSegmentExist = (marketSegmentName) => {
        let filterData = groupingJson?.findIndex(
          (x) => x.name === marketSegmentName
        );

        return filterData;
      };

      const checkRateCodeExist = (indexOfMarketSegment, rateCodeName) => {
        let filterData = groupingJson[indexOfMarketSegment]?.items?.findIndex(
          (x) => x.name === rateCodeName
        );
        return filterData;
      };

      const checkCompanyExists = (
        indexOfMarketSegment,
        indexofRateCode,
        companyName
      ) => {
        let filterData = groupingJson[indexOfMarketSegment].items[
          indexofRateCode
        ]?.items?.findIndex((x) => x.name === companyName);

        return filterData;
      };

      const marketSegmentWiseTotal = (marketSegmentName) => {
        let obj = {
          room: 0,
          lyRoom: 0,
          LOS: 0,
          pickupFrom: 0,
          pickupAdr: 0,
          adr: 0,
          lyAdr: 0,
          revenue: 0,
          lyRevenue: 0,
        };
        dataFromApi?.map((item, index) => {
          if (item.MarketSegment === marketSegmentName) {
            obj.room = obj.room + parseInt(item?.CURRENT_ROOM);
            obj.lyRoom = obj.lyRoom + parseInt(item?.LY_ROOM);
            obj.pickupFrom = obj.pickupFrom + parseInt(item?.PICKUP_FROM_ROOM);
            obj.pickupAdr = obj.pickupAdr + parseInt(item?.ADR_PICKUP);
            obj.adr = obj.adr + parseInt(item?.CURRENT_ADR);
            obj.lyAdr = obj.lyAdr + parseInt(item?.LY_ADR);
            obj.revenue = obj.revenue + parseInt(item?.CURRENT_REVENUE);
            obj.lyRevenue =
              obj.lyRevenue + item?.LY_REVENUE ? parseInt(item?.LY_REVENUE) : 0;
            obj.LOS = obj.LOS + parseInt(item?.LOS);
          }
        });
        return obj;
      };

      const rateCodeWiseTotal = (marketSegmentName, rateCodeName) => {
        let obj = {
          room: 0,
          lyRoom: 0,
          pickupFrom: 0,
          pickupAdr: 0,
          adr: 0,
          lyAdr: 0,
          revenue: 0,
          lyRevenue: 0,
          LOS: 0,
        };
        dataFromApi?.map((item, index) => {
          if (
            item.MarketSegment === marketSegmentName &&
            item.RateCode === rateCodeName
          ) {
            obj.room = obj.room + parseInt(item?.CURRENT_ROOM);
            obj.lyRoom = obj.lyRoom + parseInt(item?.LY_ROOM);
            obj.pickupFrom = obj.pickupFrom + parseInt(item?.PICKUP_FROM_ROOM);
            obj.pickupAdr = obj.pickupAdr + parseInt(item?.ADR_PICKUP);
            obj.adr = obj.adr + parseInt(item?.CURRENT_ADR);
            obj.lyAdr = obj.lyAdr + parseInt(item?.LY_ADR);
            obj.revenue = obj.revenue + parseInt(item?.CURRENT_REVENUE);
            obj.lyRevenue =
              obj.lyRevenue + item?.LY_REVENUE ? parseInt(item?.LY_REVENUE) : 0;
            obj.LOS = obj.LOS + parseInt(item?.LOS);
          }
        });
        return obj;
      };

      const companyWiseTotal = (
        marketSegmentName,
        rateCodeName,
        companyName
      ) => {
        let obj = {
          room: 0,
          lyRoom: 0,
          pickupFrom: 0,
          pickupAdr: 0,
          adr: 0,
          lyAdr: 0,
          revenue: 0,
          lyRevenue: 0,
          LOS: 0,
        };
        dataFromApi?.map((item, index) => {
          if (
            item.MarketSegment === marketSegmentName &&
            item.RateCode === rateCodeName &&
            item.Company === companyName
          ) {
            obj.room = obj.room + parseInt(item?.CURRENT_ROOM);
            obj.lyRoom = obj.lyRoom + parseInt(item?.LY_ROOM);
            obj.pickupFrom = obj.pickupFrom + parseInt(item?.PICKUP_FROM_ROOM);
            obj.pickupAdr = obj.pickupAdr + parseInt(item?.ADR_PICKUP);
            obj.adr = obj.adr + parseInt(item?.CURRENT_ADR);
            obj.lyAdr = obj.lyAdr + parseInt(item?.LY_ADR);
            obj.revenue = obj.revenue + parseInt(item?.CURRENT_REVENUE);
            obj.lyRevenue =
              obj.lyRevenue + item?.LY_REVENUE ? parseInt(item?.LY_REVENUE) : 0;
            obj.LOS = obj.LOS + parseInt(item?.LOS);
          }
        });
        return obj;
      };

      for (let i = 0; i < dataFromApi?.length; i++) {
        let indexOfMarketSegment = checkMarketSegmentExist(
          dataFromApi[i].MarketSegment
        );
        if (indexOfMarketSegment >= 0) {
          let indexofRateCode = checkRateCodeExist(
            indexOfMarketSegment,
            `${dataFromApi[i].RateCode}${
              dataFromApi[i]?.RateCodeDescript
                ? ` - ${dataFromApi[i]?.RateCodeDescript}`
                : ""
            }`
          );
          if (indexofRateCode >= 0) {
            let indexOfCompany = checkCompanyExists(
              indexOfMarketSegment,
              indexofRateCode,
              dataFromApi[i].Company
            );
            if (indexOfCompany >= 0) {
              groupingJson[indexOfMarketSegment].items[indexofRateCode].items[
                indexOfCompany
              ].items = [
                ...groupingJson[indexOfMarketSegment].items[indexofRateCode]
                  ?.items[indexOfCompany]?.items,
                {
                  name: dataFromApi[i].GuestName,
                  guid: commonService.GenerateGUID(),
                  items: "",
                  room: dataFromApi[i].CURRENT_ROOM,
                  lyRoom: dataFromApi[i].LY_ROOM,
                  arrDate: dataFromApi[i]?.ArrDate,
                  LOS: dataFromApi[i]?.LOS,
                  pickupFrom: dataFromApi[i].PICKUP_FROM_ROOM,
                  pickupAdr: dataFromApi[i].ADR_PICKUP,
                  adr: dataFromApi[i].CURRENT_ADR,
                  lyAdr: dataFromApi[i].LY_ADR,
                  revenue: dataFromApi[i].CURRENT_REVENUE,
                  lyRevenue: dataFromApi[i].LY_REVENUE,
                },
              ];
            } else {
              let companyTotal = companyWiseTotal(
                dataFromApi[i].MarketSegment,
                dataFromApi[i].RateCode,
                dataFromApi[i].Company
              );
              groupingJson[indexOfMarketSegment].items[indexofRateCode].items =
                [
                  ...groupingJson[indexOfMarketSegment].items[indexofRateCode]
                    .items,
                  {
                    name: dataFromApi[i].Company,
                    guid: commonService.GenerateGUID(),
                    room: companyTotal?.room,
                    lyRoom: companyTotal?.lyRoom,
                    arrDate: "",
                    LOS: companyTotal?.LOS,
                    pickupFrom: companyTotal?.pickupFrom,
                    pickupAdr: companyTotal?.pickupAdr,
                    adr: companyTotal?.adr,
                    lyAdr: companyTotal?.lyAdr,
                    revenue: companyTotal?.revenue,
                    lyRevenue: companyTotal?.lyRevenue,
                    items: [
                      {
                        name: dataFromApi[i].GuestName,
                        guid: commonService.GenerateGUID(),
                        items: "",
                        room: dataFromApi[i].CURRENT_ROOM,
                        lyRoom: dataFromApi[i].LY_ROOM,
                        arrDate: dataFromApi[i]?.ArrDate,
                        LOS: dataFromApi[i]?.LOS,
                        pickupFrom: dataFromApi[i].PICKUP_FROM_ROOM,
                        pickupAdr: dataFromApi[i].ADR_PICKUP,
                        adr: dataFromApi[i].CURRENT_ADR,
                        lyAdr: dataFromApi[i].LY_ADR,
                        revenue: dataFromApi[i].CURRENT_REVENUE,
                        lyRevenue: dataFromApi[i].LY_REVENUE,
                      },
                    ],
                  },
                ];
            }
          } else {
            let rateCodeTotal = rateCodeWiseTotal(
              dataFromApi[i].MarketSegment,
              dataFromApi[i].RateCode
            );
            let companyTotal = companyWiseTotal(
              dataFromApi[i].MarketSegment,
              dataFromApi[i].RateCode,
              dataFromApi[i].Company
            );
            groupingJson[indexOfMarketSegment].items = [
              ...groupingJson[indexOfMarketSegment].items,
              {
                name: `${dataFromApi[i].RateCode}${
                  dataFromApi[i]?.RateCodeDescript
                    ? ` - ${dataFromApi[i]?.RateCodeDescript}`
                    : ""
                }`,
                guid: commonService.GenerateGUID(),
                room: rateCodeTotal?.room,
                lyRoom: rateCodeTotal?.lyRoom,
                arrDate: "",
                LOS: rateCodeTotal?.LOS,
                pickupFrom: rateCodeTotal?.pickupFrom,
                pickupAdr: rateCodeTotal?.pickupAdr,
                adr: rateCodeTotal?.adr,
                lyAdr: rateCodeTotal?.lyAdr,
                revenue: rateCodeTotal?.revenue,
                lyRevenue: rateCodeTotal?.lyRevenue,
                items: [
                  {
                    name: dataFromApi[i].Company,
                    guid: commonService.GenerateGUID(),
                    room: companyTotal?.room,
                    lyRoom: companyTotal?.lyRoom,
                    arrDate: "",
                    LOS: companyTotal?.LOS,
                    pickupFrom: companyTotal?.pickupFrom,
                    pickupAdr: companyTotal?.pickupAdr,
                    adr: companyTotal?.adr,
                    lyAdr: companyTotal?.lyAdr,
                    revenue: companyTotal?.revenue,
                    lyRevenue: companyTotal?.lyRevenue,
                    items: [
                      {
                        name: dataFromApi[i].GuestName,
                        guid: commonService.GenerateGUID(),
                        items: "",
                        room: dataFromApi[i].CURRENT_ROOM,
                        lyRoom: dataFromApi[i].LY_ROOM,
                        arrDate: dataFromApi[i]?.ArrDate,
                        LOS: dataFromApi[i]?.LOS,
                        pickupFrom: dataFromApi[i].PICKUP_FROM_ROOM,
                        pickupAdr: dataFromApi[i].ADR_PICKUP,
                        adr: dataFromApi[i].CURRENT_ADR,
                        lyAdr: dataFromApi[i].LY_ADR,
                        revenue: dataFromApi[i].CURRENT_REVENUE,
                        lyRevenue: dataFromApi[i].LY_REVENUE,
                      },
                    ],
                  },
                ],
              },
            ];
          }
        } else {
          let marketSegmentTotal = marketSegmentWiseTotal(
            dataFromApi[i].MarketSegment
          );
          let rateCodeTotal = rateCodeWiseTotal(
            dataFromApi[i].MarketSegment,
            dataFromApi[i].RateCode
          );
          let companyTotal = companyWiseTotal(
            dataFromApi[i].MarketSegment,
            dataFromApi[i].RateCode,
            dataFromApi[i].Company
          );
          let newObj = {
            name: dataFromApi[i].MarketSegment,
            guid: commonService.GenerateGUID(),
            room: marketSegmentTotal?.room,
            lyRoom: marketSegmentTotal?.lyRoom,
            arrDate: "",
            LOS: marketSegmentTotal?.LOS,
            pickupFrom: marketSegmentTotal?.pickupFrom,
            pickupAdr: marketSegmentTotal?.pickupAdr,
            adr: marketSegmentTotal?.adr,
            lyAdr: marketSegmentTotal?.lyAdr,
            revenue: marketSegmentTotal?.revenue,
            lyRevenue: marketSegmentTotal?.lyRevenue,
            items: [
              {
                name: `${dataFromApi[i].RateCode}${
                  dataFromApi[i]?.RateCodeDescript
                    ? ` - ${dataFromApi[i]?.RateCodeDescript}`
                    : ""
                }`,
                guid: commonService.GenerateGUID(),
                room: rateCodeTotal?.room,
                lyRoom: rateCodeTotal?.lyRoom,
                arrDate: "",
                LOS: rateCodeTotal?.LOS,
                pickupFrom: rateCodeTotal?.pickupFrom,
                pickupAdr: rateCodeTotal?.pickupAdr,
                adr: rateCodeTotal?.adr,
                lyAdr: rateCodeTotal?.lyAdr,
                revenue: rateCodeTotal?.revenue,
                lyRevenue: rateCodeTotal?.lyRevenue,
                items: [
                  {
                    name: dataFromApi[i].Company,
                    guid: commonService.GenerateGUID(),
                    room: companyTotal?.room,
                    lyRoom: companyTotal?.lyRoom,
                    arrDate: "",
                    LOS: companyTotal?.LOS,
                    pickupFrom: companyTotal?.pickupFrom,
                    pickupAdr: companyTotal?.pickupAdr,
                    adr: companyTotal?.adr,
                    lyAdr: companyTotal?.lyAdr,
                    revenue: companyTotal?.revenue,
                    lyRevenue: companyTotal?.lyRevenue,
                    items: [
                      {
                        name: dataFromApi[i].GuestName,
                        guid: commonService.GenerateGUID(),
                        items: "",
                        room: dataFromApi[i].CURRENT_ROOM,
                        lyRoom: dataFromApi[i].LY_ROOM,
                        arrDate: dataFromApi[i]?.ArrDate,
                        LOS: dataFromApi[i]?.LOS,
                        pickupFrom: dataFromApi[i].PICKUP_FROM_ROOM,
                        pickupAdr: dataFromApi[i].ADR_PICKUP,
                        adr: dataFromApi[i].CURRENT_ADR,
                        lyAdr: dataFromApi[i].LY_ADR,
                        revenue: dataFromApi[i].CURRENT_REVENUE,
                        lyRevenue: dataFromApi[i].LY_REVENUE,
                      },
                    ],
                  },
                ],
              },
            ],
          };

          groupingJson = [...groupingJson, newObj];
        }
      }
      setSingleData(groupingJson);
      setLoading(false);
    }

    if (!response?.isValidate) {
      setSingleData(null);
      setLoading(false);
    }
  };
  useEffect(() => {
    getSingleData();
  }, [selectedDate, roomType]);

  const handleShowHideRow = (className, itemDetail) => {
    const removeClass = (classNameDetail) => {
      const classList = document.getElementsByClassName(classNameDetail);
      for (let i = 0; i < classList?.length; i++) {
        if (classList[i].classList.contains("nestedTableRowNone")) {
          classList[i].classList.remove("nestedTableRowNone");
        } else {
          classList[i].classList.add("nestedTableRowNone");
        }
      }
    };

    removeClass(className);

    const addChildClass = (childClassName) => {
      const classList = document.getElementsByClassName(childClassName);
      for (let i = 0; i < classList?.length; i++) {
        if (!classList[i].classList.contains("nestedTableRowNone")) {
          classList[i].classList.add("nestedTableRowNone");
        }
      }
    };

    const removeAllClass = (childItems) => {
      for (let i = 0; i < childItems?.length; i++) {
        addChildClass(childItems[i]?.guid);

        if (
          Array.isArray(childItems[i]?.items) &&
          childItems[i]?.items?.length > 0
        ) {
          removeAllClass(childItems[i]?.items);
        }
      }
    };

    if (Array.isArray(itemDetail?.items) && itemDetail?.items?.length > 0) {
      removeAllClass(itemDetail?.items);
    }
  };
  return (
    <div className="GroupNameModalMain ToolsSingleTicketPopUpMain singleDateSummaryModalMain">
      <div className="GroupNameModalHeader">
        <h2>Single Date Summary</h2>
        <button className="btn-close" onClick={closeModal}></button>
      </div>
      <div className="singleDateSummaryModalContent">
        <div className="ViewPropertySetupTable">
          <div className="table__container">
            <div className="stripped__table">
              <Table responsive>
                <thead>
                  <tr>
                    <th
                      className="border_right"
                      // style={{
                      //   border: "1px solid #cfd9f8",
                      //   borderRadius: "5px 5px 0 0",
                      // }}
                    >
                      Market Segment
                    </th>
                    <th
                    // style={{
                    //   border: "1px solid #cfd9f8",
                    //   borderRadius: "5px 0 0 0",
                    //   borderWidth: "1px 0 1px 0",
                    // }}
                    >
                      Room
                    </th>
                    <th
                      className="border_right"
                      // style={{
                      //   border: "1px solid #cfd9f8",
                      //   borderRadius: "0 5px 0 0",
                      //   borderWidth: "1px 1px 1px 0",
                      // }}
                    >
                      LY
                    </th>
                    {/* <th>Arr Date</th>
                    <th>LOS</th> */}
                    <th
                      className="border_right"
                      // style={{
                      //   border: "1px solid #cfd9f8",
                      //   borderRadius: "5px 5px 0 0",
                      //   borderWidth: "1px 1px 1px 0",
                      // }}
                    >
                      Pickup From
                    </th>
                    {/* <th
                      style={{
                        border: "1px solid #cfd9f8",
                        borderRadius: "0 5px 0 0",
                        borderWidth: "1px 1px 1px 0",
                      }}
                    >
                      ADR Pickup
                    </th> */}
                    <th
                      className="text-end"
                      // style={{
                      //   border: "1px solid #cfd9f8",
                      //   borderRadius: "5px 0 0 0",
                      //   borderWidth: "1px 0 1px 0",
                      // }}
                    >
                      ADR
                    </th>
                    <th
                      className="border_right text-end"
                      // style={{
                      //   border: "1px solid #cfd9f8",
                      //   borderRadius: "0 5px 0 0",
                      //   borderWidth: "1px 1px 1px 0",
                      // }}
                    >
                      LY
                    </th>
                    <th
                      className="text-end"
                      // style={{
                      //   border: "1px solid #cfd9f8",
                      //   borderRadius: "5px 0 0 0",
                      //   borderWidth: "1px 0 1px 0",
                      // }}
                    >
                      Revenue
                    </th>
                    <th
                      className="text-end"
                      // style={{
                      //   border: "1px solid #cfd9f8",
                      //   borderRadius: "0 5px 0 0",
                      //   borderWidth: "1px 1px 1px 0",
                      // }}
                    >
                      LY
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr style={{ height: "80px", position: "relative" }}>
                      <Loader />
                    </tr>
                  ) : (
                    singleData &&
                    singleData?.map((item, index) => {
                      return (
                        <>
                          <tr
                          // style={{
                          //   border: "1px solid #cfd9f8",
                          //   borderRadius: "0 0 1px 1px",
                          //   borderWidth: "0 0 1px 0",
                          // }}
                          >
                            <td
                              className="border_right"
                              // style={{
                              //   border: "1px solid #cfd9f8",
                              //   borderWidth: "0 1px 0 1px",
                              // }}
                            >
                              <div
                                className={`d-flex gap-2`}
                                onClick={() => {
                                  const classList =
                                    document.getElementsByClassName(item?.guid);

                                  if (
                                    classList[0].classList.contains(
                                      "nestedTableRowNone"
                                    )
                                  ) {
                                    handleShowHideRow(item?.guid);
                                  } else {
                                    handleShowHideRow(item?.guid, item);
                                  }
                                }}
                              >
                                <p>{item?.name}</p>
                                <img src={downArrow} />
                              </div>
                            </td>
                            <td>{item?.room}</td>
                            <td className="border_right">{item?.lyRoom}</td>
                            {/* <td>
                              {item?.arrDate
                                ? commonService.getDateInFormat(item?.arrDate)
                                : ""}
                            </td>
                            <td>{item?.LOS}</td> */}
                            <td className="border_right">{item?.pickupFrom}</td>
                            {/* <td>{item?.pickupAdr}</td> */}
                            <td className="text-end">
                              {commonService.formateAmount(item?.adr)}
                            </td>
                            <td className="text-end border_right">
                              {commonService.formateAmount(item?.lyAdr)}
                            </td>
                            <td className="text-end">
                              {commonService.formateAmount(item?.revenue)}
                            </td>
                            <td className="text-end">
                              {commonService.formateAmount(item?.lyRevenue)}
                            </td>
                          </tr>
                          {Array.isArray(item?.items) &&
                            item?.items?.length > 0 &&
                            item?.items?.map((rateCodeItem, rateCodeIndex) => (
                              <>
                                <tr
                                  className={`${item?.guid} nestedTableRowNone`}
                                >
                                  <td
                                    className="border_right"
                                    style={{ paddingLeft: "10px" }}
                                  >
                                    <div
                                      className="d-flex gap-2"
                                      onClick={() => {
                                        const classList =
                                          document.getElementsByClassName(
                                            rateCodeItem?.guid
                                          );

                                        if (
                                          classList[0].classList.contains(
                                            "nestedTableRowNone"
                                          )
                                        ) {
                                          handleShowHideRow(rateCodeItem?.guid);
                                        } else {
                                          handleShowHideRow(
                                            rateCodeItem?.guid,
                                            rateCodeItem
                                          );
                                        }
                                      }}
                                    >
                                      <p>{rateCodeItem?.name}</p>
                                      <img src={downArrow} />
                                    </div>
                                  </td>
                                  <td>{rateCodeItem?.room}</td>
                                  <td className="border_right">
                                    {rateCodeItem?.lyRoom}
                                  </td>
                                  {/* <td>
                                    {rateCodeItem?.arrDate
                                      ? commonService.getDateInFormat(
                                          rateCodeItem?.arrDate
                                        )
                                      : ""}
                                  </td>
                                  <td>{rateCodeItem?.LOS}</td> */}
                                  <td className="border_right">
                                    {rateCodeItem?.pickupFrom}
                                  </td>
                                  {/* <td>{rateCodeItem?.pickupAdr}</td> */}
                                  <td className="text-end">
                                    {commonService.formateAmount(
                                      rateCodeItem?.adr
                                    )}
                                  </td>
                                  <td className="text-end border_right">
                                    {commonService.formateAmount(
                                      rateCodeItem?.lyAdr
                                    )}
                                  </td>
                                  <td className="text-end">
                                    {commonService.formateAmount(
                                      rateCodeItem?.revenue
                                    )}
                                  </td>
                                  <td className="text-end">
                                    {commonService.formateAmount(
                                      rateCodeItem?.lyRevenue
                                    )}
                                  </td>
                                </tr>
                                {Array.isArray(rateCodeItem?.items) &&
                                  rateCodeItem?.items?.length > 0 &&
                                  rateCodeItem?.items?.map(
                                    (companyItem, companyIndex) => (
                                      <>
                                        <tr
                                          className={`${rateCodeItem?.guid} nestedTableRowNone`}
                                        >
                                          <td
                                            className="border_right"
                                            style={{ paddingLeft: "20px" }}
                                          >
                                            <div
                                              className="d-flex gap-2"
                                              onClick={() => {
                                                const classList =
                                                  document.getElementsByClassName(
                                                    companyItem?.guid
                                                  );

                                                if (
                                                  classList[0].classList.contains(
                                                    "nestedTableRowNone"
                                                  )
                                                ) {
                                                  handleShowHideRow(
                                                    companyItem?.guid
                                                  );
                                                } else {
                                                  handleShowHideRow(
                                                    companyItem?.guid,
                                                    companyItem
                                                  );
                                                }
                                              }}
                                            >
                                              <p>{companyItem?.name}</p>
                                              <img src={downArrow} />
                                            </div>
                                          </td>
                                          <td>{companyItem?.room}</td>
                                          <td className="border_right">
                                            {companyItem?.lyRoom}
                                          </td>
                                          {/* <td>
                                            {companyItem?.arrDate
                                              ? commonService.getDateInFormat(
                                                  companyItem?.arrDate
                                                )
                                              : ""}
                                          </td>
                                          <td>{companyItem?.LOS}</td> */}
                                          <td className="border_right">
                                            {companyItem?.pickupFrom}
                                          </td>
                                          {/* <td>{companyItem?.pickupAdr}</td> */}
                                          <td className="text-end">
                                            {commonService.formateAmount(
                                              companyItem?.adr
                                            )}
                                          </td>
                                          <td className="text-end border_right">
                                            {commonService.formateAmount(
                                              companyItem?.lyAdr
                                            )}
                                          </td>
                                          <td className="text-end">
                                            {commonService.formateAmount(
                                              companyItem?.revenue
                                            )}
                                          </td>
                                          <td className="text-end">
                                            {commonService.formateAmount(
                                              companyItem?.lyRevenue
                                            )}
                                          </td>
                                        </tr>
                                        {Array.isArray(companyItem?.items) &&
                                          companyItem?.items?.length > 0 &&
                                          companyItem?.items?.map(
                                            (guestItem, guestIndex) => (
                                              <tr
                                                className={`${companyItem?.guid} nestedTableRowNone`}
                                              >
                                                <td
                                                  className="border_right"
                                                  style={{
                                                    paddingLeft: "30px",
                                                  }}
                                                >
                                                  <div className="d-flex gap-2">
                                                    <p>{guestItem?.name}</p>
                                                  </div>
                                                </td>
                                                <td>{guestItem?.room}</td>
                                                <td className="border_right">
                                                  {guestItem?.lyRoom}
                                                </td>
                                                {/* <td>
                                                  {guestItem?.arrDate
                                                    ? commonService.getDateInFormat(
                                                        guestItem?.arrDate
                                                      )
                                                    : ""}
                                                </td>
                                                <td>{guestItem?.LOS}</td> */}
                                                <td className="border_right">
                                                  {guestItem?.pickupFrom}
                                                </td>
                                                {/* <td>{guestItem?.pickupAdr}</td> */}
                                                <td className="text-end">
                                                  {commonService.formateAmount(
                                                    guestItem?.adr
                                                  )}
                                                </td>
                                                <td className="text-end border_right">
                                                  {commonService.formateAmount(
                                                    guestItem?.lyAdr
                                                  )}
                                                </td>
                                                <td className="text-end">
                                                  {commonService.formateAmount(
                                                    guestItem?.revenue
                                                  )}
                                                </td>
                                                <td className="text-end">
                                                  {commonService.formateAmount(
                                                    guestItem?.lyRevenue
                                                  )}
                                                </td>
                                              </tr>
                                            )
                                          )}
                                      </>
                                    )
                                  )}
                              </>
                            ))}
                        </>
                      );
                    })
                  )}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleDateRoomTypeModal;
