import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import calendarIcon from "../../Assets/Images/Revenue-management/calendarIcon.svg";
import horizobtalBarIcon from "../../Assets/Images/Revenue-management/horizontal-bars.svg";
import graphIcon from "../../Assets/Images/Revenue-management/graphIcon.svg";
import { Link } from "react-router-dom";
import RateShopMultiAxisChart from "../CommonComponent/RateShopMultiAxisChart";
import RateShopLeadTimeChart from "../CommonComponent/RateShopLeadTimeChart";
import leftArrow from "../../Assets/Images/Revenue-management/leftArrow.svg";
import rightArrow from "../../Assets/Images/Revenue-management/rightArrow.svg";
import WidgetPickup from "./ListingComponent/WidgetModule/WidgetPickup";
import { useSelector } from "react-redux";
import AdrVsRateChart from "./ListingComponent/Chart/AdrVsRateChart";
import { apiCall } from "../../utils/axiosService";
import { API_URL } from "../../utils/apiRoutes";
import { errorToastMessage } from "../../utils/toastMessage";
import moment from "moment";
import { commonService } from "../../utils/commonService";

function RateShopGraphView({ showModal, closeModal }) {
  const [asOfDate, setAsOfDate] = useState(null);
  const propertyDetailFromRTK = useSelector(
    (store) => store.headerProperty.propertyDetail
  );

  const getAsOfDate = async () => {
    let asOfDateResponse = await apiCall(
      {
        method: "POST",
        url: API_URL.BASE_API_URL.SnapShot + API_URL.AS_OF_DATE.AS_OF_DATE,
        body: { propertyCode: propertyDetailFromRTK?.propertycode },
      },
      false
    );

    if (asOfDateResponse?.status_code === 0) {
      return errorToastMessage(asOfDateResponse?.message);
    } else if (asOfDateResponse?.status_code === 1) {
      setAsOfDate(
        asOfDateResponse?.data?.[0]?.last_data_refresh
          ? asOfDateResponse?.data?.[0]?.last_data_refresh
          : `${commonService.getDateForNode(new Date())} 00:00:00`
      );
    }
  };

  useEffect(() => {
    if (propertyDetailFromRTK) {
      getAsOfDate();
    }
  }, [propertyDetailFromRTK]);

  return (
    <Modal
      fullscreen
      centered
      show={showModal}
      onHide={closeModal}
      className="viewListingModalMain"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <div className="RMRateShopMain RateShopTableViewMain">
          <Container fluid>
            <Row>
              <Col xl={12}>
                <div className="ProspectDashboardTitleWraper  d-flex justify-content-between align-items-center">
                  <h2>
                    Dashboard-{" "}
                    <span className="tab-title-main">
                      Revenue Management - Tools - MarketEdge
                    </span>
                  </h2>
                  {/* <div className="generalTermSetup-heading-btns">
                    <Link to="/revenue/rate-shop">
                      <Button className="heading-menu-btns" type="button">
                        <img src={calendarIcon} alt="calendarIcon" />
                      </Button>
                    </Link>
                    <Link to="/revenue/rate-shop/rate-shop-table-view">
                      <Button className="heading-menu-btns" type="button">
                        <img src={horizobtalBarIcon} alt="horizobtalBarIcon" />
                      </Button>
                    </Link>
                    <Link to="/revenue/rate-shop/rate-shop-graphical-view">
                      <Button className="heading-menu-btns" type="button">
                        <img src={graphIcon} alt="graphIcon" />
                      </Button>
                    </Link>
                  </div> */}
                </div>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col xl={12}>
                {asOfDate && (
                  <AdrVsRateChart
                    api={1}
                    para={{
                      propertyDetail: propertyDetailFromRTK,
                      propertyCode: propertyDetailFromRTK?.propertycode,
                      propertyId: propertyDetailFromRTK?.propertyid,
                      asOfDate: asOfDate,
                    }}
                  />
                )}
              </Col>
            </Row>
          </Container>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default RateShopGraphView;
