import React, { useEffect, useRef, useState } from "react";
import { apiResponse } from "../../../../utils/apiResponse";
import { API_URL } from "../../../../utils/apiRoutes";
import { Button, Table } from "react-bootstrap";
import NoteIcon from "../../../../Assets/Images/CRM/notes.svg";
import downloadIcon from "../../../../Assets/Images/Operation/downloadIcon.svg";
import Loader from "../../../Loader/Loader";
import { apiCall } from "../../../../utils/axiosService";
import { compName, month } from "../../../../utils/constant";
import { commonService } from "../../../../utils/commonService";
import WidgetDetailModal from "../../WidgetDetailModal";
import { useSelector } from "react-redux";
import WidgetNote from "../../WidgetNote";
import infoIcon from "../../../../Assets/Images/Action Icons/infoIcon..svg";
import AddWidgetModal from "../../AddWidgetModal";
import { useParams } from "react-router-dom";
import emailIcon from "../../../../Assets/Images/Email.svg";
import Comments from "../Comments/Comment";
import moment from "moment";

const AnnualCancellationSummary = ({
  setCompDataFromChild,
  rowIndex,
  colIndex,
  api,
  para,
  setting,
}) => {
  const cyTableData = [
    {
      AsOfDate: "",
      MonthName: "January",
      CancelledNights: 0,
      ADR: "0",
      TotalLoss: "0",
      CancelledRatio: "0",
      "Total Inventory": 0,
      AvgLeadTime: "0",
      UnSoldRoom: 0,
    },
    {
      AsOfDate: "",
      MonthName: "February",
      CancelledNights: 0,
      ADR: "0",
      TotalLoss: "0",
      CancelledRatio: "0",
      "Total Inventory": 0,
      AvgLeadTime: "0",
      UnSoldRoom: 0,
    },
    {
      AsOfDate: "",
      MonthName: "March",
      CancelledNights: 0,
      ADR: "0",
      TotalLoss: "0",
      CancelledRatio: "0",
      "Total Inventory": 0,
      AvgLeadTime: "0",
      UnSoldRoom: 0,
    },
    {
      AsOfDate: "",
      MonthName: "April",
      CancelledNights: 0,
      ADR: "0",
      TotalLoss: "0",
      CancelledRatio: "0",
      "Total Inventory": 0,
      AvgLeadTime: "0",
      UnSoldRoom: 0,
    },
    {
      AsOfDate: "",
      MonthName: "May",
      CancelledNights: 0,
      ADR: "0",
      TotalLoss: "0",
      CancelledRatio: "0",
      "Total Inventory": 0,
      AvgLeadTime: "0",
      UnSoldRoom: 0,
    },
    {
      AsOfDate: "",
      MonthName: "June",
      CancelledNights: 0,
      ADR: "0",
      TotalLoss: "0",
      CancelledRatio: "0",
      "Total Inventory": 0,
      AvgLeadTime: "0",
      UnSoldRoom: 0,
    },
    {
      AsOfDate: "",
      MonthName: "July",
      CancelledNights: 0,
      ADR: "0",
      TotalLoss: "0",
      CancelledRatio: "0",
      "Total Inventory": 0,
      AvgLeadTime: "0",
      UnSoldRoom: 0,
    },
    {
      AsOfDate: "",
      MonthName: "August",
      CancelledNights: 0,
      ADR: "0",
      TotalLoss: "0",
      CancelledRatio: "0",
      "Total Inventory": 0,
      AvgLeadTime: "0",
      UnSoldRoom: 0,
    },
    {
      AsOfDate: "",
      MonthName: "September",
      CancelledNights: 0,
      ADR: "0",
      TotalLoss: "0",
      CancelledRatio: "0",
      "Total Inventory": 0,
      AvgLeadTime: "0",
      UnSoldRoom: 0,
    },
    {
      AsOfDate: "",
      MonthName: "October",
      CancelledNights: 0,
      ADR: "0",
      TotalLoss: "0",
      CancelledRatio: "0",
      "Total Inventory": 0,
      AvgLeadTime: "0",
      UnSoldRoom: 0,
    },
    {
      AsOfDate: "",
      MonthName: "November",
      CancelledNights: 0,
      ADR: "0",
      TotalLoss: "0",
      CancelledRatio: "0",
      "Total Inventory": 0,
      AvgLeadTime: "0",
      UnSoldRoom: 0,
    },
    {
      AsOfDate: "",
      MonthName: "December",
      CancelledNights: 0,
      ADR: "0",
      TotalLoss: "0",
      CancelledRatio: "0",
      "Total Inventory": 0,
      AvgLeadTime: "0",
      UnSoldRoom: 0,
    },
  ];

  const lyTableData = [
    {
      AsOfDate: "",
      MonthName: "January",
      CancelledNights: 0,
      ADR: "0",
      TotalLoss: "0",
      CancelledRatio: "0",
      TotalInventory: 0,
    },
    {
      AsOfDate: "",
      MonthName: "February",
      CancelledNights: 0,
      ADR: "0",
      TotalLoss: "0",
      CancelledRatio: "0",
      TotalInventory: 0,
    },
    {
      AsOfDate: "",
      MonthName: "March",
      CancelledNights: 0,
      ADR: "0",
      TotalLoss: "0",
      CancelledRatio: "0",
      TotalInventory: 0,
    },
    {
      AsOfDate: "",
      MonthName: "April",
      CancelledNights: 0,
      ADR: "0",
      TotalLoss: "0",
      CancelledRatio: "0",
      TotalInventory: 0,
    },
    {
      AsOfDate: "",
      MonthName: "May",
      CancelledNights: 0,
      ADR: "0",
      TotalLoss: "0",
      CancelledRatio: "0",
      TotalInventory: 0,
    },
    {
      AsOfDate: "",
      MonthName: "June",
      CancelledNights: 0,
      ADR: "0",
      TotalLoss: "0",
      CancelledRatio: "0",
      TotalInventory: 0,
    },
    {
      AsOfDate: "",
      MonthName: "July",
      CancelledNights: 0,
      ADR: "0",
      TotalLoss: "0",
      CancelledRatio: "0",
      TotalInventory: 0,
    },
    {
      AsOfDate: "",
      MonthName: "August",
      CancelledNights: 0,
      ADR: "0",
      TotalLoss: "0",
      CancelledRatio: "0",
      TotalInventory: 0,
    },
    {
      AsOfDate: "",
      MonthName: "September",
      CancelledNights: 0,
      ADR: "0",
      TotalLoss: "0",
      CancelledRatio: "0",
      TotalInventory: 0,
    },
    {
      AsOfDate: "",
      MonthName: "October",
      CancelledNights: 0,
      ADR: "0",
      TotalLoss: "0",
      CancelledRatio: "0",
      TotalInventory: 0,
    },
    {
      AsOfDate: "",
      MonthName: "November",
      CancelledNights: 0,
      ADR: "0",
      TotalLoss: "0",
      CancelledRatio: "0",
      TotalInventory: 0,
    },
    {
      AsOfDate: "",
      MonthName: "December",
      CancelledNights: 0,
      ADR: "0",
      TotalLoss: "0",
      CancelledRatio: "0",
      TotalInventory: 0,
    },
  ];

  const stlyTableData = [
    {
      AsOfDate: "",
      MonthName: "January",
      CancelledNights: 0,
      ADR: "0",
      CancelledRatio: "0",
      TotalLoss: "0",
      TotalInventory: 0,
    },
    {
      AsOfDate: "",
      MonthName: "February",
      CancelledNights: 0,
      ADR: "0",
      CancelledRatio: "0",
      TotalLoss: "0",
      TotalInventory: 0,
    },
    {
      AsOfDate: "",
      MonthName: "March",
      CancelledNights: 0,
      ADR: "0",
      CancelledRatio: "0",
      TotalLoss: "0",
      TotalInventory: 0,
    },
    {
      AsOfDate: "",
      MonthName: "April",
      CancelledNights: 0,
      ADR: "0",
      CancelledRatio: "0",
      TotalLoss: "0",
      TotalInventory: 0,
    },
    {
      AsOfDate: "",
      MonthName: "May",
      CancelledNights: 0,
      ADR: "0",
      CancelledRatio: "0",
      TotalLoss: "0",
      TotalInventory: 0,
    },
    {
      AsOfDate: "",
      MonthName: "June",
      CancelledNights: 0,
      ADR: "0",
      CancelledRatio: "0",
      TotalLoss: "0",
      TotalInventory: 0,
    },
    {
      AsOfDate: "",
      MonthName: "July",
      CancelledNights: 0,
      ADR: "0",
      CancelledRatio: "0",
      TotalLoss: "0",
      TotalInventory: 0,
    },
    {
      AsOfDate: "",
      MonthName: "August",
      CancelledNights: 0,
      ADR: "0",
      CancelledRatio: "0",
      TotalLoss: "0",
      TotalInventory: 0,
    },
    {
      AsOfDate: "",
      MonthName: "September",
      CancelledNights: 0,
      ADR: "0",
      CancelledRatio: "0",
      TotalLoss: "0",
      TotalInventory: 0,
    },
    {
      AsOfDate: "",
      MonthName: "October",
      CancelledNights: 0,
      ADR: "0",
      CancelledRatio: "0",
      TotalLoss: "0",
      TotalInventory: 0,
    },
    {
      AsOfDate: "",
      MonthName: "November",
      CancelledNights: 0,
      ADR: "0",
      CancelledRatio: "0",
      TotalLoss: "0",
      TotalInventory: 0,
    },
    {
      AsOfDate: "",
      MonthName: "December",
      CancelledNights: 0,
      ADR: "0",
      CancelledRatio: "0",
      TotalLoss: "0",
      TotalInventory: 0,
    },
  ];

  let inventory = 0,
    cancelledNights = 0,
    adr = 0,
    cancelledRatio = 0,
    totalLoss = 0,
    adrCount = 0,
    cancelledRatioCount = 0,
    lyinventory = 0,
    lycancelledNights = 0,
    lyadr = 0,
    lycancelledRatio = 0,
    lytotalLoss = 0,
    lyadrCount = 0,
    lycancelledRatioCount = 0,
    stlyinventory = 0,
    stlycancelledNights = 0,
    stlyadr = 0,
    stlycancelledRatio = 0,
    stlytotalLoss = 0,
    stlyadrCount = 0,
    stlycancelledRatiocount = 0;

  const [cyData, setCyData] = useState(null);
  const [cyLoading, setCyLoading] = useState(false);
  const [lyData, setLyData] = useState(null);
  const [lyLoading, setLyLoading] = useState(false);
  const [stlyData, setStlyData] = useState(null);
  const [stlyLoading, setStlyLoading] = useState(false);
  const [widgetModal, setWidgetModal] = useState(false);
  const widgetDetailRef = useRef();
  const [showNoteSidebar, setShowNoteSidebar] = useState(false);
  const propertyDetailFromRTK = useSelector(
    (store) => store.headerProperty.propertyDetail
  );
  const defaultAsOfDateFromRTK = useSelector(
    (store) => store.headerProperty.defaultAsOfDate
  );
  const { snapshottitle, propertyCode, snapshotId } = useParams();
  const closeNoteSideBar = () => {
    setShowNoteSidebar(false);
  };

  const closeWidgetModal = () => {
    setWidgetModal(false);
  };

  const getCyCancellationData = async () => {
    setCyLoading(true);
    let cancellationResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.SnapShot +
          API_URL.CANCELLATION.GET_CY_CANCELLATION_BOOKING_REV_LOSS,
        body: { propertyCode: para?.propertyCode, AsOfDate: para?.asOfDate },
      },
      false
    );

    let response = apiResponse(false, cancellationResponse);

    if (response?.isValidate) {
      setCyData(response?.data?.data);
      setCyLoading(false);
    }

    if (!response?.isValidate) {
      setCyData(cyTableData);
      setCyLoading(false);
    }
  };

  const getLyCancellationData = async () => {
    setLyLoading(true);
    let cancellationResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.SnapShot +
          API_URL.CANCELLATION.GET_LY_CANCELLATION_BOOKING_REV_LOSS,
        body: { propertyCode: para?.propertyCode, AsOfDate: para?.asOfDate },
      },
      false
    );

    let response = apiResponse(false, cancellationResponse);

    if (response?.isValidate) {
      setLyData(response?.data?.data);
      setLyLoading(false);
    }

    if (!response?.isValidate) {
      setLyData(lyTableData);
      setLyLoading(false);
    }
  };

  const getStlyCancellationData = async () => {
    setStlyLoading(true);
    let cancellationResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.SnapShot +
          API_URL.CANCELLATION.GET_STLY_CANCELLATION_BOOKING_REV_LOSS,
        body: { propertyCode: para?.propertyCode, AsOfDate: para?.asOfDate },
      },
      false
    );

    let response = apiResponse(false, cancellationResponse);

    if (response?.isValidate) {
      setStlyData(response?.data?.data);
      setStlyLoading(false);
    }

    if (!response?.isValidate) {
      setStlyData(stlyTableData);
      setStlyLoading(false);
    }
  };

  useEffect(() => {
    if (api === 0) {
      setCyData(cyTableData);
      setLyData(lyTableData);
      setStlyData(stlyTableData);
    } else {
      if (para?.asOfDate) {
        getCyCancellationData();
        getLyCancellationData();
        getStlyCancellationData();
      }
    }
  }, [para?.asOfDate]);

  const [tblids, settblids] = useState({
    annsummaryCancellationCommon: commonService.GenerateGUID(),
  });

  return (
    <div className="widget-container">
      {api !== 0 && (
        <div className="gap-1 widget-container-menu">
          {/* <Button className="heading-menu-btns" type="button">
            <img
              src={NoteIcon}
              alt="menuIcon"
              onClick={() => {
                setShowNoteSidebar(true);
              }}
            />
          </Button> */}
          <Button className="heading-menu-btns" type="button" title="Send Mail">
            <img
              src={emailIcon}
              alt="emailIcon"
              onClick={() => {
                commonService.fnSendWidgetMail(
                  tblids.annsummaryCancellationCommon,
                  "Annual Summary Cancellation",
                  snapshotId,
                  defaultAsOfDateFromRTK,
                  "",
                  para?.widgetId
                );
              }}
            />
          </Button>
          <Button className="heading-menu-btns" type="button">
            <img
              src={infoIcon}
              alt="info"
              onClick={() => {
                widgetDetailRef.current = {
                  widgetdetails: "",
                  usernote: "",
                  compName: compName?.annSummaryCancellation,
                  widgetId: para?.widgetId,
                };
                setWidgetModal(true);
              }}
            />
          </Button>
          <Button className="heading-menu-btns" type="button">
            <img
              src={downloadIcon}
              alt="menuIcon"
              import={tblids.annsummaryCancellationCommon}
              onClick={() =>
                commonService.fnExportInCSV(
                  tblids.annsummaryCancellationCommon,
                  // "Annual Summary Cancellation"
                  `${para?.propertyCode}-Annual Summary Cancellation-${moment(
                    new Date()
                  ).format("MMDDYYYY")}`
                )
              }
            />
          </Button>
        </div>
      )}
      <div className="ViewPropertySetupTable">
        <div className="stripped__table">
          <Table id={tblids.annsummaryCancellationCommon} responsive>
            <thead>
              <tr>
                <th className="border_right"></th>
                <th colSpan={5} className="text-center border_right">
                  Cancelled Booking Revenue Loss CY
                </th>
                <th colSpan={5} className="text-center border_right">
                  Cancelled Booking Revenue Loss STLY
                </th>
                <th colSpan={5} className="text-center">
                  Cancelled Booking Revenue Loss LY
                </th>
              </tr>
              <tr>
                <th className="text-center border_right">Month</th>
                <th className="text-center">Total Inventory</th>
                <th className="text-center">Cancelled Nights</th>
                <th className="text-center">ADR</th>
                <th className="text-center">Cancelled Ratio</th>
                <th className="text-center border_right">Total Loss</th>
                <th className="text-center">Total Inventory</th>
                <th className="text-center">Cancelled Bookings</th>
                <th className="text-center">ADR</th>
                <th className="text-center">Cancelled Occupancy</th>
                <th className="text-center border_right">Total Loss</th>
                <th className="text-center">Total Inventory</th>
                <th className="text-center">Cancelled Bookings</th>
                <th className="text-center">ADR</th>
                <th className="text-center">Cancelled Occupancy</th>
                <th className="text-center">Total Loss</th>
              </tr>
            </thead>
            <tbody>
              {cyLoading === true ||
              lyLoading === true ||
              stlyLoading === true ? (
                <tr style={{ height: "80px", position: "relative" }}>
                  <Loader />
                </tr>
              ) : (
                month?.map((item, index) => {
                  let cyMonthData = cyData?.filter(
                    (x) => x?.MonthName?.trim() === item
                  );

                  let lyMonthData = lyData?.filter(
                    (x) => x?.MonthName?.trim() === item
                  );

                  let stlyMonthData = stlyData?.filter(
                    (x) => x?.MonthName?.trim() === item
                  );

                  inventory =
                    inventory +
                    (cyMonthData?.[0]?.["Total Inventory"]
                      ? parseInt(cyMonthData?.[0]?.["Total Inventory"])
                      : 0);

                  cancelledNights =
                    cancelledNights +
                    (cyMonthData?.[0]?.["CancelledNights"]
                      ? parseInt(cyMonthData?.[0]?.["CancelledNights"])
                      : 0);

                  adr =
                    adr +
                    (cyMonthData?.[0]?.["ADR"]
                      ? parseInt(cyMonthData?.[0]?.["ADR"])
                      : 0);

                  cancelledRatio =
                    cancelledRatio +
                    (cyMonthData?.[0]?.["CancelledRatio"]
                      ? parseFloat(cyMonthData?.[0]?.["CancelledRatio"])
                      : 0);

                  totalLoss =
                    totalLoss +
                    (cyMonthData?.[0]?.["TotalLoss"]
                      ? parseInt(cyMonthData?.[0]?.["TotalLoss"])
                      : 0);

                  if (
                    cyMonthData?.[0]?.["CancelledRatio"] !== "0.00" &&
                    cyMonthData?.[0]?.["CancelledRatio"]
                  ) {
                    cancelledRatioCount = cancelledRatioCount + 1;
                  }

                  if (
                    cyMonthData?.[0]?.["ADR"] !== "0" &&
                    cyMonthData?.[0]?.["ADR"]
                  ) {
                    adrCount = adrCount + 1;
                  }

                  lyinventory =
                    lyinventory +
                    (lyMonthData?.[0]?.["TotalInventory"]
                      ? parseInt(lyMonthData?.[0]?.["TotalInventory"])
                      : 0);

                  lycancelledNights =
                    lycancelledNights +
                    (lyMonthData?.[0]?.["CancelledNights"]
                      ? parseInt(lyMonthData?.[0]?.["CancelledNights"])
                      : 0);

                  lyadr =
                    lyadr +
                    (lyMonthData?.[0]?.["ADR"]
                      ? parseInt(lyMonthData?.[0]?.["ADR"])
                      : 0);

                  lycancelledRatio =
                    lycancelledRatio +
                    (lyMonthData?.[0]?.["CancelledRatio"]
                      ? parseFloat(lyMonthData?.[0]?.["CancelledRatio"])
                      : 0);

                  lytotalLoss =
                    lytotalLoss +
                    (lyMonthData?.[0]?.["TotalLoss"]
                      ? parseInt(lyMonthData?.[0]?.["TotalLoss"])
                      : 0);

                  if (
                    lyMonthData?.[0]?.["ADR"] !== "0" &&
                    lyMonthData?.[0]?.["ADR"]
                  ) {
                    lyadrCount = lyadrCount + 1;
                  }

                  if (
                    lyMonthData?.[0]?.["CancelledRatio"] !== "0.00" &&
                    lyMonthData?.[0]?.["CancelledRatio"]
                  ) {
                    lycancelledRatioCount = lycancelledRatioCount + 1;
                  }

                  stlyinventory =
                    stlyinventory +
                    (stlyMonthData?.[0]?.["TotalInventory"]
                      ? parseInt(stlyMonthData?.[0]?.["TotalInventory"])
                      : 0);

                  stlycancelledNights =
                    stlycancelledNights +
                    (stlyMonthData?.[0]?.["CancelledNights"]
                      ? parseInt(stlyMonthData?.[0]?.["CancelledNights"])
                      : 0);

                  stlyadr =
                    stlyadr +
                    (stlyMonthData?.[0]?.["ADR"]
                      ? parseInt(stlyMonthData?.[0]?.["ADR"])
                      : 0);

                  stlycancelledRatio =
                    stlycancelledRatio +
                    (stlyMonthData?.[0]?.["CancelledRatio"]
                      ? parseFloat(stlyMonthData?.[0]?.["CancelledRatio"])
                      : 0);

                  stlytotalLoss =
                    stlytotalLoss +
                    (stlyMonthData?.[0]?.["TotalLoss"]
                      ? parseInt(stlyMonthData?.[0]?.["TotalLoss"])
                      : 0);

                  if (
                    stlyMonthData?.[0]?.["ADR"] !== "0" &&
                    stlyMonthData?.[0]?.["ADR"]
                  ) {
                    stlyadrCount = stlyadrCount + 1;
                  }

                  if (
                    stlyMonthData?.[0]?.["CancelledRatio"] !== "0.00" &&
                    stlyMonthData?.[0]?.["CancelledRatio"]
                  ) {
                    stlycancelledRatiocount = stlycancelledRatiocount + 1;
                  }

                  return (
                    <React.Fragment key={index}>
                      <tr className="shadowOnHover">
                        <td className="text-center border_right">{item}</td>
                        <td className="text-center">
                          {cyMonthData?.[0]?.["Total Inventory"]
                            ? commonService.formateRoom(cyMonthData?.[0]?.["Total Inventory"])
                            : 0}
                        </td>
                        <td className="text-center">
                          {cyMonthData?.[0]?.["CancelledNights"]
                            ? commonService.formateRoom(cyMonthData?.[0]?.["CancelledNights"])
                            : 0}
                        </td>
                        <td className="text-end">
                          {cyMonthData?.[0]?.["ADR"]
                            ? commonService.formateAmount(
                                cyMonthData?.[0]?.["ADR"]
                              )
                            : commonService.formateAmount(0)}
                        </td>
                        <td className="text-center">
                          {cyMonthData?.[0]?.["CancelledRatio"]
                            ? Math.round(cyMonthData?.[0]?.["CancelledRatio"])
                            : 0}
                          %
                        </td>
                        <td className="text-end border_right">
                          {cyMonthData?.[0]?.["TotalLoss"]
                            ? commonService.formateAmount(
                                cyMonthData?.[0]?.["TotalLoss"]
                              )
                            : commonService.formateAmount(0)}
                        </td>
                        <td className="text-center">
                          {stlyMonthData?.[0]?.["TotalInventory"]
                            ? commonService.formateRoom(stlyMonthData?.[0]?.["TotalInventory"])
                            : 0}
                        </td>
                        <td className="text-center">
                          {stlyMonthData?.[0]?.["CancelledNights"]
                            ? commonService.formateRoom(stlyMonthData?.[0]?.["CancelledNights"])
                            : 0}
                        </td>
                        <td className="text-end">
                          {stlyMonthData?.[0]?.["ADR"]
                            ? commonService.formateAmount(
                                stlyMonthData?.[0]?.["ADR"]
                              )
                            : commonService.formateAmount(0)}
                        </td>
                        <td className="text-center">
                          {stlyMonthData?.[0]?.["CancelledRatio"]
                            ? Math.round(stlyMonthData?.[0]?.["CancelledRatio"])
                            : 0}
                          %
                        </td>
                        <td className="text-end border_right">
                          {stlyMonthData?.[0]?.["TotalLoss"]
                            ? commonService.formateAmount(
                                stlyMonthData?.[0]?.["TotalLoss"]
                              )
                            : commonService.formateAmount(0)}
                        </td>
                        <td className="text-center">
                          {lyMonthData?.[0]?.["TotalInventory"]
                            ? commonService.formateRoom(lyMonthData?.[0]?.["TotalInventory"])
                            : 0}
                        </td>
                        <td className="text-center">
                          {lyMonthData?.[0]?.["CancelledNights"]
                            ? commonService.formateRoom(lyMonthData?.[0]?.["CancelledNights"])
                            : 0}
                        </td>
                        <td className="text-end">
                          {lyMonthData?.[0]?.["ADR"]
                            ? commonService.formateAmount(
                                lyMonthData?.[0]?.["ADR"]
                              )
                            : commonService.formateAmount(0)}
                        </td>
                        <td className="text-center">
                          {lyMonthData?.[0]?.["CancelledRatio"]
                            ? Math.round(lyMonthData?.[0]?.["CancelledRatio"])
                            : 0}
                          %
                        </td>
                        <td className="text-end">
                          {lyMonthData?.[0]?.["TotalLoss"]
                            ? commonService.formateAmount(
                                lyMonthData?.[0]?.["TotalLoss"]
                              )
                            : commonService.formateAmount(0)}
                        </td>
                      </tr>
                      {index === 11 && (
                        <tr className="shadowOnHover">
                          <td className="boldTd text-center border_right">Total</td>
                          <td className="boldTd text-center">{commonService.formateRoom(inventory)}</td>
                          <td className="boldTd text-center">
                            {commonService.formateRoom(cancelledNights)}
                          </td>
                          <td className="boldTd text-end">
                            {adr > 0
                              ? commonService.formateAmount(
                                  Math.round(adr / adrCount)
                                )
                              : commonService.formateAmount(0)}
                          </td>
                          <td className="boldTd text-center">
                            {cancelledRatio > 0
                              ? (cancelledRatio / cancelledRatioCount)?.toFixed(
                                  2
                                )
                              : 0}
                            %
                          </td>
                          <td className="boldTd text-end border_right">
                            {commonService.formateAmount(totalLoss)}
                          </td>

                          <td className="boldTd text-center">
                            {commonService.formateRoom(stlyinventory)}
                          </td>
                          <td className="boldTd text-center">
                            {commonService.formateRoom(stlycancelledNights)}
                          </td>
                          <td className="boldTd text-end">
                            {stlyadr > 0
                              ? commonService.formateAmount(
                                  Math.round(stlyadr / stlyadrCount)
                                )
                              : commonService.formateAmount(0)}
                          </td>
                          <td className="boldTd text-center">
                            {stlycancelledRatio > 0
                              ? (
                                  stlycancelledRatio / stlycancelledRatiocount
                                )?.toFixed(2)
                              : 0}
                            %
                          </td>
                          <td className="boldTd text-end border_right">
                            {commonService.formateAmount(stlytotalLoss)}
                          </td>

                          <td className="boldTd text-center">{commonService.formateRoom(lyinventory)}</td>
                          <td className="boldTd text-center">
                            {commonService.formateRoom(lycancelledNights)}
                          </td>
                          <td className="boldTd text-end">
                            {lyadr > 0
                              ? commonService.formateAmount(
                                  Math.round(lyadr / lyadrCount)
                                )
                              : commonService.formateAmount(0)}
                          </td>
                          <td className="boldTd text-center">
                            {lycancelledRatio > 0
                              ? (
                                  lycancelledRatio / lycancelledRatioCount
                                )?.toFixed(2)
                              : 0}
                            %
                          </td>
                          <td className="boldTd text-end">
                            {commonService.formateAmount(lytotalLoss)}
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  );
                })
              )}
            </tbody>
          </Table>
        </div>
      </div>
      {api !== 0 && (
        <Comments widgetDetails={para} editorId={"AnnualCancellationSummary"} />
      )}

      {/* {showNoteSidebar && (
        <WidgetNote
          show={showNoteSidebar}
          handleClose={closeNoteSideBar}
          widgetId={para?.widgetId}
          propertyId={propertyDetailFromRTK?.propertyid}
          rowTitle={para?.rowTitle}
        />
      )} */}
      {showNoteSidebar && (
        <AddWidgetModal
          showModal={showNoteSidebar}
          closeModal={closeNoteSideBar}
          widgetId={para?.widgetId}
          propertyId={propertyDetailFromRTK?.propertyid}
          rowTitle={para?.rowTitle}
        />
      )}
      {widgetModal && (
        <WidgetDetailModal
          showModal={widgetModal}
          closeModal={closeWidgetModal}
          data={widgetDetailRef.current}
        />
      )}
    </div>
  );
};

export default AnnualCancellationSummary;
