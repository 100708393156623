import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Button, Col, Container, Form, Row, Table } from "react-bootstrap";
import { apiCall } from "../../../../utils/axiosService";
import { API_URL } from "../../../../utils/apiRoutes";
import { apiResponse } from "../../../../utils/apiResponse";
import { dataTable } from "../../../../utils/dataTable";
import { commonService } from "../../../../utils/commonService";
import editIcon from "../../../../Assets/Images/Action Icons/editaction.svg";
import deleteIcon from "../../../../Assets/Images/Action Icons/deleteaction.svg";
import DeleteItemModal from "../../../Configuration/DeleteItemModal";
import plusIcon from "../../../../Assets/Images/FormCheck/plusIcon.png";
import ReminderModal from "../.././AddReminderModal";

const Reminder = ({
  setCompDataFromChild,
  rowIndex,
  colIndex,
  api,
  para,
  setting,
}) => {
  const yearsArr = [
    ...commonService.getPastYearForDropDown(10),
    ...commonService.getFutureYearForDropDown(10),
    new Date().getFullYear(),
  ]?.sort(function (a, b) {
    return a - b;
  });

  const propertyDetailFromRTK = useSelector(
    (store) => store.headerProperty.propertyDetail
  );
  const { id } = useParams();
  const [reminderDetails, setreminderDetails] = useState(null);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [deleteModal, setDeleteModal] = useState(false);
  const [addReminderModal, setaddReminderModal] = useState(false);
  //   const [syncEventModal, setSyncEventModal] = useState(false);
  const reminderIdRef = useRef();

  const closeaddReminderModal = () => {
    setaddReminderModal(false);
  };

  const closeDeleteModal = () => {
    setDeleteModal(false);
  };

  const getReminderList = async () => {
    let reminderListResponse = await apiCall(
      {
        method: "POST",
        url: API_URL.BASE_API_URL.RevenueManagement + API_URL.REMINDER.GET_LIST,
        body: {
          pageNum: 1,
          pageSize: 10,
          sortColumn: "",
          sortDirection: "asc",
          searchValue: "",
          propertyid: propertyDetailFromRTK?.propertyid
            ? parseInt(propertyDetailFromRTK?.propertyid)
            : 0,
          startdate: null,
          enddate: null,
          eventname: "",
          eventtypeterm: "",
        },
      },
      false
    );

    let response = apiResponse(false, reminderListResponse);

    if (response?.isValidate) {
      setreminderDetails(response?.data?.data);
    }

    if (!response?.isValidate) {
      setreminderDetails(null);
    }
    dataTable("tblPropertySettingReminder");
  };

  useEffect(() => {
    if (api !== 0) {
      getReminderList();
    }
  }, [propertyDetailFromRTK]);

  return (
    <div>
      <>
        <Row>
          <Col xl={12}>
            <div className="d-flex justify-content-end gap-2 mb-3">
              <Button
                className="add-btn-plus "
                title="Add"
                type="button"
                onClick={() => {
                  reminderIdRef.current = 0;
                  setaddReminderModal(true);
                }}
              >
                <img src={plusIcon} alt="plus" /> Add
              </Button>
            </div>
          </Col>
          <Col xl={12}>
            <div className="table__container">
              <div className="stripped__table">
                <Table id="tblPropertySettingReminder" responsive>
                  <thead>
                    <tr>
                      <th className="">Name</th>
                      <th className="width150 no-search">Start Date</th>
                      <th className="width150 no-search">End Date</th>
                      <th className="width150 text-end stickyLastRow no-sort no-search">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {reminderDetails &&
                      reminderDetails?.map((item, index) => (
                        <tr key={item?.reminderid}>
                          <td>{item?.remindername}</td>
                          <td>
                            {item?.startdate
                              ? commonService.getDateInFormat(item?.startdate)
                              : ""}
                          </td>
                          <td>
                            {item?.enddate
                              ? commonService.getDateInFormat(item?.enddate)
                              : ""}
                          </td>
                          <td>
                            <div className="Allactions">
                              <img
                                src={editIcon}
                                onClick={() => {
                                  reminderIdRef.current = item?.reminderid;
                                  setaddReminderModal(true);
                                }}
                              />
                              <img
                                src={deleteIcon}
                                onClick={() => {
                                  reminderIdRef.current = item?.reminderid;
                                  setDeleteModal(true);
                                }}
                              />
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </div>
            </div>
          </Col>
        </Row>
      </>
      {deleteModal && (
        <DeleteItemModal
          showDeleteItemModal={deleteModal}
          closeDeleteItemModal={closeDeleteModal}
          id={reminderIdRef.current}
          getList={getReminderList}
          url={API_URL.BASE_API_URL.RevenueManagement + API_URL.REMINDER.DELETE}
        />
      )}
      {addReminderModal && (
        <ReminderModal
          showModal={addReminderModal}
          closeModal={closeaddReminderModal}
          reminderid={reminderIdRef.current}
          propertyid={
            propertyDetailFromRTK?.propertyid
              ? parseInt(propertyDetailFromRTK?.propertyid)
              : 0
          }
          getList={getReminderList}
        />
      )}
    </div>
  );
};

export default Reminder;
