import React, { useEffect, useRef, useState } from "react";
import { commonService } from "../../../../utils/commonService";
import NoteIcon from "../../../../Assets/Images/CRM/notes.svg";
import downloadIcon from "../../../../Assets/Images/Operation/downloadIcon.svg";
import { Button, Col, Table } from "react-bootstrap";
import DatePicker from "react-datepicker";
import Loader from "../../../Loader/Loader";
import { apiCall } from "../../../../utils/axiosService";
import { API_URL } from "../../../../utils/apiRoutes";
import { apiResponse } from "../../../../utils/apiResponse";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import emailIcon from "../../../../Assets/Images/Email.svg";
import moment from "moment";

const ChoiceOccupancy = ({
  setCompDataFromChild,
  rowIndex,
  colIndex,
  api,
  para,
  setting,
}) => {
  const tableData = [
    {
      id: 1,
      propertyCode: "AC32AW",
      pullDateId: "0",
      AsOfDate: "2023-08-31",
      IDS_DATE: "2023-08-31",
      Day: "Thu",
      Rooms: "0",
      OOO: "0",
      StayOver: "0",
      Arrivals: "0",
      DueOut: "0",
      Available: "0",
      GroupBlock: "0",
      GroupPickedUp: "0",
      TransNGTD: "0",
      TransGTD: "0",
      Occupied: "0",
      OccPercent: "0 %",
      RoomRev: "0",
      RevPAR: "0",
      ADR: "0",
      Ppl: "0 / 0",
    },
    {
      id: 2,
      propertyCode: "AC32AW",
      pullDateId: "0",
      AsOfDate: "2023-08-31",
      IDS_DATE: "2023-08-31",
      Day: "Thu",
      Rooms: "0",
      OOO: "0",
      StayOver: "0",
      Arrivals: "0",
      DueOut: "0",
      Available: "0",
      GroupBlock: "0",
      GroupPickedUp: "0",
      TransNGTD: "0",
      TransGTD: "0",
      Occupied: "0",
      OccPercent: "0 %",
      RoomRev: "0",
      RevPAR: "0",
      ADR: "0",
      Ppl: "0 / 0",
    },
    {
      id: 3,
      propertyCode: "AC32AW",
      pullDateId: "0",
      AsOfDate: "2023-08-31",
      IDS_DATE: "2023-08-31",
      Day: "Thu",
      Rooms: "0",
      OOO: "0",
      StayOver: "0",
      Arrivals: "0",
      DueOut: "0",
      Available: "0",
      GroupBlock: "0",
      GroupPickedUp: "0",
      TransNGTD: "0",
      TransGTD: "0",
      Occupied: "0",
      OccPercent: "0 %",
      RoomRev: "0",
      RevPAR: "0",
      ADR: "0",
      Ppl: "0 / 0",
    },
    {
      id: 4,
      propertyCode: "AC32AW",
      pullDateId: "0",
      AsOfDate: "2023-08-31",
      IDS_DATE: "2023-08-31",
      Day: "Thu",
      Rooms: "0",
      OOO: "0",
      StayOver: "0",
      Arrivals: "0",
      DueOut: "0",
      Available: "0",
      GroupBlock: "0",
      GroupPickedUp: "0",
      TransNGTD: "0",
      TransGTD: "0",
      Occupied: "0",
      OccPercent: "0 %",
      RoomRev: "0",
      RevPAR: "0",
      ADR: "0",
      Ppl: "0 / 0",
    },
    {
      id: 5,
      propertyCode: "AC32AW",
      pullDateId: "0",
      AsOfDate: "2023-08-31",
      IDS_DATE: "2023-08-31",
      Day: "Thu",
      Rooms: "0",
      OOO: "0",
      StayOver: "0",
      Arrivals: "0",
      DueOut: "0",
      Available: "0",
      GroupBlock: "0",
      GroupPickedUp: "0",
      TransNGTD: "0",
      TransGTD: "0",
      Occupied: "0",
      OccPercent: "0 %",
      RoomRev: "0",
      RevPAR: "0",
      ADR: "0",
      Ppl: "0 / 0",
    },
    {
      id: 6,
      propertyCode: "AC32AW",
      pullDateId: "0",
      AsOfDate: "2023-08-31",
      IDS_DATE: "2023-08-31",
      Day: "Thu",
      Rooms: "0",
      OOO: "0",
      StayOver: "0",
      Arrivals: "0",
      DueOut: "0",
      Available: "0",
      GroupBlock: "0",
      GroupPickedUp: "0",
      TransNGTD: "0",
      TransGTD: "0",
      Occupied: "0",
      OccPercent: "0 %",
      RoomRev: "0",
      RevPAR: "0",
      ADR: "0",
      Ppl: "0 / 0",
    },
    {
      id: 7,
      propertyCode: "AC32AW",
      pullDateId: "0",
      AsOfDate: "2023-08-31",
      IDS_DATE: "2023-08-31",
      Day: "Thu",
      Rooms: "0",
      OOO: "0",
      StayOver: "0",
      Arrivals: "0",
      DueOut: "0",
      Available: "0",
      GroupBlock: "0",
      GroupPickedUp: "0",
      TransNGTD: "0",
      TransGTD: "0",
      Occupied: "0",
      OccPercent: "0 %",
      RoomRev: "0",
      RevPAR: "0",
      ADR: "0",
      Ppl: "0 / 0",
    },
    {
      id: 8,
      propertyCode: "AC32AW",
      pullDateId: "0",
      AsOfDate: "2023-08-31",
      IDS_DATE: "2023-08-31",
      Day: "Thu",
      Rooms: "0",
      OOO: "0",
      StayOver: "0",
      Arrivals: "0",
      DueOut: "0",
      Available: "0",
      GroupBlock: "0",
      GroupPickedUp: "0",
      TransNGTD: "0",
      TransGTD: "0",
      Occupied: "0",
      OccPercent: "0 %",
      RoomRev: "0",
      RevPAR: "0",
      ADR: "0",
      Ppl: "0 / 0",
    },
    {
      id: 9,
      propertyCode: "AC32AW",
      pullDateId: "0",
      AsOfDate: "2023-08-31",
      IDS_DATE: "2023-08-31",
      Day: "Thu",
      Rooms: "0",
      OOO: "0",
      StayOver: "0",
      Arrivals: "0",
      DueOut: "0",
      Available: "0",
      GroupBlock: "0",
      GroupPickedUp: "0",
      TransNGTD: "0",
      TransGTD: "0",
      Occupied: "0",
      OccPercent: "0 %",
      RoomRev: "0",
      RevPAR: "0",
      ADR: "0",
      Ppl: "0 / 0",
    },
    {
      id: 10,
      propertyCode: "AC32AW",
      pullDateId: "0",
      AsOfDate: "2023-08-31",
      IDS_DATE: "2023-08-31",
      Day: "Thu",
      Rooms: "0",
      OOO: "0",
      StayOver: "0",
      Arrivals: "0",
      DueOut: "0",
      Available: "0",
      GroupBlock: "0",
      GroupPickedUp: "0",
      TransNGTD: "0",
      TransGTD: "0",
      Occupied: "0",
      OccPercent: "0 %",
      RoomRev: "0",
      RevPAR: "0",
      ADR: "0",
      Ppl: "0 / 0",
    },
  ];

  const [choiceOccData, setChoiceOccData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [startRangeDate, setStartRangeDate] = useState();
  const [endRangeDate, setEndRangeDate] = useState();
  const startRangeDateRef = useRef();
  const endRangeDateRef = useRef();
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const defaultAsOfDateFromRTK = useSelector(
    (store) => store.headerProperty.defaultAsOfDate
  );
  const { snapshottitle, propertyCode, snapshotId } = useParams();
  const handleDateChange = (event) => {
    const [start, end] = event;
    let startSelectedDate = "",
      endSelectedDate = "";
    if (start) {
      let date = new Date(start);
      let month =
        date.getMonth() + 1 > 9
          ? date.getMonth() + 1
          : `0${date.getMonth() + 1}`;
      let dateFormat =
        date?.getDate() > 9 ? date.getDate() : `0${date.getDate()}`;
      startSelectedDate = `${date.getFullYear()}-${month}-${dateFormat}`;
      startRangeDateRef.current = startSelectedDate;
    }
    if (end) {
      let date = new Date(end);
      let month =
        date.getMonth() + 1 > 9
          ? date.getMonth() + 1
          : `0${date.getMonth() + 1}`;
      let dateFormat =
        date?.getDate() > 9 ? date.getDate() : `0${date.getDate()}`;
      endSelectedDate = `${date.getFullYear()}-${month}-${dateFormat}`;
      endRangeDateRef.current = endSelectedDate;
    }

    setStartRangeDate(event[0]);
    setEndRangeDate(event[1]);
    if (event[0] !== null && event[1] !== null) {
      // getChoiceOccDetail();
    }
  };

  const getChoiceOccDetail = async () => {
    setLoading(true);
    let payload = {
      AsOfDate: para?.asOfDate,
      propertyCode: para?.propertyCode,
    };

    if (startRangeDateRef.current && endRangeDateRef.current) {
      payload = {
        ...payload,
        startDate: startRangeDateRef.current,
        endDate: endRangeDateRef.current,
      };
    }
    let choiceOccResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.SnapShot +
          API_URL.WIDGET_MODULE.CHOICE_OCCUPANCY,
        body: payload,
      },
      false
    );

    let response = apiResponse(false, choiceOccResponse);
    if (response?.isValidate) {
      setLoading(false);
      setChoiceOccData(response?.data?.data);
    }

    if (!response?.isValidate) {
      setLoading(false);
      setChoiceOccData(tableData);
    }
  };

  useEffect(() => {
    if (api === 0) {
      setChoiceOccData(tableData);
    } else {
      if (para?.asOfDate) {
        getChoiceOccDetail();
      }
    }
  }, [para?.asOfDate]);

  const [tblids, settblids] = useState({
    tblChoiceOccupancy: commonService.GenerateGUID(),
  });

  return (
    <div className="widget-container">
      {api !== 0 && (
        <div className="gap-1 widget-container-menu">
          <Button className="heading-menu-btns" type="button">
            <img src={NoteIcon} alt="menuIcon" />
          </Button>
          <Button className="heading-menu-btns" type="button" title="Send Mail">
            <img
              src={emailIcon}
              alt="emailIcon"
              onClick={() => {
                commonService.fnSendWidgetMail(
                  tblids.tblChoiceOccupancy,
                  "Choice Occupancy",
                  snapshotId,
                  defaultAsOfDateFromRTK,
                  "",
                  para?.widgetId
                );
              }}
            />
          </Button>
          <Button className="heading-menu-btns" type="button">
            <img
              src={downloadIcon}
              alt="menuIcon"
              import={tblids.tblChoiceOccupancy}
              onClick={() =>
                commonService.fnExportInCSV(
                  tblids.tblChoiceOccupancy,
                  // "Choice Occupancy"
                  `${para?.propertyCode}-Choice Occupancy-${moment(
                    new Date()
                  ).format("MMDDYYYY")}`
                )
              }
            />
          </Button>
        </div>
      )}
      <div className="ViewPropertySetupTable">
        <div className="stripped__table">
        <div className="table-responsive tblDynamicPickupSticky">
          <Table
            id={tblids.tblChoiceOccupancy}
            className="positionStaticTable"
          >
            <thead className="tbl-header-sticky">
              <tr className="table-main-header-tr">
                <th
                  className="width175"
                  onClick={() => {
                    setOpenDatePicker(true);
                  }}
                >
                  Date{" "}
                  <DatePicker
                    onChange={(event) => handleDateChange(event)}
                    startDate={startRangeDate}
                    endDate={endRangeDate}
                    selectsRange
                    monthsShown={2}
                    open={openDatePicker}
                    shouldCloseOnSelect={false}
                  >
                    <div className="w-100" style={{ display: "inline-block" }}>
                      <div className="content-inside-calendar">
                        <p>
                          {startRangeDate
                            ? commonService.getDateInFormat(startRangeDate)
                            : ""}
                          {startRangeDate && endRangeDate ? " - " : ""}
                          {endRangeDate
                            ? commonService.getDateInFormat(endRangeDate)
                            : ""}
                        </p>
                        <div className="d-flex align-items-center gap-2">
                          <button
                            className="secondary-btn"
                            onClick={(e) => {
                              e.stopPropagation();
                              setStartRangeDate("");
                              setEndRangeDate("");
                              setOpenDatePicker(false);
                            }}
                          >
                            Cancel
                          </button>
                          <button
                            disabled={
                              startRangeDate && endRangeDate ? false : true
                            }
                            onClick={(e) => {
                              e.stopPropagation();
                              if (
                                startRangeDateRef.current &&
                                endRangeDateRef.current
                              ) {
                                getChoiceOccDetail();
                              }

                              setOpenDatePicker(false);
                            }}
                            className="primary-btn"
                          >
                            Apply
                          </button>
                        </div>
                      </div>
                    </div>
                  </DatePicker>
                  {startRangeDate && endRangeDate && (
                    <span
                      style={{ display: "none" }}
                    >{`${commonService.getDateInFormat(
                      startRangeDate
                    )} - ${commonService.getDateInFormat(endRangeDate)}`}</span>
                  )}
                </th>
                <th colSpan={20}></th>
              </tr>
              <tr className="table-main-header-tr">
                {/* <th className="width100">property Code</th>
                <th className="width100">pull Date Id</th> */}
                <th className="width100 text-start">As Of Date</th>
                <th className="width100 text-start">IDS DATE</th>
                <th className="width100 text-center">Day</th>
                <th className="width100 text-center">Rooms</th>
                <th className="width100 text-center">OOO</th>
                <th className="width100 text-center">Stay Over</th>
                <th className="width100 text-center">Arrivals</th>
                <th className="width100 text-center">Due Out</th>
                <th className="width100 text-center">Available</th>
                <th className="width100 text-center">Group Block</th>
                <th className="width100 text-center">Group Picked Up</th>
                <th className="width100 text-center">Trans NGTD</th>
                <th className="width100 text-center">Trans GTD</th>
                <th className="width100 text-center">Occupied</th>
                <th className="width100">Occ Percent</th>
                <th className="width100 text-center">Room Rev</th>
                <th className="width100 text-center">Rev PAR</th>
                <th className="width100 text-center">ADR</th>
                <th className="width100">Ppl</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr style={{ height: "80px", position: "relative" }}>
                  <Loader />
                </tr>
              ) : choiceOccData && choiceOccData?.length > 0 ? (
                choiceOccData?.map((item, index) => {
                  return (
                    <tr key={item?.id} className="shadowOnHover">
                      {/* <td>{item?.propertyCode}</td>
                    <td>{item?.pullDateId}</td> */}
                      <td className="text-start">
                        {commonService.getDateInFormat(item?.AsOfDate)}
                      </td>
                      <td className="text-start">
                        {commonService.getDateInFormat(item?.IDS_DATE)}
                      </td>
                      <td className="text-center">
                        {item?.Day ? item?.Day : 0}
                      </td>
                      <td className="text-center">
                        {item?.Rooms ? item?.Rooms : 0}
                      </td>
                      <td className="text-center">
                        {item?.OOO ? item?.OOO : 0}
                      </td>
                      <td className="text-center">
                        {item?.StayOver ? item?.StayOver : 0}
                      </td>
                      <td className="text-center">
                        {item?.Arrivals ? item?.Arrivals : 0}
                      </td>
                      <td className="text-center">
                        {item?.DueOut ? item?.DueOut : 0}
                      </td>
                      <td className="text-center">
                        {item?.Available ? item?.Available : 0}
                      </td>
                      <td className="text-center">
                        {item?.GroupBlock ? item?.GroupBlock : 0}
                      </td>
                      <td className="text-center">
                        {item?.GroupPickedUp ? item?.GroupPickedUp : 0}
                      </td>
                      <td className="text-center">
                        {item?.TransNGTD ? item?.TransNGTD : 0}
                      </td>
                      <td className="text-center">
                        {item?.TransGTD ? item?.TransGTD : 0}
                      </td>
                      <td className="text-center">
                        {item?.Occupied ? item?.Occupied : 0}
                      </td>
                      <td>{item?.OccPercent ? item?.OccPercent : 0}</td>
                      <td className="text-center">
                        {item?.RoomRev ? item?.RoomRev : 0}
                      </td>
                      <td className="text-center">
                        {item?.RevPAR ? item?.RevPAR : 0}
                      </td>
                      <td className="text-center">
                        {item?.ADR ? item?.ADR : 0}
                      </td>
                      <td>{item?.Ppl ? item?.Ppl : 0}</td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={21} className="text-center">
                    No Data Found
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChoiceOccupancy;
