import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart, ArcElement, Tooltip, Legend } from "chart.js";

const DoughnutChart = ({ data, options, boxWidth, boxHeight }) => {
  Chart.register(ArcElement, Tooltip, Legend);
  const legendItems = data.labels.map((label, index) => (
    <li key={index}>
      <span
        style={{
          display: "inline-block",
          width: "12px",
          height: "12px",
          backgroundColor: data.datasets[0].backgroundColor[index],
          marginRight: "5px",
        }}
      ></span>
      <span>{label}</span>
    </li>
  ));
  const defaultOptions = {
    legend: {
      display: true,
      position: "right",
      labels: {
        boxWidth: boxWidth,
        boxHeight: boxHeight,
      },
    },
  };

  const mergedOptions = { ...defaultOptions, ...options };
  return (
    <div>
      <div className="DoughnutChartMain">
        <div>
          <Doughnut
            data={data}
            options={mergedOptions}
            boxHeight={boxHeight}
            boxWidth={boxWidth}
            style={{ margin: "10px auto 20px" }}
          />
        </div>
        <ul style={{ listStyleType: "none" }} className="legends-list">
          {legendItems}
        </ul>
      </div>
    </div>
  );
};

export default DoughnutChart;
