import React, { useEffect, useRef, useState } from "react";
import { commonService } from "../../../../utils/commonService";
import { apiResponse } from "../../../../utils/apiResponse";
import { API_URL } from "../../../../utils/apiRoutes";
import { apiCall } from "../../../../utils/axiosService";
import moment from "moment";
import SingleDateSummaryModal from "../../SingleDateSummaryModal";
import DatePicker from "react-datepicker";
import NoteIcon from "../../../../Assets/Images/CRM/notes.svg";
import downloadIcon from "../../../../Assets/Images/Operation/downloadIcon.svg";
import { Button, Form, Overlay, Popover, Table } from "react-bootstrap";
import Loader from "../../../Loader/Loader";
import WidgetDetailModal from "../../WidgetDetailModal";
import { compName } from "../../../../utils/constant";
import infoIcon from "../../../../Assets/Images/Action Icons/infoIcon..svg";
import WidgetNote from "../../WidgetNote";
import { useSelector } from "react-redux";
import AddWidgetModal from "../../AddWidgetModal";
import { useParams } from "react-router-dom";
import emailIcon from "../../../../Assets/Images/Email.svg";
import Comments from "../Comments/Comment";
import { FixColumn } from "../../../../utils/dataTable";
import ticket from "../../../../Assets/Images/Revenue-management/tableTicket.svg";
import ToolsSingleTicketPopUp from "../../../CommonComponent/ToolsSingleTicketPopUp";
import EventViewModal from "../../../CommonComponent/EventViewModal";

const DayByDay = ({
  setCompDataFromChild,
  rowIndex,
  colIndex,
  api,
  para,
  setting,
}) => {
  let ooo = 0,
    RmsAvailable = 0,
    leftToSell = 0,
    otb = 0,
    occPercentage = 0,
    adr = 0,
    rev = 0,
    revPar = 0;

  const date = commonService.convertDateInTimezone(new Date());
  const startDate = commonService.getDateInDBFormat(
    commonService.convertDateInTimezone(
      new Date(date.getFullYear(), date.getMonth(), 1)
    )
  );
  const endDate = commonService.getDateInDBFormat(
    commonService.convertDateInTimezone(
      new Date(date.getFullYear(), date.getMonth() + 1, 0)
    )
  );
  const [ratePlan, setRatePlan] = useState(null);
  const [otbData, setOtbData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [otbLoading, setOtbLoading] = useState(false);
  const [startRangeDate, setStartRangeDate] = useState();
  const [endRangeDate, setEndRangeDate] = useState();
  const [selectedType, setSelectedType] = useState({
    netStly: true,
    pickup: false,
  });
  const [selectPickupDate, setSelectPickupDate] = useState();
  const startRangeDateRef = useRef();
  const endRangeDateRef = useRef();
  const [maxDateForPickupFrom, setMaxDateForPickupFrom] = useState();
  const [ONTHEBOOKS, setONTHEBOOKS] = useState({
    IsDisplay: false,
    Target: null,
    Position: "top",
  });
  const [selectedMarketSegment, setSelectedMarketSegment] = useState("");
  const [marketSegmentData, setMarketSegmentData] = useState(null);
  const dateRangeObj = useRef();
  const maxDateRef = useRef();
  const ratePlanData = useRef();
  const selectedTdIndexRef = useRef();
  const singleDateSelectedDateRef = useRef();
  const singleDatePickupDateRef = useRef();
  const ratePlanTotal = useRef();
  const [widgetModal, setWidgetModal] = useState(false);
  const widgetDetailRef = useRef();
  const [showNoteSidebar, setShowNoteSidebar] = useState(false);
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const eventListRef = useRef();
  const eventListPropsRef = useRef();
  const selectedEventDateRef = useRef();
  const [showTicketModal, setShowTicketModal] = useState(false);
  const propertyDetailFromRTK = useSelector(
    (store) => store.headerProperty.propertyDetail
  );
  const defaultAsOfDateFromRTK = useSelector(
    (store) => store.headerProperty.defaultAsOfDate
  );
  const { snapshottitle, propertyCode, snapshotId } = useParams();
  const closeNoteSideBar = () => {
    setShowNoteSidebar(false);
  };

  const closeWidgetModal = () => {
    setWidgetModal(false);
  };
  if (maxDateForPickupFrom) {
    let date = new Date(maxDateForPickupFrom);
    maxDateRef.current = date.setDate(date.getDate() - 1);
  }

  const handleFilterBtnClick = (event) => {
    // let screenY = event.screenY;
    // let Top = event.nativeEvent.layerY - 2;
    // let Left = event.nativeEvent.layerX + 30;
    // if (Top + 400 > window.innerHeight) {
    //   Top = Top - 400;
    // }
    // setONTHEBOOKS({ IsDisplay: true, Property: { top: Top, left: Left } });

    let Position = "bottom";
    let Top = event.nativeEvent.layerY - 2;
    if (Top + 400 > window.innerHeight) {
      Position = "top";
    }
    setONTHEBOOKS({
      IsDisplay: true,
      Target: event.target,
      Position: Position,
    });
  };
  const closeSingleDate = () => {
    // selectedTdIndexRef.current = -1;
    // setONTHEBOOKS({ IsDisplay: false, Property: { top: 0, left: 0 } });

    selectedTdIndexRef.current = -1;
    setONTHEBOOKS({
      IsDisplay: false,
      Target: null,
      Position: "bottom",
    });
  };

  const closeTicketModal = () => {
    setShowTicketModal(false);
  };

  const handleDateChange = (event) => {
    const [start, end] = event;
    let startSelectedDate = "",
      endSelectedDate = "";
    if (start) {
      let date = new Date(start);
      let month =
        date.getMonth() + 1 > 9
          ? date.getMonth() + 1
          : `0${date.getMonth() + 1}`;
      let dateFormat =
        date?.getDate() > 9 ? date.getDate() : `0${date.getDate()}`;
      startSelectedDate = `${date.getFullYear()}-${month}-${dateFormat}`;
      startRangeDateRef.current = commonService.getDateForNode(
        commonService.convertDateInTimezone(startSelectedDate)
      );
    }
    if (end) {
      let date = new Date(end);
      let month =
        date.getMonth() + 1 > 9
          ? date.getMonth() + 1
          : `0${date.getMonth() + 1}`;
      let dateFormat =
        date?.getDate() > 9 ? date.getDate() : `0${date.getDate()}`;
      endSelectedDate = `${date.getFullYear()}-${month}-${dateFormat}`;
      endRangeDateRef.current = commonService.getDateForNode(
        commonService.convertDateInTimezone(endSelectedDate)
      );
    }

    setStartRangeDate(event[0]);
    setEndRangeDate(event[1]);
    if (event[0] !== null && event[1] !== null) {
      // setRatePlan(null);
      // setOtbData(null);
      // dateRangeObj.current = commonService.datesBetweenTwoDate(
      //   startRangeDateRef.current ? startRangeDateRef.current : startDate,
      //   endRangeDateRef.current ? endRangeDateRef.current : endDate
      // );
      // if (selectedType?.netStly) {
      //   getDbdStlyData();
      // } else {
      //   getDbdPickupData();
      // }
      // getORGDetail();
    }
  };

  const diffDayStartDate = new Date(
    commonService.getDateInFormat(
      startRangeDateRef.current ? startRangeDateRef.current : startDate
    )
  );
  const diffDayEndDate = new Date(
    commonService.getDateInFormat(
      endRangeDateRef.current ? endRangeDateRef.current : endDate
    )
  );
  const diffDayInTime = diffDayEndDate.getTime() - diffDayStartDate.getTime();
  const diffDays = diffDayInTime / (1000 * 3600 * 24);

  const getDbdPickupData = async () => {
    setLoading(true);
    let pickupDate = new Date(para?.asOfDate);
    pickupDate.setDate(pickupDate.getDate() - 1);
    let dbdPickupResponse = await apiCall(
      {
        method: "POST",
        url: API_URL.BASE_API_URL.SnapShot + API_URL.RATEPLAN.DAY_BY_DAY_PICKUP,
        body: {
          AsOfDate: para?.asOfDate,
          startDate: startRangeDateRef.current
            ? startRangeDateRef.current
            : startDate,
          endDate: endRangeDateRef.current ? endRangeDateRef.current : endDate,
          propertyCode: para?.propertyCode,
          PickUpDate: selectPickupDate
            ? moment(selectPickupDate).format("YYYY-MM-DD")
            : moment(pickupDate).format("YYYY-MM-DD"),
          MarketSegment: selectedMarketSegment,
          // MarketSegment: "OTA Discount",
        },
      },
      false
    );

    let response = apiResponse(false, dbdPickupResponse);

    if (response?.isValidate) {
      ratePlanData.current = [];
      ratePlanTotal.current = {};
      response?.data?.data?.map((item, index) => {
        let marketSegmentExist = ratePlanData.current?.filter(
          (x) => x === item?.rateCode
        );
        if (marketSegmentExist?.length <= 0) {
          ratePlanData.current = [...ratePlanData.current, item?.rateCode];

          ratePlanTotal.current[`${item?.rateCode}CY_ROOMNIGHT`] = 0;
          ratePlanTotal.current[`${item?.rateCode}CY_REVENUE`] = 0;
          ratePlanTotal.current[`${item?.rateCode}PICKUP_FROM_ROOM`] = 0;
          ratePlanTotal.current[`${item?.rateCode}PICKUP_REVENUE`] = 0;
        }
      });
      setRatePlan(response?.data?.data);
      setLoading(false);
    }

    if (!response?.isValidate) {
      setLoading(false);
      setRatePlan([]);
    }
  };

  const getDbdStlyData = async () => {
    setLoading(true);
    let dbdStlyResponse = await apiCall(
      {
        method: "POST",
        url: API_URL.BASE_API_URL.SnapShot + API_URL.RATEPLAN.DAY_BY_DAY_STLY,
        body: {
          AsOfDate: para?.asOfDate,
          startDate: startRangeDateRef.current
            ? startRangeDateRef.current
            : startDate,
          endDate: endRangeDateRef.current ? endRangeDateRef.current : endDate,
          propertyCode: para?.propertyCode,
          MarketSegment: selectedMarketSegment,
          // MarketSegment: "OTA Discount",
        },
      },
      false
    );

    let response = apiResponse(false, dbdStlyResponse);

    if (response?.isValidate) {
      ratePlanData.current = [];
      ratePlanTotal.current = {};
      response?.data?.data?.map((item, index) => {
        let ratePlanExist = ratePlanData.current?.filter(
          (x) => x === item?.rateCode
        );
        if (ratePlanExist?.length <= 0) {
          ratePlanData.current = [...ratePlanData.current, item?.rateCode];

          ratePlanTotal.current[`${item?.rateCode}CY_ROOMNIGHT`] = 0;
          ratePlanTotal.current[`${item?.rateCode}LY_ROOMNIGHT`] = 0;
          ratePlanTotal.current[`${item?.rateCode}CY_REVENUE`] = 0;
          ratePlanTotal.current[`${item?.rateCode}LY_REVENUE`] = 0;
        }
      });
      setRatePlan(response?.data?.data);
      setLoading(false);
    }

    if (!response?.isValidate) {
      setLoading(false);
      setRatePlan([]);
    }
  };

  const getEventList = async () => {
    let eventList = await apiCall(
      {
        method: "POST",
        url: API_URL.BASE_API_URL.RevenueManagement + API_URL.EVENTS.GET_LIST,
        body: {
          pageNum: 1,
          pageSize: 10,
          sortColumn: "",
          sortDirection: "asc",
          searchValue: "",
          propertyid: parseInt(propertyDetailFromRTK?.propertyid),
          startdate: null,
          enddate: null,
          eventname: "",
          eventtypeterm: "",
          yearterm: ``,
        },
      },
      false
    );

    let eventListResponse = apiResponse(false, eventList);

    if (eventListResponse?.isValidate) {
      let eventListArr = [];

      eventListResponse?.data?.data?.forEach((item) => {
        let singleEventObj = {
          id: item?.eventid ? item?.eventid : 0,
          name: item?.eventname,
          detail: item?.eventdetails,
          startDate: item?.startdate,
          endDate: item?.enddate,
          eventJson: item?.eventjson,
          eventResourceId: item?.eventsourceid,
        };
        let eventJsonData = "";
        if (item?.eventjson) {
          var decodedString = decodeURIComponent(item?.eventjson);
          const jsonStringWithoutQuotes = decodedString.slice(1, -1);
          const jsonStringWithSingleQuotes = `${jsonStringWithoutQuotes}`;
          eventJsonData = JSON.parse(jsonStringWithSingleQuotes);
          let desc = eventJsonData?.description?.replaceAll("+", " ");
          singleEventObj = {
            ...singleEventObj,
            labels: eventJsonData?.labels ? eventJsonData?.labels : "",
            rank: eventJsonData?.rank ? eventJsonData?.rank : 0,
            local_rank: eventJsonData?.local_rank
              ? eventJsonData?.local_rank
              : 0,
            phq_attendance: eventJsonData?.phq_attendance
              ? eventJsonData?.phq_attendance
              : 0,
            geo_lat: eventJsonData?.geo_lat ? eventJsonData?.geo_lat : "",
            geo_long: eventJsonData?.geo_long ? eventJsonData?.geo_long : "",
            detail: desc,
          };
        }
        eventListArr.push(singleEventObj);
      });
      eventListRef.current = eventListArr;
    }

    if (!eventListResponse?.isValidate) {
      eventListRef.current = [];
    }
  };

  const getORGDetail = async () => {
    setOtbLoading(true);

    await getEventList();

    let orgDetailResponse = await apiCall(
      {
        method: "POST",
        url: API_URL.BASE_API_URL.SnapShot + API_URL.DAILY_SUMMARY.ORG,
        body: {
          AsOfDate: para?.asOfDate,
          startDate: startRangeDateRef.current
            ? startRangeDateRef.current
            : startDate,
          endDate: endRangeDateRef.current ? endRangeDateRef.current : endDate,
          propertyCode: para?.propertyCode,
        },
      },
      false
    );

    let response = apiResponse(false, orgDetailResponse);

    if (response?.isValidate) {
      setOtbData(response?.data?.data);
      setOtbLoading(false);
    }

    if (!response?.isValidate) {
      setOtbLoading(false);
    }
  };

  const getMarketSegmentData = async () => {
    let marketSegmentDataResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.Configuration +
          API_URL.PROJECT_TERM.GET_LIST_BY_MULTIPLE_CATEGORY,
        body: { categorynamebycomma: "Market Segment" },
      },
      false
    );

    let response = apiResponse(false, marketSegmentDataResponse);

    if (response?.isValidate) {
      setSelectedMarketSegment(response?.data?.data?.[0]?.term);
      setMarketSegmentData(response?.data?.data);
    }

    if (!response?.isValidate) {
      setMarketSegmentData(null);
    }
  };

  useEffect(() => {
    if (api === 0) {
      dateRangeObj.current = commonService.datesBetweenTwoDate(
        startRangeDateRef.current
          ? commonService.convertDateInTimezone(startRangeDateRef.current)
          : commonService.convertDateInTimezone(startDate),
        endRangeDateRef.current
          ? commonService.convertDateInTimezone(endRangeDateRef.current)
          : commonService.convertDateInTimezone(endDate)
      );
      let d = new Date(para?.asOfDate);
      d.setDate(d.getDate() - 1);
      setMaxDateForPickupFrom(new Date(para?.defaultAsOfDate));
      setOtbData([]);
      setRatePlan([]);
    } else {
      if (para?.asOfDate && selectedMarketSegment) {
        dateRangeObj.current = commonService.datesBetweenTwoDate(
          startRangeDateRef.current
            ? commonService.convertDateInTimezone(startRangeDateRef.current)
            : commonService.convertDateInTimezone(startDate),
          endRangeDateRef.current
            ? commonService.convertDateInTimezone(endRangeDateRef.current)
            : commonService.convertDateInTimezone(endDate)
        );
        let d = new Date(para?.asOfDate);
        d.setDate(d.getDate() - 1);
        setMaxDateForPickupFrom(new Date(para?.defaultAsOfDate));
        getORGDetail();
        if (selectedType?.netStly) {
          getDbdStlyData();
        } else {
          getDbdPickupData();
        }
      }
    }
  }, [para?.asOfDate, selectedType, selectPickupDate, selectedMarketSegment]);

  useEffect(() => {
    if (api === 0) {
    } else {
      getMarketSegmentData();
    }
  }, []);

  const [tblids, settblids] = useState({
    tblDayByDayRatePlan: commonService.GenerateGUID(),
  });

  commonService.setBackColorPerByTDValue(
    "#638EC6",
    "Red",
    `#${tblids.tblDayByDayRatePlan} .perbackground-otb`
  );
  commonService.setBackColorPerByTDValue(
    "Green",
    "Red",
    `#${tblids.tblDayByDayRatePlan} .perbackground-adr`
  );

  FixColumn(tblids.tblDayByDayRatePlan, [1]);

  return (
    <div className="widget-container">
      {api !== 0 && (
        <div className="gap-1 widget-container-menu">
          {/* <Button className="heading-menu-btns" type="button">
            <img
              src={NoteIcon}
              alt="menuIcon"
              onClick={() => {
                setShowNoteSidebar(true);
              }}
            />
          </Button> */}
          <Button className="heading-menu-btns" type="button" title="Send Mail">
            <img
              src={emailIcon}
              alt="emailIcon"
              onClick={() => {
                commonService.fnSendWidgetMail(
                  tblids.tblDayByDayRatePlan,
                  "Day By Day Rate Plan",
                  snapshotId,
                  defaultAsOfDateFromRTK,
                  "",
                  para?.widgetId
                );
              }}
            />
          </Button>
          <Button className="heading-menu-btns" type="button">
            <img
              src={infoIcon}
              alt="info"
              onClick={() => {
                widgetDetailRef.current = {
                  widgetdetails: "",
                  usernote: "",
                  compName: compName?.ratePlanDayByDay,
                  widgetId: para?.widgetId,
                };
                setWidgetModal(true);
              }}
            />
          </Button>
          <Button className="heading-menu-btns" type="button">
            <img
              src={downloadIcon}
              alt="menuIcon"
              import={tblids.tblDayByDayRatePlan}
              onClick={() =>
                commonService.fnExportInCSV(
                  tblids.tblDayByDayRatePlan,
                  // "Day By Day Rate Plan"
                  `${para?.propertyCode}-Day By Day Rate Plan-${moment(
                    new Date()
                  ).format("MMDDYYYY")}`
                )
              }
            />
          </Button>
        </div>
      )}
      <div className="ViewPropertySetupTable">
        <div className="stripped__table">
          <div className="table-responsive tblDynamicPickupSticky">
            <Table id={tblids.tblDayByDayRatePlan}>
              <thead className="tbl-header-sticky">
                <tr className="table-main-header-tr">
                  <th>Date</th>
                  <th className="text-center border-right" colSpan={5}>
                    On The Books
                  </th>
                  <th className="text-center border-right" colSpan={2}>
                    Revenue
                  </th>
                  <th
                    className="text-center border-right"
                    colSpan={ratePlanData.current?.length * 4}
                  >
                    <div className="d-flex align-items-center gap-2">
                      <div className="form-groups form-groups-radio-btn d-flex justify-content-center">
                        <div className="d-flex heatmap-main align-item-center">
                          <div className="form-radio d-flex align-items-center">
                            <input
                              type="radio"
                              className="form-check-input"
                              name="ratePlanDbDNetStly"
                              id="ratePlanDbDNetStly"
                              value="Inactive"
                              checked={selectedType?.netStly}
                              onChange={() => {
                                ratePlanData.current = [];
                                setSelectedType({
                                  pickup: false,
                                  netStly: true,
                                });
                              }}
                            />
                            <label
                              htmlFor="ratePlanDbDNetStly"
                              className="label-radio form-check-label m-0"
                            >
                              Net Stly
                            </label>
                          </div>

                          <div className="d-flex align-items-center gap-3">
                            <div className="form-radio d-flex align-items-center">
                              <input
                                type="radio"
                                className="form-check-input"
                                name="ratePlanDbDpickup"
                                id="ratePlanDbDpickup"
                                value="Active"
                                checked={selectedType?.pickup}
                                onChange={() => {
                                  ratePlanData.current = [];
                                  setSelectedType({
                                    pickup: true,
                                    netStly: false,
                                  });
                                }}
                              />
                              <label
                                htmlFor="ratePlanDbDpickup"
                                className="label-radio form-check-label m-0"
                              >
                                Pickup From
                              </label>
                            </div>
                            <div>
                              <DatePicker
                                id="asOfDate"
                                disabled={selectedType?.pickup ? false : true}
                                maxDate={new Date(maxDateRef.current)}
                                selected={
                                  selectPickupDate
                                    ? commonService.convertDateInTimezone(
                                        selectPickupDate
                                      )
                                    : commonService.convertDateInTimezone(
                                        maxDateRef.current
                                      )
                                }
                                onChange={(date) => {
                                  let month =
                                    date.getMonth() + 1 > 9
                                      ? date.getMonth() + 1
                                      : `0${date.getMonth() + 1}`;
                                  let dateFormat =
                                    date?.getDate() > 9
                                      ? date.getDate()
                                      : `0${date.getDate()}`;
                                  let selectedDate = `${date.getFullYear()}-${month}-${dateFormat}`;
                                  setSelectPickupDate(selectedDate);
                                }}
                              />
                              <span style={{ display: "none" }}>
                                {selectedType?.pickup && selectPickupDate
                                  ? commonService.getDateInFormat(
                                      new Date(selectPickupDate)
                                    )
                                  : commonService.getDateInFormat(
                                      new Date(maxDateRef.current)
                                    )}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="from-groups no-border-break">
                        <Form.Select
                          value={selectedMarketSegment}
                          onChange={(e) => {
                            setSelectedMarketSegment(e.target.value);
                          }}
                        >
                          {marketSegmentData?.map((item, index) => (
                            <option key={item?.termid} value={item?.term}>
                              {item?.term}
                            </option>
                          ))}
                        </Form.Select>
                        <span style={{ display: "none" }}>
                          {selectedMarketSegment}
                        </span>
                      </div>
                    </div>
                  </th>
                </tr>
                <tr className="table-main-header-tr">
                  <th className="border-right"></th>
                  <th className="border-right" colSpan={5}></th>
                  <th className="border-right" colSpan={2}></th>
                  {ratePlanData.current?.map((item) => (
                    <th
                      className="width200 text-center border-right"
                      colSpan={4}
                      key={item}
                    >
                      {item}
                    </th>
                  ))}
                </tr>
                <tr className="table-main-header-tr">
                  <th
                    className="width200"
                    onClick={() => {
                      setOpenDatePicker(true);
                    }}
                  >
                    Date{" "}
                    <DatePicker
                      onChange={(event) => handleDateChange(event)}
                      startDate={startRangeDate}
                      endDate={endRangeDate}
                      selectsRange
                      monthsShown={2}
                      open={openDatePicker}
                      shouldCloseOnSelect={false}
                    >
                      <div
                        className="w-100"
                        style={{ display: "inline-block" }}
                      >
                        <div className="content-inside-calendar">
                          <p>
                            {startRangeDate
                              ? commonService.getDateInFormat(startRangeDate)
                              : ""}
                            {startRangeDate && endRangeDate ? " - " : ""}
                            {endRangeDate
                              ? commonService.getDateInFormat(endRangeDate)
                              : ""}
                          </p>
                          <div className="d-flex align-items-center gap-2">
                            <button
                              className="secondary-btn"
                              onClick={(e) => {
                                e.stopPropagation();
                                setStartRangeDate("");
                                setEndRangeDate("");
                                setOpenDatePicker(false);
                              }}
                            >
                              Cancel
                            </button>
                            <button
                              disabled={
                                startRangeDate && endRangeDate ? false : true
                              }
                              onClick={(e) => {
                                e.stopPropagation();
                                if (
                                  startRangeDateRef.current &&
                                  endRangeDateRef.current
                                ) {
                                  setRatePlan(null);
                                  setOtbData(null);
                                  dateRangeObj.current =
                                    commonService.datesBetweenTwoDate(
                                      startRangeDateRef.current
                                        ? commonService.convertDateInTimezone(
                                            startRangeDateRef.current
                                          )
                                        : commonService.convertDateInTimezone(
                                            startDate
                                          ),
                                      endRangeDateRef.current
                                        ? commonService.convertDateInTimezone(
                                            endRangeDateRef.current
                                          )
                                        : commonService.convertDateInTimezone(
                                            endDate
                                          )
                                    );
                                  if (selectedType?.netStly) {
                                    getDbdStlyData();
                                  } else {
                                    getDbdPickupData();
                                  }
                                  getORGDetail();
                                }

                                setOpenDatePicker(false);
                              }}
                              className="primary-btn"
                            >
                              Apply
                            </button>
                          </div>
                        </div>
                      </div>
                    </DatePicker>
                    {startRangeDate && endRangeDate ? (
                      <span
                        style={{ display: "none" }}
                      >{`${commonService.getDateInFormat(
                        startRangeDate
                      )} - ${commonService.getDateInFormat(
                        endRangeDate
                      )}`}</span>
                    ) : (
                      ""
                    )}
                  </th>
                  <th className="width100 border-right">OOO/Rooms Available</th>
                  <th className="width100 text-center">Left To sell</th>
                  <th className="width100 text-center">On The Books</th>
                  <th>Total OCC%</th>
                  <th className="text-end">ADR</th>
                  <th className="border-right text-start">Rev</th>
                  <th className="text-end">RevPAr</th>
                  {ratePlanData.current?.map((item, index) => (
                    <React.Fragment key={index}>
                      <th className="border-right text-center">RMS</th>
                      <th className="text-center">
                        {selectedType?.netStly ? "STLY" : "Pickup"}
                      </th>
                      <th className="text-end">ADR</th>
                      <th className="text-end">
                        {selectedType?.netStly ? "STLY" : "Pickup"}
                      </th>
                    </React.Fragment>
                  ))}
                </tr>
              </thead>
              <tbody>
                {loading === true || otbLoading === true ? (
                  <tr style={{ height: "80px", position: "relative" }}>
                    <Loader />
                  </tr>
                ) : (
                  // otbData &&
                  // ratePlan &&
                  dateRangeObj.current &&
                  Object.keys(dateRangeObj.current)?.map((item, index) => {
                    let singleOTBData = otbData?.filter(
                      (x) => x?.Date === item
                    );
                    let dayName = false;

                    ooo = ooo + singleOTBData?.[0]?.OOO;
                    RmsAvailable =
                      RmsAvailable + singleOTBData?.[0]?.RoomAvailable;
                    leftToSell = leftToSell + singleOTBData?.[0]?.LeftToSell;
                    otb = otb + singleOTBData?.[0]?.OnTheBook;
                    occPercentage =
                      occPercentage + singleOTBData?.[0]?.TotalOCCPercentage;
                    adr = adr + singleOTBData?.[0]?.ADR;
                    rev = rev + singleOTBData?.[0]?.REV;
                    revPar = revPar + singleOTBData?.[0]?.RevPAR;

                    if (
                      moment(
                        commonService.convertDateInTimezone(item),
                        "l"
                      ).format("ddd") === "Fri" ||
                      moment(
                        commonService.convertDateInTimezone(item),
                        "l"
                      ).format("ddd") === "Sat"
                    ) {
                      dayName = true;
                    }

                    let checkEvent = eventListRef.current?.filter((x) => {
                      let checkingDate = moment(item);
                      let startingDate = moment(x?.startDate);
                      let endingDate = moment(x?.endDate);
                      if (
                        checkingDate.isSameOrAfter(startingDate) &&
                        checkingDate.isSameOrBefore(endingDate)
                      ) {
                        return x;
                      }
                    });

                    return (
                      <React.Fragment key={item}>
                        <tr
                          key={item}
                          className={`${
                            dayName ? "backgroundTr" : ""
                          } shadowOnHover`}
                        >
                          <td>
                            <div className="d-flex gap-4">
                              {commonService.getDateInFormat(
                                commonService.convertDateInTimezone(item)
                              )}{" "}
                              {moment(
                                commonService.convertDateInTimezone(item),
                                "l"
                              ).format("ddd")}
                              <div className="d-flex justify-content-between align-items-center gap-2 ticketAlarmReload">
                                {checkEvent?.length > 0 && (
                                  <img
                                    style={{ height: "11px", width: "11px" }}
                                    className="iconcolor"
                                    src={ticket}
                                    alt="ticket"
                                    onClick={() => {
                                      selectedEventDateRef.current = item;
                                      eventListPropsRef.current = checkEvent;
                                      setShowTicketModal(true);
                                    }}
                                  />
                                )}
                              </div>
                            </div>
                          </td>
                          <td className="width100 border-right">
                            {singleOTBData?.[0]?.OOO
                              ? commonService.formateRoom(singleOTBData?.[0]?.OOO)
                              : 0}
                            /
                            {singleOTBData?.[0]?.RoomAvailable
                              ? commonService.formateRoom(singleOTBData?.[0]?.RoomAvailable)
                              : 0}
                          </td>
                          <td className="text-center">
                            {singleOTBData?.[0]?.LeftToSell
                              ? commonService.formateRoom(singleOTBData?.[0]?.LeftToSell)
                              : 0}
                          </td>
                          <td
                            className="text-primary text-center perbackground perbackground-otb"
                            value={singleOTBData?.[0]?.OnTheBook}
                          >
                            <span
                              className={`${
                                selectedTdIndexRef.current === index
                                  ? "singleDateSummarySpan"
                                  : ""
                              }`}
                              onClick={(e) => {
                                if (api !== 0) {
                                  singleDateSelectedDateRef.current = item;
                                  handleFilterBtnClick(e);
                                  selectedTdIndexRef.current = index;
                                }
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              {singleOTBData?.[0]?.OnTheBook
                                ? commonService.formateRoom(singleOTBData?.[0]?.OnTheBook)
                                : 0}
                            </span>
                          </td>

                          <td>
                            {singleOTBData?.[0]?.TotalOCCPercentage
                              ? `${singleOTBData?.[0]?.TotalOCCPercentage}%`
                              : `0%`}
                          </td>
                          <td
                            className="text-end perbackground perbackground-adr"
                            value={singleOTBData?.[0]?.ADR}
                          >
                            {singleOTBData?.[0]?.ADR
                              ? `${commonService.formateAmount(
                                  singleOTBData?.[0]?.ADR
                                )}`
                              : `${commonService.formateAmount(0)}`}
                          </td>
                          <td className="text-start border-right">
                            {singleOTBData?.[0]?.REV
                              ? `${commonService.formateAmount(
                                  singleOTBData?.[0]?.REV
                                )}`
                              : `${commonService.formateAmount(0)}`}
                          </td>
                          <td className="text-end">
                            {singleOTBData?.[0]?.RevPAR
                              ? `${commonService.formateAmount(
                                  singleOTBData?.[0]?.RevPAR
                                )}`
                              : `${commonService.formateAmount(0)}`}
                          </td>
                          {ratePlanData.current?.map((ratePlanItem, index) => {
                            let findRatePlanData = ratePlan?.filter(
                              (x) =>
                                x?.staydate === item &&
                                x?.rateCode === ratePlanItem
                            );

                            if (selectedType?.netStly) {
                              ratePlanTotal.current[
                                `${findRatePlanData?.[0]?.rateCode}CY_ROOMNIGHT`
                              ] = findRatePlanData?.[0]?.cy_roomnight
                                ? ratePlanTotal.current[
                                    `${findRatePlanData?.[0]?.rateCode}CY_ROOMNIGHT`
                                  ] +
                                  parseFloat(
                                    findRatePlanData?.[0]?.cy_roomnight
                                  )
                                : ratePlanTotal.current[
                                    `${findRatePlanData?.[0]?.rateCode}CY_ROOMNIGHT`
                                  ];

                              ratePlanTotal.current[
                                `${findRatePlanData?.[0]?.rateCode}LY_ROOMNIGHT`
                              ] = findRatePlanData?.[0]?.ly_roomnight
                                ? ratePlanTotal.current[
                                    `${findRatePlanData?.[0]?.rateCode}LY_ROOMNIGHT`
                                  ] +
                                  parseFloat(
                                    findRatePlanData?.[0]?.ly_roomnight
                                  )
                                : ratePlanTotal.current[
                                    `${findRatePlanData?.[0]?.rateCode}LY_ROOMNIGHT`
                                  ];

                              ratePlanTotal.current[
                                `${findRatePlanData?.[0]?.rateCode}CY_REVENUE`
                              ] = findRatePlanData?.[0]?.cy_revenue
                                ? ratePlanTotal.current[
                                    `${findRatePlanData?.[0]?.rateCode}CY_REVENUE`
                                  ] +
                                  parseFloat(findRatePlanData?.[0]?.cy_revenue)
                                : ratePlanTotal.current[
                                    `${findRatePlanData?.[0]?.rateCode}CY_REVENUE`
                                  ];

                              ratePlanTotal.current[
                                `${findRatePlanData?.[0]?.rateCode}LY_REVENUE`
                              ] = findRatePlanData?.[0]?.ly_revenue
                                ? ratePlanTotal.current[
                                    `${findRatePlanData?.[0]?.rateCode}LY_REVENUE`
                                  ] +
                                  parseFloat(findRatePlanData?.[0]?.ly_revenue)
                                : ratePlanTotal.current[
                                    `${findRatePlanData?.[0]?.rateCode}LY_REVENUE`
                                  ];
                            } else {
                              ratePlanTotal.current[
                                `${findRatePlanData?.[0]?.rateCode}CY_ROOMNIGHT`
                              ] = findRatePlanData?.[0]?.cy_roomnight
                                ? ratePlanTotal.current[
                                    `${findRatePlanData?.[0]?.rateCode}CY_ROOMNIGHT`
                                  ] +
                                  parseFloat(
                                    findRatePlanData?.[0]?.cy_roomnight
                                  )
                                : ratePlanTotal.current[
                                    `${findRatePlanData?.[0]?.rateCode}CY_ROOMNIGHT`
                                  ];

                              ratePlanTotal.current[
                                `${findRatePlanData?.[0]?.rateCode}CY_REVENUE`
                              ] = findRatePlanData?.[0]?.cy_revenue
                                ? ratePlanTotal.current[
                                    `${findRatePlanData?.[0]?.rateCode}CY_REVENUE`
                                  ] +
                                  parseFloat(findRatePlanData?.[0]?.cy_revenue)
                                : ratePlanTotal.current[
                                    `${findRatePlanData?.[0]?.rateCode}CY_REVENUE`
                                  ];

                              ratePlanTotal.current[
                                `${findRatePlanData?.[0]?.rateCode}PICKUP_FROM_ROOM`
                              ] = findRatePlanData?.[0]?.PICKUP_FROM_ROOM
                                ? ratePlanTotal.current[
                                    `${findRatePlanData?.[0]?.rateCode}PICKUP_FROM_ROOM`
                                  ] +
                                  parseFloat(
                                    findRatePlanData?.[0]?.PICKUP_FROM_ROOM
                                  )
                                : ratePlanTotal.current[
                                    `${findRatePlanData?.[0]?.rateCode}PICKUP_FROM_ROOM`
                                  ];

                              ratePlanTotal.current[
                                `${findRatePlanData?.[0]?.rateCode}PICKUP_REVENUE`
                              ] = findRatePlanData?.[0]?.pickup_revenue
                                ? ratePlanTotal.current[
                                    `${findRatePlanData?.[0]?.rateCode}PICKUP_REVENUE`
                                  ] +
                                  parseFloat(
                                    findRatePlanData?.[0]?.pickup_revenue
                                  )
                                : ratePlanTotal.current[
                                    `${findRatePlanData?.[0]?.rateCode}PICKUP_REVENUE`
                                  ];
                            }
                            return (
                              <React.Fragment key={`${ratePlanItem}${item}`}>
                                <td className="border-right text-center">
                                  {findRatePlanData?.[0]?.cy_roomnight
                                    ? commonService.formateRoom(findRatePlanData?.[0]?.cy_roomnight)
                                    : 0}
                                </td>
                                <td className="text-center">
                                  {selectedType?.netStly
                                    ? findRatePlanData?.[0]?.ly_roomnight
                                      ? commonService.formateRoom(findRatePlanData?.[0]?.ly_roomnight)
                                      : 0
                                    : findRatePlanData?.[0]?.PICKUP_FROM_ROOM
                                    ? commonService.formateRoom(findRatePlanData?.[0]?.PICKUP_FROM_ROOM)
                                    : 0}
                                </td>
                                <td className="text-end">
                                  {selectedType?.netStly
                                    ? findRatePlanData?.[0]?.cy_ADR
                                      ? `${commonService.formateAmount(
                                          findRatePlanData?.[0]?.cy_ADR
                                        )}`
                                      : `${commonService.formateAmount(0)}`
                                    : findRatePlanData?.[0]?.cy_ADR
                                    ? `${commonService.formateAmount(
                                        findRatePlanData?.[0]?.cy_ADR
                                      )}`
                                    : `${commonService.formateAmount(0)}`}
                                </td>
                                <td className="text-end">
                                  {selectedType?.netStly
                                    ? findRatePlanData?.[0]?.ly_ADR
                                      ? `${commonService.formateAmount(
                                          findRatePlanData?.[0]?.ly_ADR
                                        )}`
                                      : `${commonService.formateAmount(0)}`
                                    : findRatePlanData?.[0]?.ADR_PICKUP
                                    ? `${commonService.formateAmount(
                                        findRatePlanData?.[0]?.ADR_PICKUP
                                      )}`
                                    : `${commonService.formateAmount(0)}`}
                                </td>
                              </React.Fragment>
                            );
                          })}
                        </tr>
                        {index ===
                          Object.keys(dateRangeObj.current)?.length - 1 && (
                          <tr className="shadowOnHover">
                            <td className="boldTd">Total</td>
                            <td className="boldTd border-right">
                              {commonService.formateRoom(ooo)}/{commonService.formateRoom(RmsAvailable)}
                            </td>
                            <td className="boldTd text-center">{commonService.formateRoom(leftToSell)}</td>
                            <td className="boldTd text-center">{commonService.formateRoom(otb)}</td>
                            <td className="boldTd">
                              {occPercentage > 0
                                ? `${parseInt(occPercentage / diffDays)}%`
                                : `0%`}
                            </td>
                            <td className="boldTd text-end">
                              {rev > 0 && otb > 0
                                ? `${commonService.formateAmount(
                                    parseInt(rev / otb)
                                  )}`
                                : `${commonService.formateAmount(0)}`}
                            </td>
                            <td className="boldTd text-end border-right">
                              {commonService.formateAmount(rev)}
                            </td>
                            <td className="boldTd text-end">
                              {revPar > 0
                                ? `${commonService.formateAmount(
                                    parseInt(revPar / diffDays)
                                  )}`
                                : `${commonService.formateAmount(0)}`}
                            </td>
                            {ratePlanData.current?.map((totalItem, index) => {
                              let rms = 0,
                                adr = 0,
                                firstStly = 0,
                                secondStly = 0,
                                firstPickup = 0,
                                secondPickup = 0;
                              if (selectedType?.netStly) {
                                rms =
                                  ratePlanTotal.current[
                                    `${totalItem}CY_ROOMNIGHT`
                                  ];

                                firstStly =
                                  ratePlanTotal.current[
                                    `${totalItem}LY_ROOMNIGHT`
                                  ];

                                if (
                                  ratePlanTotal.current[
                                    `${totalItem}CY_REVENUE`
                                  ] > 0 &&
                                  ratePlanTotal.current[
                                    `${totalItem}CY_ROOMNIGHT`
                                  ] > 0
                                ) {
                                  adr =
                                    ratePlanTotal.current[
                                      `${totalItem}CY_REVENUE`
                                    ] /
                                    ratePlanTotal.current[
                                      `${totalItem}CY_ROOMNIGHT`
                                    ];
                                }

                                if (
                                  ratePlanTotal.current[
                                    `${totalItem}LY_REVENUE`
                                  ] > 0 &&
                                  ratePlanTotal.current[
                                    `${totalItem}LY_ROOMNIGHT`
                                  ] > 0
                                ) {
                                  secondStly =
                                    ratePlanTotal.current[
                                      `${totalItem}LY_REVENUE`
                                    ] /
                                    ratePlanTotal.current[
                                      `${totalItem}LY_ROOMNIGHT`
                                    ];
                                }
                              } else {
                                rms =
                                  ratePlanTotal.current[
                                    `${totalItem}CY_ROOMNIGHT`
                                  ];

                                firstPickup =
                                  ratePlanTotal.current[
                                    `${totalItem}PICKUP_FROM_ROOM`
                                  ];

                                if (
                                  ratePlanTotal.current[
                                    `${totalItem}CY_REVENUE`
                                  ] > 0 &&
                                  ratePlanTotal.current[
                                    `${totalItem}CY_ROOMNIGHT`
                                  ] > 0
                                ) {
                                  adr =
                                    ratePlanTotal.current[
                                      `${totalItem}CY_REVENUE`
                                    ] /
                                    ratePlanTotal.current[
                                      `${totalItem}CY_ROOMNIGHT`
                                    ];
                                }

                                if (
                                  ratePlanTotal.current[
                                    `${totalItem}PICKUP_REVENUE`
                                  ] > 0 &&
                                  ratePlanTotal.current[
                                    `${totalItem}PICKUP_FROM_ROOM`
                                  ] > 0
                                ) {
                                  secondPickup =
                                    ratePlanTotal.current[
                                      `${totalItem}PICKUP_REVENUE`
                                    ] /
                                    ratePlanTotal.current[
                                      `${totalItem}PICKUP_FROM_ROOM`
                                    ];
                                }
                              }
                              return (
                                <>
                                  <td className="boldTd text-center border-right">
                                    {commonService.formateRoom(Math.round(rms))}
                                  </td>
                                  <td className="boldTd text-center">
                                    {selectedType?.netStly
                                      ? commonService.formateRoom(Math.round(firstStly))
                                      : commonService.formateRoom(Math.round(firstPickup))}
                                  </td>
                                  <td className="boldTd text-end">
                                    {commonService.formateAmount(
                                      Math.round(adr)
                                    )}
                                  </td>
                                  <td className="boldTd text-end">
                                    {selectedType?.netStly
                                      ? commonService.formateAmount(
                                          Math.round(secondStly)
                                        )
                                      : commonService.formateAmount(
                                          Math.round(secondPickup)
                                        )}
                                  </td>
                                </>
                              );
                            })}
                          </tr>
                        )}
                      </React.Fragment>
                    );
                  })
                )}
              </tbody>
            </Table>
          </div>
        </div>
        {showTicketModal && (
          <EventViewModal
            showModal={showTicketModal}
            closeModal={closeTicketModal}
            eventDetails={eventListPropsRef.current}
            selectedDate={selectedEventDateRef.current}
            getEventList={getEventList}
          />
        )}
      </div>
      {api !== 0 && <Comments widgetDetails={para} editorId={"DayByDayRp"} />}

      <Overlay
        show={ONTHEBOOKS.IsDisplay}
        target={ONTHEBOOKS.Target}
        placement={ONTHEBOOKS.Position}
      >
        <Popover
          id="popover-contained"
          className="SingleDateSummaryModalPopoverMain"
        >
          <div className="horizontal-menu-option active">
            <SingleDateSummaryModal
              closeModal={closeSingleDate}
              asOfDate={para?.asOfDate}
              selectedDate={singleDateSelectedDateRef.current}
              pickupDate={
                selectPickupDate
                  ? selectPickupDate
                  : singleDatePickupDateRef.current
              }
              propertyDetail={{ propertyCode: para?.propertyCode }}
            />
          </div>
        </Popover>
      </Overlay>
      {/* {showNoteSidebar && (
        <WidgetNote
          show={showNoteSidebar}
          handleClose={closeNoteSideBar}
          widgetId={para?.widgetId}
          propertyId={propertyDetailFromRTK?.propertyid}
          rowTitle={para?.rowTitle}
        />
      )} */}
      {showNoteSidebar && (
        <AddWidgetModal
          showModal={showNoteSidebar}
          closeModal={closeNoteSideBar}
          widgetId={para?.widgetId}
          propertyId={propertyDetailFromRTK?.propertyid}
          rowTitle={para?.rowTitle}
        />
      )}
      {widgetModal && (
        <WidgetDetailModal
          showModal={widgetModal}
          closeModal={closeWidgetModal}
          data={widgetDetailRef.current}
        />
      )}
    </div>
  );
};

export default DayByDay;
