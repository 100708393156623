import React, { useEffect, useRef, useState } from "react";
import { apiResponse } from "../../../../utils/apiResponse";
import { API_URL } from "../../../../utils/apiRoutes";
import { apiCall } from "../../../../utils/axiosService";
import NoteIcon from "../../../../Assets/Images/CRM/notes.svg";
import downloadIcon from "../../../../Assets/Images/Operation/downloadIcon.svg";
import { commonService } from "../../../../utils/commonService";
import { Button, Col, Form, Row, Table } from "react-bootstrap";
import Loader from "../../../Loader/Loader";
import { compName, month } from "../../../../utils/constant";
import WidgetDetailModal from "../../WidgetDetailModal";
import infoIcon from "../../../../Assets/Images/Action Icons/infoIcon..svg";
import WidgetNote from "../../WidgetNote";
import { useSelector } from "react-redux";
import AddWidgetModal from "../../AddWidgetModal";
import { useParams } from "react-router-dom";
import emailIcon from "../../../../Assets/Images/Email.svg";
import moment from "moment";

const EmaForecastV3 = ({
  setCompDataFromChild,
  rowIndex,
  colIndex,
  api,
  para,
  setting,
}) => {
  const yearsArr = [
    ...commonService.getPastYearForDropDown(10),
    ...commonService.getFutureYearForDropDown(10),
    new Date().getFullYear(),
  ]?.sort(function (a, b) {
    return a - b;
  });
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const tableData = [
    { month: "January", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "February", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "March", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "April", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "May", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "June", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "July", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "August", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "September", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "October", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "November", occ: 0, rms: 0, adr: 0, rev: 0 },
    { month: "December", occ: 0, rms: 0, adr: 0, rev: 0 },
  ];

  const [onTheBookData, setOnTheBookData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [widgetModal, setWidgetModal] = useState(false);
  const widgetDetailRef = useRef();
  const [showNoteSidebar, setShowNoteSidebar] = useState(false);
  const propertyDetailFromRTK = useSelector(
    (store) => store.headerProperty.propertyDetail
  );
  const defaultAsOfDateFromRTK = useSelector(
    (store) => store.headerProperty.defaultAsOfDate
  );
  const { snapshottitle, propertyCode, snapshotId } = useParams();
  const closeNoteSideBar = () => {
    setShowNoteSidebar(false);
  };

  const closeWidgetModal = () => {
    setWidgetModal(false);
  };
  let occ = 0,
    rms = 0,
    adr = 0,
    rev = 0;

  const getOnTheBookDetail = async () => {
    setLoading(true);
    let onTheBookResponse = await apiCall(
      {
        method: "POST",
        url: API_URL.BASE_API_URL.SnapShot + API_URL.FORECAST.EMA_FORECAST_V3,
        body: {
          AsOfDate: para?.asOfDate,
          year: selectedYear,
          propertyCode: para?.propertyCode,
        },
      },
      false
    );

    let response = apiResponse(false, onTheBookResponse);
    if (response?.isValidate) {
      setOnTheBookData(response?.data?.data);
      setLoading(false);
    }

    if (!response?.isValidate) {
      setOnTheBookData(tableData);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (api === 0) {
      setOnTheBookData(tableData);
    } else {
      if (para?.asOfDate) {
        getOnTheBookDetail();
      }
    }
  }, [selectedYear, para?.asOfDate]);

  const [tblids, settblids] = useState({
    emaForecastV3Table: commonService.GenerateGUID(),
  });

  return (
    <div className="widget-container">
      {api !== 0 && (
        <div className="gap-1 widget-container-menu">
          <Button className="heading-menu-btns" type="button">
            <img
              src={NoteIcon}
              alt="menuIcon"
              onClick={() => {
                setShowNoteSidebar(true);
              }}
            />
          </Button>
          <Button className="heading-menu-btns" type="button" title="Send Mail">
            <img
              src={emailIcon}
              alt="emailIcon"
              onClick={() => {
                commonService.fnSendWidgetMail(
                  tblids.emaForecastV3Table,
                  "System Forecast V3",
                  snapshotId,
                  defaultAsOfDateFromRTK,
                  "",
                  para?.widgetId
                );
              }}
            />
          </Button>
          <Button className="heading-menu-btns" type="button">
            <img
              src={infoIcon}
              alt="info"
              onClick={() => {
                widgetDetailRef.current = {
                  widgetdetails: "",
                  usernote: "",
                  compName: compName?.emaForecastV3,
                  widgetId: para?.widgetId,
                };
                setWidgetModal(true);
              }}
            />
          </Button>
          <Button className="heading-menu-btns" type="button">
            <img
              src={downloadIcon}
              alt="menuIcon"
              import={tblids.emaForecastV3Table}
              onClick={() =>
                commonService.fnExportInCSV(
                  tblids.emaForecastV3Table,
                  // "System Forecast V3"
                  `${para?.propertyCode}-System Forecast V3-${moment(
                    new Date()
                  ).format("MMDDYYYY")}`
                )
              }
            />
          </Button>
        </div>
      )}
      <div className="ViewPropertySetupTable">
        <div className="stripped__table">
          <Table id={tblids.emaForecastV3Table} responsive>
            <thead>
              <tr>
                <th colSpan={5} className="text-center">
                  <Row>
                    <Col xl={4}>
                      {" "}
                      <Form.Select
                        value={selectedYear}
                        onChange={(e) => {
                          setSelectedYear(e.target.value);
                        }}
                        // className="width100"
                      >
                        <option value="">Select</option>
                        {yearsArr?.map((item, index) => (
                          <option key={index} value={item}>
                            {item}
                          </option>
                        ))}
                      </Form.Select>
                      <span style={{ display: "none" }}>{selectedYear}</span>
                    </Col>
                    <Col xl={8}>System Forecast V3</Col>
                  </Row>
                </th>
              </tr>
              <tr>
                <th>Month</th>
                <th>OCC</th>
                <th className="text-center">RMS</th>
                <th className="text-end">ADR</th>
                <th className="text-end">REV</th>
              </tr>
            </thead>
            <tbody>
              {api === 0 ? (
                onTheBookData?.map((item, index) => (
                  <React.Fragment key={index}>
                    <tr key={index}>
                      <td>{item?.month}</td>
                      <td>{item?.occ}%</td>
                      <td className="text-center">{item?.rms}</td>
                      <td className="text-end">${item?.adr}</td>
                      <td
                        className="text-end perbackground perbackground-rev"
                        value={item?.rev}
                      >
                        ${item?.rev}
                      </td>
                    </tr>
                    {index === onTheBookData?.length - 1 && (
                      <tr>
                        <td className="boldTd">Total</td>
                        <td className="boldTd">0%</td>
                        <td className="boldTd text-center">0</td>
                        <td className="boldTd text-end">$0</td>
                        <td className="boldTd text-end">$0</td>
                      </tr>
                    )}
                  </React.Fragment>
                ))
              ) : loading ? (
                <tr style={{ height: "80px", position: "relative" }}>
                  <Loader />
                </tr>
              ) : (
                onTheBookData &&
                month?.map((item, index) => {
                  let findMonthData = onTheBookData?.filter(
                    (x) => x.month === item
                  );

                  occ =
                    occ +
                    (findMonthData?.[0]?.occ
                      ? parseInt(findMonthData?.[0]?.occ)
                      : 0);
                  rms =
                    rms +
                    (findMonthData?.[0]?.rms
                      ? parseInt(findMonthData?.[0]?.rms)
                      : 0);
                  adr =
                    adr +
                    (findMonthData?.[0]?.adr
                      ? parseFloat(findMonthData?.[0]?.adr)
                      : 0);
                  rev =
                    rev +
                    (findMonthData?.[0]?.rev
                      ? parseFloat(findMonthData?.[0]?.rev)
                      : 0);

                  return (
                    <React.Fragment key={index}>
                      <tr key={index} className="shadowOnHover">
                        <td>{item}</td>
                        <td>
                          {findMonthData?.[0]?.occ
                            ? findMonthData?.[0]?.occ
                            : 0}
                          %
                        </td>

                        <td className="text-center">
                          {findMonthData?.[0]?.rms
                            ? commonService.formateRoom(findMonthData?.[0]?.rms)
                            : 0}
                        </td>

                        <td className="text-end">
                          {findMonthData?.[0]?.adr
                            ? commonService.formateAmount(
                                findMonthData?.[0]?.adr
                              )
                            : commonService.formateAmount(0)}
                        </td>

                        <td
                          className="text-end perbackground perbackground-rev"
                          value={findMonthData?.[0]?.rev}
                        >
                          {findMonthData?.[0]?.rev
                            ? commonService.formateAmount(
                                findMonthData?.[0]?.rev
                              )
                            : commonService.formateAmount(0)}
                        </td>
                      </tr>
                      {index === 11 && (
                        <tr key={index} className="shadowOnHover">
                          <td className="boldTd">Total</td>

                          <td className="boldTd">{parseInt(occ / 12)}%</td>

                          <td className="boldTd text-center">{commonService.formateRoom(rms)}</td>

                          <td className="boldTd text-end">
                            {rev !== 0 && rms !== 0
                              ? commonService.formateAmount(parseInt(rev / rms))
                              : commonService.formateAmount(0)}
                          </td>

                          <td className="boldTd text-end">
                            {commonService.formateAmount(rev)}
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  );
                })
              )}
            </tbody>
          </Table>
        </div>
      </div>
      {/* {showNoteSidebar && (
        <WidgetNote
          show={showNoteSidebar}
          handleClose={closeNoteSideBar}
          widgetId={para?.widgetId}
          propertyId={propertyDetailFromRTK?.propertyid}
          rowTitle={para?.rowTitle}
        />
      )} */}
      {showNoteSidebar && (
        <AddWidgetModal
          showModal={showNoteSidebar}
          closeModal={closeNoteSideBar}
          widgetId={para?.widgetId}
          propertyId={propertyDetailFromRTK?.propertyid}
          rowTitle={para?.rowTitle}
        />
      )}
      {widgetModal && (
        <WidgetDetailModal
          showModal={widgetModal}
          closeModal={closeWidgetModal}
          data={widgetDetailRef.current}
        />
      )}
    </div>
  );
};

export default EmaForecastV3;
