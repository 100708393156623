import React, { useEffect, useRef, useState } from "react";
import { apiCall } from "../../utils/axiosService";
import { API_URL } from "../../utils/apiRoutes";
import { Badge, Button, Col, Modal, Row } from "react-bootstrap";
import { apiResponse } from "../../utils/apiResponse";
import { useSelector } from "react-redux";
// import Budget from "../RevenueManagement/ListingComponent";
import Budget from "../RevenueManagement/ListingComponent/AnnualSummary/Budget";
import NetStly from "../RevenueManagement/ListingComponent/AnnualSummary/NetStly";
import OnTheBook from "../RevenueManagement/ListingComponent/AnnualSummary/OnTheBook";
import TotalLy from "../RevenueManagement/ListingComponent/AnnualSummary/TotalLy";
import OneDay from "../RevenueManagement/ListingComponent/PickUp/OneDay";
import OneDayForecastChange from "../RevenueManagement/ListingComponent/PickUp/OneDayForecastChange";
import SevenDay from "../RevenueManagement/ListingComponent/PickUp/SevenDay";
import SevenDayForecastChange from "../RevenueManagement/ListingComponent/PickUp/SevenDayForecastChange";
import Group from "../RevenueManagement/ListingComponent/Group/Group";
import GroupLastYear from "../RevenueManagement/ListingComponent/Group/GroupLastYear";
import Transient from "../RevenueManagement/ListingComponent/Group/Transient";
import TransientLastYearGroup from "../RevenueManagement/ListingComponent/Group/TransientLastYear";
import ORG from "../RevenueManagement/ListingComponent/DailySummary/ORG";
import PickupFrom from "../RevenueManagement/ListingComponent/DailySummary/PickupFrom";
import ReservationActivity from "../RevenueManagement/ListingComponent/ReservationActivity/ReservationActivity";
import HeatMap from "../RevenueManagement/ListingComponent/HeatMap/HeatMap";
import WidgetBudgetGraph from "../RevenueManagement/ListingComponent/WidgetBudget/WidgetBudgetGraph";
import Spider from "../RevenueManagement/ListingComponent/Forecast/Spider";
import Rms from "../RevenueManagement/ListingComponent/Forecast/Rms";
import User from "../RevenueManagement/ListingComponent/Forecast/User";
import ChoiceRes from "../RevenueManagement/ListingComponent/ChoiceRes/ChoiceRes";
import MstReservation from "../RevenueManagement/ListingComponent/MstReservation/MstReservation";
import DailyDataTransaction from "../RevenueManagement/ListingComponent/DailyDataTransaction/DailyDataTransaction";
import EMAForecast from "../RevenueManagement/ListingComponent/Forecast/EMAForecast";
import AnnualSummaryChart from "../RevenueManagement/ListingComponent/AnnualSummary/AnnualSummaryChart";
import ForecastChart from "../RevenueManagement/ListingComponent/Forecast/ForecastChart";
import PickupChart from "../RevenueManagement/ListingComponent/PickUp/PickupChart";
import GroupChart from "../RevenueManagement/ListingComponent/Group/GroupChart";
import BookinPaceChart from "../RevenueManagement/ListingComponent/BookingPace/BookinPaceChart";
import MarketSegmentChart from "../RevenueManagement/ListingComponent/MarketSegment/MarketSegmentChart";
import ChoiceOccupancy from "../RevenueManagement/ListingComponent/WidgetModule/ChoiceOccupancy";
import HotelKeyReservation from "../RevenueManagement/ListingComponent/WidgetModule/HotelKeyReservation";
import HotelKeyOccupancySnapshot from "../RevenueManagement/ListingComponent/WidgetModule/HotelKeyOccupancySnapshot";
import BestWesternGuestName from "../RevenueManagement/ListingComponent/WidgetModule/BestWesternGuestName";
import BestWesternReservationAgent from "../RevenueManagement/ListingComponent/WidgetModule/BestWesternReservationAgent";
import BestWesternReservationForecast from "../RevenueManagement/ListingComponent/WidgetModule/BestWesternReservationForecast";
import BestWesternRoomMaintaince from "../RevenueManagement/ListingComponent/WidgetModule/BestWesternRoomMaintaince";
import ChoiceCancelationSummaryReport from "../RevenueManagement/ListingComponent/WidgetModule/ChoiceCancelationSummaryReport";
import AdrVsRateChart from "../RevenueManagement/ListingComponent/Chart/AdrVsRateChart";
import WidgetPickup from "../RevenueManagement/ListingComponent/WidgetModule/WidgetPickup";
import BookingPaceTable from "../RevenueManagement/ListingComponent/BookingPace/BookingPaceTable";
import AnnualSummary from "../RevenueManagement/ListingComponent/AnnualSummary/AnnualSummary";
import ChoiceBusinessOnTheBook from "../RevenueManagement/ListingComponent/WidgetModule/ChoiceBusinessOnTheBook";
import ChoiceRevenue from "../RevenueManagement/ListingComponent/WidgetModule/ChoiceRevenue";
import DayByDay from "../RevenueManagement/ListingComponent/MarketSegment/DayByDay";
import RoomType from "../RevenueManagement/ListingComponent/MarketSegment/RoomType";
import RatePlanDayByDay from "../RevenueManagement/ListingComponent/RatePlan/DayByDay";
import DynamicPickup from "../RevenueManagement/ListingComponent/PickUp/DynamicPickup";
import LosDayOfWeek from "../RevenueManagement/ListingComponent/ReservationActivity/LosDayOfWeek";
import Top10Chart from "../RevenueManagement/ListingComponent/RatePlan/Top10Chart";
import DayByDayGroup from "../RevenueManagement/ListingComponent/MarketSegment/Group";
import SevenDayBookingReservation from "../RevenueManagement/ListingComponent/PickUp/SevenDayBookingReservation";
import SevenDayBookingExpected from "../RevenueManagement/ListingComponent/PickUp/SevenDayBookingExpected";
import SevenDayBookingPace from "../RevenueManagement/ListingComponent/PickUp/SevenDayBookingPace";
import SevenWeekBookingPace from "../RevenueManagement/ListingComponent/PickUp/SevenWeekBookingPace";
import LengthOfStay from "../RevenueManagement/ListingComponent/MarketSegment/LengthOfStay";
import EmaForecastV2 from "../RevenueManagement/ListingComponent/Forecast/EmaForecastV2";
import EmaForecastV3 from "../RevenueManagement/ListingComponent/Forecast/EmaForecastV3";
import ForecastCommon from "../RevenueManagement/ListingComponent/Forecast/ForecastCommon";
import PickupCommon from "../RevenueManagement/ListingComponent/PickUp/PickupCommon";
import GroupCommon from "../RevenueManagement/ListingComponent/Group/GroupCommon";
import RevYoYChart from "../RevenueManagement/ListingComponent/Chart/RevYoYChart";
import SegmentDrillDown from "../RevenueManagement/ListingComponent/SegmentDrilldown/SegmentDrillDown";
import NinentyDayOutLook from "../RevenueManagement/ListingComponent/RevPak/NinentyDayOutLook";
import LastYearAndForecast from "../RevenueManagement/ListingComponent/LastYearAndForecast/LastYearAndForecast";
import AnnSummary from "../RevenueManagement/ListingComponent/Cancellation/AnnSummary";
import CancellationPace from "../RevenueManagement/ListingComponent/Cancellation/CancellationPace";
import Account from "../RevenueManagement/ListingComponent/Accounts/Account";
import CyCancellationBookingRevLoss from "../RevenueManagement/ListingComponent/Cancellation/CyCancellationBookingRevLoss";
import LyCancellationBookingRevLoss from "../RevenueManagement/ListingComponent/Cancellation/LyCancellationBookingRevLoss";
import StlyCancellationBookingRevLoss from "../RevenueManagement/ListingComponent/Cancellation/StlyCancellationBookingRevLoss";
import Cy2CancellationBookingRevLoss from "../RevenueManagement/ListingComponent/Cancellation/Cy2CancellationBookingRevLoss";
import AnnualCancellationSummary from "../RevenueManagement/ListingComponent/Cancellation/AnnualCancellationSummary";
import MonthlyCancellationPace from "../RevenueManagement/ListingComponent/Cancellation/MonthlyCancellationPace";
import MonthlyCancellationPaceChart from "../RevenueManagement/ListingComponent/Cancellation/MonthlyCancellationPaceChart";
import NinentyDaySegment from "../RevenueManagement/ListingComponent/RevPak/NinentyDaySegment";
import CancellationDayByDay from "../RevenueManagement/ListingComponent/Cancellation/CancellationDayByDay";
import Top50ByRevenue from "../RevenueManagement/ListingComponent/General/Top50ByRevenue";
import { setDefaultAsOfDateForHeader } from "../../utils/slices/headerPropertySlice";
import { compName } from "../../utils/constant";
import DailyData from "../RevenueManagement/ListingComponent/Email/DailyData";
import WeeklyData from "../RevenueManagement/ListingComponent/Email/WeeklyData";
import MonthlyData from "../RevenueManagement/ListingComponent/Email/MonthlyData";
import RunningStar from "../RevenueManagement/ListingComponent/RevPak/RunningStar";
import WeeklyStar from "../RevenueManagement/ListingComponent/RevPak/WeeklyStar";
import { commonService } from "../../utils/commonService";
import StrAnalysis from "../RevenueManagement/ListingComponent/RevPak/StrAnalysis";
import StrDayByDay from "../RevenueManagement/ListingComponent/STR/DayByDay";
import MonthWiseDrillDown from "../RevenueManagement/ListingComponent/SegmentDrilldown/MonthWiseDrillDown";
import PastDynamicPickup from "../RevenueManagement/ListingComponent/PickUp/PastDynamicPickup";
import BookingPaceComparisonChart from "../RevenueManagement/ListingComponent/BookingPace/BookingPaceComparisonChart";
import ThirtyDayPickup from "../RevenueManagement/ListingComponent/PickUp/ThirtyDayPickup";
import BookingPaceComparison from "../RevenueManagement/ListingComponent/BookingPace/BookingPaceComparison";
import videoblue from "../../Assets/Images/Action Icons/video-blue.png";
import videogreen from "../../Assets/Images/Action Icons/video-green.png";
import {
  errorToastMessage,
  successToastMessage,
} from "../../utils/toastMessage";
import CommonComment from "../RevenueManagement/ListingComponent/Comments/CommonComment";
import ForecastUsingNinetyDayPickup from "../RevenueManagement/ListingComponent/PickUp/ForecastUsingNinetyDayPickup";
import RevenueByCompany from "../RevenueManagement/ListingComponent/CrmSnapshot/RevenueByCompany";
import RevenueByMarketSegment from "../RevenueManagement/ListingComponent/CrmSnapshot/RevenueByMarketSegment";
import RevenueByRoomType from "../RevenueManagement/ListingComponent/CrmSnapshot/RevenueByRoomType";
import ForecastVsOTBChart from "../RevenueManagement/ListingComponent/Forecast/ForecastVsOTBChart";
import PerityCheck from "../RevenueManagement/ListingComponent/RateShop/PerityCheck";
import ChannelMonthlyDrilldown from "../RevenueManagement/ListingComponent/SegmentDrilldown/ChannelMonthlyDrilldown";
import Channel from "../RevenueManagement/ListingComponent/MarketSegment/Channel";
import CrmCancellationActivity from "../RevenueManagement/ListingComponent/CrmSnapshot/CancellationActivity";
import CrmCompanyMonthlyDrilldown from "../RevenueManagement/ListingComponent/CrmSnapshot/CompanyMonthlyDrilldown";
import CrmTodayArrivalDeparture from "../RevenueManagement/ListingComponent/CrmSnapshot/TodayArrivalDeparture";
import BenchMarking from "../RevenueManagement/ListingComponent/RateShop/BenchMarking";
import Rateshop from "../RevenueManagement/ListingComponent/Tools/Rateshop";
import Reminder from "../RevenueManagement/ListingComponent/Tools/Reminder";
import Event from "../RevenueManagement/ListingComponent/Tools/Event";
import RateIq from "../RevenueManagement/ListingComponent/Tools/RateIq";
import DisplacementCalculator from "../RevenueManagement/ListingComponent/Tools/DisplacementCalculator";
import ToolPerityCheck from "../RevenueManagement/ListingComponent/Tools/PerityCheck";
import HeatMapDaywise from "../RevenueManagement/ListingComponent/HeatMap/HeatMapDaywise";
import EventStretegy from "../RevenueManagement/ListingComponent/Event/EventStretegy";
import PastForecastChart from "../RevenueManagement/ListingComponent/Forecast/PastForecastChart";
import WeatherWidget from "../RevenueManagement/ListingComponent/General/WeatherWidget";
import BookingPaceForecastChart from "../RevenueManagement/ListingComponent/BookingPace/BookingPaceForecastChart";
import BookingPaceMonthlyForecast from "../RevenueManagement/ListingComponent/BookingPace/BookingPaceMonthlyForecast";
import DynamicForecastUsingNinetyDayPickup from "../RevenueManagement/ListingComponent/PickUp/DynamicForecastUsingNinetyDayPickup";
import RateShopChart from "../RevenueManagement/ListingComponent/RateShop/RateShopChart";
import SeasonalityAnalysis from "../RevenueManagement/ListingComponent/AnnualSummary/SeasonalityAnalysis";
import ReservationAnalyticsChart from "../RevenueManagement/ListingComponent/MarketSegment/ReservationAnalyticsChart";
import DeleteModal from "../RevenueManagement/DeleteModal";
import MonthlyBookingWindow from "../RevenueManagement/ListingComponent/BookingWindow/MonthlyBookingWindow";
import DayByDayBookingWindow from "../RevenueManagement/ListingComponent/BookingWindow/DayByDayBookingWindow";
import GoogleRatings from "../RevenueManagement/ListingComponent/Analytics/GoogleRatings";
import Reviews from "../RevenueManagement/ListingComponent/Analytics/Reviews";
import Rates from "../RevenueManagement/ListingComponent/Analytics/Rates";
import RatesCount from "../RevenueManagement/ListingComponent/Analytics/RatesCount";

const TodoDetailModal = ({ todoId, showModal, closeModal, getList }) => {
  const defaultAsOfDateRef = useRef();
  const propertyDetailFromRTK = useSelector(
    (store) => store.headerProperty.propertyDetail
  );
  const asOfDateFromRTK = useSelector((store) => store.headerProperty.asOfDate);
  const defaultAsOfDateFromRTK = useSelector(
    (store) => store.headerProperty.defaultAsOfDate
  );
  if (defaultAsOfDateFromRTK) {
    defaultAsOfDateRef.current = defaultAsOfDateFromRTK;
  }
  const [todoDetail, setTodoDetail] = useState(null);

  const [IsSchedule, setIsSchedule] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const closeDeleteModal = () => {
    setDeleteModal(false);
    closeModal();
  };

  let componentObj = {
    [compName?.budget]: Budget,
    [compName?.netStly]: NetStly,
    [compName?.onTheBook]: OnTheBook,
    [compName?.totalLy]: TotalLy,
    [compName?.oneDay]: OneDay,
    [compName?.oneDayForecastChange]: OneDayForecastChange,
    [compName?.sevenDay]: SevenDay,
    [compName?.sevenDayForecastChange]: SevenDayForecastChange,
    [compName?.group]: Group,
    [compName?.groupLastYear]: GroupLastYear,
    [compName?.transient]: Transient,
    [compName?.transientLastYear]: TransientLastYearGroup,
    [compName?.org]: ORG,
    [compName?.pickupFrom]: PickupFrom,
    [compName?.reservationActivity]: ReservationActivity,
    [compName?.heatMap]: HeatMap,
    [compName?.widgetBudgetGraph]: WidgetBudgetGraph,
    [compName?.spiderForecast]: Spider,
    [compName?.rmsForecast]: Rms,
    [compName?.userForecast]: User,
    [compName?.choiceRes]: ChoiceRes,
    [compName?.mstReservation]: MstReservation,
    [compName?.dailyDataTransaction]: DailyDataTransaction,
    [compName?.emaForecast]: EMAForecast,
    [compName?.annualSummaryChart]: AnnualSummaryChart,
    [compName?.forecastChart]: ForecastChart,
    [compName?.pickupChart]: PickupChart,
    [compName?.groupChart]: GroupChart,
    [compName?.bookingPaceChart]: BookinPaceChart,
    [compName?.marketSegmentChart]: MarketSegmentChart,
    [compName?.widgetChoiceOccupancy]: ChoiceOccupancy,
    [compName?.widgetHotelKeyReservation]: HotelKeyReservation,
    [compName?.widgetHotelKeyOccupancySnapshot]: HotelKeyOccupancySnapshot,
    [compName?.widgetBestWesternGuestName]: BestWesternGuestName,
    [compName?.widgetBestWesternReservationAgent]: BestWesternReservationAgent,
    [compName?.widgetBestWesternReservationForecast]:
      BestWesternReservationForecast,
    [compName?.widgetBestWesternRoomMaintaince]: BestWesternRoomMaintaince,
    [compName?.widgetChoiceCancelationSummary]: ChoiceCancelationSummaryReport,
    [compName?.adrVsRateChart]: AdrVsRateChart,
    [compName?.widgetPickup]: WidgetPickup,
    [compName?.bookingPaceTable]: BookingPaceTable,
    [compName?.annualSummary]: AnnualSummary,
    [compName?.widgetChoiceBusinessOTB]: ChoiceBusinessOnTheBook,
    [compName?.widgetChoiceRevenue]: ChoiceRevenue,
    [compName?.marketSegmentDayByDay]: DayByDay,
    [compName?.marketSegmentRoomType]: RoomType,
    [compName?.ratePlanDayByDay]: RatePlanDayByDay,
    [compName?.dynamicPickup]: DynamicPickup,
    [compName?.losDayOfWeek]: LosDayOfWeek,
    [compName?.top10Chart]: Top10Chart,
    [compName?.dayByDayGroup]: DayByDayGroup,
    [compName?.sevenDayBookingReservation]: SevenDayBookingReservation,
    [compName?.sevenDayBookingExpected]: SevenDayBookingExpected,
    [compName?.sevenDayBookingPace]: SevenDayBookingPace,
    [compName?.sevenWeekBookingPace]: SevenWeekBookingPace,
    [compName?.lengthOfStay]: LengthOfStay,
    [compName?.emaForecastV2]: EmaForecastV2,
    [compName?.emaForecastV3]: EmaForecastV3,
    [compName?.forecastCommon]: ForecastCommon,
    [compName?.pickupCommon]: PickupCommon,
    [compName?.groupCommon]: GroupCommon,
    [compName?.revYoYChart]: RevYoYChart,
    [compName?.segmentDrillDown]: SegmentDrillDown,
    [compName?.ninetyDayOutlook]: NinentyDayOutLook,
    [compName?.lastYearAndForecast]: LastYearAndForecast,
    [compName?.annSummaryCancellation]: AnnSummary,
    [compName?.cancellationPace]: CancellationPace,
    [compName?.account]: Account,
    [compName?.cyCancellationBookingRevLoss]: CyCancellationBookingRevLoss,
    [compName?.lyCancellationBookingRevLoss]: LyCancellationBookingRevLoss,
    [compName?.stlyCancellationBookingRevLoss]: StlyCancellationBookingRevLoss,
    [compName?.cy2CancellationBookingRevLoss]: Cy2CancellationBookingRevLoss,
    [compName?.annCancellationSummary]: AnnualCancellationSummary,
    [compName?.monthlyCancellationPace]: MonthlyCancellationPace,
    [compName?.monthlyCancellationPaceChart]: MonthlyCancellationPaceChart,
    [compName?.ninetyDaySegment]: NinentyDaySegment,
    [compName?.cancellationDayByDay]: CancellationDayByDay,
    [compName?.top50ByRevenue]: Top50ByRevenue,
    [compName?.dailyDataForEmail]: DailyData,
    [compName?.weeklyDataForEmail]: WeeklyData,
    [compName?.monthlyDataForEmail]: MonthlyData,
    [compName?.runningStrReport]: RunningStar,
    [compName?.weeklyStrReport]: WeeklyStar,
    [compName?.strAnalysis]: StrAnalysis,
    [compName?.strDayByDay]: StrDayByDay,
    [compName?.monthlySegmentDrilldown]: MonthWiseDrillDown,
    [compName?.pastDynamicPickup]: PastDynamicPickup,
    [compName?.bookingPaceComparisonChart]: BookingPaceComparisonChart,
    [compName?.thirtyDayPickup]: ThirtyDayPickup,
    [compName?.bookingPaceComparison]: BookingPaceComparison,
    [compName?.commentWidget]: CommonComment,
    [compName?.forecastUsing90DayPickup]: ForecastUsingNinetyDayPickup,
    [compName?.crmRevnueByCompany]: RevenueByCompany,
    [compName?.crmRevenueByRoomType]: RevenueByRoomType,
    [compName?.crmRevenueByMarketSegment]: RevenueByMarketSegment,
    [compName?.forecastvsOtbChart]: ForecastVsOTBChart,
    [compName?.perityCheck]: PerityCheck,
    [compName?.channelMonthlyDrilldown]: ChannelMonthlyDrilldown,
    [compName?.dayByDayChannel]: Channel,
    [compName?.crmCancellationActivity]: CrmCancellationActivity,
    [compName?.crmCompanyDrilldown]: CrmCompanyMonthlyDrilldown,
    [compName?.crmArrialDeparture]: CrmTodayArrivalDeparture,
    [compName?.rateShopBenchmarking]: BenchMarking,
    [compName?.toolRateShop]: Rateshop,
    [compName?.toolReminder]: Reminder,
    [compName?.toolEvent]: Event,
    [compName?.toolRateIq]: RateIq,
    [compName?.toolDisplacementCalculator]: DisplacementCalculator,
    [compName?.toolPerityCheck]: ToolPerityCheck,
    [compName?.heatMapdaywise]: HeatMapDaywise,
    [compName?.eventStretegyWidget]: EventStretegy,
    [compName?.pastForecastChart]: PastForecastChart,
    [compName?.weatherWidget]: WeatherWidget,
    [compName?.bookingPaceForecastComparison]: BookingPaceForecastChart,
    [compName?.bookingPaceMonthlyForecast]: BookingPaceMonthlyForecast,
    [compName?.dynamicForecastUsingNinentyDayPickup]:
      DynamicForecastUsingNinetyDayPickup,
    [compName?.rateShopChart]: RateShopChart,
    [compName?.seasonalityAnalysis]: SeasonalityAnalysis,
    [compName?.marketSegmentWiseReservation]: ReservationAnalyticsChart,
    [compName?.monthlyBookingWindow]: MonthlyBookingWindow,
    [compName?.dayByDayBookingWindow]: DayByDayBookingWindow,
    [compName?.googleRatings]: GoogleRatings,
    [compName?.analyticsReview]: Reviews,
    [compName?.analyticsRates]: Rates,
    [compName?.analyticsRatesCount]: RatesCount,
  };

  const getTodoByPrimaryKey = async () => {
    setIsSchedule(false);
    let todoResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.Configuration + API_URL.TODO.GET_BY_PRIMARY_KEY,
        body: {
          id: todoId,
        },
      },
      false
    );

    let response = apiResponse(false, todoResponse);

    if (response?.isValidate) {
      setTodoDetail(response?.data?.data);
      if (
        response?.data?.data.associationtype !== null &&
        response?.data?.data.associationtype.toUpperCase() === "SCHEDULE"
      ) {
        setIsSchedule(true);
      }
    }

    if (!response?.isValidate) {
      setTodoDetail(null);
    }
  };

  const fnJoinZoomMeeting = async (obj) => {
    if (new Date() >= new Date(obj.tododate)) {
      let propertyResponse = await apiCall(
        {
          method: "POST",
          url:
            API_URL.BASE_API_URL.RevenueManagement +
            API_URL.SCHEDULE_REVENUE.GET_DETAIL_BYID,
          body: {
            id: obj.associationid,
          },
        },
        false
      );

      let response = apiResponse(false, propertyResponse);

      if (response?.isValidate) {
        var meetingdetails = response?.data?.data?.schedule[0].meetingdetails;
        if (
          meetingdetails !== null &&
          meetingdetails !== undefined &&
          meetingdetails !== ""
        ) {
          var MeetObj = JSON.parse(meetingdetails);
          if (
            MeetObj.join_url !== null &&
            MeetObj.join_url !== undefined &&
            MeetObj.join_url !== ""
          ) {
            window.open(MeetObj.join_url, "_blank");
          }
        }
      }
    } else {
      errorToastMessage(
        "Meeting date and time is " +
          commonService.getDateTimeInFormat(obj.tododate)
      );
    }
  };

  useEffect(() => {
    getTodoByPrimaryKey();
  }, []);
  return (
    <>
      <Modal
        className="AddPropertySetupModalMain todo-detail-modal-main-wrapper"
        size="lg"
        centered
        show={showModal}
        onHide={closeModal}
      >
        <Modal.Header>
          <Modal.Title
            id="contained-modal-title-vcenter"
            title={todoDetail?.title}
          >
            {todoDetail?.title?.length > 80
              ? todoDetail?.title.slice(0, 80) + "..."
              : todoDetail?.title}
          </Modal.Title>
          <div className="d-flex align-items-center gap-3">
            {/* {IsSchedule && new Date() >= new Date(todoDetail.tododate) ? (
              <img
                src={videogreen}
                alt="viewIcon"
                onClick={(e) => fnJoinZoomMeeting(todoDetail)}
              />
            ) : (
              <img
                src={videoblue}
                alt="viewIcon"
                onClick={(e) => fnJoinZoomMeeting(todoDetail)}
              />
            )} */}
            {todoDetail?.status === "Not Done" ? (
              <Badge className="default-badge inactive-badge">Not Done</Badge>
            ) : (
              <Badge className="default-badge active-badge">Done</Badge>
            )}
            <button
              type="button"
              class="btn-close"
              aria-label="Close"
              onClick={closeModal}
            ></button>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="">
            <Row>
              <Col xl={12}>
                <p className="todoDetail">
                  {todoDetail?.associationtype === "WIDGETNOTE" ? (
                    <div
                      dangerouslySetInnerHTML={{ __html: todoDetail?.details }}
                    ></div>
                  ) : (
                    <div>{todoDetail?.details}</div>
                  )}
                </p>
              </Col>

              {/* {todoDetail?.associationtype === "WIDGETNOTE" &&
                React.createElement(componentObj[todoDetail?.componentname], {
                  api: 1,
                  para: {
                    propertyDetail: propertyDetailFromRTK,
                    propertyCode: todoDetail?.propertycode,
                    // asOfDate: asOfDateFromRTK,
                    asOfDate: commonService.getDateForNode(
                      todoDetail?.asofdate
                    ),
                    widgetId: todoDetail?.widgetid,
                    defaultAsOfDate: defaultAsOfDateRef.current,
                    rowTitle: "",
                  },
                  setting: "",
                })} */}
            </Row>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="default-btn primary-btn red-btn"
            onClick={() => {
              setDeleteModal(true);
            }}
          >
            Delete
          </button>
        </Modal.Footer>
      </Modal>
      {deleteModal && (
        <DeleteModal
          showDeleteItemModal={deleteModal}
          closeDeleteItemModal={closeDeleteModal}
          payload={{
            id: parseInt(todoId),
            date: commonService.getDateInDBFormat(new Date()),
            type: "Todo",
          }}
          getList={getList}
          url={
            API_URL.BASE_API_URL.RevenueManagement +
            API_URL.EVENTS.DELETE_REVENUE_DASHBOARD_EVENT
          }
        />
      )}
    </>
  );
};

export default TodoDetailModal;
