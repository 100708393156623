import React, { useRef } from "react";
import { useState } from "react";
import { commonService } from "../../../../utils/commonService";
import { Col, Table, Button } from "react-bootstrap";
import { apiCall } from "../../../../utils/axiosService";
import { apiResponse } from "../../../../utils/apiResponse";
import { useEffect } from "react";
import DatePicker from "react-datepicker";
import NoteIcon from "../../../../Assets/Images/CRM/notes.svg";
import downloadIcon from "../../../../Assets/Images/Operation/downloadIcon.svg";
import { API_URL } from "../../../../utils/apiRoutes";
import infoIcon from "../../../../Assets/Images/Action Icons/infoIcon..svg";
import { useSelector } from "react-redux";
import { compName } from "../../../../utils/constant";
import WidgetNote from "../../WidgetNote";
import WidgetDetailModal from "../../WidgetDetailModal";
import AddWidgetModal from "../../AddWidgetModal";
import { useParams } from "react-router-dom";
import emailIcon from "../../../../Assets/Images/Email.svg";
import moment from "moment";

const ChoiceCancelationSummaryReport = ({
  setCompDataFromChild,
  rowIndex,
  colIndex,
  api,
  para,
  setting,
}) => {
  const tableData = [
    {
      id: 1,
      propertyCode: "-",
      pullDateId: "0",
      AsOfDate: "-",
      CancellationReason: "-",
      CxlDate: "-",
      Resv: "0",
      RoomNights: "0",
      RoomRev: "$0",
    },
    {
      id: 2,
      propertyCode: "-",
      pullDateId: "0",
      AsOfDate: "-",
      CancellationReason: "-",
      CxlDate: "-",
      Resv: "0",
      RoomNights: "0",
      RoomRev: "$0",
    },
    {
      id: 3,
      propertyCode: "-",
      pullDateId: "0",
      AsOfDate: "-",
      CancellationReason: "-",
      CxlDate: "-",
      Resv: "0",
      RoomNights: "0",
      RoomRev: "$0",
    },
    {
      id: 4,
      propertyCode: "-",
      pullDateId: "0",
      AsOfDate: "-",
      CancellationReason: "-",
      CxlDate: "-",
      Resv: "0",
      RoomNights: "0",
      RoomRev: "$0",
    },
    {
      id: 5,
      propertyCode: "-",
      pullDateId: "0",
      AsOfDate: "-",
      CancellationReason: "-",
      CxlDate: "-",
      Resv: "0",
      RoomNights: "0",
      RoomRev: "$0",
    },
    {
      id: 6,
      propertyCode: "-",
      pullDateId: "0",
      AsOfDate: "-",
      CancellationReason: "-",
      CxlDate: "-",
      Resv: "0",
      RoomNights: "0",
      RoomRev: "$0",
    },
    {
      id: 7,
      propertyCode: "-",
      pullDateId: "0",
      AsOfDate: "-",
      CancellationReason: "-",
      CxlDate: "-",
      Resv: "0",
      RoomNights: "0",
      RoomRev: "$0",
    },
    {
      id: 8,
      propertyCode: "-",
      pullDateId: "0",
      AsOfDate: "-",
      CancellationReason: "-",
      CxlDate: "-",
      Resv: "0",
      RoomNights: "0",
      RoomRev: "$0",
    },
    {
      id: 9,
      propertyCode: "-",
      pullDateId: "0",
      AsOfDate: "-",
      CancellationReason: "-",
      CxlDate: "-",
      Resv: "0",
      RoomNights: "0",
      RoomRev: "$0",
    },
    {
      id: 10,
      propertyCode: "-",
      pullDateId: "0",
      AsOfDate: "-",
      CancellationReason: "-",
      CxlDate: "-",
      Resv: "0",
      RoomNights: "0",
      RoomRev: "$0",
    },
  ];

  const [choiceCancelationData, setChoiceCancelationData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [startRangeDate, setStartRangeDate] = useState();
  const [endRangeDate, setEndRangeDate] = useState();
  const startRangeDateRef = useRef();
  const endRangeDateRef = useRef();
  const [showNoteSidebar, setShowNoteSidebar] = useState(false);
  const [widgetModal, setWidgetModal] = useState(false);
  const widgetDetailRef = useRef();
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const propertyDetailFromRTK = useSelector(
    (store) => store.headerProperty.propertyDetail
  );
  const defaultAsOfDateFromRTK = useSelector(
    (store) => store.headerProperty.defaultAsOfDate
  );
  const { snapshottitle, propertyCode, snapshotId } = useParams();
  const closeWidgetModal = () => {
    setWidgetModal(false);
  };
  const closeNoteSideBar = () => {
    setShowNoteSidebar(false);
  };
  const handleDateChange = (event) => {
    const [start, end] = event;
    let startSelectedDate = "",
      endSelectedDate = "";
    if (start) {
      let date = new Date(start);
      let month =
        date.getMonth() + 1 > 9
          ? date.getMonth() + 1
          : `0${date.getMonth() + 1}`;
      let dateFormat =
        date?.getDate() > 9 ? date.getDate() : `0${date.getDate()}`;
      startSelectedDate = `${date.getFullYear()}-${month}-${dateFormat}`;
      startRangeDateRef.current = startSelectedDate;
    }
    if (end) {
      let date = new Date(end);
      let month =
        date.getMonth() + 1 > 9
          ? date.getMonth() + 1
          : `0${date.getMonth() + 1}`;
      let dateFormat =
        date?.getDate() > 9 ? date.getDate() : `0${date.getDate()}`;
      endSelectedDate = `${date.getFullYear()}-${month}-${dateFormat}`;
      endRangeDateRef.current = endSelectedDate;
    }

    setStartRangeDate(event[0]);
    setEndRangeDate(event[1]);
    if (event[0] !== null && event[1] !== null) {
      // getChoiceCancelationSummary();
    }
  };

  const getChoiceCancelationSummary = async () => {
    setLoading(true);
    let payload = {
      AsOfDate: para?.asOfDate,
      propertyCode: para?.propertyCode,
    };

    if (startRangeDateRef.current && endRangeDateRef.current) {
      payload = {
        ...payload,
        startDate: startRangeDateRef.current,
        endDate: endRangeDateRef.current,
      };
    }
    let choiceCancelationResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.SnapShot +
          API_URL.WIDGET_MODULE.CHOICE_CANCELATION_SUMMARY,
        body: payload,
      },
      false
    );

    let response = apiResponse(false, choiceCancelationResponse);

    if (response?.isValidate) {
      setLoading(false);
      setChoiceCancelationData(response?.data?.data);
    }
    if (!response?.isValidate) {
      setLoading(false);
      setChoiceCancelationData(null);
    }
  };

  useEffect(() => {
    if (api === 0) {
      setChoiceCancelationData(tableData);
    } else {
      if (para?.asOfDate) {
        getChoiceCancelationSummary();
      }
    }
  }, [para?.asOfDate]);

  const [tblids, settblids] = useState({
    tblChoiceCancelation: commonService.GenerateGUID(),
  });

  return (
    <div className="widget-container">
      {api !== 0 && (
        <div className="gap-1 widget-container-menu">
          <Button className="heading-menu-btns" type="button">
            <img
              src={NoteIcon}
              alt="menuIcon"
              onClick={() => {
                setShowNoteSidebar(true);
              }}
            />
          </Button>
          <Button className="heading-menu-btns" type="button" title="Send Mail">
            <img
              src={emailIcon}
              alt="emailIcon"
              onClick={() => {
                commonService.fnSendWidgetMail(
                  tblids.tblChoiceCancelation,
                  "Choice Cancelation Summary",
                  snapshotId,
                  defaultAsOfDateFromRTK,
                  "",
                  para?.widgetId
                );
              }}
            />
          </Button>
          <Button className="heading-menu-btns" type="button">
            <img
              src={infoIcon}
              alt="info"
              onClick={() => {
                widgetDetailRef.current = {
                  widgetdetails: "",
                  usernote: "",
                  compName: compName?.widgetChoiceCancelationSummary,
                  widgetId: para?.widgetId,
                };
                setWidgetModal(true);
              }}
            />
          </Button>
          <Button className="heading-menu-btns" type="button">
            <img
              src={downloadIcon}
              alt="menuIcon"
              import={tblids.tblChoiceCancelation}
              onClick={() =>
                commonService.fnExportInCSV(
                  tblids.tblChoiceCancelation,
                  // "Choice Cancelation Summary"
                  `${para?.propertyCode}-Choice Cancelation Summary-${moment(
                    new Date()
                  ).format("MMDDYYYY")}`
                )
              }
            />
          </Button>
        </div>
      )}
      <div className="ViewPropertySetupTable">
        <div className="stripped__table">
          <Table
            responsive
            id={tblids.tblChoiceCancelation}
            className="positionStaticTable"
          >
            <thead>
              <tr>
                <th
                  className="width175"
                  onClick={() => {
                    setOpenDatePicker(true);
                  }}
                >
                  Date{" "}
                  <DatePicker
                    onChange={(event) => handleDateChange(event)}
                    startDate={startRangeDate}
                    endDate={endRangeDate}
                    selectsRange
                    monthsShown={2}
                    open={openDatePicker}
                    shouldCloseOnSelect={false}
                  >
                    <div className="w-100" style={{ display: "inline-block" }}>
                      <div className="content-inside-calendar">
                        <p>
                          {startRangeDate
                            ? commonService.getDateInFormat(startRangeDate)
                            : ""}
                          {startRangeDate && endRangeDate ? " - " : ""}
                          {endRangeDate
                            ? commonService.getDateInFormat(endRangeDate)
                            : ""}
                        </p>
                        <div className="d-flex align-items-center gap-2">
                          <button
                            className="secondary-btn"
                            onClick={(e) => {
                              e.stopPropagation();
                              setStartRangeDate("");
                              setEndRangeDate("");
                              setOpenDatePicker(false);
                            }}
                          >
                            Cancel
                          </button>
                          <button
                            disabled={
                              startRangeDate && endRangeDate ? false : true
                            }
                            onClick={(e) => {
                              e.stopPropagation();
                              if (
                                startRangeDateRef.current &&
                                endRangeDateRef.current
                              ) {
                                getChoiceCancelationSummary();
                              }

                              setOpenDatePicker(false);
                            }}
                            className="primary-btn"
                          >
                            Apply
                          </button>
                        </div>
                      </div>
                    </div>
                  </DatePicker>
                  {startRangeDate && endRangeDate && (
                    <span
                      style={{ display: "none" }}
                    >{`${commonService.getDateInFormat(
                      startRangeDate
                    )} - ${commonService.getDateInFormat(endRangeDate)}`}</span>
                  )}
                </th>
                <th colSpan={8}></th>
              </tr>
              <tr>
                {/* <th>propertyCode</th>
                <th>pullDateId</th> */}
                <th>AsOfDate</th>
                <th>CancellationReason</th>
                <th>CxlDate</th>
                <th className="text-center">Resv</th>
                <th className="text-center">RoomNights</th>
                <th className="text-center">RoomRev</th>
              </tr>
            </thead>
            <tbody>
              {choiceCancelationData && choiceCancelationData?.length > 0 ? (
                choiceCancelationData?.map((item, index) => (
                  <tr key={item?.id} className="shadowOnHover">
                    {/* <td>{item?.propertyCode}</td>
                    <td>{item?.pullDateId}</td> */}
                    <td>{item?.AsOfDate}</td>
                    <td>{item?.CancellationReason}</td>
                    <td>{item?.CxlDate}</td>
                    <td className="text-center">{item?.Resv}</td>
                    <td className="text-center">{item?.RoomNights}</td>
                    <td className="text-center">{item?.RoomRev}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={9} className="text-center">
                    No Data Found
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </div>
      {/* {showNoteSidebar && (
        <WidgetNote
          show={showNoteSidebar}
          handleClose={closeNoteSideBar}
          widgetId={para?.widgetId}
          propertyId={propertyDetailFromRTK?.propertyid}
          rowTitle={para?.rowTitle}
        />
      )} */}
      {showNoteSidebar && (
        <AddWidgetModal
          showModal={showNoteSidebar}
          closeModal={closeNoteSideBar}
          widgetId={para?.widgetId}
          propertyId={propertyDetailFromRTK?.propertyid}
          rowTitle={para?.rowTitle}
        />
      )}
      {widgetModal && (
        <WidgetDetailModal
          showModal={widgetModal}
          closeModal={closeWidgetModal}
          data={widgetDetailRef.current}
        />
      )}
    </div>
  );
};

export default ChoiceCancelationSummaryReport;
