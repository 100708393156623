import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { apiCall } from "../../utils/axiosService";
import { API_URL } from "../../utils/apiRoutes";
import { apiResponse } from "../../utils/apiResponse";
import Loader from "../Loader/Loader";
import { commonService } from "../../utils/commonService";

const SingleDayGroupModal = ({
  showModal,
  closeModal,
  asOfDate,
  selectedDate,
  pickupDate,
  propertyCode,
}) => {
  const [groupData, setGroupData] = useState(null);
  const [loading, setLoading] = useState(false);

  const getGroupData = async () => {
    setLoading(true);
    let groupResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.SnapShot +
          API_URL.DAILY_SUMMARY.SINGLE_DAY_GROUP_SUMMARY,
        body: {
          AsOfDate: asOfDate,
          selectedDate: selectedDate,
          propertyCode: propertyCode,
        },
      },
      false
    );

    let response = apiResponse(false, groupResponse);

    if (response?.isValidate) {
      setLoading(false);
      setGroupData(response?.data?.data);
    }

    if (!response?.isValidate) {
      setLoading(false);
      setGroupData(null);
    }
  };

  useEffect(() => {
    getGroupData();
  }, [asOfDate, selectedDate]);

  return (
    <div className="GroupNameModalMain ToolsSingleTicketPopUpMain singleDateSummaryModalMain">
      <div className="GroupNameModalHeader">
        <h2>Single Group Summary</h2>
        <button className="btn-close" onClick={closeModal}></button>
      </div>
      <div className="singleDateSummaryModalContent">
        <div className="ViewPropertySetupTable">
          <div className="table__container">
            <div className="stripped__table">
              <Table responsive>
                <thead>
                  <tr>
                    <th className="border_right">Group Block</th>
                    <th className="border_right">7 Day Block Pickup</th>
                    <th className="border_right">Group Otb</th>
                    <th>7 Day OTB Pickup</th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr style={{ height: "80px", position: "relative" }}>
                      <Loader />
                    </tr>
                  ) : (
                    <tr>
                      <td className="border_right">
                        {groupData?.[0]?.GroupBlock
                          ? groupData?.[0]?.GroupBlock
                          : 0}
                      </td>
                      <td className="border_right">
                        {(groupData?.[0]?.GroupBlock
                          ? groupData?.[0]?.GroupBlock
                          : 0) -
                          (groupData?.[0]?.["7 day block pickup"]
                            ? groupData?.[0]?.["7 day block pickup"]
                            : 0)}
                      </td>
                      <td className="border_right">
                        {groupData?.[0]?.GroupOTB
                          ? groupData?.[0]?.GroupOTB
                          : 0}
                      </td>
                      <td>
                        {(groupData?.[0]?.GroupOTB
                          ? groupData?.[0]?.GroupOTB
                          : 0) -
                          (groupData?.[0]?.["7 day OTB pickup"]
                            ? groupData?.[0]?.["7 day OTB pickup"]
                            : 0)}
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
        <div className="ViewPropertySetupTable mt-3">
          <div className="table__container">
            <div className="stripped__table">
              <Table responsive>
                <thead>
                  <tr>
                    <th className="border_right">Group Name</th>
                    <th className="border_right">Rooms</th>
                    <th className="text-end border_right">Adr</th>
                    <th className="text-end">Revenue</th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr style={{ height: "80px", position: "relative" }}>
                      <Loader />
                    </tr>
                  ) : (
                    <tr>
                      <td className="border_right">0</td>
                      <td className="border_right">0</td>
                      <td className="text-end border_right">
                        {groupData?.[0]?.ADR
                          ? `${commonService.formateAmount(
                              groupData?.[0]?.ADR
                            )}`
                          : 0}
                      </td>
                      <td className="text-end">
                        {groupData?.[0]?.TotalRevenue
                          ? `${commonService.formateAmount(
                              groupData?.[0]?.TotalRevenue
                            )}`
                          : 0}
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleDayGroupModal;
