import { createSlice } from "@reduxjs/toolkit";
import { commonService } from "../commonService";

const headerPropertySlice = createSlice({
  name: "headerProperty",
  initialState: {
    propertyDetail: localStorage.getItem("property")
      ? JSON.parse(commonService.getItem("property"))
      : null,
    crmPropertyDetail: localStorage.getItem("crmProperty")
      ? JSON.parse(commonService.getItem("crmProperty"))
      : null,
    asOfDate: "",
    defaultAsOfDate: "",
    userRolePropertyList: null,
    isSidebarOpen: true,
    allDates: {},
    threadId: null,
    allNotificationCount: null,
    allPropertyDetails: null,
    bookingPaceMonth: null,
  },
  reducers: {
    setPropertyInRedux: (state, action) => {
      state.propertyDetail = action.payload;
    },
    setCrmPropertyInRedux: (state, action) => {
      state.crmPropertyDetail = action.payload;
    },
    setAsOfDateForHeader: (state, action) => {
      state.asOfDate = action.payload;
    },
    setDefaultAsOfDateForHeader: (state, action) => {
      state.defaultAsOfDate = action.payload;
    },
    setUserRolePropertyList: (state, action) => {
      state.userRolePropertyList = action.payload;
    },
    setSidebarCollapse: (state, action) => {
      state.isSidebarOpen = !state.isSidebarOpen;
    },
    setDefaultSidebar: (state, action) => {
      state.isSidebarOpen = true;
    },
    setAllDates: (state, action) => {
      state.allDates = action.payload;
    },
    setThreadId: (state, action) => {
      state.threadId = action.payload;
    },
    setAllNotificationCount: (state, action) => {
      state.allNotificationCount = action.payload;
    },
    setAllPropertyDetails: (state, action) => {
      state.allPropertyDetails = action.payload;
    },
    setBookingPaceMonth: (state, action) => {
      state.bookingPaceMonth = action.payload;
    },
  },
});

export const {
  setPropertyInRedux,
  setCrmPropertyInRedux,
  setAsOfDateForHeader,
  setDefaultAsOfDateForHeader,
  setUserRolePropertyList,
  setSidebarCollapse,
  setDefaultSidebar,
  setAllDates,
  setThreadId,
  setAllNotificationCount,
  setAllPropertyDetails,
  setBookingPaceMonth,
} = headerPropertySlice.actions;

export default headerPropertySlice.reducer;
