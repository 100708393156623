import React, { useEffect, useState } from "react";

import {
  Chart,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { API_URL } from "../../../../utils/apiRoutes";
import { apiCall } from "../../../../utils/axiosService";
import { apiResponse } from "../../../../utils/apiResponse";
import Loader from "../../../Loader/Loader";

const SevenDayBookingPace = ({
  setCompDataFromChild,
  rowIndex,
  colIndex,
  api,
  para,
  setting,
}) => {
  const [bookingPaceData, setBookingPaceData] = useState(null);
  const [loading, setLoading] = useState(false);

  let chartData = {
    labels: ["0", "1", "2", "3", "4", "5", "6", "7"],
    datasets: [
      {
        label: "",
        data: [],
        fill: false,
        backgroundColor: "#5591F5",
        borderColor: "#5591F5",
      },
    ],
  };

  let options = {
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: "Rooms Booked",
        },
      },
      x: {
        title: {
          display: true,
          text: "Days Out",
        },
      },
    },
  };

  const getBookingReservation = async () => {
    setLoading(true);
    let reservationResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.SnapShot +
          API_URL.PICK_UP.SEVEN_DAY_BOOKING_PACE,
        body: {
          AsOfDate: para?.asOfDate,
          propertyCode: para?.propertyCode,
        },
      },
      false
    );

    let response = apiResponse(false, reservationResponse);

    if (response?.isValidate) {
      response?.data?.data?.map((item) => {
        chartData.datasets[0].data.push(item?.ExpectedPickUp);
      });
      chartData.datasets[0].data = [
        response?.data?.data?.[0]?.dba_0 ? response?.data?.data?.[0]?.dba_0 : 0,
        response?.data?.data?.[0]?.dba_1 ? response?.data?.data?.[0]?.dba_1 : 0,
        response?.data?.data?.[0]?.dba_2 ? response?.data?.data?.[0]?.dba_2 : 0,
        response?.data?.data?.[0]?.dba_3 ? response?.data?.data?.[0]?.dba_3 : 0,
        response?.data?.data?.[0]?.dba_4 ? response?.data?.data?.[0]?.dba_4 : 0,
        response?.data?.data?.[0]?.dba_5 ? response?.data?.data?.[0]?.dba_5 : 0,
        response?.data?.data?.[0]?.dba_6 ? response?.data?.data?.[0]?.dba_6 : 0,
        response?.data?.data?.[0]?.dba_7 ? response?.data?.data?.[0]?.dba_7 : 0,
      ];
      setBookingPaceData(chartData);
      setLoading(false);
    }

    if (!response?.isValidate) {
      chartData.datasets[0].data = [0, 0, 0, 0, 0, 0, 0, 0];
      setBookingPaceData(chartData);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (api === 0) {
      chartData.datasets[0].data = [0, 0, 0, 0, 0, 0, 0, 0];
      setBookingPaceData(chartData);
    } else {
      if (para?.asOfDate) {
        getBookingReservation();
      }
    }
  }, [para?.asOfDate]);

  Chart.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Tooltip,
    Legend,
    PointElement,
    LineElement
  );
  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        bookingPaceData && (
          <div className="ThreeBarAndLineChartWrapper">
            <h4>Day Booking Pace</h4>
            <Line data={bookingPaceData} options={options} />
          </div>
        )
      )}
    </div>
  );
};

export default SevenDayBookingPace;
