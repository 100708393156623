import React, { useEffect, useRef, useState } from "react";
import { Button, Table } from "react-bootstrap";
import Loader from "../../../Loader/Loader";
import NoteIcon from "../../../../Assets/Images/CRM/notes.svg";
import downloadIcon from "../../../../Assets/Images/Operation/downloadIcon.svg";
import { commonService } from "../../../../utils/commonService";
import { apiCall } from "../../../../utils/axiosService";
import { API_URL } from "../../../../utils/apiRoutes";
import { apiResponse } from "../../../../utils/apiResponse";
import { FixColumn } from "../../../../utils/dataTable";
import moment from "moment";
import { compName } from "../../../../utils/constant";
import WidgetDetailModal from "../../WidgetDetailModal";
import WidgetNote from "../../WidgetNote";
import { useSelector } from "react-redux";
import infoIcon from "../../../../Assets/Images/Action Icons/infoIcon..svg";
import AddWidgetModal from "../../AddWidgetModal";
import { useParams } from "react-router-dom";
import emailIcon from "../../../../Assets/Images/Email.svg";
import Comments from "../Comments/Comment";

const ThirtyDayPickup = ({
  setCompDataFromChild,
  rowIndex,
  colIndex,
  api,
  para,
  setting,
}) => {
  const tableData = [
    {
      staydate: 1688083200000,
      day_diff: 1,
      total_booking: 0,
      forcastroom: 0,
      pu_avg: 0,
      dba_90: 0,
      pu_90: 0,
      dba_89: 0,
      pu_89: 0,
      dba_88: 0,
      pu_88: 0,
      dba_87: 0,
      pu_87: 0,
      dba_86: 0,
      pu_86: 0,
      dba_85: 0,
      pu_85: 0,
      dba_84: 0,
      pu_84: 0,
      dba_83: 0,
      pu_83: 0,
      dba_82: 0,
      pu_82: 0,
      dba_81: 0,
      pu_81: 0,
      dba_80: 0,
      pu_80: 0,
      dba_79: 0,
      pu_79: 0,
      dba_78: 0,
      pu_78: 0,
      dba_77: 0,
      pu_77: 0,
      dba_76: 0,
      pu_76: 0,
      dba_75: 0,
      pu_75: 0,
      dba_74: 0,
      pu_74: 0,
      dba_73: 0,
      pu_73: 0,
      dba_72: 0,
      pu_72: 0,
      dba_71: 0,
      pu_71: 0,
      dba_70: 0,
      pu_70: 0,
      dba_69: 0,
      pu_69: 0,
      dba_68: 0,
      pu_68: 0,
      dba_67: 0,
      pu_67: 0,
      dba_66: 0,
      pu_66: 0,
      dba_65: 0,
      pu_65: 0,
      dba_64: 0,
      pu_64: 0,
      dba_63: 0,
      pu_63: 0,
      dba_62: 0,
      pu_62: 0,
      dba_61: 1,
      pu_61: 0,
      dba_60: 0,
      pu_60: 0,
      dba_59: 0,
      pu_59: 0,
      dba_58: 0,
      pu_58: 0,
      dba_57: 0,
      pu_57: 0,
      dba_56: 0,
      pu_56: 0,
      dba_55: 0,
      pu_55: 0,
      dba_54: 0,
      pu_54: 0,
      dba_53: 0,
      pu_53: 0,
      dba_52: 0,
      pu_52: 0,
      dba_51: 0,
      pu_51: 0,
      dba_50: 0,
      pu_50: 0,
      dba_49: 0,
      pu_49: 0,
      dba_48: 0,
      pu_48: 0,
      dba_47: 0,
      pu_47: 0,
      dba_46: 0,
      pu_46: 0,
      dba_45: 0,
      pu_45: 0,
      dba_44: 0,
      pu_44: 0,
      dba_43: 0,
      pu_43: 0,
      dba_42: 0,
      pu_42: 0,
      dba_41: 0,
      pu_41: 0,
      dba_40: 0,
      pu_40: 0,
      dba_39: 0,
      pu_39: 0,
      dba_38: 0,
      pu_38: 0,
      dba_37: 0,
      pu_37: 0,
      dba_36: 0,
      pu_36: 0,
      dba_35: 0,
      pu_35: 0,
      dba_34: 0,
      pu_34: 0,
      dba_33: 0,
      pu_33: 0,
      dba_32: 0,
      pu_32: 0,
      dba_31: 0,
      pu_31: 0,
      dba_30: 0,
      pu_30: 0,
      dba_29: 0,
      pu_29: 0,
      dba_28: 0,
      pu_28: 0,
      dba_27: 0,
      pu_27: 0,
      dba_26: 0,
      pu_26: 0,
      dba_25: 0,
      pu_25: 0,
      dba_24: 0,
      pu_24: 0,
      dba_23: 0,
      pu_23: 0,
      dba_22: 0,
      pu_22: 0,
      dba_21: 0,
      pu_21: 0,
      dba_20: 0,
      pu_20: 0,
      dba_19: 0,
      pu_19: 0,
      dba_18: 0,
      pu_18: 0,
      dba_17: 0,
      pu_17: 0,
      dba_16: 0,
      pu_16: 0,
      dba_15: 0,
      pu_15: 0,
      dba_14: 0,
      pu_14: 0,
      dba_13: 0,
      pu_13: 0,
      dba_12: 0,
      pu_12: 0,
      dba_11: 0,
      pu_11: 0,
      dba_10: 0,
      pu_10: 0,
      dba_9: 0,
      pu_9: 0,
      dba_8: 0,
      pu_8: 0,
      dba_7: 0,
      pu_7: 0,
      dba_6: 0,
      pu_6: 0,
      dba_5: 0,
      pu_5: 0,
      dba_4: 0,
      pu_4: 0,
      dba_3: 0,
      pu_3: 0,
      dba_2: 0,
      pu_2: 0,
      dba_1: 0,
      pu_1: 0,
      dba_0: 0,
      pu_0: 0,
    },
    {
      staydate: 1688083200000,
      day_diff: 1,
      total_booking: 0,
      forcastroom: 0,
      pu_avg: 0,
      dba_90: 0,
      pu_90: 0,
      dba_89: 0,
      pu_89: 0,
      dba_88: 0,
      pu_88: 0,
      dba_87: 0,
      pu_87: 0,
      dba_86: 0,
      pu_86: 0,
      dba_85: 0,
      pu_85: 0,
      dba_84: 0,
      pu_84: 0,
      dba_83: 0,
      pu_83: 0,
      dba_82: 0,
      pu_82: 0,
      dba_81: 0,
      pu_81: 0,
      dba_80: 0,
      pu_80: 0,
      dba_79: 0,
      pu_79: 0,
      dba_78: 0,
      pu_78: 0,
      dba_77: 0,
      pu_77: 0,
      dba_76: 0,
      pu_76: 0,
      dba_75: 0,
      pu_75: 0,
      dba_74: 0,
      pu_74: 0,
      dba_73: 0,
      pu_73: 0,
      dba_72: 0,
      pu_72: 0,
      dba_71: 0,
      pu_71: 0,
      dba_70: 0,
      pu_70: 0,
      dba_69: 0,
      pu_69: 0,
      dba_68: 0,
      pu_68: 0,
      dba_67: 0,
      pu_67: 0,
      dba_66: 0,
      pu_66: 0,
      dba_65: 0,
      pu_65: 0,
      dba_64: 0,
      pu_64: 0,
      dba_63: 0,
      pu_63: 0,
      dba_62: 0,
      pu_62: 0,
      dba_61: 1,
      pu_61: 0,
      dba_60: 0,
      pu_60: 0,
      dba_59: 0,
      pu_59: 0,
      dba_58: 0,
      pu_58: 0,
      dba_57: 0,
      pu_57: 0,
      dba_56: 0,
      pu_56: 0,
      dba_55: 0,
      pu_55: 0,
      dba_54: 0,
      pu_54: 0,
      dba_53: 0,
      pu_53: 0,
      dba_52: 0,
      pu_52: 0,
      dba_51: 0,
      pu_51: 0,
      dba_50: 0,
      pu_50: 0,
      dba_49: 0,
      pu_49: 0,
      dba_48: 0,
      pu_48: 0,
      dba_47: 0,
      pu_47: 0,
      dba_46: 0,
      pu_46: 0,
      dba_45: 0,
      pu_45: 0,
      dba_44: 0,
      pu_44: 0,
      dba_43: 0,
      pu_43: 0,
      dba_42: 0,
      pu_42: 0,
      dba_41: 0,
      pu_41: 0,
      dba_40: 0,
      pu_40: 0,
      dba_39: 0,
      pu_39: 0,
      dba_38: 0,
      pu_38: 0,
      dba_37: 0,
      pu_37: 0,
      dba_36: 0,
      pu_36: 0,
      dba_35: 0,
      pu_35: 0,
      dba_34: 0,
      pu_34: 0,
      dba_33: 0,
      pu_33: 0,
      dba_32: 0,
      pu_32: 0,
      dba_31: 0,
      pu_31: 0,
      dba_30: 0,
      pu_30: 0,
      dba_29: 0,
      pu_29: 0,
      dba_28: 0,
      pu_28: 0,
      dba_27: 0,
      pu_27: 0,
      dba_26: 0,
      pu_26: 0,
      dba_25: 0,
      pu_25: 0,
      dba_24: 0,
      pu_24: 0,
      dba_23: 0,
      pu_23: 0,
      dba_22: 0,
      pu_22: 0,
      dba_21: 0,
      pu_21: 0,
      dba_20: 0,
      pu_20: 0,
      dba_19: 0,
      pu_19: 0,
      dba_18: 0,
      pu_18: 0,
      dba_17: 0,
      pu_17: 0,
      dba_16: 0,
      pu_16: 0,
      dba_15: 0,
      pu_15: 0,
      dba_14: 0,
      pu_14: 0,
      dba_13: 0,
      pu_13: 0,
      dba_12: 0,
      pu_12: 0,
      dba_11: 0,
      pu_11: 0,
      dba_10: 0,
      pu_10: 0,
      dba_9: 0,
      pu_9: 0,
      dba_8: 0,
      pu_8: 0,
      dba_7: 0,
      pu_7: 0,
      dba_6: 0,
      pu_6: 0,
      dba_5: 0,
      pu_5: 0,
      dba_4: 0,
      pu_4: 0,
      dba_3: 0,
      pu_3: 0,
      dba_2: 0,
      pu_2: 0,
      dba_1: 0,
      pu_1: 0,
      dba_0: 0,
      pu_0: 0,
    },
    {
      staydate: 1688083200000,
      day_diff: 1,
      total_booking: 0,
      forcastroom: 0,
      pu_avg: 0,
      dba_90: 0,
      pu_90: 0,
      dba_89: 0,
      pu_89: 0,
      dba_88: 0,
      pu_88: 0,
      dba_87: 0,
      pu_87: 0,
      dba_86: 0,
      pu_86: 0,
      dba_85: 0,
      pu_85: 0,
      dba_84: 0,
      pu_84: 0,
      dba_83: 0,
      pu_83: 0,
      dba_82: 0,
      pu_82: 0,
      dba_81: 0,
      pu_81: 0,
      dba_80: 0,
      pu_80: 0,
      dba_79: 0,
      pu_79: 0,
      dba_78: 0,
      pu_78: 0,
      dba_77: 0,
      pu_77: 0,
      dba_76: 0,
      pu_76: 0,
      dba_75: 0,
      pu_75: 0,
      dba_74: 0,
      pu_74: 0,
      dba_73: 0,
      pu_73: 0,
      dba_72: 0,
      pu_72: 0,
      dba_71: 0,
      pu_71: 0,
      dba_70: 0,
      pu_70: 0,
      dba_69: 0,
      pu_69: 0,
      dba_68: 0,
      pu_68: 0,
      dba_67: 0,
      pu_67: 0,
      dba_66: 0,
      pu_66: 0,
      dba_65: 0,
      pu_65: 0,
      dba_64: 0,
      pu_64: 0,
      dba_63: 0,
      pu_63: 0,
      dba_62: 0,
      pu_62: 0,
      dba_61: 1,
      pu_61: 0,
      dba_60: 0,
      pu_60: 0,
      dba_59: 0,
      pu_59: 0,
      dba_58: 0,
      pu_58: 0,
      dba_57: 0,
      pu_57: 0,
      dba_56: 0,
      pu_56: 0,
      dba_55: 0,
      pu_55: 0,
      dba_54: 0,
      pu_54: 0,
      dba_53: 0,
      pu_53: 0,
      dba_52: 0,
      pu_52: 0,
      dba_51: 0,
      pu_51: 0,
      dba_50: 0,
      pu_50: 0,
      dba_49: 0,
      pu_49: 0,
      dba_48: 0,
      pu_48: 0,
      dba_47: 0,
      pu_47: 0,
      dba_46: 0,
      pu_46: 0,
      dba_45: 0,
      pu_45: 0,
      dba_44: 0,
      pu_44: 0,
      dba_43: 0,
      pu_43: 0,
      dba_42: 0,
      pu_42: 0,
      dba_41: 0,
      pu_41: 0,
      dba_40: 0,
      pu_40: 0,
      dba_39: 0,
      pu_39: 0,
      dba_38: 0,
      pu_38: 0,
      dba_37: 0,
      pu_37: 0,
      dba_36: 0,
      pu_36: 0,
      dba_35: 0,
      pu_35: 0,
      dba_34: 0,
      pu_34: 0,
      dba_33: 0,
      pu_33: 0,
      dba_32: 0,
      pu_32: 0,
      dba_31: 0,
      pu_31: 0,
      dba_30: 0,
      pu_30: 0,
      dba_29: 0,
      pu_29: 0,
      dba_28: 0,
      pu_28: 0,
      dba_27: 0,
      pu_27: 0,
      dba_26: 0,
      pu_26: 0,
      dba_25: 0,
      pu_25: 0,
      dba_24: 0,
      pu_24: 0,
      dba_23: 0,
      pu_23: 0,
      dba_22: 0,
      pu_22: 0,
      dba_21: 0,
      pu_21: 0,
      dba_20: 0,
      pu_20: 0,
      dba_19: 0,
      pu_19: 0,
      dba_18: 0,
      pu_18: 0,
      dba_17: 0,
      pu_17: 0,
      dba_16: 0,
      pu_16: 0,
      dba_15: 0,
      pu_15: 0,
      dba_14: 0,
      pu_14: 0,
      dba_13: 0,
      pu_13: 0,
      dba_12: 0,
      pu_12: 0,
      dba_11: 0,
      pu_11: 0,
      dba_10: 0,
      pu_10: 0,
      dba_9: 0,
      pu_9: 0,
      dba_8: 0,
      pu_8: 0,
      dba_7: 0,
      pu_7: 0,
      dba_6: 0,
      pu_6: 0,
      dba_5: 0,
      pu_5: 0,
      dba_4: 0,
      pu_4: 0,
      dba_3: 0,
      pu_3: 0,
      dba_2: 0,
      pu_2: 0,
      dba_1: 0,
      pu_1: 0,
      dba_0: 0,
      pu_0: 0,
    },
    {
      staydate: 1688083200000,
      day_diff: 1,
      total_booking: 0,
      forcastroom: 0,
      pu_avg: 0,
      dba_90: 0,
      pu_90: 0,
      dba_89: 0,
      pu_89: 0,
      dba_88: 0,
      pu_88: 0,
      dba_87: 0,
      pu_87: 0,
      dba_86: 0,
      pu_86: 0,
      dba_85: 0,
      pu_85: 0,
      dba_84: 0,
      pu_84: 0,
      dba_83: 0,
      pu_83: 0,
      dba_82: 0,
      pu_82: 0,
      dba_81: 0,
      pu_81: 0,
      dba_80: 0,
      pu_80: 0,
      dba_79: 0,
      pu_79: 0,
      dba_78: 0,
      pu_78: 0,
      dba_77: 0,
      pu_77: 0,
      dba_76: 0,
      pu_76: 0,
      dba_75: 0,
      pu_75: 0,
      dba_74: 0,
      pu_74: 0,
      dba_73: 0,
      pu_73: 0,
      dba_72: 0,
      pu_72: 0,
      dba_71: 0,
      pu_71: 0,
      dba_70: 0,
      pu_70: 0,
      dba_69: 0,
      pu_69: 0,
      dba_68: 0,
      pu_68: 0,
      dba_67: 0,
      pu_67: 0,
      dba_66: 0,
      pu_66: 0,
      dba_65: 0,
      pu_65: 0,
      dba_64: 0,
      pu_64: 0,
      dba_63: 0,
      pu_63: 0,
      dba_62: 0,
      pu_62: 0,
      dba_61: 1,
      pu_61: 0,
      dba_60: 0,
      pu_60: 0,
      dba_59: 0,
      pu_59: 0,
      dba_58: 0,
      pu_58: 0,
      dba_57: 0,
      pu_57: 0,
      dba_56: 0,
      pu_56: 0,
      dba_55: 0,
      pu_55: 0,
      dba_54: 0,
      pu_54: 0,
      dba_53: 0,
      pu_53: 0,
      dba_52: 0,
      pu_52: 0,
      dba_51: 0,
      pu_51: 0,
      dba_50: 0,
      pu_50: 0,
      dba_49: 0,
      pu_49: 0,
      dba_48: 0,
      pu_48: 0,
      dba_47: 0,
      pu_47: 0,
      dba_46: 0,
      pu_46: 0,
      dba_45: 0,
      pu_45: 0,
      dba_44: 0,
      pu_44: 0,
      dba_43: 0,
      pu_43: 0,
      dba_42: 0,
      pu_42: 0,
      dba_41: 0,
      pu_41: 0,
      dba_40: 0,
      pu_40: 0,
      dba_39: 0,
      pu_39: 0,
      dba_38: 0,
      pu_38: 0,
      dba_37: 0,
      pu_37: 0,
      dba_36: 0,
      pu_36: 0,
      dba_35: 0,
      pu_35: 0,
      dba_34: 0,
      pu_34: 0,
      dba_33: 0,
      pu_33: 0,
      dba_32: 0,
      pu_32: 0,
      dba_31: 0,
      pu_31: 0,
      dba_30: 0,
      pu_30: 0,
      dba_29: 0,
      pu_29: 0,
      dba_28: 0,
      pu_28: 0,
      dba_27: 0,
      pu_27: 0,
      dba_26: 0,
      pu_26: 0,
      dba_25: 0,
      pu_25: 0,
      dba_24: 0,
      pu_24: 0,
      dba_23: 0,
      pu_23: 0,
      dba_22: 0,
      pu_22: 0,
      dba_21: 0,
      pu_21: 0,
      dba_20: 0,
      pu_20: 0,
      dba_19: 0,
      pu_19: 0,
      dba_18: 0,
      pu_18: 0,
      dba_17: 0,
      pu_17: 0,
      dba_16: 0,
      pu_16: 0,
      dba_15: 0,
      pu_15: 0,
      dba_14: 0,
      pu_14: 0,
      dba_13: 0,
      pu_13: 0,
      dba_12: 0,
      pu_12: 0,
      dba_11: 0,
      pu_11: 0,
      dba_10: 0,
      pu_10: 0,
      dba_9: 0,
      pu_9: 0,
      dba_8: 0,
      pu_8: 0,
      dba_7: 0,
      pu_7: 0,
      dba_6: 0,
      pu_6: 0,
      dba_5: 0,
      pu_5: 0,
      dba_4: 0,
      pu_4: 0,
      dba_3: 0,
      pu_3: 0,
      dba_2: 0,
      pu_2: 0,
      dba_1: 0,
      pu_1: 0,
      dba_0: 0,
      pu_0: 0,
    },
    {
      staydate: 1688083200000,
      day_diff: 1,
      total_booking: 0,
      forcastroom: 0,
      pu_avg: 0,
      dba_90: 0,
      pu_90: 0,
      dba_89: 0,
      pu_89: 0,
      dba_88: 0,
      pu_88: 0,
      dba_87: 0,
      pu_87: 0,
      dba_86: 0,
      pu_86: 0,
      dba_85: 0,
      pu_85: 0,
      dba_84: 0,
      pu_84: 0,
      dba_83: 0,
      pu_83: 0,
      dba_82: 0,
      pu_82: 0,
      dba_81: 0,
      pu_81: 0,
      dba_80: 0,
      pu_80: 0,
      dba_79: 0,
      pu_79: 0,
      dba_78: 0,
      pu_78: 0,
      dba_77: 0,
      pu_77: 0,
      dba_76: 0,
      pu_76: 0,
      dba_75: 0,
      pu_75: 0,
      dba_74: 0,
      pu_74: 0,
      dba_73: 0,
      pu_73: 0,
      dba_72: 0,
      pu_72: 0,
      dba_71: 0,
      pu_71: 0,
      dba_70: 0,
      pu_70: 0,
      dba_69: 0,
      pu_69: 0,
      dba_68: 0,
      pu_68: 0,
      dba_67: 0,
      pu_67: 0,
      dba_66: 0,
      pu_66: 0,
      dba_65: 0,
      pu_65: 0,
      dba_64: 0,
      pu_64: 0,
      dba_63: 0,
      pu_63: 0,
      dba_62: 0,
      pu_62: 0,
      dba_61: 1,
      pu_61: 0,
      dba_60: 0,
      pu_60: 0,
      dba_59: 0,
      pu_59: 0,
      dba_58: 0,
      pu_58: 0,
      dba_57: 0,
      pu_57: 0,
      dba_56: 0,
      pu_56: 0,
      dba_55: 0,
      pu_55: 0,
      dba_54: 0,
      pu_54: 0,
      dba_53: 0,
      pu_53: 0,
      dba_52: 0,
      pu_52: 0,
      dba_51: 0,
      pu_51: 0,
      dba_50: 0,
      pu_50: 0,
      dba_49: 0,
      pu_49: 0,
      dba_48: 0,
      pu_48: 0,
      dba_47: 0,
      pu_47: 0,
      dba_46: 0,
      pu_46: 0,
      dba_45: 0,
      pu_45: 0,
      dba_44: 0,
      pu_44: 0,
      dba_43: 0,
      pu_43: 0,
      dba_42: 0,
      pu_42: 0,
      dba_41: 0,
      pu_41: 0,
      dba_40: 0,
      pu_40: 0,
      dba_39: 0,
      pu_39: 0,
      dba_38: 0,
      pu_38: 0,
      dba_37: 0,
      pu_37: 0,
      dba_36: 0,
      pu_36: 0,
      dba_35: 0,
      pu_35: 0,
      dba_34: 0,
      pu_34: 0,
      dba_33: 0,
      pu_33: 0,
      dba_32: 0,
      pu_32: 0,
      dba_31: 0,
      pu_31: 0,
      dba_30: 0,
      pu_30: 0,
      dba_29: 0,
      pu_29: 0,
      dba_28: 0,
      pu_28: 0,
      dba_27: 0,
      pu_27: 0,
      dba_26: 0,
      pu_26: 0,
      dba_25: 0,
      pu_25: 0,
      dba_24: 0,
      pu_24: 0,
      dba_23: 0,
      pu_23: 0,
      dba_22: 0,
      pu_22: 0,
      dba_21: 0,
      pu_21: 0,
      dba_20: 0,
      pu_20: 0,
      dba_19: 0,
      pu_19: 0,
      dba_18: 0,
      pu_18: 0,
      dba_17: 0,
      pu_17: 0,
      dba_16: 0,
      pu_16: 0,
      dba_15: 0,
      pu_15: 0,
      dba_14: 0,
      pu_14: 0,
      dba_13: 0,
      pu_13: 0,
      dba_12: 0,
      pu_12: 0,
      dba_11: 0,
      pu_11: 0,
      dba_10: 0,
      pu_10: 0,
      dba_9: 0,
      pu_9: 0,
      dba_8: 0,
      pu_8: 0,
      dba_7: 0,
      pu_7: 0,
      dba_6: 0,
      pu_6: 0,
      dba_5: 0,
      pu_5: 0,
      dba_4: 0,
      pu_4: 0,
      dba_3: 0,
      pu_3: 0,
      dba_2: 0,
      pu_2: 0,
      dba_1: 0,
      pu_1: 0,
      dba_0: 0,
      pu_0: 0,
    },
    {
      staydate: 1688083200000,
      day_diff: 1,
      total_booking: 0,
      forcastroom: 0,
      pu_avg: 0,
      dba_90: 0,
      pu_90: 0,
      dba_89: 0,
      pu_89: 0,
      dba_88: 0,
      pu_88: 0,
      dba_87: 0,
      pu_87: 0,
      dba_86: 0,
      pu_86: 0,
      dba_85: 0,
      pu_85: 0,
      dba_84: 0,
      pu_84: 0,
      dba_83: 0,
      pu_83: 0,
      dba_82: 0,
      pu_82: 0,
      dba_81: 0,
      pu_81: 0,
      dba_80: 0,
      pu_80: 0,
      dba_79: 0,
      pu_79: 0,
      dba_78: 0,
      pu_78: 0,
      dba_77: 0,
      pu_77: 0,
      dba_76: 0,
      pu_76: 0,
      dba_75: 0,
      pu_75: 0,
      dba_74: 0,
      pu_74: 0,
      dba_73: 0,
      pu_73: 0,
      dba_72: 0,
      pu_72: 0,
      dba_71: 0,
      pu_71: 0,
      dba_70: 0,
      pu_70: 0,
      dba_69: 0,
      pu_69: 0,
      dba_68: 0,
      pu_68: 0,
      dba_67: 0,
      pu_67: 0,
      dba_66: 0,
      pu_66: 0,
      dba_65: 0,
      pu_65: 0,
      dba_64: 0,
      pu_64: 0,
      dba_63: 0,
      pu_63: 0,
      dba_62: 0,
      pu_62: 0,
      dba_61: 1,
      pu_61: 0,
      dba_60: 0,
      pu_60: 0,
      dba_59: 0,
      pu_59: 0,
      dba_58: 0,
      pu_58: 0,
      dba_57: 0,
      pu_57: 0,
      dba_56: 0,
      pu_56: 0,
      dba_55: 0,
      pu_55: 0,
      dba_54: 0,
      pu_54: 0,
      dba_53: 0,
      pu_53: 0,
      dba_52: 0,
      pu_52: 0,
      dba_51: 0,
      pu_51: 0,
      dba_50: 0,
      pu_50: 0,
      dba_49: 0,
      pu_49: 0,
      dba_48: 0,
      pu_48: 0,
      dba_47: 0,
      pu_47: 0,
      dba_46: 0,
      pu_46: 0,
      dba_45: 0,
      pu_45: 0,
      dba_44: 0,
      pu_44: 0,
      dba_43: 0,
      pu_43: 0,
      dba_42: 0,
      pu_42: 0,
      dba_41: 0,
      pu_41: 0,
      dba_40: 0,
      pu_40: 0,
      dba_39: 0,
      pu_39: 0,
      dba_38: 0,
      pu_38: 0,
      dba_37: 0,
      pu_37: 0,
      dba_36: 0,
      pu_36: 0,
      dba_35: 0,
      pu_35: 0,
      dba_34: 0,
      pu_34: 0,
      dba_33: 0,
      pu_33: 0,
      dba_32: 0,
      pu_32: 0,
      dba_31: 0,
      pu_31: 0,
      dba_30: 0,
      pu_30: 0,
      dba_29: 0,
      pu_29: 0,
      dba_28: 0,
      pu_28: 0,
      dba_27: 0,
      pu_27: 0,
      dba_26: 0,
      pu_26: 0,
      dba_25: 0,
      pu_25: 0,
      dba_24: 0,
      pu_24: 0,
      dba_23: 0,
      pu_23: 0,
      dba_22: 0,
      pu_22: 0,
      dba_21: 0,
      pu_21: 0,
      dba_20: 0,
      pu_20: 0,
      dba_19: 0,
      pu_19: 0,
      dba_18: 0,
      pu_18: 0,
      dba_17: 0,
      pu_17: 0,
      dba_16: 0,
      pu_16: 0,
      dba_15: 0,
      pu_15: 0,
      dba_14: 0,
      pu_14: 0,
      dba_13: 0,
      pu_13: 0,
      dba_12: 0,
      pu_12: 0,
      dba_11: 0,
      pu_11: 0,
      dba_10: 0,
      pu_10: 0,
      dba_9: 0,
      pu_9: 0,
      dba_8: 0,
      pu_8: 0,
      dba_7: 0,
      pu_7: 0,
      dba_6: 0,
      pu_6: 0,
      dba_5: 0,
      pu_5: 0,
      dba_4: 0,
      pu_4: 0,
      dba_3: 0,
      pu_3: 0,
      dba_2: 0,
      pu_2: 0,
      dba_1: 0,
      pu_1: 0,
      dba_0: 0,
      pu_0: 0,
    },
    {
      staydate: 1688083200000,
      day_diff: 1,
      total_booking: 0,
      forcastroom: 0,
      pu_avg: 0,
      dba_90: 0,
      pu_90: 0,
      dba_89: 0,
      pu_89: 0,
      dba_88: 0,
      pu_88: 0,
      dba_87: 0,
      pu_87: 0,
      dba_86: 0,
      pu_86: 0,
      dba_85: 0,
      pu_85: 0,
      dba_84: 0,
      pu_84: 0,
      dba_83: 0,
      pu_83: 0,
      dba_82: 0,
      pu_82: 0,
      dba_81: 0,
      pu_81: 0,
      dba_80: 0,
      pu_80: 0,
      dba_79: 0,
      pu_79: 0,
      dba_78: 0,
      pu_78: 0,
      dba_77: 0,
      pu_77: 0,
      dba_76: 0,
      pu_76: 0,
      dba_75: 0,
      pu_75: 0,
      dba_74: 0,
      pu_74: 0,
      dba_73: 0,
      pu_73: 0,
      dba_72: 0,
      pu_72: 0,
      dba_71: 0,
      pu_71: 0,
      dba_70: 0,
      pu_70: 0,
      dba_69: 0,
      pu_69: 0,
      dba_68: 0,
      pu_68: 0,
      dba_67: 0,
      pu_67: 0,
      dba_66: 0,
      pu_66: 0,
      dba_65: 0,
      pu_65: 0,
      dba_64: 0,
      pu_64: 0,
      dba_63: 0,
      pu_63: 0,
      dba_62: 0,
      pu_62: 0,
      dba_61: 1,
      pu_61: 0,
      dba_60: 0,
      pu_60: 0,
      dba_59: 0,
      pu_59: 0,
      dba_58: 0,
      pu_58: 0,
      dba_57: 0,
      pu_57: 0,
      dba_56: 0,
      pu_56: 0,
      dba_55: 0,
      pu_55: 0,
      dba_54: 0,
      pu_54: 0,
      dba_53: 0,
      pu_53: 0,
      dba_52: 0,
      pu_52: 0,
      dba_51: 0,
      pu_51: 0,
      dba_50: 0,
      pu_50: 0,
      dba_49: 0,
      pu_49: 0,
      dba_48: 0,
      pu_48: 0,
      dba_47: 0,
      pu_47: 0,
      dba_46: 0,
      pu_46: 0,
      dba_45: 0,
      pu_45: 0,
      dba_44: 0,
      pu_44: 0,
      dba_43: 0,
      pu_43: 0,
      dba_42: 0,
      pu_42: 0,
      dba_41: 0,
      pu_41: 0,
      dba_40: 0,
      pu_40: 0,
      dba_39: 0,
      pu_39: 0,
      dba_38: 0,
      pu_38: 0,
      dba_37: 0,
      pu_37: 0,
      dba_36: 0,
      pu_36: 0,
      dba_35: 0,
      pu_35: 0,
      dba_34: 0,
      pu_34: 0,
      dba_33: 0,
      pu_33: 0,
      dba_32: 0,
      pu_32: 0,
      dba_31: 0,
      pu_31: 0,
      dba_30: 0,
      pu_30: 0,
      dba_29: 0,
      pu_29: 0,
      dba_28: 0,
      pu_28: 0,
      dba_27: 0,
      pu_27: 0,
      dba_26: 0,
      pu_26: 0,
      dba_25: 0,
      pu_25: 0,
      dba_24: 0,
      pu_24: 0,
      dba_23: 0,
      pu_23: 0,
      dba_22: 0,
      pu_22: 0,
      dba_21: 0,
      pu_21: 0,
      dba_20: 0,
      pu_20: 0,
      dba_19: 0,
      pu_19: 0,
      dba_18: 0,
      pu_18: 0,
      dba_17: 0,
      pu_17: 0,
      dba_16: 0,
      pu_16: 0,
      dba_15: 0,
      pu_15: 0,
      dba_14: 0,
      pu_14: 0,
      dba_13: 0,
      pu_13: 0,
      dba_12: 0,
      pu_12: 0,
      dba_11: 0,
      pu_11: 0,
      dba_10: 0,
      pu_10: 0,
      dba_9: 0,
      pu_9: 0,
      dba_8: 0,
      pu_8: 0,
      dba_7: 0,
      pu_7: 0,
      dba_6: 0,
      pu_6: 0,
      dba_5: 0,
      pu_5: 0,
      dba_4: 0,
      pu_4: 0,
      dba_3: 0,
      pu_3: 0,
      dba_2: 0,
      pu_2: 0,
      dba_1: 0,
      pu_1: 0,
      dba_0: 0,
      pu_0: 0,
    },
    {
      staydate: 1688083200000,
      day_diff: 1,
      total_booking: 0,
      forcastroom: 0,
      pu_avg: 0,
      dba_90: 0,
      pu_90: 0,
      dba_89: 0,
      pu_89: 0,
      dba_88: 0,
      pu_88: 0,
      dba_87: 0,
      pu_87: 0,
      dba_86: 0,
      pu_86: 0,
      dba_85: 0,
      pu_85: 0,
      dba_84: 0,
      pu_84: 0,
      dba_83: 0,
      pu_83: 0,
      dba_82: 0,
      pu_82: 0,
      dba_81: 0,
      pu_81: 0,
      dba_80: 0,
      pu_80: 0,
      dba_79: 0,
      pu_79: 0,
      dba_78: 0,
      pu_78: 0,
      dba_77: 0,
      pu_77: 0,
      dba_76: 0,
      pu_76: 0,
      dba_75: 0,
      pu_75: 0,
      dba_74: 0,
      pu_74: 0,
      dba_73: 0,
      pu_73: 0,
      dba_72: 0,
      pu_72: 0,
      dba_71: 0,
      pu_71: 0,
      dba_70: 0,
      pu_70: 0,
      dba_69: 0,
      pu_69: 0,
      dba_68: 0,
      pu_68: 0,
      dba_67: 0,
      pu_67: 0,
      dba_66: 0,
      pu_66: 0,
      dba_65: 0,
      pu_65: 0,
      dba_64: 0,
      pu_64: 0,
      dba_63: 0,
      pu_63: 0,
      dba_62: 0,
      pu_62: 0,
      dba_61: 1,
      pu_61: 0,
      dba_60: 0,
      pu_60: 0,
      dba_59: 0,
      pu_59: 0,
      dba_58: 0,
      pu_58: 0,
      dba_57: 0,
      pu_57: 0,
      dba_56: 0,
      pu_56: 0,
      dba_55: 0,
      pu_55: 0,
      dba_54: 0,
      pu_54: 0,
      dba_53: 0,
      pu_53: 0,
      dba_52: 0,
      pu_52: 0,
      dba_51: 0,
      pu_51: 0,
      dba_50: 0,
      pu_50: 0,
      dba_49: 0,
      pu_49: 0,
      dba_48: 0,
      pu_48: 0,
      dba_47: 0,
      pu_47: 0,
      dba_46: 0,
      pu_46: 0,
      dba_45: 0,
      pu_45: 0,
      dba_44: 0,
      pu_44: 0,
      dba_43: 0,
      pu_43: 0,
      dba_42: 0,
      pu_42: 0,
      dba_41: 0,
      pu_41: 0,
      dba_40: 0,
      pu_40: 0,
      dba_39: 0,
      pu_39: 0,
      dba_38: 0,
      pu_38: 0,
      dba_37: 0,
      pu_37: 0,
      dba_36: 0,
      pu_36: 0,
      dba_35: 0,
      pu_35: 0,
      dba_34: 0,
      pu_34: 0,
      dba_33: 0,
      pu_33: 0,
      dba_32: 0,
      pu_32: 0,
      dba_31: 0,
      pu_31: 0,
      dba_30: 0,
      pu_30: 0,
      dba_29: 0,
      pu_29: 0,
      dba_28: 0,
      pu_28: 0,
      dba_27: 0,
      pu_27: 0,
      dba_26: 0,
      pu_26: 0,
      dba_25: 0,
      pu_25: 0,
      dba_24: 0,
      pu_24: 0,
      dba_23: 0,
      pu_23: 0,
      dba_22: 0,
      pu_22: 0,
      dba_21: 0,
      pu_21: 0,
      dba_20: 0,
      pu_20: 0,
      dba_19: 0,
      pu_19: 0,
      dba_18: 0,
      pu_18: 0,
      dba_17: 0,
      pu_17: 0,
      dba_16: 0,
      pu_16: 0,
      dba_15: 0,
      pu_15: 0,
      dba_14: 0,
      pu_14: 0,
      dba_13: 0,
      pu_13: 0,
      dba_12: 0,
      pu_12: 0,
      dba_11: 0,
      pu_11: 0,
      dba_10: 0,
      pu_10: 0,
      dba_9: 0,
      pu_9: 0,
      dba_8: 0,
      pu_8: 0,
      dba_7: 0,
      pu_7: 0,
      dba_6: 0,
      pu_6: 0,
      dba_5: 0,
      pu_5: 0,
      dba_4: 0,
      pu_4: 0,
      dba_3: 0,
      pu_3: 0,
      dba_2: 0,
      pu_2: 0,
      dba_1: 0,
      pu_1: 0,
      dba_0: 0,
      pu_0: 0,
    },
    {
      staydate: 1688083200000,
      day_diff: 1,
      total_booking: 0,
      forcastroom: 0,
      pu_avg: 0,
      dba_90: 0,
      pu_90: 0,
      dba_89: 0,
      pu_89: 0,
      dba_88: 0,
      pu_88: 0,
      dba_87: 0,
      pu_87: 0,
      dba_86: 0,
      pu_86: 0,
      dba_85: 0,
      pu_85: 0,
      dba_84: 0,
      pu_84: 0,
      dba_83: 0,
      pu_83: 0,
      dba_82: 0,
      pu_82: 0,
      dba_81: 0,
      pu_81: 0,
      dba_80: 0,
      pu_80: 0,
      dba_79: 0,
      pu_79: 0,
      dba_78: 0,
      pu_78: 0,
      dba_77: 0,
      pu_77: 0,
      dba_76: 0,
      pu_76: 0,
      dba_75: 0,
      pu_75: 0,
      dba_74: 0,
      pu_74: 0,
      dba_73: 0,
      pu_73: 0,
      dba_72: 0,
      pu_72: 0,
      dba_71: 0,
      pu_71: 0,
      dba_70: 0,
      pu_70: 0,
      dba_69: 0,
      pu_69: 0,
      dba_68: 0,
      pu_68: 0,
      dba_67: 0,
      pu_67: 0,
      dba_66: 0,
      pu_66: 0,
      dba_65: 0,
      pu_65: 0,
      dba_64: 0,
      pu_64: 0,
      dba_63: 0,
      pu_63: 0,
      dba_62: 0,
      pu_62: 0,
      dba_61: 1,
      pu_61: 0,
      dba_60: 0,
      pu_60: 0,
      dba_59: 0,
      pu_59: 0,
      dba_58: 0,
      pu_58: 0,
      dba_57: 0,
      pu_57: 0,
      dba_56: 0,
      pu_56: 0,
      dba_55: 0,
      pu_55: 0,
      dba_54: 0,
      pu_54: 0,
      dba_53: 0,
      pu_53: 0,
      dba_52: 0,
      pu_52: 0,
      dba_51: 0,
      pu_51: 0,
      dba_50: 0,
      pu_50: 0,
      dba_49: 0,
      pu_49: 0,
      dba_48: 0,
      pu_48: 0,
      dba_47: 0,
      pu_47: 0,
      dba_46: 0,
      pu_46: 0,
      dba_45: 0,
      pu_45: 0,
      dba_44: 0,
      pu_44: 0,
      dba_43: 0,
      pu_43: 0,
      dba_42: 0,
      pu_42: 0,
      dba_41: 0,
      pu_41: 0,
      dba_40: 0,
      pu_40: 0,
      dba_39: 0,
      pu_39: 0,
      dba_38: 0,
      pu_38: 0,
      dba_37: 0,
      pu_37: 0,
      dba_36: 0,
      pu_36: 0,
      dba_35: 0,
      pu_35: 0,
      dba_34: 0,
      pu_34: 0,
      dba_33: 0,
      pu_33: 0,
      dba_32: 0,
      pu_32: 0,
      dba_31: 0,
      pu_31: 0,
      dba_30: 0,
      pu_30: 0,
      dba_29: 0,
      pu_29: 0,
      dba_28: 0,
      pu_28: 0,
      dba_27: 0,
      pu_27: 0,
      dba_26: 0,
      pu_26: 0,
      dba_25: 0,
      pu_25: 0,
      dba_24: 0,
      pu_24: 0,
      dba_23: 0,
      pu_23: 0,
      dba_22: 0,
      pu_22: 0,
      dba_21: 0,
      pu_21: 0,
      dba_20: 0,
      pu_20: 0,
      dba_19: 0,
      pu_19: 0,
      dba_18: 0,
      pu_18: 0,
      dba_17: 0,
      pu_17: 0,
      dba_16: 0,
      pu_16: 0,
      dba_15: 0,
      pu_15: 0,
      dba_14: 0,
      pu_14: 0,
      dba_13: 0,
      pu_13: 0,
      dba_12: 0,
      pu_12: 0,
      dba_11: 0,
      pu_11: 0,
      dba_10: 0,
      pu_10: 0,
      dba_9: 0,
      pu_9: 0,
      dba_8: 0,
      pu_8: 0,
      dba_7: 0,
      pu_7: 0,
      dba_6: 0,
      pu_6: 0,
      dba_5: 0,
      pu_5: 0,
      dba_4: 0,
      pu_4: 0,
      dba_3: 0,
      pu_3: 0,
      dba_2: 0,
      pu_2: 0,
      dba_1: 0,
      pu_1: 0,
      dba_0: 0,
      pu_0: 0,
    },
    {
      staydate: 1688083200000,
      day_diff: 1,
      total_booking: 0,
      forcastroom: 0,
      pu_avg: 0,
      dba_90: 0,
      pu_90: 0,
      dba_89: 0,
      pu_89: 0,
      dba_88: 0,
      pu_88: 0,
      dba_87: 0,
      pu_87: 0,
      dba_86: 0,
      pu_86: 0,
      dba_85: 0,
      pu_85: 0,
      dba_84: 0,
      pu_84: 0,
      dba_83: 0,
      pu_83: 0,
      dba_82: 0,
      pu_82: 0,
      dba_81: 0,
      pu_81: 0,
      dba_80: 0,
      pu_80: 0,
      dba_79: 0,
      pu_79: 0,
      dba_78: 0,
      pu_78: 0,
      dba_77: 0,
      pu_77: 0,
      dba_76: 0,
      pu_76: 0,
      dba_75: 0,
      pu_75: 0,
      dba_74: 0,
      pu_74: 0,
      dba_73: 0,
      pu_73: 0,
      dba_72: 0,
      pu_72: 0,
      dba_71: 0,
      pu_71: 0,
      dba_70: 0,
      pu_70: 0,
      dba_69: 0,
      pu_69: 0,
      dba_68: 0,
      pu_68: 0,
      dba_67: 0,
      pu_67: 0,
      dba_66: 0,
      pu_66: 0,
      dba_65: 0,
      pu_65: 0,
      dba_64: 0,
      pu_64: 0,
      dba_63: 0,
      pu_63: 0,
      dba_62: 0,
      pu_62: 0,
      dba_61: 1,
      pu_61: 0,
      dba_60: 0,
      pu_60: 0,
      dba_59: 0,
      pu_59: 0,
      dba_58: 0,
      pu_58: 0,
      dba_57: 0,
      pu_57: 0,
      dba_56: 0,
      pu_56: 0,
      dba_55: 0,
      pu_55: 0,
      dba_54: 0,
      pu_54: 0,
      dba_53: 0,
      pu_53: 0,
      dba_52: 0,
      pu_52: 0,
      dba_51: 0,
      pu_51: 0,
      dba_50: 0,
      pu_50: 0,
      dba_49: 0,
      pu_49: 0,
      dba_48: 0,
      pu_48: 0,
      dba_47: 0,
      pu_47: 0,
      dba_46: 0,
      pu_46: 0,
      dba_45: 0,
      pu_45: 0,
      dba_44: 0,
      pu_44: 0,
      dba_43: 0,
      pu_43: 0,
      dba_42: 0,
      pu_42: 0,
      dba_41: 0,
      pu_41: 0,
      dba_40: 0,
      pu_40: 0,
      dba_39: 0,
      pu_39: 0,
      dba_38: 0,
      pu_38: 0,
      dba_37: 0,
      pu_37: 0,
      dba_36: 0,
      pu_36: 0,
      dba_35: 0,
      pu_35: 0,
      dba_34: 0,
      pu_34: 0,
      dba_33: 0,
      pu_33: 0,
      dba_32: 0,
      pu_32: 0,
      dba_31: 0,
      pu_31: 0,
      dba_30: 0,
      pu_30: 0,
      dba_29: 0,
      pu_29: 0,
      dba_28: 0,
      pu_28: 0,
      dba_27: 0,
      pu_27: 0,
      dba_26: 0,
      pu_26: 0,
      dba_25: 0,
      pu_25: 0,
      dba_24: 0,
      pu_24: 0,
      dba_23: 0,
      pu_23: 0,
      dba_22: 0,
      pu_22: 0,
      dba_21: 0,
      pu_21: 0,
      dba_20: 0,
      pu_20: 0,
      dba_19: 0,
      pu_19: 0,
      dba_18: 0,
      pu_18: 0,
      dba_17: 0,
      pu_17: 0,
      dba_16: 0,
      pu_16: 0,
      dba_15: 0,
      pu_15: 0,
      dba_14: 0,
      pu_14: 0,
      dba_13: 0,
      pu_13: 0,
      dba_12: 0,
      pu_12: 0,
      dba_11: 0,
      pu_11: 0,
      dba_10: 0,
      pu_10: 0,
      dba_9: 0,
      pu_9: 0,
      dba_8: 0,
      pu_8: 0,
      dba_7: 0,
      pu_7: 0,
      dba_6: 0,
      pu_6: 0,
      dba_5: 0,
      pu_5: 0,
      dba_4: 0,
      pu_4: 0,
      dba_3: 0,
      pu_3: 0,
      dba_2: 0,
      pu_2: 0,
      dba_1: 0,
      pu_1: 0,
      dba_0: 0,
      pu_0: 0,
    },
    {
      staydate: 1688083200000,
      day_diff: 1,
      total_booking: 0,
      forcastroom: 0,
      pu_avg: 0,
      dba_90: 0,
      pu_90: 0,
      dba_89: 0,
      pu_89: 0,
      dba_88: 0,
      pu_88: 0,
      dba_87: 0,
      pu_87: 0,
      dba_86: 0,
      pu_86: 0,
      dba_85: 0,
      pu_85: 0,
      dba_84: 0,
      pu_84: 0,
      dba_83: 0,
      pu_83: 0,
      dba_82: 0,
      pu_82: 0,
      dba_81: 0,
      pu_81: 0,
      dba_80: 0,
      pu_80: 0,
      dba_79: 0,
      pu_79: 0,
      dba_78: 0,
      pu_78: 0,
      dba_77: 0,
      pu_77: 0,
      dba_76: 0,
      pu_76: 0,
      dba_75: 0,
      pu_75: 0,
      dba_74: 0,
      pu_74: 0,
      dba_73: 0,
      pu_73: 0,
      dba_72: 0,
      pu_72: 0,
      dba_71: 0,
      pu_71: 0,
      dba_70: 0,
      pu_70: 0,
      dba_69: 0,
      pu_69: 0,
      dba_68: 0,
      pu_68: 0,
      dba_67: 0,
      pu_67: 0,
      dba_66: 0,
      pu_66: 0,
      dba_65: 0,
      pu_65: 0,
      dba_64: 0,
      pu_64: 0,
      dba_63: 0,
      pu_63: 0,
      dba_62: 0,
      pu_62: 0,
      dba_61: 1,
      pu_61: 0,
      dba_60: 0,
      pu_60: 0,
      dba_59: 0,
      pu_59: 0,
      dba_58: 0,
      pu_58: 0,
      dba_57: 0,
      pu_57: 0,
      dba_56: 0,
      pu_56: 0,
      dba_55: 0,
      pu_55: 0,
      dba_54: 0,
      pu_54: 0,
      dba_53: 0,
      pu_53: 0,
      dba_52: 0,
      pu_52: 0,
      dba_51: 0,
      pu_51: 0,
      dba_50: 0,
      pu_50: 0,
      dba_49: 0,
      pu_49: 0,
      dba_48: 0,
      pu_48: 0,
      dba_47: 0,
      pu_47: 0,
      dba_46: 0,
      pu_46: 0,
      dba_45: 0,
      pu_45: 0,
      dba_44: 0,
      pu_44: 0,
      dba_43: 0,
      pu_43: 0,
      dba_42: 0,
      pu_42: 0,
      dba_41: 0,
      pu_41: 0,
      dba_40: 0,
      pu_40: 0,
      dba_39: 0,
      pu_39: 0,
      dba_38: 0,
      pu_38: 0,
      dba_37: 0,
      pu_37: 0,
      dba_36: 0,
      pu_36: 0,
      dba_35: 0,
      pu_35: 0,
      dba_34: 0,
      pu_34: 0,
      dba_33: 0,
      pu_33: 0,
      dba_32: 0,
      pu_32: 0,
      dba_31: 0,
      pu_31: 0,
      dba_30: 0,
      pu_30: 0,
      dba_29: 0,
      pu_29: 0,
      dba_28: 0,
      pu_28: 0,
      dba_27: 0,
      pu_27: 0,
      dba_26: 0,
      pu_26: 0,
      dba_25: 0,
      pu_25: 0,
      dba_24: 0,
      pu_24: 0,
      dba_23: 0,
      pu_23: 0,
      dba_22: 0,
      pu_22: 0,
      dba_21: 0,
      pu_21: 0,
      dba_20: 0,
      pu_20: 0,
      dba_19: 0,
      pu_19: 0,
      dba_18: 0,
      pu_18: 0,
      dba_17: 0,
      pu_17: 0,
      dba_16: 0,
      pu_16: 0,
      dba_15: 0,
      pu_15: 0,
      dba_14: 0,
      pu_14: 0,
      dba_13: 0,
      pu_13: 0,
      dba_12: 0,
      pu_12: 0,
      dba_11: 0,
      pu_11: 0,
      dba_10: 0,
      pu_10: 0,
      dba_9: 0,
      pu_9: 0,
      dba_8: 0,
      pu_8: 0,
      dba_7: 0,
      pu_7: 0,
      dba_6: 0,
      pu_6: 0,
      dba_5: 0,
      pu_5: 0,
      dba_4: 0,
      pu_4: 0,
      dba_3: 0,
      pu_3: 0,
      dba_2: 0,
      pu_2: 0,
      dba_1: 0,
      pu_1: 0,
      dba_0: 0,
      pu_0: 0,
    },
  ];
  const [dynamicPickupData, setDynamicPickupData] = useState(null);
  const [dynamicTotal, setDynamicTotal] = useState(null);
  const [loading, setLoading] = useState(false);
  const [widgetModal, setWidgetModal] = useState(false);
  const widgetDetailRef = useRef();
  const [showNoteSidebar, setShowNoteSidebar] = useState(false);
  const totalRef = useRef();
  const propertyDetailFromRTK = useSelector(
    (store) => store.headerProperty.propertyDetail
  );
  const defaultAsOfDateFromRTK = useSelector(
    (store) => store.headerProperty.defaultAsOfDate
  );
  const { snapshottitle, propertyCode, snapshotId } = useParams();
  const closeNoteSideBar = () => {
    setShowNoteSidebar(false);
  };

  const closeWidgetModal = () => {
    setWidgetModal(false);
  };
  let dummyArr = Array.from(Array(91).keys());

  const getDynamicPickupData = async () => {
    setLoading(true);
    let dynamicPickupResponse = await apiCall(
      {
        method: "POST",
        url: API_URL.BASE_API_URL.SnapShot + API_URL.PICK_UP.THIRTY_DAY_PICKUP,
        body: {
          AsOfDate: para?.asOfDate,
          propertyCode: para?.propertyCode,
        },
      },
      false
    );

    let response = apiResponse(false, dynamicPickupResponse);

    if (response?.isValidate) {
      let dataFromApi = response?.data?.data;
      let groupingJson = [];
      let totalJson = [];
      let total = {
        dayCount: 0,
        pu_90: 0,
        pu_89: 0,
        pu_88: 0,
        pu_87: 0,
        pu_86: 0,
        pu_85: 0,
        pu_84: 0,
        pu_83: 0,
        pu_82: 0,
        pu_81: 0,
        pu_80: 0,
        pu_79: 0,
        pu_78: 0,
        pu_77: 0,
        pu_76: 0,
        pu_75: 0,
        pu_74: 0,
        pu_73: 0,
        pu_72: 0,
        pu_71: 0,
        pu_70: 0,
        pu_69: 0,
        pu_68: 0,
        pu_67: 0,
        pu_66: 0,
        pu_65: 0,
        pu_64: 0,
        pu_63: 0,
        pu_62: 0,
        pu_61: 0,
        pu_60: 0,
        pu_59: 0,
        pu_58: 0,
        pu_57: 0,
        pu_56: 0,
        pu_55: 0,
        pu_54: 0,
        pu_53: 0,
        pu_52: 0,
        pu_51: 0,
        pu_50: 0,
        pu_49: 0,
        pu_48: 0,
        pu_47: 0,
        pu_46: 0,
        pu_45: 0,
        pu_44: 0,
        pu_43: 0,
        pu_42: 0,
        pu_41: 0,
        pu_40: 0,
        pu_39: 0,
        pu_38: 0,
        pu_37: 0,
        pu_36: 0,
        pu_35: 0,
        pu_34: 0,
        pu_33: 0,
        pu_32: 0,
        pu_31: 0,
        pu_30: 0,
        pu_29: 0,
        pu_28: 0,
        pu_27: 0,
        pu_26: 0,
        pu_25: 0,
        pu_24: 0,
        pu_23: 0,
        pu_22: 0,
        pu_21: 0,
        pu_20: 0,
        pu_19: 0,
        pu_18: 0,
        pu_17: 0,
        pu_16: 0,
        pu_15: 0,
        pu_14: 0,
        pu_13: 0,
        pu_12: 0,
        pu_11: 0,
        pu_10: 0,
        pu_9: 0,
        pu_8: 0,
        pu_7: 0,
        pu_6: 0,
        pu_5: 0,
        pu_4: 0,
        pu_3: 0,
        pu_2: 0,
        pu_1: 0,
        pu_0: 0,
      };
      for (let i = 0; i < dataFromApi?.length; i++) {
        groupingJson.push(dataFromApi[i]);
        totalJson.push({
          day_diff: dataFromApi?.[i]?.day_diff,
          pu_avg: dataFromApi?.[i]?.pu_avg,
        });

        total.dayCount = total.dayCount + 1;
        total.pu_90 =
          total.pu_90 + (dataFromApi?.[i]?.pu_90 ? dataFromApi?.[i]?.pu_90 : 0);
        total.pu_89 =
          total.pu_89 + (dataFromApi?.[i]?.pu_89 ? dataFromApi?.[i]?.pu_89 : 0);
        total.pu_88 =
          total.pu_88 + (dataFromApi?.[i]?.pu_88 ? dataFromApi?.[i]?.pu_88 : 0);
        total.pu_87 =
          total.pu_87 + (dataFromApi?.[i]?.pu_87 ? dataFromApi?.[i]?.pu_87 : 0);
        total.pu_86 =
          total.pu_86 + (dataFromApi?.[i]?.pu_86 ? dataFromApi?.[i]?.pu_86 : 0);
        total.pu_85 =
          total.pu_85 + (dataFromApi?.[i]?.pu_85 ? dataFromApi?.[i]?.pu_85 : 0);
        total.pu_84 =
          total.pu_84 + (dataFromApi?.[i]?.pu_84 ? dataFromApi?.[i]?.pu_84 : 0);
        total.pu_83 =
          total.pu_83 + (dataFromApi?.[i]?.pu_83 ? dataFromApi?.[i]?.pu_83 : 0);
        total.pu_82 =
          total.pu_82 + (dataFromApi?.[i]?.pu_82 ? dataFromApi?.[i]?.pu_82 : 0);
        total.pu_81 =
          total.pu_81 + (dataFromApi?.[i]?.pu_81 ? dataFromApi?.[i]?.pu_81 : 0);
        total.pu_80 =
          total.pu_80 + (dataFromApi?.[i]?.pu_80 ? dataFromApi?.[i]?.pu_80 : 0);
        total.pu_79 =
          total.pu_79 + (dataFromApi?.[i]?.pu_79 ? dataFromApi?.[i]?.pu_79 : 0);
        total.pu_78 =
          total.pu_78 + (dataFromApi?.[i]?.pu_78 ? dataFromApi?.[i]?.pu_78 : 0);
        total.pu_77 =
          total.pu_77 + (dataFromApi?.[i]?.pu_77 ? dataFromApi?.[i]?.pu_77 : 0);
        total.pu_76 =
          total.pu_76 + (dataFromApi?.[i]?.pu_76 ? dataFromApi?.[i]?.pu_76 : 0);
        total.pu_75 =
          total.pu_75 + (dataFromApi?.[i]?.pu_75 ? dataFromApi?.[i]?.pu_75 : 0);
        total.pu_74 =
          total.pu_74 + (dataFromApi?.[i]?.pu_74 ? dataFromApi?.[i]?.pu_74 : 0);
        total.pu_73 =
          total.pu_73 + (dataFromApi?.[i]?.pu_73 ? dataFromApi?.[i]?.pu_73 : 0);
        total.pu_72 =
          total.pu_72 + (dataFromApi?.[i]?.pu_72 ? dataFromApi?.[i]?.pu_72 : 0);
        total.pu_71 =
          total.pu_71 + (dataFromApi?.[i]?.pu_71 ? dataFromApi?.[i]?.pu_71 : 0);
        total.pu_70 =
          total.pu_70 + (dataFromApi?.[i]?.pu_70 ? dataFromApi?.[i]?.pu_70 : 0);
        total.pu_69 =
          total.pu_69 + (dataFromApi?.[i]?.pu_69 ? dataFromApi?.[i]?.pu_69 : 0);
        total.pu_68 =
          total.pu_68 + (dataFromApi?.[i]?.pu_68 ? dataFromApi?.[i]?.pu_68 : 0);
        total.pu_67 =
          total.pu_67 + (dataFromApi?.[i]?.pu_67 ? dataFromApi?.[i]?.pu_67 : 0);
        total.pu_66 =
          total.pu_66 + (dataFromApi?.[i]?.pu_66 ? dataFromApi?.[i]?.pu_66 : 0);
        total.pu_65 =
          total.pu_65 + (dataFromApi?.[i]?.pu_65 ? dataFromApi?.[i]?.pu_65 : 0);
        total.pu_64 =
          total.pu_64 + (dataFromApi?.[i]?.pu_64 ? dataFromApi?.[i]?.pu_64 : 0);
        total.pu_63 =
          total.pu_63 + (dataFromApi?.[i]?.pu_63 ? dataFromApi?.[i]?.pu_63 : 0);
        total.pu_62 =
          total.pu_62 + (dataFromApi?.[i]?.pu_62 ? dataFromApi?.[i]?.pu_62 : 0);
        total.pu_61 =
          total.pu_61 + (dataFromApi?.[i]?.pu_61 ? dataFromApi?.[i]?.pu_61 : 0);
        total.pu_60 =
          total.pu_60 + (dataFromApi?.[i]?.pu_60 ? dataFromApi?.[i]?.pu_60 : 0);
        total.pu_59 =
          total.pu_59 + (dataFromApi?.[i]?.pu_59 ? dataFromApi?.[i]?.pu_59 : 0);
        total.pu_58 =
          total.pu_58 + (dataFromApi?.[i]?.pu_58 ? dataFromApi?.[i]?.pu_58 : 0);
        total.pu_57 =
          total.pu_57 + (dataFromApi?.[i]?.pu_57 ? dataFromApi?.[i]?.pu_57 : 0);
        total.pu_56 =
          total.pu_56 + (dataFromApi?.[i]?.pu_56 ? dataFromApi?.[i]?.pu_56 : 0);
        total.pu_55 =
          total.pu_55 + (dataFromApi?.[i]?.pu_55 ? dataFromApi?.[i]?.pu_55 : 0);
        total.pu_54 =
          total.pu_54 + (dataFromApi?.[i]?.pu_54 ? dataFromApi?.[i]?.pu_54 : 0);
        total.pu_53 =
          total.pu_53 + (dataFromApi?.[i]?.pu_53 ? dataFromApi?.[i]?.pu_53 : 0);
        total.pu_52 =
          total.pu_52 + (dataFromApi?.[i]?.pu_52 ? dataFromApi?.[i]?.pu_52 : 0);
        total.pu_51 =
          total.pu_51 + (dataFromApi?.[i]?.pu_51 ? dataFromApi?.[i]?.pu_51 : 0);
        total.pu_50 =
          total.pu_50 + (dataFromApi?.[i]?.pu_50 ? dataFromApi?.[i]?.pu_50 : 0);
        total.pu_49 =
          total.pu_49 + (dataFromApi?.[i]?.pu_49 ? dataFromApi?.[i]?.pu_49 : 0);
        total.pu_48 =
          total.pu_48 + (dataFromApi?.[i]?.pu_48 ? dataFromApi?.[i]?.pu_48 : 0);
        total.pu_47 =
          total.pu_47 + (dataFromApi?.[i]?.pu_47 ? dataFromApi?.[i]?.pu_47 : 0);
        total.pu_46 =
          total.pu_46 + (dataFromApi?.[i]?.pu_46 ? dataFromApi?.[i]?.pu_46 : 0);
        total.pu_45 =
          total.pu_45 + (dataFromApi?.[i]?.pu_45 ? dataFromApi?.[i]?.pu_45 : 0);
        total.pu_44 =
          total.pu_44 + (dataFromApi?.[i]?.pu_44 ? dataFromApi?.[i]?.pu_44 : 0);
        total.pu_43 =
          total.pu_43 + (dataFromApi?.[i]?.pu_43 ? dataFromApi?.[i]?.pu_43 : 0);
        total.pu_42 =
          total.pu_42 + (dataFromApi?.[i]?.pu_42 ? dataFromApi?.[i]?.pu_42 : 0);
        total.pu_41 =
          total.pu_41 + (dataFromApi?.[i]?.pu_41 ? dataFromApi?.[i]?.pu_41 : 0);
        total.pu_40 =
          total.pu_40 + (dataFromApi?.[i]?.pu_40 ? dataFromApi?.[i]?.pu_40 : 0);
        total.pu_39 =
          total.pu_39 + (dataFromApi?.[i]?.pu_39 ? dataFromApi?.[i]?.pu_39 : 0);
        total.pu_38 =
          total.pu_38 + (dataFromApi?.[i]?.pu_38 ? dataFromApi?.[i]?.pu_38 : 0);
        total.pu_37 =
          total.pu_37 + (dataFromApi?.[i]?.pu_37 ? dataFromApi?.[i]?.pu_37 : 0);
        total.pu_36 =
          total.pu_36 + (dataFromApi?.[i]?.pu_36 ? dataFromApi?.[i]?.pu_36 : 0);
        total.pu_35 =
          total.pu_35 + (dataFromApi?.[i]?.pu_35 ? dataFromApi?.[i]?.pu_35 : 0);
        total.pu_34 =
          total.pu_34 + (dataFromApi?.[i]?.pu_34 ? dataFromApi?.[i]?.pu_34 : 0);
        total.pu_33 =
          total.pu_33 + (dataFromApi?.[i]?.pu_33 ? dataFromApi?.[i]?.pu_33 : 0);
        total.pu_32 =
          total.pu_32 + (dataFromApi?.[i]?.pu_32 ? dataFromApi?.[i]?.pu_32 : 0);
        total.pu_31 =
          total.pu_31 + (dataFromApi?.[i]?.pu_31 ? dataFromApi?.[i]?.pu_31 : 0);
        total.pu_30 =
          total.pu_30 + (dataFromApi?.[i]?.pu_30 ? dataFromApi?.[i]?.pu_30 : 0);
        total.pu_29 =
          total.pu_29 + (dataFromApi?.[i]?.pu_29 ? dataFromApi?.[i]?.pu_29 : 0);
        total.pu_28 =
          total.pu_28 + (dataFromApi?.[i]?.pu_28 ? dataFromApi?.[i]?.pu_28 : 0);
        total.pu_27 =
          total.pu_27 + (dataFromApi?.[i]?.pu_27 ? dataFromApi?.[i]?.pu_27 : 0);
        total.pu_26 =
          total.pu_26 + (dataFromApi?.[i]?.pu_26 ? dataFromApi?.[i]?.pu_26 : 0);
        total.pu_25 =
          total.pu_25 + (dataFromApi?.[i]?.pu_25 ? dataFromApi?.[i]?.pu_25 : 0);
        total.pu_24 =
          total.pu_24 + (dataFromApi?.[i]?.pu_24 ? dataFromApi?.[i]?.pu_24 : 0);
        total.pu_23 =
          total.pu_23 + (dataFromApi?.[i]?.pu_23 ? dataFromApi?.[i]?.pu_23 : 0);
        total.pu_22 =
          total.pu_22 + (dataFromApi?.[i]?.pu_22 ? dataFromApi?.[i]?.pu_22 : 0);
        total.pu_21 =
          total.pu_21 + (dataFromApi?.[i]?.pu_21 ? dataFromApi?.[i]?.pu_21 : 0);
        total.pu_20 =
          total.pu_20 + (dataFromApi?.[i]?.pu_20 ? dataFromApi?.[i]?.pu_20 : 0);
        total.pu_19 =
          total.pu_19 + (dataFromApi?.[i]?.pu_19 ? dataFromApi?.[i]?.pu_19 : 0);
        total.pu_18 =
          total.pu_18 + (dataFromApi?.[i]?.pu_18 ? dataFromApi?.[i]?.pu_18 : 0);
        total.pu_17 =
          total.pu_17 + (dataFromApi?.[i]?.pu_17 ? dataFromApi?.[i]?.pu_17 : 0);
        total.pu_16 =
          total.pu_16 + (dataFromApi?.[i]?.pu_16 ? dataFromApi?.[i]?.pu_16 : 0);
        total.pu_15 =
          total.pu_15 + (dataFromApi?.[i]?.pu_15 ? dataFromApi?.[i]?.pu_15 : 0);
        total.pu_14 =
          total.pu_14 + (dataFromApi?.[i]?.pu_14 ? dataFromApi?.[i]?.pu_14 : 0);
        total.pu_13 =
          total.pu_13 + (dataFromApi?.[i]?.pu_13 ? dataFromApi?.[i]?.pu_13 : 0);
        total.pu_12 =
          total.pu_12 + (dataFromApi?.[i]?.pu_12 ? dataFromApi?.[i]?.pu_12 : 0);
        total.pu_11 =
          total.pu_11 + (dataFromApi?.[i]?.pu_11 ? dataFromApi?.[i]?.pu_11 : 0);
        total.pu_10 =
          total.pu_10 + (dataFromApi?.[i]?.pu_10 ? dataFromApi?.[i]?.pu_10 : 0);
        total.pu_9 =
          total.pu_9 + (dataFromApi?.[i]?.pu_9 ? dataFromApi?.[i]?.pu_9 : 0);
        total.pu_8 =
          total.pu_8 + (dataFromApi?.[i]?.pu_8 ? dataFromApi?.[i]?.pu_8 : 0);
        total.pu_7 =
          total.pu_7 + (dataFromApi?.[i]?.pu_7 ? dataFromApi?.[i]?.pu_7 : 0);
        total.pu_6 =
          total.pu_6 + (dataFromApi?.[i]?.pu_6 ? dataFromApi?.[i]?.pu_6 : 0);
        total.pu_5 =
          total.pu_5 + (dataFromApi?.[i]?.pu_5 ? dataFromApi?.[i]?.pu_5 : 0);
        total.pu_4 =
          total.pu_4 + (dataFromApi?.[i]?.pu_4 ? dataFromApi?.[i]?.pu_4 : 0);
        total.pu_3 =
          total.pu_3 + (dataFromApi?.[i]?.pu_3 ? dataFromApi?.[i]?.pu_3 : 0);
        total.pu_2 =
          total.pu_2 + (dataFromApi?.[i]?.pu_2 ? dataFromApi?.[i]?.pu_2 : 0);
        total.pu_1 =
          total.pu_1 + (dataFromApi?.[i]?.pu_1 ? dataFromApi?.[i]?.pu_1 : 0);
        total.pu_0 =
          total.pu_0 + (dataFromApi?.[i]?.pu_0 ? dataFromApi?.[i]?.pu_0 : 0);
      }
      let sortedTotalJson = totalJson.sort(
        (a, b) => parseFloat(b.day_diff) - parseFloat(a.day_diff)
      );
      totalRef.current = total;
      setLoading(false);
      setDynamicTotal(sortedTotalJson);
      setDynamicPickupData(groupingJson);
    }

    if (!response?.isValidate) {
      setLoading(false);
      setDynamicPickupData(tableData);
    }
  };

  useEffect(() => {
    if (api === 0) {
      setDynamicPickupData(tableData);
    } else {
      if (para?.asOfDate) {
        getDynamicPickupData();
      }
    }
  }, [para?.asOfDate]);

  FixColumn("tblDynamicPickup", [1, 2, 3, 4]);

  const [tblids, settblids] = useState({
    tblDynamicPickup: commonService.GenerateGUID(),
  });

  return (
    <div className="widget-container">
      {api !== 0 && (
        <div className="gap-1 widget-container-menu">
          {/* <Button className="heading-menu-btns" type="button">
            <img
              src={NoteIcon}
              alt="menuIcon"
              onClick={() => {
                setShowNoteSidebar(true);
              }}
            />
          </Button> */}
          <Button className="heading-menu-btns" type="button" title="Send Mail">
            <img
              src={emailIcon}
              alt="emailIcon"
              onClick={() => {
                commonService.fnSendWidgetMail(
                  tblids.tblDynamicPickup,
                  "Past Dynamic Pickup",
                  snapshotId,
                  defaultAsOfDateFromRTK,
                  "",
                  para?.widgetId
                );
              }}
            />
          </Button>
          <Button className="heading-menu-btns" type="button">
            <img
              src={infoIcon}
              alt="info"
              onClick={() => {
                widgetDetailRef.current = {
                  widgetdetails: "",
                  usernote: "",
                  compName: compName?.thirtyDayPickup,
                  widgetId: para?.widgetId,
                };
                setWidgetModal(true);
              }}
            />
          </Button>
          <Button className="heading-menu-btns" type="button">
            <img
              src={downloadIcon}
              alt="menuIcon"
              import={tblids.tblDynamicPickup}
              onClick={() =>
                commonService.fnExportInCSV(
                  tblids.tblDynamicPickup,
                  // "Dynamic Pickup"
                  `${para?.propertyCode}-Dynamic Pickup-${moment(
                    new Date()
                  ).format("MMDDYYYY")}`
                )
              }
            />
          </Button>
        </div>
      )}
      <div className="ViewPropertySetupTable">
        <div className="stripped__table">
          <div className="table-responsive tblDynamicPickupSticky">
            <Table id={tblids.tblDynamicPickup} responsive>
              <thead className="tbl-header-sticky">
                <tr className="table-main-header-tr">
                  <th></th>
                  {/* <th></th>
                  <th></th>
                  <th></th> */}
                  {dummyArr?.map((item, index) => (
                    <th className="text-center border-right" colSpan={2}>
                      {90 - index}
                    </th>
                  ))}
                </tr>
                <tr className="table-main-header-tr">
                  {dynamicPickupData && (
                    <>
                      <th>staydate</th>
                      {/* <th>day diff</th>
                      <th>total booking</th>
                      <th>forcast room</th> */}
                      {Object?.keys(dynamicPickupData?.[0])?.map(
                        (item, index) => {
                          return (
                            <>
                              {item !== "staydate" &&
                                item !== "day_diff" &&
                                item !== "total_booking" &&
                                item !== "forcastroom" &&
                                item !== "pu_avg" && (
                                  <th
                                    key={index}
                                    className={`${
                                      item === "staydate"
                                        ? "text-left"
                                        : "text-center"
                                    } ${index % 2 !== 0 ? "border-right" : ""}`}
                                  >
                                    {/* {item?.replace("_", " ")} */}
                                    {item?.split("_")?.[0]}
                                  </th>
                                )}
                            </>
                          );
                        }
                      )}
                    </>
                  )}
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr style={{ height: "80px", position: "relative" }}>
                    <Loader />
                  </tr>
                ) : (
                  dynamicPickupData &&
                  dynamicPickupData?.map((item, index) => {
                    return (
                      <>
                        <tr key={index}>
                          <td>
                            {commonService.getDateInFormat(
                              new Date(item?.["staydate"])
                            )}
                          </td>
                          {/* <td className="text-center">{item?.day_diff}</td>
                          <td className="text-center">{item?.total_booking}</td>
                          <td className="text-center">{item?.forcastroom}</td> */}
                          {Object.keys(item)?.map((objItem, objIndex) => {
                            return (
                              <>
                                {objItem !== "staydate" &&
                                  objItem !== "day_diff" &&
                                  objItem !== "total_booking" &&
                                  objItem !== "forcastroom" &&
                                  objItem !== "pu_avg" && (
                                    <td
                                      className={`${
                                        objItem === "staydate"
                                          ? "text-left"
                                          : "text-center"
                                      } ${
                                        objIndex % 2 !== 0 ? "border-right" : ""
                                      }`}
                                    >
                                      {item?.[objItem]}
                                    </td>
                                  )}
                              </>
                            );
                          })}
                        </tr>
                        {index === dynamicPickupData?.length - 1 && (
                          <tr>
                            <td className="boldTd border_right">30 Day Avg</td>
                            {/* <td className="boldTd"></td>
                            <td className="boldTd"></td>
                            <td className="boldTd border_right"></td> */}
                            {totalRef.current &&
                              Object.entries(totalRef.current).map(
                                ([key, value]) => {
                                  if (key !== "dayCount") {
                                    return (
                                      <>
                                        <td className="boldTd"></td>
                                        <td className="boldTd border_right">
                                          {Math.round(
                                            value / totalRef.current?.dayCount
                                          )}
                                        </td>
                                      </>
                                    );
                                  }
                                }
                              )}
                          </tr>
                        )}
                      </>
                    );
                  })
                )}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
      {api !== 0 && <Comments widgetDetails={para} editorId={"ThirtyDayPickup"}/>}

      {showNoteSidebar && (
        <AddWidgetModal
          showModal={showNoteSidebar}
          closeModal={closeNoteSideBar}
          widgetId={para?.widgetId}
          propertyId={propertyDetailFromRTK?.propertyid}
          rowTitle={para?.rowTitle}
        />
      )}
      {widgetModal && (
        <WidgetDetailModal
          showModal={widgetModal}
          closeModal={closeWidgetModal}
          data={widgetDetailRef.current}
        />
      )}
    </div>
  );
};

export default ThirtyDayPickup;
