import { apiResponse } from "../../../utils/apiResponse";
import { apiCall } from "../../../utils/axiosService";

export const hotelListCommon = async (payload, formType) => {
  let Result = {
    data: "",
    isValidate: false,
  };
  let propertyListResponse = await apiCall(
    {
      method: payload.method,
      url: payload.url,
      body: payload.body,
    },
    formType
  );

  let response = apiResponse(formType, propertyListResponse);

  if (response?.isValidate) {
    Result = {
      data: response?.data?.data,
      isValidate: true,
    };
    return Result;
  } else {
    // errorToastMessage("GetList Error");
  }
};
