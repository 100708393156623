import React, { useEffect, useState } from "react";
import { commonService } from "../../../../utils/commonService";
import { Col, Container, Form, Row, Table } from "react-bootstrap";
import { apiCall } from "../../../../utils/axiosService";
import { API_URL } from "../../../../utils/apiRoutes";
import { apiResponse } from "../../../../utils/apiResponse";
import { Bar, PolarArea } from "react-chartjs-2";
import {
  Chart,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  RadialLinearScale,
} from "chart.js";
import Loader from "../../../Loader/Loader";
import Comments from "../Comments/Comment";

const CancellationPace = ({
  setCompDataFromChild,
  rowIndex,
  colIndex,
  api,
  para,
  setting,
}) => {
  const yearsArr = [
    ...commonService.getPastYearForDropDown(10),
    ...commonService.getFutureYearForDropDown(10),
    new Date().getFullYear(),
  ]?.sort(function (a, b) {
    return a - b;
  });
  const tableData = [
    {
      groupName: "CP2TO7",
      groupValue: "0",
    },
    {
      groupName: "CP0",
      groupValue: "0",
    },
    {
      groupName: "CP1",
      groupValue: "0",
    },
    {
      groupName: "CP16TO30",
      groupValue: "0",
    },
    {
      groupName: "CP31TO60",
      groupValue: "0",
    },
    {
      groupName: "CP91TOUP",
      groupValue: "0",
    },
    {
      groupName: "CP8TO15",
      groupValue: "0",
    },
    {
      groupName: "CP61TO90",
      groupValue: "0",
    },
  ];

  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [loading, setLoading] = useState(false);
  const [paceData, setPaceData] = useState(null);

  let nameOfGroup = {
    CP0: "0 Day",
    CP1: "1 Day",
    CP2TO7: "2 to 7 Days",
    CP8TO15: "8 to 15 Days",
    CP16TO30: "16 to 30 Days",
    CP31TO60: "31 to 60 Days",
    CP61TO90: "61 to 90 Days",
    CP91TOUP: "90+ Days",
  };

  const getCancellationPaceData = async () => {
    setLoading(true);
    let cancellationPaceResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.SnapShot +
          API_URL.CANCELLATION.CANCELLATION_PACE,
        body: {
          AsOfDate: para?.asOfDate,
          propertyCode: para?.propertyCode,
          year: selectedYear,
        },
      },
      false
    );

    let response = apiResponse(false, cancellationPaceResponse);

    if (response?.isValidate) {
      let dataFromApi = response?.data?.data;
      let groupingJson = [];

      Object.entries(dataFromApi?.[0]).map(([key, value]) => {
        if (
          key !== "AsOfDate" &&
          !key?.includes("ADR") &&
          !key?.includes("REV")
        ) {
          groupingJson = [
            ...groupingJson,
            {
              groupName: key,
              groupValue: value,
              groupAdr: dataFromApi?.[0]?.[`${[key]}_ADR`],
              groupRev: dataFromApi?.[0]?.[`${[key]}_REV`],
            },
          ];
        }
      });

      groupingJson.sort(function (a, b) {
        return parseFloat(b.groupValue) - parseFloat(a.groupValue);
      });

      setPaceData(groupingJson);
      setLoading(false);
    }

    if (!response?.isValidate) {
      setPaceData(tableData);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (api === 0) {
      setPaceData(tableData);
    } else {
      if (para?.asOfDate) {
        getCancellationPaceData();
      }
    }
  }, [para?.asOfDate, selectedYear]);

  const [tblids, settblids] = useState({
    cancellationPace: commonService.GenerateGUID(),
  });

  return (
    <div className="widget-container">
      {/* <Container fluid> */}
      <Row>
        <Col xl={12}>
          <Col xl={1}>
            <div className="from-groups no-border-break">
              <Form.Select
                aria-label="Default select example"
                value={selectedYear}
                onChange={(e) => {
                  setSelectedYear(e.target.value);
                }}
              >
                {yearsArr?.map((item, index) => (
                  <option key={item} value={item}>
                    {item}
                  </option>
                ))}
              </Form.Select>
            </div>
          </Col>
        </Col>
        <Col xl={12} className="mt-2">
          <Row>
            <Col xl={4}>
              <div className="ViewPropertySetupTable">
                <div className="stripped__table">
                  <Table id={tblids.cancellationPace} responsive>
                    <thead>
                      <tr>
                        <th className="text-center" colSpan={4}>
                          CANCELLATION Window
                        </th>
                      </tr>
                      <tr>
                        <th className="text-center">Cancellation Days Group</th>
                        <th className="text-center">Count of room night</th>
                        <th className="text-end">ADR</th>
                        <th className="text-end">Revenue Loss</th>
                      </tr>
                    </thead>
                    <tbody>
                      {loading ? (
                        <tr style={{ height: "80px", position: "relative" }}>
                          <Loader />
                        </tr>
                      ) : (
                        paceData &&
                        paceData?.map((item) => (
                          <tr className="shadowOnHover">
                            <td className="text-center">
                              {nameOfGroup[item?.groupName]}
                            </td>
                            <td className="text-center">{commonService.formateRoom(item?.groupValue)}</td>
                            <td className="text-end">
                              {item?.groupAdr ? commonService.formateAmount(item?.groupAdr) : commonService.formateAmount(0)}
                            </td>
                            <td className="text-end">
                              {item?.groupRev ? commonService.formateAmount(item?.groupRev) : commonService.formateAmount(0)}
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
            </Col>
            <Col xl={8}>
              <div className="funnelChart">
                <Table>
                  <thead>
                    <tr>
                      <th className="text-center" colSpan={2}>
                        Yearly Cancellation Window
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <tr style={{ height: "80px", position: "relative" }}>
                        <Loader />
                      </tr>
                    ) : (
                      paceData &&
                      paceData?.map((item, index) => {
                        let maxValue = paceData?.[0]?.groupValue
                          ? parseInt(paceData?.[0]?.groupValue)
                          : 0;

                        let percentage =
                          (parseInt(item?.groupValue) * 100) / maxValue;
                        return (
                          <tr>
                            <td className="width125">
                              {nameOfGroup[item?.groupName]}
                            </td>
                            <td
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                height: "35px",
                              }}
                            >
                              <div
                                style={{
                                  backgroundColor: "#4472C4",
                                  color: "white",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  width: `calc(${percentage}% + 15px)`,
                                }}
                              >
                                {commonService.formateRoom(item?.groupValue)}
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </Table>
              </div>
            </Col>
            {api !== 0 && <Comments widgetDetails={para} editorId={"CancellationPace"}/>}
          </Row>
        </Col>
      </Row>
      {/* </Container> */}
    </div>
  );
};

export default CancellationPace;
