import React, { useState } from "react";
import WeeklyStar from "./WeeklyStar";
import RunningStar from "./RunningStar";
import StrDayByDay from "./StrDayByDay";
import { Col } from "react-bootstrap";
import Comments from "../Comments/Comment";

const StrAnalysis = ({
  setCompDataFromChild,
  rowIndex,
  colIndex,
  api,
  para,
  setting,
}) => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const handleSelectedDate = (date) => {
    setSelectedDate(date);
  };
  return (
    <div className="strAnalysisRevpak">
      <Col xl={12}>
        <WeeklyStar
          setCompDataFromChild={setCompDataFromChild}
          rowIndex={rowIndex}
          colIndex={colIndex}
          api={api}
          para={para}
          setting={setting}
          setWeeklyDate={handleSelectedDate}
        />
      </Col>

      <Col xl={12} className="mt-3">
        <RunningStar
          setCompDataFromChild={setCompDataFromChild}
          rowIndex={rowIndex}
          colIndex={colIndex}
          api={api}
          para={para}
          setting={setting}
        />
      </Col>

      <Col xl={12} className="mt-3">
        <StrDayByDay
          setCompDataFromChild={setCompDataFromChild}
          rowIndex={rowIndex}
          colIndex={colIndex}
          api={api}
          para={para}
          setting={setting}
          selectedDateForApiCall={selectedDate}
        />
      </Col>
      {api !== 0 && <Comments widgetDetails={para} editorId={"StrAnalysis"}/>}
    </div>
  );
};

export default StrAnalysis;
