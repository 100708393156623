import React, { useEffect, useRef, useState } from "react";
import { Button, Overlay, Popover, Table } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { commonService } from "../../../../utils/commonService";
import { apiResponse } from "../../../../utils/apiResponse";
import { apiCall } from "../../../../utils/axiosService";
import { API_URL } from "../../../../utils/apiRoutes";
import moment from "moment";
import Loader from "../../../Loader/Loader";
import SingleDateSummaryModal from "../../SingleDateSummaryModal";
import SingleDateRMSModal from "../../SingleDateRMSModal";
import NoteIcon from "../../../../Assets/Images/CRM/notes.svg";
import downloadIcon from "../../../../Assets/Images/Operation/downloadIcon.svg";
import WidgetDetailModal from "../../WidgetDetailModal";
import { compName } from "../../../../utils/constant";
import WidgetNote from "../../WidgetNote";
import infoIcon from "../../../../Assets/Images/Action Icons/infoIcon..svg";
import { useSelector } from "react-redux";
import AddWidgetModal from "../../AddWidgetModal";
import { useParams } from "react-router-dom";
import emailIcon from "../../../../Assets/Images/Email.svg";
import Comments from "../Comments/Comment";
import { FixColumn } from "../../../../utils/dataTable";
import ticket from "../../../../Assets/Images/Revenue-management/tableTicket.svg";
import ToolsSingleTicketPopUp from "../../../CommonComponent/ToolsSingleTicketPopUp";
import EventViewModal from "../../../CommonComponent/EventViewModal";

const LengthOfStay = ({ api, para }) => {
  const date = commonService.convertDateInTimezone(new Date());
  const startDate = commonService.getDateInDBFormat(
    commonService.convertDateInTimezone(
      new Date(date.getFullYear(), date.getMonth(), 1)
    )
  );
  const endDate = commonService.getDateInDBFormat(
    commonService.convertDateInTimezone(
      new Date(date.getFullYear(), date.getMonth() + 1, 0)
    )
  );
  const [dbdData, setDbdData] = useState(null);
  const [otbData, setOtbData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [otbLoading, setOtbLoading] = useState(false);
  const [startRangeDate, setStartRangeDate] = useState();
  const [endRangeDate, setEndRangeDate] = useState();
  const [selectedType, setSelectedType] = useState({
    netStly: true,
    pickup: false,
  });
  const [selectPickupDate, setSelectPickupDate] = useState();
  const startRangeDateRef = useRef();
  const endRangeDateRef = useRef();
  const [maxDateForPickupFrom, setMaxDateForPickupFrom] = useState();
  const [ONTHEBOOKS, setONTHEBOOKS] = useState({
    IsDisplay: false,
    Target: null,
    Position: "top",
  });
  const [rms, setRms] = useState({
    IsDisplay: false,
    Target: null,
    Position: "top",
  });

  const rmsSingleDateRef = useRef();
  const rmsSelectedTdIndexRef = useRef();
  const rmsSelectedMarketSegmentRef = useRef();
  const dateRangeObj = useRef();
  const maxDateRef = useRef();
  const marketSegmentData = useRef();
  const selectedTdIndexRef = useRef();
  const singleDateSelectedDateRef = useRef();
  const singleDatePickupDateRef = useRef();
  const [widgetModal, setWidgetModal] = useState(false);
  const widgetDetailRef = useRef();
  const [showNoteSidebar, setShowNoteSidebar] = useState(false);
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const eventListRef = useRef();
  const eventListPropsRef = useRef();
  const selectedEventDateRef = useRef();
  const [showTicketModal, setShowTicketModal] = useState(false);
  const propertyDetailFromRTK = useSelector(
    (store) => store.headerProperty.propertyDetail
  );
  const defaultAsOfDateFromRTK = useSelector(
    (store) => store.headerProperty.defaultAsOfDate
  );
  const { snapshottitle, propertyCode, snapshotId } = useParams();
  const closeNoteSideBar = () => {
    setShowNoteSidebar(false);
  };

  const closeWidgetModal = () => {
    setWidgetModal(false);
  };

  const closeTicketModal = () => {
    setShowTicketModal(false);
  };

  let ooo = 0,
    RmsAvailable = 0,
    leftToSell = 0,
    otb = 0,
    occPercentage = 0,
    adr = 0,
    rev = 0,
    revPar = 0,
    LOS1_RMS = 0,
    LOS1_REV = 0,
    LOS1_LYREV = 0,
    LOS1_LYRMS = 0,
    LOS1_ADR = 0,
    LOS1_LYADR = 0,
    LOS2_RMS = 0,
    LOS2_REV = 0,
    LOS2_LYREV = 0,
    LOS2_LYRMS = 0,
    LOS2_ADR = 0,
    LOS2_LYADR = 0,
    LOS3_RMS = 0,
    LOS3_REV = 0,
    LOS3_LYREV = 0,
    LOS3_LYRMS = 0,
    LOS3_ADR = 0,
    LOS3_LYADR = 0,
    LOS4_RMS = 0,
    LOS4_REV = 0,
    LOS4_LYREV = 0,
    LOS4_LYRMS = 0,
    LOS4_ADR = 0,
    LOS4_LYADR = 0,
    LOS5_RMS = 0,
    LOS5_REV = 0,
    LOS5_LYREV = 0,
    LOS5_LYRMS = 0,
    LOS5_ADR = 0,
    LOS5_LYADR = 0,
    LOS6_RMS = 0,
    LOS6_REV = 0,
    LOS6_LYREV = 0,
    LOS6_LYRMS = 0,
    LOS6_ADR = 0,
    LOS6_LYADR = 0,
    //PICKUP VARIABLE
    LOS1_PICKUP_REV = 0,
    LOS1_PICKUP_RMS = 0,
    LOS1_PICKUP_ADR = 0,
    LOS2_PICKUP_REV = 0,
    LOS2_PICKUP_RMS = 0,
    LOS2_PICKUP_ADR = 0,
    LOS3_PICKUP_REV = 0,
    LOS3_PICKUP_RMS = 0,
    LOS3_PICKUP_ADR = 0,
    LOS4_PICKUP_REV = 0,
    LOS4_PICKUP_RMS = 0,
    LOS4_PICKUP_ADR = 0,
    LOS5_PICKUP_REV = 0,
    LOS5_PICKUP_RMS = 0,
    LOS5_PICKUP_ADR = 0,
    LOS6_PICKUP_REV = 0,
    LOS6_PICKUP_RMS = 0,
    LOS6_PICKUP_ADR = 0;

  if (maxDateForPickupFrom) {
    let date = new Date(maxDateForPickupFrom);
    maxDateRef.current = date.setDate(date.getDate() - 1);
  }

  const handleFilterBtnClick = (event) => {
    let Position = "bottom";
    let Top = event.nativeEvent.layerY - 2;
    if (Top + 400 > window.innerHeight) {
      Position = "top";
    }
    setONTHEBOOKS({
      IsDisplay: true,
      Target: event.target,
      Position: Position,
    });
  };
  const closeSingleDate = () => {
    selectedTdIndexRef.current = -1;
    setONTHEBOOKS({
      IsDisplay: false,
      Target: null,
      Position: "bottom",
    });
  };

  const handleRmsClick = (event) => {
    let Position = "bottom";
    let Top = event.nativeEvent.layerY - 2;
    if (Top + 400 > window.innerHeight) {
      Position = "top";
    }
    setRms({
      IsDisplay: true,
      Target: event.target,
      Position: Position,
    });
  };
  const diffDayStartDate = new Date(
    commonService.getDateInFormat(
      startRangeDateRef.current ? startRangeDateRef.current : startDate
    )
  );
  const diffDayEndDate = new Date(
    commonService.getDateInFormat(
      endRangeDateRef.current ? endRangeDateRef.current : endDate
    )
  );

  const diffDayInTime = diffDayEndDate.getTime() - diffDayStartDate.getTime();
  const diffDays = diffDayInTime / (1000 * 3600 * 24);

  const getDbdStlyData = async () => {
    setLoading(true);
    let dbdStlyResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.SnapShot +
          API_URL.MARKET_SEGMENT.LENGTH_OF_STAY_STLY,
        body: {
          AsOfDate: para?.asOfDate,
          startDate: startRangeDateRef.current
            ? startRangeDateRef.current
            : startDate,
          endDate: endRangeDateRef.current ? endRangeDateRef.current : endDate,
          propertyCode: para?.propertyCode,
        },
      },
      false
    );

    let response = apiResponse(false, dbdStlyResponse);

    if (response?.isValidate) {
      setDbdData(response?.data?.data);
      setLoading(false);
    }

    if (!response?.isValidate) {
      setLoading(false);
    }
  };

  const getDbdPickupData = async () => {
    setLoading(true);
    let pickupDate = new Date(para?.asOfDate);
    pickupDate.setDate(pickupDate.getDate() - 1);
    let dbdPickupResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.SnapShot +
          API_URL.MARKET_SEGMENT.LENGTH_OF_STAY_PICKUP,
        body: {
          AsOfDate: para?.asOfDate,
          startDate: startRangeDateRef.current
            ? startRangeDateRef.current
            : startDate,
          endDate: endRangeDateRef.current ? endRangeDateRef.current : endDate,
          propertyCode: para?.propertyCode,
          PickUpDate: selectPickupDate
            ? moment(selectPickupDate).format("YYYY-MM-DD")
            : moment(pickupDate).format("YYYY-MM-DD"),
        },
      },
      false
    );

    let response = apiResponse(false, dbdPickupResponse);

    if (response?.isValidate) {
      setDbdData(response?.data?.data);
      setLoading(false);
    }

    if (!response?.isValidate) {
      setLoading(false);
    }
  };

  const getEventList = async () => {
    let eventList = await apiCall(
      {
        method: "POST",
        url: API_URL.BASE_API_URL.RevenueManagement + API_URL.EVENTS.GET_LIST,
        body: {
          pageNum: 1,
          pageSize: 10,
          sortColumn: "",
          sortDirection: "asc",
          searchValue: "",
          propertyid: parseInt(propertyDetailFromRTK?.propertyid),
          startdate: null,
          enddate: null,
          eventname: "",
          eventtypeterm: "",
          yearterm: ``,
        },
      },
      false
    );

    let eventListResponse = apiResponse(false, eventList);

    if (eventListResponse?.isValidate) {
      let eventListArr = [];

      eventListResponse?.data?.data?.forEach((item) => {
        let singleEventObj = {
          id: item?.eventid ? item?.eventid : 0,
          name: item?.eventname,
          detail: item?.eventdetails,
          startDate: item?.startdate,
          endDate: item?.enddate,
          eventJson: item?.eventjson,
          eventResourceId: item?.eventsourceid,
        };
        let eventJsonData = "";
        if (item?.eventjson) {
          var decodedString = decodeURIComponent(item?.eventjson);
          const jsonStringWithoutQuotes = decodedString.slice(1, -1);
          const jsonStringWithSingleQuotes = `${jsonStringWithoutQuotes}`;
          eventJsonData = JSON.parse(jsonStringWithSingleQuotes);
          let desc = eventJsonData?.description?.replaceAll("+", " ");
          singleEventObj = {
            ...singleEventObj,
            labels: eventJsonData?.labels ? eventJsonData?.labels : "",
            rank: eventJsonData?.rank ? eventJsonData?.rank : 0,
            local_rank: eventJsonData?.local_rank
              ? eventJsonData?.local_rank
              : 0,
            phq_attendance: eventJsonData?.phq_attendance
              ? eventJsonData?.phq_attendance
              : 0,
            geo_lat: eventJsonData?.geo_lat ? eventJsonData?.geo_lat : "",
            geo_long: eventJsonData?.geo_long ? eventJsonData?.geo_long : "",
            detail: desc,
          };
        }
        eventListArr.push(singleEventObj);
      });
      eventListRef.current = eventListArr;
    }

    if (!eventListResponse?.isValidate) {
      eventListRef.current = [];
    }
  };

  const getORGDetail = async () => {
    setOtbLoading(true);
    await getEventList();

    let orgDetailResponse = await apiCall(
      {
        method: "POST",
        url: API_URL.BASE_API_URL.SnapShot + API_URL.DAILY_SUMMARY.ORG,
        body: {
          AsOfDate: para?.asOfDate,
          startDate: startRangeDateRef.current
            ? startRangeDateRef.current
            : startDate,
          endDate: endRangeDateRef.current ? endRangeDateRef.current : endDate,
          propertyCode: para?.propertyCode,
        },
      },
      false
    );

    let response = apiResponse(false, orgDetailResponse);

    if (response?.isValidate) {
      setOtbData(response?.data?.data);
      setOtbLoading(false);
    }

    if (!response?.isValidate) {
      setOtbLoading(false);
    }
  };

  const handleDateChange = (event) => {
    const [start, end] = event;
    let startSelectedDate = "",
      endSelectedDate = "";
    if (start) {
      let date = new Date(start);
      let month =
        date.getMonth() + 1 > 9
          ? date.getMonth() + 1
          : `0${date.getMonth() + 1}`;
      let dateFormat =
        date?.getDate() > 9 ? date.getDate() : `0${date.getDate()}`;
      startSelectedDate = `${date.getFullYear()}-${month}-${dateFormat}`;
      startRangeDateRef.current = commonService.getDateForNode(
        commonService.convertDateInTimezone(startSelectedDate)
      );
    }
    if (end) {
      let date = new Date(end);
      let month =
        date.getMonth() + 1 > 9
          ? date.getMonth() + 1
          : `0${date.getMonth() + 1}`;
      let dateFormat =
        date?.getDate() > 9 ? date.getDate() : `0${date.getDate()}`;
      endSelectedDate = `${date.getFullYear()}-${month}-${dateFormat}`;
      endRangeDateRef.current = commonService.getDateForNode(
        commonService.convertDateInTimezone(endSelectedDate)
      );
    }

    setStartRangeDate(event[0]);
    setEndRangeDate(event[1]);
    if (event[0] !== null && event[1] !== null) {
      // setDbdData(null);
      // setOtbData(null);
      // dateRangeObj.current = commonService.datesBetweenTwoDate(
      //   startRangeDateRef.current ? startRangeDateRef.current : startDate,
      //   endRangeDateRef.current ? endRangeDateRef.current : endDate
      // );
      // if (selectedType?.netStly) {
      //   getDbdStlyData();
      // } else {
      //   getDbdPickupData();
      // }
      // getORGDetail();
    }
  };

  useEffect(() => {
    if (api === 0) {
      dateRangeObj.current = commonService.datesBetweenTwoDate(
        startRangeDateRef.current
          ? commonService.convertDateInTimezone(startRangeDateRef.current)
          : commonService.convertDateInTimezone(startDate),
        endRangeDateRef.current
          ? commonService.convertDateInTimezone(endRangeDateRef.current)
          : commonService.convertDateInTimezone(endDate)
      );
      let d = new Date(para?.asOfDate);
      d.setDate(d.getDate() - 1);
      setMaxDateForPickupFrom(new Date(para?.defaultAsOfDate));
      setOtbData([]);
      setDbdData([]);
    } else {
      if (para?.asOfDate) {
        dateRangeObj.current = commonService.datesBetweenTwoDate(
          startRangeDateRef.current
            ? commonService.convertDateInTimezone(startRangeDateRef.current)
            : commonService.convertDateInTimezone(startDate),
          endRangeDateRef.current
            ? commonService.convertDateInTimezone(endRangeDateRef.current)
            : commonService.convertDateInTimezone(endDate)
        );
        let d = new Date(para?.asOfDate);
        d.setDate(d.getDate() - 1);
        setMaxDateForPickupFrom(new Date(para?.defaultAsOfDate));
        getORGDetail();
        if (selectedType?.netStly) {
          getDbdStlyData();
        } else {
          getDbdPickupData();
        }
      }
    }
  }, [para?.asOfDate, selectedType, selectPickupDate]);

  const closeRmsModal = () => {
    rmsSelectedTdIndexRef.current = -1;
    setRms({ IsDisplay: false, Target: null, Position: "bottom" });
  };

  const [tblids, settblids] = useState({
    tblLengthOfStayMarketSegment: commonService.GenerateGUID(),
  });

  commonService.setBackColorPerByTDValue(
    "#638EC6",
    "Red",
    `#${tblids.tblLengthOfStayMarketSegment} .perbackground-otb`
  );
  commonService.setBackColorPerByTDValue(
    "Green",
    "Red",
    `#${tblids.tblLengthOfStayMarketSegment} .perbackground-adr`
  );
  FixColumn(tblids.tblLengthOfStayMarketSegment, [1]);
  return (
    <div className="widget-container">
      {api !== 0 && (
        <div className="gap-1 widget-container-menu">
          {/* <Button className="heading-menu-btns" type="button">
            <img
              src={NoteIcon}
              alt="menuIcon"
              onClick={() => {
                setShowNoteSidebar(true);
              }}
            />
          </Button> */}
          <Button className="heading-menu-btns" type="button" title="Send Mail">
            <img
              src={emailIcon}
              alt="emailIcon"
              onClick={() => {
                commonService.fnSendWidgetMail(
                  tblids.tblLengthOfStayMarketSegment,
                  "Length Of Stay Market Segment",
                  snapshotId,
                  defaultAsOfDateFromRTK,
                  "",
                  para?.widgetId
                );
              }}
            />
          </Button>
          <Button className="heading-menu-btns" type="button">
            <img
              src={infoIcon}
              alt="info"
              onClick={() => {
                widgetDetailRef.current = {
                  widgetdetails: "",
                  usernote: "",
                  compName: compName?.lengthOfStay,
                  widgetId: para?.widgetId,
                };
                setWidgetModal(true);
              }}
            />
          </Button>
          <Button className="heading-menu-btns" type="button">
            <img
              src={downloadIcon}
              alt="menuIcon"
              import={tblids.tblLengthOfStayMarketSegment}
              onClick={() =>
                commonService.fnExportInCSV(
                  tblids.tblLengthOfStayMarketSegment,
                  // "Length Of Stay Market Segment"
                  `${para?.propertyCode}-Length Of Stay Market Segment-${moment(
                    new Date()
                  ).format("MMDDYYYY")}`
                )
              }
            />
          </Button>
        </div>
      )}
      <div className="ViewPropertySetupTable">
        <div className="stripped__table">
          <div className="table-responsive tblDynamicPickupSticky">
            <Table id={tblids.tblLengthOfStayMarketSegment}>
              <thead className="tbl-header-sticky">
                <tr className="table-main-header-tr">
                  <th>Date</th>
                  <th className="text-center border-right" colSpan={5}>
                    On The Books
                  </th>
                  <th className="text-center border-right" colSpan={2}>
                    Revenue
                  </th>
                  <th className="text-center border-right" colSpan={24}>
                    <div className="form-groups form-groups-radio-btn d-flex justify-content-center">
                      <div className="d-flex heatmap-main align-item-center">
                        <div className="form-radio d-flex align-items-center">
                          <input
                            type="radio"
                            className="form-check-input"
                            name="radioGroup"
                            id="Option2"
                            value="Inactive"
                            checked={selectedType?.netStly}
                            onChange={() => {
                              marketSegmentData.current = [];
                              setSelectedType({
                                pickup: false,
                                netStly: true,
                              });
                            }}
                          />
                          <label
                            htmlFor="Option2"
                            className="label-radio form-check-label m-0"
                          >
                            Net Stly
                          </label>
                        </div>

                        <div className="d-flex align-items-center gap-3">
                          <div className="form-radio d-flex align-items-center">
                            <input
                              type="radio"
                              className="form-check-input"
                              name="radioGroup"
                              id="Option1"
                              value="Active"
                              checked={selectedType?.isPickupFrom}
                              onChange={() => {
                                marketSegmentData.current = [];
                                setSelectedType({
                                  isPickupFrom: true,
                                  isOnTheBook: false,
                                });
                              }}
                            />
                            <label
                              htmlFor="Option1"
                              className="label-radio form-check-label m-0"
                            >
                              Pickup From
                            </label>
                          </div>
                          <div>
                            <DatePicker
                              id="asOfDate"
                              disabled={
                                selectedType?.isPickupFrom ? false : true
                              }
                              maxDate={new Date(maxDateRef.current)}
                              selected={
                                selectPickupDate
                                  ? commonService.convertDateInTimezone(
                                      selectPickupDate
                                    )
                                  : commonService.convertDateInTimezone(
                                      maxDateRef.current
                                    )
                              }
                              onChange={(date) => {
                                let month =
                                  date.getMonth() + 1 > 9
                                    ? date.getMonth() + 1
                                    : `0${date.getMonth() + 1}`;
                                let dateFormat =
                                  date?.getDate() > 9
                                    ? date.getDate()
                                    : `0${date.getDate()}`;
                                let selectedDate = `${date.getFullYear()}-${month}-${dateFormat}`;
                                setSelectPickupDate(selectedDate);
                              }}
                            />
                            <span style={{ display: "none" }}>
                              {selectedType?.isPickupFrom && selectPickupDate
                                ? commonService.getDateInFormat(
                                    new Date(selectPickupDate)
                                  )
                                : commonService.getDateInFormat(
                                    new Date(maxDateRef.current)
                                  )}
                            </span>
                          </div>
                        </div>
                        <span style={{ display: "none" }}>
                          {selectedType?.isPickupFrom
                            ? "PickupFrom"
                            : "On The Book"}
                        </span>
                      </div>
                    </div>
                  </th>
                </tr>
                <tr className="table-main-header-tr">
                  <th className="border-right"></th>
                  <th className="border-right" colSpan={5}></th>
                  <th className="border-right" colSpan={2}></th>
                  <th className="width200 text-center border-right" colSpan={4}>
                    1 Night Stay
                  </th>
                  <th className="width200 text-center border-right" colSpan={4}>
                    2 Night Stay
                  </th>
                  <th className="width200 text-center border-right" colSpan={4}>
                    3-6 Night Stay
                  </th>
                  <th className="width200 text-center border-right" colSpan={4}>
                    7-14 Night Stay
                  </th>
                  <th className="width200 text-center border-right" colSpan={4}>
                    15-29 Night Stay
                  </th>
                  <th className="width200 text-center border-right" colSpan={4}>
                    30+ Night Stay
                  </th>
                </tr>
                <tr className="table-main-header-tr">
                  <th
                    className="width200"
                    onClick={() => {
                      setOpenDatePicker(true);
                    }}
                  >
                    Date{" "}
                    <DatePicker
                      onChange={(event) => handleDateChange(event)}
                      startDate={startRangeDate}
                      endDate={endRangeDate}
                      selectsRange
                      monthsShown={2}
                      open={openDatePicker}
                      shouldCloseOnSelect={false}
                    >
                      <div
                        className="w-100"
                        style={{ display: "inline-block" }}
                      >
                        <div className="content-inside-calendar">
                          <p>
                            {startRangeDate
                              ? commonService.getDateInFormat(startRangeDate)
                              : ""}
                            {startRangeDate && endRangeDate ? " - " : ""}
                            {endRangeDate
                              ? commonService.getDateInFormat(endRangeDate)
                              : ""}
                          </p>
                          <div className="d-flex align-items-center gap-2">
                            <button
                              className="secondary-btn"
                              onClick={(e) => {
                                e.stopPropagation();
                                setStartRangeDate("");
                                setEndRangeDate("");
                                setOpenDatePicker(false);
                              }}
                            >
                              Cancel
                            </button>
                            <button
                              disabled={
                                startRangeDate && endRangeDate ? false : true
                              }
                              onClick={(e) => {
                                e.stopPropagation();
                                if (
                                  startRangeDateRef.current &&
                                  endRangeDateRef.current
                                ) {
                                  setDbdData(null);
                                  setOtbData(null);
                                  dateRangeObj.current =
                                    commonService.datesBetweenTwoDate(
                                      startRangeDateRef.current
                                        ? commonService.convertDateInTimezone(
                                            startRangeDateRef.current
                                          )
                                        : commonService.convertDateInTimezone(
                                            startDate
                                          ),
                                      endRangeDateRef.current
                                        ? commonService.convertDateInTimezone(
                                            endRangeDateRef.current
                                          )
                                        : commonService.convertDateInTimezone(
                                            endDate
                                          )
                                    );
                                  if (selectedType?.netStly) {
                                    getDbdStlyData();
                                  } else {
                                    getDbdPickupData();
                                  }
                                  getORGDetail();
                                }

                                setOpenDatePicker(false);
                              }}
                              className="primary-btn"
                            >
                              Apply
                            </button>
                          </div>
                        </div>
                      </div>
                    </DatePicker>
                    {startRangeDate && endRangeDate ? (
                      <span
                        style={{ display: "none" }}
                      >{`${commonService.getDateInFormat(
                        startRangeDate
                      )} - ${commonService.getDateInFormat(
                        endRangeDate
                      )}`}</span>
                    ) : (
                      ""
                    )}
                  </th>
                  <th className="width100 border-right">OOO/Rooms Available</th>
                  <th className="width100 text-center">Left To sell</th>
                  <th className="width100 text-center">On The Books</th>
                  <th>Total OCC%</th>
                  <th className="text-right">ADR</th>
                  <th className="border-right text-right">Rev</th>
                  <th>RevPAr</th>
                  <th className="border-right text-center">RMS</th>
                  <th className="text-center">
                    {selectedType.netStly ? "Stly" : "pickup"}
                  </th>
                  <th className="text-center text-end">ADR</th>
                  <th className="text-end">
                    {selectedType.netStly ? "Stly" : "pickup"}
                  </th>
                  <th className="border-right text-center">RMS</th>
                  <th className="text-center">
                    {selectedType.netStly ? "Stly" : "pickup"}
                  </th>
                  <th className="text-center">ADR</th>
                  <th className="text-end">
                    {selectedType.netStly ? "Stly" : "pickup"}
                  </th>
                  <th className="border-right text-center">RMS</th>
                  <th className="text-center">
                    {selectedType.netStly ? "Stly" : "pickup"}
                  </th>
                  <th className="text-center">ADR</th>
                  <th className="text-end">
                    {selectedType.netStly ? "Stly" : "pickup"}
                  </th>
                  <th className="border-right text-center">RMS</th>
                  <th className="text-center">
                    {selectedType.netStly ? "Stly" : "pickup"}
                  </th>
                  <th className="text-center">ADR</th>
                  <th className="text-end">
                    {selectedType.netStly ? "Stly" : "pickup"}
                  </th>
                  <th className="border-right text-center">RMS</th>
                  <th className="text-center">
                    {" "}
                    {selectedType.netStly ? "Stly" : "pickup"}
                  </th>
                  <th className="text-center">ADR</th>
                  <th className="text-end">
                    {selectedType.netStly ? "Stly" : "pickup"}
                  </th>
                  <th className="border-right text-center">RMS</th>
                  <th className="text-center">
                    {selectedType.netStly ? "Stly" : "pickup"}
                  </th>
                  <th className="text-center">ADR</th>
                  <th className="text-end">
                    {selectedType.netStly ? "Stly" : "pickup"}
                  </th>
                </tr>
              </thead>
              <tbody>
                {loading === true || otbLoading === true ? (
                  <tr style={{ height: "80px", position: "relative" }}>
                    <Loader />
                  </tr>
                ) : (
                  // otbData &&
                  // dbdData &&
                  dateRangeObj.current &&
                  Object.keys(dateRangeObj.current)?.map((item, index) => {
                    let singleOTBData = otbData?.filter(
                      (x) => x?.Date === item
                    );
                    let dbdDataStore = dbdData.filter(
                      (x) => x.staydate === item
                    );
                    let dayName = false;

                    ooo = ooo + singleOTBData?.[0]?.OOO;
                    RmsAvailable =
                      RmsAvailable + singleOTBData?.[0]?.RoomAvailable;
                    leftToSell = leftToSell + singleOTBData?.[0]?.LeftToSell;
                    otb = otb + singleOTBData?.[0]?.OnTheBook;
                    occPercentage =
                      occPercentage + singleOTBData?.[0]?.TotalOCCPercentage;
                    adr = adr + singleOTBData?.[0]?.ADR;
                    rev = rev + singleOTBData?.[0]?.REV;

                    revPar = revPar + singleOTBData?.[0]?.RevPAR;

                    LOS1_RMS = LOS1_RMS + dbdDataStore?.[0]?.LOS1_RMS;
                    LOS1_LYRMS = LOS1_LYRMS + dbdDataStore?.[0]?.LOS1_LYRMS;
                    LOS1_ADR = LOS1_ADR + dbdDataStore?.[0]?.LOS1_ADR;
                    LOS1_LYADR = LOS1_LYADR + dbdDataStore?.[0]?.LOS1_LYADR;
                    LOS1_REV = LOS1_REV + dbdDataStore?.[0]?.LOS1_REV;
                    LOS1_LYREV = LOS1_LYREV + dbdDataStore?.[0]?.LOS1_LYREV;
                    LOS2_RMS = LOS2_RMS + dbdDataStore?.[0]?.LOS2_RMS;
                    LOS2_LYRMS = LOS2_LYRMS + dbdDataStore?.[0]?.LOS2_LYRMS;
                    LOS2_ADR = LOS2_ADR + dbdDataStore?.[0]?.LOS2_ADR;
                    LOS2_LYADR = LOS2_LYADR + dbdDataStore?.[0]?.LOS2_LYADR;
                    LOS2_REV = LOS2_REV + dbdDataStore?.[0]?.LOS2_REV;
                    LOS2_LYREV = LOS2_LYREV + dbdDataStore?.[0]?.LOS2_LYREV;
                    LOS3_RMS = LOS3_RMS + dbdDataStore?.[0]?.LOS3_RMS;
                    LOS3_LYRMS = LOS3_LYRMS + dbdDataStore?.[0]?.LOS3_LYRMS;
                    LOS3_ADR = LOS3_ADR + dbdDataStore?.[0]?.LOS3_ADR;
                    LOS3_LYADR = LOS3_LYADR + dbdDataStore?.[0]?.LOS3_LYADR;
                    LOS3_REV = LOS3_REV + dbdDataStore?.[0]?.LOS3_REV;
                    LOS3_LYREV = LOS3_LYREV + dbdDataStore?.[0]?.LOS3_LYREV;
                    LOS4_RMS = LOS4_RMS + dbdDataStore?.[0]?.LOS4_RMS;
                    LOS4_LYRMS = LOS4_LYRMS + dbdDataStore?.[0]?.LOS4_LYRMS;
                    LOS4_ADR = LOS4_ADR + dbdDataStore?.[0]?.LOS4_ADR;
                    LOS4_LYADR = LOS4_LYADR + dbdDataStore?.[0]?.LOS4_LYADR;
                    LOS4_REV = LOS4_REV + dbdDataStore?.[0]?.LOS4_REV;
                    LOS4_LYREV = LOS4_LYREV + dbdDataStore?.[0]?.LOS4_LYREV;
                    LOS5_RMS = LOS5_RMS + dbdDataStore?.[0]?.LOS5_RMS;
                    LOS5_LYRMS = LOS5_LYRMS + dbdDataStore?.[0]?.LOS5_LYRMS;
                    LOS5_ADR = LOS5_ADR + dbdDataStore?.[0]?.LOS5_ADR;
                    LOS5_LYADR = LOS5_LYADR + dbdDataStore?.[0]?.LOS5_LYADR;
                    LOS5_REV = LOS5_REV + dbdDataStore?.[0]?.LOS5_REV;
                    LOS5_LYREV = LOS5_LYREV + dbdDataStore?.[0]?.LOS5_LYREV;
                    LOS6_RMS = LOS6_RMS + dbdDataStore?.[0]?.LOS6_RMS;
                    LOS6_LYRMS = LOS6_LYRMS + dbdDataStore?.[0]?.LOS6_LYRMS;
                    LOS6_ADR = LOS6_ADR + dbdDataStore?.[0]?.LOS6_ADR;
                    LOS6_LYADR = LOS6_LYADR + dbdDataStore?.[0]?.LOS6_LYADR;
                    LOS6_REV = LOS6_REV + dbdDataStore?.[0]?.LOS6_REV;
                    LOS6_LYREV = LOS6_LYREV + dbdDataStore?.[0]?.LOS6_LYREV;
                    //PICKUP  VARIABLE
                    LOS1_PICKUP_REV =
                      LOS1_PICKUP_REV + dbdDataStore?.[0]?.LOS1_PICKUP_REV;
                    LOS1_PICKUP_RMS =
                      LOS1_PICKUP_RMS + dbdDataStore?.[0]?.LOS1_PICKUP_RMS;
                    LOS1_PICKUP_ADR =
                      LOS1_PICKUP_ADR + dbdDataStore?.[0]?.LOS1_PICKUP_ADR;
                    LOS2_PICKUP_REV =
                      LOS2_PICKUP_REV + dbdDataStore?.[0]?.LOS2_PICKUP_REV;
                    LOS2_PICKUP_RMS =
                      LOS2_PICKUP_RMS + dbdDataStore?.[0]?.LOS2_PICKUP_RMS;
                    LOS2_PICKUP_ADR =
                      LOS2_PICKUP_ADR + dbdDataStore?.[0]?.LOS2_PICKUP_ADR;
                    LOS3_PICKUP_REV =
                      LOS3_PICKUP_REV + dbdDataStore?.[0]?.LOS3_PICKUP_REV;
                    LOS3_PICKUP_RMS =
                      LOS3_PICKUP_RMS + dbdDataStore?.[0]?.LOS3_PICKUP_RMS;
                    LOS3_PICKUP_ADR =
                      LOS3_PICKUP_ADR + dbdDataStore?.[0]?.LOS3_PICKUP_ADR;
                    LOS4_PICKUP_REV =
                      LOS4_PICKUP_REV + dbdDataStore?.[0]?.LOS4_PICKUP_REV;
                    LOS4_PICKUP_RMS =
                      LOS4_PICKUP_RMS + dbdDataStore?.[0]?.LOS4_PICKUP_RMS;
                    LOS4_PICKUP_ADR =
                      LOS4_PICKUP_ADR + dbdDataStore?.[0]?.LOS4_PICKUP_ADR;
                    LOS5_PICKUP_REV =
                      LOS5_PICKUP_REV + dbdDataStore?.[0]?.LOS5_PICKUP_REV;
                    LOS5_PICKUP_RMS =
                      LOS5_PICKUP_RMS + dbdDataStore?.[0]?.LOS5_PICKUP_RMS;
                    LOS5_PICKUP_ADR =
                      LOS5_PICKUP_ADR + dbdDataStore?.[0]?.LOS5_PICKUP_ADR;
                    LOS6_PICKUP_REV =
                      LOS6_PICKUP_REV + dbdDataStore?.[0]?.LOS6_PICKUP_REV;
                    LOS6_PICKUP_RMS =
                      LOS6_PICKUP_RMS + dbdDataStore?.[0]?.LOS6_PICKUP_RMS;
                    LOS6_PICKUP_ADR =
                      LOS6_PICKUP_ADR + dbdDataStore?.[0]?.LOS6_PICKUP_ADR;

                    if (
                      moment(
                        commonService.convertDateInTimezone(item),
                        "l"
                      ).format("ddd") === "Fri" ||
                      moment(
                        commonService.convertDateInTimezone(item),
                        "l"
                      ).format("ddd") === "Sat"
                    ) {
                      dayName = true;
                    }

                    let checkEvent = eventListRef.current?.filter((x) => {
                      let checkingDate = moment(item);
                      let startingDate = moment(x?.startDate);
                      let endingDate = moment(x?.endDate);
                      if (
                        checkingDate.isSameOrAfter(startingDate) &&
                        checkingDate.isSameOrBefore(endingDate)
                      ) {
                        return x;
                      }
                    });

                    return (
                      <React.Fragment key={item}>
                        <tr
                          key={item}
                          className={`${
                            dayName ? "backgroundTr" : ""
                          } shadowOnHover`}
                        >
                          <td>
                            <div className="d-flex gap-4">
                              {commonService.getDateInFormat(
                                commonService.convertDateInTimezone(item)
                              )}{" "}
                              {moment(
                                commonService.convertDateInTimezone(item),
                                "l"
                              ).format("ddd")}
                              <div className="d-flex justify-content-between align-items-center gap-2 ticketAlarmReload">
                                {checkEvent?.length > 0 && (
                                  <img
                                    style={{ height: "11px", width: "11px" }}
                                    className="iconcolor"
                                    src={ticket}
                                    alt="ticket"
                                    onClick={() => {
                                      selectedEventDateRef.current = item;
                                      eventListPropsRef.current = checkEvent;
                                      setShowTicketModal(true);
                                    }}
                                  />
                                )}
                              </div>
                            </div>
                          </td>
                          <td className="width100 border-right">
                            {singleOTBData?.[0]?.OOO
                              ? commonService.formateRoom(singleOTBData?.[0]?.OOO)
                              : 0}
                            /
                            {singleOTBData?.[0]?.RoomAvailable
                              ? commonService.formateRoom(singleOTBData?.[0]?.RoomAvailable)
                              : 0}
                          </td>
                          <td className="text-center">
                            {singleOTBData?.[0]?.LeftToSell
                              ? commonService.formateRoom(singleOTBData?.[0]?.LeftToSell)
                              : 0}
                          </td>
                          <td
                            className="text-primary text-center perbackground perbackground-otb"
                            value={singleOTBData?.[0]?.OnTheBook}
                          >
                            <span
                              className={`${
                                selectedTdIndexRef.current === index
                                  ? "singleDateSummarySpan"
                                  : ""
                              }`}
                              onClick={(e) => {
                                if (api !== 0) {
                                  singleDateSelectedDateRef.current = item;
                                  handleFilterBtnClick(e);
                                  selectedTdIndexRef.current = index;
                                }
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              {singleOTBData?.[0]?.OnTheBook
                                ? commonService.formateRoom(singleOTBData?.[0]?.OnTheBook)
                                : 0}
                            </span>
                          </td>
                          <td>
                            {singleOTBData?.[0]?.TotalOCCPercentage
                              ? `${singleOTBData?.[0]?.TotalOCCPercentage}%`
                              : `0%`}
                          </td>
                          <td
                            className="text-end perbackground perbackground-adr"
                            value={singleOTBData?.[0]?.ADR}
                          >
                            {singleOTBData?.[0]?.ADR
                              ? `${commonService.formateAmount(
                                  singleOTBData?.[0]?.ADR
                                )}`
                              : `${commonService.formateAmount(0)}`}
                          </td>
                          <td className="text-start border-right">
                            {singleOTBData?.[0]?.REV
                              ? `${commonService.formateAmount(
                                  singleOTBData?.[0]?.REV
                                )}`
                              : `${commonService.formateAmount(0)}`}
                          </td>
                          <td className="text-end">
                            {singleOTBData?.[0]?.RevPAR
                              ? `${commonService.formateAmount(
                                  singleOTBData?.[0]?.RevPAR
                                )}`
                              : `${commonService.formateAmount(0)}`}
                          </td>
                          {/* Second API start */}
                          <td className="text-center border-start">
                            {selectedType?.netStly
                              ? dbdDataStore?.[0]?.LOS1_RMS
                                ? commonService.formateRoom(dbdDataStore?.[0]?.LOS1_RMS)
                                : 0
                              : dbdDataStore?.[0]?.LOS1_RMS
                              ? commonService.formateRoom(dbdDataStore?.[0]?.LOS1_RMS)
                              : 0}
                          </td>
                          <td className="text-center">
                            {selectedType?.netStly
                              ? dbdDataStore?.[0]?.LOS1_LYRMS
                                ? commonService.formateRoom(dbdDataStore?.[0]?.LOS1_LYRMS)
                                : 0
                              : dbdDataStore?.[0]?.LOS1_PICKUP_RMS
                              ? commonService.formateRoom(dbdDataStore?.[0]?.LOS1_PICKUP_RMS)
                              : 0}
                          </td>
                          <td className="text-center">
                            {selectedType?.netStly
                              ? dbdDataStore?.[0]?.LOS1_ADR
                                ? `${commonService.formateAmount(
                                    dbdDataStore?.[0]?.LOS1_ADR
                                  )}`
                                : `${commonService.formateAmount(0)}`
                              : `${commonService.formateAmount(
                                  dbdDataStore?.[0]?.LOS1_ADR
                                )}`
                              ? `${commonService.formateAmount(
                                  dbdDataStore?.[0]?.LOS1_ADR
                                )}`
                              : `${commonService.formateAmount(0)}`}
                          </td>
                          <td className="text-end">
                            {selectedType?.netStly
                              ? dbdDataStore?.[0]?.LOS1_LYADR
                                ? `${commonService.formateAmount(
                                    dbdDataStore?.[0]?.LOS1_LYADR
                                  )}`
                                : `${commonService.formateAmount(0)}`
                              : `${commonService.formateAmount(
                                  dbdDataStore?.[0]?.LOS1_PICKUP_ADR
                                )}`
                              ? `${commonService.formateAmount(
                                  dbdDataStore?.[0]?.LOS1_PICKUP_ADR
                                )}`
                              : `${commonService.formateAmount(0)}`}
                          </td>

                          <td className="text-center border-start">
                            {selectedType?.netStly
                              ? dbdDataStore?.[0]?.LOS2_RMS
                                ? commonService.formateRoom(dbdDataStore?.[0]?.LOS2_RMS)
                                : 0
                              : dbdDataStore?.[0]?.LOS2_RMS
                              ? commonService.formateRoom(dbdDataStore?.[0]?.LOS2_RMS)
                              : 0}
                          </td>
                          <td className="text-center">
                            {selectedType?.netStly
                              ? dbdDataStore?.[0]?.LOS2_LYRMS
                                ? commonService.formateRoom(dbdDataStore?.[0]?.LOS2_LYRMS)
                                : 0
                              : dbdDataStore?.[0]?.LOS2_PICKUP_RMS
                              ? commonService.formateRoom(dbdDataStore?.[0]?.LOS2_PICKUP_RMS)
                              : 0}
                          </td>
                          <td className="text-center">
                            {selectedType?.netStly
                              ? dbdDataStore?.[0]?.LOS2_ADR
                                ? `${commonService.formateAmount(
                                    dbdDataStore?.[0]?.LOS2_ADR
                                  )}`
                                : `${commonService.formateAmount(0)}`
                              : `${commonService.formateAmount(
                                  dbdDataStore?.[0]?.LOS2_ADR
                                )}`
                              ? `${commonService.formateAmount(
                                  dbdDataStore?.[0]?.LOS2_ADR
                                )}`
                              : `${commonService.formateAmount(0)}`}
                          </td>
                          <td className="text-end">
                            {selectedType?.netStly
                              ? dbdDataStore?.[0]?.LOS2_LYADR
                                ? `${commonService.formateAmount(
                                    dbdDataStore?.[0]?.LOS2_LYADR
                                  )}`
                                : `${commonService.formateAmount(0)}`
                              : `${commonService.formateAmount(
                                  dbdDataStore?.[0]?.LOS2_PICKUP_ADR
                                )}`
                              ? `${commonService.formateAmount(
                                  dbdDataStore?.[0]?.LOS2_PICKUP_ADR
                                )}`
                              : `${commonService.formateAmount(0)}`}
                          </td>

                          <td className="text-center border-start">
                            {selectedType?.netStly
                              ? dbdDataStore?.[0]?.LOS3_RMS
                                ? commonService.formateRoom(dbdDataStore?.[0]?.LOS3_RMS)
                                : 0
                              : dbdDataStore?.[0]?.LOS3_RMS
                              ? commonService.formateRoom(dbdDataStore?.[0]?.LOS3_RMS)
                              : 0}
                          </td>
                          <td className="text-center">
                            {selectedType?.netStly
                              ? dbdDataStore?.[0]?.LOS3_LYRMS
                                ? commonService.formateRoom(dbdDataStore?.[0]?.LOS3_LYRMS)
                                : 0
                              : dbdDataStore?.[0]?.LOS3_PICKUP_RMS
                              ? commonService.formateRoom(dbdDataStore?.[0]?.LOS3_PICKUP_RMS)
                              : 0}
                          </td>
                          <td className="text-center">
                            {selectedType?.netStly
                              ? dbdDataStore?.[0]?.LOS3_ADR
                                ? `${commonService.formateAmount(
                                    dbdDataStore?.[0]?.LOS3_ADR
                                  )}`
                                : `${commonService.formateAmount(0)}`
                              : `${commonService.formateAmount(
                                  dbdDataStore?.[0]?.LOS3_ADR
                                )}`
                              ? `${commonService.formateAmount(
                                  dbdDataStore?.[0]?.LOS3_ADR
                                )}`
                              : `${commonService.formateAmount(0)}`}
                          </td>
                          <td className="text-end">
                            {selectedType?.netStly
                              ? dbdDataStore?.[0]?.LOS3_LYADR
                                ? `${commonService.formateAmount(
                                    dbdDataStore?.[0]?.LOS3_LYADR
                                  )}`
                                : `${commonService.formateAmount(0)}`
                              : `${commonService.formateAmount(
                                  dbdDataStore?.[0]?.LOS3_PICKUP_ADR
                                )}`
                              ? `${commonService.formateAmount(
                                  dbdDataStore?.[0]?.LOS3_PICKUP_ADR
                                )}`
                              : `${commonService.formateAmount(0)}`}
                          </td>

                          <td className="text-center border-start">
                            {selectedType?.netStly
                              ? dbdDataStore?.[0]?.LOS4_RMS
                                ? commonService.formateRoom(dbdDataStore?.[0]?.LOS4_RMS)
                                : 0
                              : dbdDataStore?.[0]?.LOS4_RMS
                              ? commonService.formateRoom(dbdDataStore?.[0]?.LOS4_RMS)
                              : 0}
                          </td>
                          <td className="text-center">
                            {selectedType?.netStly
                              ? dbdDataStore?.[0]?.LOS4_LYRMS
                                ? commonService.formateRoom(dbdDataStore?.[0]?.LOS4_LYRMS)
                                : 0
                              : dbdDataStore?.[0]?.LOS4_PICKUP_RMS
                              ? commonService.formateRoom(dbdDataStore?.[0]?.LOS4_PICKUP_RMS)
                              : 0}
                          </td>
                          <td className="text-center">
                            {selectedType?.netStly
                              ? dbdDataStore?.[0]?.LOS4_ADR
                                ? `${commonService.formateAmount(
                                    dbdDataStore?.[0]?.LOS4_ADR
                                  )}`
                                : `${commonService.formateAmount(0)}`
                              : `${commonService.formateAmount(
                                  dbdDataStore?.[0]?.LOS4_ADR
                                )}`
                              ? `${commonService.formateAmount(
                                  dbdDataStore?.[0]?.LOS4_ADR
                                )}`
                              : `${commonService.formateAmount(0)}`}
                          </td>
                          <td className="text-end">
                            {selectedType?.netStly
                              ? dbdDataStore?.[0]?.LOS4_LYADR
                                ? `${commonService.formateAmount(
                                    dbdDataStore?.[0]?.LOS4_LYADR
                                  )}`
                                : `${commonService.formateAmount(0)}`
                              : `${commonService.formateAmount(
                                  dbdDataStore?.[0]?.LOS4_PICKUP_ADR
                                )}`
                              ? `${commonService.formateAmount(
                                  dbdDataStore?.[0]?.LOS4_PICKUP_ADR
                                )}`
                              : `${commonService.formateAmount(0)}`}
                          </td>

                          <td className="text-center border-start">
                            {selectedType?.netStly
                              ? dbdDataStore?.[0]?.LOS5_RMS
                                ? commonService.formateRoom(dbdDataStore?.[0]?.LOS5_RMS)
                                : 0
                              : dbdDataStore?.[0]?.LOS5_RMS
                              ? commonService.formateRoom(dbdDataStore?.[0]?.LOS5_RMS)
                              : 0}
                          </td>
                          <td className="text-center">
                            {selectedType?.netStly
                              ? dbdDataStore?.[0]?.LOS5_LYRMS
                                ? commonService.formateRoom(dbdDataStore?.[0]?.LOS5_LYRMS)
                                : 0
                              : dbdDataStore?.[0]?.LOS5_PICKUP_RMS
                              ? commonService.formateRoom(dbdDataStore?.[0]?.LOS5_PICKUP_RMS)
                              : 0}
                          </td>
                          <td className="text-center">
                            {selectedType?.netStly
                              ? dbdDataStore?.[0]?.LOS5_ADR
                                ? `${commonService.formateAmount(
                                    dbdDataStore?.[0]?.LOS5_ADR
                                  )}`
                                : `${commonService.formateAmount(0)}`
                              : `${commonService.formateAmount(
                                  dbdDataStore?.[0]?.LOS5_ADR
                                )}`
                              ? `${commonService.formateAmount(
                                  dbdDataStore?.[0]?.LOS5_ADR
                                )}`
                              : `${commonService.formateAmount(0)}`}
                          </td>
                          <td className="text-end">
                            {selectedType?.netStly
                              ? dbdDataStore?.[0]?.LOS5_LYADR
                                ? `${commonService.formateAmount(
                                    dbdDataStore?.[0]?.LOS5_LYADR
                                  )}`
                                : `${commonService.formateAmount(0)}`
                              : `${commonService.formateAmount(
                                  dbdDataStore?.[0]?.LOS5_PICKUP_ADR
                                )}`
                              ? `${commonService.formateAmount(
                                  dbdDataStore?.[0]?.LOS5_PICKUP_ADR
                                )}`
                              : `${commonService.formateAmount(0)}`}
                          </td>

                          <td className="text-center border-start">
                            {selectedType?.netStly
                              ? dbdDataStore?.[0]?.LOS6_RMS
                                ? commonService.formateRoom(dbdDataStore?.[0]?.LOS6_RMS)
                                : 0
                              : dbdDataStore?.[0]?.LOS6_RMS
                              ? commonService.formateRoom(dbdDataStore?.[0]?.LOS6_RMS)
                              : 0}
                          </td>
                          <td className="text-center">
                            {selectedType?.netStly
                              ? dbdDataStore?.[0]?.LOS6_LYRMS
                                ? commonService.formateRoom(dbdDataStore?.[0]?.LOS6_LYRMS)
                                : 0
                              : dbdDataStore?.[0]?.LOS6_PICKUP_RMS
                              ? commonService.formateRoom(dbdDataStore?.[0]?.LOS6_PICKUP_RMS)
                              : 0}
                          </td>
                          <td className="text-center">
                            {selectedType?.netStly
                              ? dbdDataStore?.[0]?.LOS6_ADR
                                ? `${commonService.formateAmount(
                                    dbdDataStore?.[0]?.LOS6_ADR
                                  )}`
                                : `${commonService.formateAmount(0)}`
                              : `${commonService.formateAmount(
                                  dbdDataStore?.[0]?.LOS6_ADR
                                )}`
                              ? `${commonService.formateAmount(
                                  dbdDataStore?.[0]?.LOS6_ADR
                                )}`
                              : `${commonService.formateAmount(0)}`}
                          </td>
                          <td className="text-end">
                            {selectedType?.netStly
                              ? dbdDataStore?.[0]?.LOS6_LYADR
                                ? `${commonService.formateAmount(
                                    dbdDataStore?.[0]?.LOS6_LYADR
                                  )}`
                                : `${commonService.formateAmount(0)}`
                              : `${commonService.formateAmount(
                                  dbdDataStore?.[0]?.LOS6_PICKUP_ADR
                                )}`
                              ? `${commonService.formateAmount(
                                  dbdDataStore?.[0]?.LOS6_PICKUP_ADR
                                )}`
                              : `${commonService.formateAmount(0)}`}
                          </td>
                        </tr>
                        {index ===
                          Object.keys(dateRangeObj.current)?.length - 1 && (
                          <tr className="shadowOnHover">
                            <td className="boldTd">Total</td>
                            <td className="boldTd border-right ">
                              {commonService.formateRoom(ooo)}/{commonService.formateRoom(RmsAvailable)}
                            </td>
                            <td className="boldTd text-center">{commonService.formateRoom(leftToSell)}</td>
                            <td className="boldTd text-center">{commonService.formateRoom(otb)}</td>
                            <td className="boldTd">
                              {occPercentage > 0
                                ? `${parseInt(occPercentage / diffDays)}%`
                                : `0%`}
                            </td>
                            <td className="boldTd text-end">
                              {rev > 0 && otb > 0
                                ? `${commonService.formateAmount(
                                    parseInt(rev / otb)
                                  )}`
                                : `${commonService.formateAmount(0)}`}
                            </td>
                            <td className="boldTd text-end border-right">
                              {commonService.formateAmount(rev)}
                            </td>
                            <td className="boldTd text-end">
                              {revPar > 0
                                ? `${commonService.formateAmount(
                                    Math.round(revPar / diffDays)
                                  )}`
                                : `${commonService.formateAmount(0)}`}
                            </td>
                            <td className="boldTd text-center">{commonService.formateRoom(LOS1_RMS)}</td>
                            <td className="boldTd text-center">
                              {selectedType?.netStly
                                ? commonService.formateRoom(LOS1_LYRMS)
                                : commonService.formateRoom(LOS1_PICKUP_RMS)}
                            </td>
                            <td className="boldTd text-center">
                              {selectedType?.netStly
                                ? LOS1_REV > 0 && LOS1_RMS > 0
                                  ? `${commonService.formateAmount(
                                      Math.round(LOS1_REV / LOS1_RMS)
                                    )}`
                                  : `${commonService.formateAmount(0)}`
                                : `${commonService.formateAmount(
                                    Math.round(LOS1_REV / LOS1_RMS)
                                  )}`
                                ? `${commonService.formateAmount(
                                    Math.round(LOS1_REV / LOS1_RMS)
                                  )}`
                                : `${commonService.formateAmount(0)}`}
                            </td>
                            <td className="boldTd text-end">
                              {selectedType?.netStly
                                ? LOS1_LYREV > 0 && LOS1_LYRMS > 0
                                  ? `${commonService.formateAmount(
                                      parseInt(LOS1_LYREV / LOS1_LYRMS)
                                    )}`
                                  : `${commonService.formateAmount(0)}`
                                : LOS1_PICKUP_REV > 0 && LOS1_PICKUP_RMS > 0
                                ? `${commonService.formateAmount(
                                    parseInt(LOS1_PICKUP_REV / LOS1_PICKUP_RMS)
                                  )}`
                                : `${commonService.formateAmount(0)}`}
                            </td>
                            <td className="boldTd text-center">{commonService.formateRoom(LOS2_RMS)}</td>
                            <td className="boldTd text-center">
                              {selectedType?.netStly
                                ? commonService.formateRoom(LOS2_LYRMS)
                                : commonService.formateRoom(LOS2_PICKUP_RMS)}
                            </td>
                            <td className="boldTd text-center">
                              {selectedType?.netStly
                                ? LOS2_REV > 0 && LOS2_RMS > 0
                                  ? `${commonService.formateAmount(
                                      parseInt(LOS2_REV / LOS2_RMS)
                                    )}`
                                  : `${commonService.formateAmount(0)}`
                                : `${commonService.formateAmount(
                                    parseInt(LOS2_REV / LOS2_RMS)
                                  )}`
                                ? `${commonService.formateAmount(
                                    parseInt(LOS2_REV / LOS2_RMS)
                                  )}`
                                : `${commonService.formateAmount(0)}`}
                            </td>
                            <td className="boldTd text-end">
                              {selectedType?.netStly
                                ? LOS2_LYREV > 0 && LOS2_LYRMS > 0
                                  ? `${commonService.formateAmount(
                                      parseInt(LOS2_LYREV / LOS2_LYRMS)
                                    )}`
                                  : `${commonService.formateAmount(0)}`
                                : LOS2_PICKUP_REV > 0 && LOS2_PICKUP_RMS > 0
                                ? `${commonService.formateAmount(
                                    parseInt(LOS2_PICKUP_REV / LOS2_PICKUP_RMS)
                                  )}`
                                : `${commonService.formateAmount(0)}`}
                            </td>
                            <td className="boldTd text-center">{commonService.formateRoom(LOS3_RMS)}</td>
                            <td className="boldTd text-center">
                              {selectedType?.netStly
                                ? commonService.formateRoom(LOS3_LYRMS)
                                : commonService.formateRoom(LOS3_PICKUP_RMS)}
                            </td>
                            <td className="boldTd text-center">
                              {selectedType?.netStly
                                ? LOS3_REV > 0 && LOS3_RMS > 0
                                  ? `${commonService.formateAmount(
                                      parseInt(LOS3_REV / LOS3_RMS)
                                    )}`
                                  : `${commonService.formateAmount(0)}`
                                : `${commonService.formateAmount(
                                    parseInt(LOS3_REV / LOS3_RMS)
                                  )}`
                                ? `${commonService.formateAmount(
                                    parseInt(LOS3_REV / LOS3_RMS)
                                  )}`
                                : `${commonService.formateAmount(0)}`}
                            </td>
                            <td className="boldTd text-end">
                              {selectedType?.netStly
                                ? LOS3_LYREV > 0 && LOS3_LYRMS > 0
                                  ? `${commonService.formateAmount(
                                      parseInt(LOS3_LYREV / LOS3_LYRMS)
                                    )}`
                                  : `${commonService.formateAmount(0)}`
                                : LOS3_PICKUP_REV > 0 && LOS3_PICKUP_RMS > 0
                                ? `${commonService.formateAmount(
                                    parseInt(LOS3_PICKUP_REV / LOS3_PICKUP_RMS)
                                  )}`
                                : `${commonService.formateAmount(0)}`}
                            </td>
                            <td className="boldTd text-center">{commonService.formateRoom(LOS4_RMS)}</td>
                            <td className="boldTd text-center">
                              {selectedType?.netStly
                                ? commonService.formateRoom(LOS4_LYRMS)
                                : commonService.formateRoom(LOS4_PICKUP_RMS)}
                            </td>
                            <td className="boldTd text-center">
                              {selectedType?.netStly
                                ? LOS4_REV > 0 && LOS4_RMS > 0
                                  ? `${commonService.formateAmount(
                                      parseInt(LOS4_REV / LOS4_RMS)
                                    )}`
                                  : `${commonService.formateAmount(0)}`
                                : `${commonService.formateAmount(
                                    parseInt(LOS4_REV / LOS4_RMS)
                                  )}`
                                ? `${commonService.formateAmount(
                                    parseInt(LOS4_REV / LOS4_RMS)
                                  )}`
                                : `${commonService.formateAmount(0)}`}
                            </td>
                            <td className="boldTd text-end">
                              {selectedType?.netStly
                                ? LOS4_LYREV > 0 && LOS4_LYRMS > 0
                                  ? `${commonService.formateAmount(
                                      parseInt(LOS4_LYREV / LOS4_LYRMS)
                                    )}`
                                  : `${commonService.formateAmount(0)}`
                                : LOS4_PICKUP_REV > 0 && LOS4_PICKUP_RMS > 0
                                ? `${commonService.formateAmount(
                                    parseInt(LOS4_PICKUP_REV / LOS4_PICKUP_RMS)
                                  )}`
                                : `${commonService.formateAmount(0)}`}
                            </td>
                            <td className="boldTd text-center">{commonService.formateRoom(LOS5_RMS)}</td>
                            <td className="boldTd text-center">
                              {selectedType?.netStly
                                ? commonService.formateRoom(LOS5_LYRMS)
                                : commonService.formateRoom(LOS5_PICKUP_RMS)}
                            </td>
                            <td className="boldTd text-center">
                              {selectedType?.netStly
                                ? LOS5_REV > 0 && LOS5_RMS > 0
                                  ? `${commonService.formateAmount(
                                      parseInt(LOS5_REV / LOS5_RMS)
                                    )}`
                                  : `${commonService.formateAmount(0)}`
                                : `${commonService.formateAmount(
                                    parseInt(LOS5_REV / LOS5_RMS)
                                  )}`
                                ? `${commonService.formateAmount(
                                    parseInt(LOS5_REV / LOS5_RMS)
                                  )}`
                                : `${commonService.formateAmount(0)}`}
                            </td>
                            <td className="boldTd text-end">
                              {selectedType?.netStly
                                ? LOS5_LYREV > 0 && LOS5_LYRMS > 0
                                  ? `${commonService.formateAmount(
                                      parseInt(LOS5_LYREV / LOS5_LYRMS)
                                    )}`
                                  : `${commonService.formateAmount(0)}`
                                : LOS5_PICKUP_REV > 0 && LOS5_PICKUP_RMS > 0
                                ? `${commonService.formateAmount(
                                    parseInt(LOS5_PICKUP_REV / LOS5_PICKUP_RMS)
                                  )}`
                                : `${commonService.formateAmount(0)}`}
                            </td>
                            <td className="boldTd text-center">{commonService.formateRoom(LOS6_RMS)}</td>
                            <td className="boldTd text-center">
                              {selectedType?.netStly
                                ? commonService.formateRoom(LOS6_LYRMS)
                                : commonService.formateRoom(LOS6_PICKUP_RMS)}
                            </td>
                            <td className="boldTd text-center">
                              {selectedType?.netStly
                                ? LOS6_REV > 0 && LOS6_RMS > 0
                                  ? `${commonService.formateAmount(
                                      parseInt(LOS6_REV / LOS6_RMS)
                                    )}`
                                  : `${commonService.formateAmount(0)}`
                                : `${commonService.formateAmount(
                                    parseInt(LOS6_REV / LOS6_RMS)
                                  )}`
                                ? `${commonService.formateAmount(
                                    parseInt(LOS6_REV / LOS6_RMS)
                                  )}`
                                : `${commonService.formateAmount(0)}`}
                            </td>
                            <td className="boldTd text-end">
                              {selectedType?.netStly
                                ? LOS6_LYREV > 0 && LOS6_LYRMS > 0
                                  ? `${commonService.formateAmount(
                                      parseInt(LOS6_LYREV / LOS6_LYRMS)
                                    )}`
                                  : `${commonService.formateAmount(0)}`
                                : LOS6_PICKUP_REV > 0 && LOS6_PICKUP_RMS > 0
                                ? `${commonService.formateAmount(
                                    parseInt(LOS6_PICKUP_REV / LOS6_PICKUP_RMS)
                                  )}`
                                : `${commonService.formateAmount(0)}`}
                            </td>
                          </tr>
                        )}
                      </React.Fragment>
                    );
                  })
                )}
              </tbody>
            </Table>
          </div>
        </div>
        <Overlay
          show={rms.IsDisplay}
          target={rms.Target}
          placement={rms.Position}
        >
          <Popover
            id="popover-contained"
            className="SingleDateSummaryModalPopoverMain"
          >
            <div className="horizontal-menu-option active">
              <SingleDateRMSModal
                closeModal={closeRmsModal}
                asOfDate={para?.asOfDate}
                selectedDate={rmsSingleDateRef.current}
                pickupDate={
                  selectPickupDate
                    ? selectPickupDate
                    : singleDatePickupDateRef.current
                }
                propertyCode={para?.propertyCode}
                marketSegment={rmsSelectedMarketSegmentRef.current}
              />
            </div>
          </Popover>
        </Overlay>

        <Overlay
          show={ONTHEBOOKS.IsDisplay}
          target={ONTHEBOOKS.Target}
          placement={ONTHEBOOKS.Position}
        >
          <Popover
            id="popover-contained"
            className="SingleDateSummaryModalPopoverMain"
          >
            <div className="horizontal-menu-option active">
              <SingleDateSummaryModal
                closeModal={closeSingleDate}
                asOfDate={para?.asOfDate}
                selectedDate={singleDateSelectedDateRef.current}
                pickupDate={
                  selectPickupDate
                    ? selectPickupDate
                    : singleDatePickupDateRef.current
                }
                propertyDetail={{ propertyCode: para?.propertyCode }}
              />
            </div>
          </Popover>
        </Overlay>
        {showTicketModal && (
          <EventViewModal
            showModal={showTicketModal}
            closeModal={closeTicketModal}
            eventDetails={eventListPropsRef.current}
            selectedDate={selectedEventDateRef.current}
            getEventList={getEventList}
          />
        )}
      </div>
      {api !== 0 && <Comments widgetDetails={para} editorId={"LengthOfStay"} />}

      {/* {showNoteSidebar && (
                <WidgetNote
                    show={showNoteSidebar}
                    handleClose={closeNoteSideBar}
                    widgetId={para?.widgetId}
                    propertyId={propertyDetailFromRTK?.propertyid}
                    rowTitle={para?.rowTitle}
                />
            )} */}
      {showNoteSidebar && (
        <AddWidgetModal
          showModal={showNoteSidebar}
          closeModal={closeNoteSideBar}
          widgetId={para?.widgetId}
          propertyId={propertyDetailFromRTK?.propertyid}
          rowTitle={para?.rowTitle}
        />
      )}
      {widgetModal && (
        <WidgetDetailModal
          showModal={widgetModal}
          closeModal={closeWidgetModal}
          data={widgetDetailRef.current}
        />
      )}
    </div>
  );
};

export default LengthOfStay;
