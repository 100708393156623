import { Navigate, Route, Routes } from "react-router-dom";
import Header from "../Header/Header";
import AsideConfiguration from "./AsideConfiguration";
import React from "react";
import Loader from "../Loader/Loader";
import { commonService } from "../../utils/commonService";
import { useSelector } from "react-redux";
const Configuration = React.lazy(() => import("./Configuration"));
const EmailConfiguration = React.lazy(() => import("./EmailConfiguration"));
const RoleMangement = React.lazy(() => import("./RoleMangement"));
const UserManagement = React.lazy(() => import("./UserManagement"));
const LogInLogs = React.lazy(() => import("./LogInLogs"));
const ViewPropertySetup = React.lazy(() => import("./ViewPropertySetup"));
const EmployeeManagement = React.lazy(() => import("./EmployeeManagement"));
const EmailTemplateConfig = React.lazy(() => import("./EmailTemplateConfig"));
const ActionLogs = React.lazy(() => import("./ActionLogs"));
const CodeSetup = React.lazy(() => import("./CodeSetup"));
const PortfolioSetup = React.lazy(() => import("./PortfolioSetup"));
const GeneralTermSetup = React.lazy(() => import("./GeneralTermSetup"));
const PropertySetup = React.lazy(() => import("./PropertySetup"));
const EmployeeDetail = React.lazy(() => import("./EmployeeDetail"));
const EditEmailTempConfigPage = React.lazy(() =>
    import("./EditEmailTempConfigPage")
);

function LayoutConfig() {
    const token = commonService.getItem("token");
    const sidebarOpen = useSelector(
        (store) => store.headerProperty.isSidebarOpen
    );
    return (
        <div className={`mainRoot ${sidebarOpen ? "full-menu" : "icon-toggle-menu"}`} >
            <Header />
            <AsideConfiguration />
            <main
                className={`config-page-body container-data-load`}
            >
                <Routes>
                    <Route
                        path="/configuration"
                        element={
                            token ? (
                                <React.Suspense fallback={<Loader />}>
                                    <Configuration />
                                </React.Suspense>
                            ) : (
                                <Navigate to="/" replace />
                            )
                        }
                    />
                    <Route
                        path="/property-setup/view-property-setup"
                        element={
                            token ? (
                                <React.Suspense fallback={<Loader />}>
                                    <ViewPropertySetup />
                                </React.Suspense>
                            ) : (
                                <Navigate to="/" replace />
                            )
                        }
                    />
                    <Route
                        path="/general-term-setup"
                        element={
                            token ? (
                                <React.Suspense fallback={<Loader />}>
                                    <GeneralTermSetup />
                                </React.Suspense>
                            ) : (
                                <Navigate to="/" replace />
                            )
                        }
                    />
                    <Route
                        path="/general-term-setup/code-setup"
                        element={
                            token ? (
                                <React.Suspense fallback={<Loader />}>
                                    <CodeSetup />
                                </React.Suspense>
                            ) : (
                                <Navigate to="/" replace />
                            )
                        }
                    />
                    <Route
                        path="/email-configuration"
                        element={
                            token ? (
                                <React.Suspense fallback={<Loader />}>
                                    <EmailConfiguration />
                                </React.Suspense>
                            ) : (
                                <Navigate to="/" replace />
                            )
                        }
                    />
                    <Route
                        path="/email-template-configuration"
                        element={
                            token ? (
                                <React.Suspense fallback={<Loader />}>
                                    <EmailTemplateConfig />
                                </React.Suspense>
                            ) : (
                                <Navigate to="/" replace />
                            )
                        }
                    />
                    <Route
                        path="/edit-email-template-configuration/:id"
                        element={
                            token ? (
                                <React.Suspense fallback={<Loader />}>
                                    <EditEmailTempConfigPage />
                                </React.Suspense>
                            ) : (
                                <Navigate to="/" replace />
                            )
                        }
                    />
                    <Route
                        path="/employee-management"
                        element={
                            token ? (
                                <React.Suspense fallback={<Loader />}>
                                    <EmployeeManagement />
                                </React.Suspense>
                            ) : (
                                <Navigate to="/" replace />
                            )
                        }
                    />
                    <Route
                        path="/role-management"
                        element={
                            token ? (
                                <React.Suspense fallback={<Loader />}>
                                    <RoleMangement />
                                </React.Suspense>
                            ) : (
                                <Navigate to="/" replace />
                            )
                        }
                    />
                    <Route
                        path="/user-management"
                        element={
                            token ? (
                                <React.Suspense fallback={<Loader />}>
                                    <UserManagement />
                                </React.Suspense>
                            ) : (
                                <Navigate to="/" replace />
                            )
                        }
                    />
                    <Route
                        path="/login-logs"
                        element={
                            token ? (
                                <React.Suspense fallback={<Loader />}>
                                    <LogInLogs />
                                </React.Suspense>
                            ) : (
                                <Navigate to="/" replace />
                            )
                        }
                    />
                    <Route
                        path="/portfolio-setup"
                        element={
                            token ? (
                                <React.Suspense fallback={<Loader />}>
                                    <PortfolioSetup />
                                </React.Suspense>
                            ) : (
                                <Navigate to="/" replace />
                            )
                        }
                    />
                    <Route
                        path="/property-setup"
                        element={
                            token ? (
                                <React.Suspense fallback={<Loader />}>
                                    <PropertySetup />
                                </React.Suspense>
                            ) : (
                                <Navigate to="/" replace />
                            )
                        }
                    />
                    <Route
                        path="/action-logs"
                        element={
                            token ? (
                                <React.Suspense fallback={<Loader />}>
                                    <ActionLogs />
                                </React.Suspense>
                            ) : (
                                <Navigate to="/" replace />
                            )
                        }
                    />
                    <Route
                        path="/employee-management/employee-detail/:id"
                        element={
                            token ? (
                                <React.Suspense fallback={<Loader />}>
                                    <EmployeeDetail />
                                </React.Suspense>
                            ) : (
                                <Navigate to="/" replace />
                            )
                        }
                    />
                </Routes>
            </main>
        </div>
    );
}

export default LayoutConfig;
