import { configureStore } from "@reduxjs/toolkit";
import headerPropertySlice from "./slices/headerPropertySlice";
import callApiAgainSlice from "./slices/callApiAgainSlice";

const store = configureStore({
  reducer: {
    headerProperty: headerPropertySlice,
    callApiAgain: callApiAgainSlice,
  },
});

export default store;
