import React, { useEffect, useRef, useState } from "react";
import { apiCall } from "../../../../utils/axiosService";
import { API_URL } from "../../../../utils/apiRoutes";
import { apiResponse } from "../../../../utils/apiResponse";
import { Button, Table } from "react-bootstrap";
import NoteIcon from "../../../../Assets/Images/CRM/notes.svg";
import downloadIcon from "../../../../Assets/Images/Operation/downloadIcon.svg";
import Loader from "../../../Loader/Loader";
import { commonService } from "../../../../utils/commonService";
import { compName, month } from "../../../../utils/constant";
import WidgetDetailModal from "../../WidgetDetailModal";
import WidgetNote from "../../WidgetNote";
import { useSelector } from "react-redux";
import infoIcon from "../../../../Assets/Images/Action Icons/infoIcon..svg";
import AddWidgetModal from "../../AddWidgetModal";
import { useParams } from "react-router-dom";
import emailIcon from "../../../../Assets/Images/Email.svg";
import Comments from "../Comments/Comment";
import moment from "moment";

const CyCancellationBookingRevLoss = ({
  setCompDataFromChild,
  rowIndex,
  colIndex,
  api,
  para,
  setting,
}) => {
  const tableData = [
    {
      AsOfDate: "",
      MonthName: "January",
      CancelledNights: 0,
      ADR: "0",
      TotalLoss: "0",
      CancelledRatio: "0",
      "Total Inventory": 0,
      AvgLeadTime: "0",
      UnSoldRoom: 0,
    },
    {
      AsOfDate: "",
      MonthName: "February",
      CancelledNights: 0,
      ADR: "0",
      TotalLoss: "0",
      CancelledRatio: "0",
      "Total Inventory": 0,
      AvgLeadTime: "0",
      UnSoldRoom: 0,
    },
    {
      AsOfDate: "",
      MonthName: "March",
      CancelledNights: 0,
      ADR: "0",
      TotalLoss: "0",
      CancelledRatio: "0",
      "Total Inventory": 0,
      AvgLeadTime: "0",
      UnSoldRoom: 0,
    },
    {
      AsOfDate: "",
      MonthName: "April",
      CancelledNights: 0,
      ADR: "0",
      TotalLoss: "0",
      CancelledRatio: "0",
      "Total Inventory": 0,
      AvgLeadTime: "0",
      UnSoldRoom: 0,
    },
    {
      AsOfDate: "",
      MonthName: "May",
      CancelledNights: 0,
      ADR: "0",
      TotalLoss: "0",
      CancelledRatio: "0",
      "Total Inventory": 0,
      AvgLeadTime: "0",
      UnSoldRoom: 0,
    },
    {
      AsOfDate: "",
      MonthName: "June",
      CancelledNights: 0,
      ADR: "0",
      TotalLoss: "0",
      CancelledRatio: "0",
      "Total Inventory": 0,
      AvgLeadTime: "0",
      UnSoldRoom: 0,
    },
    {
      AsOfDate: "",
      MonthName: "July",
      CancelledNights: 0,
      ADR: "0",
      TotalLoss: "0",
      CancelledRatio: "0",
      "Total Inventory": 0,
      AvgLeadTime: "0",
      UnSoldRoom: 0,
    },
    {
      AsOfDate: "",
      MonthName: "August",
      CancelledNights: 0,
      ADR: "0",
      TotalLoss: "0",
      CancelledRatio: "0",
      "Total Inventory": 0,
      AvgLeadTime: "0",
      UnSoldRoom: 0,
    },
    {
      AsOfDate: "",
      MonthName: "September",
      CancelledNights: 0,
      ADR: "0",
      TotalLoss: "0",
      CancelledRatio: "0",
      "Total Inventory": 0,
      AvgLeadTime: "0",
      UnSoldRoom: 0,
    },
    {
      AsOfDate: "",
      MonthName: "October",
      CancelledNights: 0,
      ADR: "0",
      TotalLoss: "0",
      CancelledRatio: "0",
      "Total Inventory": 0,
      AvgLeadTime: "0",
      UnSoldRoom: 0,
    },
    {
      AsOfDate: "",
      MonthName: "November",
      CancelledNights: 0,
      ADR: "0",
      TotalLoss: "0",
      CancelledRatio: "0",
      "Total Inventory": 0,
      AvgLeadTime: "0",
      UnSoldRoom: 0,
    },
    {
      AsOfDate: "",
      MonthName: "December",
      CancelledNights: 0,
      ADR: "0",
      TotalLoss: "0",
      CancelledRatio: "0",
      "Total Inventory": 0,
      AvgLeadTime: "0",
      UnSoldRoom: 0,
    },
  ];

  let inventory = 0,
    cancelledNights = 0,
    adr = 0,
    cancelledRatio = 0,
    totalLoss = 0,
    adrCount = 0,
    cancelledRatioCount = 0;

  const [cancellationData, setCancellationData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [widgetModal, setWidgetModal] = useState(false);
  const widgetDetailRef = useRef();
  const [showNoteSidebar, setShowNoteSidebar] = useState(false);
  const propertyDetailFromRTK = useSelector(
    (store) => store.headerProperty.propertyDetail
  );
  const defaultAsOfDateFromRTK = useSelector(
    (store) => store.headerProperty.defaultAsOfDate
  );
  const { snapshottitle, propertyCode, snapshotId } = useParams();
  const closeNoteSideBar = () => {
    setShowNoteSidebar(false);
  };

  const closeWidgetModal = () => {
    setWidgetModal(false);
  };

  const getCancellationData = async () => {
    setLoading(true);
    let cancellationResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.SnapShot +
          API_URL.CANCELLATION.GET_CY_CANCELLATION_BOOKING_REV_LOSS,
        body: { propertyCode: para?.propertyCode, AsOfDate: para?.asOfDate },
      },
      false
    );

    let response = apiResponse(false, cancellationResponse);

    if (response?.isValidate) {
      setCancellationData(response?.data?.data);
      setLoading(false);
    }

    if (!response?.isValidate) {
      setCancellationData(tableData);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (api === 0) {
      setCancellationData(tableData);
    } else {
      if (para?.asOfDate) {
        getCancellationData();
      }
    }
  }, [para?.asOfDate]);

  const [tblids, settblids] = useState({
    cyCancellationBookinRevLoss: commonService.GenerateGUID(),
  });

  return (
    <div className="widget-container">
      {api !== 0 && (
        <div className="gap-1 widget-container-menu">
          <Button className="heading-menu-btns" type="button">
            <img
              src={NoteIcon}
              alt="menuIcon"
              onClick={() => {
                setShowNoteSidebar(true);
              }}
            />
          </Button>
          <Button className="heading-menu-btns" type="button" title="Send Mail">
            <img
              src={emailIcon}
              alt="emailIcon"
              onClick={() => {
                commonService.fnSendWidgetMail(
                  tblids.cyCancellationBookinRevLoss,
                  "Cancellation Booking Revenue Loss CY",
                  snapshotId,
                  defaultAsOfDateFromRTK,
                  "",
                  para?.widgetId
                );
              }}
            />
          </Button>
          <Button className="heading-menu-btns" type="button">
            <img
              src={infoIcon}
              alt="info"
              onClick={() => {
                widgetDetailRef.current = {
                  widgetdetails: "",
                  usernote: "",
                  compName: compName?.cyCancellationBookingRevLoss,
                  widgetId: para?.widgetId,
                };
                setWidgetModal(true);
              }}
            />
          </Button>
          <Button className="heading-menu-btns" type="button">
            <img
              src={downloadIcon}
              alt="menuIcon"
              import={tblids.cyCancellationBookinRevLoss}
              onClick={() =>
                commonService.fnExportInCSV(
                  tblids.cyCancellationBookinRevLoss,
                  // "Cancellation Booking Revenue Loss CY"
                  `${
                    para?.propertyCode
                  }-Cancellation Booking Revenue Loss CY-${moment(
                    new Date()
                  ).format("MMDDYYYY")}`
                )
              }
            />
          </Button>
        </div>
      )}
      <div className="ViewPropertySetupTable">
        <div className="stripped__table">
          <Table id={tblids.cyCancellationBookinRevLoss} responsive>
            <thead>
              <tr>
                <th className="text-center" colSpan={6}>
                  ANNUAL CANCELLATION
                </th>
              </tr>
              <tr>
                <th className="text-center">Month</th>
                <th className="text-center">Total Inventory</th>
                <th className="text-center">Cancelled Nights</th>
                <th className="text-end">ADR</th>
                <th className="text-center">Cancelled Ratio</th>
                <th className="text-end">Total Loss</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr style={{ height: "80px", position: "relative" }}>
                  <Loader />
                </tr>
              ) : (
                month?.map((item, index) => {
                  let findMonthData = cancellationData?.filter(
                    (x) => x?.MonthName?.trim() === item
                  );

                  inventory =
                    inventory +
                    (findMonthData?.[0]?.["Total Inventory"]
                      ? parseInt(findMonthData?.[0]?.["Total Inventory"])
                      : 0);

                  cancelledNights =
                    cancelledNights +
                    (findMonthData?.[0]?.["CancelledNights"]
                      ? parseInt(findMonthData?.[0]?.["CancelledNights"])
                      : 0);

                  adr =
                    adr +
                    (findMonthData?.[0]?.["ADR"]
                      ? parseInt(findMonthData?.[0]?.["ADR"])
                      : 0);

                  cancelledRatio =
                    cancelledRatio +
                    (findMonthData?.[0]?.["CancelledRatio"]
                      ? parseFloat(findMonthData?.[0]?.["CancelledRatio"])
                      : 0);

                  totalLoss =
                    totalLoss +
                    (findMonthData?.[0]?.["TotalLoss"]
                      ? parseInt(findMonthData?.[0]?.["TotalLoss"])
                      : 0);

                  if (
                    findMonthData?.[0]?.["CancelledRatio"] !== "0.00" &&
                    findMonthData?.[0]?.["CancelledRatio"]
                  ) {
                    cancelledRatioCount = cancelledRatioCount + 1;
                  }

                  if (
                    findMonthData?.[0]?.["ADR"] !== "0" &&
                    findMonthData?.[0]?.["ADR"]
                  ) {
                    adrCount = adrCount + 1;
                  }
                  return (
                    <React.Fragment key={index}>
                      <tr className="shadowOnHover">
                        <td className="text-center">{item}</td>
                        <td className="text-center">
                          {findMonthData?.[0]?.["Total Inventory"]
                            ? commonService.formateRoom(findMonthData?.[0]?.["Total Inventory"])
                            : 0}
                        </td>
                        <td className="text-center">
                          {findMonthData?.[0]?.["CancelledNights"]
                            ? commonService.formateRoom(findMonthData?.[0]?.["CancelledNights"])
                            : 0}
                        </td>
                        <td className="text-end">
                          {findMonthData?.[0]?.["ADR"]
                            ? commonService.formateAmount(
                                findMonthData?.[0]?.["ADR"]
                              )
                            : commonService.formateAmount(0)}
                        </td>
                        <td className="text-center">
                          {findMonthData?.[0]?.["CancelledRatio"]
                            ? findMonthData?.[0]?.["CancelledRatio"]
                            : 0}
                          %
                        </td>
                        <td className="text-end">
                          {findMonthData?.[0]?.["TotalLoss"]
                            ? commonService.formateAmount(
                                findMonthData?.[0]?.["TotalLoss"]
                              )
                            : commonService.formateAmount(0)}
                        </td>
                      </tr>
                      {index === 11 && (
                        <tr className="shadowOnHover">
                          <td className="boldTd text-center">Total</td>
                          <td className="boldTd text-center">{commonService.formateRoom(inventory)}</td>
                          <td className="boldTd text-center">
                            {commonService.formateRoom(cancelledNights)}
                          </td>
                          <td className="boldTd text-end">
                            {adr > 0
                              ? commonService.formateAmount(
                                  Math.round(adr / adrCount)
                                )
                              : commonService.formateAmount(0)}
                          </td>
                          <td className="boldTd text-center">
                            {cancelledRatio > 0
                              ? (cancelledRatio / cancelledRatioCount)?.toFixed(
                                  2
                                )
                              : 0}
                            %
                          </td>
                          <td className="boldTd text-end">
                            {commonService.formateAmount(totalLoss)}
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  );
                })
              )}
            </tbody>
          </Table>
        </div>
      </div>
      {api !== 0 && (
        <Comments
          widgetDetails={para}
          editorId={"CyCancellationBookingRevLoss"}
        />
      )}

      {/* {showNoteSidebar && (
        <WidgetNote
          show={showNoteSidebar}
          handleClose={closeNoteSideBar}
          widgetId={para?.widgetId}
          propertyId={propertyDetailFromRTK?.propertyid}
          rowTitle={para?.rowTitle}
        />
      )} */}
      {showNoteSidebar && (
        <AddWidgetModal
          showModal={showNoteSidebar}
          closeModal={closeNoteSideBar}
          widgetId={para?.widgetId}
          propertyId={propertyDetailFromRTK?.propertyid}
          rowTitle={para?.rowTitle}
        />
      )}
      {widgetModal && (
        <WidgetDetailModal
          showModal={widgetModal}
          closeModal={closeWidgetModal}
          data={widgetDetailRef.current}
        />
      )}
    </div>
  );
};

export default CyCancellationBookingRevLoss;
