import $ from "jquery";
import searchIcon from "../Assets/Images/Configuration/search.svg";
export const dataTable = (tableId, isCRM) => {
  if ($.fn.DataTable.isDataTable("#" + tableId)) {
    $("#" + tableId)
      .DataTable()
      .destroy();
  }
  ApplyDataTable(tableId, isCRM);
};

function ApplyDataTable(tableId, isCRM) {
  $(document).ready(function () {
    let TableTime = setInterval(function () {
      clearInterval(TableTime);
      let aTargets = [];
      let order = [];
      $(`#${tableId} thead th.no-sort`).each(function () {
        aTargets.push($(this)[0].cellIndex);
      });

      $(`#${tableId} thead th.order-asc`).each(function () {
        order.push([$(this)[0].cellIndex, "asc"]);
      });

      $(`#${tableId} thead th.order-desc`).each(function () {
        order.push([$(this)[0].cellIndex, "desc"]);
      });

      /*   no - search*/

      $(`#${tableId} thead tr.filters`).remove();

      if (
        $(`#${tableId} thead tr th`).length !=
        $(`#${tableId} thead tr th.no-search`).length
      ) {
        $(`#${tableId} thead tr`)
          .clone(true)
          .addClass("filters")
          .appendTo(`#${tableId} thead`);
      }

      $(`#${tableId}`).DataTable({
        destroy: true,
        searching: true,
        orderCellsTop: true,
        language: {
          info: "Showing <b> _PAGE_ </b> of <b>_PAGES_ </b>Results",
          paginate: {
            previous:
              '<span class="next-icon"><svg width="6" height="12" viewBox="0 0 6 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.25 1.5L0.75 6L5.25 10.5" stroke="black" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/></svg></span>',
            next: '<span class="next-icon"><svg width="6" height="12" viewBox="0 0 6 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.75 1.5L5.25 6L0.75 10.5" stroke="black" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/></svg></span>',
          },
        },
        dom: `<"table-wrapper"t><"table-header"<"table-info"i${
          isCRM ? "l" : ""
        }><"table-pagination"p>>`,
        order: order,
        aoColumnDefs: [
          {
            bSortable: false,
            aTargets: aTargets,
          },
        ],
        initComplete: function () {
          $(".dataTables_length label")
            .contents()
            .filter(function () {
              return this.nodeType === 3;
            })
            .remove();
          var api = this.api();

          api
            .columns()
            .eq(0)
            .each(function (colIdx) {
              var cell = $(`#${tableId}`)
                .find(".filters th")
                .eq($(api.column(colIdx).header()).index());

              var title = api.column(colIdx).header().innerText;
              if (!cell.hasClass("no-search")) {
                $(cell).html(
                  `<div class="search-group-for-table input-group"><button type="button" class="border-0 btn btn-primary"><img src="${searchIcon}"></button><input "` +
                    title +
                    `" aria-label="Search" class="border-0 form-control"></div>`
                );
              } else {
                $(cell).text("");
              }

              $(
                "input",
                $(`#${tableId}`)
                  .find(".filters th")
                  .eq($(api.column(colIdx).header()).index())
              )
                .off("keyup change")
                .on("keyup change", function (e) {
                  e.stopPropagation();

                  $(this).attr("title", $(this).val());
                  var regexr = "({search})";

                  var cursorPosition = this.selectionStart;
                  api
                    .column(colIdx)
                    .search(
                      this.value != ""
                        ? regexr.replace("{search}", "(((" + this.value + ")))")
                        : "",
                      this.value != "",
                      this.value == ""
                    )
                    .draw();

                  $(this)
                    .focus()[0]
                    .setSelectionRange(cursorPosition, cursorPosition);
                });
            });

          $(`#${tableId} thead tr.filters th`).attr("class", "");
        },
      });
    }, 200);
  });
}

export const FixColumn = (tableId, colPositionArray) => {
  $(document).ready(function () {
    if (
      colPositionArray !== null &&
      colPositionArray !== undefined &&
      colPositionArray.length !== 0
    ) {
      $("#" + tableId + " thead tr.table-main-header-tr").each(function () {
        for (let i = 0; i < colPositionArray.length; i++) {
          let leftPos = 0;
          if (colPositionArray[i] > 0) {
            for (let pd = 1; pd < colPositionArray[i]; pd++) {
              if (
                $(
                  "#" +
                    tableId +
                    " tr.table-main-header-tr th:nth-child(" +
                    pd +
                    ")"
                ).width() !== undefined
              ) {
                leftPos =
                  leftPos +
                  $(
                    "#" +
                      tableId +
                      " tr.table-main-header-tr th:nth-child(" +
                      pd +
                      ")"
                  ).width() +
                  10;
              }
            }
          }
          $("#" + tableId + " tr td:nth-child(" + colPositionArray[i] + ")")
            .addClass("stickycolumn my-special-sticky")
            .css("left", leftPos + "px")
            .css("zIndex", "8");

          $("#" + tableId + " tr th:nth-child(" + colPositionArray[i] + ")")
            .addClass("stickycolumn")
            .css("left", leftPos + "px");
        }
      });
    }
  });
};
