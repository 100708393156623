import { Button, Col, Form, Tab, Tabs } from "react-bootstrap";
import ThreeBarAndLineChart from "../../../CommonComponent/ThreeBarAndLineChart";
import { apiCall } from "../../../../utils/axiosService";
import { API_URL } from "../../../../utils/apiRoutes";
import { apiResponse } from "../../../../utils/apiResponse";
import { useEffect, useState } from "react";
import NoteIcon from "../../../../Assets/Images/CRM/notes.svg";
import downloadIcon from "../../../../Assets/Images/Operation/downloadIcon.svg";
import { commonService } from "../../../../utils/commonService";

const WidgetBudgetGraph = ({
  setCompDataFromChild,
  rowIndex,
  colIndex,
  api,
  para,
  setting,
}) => {
  const [graphData, setGraphData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedGraph, setSelectedGraph] = useState("REVENUE");
  const resultType = ["budget", "net_stly", "OTB", "total_ly"];
  const graphDummyData = [
    {
      propertyCode: "AC32AW",
      AsOfDate: "2023-08-17",
      year: 2023,
      month: "October",
      rev: "138317.00",
      result_type: "budget",
    },
    {
      propertyCode: "AC32AW",
      AsOfDate: "2023-08-17",
      year: 2023,
      month: "February",
      rev: "103080.00",
      result_type: "budget",
    },
    {
      propertyCode: "AC32AW",
      AsOfDate: "2023-08-17",
      year: 2023,
      month: "April",
      rev: "136356.00",
      result_type: "budget",
    },
    {
      propertyCode: "AC32AW",
      AsOfDate: "2023-08-17",
      year: 2023,
      month: "September",
      rev: "168325.00",
      result_type: "budget",
    },
    {
      propertyCode: "AC32AW",
      AsOfDate: "2023-08-17",
      year: 2023,
      month: "November",
      rev: "97636.00",
      result_type: "budget",
    },
    {
      propertyCode: "AC32AW",
      AsOfDate: "2023-08-17",
      year: 2023,
      month: "December",
      rev: "76453.00",
      result_type: "budget",
    },
    {
      propertyCode: "AC32AW",
      AsOfDate: "2023-08-17",
      year: 2023,
      month: "August",
      rev: "176167.00",
      result_type: "budget",
    },
    {
      propertyCode: "AC32AW",
      AsOfDate: "2023-08-17",
      year: 2023,
      month: "July",
      rev: "159910.00",
      result_type: "budget",
    },
    {
      propertyCode: "AC32AW",
      AsOfDate: "2023-08-17",
      year: 2023,
      month: "June",
      rev: "155269.00",
      result_type: "budget",
    },
    {
      propertyCode: "AC32AW",
      AsOfDate: "2023-08-17",
      year: 2023,
      month: "January",
      rev: "104718.00",
      result_type: "budget",
    },
    {
      propertyCode: "AC32AW",
      AsOfDate: "2023-08-17",
      year: 2023,
      month: "March",
      rev: "190565.00",
      result_type: "budget",
    },
    {
      propertyCode: "AC32AW",
      AsOfDate: "2023-08-17",
      year: 2023,
      month: "May",
      rev: "135643.00",
      result_type: "budget",
    },
    {
      propertyCode: "AC32AW",
      AsOfDate: "2023-08-17",
      year: 2023,
      month: "July",
      rev: "147141.07",
      result_type: "net_stly",
    },
    {
      propertyCode: "AC32AW",
      AsOfDate: "2023-08-17",
      year: 2023,
      month: "May",
      rev: "124806.50",
      result_type: "net_stly",
    },
    {
      propertyCode: "AC32AW",
      AsOfDate: "2023-08-17",
      year: 2023,
      month: "September",
      rev: "9487.74",
      result_type: "net_stly",
    },
    {
      propertyCode: "AC32AW",
      AsOfDate: "2023-08-17",
      year: 2023,
      month: "April",
      rev: "125470.27",
      result_type: "net_stly",
    },
    {
      propertyCode: "AC32AW",
      AsOfDate: "2023-08-17",
      year: 2023,
      month: "February",
      rev: "94849.95",
      result_type: "net_stly",
    },
    {
      propertyCode: "AC32AW",
      AsOfDate: "2023-08-17",
      year: 2023,
      month: "December",
      rev: "0.00",
      result_type: "net_stly",
    },
    {
      propertyCode: "AC32AW",
      AsOfDate: "2023-08-17",
      year: 2023,
      month: "November",
      rev: "371.16",
      result_type: "net_stly",
    },
    {
      propertyCode: "AC32AW",
      AsOfDate: "2023-08-17",
      year: 2023,
      month: "October",
      rev: "6925.11",
      result_type: "net_stly",
    },
    {
      propertyCode: "AC32AW",
      AsOfDate: "2023-08-17",
      year: 2023,
      month: "January",
      rev: "96352.45",
      result_type: "net_stly",
    },
    {
      propertyCode: "AC32AW",
      AsOfDate: "2023-08-17",
      year: 2023,
      month: "June",
      rev: "142863.06",
      result_type: "net_stly",
    },
    {
      propertyCode: "AC32AW",
      AsOfDate: "2023-08-17",
      year: 2023,
      month: "August",
      rev: "109871.13",
      result_type: "net_stly",
    },
    {
      propertyCode: "AC32AW",
      AsOfDate: "2023-08-17",
      year: 2023,
      month: "March",
      rev: "175346.28",
      result_type: "net_stly",
    },
    {
      propertyCode: "AC32AW",
      AsOfDate: "2023-08-17",
      year: 2023,
      month: "January",
      rev: "93844.08",
      result_type: "OTB",
    },
    {
      propertyCode: "AC32AW",
      AsOfDate: "2023-08-17",
      year: 2023,
      month: "March",
      rev: "210418.90",
      result_type: "OTB",
    },
    {
      propertyCode: "AC32AW",
      AsOfDate: "2023-08-17",
      year: 2023,
      month: "July",
      rev: "163363.29",
      result_type: "OTB",
    },
    {
      propertyCode: "AC32AW",
      AsOfDate: "2023-08-17",
      year: 2023,
      month: "April",
      rev: "134686.39",
      result_type: "OTB",
    },
    {
      propertyCode: "AC32AW",
      AsOfDate: "2023-08-17",
      year: 2023,
      month: "February",
      rev: "148109.12",
      result_type: "OTB",
    },
    {
      propertyCode: "AC32AW",
      AsOfDate: "2023-08-17",
      year: 2023,
      month: "May",
      rev: "113836.67",
      result_type: "OTB",
    },
    {
      propertyCode: "AC32AW",
      AsOfDate: "2023-08-17",
      year: 2023,
      month: "December",
      rev: "55.23",
      result_type: "OTB",
    },
    {
      propertyCode: "AC32AW",
      AsOfDate: "2023-08-17",
      year: 2023,
      month: "October",
      rev: "9326.09",
      result_type: "OTB",
    },
    {
      propertyCode: "AC32AW",
      AsOfDate: "2023-08-17",
      year: 2023,
      month: "September",
      rev: "16440.32",
      result_type: "OTB",
    },
    {
      propertyCode: "AC32AW",
      AsOfDate: "2023-08-17",
      year: 2023,
      month: "November",
      rev: "819.33",
      result_type: "OTB",
    },
    {
      propertyCode: "AC32AW",
      AsOfDate: "2023-08-17",
      year: 2023,
      month: "June",
      rev: "146800.71",
      result_type: "OTB",
    },
    {
      propertyCode: "AC32AW",
      AsOfDate: "2023-08-17",
      year: 2023,
      month: "August",
      rev: "124734.64",
      result_type: "OTB",
    },
    {
      propertyCode: "AC32AW",
      AsOfDate: "2023-08-17",
      year: 2023,
      month: "September",
      rev: "154883.87",
      result_type: "total_ly",
    },
    {
      propertyCode: "AC32AW",
      AsOfDate: "2023-08-17",
      year: 2023,
      month: "February",
      rev: "94849.95",
      result_type: "total_ly",
    },
    {
      propertyCode: "AC32AW",
      AsOfDate: "2023-08-17",
      year: 2023,
      month: "November",
      rev: "89841.44",
      result_type: "total_ly",
    },
    {
      propertyCode: "AC32AW",
      AsOfDate: "2023-08-17",
      year: 2023,
      month: "July",
      rev: "147141.07",
      result_type: "total_ly",
    },
    {
      propertyCode: "AC32AW",
      AsOfDate: "2023-08-17",
      year: 2023,
      month: "April",
      rev: "125470.27",
      result_type: "total_ly",
    },
    {
      propertyCode: "AC32AW",
      AsOfDate: "2023-08-17",
      year: 2023,
      month: "August",
      rev: "162100.30",
      result_type: "total_ly",
    },
    {
      propertyCode: "AC32AW",
      AsOfDate: "2023-08-17",
      year: 2023,
      month: "June",
      rev: "142863.06",
      result_type: "total_ly",
    },
    {
      propertyCode: "AC32AW",
      AsOfDate: "2023-08-17",
      year: 2023,
      month: "December",
      rev: "70349.24",
      result_type: "total_ly",
    },
    {
      propertyCode: "AC32AW",
      AsOfDate: "2023-08-17",
      year: 2023,
      month: "October",
      rev: "127270.97",
      result_type: "total_ly",
    },
    {
      propertyCode: "AC32AW",
      AsOfDate: "2023-08-17",
      year: 2023,
      month: "January",
      rev: "96352.45",
      result_type: "total_ly",
    },
    {
      propertyCode: "AC32AW",
      AsOfDate: "2023-08-17",
      year: 2023,
      month: "March",
      rev: "175346.28",
      result_type: "total_ly",
    },
    {
      propertyCode: "AC32AW",
      AsOfDate: "2023-08-17",
      year: 2023,
      month: "May",
      rev: "124806.50",
      result_type: "total_ly",
    },
  ];

  const setLabel = (labelType) => {
    let label = "Budget";
    if (labelType === "net_stly") {
      label = "Net Stly";
    } else if (labelType === "OTB") {
      label = "OTB";
    } else if (labelType === "total_ly") {
      label = "Total LY";
    }
    return label;
  };

  const getGraphDetail = async () => {
    setLoading(true);
    let graphDetailResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.SnapShot + API_URL.WIDGET_BUDGET.WIDGET_BUDGET,
        body: {
          AsOfDate: para?.asOfDate,
          year: `${new Date().getFullYear()}`,
          propertyCode: para?.propertyCode,
          widgetType: selectedGraph,
        },
      },
      false
    );

    let response = apiResponse(false, graphDetailResponse);

    if (response?.isValidate) {
      let keyValue = "";
      if (selectedGraph === "REVENUE") {
        keyValue = "rev";
      } else if (selectedGraph === "RMS") {
        keyValue = "rms";
      } else if (selectedGraph === "Occupancy") {
        keyValue = "occ";
      }
      let graphGroupingData = {};
      let responseData = response?.data?.data;
      for (let i = 0; i < resultType?.length; i++) {
        graphGroupingData = {
          ...graphGroupingData,
          [resultType[i]]: {
            labels: [
              "Jan",
              "Feb",
              "Mar",
              "Apr",
              "May",
              "Jun",
              "Jul",
              "Aug",
              "Sep",
              "Oct",
              "Nov",
              "Dec",
            ],
            label: `${setLabel(resultType[i])}`,
            data: [],
          },
        };
        for (let j = 0; j < responseData?.length; j++) {
          if (resultType[i] === responseData[j]?.result_type) {
            graphGroupingData[resultType[i]].data = [
              ...graphGroupingData?.[resultType[i]].data,
              responseData?.[j]?.[keyValue],
            ];
          }
        }
      }
      setGraphData(graphGroupingData);
      setLoading(false);
    }
    if (!response?.isValidate) {
      let keyValue = "";
      if (selectedGraph === "REVENUE") {
        keyValue = "rev";
      } else if (selectedGraph === "RMS") {
        keyValue = "rms";
      } else if (selectedGraph === "Occupancy") {
        keyValue = "occ";
      }
      let graphGroupingData = {};
      let responseData = graphDummyData;
      for (let i = 0; i < resultType?.length; i++) {
        graphGroupingData = {
          ...graphGroupingData,
          [resultType[i]]: {
            labels: [
              "Jan",
              "Feb",
              "Mar",
              "Apr",
              "May",
              "Jun",
              "Jul",
              "Aug",
              "Sep",
              "Oct",
              "Nov",
              "Dec",
            ],
            label: `${setLabel(resultType[i])}`,
            data: [],
          },
        };
        for (let j = 0; j < responseData?.length; j++) {
          if (resultType[i] === responseData[j]?.result_type) {
            graphGroupingData[resultType[i]].data = [
              ...graphGroupingData?.[resultType[i]].data,
              0,
            ];
          }
        }
      }
      setGraphData(graphGroupingData);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (api === 0) {
      let keyValue = "";
      if (selectedGraph === "REVENUE") {
        keyValue = "rev";
      } else if (selectedGraph === "RMS") {
        keyValue = "rms";
      } else if (selectedGraph === "Occupancy") {
        keyValue = "occ";
      }
      let graphGroupingData = {};
      let responseData = graphDummyData;
      for (let i = 0; i < resultType?.length; i++) {
        graphGroupingData = {
          ...graphGroupingData,
          [resultType[i]]: {
            labels: [
              "Jan",
              "Feb",
              "Mar",
              "Apr",
              "May",
              "Jun",
              "Jul",
              "Aug",
              "Sep",
              "Oct",
              "Nov",
              "Dec",
            ],
            label: `${setLabel(resultType[i])}`,
            data: [],
          },
        };
        for (let j = 0; j < responseData?.length; j++) {
          if (resultType[i] === responseData[j]?.result_type) {
            graphGroupingData[resultType[i]].data = [
              ...graphGroupingData?.[resultType[i]].data,
              responseData?.[j]?.rev,
            ];
          }
        }
      }
      setGraphData(graphGroupingData);
    } else {
      if (para?.asOfDate) {
        getGraphDetail();
      }
    }
  }, [selectedGraph, para?.asOfDate]);

  return (
    <div>
      <div className="tabComponentMain">
        <Tabs
          defaultActiveKey="REVENUE"
          id="uncontrolled-tab-example"
          variant="underline"
          onSelect={(k) => {
            setSelectedGraph(k);
          }}
        >
          <Tab eventKey="REVENUE" title="Revenue"></Tab>
          <Tab eventKey="RMS" title="RMS"></Tab>
          <Tab eventKey="Occupancy" title="Occupancy"></Tab>
        </Tabs>
      </div>
      {graphData && (
        <div className="ThreeBarAndLineChartWrapper">
          <ThreeBarAndLineChart
            barData1={graphData?.budget}
            barData2={graphData?.net_stly}
            barData3={graphData?.OTB}
            lineData={graphData?.total_ly}
          />
        </div>
      )}
    </div>
  );
};

export default WidgetBudgetGraph;
