import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { apiCall } from "../../../utils/axiosService";
import { API_URL } from "../../../utils/apiRoutes";
import { Button, Col, Form, Overlay, Popover, Table } from "react-bootstrap";
import plusIcon from "../../../Assets/Images/FormCheck/plusIcon.png";
import Loader from "../../Loader/Loader";
import { apiResponse } from "../../../utils/apiResponse";
import Skeleton from "react-loading-skeleton";
import { commonService } from "../../../utils/commonService";
import moment from "moment";
import YieldOccModal from "./YieldOccModal";
import EventViewModal from "../../CommonComponent/EventViewModal";

const Yield = () => {
  const propertyDetailFromRTK = useSelector(
    (store) => store.headerProperty.propertyDetail
  );
  const crmPropertyDetailFromRTK = useSelector(
    (store) => store.headerProperty.crmPropertyDetail
  );
  const [groupModal, setGroupModal] = useState({
    IsDisplay: false,
    Target: null,
    Position: "top",
  });

  const yieldObj = [
    {
      0: {
        0: {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 0,
          minday: 0,
          maxday: 0,
          ratevalue: 0,
        },
        1: {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 0,
          minday: 1,
          maxday: 1,
          ratevalue: 0,
        },
        2: {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 0,
          minday: 2,
          maxday: 2,
          ratevalue: 0,
        },
        "3-7": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 0,
          minday: 3,
          maxday: 7,
          ratevalue: 0,
        },
        "8-15": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 0,
          minday: 8,
          maxday: 15,
          ratevalue: 0,
        },
        "16-30": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 0,
          minday: 16,
          maxday: 30,
          ratevalue: 0,
        },
        "31-60": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 0,
          minday: 31,
          maxday: 60,
          ratevalue: 0,
        },
        "61-90": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 0,
          minday: 61,
          maxday: 90,
          ratevalue: 0,
        },
        "91+": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 0,
          minday: 91,
          maxday: 9999,
          ratevalue: 0,
        },
      },
    },
    {
      5: {
        0: {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 5,
          minday: 0,
          maxday: 0,
          ratevalue: 0,
        },
        1: {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 5,
          minday: 1,
          maxday: 1,
          ratevalue: 0,
        },
        2: {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 5,
          minday: 2,
          maxday: 2,
          ratevalue: 0,
        },
        "3-7": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 5,
          minday: 3,
          maxday: 7,
          ratevalue: 0,
        },
        "8-15": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 5,
          minday: 8,
          maxday: 15,
          ratevalue: 0,
        },
        "16-30": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 5,
          minday: 16,
          maxday: 30,
          ratevalue: 0,
        },
        "31-60": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 5,
          minday: 31,
          maxday: 60,
          ratevalue: 0,
        },
        "61-90": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 5,
          minday: 61,
          maxday: 90,
          ratevalue: 0,
        },
        "91+": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 5,
          minday: 91,
          maxday: 9999,
          ratevalue: 0,
        },
      },
    },
    {
      10: {
        0: {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 10,
          minday: 0,
          maxday: 0,
          ratevalue: 0,
        },
        1: {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 10,
          minday: 1,
          maxday: 1,
          ratevalue: 0,
        },
        2: {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 10,
          minday: 2,
          maxday: 2,
          ratevalue: 0,
        },
        "3-7": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 10,
          minday: 3,
          maxday: 7,
          ratevalue: 0,
        },
        "8-15": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 10,
          minday: 8,
          maxday: 15,
          ratevalue: 0,
        },
        "16-30": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 10,
          minday: 16,
          maxday: 30,
          ratevalue: 0,
        },
        "31-60": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 10,
          minday: 31,
          maxday: 60,
          ratevalue: 0,
        },
        "61-90": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 10,
          minday: 61,
          maxday: 90,
          ratevalue: 0,
        },
        "91+": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 10,
          minday: 91,
          maxday: 9999,
          ratevalue: 0,
        },
      },
    },
    {
      15: {
        0: {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 15,
          minday: 0,
          maxday: 0,
          ratevalue: 0,
        },
        1: {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 15,
          minday: 1,
          maxday: 1,
          ratevalue: 0,
        },
        2: {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 15,
          minday: 2,
          maxday: 2,
          ratevalue: 0,
        },
        "3-7": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 15,
          minday: 3,
          maxday: 7,
          ratevalue: 0,
        },
        "8-15": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 15,
          minday: 8,
          maxday: 15,
          ratevalue: 0,
        },
        "16-30": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 15,
          minday: 16,
          maxday: 30,
          ratevalue: 0,
        },
        "31-60": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 15,
          minday: 31,
          maxday: 60,
          ratevalue: 0,
        },
        "61-90": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 15,
          minday: 61,
          maxday: 90,
          ratevalue: 0,
        },
        "91+": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 15,
          minday: 91,
          maxday: 9999,
          ratevalue: 0,
        },
      },
    },
    {
      20: {
        0: {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 20,
          minday: 0,
          maxday: 0,
          ratevalue: 0,
        },
        1: {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 20,
          minday: 1,
          maxday: 1,
          ratevalue: 0,
        },
        2: {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 20,
          minday: 2,
          maxday: 2,
          ratevalue: 0,
        },
        "3-7": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 20,
          minday: 3,
          maxday: 7,
          ratevalue: 0,
        },
        "8-15": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 20,
          minday: 8,
          maxday: 15,
          ratevalue: 0,
        },
        "16-30": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 20,
          minday: 16,
          maxday: 30,
          ratevalue: 0,
        },
        "31-60": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 20,
          minday: 31,
          maxday: 60,
          ratevalue: 0,
        },
        "61-90": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 20,
          minday: 61,
          maxday: 90,
          ratevalue: 0,
        },
        "91+": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 20,
          minday: 91,
          maxday: 9999,
          ratevalue: 0,
        },
      },
    },
    {
      25: {
        0: {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 25,
          minday: 0,
          maxday: 0,
          ratevalue: 0,
        },
        1: {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 25,
          minday: 1,
          maxday: 1,
          ratevalue: 0,
        },
        2: {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 25,
          minday: 2,
          maxday: 2,
          ratevalue: 0,
        },
        "3-7": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 25,
          minday: 3,
          maxday: 7,
          ratevalue: 0,
        },
        "8-15": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 25,
          minday: 8,
          maxday: 15,
          ratevalue: 0,
        },
        "16-30": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 25,
          minday: 16,
          maxday: 30,
          ratevalue: 0,
        },
        "31-60": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 25,
          minday: 31,
          maxday: 60,
          ratevalue: 0,
        },
        "61-90": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 25,
          minday: 61,
          maxday: 90,
          ratevalue: 0,
        },
        "91+": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 25,
          minday: 91,
          maxday: 9999,
          ratevalue: 0,
        },
      },
    },
    {
      30: {
        0: {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 30,
          minday: 0,
          maxday: 0,
          ratevalue: 0,
        },
        1: {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 30,
          minday: 1,
          maxday: 1,
          ratevalue: 0,
        },
        2: {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 30,
          minday: 2,
          maxday: 2,
          ratevalue: 0,
        },
        "3-7": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 30,
          minday: 3,
          maxday: 7,
          ratevalue: 0,
        },
        "8-15": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 30,
          minday: 8,
          maxday: 15,
          ratevalue: 0,
        },
        "16-30": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 30,
          minday: 16,
          maxday: 30,
          ratevalue: 0,
        },
        "31-60": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 30,
          minday: 31,
          maxday: 60,
          ratevalue: 0,
        },
        "61-90": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 30,
          minday: 61,
          maxday: 90,
          ratevalue: 0,
        },
        "91+": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 30,
          minday: 91,
          maxday: 9999,
          ratevalue: 0,
        },
      },
    },
    {
      35: {
        0: {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 35,
          minday: 0,
          maxday: 0,
          ratevalue: 0,
        },
        1: {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 35,
          minday: 1,
          maxday: 1,
          ratevalue: 0,
        },
        2: {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 35,
          minday: 2,
          maxday: 2,
          ratevalue: 0,
        },
        "3-7": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 35,
          minday: 3,
          maxday: 7,
          ratevalue: 0,
        },
        "8-15": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 35,
          minday: 8,
          maxday: 15,
          ratevalue: 0,
        },
        "16-30": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 35,
          minday: 16,
          maxday: 30,
          ratevalue: 0,
        },
        "31-60": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 35,
          minday: 31,
          maxday: 60,
          ratevalue: 0,
        },
        "61-90": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 35,
          minday: 61,
          maxday: 90,
          ratevalue: 0,
        },
        "91+": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 35,
          minday: 91,
          maxday: 9999,
          ratevalue: 0,
        },
      },
    },
    {
      40: {
        0: {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 40,
          minday: 0,
          maxday: 0,
          ratevalue: 0,
        },
        1: {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 40,
          minday: 1,
          maxday: 1,
          ratevalue: 0,
        },
        2: {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 40,
          minday: 2,
          maxday: 2,
          ratevalue: 0,
        },
        "3-7": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 40,
          minday: 3,
          maxday: 7,
          ratevalue: 0,
        },
        "8-15": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 40,
          minday: 8,
          maxday: 15,
          ratevalue: 0,
        },
        "16-30": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 40,
          minday: 16,
          maxday: 30,
          ratevalue: 0,
        },
        "31-60": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 40,
          minday: 31,
          maxday: 60,
          ratevalue: 0,
        },
        "61-90": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 40,
          minday: 61,
          maxday: 90,
          ratevalue: 0,
        },
        "91+": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 40,
          minday: 91,
          maxday: 9999,
          ratevalue: 0,
        },
      },
    },
    {
      45: {
        0: {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 45,
          minday: 0,
          maxday: 0,
          ratevalue: 0,
        },
        1: {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 45,
          minday: 1,
          maxday: 1,
          ratevalue: 0,
        },
        2: {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 45,
          minday: 2,
          maxday: 2,
          ratevalue: 0,
        },
        "3-7": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 45,
          minday: 3,
          maxday: 7,
          ratevalue: 0,
        },
        "8-15": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 45,
          minday: 8,
          maxday: 15,
          ratevalue: 0,
        },
        "16-30": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 45,
          minday: 16,
          maxday: 30,
          ratevalue: 0,
        },
        "31-60": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 45,
          minday: 31,
          maxday: 60,
          ratevalue: 0,
        },
        "61-90": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 45,
          minday: 61,
          maxday: 90,
          ratevalue: 0,
        },
        "91+": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 45,
          minday: 91,
          maxday: 9999,
          ratevalue: 0,
        },
      },
    },
    {
      50: {
        0: {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 50,
          minday: 0,
          maxday: 0,
          ratevalue: 0,
        },
        1: {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 50,
          minday: 1,
          maxday: 1,
          ratevalue: 0,
        },
        2: {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 50,
          minday: 2,
          maxday: 2,
          ratevalue: 0,
        },
        "3-7": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 50,
          minday: 3,
          maxday: 7,
          ratevalue: 0,
        },
        "8-15": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 50,
          minday: 8,
          maxday: 15,
          ratevalue: 0,
        },
        "16-30": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 50,
          minday: 16,
          maxday: 30,
          ratevalue: 0,
        },
        "31-60": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 50,
          minday: 31,
          maxday: 60,
          ratevalue: 0,
        },
        "61-90": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 50,
          minday: 61,
          maxday: 90,
          ratevalue: 0,
        },
        "91+": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 50,
          minday: 91,
          maxday: 9999,
          ratevalue: 0,
        },
      },
    },
    {
      55: {
        0: {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 55,
          minday: 0,
          maxday: 0,
          ratevalue: 0,
        },
        1: {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 55,
          minday: 1,
          maxday: 1,
          ratevalue: 0,
        },
        2: {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 55,
          minday: 2,
          maxday: 2,
          ratevalue: 0,
        },
        "3-7": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 55,
          minday: 3,
          maxday: 7,
          ratevalue: 0,
        },
        "8-15": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 55,
          minday: 8,
          maxday: 15,
          ratevalue: 0,
        },
        "16-30": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 55,
          minday: 16,
          maxday: 30,
          ratevalue: 0,
        },
        "31-60": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 55,
          minday: 31,
          maxday: 60,
          ratevalue: 0,
        },
        "61-90": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 55,
          minday: 61,
          maxday: 90,
          ratevalue: 0,
        },
        "91+": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 55,
          minday: 91,
          maxday: 9999,
          ratevalue: 0,
        },
      },
    },
    {
      60: {
        0: {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 60,
          minday: 0,
          maxday: 0,
          ratevalue: 0,
        },
        1: {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 60,
          minday: 1,
          maxday: 1,
          ratevalue: 0,
        },
        2: {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 60,
          minday: 2,
          maxday: 2,
          ratevalue: 0,
        },
        "3-7": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 60,
          minday: 3,
          maxday: 7,
          ratevalue: 0,
        },
        "8-15": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 60,
          minday: 8,
          maxday: 15,
          ratevalue: 0,
        },
        "16-30": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 60,
          minday: 16,
          maxday: 30,
          ratevalue: 0,
        },
        "31-60": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 60,
          minday: 31,
          maxday: 60,
          ratevalue: 0,
        },
        "61-90": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 60,
          minday: 61,
          maxday: 90,
          ratevalue: 0,
        },
        "91+": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 60,
          minday: 91,
          maxday: 9999,
          ratevalue: 0,
        },
      },
    },
    {
      65: {
        0: {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 65,
          minday: 0,
          maxday: 0,
          ratevalue: 0,
        },
        1: {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 65,
          minday: 1,
          maxday: 1,
          ratevalue: 0,
        },
        2: {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 65,
          minday: 2,
          maxday: 2,
          ratevalue: 0,
        },
        "3-7": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 65,
          minday: 3,
          maxday: 7,
          ratevalue: 0,
        },
        "8-15": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 65,
          minday: 8,
          maxday: 15,
          ratevalue: 0,
        },
        "16-30": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 65,
          minday: 16,
          maxday: 30,
          ratevalue: 0,
        },
        "31-60": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 65,
          minday: 31,
          maxday: 60,
          ratevalue: 0,
        },
        "61-90": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 65,
          minday: 61,
          maxday: 90,
          ratevalue: 0,
        },
        "91+": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 65,
          minday: 91,
          maxday: 9999,
          ratevalue: 0,
        },
      },
    },
    {
      70: {
        0: {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 70,
          minday: 0,
          maxday: 0,
          ratevalue: 0,
        },
        1: {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 70,
          minday: 1,
          maxday: 1,
          ratevalue: 0,
        },
        2: {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 70,
          minday: 2,
          maxday: 2,
          ratevalue: 0,
        },
        "3-7": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 70,
          minday: 3,
          maxday: 7,
          ratevalue: 0,
        },
        "8-15": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 70,
          minday: 8,
          maxday: 15,
          ratevalue: 0,
        },
        "16-30": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 70,
          minday: 16,
          maxday: 30,
          ratevalue: 0,
        },
        "31-60": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 70,
          minday: 31,
          maxday: 60,
          ratevalue: 0,
        },
        "61-90": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 70,
          minday: 61,
          maxday: 90,
          ratevalue: 0,
        },
        "91+": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 70,
          minday: 91,
          maxday: 9999,
          ratevalue: 0,
        },
      },
    },
    {
      75: {
        0: {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 75,
          minday: 0,
          maxday: 0,
          ratevalue: 0,
        },
        1: {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 75,
          minday: 1,
          maxday: 1,
          ratevalue: 0,
        },
        2: {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 75,
          minday: 2,
          maxday: 2,
          ratevalue: 0,
        },
        "3-7": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 75,
          minday: 3,
          maxday: 7,
          ratevalue: 0,
        },
        "8-15": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 75,
          minday: 8,
          maxday: 15,
          ratevalue: 0,
        },
        "16-30": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 75,
          minday: 16,
          maxday: 30,
          ratevalue: 0,
        },
        "31-60": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 75,
          minday: 31,
          maxday: 60,
          ratevalue: 0,
        },
        "61-90": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 75,
          minday: 61,
          maxday: 90,
          ratevalue: 0,
        },
        "91+": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 75,
          minday: 91,
          maxday: 9999,
          ratevalue: 0,
        },
      },
    },
    {
      80: {
        0: {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 80,
          minday: 0,
          maxday: 0,
          ratevalue: 0,
        },
        1: {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 80,
          minday: 1,
          maxday: 1,
          ratevalue: 0,
        },
        2: {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 80,
          minday: 2,
          maxday: 2,
          ratevalue: 0,
        },
        "3-7": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 80,
          minday: 3,
          maxday: 7,
          ratevalue: 0,
        },
        "8-15": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 80,
          minday: 8,
          maxday: 15,
          ratevalue: 0,
        },
        "16-30": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 80,
          minday: 16,
          maxday: 30,
          ratevalue: 0,
        },
        "31-60": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 80,
          minday: 31,
          maxday: 60,
          ratevalue: 0,
        },
        "61-90": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 80,
          minday: 61,
          maxday: 90,
          ratevalue: 0,
        },
        "91+": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 80,
          minday: 91,
          maxday: 9999,
          ratevalue: 0,
        },
      },
    },
    {
      85: {
        0: {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 85,
          minday: 0,
          maxday: 0,
          ratevalue: 0,
        },
        1: {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 85,
          minday: 1,
          maxday: 1,
          ratevalue: 0,
        },
        2: {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 85,
          minday: 2,
          maxday: 2,
          ratevalue: 0,
        },
        "3-7": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 85,
          minday: 3,
          maxday: 7,
          ratevalue: 0,
        },
        "8-15": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 85,
          minday: 8,
          maxday: 15,
          ratevalue: 0,
        },
        "16-30": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 85,
          minday: 16,
          maxday: 30,
          ratevalue: 0,
        },
        "31-60": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 85,
          minday: 31,
          maxday: 60,
          ratevalue: 0,
        },
        "61-90": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 85,
          minday: 61,
          maxday: 90,
          ratevalue: 0,
        },
        "91+": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 85,
          minday: 91,
          maxday: 9999,
          ratevalue: 0,
        },
      },
    },
    {
      90: {
        0: {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 90,
          minday: 0,
          maxday: 0,
          ratevalue: 0,
        },
        1: {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 90,
          minday: 1,
          maxday: 1,
          ratevalue: 0,
        },
        2: {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 90,
          minday: 2,
          maxday: 2,
          ratevalue: 0,
        },
        "3-7": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 90,
          minday: 3,
          maxday: 7,
          ratevalue: 0,
        },
        "8-15": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 90,
          minday: 8,
          maxday: 15,
          ratevalue: 0,
        },
        "16-30": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 90,
          minday: 16,
          maxday: 30,
          ratevalue: 0,
        },
        "31-60": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 90,
          minday: 31,
          maxday: 60,
          ratevalue: 0,
        },
        "61-90": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 90,
          minday: 61,
          maxday: 90,
          ratevalue: 0,
        },
        "91+": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 90,
          minday: 91,
          maxday: 9999,
          ratevalue: 0,
        },
      },
    },
    {
      95: {
        0: {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 95,
          minday: 0,
          maxday: 0,
          ratevalue: 0,
        },
        1: {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 95,
          minday: 1,
          maxday: 1,
          ratevalue: 0,
        },
        2: {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 95,
          minday: 2,
          maxday: 2,
          ratevalue: 0,
        },
        "3-7": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 95,
          minday: 3,
          maxday: 7,
          ratevalue: 0,
        },
        "8-15": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 95,
          minday: 8,
          maxday: 15,
          ratevalue: 0,
        },
        "16-30": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 95,
          minday: 16,
          maxday: 30,
          ratevalue: 0,
        },
        "31-60": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 95,
          minday: 31,
          maxday: 60,
          ratevalue: 0,
        },
        "61-90": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 95,
          minday: 61,
          maxday: 90,
          ratevalue: 0,
        },
        "91+": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 95,
          minday: 91,
          maxday: 9999,
          ratevalue: 0,
        },
      },
    },
    {
      100: {
        0: {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 100,
          minday: 0,
          maxday: 0,
          ratevalue: 0,
        },
        1: {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 100,
          minday: 1,
          maxday: 1,
          ratevalue: 0,
        },
        2: {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 100,
          minday: 2,
          maxday: 2,
          ratevalue: 0,
        },
        "3-7": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 100,
          minday: 3,
          maxday: 7,
          ratevalue: 0,
        },
        "8-15": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 100,
          minday: 8,
          maxday: 15,
          ratevalue: 0,
        },
        "16-30": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 100,
          minday: 16,
          maxday: 30,
          ratevalue: 0,
        },
        "31-60": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 100,
          minday: 31,
          maxday: 60,
          ratevalue: 0,
        },
        "61-90": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 100,
          minday: 61,
          maxday: 90,
          ratevalue: 0,
        },
        "91+": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 100,
          minday: 91,
          maxday: 9999,
          ratevalue: 0,
        },
      },
    },
    {
      105: {
        0: {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 105,
          minday: 0,
          maxday: 0,
          ratevalue: 0,
        },
        1: {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 105,
          minday: 1,
          maxday: 1,
          ratevalue: 0,
        },
        2: {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 105,
          minday: 2,
          maxday: 2,
          ratevalue: 0,
        },
        "3-7": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 105,
          minday: 3,
          maxday: 7,
          ratevalue: 0,
        },
        "8-15": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 105,
          minday: 8,
          maxday: 15,
          ratevalue: 0,
        },
        "16-30": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 105,
          minday: 16,
          maxday: 30,
          ratevalue: 0,
        },
        "31-60": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 105,
          minday: 31,
          maxday: 60,
          ratevalue: 0,
        },
        "61-90": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 105,
          minday: 61,
          maxday: 90,
          ratevalue: 0,
        },
        "91+": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 105,
          minday: 91,
          maxday: 9999,
          ratevalue: 0,
        },
      },
    },
    {
      110: {
        0: {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 110,
          minday: 0,
          maxday: 0,
          ratevalue: 0,
        },
        1: {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 110,
          minday: 1,
          maxday: 1,
          ratevalue: 0,
        },
        2: {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 110,
          minday: 2,
          maxday: 2,
          ratevalue: 0,
        },
        "3-7": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 110,
          minday: 3,
          maxday: 7,
          ratevalue: 0,
        },
        "8-15": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 110,
          minday: 8,
          maxday: 15,
          ratevalue: 0,
        },
        "16-30": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 110,
          minday: 16,
          maxday: 30,
          ratevalue: 0,
        },
        "31-60": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 110,
          minday: 31,
          maxday: 60,
          ratevalue: 0,
        },
        "61-90": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 110,
          minday: 61,
          maxday: 90,
          ratevalue: 0,
        },
        "91+": {
          rateiqyieldid: 0,
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
          occupancy: 110,
          minday: 91,
          maxday: 9999,
          ratevalue: 0,
        },
      },
    },
  ];

  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [yieldData, setYieldData] = useState(null);
  const [occArrData, setOccArrData] = useState(null);
  const occArrRef = useRef();
  const changesArrRef = useRef([]);
  const yieldDataRef = useRef();
  yieldDataRef.current = yieldData;
  const [yieldOccData, setYieldOccData] = useState(null);
  const yieldOccDataRef = useRef(null);
  const yieldModalDataRef = useRef();
  const eventDataRef = useRef();
  const [showTicketModal, setShowTicketModal] = useState(false);
  const closeTicketModal = () => {
    setShowTicketModal(false);
  };

  const getYieldList = async () => {
    setLoading(true);
    let yieldResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.RevenueManagement +
          API_URL.RATEIQ_YIELD.GET_LIST,
        body: {
          propertyid: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertyid
            : propertyDetailFromRTK?.propertyid,
        },
      },
      false
    );

    let response = apiResponse(false, yieldResponse);

    if (response?.isValidate) {
      let dataFromapi = response?.data?.data;
      let newAddedYield = [],
        existYield = [];

      let occArr = [];

      for (let i = 0; i < dataFromapi?.length; i++) {
        let findOcc = occArr?.filter((x) => x === dataFromapi[i]?.occupancy);

        if (findOcc?.length <= 0) {
          occArr = [...occArr, dataFromapi[i]?.occupancy];
        }
      }

      occArrRef.current = occArr;
      setOccArrData(occArr);
      setYieldData(dataFromapi);
      setLoading(false);
    }

    if (!response?.isValidate) {
      setYieldData(yieldObj);
      setLoading(false);
      let occArr = [
        0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85,
        90, 95, 100, 105, 110,
      ];
      setOccArrData(occArr);
    }
  };

  const getYieldOcc = async () => {
    let yieldOccResponse = await apiCall(
      {
        method: "POST",
        url: API_URL.BASE_API_URL.SnapShot + API_URL.RATEIQ_YIELD.GET_OCCUPANCY,
        body: {
          CurrentDate: commonService.getDateForNode(new Date()),
          propertyCode: window.location.href.includes("/crm")
            ? crmPropertyDetailFromRTK?.propertycode
            : propertyDetailFromRTK?.propertycode,
        },
      },
      false
    );

    let response = apiResponse(false, yieldOccResponse);

    if (response?.isValidate) {
      let dataFromapi = response?.data?.data;

      function findNearestNumber(arr, target) {
        return arr.reduce((prev, curr) => {
          return Math.abs(curr - target) < Math.abs(prev - target)
            ? curr
            : prev;
        });
      }

      let groupingJson = [];
      dataFromapi?.forEach((item) => {
        let nearest = findNearestNumber(
          occArrRef.current,
          item?.Occperc ? parseFloat(item?.Occperc) : 0
        );
        item.nearest = nearest;
        groupingJson.push(item);
      });
      yieldOccDataRef.current = groupingJson;
      setYieldOccData(groupingJson);
    }

    if (!response?.isValidate) {
      yieldOccDataRef.current = null;
      setYieldOccData(null);
    }
  };

  const handleSubmit = async () => {
    setSubmitLoading(true);
    let savePayload = [];

    let findTable = document.getElementById("yieldRateIqTbl");
    let tbodyData = findTable.getElementsByTagName("tbody");
    let trData = tbodyData?.[0]?.children;

    for (let i = 0; i < trData?.length; i++) {
      let tdData = trData[i];
      let occupancyValue = tdData?.children?.[0];
      let occupancyInput = occupancyValue.getElementsByTagName("input");

      for (let j = 1; j < tdData?.children?.length; j++) {
        let rateValue = tdData?.children?.[j];
        let rateInput = rateValue.getElementsByTagName("input");

        savePayload = [
          ...savePayload,
          {
            rateiqyieldid: 0,
            propertyid: window.location.href.includes("/crm")
              ? crmPropertyDetailFromRTK?.propertyid
              : propertyDetailFromRTK?.propertyid,
            occupancy: occupancyInput?.[0]?.value,
            minday: rateInput?.[0]?.attributes?.minday?.value,
            maxday: rateInput?.[0]?.attributes?.maxday?.value,
            ratevalue: rateInput?.[0]?.value,
          },
        ];
      }
    }

    let saveResponse = await apiCall(
      {
        method: "POST",
        url: API_URL.BASE_API_URL.RevenueManagement + API_URL.RATEIQ_YIELD.SAVE,
        body: {
          F: savePayload,
        },
      },
      true
    );

    let response = apiResponse(true, saveResponse, setSubmitLoading);

    if (response?.isValidate) {
    }

    if (!response?.isValidate) {
    }
  };

  const callBothApi = async () => {
    await getYieldList();
    await getYieldOcc();
  };

  useEffect(() => {
    callBothApi();
  }, []);

  const getDateForHeader = (dayCount) => {
    let dateForHeader = new Date();
    return moment(
      new Date().setDate(dateForHeader.getDate() + dayCount)
    ).format("MM/DD");
  };

  const handleGroupBtnClick = (event) => {
    let Position = "bottom";
    let Top = event.nativeEvent.layerY - 2;
    if (Top + 400 > window.innerHeight) {
      Position = "top";
    }
    setGroupModal({
      IsDisplay: true,
      Target: event.target,
      Position: Position,
    });
  };

  const closeGroupDate = () => {
    setGroupModal({
      IsDisplay: false,
      Target: null,
      Position: "bottom",
    });
  };

  const handleEventClickFromPopover = (data) => {
    eventDataRef.current = data;
    setShowTicketModal(true);
    closeGroupDate();
  };

  return (
    <>
      <div>
        <Col xl={12}>
          <div className="table__container">
            <div className="stripped__table">
              <div className="table-responsive maxHeight400">
                <Table id="yieldRateIqTbl">
                  <thead className="tbl-header-sticky my-tbl-header-sticky">
                    <tr>
                      <th>Days</th>
                      <th>0</th>
                      <th>1</th>
                      <th>2</th>
                      <th>3-7</th>
                      <th>8-15</th>
                      <th>16-30</th>
                      <th>31-60</th>
                      <th>61-90</th>
                      <th>91+</th> 
                    </tr>
                    <tr>
                      <th>Occupancy (%)</th>
                      {/* <th>0</th>
                      <th>1</th>
                      <th>2</th>
                      <th>3-7</th>
                      <th>8-15</th>
                      <th>16-30</th>
                      <th>31-60</th>
                      <th>61-90</th>
                      <th>91+</th> */}
                      <th>{getDateForHeader(0)}</th>
                      <th>{getDateForHeader(1)}</th>
                      <th>{getDateForHeader(2)}</th>
                      <th>
                        {getDateForHeader(3)}-{getDateForHeader(7)}
                      </th>
                      <th>
                        {getDateForHeader(8)}-{getDateForHeader(15)}
                      </th>
                      <th>
                        {getDateForHeader(16)}-{getDateForHeader(30)}
                      </th>
                      <th>
                        {getDateForHeader(31)}-{getDateForHeader(60)}
                      </th>
                      <th>
                        {getDateForHeader(61)}-{getDateForHeader(90)}
                      </th>
                      <th>{getDateForHeader(91)} +</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <tr>
                        <td>
                          <Skeleton count={5} />
                        </td>
                        <td>
                          <Skeleton count={5} />
                        </td>
                        <td>
                          <Skeleton count={5} />
                        </td>
                        <td>
                          <Skeleton count={5} />
                        </td>
                        <td>
                          <Skeleton count={5} />
                        </td>
                        <td>
                          <Skeleton count={5} />
                        </td>
                        <td>
                          <Skeleton count={5} />
                        </td>
                        <td>
                          <Skeleton count={5} />
                        </td>
                        <td>
                          <Skeleton count={5} />
                        </td>
                        <td>
                          <Skeleton count={5} />
                        </td>
                      </tr>
                    ) : (
                      occArrData?.map((item, index) => {
                        let zeroOccData = yieldData?.filter(
                          (x) =>
                            x?.occupancy == item &&
                            x?.minday == 0 &&
                            x?.maxday == 0
                        );
                        let oneOccData = yieldData?.filter(
                          (x) =>
                            x?.occupancy == item &&
                            x?.minday == 1 &&
                            x?.maxday == 1
                        );
                        let twoOccData = yieldData?.filter(
                          (x) =>
                            x?.occupancy == item &&
                            x?.minday == 2 &&
                            x?.maxday == 2
                        );
                        let thirdOccData = yieldData?.filter(
                          (x) =>
                            x?.occupancy == item &&
                            x?.minday == 3 &&
                            x?.maxday == 7
                        );
                        let fourhtOccData = yieldData?.filter(
                          (x) =>
                            x?.occupancy == item &&
                            x?.minday == 8 &&
                            x?.maxday == 15
                        );
                        let fifthOccData = yieldData?.filter(
                          (x) =>
                            x?.occupancy == item &&
                            x?.minday == 16 &&
                            x?.maxday == 30
                        );
                        let sixthOccData = yieldData?.filter(
                          (x) =>
                            x?.occupancy == item &&
                            x?.minday == 31 &&
                            x?.maxday == 60
                        );
                        let sevenOccData = yieldData?.filter(
                          (x) =>
                            x?.occupancy == item &&
                            x?.minday == 61 &&
                            x?.maxday == 90
                        );
                        let eightOccData = yieldData?.filter(
                          (x) =>
                            x?.occupancy == item &&
                            x?.minday == 91 &&
                            x?.maxday == 9999
                        );

                        let zeroYieldOccData = yieldOccDataRef.current?.filter(
                          (x) => x?.nearest == item && x?.step === "0"
                        );
                        let oneYieldOccData = yieldOccDataRef.current?.filter(
                          (x) => x?.nearest == item && x?.step === "1"
                        );
                        let twoYieldOccData = yieldOccDataRef.current?.filter(
                          (x) => x?.nearest == item && x?.step === "2"
                        );
                        let thirdYieldOccData = yieldOccDataRef.current?.filter(
                          (x) => x?.nearest == item && x?.step === "3-7"
                        );
                        let fourthYieldOccData =
                          yieldOccDataRef.current?.filter(
                            (x) => x?.nearest == item && x?.step === "8-15"
                          );
                        let fifthYieldOccData = yieldOccDataRef.current?.filter(
                          (x) => x?.nearest == item && x?.step === "16-30"
                        );
                        let sixthYieldOccData = yieldOccDataRef.current?.filter(
                          (x) => x?.nearest == item && x?.step === "31-60"
                        );
                        let sevenYieldOccData = yieldOccDataRef.current?.filter(
                          (x) => x?.nearest == item && x?.step === "61-90"
                        );
                        let eightYieldOccData = yieldOccDataRef.current?.filter(
                          (x) => x?.nearest == item && x?.step === "91"
                        );
                        return (
                          <tr className="shadowOnHover">
                            <td className="p-1">
                              <Form.Control
                                type="text"
                                className="py-0 px-2"
                                maxLength={6}
                                defaultValue={item}
                                onChange={(e) => {
                                  let copyOccArr = [...occArrData];

                                  let prevValue = copyOccArr[index];

                                  let updatedData = yieldData?.filter(
                                    (x) => x?.occupancy == prevValue
                                  );

                                  updatedData?.map((i, ind) => {
                                    // handleChangesData(i);
                                  });

                                  copyOccArr[index] = e.target.value;
                                  // setOccArrData(copyOccArr);
                                }}
                              />
                            </td>

                            <td className="p-1">
                              <div className="d-flex gap-1">
                                <Form.Control
                                  type="text"
                                  className="py-0 px-2"
                                  maxLength={6}
                                  minday={0}
                                  maxday={0}
                                  defaultValue={
                                    zeroOccData?.[0]?.ratevalue
                                      ? zeroOccData?.[0]?.ratevalue
                                      : 0
                                  }
                                  onChange={(e) => {}}
                                />
                                {zeroYieldOccData?.[0]?.Occperc && (
                                  <span
                                    onClick={(e) => {
                                      yieldModalDataRef.current =
                                        zeroYieldOccData;
                                      handleGroupBtnClick(e);
                                    }}
                                    className="cursorPointer fontBold"
                                  >
                                    ...
                                  </span>
                                )}
                              </div>
                            </td>
                            <td className="p-1">
                              <div className="d-flex gap-1">
                                <Form.Control
                                  type="text"
                                  className="py-0 px-2"
                                  maxLength={6}
                                  minday={1}
                                  maxday={1}
                                  defaultValue={
                                    oneOccData?.[0]?.ratevalue
                                      ? oneOccData?.[0]?.ratevalue
                                      : 0
                                  }
                                  onChange={(e) => {}}
                                />
                                {oneYieldOccData?.[0]?.Occperc && (
                                  <span
                                    onClick={(e) => {
                                      yieldModalDataRef.current =
                                        oneYieldOccData;
                                      handleGroupBtnClick(e);
                                    }}
                                    className="cursorPointer fontBold"
                                  >
                                    ...
                                  </span>
                                )}
                              </div>
                            </td>
                            <td className="p-1">
                              <div className="d-flex gap-1">
                                <Form.Control
                                  type="text"
                                  className="py-0 px-2"
                                  maxLength={6}
                                  minday={2}
                                  maxday={2}
                                  defaultValue={
                                    twoOccData?.[0]?.ratevalue
                                      ? twoOccData?.[0]?.ratevalue
                                      : 0
                                  }
                                  onChange={(e) => {}}
                                />
                                {twoYieldOccData?.[0]?.Occperc && (
                                  <span
                                    onClick={(e) => {
                                      yieldModalDataRef.current =
                                        twoYieldOccData;
                                      handleGroupBtnClick(e);
                                    }}
                                    className="cursorPointer fontBold"
                                  >
                                    ...
                                  </span>
                                )}
                              </div>
                            </td>
                            <td className="p-1">
                              <div className="d-flex gap-1">
                                <Form.Control
                                  type="text"
                                  className="py-0 px-2"
                                  maxLength={6}
                                  minday={3}
                                  maxday={7}
                                  defaultValue={
                                    thirdOccData?.[0]?.ratevalue
                                      ? thirdOccData?.[0]?.ratevalue
                                      : 0
                                  }
                                  onChange={(e) => {}}
                                />
                                {thirdYieldOccData?.[0]?.Occperc && (
                                  <span
                                    onClick={(e) => {
                                      yieldModalDataRef.current =
                                        thirdYieldOccData;
                                      handleGroupBtnClick(e);
                                    }}
                                    className="cursorPointer fontBold"
                                  >
                                    ...
                                  </span>
                                )}
                              </div>
                            </td>
                            <td className="p-1">
                              <div className="d-flex gap-1">
                                <Form.Control
                                  type="text"
                                  className="py-0 px-2"
                                  maxLength={6}
                                  minday={8}
                                  maxday={15}
                                  defaultValue={
                                    fourhtOccData?.[0]?.ratevalue
                                      ? fourhtOccData?.[0]?.ratevalue
                                      : 0
                                  }
                                  onChange={(e) => {}}
                                />
                                {fourthYieldOccData?.[0]?.Occperc && (
                                  <span
                                    onClick={(e) => {
                                      yieldModalDataRef.current =
                                        fourthYieldOccData;
                                      handleGroupBtnClick(e);
                                    }}
                                    className="cursorPointer fontBold"
                                  >
                                    ...
                                  </span>
                                )}
                              </div>
                            </td>
                            <td className="p-1">
                              <div className="d-flex gap-1">
                                <Form.Control
                                  type="text"
                                  className="py-0 px-2"
                                  maxLength={6}
                                  minday={16}
                                  maxday={30}
                                  defaultValue={
                                    fifthOccData?.[0]?.ratevalue
                                      ? fifthOccData?.[0]?.ratevalue
                                      : 0
                                  }
                                  onChange={(e) => {}}
                                />
                                {fifthYieldOccData?.[0]?.Occperc && (
                                  <span
                                    onClick={(e) => {
                                      yieldModalDataRef.current =
                                        fifthYieldOccData;
                                      handleGroupBtnClick(e);
                                    }}
                                    className="cursorPointer fontBold"
                                  >
                                    ...
                                  </span>
                                )}
                              </div>
                            </td>
                            <td className="p-1">
                              <div className="d-flex gap-1">
                                <Form.Control
                                  type="text"
                                  className="py-0 px-2"
                                  maxLength={6}
                                  minday={31}
                                  maxday={60}
                                  defaultValue={
                                    sixthOccData?.[0]?.ratevalue
                                      ? sixthOccData?.[0]?.ratevalue
                                      : 0
                                  }
                                  onChange={(e) => {}}
                                />
                                {sixthYieldOccData?.[0]?.Occperc && (
                                  <span
                                    onClick={(e) => {
                                      yieldModalDataRef.current =
                                        sixthYieldOccData;
                                      handleGroupBtnClick(e);
                                    }}
                                    className="cursorPointer fontBold"
                                  >
                                    ...
                                  </span>
                                )}
                              </div>
                            </td>
                            <td className="p-1">
                              <div className="d-flex gap-1">
                                <Form.Control
                                  type="text"
                                  className="py-0 px-2"
                                  maxLength={6}
                                  minday={61}
                                  maxday={90}
                                  defaultValue={
                                    sevenOccData?.[0]?.ratevalue
                                      ? sevenOccData?.[0]?.ratevalue
                                      : 0
                                  }
                                  onChange={(e) => {}}
                                />
                                {sevenYieldOccData?.[0]?.Occperc && (
                                  <span
                                    onClick={(e) => {
                                      yieldModalDataRef.current =
                                        sevenYieldOccData;
                                      handleGroupBtnClick(e);
                                    }}
                                    className="cursorPointer fontBold"
                                  >
                                    ...
                                  </span>
                                )}
                              </div>
                            </td>
                            <td className="p-1">
                              <div className="d-flex gap-1">
                                <Form.Control
                                  type="text"
                                  className="py-0 px-2"
                                  maxLength={6}
                                  minday={91}
                                  maxday={9999}
                                  defaultValue={
                                    eightOccData?.[0]?.ratevalue
                                      ? eightOccData?.[0]?.ratevalue
                                      : 0
                                  }
                                  onChange={(e) => {}}
                                />
                                {eightYieldOccData?.[0]?.Occperc && (
                                  <span
                                    onClick={(e) => {
                                      yieldModalDataRef.current =
                                        eightYieldOccData;
                                      handleGroupBtnClick(e);
                                    }}
                                    className="cursorPointer fontBold"
                                  >
                                    ...
                                  </span>
                                )}
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </Col>
      </div>
      <div className="rate-iq-setting-modal footer-buttons pt-2 gap-2 ">
        {submitLoading ? (
          <button className="default-btn primary-btn" type="button" disabled>
            <span
              className="spinner-border spinner-border-sm me-3"
              role="status"
              aria-hidden="true"
            ></span>
            Loading...
          </button>
        ) : (
          <button
            className="default-btn primary-btn"
            onClick={() => {
              handleSubmit();
            }}
          >
            Submit
          </button>
        )}
      </div>
      <Overlay
        show={groupModal.IsDisplay}
        target={groupModal.Target}
        placement={groupModal.Position}
      >
        <Popover
          id="popover-contained"
          className="SingleDateSummaryModalPopoverMain"
        >
          <div className="horizontal-menu-option active singleDayGroupSummary">
            <YieldOccModal
              closeModal={closeGroupDate}
              data={yieldModalDataRef.current}
              handleEventClick={handleEventClickFromPopover}
            />
          </div>
        </Popover>
      </Overlay>
      {showTicketModal && (
        <EventViewModal
          showModal={showTicketModal}
          closeModal={closeTicketModal}
          eventDetails={eventDataRef.current?.eventDetails}
          selectedDate={eventDataRef.current?.selectedDate}
          getEventList={eventDataRef.current?.getEventList}
        />
      )}
    </>
  );
};

export default Yield;
