import React from "react";
import ThirtyDaySegmentChild from "./ThirtyDaySegmentChild";
import SixtyDaySegmentChild from "./SixtyDaySegmentChild";
import NinentyDaySegmentChild from "./NinentyDaySegmentChild";

const NinentyDaySegment = ({
  setCompDataFromChild,
  rowIndex,
  colIndex,
  api,
  para,
  setting,
}) => {
  return (
    <div className="widget-container">
      <ThirtyDaySegmentChild api={api} para={para} />
      <SixtyDaySegmentChild api={api} para={para} />
      <NinentyDaySegmentChild api={api} para={para} />
    </div>
  );
};

export default NinentyDaySegment;
