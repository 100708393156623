import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import "datatables.net";
import { Routes, Route } from "react-router-dom";
import Calendar from "./Component/Configuration/Calendar";
import Loader from "./Component/Loader/Loader";
import Layout from "./Component/Layout";
import UpdatePassword from "./Component/Authentication/UpdatePassword";
const LogIn = React.lazy(() => import("./Component/Authentication/LogIn"));
const Element = React.lazy(() => import("./Pages/View/Element"));
const LogInSwitch = React.lazy(() => import("./Component/Authentication/LogInSwitch"));

function App() {
    return (
        <Routes>
            <Route
                path="/element"
                element={
                    <React.Suspense fallback={<Loader />}>
                        <Element />
                    </React.Suspense>
                }
            />
            <Route
                path="/"
                element={
                    <React.Suspense fallback={<Loader />}>
                        <LogIn />
                    </React.Suspense>
                }
            />
            <Route
                path="/updatePassword"
                element={
                    <React.Suspense fallback={<Loader />}>
                        <UpdatePassword />
                    </React.Suspense>
                }
            />
            <Route
                path="/loginswitch"
                element={
                    <React.Suspense fallback={<Loader />}>
                        <LogInSwitch />
                    </React.Suspense>
                }
            />
            <Route path="/*" element={<Layout />} />
        </Routes>

    );
}

export default App;
