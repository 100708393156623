import React from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";

const ViewWidgetNoteModal = ({ noteData, showModal, closeModal }) => {
  return (
    <>
      <Modal
        className="DeviceDetailModalMain"
        size="lg"
        centered
        show={showModal}
        onHide={closeModal}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {`${noteData?.widgetname} (${noteData?.propertyname})`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="widgetDetailModal">
          <Container fluid>
            <Row>
              {/* <Col xl={6}>
                <p className="todoDetail">
                  <b>Title :</b> <br />
                  {noteData?.title}
                </p>
              </Col>
              <Col xl={6}>
                <p className="todoDetail mt-2">
                  <b>Property Name :</b> <br />
                  {noteData?.propertyname}
                </p>
              </Col>
              <Col xl={6}>
                <p className="todoDetail mt-2">
                  <b>Widget Name :</b> <br />
                  {noteData?.widgetname}
                </p>
              </Col> */}
              <Col xl={12}>
                <p className="todoDetail mt-2">
                  <b>Note :</b> <br />
                  <div
                    dangerouslySetInnerHTML={{ __html: noteData?.widgetnotes }}
                  />
                </p>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ViewWidgetNoteModal;
