import React, { useEffect, useRef, useState } from "react";
import { commonService } from "../../../../utils/commonService";
import { apiCall } from "../../../../utils/axiosService";
import { apiResponse } from "../../../../utils/apiResponse";
import { Button, Form, Table } from "react-bootstrap";
import NoteIcon from "../../../../Assets/Images/CRM/notes.svg";
import downloadIcon from "../../../../Assets/Images/Operation/downloadIcon.svg";
import Loader from "../../../Loader/Loader";
import WidgetDetailModal from "../../WidgetDetailModal";
import { compName, month } from "../../../../utils/constant";
import { API_URL } from "../../../../utils/apiRoutes";
import infoIcon from "../../../../Assets/Images/Action Icons/infoIcon..svg";
import { useSelector } from "react-redux";
import WidgetNote from "../../WidgetNote";
import AddWidgetModal from "../../AddWidgetModal";
import { useParams } from "react-router-dom";
import emailIcon from "../../../../Assets/Images/Email.svg";
import Comments from "../Comments/Comment";
import moment from "moment";

const MonthlyCancellationPace = ({
  setCompDataFromChild,
  rowIndex,
  colIndex,
  api,
  para,
  setting,
}) => {
  const yearsArr = [
    ...commonService.getPastYearForDropDown(10),
    ...commonService.getFutureYearForDropDown(10),
    new Date().getFullYear(),
  ]?.sort(function (a, b) {
    return a - b;
  });
  const tableData = [
    {
      MonthName: "april",
      CP0: "0",
      CP1: "0",
      CP2TO7: "0",
      CP8TO15: "0",
      CP16TO30: "0",
      CP31TO60: "0",
      CP61TO90: "0",
      CP91TOUP: "0",
      CP0_REV: "0",
      CP1_REV: "0",
      CP2TO7_REV: "0",
      CP8TO15_REV: "0",
      CP16TO30_REV: "0",
      CP31TO60_REV: "0",
      CP61TO90_REV: "0",
      CP91TOUP_REV: "0",
      CP0_ADR: "0",
      CP1_ADR: "82",
      CP2TO7_ADR: "0",
      CP8TO15_ADR: "0",
      CP16TO30_ADR: "0",
      CP31TO60_ADR: "0",
      CP61TO90_ADR: "0",
      CP91TOUP_ADR: "0",
    },
    {
      MonthName: "august",
      CP0: "0",
      CP1: "0",
      CP2TO7: "0",
      CP8TO15: "0",
      CP16TO30: "0",
      CP31TO60: "0",
      CP61TO90: "0",
      CP91TOUP: "0",
      CP0_REV: "0",
      CP1_REV: "0",
      CP2TO7_REV: "0",
      CP8TO15_REV: "0",
      CP16TO30_REV: "0",
      CP31TO60_REV: "0",
      CP61TO90_REV: "0",
      CP91TOUP_REV: "0",
      CP0_ADR: "0",
      CP1_ADR: "82",
      CP2TO7_ADR: "0",
      CP8TO15_ADR: "0",
      CP16TO30_ADR: "0",
      CP31TO60_ADR: "0",
      CP61TO90_ADR: "0",
      CP91TOUP_ADR: "0",
    },
    {
      MonthName: "december",
      CP0: "0",
      CP1: "0",
      CP2TO7: "0",
      CP8TO15: "0",
      CP16TO30: "0",
      CP31TO60: "0",
      CP61TO90: "0",
      CP91TOUP: "0",
      CP0_REV: "0",
      CP1_REV: "0",
      CP2TO7_REV: "0",
      CP8TO15_REV: "0",
      CP16TO30_REV: "0",
      CP31TO60_REV: "0",
      CP61TO90_REV: "0",
      CP91TOUP_REV: "0",
      CP0_ADR: "0",
      CP1_ADR: "82",
      CP2TO7_ADR: "0",
      CP8TO15_ADR: "0",
      CP16TO30_ADR: "0",
      CP31TO60_ADR: "0",
      CP61TO90_ADR: "0",
      CP91TOUP_ADR: "0",
    },
    {
      MonthName: "february",
      CP0: "0",
      CP1: "0",
      CP2TO7: "0",
      CP8TO15: "0",
      CP16TO30: "0",
      CP31TO60: "0",
      CP61TO90: "0",
      CP91TOUP: "0",
      CP0_REV: "0",
      CP1_REV: "0",
      CP2TO7_REV: "0",
      CP8TO15_REV: "0",
      CP16TO30_REV: "0",
      CP31TO60_REV: "0",
      CP61TO90_REV: "0",
      CP91TOUP_REV: "0",
      CP0_ADR: "0",
      CP1_ADR: "82",
      CP2TO7_ADR: "0",
      CP8TO15_ADR: "0",
      CP16TO30_ADR: "0",
      CP31TO60_ADR: "0",
      CP61TO90_ADR: "0",
      CP91TOUP_ADR: "0",
    },
    {
      MonthName: "january",
      CP0: "0",
      CP1: "0",
      CP2TO7: "0",
      CP8TO15: "0",
      CP16TO30: "0",
      CP31TO60: "0",
      CP61TO90: "0",
      CP91TOUP: "0",
      CP0_REV: "0",
      CP1_REV: "0",
      CP2TO7_REV: "0",
      CP8TO15_REV: "0",
      CP16TO30_REV: "0",
      CP31TO60_REV: "0",
      CP61TO90_REV: "0",
      CP91TOUP_REV: "0",
      CP0_ADR: "0",
      CP1_ADR: "82",
      CP2TO7_ADR: "0",
      CP8TO15_ADR: "0",
      CP16TO30_ADR: "0",
      CP31TO60_ADR: "0",
      CP61TO90_ADR: "0",
      CP91TOUP_ADR: "0",
    },
    {
      MonthName: "july",
      CP0: "0",
      CP1: "0",
      CP2TO7: "0",
      CP8TO15: "0",
      CP16TO30: "0",
      CP31TO60: "0",
      CP61TO90: "0",
      CP91TOUP: "0",
      CP0_REV: "0",
      CP1_REV: "0",
      CP2TO7_REV: "0",
      CP8TO15_REV: "0",
      CP16TO30_REV: "0",
      CP31TO60_REV: "0",
      CP61TO90_REV: "0",
      CP91TOUP_REV: "0",
      CP0_ADR: "0",
      CP1_ADR: "82",
      CP2TO7_ADR: "0",
      CP8TO15_ADR: "0",
      CP16TO30_ADR: "0",
      CP31TO60_ADR: "0",
      CP61TO90_ADR: "0",
      CP91TOUP_ADR: "0",
    },
    {
      MonthName: "june",
      CP0: "0",
      CP1: "0",
      CP2TO7: "0",
      CP8TO15: "0",
      CP16TO30: "0",
      CP31TO60: "0",
      CP61TO90: "0",
      CP91TOUP: "0",
      CP0_REV: "0",
      CP1_REV: "0",
      CP2TO7_REV: "0",
      CP8TO15_REV: "0",
      CP16TO30_REV: "0",
      CP31TO60_REV: "0",
      CP61TO90_REV: "0",
      CP91TOUP_REV: "0",
      CP0_ADR: "0",
      CP1_ADR: "82",
      CP2TO7_ADR: "0",
      CP8TO15_ADR: "0",
      CP16TO30_ADR: "0",
      CP31TO60_ADR: "0",
      CP61TO90_ADR: "0",
      CP91TOUP_ADR: "0",
    },
    {
      MonthName: "march",
      CP0: "0",
      CP1: "0",
      CP2TO7: "0",
      CP8TO15: "0",
      CP16TO30: "0",
      CP31TO60: "0",
      CP61TO90: "0",
      CP91TOUP: "0",
      CP0_REV: "0",
      CP1_REV: "0",
      CP2TO7_REV: "0",
      CP8TO15_REV: "0",
      CP16TO30_REV: "0",
      CP31TO60_REV: "0",
      CP61TO90_REV: "0",
      CP91TOUP_REV: "0",
      CP0_ADR: "0",
      CP1_ADR: "82",
      CP2TO7_ADR: "0",
      CP8TO15_ADR: "0",
      CP16TO30_ADR: "0",
      CP31TO60_ADR: "0",
      CP61TO90_ADR: "0",
      CP91TOUP_ADR: "0",
    },
    {
      MonthName: "may",
      CP0: "0",
      CP1: "0",
      CP2TO7: "0",
      CP8TO15: "0",
      CP16TO30: "0",
      CP31TO60: "0",
      CP61TO90: "0",
      CP91TOUP: "0",
      CP0_REV: "0",
      CP1_REV: "0",
      CP2TO7_REV: "0",
      CP8TO15_REV: "0",
      CP16TO30_REV: "0",
      CP31TO60_REV: "0",
      CP61TO90_REV: "0",
      CP91TOUP_REV: "0",
      CP0_ADR: "0",
      CP1_ADR: "82",
      CP2TO7_ADR: "0",
      CP8TO15_ADR: "0",
      CP16TO30_ADR: "0",
      CP31TO60_ADR: "0",
      CP61TO90_ADR: "0",
      CP91TOUP_ADR: "0",
    },
    {
      MonthName: "november",
      CP0: "0",
      CP1: "0",
      CP2TO7: "0",
      CP8TO15: "0",
      CP16TO30: "0",
      CP31TO60: "0",
      CP61TO90: "0",
      CP91TOUP: "0",
      CP0_REV: "0",
      CP1_REV: "0",
      CP2TO7_REV: "0",
      CP8TO15_REV: "0",
      CP16TO30_REV: "0",
      CP31TO60_REV: "0",
      CP61TO90_REV: "0",
      CP91TOUP_REV: "0",
      CP0_ADR: "0",
      CP1_ADR: "82",
      CP2TO7_ADR: "0",
      CP8TO15_ADR: "0",
      CP16TO30_ADR: "0",
      CP31TO60_ADR: "0",
      CP61TO90_ADR: "0",
      CP91TOUP_ADR: "0",
    },
    {
      MonthName: "october",
      CP0: "0",
      CP1: "0",
      CP2TO7: "0",
      CP8TO15: "0",
      CP16TO30: "0",
      CP31TO60: "0",
      CP61TO90: "0",
      CP91TOUP: "0",
      CP0_REV: "0",
      CP1_REV: "0",
      CP2TO7_REV: "0",
      CP8TO15_REV: "0",
      CP16TO30_REV: "0",
      CP31TO60_REV: "0",
      CP61TO90_REV: "0",
      CP91TOUP_REV: "0",
      CP0_ADR: "0",
      CP1_ADR: "82",
      CP2TO7_ADR: "0",
      CP8TO15_ADR: "0",
      CP16TO30_ADR: "0",
      CP31TO60_ADR: "0",
      CP61TO90_ADR: "0",
      CP91TOUP_ADR: "0",
    },
    {
      MonthName: "september",
      CP0: "0",
      CP1: "0",
      CP2TO7: "0",
      CP8TO15: "0",
      CP16TO30: "0",
      CP31TO60: "0",
      CP61TO90: "0",
      CP91TOUP: "0",
      CP0_REV: "0",
      CP1_REV: "0",
      CP2TO7_REV: "0",
      CP8TO15_REV: "0",
      CP16TO30_REV: "0",
      CP31TO60_REV: "0",
      CP61TO90_REV: "0",
      CP91TOUP_REV: "0",
      CP0_ADR: "0",
      CP1_ADR: "82",
      CP2TO7_ADR: "0",
      CP8TO15_ADR: "0",
      CP16TO30_ADR: "0",
      CP31TO60_ADR: "0",
      CP61TO90_ADR: "0",
      CP91TOUP_ADR: "0",
    },
  ];

  let total = {
    CP0: "0 Day",
    CP1: "1 Day",
    CP2TO7: "2 to 7 Days",
    CP8TO15: "8 to 15 Days",
    CP16TO30: "16 to 30 Days",
    CP31TO60: "31 to 60 Days",
    CP61TO90: "61 to 90 Days",
    CP91TOUP: "90+ Days",
  };

  let CP0 = 0,
    CP0_ADR = 0,
    CP0_ADR_COUNT = 0,
    CP0_REV = 0,
    CP1 = 0,
    CP1_ADR = 0,
    CP1_ADR_COUNT = 0,
    CP1_REV = 0,
    CP2TO7 = 0,
    CP2TO7_ADR = 0,
    CP2TO7_ADR_COUNT = 0,
    CP2TO7_REV = 0,
    CP8TO15 = 0,
    CP8TO15_ADR = 0,
    CP8TO15_ADR_COUNT = 0,
    CP8TO15_REV = 0,
    CP16TO30 = 0,
    CP16TO30_ADR = 0,
    CP16TO30_ADR_COUNT = 0,
    CP16TO30_REV = 0,
    CP31TO60 = 0,
    CP31TO60_ADR = 0,
    CP31TO60_ADR_COUNT = 0,
    CP31TO60_REV = 0,
    CP61TO90 = 0,
    CP61TO90_ADR = 0,
    CP61TO90_ADR_COUNT = 0,
    CP61TO90_REV = 0,
    CP91TOUP = 0,
    CP91TOUP_ADR = 0,
    CP91TOUP_ADR_COUNT = 0,
    CP91TOUP_REV = 0;

  const [loading, setLoading] = useState(false);
  const [monthlyCancellationData, setMonthlyCancellationData] = useState(null);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [widgetModal, setWidgetModal] = useState(false);
  const widgetDetailRef = useRef();
  const [showNoteSidebar, setShowNoteSidebar] = useState(false);
  const propertyDetailFromRTK = useSelector(
    (store) => store.headerProperty.propertyDetail
  );
  const defaultAsOfDateFromRTK = useSelector(
    (store) => store.headerProperty.defaultAsOfDate
  );
  const { snapshottitle, propertyCode, snapshotId } = useParams();
  const closeNoteSideBar = () => {
    setShowNoteSidebar(false);
  };

  const closeWidgetModal = () => {
    setWidgetModal(false);
  };

  const getMonthlyCancellationData = async () => {
    setLoading(true);
    let monthlyCancellationResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.SnapShot +
          API_URL.CANCELLATION.MONTHLY_CANCELLATION_PACE,
        body: {
          propertyCode: para?.propertyCode,
          AsOfDate: para?.asOfDate,
          year: selectedYear,
        },
      },
      false
    );

    let response = apiResponse(false, monthlyCancellationResponse);

    if (response?.isValidate) {
      setMonthlyCancellationData(response?.data?.data);
      setLoading(false);
    }

    if (!response?.isValidate) {
      setMonthlyCancellationData(tableData);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (api === 0) {
    } else {
      if (para?.asOfDate) {
        getMonthlyCancellationData();
      }
    }
  }, [para?.asOfDate, selectedYear]);

  const [tblids, settblids] = useState({
    monthlyCancellationSummary: commonService.GenerateGUID(),
  });

  return (
    <div className="widget-container">
      {api !== 0 && (
        <div className="gap-1 widget-container-menu">
          {/* <Button className="heading-menu-btns" type="button">
            <img
              src={NoteIcon}
              alt="menuIcon"
              onClick={() => {
                setShowNoteSidebar(true);
              }}
            />
          </Button> */}
          <Button className="heading-menu-btns" type="button" title="Send Mail">
            <img
              src={emailIcon}
              alt="emailIcon"
              onClick={() => {
                commonService.fnSendWidgetMail(
                  tblids.monthlyCancellationSummary,
                  "Monthly Cancellation Summary",
                  snapshotId,
                  defaultAsOfDateFromRTK,
                  "",
                  para?.widgetId
                );
              }}
            />
          </Button>
          <Button className="heading-menu-btns" type="button">
            <img
              src={infoIcon}
              alt="info"
              onClick={() => {
                widgetDetailRef.current = {
                  widgetdetails: "",
                  usernote: "",
                  compName: compName?.monthlyCancellationPace,
                  widgetId: para?.widgetId,
                };
                setWidgetModal(true);
              }}
            />
          </Button>
          <Button className="heading-menu-btns" type="button">
            <img
              src={downloadIcon}
              alt="menuIcon"
              import={tblids.monthlyCancellationSummary}
              onClick={() =>
                commonService.fnExportInCSV(
                  tblids.monthlyCancellationSummary,
                  // "Monthly Cancellation Summary"
                  `${para?.propertyCode}-Monthly Cancellation Summary-${moment(
                    new Date()
                  ).format("MMDDYYYY")}`
                )
              }
            />
          </Button>
        </div>
      )}
      <div className="ViewPropertySetupTable">
        <div className="stripped__table">
          <Table id={tblids.monthlyCancellationSummary} responsive>
            <thead>
              <tr>
                <th className="text-center" colSpan={25}>
                  MONTHLY CANCELLATION Window
                </th>
              </tr>
              <tr>
                <th className="border_right width75">
                  <div>
                    <Form.Select
                      value={selectedYear}
                      onChange={(e) => {
                        setSelectedYear(e.target.value);
                      }}
                    >
                      {yearsArr?.map((item, index) => (
                        <option key={index} value={item}>
                          {item}
                        </option>
                      ))}
                    </Form.Select>
                    <span style={{ display: "none" }}>{selectedYear}</span>
                  </div>
                </th>
                <th colSpan={3} className="text-center border_right">
                  0 Day
                </th>
                <th colSpan={3} className="text-center border_right">
                  1 Day
                </th>
                <th colSpan={3} className="text-center border_right">
                  2 to 7 Days
                </th>
                <th colSpan={3} className="text-center border_right">
                  8 to 15 Days
                </th>
                <th colSpan={3} className="text-center border_right">
                  16 to 30 Days
                </th>
                <th colSpan={3} className="text-center border_right">
                  31 to 60 Days
                </th>
                <th colSpan={3} className="text-center border_right">
                  61 to 90 Days
                </th>
                <th colSpan={3} className="text-center border_right">
                  90+ Days
                </th>
              </tr>
              <tr>
                <th className="border_right">Month</th>
                <th className="text-center">Cancelled Nights</th>
                <th className="text-end">ADR</th>
                <th className="text-end border_right">Revenue Loss</th>
                <th className="text-center">Cancelled Nights</th>
                <th className="text-end">ADR</th>
                <th className="text-end border_right">Revenue Loss</th>
                <th className="text-center">Cancelled Nights</th>
                <th className="text-end">ADR</th>
                <th className="text-end border_right">Revenue Loss</th>
                <th className="text-center">Cancelled Nights</th>
                <th className="text-end">ADR</th>
                <th className="text-end border_right">Revenue Loss</th>
                <th className="text-center">Cancelled Nights</th>
                <th className="text-end">ADR</th>
                <th className="text-end border_right">Revenue Loss</th>
                <th className="text-center">Cancelled Nights</th>
                <th className="text-end">ADR</th>
                <th className="text-end border_right">Revenue Loss</th>
                <th className="text-center">Cancelled Nights</th>
                <th className="text-end">ADR</th>
                <th className="text-end border_right">Revenue Loss</th>
                <th className="text-center">Cancelled Nights</th>
                <th className="text-end">ADR</th>
                <th className="text-end border_right">Revenue Loss</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr style={{ height: "80px", position: "relative" }}>
                  <Loader />
                </tr>
              ) : (
                month?.map((item, index) => {
                  let findMonthData = monthlyCancellationData?.filter(
                    (x) =>
                      x?.MonthName?.trim()?.toLowerCase() ===
                      item?.toLowerCase()
                  );

                  CP0 =
                    CP0 +
                    (findMonthData?.[0]?.CP0
                      ? parseInt(findMonthData?.[0]?.CP0)
                      : 0);

                  CP0_ADR =
                    CP0_ADR +
                    (findMonthData?.[0]?.CP0_ADR
                      ? parseInt(findMonthData?.[0]?.CP0_ADR)
                      : 0);

                  if (
                    findMonthData?.[0]?.CP0_ADR !== "0" &&
                    findMonthData?.[0]?.CP0_ADR
                  ) {
                    CP0_ADR_COUNT = CP0_ADR_COUNT + 1;
                  }

                  CP0_REV =
                    CP0_REV +
                    (findMonthData?.[0]?.CP0_REV
                      ? parseInt(findMonthData?.[0]?.CP0_REV)
                      : 0);

                  CP1 =
                    CP1 +
                    (findMonthData?.[0]?.CP1
                      ? parseInt(findMonthData?.[0]?.CP1)
                      : 0);

                  CP1_ADR =
                    CP1_ADR +
                    (findMonthData?.[0]?.CP1_ADR
                      ? parseInt(findMonthData?.[0]?.CP1_ADR)
                      : 0);

                  if (
                    findMonthData?.[0]?.CP1_ADR !== "0" &&
                    findMonthData?.[0]?.CP1_ADR
                  ) {
                    CP1_ADR_COUNT = CP1_ADR_COUNT + 1;
                  }

                  CP1_REV =
                    CP1_REV +
                    (findMonthData?.[0]?.CP1_REV
                      ? parseInt(findMonthData?.[0]?.CP1_REV)
                      : 0);

                  CP2TO7 =
                    CP2TO7 +
                    (findMonthData?.[0]?.CP2TO7
                      ? parseInt(findMonthData?.[0]?.CP2TO7)
                      : 0);

                  CP2TO7_ADR =
                    CP2TO7_ADR +
                    (findMonthData?.[0]?.CP2TO7_ADR
                      ? parseInt(findMonthData?.[0]?.CP2TO7_ADR)
                      : 0);

                  if (
                    findMonthData?.[0]?.CP2TO7_ADR !== "0" &&
                    findMonthData?.[0]?.CP2TO7_ADR
                  ) {
                    CP2TO7_ADR_COUNT = CP2TO7_ADR_COUNT + 1;
                  }

                  CP2TO7_REV =
                    CP2TO7_REV +
                    (findMonthData?.[0]?.CP2TO7_REV
                      ? parseInt(findMonthData?.[0]?.CP2TO7_REV)
                      : 0);

                  CP8TO15 =
                    CP8TO15 +
                    (findMonthData?.[0]?.CP8TO15
                      ? parseInt(findMonthData?.[0]?.CP8TO15)
                      : 0);

                  CP8TO15_ADR =
                    CP8TO15_ADR +
                    (findMonthData?.[0]?.CP8TO15_ADR
                      ? parseInt(findMonthData?.[0]?.CP8TO15_ADR)
                      : 0);

                  if (
                    findMonthData?.[0]?.CP8TO15_ADR !== "0" &&
                    findMonthData?.[0]?.CP8TO15_ADR
                  ) {
                    CP8TO15_ADR_COUNT = CP8TO15_ADR_COUNT + 1;
                  }

                  CP8TO15_REV =
                    CP8TO15_REV +
                    (findMonthData?.[0]?.CP8TO15_REV
                      ? parseInt(findMonthData?.[0]?.CP8TO15_REV)
                      : 0);

                  CP16TO30 =
                    CP16TO30 +
                    (findMonthData?.[0]?.CP16TO30
                      ? parseInt(findMonthData?.[0]?.CP16TO30)
                      : 0);

                  CP16TO30_ADR =
                    CP16TO30_ADR +
                    (findMonthData?.[0]?.CP16TO30_ADR
                      ? parseInt(findMonthData?.[0]?.CP16TO30_ADR)
                      : 0);

                  if (
                    findMonthData?.[0]?.CP16TO30_ADR !== "0" &&
                    findMonthData?.[0]?.CP16TO30_ADR
                  ) {
                    CP16TO30_ADR_COUNT = CP16TO30_ADR_COUNT + 1;
                  }

                  CP16TO30_REV =
                    CP16TO30_REV +
                    (findMonthData?.[0]?.CP16TO30_REV
                      ? parseInt(findMonthData?.[0]?.CP16TO30_REV)
                      : 0);

                  CP31TO60 =
                    CP31TO60 +
                    (findMonthData?.[0]?.CP31TO60
                      ? parseInt(findMonthData?.[0]?.CP31TO60)
                      : 0);

                  CP31TO60_ADR =
                    CP31TO60_ADR +
                    (findMonthData?.[0]?.CP31TO60_ADR
                      ? parseInt(findMonthData?.[0]?.CP31TO60_ADR)
                      : 0);

                  if (
                    findMonthData?.[0]?.CP31TO60_ADR !== "0" &&
                    findMonthData?.[0]?.CP31TO60_ADR
                  ) {
                    CP31TO60_ADR_COUNT = CP31TO60_ADR_COUNT + 1;
                  }

                  CP31TO60_REV =
                    CP31TO60_REV +
                    (findMonthData?.[0]?.CP31TO60_REV
                      ? parseInt(findMonthData?.[0]?.CP31TO60_REV)
                      : 0);

                  CP61TO90 =
                    CP61TO90 +
                    (findMonthData?.[0]?.CP61TO90
                      ? parseInt(findMonthData?.[0]?.CP61TO90)
                      : 0);

                  CP61TO90_ADR =
                    CP61TO90_ADR +
                    (findMonthData?.[0]?.CP61TO90_ADR
                      ? parseInt(findMonthData?.[0]?.CP61TO90_ADR)
                      : 0);

                  if (
                    findMonthData?.[0]?.CP61TO90_ADR !== "0" &&
                    findMonthData?.[0]?.CP61TO90_ADR
                  ) {
                    CP61TO90_ADR_COUNT = CP61TO90_ADR_COUNT + 1;
                  }

                  CP61TO90_REV =
                    CP61TO90_REV +
                    (findMonthData?.[0]?.CP61TO90_REV
                      ? parseInt(findMonthData?.[0]?.CP61TO90_REV)
                      : 0);

                  CP91TOUP =
                    CP91TOUP +
                    (findMonthData?.[0]?.CP91TOUP
                      ? parseInt(findMonthData?.[0]?.CP91TOUP)
                      : 0);

                  CP91TOUP_ADR =
                    CP91TOUP_ADR +
                    (findMonthData?.[0]?.CP91TOUP_ADR
                      ? parseInt(findMonthData?.[0]?.CP91TOUP_ADR)
                      : 0);

                  if (
                    findMonthData?.[0]?.CP91TOUP_ADR !== "0" &&
                    findMonthData?.[0]?.CP91TOUP_ADR
                  ) {
                    CP91TOUP_ADR_COUNT = CP91TOUP_ADR_COUNT + 1;
                  }

                  CP91TOUP_REV =
                    CP91TOUP_REV +
                    (findMonthData?.[0]?.CP91TOUP_REV
                      ? parseInt(findMonthData?.[0]?.CP91TOUP_REV)
                      : 0);

                  return (
                    <React.Fragment key={index}>
                      <tr className="shadowOnHover">
                        <td className="border_right">{item}</td>
                        <td className="text-center">
                          {findMonthData?.[0]?.CP0
                            ? commonService.formateRoom(findMonthData?.[0]?.CP0)
                            : 0}
                        </td>
                        <td className="text-end">
                          {findMonthData?.[0]?.CP0_ADR
                            ? commonService.formateAmount(
                                findMonthData?.[0]?.CP0_ADR
                              )
                            : commonService.formateAmount(0)}
                        </td>
                        <td className="text-end border_right">
                          {findMonthData?.[0]?.CP0_REV
                            ? commonService.formateAmount(
                                findMonthData?.[0]?.CP0_REV
                              )
                            : commonService.formateAmount(0)}
                        </td>

                        <td className="text-center">
                          {findMonthData?.[0]?.CP1
                            ? commonService.formateRoom(findMonthData?.[0]?.CP1)
                            : 0}
                        </td>
                        <td className="text-end">
                          {findMonthData?.[0]?.CP1_ADR
                            ? commonService.formateAmount(
                                findMonthData?.[0]?.CP1_ADR
                              )
                            : commonService.formateAmount(0)}
                        </td>
                        <td className="text-end border_right">
                          {findMonthData?.[0]?.CP1_REV
                            ? commonService.formateAmount(
                                findMonthData?.[0]?.CP1_REV
                              )
                            : commonService.formateAmount(0)}
                        </td>

                        <td className="text-center">
                          {findMonthData?.[0]?.CP2TO7
                            ? commonService.formateRoom(findMonthData?.[0]?.CP2TO7)
                            : 0}
                        </td>
                        <td className="text-end">
                          {findMonthData?.[0]?.CP2TO7_ADR
                            ? commonService.formateAmount(
                                findMonthData?.[0]?.CP2TO7_ADR
                              )
                            : commonService.formateAmount(0)}
                        </td>
                        <td className="text-end border_right">
                          {findMonthData?.[0]?.CP2TO7_REV
                            ? commonService.formateAmount(
                                findMonthData?.[0]?.CP2TO7_REV
                              )
                            : commonService.formateAmount(0)}
                        </td>

                        <td className="text-center">
                          {findMonthData?.[0]?.CP8TO15
                            ? commonService.formateRoom(findMonthData?.[0]?.CP8TO15)
                            : 0}
                        </td>
                        <td className="text-end">
                          {findMonthData?.[0]?.CP8TO15_ADR
                            ? commonService.formateAmount(
                                findMonthData?.[0]?.CP8TO15_ADR
                              )
                            : commonService.formateAmount(0)}
                        </td>
                        <td className="text-end border_right">
                          {findMonthData?.[0]?.CP8TO15_REV
                            ? commonService.formateAmount(
                                findMonthData?.[0]?.CP8TO15_REV
                              )
                            : commonService.formateAmount(0)}
                        </td>

                        <td className="text-center">
                          {findMonthData?.[0]?.CP16TO30
                            ? commonService.formateRoom(findMonthData?.[0]?.CP16TO30)
                            : 0}
                        </td>
                        <td className="text-end">
                          {findMonthData?.[0]?.CP16TO30_ADR
                            ? commonService.formateAmount(
                                findMonthData?.[0]?.CP16TO30_ADR
                              )
                            : commonService.formateAmount(0)}
                        </td>
                        <td className="text-end border_right">
                          {findMonthData?.[0]?.CP16TO30_REV
                            ? commonService.formateAmount(
                                findMonthData?.[0]?.CP16TO30_REV
                              )
                            : commonService.formateAmount(0)}
                        </td>

                        <td className="text-center">
                          {findMonthData?.[0]?.CP31TO60
                            ? commonService.formateRoom(findMonthData?.[0]?.CP31TO60)
                            : 0}
                        </td>
                        <td className="text-end">
                          {findMonthData?.[0]?.CP31TO60_ADR
                            ? commonService.formateAmount(
                                findMonthData?.[0]?.CP31TO60_ADR
                              )
                            : commonService.formateAmount(0)}
                        </td>
                        <td className="text-end border_right">
                          {findMonthData?.[0]?.CP31TO60_REV
                            ? commonService.formateAmount(
                                findMonthData?.[0]?.CP31TO60_REV
                              )
                            : commonService.formateAmount(0)}
                        </td>

                        <td className="text-center">
                          {findMonthData?.[0]?.CP61TO90
                            ? commonService.formateRoom(findMonthData?.[0]?.CP61TO90)
                            : 0}
                        </td>
                        <td className="text-end">
                          {findMonthData?.[0]?.CP61TO90_ADR
                            ? commonService.formateAmount(
                                findMonthData?.[0]?.CP61TO90_ADR
                              )
                            : commonService.formateAmount(0)}
                        </td>
                        <td className="text-end border_right">
                          {findMonthData?.[0]?.CP61TO90_REV
                            ? commonService.formateAmount(
                                findMonthData?.[0]?.CP61TO90_REV
                              )
                            : commonService.formateAmount(0)}
                        </td>

                        <td className="text-center">
                          {findMonthData?.[0]?.CP91TOUP
                            ? commonService.formateRoom(findMonthData?.[0]?.CP91TOUP)
                            : 0}
                        </td>
                        <td className="text-end">
                          {findMonthData?.[0]?.CP91TOUP_ADR
                            ? commonService.formateAmount(
                                findMonthData?.[0]?.CP91TOUP_ADR
                              )
                            : commonService.formateAmount(0)}
                        </td>
                        <td className="text-end border_right">
                          {findMonthData?.[0]?.CP91TOUP_REV
                            ? commonService.formateAmount(
                                findMonthData?.[0]?.CP91TOUP_REV
                              )
                            : commonService.formateAmount(0)}
                        </td>
                      </tr>
                      {index === 11 && (
                        <tr className="shadowOnHover">
                          <td className="boldTd border_right">Total</td>
                          <td className="boldTd text-center">{commonService.formateRoom(CP0)}</td>
                          <td className="boldTd text-end">
                            {CP0_ADR > 0
                              ? commonService.formateAmount(
                                  Math.round(CP0_ADR / CP0_ADR_COUNT)
                                )
                              : commonService.formateAmount(0)}
                          </td>
                          <td className="boldTd text-end border_right">
                            {commonService.formateAmount(CP0_REV)}
                          </td>

                          <td className="boldTd text-center">{commonService.formateRoom(CP1)}</td>
                          <td className="boldTd text-end">
                            {CP1_ADR > 0
                              ? commonService.formateAmount(
                                  Math.round(CP1_ADR / CP1_ADR_COUNT)
                                )
                              : commonService.formateAmount(0)}
                          </td>
                          <td className="boldTd text-end border_right">
                            {commonService.formateAmount(CP1_REV)}
                          </td>

                          <td className="boldTd text-center">{commonService.formateRoom(CP2TO7)}</td>
                          <td className="boldTd text-end">
                            {CP2TO7_ADR > 0
                              ? commonService.formateAmount(
                                  Math.round(CP2TO7_ADR / CP2TO7_ADR_COUNT)
                                )
                              : commonService.formateAmount(0)}
                          </td>
                          <td className="boldTd text-end border_right">
                            {commonService.formateAmount(CP2TO7_REV)}
                          </td>

                          <td className="boldTd text-center">{commonService.formateRoom(CP8TO15)}</td>
                          <td className="boldTd text-end">
                            {CP8TO15_ADR > 0
                              ? commonService.formateAmount(
                                  Math.round(CP8TO15_ADR / CP8TO15_ADR_COUNT)
                                )
                              : commonService.formateAmount(0)}
                          </td>
                          <td className="boldTd text-end border_right">
                            {commonService.formateAmount(CP8TO15_REV)}
                          </td>

                          <td className="boldTd text-center">{commonService.formateRoom(CP16TO30)}</td>
                          <td className="boldTd text-end">
                            {CP16TO30_ADR > 0
                              ? commonService.formateAmount(
                                  Math.round(CP16TO30_ADR / CP16TO30_ADR_COUNT)
                                )
                              : commonService.formateAmount(0)}
                          </td>
                          <td className="boldTd text-end border_right">
                            {commonService.formateAmount(CP16TO30_REV)}
                          </td>

                          <td className="boldTd text-center">{commonService.formateRoom(CP31TO60)}</td>
                          <td className="boldTd text-end">
                            {CP31TO60_ADR > 0
                              ? commonService.formateAmount(
                                  Math.round(CP31TO60_ADR / CP31TO60_ADR_COUNT)
                                )
                              : commonService.formateAmount(0)}
                          </td>
                          <td className="boldTd text-end border_right">
                            {commonService.formateAmount(CP31TO60_REV)}
                          </td>

                          <td className="boldTd text-center">{commonService.formateRoom(CP61TO90)}</td>
                          <td className="boldTd text-end">
                            {CP61TO90_ADR > 0
                              ? commonService.formateAmount(
                                  Math.round(CP61TO90_ADR / CP61TO90_ADR_COUNT)
                                )
                              : commonService.formateAmount(0)}
                          </td>
                          <td className="boldTd text-end border_right">
                            {commonService.formateAmount(CP61TO90_REV)}
                          </td>

                          <td className="boldTd text-center">{commonService.formateRoom(CP91TOUP)}</td>
                          <td className="boldTd text-end">
                            {CP91TOUP_ADR > 0
                              ? commonService.formateAmount(
                                  Math.round(CP91TOUP_ADR / CP91TOUP_ADR_COUNT)
                                )
                              : commonService.formateAmount(0)}
                          </td>
                          <td className="boldTd text-end border_right">
                            {commonService.formateAmount(CP91TOUP_REV)}
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  );
                })
              )}
            </tbody>
          </Table>
        </div>
      </div>
      {api !== 0 && (
        <Comments widgetDetails={para} editorId={"MonthlyCancellationPace"} />
      )}

      {/* {showNoteSidebar && (
        <WidgetNote
          show={showNoteSidebar}
          handleClose={closeNoteSideBar}
          widgetId={para?.widgetId}
          propertyId={propertyDetailFromRTK?.propertyid}
          rowTitle={para?.rowTitle}
        />
      )} */}
      {showNoteSidebar && (
        <AddWidgetModal
          showModal={showNoteSidebar}
          closeModal={closeNoteSideBar}
          widgetId={para?.widgetId}
          propertyId={propertyDetailFromRTK?.propertyid}
          rowTitle={para?.rowTitle}
        />
      )}
      {widgetModal && (
        <WidgetDetailModal
          showModal={widgetModal}
          closeModal={closeWidgetModal}
          data={widgetDetailRef.current}
        />
      )}
    </div>
  );
};

export default MonthlyCancellationPace;
