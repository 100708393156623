import React, { useEffect, useRef, useState } from "react";
import { apiCall } from "../../../../utils/axiosService";
import { apiResponse } from "../../../../utils/apiResponse";
import { API_URL } from "../../../../utils/apiRoutes";
import { Button, Col, Row, Table } from "react-bootstrap";
import Loader from "../../../Loader/Loader";
import WidgetDetailModal from "../../WidgetDetailModal";
import { commonService } from "../../../../utils/commonService";

const TodayArrivalDeparture = ({
  setCompDataFromChild,
  rowIndex,
  colIndex,
  api,
  para,
  setting,
}) => {
  const [arrivalLoading, setArrivalLoading] = useState(false);
  const [departureLoading, setDepartureLoading] = useState(false);
  const [arrivalData, setArrivalData] = useState(null);
  const [departureData, setDepartureData] = useState(null);
  const [widgetModal, setWidgetModal] = useState(false);
  const widgetDetailRef = useRef();

  const closeWidgetModal = () => {
    setWidgetModal(false);
  };

  const getArrivalData = async () => {
    setArrivalLoading(true);
    let arrivalDataResponse = await apiCall(
      {
        method: "POST",
        url: API_URL.BASE_API_URL.SnapShot + API_URL.CRM_SNAPSHOT.TODAY_ARRIVAL,
        body: {
          propertyCode: para?.propertyCode,
          AsOfDate: para?.asOfDate,
        },
      },
      false
    );

    let response = apiResponse(false, arrivalDataResponse);

    if (response?.isValidate) {
      setArrivalData(response?.data?.data);
      setArrivalLoading(false);
    }

    if (!response?.isValidate) {
      setArrivalData(null);
      setArrivalLoading(false);
    }
  };

  const getDepartureData = async () => {
    setDepartureLoading(true);
    let departureDataResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.SnapShot + API_URL.CRM_SNAPSHOT.TODAY_DEPARTURE,
        body: {
          propertyCode: para?.propertyCode,
          AsOfDate: para?.asOfDate,
        },
      },
      false
    );

    let response = apiResponse(false, departureDataResponse);

    if (response?.isValidate) {
      setDepartureData(response?.data?.data);
      setDepartureLoading(false);
    }

    if (!response?.isValidate) {
      setDepartureData(null);
      setDepartureLoading(false);
    }
  };

  useEffect(() => {
    if (api === 0) {
    } else {
      if (para?.asOfDate) {
        getArrivalData();
        getDepartureData();
      }
    }
  }, [para?.asOfDate]);

  return (
    <div className="widget-container">
      <Col xl={12}>
        <Row>
          <Col xl={6}>
            <div className="ViewPropertySetupTable">
              <div className="table__container">
                <div className="stripped__table">
                  <div className="table-responsive tblDynamicPickupSticky">
                    <Table>
                      <thead className="tbl-header-sticky">
                        <tr className="table-main-header-tr">
                          <th colSpan={6} className="text-center">
                            Today Arrivals
                          </th>
                        </tr>
                        <tr className="table-main-header-tr">
                          <th>MarketSegment</th>
                          <th>Ratecode</th>
                          <th>Guestname</th>
                          <th>LOS</th>
                          <th>Rate</th>
                          <th>Revenue</th>
                        </tr>
                      </thead>
                      <tbody>
                        {arrivalLoading ? (
                          <tr style={{ height: "80px", position: "relative" }}>
                            <Loader />
                          </tr>
                        ) : arrivalData ? (
                          arrivalData?.map((item) => {
                            return (
                              <tr className="shadowOnHover">
                                <td>{item?.MarketSegment}</td>
                                <td>{item?.RateCode}</td>
                                <td>{item?.GuestName}</td>
                                <td>{item?.LOS}</td>
                                <td>
                                  {item?.Rate
                                    ? `${commonService.formateAmount(
                                        item?.Rate
                                      )}`
                                    : `-`}
                                </td>
                                <td>
                                  {item?.Revenue
                                    ? `${commonService.formateAmount(
                                        item?.Revenue
                                      )}`
                                    : `-`}
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td className="text-center" colSpan={6}>
                              No Data Found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col xl={6}>
            <div className="ViewPropertySetupTable">
              <div className="table__container">
                <div className="stripped__table">
                  <div className="table-responsive tblDynamicPickupSticky">
                    <Table>
                      <thead className="tbl-header-sticky">
                        <tr className="table-main-header-tr">
                          <th colSpan={6} className="text-center">
                            Today Departures
                          </th>
                        </tr>
                        <tr className="table-main-header-tr">
                          <th>MarketSegment</th>
                          <th>Ratecode</th>
                          <th>Guestname</th>
                          <th>LOS</th>
                          <th>Rate</th>
                          <th>Revenue</th>
                        </tr>
                      </thead>
                      <tbody>
                        {departureLoading ? (
                          <tr style={{ height: "80px", position: "relative" }}>
                            <Loader />
                          </tr>
                        ) : departureData ? (
                          departureData?.map((item) => {
                            return (
                              <tr className="shadowOnHover">
                                <td>{item?.MarketSegment}</td>
                                <td>{item?.RateCode}</td>
                                <td>{item?.GuestName}</td>
                                <td>{item?.LOS}</td>
                                <td>
                                  {item?.Rate
                                    ? `${commonService.formateAmount(
                                        item?.Rate
                                      )}`
                                    : "-"}
                                </td>
                                <td>
                                  {item?.Revenue
                                    ? `${commonService.formateAmount(
                                        item?.Revenue
                                      )}`
                                    : `-`}
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td className="text-center" colSpan={6}>
                              No Data Found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Col>

      {widgetModal && (
        <WidgetDetailModal
          showModal={widgetModal}
          closeModal={closeWidgetModal}
          data={widgetDetailRef.current}
        />
      )}
    </div>
  );
};

export default TodayArrivalDeparture;
