import React, { useEffect, useRef, useState } from "react";
import { Row, Form, Col, Button, Container } from "react-bootstrap";
import pdfIcon from "../../../Assets/Images/CRM/pdf.svg";
import { commonService } from "../../../utils/commonService";
import { API_URL } from "../../../utils/apiRoutes";
import { validationRule } from "../../../utils/constant";
import { checkValidation } from "../../../utils/helper";
import { apiCall } from "../../../utils/axiosService";
import { apiResponse } from "../../../utils/apiResponse";
import { successToastMessage } from "../../../utils/toastMessage";
import { month } from "../../../utils/constant";
import { useSelector } from "react-redux";
import filterIcon from "../../../Assets/Images/CRM/filter.png";
import Loader from "../../Loader/Loader";
import { Link } from "react-router-dom";

const MeetingRoomBookReport = ({ title }) => {
  const [loading, setLoading] = useState(false);
  const setPdfRef = useRef("");
  const [showPdf, setShowPdf] = useState(false);
  const [showAllReport, setshowAllReport] = useState(false);
  const isDefaultApiCalledRef = useRef(false);
  const [errorMessage, setErrorMessage] = useState(false);

  const handleshowAllReport = () => {
    setshowAllReport(!showAllReport);
  };
  const yearsArr = [
    ...commonService.getPastYearForDropDown(10),
    ...commonService.getFutureYearForDropDown(10),
    new Date().getFullYear(),
  ]?.sort(function (a, b) {
    return a - b;
  });

  const crmPropertyDetailFromRTK = useSelector(
    (store) => store.headerProperty.crmPropertyDetail
  );
  let propertyIdPayload = "";
  if (crmPropertyDetailFromRTK) {
    if (crmPropertyDetailFromRTK?.propertyid === "") {
      let propertyPayload = crmPropertyDetailFromRTK?.allPropertyId;
      propertyIdPayload = propertyPayload.slice(0, -1);
    } else {
      propertyIdPayload = crmPropertyDetailFromRTK?.propertyid?.toString();
    }
  }

  let validationObj = {
    propertyID: propertyIdPayload,
    year: new Date().getFullYear(),
    month: "1",

    validationRule: [
      {
        fieldName: "year",
        rule: validationRule.required,
        message: "Year is required",
      },
      {
        fieldName: "month",
        rule: validationRule.required,
        message: "Month is required",
      },
    ],
    errorObj: {},
    isValidate: true,
  };
  const [meetingRoom, setMeetingRoom] = useState(validationObj);

  const iframeloader = () => {
    setLoading(false);
  };
  const GenerateReportbtn = async () => {
    setShowPdf(false);
    setLoading(true);
    let validation = checkValidation(meetingRoom);

    if (!validation.isValidate) {
      setMeetingRoom(validation);
      setLoading(false);
      return;
    }

    let meetingRoomApi = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.CRM +
          API_URL.CRM_REPORT.MEETING_ROOM_BOOKED_REPORT,
        body: {
          propertyID: propertyIdPayload,
          year: meetingRoom.year.toString(),
          month: meetingRoom.month,
        },
      },
      false
    );

    let response = apiResponse(false, meetingRoomApi);

    if (response?.isValidate) {
      isDefaultApiCalledRef.current = true;
      // setLoading(false);
      setshowAllReport(false);
      setErrorMessage(false);
      // successToastMessage(response?.message);
      setPdfRef.current =
        process.env.REACT_APP_APIBASEPATH_CRM_REPORT_PDF + response.data.data;
      setShowPdf(true);
    }
    if (!response?.isValidate) {
      isDefaultApiCalledRef.current = true;
      setshowAllReport(true);
      setLoading(false);
      setErrorMessage(true);
    }
  };

  useEffect(() => {
    if (
      !isDefaultApiCalledRef.current &&
      crmPropertyDetailFromRTK?.propertyid !== ""
    ) {
      GenerateReportbtn();
    }
  }, [meetingRoom.propertyID]);

  useEffect(() => {
    if (crmPropertyDetailFromRTK?.propertyid === "") {
      isDefaultApiCalledRef.current = true;
      setshowAllReport(true);
    }
  }, []);
  return (
    <div className="all-reports-main ToolsDisplacementCalcMain">
      <Container fluid>
        <Row>
          <Col xl={12}>
            <div className="ProspectDashboardTitleWraper  d-flex justify-content-between align-items-center marginBottom20">
              <h2 className="d-flex align-items-center gap-1">
                <Link to="/crm/dashboard">Dashboard - </Link>{" "}
                <span className="tab-title-main">{title}</span>
              </h2>
              <div className="heading-menu-btns">
                <img
                  src={filterIcon}
                  alt="filterIcon"
                  title="Filter Report"
                  className="cursorPointer report-filter"
                  onClick={handleshowAllReport}
                />
              </div>
            </div>
          </Col>
        </Row>

        {showAllReport && (
          <div className="displacementCalcWrapper width400">
            <div className="startFrom from-groups no-border-break mb-2">
              <Form.Label htmlFor="startDate">Year</Form.Label>
              <Form.Select
                value={meetingRoom.year}
                aria-label="Default select example"
                className="blue-colored-select"
                onChange={(e) => {
                  setMeetingRoom({
                    ...meetingRoom,
                    year: e.target.value,
                  });
                }}
              >
                {yearsArr?.map((item, index) => (
                  <option key={index} value={item}>
                    {item}
                  </option>
                ))}
              </Form.Select>
              {meetingRoom?.errorObj?.year && meetingRoom?.year === "" && (
                <small className="text-danger">
                  {meetingRoom?.errorObj?.year}
                </small>
              )}
            </div>

            <div className="endDate from-groups no-border-break mb-4">
              <Form.Label htmlFor="endDate">Month</Form.Label>

              <Form.Select
                value={meetingRoom.month}
                aria-label="Default select example"
                className="blue-colored-select"
                onChange={(e) => {
                  setMeetingRoom({
                    ...meetingRoom,
                    month: e.target.value,
                  });
                }}
              >
                {month?.map((item, index) => (
                  <option key={index} value={index + 1}>
                    {item}
                  </option>
                ))}
              </Form.Select>
              {meetingRoom?.errorObj?.month && meetingRoom?.month === "" && (
                <small className="text-danger">
                  {meetingRoom?.errorObj?.month}
                </small>
              )}
            </div>

            <div className="d-flex gap-3 align-items-center justify-content-end">
              {loading ? (
                <button
                  className="default-btn primary-btn d-flex gap-1 align-items-center"
                  type="button"
                  disabled
                >
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Loading...
                </button>
              ) : (
                <button
                  className="default-btn primary-btn d-flex gap-1 align-items-center"
                  onClick={() => {
                    GenerateReportbtn();
                  }}
                >
                  <span className="revpak-icon">
                    <img src={pdfIcon} alt="" />
                  </span>
                  <span>Generate Report</span>
                </button>
              )}
            </div>
          </div>
        )}
        <p className="text-center">{errorMessage && " No Record Found"}</p>
        {loading && <Loader />}
        <div className="pdf-is-showing-here mt-3">
          {showPdf && setPdfRef.current && (
            <iframe
              onLoad={iframeloader}
              title="Report Doc"
              src={setPdfRef.current}
              width="100%"
              height="800px"
            />
          )}
        </div>
        {/* {loading ? (
          <Loader />
        ) : (
          <div className="pdf-is-showing-here mt-3">
            {showPdf && setPdfRef.current && (
              <iframe
                title="Report Doc"
                src={setPdfRef.current}
                width="100%"
                height="800px"
              />
            )}
          </div>
        )} */}
      </Container>
    </div>
  );
};

export default MeetingRoomBookReport;
