import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Col,
  FloatingLabel,
  Form,
  Modal,
  Row,
  Tab,
  Table,
  Tabs,
  Card,
} from "react-bootstrap";
import Multiselect from "multiselect-react-dropdown";
import { MultiSelect } from "primereact/multiselect";
import RmEventDetailCard from "../RevenueManagement/RmEventDetailCard";
import reloadIcon from "../../Assets/Images/Revenue-management/blue-reload.svg";
import tentIcon from "../../Assets/Images/Revenue-management/circus-tent.svg";
import moment from "moment";
import { apiCall } from "../../utils/axiosService";
import { API_URL } from "../../utils/apiRoutes";
import { apiResponse } from "../../utils/apiResponse";
import { useSelector } from "react-redux";
import {
  GoogleMap,
  StandaloneSearchBox,
  LoadScript,
  Marker,
} from "@react-google-maps/api";
import DeleteItemModal from "../Configuration/DeleteItemModal";
import deleteIcon from "../../Assets/Images/Action Icons/deleteaction.svg";
import closeBtn from "../../Assets/Images/Action Icons/closeIcon.svg";
import { commonService } from "../../utils/commonService";
const lib = ["places", "geometry"];

const EventViewModal = ({
  showModal,
  closeModal,
  eventDetails,
  importEventDetail,
  getEventList,
  selectedDate,
  para,
}) => {
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const propertyDetailFromRTK = useSelector(
    (store) => store.headerProperty.propertyDetail
  );
  const asOfDate = useSelector((store) => store.headerProperty.asOfDate);
  const [loading, setLoading] = useState(false);
  const [lyData, setLyData] = useState(null);
  const [stlyData, setStlyData] = useState(null);
  const [sevenDayPickup, setSevenDayPickup] = useState(null);
  const [fourteenDayPickup, setFourteenDayPickup] = useState(null);
  const [otbData, setOtbData] = useState(null);
  const [forecastData, setForecastData] = useState(null);
  const [rateShopData, setRateShopData] = useState(null);
  const [selectedEvent, setSelectedEvent] = useState(0);
  const [selectedEventDetail, setSelectedEventDetail] = useState(
    eventDetails?.[0]
  );

  let latitude = "",
    longitude = "",
    presentLocation = {};
  let showAddPropertyRef = useRef(false);
  if (selectedEventDetail?.id) {
    let isEventExists = eventDetails?.filter(
      (x) =>
        x?.eventResourceId === selectedEventDetail?.eventResourceId && x?.id > 0
    );
    if (isEventExists && isEventExists?.length > 0) {
      showAddPropertyRef.current = false;
    } else {
      showAddPropertyRef.current = true;
    }
  }
  if (selectedEventDetail?.geo_lat) {
    presentLocation = {
      lat: parseFloat(selectedEventDetail?.geo_long),
      lng: parseFloat(selectedEventDetail?.geo_lat),
    };
    latitude = selectedEventDetail?.geo_lat;
    longitude = selectedEventDetail?.geo_long;
  }

  const handleSubmit = async (d) => {
    setLoading(true);
    let eventName = d?.title?.replace(/['"]/g, "");
    let description = d?.detail?.replace(/['"]/g, "");
    let userNote = d?.labels?.replace(/['"]/g, "");

    const saveResponse = await apiCall(
      {
        method: "POST",
        url: API_URL.BASE_API_URL.RevenueManagement + API_URL.EVENTS.IMPORT,
        body: {
          F: [
            {
              id: d?.id,
              yearterm: new Date(d?.startDate).getFullYear(),
              startdate: commonService.getDateInDBFormat(d?.startDate),
              eventname: eventName,
              eventdetails: description,
              eventtypeterm: d?.category,
              eventimpactterm: "",
              propertyid: propertyDetailFromRTK?.propertyid,
              usernote: userNote,
              enddate: commonService.getDateInDBFormat(d?.endDate),
              eventsourceid: d?.eventJson?.event_id,
              eventjson: `"${JSON.stringify(d?.eventJson)}"`,
            },
          ],
        },
      },
      true
    );

    let response = apiResponse(true, saveResponse, setLoading);
    setLoading(false);
    if (response?.isValidate) {
      if (getEventList) {
        getEventList();
      }
      closeModal();
      setLoading(false);
    }
  };

  const handleDelete = (event) => {
    setDeleteModal(true);
    setDeleteId(event.id);
  };

  const closeDeleteModal = () => {
    setDeleteModal(false);
    setLoading(false);
    closeModal();
  };

  const getOtbData = async () => {
    let otbResponse = await apiCall(
      {
        method: "POST",
        url: API_URL.BASE_API_URL.SnapShot + API_URL.DAILY_SUMMARY.ORG,
        body: {
          AsOfDate: commonService.getDateForNode(asOfDate),
          startDate: selectedDate
            ? commonService.getDateForNode(selectedDate)
            : "",
          endDate: selectedDate
            ? commonService.getDateForNode(selectedDate)
            : "",
          propertyCode: propertyDetailFromRTK?.propertycode,
        },
      },
      false
    );

    let response = apiResponse(false, otbResponse);

    if (response?.isValidate) {
      setOtbData(response?.data?.data);
    }

    if (!response?.isValidate) {
      setOtbData(null);
    }
  };

  const getSevenDayPickupData = async () => {
    let date = new Date(commonService.getDateForNode(asOfDate));
    date.setDate(date.getDate() - 7);
    let pickupResponse = await apiCall(
      {
        method: "POST",
        url: API_URL.BASE_API_URL.SnapShot + API_URL.DAILY_SUMMARY.PICKUP_FROM,
        body: {
          AsOfDate: commonService.getDateForNode(asOfDate),
          startDate: selectedDate
            ? commonService.getDateForNode(selectedDate)
            : "",
          endDate: selectedDate
            ? commonService.getDateForNode(selectedDate)
            : "",
          pickupDate: commonService.getDateForNode(date),
          propertyCode: propertyDetailFromRTK?.propertycode,
        },
      },
      false
    );

    let response = apiResponse(false, pickupResponse);

    if (response?.isValidate) {
      setSevenDayPickup(response?.data?.data);
    }

    if (!response?.isValidate) {
      setSevenDayPickup(null);
    }
  };

  const getFourteenDayPickupData = async () => {
    let date = new Date(commonService.getDateForNode(asOfDate));
    date.setDate(date.getDate() - 14);
    let pickupResponse = await apiCall(
      {
        method: "POST",
        url: API_URL.BASE_API_URL.SnapShot + API_URL.DAILY_SUMMARY.PICKUP_FROM,
        body: {
          AsOfDate: commonService.getDateForNode(asOfDate),
          startDate: selectedDate
            ? commonService.getDateForNode(selectedDate)
            : "",
          endDate: selectedDate
            ? commonService.getDateForNode(selectedDate)
            : "",
          pickupDate: commonService.getDateForNode(date),
          propertyCode: propertyDetailFromRTK?.propertycode,
        },
      },
      false
    );

    let response = apiResponse(false, pickupResponse);

    if (response?.isValidate) {
      setFourteenDayPickup(response?.data?.data);
    }

    if (!response?.isValidate) {
      setFourteenDayPickup(null);
    }
  };

  const getLastYearData = async () => {
    let lyDataResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.SnapShot + API_URL.REV_PAK_90_DAY_OUTLOOK.GET_LY,
        body: {
          AsOfDate: commonService.getDateForNode(asOfDate),
          startDate: selectedDate
            ? commonService.getDateForNode(selectedDate)
            : "",
          endDate: selectedDate
            ? commonService.getDateForNode(selectedDate)
            : "",
          propertyCode: propertyDetailFromRTK?.propertycode,
        },
      },
      false
    );

    let response = apiResponse(false, lyDataResponse);

    if (response?.isValidate) {
      setLyData(response?.data?.data);
    }

    if (!response?.isValidate) {
      setLyData(null);
    }
  };

  const getStlyData = async () => {
    let stlyResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.SnapShot +
          API_URL.REV_PAK_90_DAY_OUTLOOK.GET_NET_STLY,
        body: {
          AsOfDate: commonService.getDateForNode(asOfDate),
          startDate: selectedDate
            ? commonService.getDateForNode(selectedDate)
            : "",
          endDate: selectedDate
            ? commonService.getDateForNode(selectedDate)
            : "",
          propertyCode: propertyDetailFromRTK?.propertycode,
        },
      },
      false
    );

    let response = apiResponse(false, stlyResponse);

    if (response?.isValidate) {
      setStlyData(response?.data?.data);
    }

    if (!response?.isValidate) {
      setStlyData(null);
    }
  };

  const getForecastData = async () => {
    let forecastResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.SnapShot +
          API_URL.DAILY_SUMMARY.PRICING_FORECAST,
        body: {
          AsOfDate: commonService.getDateForNode(asOfDate),
          startDate: selectedDate
            ? commonService.getDateForNode(selectedDate)
            : "",
          endDate: selectedDate
            ? commonService.getDateForNode(selectedDate)
            : "",
          propertyCode: propertyDetailFromRTK?.propertycode,
          ForcastType: "EMA",
        },
      },
      false
    );

    let response = apiResponse(false, forecastResponse);

    if (response?.isValidate) {
      setForecastData(response?.data?.data);
    }

    if (!response?.isValidate) {
      setForecastData(null);
    }
  };

  const getRateShopData = async () => {
    let byPropertyIdResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.RevenueManagement +
          API_URL.REVENUE_RATE_SHOP_CONFIG.GET_BY_PROPERTY_ID,
        body: {
          id: propertyDetailFromRTK?.propertyid,
        },
      },
      false
    );

    let propertyResponse = apiResponse(false, byPropertyIdResponse);

    let rateShopDataResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.SnapShot + API_URL.DAILY_SUMMARY.RATESHOP_LIST,
        body: {
          AsOfDate: asOfDate,
          propertyCode: propertyDetailFromRTK?.propertycode,
          Channel: propertyResponse?.data?.data?.channel_term
            ? propertyResponse?.data?.data?.channel_term
            : "",
          startDate: selectedDate
            ? commonService.getDateForNode(selectedDate)
            : "",
          endDate: selectedDate
            ? commonService.getDateForNode(selectedDate)
            : "",
          propertyid: propertyDetailFromRTK?.propertyid,
        },
      },
      false
    );

    let response = apiResponse(false, rateShopDataResponse);

    if (response?.isValidate) {
      setRateShopData(response?.data?.data);
    }

    if (!response?.isValidate) {
      setRateShopData(null);
    }
  };

  useEffect(() => {
    getOtbData();
    getSevenDayPickupData();
    getFourteenDayPickupData();
    getLastYearData();
    getStlyData();
    getForecastData();
    getRateShopData();
  }, []);

  const findCompetitorAvg = () => {
    let rateShopDataCount = 0,
      totalRateshop = 0;

    rateShopData?.forEach((rateItem) => {
      if (!rateItem?.IsSelf) {
        let propertyRate = rateItem?.Rate ? parseFloat(rateItem?.Rate) : 0;
        totalRateshop = totalRateshop + propertyRate;
        if (propertyRate > 0) {
          rateShopDataCount++;
        }
      }
    });

    let competitorAvg =
      totalRateshop > 0 && rateShopDataCount > 0
        ? Math.round(totalRateshop / rateShopDataCount)
        : 0;

    return competitorAvg;
  };

  const findSelfPropertyData = () => {
    let selfPropertyData = rateShopData?.filter((x) => x?.IsSelf);
    return selfPropertyData;
  };

  return (
    <>
      <Modal
        className="ToolsEventModalMain transparent-modal"
        size="xl"
        centered
        show={showModal}
        onHide={closeModal ? closeModal : closeDeleteModal()}
      >
        <div className="ToolsEventModalInner">
          <Row>
            <Col xl={3} className="tools-new-design-main">
              <div className="tools-new-design-header">
                <h4>Events</h4>
              </div>
              <div className="tools-new-design-body">
                {eventDetails?.map((item, index) => {
                  return (
                    <p
                      key={`eventView-${index}`}
                      onClick={() => {
                        setSelectedEvent(index);
                        setSelectedEventDetail(item);
                      }}
                      className={`${
                        selectedEvent === index ? "danger-color-text" : ""
                      }`}
                    >
                      {item?.name}
                    </p>
                  );
                })}
              </div>
            </Col>
            <Col xl={9}>
              <Row>
                <Col xl={7} className="pe-0">
                  <div className="RmEventDetailCardMain light-pink-bg-wrapper">
                    <div className="eventDetailWrapper">
                      <div className="eventDetailInner">
                        <div className="d-flex align-items-start justify-content-between">
                          <h4>{selectedEventDetail?.name}</h4>
                        </div>
                        <p>
                          {`${moment(selectedEventDetail?.startDate).format(
                            "L"
                          )} - ${moment(selectedEventDetail?.endDate).format(
                            "L"
                          )}`}
                        </p>
                      </div>
                      {selectedEventDetail?.labels && (
                        <div className="eventDetailInner2">
                          <img src={tentIcon} alt="tentIcon" />
                          {selectedEventDetail?.labels
                            ?.split(",")
                            ?.map((item, index) => (
                              <p key={item}>{item}</p>
                            ))}
                        </div>
                      )}

                      <div className="eventDetailInner3">
                        <span>Description</span>
                        <p>
                          {selectedEventDetail?.detail
                            ? selectedEventDetail?.detail
                            : "-"}
                        </p>
                      </div>
                      {selectedEventDetail?.rank !== null &&
                        selectedEventDetail?.rank !== undefined && (
                          <div className="eventDetailInner4">
                            {selectedEventDetail?.rank !== null &&
                              selectedEventDetail?.rank !== undefined && (
                                <div className="eventDetailInnerCircular">
                                  <h5>
                                    {selectedEventDetail?.rank
                                      ? Math.round(selectedEventDetail?.rank)
                                      : 0}
                                  </h5>
                                  <p>Rank</p>
                                </div>
                              )}

                            {selectedEventDetail?.local_rank !== null &&
                              selectedEventDetail?.local_rank !== undefined && (
                                <div className="eventDetailInnerCircular">
                                  <h5>
                                    {selectedEventDetail?.local_rank
                                      ? Math.round(
                                          selectedEventDetail?.local_rank
                                        )
                                      : 0}
                                  </h5>
                                  <p>Local</p>
                                </div>
                              )}

                            {selectedEventDetail?.phq_attendance !== null &&
                              selectedEventDetail?.phq_attendance !==
                                undefined && (
                                <div>
                                  <h5>
                                    {selectedEventDetail?.phq_attendance
                                      ? Math.round(
                                          selectedEventDetail?.phq_attendance
                                        )
                                      : 0}
                                  </h5>
                                  <p>Attendance</p>
                                </div>
                              )}
                          </div>
                        )}

                      <div
                        className="eventDetailInner5"
                        style={{ zIndex: "9999 !important" }}
                      >
                        {selectedEventDetail?.geo_lat && (
                          <LoadScript
                            googleMapsApiKey={
                              process.env.REACT_APP_GOOGLE_MAP_API_KEY
                            }
                            libraries={lib}
                          >
                            <GoogleMap
                              mapContainerStyle={{
                                height: "300px",
                                width: "100%",
                                position: "relative",
                              }}
                              center={presentLocation}
                              zoom={12}
                              options={{ mapTypeControl: false }}
                            >
                              <Marker position={presentLocation} />
                            </GoogleMap>
                          </LoadScript>
                        )}
                      </div>
                      {showAddPropertyRef.current ? (
                        <div className="eventDetailFooterBtn mt-3">
                          <>
                            {loading ? (
                              <button
                                className="default-btn primary-btn w-100"
                                type="button"
                                disabled
                              >
                                <span
                                  className="spinner-border spinner-border-sm"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                                Loading...
                              </button>
                            ) : (
                              <Button
                                onClick={() => {
                                  handleSubmit(selectedEventDetail);
                                }}
                                className="default-btn primary-btn w-100"
                              >
                                Add to Property
                              </Button>
                            )}
                          </>
                        </div>
                      ) : (
                        <div className="eventDetailFooterBtn mt-3">
                          <>
                            {loading ? (
                              <button
                                className="default-btn primary-btn"
                                type="button"
                                disabled
                              >
                                <span
                                  className="spinner-border spinner-border-sm"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                                Loading...
                              </button>
                            ) : (
                              <Button
                                onClick={() => {
                                  handleDelete(selectedEventDetail);
                                }}
                                className="default-btn primary-btn w-100 red-btn"
                              >
                                Delete event
                              </Button>
                            )}
                          </>
                        </div>
                      )}
                    </div>
                  </div>
                </Col>
                <Col xl={5} className="ps-0">
                  <div className="tools-new-design-right-sec">
                    <div className="d-flex align-items-center justify-content-between mb-3">
                      <h6>{moment(selectedDate).format("ddd DD, MMM YYYY")}</h6>
                      <button
                        className="btn-close"
                        onClick={closeModal}
                      ></button>
                    </div>
                    <div className="tools-new-design-right-sec-table">
                      <Table responsive>
                        <thead>
                          <tr>
                            <th></th>
                            <th className="text-center">7 DAY PICKUP</th>
                            <th className="text-center">14 DAY PICKUP</th>
                            <th className="text-center">LAST YEAR</th>
                            <th className="text-center">STLY</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <b>RMS</b>
                            </td>
                            <td className="text-center">
                              {sevenDayPickup?.[0]?.RMS
                                ? sevenDayPickup?.[0]?.RMS
                                : 0}
                            </td>
                            <td className="text-center">
                              {fourteenDayPickup?.[0]?.RMS
                                ? fourteenDayPickup?.[0]?.RMS
                                : 0}
                            </td>
                            <td className="text-center">
                              {lyData?.[0]?.RMS ? lyData?.[0]?.RMS : 0}
                            </td>
                            <td className="text-center">
                              {stlyData?.[0]?.RMS ? stlyData?.[0]?.RMS : 0}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <b>ADR</b>
                            </td>
                            <td className="text-center">
                              {sevenDayPickup?.[0]?.ADR
                                ? commonService.formateAmount(
                                    sevenDayPickup?.[0]?.ADR
                                  )
                                : commonService.formateAmount(0)}
                            </td>
                            <td className="text-center">
                              {fourteenDayPickup?.[0]?.ADR
                                ? commonService.formateAmount(
                                    fourteenDayPickup?.[0]?.ADR
                                  )
                                : 0}
                            </td>
                            <td className="text-center">
                              {lyData?.[0]?.ADR
                                ? commonService.formateAmount(lyData?.[0]?.ADR)
                                : commonService.formateAmount(0)}
                            </td>
                            <td className="text-center">
                              {stlyData?.[0]?.ADR
                                ? commonService.formateAmount(
                                    stlyData?.[0]?.ADR
                                  )
                                : commonService.formateAmount(0)}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <b>REV</b>
                            </td>
                            <td className="text-center">
                              {sevenDayPickup?.[0]?.REV
                                ? commonService.formateAmount(
                                    sevenDayPickup?.[0]?.REV
                                  )
                                : commonService.formateAmount(0)}
                            </td>
                            <td className="text-center">
                              {fourteenDayPickup?.[0]?.REV
                                ? commonService.formateAmount(
                                    fourteenDayPickup?.[0]?.REV
                                  )
                                : commonService.formateAmount(0)}
                            </td>
                            <td className="text-center">
                              {lyData?.[0]?.REV
                                ? commonService.formateAmount(lyData?.[0]?.REV)
                                : commonService.formateAmount(0)}
                            </td>
                            <td className="text-center">
                              {stlyData?.[0]?.REV
                                ? commonService.formateAmount(
                                    stlyData?.[0]?.REV
                                  )
                                : commonService.formateAmount(0)}
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                    <h6 className="tools-new-design-right-sec-red-title">
                      On The Books
                    </h6>
                    <div className="tools-new-design-right-sec-table no-thead-table">
                      <Table responsive>
                        <tbody>
                          <tr>
                            <td>
                              <b>Left to sell</b>
                            </td>
                            <td className="text-end">
                              {otbData?.[0]?.LeftToSell
                                ? otbData?.[0]?.LeftToSell
                                : 0}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <b>on the books</b>
                            </td>
                            <td className="text-end">
                              {otbData?.[0]?.OnTheBook ? otbData?.[0]?.OnTheBook : 0}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <b>total OCC%</b>
                            </td>
                            <td className="text-end">
                              {otbData?.[0]?.TotalOCCPercentage
                                ? otbData?.[0]?.TotalOCCPercentage
                                : 0}
                              %
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <b>ADR</b>
                            </td>
                            <td className="text-end">
                              {otbData?.[0]?.ADR
                                ? commonService.formateAmount(otbData?.[0]?.ADR)
                                : commonService.formateAmount(0)}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <b>Revenue</b>
                            </td>
                            <td className="text-end">
                              {otbData?.[0]?.REV
                                ? commonService.formateAmount(otbData?.[0]?.REV)
                                : commonService.formateAmount(0)}
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                    <h6 className="tools-new-design-right-sec-red-title">
                      Forecast
                    </h6>
                    <div className="tools-new-design-right-sec-table no-thead-table">
                      <Table responsive>
                        <tbody>
                          <tr>
                            <td>
                              <b>Rooms</b>
                            </td>
                            <td className="text-end">
                              {forecastData?.[0]?.RMS
                                ? forecastData?.[0]?.RMS
                                : 0}
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                    <h6 className="tools-new-design-right-sec-red-title">
                      Rate Shop
                    </h6>
                    <div className="tools-new-design-right-sec-table no-thead-table">
                      <Table responsive>
                        <tbody>
                          {rateShopData && (
                            <tr>
                              <td>
                                <b>
                                  {findSelfPropertyData()?.[0]
                                    ?.competiterpropertyname?.length > 10
                                    ? `${findSelfPropertyData()?.[0]?.competiterpropertyname?.slice(
                                        0,
                                        10
                                      )}...`
                                    : findSelfPropertyData()?.[0]
                                        ?.competiterpropertyname}
                                </b>
                              </td>
                              <td className="text-end">
                                {findSelfPropertyData()?.[0]?.Rate
                                  ? commonService.formateAmount(
                                      findSelfPropertyData()?.[0]?.Rate
                                    )
                                  : commonService.formateAmount(0)}
                              </td>
                            </tr>
                          )}
                          {rateShopData && (
                            <tr>
                              <td>
                                <b>Avg Compititor</b>
                              </td>
                              <td className="text-end">
                                {commonService.formateAmount(
                                  findCompetitorAvg()
                                )}
                              </td>
                            </tr>
                          )}

                          {rateShopData?.map((item) => {
                            return (
                              <>
                                {item?.IsSelf === false && (
                                  <tr>
                                    <td>
                                      <b>
                                        {item?.competiterpropertyname?.length >
                                        10
                                          ? `${item?.competiterpropertyname?.slice(
                                              0,
                                              10
                                            )}...`
                                          : item?.competiterpropertyname}
                                      </b>
                                    </td>
                                    <td className="text-end">
                                      {item?.Rate
                                        ? commonService.formateAmount(
                                            item?.Rate
                                          )
                                        : commonService.formateAmount(0)}
                                    </td>
                                  </tr>
                                )}
                              </>
                            );
                          })}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </Modal>
      {deleteModal && (
        <DeleteItemModal
          showDeleteItemModal={deleteModal}
          closeDeleteItemModal={closeDeleteModal}
          id={deleteId}
          getList={getEventList}
          url={API_URL.BASE_API_URL.RevenueManagement + API_URL.EVENTS.DELETE}
        />
      )}
    </>
  );
};

export default EventViewModal;
