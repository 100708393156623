import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Col,
  Container,
  Row,
  Table,
  Form,
  Badge,
  Overlay,
  Popover,
} from "react-bootstrap";
import { useSelector } from "react-redux";
import moment from "moment";
import DatePicker from "react-datepicker";
import calendarIcon from "../../../../Assets/Images/Revenue-management/calendarIcon.svg";
import { apiCall } from "../../../../utils/axiosService";
import { API_URL } from "../../../../utils/apiRoutes";
import { apiResponse } from "../../../../utils/apiResponse";
import { commonService } from "../../../../utils/commonService";
import { errorToastMessage } from "../../../../utils/toastMessage";
import ticket from "../../../../Assets/Images/Revenue-management/tableTicket.svg";
import alarm from "../../../../Assets/Images/Revenue-management/tableAlarm.svg";
import NoteIcon from "../../../../Assets/Images/CRM/notes.svg";
import darkNoteIcon from "../../../../Assets/Images/CRM/darkNotes.svg";
import ToolsSingleTicketPopUp from "../../../CommonComponent/ToolsSingleTicketPopUp";
import RateIQPopupCalender from "../.././RateIQPopupCalender";
import Loader from "../../../Loader/Loader";
import WidgetNote from "../.././WidgetNote";
import premonth from "../../../../Assets/Images/Revenue-management/preMonth.svg";
import nextmonth from "../../../../Assets/Images/Revenue-management/nextMonth.svg";
import settingIcon from "../../../../Assets/Images/Home-side-bar/setting.svg";
import RateIqSettingModal from "../.././RateIqSettingModal";
import viewIcon from "../../../../Assets/Images/Action Icons/viewaction.svg";
import RateIqViewModal from "../.././RateIqViewModal";
import ToolsSummaryModal from "../.././ToolsSummaryModal";
import EventViewModal from "../../../CommonComponent/EventViewModal";

const RateIq = ({
  setCompDataFromChild,
  rowIndex,
  colIndex,
  api,
  para,
  setting,
}) => {
  const date = commonService.convertDateInTimezone(new Date());
  const startDate = commonService.getDateInDBFormat(
    commonService.convertDateInTimezone(
      new Date(date.getFullYear(), date.getMonth(), 1)
    )
  );
  const endDate = commonService.getDateInDBFormat(
    commonService.convertDateInTimezone(
      new Date(date.getFullYear(), date.getMonth() + 2, 0)
    )
  );
  const propertyDetailFromRTK = useSelector(
    (store) => store.headerProperty.propertyDetail
  );

  const [checkIn, setCheckIn] = useState(new Date());
  const [checkOut, setCheckOut] = useState(
    new Date().setDate(new Date().getDate() + 1)
  );
  const [night, setNight] = useState(1);
  const [tableData, setTableData] = useState(null);
  const eventListRef = useRef();
  const eventListPropsRef = useRef();
  const reminderListPropsRef = useRef();
  const selectedEventDateRef = useRef();
  const reminderListRef = useRef();
  const isReminderRef = useRef();
  const [showTicketModal, setShowTicketModal] = useState(false);

  const [rateIqCalenderModal, setRateIqCalenderModal] = useState(false);
  const [datesArrForBgColor, setDatesArrForBgColor] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showNoteSidebar, setShowNoteSidebar] = useState(false);
  const [settingModal, setSettingModal] = useState(false);
  const [viewReminderModal, setViewReminderModal] = useState(false);
  const closeSettingModal = () => {
    setSettingModal(false);
  };
  const closeViewReminderModal = () => {
    setViewReminderModal(false);
  };
  const clickedDateForNote = useRef();
  const closeNoteSideBar = () => {
    setShowNoteSidebar(false);
  };
  const [dateRange, setDateRange] = useState({
    start: commonService.convertDateInTimezone(startDate),
    end: commonService.convertDateInTimezone(endDate),
  });

  const datesArr = commonService.getDateBetweenTwoDates(
    commonService.convertDateInTimezone(dateRange.start),
    commonService.convertDateInTimezone(dateRange.end)
  );

  const yieldDataRef = useRef();
  const losDataRef = useRef();
  const marketSegmentRef = useRef();
  const customerTypeRef = useRef();
  const weekDayRef = useRef();
  const eventRef = useRef();
  const rateIqSettingApiCalledRef = useRef(false);
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [rateGainData, setRateGainData] = useState(null);
  const calculationDetailRef = useRef({});
  const [rateIqViewModal, setRateIqViewModal] = useState(false);
  const clickedDateDetail = useRef();
  const [showToolsSummaryModal, setToolsSummaryModal] = useState(false);
  const selectedDate = useRef();
  const [groupModal, setGroupModal] = useState({
    IsDisplay: false,
    Target: null,
    Position: "top",
  });
  const clickedDateRef = useRef();
  const [noteDetail, setNoteDetail] = useState(null);

  const handleGroupBtnClick = (event) => {
    let Position = "bottom";
    let Top = event.nativeEvent.layerY - 2;
    if (Top + 400 > window.innerHeight) {
      Position = "top";
    }
    setGroupModal({
      IsDisplay: true,
      Target: event.target,
      Position: Position,
    });
  };

  const closeGroupDate = () => {
    clickedDateRef.current = "";
    setGroupModal({
      IsDisplay: false,
      Target: null,
      Position: "bottom",
    });
  };

  const closeToolsSummaryModal = () => {
    setToolsSummaryModal(false);
  };

  const closeRateIqViewModal = () => {
    setRateIqViewModal(false);
  };

  const handlePrevMonth = () => {
    let startDate = moment(dateRange.start).toDate();
    startDate.setMonth(startDate.getMonth() - 2);
    let sd = commonService.convertDateInTimezone(startDate);

    let ed = moment(dateRange.end).toDate();
    let endDate = commonService.getDateInDBFormat(
      commonService.convertDateInTimezone(
        new Date(ed.getFullYear(), ed.getMonth() - 1, 0)
      )
    );

    setDateRange({
      start: commonService.convertDateInTimezone(sd),
      end: commonService.convertDateInTimezone(endDate),
    });
  };

  const handleNextMonth = () => {
    let startDate = moment(dateRange.start).toDate();
    startDate.setMonth(startDate.getMonth() + 2);
    let sd = commonService.convertDateInTimezone(startDate);

    let ed = moment(dateRange.end).toDate();
    let endDate = commonService.getDateInDBFormat(
      commonService.convertDateInTimezone(
        new Date(ed.getFullYear(), ed.getMonth() + 3, 0)
      )
    );

    setDateRange({
      start: commonService.convertDateInTimezone(sd),
      end: commonService.convertDateInTimezone(endDate),
    });
  };

  const closeRateIqCalendarModal = () => {
    setRateIqCalenderModal(false);
  };

  const closeTicketModal = () => {
    setShowTicketModal(false);
  };

  const getRateGainData = async () => {
    let rateGainDataResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.SnapShot +
          API_URL.RATEIQ.GET_RATE_GAIN_DATA_FROM_THIRD_PARTY,
        body: {
          propertyCode: propertyDetailFromRTK
            ? propertyDetailFromRTK?.propertycode
            : "",
          propertyid: propertyDetailFromRTK
            ? propertyDetailFromRTK?.propertyid
            : "",
          CheckInStartDate: checkIn
            ? commonService.getDateForNode(checkIn)
            : "",
          CheckInEndDate: checkOut
            ? commonService.getDateForNode(checkOut)
            : "",
        },
      },
      false
    );

    let response = apiResponse(false, rateGainDataResponse);

    if (response?.isValidate) {
      setRateGainData(response?.data?.data);
    }

    if (!response?.isValidate) {
      setRateGainData(null);
    }
  };

  const handleGoBtnClick = () => {
    closeGroupDate();
    calculationDetailRef.current = [];
    if (checkIn && checkOut) {
      let datesArr = commonService.getDateBetweenTwoDatesForHiglightRateIq(
        commonService.convertDateInTimezone(checkIn),
        commonService.convertDateInTimezone(checkOut)
      );
      setDatesArrForBgColor(datesArr);
      getRateGainData();
    } else {
      setDatesArrForBgColor(null);
    }
  };

  const getReminderList = async () => {
    let reminderListResponse = await apiCall(
      {
        method: "POST",
        url: API_URL.BASE_API_URL.RevenueManagement + API_URL.REMINDER.GET_LIST,
        body: {
          pageNum: 1,
          pageSize: 10,
          sortColumn: "",
          sortDirection: "asc",
          searchValue: "",
          propertyid: propertyDetailFromRTK?.propertyid,
          startdate: commonService.getDateInDBFormat(
            commonService.convertDateInTimezone(dateRange.start)
          ),
          enddate: commonService.getDateInDBFormat(
            commonService.convertDateInTimezone(dateRange.end)
          ),
          remindername: "",
          remindertypeterm: "",
        },
      },
      false
    );

    let reminderResponse = apiResponse(false, reminderListResponse);

    if (reminderResponse?.isValidate) {
      reminderListRef.current = reminderResponse?.data?.data;
    }
  };

  const getRateIqData = async () => {
    setLoading(true);
    let rateIqResponse = await apiCall(
      {
        method: "POST",
        url: API_URL.BASE_API_URL.SnapShot + API_URL.RATEIQ.GET_LIST,
        body: {
          propertyCode: propertyDetailFromRTK?.propertycode,
          propertyid: propertyDetailFromRTK?.propertyid,
          AsOfDate: para?.asOfDate,
          startDate: commonService.getDateForNode(
            commonService.convertDateInTimezone(dateRange.start)
          ),
          endDate: commonService.getDateForNode(
            commonService.convertDateInTimezone(dateRange.end)
          ),
        },
      },
      false
    );

    let response = apiResponse(false, rateIqResponse);

    if (response?.isValidate) {
      setTableData(response?.data?.data);
      setLoading(false);
    }

    if (!response?.isValidate) {
      setLoading(false);
      setTableData(null);
    }
  };

  const getYieldList = async () => {
    setLoading(true);
    rateIqSettingApiCalledRef.current = true;
    let yieldResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.RevenueManagement +
          API_URL.RATEIQ_YIELD.GET_LIST,
        body: {
          propertyid: propertyDetailFromRTK?.propertyid,
        },
      },
      false
    );

    let response = apiResponse(false, yieldResponse);

    if (response?.isValidate) {
      let dataFromapi = response?.data?.data;
      yieldDataRef.current = dataFromapi;
    }

    if (!response?.isValidate) {
      yieldDataRef.current = null;
    }
  };

  const getLosDetail = async () => {
    let losResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.RevenueManagement + API_URL.RATEIQ_LOS.GET_LIST,
        body: { propertyid: propertyDetailFromRTK?.propertyid },
      },
      false
    );

    let response = apiResponse(false, losResponse);

    if (response?.isValidate) {
      losDataRef.current = response?.data?.data;
    }

    if (!response?.isValidate) {
      losDataRef.current = [];
    }
  };

  const getMarketSegmentData = async () => {
    let marketSegmentResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.Configuration +
          API_URL.PROJECT_TERM.GET_LIST_BY_MULTIPLE_CATEGORY,
        body: {
          categorynamebycomma: "Market Segment",
        },
      },
      false
    );

    let response = apiResponse(false, marketSegmentResponse);

    if (response?.isValidate) {
      marketSegmentRef.current = response?.data?.data;
    }

    if (!response?.isValidate) {
      marketSegmentRef.current = null;
    }
  };

  const getCustomerType = async () => {
    let customerTypeResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.RevenueManagement +
          API_URL.RATEIQ_CUSTOMER_TYPE.GET_LIST,
        body: {
          propertyid: propertyDetailFromRTK?.propertyid,
        },
      },
      false
    );

    let response = apiResponse(false, customerTypeResponse);

    if (response?.isValidate) {
      customerTypeRef.current = response?.data?.data;
    }

    if (!response?.isValidate) {
      customerTypeRef.current = null;
    }
  };

  const getWeekDayData = async () => {
    let weekDayResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.RevenueManagement +
          API_URL.RATEIQ_WEEKDAY.GET_LIST,
        body: {
          propertyid: propertyDetailFromRTK?.propertyid,
        },
      },
      false
    );

    let response = apiResponse(false, weekDayResponse);

    if (response?.isValidate) {
      weekDayRef.current = response?.data?.data;
    }

    if (!response?.isValidate) {
      weekDayRef.current = null;
    }
  };

  const getEventList = async () => {
    let eventListResponse = await apiCall(
      {
        method: "POST",
        url: API_URL.BASE_API_URL.RevenueManagement + API_URL.EVENTS.GET_LIST,
        body: {
          pageNum: 1,
          pageSize: 10,
          sortColumn: "",
          sortDirection: "asc",
          searchValue: "",
          propertyid: parseInt(propertyDetailFromRTK?.propertyid),
          startdate: null,
          enddate: null,
          eventname: "",
          eventtypeterm: "",
          yearterm: ``,
        },
      },
      false
    );

    let response = apiResponse(false, eventListResponse);

    if (response?.isValidate) {
      eventRef.current = response?.data?.data;
      let eventListArr = [];

      response?.data?.data?.forEach((item) => {
        let singleEventObj = {
          id: item?.eventid ? item?.eventid : 0,
          name: item?.eventname,
          detail: item?.eventdetails,
          startDate: item?.startdate,
          endDate: item?.enddate,
          eventJson: item?.eventjson,
          eventResourceId: item?.eventsourceid,
        };
        let eventJsonData = "";
        if (item?.eventjson) {
          var decodedString = decodeURIComponent(item?.eventjson);
          const jsonStringWithoutQuotes = decodedString.slice(1, -1);
          const jsonStringWithSingleQuotes = `${jsonStringWithoutQuotes}`;
          eventJsonData = JSON.parse(jsonStringWithSingleQuotes);
          let desc = eventJsonData?.description?.replaceAll("+", " ");
          singleEventObj = {
            ...singleEventObj,
            labels: eventJsonData?.labels ? eventJsonData?.labels : "",
            rank: eventJsonData?.rank ? eventJsonData?.rank : 0,
            local_rank: eventJsonData?.local_rank
              ? eventJsonData?.local_rank
              : 0,
            phq_attendance: eventJsonData?.phq_attendance
              ? eventJsonData?.phq_attendance
              : 0,
            geo_lat: eventJsonData?.geo_lat ? eventJsonData?.geo_lat : "",
            geo_long: eventJsonData?.geo_long ? eventJsonData?.geo_long : "",
            detail: desc,
          };
        }
        eventListArr.push(singleEventObj);
      });
      eventListRef.current = eventListArr;
    }

    if (!response?.isValidate) {
      eventRef.current = null;
      eventListRef.current = null;
    }
  };

  const getNoteByDataList = async () => {
    let noteByDataListResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.Configuration +
          API_URL.WIDGET_NOTE.GET_BY_DATA_LIST,
        body: {
          propertyid: propertyDetailFromRTK?.propertyid,
          // widgetid: para?.widgetId,
          widgetid: 0,
          startdate: commonService.getDateInDBFormat(dateRange?.start),
          enddate: commonService.getDateInDBFormat(dateRange?.end),
        },
      },
      false
    );

    let response = apiResponse(false, noteByDataListResponse);

    if (response?.isValidate) {
      setNoteDetail(response?.data?.data);
    }

    if (!response?.isValidate) {
      setNoteDetail(null);
    }
  };

  const callAllApi = async () => {
    if (para?.asOfDate) {
      if (rateIqSettingApiCalledRef.current === false) {
        await getYieldList();
        await getLosDetail();
        await getMarketSegmentData();
        await getCustomerType();
        await getWeekDayData();
        await getEventList();
      }
      await getRateIqData();
      await getNoteByDataList();
    }
  };

  useEffect(() => {
    if (api !== 0) {
      handleGoBtnClick();
      getRateGainData();
    }
  }, []);

  useEffect(() => {
    if (api !== 0) {
      getReminderList();
      callAllApi();
    }
  }, [dateRange, propertyDetailFromRTK, para?.asOfDate]);
  useEffect(() => {
    if (api !== 0) {
      rateIqSettingApiCalledRef.current = false;
    }
  }, [propertyDetailFromRTK]);

  const calculateMyRate = (currentDate) => {
    let myRate = 0;

    let filterOutData = rateGainData?.filter((item) => {
      if (
        commonService.getDateInFormat(item?.CheckInDate) ===
          commonService.getDateInFormat(currentDate) &&
        item?.isself === true
      ) {
        return item;
      }
    });

    myRate = filterOutData?.[0]?.Rate ? filterOutData?.[0]?.Rate : 0;
    filterOutData?.forEach((item) => {
      if (myRate > item?.Rate) {
        myRate = item?.Rate;
      }
    });

    return myRate;
  };

  const calculateCompetitorAvg = (currentDate) => {
    let avgRate = 0;

    let filterOutData = rateGainData?.filter((item) => {
      if (
        commonService.getDateInFormat(item?.CheckInDate) ===
          commonService.getDateInFormat(currentDate) &&
        item?.isself === false
      ) {
        return item;
      }
    });

    let obj = {};
    filterOutData?.forEach((item) => {
      if (obj.hasOwnProperty([item?.RGPropertyID])) {
        if (item?.Rate < obj[item?.RGPropertyID]) {
          obj[item?.RGPropertyID] = item?.Rate;
        }
      } else {
        obj[item?.RGPropertyID] = item?.Rate;
      }
    });
    let countForPropertyAvg = 0,
      totalOfCompetitorPropertyRate = 0;
    Object?.entries(obj)?.map(([key, value]) => {
      if (
        value !== null &&
        value !== 0 &&
        value !== "" &&
        value !== undefined
      ) {
        countForPropertyAvg++;
        totalOfCompetitorPropertyRate = totalOfCompetitorPropertyRate + value;
      }
    });
    avgRate =
      countForPropertyAvg > 0
        ? Math.round(totalOfCompetitorPropertyRate / countForPropertyAvg)
        : 0;
    return avgRate;
  };

  return (
    <div className="RMRateShopMain">
      {propertyDetailFromRTK ? (
        <Container fluid>
          <Row>
            <Col xl={12}>
              <div className="ProspectDashboardTitleWraper  d-flex justify-content-between align-items-center">
                <h2>
                  Dashboard-{" "}
                  <span className="tab-title-main">
                    Revenue Management - Tools - RateIQ
                  </span>
                </h2>
                <div className="generalTermSetup-heading-btns">
                  {/* <Link to="/revenue/rateIq-calender">
                      <Button className="heading-menu-btns" type="button">
                        <img src={calendarIcon} alt="calendarIcon" />
                      </Button>
                    </Link> */}
                  <Button
                    className="heading-menu-btns"
                    type="button"
                    onClick={() => {
                      closeGroupDate();
                      setRateIqCalenderModal(true);
                    }}
                  >
                    <img src={calendarIcon} alt="calendarIcon" />
                  </Button>
                  {/* <Link to="/revenue/rateIq">
                      <Button className="heading-menu-btns" type="button">
                        <img src={horizobtalBarIcon} alt="horizobtalBarIcon" />
                      </Button>
                    </Link> */}
                  <Button
                    className="heading-menu-btns"
                    type="button"
                    onClick={() => {
                      closeGroupDate();
                      setSettingModal(true);
                    }}
                  >
                    <img src={settingIcon} alt="settingIcon" />
                  </Button>
                </div>
              </div>
            </Col>
            <Col xl={12}>
              <Row>
                <Col xl={2}>
                  <div className="from-groups no-border-break">
                    <Form.Label>Check In</Form.Label>
                    <DatePicker
                      selected={checkIn}
                      onChange={(date) => {
                        if (night) {
                          let checkoutDate = new Date(date);
                          checkoutDate.setDate(
                            checkoutDate.getDate() + parseInt(night)
                          );
                          setCheckOut(checkoutDate);
                        }
                        setCheckIn(date);
                      }}
                    />
                  </div>
                </Col>
                <Col xl={2}>
                  <div className="from-groups no-border-break">
                    <Form.Label>Night</Form.Label>
                    <Form.Control
                      type="text"
                      maxLength={16}
                      value={night}
                      pattern="[0-9]*"
                      onChange={(e) => {
                        if (e.target.validity.valid) {
                          if (checkIn && e.target.value !== "") {
                            let checkoutDate = new Date(checkIn);
                            checkoutDate.setDate(
                              checkoutDate.getDate() + parseInt(e.target.value)
                            );
                            setCheckOut(checkoutDate);
                          }

                          setNight(e.target.value);
                        }
                      }}
                    />
                  </div>
                </Col>
                <Col xl={2}>
                  <div className="from-groups no-border-break">
                    <Form.Label>Check Out</Form.Label>
                    <DatePicker
                      selected={checkOut}
                      onChange={(date) => {
                        if (checkIn && checkOut) {
                          let datesArr =
                            commonService.getDateBetweenTwoDatesForHiglightRateIq(
                              new Date(checkIn),
                              new Date(date)
                            );

                          setNight(datesArr?.length);
                        }
                        setCheckOut(date);
                      }}
                    />
                  </div>
                </Col>
                <Col xl={2}>
                  <div className="from-groups no-border-break">
                    <Form.Label>Customer Type</Form.Label>
                    <Form.Select
                      aria-label="Default select example"
                      value={selectedCustomer}
                      onChange={(e) => {
                        setSelectedCustomer(e.target.value);
                      }}
                    >
                      <option value="">Select Customer Type</option>
                      {marketSegmentRef.current?.map((item) => {
                        return (
                          <option key={item?.term} value={item?.term}>
                            {item?.term}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </div>
                </Col>
                <Col xl={4} className="gobtn">
                  <div className="d-flex gap-2">
                    <Button
                      className="default-btn primary-btn gobtnwidth"
                      onClick={() => {
                        handleGoBtnClick();
                      }}
                    >
                      Go
                    </Button>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col xl={12} className="mt-3">
              <div className="d-flex justify-content-center">
                <div
                  className="month-name-with-arrow"
                  style={{ marginBottom: "0", marginLeft: 0 }}
                >
                  <button
                    onClick={() => {
                      let d = new Date(dateRange?.start);
                      d.setMonth(d.getMonth() - 1);
                      const startDate = commonService.getDateInDBFormat(
                        new Date(d.getFullYear(), d.getMonth(), 1)
                      );
                      const endDate = commonService.getDateInDBFormat(
                        new Date(d.getFullYear(), d.getMonth() + 1, 0)
                      );
                      setDateRange({
                        start: startDate,
                        end: endDate,
                      });
                      handlePrevMonth();
                    }}
                    disabled={loading ? true : false}
                  >
                    <img src={premonth} alt="premonth" />
                  </button>
                  <h4>
                    {`${moment(dateRange?.start)
                      ?.toDate()
                      ?.toLocaleDateString("en-US", {
                        month: "short",
                        year: "numeric",
                      })} ${moment(dateRange?.end)
                      ?.toDate()
                      ?.toLocaleDateString("en-US", {
                        month: "short",
                        year: "numeric",
                      })}`}
                  </h4>
                  <button
                    onClick={() => {
                      let d = new Date(dateRange?.start);
                      d.setMonth(d.getMonth() + 1);
                      const startDate = commonService.getDateInDBFormat(
                        new Date(d.getFullYear(), d.getMonth(), 1)
                      );
                      const endDate = commonService.getDateInDBFormat(
                        new Date(d.getFullYear(), d.getMonth() + 1, 0)
                      );
                      setDateRange({
                        start: startDate,
                        end: endDate,
                      });
                      handleNextMonth();
                    }}
                    disabled={loading ? true : false}
                  >
                    <img src={nextmonth} alt="nextmonth" />
                  </button>
                </div>
              </div>
            </Col>
            <Col xl={12}>
              <div className="RateShopTableViewTable mt-2">
                <div className="table__container">
                  <div className="stripped__table">
                    <div className="table-responsive tblDynamicPickupSticky">
                      <Table>
                        <thead className="tbl-header-sticky">
                          <tr>
                            <th>Date</th>
                            <th>Occ(%)</th>
                            <th>RMS</th>
                            <th>ADR</th>
                            <th>Revenue</th>
                            <th>Predicated Rate</th>
                            <th>My Rate</th>
                            <th>Variances</th>
                            <th>Comp Set Avg</th>
                            <th>Note</th>
                          </tr>
                        </thead>
                        <tbody>
                          {loading ? (
                            <tr
                              style={{ height: "80px", position: "relative" }}
                            >
                              <Loader />
                            </tr>
                          ) : tableData ? (
                            datesArr?.map((item, index) => {
                              let checkEvent = eventListRef.current?.filter(
                                (x) => {
                                  let checkingDate = moment(item);
                                  let startingDate = moment(x?.startDate);
                                  let endingDate = moment(x?.endDate);
                                  if (
                                    checkingDate.isSameOrAfter(startingDate) &&
                                    checkingDate.isSameOrBefore(endingDate)
                                  ) {
                                    return x;
                                  }
                                }
                              );

                              let checkReminder =
                                reminderListRef.current?.filter((x) => {
                                  let checkingDate = moment(item);
                                  let startingDate = moment(x?.startdate);
                                  let endingDate = moment(x?.enddate);
                                  if (
                                    checkingDate.isSameOrAfter(startingDate) &&
                                    checkingDate.isSameOrBefore(endingDate)
                                  ) {
                                    return x;
                                  }
                                });

                              let checkNoteExists =
                                noteDetail?.notedata?.filter(
                                  (x) =>
                                    moment(x?.datadate).format("YYYY-MM-DD") ===
                                    moment(item).format("YYYY-MM-DD")
                                );

                              let singleDateDetail = tableData?.filter(
                                (x) =>
                                  moment(x?.Dates).format("YYYY-MM-DD") ===
                                  moment(item).format("YYYY-MM-DD")
                              );

                              let isApplyBgColor = datesArrForBgColor?.filter(
                                (x) =>
                                  moment(x).format("YYYY-MM-DD") ===
                                  moment(item).format("YYYY-MM-DD")
                              );

                              let myRateByCalculation =
                                singleDateDetail?.[0]?.["predicted rate"];

                              if (!calculationDetailRef.current[item]) {
                                calculationDetailRef.current[item] = {
                                  0: {
                                    type: "Predicated Rate",
                                    calculation: `${commonService.formateAmount(
                                      singleDateDetail?.[0]?.["predicted rate"]
                                    )}`,
                                  },
                                };
                              }

                              const calculationForMyRate = (
                                rate,
                                type,
                                order
                              ) => {
                                let myRateCalc =
                                  (myRateByCalculation * Math.abs(rate)) / 100;

                                if (rate > 0) {
                                  myRateByCalculation =
                                    myRateByCalculation + myRateCalc;
                                  calculationDetailRef.current[item] = {
                                    ...calculationDetailRef.current[item],
                                    [order]: {
                                      type,
                                      calculation: `+${commonService.formateAmount(
                                        myRateCalc?.toFixed(2)
                                      )}`,
                                    },
                                  };
                                } else {
                                  myRateByCalculation =
                                    myRateByCalculation - myRateCalc;
                                  calculationDetailRef.current[item] = {
                                    ...calculationDetailRef.current[item],
                                    [order]: {
                                      type,
                                      calculation: `-${commonService.formateAmount(
                                        myRateCalc?.toFixed(2)
                                      )}`,
                                    },
                                  };
                                }
                              };

                              if (
                                isApplyBgColor?.length > 0 &&
                                myRateByCalculation
                              ) {
                                let nearestOcc = yieldDataRef.current?.reduce(
                                  (acc, obj) =>
                                    Math.abs(
                                      singleDateDetail?.[0]?.OCC - obj.occupancy
                                    ) <
                                    Math.abs(
                                      singleDateDetail?.[0]?.OCC - acc.occupancy
                                    )
                                      ? obj
                                      : acc
                                );

                                let findYieldData =
                                  yieldDataRef.current?.filter((yieldItem) => {
                                    if (
                                      yieldItem?.occupancy ===
                                        nearestOcc?.occupancy &&
                                      night >= yieldItem?.minday &&
                                      night <= yieldItem?.maxday
                                    ) {
                                      return yieldItem;
                                    }
                                  });
                                if (
                                  findYieldData?.length > 0 &&
                                  findYieldData?.[0]?.ratevalue !== 0
                                ) {
                                  calculationForMyRate(
                                    findYieldData?.[0]?.ratevalue,
                                    "Yield",
                                    "1"
                                  );
                                }

                                let findLosData = losDataRef.current?.filter(
                                  (losItem) => {
                                    if (
                                      night >= losItem?.minlos &&
                                      night <= losItem?.maxlos
                                    ) {
                                      return losItem;
                                    }
                                  }
                                );

                                if (
                                  findLosData?.length > 0 &&
                                  findLosData?.[0]?.ratevalue !== 0
                                ) {
                                  calculationForMyRate(
                                    findLosData?.[0]?.ratevalue,
                                    "LOS",
                                    "2"
                                  );
                                }

                                let findCustomerData =
                                  customerTypeRef.current?.filter(
                                    (customerItem) => {
                                      if (
                                        customerItem?.customertype ===
                                        selectedCustomer
                                      ) {
                                        return customerItem;
                                      }
                                    }
                                  );

                                if (
                                  findCustomerData?.length > 0 &&
                                  findCustomerData?.[0]?.ratevalue !== 0
                                ) {
                                  calculationForMyRate(
                                    findCustomerData?.[0]?.ratevalue,
                                    "Customer Type",
                                    "3"
                                  );
                                }

                                let findWeekDayData =
                                  weekDayRef.current?.filter((weekDayItem) => {
                                    if (
                                      weekDayItem?.weekdayname ==
                                      moment(
                                        commonService.convertDateInTimezone(
                                          item
                                        ),
                                        "l"
                                      ).format("dddd")
                                    ) {
                                      return weekDayItem;
                                    }
                                  });

                                if (
                                  findWeekDayData?.length > 0 &&
                                  findWeekDayData?.[0]?.ratevalue !== 0
                                ) {
                                  calculationForMyRate(
                                    findWeekDayData?.[0]?.ratevalue,
                                    "Week Day",
                                    "4"
                                  );
                                }

                                let findEventData = eventRef.current?.filter(
                                  (x) => {
                                    let checkingDate =
                                      moment(item).format("MM/DD/YYYY");
                                    let startingDate = moment(
                                      x?.startdate
                                    ).format("MM/DD/YYYY");
                                    let endingDate = moment(x?.enddate).format(
                                      "MM/DD/YYYY"
                                    );
                                    let d1 = startingDate?.split("/");
                                    let d2 = endingDate?.split("/");
                                    let d3 = checkingDate?.split("/");

                                    let parseStartingDate = new Date(
                                      d1[2],
                                      parseInt(d1[1]) - 1,
                                      d1[0]
                                    );
                                    let parseEndingDate = new Date(
                                      d2[2],
                                      parseInt(d2[1]) - 1,
                                      d2[0]
                                    );
                                    let parseCheckingDate = new Date(
                                      d3[2],
                                      parseInt(d3[1]) - 1,
                                      d3[0]
                                    );
                                    if (
                                      parseCheckingDate >= parseStartingDate &&
                                      parseCheckingDate <= parseEndingDate
                                    ) {
                                      return x;
                                    }
                                  }
                                );

                                if (
                                  findEventData?.length > 0 &&
                                  findEventData?.[0]?.rateiqratevalue !== 0
                                ) {
                                  calculationForMyRate(
                                    findEventData?.[0]?.rateiqratevalue,
                                    "Event",
                                    "5"
                                  );
                                }
                              }

                              calculationDetailRef.current[item] = {
                                ...calculationDetailRef.current[item],
                                6: {
                                  type: "Final Rate",
                                  calculation: `${
                                    myRateByCalculation
                                      ? commonService.formateAmount(
                                          Math.round(myRateByCalculation)
                                        )
                                      : commonService.formateAmount(0)
                                  }`,
                                },
                              };

                              let weekendTrBgColor = "";
                              if (
                                moment(
                                  commonService.convertDateInTimezone(item),
                                  "l"
                                ).format("ddd") === "Fri" ||
                                moment(
                                  commonService.convertDateInTimezone(item),
                                  "l"
                                ).format("ddd") === "Sat"
                              ) {
                                weekendTrBgColor = "rateIq-weekend";
                              }

                              let variance =
                                (singleDateDetail?.[0]?.ml_rate
                                  ? singleDateDetail?.[0]?.ml_rate
                                  : 0) -
                                (singleDateDetail?.[0]?.my_rate
                                  ? singleDateDetail?.[0]?.my_rate
                                  : 0);
                              return (
                                <tr
                                  key={item}
                                  className={`${
                                    isApplyBgColor?.length > 0 ? "rateIqTr" : ""
                                  } ${weekendTrBgColor} shadowOnHover`}
                                >
                                  {/* <td>{commonService.getDateInFormat(item)}</td> */}
                                  <td>
                                    <div className="d-flex align-items-center gap-4">
                                      <div
                                        style={{ width: "100px" }}
                                        className="cursorPointer"
                                        onClick={() => {
                                          selectedDate.current = item;
                                          setToolsSummaryModal(true);
                                        }}
                                      >
                                        {`${commonService.getDateInFormat(
                                          commonService.convertDateInTimezone(
                                            item
                                          )
                                        )} ${moment(
                                          commonService.convertDateInTimezone(
                                            item
                                          ),
                                          "l"
                                        ).format("ddd")}`}
                                      </div>
                                      <div className="d-flex justify-content-between align-items-center gap-2 ticketAlarmReload">
                                        <img
                                          src={ticket}
                                          className={`iconcolor ${
                                            checkEvent?.length > 0
                                              ? "rateiq-img-visible"
                                              : "rateiq-img-hide"
                                          }`}
                                          alt="ticket"
                                          onClick={() => {
                                            closeGroupDate();
                                            selectedEventDateRef.current = item;
                                            eventListPropsRef.current =
                                              checkEvent;
                                            setShowTicketModal(true);
                                          }}
                                        />

                                        <img
                                          className={`iconcolor ${
                                            checkReminder?.length > 0
                                              ? "rateiq-img-visible"
                                              : "rateiq-img-hide"
                                          }`}
                                          src={alarm}
                                          alt="ticket"
                                          onClick={() => {
                                            closeGroupDate();
                                            reminderListPropsRef.current =
                                              checkReminder;
                                            isReminderRef.current = true;
                                            setViewReminderModal(true);
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    {singleDateDetail?.[0]?.OCC
                                      ? `${singleDateDetail?.[0]?.OCC}%`
                                      : `0%`}
                                  </td>
                                  <td>
                                    {singleDateDetail?.[0]?.RMS
                                      ? singleDateDetail?.[0]?.RMS
                                      : 0}
                                  </td>
                                  <td>
                                    {singleDateDetail?.[0]?.ADR
                                      ? commonService.formateAmount(
                                          singleDateDetail?.[0]?.ADR
                                        )
                                      : commonService.formateAmount(0)}
                                  </td>
                                  <td>
                                    {singleDateDetail?.[0]?.REV
                                      ? commonService.formateAmount(
                                          singleDateDetail?.[0]?.REV
                                        )
                                      : commonService.formateAmount(0)}
                                  </td>
                                  <td>
                                    <div className="d-flex align-items-center  gap-2">
                                      <p className="width75">
                                        {myRateByCalculation
                                          ? `${commonService.formateAmount(
                                              Math.round(myRateByCalculation)
                                            )}`
                                          : commonService.formateAmount(0)}
                                      </p>
                                      {isApplyBgColor?.length > 0 && (
                                        <img
                                          style={{
                                            height: "15px",
                                            cursor: "pointer",
                                          }}
                                          src={viewIcon}
                                          onClick={(e) => {
                                            if (
                                              clickedDateRef.current ===
                                              commonService.getDateInFormat(
                                                calculationDetailRef.current[
                                                  item
                                                ]
                                              )
                                            ) {
                                              closeGroupDate();
                                            } else {
                                              clickedDateRef.current =
                                                commonService.getDateInFormat(
                                                  calculationDetailRef.current[
                                                    item
                                                  ]
                                                );
                                              handleGroupBtnClick(e);
                                              clickedDateDetail.current =
                                                calculationDetailRef.current[
                                                  item
                                                ];
                                            }
                                          }}
                                        />
                                      )}
                                    </div>
                                  </td>
                                  <td>
                                    {isApplyBgColor?.length > 0
                                      ? `${commonService.formateAmount(
                                          calculateMyRate(item)
                                        )}`
                                      : singleDateDetail?.[0]?.my_rate
                                      ? `${commonService.formateAmount(
                                          singleDateDetail?.[0]?.my_rate
                                        )}`
                                      : commonService.formateAmount(0)}
                                  </td>
                                  <td>
                                    {variance > 0 ? (
                                      <Badge className="default-badge active-badge">
                                        {commonService.formateAmount(variance)}
                                      </Badge>
                                    ) : (
                                      <Badge className="default-badge inactive-badge">
                                        {commonService.formateAmount(
                                          Math.abs(variance)
                                        )}
                                      </Badge>
                                    )}
                                  </td>
                                  <td
                                    className="cursorPointer"
                                    onClick={() => {
                                      selectedDate.current = item;
                                      setToolsSummaryModal(true);
                                    }}
                                  >
                                    {/* $
                                    {singleDateDetail?.[0]?.avg_rate
                                      ? singleDateDetail?.[0]?.avg_rate
                                      : 0} */}

                                    {isApplyBgColor?.length > 0
                                      ? `${commonService.formateAmount(
                                          calculateCompetitorAvg(item)
                                        )}`
                                      : singleDateDetail?.[0]?.avg_rate
                                      ? `${commonService.formateAmount(
                                          singleDateDetail?.[0]?.avg_rate
                                        )}`
                                      : commonService.formateAmount(0)}
                                  </td>
                                  <td>
                                    {checkNoteExists?.length > 0 ? (
                                      <img
                                        style={{ cursor: "pointer" }}
                                        src={darkNoteIcon}
                                        onClick={() => {
                                          clickedDateForNote.current = item;
                                          setShowNoteSidebar(true);
                                        }}
                                        alt="note"
                                      />
                                    ) : (
                                      <img
                                        style={{ cursor: "pointer" }}
                                        src={NoteIcon}
                                        onClick={() => {
                                          clickedDateForNote.current = item;
                                          setShowNoteSidebar(true);
                                        }}
                                        alt="note"
                                      />
                                    )}
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td className="text-center" colSpan={10}>
                                No Data Found
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>
                {showTicketModal && (
                  <EventViewModal
                    showModal={showTicketModal}
                    closeModal={closeTicketModal}
                    eventDetails={eventListPropsRef.current}
                    selectedDate={selectedEventDateRef.current}
                    getEventList={getEventList}
                  />
                )}
                {viewReminderModal && (
                  <ToolsSingleTicketPopUp
                    showModal={viewReminderModal}
                    closeModal={closeViewReminderModal}
                    eventDetails={reminderListPropsRef.current}
                    isReminder={isReminderRef.current}
                  />
                )}
              </div>
            </Col>
          </Row>
        </Container>
      ) : (
        <Container>
          <div className="d-flex justify-content-center">
            <h5>Please Select Property</h5>
          </div>
        </Container>
      )}
      {settingModal && (
        <RateIqSettingModal
          showModal={settingModal}
          closeModal={closeSettingModal}
        />
      )}
      {showNoteSidebar && (
        <WidgetNote
          show={showNoteSidebar}
          handleClose={closeNoteSideBar}
          // widgetId={101}
          widgetId={0}
          propertyId={propertyDetailFromRTK?.propertyid}
          rowTitle={"Rate IQ"}
          dateForNote={clickedDateForNote.current}
        />
      )}
      {rateIqCalenderModal && (
        <RateIQPopupCalender
          showModal={rateIqCalenderModal}
          closeModal={closeRateIqCalendarModal}
        />
      )}
      {/* {rateIqViewModal && (
        <RateIqViewModal
          showModal={rateIqViewModal}
          closeModal={closeRateIqViewModal}
          data={clickedDateDetail.current}
        />
      )} */}
      <Overlay
        show={groupModal.IsDisplay}
        target={groupModal.Target}
        placement={groupModal.Position}
      >
        <Popover
          id="popover-contained"
          className="SingleDateSummaryModalPopoverMain"
        >
          <div className="horizontal-menu-option my-special-horizontal-menu-option active">
            <RateIqViewModal
              closeModal={closeGroupDate}
              data={clickedDateDetail.current}
            />
          </div>
        </Popover>
      </Overlay>
      {showToolsSummaryModal && (
        <ToolsSummaryModal
          showToolsSummaryModal={showToolsSummaryModal}
          closeToolsSummaryModal={closeToolsSummaryModal}
          selectedDate={selectedDate.current}
          propertyDetail={propertyDetailFromRTK}
          ismlRateExist={true}
        />
      )}
    </div>
  );
};

export default RateIq;
