import React, { useEffect, useRef, useState } from "react";
import profileImage from "../../../../Assets/Images/Configuration/userNo1.png";
import send from "../../../../Assets/Images/Revenue-management/send.svg";
import { apiCall } from "../../../../utils/axiosService";
import { useSelector } from "react-redux";
import { API_URL } from "../../../../utils/apiRoutes";
import { apiResponse } from "../../../../utils/apiResponse";
import DatePicker from "react-datepicker";
import { commonService } from "../../../../utils/commonService";
import { Col } from "react-bootstrap";
import moment from "moment";
import { useParams } from "react-router-dom";
import Loader from "../../../Loader/Loader";

const CommonComment = ({
  setCompDataFromChild,
  rowIndex,
  colIndex,
  api,
  para,
  setting,
}) => {
  const propertyDetailFromRTK = useSelector(
    (store) => store.headerProperty.propertyDetail
  );

  const userRolePropertyListFromRTK = useSelector(
    (store) => store.headerProperty.userRolePropertyList
  );

  const { snapshottitle, propertyCode, snapshotId } = useParams();
  // const validationObj = {
  //   widgetnoteid: 0,
  //   associationid: snapshotId,
  //   associationtype: "snapshot",
  //   widgetnotes: "",
  //   propertyid: propertyDetailFromRTK?.propertyid,
  //   files: "",
  //   asofdate: "",
  //   title: snapshottitle,
  //   istodo: false,
  //   assigntouser: 0,
  //   tododate: commonService.getDateInDBFormat(new Date()),
  //   datadate: "",
  // };
  const defaultAsOfDateFromRTK = useSelector(
    (store) => store.headerProperty.defaultAsOfDate
  );
  const [commentList, setCommentList] = useState(null);
  const [startRangeDate, setStartRangeDate] = useState(
    para?.asOfDate ? new Date(para?.asOfDate) : new Date()
  );
  const [endRangeDate, setEndRangeDate] = useState(
    para?.asOfDate ? new Date(para?.asOfDate) : new Date()
  );
  const startRangeDateRef = useRef();
  const endRangeDateRef = useRef();
  const refForScroll = useRef();
  const [loading, setLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [formData, setFormData] = useState();
  const [filteredApiList, setFilteredApiList] = useState([]);
  const [commentText, setCommentText] = useState(null);
  const [userList, setUserList] = useState(null);
  const [selectedUserId, setSelectedUserIds] = useState([]);
  const [btnDisable, setBtnDisable] = useState(false);

  const handleDateChange = (event) => {
    const [start, end] = event;
    let startSelectedDate = "",
      endSelectedDate = "";
    if (start) {
      let date = new Date(start);
      let month =
        date.getMonth() + 1 > 9
          ? date.getMonth() + 1
          : `0${date.getMonth() + 1}`;
      let dateFormat =
        date?.getDate() > 9 ? date.getDate() : `0${date.getDate()}`;
      startSelectedDate = `${date.getFullYear()}-${month}-${dateFormat}`;
      startRangeDateRef.current = startSelectedDate;
    }
    if (end) {
      let date = new Date(end);
      let month =
        date.getMonth() + 1 > 9
          ? date.getMonth() + 1
          : `0${date.getMonth() + 1}`;
      let dateFormat =
        date?.getDate() > 9 ? date.getDate() : `0${date.getDate()}`;
      endSelectedDate = `${date.getFullYear()}-${month}-${dateFormat}`;
      endRangeDateRef.current = endSelectedDate;
    }

    setStartRangeDate(event[0]);
    setEndRangeDate(event[1]);
    if (event[0] !== null && event[1] !== null) {
      getCommentList();
    }
  };

  const saveComment = async () => {
    setBtnDisable(true);

    if (commentText) {
      setSaveLoading(true);
      let saveResponse = await apiCall(
        {
          method: "POST",
          url:
            API_URL.BASE_API_URL.Configuration +
            API_URL.WIDGET_NOTE.SAVE_BY_ASSOCIATION,
          body: {
            widgetnoteid: 0,
            associationid: snapshotId,
            associationtype: "snapshot",
            widgetnotes: commentText,
            propertyid: propertyDetailFromRTK?.propertyid,
            files: "",
            asofdate: commonService.getDateInDBFormat(para?.asOfDate),
            title: snapshottitle,
            istodo: false,
            assigntouser: 0,
            tododate: commonService.getDateInDBFormat(new Date()),
            datadate: "",
          },
        },
        true
      );

      let response = apiResponse(true, saveResponse, setSaveLoading);

      if (response?.isValidate) {
        setBtnDisable(true);

        const saveResponse = response?.data?.data;
        if (
          saveResponse.widgetnotes !== null &&
          saveResponse.widgetnotes !== "" &&
          saveResponse.widgetnotes !== undefined
        ) {
          let selectedUserId = [];
          let SplitByAT = saveResponse.widgetnotes.match(/\@(.*?)\ /g);
          if (
            userList !== null &&
            SplitByAT !== null &&
            userList.length > 0 &&
            SplitByAT.length > 0
          ) {
            SplitByAT.map((userdisplayname) => {
              let FindData = userList.filter(
                (i) =>
                  "@" +
                    i.displayname.replaceAll(" ", "").toLowerCase().trim() ===
                  userdisplayname.toLowerCase().trim()
              );
              if (FindData !== null && FindData.length > 0) {
                selectedUserId.push(FindData[0].userid);
              }
            });
          }

          if (selectedUserId !== null && selectedUserId.length > 0) {
            let WN = para?.rowTitle ? para?.rowTitle : para?.widgetname;
            commonService.SendPushNotification(
              "New comment on " +
                para?.propertyDetail?.propertyname +
                " property > " +
                para?.snapshottitle +
                " snapshot > " +
                WN +
                " of " +
                commonService.getDateInFormat(para?.asOfDate),
              saveResponse.widgetnotes,
              saveResponse.widgetid,
              saveResponse?.associationtype,
              selectedUserId ? selectedUserId.join(",") : ""
            );
          }
          setCommentText("");
          getCommentList();
        }
      } else {
        setBtnDisable(false);
      }
    }
    setBtnDisable(false);
  };

  const getCommentList = async () => {
    setLoading(true);
    let commentListResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.Configuration +
          API_URL.WIDGET_NOTE.GET_BY_ASSOCIATION,
        body: {
          propertyid: propertyDetailFromRTK?.propertyid,
          associationid: parseInt(snapshotId),
          associationtype: "snapshot",
          startdate: startRangeDateRef.current
            ? commonService.getDateInDBFormat(startRangeDateRef.current)
            : commonService.getDateInDBFormat(defaultAsOfDateFromRTK),
          enddate: endRangeDateRef.current
            ? commonService.getDateInDBFormat(endRangeDateRef.current)
            : commonService.getDateInDBFormat(defaultAsOfDateFromRTK),
        },
      },
      false
    );

    let response = apiResponse(false, commentListResponse);

    if (response?.isValidate) {
      let dataFromApi = response?.data?.data?.notedata;
      let groupingJson = {};

      for (let i = 0; i < dataFromApi?.length; i++) {
        let dateKey = moment(dataFromApi?.[i]?.asofdate).format("YYYY-MM-DD");
        if (groupingJson?.hasOwnProperty(dateKey)) {
          groupingJson[dateKey] = [...groupingJson[dateKey], dataFromApi?.[i]];
        } else {
          groupingJson = {
            ...groupingJson,
            [dateKey]: [dataFromApi?.[i]],
          };
        }
      }

      let sortByKey = {};
      Object.keys(groupingJson)
        .sort((a, b) => {
          return new Date(a) - new Date(b);
        })
        .forEach(function (v, i) {
          let finalJson = groupingJson?.[v]?.sort((a, b) => {
            return new Date(a.createdon) - new Date(b.createdon);
          });
          sortByKey = { ...sortByKey, [v]: finalJson };
        });

      setCommentList(sortByKey);
      setLoading(false);
    }

    if (!response?.isValidate) {
      setCommentList(null);
      setLoading(false);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "@") {
      setUserList(userRolePropertyListFromRTK);
      setFilteredApiList(userRolePropertyListFromRTK);
    }
    if (
      e.keyCode === 13 &&
      (filteredApiList === undefined ||
        filteredApiList === null ||
        filteredApiList.length === 0)
    ) {
      saveComment();
    } else if (
      e.keyCode === 13 &&
      filteredApiList !== null &&
      filteredApiList.length > 0
    ) {
      const commentParts = commentText?.split("@");
      commentParts[
        commentParts.length - 1
      ] = `${filteredApiList[0].displayname.replaceAll(" ", "")} `;
      const updatedComment = commentParts.join("@");
      setCommentText(updatedComment);
      setFilteredApiList([]);
    }
  };

  const handleInputChange = (value) => {
    setCommentText(value);
    const array = value.split("@");
    const lastElement = array.pop();
    const sanitizedValue = lastElement.replace("@", "");
    const filteredList = userList?.filter(
      (api) =>
        api.displayname.toLowerCase().indexOf(sanitizedValue.toLowerCase()) ===
        0
    );
    setFilteredApiList(filteredList);
  };

  const selectUserNameFromList = (selectedUser) => {
    const commentParts = commentText?.split("@");
    commentParts[
      commentParts.length - 1
    ] = `${selectedUser.displayname.replaceAll(" ", "")} `;
    const updatedComment = commentParts.join("@");
    setCommentText(updatedComment);
    setFilteredApiList([]);
  };

  useEffect(() => {
    if (!commentText) {
      setUserList(null);
      setFilteredApiList(null);
    }
  }, [commentText]);

  useEffect(() => {
    if (api === 0) {
    } else {
      if (para?.asOfDate) {
        getCommentList();
      }
    }
  }, [propertyDetailFromRTK, para?.asOfDate]);

  useEffect(() => {
    if (commentList) {
      refForScroll.current?.scrollIntoView({
        block: "end",
      });
    }
  }, [commentList]);

  return (
    <div>
      <Col xl={2}>
        <DatePicker
          onChange={(event) => handleDateChange(event)}
          startDate={startRangeDate}
          endDate={endRangeDate}
          selectsRange
          monthsShown={2}
        />
      </Col>

      <div className="archive-content-right mt-3">
        <div className="all-comments">
          {loading ? (
            <Loader />
          ) : commentList ? (
            Object.entries(commentList).map(([key, value]) => {
              return (
                <>
                  <div class="line-breaker">
                    {commonService.getDateInFormat(key)}
                  </div>
                  {value?.map((item, index) => {
                    return (
                      <div className="single-comment-in-widget d-flex justify-content-between">
                        <div className="single-comment-in-widget-inner">
                          <img src={item?.profilepic} alt="profileImage" />
                          <div>
                            <span>
                              {item?.displayname},{" "}
                              {moment(item?.createdon).format("L hh:mm A")}
                            </span>
                            <p>{item?.widgetnotes}</p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </>
              );
            })
          ) : (
            <div className="text-center">
              <p>No Data Found</p>
            </div>
          )}
          <div ref={refForScroll}></div>
        </div>
        <div className="enter-comment-here position-relative">
          <input
            type="text"
            value={commentText}
            disabled={btnDisable}
            onKeyDown={(e) => handleKeyPress(e)}
            placeholder="Write a comment..."
            className="enter-comment-input w-100"
            onChange={(e) => handleInputChange(e.target.value)}
          />

          <button disabled={btnDisable} className="send-comment-btn">
            <img onClick={() => saveComment()} src={send} alt="send" />
          </button>
          {filteredApiList?.length > 0 && (
            <div className="enter-comment-suggestions">
              <ul>
                {filteredApiList?.map((j, i) => (
                  <li key={i} onClick={() => selectUserNameFromList(j)}>
                    {j.displayname}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CommonComment;
