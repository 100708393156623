import React, { useEffect, useRef, useState } from "react";
import { apiCall } from "../../../../utils/axiosService";
import { API_URL } from "../../../../utils/apiRoutes";
import { apiResponse } from "../../../../utils/apiResponse";
import { Button, Col, Table } from "react-bootstrap";
import Loader from "../../../Loader/Loader";
import NoteIcon from "../../../../Assets/Images/CRM/notes.svg";
import downloadIcon from "../../../../Assets/Images/Operation/downloadIcon.svg";
import { commonService } from "../../../../utils/commonService";
import infoIcon from "../../../../Assets/Images/Action Icons/infoIcon..svg";
import { compName } from "../../../../utils/constant";
import WidgetDetailModal from "../../WidgetDetailModal";
import WidgetNote from "../../WidgetNote";
import { useSelector } from "react-redux";
import AddWidgetModal from "../../AddWidgetModal";
import { useParams } from "react-router-dom";
import emailIcon from "../../../../Assets/Images/Email.svg";
import Comments from "../Comments/Comment";
import { FixColumn } from "../../../../utils/dataTable";
import moment from "moment";

const WidgetPickup = ({
  setCompDataFromChild,
  rowIndex,
  colIndex,
  api,
  para,
  setting,
}) => {
  const tableData = {
    Pickup_list: [
      {
        id: 1,
        propertyCode: "-",
        AsOfDate: "-",
        Date: "-",
        Day_Diff: "0",
        Rms_Forecast: 0,
        Total_Booking: "0",
        DBA_90: "0",
        PU_90: "0",
        DBA_60: "0",
        PU_60: "0",
        DBA_30: "0",
        PU_30: "0",
        DBA_14: "0",
        PU_14: "0",
        DBA_07: "0",
        PU_07: "0",
        DBA_03: "0",
        PU_03: "0",
        DBA_01: "0",
        PU_01: "0",
        DBA_00: "0",
        PU_00: "0",
        Final: "0",
      },
      {
        id: 2,
        propertyCode: "-",
        AsOfDate: "-",
        Date: "-",
        Day_Diff: "0",
        Rms_Forecast: 0,
        Total_Booking: "0",
        DBA_90: "0",
        PU_90: "0",
        DBA_60: "0",
        PU_60: "0",
        DBA_30: "0",
        PU_30: "0",
        DBA_14: "0",
        PU_14: "0",
        DBA_07: "0",
        PU_07: "0",
        DBA_03: "0",
        PU_03: "0",
        DBA_01: "0",
        PU_01: "0",
        DBA_00: "0",
        PU_00: "0",
        Final: "0",
      },
      {
        id: 3,
        propertyCode: "-",
        AsOfDate: "-",
        Date: "-",
        Day_Diff: "0",
        Rms_Forecast: 0,
        Total_Booking: "0",
        DBA_90: "0",
        PU_90: "0",
        DBA_60: "0",
        PU_60: "0",
        DBA_30: "0",
        PU_30: "0",
        DBA_14: "0",
        PU_14: "0",
        DBA_07: "0",
        PU_07: "0",
        DBA_03: "0",
        PU_03: "0",
        DBA_01: "0",
        PU_01: "0",
        DBA_00: "0",
        PU_00: "0",
        Final: "0",
      },
      {
        id: 4,
        propertyCode: "-",
        AsOfDate: "-",
        Date: "-",
        Day_Diff: "0",
        Rms_Forecast: 0,
        Total_Booking: "0",
        DBA_90: "0",
        PU_90: "0",
        DBA_60: "0",
        PU_60: "0",
        DBA_30: "0",
        PU_30: "0",
        DBA_14: "0",
        PU_14: "0",
        DBA_07: "0",
        PU_07: "0",
        DBA_03: "0",
        PU_03: "0",
        DBA_01: "0",
        PU_01: "0",
        DBA_00: "0",
        PU_00: "0",
        Final: "0",
      },
      {
        id: 5,
        propertyCode: "-",
        AsOfDate: "-",
        Date: "-",
        Day_Diff: "0",
        Rms_Forecast: 0,
        Total_Booking: "0",
        DBA_90: "0",
        PU_90: "0",
        DBA_60: "0",
        PU_60: "0",
        DBA_30: "0",
        PU_30: "0",
        DBA_14: "0",
        PU_14: "0",
        DBA_07: "0",
        PU_07: "0",
        DBA_03: "0",
        PU_03: "0",
        DBA_01: "0",
        PU_01: "0",
        DBA_00: "0",
        PU_00: "0",
        Final: "0",
      },
      {
        id: 6,
        propertyCode: "-",
        AsOfDate: "-",
        Date: "-",
        Day_Diff: "0",
        Rms_Forecast: 0,
        Total_Booking: "0",
        DBA_90: "0",
        PU_90: "0",
        DBA_60: "0",
        PU_60: "0",
        DBA_30: "0",
        PU_30: "0",
        DBA_14: "0",
        PU_14: "0",
        DBA_07: "0",
        PU_07: "0",
        DBA_03: "0",
        PU_03: "0",
        DBA_01: "0",
        PU_01: "0",
        DBA_00: "0",
        PU_00: "0",
        Final: "0",
      },
      {
        id: 7,
        propertyCode: "-",
        AsOfDate: "-",
        Date: "-",
        Day_Diff: "0",
        Rms_Forecast: 0,
        Total_Booking: "0",
        DBA_90: "0",
        PU_90: "0",
        DBA_60: "0",
        PU_60: "0",
        DBA_30: "0",
        PU_30: "0",
        DBA_14: "0",
        PU_14: "0",
        DBA_07: "0",
        PU_07: "0",
        DBA_03: "0",
        PU_03: "0",
        DBA_01: "0",
        PU_01: "0",
        DBA_00: "0",
        PU_00: "0",
        Final: "0",
      },
      {
        id: 8,
        propertyCode: "-",
        AsOfDate: "-",
        Date: "-",
        Day_Diff: "0",
        Rms_Forecast: 0,
        Total_Booking: "0",
        DBA_90: "0",
        PU_90: "0",
        DBA_60: "0",
        PU_60: "0",
        DBA_30: "0",
        PU_30: "0",
        DBA_14: "0",
        PU_14: "0",
        DBA_07: "0",
        PU_07: "0",
        DBA_03: "0",
        PU_03: "0",
        DBA_01: "0",
        PU_01: "0",
        DBA_00: "0",
        PU_00: "0",
        Final: "0",
      },
      {
        id: 9,
        propertyCode: "-",
        AsOfDate: "-",
        Date: "-",
        Day_Diff: "0",
        Rms_Forecast: 0,
        Total_Booking: "0",
        DBA_90: "0",
        PU_90: "0",
        DBA_60: "0",
        PU_60: "0",
        DBA_30: "0",
        PU_30: "0",
        DBA_14: "0",
        PU_14: "0",
        DBA_07: "0",
        PU_07: "0",
        DBA_03: "0",
        PU_03: "0",
        DBA_01: "0",
        PU_01: "0",
        DBA_00: "0",
        PU_00: "0",
        Final: "0",
      },
      {
        id: 10,
        propertyCode: "-",
        AsOfDate: "-",
        Date: "-",
        Day_Diff: "0",
        Rms_Forecast: 0,
        Total_Booking: "0",
        DBA_90: "0",
        PU_90: "0",
        DBA_60: "0",
        PU_60: "0",
        DBA_30: "0",
        PU_30: "0",
        DBA_14: "0",
        PU_14: "0",
        DBA_07: "0",
        PU_07: "0",
        DBA_03: "0",
        PU_03: "0",
        DBA_01: "0",
        PU_01: "0",
        DBA_00: "0",
        PU_00: "0",
        Final: "0",
      },
    ],
  };

  const [pickupData, setPickupData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showNoteSidebar, setShowNoteSidebar] = useState(false);
  const [widgetModal, setWidgetModal] = useState(false);
  const widgetDetailRef = useRef();
  const propertyDetailFromRTK = useSelector(
    (store) => store.headerProperty.propertyDetail
  );
  const defaultAsOfDateFromRTK = useSelector(
    (store) => store.headerProperty.defaultAsOfDate
  );
  const { snapshottitle, propertyCode, snapshotId } = useParams();
  const closeWidgetModal = () => {
    setWidgetModal(false);
  };
  const closeNoteSideBar = () => {
    setShowNoteSidebar(false);
  };

  const getWidgetPickupData = async () => {
    setLoading(true);
    let pickupResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.SnapShot + API_URL.WIDGET_MODULE.WIDGET_PICKUP,
        body: {
          AsOfDate: para?.asOfDate,
          propertyCode: para?.propertyCode,
        },
      },
      false
    );

    let response = apiResponse(false, pickupResponse);

    if (response?.isValidate) {
      let groupingData = response?.data?.data?.Pickup_list?.filter(
        (x) => parseFloat(x?.Day_Diff) > 0
      );
      setPickupData({
        Pickup_list: groupingData,
        Average_list: response?.data?.data?.Average_list,
      });
      setLoading(false);
    }

    if (!response?.isValidate) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (api === 0) {
      setPickupData(tableData);
    } else {
      if (para?.asOfDate) {
        getWidgetPickupData();
      }
    }
  }, [para?.asOfDate]);

  const [tblids, settblids] = useState({
    tblWidgetPickup: commonService.GenerateGUID(),
  });

  FixColumn(tblids.tblWidgetPickup, [1]);

  return (
    <div className="widget-container">
      {api !== 0 && (
        <div className="gap-1 widget-container-menu">
          {/* <Button className="heading-menu-btns" type="button">
            <img
              src={NoteIcon}
              alt="menuIcon"
              onClick={() => {
                setShowNoteSidebar(true);
              }}
            />
          </Button> */}
          <Button className="heading-menu-btns" type="button" title="Send Mail">
            <img
              src={emailIcon}
              alt="emailIcon"
              onClick={() => {
                commonService.fnSendWidgetMail(
                  tblids.tblWidgetPickup,
                  "Widget Pickup",
                  snapshotId,
                  defaultAsOfDateFromRTK,
                  "",
                  para?.widgetId
                );
              }}
            />
          </Button>
          <Button className="heading-menu-btns" type="button">
            <img
              src={infoIcon}
              alt="info"
              onClick={() => {
                widgetDetailRef.current = {
                  widgetdetails: "",
                  usernote: "",
                  compName: compName?.widgetPickup,
                  widgetId: para?.widgetId,
                };
                setWidgetModal(true);
              }}
            />
          </Button>
          <Button className="heading-menu-btns" type="button">
            <img
              src={downloadIcon}
              alt="menuIcon"
              import={tblids.tblWidgetPickup}
              onClick={() =>
                commonService.fnExportInCSV(
                  tblids.tblWidgetPickup,
                  // "Widget Pickup"
                  `${para?.propertyCode}-Widget Pickup-${moment(
                    new Date()
                  ).format("MMDDYYYY")}`
                )
              }
            />
          </Button>
        </div>
      )}
      <div className="ViewPropertySetupTable">
        <div className="stripped__table">
          <div className="table-responsive tblDynamicPickupSticky">
            <Table id={tblids.tblWidgetPickup}>
              <thead className="tbl-header-sticky">
                <tr className="table-main-header-tr">
                  {/* <th colSpan={21} className="text-center">
                  Widget Pickup
                </th> */}
                  <th colSpan={4} className="text-center"></th>
                  <th colSpan={2} className="text-center border-right">
                    90
                  </th>
                  <th colSpan={2} className="text-center border-right">
                    60
                  </th>
                  <th colSpan={2} className="text-center border-right">
                    30
                  </th>
                  <th colSpan={2} className="text-center border-right">
                    14
                  </th>
                  <th colSpan={2} className="text-center border-right">
                    7
                  </th>
                  <th colSpan={2} className="text-center border-right">
                    3
                  </th>
                  <th colSpan={2} className="text-center border-right">
                    1
                  </th>
                  <th colSpan={2} className="text-center border-right">
                    0
                  </th>
                  <th className="border-right"></th>
                </tr>
                <tr className="table-main-header-tr">
                  <th className="text-center">Date</th>
                  <th className="text-center">Day Diff</th>
                  <th className="text-center">Rms Forecast</th>
                  <th className="text-center">Total Booking</th>
                  <th className="text-center border-right">DBA</th>
                  <th className="text-center">PU</th>
                  <th className="text-center border-right">DBA</th>
                  <th className="text-center">PU</th>
                  <th className="text-center border-right">DBA</th>
                  <th className="text-center">PU</th>
                  <th className="text-center border-right">DBA</th>
                  <th className="text-center">PU</th>
                  <th className="text-center border-right">DBA</th>
                  <th className="text-center">PU</th>
                  <th className="text-center border-right">DBA</th>
                  <th className="text-center">PU</th>
                  <th className="text-center border-right">DBA</th>
                  <th className="text-center">PU</th>
                  <th className="text-center border-right">DBA</th>
                  <th className="text-center">PU</th>
                  <th className="text-center border-right">Final</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr style={{ height: "80px", position: "relative" }}>
                    <Loader />
                  </tr>
                ) : (
                  pickupData &&
                  pickupData?.Pickup_list?.map((item, index) => (
                    <>
                      <tr key={item?.id} className="shadowOnHover">
                        <td>
                          {item?.Date
                            ? commonService.getDateInFormat(item?.Date)
                            : ""}
                        </td>
                        <td className="text-center">{item?.Day_Diff}</td>
                        <td className="text-center">
                          {item?.Rms_Forecast ? item?.Rms_Forecast : 0}
                        </td>
                        <td className="text-center">{item?.Total_Booking}</td>
                        <td className="text-center border-right">
                          {item?.DBA_90}
                        </td>
                        <td className="text-center">{item?.PU_90}</td>
                        <td className="text-center border-right">
                          {item?.DBA_60}
                        </td>
                        <td className="text-center">{item?.PU_60}</td>
                        <td className="text-center border-right">
                          {item?.DBA_30}
                        </td>
                        <td className="text-center">{item?.PU_30}</td>
                        <td className="text-center border-right">
                          {item?.DBA_14}
                        </td>
                        <td className="text-center">{item?.PU_14}</td>
                        <td className="text-center border-right">
                          {item?.DBA_07}
                        </td>
                        <td className="text-center">{item?.PU_07}</td>
                        <td className="text-center border-right">
                          {item?.DBA_03}
                        </td>
                        <td className="text-center">{item?.PU_03}</td>
                        <td className="text-center border-right">
                          {item?.DBA_01}
                        </td>
                        <td className="text-center">{item?.PU_01}</td>
                        <td className="text-center border-right">
                          {item?.DBA_00}
                        </td>
                        <td className="text-center">{item?.PU_00}</td>
                        <td className="text-center border-right">
                          {item?.Final}
                        </td>
                      </tr>
                      {index === pickupData?.Pickup_list?.length - 1 &&
                        pickupData?.Average_list?.map((item, index) => (
                          <tr key={index} className="shadowOnHover">
                            <td className="boldTd text-start">Last 90D Avg</td>
                            <td className="boldTd text-center"></td>
                            <td className="boldTd text-center"></td>
                            <td className="boldTd text-center"></td>
                            <td className="boldTd text-center border-right"></td>
                            <td className="boldTd text-center">
                              {item?.PU_90}
                            </td>
                            <td className="boldTd text-center border-right"></td>
                            <td className="boldTd text-center">
                              {item?.PU_60}
                            </td>
                            <td className="boldTd text-center border-right"></td>
                            <td className="boldTd text-center">
                              {item?.PU_30}
                            </td>
                            <td className="boldTd text-center border-right"></td>
                            <td className="boldTd text-center">
                              {item?.PU_14}
                            </td>
                            <td className="boldTd text-center border-right"></td>
                            <td className="boldTd text-center">
                              {item?.PU_07}
                            </td>
                            <td className="boldTd text-center border-right"></td>
                            <td className="boldTd text-center">
                              {item?.PU_03}
                            </td>
                            <td className="boldTd text-center border-right"></td>
                            <td className="boldTd text-center">
                              {item?.PU_01}
                            </td>
                            <td className="boldTd text-center border-right"></td>
                            <td className="boldTd text-center">
                              {item?.PU_00}
                            </td>
                            <td className="boldTd text-center border-right">
                              {}
                            </td>
                          </tr>
                        ))}
                    </>
                  ))
                )}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
      {api !== 0 && <Comments widgetDetails={para} editorId={"WidgetPickup"}/>}
      {/* {showNoteSidebar && (
        <WidgetNote
          show={showNoteSidebar}
          handleClose={closeNoteSideBar}
          widgetId={para?.widgetId}
          propertyId={propertyDetailFromRTK?.propertyid}
          rowTitle={para?.rowTitle}
        />
      )} */}
      {showNoteSidebar && (
        <AddWidgetModal
          showModal={showNoteSidebar}
          closeModal={closeNoteSideBar}
          widgetId={para?.widgetId}
          propertyId={propertyDetailFromRTK?.propertyid}
          rowTitle={para?.rowTitle}
        />
      )}
      {widgetModal && (
        <WidgetDetailModal
          showModal={widgetModal}
          closeModal={closeWidgetModal}
          data={widgetDetailRef.current}
        />
      )}
    </div>
  );
};

export default WidgetPickup;
