import React, { useRef, useState } from "react";
import { Button, Col, Container, Row, Form } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import calendarIcon from "../../../../Assets/Images/Revenue-management/calendarIcon.svg";
import horizobtalBarIcon from "../../../../Assets/Images/Revenue-management/horizontal-bars.svg";
import ToolsEventModal from "../.././ToolsEventModal";
import RmEventFilter from "../.././RmEventFilter";
import { apiCall } from "../../../../utils/axiosService";
import { apiResponse } from "../../../../utils/apiResponse";
import { API_URL } from "../../../../utils/apiRoutes";
import EventMonthView from "../.././EventMonthView";
import EventWeekView from "../.././EventWeekView";
import location from "../../../../Assets/Images/Revenue-management/mapLocation.svg";

const Event = ({
  setCompDataFromChild,
  rowIndex,
  colIndex,
  api,
  para,
  setting,
}) => {
  const propertyDetailFromRTK = useSelector(
    (store) => store.headerProperty.propertyDetail
  );
  const [dateFromChild, setDateFromChild] = useState(new Date());
  const [eventList, setEventList] = useState([]);
  const nodeEventListRef = useRef([]);
  const dbEventListRef = useRef([]);
  const eventDetailRef = useRef();
  const [calenderView, setCalenderView] = useState("Month");

  const [CalOption, setCalOption] = useState({
    date: dateFromChild,
    viewtype: calenderView,
    data: eventList,
  });

  const setValueFromChild = (date) => {
    setDateFromChild(date);
  };

  const [eventDetailModal, setEventDetailModal] = useState(false);

  const closeEventDetailModal = () => {
    setEventDetailModal(false);
  };

  const handleEventDetailsModal = (eventDetail) => {
    let data = eventDetail;
    if (
      eventDetail?.eventjson !== null &&
      eventDetail?.eventjson !== "" &&
      eventDetail?.eventjson !== undefined
    ) {
      var str = decodeURIComponent(eventDetail?.eventjson);
      str = str.substring(1, str.length - 1);
      data = JSON.parse(str.replaceAll("+", " "));
    }
    eventDetailRef.current = data;
    setEventDetailModal(true);
  };

  const getEventFromNodeSide = async () => {
    let eventListResponse = await apiCall(
      {
        method: "POST",
        url: API_URL.BASE_API_URL.SnapShot + API_URL.WIDGET_MODULE.EVENT,
        body: {
          propertyCode: propertyDetailFromRTK?.propertycode,
          startDate: "",
          endDate: "",
          category: "",
          rankFrom: "",
          rankTo: "",
          attendanceFrom: "",
          attendanceTo: "",
          localrankFrom: "",
          localrankTo: "",
        },
      },
      false
    );

    let response = apiResponse(false, eventListResponse);

    if (response?.isValidate) {
      nodeEventListRef.current = response?.data?.data;
      setEventList([...dbEventListRef.current, ...response?.data?.data]);
    }
  };

  const getEventFromDb = async () => {
    let eventListResponse = await apiCall(
      {
        method: "POST",
        url: API_URL.BASE_API_URL.RevenueManagement + API_URL.EVENTS.GET_LIST,
        body: {
          pageNum: 1,
          pageSize: 10,
          sortColumn: "",
          sortDirection: "asc",
          searchValue: "",
          propertyid: propertyDetailFromRTK?.propertyid,
          startdate: null,
          enddate: null,
          eventname: "",
          eventtypeterm: "",
          yearterm: `${new Date(dateFromChild).getFullYear()}`,
        },
      },
      false
    );

    let response = apiResponse(false, eventListResponse);

    if (response?.isValidate) {
      let filterdData = response?.data?.data?.filter(
        (x) => x?.eventsourceid === null
      );
      dbEventListRef.current = response?.data?.data.filter(
        (x) => x?.eventsourceid !== null && x?.eventsourceid !== ""
      );
      setEventList([...nodeEventListRef.current, ...filterdData]);
    }
  };

  const [showModal, setShowModal] = useState(false);
  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);
  const navigate = useNavigate();
  const [showView, setShowView] = useState("Calendar");
  return (
    <div className="RmEventsMain" style={{ position: "relative" }}>
      {propertyDetailFromRTK ? (
        <Container fluid>
          <Row>
            <Col xl={12}>
              <div className="ProspectDashboardTitleWraper  d-flex justify-content-between  align-items-center">
                <h2>
                  Dashboard-{" "}
                  <span className="tab-title-main">Tools - Events</span>
                </h2>
                <div className="generalTermSetup-heading-btns">
                  <div className="from-groups no-border-break diffBgSelect">
                    <Form.Select
                      aria-label="Default select example"
                      style={{ height: "36px" }}
                      onChange={(e) => {
                        setCalenderView(e.target.value);
                      }}
                    >
                      <option value="Month">Month</option>
                      <option value="Week">Week</option>
                    </Form.Select>
                  </div>
                  <Link to="/revenue/event">
                    <Button className="heading-menu-btns" type="button">
                      <img src={calendarIcon} alt="calendarIcon" />
                    </Button>
                  </Link>
                  <Link to="/revenue/event/listing-page">
                    <Button className="heading-menu-btns" type="button">
                      <img src={horizobtalBarIcon} alt="horizobtalBarIcon" />
                    </Button>
                  </Link>
                  <Link to={"/revenue/event/mapView"}>
                    <Button className="heading-menu-btns" type="button">
                      <img src={location} alt="location" />
                    </Button>
                  </Link>
                </div>
              </div>
            </Col>

            <Col xl={12}>
              <div>
                <div className="meeting-catering-calendar">
                  {calenderView === "Month" ? (
                    <EventMonthView showData={showView} />
                  ) : (
                    <EventWeekView />
                  )}
                </div>
              </div>
            </Col>
          </Row>
          {eventDetailModal && (
            <ToolsEventModal
              showToolsEventModal={eventDetailModal}
              closeToolsEventModal={closeEventDetailModal}
              eventDetails={eventDetailRef.current}
              nodeEventData={nodeEventListRef.current}
              dbEventData={dbEventListRef.current}
            />
          )}

          <RmEventFilter show={showModal} handleClose={closeModal} />
        </Container>
      ) : (
        <Container>
          <div className="d-flex justify-content-center">
            <h5>Please Select Property</h5>
          </div>
        </Container>
      )}
    </div>
  );
};

export default Event;
