import React, { useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import dashboardIcon from "../../Assets/Images/Operation/dashboardIcon.svg";
import frontdeskIcon from "../../Assets/Images/Operation/frontdeskIcon.svg";
import houskeepingIcon from "../../Assets/Images/Operation/housekeepingIcon.svg";
import maintananceIcon from "../../Assets/Images/Operation/maintananceIcon.svg";
import generalmanagerIcon from "../../Assets/Images/Operation/generalmanagerIcon.svg";
import { Link, NavLink } from "react-router-dom";

function AsideOperation() {
  const [activeRoute, setActiveRoute] = useState("/");
  return (
    <aside className="asideConfigurationMain asideOperationMain">
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header className="configurationAccordionHeader">
            <NavLink to="/operation/dashboard">
              <img src={dashboardIcon} alt="dashboardIcon" />
              Dashboard
            </NavLink>
          </Accordion.Header>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header className="configurationAccordionHeader  having-children">
            <img src={frontdeskIcon} alt="frontdeskIcon" /> Setup & Front Desk
          </Accordion.Header>
          <Accordion.Body>
            <ul>
              <li>
                <NavLink to="/operation/property">Property</NavLink>
              </li>
              <li>
                <NavLink to="/operation/arrival-and-departure">
                  Arrival/ Departure
                </NavLink>
              </li>
              <li>
                <NavLink to="/operation/history">History</NavLink>
              </li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header className="configurationAccordionHeader  having-children">
            <NavLink to="/operation/housekeeping">
              <img src={houskeepingIcon} alt="houskeepingIcon" />
              Housekeeping
            </NavLink>
          </Accordion.Header>
          <Accordion.Body>
            <ul>
              <li>
                <NavLink to="/operation/housekeeping/room-attendant">Room Attendant</NavLink>
              </li>
              <li>
                <NavLink to="/operation/housekeeping/checklist">Checklist</NavLink>
              </li>
              <li>
                <NavLink to="/operation/housekeeping/roaster-management">Roaster Management</NavLink>
              </li>
              <li>
                <NavLink to="/operation/housekeeping/schedule">Schedule</NavLink>
              </li>
              <li>
                <NavLink to="/operation/housekeeping/costcalculator">Cost Calculator</NavLink>
              </li>
              <li>
                <NavLink to="/operation/housekeeping/status">Status</NavLink>
              </li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header className="configurationAccordionHeader  having-children">
            <NavLink to="/operation/maintenance">
              <img src={maintananceIcon} alt="maintananceIcon" />
              Maintenance
            </NavLink>
          </Accordion.Header>
          <Accordion.Body>
            <ul>
              <li>
                <NavLink to="/operation/maintenance/task">Task</NavLink>
              </li>
              <li>
                <NavLink to="/operation/maintenance/vendor">Vendor</NavLink>
              </li>
              <li>
                <NavLink to="/operation/maintenance/capexrequests">CAPEx Requests</NavLink>
              </li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="4">
          <Accordion.Header className="configurationAccordionHeader  having-children">
            <NavLink to="/operation/generalmanager/nightaudit">
              <img src={generalmanagerIcon} alt="generalmanagerIcon" />
              General Manager
            </NavLink>
          </Accordion.Header>
          <Accordion.Body>
            <ul>
              <li>
                <NavLink to="/operation/generalmanager/nightaudit">Night Audit</NavLink>
              </li>
              <li>
                <NavLink to="/operation/generalmanager/reservereconciliation">Reserve Reconciliation</NavLink>
              </li>
              <li>
                <NavLink to="/operation/generalmanager/accountreconciliation">Account Reconciliation</NavLink>
              </li>
              <li>
                <NavLink to="/operation/generalmanager/rates">Rates</NavLink>
              </li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </aside>
  );
}

export default AsideOperation;
